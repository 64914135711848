import {
  PortableTextProps,
} from '@portabletext/react';

export const reduceFootnotes = (blocks: PortableTextProps['value']) => {
  if (!Array.isArray(blocks)) {
    return null;
  }
  const notes = blocks
    // eslint-disable-next-line no-underscore-dangle
    .filter((block) => block._type === 'richBlock')
    .reduce((acc, block) => [...acc, ...block.markDefs], [] as { _type: string, _key: string, text: PortableTextProps['value'] }[])
    // eslint-disable-next-line no-underscore-dangle
    .filter((markDef) => markDef._type === 'footnote');
  if (notes.length === 0) {
    return null;
  }
  return notes;
};
