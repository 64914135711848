import * as React from 'react';
import { OnSubmitType, onSubmitContext } from './context';

export const OnSubmitContextProvider = (props: React.PropsWithChildren<{ onSubmit?: OnSubmitType}>) => {
  const { onSubmit = null, children } = props;
  return (
    <onSubmitContext.Provider value={onSubmit}>
      {children}
    </onSubmitContext.Provider>
  );
};
