import {
  dataStructure, Product, UncastedDataStructure, UncastedOnboarding,
} from '@goldwasserexchange/oblis-frontend-utils';
import { makeNext, NavAction } from '../../../../StateMachine/utils';

const onboardingPath: keyof Pick<UncastedDataStructure, 'onboarding'> = 'onboarding';

const product: keyof Pick<UncastedOnboarding, 'products'> = 'products';

const experience: keyof Pick<Product, 'experience'> = 'experience';

export const makeExperienceQuestionnaireStep = (questionnaireName: dataStructure.financialProducts.listType, target: string, parallel?: boolean) => {
  const productExperienceKey = `${onboardingPath}.${product}.${questionnaireName}.${experience}`;
  const step = ({
    on: {
      [NavAction.NEXT]: [makeNext(target, {
        cond: {
          type: 'formikValuesAreValid',
          names: [productExperienceKey],
        },
      })[NavAction.NEXT], {
        actions: [{
          type: 'touch',
          names: [productExperienceKey],
        }],
      }],
    },
  });
  if (!parallel) {
    return { experience: step };
  }
  return (
    {
      experience: {
        initial: 'question',
        states: {
          question: step,
          done: {
            type: 'final' as const,
          },
        },
      },
    }
  );
};
