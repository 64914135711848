import ouiBase from '@goldwasserexchange/oui';
import { useBondSameIssuerFieldApiBySAccId } from '../api';

export const useBondSameIssuerSMarketYield = () => {
  const sAccId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const response = useBondSameIssuerFieldApiBySAccId({
    sAccId,
    field: 'S_MARKET.YIELD',
  });
  return response;
};
