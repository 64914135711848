import * as React from 'react';
import { dataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import ouiDom from '@goldwasserexchange/oui-dom';
import { Ramp } from '@goldwasserexchange/react-topmodel';
import ouiBase from '@goldwasserexchange/oui';
import { detailedAccountTypeLabelsHeadingContentGold } from './gold';
import { detailedAccountTypeLabelsHeadingContentPremium } from './premium';
import { detailedAccountTypeLabelsHeadingContentGlobalPlus } from './globalPlus';

export const ServiceSection = (props: React.PropsWithChildren<{
  accountType: dataStructure.accountType.listType,
  index: number,
  illustrationPosition?: ouiBase.HeadingSection.HeadingSectionIllustrationPosition,
  introFirst?: boolean,
}>) => {
  const {
    accountType,
    index,
    illustrationPosition = 'before',
    introFirst = true,
    children,
  } = props;
  return (
    <ouiDom.HeadingSection.HeadingSection
      index={index}
      name={`${accountType}-section`}
      introFirst={introFirst}
      illustrationPosition={illustrationPosition}
      textAlign="center"
    >
      <Ramp rampName="AdvantagesList" />
      {detailedAccountTypeLabelsHeadingContentGold}
      {detailedAccountTypeLabelsHeadingContentPremium}
      {detailedAccountTypeLabelsHeadingContentGlobalPlus}
      {children}
    </ouiDom.HeadingSection.HeadingSection>
  );
};
