import useSWR, { KeyedMutator } from 'swr';
import { useMemo } from 'react';
import memoize from 'memoizee';
import { AccountPosition } from '../type';
import { Accounts } from '../../enum';
import { getServiceUrl } from '../../../../shared';
import { useAccountPositionsStringifiedParams } from './defaultParams';
import { ACTOR_DEDUPING_INTERVAL, isIdLoading } from '../../../shared';
import { useFetchWithAuthWithSend } from '../../../shared/utils/fetchWithAuth';
import { makeApiUrl } from '../../../../../../aws';

const useMakeAccountsPositionsUrl = () => {
  const stringifiedParams = useAccountPositionsStringifiedParams();
  const url = stringifiedParams !== null ? `${makeApiUrl('actor')}/${getServiceUrl(Accounts.Positions)}${stringifiedParams}` : null;
  return url;
};

type BaseUseAccountPositionApiResponse = {
  isLoading: boolean,
  error?: any,
  mutate: KeyedMutator<AccountPosition[]>,
  isValidating: boolean,
}

type UseAccountPositionsApiResponse = {
  data: undefined | AccountPosition[],
} & BaseUseAccountPositionApiResponse;

type FilterPredicate = (accountsPosition: AccountPosition) => boolean;

type SortPredicate = (prevAccountPosition: AccountPosition, nextAccountPosition: AccountPosition) => number;

type UseAccountPositionsApiProps = {
  filterPredicate?: FilterPredicate,
  sortPredicate?: SortPredicate,
};

export const useAccountPositionsApi = (props: UseAccountPositionsApiProps): UseAccountPositionsApiResponse => {
  const {
    filterPredicate,
    sortPredicate,
  } = props;
  const url = useMakeAccountsPositionsUrl();
  const fetcher = useFetchWithAuthWithSend();
  const result = useSWR<AccountPosition[], any>(
    url,
    fetcher,
    { dedupingInterval: ACTOR_DEDUPING_INTERVAL },
  );
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    let { data } = result;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      if (filterPredicate) {
        data = data.filter((position) => filterPredicate(position));
      }
    }
    return ({
      ...result,
      data,
      isLoading,
    });
  }, [result, filterPredicate, sortPredicate]);
  return resultWithLoading;
};

type TransformArrayFunction<T> = (accountPositions: AccountPosition[] | undefined) => T

type UseAccountPositionsApiTransformProps<T> = {
  transformFunction: TransformArrayFunction<T>,
} & UseAccountPositionsApiProps

type UseAccountPositionApiTransformResponse<T> = {
  data: T | undefined,
} & BaseUseAccountPositionApiResponse;

export function useAccountPositionsTransformApi<T>(props: UseAccountPositionsApiTransformProps<T>): UseAccountPositionApiTransformResponse<T> {
  const {
    filterPredicate,
    sortPredicate,
    transformFunction,
  } = props;
  const response = useAccountPositionsApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: (!isLoading && !error) ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UseAccountPositionApiResponse = {
  data: AccountPosition | undefined,
} & BaseUseAccountPositionApiResponse;

export const useAccountPositionApi = (props: UseAccountPositionsApiProps): UseAccountPositionApiResponse => {
  const {
    filterPredicate,
    sortPredicate,
  } = props;
  const url = useMakeAccountsPositionsUrl();
  const fetcher = useFetchWithAuthWithSend();
  const result = useSWR<AccountPosition[], any>(
    url,
    fetcher,
    { dedupingInterval: ACTOR_DEDUPING_INTERVAL },
  );
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    // eslint-disable-next-line prefer-destructuring
    let data: AccountPosition[] | AccountPosition | undefined = result.data;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      if (filterPredicate) {
        data = data.find((position) => filterPredicate(position));
      }
      if (Array.isArray(data)) {
        // eslint-disable-next-line prefer-destructuring
        data = data[0];
      }
    }
    return ({
      ...result,
      data,
      isLoading,
    });
  }, [result, filterPredicate, sortPredicate]);
  return resultWithLoading;
};

type TransformFunction<T> = (accountPosition: AccountPosition | undefined) => T

type UseAccountPositionApiTransformProps<T> = {
  transformFunction: TransformFunction<T>,
} & UseAccountPositionsApiProps;

export function useAccountPositionTransformApi<T>(props: UseAccountPositionApiTransformProps<T>): UseAccountPositionApiTransformResponse<T> {
  const {
    filterPredicate,
    sortPredicate,
    transformFunction,
  } = props;
  const response = useAccountPositionApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: !isLoading && !error ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UseAccountPositionFieldApiResponse<Field extends keyof AccountPosition> = {
  data: AccountPosition[Field] | undefined,
} & BaseUseAccountPositionApiResponse;

type UseAccountPositionFieldApiProps<Field extends keyof AccountPosition> = {
  field: Field,
} & UseAccountPositionsApiProps

export function useAccountPositionFieldApi<Field extends keyof AccountPosition>(props: UseAccountPositionFieldApiProps<Field>): UseAccountPositionFieldApiResponse<Field> {
  const {
    filterPredicate,
    sortPredicate,
    field,
  } = props;
  const response = useAccountPositionApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
    } = response;
    return {
      ...response,
      data: data ? data[field] : undefined,
    };
  }, [response, field]);
  return result;
}

const memoFindByGesEstimDId: (data: AccountPosition[], hisMvtId: string) => AccountPosition | undefined = memoize((data: AccountPosition[], gesEstimDId: string) => data.find((positionLine) => `${positionLine['GES_ESTIM_D.ID']}` === gesEstimDId));

type UseAccountPositionApiTransformByGesEstimDIdProps<T> = {
  gesEstimDId: string,
  transformFunction: TransformFunction<T>,
  sortPredicate?: SortPredicate | undefined,
}

export function useAccountPositionTransformApiByGesEstimDId<T>(props: UseAccountPositionApiTransformByGesEstimDIdProps<T>): UseAccountPositionApiTransformResponse<T> {
  const {
    gesEstimDId,
    sortPredicate,
    transformFunction,
  } = props;
  const url = useMakeAccountsPositionsUrl();
  const gesEstimDIdLoading = isIdLoading(gesEstimDId);
  const fetcher = useFetchWithAuthWithSend();
  const response = useSWR<AccountPosition[], any>(
    !gesEstimDIdLoading ? url : null,
    fetcher,
    { dedupingInterval: ACTOR_DEDUPING_INTERVAL },
  );
  const result = useMemo(() => {
    const isLoading = !response.data && !response.error;
    // eslint-disable-next-line prefer-destructuring
    let data: AccountPosition[] | AccountPosition | undefined = response.data;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      data = memoFindByGesEstimDId(data, gesEstimDId);
    }
    return {
      ...response,
      isLoading,
      data: data ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UseAccountPositionFieldApiPropsByGesEstimDId<Field extends keyof AccountPosition> = {
  gesEstimDId: string,
  field: Field,
  sortPredicate?: SortPredicate | undefined,
}

export function useAccountPositionFieldApiByGesEstimD<Field extends keyof AccountPosition>(props: UseAccountPositionFieldApiPropsByGesEstimDId<Field>): UseAccountPositionFieldApiResponse<Field> {
  const {
    gesEstimDId,
    sortPredicate,
    field,
  } = props;
  const url = useMakeAccountsPositionsUrl();
  const gesEstimDIdLoading = isIdLoading(gesEstimDId);
  const fetcher = useFetchWithAuthWithSend();
  const response = useSWR<AccountPosition[], any>(
    !gesEstimDIdLoading ? url : null,
    fetcher,
    { dedupingInterval: ACTOR_DEDUPING_INTERVAL },
  );
  const result = useMemo(() => {
    const isLoading = !response.data && !response.error;
    // eslint-disable-next-line prefer-destructuring
    let data: AccountPosition[] | AccountPosition | undefined = response.data;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      data = memoFindByGesEstimDId(data, gesEstimDId);
    }
    return {
      ...response,
      isLoading,
      data: data ? data[field] : undefined,
    };
  }, [response, field]);
  return result;
}
