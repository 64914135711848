var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { getTopModelFromChildren, Ramp, TopModel, TopModelMerge, } from '@goldwasserexchange/react-topmodel';
import { match, defaultElementMatcherFactory, } from '../match';
import { stringifyIs } from './stringifyIs';
import { useNamedFieldValuesByPathsWithParentName } from '../../Form/context/data/hooks';
export function extractPathsSetFromLogic(logic) {
    if ('result' in logic) {
        return new Set();
    }
    if ('map' in logic) {
        return logic.map.reduce(function (acc, mapElement) {
            var mapElementPaths = extractPathsSetFromLogic(mapElement);
            return new Set(__spreadArray(__spreadArray([], __read(acc), false), __read(mapElementPaths), false));
        }, new Set());
    }
    if ('until' in logic) {
        return logic.until.reduce(function (acc, untilElement) {
            var untilElementPaths = extractPathsSetFromLogic(untilElement);
            return new Set(__spreadArray(__spreadArray([], __read(acc), false), __read(untilElementPaths), false));
        }, new Set());
    }
    if ('every' in logic) {
        return logic.every.reduce(function (acc, everyElement) {
            var everyElementPaths = extractPathsSetFromLogic(everyElement);
            return new Set(__spreadArray(__spreadArray([], __read(acc), false), __read(everyElementPaths), false));
        }, new Set());
    }
    if ('some' in logic) {
        return logic.some.reduce(function (acc, someElement) {
            var someElementPaths = extractPathsSetFromLogic(someElement);
            return new Set(__spreadArray(__spreadArray([], __read(acc), false), __read(someElementPaths), false));
        }, new Set());
    }
    var name = logic.name, alternatives = logic.alternatives;
    var truthy = extractPathsSetFromLogic(alternatives.truthy);
    var falsy = extractPathsSetFromLogic(alternatives.falsy);
    return new Set(__spreadArray(__spreadArray([
        name
    ], __read(truthy), false), __read(falsy), false));
}
export function extractPathsFromLogic(rawLogic) {
    var pathsSet = extractPathsSetFromLogic(rawLogic);
    return __spreadArray([], __read(pathsSet), false);
}
export function executeFilterLogicMatch(logic, states) {
    var name = logic.name, is = logic.is, options = logic.options;
    var value = stringifyIs(states[name].get());
    var result = match(defaultElementMatcherFactory(value), stringifyIs(is), options);
    return result;
}
export function executeFilterLogicThunk(logic, states) {
    if ('result' in logic) {
        return logic.result;
    }
    if ('every' in logic) {
        var alternatives_1 = logic.alternatives, every = logic.every;
        var matchResult_1 = every.every(function (everyElement) { return executeFilterLogic(everyElement, states); });
        return function () { return executeFilterLogicThunk(matchResult_1 === true ? alternatives_1.truthy : alternatives_1.falsy, states); };
    }
    if ('some' in logic) {
        var alternatives_2 = logic.alternatives, some = logic.some;
        var matchResult_2 = some.some(function (someElement) { return executeFilterLogic(someElement, states); });
        return function () { return executeFilterLogicThunk(matchResult_2 === true ? alternatives_2.truthy : alternatives_2.falsy, states); };
    }
    if ('until' in logic) {
        var until = logic.until, alternatives_3 = logic.alternatives;
        var _a = __read(until), head_1 = _a[0], tail_1 = _a.slice(1);
        var matchResult_3 = executeFilterLogic(head_1, states);
        if (matchResult_3 === 'next') {
            return function () { return executeFilterLogicThunk({ until: tail_1, alternatives: alternatives_3 }, states); };
        }
        if (typeof matchResult_3 !== 'boolean') {
            return function () { return executeFilterLogicThunk(head_1, states); };
        }
        return function () { return executeFilterLogicThunk(matchResult_3 ? alternatives_3.truthy : alternatives_3.falsy, states); };
    }
    var alternatives = logic.alternatives, filterProps = __rest(logic, ["alternatives"]);
    var matchResult = executeFilterLogicMatch(filterProps, states);
    return function () { return executeFilterLogicThunk(matchResult === true ? alternatives.truthy : alternatives.falsy, states); };
}
export function executeFilterLogic(logic, states) {
    var tempResult = executeFilterLogicThunk(logic, states);
    while (tempResult instanceof Function) {
        tempResult = tempResult();
    }
    return tempResult;
}
export function useFormFilterLogic(logic, parentName) {
    var paths = extractPathsFromLogic(logic);
    var states = useNamedFieldValuesByPathsWithParentName(parentName, paths);
    var result = executeFilterLogic(logic, states);
    return result;
}
export function executeFormFilterLogicMap(logic, states) {
    var map = logic.map;
    return map.map(function (mapLogic) { return executeFilterLogic(mapLogic, states); });
}
export function useFormFilterLogicMap(logic, parentName) {
    var paths = extractPathsFromLogic(logic);
    var states = useNamedFieldValuesByPathsWithParentName(parentName, paths);
    var result = executeFormFilterLogicMap(logic, states);
    return result;
}
function FormFilterLogicContent(props) {
    var logic = props.logic, parentName = props.parentName, _a = props.log, log = _a === void 0 ? false : _a, children = props.children;
    var result = useFormFilterLogic(logic, parentName);
    if (log) {
        // eslint-disable-next-line no-console
        console.log('logicResult:', result, logic, parentName);
    }
    return typeof result === 'string' ? (React.createElement(Ramp, { rampName: result }, children)) : null;
}
export function FormFilterLogic(props) {
    var logic = props.logic, parentName = props.parentName, incomingChildren = props.children, log = props.log;
    var _a = __read(getTopModelFromChildren(incomingChildren), 2), children = _a[0], topModelChildren = _a[1];
    return (React.createElement(TopModelMerge, { topModels: topModelChildren },
        React.createElement(FormFilterLogicContent, { logic: logic, parentName: parentName, log: log }, children)));
}
export function FormFilterLogicVisible(props) {
    var logic = props.logic, parentName = props.parentName, children = props.children, _a = props.options, options = _a === void 0 ? { isNegative: false } : _a;
    var _b = options.isNegative, isNegative = _b === void 0 ? false : _b;
    return (React.createElement(FormFilterLogic, { logic: logic, parentName: parentName },
        React.createElement(TopModel, { name: !isNegative ? 'visible' : 'invisible' }, children)));
}
