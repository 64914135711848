export var TransformOriginProp;
(function (TransformOriginProp) {
    TransformOriginProp["center"] = "center";
    TransformOriginProp["top"] = "top";
    TransformOriginProp["topRight"] = "top right";
    TransformOriginProp["right"] = "right";
    TransformOriginProp["bottomRight"] = "bottom right";
    TransformOriginProp["bottom"] = "bottom";
    TransformOriginProp["bottomLeft"] = "bottom left";
    TransformOriginProp["left"] = "left";
    TransformOriginProp["topLeft"] = "top left";
})(TransformOriginProp || (TransformOriginProp = {}));
