import { atom } from 'jotai';
import { pathnamePartFamilly } from '../../../../atoms';

export const pathnameUrlOblisSchoolLevelAtom = atom((get) => {
  const subSubSection = get(pathnamePartFamilly(3));
  if (subSubSection == null) {
    return 'beginner';
  }
  if (!['beginner', 'advanced'].includes(subSubSection)) {
    return 'not-found';
  }
  return subSubSection;
});

export const pathnameUrlOblisSchoolArticleAtom = atom((get) => {
  const subSubSubSection = get(pathnamePartFamilly(4));
  if (subSubSubSection == null) {
    return null;
  }
  return subSubSubSection;
});
