/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import React from 'react';
import { animated } from 'react-spring';
import { useToolbarDropDownOpenSpring } from '../context';

const toolbarDropDownLabelTextContainerStyle: ouiStyle.InStyle = {
  color: ouiStyle.Constants.Colors.inverted,
  textTransform: 'uppercase',
  fontSize: 'm',
  alignSelf: ouiStyle.Constants.AlignSelfProp.center,
};

const toolbarDropDownLabelTextContainerCss = ouiStyle.makeCss(toolbarDropDownLabelTextContainerStyle);

export const ToolbarDropDownLabelTextContainer = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  const open = useToolbarDropDownOpenSpring();
  const style = {
    transform: open.to((v: number) => `scale(${(1 - v)})`),
  };
  return (
    <animated.span
      css={toolbarDropDownLabelTextContainerCss}
      style={style}
    >
      {children}
    </animated.span>
  );
};
