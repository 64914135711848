import { AccountType, OrientationDecisionType, OrientationEvaluationFrequency, OrientationMarketNewsFrequency, OrientationThreeYearsInvestmentPlan, } from '../../types';
export var getOrientationAdvice = function (skippedOrientation, decisionType, evaluationFrequency, marketNewsFrequency, threeYearInvestmentPlan) {
    if (skippedOrientation
        || decisionType == null
        || evaluationFrequency == null
        || marketNewsFrequency == null
        || threeYearInvestmentPlan == null) {
        return null;
    }
    if (decisionType === OrientationDecisionType.AUTONOMOUS) {
        if (evaluationFrequency === OrientationEvaluationFrequency.RARELY) {
            if (threeYearInvestmentPlan === OrientationThreeYearsInvestmentPlan.LESS_THAN_100K) {
                return AccountType.CONSEIL;
            }
            return AccountType.GESTION;
        }
        if (marketNewsFrequency === OrientationMarketNewsFrequency.RARELY) {
            return AccountType.CONSEIL;
        }
        return AccountType.RTO;
    }
    if (decisionType === OrientationDecisionType.RECOMMENDATION) {
        if (evaluationFrequency === OrientationEvaluationFrequency.RARELY) {
            if (threeYearInvestmentPlan === OrientationThreeYearsInvestmentPlan.LESS_THAN_100K) {
                return AccountType.CONSEIL;
            }
            return AccountType.GESTION;
        }
        return AccountType.CONSEIL;
    }
    if (decisionType === OrientationDecisionType.DELEGATE) {
        if (threeYearInvestmentPlan === OrientationThreeYearsInvestmentPlan.LESS_THAN_100K) {
            return AccountType.CONSEIL;
        }
        return AccountType.GESTION;
    }
    return null;
};
