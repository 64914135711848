import { History, Update } from 'history';
import { languageRouting } from './language';
import { sectionsRouting } from './sections';

export const routerListener = (sourceHistory: History) => (currentUpdate: Update) => {
  let update = currentUpdate;
  update = languageRouting(sourceHistory, update);
  update = sectionsRouting(sourceHistory, update);
  return update;
};

export const initialiseRouterListener = (sourceHistory: History) => routerListener(sourceHistory)(sourceHistory);
