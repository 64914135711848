/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const toastDismissContainerStyle: ouiStyle.InStyle = {
  display: ouiStyle.Constants.DisplayProp.flex,
  flexDirection: ouiStyle.Constants.InFlexDirectionProp.col,
  alignItems: ouiStyle.Constants.AlignItemsProp.center,
  justifyContent: ouiStyle.Constants.JustifyContentProp.start,
  alignSelf: ouiStyle.Constants.AlignSelfProp.stretch,
};

const toastDismissContainerCss = ouiStyle.makeCss(toastDismissContainerStyle);

export const ToastDismissContainer = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  return (
    <div
      css={toastDismissContainerCss}
    >
      {children}
    </div>
  );
};
