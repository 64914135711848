import useSWR, { KeyedMutator } from 'swr';
import { useMemo } from 'react';
import { PositionSubGroup } from '../type';
import { Positions } from '../../enum';
import { AccountPosition, useAccountPositionsStringifiedParams } from '../../../accounts';
import { memoPositionsGroupBySTypeGroup } from '../utils';
import { getServiceUrl } from '../../../../shared';
import { ACTOR_DEDUPING_INTERVAL } from '../../../shared';
import { useFetchWithAuthWithSend } from '../../../shared/utils/fetchWithAuth';
import { makeApiUrl } from '../../../../../../aws';

const useMakePositionSubGroupUrl = () => {
  const stringifiedParams = useAccountPositionsStringifiedParams();
  const url = stringifiedParams !== null ? `${makeApiUrl('actor')}/${getServiceUrl(Positions.SubGroup)}${stringifiedParams}` : null;
  return url;
};

type BaseUsePositionsSubGroupApiResponse = {
  isLoading: boolean,
  error?: any,
  mutate: KeyedMutator<AccountPosition[]>,
  isValidating: boolean,
}

type UsePositionsSubGroupsApiResponse = {
  data: undefined | PositionSubGroup[],
} & BaseUsePositionsSubGroupApiResponse;

type FilterPredicate = (positionsSubGroup: PositionSubGroup) => boolean;

type SortPredicate = (prevPositionsSubGroup: PositionSubGroup, nextPositionsSubGroup: PositionSubGroup) => number;

type UsePositionsSubGroupsApiProps = {
  filterPredicate?: FilterPredicate,
  sortPredicate?: SortPredicate,
};

export const usePositionsSubGroupsApi = (props: UsePositionsSubGroupsApiProps): UsePositionsSubGroupsApiResponse => {
  const {
    filterPredicate,
    sortPredicate,
  } = props;
  const url = useMakePositionSubGroupUrl();
  const fetcher = useFetchWithAuthWithSend();
  const result = useSWR<AccountPosition[], any>(
    url,
    fetcher,
    { dedupingInterval: ACTOR_DEDUPING_INTERVAL },
  );
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    let data = memoPositionsGroupBySTypeGroup(result.data);
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      if (filterPredicate) {
        data = data.filter((positionsSubGroup) => filterPredicate(positionsSubGroup));
      }
    }
    return ({
      ...result,
      data,
      isLoading,
    });
  }, [result, filterPredicate, sortPredicate]);
  return resultWithLoading;
};

type TransformArrayFunction<T> = (positionsSubGroups: PositionSubGroup[] | undefined) => T

type UsePositionsSubGroupsApiTransformProps<T> = {
  transformFunction: TransformArrayFunction<T>,
} & UsePositionsSubGroupsApiProps

type UsePositionsSubGroupApiTransformResponse<T> = {
  data: T | undefined,
} & BaseUsePositionsSubGroupApiResponse;

export function usePositionsSubGroupsTransformApi<T>(props: UsePositionsSubGroupsApiTransformProps<T>): UsePositionsSubGroupApiTransformResponse<T> {
  const {
    filterPredicate,
    sortPredicate,
    transformFunction,
  } = props;
  const response = usePositionsSubGroupsApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: (!isLoading && !error) ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UsePositionsSubGroupApiResponse = {
  data: PositionSubGroup | undefined,
} & BaseUsePositionsSubGroupApiResponse;

export const usePositionsSubGroupApi = (props: UsePositionsSubGroupsApiProps): UsePositionsSubGroupApiResponse => {
  const {
    filterPredicate,
    sortPredicate,
  } = props;
  const url = useMakePositionSubGroupUrl();
  const fetcher = useFetchWithAuthWithSend();
  const result = useSWR<AccountPosition[], any>(
    url,
    fetcher,
    { dedupingInterval: ACTOR_DEDUPING_INTERVAL },
  );
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;

    let data: PositionSubGroup[] | PositionSubGroup | undefined = memoPositionsGroupBySTypeGroup(result.data);
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      if (filterPredicate) {
        data = data.find((positionsSubGroup) => filterPredicate(positionsSubGroup));
      }
      if (Array.isArray(data)) {
        // eslint-disable-next-line prefer-destructuring
        data = data[0];
      }
    }
    return ({
      ...result,
      data,
      isLoading,
    });
  }, [result, filterPredicate, sortPredicate]);
  return resultWithLoading;
};

type TransformFunction<T> = (positionsSubGroup: PositionSubGroup | undefined) => T

type UsePositionsSubGroupApiTransformProps<T> = {
  transformFunction: TransformFunction<T>,
} & UsePositionsSubGroupsApiProps;

export function usePositionsSubGroupTransformApi<T>(props: UsePositionsSubGroupApiTransformProps<T>): UsePositionsSubGroupApiTransformResponse<T> {
  const {
    filterPredicate,
    sortPredicate,
    transformFunction,
  } = props;
  const response = usePositionsSubGroupApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: !isLoading && !error ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UsePositionsSubGroupFieldApiResponse<Field extends keyof PositionSubGroup> = {
  data: PositionSubGroup[Field] | undefined,
} & BaseUsePositionsSubGroupApiResponse;

type UsePositionsSubGroupFieldApiProps<Field extends keyof PositionSubGroup> = {
  field: Field,
} & UsePositionsSubGroupsApiProps

export function usePositionsSubGroupFieldApi<Field extends keyof PositionSubGroup>(props: UsePositionsSubGroupFieldApiProps<Field>): UsePositionsSubGroupFieldApiResponse<Field> {
  const {
    filterPredicate,
    sortPredicate,
    field,
  } = props;
  const response = usePositionsSubGroupApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
    } = response;
    return {
      ...response,
      data: data ? data[field] : undefined,
    };
  }, [response, field]);
  return result;
}
