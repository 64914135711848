var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import { animated, } from 'react-spring';
import ouiStyle from '@goldwasserexchange/oui-style';
var rippleStyle = {
    transform: 'scale(0)',
    borderRadius: 'full',
    position: 'absolute',
    opacity: 0.75,
    backgroundColor: ouiStyle.Constants.Colors.inverted,
    lines: 'full',
    widthLines: 'full',
};
export var DomRippleEffectContent = function (props) {
    var buttonBackgroundColor = props.buttonBackgroundColor, style = props.style;
    var isBackgroundInverted = buttonBackgroundColor === ouiStyle.Constants.Colors.inverted;
    var rippleCss = React.useMemo(function () { return ouiStyle.makeCss(__assign(__assign({}, rippleStyle), { backgroundColor: !isBackgroundInverted ? ouiStyle.Constants.Colors.inverted : ouiStyle.Constants.Colors.disabled })); }, [isBackgroundInverted]);
    return (jsx(animated.span, { css: ouiStyle.mediaQuery(rippleCss), style: style }));
};
