import { STypeGrp } from '../../types';
export var list = [
    STypeGrp.OBLIGATIONS,
    STypeGrp.FONDS,
    STypeGrp.OBLIGATIONS_PERP,
    STypeGrp.ACTIONS,
    STypeGrp.OBLIGATIONS_SUB,
    STypeGrp.TRACKERS,
    STypeGrp.TRACKERS_MAT,
];
