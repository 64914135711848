import { UncastedDataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import { AnyObjectSchema } from 'yup';
import { ValidationContextType } from './contextType';

export const validateAt = (props: {
  validator: AnyObjectSchema,
  path: string,
  values: UncastedDataStructure,
  overwriteAt?: boolean,
}) => {
  const {
    validator,
    path,
    values,
    overwriteAt = false,
  } = props;
  const context: ValidationContextType = {
    at: !overwriteAt,
    rootValue: values,
    validator,
  };
  return validator.validateAt(path, values, { context, abortEarly: false });
};

export const validateSyncAt = (props: {
  validator: AnyObjectSchema,
  path: string,
  values: UncastedDataStructure,
}) => {
  const {
    validator,
    path,
    values,
  } = props;
  const context: ValidationContextType = {
    at: true,
    rootValue: values,
    validator,
  };
  return validator.validateSyncAt(path, values, { context, abortEarly: false });
};

export const getValidateAtsArray = (props: {
  validator: AnyObjectSchema,
  paths: string[],
  values: UncastedDataStructure,
}) => {
  const {
    validator,
    paths,
    values,
  } = props;
  return paths.map((path) => validateAt({
    validator,
    path,
    values,
  }));
};

export const validateAts = (props: {
  validator: AnyObjectSchema,
  paths: string[],
  values: UncastedDataStructure,
  allSettled?: boolean,
}) => {
  const {
    validator,
    paths,
    values,
    allSettled = false,
  } = props;
  if (allSettled) {
    return Promise.allSettled(getValidateAtsArray({
      validator,
      paths,
      values,
    }));
  }
  return Promise.all(getValidateAtsArray({
    validator,
    paths,
    values,
  }));
};
