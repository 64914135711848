import { Ramp } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import { ScrollDirections, useIsButtonVisible } from '../context';

export type ScrollButtonProps = {
  direction: ScrollDirections,
}

export const ScrollButton = (props: ScrollButtonProps) => {
  const {
    direction,
  } = props;
  const visible = useIsButtonVisible(direction);
  return visible
    ? (
      <Ramp
        rampName={`ScrollableScrollButtonContainer-${direction}`}
        rampFallbacks={['ScrollableScrollButtonContainer']}
        direction={direction}
      >
        <Ramp
          rampName={`ScrollableScrollButtonIcon-${direction}`}
          rampFallbacks={['ScrollableScrollButtonIcon']}
          direction={direction}
        />
      </Ramp>
    )
    : null;
};
