/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { animated } from 'react-spring';
import { useZIndex, ZIndexAddProvider } from '../../../../../../../../OUI/Form';
import {
  InputVariantsNames,
  InputVariantHooksArray,
  useBaseVariant,
  useHasFocusVariant,
  useValidationVariant,
  useDisabledVariant,
} from '../../../../../../../../OUI/Inputs';
import { useVariantSpring } from '../../../../../../utils';

const defaultDomSelectInputListMapperContainerStyle: ouiStyle.InStyle = {
  position: 'absolute',
  widthLines: 'full',
  backgroundColor: ouiStyle.Constants.Colors.inverted,
  borderColor: ouiStyle.Constants.Colors.primary,
  borderWidth: 'simple',
  borderStyle: 'solid',
  minLines: 1,
  minChildPaddingTop: 'simple',
  minChildPaddingBottom: 'simple',
};

const baseDomSelectInputListMapperContainerCss = ouiStyle.makeCssAndExtract(
  defaultDomSelectInputListMapperContainerStyle,
  ['borderTopColor', 'borderRightColor', 'borderBottomColor', 'borderLeftColor'],
);

const focusDomSelectInputListMapperContainerStyle: ouiStyle.InStyle = {
  ...defaultDomSelectInputListMapperContainerStyle,
  borderColor: ouiStyle.Constants.Colors.accent,
};

const focusDomSelectInputListMapperContainerCss = ouiStyle.makeCssAndExtract(
  focusDomSelectInputListMapperContainerStyle,
  ['borderTopColor', 'borderRightColor', 'borderBottomColor', 'borderLeftColor'],
);

const errorDomSelectInputListMapperContainerStyle: ouiStyle.InStyle = {
  ...defaultDomSelectInputListMapperContainerStyle,
  borderColor: ouiStyle.Constants.Colors.danger,
};

const errorDomSelectInputListMapperContainerCss = ouiStyle.makeCssAndExtract(
  errorDomSelectInputListMapperContainerStyle,
  ['borderTopColor', 'borderRightColor', 'borderBottomColor', 'borderLeftColor'],
);

const disabledDomSelectInputListMapperContainerStyle: ouiStyle.InStyle = {
  ...defaultDomSelectInputListMapperContainerStyle,
  borderColor: ouiStyle.Constants.Colors.disabled,
};

const disabledDomSelectInputListMapperContainerCss = ouiStyle.makeCssAndExtract(
  disabledDomSelectInputListMapperContainerStyle,
  ['borderTopColor', 'borderRightColor', 'borderBottomColor', 'borderLeftColor'],
);

const DomSelectInputListMapperContainerVariants: Record<
InputVariantsNames,
Partial<
Pick<
ouiStyle.Style,
| 'borderTopColor'
| 'borderRightColor'
| 'borderBottomColor'
| 'borderLeftColor'
>
>
> = {
  opened: {},
  closed: {},
  base: baseDomSelectInputListMapperContainerCss,
  hasContent: {},
  noContent: {},
  focus: focusDomSelectInputListMapperContainerCss,
  noFocus: {},
  untouched: {},
  valid: {},
  error: errorDomSelectInputListMapperContainerCss,
  disabled: disabledDomSelectInputListMapperContainerCss,
  enabled: {},
  checked: {},
  unChecked: {},
  last: {},
  notLast: {},
  before: {},
  inside: {},
  after: {},
  noSelection: {},
  notCorrected: {},
  correct: {},
  incorrect: {},
  correctSelected: {},
  correctNotSelected: {},
  incorrectSelected: {},
  incorrectNotSelected: {},
};

const domSelectInputListMapperContainerHookVariants: InputVariantHooksArray = [
  useBaseVariant,
  useHasFocusVariant,
  useValidationVariant,
  useDisabledVariant,
];

export function DefaultDomSelectInputListMapperContainer(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  const style = useVariantSpring(
    DomSelectInputListMapperContainerVariants,
    domSelectInputListMapperContainerHookVariants,
  );
  const zIndex = useZIndex();
  const css = React.useMemo(() => ouiStyle.makeCss({
    ...defaultDomSelectInputListMapperContainerStyle,
    zIndex,
  }), [zIndex]);
  return (
    <animated.div css={css} style={style}>
      <ZIndexAddProvider>{children}</ZIndexAddProvider>
    </animated.div>
  );
}
