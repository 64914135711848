import { BondsCall } from '../type';
import { isPostCall } from './isPostCall';
import { transformCmtHist } from './transformCmtHist';

export const lastNonPostCallCmtHist = (bondsCall: BondsCall[] | undefined) => {
  if (!bondsCall) {
    return undefined;
  }
  const line = bondsCall
    .filter((bondCall) => !isPostCall(bondCall['EVT_ENTRY_S.ID_EVT_CODE']))
    .at(-1);
  if (!line) {
    return undefined;
  }
  return transformCmtHist(line['EVT_ENTRY_S.CMT_HIST']);
};
