import { UncastedOnboarding } from '@goldwasserexchange/oblis-frontend-utils';
import React from 'react';
import { object } from 'yup';
import {
  tAccIdGesEvalTypeValidator,
  tAccIdGesValPerValidator,
} from '../../../../tAcc/inputs/idGesEvalPer';

export const LazyPreferences = React.lazy(() => import('./component'));

const tAccPreferenceValidatorShape: Record<keyof UncastedOnboarding['T_ACC'], any> = {
  idGesEvalType: tAccIdGesEvalTypeValidator,
  ID_GES_EVAL_PER: tAccIdGesValPerValidator,
};

export const tAccPreferenceValidator = object().shape(tAccPreferenceValidatorShape);
