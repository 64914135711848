import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import ouiStyle from '@goldwasserexchange/oui-style';
import ouiDom from '@goldwasserexchange/oui-dom';
import { isNil } from 'ramda';
import { RECOMMENDATION, recommendationTopModels } from './recommendation';
import { InvestmentUniverseTopModels, INVESTMENT_UNIVERSE } from './investmentUniverse';
import { minimalInvestmentTopModels, MINIMAL_INVESTMENT } from './minimalInvestments';
import { FULL_INVESTMENT_MANAGMENT, fullInvestmentMangementTopModels } from './fullInvestment';
import { PERSONAL_INVESTMENT_RECOMMENDATIONS, personalInvestmentRecomendationsTopModel } from './personalInvestmentRecommendations';
import { PERSONAL_SALESPERSON, personalSalesPersonTopModels } from './personalSalesPerson';
import { INSTANT_ASSIST, instantAssistTopModels } from './instantAssist';
import { brokerageFeesTopModels, BROKERAGE_FEES } from './brokerageFees';
import { MANAGEMENT_FEE, managmentFeesTopModels } from './managementFees';
import { CUSTODY_FEES, custodyFeesTopModels } from './custodyFees';
import { couponCashingFeesTopModels, COUPON_CASHING_FEE } from './couponCashing';
import { CHANGE_FEE, changeFeesTopModels } from './changeFees';
import { OUTGOING_SECURITIES_FEE, outgoingSecuritiesFeesTopModels } from './outgoingFee';
import {
  SELECTION_END, selectionTopModels,
} from './selection';
import { ONBOARDING_LINK } from './onboardingLink';
import { AccountTypeBodyRowCellContainer, AccountTypeBodyRowHeadingContainer } from './shared';

export const accountTypeBaseSecondaryDimension = [
  INVESTMENT_UNIVERSE,
  MINIMAL_INVESTMENT,
  FULL_INVESTMENT_MANAGMENT,
  PERSONAL_INVESTMENT_RECOMMENDATIONS,
  PERSONAL_SALESPERSON,
  INSTANT_ASSIST,
  BROKERAGE_FEES,
  MANAGEMENT_FEE,
  CUSTODY_FEES,
  COUPON_CASHING_FEE,
  CHANGE_FEE,
  OUTGOING_SECURITIES_FEE,
];

const wrapWithSelection = (dimension: string[]) => [
  ...dimension,
  SELECTION_END,
];

const addRecommendation = (dimension: string[], upMq: boolean) => [
  !upMq ? RECOMMENDATION : null,
  ...dimension,
  upMq ? RECOMMENDATION : null,
].filter((d): d is string => !isNil(d));

const addOnboardingLink = (dimension: string[]) => [
  ...dimension,
  ONBOARDING_LINK,
];

export const getSecondaryDimension = (select = false, recomend = false, onboardingLink = false, upMq = false) => {
  const recommendedList = !recomend ? accountTypeBaseSecondaryDimension : addRecommendation(accountTypeBaseSecondaryDimension, upMq);
  const selectionList = !select || !upMq ? recommendedList : wrapWithSelection(recommendedList);
  const withOnboardingLink = !onboardingLink ? selectionList : addOnboardingLink(selectionList);
  return withOnboardingLink;
};

export const accountTypeTableSecondaryDimensionTopModels = (
  <>
    <TopModel
      name="TableContainer"
      display={[ouiStyle.Constants.DisplayProp.block, undefined, ouiStyle.Constants.DisplayProp.table, undefined, undefined, undefined]}
    >
      {ouiDom.Table.DomTableContainer}
    </TopModel>
    <TopModel
      name="TableHeaderContainer"
      display={[ouiStyle.Constants.DisplayProp.hidden, undefined, ouiStyle.Constants.DisplayProp.tableHeaderGroup, undefined, undefined, undefined]}
    >
      {ouiDom.Table.TableHeaderContainer}
    </TopModel>
    <TopModel
      name="TableHeaderRowContainer"
      display={[ouiStyle.Constants.DisplayProp.block, undefined, ouiStyle.Constants.DisplayProp.tableRow, undefined, undefined, undefined]}
    >
      {ouiDom.Table.TableRowContainer}
    </TopModel>
    <TopModel
      name="TableBodyContainer"
      display={[ouiStyle.Constants.DisplayProp.block, undefined, ouiStyle.Constants.DisplayProp.tableRowGroup, undefined, undefined, undefined]}
    >
      {ouiDom.Table.TableBodyContainer}
    </TopModel>
    <TopModel
      name="TableBodyRowContainer"
      display={[ouiStyle.Constants.DisplayProp.block, undefined, ouiStyle.Constants.DisplayProp.tableRow, undefined, undefined, undefined]}
    >
      {ouiDom.Table.TableBodyRowContainer}
    </TopModel>
    <TopModel
      name="TableBodyRowCellContainer"
    >
      {AccountTypeBodyRowCellContainer}
    </TopModel>
    <TopModel
      name="TableBodyRowCellContainer--index-last"
    >
      {AccountTypeBodyRowCellContainer}
    </TopModel>
    <TopModel
      name="TableSecondaryTitleCellContainer"
    >
      {AccountTypeBodyRowHeadingContainer}
    </TopModel>
    {recommendationTopModels}
    {InvestmentUniverseTopModels}
    {minimalInvestmentTopModels}
    {brokerageFeesTopModels}
    {managmentFeesTopModels}
    {custodyFeesTopModels}
    {couponCashingFeesTopModels}
    {changeFeesTopModels}
    {outgoingSecuritiesFeesTopModels}
    {fullInvestmentMangementTopModels}
    {personalInvestmentRecomendationsTopModel}
    {personalSalesPersonTopModels}
    {instantAssistTopModels}
    {selectionTopModels}
  </>
);
