import { useFilterByTAccId, shouldShowTResp } from '../../utils';
import { usePersonAccountFieldApi } from '../api';

export const usePersonAccountsTCatShortcut = () => {
  const filterPredicate = useFilterByTAccId();
  const result = usePersonAccountFieldApi({
    filterPredicate,
    field: 'T_CAT.SHORTCUT',
  });
  return result;
};

export const usePersonAccountsTCatShortcutRespName = () => {
  const result = usePersonAccountFieldApi({
    filterPredicate: shouldShowTResp,
    field: 'T_CAT.SHORTCUT',
  });
  return result;
};
