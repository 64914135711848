import ouiBase from '@goldwasserexchange/oui';
import { RampWithNameTransform } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import { DefaultTextInputContextProviderProps } from '.';
import { LocalRifmArgs } from '../context/rifm/context';

export type DefaultTextInputBaseProps = {
  fieldPath: string,
  dependenciesFieldPaths?: string[],
  type?: Exclude<ouiBase.Input.InputTypes, 'radio' | 'checkbox'>,
  autocomplete?: boolean,
} & LocalRifmArgs

export function DefaultTextInputBase(
  props: React.PropsWithChildren<DefaultTextInputBaseProps>,
) {
  const {
    fieldPath,
    dependenciesFieldPaths = [],
    format,
    replace,
    append,
    mask,
    accept,
    type,
    autocomplete,
  } = props;
  // the dependenciesFieldPaths prop uses a trick that could break the rule of hooks if it's length changed,
  // we need to do this to allow input depending on other inputs for validation to update
  // to avoid an hard error we memoize the array here and send a warning to the developper if the length changes
  const freezedDependenciesFieldPaths = ouiBase.utils.freeze.useFreezeArrayOnLength(
    dependenciesFieldPaths,
    'dependenciesFieldPaths',
  );
  return (
    <RampWithNameTransform<DefaultTextInputContextProviderProps>
      rampName="InputContextProvider"
      autocomplete={autocomplete}
      fieldPath={fieldPath}
      dependenciesFieldPaths={freezedDependenciesFieldPaths}
      format={format}
      replace={replace}
      append={append}
      mask={mask}
      accept={accept}
      type={type}
    >
      <RampWithNameTransform rampName="InputContainer">
        <RampWithNameTransform rampName="InputContent" />
      </RampWithNameTransform>
    </RampWithNameTransform>
  );
}
