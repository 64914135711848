import * as React from 'react';
import { highlightListContext } from './context';

export const HighlightListContextProvider = (props: React.PropsWithChildren<{
  whiteList: string[],
  blackList: string[],
}>) => {
  const {
    whiteList,
    blackList,
    children,
  } = props;
  const list = whiteList.filter((el) => !blackList.includes(el));
  return (
    <highlightListContext.Provider value={list}>
      {children}
    </highlightListContext.Provider>
  );
};
