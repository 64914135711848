/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { useMemo } from 'react';
import { useIsCurrentModal } from './context/currentModal/hooks';
import { useZIndex } from '../../OUI/Form';
import { useSetCurrentModalValue } from './context';
import { useCanCloseCurrentModal } from './context/canCloseModal';

const modalSkrimStyle: ouiStyle.InStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  lines: 'full',
  backgroundColor: ouiStyle.Constants.Colors.skrimShade,
};

export const ModalSkrim = () => {
  const zIndex = useZIndex();
  const isOpen = !useIsCurrentModal(null);
  const canClose = useCanCloseCurrentModal();
  const closeModal = useSetCurrentModalValue(null, !canClose);
  const modelaSkrimCss = useMemo(() => ouiStyle.makeCss({
    ...modalSkrimStyle,
    zIndex: zIndex - 1,
  }), [zIndex]);
  return isOpen ? (
    <div
      css={modelaSkrimCss}
      onClick={closeModal}
    />
  ) : null;
};
