import { PutCommand } from '@aws-sdk/lib-dynamodb';
import { addDays, getUnixTime } from 'date-fns';
import { useCallback } from 'react';
import { KeyedMutator } from 'swr';
import { useSingleTableNameContext } from '../../../../Components/Auth';
import { getSub } from '../../auth';
import { getDynamoDbDocumentClient } from '../getDynamoDbClient';
import { useOrders } from './getOrders';
import { optimisticUpdateOrders } from './optimisticUpdateOrders';
import { Order, OrderDto } from './type';

type BaseAddOrderProps = {
  tableName: string | null,
  data: Order[] | undefined,
}

type AddOrderProps = BaseAddOrderProps & {
  element: Order,
}

export const addOrder = async (props: AddOrderProps) => {
  const {
    tableName,
    data,
    element,
  } = props;
  if (!tableName) {
    throw new Error('no tableName is present');
  }
  const { client } = await getDynamoDbDocumentClient();
  const {
    pk,
    sk,
    sAccId,
    tAccId,
    side,
    message,
    ttl,
  } = element;
  const order: Order = {
    pk,
    sk,
    side,
    sAccId,
    ...(tAccId != null ? { tAccId } : {}),
    ...(message != null ? { message } : {}),
    ttl,
  };
  await client.send(new PutCommand({
    TableName: tableName,
    Item: order,
  }));
  client.destroy();
  return optimisticUpdateOrders({
    data,
    element: order,
  });
};

export const addOrderWithMutate = (props: BaseAddOrderProps & {
  mutate: KeyedMutator<Order[]>,
}) => async (element: OrderDto) => {
  const {
    tableName,
    data,
    mutate,
  } = props;
  const sub = await getSub();
  if (!sub) {
    return Promise.resolve([]);
  }
  const pk = `u#${sub}`;
  const timestamp = Date.now();
  const sk: Order['sk'] = `order#${timestamp}`;
  const ttl = getUnixTime(addDays(timestamp, 1));
  const order: Order = {
    ...element,
    pk,
    sk,
    ttl,
  };
  return mutate(
    addOrder({
      tableName,
      data,
      element: order,
    }),
    {
      optimisticData: optimisticUpdateOrders({
        data,
        element: order,
      }),
      rollbackOnError: true,
      populateCache: true,
      revalidate: false,
    },
  );
};

export const useAddOrderFn = () => {
  const tableName = useSingleTableNameContext();
  const {
    data,
    mutate,
  } = useOrders({
    tableName,
  });
  const addOrderFn = useCallback(addOrderWithMutate({ tableName, data, mutate }), [data, mutate]);
  return addOrderFn;
};
