import React from 'react';
import { object } from 'yup';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import {
  dataStructure, UncastedDataStructure, AccountType, PersonType, UncastedOnboarding,
} from '@goldwasserexchange/oblis-frontend-utils';
import ouiBase from '@goldwasserexchange/oui';
import { InfoQuestionnaireInput } from '../input';
import { QuestionGroupTitle } from '../utils';
import { FinancialQuestionnaireTitle } from '../../../../../TextIndex/FinancialQuestionnaireTitle';
import { FinancialQuestionnaireIntro } from '../../../../../TextIndex/FinancialQuestionnaireIntro';
import { StepContainer } from '../../utils/Step';
import {
  makeRequired, nilValidator, stringValidator, numberValidator,
} from '../../../../../../OUI/Inputs';
import { addWhenAlwaysCast, makeWhenAlwaysCast } from '../../../../../Form/validations/whenAlwaysCast';

addWhenAlwaysCast();

export const netWorthValidator = makeRequired(numberValidator);

export const monthlyExpensesValidator = makeRequired(numberValidator);

export const monthlyIncomeValidator = makeRequired(numberValidator);

export const cashValidator = makeRequired(numberValidator);

export const ownerValidator = makeRequired(stringValidator);

const financialSituationValidatorDependencyPaths: (keyof UncastedOnboarding)[] = [
  'selectedService',
  'personType',
];

const financialSituationValidatorShape: (personType: PersonType) => Record<keyof UncastedOnboarding['financialSituation'], any> = (personType) => ({
  netWorth: netWorthValidator,
  monthlyExpenses: monthlyExpensesValidator,
  monthlyIncome: monthlyIncomeValidator,
  cash: cashValidator,
  owner: personType !== PersonType.MORAL ? ownerValidator : nilValidator,
});

export const financialSituationValidator = makeWhenAlwaysCast(
  financialSituationValidatorDependencyPaths,
  (serviceSelection: dataStructure.accountType.listType, personType: PersonType) => {
    if (serviceSelection === AccountType.CONSEIL || serviceSelection === AccountType.GESTION) {
      return object().shape(financialSituationValidatorShape(personType));
    }
    return nilValidator;
  },
);

export const DEFAULT_FINANCIAL_SITUATION_QUESTIONNAIRE_INITIAL_VALUES: Record<keyof UncastedOnboarding['financialSituation'], ''> = {
  netWorth: '',
  monthlyExpenses: '',
  monthlyIncome: '',
  cash: '',
  owner: '',
};

export const FinancialSituationQuestionnaireStep = (): JSX.Element => {
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  return (
    <StepContainer>
      <QuestionGroupTitle>
        <FinancialQuestionnaireTitle />
      </QuestionGroupTitle>
      <p>
        <FinancialQuestionnaireIntro />
      </p>
      <dataStructure.financialSituation.render.Render
        parentName={valueState.onboarding.financialSituation.path.join('.')}
      >
        <TopModel name="FinancialSituationQuestionnaireInput">
          {InfoQuestionnaireInput}
        </TopModel>
      </dataStructure.financialSituation.render.Render>
    </StepContainer>
  );
};
