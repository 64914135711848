import { useFilterByPrimaryDimensionTRelFinId, getIbanFromAccountIban } from '../../utils';
import { useAccountIbanTransformApi } from '../api';

export const useAccountIbansTRelFinNumFin = () => {
  const filterPredicate = useFilterByPrimaryDimensionTRelFinId();
  const result = useAccountIbanTransformApi({
    filterPredicate,
    transformFunction: getIbanFromAccountIban,
  });
  return result;
};
