import * as fields from './fields';
export var checkboxPotentialFieldPaths = {
    preferences: fields.preferences.checkboxPotentialFieldPaths,
    taxonomy: fields.taxonomy.checkboxPotentialFieldPaths,
    taxonomyAlignment: fields.taxonomyAlignment.checkboxPotentialFieldPaths,
    taxonomyProportion: fields.taxonomyProportion.checkboxPotentialFieldPaths,
    sfdr: fields.sfdr.checkboxPotentialFieldPaths,
    sfdrDurableProportion: fields.sfdrDurableProportion.checkboxPotentialFieldPaths,
    sfdrSocialEnvironmentalProportion: fields.sfdrSocialEnvironmentalProportion.checkboxPotentialFieldPaths,
    sfdrDurableOnly: fields.sfdrDurableOnly.checkboxPotentialFieldPaths,
    sfdrSocialEnvironmentalOnly: fields.sfdrSocialEnvironmentalOnly.checkboxPotentialFieldPaths,
    incidence: fields.incidence.checkboxPotentialFieldPaths,
    incidenceType: fields.incidenceType.checkboxPotentialFieldPaths,
    incidenceProportion: fields.incidenceProportion.checkboxPotentialFieldPaths,
};
