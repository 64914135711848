import { makeTAddIsTypePhysicLogic } from '../TYPE/logic';
import { PersonType, TAddType, } from '../../../../types';
var onboardingPath = 'onboarding';
var personTypePath = 'personType';
var currentPath = 'current';
var isPrintPath = 'isPrint';
var tAddTypePath = 'T_ADD_type';
var isMinorPath = 'isMinor';
export var showLogic = makeTAddIsTypePhysicLogic({
    alternatives: {
        truthy: {
            name: "<parentName>.".concat(isMinorPath),
            is: false,
            alternatives: {
                truthy: {
                    name: "".concat(onboardingPath, ".").concat(personTypePath),
                    is: PersonType.JOINT,
                    alternatives: {
                        truthy: {
                            name: "".concat(onboardingPath, ".").concat(isPrintPath),
                            is: false,
                            alternatives: {
                                truthy: {
                                    name: "<parentName>.".concat(currentPath),
                                    is: true,
                                    alternatives: {
                                        truthy: {
                                            result: 'visible',
                                        },
                                        falsy: {
                                            result: 'invisible',
                                        },
                                    },
                                },
                                falsy: {
                                    result: 'visible',
                                },
                            },
                        },
                        falsy: {
                            name: "".concat(onboardingPath, ".").concat(personTypePath),
                            is: PersonType.FOR_MINORS,
                            alternatives: {
                                truthy: {
                                    name: "<parentName>.".concat(currentPath),
                                    is: true,
                                    alternatives: {
                                        truthy: {
                                            result: 'visible',
                                        },
                                        falsy: {
                                            name: "<parentName>.".concat(tAddTypePath),
                                            is: TAddType.TITULAR,
                                            options: {
                                                isNegative: true,
                                            },
                                            alternatives: {
                                                truthy: {
                                                    result: 'visible',
                                                },
                                                falsy: {
                                                    result: 'invisible',
                                                },
                                            },
                                        },
                                    },
                                },
                                falsy: {
                                    result: 'visible',
                                },
                            },
                        },
                    },
                },
                falsy: {
                    result: 'invisible',
                },
            },
        },
        falsy: {
            result: 'invisible',
        },
    },
    exclude: [TAddType.ONLY_REPRESENTATIVE],
});
export var pathTypeLogic = {
    name: "<parentName>.".concat(currentPath),
    is: true,
    alternatives: {
        truthy: {
            result: 'selectedUser',
        },
        falsy: {
            name: "".concat(onboardingPath, ".").concat(personTypePath),
            is: PersonType.JOINT,
            alternatives: {
                truthy: {
                    result: 'currentUser',
                },
                falsy: {
                    result: 'selectedUser',
                },
            },
        },
    },
};
