import ouiBase from '@goldwasserexchange/oui';
import * as React from 'react';
import { checkboxPotentialFieldPathsContext } from './context';

export const InputCheckboxPotentialFieldPathsProvider = (
  props: React.PropsWithChildren<{
    checkboxPotentialFieldPaths: string[],
  }>,
) => {
  const { checkboxPotentialFieldPaths, children } = props;
  // the checkboxPotentialFieldPaths prop uses a trick that could break the rule of hooks if it's length changed,
  // we need to do this to allow input depending on other inputs for validation to update
  // to avoid an hard error we memoize the array here and send a warning to the developper if the length changes
  const freezedCheckboxPotentialFieldPaths = ouiBase.utils.freeze.useFreezeArrayOnLength(
    checkboxPotentialFieldPaths,
    'checkboxPotentialFieldPaths',
  );
  return (
    <checkboxPotentialFieldPathsContext.Provider
      value={freezedCheckboxPotentialFieldPaths}
    >
      {children}
    </checkboxPotentialFieldPathsContext.Provider>
  );
};
