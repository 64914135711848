/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import { animated } from 'react-spring';
import ouiStyle from '@goldwasserexchange/oui-style';
import {
  labelStyle,
  focusLabelStyle,
  noFocusLabelStyle,
  errorLabelStyle,
  disabledLabelStyle,
} from '../../../../label';
import {
  InputVariantsNames,
  useBaseVariant,
  useHasFocusVariant,
  useValidationVariant,
  useDisabledVariant,
  InputVariantHooksArray,
} from '../../../../../../../../../OUI/Inputs';
import { useVariantSpring } from '../../../../../../../utils';

export const listInputLabelContainerStyle: ouiStyle.InStyle = {
  ...labelStyle,
  lines: 'auto',
  paddingBottom: 'simple',
  flexGrow: true,
  flexShrink: true,
};

export const listInputLabelContainerCss = ouiStyle.makeCss(listInputLabelContainerStyle);

const baseLabelCss = ouiStyle.makeCssAndExtract(listInputLabelContainerStyle, ['color']);

const listInputFocusLabelStyle: ouiStyle.InStyle = {
  ...focusLabelStyle,
  paddingBottom: 'simple',
  fontWeight: ouiStyle.Constants.FontWeightProp.normal,
};

const focusLabelCss = ouiStyle.makeCssAndExtract(listInputFocusLabelStyle, [
  'fontWeight',
  'color',
]);

const listInputNoFocusLabelStyle: ouiStyle.InStyle = {
  ...noFocusLabelStyle,
  paddingBottom: 'simple',
};

const noFocusLabelCss = ouiStyle.makeCssAndExtract(listInputNoFocusLabelStyle, [
  'fontWeight',
  'color',
]);

const listInputErrorLabelStyle: ouiStyle.InStyle = {
  ...errorLabelStyle,
  paddingBottom: 'simple',
};
const errorLabelCss = ouiStyle.makeCssAndExtract(listInputErrorLabelStyle, ['color']);

const listInputDisabledLabelStyle: ouiStyle.InStyle = {
  ...disabledLabelStyle,
  paddingBottom: 'simple',
};

const disabledLabelCss = ouiStyle.makeCssAndExtract(listInputDisabledLabelStyle, [
  'color',
]);

export const labelVariants: Record<
InputVariantsNames,
Partial<Pick<ouiStyle.Style, 'fontWeight' | 'color'>>
> = {
  opened: {},
  closed: {},
  base: baseLabelCss,
  hasContent: {},
  noContent: {},
  focus: focusLabelCss,
  noFocus: noFocusLabelCss,
  untouched: {},
  valid: {},
  error: errorLabelCss,
  disabled: disabledLabelCss,
  enabled: {},
  checked: {},
  unChecked: {},
  last: {},
  notLast: {},
  before: {},
  inside: {},
  after: {},
  noSelection: {},
  notCorrected: {},
  correct: {},
  incorrect: {},
  correctSelected: {},
  correctNotSelected: {},
  incorrectSelected: {},
  incorrectNotSelected: {},
};

const listInputLabelContainerHookVariants: InputVariantHooksArray = [
  useBaseVariant,
  useHasFocusVariant,
  useValidationVariant,
  useDisabledVariant,
];

export function ListInputLabelContainer(props: React.PropsWithChildren<{}>) {
  const { children } = props;
  const spring = useVariantSpring(
    labelVariants,
    listInputLabelContainerHookVariants,
  );
  return (
    <animated.p css={listInputLabelContainerCss} style={spring}>
      {children}
    </animated.p>
  );
}
