import { AccountDocument } from '../type';

const isEndLine = (accountDocument: AccountDocument) => accountDocument.ROW_NUMBER === accountDocument.TOTAL_ROWS;

const isLastLineEndLine = (accountDocuments: AccountDocument[]) => {
  if (accountDocuments.length > 0) {
    const lastLine = accountDocuments[accountDocuments.length - 1];
    return isEndLine(lastLine);
  }
  return false;
};

export const getAccountDocumentsEndReached = (v?: AccountDocument[]) => {
  if (v) {
    return isLastLineEndLine(v);
  }
  return false;
};
