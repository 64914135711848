import React, { PropsWithChildren, useContext } from 'react';
import { none, useHookstate } from '@hookstate/core';
import {
  makeName, dataStructure, UncastedDataStructure, UncastedOnboarding,
} from '@goldwasserexchange/oblis-frontend-utils';
import ouiBase from '@goldwasserexchange/oui';
import ouiDom from '@goldwasserexchange/oui-dom';
import { usePushFieldArray, useFormArrayWithFilter } from './utils';
import { useScrollTopOnClick } from '../AppContainer/context';
import { useSelectedId, SelectionListContext } from '../BaseComponents/List';
import { useErrors, useTouchedContext } from '../../OUI/Form';
import { ButtonContainer } from '../Sections/shared/imageHeadingSection/buttonContainer';

type BottomAddButtonProps = {
  addValue: any,
}

export const BottomAddButton = (props: PropsWithChildren<BottomAddButtonProps>): JSX.Element => {
  const {
    addValue, children,
  } = props;
  const pushFieldArray = usePushFieldArray(addValue);
  const scrollToTop = useScrollTopOnClick();
  const onClick = (): void => {
    scrollToTop();
    pushFieldArray();
  };
  return (
    <ButtonContainer>
      <ouiDom.Button.Button
        onClick={onClick}
        type="button"
      >
        {children}
      </ouiDom.Button.Button>
    </ButtonContainer>
  );
};

export type FormArrayRemoveButtonProps = {
  resetUnlessFn: (selectedIndex: number | null) => boolean,
  filterFn: (tAdd: any) => boolean,
  minLength: number,
}

const onboardingPath: keyof Pick<UncastedDataStructure, 'onboarding'> = 'onboarding';

const usersPath: keyof Pick<UncastedOnboarding, 'users'> = 'users';

export const useFormArrayRemoveOnClick = (props: {
  filterFn: FormArrayRemoveButtonProps['filterFn'],
}) => {
  const {
    filterFn,
  } = props;
  const selectedIndex = useSelectedId();
  const scrollTopOnClick = useScrollTopOnClick();
  const data = useFormArrayWithFilter(filterFn);
  const [, setSelected] = useContext(SelectionListContext);
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const selectedIndexInt = (typeof selectedIndex === 'string' ? parseInt(selectedIndex, 10) : selectedIndex) ?? 0;
  const selectedArrayState = useHookstate(selectedIndexInt !== null ? valueState.onboarding.users[selectedIndexInt] : []);
  const touchedStates = useTouchedContext();
  const errorsStates = useErrors();
  const onClick = (): void => {
    scrollTopOnClick();
    if (selectedIndex) {
      const fieldPath = makeName(onboardingPath, usersPath, selectedIndex);
      const currentDataIndex = data.findIndex((id) => id === selectedIndex);
      setSelected(data[currentDataIndex - 1] ?? null)();
      selectedArrayState.set(none);
      touchedStates.keys.filter((key) => key.includes(fieldPath)).forEach((key) => touchedStates.nested(key).set(none));
      errorsStates.keys.filter((key) => key.includes(fieldPath)).forEach((key) => touchedStates.nested(key).set(none));
    }
  };
  return onClick;
};

export const useFormArrayRemoveIsVisible = (props: {
  filterFn: FormArrayRemoveButtonProps['filterFn'],
  minLength: FormArrayRemoveButtonProps['minLength'],
  resetUnlessFn: FormArrayRemoveButtonProps['resetUnlessFn'],
}) => {
  const {
    filterFn,
    minLength,
    resetUnlessFn,
  } = props;
  const selectedIndex = useSelectedId();
  const currentUserIndex = dataStructure.T_ADD.fields.CURRENT.hooks.useCurrentUserIndex();
  const selectedIndexIsNil = selectedIndex == null;
  const selectedIndexIsCurrent = selectedIndex === `${currentUserIndex}`;
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const arrayState = useHookstate(valueState.onboarding.users);
  const array = arrayState.get();
  const filteredValue = filterFn ? array.filter((valueItem) => filterFn(valueItem)) : array;
  const isFilteredBellowOrEqualToMinLength = filteredValue.length <= minLength;
  const resetUnless = resetUnlessFn(typeof selectedIndex === 'string' ? parseInt(selectedIndex, 10) : selectedIndex);
  const removeIsVisible = !(selectedIndexIsNil || isFilteredBellowOrEqualToMinLength || resetUnless || selectedIndexIsCurrent);
  return removeIsVisible;
};

export const FormArrayRemoveButton = (props: React.PropsWithChildren<FormArrayRemoveButtonProps>): JSX.Element | null => {
  const {
    resetUnlessFn,
    filterFn,
    children,
    minLength,
  } = props;
  const removeIsVisible = useFormArrayRemoveIsVisible({
    filterFn,
    minLength,
    resetUnlessFn,
  });
  const onClick = useFormArrayRemoveOnClick({
    filterFn,
  });
  return (
    removeIsVisible ? (
      <ButtonContainer>
        <ouiDom.Button.Button
          onClick={onClick}
          type="button"
        >
          {children}
        </ouiDom.Button.Button>
      </ButtonContainer>
    )
      : null
  );
};
