import ouiBase from '@goldwasserexchange/oui';
import { getModalName } from '../../../../shared';
import { useAccountPositionsHistoryTransformApiByHisMvtId } from '../api';

export const useAccountPositionsHistoryArDocModalName = () => {
  const hisMvtId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const response = useAccountPositionsHistoryTransformApiByHisMvtId({
    hisMvtId,
    transformFunction: getModalName,
  });
  return response;
};
