import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { useAtomValue } from 'jotai';
import { ValidSections, pathnameUrlLanguageAtom } from '../../../../../history';
import { ToolbarDropDownListElementLink } from './link';

export const SectionToolbarDropDownListElementLink = (props: React.PropsWithChildren<{
  section: ValidSections,
  textTransform: ouiStyle.InStyle['textTransform'],
  textAlign: ouiStyle.InStyle['textAlign'],
}>) => {
  const {
    section,
    textTransform,
    textAlign,
    children,
  } = props;
  const language = useAtomValue(pathnameUrlLanguageAtom);
  const to = `/${language}/${section}`;
  return (
    <ToolbarDropDownListElementLink
      to={to}
      textAlign={textAlign}
      textTransform={textTransform}
    >
      {children}
    </ToolbarDropDownListElementLink>
  );
};
