import { createContext } from 'react';
import { SpringRef, SpringValue } from 'react-spring';

export type AppBarLogoExpandedSpringValue = SpringValue<number>;

export type AppBarClosedSpringValue = SpringValue<number>;

export type AppBarMqUpSpringValue = SpringValue<number>;

export type AppBarCollapse = SpringValue<number>;

export type AppBarSpringRefValues = {
  logoExpanded: number,
  closed: number,
  mqUp: number,
  collapse: number,
  logoExpandedAction: number,
  openAction: number,
  mqAction: number,
  collapsingAction: number,
}

export type AppBarSpringRef = SpringRef<AppBarSpringRefValues>

export const animationControllerApiContext = createContext<AppBarSpringRef | null>(null);

export const animationControllerLogoExpandedContext = createContext<AppBarLogoExpandedSpringValue | null>(null);

export const animationControllerClosedTopContext = createContext<AppBarClosedSpringValue | null>(null);

export const animationControllerMqUpContext = createContext<AppBarMqUpSpringValue | null>(null);

export const animationControllerCollapseContext = createContext<AppBarCollapse | null>(null);
