import { makeName } from '@goldwasserexchange/oblis-frontend-utils';
import toPath from 'lodash.topath';
import { init, last } from 'ramda';
import { useContext, useMemo } from 'react';
import { fieldPathContext } from './context';

export function useGetFieldPath() {
  const fieldPath = useContext(fieldPathContext);
  return fieldPath;
}

export function useGetFieldPathArray() {
  const fieldPath = useGetFieldPath();
  const fieldPathArray = useMemo(() => toPath(fieldPath), [fieldPath]);
  return fieldPathArray;
}

export function useLastFieldPathPart() {
  const fieldPathArray = useGetFieldPathArray();
  const lastFieldPathPart = useMemo(() => last(fieldPathArray), [fieldPathArray]);
  return lastFieldPathPart;
}

export function useParentFieldPathArray() {
  const fieldPathArray = useGetFieldPathArray();
  const parentFieldPathArray = useMemo(() => init(fieldPathArray), [fieldPathArray]);
  return parentFieldPathArray;
}

export function useLastParentFieldPathPart() {
  const parentFieldPathArray = useParentFieldPathArray();
  return last(parentFieldPathArray);
}

export function useParentFieldPath() {
  const parentFieldPathPart = useParentFieldPathArray();
  return makeName(...parentFieldPathPart);
}
