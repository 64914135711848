import { DEFAULT_ONLINE_SECTION, ONLINE_SECTIONS } from './constants';

export const isValidAccountsSections = (section: string): section is 'details' | 'history' | 'timetable' | 'documents' | 'transfers' | 'rates' => ONLINE_SECTIONS.includes(section);

export const getValidAccountsSection = (section: string | undefined): 'details' | 'history' | 'timetable' | 'documents' | 'transfers' | 'rates' | 'not-found' => {
  if (section === '' || section == null) {
    return DEFAULT_ONLINE_SECTION;
  }
  if (!isValidAccountsSections(section)) {
    return 'not-found';
  }
  return section;
};
