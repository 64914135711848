import { safeLocaleCompare } from '@goldwasserexchange/utils';

export const makeCurrencySorter = (first?: string | string[]) => (prev: string, next: string) => {
  if (first != null) {
    if (!Array.isArray(first)) {
      if (prev === first && next === first) {
        return 0;
      } if (prev === first) {
        return -1;
      } if (next === first) {
        return 1;
      }
    }
    if (first.includes(prev) && first.includes(next)) {
      return 0;
    } if (first.includes(prev)) {
      return -1;
    } if (first.includes(next)) {
      return 1;
    }
  }
  return safeLocaleCompare(prev, next);
};
