import { AppBarSpringRef, AppBarSpringRefValues } from '../../../../animation/context/context';

export const makeSpringService = (
  name: string,
  key: keyof AppBarSpringRefValues,
  value: number,
  immediate = false, // eslint-disable-line default-param-last
  eventBuilder,
) => (springApi: AppBarSpringRef) => ({
  [name]: () => (callback) => {
    let mounted = true;
    if (springApi.current.values[key] !== value) {
      const controllerPromises = springApi.start({ [key]: value, immediate });
      Promise.all(controllerPromises).then((controllers) => {
        if (mounted && controllers[0] && controllers[0].finished) {
          callback(eventBuilder());
        }
      });
    } else if (mounted) {
      callback(eventBuilder());
    }
    return () => {
      mounted = false;
    };
  },
});
