import * as React from 'react';
import { searchKeysContext, SearchKey } from './context';

export function SearchKeysSetContext(
  props: React.PropsWithChildren<{
    searchKeys?: SearchKey[],
  }>,
) {
  const { searchKeys = [], children } = props;
  return (
    <searchKeysContext.Provider value={searchKeys}>
      {children}
    </searchKeysContext.Provider>
  );
}
