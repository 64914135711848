import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import {
  YesNoElementLabelContent,
} from '../components';

export const yesNoInputDomDefaultTopModelsObject = {
  InputListElementLabelContent: (
    <TopModel name="InputListElementLabelContent">
      {YesNoElementLabelContent}
    </TopModel>
  ),
};
