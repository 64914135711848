import * as React from 'react';
import ouiDom from '@goldwasserexchange/oui-dom';
import ouiStyle from '@goldwasserexchange/oui-style';
import { useSubmitDisabled } from '../context/validation/hooks';

export const SubmitButton = (props: React.PropsWithChildren<{
  paths: string[],
  color?: ouiStyle.InStyle['color'],
  backgroundColor?: ouiStyle.InStyle['backgroundColor'],
  hoverBackgroundColor?: ouiStyle.InStyle['backgroundColor'],
}>) => {
  const {
    paths,
    color,
    backgroundColor,
    hoverBackgroundColor,
    children,
  } = props;
  const disabled = useSubmitDisabled(paths);
  return (
    <ouiDom.Button.Button
      type="submit"
      disabled={disabled}
      backgroundColor={!disabled ? backgroundColor : ouiStyle.Constants.Colors.disabled}
      color={disabled ? color : ouiStyle.Constants.Colors.inverted}
      hoverBackgroundColor={!disabled ? hoverBackgroundColor : ouiStyle.Constants.Colors.disabled}
      borderColor={!disabled ? undefined : ouiStyle.Constants.Colors.disabled}
    >
      {children}
    </ouiDom.Button.Button>
  );
};
