import * as React from 'react';
import { useSpring } from 'react-spring';
import {
  toolbarDropDownOpenSpringContext,
  toolbarDropDownOpenContext,
  toolbarDropDownSetOpenContext,
  toolbarDropDownListContext,
} from './context';
import { useToolbarDropDownOpen } from './hooks';

export const ToolbarDropDownOpenSpringContextProvider = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  const isOpen = useToolbarDropDownOpen();
  const { open } = useSpring({
    from: {
      open: 0,
    },
    to: {
      open: isOpen ? 1 : 0,
    },
  });
  return (
    <toolbarDropDownOpenSpringContext.Provider value={open}>
      {children}
    </toolbarDropDownOpenSpringContext.Provider>
  );
};

export const ToolbarDropDownOpenContextProvider = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <toolbarDropDownOpenContext.Provider
      value={isOpen}
    >
      <toolbarDropDownSetOpenContext.Provider
        value={setIsOpen}
      >
        {children}
      </toolbarDropDownSetOpenContext.Provider>
    </toolbarDropDownOpenContext.Provider>
  );
};

export const ToolbarDropDownContextProvider = (props: React.PropsWithChildren<{
  list: string[],
}>) => {
  const {
    list,
    children,
  } = props;
  return (
    <toolbarDropDownListContext.Provider value={list}>
      <ToolbarDropDownOpenContextProvider>
        <ToolbarDropDownOpenSpringContextProvider>
          {children}
        </ToolbarDropDownOpenSpringContextProvider>
      </ToolbarDropDownOpenContextProvider>
    </toolbarDropDownListContext.Provider>
  );
};
