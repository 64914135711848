import * as React from 'react';
import ouiDom from '@goldwasserexchange/oui-dom';
import ouiStyle from '@goldwasserexchange/oui-style';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { Trans } from '@lingui/macro';
import { UncastedDataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import ouiBase from '@goldwasserexchange/oui';
import { TextInput } from '../../../../../../../OUI-dom/Inputs';
import { CenteredButtonContainer } from '../../../../../../CenteredButtonContainer';
import { ForgotPasswordInputCodeAndNewPasswordSubmitButton } from './submitButton';
import { ForgotPasswordInputCodeAndNewPasswordForm } from './form';
import { ForgotPasswordIntro } from './components';

export const InputForgotPasswordCodeAndNewPassword = () => {
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  return (
    <ouiDom.HeadingSection.HeadingSection
      name="InputCodeSignIn"
      index={1}
      backgroundColor={ouiStyle.Constants.Colors.transparent}
      container={ouiStyle.Constants.Container.slim}
      paddingHorizontal="double"
    >
      <ForgotPasswordInputCodeAndNewPasswordForm>
        <ouiDom.P.P>
          <ForgotPasswordIntro />
        </ouiDom.P.P>
        <TextInput
          fieldPath={valueState.auth.mfaCode.path.join('.')}
          autocomplete={false}
        >
          <TopModel name="TextInputLabelContent">
            <Trans>
              Code de sécurité
            </Trans>
          </TopModel>
        </TextInput>
        <TextInput
          fieldPath={valueState.auth.newPassword.path.join('.')}
          type="password"
        >
          <TopModel name="TextInputLabelContent">
            <Trans>Nouveau mot de passe</Trans>
          </TopModel>
        </TextInput>
        <TextInput
          fieldPath={valueState.auth.newPasswordRepeat.path.join('.')}
          type="password"
          dependenciesFieldPaths={[
            valueState.auth.newPassword.path.join('.'),
          ]}
        >
          <TopModel name="TextInputLabelContent">
            <Trans>Répétition du nouveau mot de passe</Trans>
          </TopModel>
          <TopModel name="TextInputEqualsRefError">
            <Trans>Différent du nouveau mot de passe</Trans>
          </TopModel>
        </TextInput>
        <CenteredButtonContainer>
          <ForgotPasswordInputCodeAndNewPasswordSubmitButton />
        </CenteredButtonContainer>
      </ForgotPasswordInputCodeAndNewPasswordForm>
    </ouiDom.HeadingSection.HeadingSection>
  );
};
