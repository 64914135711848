var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
// should be global variable
var SubscribePluginId = Symbol('MyStateSubscribePlugin');
var SubscribePluginInstance = /** @class */ (function () {
    function SubscribePluginInstance() {
        var _this = this;
        this.subscriptions = {};
        this.onSet = function (data) {
            var _a;
            var callbacks = Object.values((_a = _this.subscriptions[data.path.join('.')]) !== null && _a !== void 0 ? _a : {});
            callbacks.forEach(function (cb) {
                cb(data);
            });
        };
        this.onDestroy = function () {
            _this.subscriptions = {};
        };
        this.subscribe = function (path, name, fn) {
            var _a;
            var _b;
            _this.subscriptions[path] = __assign(__assign({}, ((_b = _this.subscriptions[path]) !== null && _b !== void 0 ? _b : {})), (_a = {}, _a[name] = fn, _a));
        };
        this.subscribeMultiple = function (paths, name, fn) {
            paths.forEach(function (path) { return _this.subscribe(path, name, fn); });
        };
        this.unsubscribe = function (path, name) {
            var _a;
            _this.subscriptions[path] = Object.fromEntries(Object.entries((_a = _this.subscriptions[path]) !== null && _a !== void 0 ? _a : {}).filter(function (_a) {
                var _b = __read(_a, 1), key = _b[0];
                return key !== name;
            }));
        };
        this.unsubscribeMultiple = function (paths, name) {
            paths.forEach(function (path) { return _this.unsubscribe(path, name); });
        };
    }
    return SubscribePluginInstance;
}());
export function SubscribePlugin($this) {
    if ($this) {
        var $th_1 = $this;
        var _a = __read($th_1.attach(SubscribePluginId), 1), instance = _a[0];
        if (instance instanceof Error) {
            throw instance;
        }
        var inst_1 = instance;
        return {
            subscribe: function (name, fn) { return inst_1.subscribe($th_1.path.join('.'), name, fn); },
            unsubscribe: function (name) { return inst_1.unsubscribe($th_1.path.join('.'), name); },
            subscribeMultiple: function (paths, name, fn) { return inst_1.subscribeMultiple(paths, name, fn); },
            unsubscribeMultiple: function (paths, name) { return inst_1.unsubscribeMultiple(paths, name); },
        };
    }
    return {
        id: SubscribePluginId,
        init: function () { return new SubscribePluginInstance(); },
    };
}
