import { Trans } from '@lingui/macro';
import * as React from 'react';

export const wrongLeiFormatErrorMessage = (
  <Trans>
    Ce champ doit posséder un code LEI correctement formaté
  </Trans>
);

export const invalidLeiErrorMessage = (
  <Trans>
    Ce champ doit posséder un code LEI valide
  </Trans>
);
