export var initialRTOValues = function () { return ({
    RTOBondQuestion0RemedialAnswer: undefined,
    RTOBondQuestion0Answer: undefined,
    RTOBondQuestion0IsCorrect: undefined,
    RTOBondQuestion0RemedialIsCorrect: undefined,
    RTOBondQuestion0WasRetried: false,
    RTOBondQuestion0IsCorrected: false,
    RTOBondQuestion0RemedialIsCorrected: false,
    RTOBondQuestion1Answer: undefined,
    RTOBondQuestion1RemedialAnswer: undefined,
    RTOBondQuestion1IsCorrect: undefined,
    RTOBondQuestion1RemedialIsCorrect: undefined,
    RTOBondQuestion1WasRetried: false,
    RTOBondQuestion1IsCorrected: false,
    RTOBondQuestion1RemedialIsCorrected: false,
    RTOBondQuestion2Answer: undefined,
    RTOBondQuestion2RemedialAnswer: undefined,
    RTOBondQuestion2IsCorrect: undefined,
    RTOBondQuestion2RemedialIsCorrect: undefined,
    RTOBondQuestion2WasRetried: false,
    RTOBondQuestion2IsCorrected: false,
    RTOBondQuestion2RemedialIsCorrected: false,
    RTOBondQuestion3Answer: undefined,
    RTOBondQuestion3RemedialAnswer: undefined,
    RTOBondQuestion3IsCorrect: undefined,
    RTOBondQuestion3RemedialIsCorrect: undefined,
    RTOBondQuestion3WasRetried: false,
    RTOBondQuestion3IsCorrected: false,
    RTOBondQuestion3RemedialIsCorrected: false,
    RTOBondQuestion4Answer: undefined,
    RTOBondQuestion4RemedialAnswer: undefined,
    RTOBondQuestion4IsCorrect: undefined,
    RTOBondQuestion4RemedialIsCorrect: undefined,
    RTOBondQuestion4WasRetried: false,
    RTOBondQuestion4IsCorrected: false,
    RTOBondQuestion4RemedialIsCorrected: false,
    RTOBondQuestion5Answer: undefined,
    RTOBondQuestion5RemedialAnswer: undefined,
    RTOBondQuestion5IsCorrect: undefined,
    RTOBondQuestion5RemedialIsCorrect: undefined,
    RTOBondQuestion5WasRetried: false,
    RTOBondQuestion5IsCorrected: false,
    RTOBondQuestion5RemedialIsCorrected: false,
    RTOBondQuestion6Answer: undefined,
    RTOBondQuestion6RemedialAnswer: undefined,
    RTOBondQuestion6IsCorrect: undefined,
    RTOBondQuestion6RemedialIsCorrect: undefined,
    RTOBondQuestion6WasRetried: false,
    RTOBondQuestion6IsCorrected: false,
    RTOBondQuestion6RemedialIsCorrected: false,
    RTOBondQuestion7Answer: undefined,
    RTOBondQuestion7RemedialAnswer: undefined,
    RTOBondQuestion7IsCorrect: undefined,
    RTOBondQuestion7RemedialIsCorrect: undefined,
    RTOBondQuestion7WasRetried: false,
    RTOBondQuestion7IsCorrected: false,
    RTOBondQuestion7RemedialIsCorrected: false,
    RTOBondQuestion8Answer: undefined,
    RTOBondQuestion8RemedialAnswer: undefined,
    RTOBondQuestion8IsCorrect: undefined,
    RTOBondQuestion8RemedialIsCorrect: undefined,
    RTOBondQuestion8WasRetried: false,
    RTOBondQuestion8IsCorrected: false,
    RTOBondQuestion8RemedialIsCorrected: false,
    RTOBondQuestion9Answer: undefined,
    RTOBondQuestion9RemedialAnswer: undefined,
    RTOBondQuestion9IsCorrect: undefined,
    RTOBondQuestion9RemedialIsCorrect: undefined,
    RTOBondQuestion9WasRetried: false,
    RTOBondQuestion9IsCorrected: false,
    RTOBondQuestion9RemedialIsCorrected: false,
    RTOBondQuestion10Answer: undefined,
    RTOBondQuestion10RemedialAnswer: undefined,
    RTOBondQuestion10IsCorrect: undefined,
    RTOBondQuestion10RemedialIsCorrect: undefined,
    RTOBondQuestion10WasRetried: false,
    RTOBondQuestion10IsCorrected: false,
    RTOBondQuestion10RemedialIsCorrected: false,
    RTOBondQuestion11Answer: undefined,
    RTOBondQuestion11RemedialAnswer: undefined,
    RTOBondQuestion11IsCorrect: undefined,
    RTOBondQuestion11RemedialIsCorrect: undefined,
    RTOBondQuestion11WasRetried: false,
    RTOBondQuestion11IsCorrected: false,
    RTOBondQuestion11RemedialIsCorrected: false,
}); };
export var initialConseilGestionValues = function () { return ({
    bondCurrencyRiskAnswer: undefined,
    bondCurrencyRiskIsCorrect: undefined,
    bondCurrencyRiskWasRetried: false,
    bondCurrencyRiskIsCorrected: false,
    bondDefaultAnswer: undefined,
    bondDefaultIsCorrect: undefined,
    bondDefaultWasRetried: false,
    bondDefaultIsCorrected: false,
    bondDefinitionAnswer: undefined,
    bondDefinitionIsCorrect: undefined,
    bondDefinitionWasRetried: false,
    bondDefinitionIsCorrected: false,
    bondSaleAnswer: undefined,
    bondSaleIsCorrect: undefined,
    bondSaleWasRetried: false,
    bondSaleIsCorrected: false,
    bondVariationAnswer: undefined,
    bondVariationIsCorrect: undefined,
    bondVariationWasRetried: false,
    bondVariationIsCorrected: false,
}); };
