import { UncastedDataStructure, UncastedOnboarding } from '@goldwasserexchange/oblis-frontend-utils';

const onboardingPath: keyof Pick<UncastedDataStructure, 'onboarding'> = 'onboarding';

const knowledgeQuestionnaireKey: keyof Pick<UncastedOnboarding, 'knowledgeQuestionnaire'> = 'knowledgeQuestionnaire';

export const correctBondAnswers = {
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTOBondQuestion0`]: '1',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTOBondQuestion0Remedial`]: '0',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTOBondQuestion1`]: '0',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTOBondQuestion1Remedial`]: '0',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTOBondQuestion2`]: '2',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTOBondQuestion2Remedial`]: '0',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTOBondQuestion3`]: '2',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTOBondQuestion3Remedial`]: '0',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTOBondQuestion4`]: '1',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTOBondQuestion4Remedial`]: '0',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTOBondQuestion5`]: '2',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTOBondQuestion5Remedial`]: '1',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTOBondQuestion6`]: '1',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTOBondQuestion6Remedial`]: '2',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTOBondQuestion7`]: '0',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTOBondQuestion7Remedial`]: '0',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTOBondQuestion8`]: '1',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTOBondQuestion8Remedial`]: '0',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTOBondQuestion9`]: '0',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTOBondQuestion9Remedial`]: '2',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTOBondQuestion10`]: '1',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTOBondQuestion10Remedial`]: '1',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTOBondQuestion11`]: '1',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTOBondQuestion11Remedial`]: '1',
};

export const correctPerpetualBondAnswers = {
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTOPerpetualBondQuestion0`]: '2',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTOPerpetualBondQuestion0Remedial`]: '1',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTOPerpetualBondQuestion1`]: '0',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTOPerpetualBondQuestion1Remedial`]: '0',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTOPerpetualBondQuestion2`]: '0',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTOPerpetualBondQuestion2Remedial`]: '0',
};

export const correctSubordinatedBondAnswers = {
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTOSubordinatedBondQuestion0`]: '1',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTOSubordinatedBondQuestion0Remedial`]: '1',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTOSubordinatedBondQuestion1`]: '2',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTOSubordinatedBondQuestion1Remedial`]: '2',
};

export const correctTrackerAndETFAnswers = {
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTORawMaterialTrackerQuestion0`]: '0',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTORawMaterialTrackerQuestion0Remedial`]: '2',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTORawMaterialTrackerQuestion1`]: '0',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTORawMaterialTrackerQuestion1Remedial`]: '1',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTORawMaterialTrackerQuestion2`]: '2',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTORawMaterialTrackerQuestion2Remedial`]: '1',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTORawMaterialTrackerQuestion3`]: '0',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTORawMaterialTrackerQuestion3Remedial`]: '0',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTORawMaterialTrackerQuestion4`]: '1',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTORawMaterialTrackerQuestion4Remedial`]: '1',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTORawMaterialTrackerQuestion5`]: '0',
  [`${onboardingPath}.${knowledgeQuestionnaireKey}.RTORawMaterialTrackerQuestion5Remedial`]: '1',
};
