import ouiBase from '@goldwasserexchange/oui';
import { useCallback } from 'react';
import { PersonDevices } from '../type';

export const filterByDeviceId = (deviceId: string) => (devideLine: PersonDevices) => devideLine.id === deviceId;

export const useFilterByDeviceId = (deviceId: string) => {
  const filterFn = useCallback(filterByDeviceId(deviceId), [deviceId]);
  return filterFn;
};

export const useFilterByPrimaryDimensionDeviceId = () => {
  const id = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const filterPredicat = useFilterByDeviceId(id);
  return filterPredicat;
};
