import * as TYPE from '../../TYPE';
var onboardingPath = 'onboarding';
var fiscalResidencySameAsResidencyPath = 'fiscalResidencySameAsResidency';
var isPrintPath = 'isPrint';
export var showLogic = TYPE.logic.makeTAddIsNotMoralOrIgnored({
    truthy: {
        name: "<parentName>.".concat(fiscalResidencySameAsResidencyPath),
        is: false,
        alternatives: {
            truthy: {
                result: 'visible',
            },
            falsy: {
                name: "".concat(onboardingPath, ".").concat(isPrintPath),
                is: false,
                alternatives: {
                    truthy: {
                        result: 'invisible',
                    },
                    falsy: {
                        result: 'visible',
                    },
                },
            },
        },
    },
});
export var showBooleanLogic = TYPE.logic.makeTAddIsNotMoralOrIgnored({
    truthy: {
        name: "<parentName>.".concat(fiscalResidencySameAsResidencyPath),
        is: false,
        alternatives: {
            truthy: {
                result: true,
            },
            falsy: {
                name: "".concat(onboardingPath, ".").concat(isPrintPath),
                is: false,
                alternatives: {
                    truthy: {
                        result: false,
                    },
                    falsy: {
                        result: true,
                    },
                },
            },
        },
    },
});
