import {
  differenceInMilliseconds, endOfDay, endOfHour, endOfMinute, endOfMonth, endOfQuarter, endOfSecond, endOfWeek, endOfYear, startOfDay, startOfHour, startOfMinute, startOfMonth, startOfQuarter, startOfSecond, startOfWeek, startOfYear,
} from 'date-fns';
import * as React from 'react';
import { getDefaultTimeFormatUnit } from '.';
import { useTickingNowState } from '../useTickingNowState';
import { RelativeTime, TimeFormatUnit } from './relativeTime';

const timeFormatUnitDurations: Record<TimeFormatUnit, (now: Date) => number> = {
  year: (now: Date) => differenceInMilliseconds(startOfYear(now), endOfYear(now)),
  quarter: (now: Date) => differenceInMilliseconds(startOfQuarter(now), endOfQuarter(now)),
  month: (now: Date) => differenceInMilliseconds(startOfMonth(now), endOfMonth(now)),
  week: (now: Date) => differenceInMilliseconds(startOfWeek(now), endOfWeek(now)),
  day: (now: Date) => differenceInMilliseconds(startOfDay(now), endOfDay(now)),
  hour: (now: Date) => differenceInMilliseconds(startOfHour(now), endOfHour(now)),
  minute: (now: Date) => differenceInMilliseconds(startOfMinute(now), endOfMinute(now)),
  second: (now: Date) => differenceInMilliseconds(startOfSecond(now), endOfSecond(now)),
};

export const RelativeTimeToNow = (props: {
  startDate: Date,
  timeFormatUnit?: TimeFormatUnit,
  styleFormat?: 'short' | 'narrow' | 'long',
}) => {
  const {
    startDate,
    timeFormatUnit,
    styleFormat,
  } = props;
  const now = new Date();
  const timeFormatUnitWithDefault = timeFormatUnit ?? getDefaultTimeFormatUnit(startDate, now);
  const intervalTime = timeFormatUnitDurations[timeFormatUnitWithDefault](now);
  const nowDate = useTickingNowState(intervalTime);
  return (
    <RelativeTime
      startDate={startDate}
      endDate={nowDate}
      timeFormatUnit={timeFormatUnitWithDefault}
      style={styleFormat}
    />
  );
};
