import * as React from 'react';

export const UserIcon = (props: {
  style:React.SVGAttributes<SVGSVGElement>['style'],
}) => {
  const {
    style,
  } = props;
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 75 75"
      style={style}
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M48.1,36.5c4.8-3.4,7.9-8.9,7.9-15.2c0-10.2-8.3-18.6-18.5-18.6s-18.6,8.3-18.6,18.6c0,6.3,3.2,11.8,7.9,15.2
  C12.8,41,2.6,54.2,2.6,69.8c0,1.4,1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5c0-16.5,13.4-29.9,29.9-29.9s29.9,13.4,29.9,29.9
  c0,1.4,1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5C72.4,54.2,62.2,41,48.1,36.5z M23.9,21.3c0-7.5,6.1-13.6,13.6-13.6s13.5,6.1,13.5,13.6
  S45,34.8,37.5,34.8S23.9,28.8,23.9,21.3z"
      />
    </svg>
  );
};
