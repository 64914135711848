import * as Yup from 'yup';
import { UncastedDataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import {
  stringValidator, booleanValidator, makeRequired, makeDateNotMinor, makeDateInThePast, euroDateValidator,
} from '../../../../../OUI/Inputs';
import { mfaCodeFieldValidator } from './mfaCode';
import { passwordRepeatFieldValidator } from './passwordRepeat';
import { newPasswordFieldValidator } from './newPassword';
import { passwordFieldValidator } from './password';
import { newPasswordRepeatFieldValidator } from './newPasswordRepeat';
import { recaptchaValidator } from './recaptcha';
import { accountValidator } from './account';
import { rememberDeviceValidator } from './rememberDevice';
import { usernameFieldValidator } from './username';
import { birthdateFieldValidator } from './birthdate';
import { changedPasswordPasswordFieldValidator } from './changePassword';
import { changedPasswordRepeatFieldValidator } from './changePasswordRepeat';
import { makeWhenAlwaysCast } from '../../../../Form/validations/whenAlwaysCast';

const authShape: Record<keyof UncastedDataStructure['auth'], any> = {
  username: usernameFieldValidator,
  birthdate: makeWhenAlwaysCast([], (_, meta) => {
    const submitType = meta?.context?.rootValue?.submitType;
    if (submitType === 'onboardingSignUp') {
      return makeRequired(makeDateNotMinor(makeDateInThePast(euroDateValidator)));
    }
    return birthdateFieldValidator;
  }),
  firstName: makeRequired(stringValidator),
  lastName: makeRequired(stringValidator),
  password: passwordFieldValidator,
  passwordRepeat: passwordRepeatFieldValidator,
  mfaCode: mfaCodeFieldValidator,
  newPassword: newPasswordFieldValidator,
  newPasswordRepeat: newPasswordRepeatFieldValidator,
  changedPassword: changedPasswordPasswordFieldValidator,
  changedPasswordRepeat: changedPasswordRepeatFieldValidator,
  recaptcha: recaptchaValidator,
  account: accountValidator,
  rememberDevice: rememberDeviceValidator,
  identityId: stringValidator.default(''),
  bucketName: stringValidator.nullable(),
  singleTableName: stringValidator.nullable(),
  connected: booleanValidator,
  onboardingConnected: booleanValidator,
};

export const authValidator = Yup.object().shape(authShape);
