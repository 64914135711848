import { useContext } from 'react';
import { useHookstate } from '@hookstate/core';
import { errorsContext } from './context';

export function useErrors() {
  const errors = useContext(errorsContext);
  return useHookstate(errors);
}

export function useFieldErrorByFieldPath(fieldPath: string) {
  const errors = useErrors();
  const key = errors.keys.findIndex((errorKey) => errorKey === fieldPath);
  if (key === -1) {
    errors.nested(fieldPath).set([]);
  }
  return useHookstate(errors.nested(fieldPath));
}
