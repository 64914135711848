import {
  Action,
  Location,
  Update,
} from 'history';
import { atom, createStore } from 'jotai';

export const historyRootAtom = atom<{
  location: Location,
  action: Action,
}>({
  location: {
    pathname: window.location.pathname,
    search: window.location.search,
    hash: window.location.hash,
    state: null,
    key: 'start',
  },
  action: Action.Pop,
});

export const historyAtomListener = (jotaiStore: ReturnType<typeof createStore>) => (currentUpdate: Update) => {
  const {
    location: rootLocation,
    action: rootAction,
  } = currentUpdate;
  jotaiStore.set(historyRootAtom, () => ({
    location: rootLocation,
    action: rootAction,
  }));
  return currentUpdate;
};
