import { useFilterByCCurrencyCode } from '../../../positions/utils/cCurrency/filterByCCurrencyCode';
import { useAccountAvailableCashFieldApi } from '../api';

export const useAccountAvailableCashCCurrencyCode = () => {
  const filterPredicate = useFilterByCCurrencyCode();
  const result = useAccountAvailableCashFieldApi({
    filterPredicate,
    field: 'C_CURRENCY.CODE',
  });
  return result;
};
