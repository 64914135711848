import { makeName } from '../../../../makeName';
var onboardingPath = 'onboarding';
var productPath = 'products';
var experiencePath = 'experience';
export function makeHasExperienceLogicByFinancialProduct(props) {
    var financialProduct = props.financialProduct, alternatives = props.alternatives;
    var hasExperience = alternatives.hasExperience, hasNotExperience = alternatives.hasNotExperience, notAnswered = alternatives.notAnswered;
    var name = makeName(onboardingPath, productPath, financialProduct, experiencePath);
    return {
        name: name,
        is: true,
        alternatives: {
            truthy: hasExperience,
            falsy: {
                name: name,
                is: undefined,
                alternatives: {
                    truthy: notAnswered,
                    falsy: hasNotExperience,
                },
            },
        },
    };
}
