import { dataStructure, AccountType } from '@goldwasserexchange/oblis-frontend-utils';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { Trans } from '@lingui/macro';
import * as React from 'react';
import ouiBase from '@goldwasserexchange/oui';

export const OUTGOING_SECURITIES_FEE = 'outgoingSecuritiesFee';

const outgoingSecuritiesFeesLabels = {
  [AccountType.RTO]: {
    label: (
      <Trans>60,50 EUR par poste</Trans>
    ),
  },
  [AccountType.CONSEIL]: {
    label: (
      <Trans>60,50 EUR par poste</Trans>
    ),
  },
  [AccountType.GESTION]: {
    label: (
      <Trans>60,50 EUR par poste</Trans>
    ),
  },
};

export const useOutgoingSecuritiesFeesLabel = (accountType: dataStructure.accountType.listType) => {
  const translated = outgoingSecuritiesFeesLabels[accountType].label;
  return translated;
};

export const OutgoingSecuritiesFeesLabel = (props: { accountType: dataStructure.accountType.listType }) => {
  const { accountType } = props;
  const translated = useOutgoingSecuritiesFeesLabel(accountType);
  return translated;
};

export const OutgoingSecuritiesFeesLabelOnPrimaryDimension = () => {
  const accountType = ouiBase.utils.dimensions.useCurrentPrimaryDimension() as dataStructure.accountType.listType;
  return (
    <OutgoingSecuritiesFeesLabel accountType={accountType} />
  );
};

const outgoingSecurityFeesLabel = (
  <Trans>
    Transfert titres sortants
  </Trans>

);

export const outgoingSecuritiesFeesTopModels = (
  <>
    <TopModel name={`TableSecondaryTitleCellContent-${OUTGOING_SECURITIES_FEE}`}>
      {outgoingSecurityFeesLabel}
    </TopModel>
    <TopModel name={`TableBodyRowCellContent--${OUTGOING_SECURITIES_FEE}`}>
      {OutgoingSecuritiesFeesLabelOnPrimaryDimension}
    </TopModel>
  </>
);
