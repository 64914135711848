import { isNil } from 'ramda';
export function stringifyIs(is) {
    if (Array.isArray(is)) {
        return is.map(function (individualIs) { return stringifyIs(individualIs); });
    }
    if (isNil(is)) {
        return '';
    }
    if (typeof is === 'boolean') {
        if (is === true) {
            return '1';
        }
        return '0';
    }
    return "".concat(is);
}
