import { createStore } from 'jotai';
import { isCookieFirstLoaded } from './utils';
import { cookieFirstLoadedAtom } from './atoms';
import { pathnameUrlLanguageAtom } from '../router';

export const makeCookieFirstLoadedListener = (jotaiStore: ReturnType<typeof createStore>) => {
  let unsubscribe: null | (() => void) = null;
  const initFn = () => {
    jotaiStore.set(cookieFirstLoadedAtom, () => true);
    const language = jotaiStore.get(pathnameUrlLanguageAtom);
    if (window && window.CookieFirst) {
      window.CookieFirst.changeLanguage(language);
    }
    if (unsubscribe != null && typeof unsubscribe === 'function') {
      unsubscribe();
    }
  };
  unsubscribe = () => {
    window.removeEventListener('cf_init', initFn);
  };
  return () => {
    if (!isCookieFirstLoaded() && window) {
      window.addEventListener('cf_init', initFn);
    }
  };
};
