export type BondSortColumns =
  | 'S_ACC.CREATE_AT'
  | 'C_CURRENCY.CODE'
  | 'S_ISSUER.NAME_1'
  | 'S_ACC.INT_RATE'
  | 'S_ACC.OBL_END_DATE'
  | 'S_QUOTE.PRICE_CLOSE'
  | 'S_MARKET.YIELD'
  | 'S_MARKET.QT_MIN'
  | 'GES_RAT_VAL_SP.SHORTCUT';

export const bondSortColumns: BondSortColumns[] = [
  'C_CURRENCY.CODE',
  'S_ISSUER.NAME_1',
  'S_ACC.INT_RATE',
  'S_ACC.OBL_END_DATE',
  'S_QUOTE.PRICE_CLOSE',
  'S_MARKET.YIELD',
  'S_MARKET.QT_MIN',
  'GES_RAT_VAL_SP.SHORTCUT',
];
