import { Trans } from '@lingui/macro';
import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';

export const tAddPhonePhoneNumberHelpText = <Trans>Au format belge ou international (00 ou +)</Trans>;

export const tAddPhonePhoneNumberHelpTextTopModel = (
  <TopModel name="TextInputFooterHelpContent">
    {tAddPhonePhoneNumberHelpText}
  </TopModel>
);
