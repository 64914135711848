export const makeGuardOrSkip = (cond, sequenceName, sequence) => {
  const conds = (Array.isArray(cond) ? cond : [cond]).map((singleCond) => ({
    target: 'done',
    cond: singleCond,
  }));
  return ({
    initial: 'initial',
    states: {
      initial: {
        on: {
          '': [...conds, {
            target: sequenceName,
          }],
        },
      },
      [sequenceName]: {
        ...sequence,
        onDone: 'done',
      },
      done: {
        type: 'final' as const,
      },
    },
  });
};
