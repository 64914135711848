import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import {
  inputBooleanErrorMessage,
  inputBooleanIsFalseErrorMessage,
  inputBooleanIsTrueErrorMessage,
  inputDateMinorErrorMessage,
  inputEmailErrorMessage,
  inputNotAnEuroDateErrorMessage,
  inputHasAtLeatOneDigitErrorMessage,
  inputHasAtLeatOneLowerCaseErrorMessage,
  inputHasAtLeatOneSpecialCharErrorMessage,
  inputHasAtLeatOneUpperCaseErrorMessage,
  inputInvalidBirthdateErrorMessage,
  wrongLeiFormatErrorMessage,
  invalidLeiErrorMessage,
  inputMultiChoiceAtLeatOneErrorMessage,
  inputNationalRegisterErrorMessage,
  inputNationalRegisterAndBirthdateMismatchErrorMessage,
  inputNotANumberErrorMessage,
  inputNotAStringErrorMessage,
  inputNotInTheFutureErrorMessage,
  inputNotInThePastErrorMessage,
  inputNotNilErrorMessage,
  inputNumberLessThanErrorMessage,
  inputNumberMaxErrorMessage,
  inputNumberMinErrorMessage,
  inputNumberMoreThanErrorMessage,
  inputNumberNegativeErrorMessage,
  inputNumberPositiveErrorMessage,
  inputOnlyNumbersErrorMessage,
  inputPhoneErrorMessage,
  inputRequiredErrorMessage,
  inputTvaNumberError,
  inputLengthErrorMessage,
  inputDateNotMinorErrorMessage,
} from '..';
import { inputMinErrorMessage } from '../minError';

export const errorMessagesTopModelObject = {
  InputRequiredError: (
    <TopModel name="InputRequiredError">{inputRequiredErrorMessage}</TopModel>
  ),
  InputNotNilError: (
    <TopModel name="InputNotNilError">{inputNotNilErrorMessage}</TopModel>
  ),
  InputBooleanError: (
    <TopModel name="InputNotNilError">{inputBooleanErrorMessage}</TopModel>
  ),
  InputBooleanIsTrueError: (
    <TopModel name="InputBooleanIsTrueError">{inputBooleanIsTrueErrorMessage}</TopModel>
  ),
  InputBooleanIsFalseError: (
    <TopModel name="InputBooleanIsFalseError">{inputBooleanIsFalseErrorMessage}</TopModel>
  ),
  InputNotAEuroDateError: (
    <TopModel name="InputNotAEuroDateError">{inputNotAnEuroDateErrorMessage}</TopModel>
  ),
  InputDateMinorError: (
    <TopModel name="InputDateMinorError">{inputDateMinorErrorMessage}</TopModel>
  ),
  InputDateNotMinorError: (
    <TopModel name="InputDateNotMinorError">{inputDateNotMinorErrorMessage}</TopModel>
  ),
  InputNotInThePastError: (
    <TopModel name="InputNotInThePastError">{inputNotInThePastErrorMessage}</TopModel>
  ),
  InputNotInTheFutureError: (
    <TopModel name="InputNotInTheFutureError">{inputNotInTheFutureErrorMessage}</TopModel>
  ),
  InputNotANumberError: (
    <TopModel name="InputNotANumberError">{inputNotANumberErrorMessage}</TopModel>
  ),
  InputNumberMinError: (
    <TopModel name="InputNumberMinError">{inputNumberMinErrorMessage}</TopModel>
  ),
  InputNumberMaxError: (
    <TopModel name="InputNumberMaxError">{inputNumberMaxErrorMessage}</TopModel>
  ),
  InputNumberMoreThanError: (
    <TopModel name="InputNumberMoreThanError">{inputNumberMoreThanErrorMessage}</TopModel>
  ),
  InputNumberLessThanError: (
    <TopModel name="InputNumberLessThanError">{inputNumberLessThanErrorMessage}</TopModel>
  ),
  InputNumberPositiveError: (
    <TopModel name="InputNumberPositiveError">{inputNumberPositiveErrorMessage}</TopModel>
  ),
  InputNumberNegativeError: (
    <TopModel name="InputNumberNegativeError">{inputNumberNegativeErrorMessage}</TopModel>
  ),
  InputNotAStringError: (
    <TopModel name="InputNotAStringError">{inputNotAStringErrorMessage}</TopModel>
  ),
  InputOnlyNumbersError: (
    <TopModel name="InputOnlyNumbersError">{inputOnlyNumbersErrorMessage}</TopModel>
  ),
  InputHasAtLeastOneDigitError: (
    <TopModel name="InputHasAtLeastOneDigitError">{inputHasAtLeatOneDigitErrorMessage}</TopModel>
  ),
  InputHasAtLeastOneLowerCaseError: (
    <TopModel name="inputHasAtLeastOneLowerCaseError">{inputHasAtLeatOneLowerCaseErrorMessage}</TopModel>
  ),
  InputHasAtLeastOneUpperCaseError: (
    <TopModel name="InputHasAtLeastOneUpperCaseError">{inputHasAtLeatOneUpperCaseErrorMessage}</TopModel>
  ),
  InputHasAtLeastOneSpecialCharError: (
    <TopModel name="inputHasAtLeastOneSpecialCharError">{inputHasAtLeatOneSpecialCharErrorMessage}</TopModel>
  ),
  InputPhoneError: (
    <TopModel name="inputPhoneError">{inputPhoneErrorMessage}</TopModel>
  ),
  InputEmailError: (
    <TopModel name="inputEmailError">{inputEmailErrorMessage}</TopModel>
  ),
  InputWrongLeiFormatError: (
    <TopModel name="InputWrongLeiFormatError">{wrongLeiFormatErrorMessage}</TopModel>
  ),
  InputInvalidLeiError: (
    <TopModel name="InputInvalidLeiError">{invalidLeiErrorMessage}</TopModel>
  ),
  InputTvaNumberError: (
    <TopModel name="InputLeiError">{inputTvaNumberError}</TopModel>
  ),
  InputNationalRegisterError: (
    <TopModel name="InputNationalRegisterError">{inputNationalRegisterErrorMessage}</TopModel>
  ),
  InputNationalRegisterAndBirthdateMismatchError: (
    <TopModel name="InputNationalRegisterAndBirthdateMismatchError">{inputNationalRegisterAndBirthdateMismatchErrorMessage}</TopModel>
  ),
  InputInvalidBirthdateError: (
    <TopModel name="InputInvalidBirthdateError">{inputInvalidBirthdateErrorMessage}</TopModel>
  ),
  InputMultiChoiceAtLeatOneError: (
    <TopModel name="InputMultiChoiceAtLeatOneError">{inputMultiChoiceAtLeatOneErrorMessage}</TopModel>
  ),
  InputLengthError: (
    <TopModel name="InputLengthError">{inputLengthErrorMessage}</TopModel>
  ),
  InputMinError: (
    <TopModel name="InputMinError">{inputMinErrorMessage}</TopModel>
  ),
};
