import { isNil } from 'ramda';
import { PersonAccounts } from '../../type';
import { shouldShowTResp } from '../../utils';
import { usePersonAccountTransformApi } from '../api';

const hasData = (data?: PersonAccounts | null) => {
  const tCatShortcut = data ? data['T_CAT.SHORTCUT'] : null;
  return !isNil(tCatShortcut);
};

export const usePersonAccountsShouldShowTResp = () => {
  const response = usePersonAccountTransformApi({
    transformFunction: hasData,
    filterPredicate: shouldShowTResp,
  });
  return response;
};
