import * as React from 'react';
import { Untracked } from '@hookstate/untracked';
import { AnyObjectSchema, ValidationError } from 'yup';
import ouiBase from '@goldwasserexchange/oui';
import { UncastedDataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import { State } from '@hookstate/core';
import { fieldErrorsContext } from './context';
import { useGetFieldPath } from '../fieldPath/hooks';
import { useFieldErrorByFieldPath, useFormValidator } from '../../../Form';
import { useFieldContent } from '../content';
import { useDependenciesFieldPaths } from './dependencies';
import { validateAt } from '../../../../Components/Form/validations/validate';

const initialErrorArray: string[] = [];

const makeValidate = (props: {
  dataState: State<UncastedDataStructure>,
  validator: AnyObjectSchema,
  fieldPath: string,
  errs: State<string[]>,
}) => () => {
  const {
    dataState,
    validator,
    fieldPath,
    errs,
  } = props;
  const data = Untracked(dataState).get() as UncastedDataStructure;
  if (validator) {
    validateAt({
      validator,
      path: fieldPath,
      values: data,
    })
      .then(() => {
        errs.set(initialErrorArray);
      })
      .catch((err: ValidationError) => {
        const { errors: yupErrors } = err;
        errs.set(yupErrors);
      });
  }
};

export function FieldErrorsContextProvider(props: React.PropsWithChildren<{}>) {
  const { children } = props;
  const dependenciesFieldPaths = useDependenciesFieldPaths();
  const fieldPath = useGetFieldPath();
  const errs = useFieldErrorByFieldPath(fieldPath);
  const dataState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const fieldContentState = useFieldContent();
  const fieldContent = fieldContentState.get();
  const validator = useFormValidator();
  const validate = React.useCallback(
    makeValidate({
      dataState,
      validator,
      fieldPath,
      errs,
    }),
    [
      dataState,
      validator,
      fieldPath,
    ],
  );
  React.useEffect(() => {
    validate();
    ouiBase.Form.SubscribePlugin(dataState).subscribeMultiple([fieldPath, ...dependenciesFieldPaths], fieldPath, () => {
      validate();
    });
    return () => {
      ouiBase.Form.SubscribePlugin(dataState).unsubscribeMultiple([fieldPath, ...dependenciesFieldPaths], fieldPath);
    };
  }, [validate, fieldContent, fieldPath, dependenciesFieldPaths.join('-')]);
  return (
    <fieldErrorsContext.Provider value={errs.get() ?? initialErrorArray}>
      {children}
    </fieldErrorsContext.Provider>
  );
}
