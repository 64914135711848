import { useContext, useCallback } from 'react';
import { showHelpTextContext } from './context';

export const useShowHelpState = () => {
  const showHelpState = useContext(showHelpTextContext);
  return showHelpState;
};

export const useShowHelp = () => {
  const [showHelp] = useShowHelpState();
  return showHelp;
};

export const useToggleHelp = () => {
  const [, setShowHelp] = useShowHelpState();
  const toggleHelp = useCallback(() => {
    setShowHelp((s) => !s);
  }, [setShowHelp]);
  return toggleHelp;
};
