var _a;
import ouiBase from '@goldwasserexchange/oui';
import * as React from 'react';
import { useHookstate } from '@hookstate/core';
import { i18n } from '@goldwasserexchange/i18n';
import { PersonType } from '../../types';
var labels = (_a = {},
    _a[PersonType.INDIVIDUAL] = {
        ID: PersonType.INDIVIDUAL,
        label: function () { return i18n._('Un compte individuel ouvert au nom d\'une seule personne'); },
    },
    _a[PersonType.JOINT] = {
        ID: PersonType.JOINT,
        label: function () { return i18n._('Un compte joint ouvert au nom de deux époux/cohabitants légaux'); },
    },
    _a[PersonType.INDIVISION] = {
        ID: PersonType.INDIVISION,
        label: function () { return i18n._('Un compte en indivision ouvert au nom de plusieurs personnes qui ne sont pas des époux/cohabitants légaux'); },
    },
    _a[PersonType.DIVIDED] = {
        ID: PersonType.DIVIDED,
        label: function () { return i18n._('Un compte démembré ouvert au nom d\'un ou plusieurs usufruitier(s) et d\'un ou plusieurs nu-propriétaire(s)'); },
    },
    _a[PersonType.FOR_MINORS] = {
        ID: PersonType.FOR_MINORS,
        label: function () { return i18n._('Un compte ouvert au nom d\'un ou plusieurs mineurs dont vous êtes le représentant légal'); },
    },
    _a[PersonType.MORAL] = {
        ID: PersonType.MORAL,
        label: function () { return i18n._('Un compte ouvert au nom d\'une personne morale'); },
    },
    _a);
export var useLabel = function (personType) {
    var valueState = ouiBase.Form.useFormData();
    var isPrint = useHookstate(valueState.onboarding.isPrint).get();
    if (personType == null) {
        return '';
    }
    if (isPrint && personType === PersonType.FOR_MINORS) {
        return i18n._('Un compte ouvert au nom d\'un ou plusieurs mineurs');
    }
    var translated = labels[personType].label();
    return translated;
};
export var useShortLabel = function (personType) {
    switch (personType) {
        case PersonType.INDIVIDUAL:
            return i18n._('Individuel');
        case PersonType.JOINT:
            return i18n._('Joint');
        case PersonType.INDIVISION:
            return i18n._('Indivision');
        case PersonType.DIVIDED:
            return i18n._('NP/US');
        case PersonType.FOR_MINORS:
            return i18n._('Mineur');
        case PersonType.MORAL:
            return i18n._('Personne morale');
        default:
            return '';
    }
};
export var Label = function (props) {
    var personType = props.personType;
    var translated = useLabel(personType);
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return React.createElement(React.Fragment, null, translated);
};
export var LabelOnFieldValue = function () {
    var valueState = ouiBase.Form.useFormData();
    var personType = useHookstate(valueState.onboarding.personType);
    return (React.createElement(Label, { personType: personType.get() }));
};
