import { AccountType, dataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import ouiBase from '@goldwasserexchange/oui';
import ouiStyle from '@goldwasserexchange/oui-style';
import ouiDom from '@goldwasserexchange/oui-dom';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import {
  globalPlusIntro,
} from '../../../globalPlus';
import {
  goldIntro,
} from '../../../gold';
import {
  premiumIntro,
} from '../../../premium';
import {
  ServiceSection,
} from '../../../serviceSection';
import { AccountTypePrimaryTitleCellContainer } from '../shared';
import { OrientationInformation } from '../../../../TextIndex/OrientationInformation';
import { useOuiStyleMediaContextByIndex } from '../../../../MediaContext';

const AccountTypeSelectionLabelOnPrimaryDimension = () => {
  const accountType = ouiBase.utils.dimensions.useCurrentPrimaryDimension() as dataStructure.accountType.listType;
  const list = ouiBase.utils.dimensions.usePrimaryDimensionList();
  const index = list.indexOf(accountType);
  const upMq = useOuiStyleMediaContextByIndex(2);
  return (
    <ServiceSection
      accountType={accountType}
      index={index}
      illustrationPosition={upMq ? 'before' : 'after'}
      introFirst={upMq}
    >
      <TopModel
        name="HeadingSectionTitleContainer-2"
        color={ouiStyle.Constants.Colors.accent}
        textAlign={['center', undefined, 'left', undefined, undefined, undefined]}
      >
        {ouiDom.HeadingSection.DomH2}
      </TopModel>
      <TopModel
        name="HeadingSectionIntroContainer-2"
        color={ouiStyle.Constants.Colors.accent}
        textAlign={['center', undefined, 'left', undefined, undefined, undefined]}
        minLines={upMq ? 3 : undefined}
        paddingBottom={['none', undefined, 'simple', undefined, undefined, undefined]}
      >
        {ouiDom.P.DomPContainer}
      </TopModel>
      <TopModel<{
        textAlign: ouiStyle.InStyleWithMediaQueries['textAlign'],
      }>
        name="HeadingSectionIllustrationContainer"
        textAlign={['center', undefined, 'left', undefined, undefined, undefined]}
      >
        {ouiDom.HeadingSection.DomIllustrationContainer}
      </TopModel>
      <TopModel name={`HeadingSectionIntroContent-${AccountType.RTO}-section`}>
        {goldIntro}
      </TopModel>
      <TopModel name={`HeadingSectionIntroContent-${AccountType.CONSEIL}-section`}>
        {premiumIntro}
      </TopModel>
      <TopModel name={`HeadingSectionIntroContent-${AccountType.GESTION}-section`}>
        {globalPlusIntro}
      </TopModel>
    </ServiceSection>
  );
};

export const accountTypeSelectionTablePrimaryDimensionTopModels = (
  <>
    <TopModel
      name="TablePrimaryTitleCellContainer"
      verticalAlign="top"
      visibleDownM
      paddingTop={['none', undefined, 'simple', undefined, undefined, undefined]}
      paddingBottom={['none', undefined, 'simple', undefined, undefined, undefined]}
      borderBottomWidth={['none', undefined, 'double', undefined, undefined, undefined]}
      display={[ouiStyle.Constants.DisplayProp.block, undefined, ouiStyle.Constants.DisplayProp.tableCell, undefined, undefined, undefined]}
    >
      {AccountTypePrimaryTitleCellContainer}
    </TopModel>
    <TopModel name="TablePrimaryTitleCellContent">
      {AccountTypeSelectionLabelOnPrimaryDimension}
    </TopModel>
    <TopModel
      name="TableHeaderHorizontalWrapperCornerTitleContainer"
      display={[ouiStyle.Constants.DisplayProp.block, undefined, ouiStyle.Constants.DisplayProp.tableCell, undefined, undefined, undefined]}
      verticalAlign="top"
    >
      {ouiDom.Table.TableHeaderHeading}
    </TopModel>
    <TopModel name="TableHeaderHorizontalWrapperCornerTitleContent">
      {OrientationInformation}
    </TopModel>
  </>
);

export const upSmAccountTypeSelectionTablePrimaryDimensionTopModels = (
  <TopModel
    name="TablePrimaryTitleCellContainer-index-last"
    verticalAlign="top"
    borderRightWidth="none"
  >
    {ouiDom.Table.TableHeaderHeading}
  </TopModel>
);
