import { Trans } from '@lingui/macro';
import * as React from 'react';
import { usePersonAccountsGesTypeShortcut } from '../../hooks';
import { ActorApiStringDisplay } from '../../../../../../components/display/string';

export const PersonsAccountsGesTypeShortcut = () => {
  const {
    data,
    isLoading,
    error,
  } = usePersonAccountsGesTypeShortcut();
  return (
    <Trans>
      Compte&#32;
      <ActorApiStringDisplay
        data={data}
        isLoading={isLoading}
        error={error}
        loadingValue="----"
      />
    </Trans>
  );
};
