import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import ouiBase from '@goldwasserexchange/oui';
import {
  DefaultUnorderedListContainer,
  DefaultUnorderedListWithNoMarkContainer,
  DefaultUnorderedListElementContainer,
  DefaultUnorderedListElementWithNoMarkContainer,
} from './components';

export const unorderedListDefaultTopModelsObject = {
  ...ouiBase.List.listDefaultTopModelsObject,
  ListContainer: (
    <TopModel name="ListContainer">{DefaultUnorderedListContainer}</TopModel>
  ),
  ListElementContainer: (
    <TopModel name="ListElementContainer">
      {DefaultUnorderedListElementContainer}
    </TopModel>
  ),
};

export const unorderedListWithNoMarkDefaultTopModelsObject = {
  ...ouiBase.List.listDefaultTopModelsObject,
  ListContainer: (
    <TopModel name="ListContainer">
      {DefaultUnorderedListWithNoMarkContainer}
    </TopModel>
  ),
  ListElementContainer: (
    <TopModel name="ListElementContainer">
      {DefaultUnorderedListElementWithNoMarkContainer}
    </TopModel>
  ),
};
