import * as React from 'react';
import ouiDom from '@goldwasserexchange/oui-dom';
import ouiStyle from '@goldwasserexchange/oui-style';
import {
  getImageDimensions,
  useImageProps,
} from '../../sanity/utils/image';
import { GESanityImage } from '../../sanity/queries/shared';

export const SanityImage = (props: {
  image?: GESanityImage,
  alt?: string,
  defaultAspectRatio?: number,
  maxPotentialWidth: number,
  containerMarginVertical?: ouiStyle.InStyle['marginVertical'],
  containerMarginTop?: ouiStyle.InStyle['marginTop'],
  containerMarginBottom?: ouiStyle.InStyle['marginBottom'],
  forcedRatio?: number,
  objectFit?: ouiStyle.InStyleWithMediaQueries['objectFit'],
  lines?: ouiStyle.InStyleWithMediaQueries['lines'],
}) => {
  const {
    image,
    alt,
    defaultAspectRatio = 16 / 9,
    maxPotentialWidth,
    containerMarginVertical,
    containerMarginTop,
    containerMarginBottom,
    forcedRatio,
    objectFit,
    lines,
  } = props;
  const defaultAspectRatioObj = {
    aspectRatio: defaultAspectRatio,
  };
  const {
    aspectRatio,
  } = (image != null ? getImageDimensions(image) : defaultAspectRatioObj) ?? defaultAspectRatioObj;
  const {
    src,
    srcSet,
    sizes,
    width,
    height,
    lqip,
  } = useImageProps({ image, width: maxPotentialWidth, forcedRatio }) ?? {};
  return (
    <ouiDom.RatioImage.RatioImage
      src={src}
      srcSet={srcSet}
      sizes={sizes}
      width={width}
      height={height}
      alt={alt}
      aspectRatio={forcedRatio ?? aspectRatio}
      lqip={lqip}
      loading="lazy"
      decoding="async"
      containerMarginVertical={containerMarginVertical}
      containerMarginTop={containerMarginTop}
      containerMarginBottom={containerMarginBottom}
      objectFit={objectFit}
      lines={lines}
    />
  );
};
