import * as React from 'react';
import ouiBase from '@goldwasserexchange/oui';
import ouiStyle from '@goldwasserexchange/oui-style';

export const defaultListInputListElementContainerStyle: ouiStyle.InStyle = {
  display: ouiStyle.Constants.DisplayProp.flex,
  ...ouiBase.List.defaultListFontSize,
  childPaddingBottom: 'simple',
  childPaddingLeft: 'simple',
  childPaddingRight: 'simple',
  childPaddingTop: 'simple',
  alignItems: ouiStyle.Constants.AlignItemsProp.stretch,
};

export function useMakeDefaultListInputListElementContainerCss() {
  const lines = ouiBase.List.useListInputElementLines();
  const childPaddings = ouiBase.List.useChildPaddings();
  const css = React.useMemo(() => ouiStyle.mediaQuery(ouiStyle.makeCss({
    ...defaultListInputListElementContainerStyle,
    ...childPaddings,
    lines,
  })), [lines, childPaddings]);
  return css;
}
