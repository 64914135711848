import makeWatchMediaQuery from './watchMediaQuery';
import makeWatchScroll from './watchScroll';
import closing from './closing';
import opening from './opening';
import collapsing from './collapsing';
import uncollapsing from './uncollapsing';
import animateMqDown from './animateMqDown';
import animateMqUp from './animateMqUp';
import animateLogoExpanding from './animateLogoExpanding';
import animateLogoShrinking from './animateLogoShrinking';
import { AppBarSpringRef } from '../../../animation/context/context';

export * as watchMediaQuery from './watchMediaQuery';
export * as watchScroll from './watchScroll';
export * as closing from './closing';
export * as opening from './opening';
export * as collapsing from './collapsing';
export * as uncollapsing from './uncollapsing';
export * as animateMqDown from './animateMqDown';
export * as animateMqUp from './animateMqUp';
export * as animateLogoExpanding from './animateLogoExpanding';
export * as animateLogoShrinking from './animateLogoShrinking';

export const makeServices = (matcher: MediaQueryList, springApi: AppBarSpringRef) => ({
  ...makeWatchMediaQuery(matcher),
  ...makeWatchScroll(),
  ...closing(springApi),
  ...opening(springApi),
  ...collapsing(springApi),
  ...uncollapsing(springApi),
  ...animateMqDown(springApi),
  ...animateMqUp(springApi),
  ...animateLogoExpanding(springApi),
  ...animateLogoShrinking(springApi),
});

export default makeServices;
