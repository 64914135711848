import { defaultApiCurrencySorter } from '../../../../../../../Components/Sections/online/utils/currencySorter';
import { AccountPosition } from '../../type';
import { filterPositionLiquidities } from '../../utils';
import { useAccountPositionsTransformApi } from '../api';

const extractCurrencyCodes = (d: AccountPosition[] | undefined) => {
  const currencyCodes = (d ?? []).map((line) => line['C_CURRENCY.CODE']);
  return currencyCodes;
};

export const useAccountPositionsLiquiditiesCCurrencyCodes = () => {
  const liquiditiesCurrencyCodesApiResponse = useAccountPositionsTransformApi({
    transformFunction: extractCurrencyCodes,
    sortPredicate: defaultApiCurrencySorter,
    filterPredicate: filterPositionLiquidities,
  });
  return liquiditiesCurrencyCodesApiResponse;
};
