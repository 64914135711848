import { SimulationBuyOrSellCostData } from '../type';
import { getExchangeRate } from './getExchangeRate';

export type ComputeGrossAmountProps = {
  quantity: SimulationBuyOrSellCostData['quantity'],
  price: SimulationBuyOrSellCostData['price'],
  type: SimulationBuyOrSellCostData['type'],
};

const computeGrossAmount = (props: ComputeGrossAmountProps) => {
  const {
    quantity,
    price,
    type,
  } = props;
  return (quantity * price) / (type === 'stocks' ? 1 : 100);
};

export const computeGrossAmountInEur = (props: SimulationBuyOrSellCostData) => {
  const {
    quantity,
    price,
    type,
    currency = 'EUR',
  } = props;
  const exchangeRate = getExchangeRate({
    currency,
  });
  const grossAmount = computeGrossAmount({
    quantity,
    price,
    type,
  });
  return grossAmount / exchangeRate;
};

export type GetGrossAmountProps = {
  grossAmountInEur: number,
}

export type ComputeOrGetGrossAmountProps = SimulationBuyOrSellCostData | GetGrossAmountProps;

export const computeOrGetGrossAmount = (props: ComputeOrGetGrossAmountProps) => {
  if ('grossAmountInEur' in props) {
    const {
      grossAmountInEur,
    } = props;
    return grossAmountInEur;
  }
  const {
    quantity,
    price,
    type,
    currency,
  } = props;
  const grossAmountInEur = computeGrossAmountInEur({
    quantity,
    price,
    type,
    currency,
  });
  return grossAmountInEur;
};
