import { useFilterByTAccId } from '../../utils/tAcc/filterByTAccId';
import { usePersonAccountFieldApi } from '../api';

export const usePersonAccountsGesTypeShortcut = () => {
  const tAccFilterById = useFilterByTAccId();
  const personAccountsCCurrencyTAccCodeApiResponse = usePersonAccountFieldApi({
    field: 'GES_TYPE.SHORTCUT',
    filterPredicate: tAccFilterById,
  });
  return personAccountsCCurrencyTAccCodeApiResponse;
};
