import { AccountCashflow } from '../type';

export const computeRefundValue = (qt: number, oblEndPrice: number) => (qt * oblEndPrice) / 100;

export const computeRefundValueFromLine = (line: AccountCashflow | undefined | null) => {
  if (line && line['S_ACC.OBL_END_PRICE']) {
    const qt = line['GES_ESTIM_D.QT'];
    const oblEndPrice = line['S_ACC.OBL_END_PRICE'];
    return computeRefundValue(qt, oblEndPrice);
  }
  return 0;
};

export const computeRefundValueFromLineInAccountCurrency = (line: AccountCashflow | undefined | null) => {
  if (line) {
    const value = computeRefundValueFromLine(line);
    const exRateGes = line['EXRATE_HIS.EXRATE_GES'];
    const valueInAccountCurrency = value * (1 / exRateGes);
    return valueInAccountCurrency;
  }
  return 0;
};
