import { dataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import { useQuestionnaireSelectedDisabledReasonByProductNames } from './bySelected';
import { useQuestionnaireServiceSelectionDisabledReasonByProductNames } from './byService';
import { useQuestionnaireStatusDisabledReasonByProductNames } from './byStatus';

export const useQuestionnaireDisabledReasonByProductName = (productName: dataStructure.financialProducts.listType) => {
  const disabledReasonFromStatuses = useQuestionnaireStatusDisabledReasonByProductNames(productName);
  const disabledReasonFromSelected = useQuestionnaireSelectedDisabledReasonByProductNames(productName);
  const disabledReasonFromServiceSelection = useQuestionnaireServiceSelectionDisabledReasonByProductNames(productName, ['NotRequired']);
  const disabledReasons = [disabledReasonFromStatuses, disabledReasonFromSelected, disabledReasonFromServiceSelection];
  const firstDisabledReason = disabledReasons.find((disabledReason) => disabledReason !== '');
  return firstDisabledReason ?? '';
};
