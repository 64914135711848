import {
  createContext,
  Dispatch,
  SetStateAction,
} from 'react';

export const initialInvalidBondIds: Set<number> = new Set();

export const bondInvalidSAccIdsContext = createContext<[Set<number>, Dispatch<SetStateAction<Set<number>>>]>([
  initialInvalidBondIds,
  () => {
    throw new Error('No provider found for bondValidSAccIdsContext');
  },
]);
