import { requiredError, multiChoiceAtLeastOne } from '../errornames';

export function makeRequired(validator) {
  const required = validator.required(requiredError);
  return required;
}

export const makeAtLeastOneTrue = (validator) => validator.test(multiChoiceAtLeastOne, multiChoiceAtLeastOne, (context) => {
  if (typeof context === 'object') {
    return Object.values(context).some((v) => v === true);
  }
  return false;
});
