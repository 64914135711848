export var initialRTOValues = function () { return ({
    RTOPerpetualBondQuestion0Answer: undefined,
    RTOPerpetualBondQuestion0RemedialAnswer: undefined,
    RTOPerpetualBondQuestion0IsCorrect: undefined,
    RTOPerpetualBondQuestion0RemedialIsCorrect: undefined,
    RTOPerpetualBondQuestion0WasRetried: false,
    RTOPerpetualBondQuestion0IsCorrected: false,
    RTOPerpetualBondQuestion0RemedialIsCorrected: false,
    RTOPerpetualBondQuestion1Answer: undefined,
    RTOPerpetualBondQuestion1RemedialAnswer: undefined,
    RTOPerpetualBondQuestion1IsCorrect: undefined,
    RTOPerpetualBondQuestion1RemedialIsCorrect: undefined,
    RTOPerpetualBondQuestion1WasRetried: false,
    RTOPerpetualBondQuestion1IsCorrected: false,
    RTOPerpetualBondQuestion1RemedialIsCorrected: false,
    RTOPerpetualBondQuestion2Answer: undefined,
    RTOPerpetualBondQuestion2RemedialAnswer: undefined,
    RTOPerpetualBondQuestion2IsCorrect: undefined,
    RTOPerpetualBondQuestion2RemedialIsCorrect: undefined,
    RTOPerpetualBondQuestion2WasRetried: false,
    RTOPerpetualBondQuestion2IsCorrected: false,
    RTOPerpetualBondQuestion2RemedialIsCorrected: false,
}); };
export var initialConseilGestionValues = function () { return ({
    perpetualBondCallAnswer: undefined,
    perpetualBondCallIsCorrect: undefined,
    perpetualBondCallWasRetried: false,
    perpetualBondCallIsCorrected: false,
    perpetualBondDefinitionAnswer: undefined,
    perpetualBondDefinitionIsCorrect: undefined,
    perpetualBondDefinitionWasRetried: false,
    perpetualBondDefinitionIsCorrected: false,
    perpetualBondVariationAnswer: undefined,
    perpetualBondVariationIsCorrect: undefined,
    perpetualBondVariationWasRetried: false,
    perpetualBondVariationIsCorrected: false,
}); };
