import { useContext, useMemo } from 'react';
import { usePrimaryDimensionDirection } from '../primaryDimension/direction/hooks';
import { useCurrentRenderDirection } from './current';
import { dimensionCurrentContextByDirectionMap, dimensionCurrentIndexContextByDirectionMap, dimensionLengthContextByDirectionMap, dimensionListContextByDirectionMap, } from './data';
export var useDimensionListByDirection = function (direction) {
    var currentDirection = usePrimaryDimensionDirection();
    var dimension = useContext(dimensionListContextByDirectionMap[currentDirection][direction]);
    return dimension;
};
export var useDimensionListByCurrentRenderDirection = function () {
    var currentRenderDirection = useCurrentRenderDirection();
    return useDimensionListByDirection(currentRenderDirection);
};
export var useDimensionLengthByDirection = function (direction) {
    var currentDirection = usePrimaryDimensionDirection();
    var dimension = useContext(dimensionLengthContextByDirectionMap[currentDirection][direction]);
    return dimension;
};
export var useDimensionLengthByCurrentRenderDirection = function () {
    var currentRenderDirection = useCurrentRenderDirection();
    return useDimensionLengthByDirection(currentRenderDirection);
};
export var useCurrentDimensionValueByDirection = function (renderDirection) {
    var currentDirection = usePrimaryDimensionDirection();
    var dimension = useContext(dimensionCurrentContextByDirectionMap[currentDirection][renderDirection]);
    return dimension;
};
export var useCurrentDimensionValueByPrimaryAndRenderDirection = function () {
    var renderDirection = useCurrentRenderDirection();
    return useCurrentDimensionValueByDirection(renderDirection);
};
export var useCurrentDimensionIndexByDirection = function (renderDirection) {
    var currentDirection = usePrimaryDimensionDirection();
    var dimension = useContext(dimensionCurrentIndexContextByDirectionMap[currentDirection][renderDirection]);
    return dimension;
};
export var useCurrentDimensionIndexByPrimaryAndRenderDirection = function () {
    var renderDirection = useCurrentRenderDirection();
    return useCurrentDimensionIndexByDirection(renderDirection);
};
export var useCurrentDimensionIsLastByDirection = function (renderDirection) {
    var index = useCurrentDimensionIndexByDirection(renderDirection);
    var length = useDimensionLengthByDirection(renderDirection);
    return index === length - 1;
};
export function useIsLastCurrentDimensionVariantByDirection(renderDirection) {
    var isLast = useCurrentDimensionIsLastByDirection(renderDirection);
    return isLast ? 'last' : 'notLast';
}
export function useIsLastCurrentDimensionVariantByDirectionAndRenderDirection() {
    var currentRenderDirection = useCurrentRenderDirection();
    var variant = useIsLastCurrentDimensionVariantByDirection(currentRenderDirection);
    return variant;
}
export var useCurrentDimensionListElementByIndexAndDirection = function (index, renderDirection) {
    var listData = useDimensionListByDirection(renderDirection);
    var memoizedIndex = useMemo(function () { return listData[index]; }, [listData, index]);
    return memoizedIndex !== null && memoizedIndex !== void 0 ? memoizedIndex : {};
};
export var useCurrentCurrentDimensionValueByIndexAndDirection = function (renderDirection) {
    var index = useCurrentDimensionIndexByDirection(renderDirection);
    var data = useCurrentDimensionListElementByIndexAndDirection(index, renderDirection);
    return data;
};
