import * as React from 'react';
import { ActorApiTable, ActorApiTableProps } from '../../../../../../components';
import { useSTypeGrpId } from '../../../../../../../Components/Sections/online/sections/details/positionDetails/sTypeGroupTable/context/sTypeGrpId';
import { usePositionsInSubGroupList } from '../../hooks/shared';
import { makeLoadingIds } from '../../../../shared/utils';
import { STypeGrpIds } from '../../../../../shared/utils/sTypeGrpIds';

const loadingValues = {
  [STypeGrpIds.CASH]: makeLoadingIds(2, 1),
  [STypeGrpIds.ACTIONS]: makeLoadingIds(10, 3),
  [STypeGrpIds.OBLIGATIONS]: makeLoadingIds(10, 13),
};

const defaultLoadingValues = makeLoadingIds(10, 23);

const InnerPositionsSubGroupTable = (props: React.PropsWithChildren<Pick<ActorApiTableProps, 'direction' | 'wrapping' | 'multiBody' | 'secondaryDimensionList'> & {
  sTypeGrpId: string,
}>) => {
  const {
    direction,
    wrapping,
    multiBody,
    secondaryDimensionList,
    sTypeGrpId,
    children,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = usePositionsInSubGroupList();
  return sTypeGrpId !== null ? (
    <ActorApiTable
      data={data}
      isLoading={isLoading}
      error={error}
      loadingValue={loadingValues[sTypeGrpId] ?? defaultLoadingValues}
      direction={direction}
      wrapping={wrapping}
      multiBody={multiBody}
      secondaryDimensionList={secondaryDimensionList}
    >
      {children}
    </ActorApiTable>
  ) : null;
};

export const PositionsSubGroupTable = (props: React.PropsWithChildren<Pick<ActorApiTableProps, 'direction' | 'wrapping' | 'multiBody' | 'secondaryDimensionList'>>) => {
  const {
    direction,
    wrapping,
    multiBody,
    secondaryDimensionList,
    children,
  } = props;
  const sTypeGrpId = useSTypeGrpId();
  if (sTypeGrpId === null) {
    return null;
  }
  return (
    <InnerPositionsSubGroupTable
      direction={direction}
      wrapping={wrapping}
      multiBody={multiBody}
      secondaryDimensionList={secondaryDimensionList}
      sTypeGrpId={sTypeGrpId}
    >
      {children}
    </InnerPositionsSubGroupTable>
  );
};
