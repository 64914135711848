/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { SuccessState } from './type';

const cardContainerStyle: ouiStyle.InStyle = {
  borderStyle: 'solid',
  borderWidth: 'simple',
  paddingTop: 'none',
  paddingLeft: 'simple',
  paddingRight: 'simple',
  paddingBottom: 'simple',
  marginTop: 'simple',
  marginRight: 'simple',
};

const useCardContainerStyle = (successState?: SuccessState) => {
  const css = React.useMemo(
    () => {
      switch (successState) {
        case 'success':
          return ouiStyle.makeCss({
            ...cardContainerStyle,
            borderColor: ouiStyle.Constants.Colors.success,
          });
        case 'warning':
          return ouiStyle.makeCss({
            ...cardContainerStyle,
            borderColor: ouiStyle.Constants.Colors.warning,
          });
        case 'error':
          return ouiStyle.makeCss({
            ...cardContainerStyle,
            borderColor: ouiStyle.Constants.Colors.danger,
          });
        default:
          return ouiStyle.makeCss(cardContainerStyle);
      }
    },
    [successState],
  );
  return css;
};

const CardContainer = (props: React.PropsWithChildren<{
  innerRef?: React.Ref<HTMLDivElement>,
  successState?: SuccessState,
}>) => {
  const {
    innerRef,
    successState,
    children,
  } = props;
  const cardContainerCss = useCardContainerStyle(successState);
  return (
    <div
      ref={innerRef}
      css={cardContainerCss}
    >
      {children}
    </div>
  );
};

export const CardContainerWithRef = React.forwardRef<
HTMLDivElement,
React.PropsWithChildren<{
  successState?: SuccessState,
}>
>((props, ref) => {
  const {
    successState,
    children,
  } = props;
  return (
    <CardContainer
      innerRef={ref}
      successState={successState}
    >
      {children}
    </CardContainer>
  );
});
