import * as React from 'react';
import { UncastedDataStructure, OrientationThreeYearsInvestmentPlan } from '@goldwasserexchange/oblis-frontend-utils';
import { Trans } from '@lingui/macro';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import ouiBase from '@goldwasserexchange/oui';
import { makeRequired, numberValidator } from '../../../../../../OUI/Inputs';
import { RadioListInput } from '../../../../../../OUI-dom/Inputs';
import { GetComponentKeyByElementPrimaryKey } from '../../utils/ElementLabel';
import { OnChangeNumberHandlerEnhanceContext } from '../../../../../../OUI';

export const threeYearInvestmentPlanValidator = makeRequired(numberValidator);

const threeYearInvestmenPlanData: Record<OrientationThreeYearsInvestmentPlan, { ID: OrientationThreeYearsInvestmentPlan, value: JSX.Element }> = {
  [OrientationThreeYearsInvestmentPlan.LESS_THAN_100K]: {
    value: <Trans>Moins de 100 000 €</Trans>,
    ID: OrientationThreeYearsInvestmentPlan.LESS_THAN_100K,
  },
  [OrientationThreeYearsInvestmentPlan.BETWEEN_100_AND_500K]: {
    value: <Trans>Entre 100 000 € et 500 000 €</Trans>,
    ID: OrientationThreeYearsInvestmentPlan.BETWEEN_100_AND_500K,
  },
  [OrientationThreeYearsInvestmentPlan.BETWEEN_500K_AND_1M]: {
    value: <Trans>Entre 500 000 € et 1 000 000 €</Trans>,
    ID: OrientationThreeYearsInvestmentPlan.BETWEEN_500K_AND_1M,
  },
  [OrientationThreeYearsInvestmentPlan.MORE_THAN_1M]: {
    value: <Trans>Plus de 1 000 000 €</Trans>,
    ID: OrientationThreeYearsInvestmentPlan.MORE_THAN_1M,
  },
};

const threeYearInvestmenPlanList = Object.keys(threeYearInvestmenPlanData);

const threeYearInvestmenPlanLabelText = (
  <TopModel name="RadioListInputLabelContent">
    <Trans>Quel montant avez-vous l'intention d'investir chez Goldwasser Exchange dans les trois prochaines années ?</Trans>
  </TopModel>
);

const ThreeYearInvestmentPlanElementLabel = (
  <TopModel name="RadioListInputListElementLabelContent" data={threeYearInvestmenPlanData} valueKey="value">
    {GetComponentKeyByElementPrimaryKey}
  </TopModel>
);

export const ThreeYearInvestmentPlanInput = (): JSX.Element => {
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  return (
    <RadioListInput
      fieldPath={valueState.onboarding.orientation.threeYearInvestmentPlan.path.join('.')}
      dependenciesFieldPaths={[valueState.onboarding.orientation.submitted.path.join('.')]}
      list={threeYearInvestmenPlanList}
    >
      {threeYearInvestmenPlanLabelText}
      {ThreeYearInvestmentPlanElementLabel}
      <TopModel name="RadioListInputListElementOnChangeContextProvider">
        {OnChangeNumberHandlerEnhanceContext}
      </TopModel>
    </RadioListInput>
  );
};
