import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { listElementChildPaddingByDirection } from './context';
export function ListElementChildPaddingDirectionProvider(props) {
    var direction = props.direction, _a = props.childPadding, childPadding = _a === void 0 ? ouiStyle.Constants.defaultPadding : _a, children = props.children;
    var context = listElementChildPaddingByDirection[direction];
    return (React.createElement(context.Provider, { value: childPadding }, children));
}
export function ListElementChildPaddingProvider(props) {
    var elementChildPaddingTop = props.elementChildPaddingTop, elementChildPaddingRight = props.elementChildPaddingRight, elementChildPaddingBottom = props.elementChildPaddingBottom, elementChildPaddingLeft = props.elementChildPaddingLeft, children = props.children;
    return (React.createElement(ListElementChildPaddingDirectionProvider, { direction: "top", childPadding: elementChildPaddingTop },
        React.createElement(ListElementChildPaddingDirectionProvider, { direction: "right", childPadding: elementChildPaddingRight },
            React.createElement(ListElementChildPaddingDirectionProvider, { direction: "bottom", childPadding: elementChildPaddingBottom },
                React.createElement(ListElementChildPaddingDirectionProvider, { direction: "left", childPadding: elementChildPaddingLeft }, children)))));
}
