import ouiStyle from '@goldwasserexchange/oui-style';

export const inputIconInnerContainerStyle: ouiStyle.InStyle = {
  lines: 1,
  widthLines: 1,
  borderColor: ouiStyle.Constants.Colors.inverted,
  borderWidth: 'none',
  borderStyle: 'none',
  padding: 'simple',
  iconContainer: true,
  baselinePadding: true,
  flex: 'none',
  position: 'absolute',
};

export const inputIconContainerStyle: ouiStyle.InStyle = {
  lines: 1,
  position: 'relative',
  widthLines: 1,
  ...ouiStyle.getChildPaddings(inputIconInnerContainerStyle),
};

export const inputIconContainerCss = ouiStyle.makeCss(inputIconContainerStyle);
