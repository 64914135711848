import { useFilterByTAccId } from '../../utils/tAcc/filterByTAccId';
import { PersonAccounts } from '../../type';
import { usePersonAccountTransformApi } from '../api';

const transferIsCorrectAccountType = (line: PersonAccounts | null | undefined) => {
  const v = line ? line['T_CAT.SHORTCUT'] : '';
  const isCorrectAccountType = (v === 'GOLD' || v === 'CONSEIL');
  return isCorrectAccountType;
};

export const useAccountTypeAllowTransfer = () => {
  const filterBySelectedTAccId = useFilterByTAccId();
  const accountTypeAllowTransferResult = usePersonAccountTransformApi({
    transformFunction: transferIsCorrectAccountType,
    filterPredicate: filterBySelectedTAccId,
  });
  return accountTypeAllowTransferResult;
};
