/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const inputBoxStyle: ouiStyle.InStyle = {
  display: ouiStyle.Constants.DisplayProp.flex,
};

const inputBoxCss = ouiStyle.makeCss(inputBoxStyle);

export const InputBox = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  return (
    <div
      css={inputBoxCss}
    >
      {children}
    </div>
  );
};
