import * as React from 'react';
import { Trans } from '@lingui/macro';
import { UncastedDataStructure, PersonType } from '@goldwasserexchange/oblis-frontend-utils';
import ouiBase from '@goldwasserexchange/oui';
import { useHookstate } from '@hookstate/core';

const financialSituationQuestionnaireByPersonTypeIntros: Record<PersonType, React.ReactNode> = {
  [PersonType.INDIVIDUAL]: (
    <p>
      <Trans>
        Le répondant s'engage à répondre aux questions ci-dessous en tenant compte de la situation financière personnelle de la personne concernée
      </Trans>
    </p>
  ),
  [PersonType.JOINT]: (
    <p>
      <Trans>
        Le répondant s'engage à répondre aux questions ci-dessous en tenant compte de la situation financière globale du couple
      </Trans>
    </p>
  ),
  [PersonType.INDIVISION]: (
    <p>
      <Trans>
        Le répondant s'engage à répondre aux questions ci-dessous par référence au profil financier de la personne concernée dont la situation financière est la plus fragile
      </Trans>
    </p>
  ),
  [PersonType.DIVIDED]: (
    <p>
      <Trans>
        Le répondant s'engage à répondre aux questions ci-dessous par référence au profil financier du nu-propriétaire ou de celui des nus-propriétaires dont la situation financière est la plus fragile
      </Trans>
    </p>
  ),
  [PersonType.FOR_MINORS]: (
    <p>
      <Trans>
        Le répondant s'engage à répondre aux questions ci-dessous en tenant compte de la situation financière des personnes qu'il représente.
      </Trans>
    </p>
  ),
  [PersonType.MORAL]: (
    <p>
      <Trans>
        Le répondant s'engage à répondre aux question ci-dessous par référence à la situation financière de la Société concernée.
      </Trans>
    </p>
  ),
};

const FinancialSituationQuestionnairePersonTypeIntro = (): JSX.Element | null => {
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const personType = useHookstate(valueState.onboarding.personType).get();
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return personType ? <>{financialSituationQuestionnaireByPersonTypeIntros[personType]}</> : null;
};

export const FinancialQuestionnaireIntro = (): JSX.Element => (
  <>
    <p>
      <Trans>
        Les informations récoltées sous cette rubrique seront utilisées aux seules fins de permettre à Goldwasser Exchange de vérifier que les transactions que vous envisagerez d'effectuer sont bien adéquates au regard de votre situation financière. Il s'agira essentiellement de vérifier que vos transactions ne remettent pas en cause vos moyens de subsistance tant sur le court que sur le long terme.
      </Trans>
    </p>
    <FinancialSituationQuestionnairePersonTypeIntro />
  </>
);
