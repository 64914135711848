import * as React from 'react';
import { Trans } from '@lingui/macro';
import ouiBase from '@goldwasserexchange/oui';
import { useInverted } from '../../../context';

export const YesNoElementLabelContent = () => {
  const inverted = useInverted();
  const primaryKey = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  if ((!inverted && primaryKey === '1') || (inverted && primaryKey === '0')) {
    return <Trans>Oui</Trans>;
  }
  if ((!inverted && primaryKey === '0') || (inverted && primaryKey === '1')) {
    return <Trans>Non</Trans>;
  }
  return null;
};
