/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';

const style: ouiStyle.InStyle = {
  lineFactor: 0.8,
};

const css = ouiStyle.makeCss(style);

export const SanitySup = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  return (
    <sup
      css={css}
    >
      {children}
    </sup>
  );
};
