import * as React from 'react';
import { GetDocumentsParams } from '@goldwasserexchange/actor/rest-services';
import { useDocumentGetS3DefaultParam } from '../hooks';
import { documentGetS3ParamsContext } from '../context';

export const DocumentGetS3ParamsProvider = (props: React.PropsWithChildren<{
  params: Partial<GetDocumentsParams>,
}>) => {
  const {
    params,
    children,
  } = props;
  const value = useDocumentGetS3DefaultParam(params);
  return (
    <documentGetS3ParamsContext.Provider
      value={value}
    >
      {children}
    </documentGetS3ParamsContext.Provider>
  );
};
