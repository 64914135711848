import * as React from 'react';
import { useAccountPositionGesEstimDPcPortInt, useAccountPositionGesEstimDPcPortIntBySTypeGrp } from '../../hooks';
import { ActorApiNumberDisplay } from '../../../../../../components/display/number/component';

export const AccountsPositionsGesEstimDPcPortInt = () => {
  const {
    data,
    isLoading,
    error,
  } = useAccountPositionGesEstimDPcPortInt();
  return (
    <ActorApiNumberDisplay
      data={data}
      isLoading={isLoading}
      error={error}
      format={{
        style: 'percent',
      }}
    />
  );
};

export const AccountsPositionsGesEstimDPcPortIntTotalBySTypeGrp = () => {
  const {
    data,
    isLoading,
    error,
  } = useAccountPositionGesEstimDPcPortIntBySTypeGrp();
  return (
    <ActorApiNumberDisplay
      data={data}
      isLoading={isLoading}
      error={error}
      format={{
        style: 'percent',
      }}
    />
  );
};
