import * as preferences from '../preferences';
import * as taxonomy from '../taxonomy';
import * as taxonomyAlignment from '../taxonomyAlignment';
import * as sfdr from '../sfdr';
import * as sfdrDurableProportion from '../sfdrDurableProportion';
import * as sfdrDurableOnly from '../sfdrDurableOnly';
import * as sfdrSocialEnvironmentalOnly from '../sfdrSocialEnvironmentalOnly';
import * as sfdrSocialEnvironmentalProportion from '../sfdrSocialEnvironmentalProportion';
import * as incidence from '../incidence';
import * as taxonomyProportion from '../taxonomyProportion';
import * as incidenceType from '../incidenceType';
export var map = {
    preferences: preferences.logic.label.logic,
    taxonomy: taxonomy.logic.label.logic,
    taxonomyAlignment: taxonomyAlignment.logic.label.logic,
    taxonomyProportion: taxonomyProportion.logic.label.logic,
    sfdr: sfdr.logic.label.logic,
    sfdrDurableProportion: sfdrDurableProportion.logic.label.logic,
    sfdrSocialEnvironmentalProportion: sfdrSocialEnvironmentalProportion.logic.label.logic,
    sfdrDurableOnly: sfdrDurableOnly.logic.label.logic,
    sfdrSocialEnvironmentalOnly: sfdrSocialEnvironmentalOnly.logic.label.logic,
    incidence: incidence.logic.label.logic,
    incidenceType: incidenceType.logic.label.logic,
    incidenceProportion: incidenceType.logic.label.logic,
};
