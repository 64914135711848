import * as React from 'react';
import { RampWithNameTransform } from '@goldwasserexchange/react-topmodel';
import { DefaultListInputElementContextContainer } from '../../../../shared';
import {
  FieldPathProvider,
  FieldErrorsContextProvider,
  TouchedContext,
} from '../../../../../context';
import { useExecuteElementFieldPathHook } from '../../../context';

export function DefaultCheckboxListInputElementFieldPathProvider(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  const fieldPath = useExecuteElementFieldPathHook();
  return (
    <FieldPathProvider fieldPath={fieldPath}>{children}</FieldPathProvider>
  );
}
export function DefaultCheckboxListInputElementContextContainer(
  props: React.PropsWithChildren<{ index: number }>,
) {
  const { index, children } = props;
  return (
    <DefaultListInputElementContextContainer index={index}>
      <DefaultCheckboxListInputElementFieldPathProvider>
        <FieldErrorsContextProvider>
          <TouchedContext>
            <RampWithNameTransform rampName="ElementOnChangeContextProvider">
              {children}
            </RampWithNameTransform>
          </TouchedContext>
        </FieldErrorsContextProvider>
      </DefaultCheckboxListInputElementFieldPathProvider>
    </DefaultListInputElementContextContainer>
  );
}
