import * as React from 'react';
import { MachineContextProvider } from '../machine';
import { AnimationControllerProvider } from '../animation';

export const AppBarProvider = (
  props: React.PropsWithChildren<{}>,
) => {
  const {
    children,
  } = props;
  return (
    <AnimationControllerProvider>
      <MachineContextProvider>
        {children}
      </MachineContextProvider>
    </AnimationControllerProvider>
  );
};
