import { GetBondsFavoritesResponse, GetBondsListResponse } from '@goldwasserexchange/actor/rest-services';
import { useCallback } from 'react';
import { useCognitoUserValidAccounts } from '../../../../../../../Components/Sections/online/selectedAccount';
import { BondList } from '../../type';
import {
  useBondListFieldApi,
  useBondListFieldTransformApi,
} from '../api';

export const useBondsListRedacted = () => {
  const response = useBondListFieldApi({
    field: 'redacted',
  });
  return response;
};

const defaultRedacted = [
  'S_ACC.ISIN',
  'S_QUOTE.PRICE_CLOSE',
  'S_QUOTE.PRICE_MAX',
  'S_MARKET.YIELD',
  'S_QUOTE.ARCHIVE_DATE',
  'GES_RAT_VAL_SP.SHORTCUT',
  'CPS_HIS.ACCRUED',
];

const makeIsRedacted = (props: {
  field: ((keyof GetBondsListResponse) | (keyof GetBondsFavoritesResponse) | 'CPS_HIS.ACCRUED'),
  connected: boolean,
}) => (redacted: BondList['redacted'] | undefined) => {
  const {
    field,
    connected,
  } = props;
  if (connected) {
    return false;
  }
  if (!redacted) {
    return defaultRedacted.includes(field);
  }
  return redacted.includes(field) || field === 'CPS_HIS.ACCRUED';
};

export const useBondsListIsRedacted = (field: ((keyof GetBondsListResponse) | (keyof GetBondsFavoritesResponse) | 'CPS_HIS.ACCRUED')) => {
  const accounts = useCognitoUserValidAccounts();
  const transformFunction = useCallback(makeIsRedacted({
    field,
    connected: accounts.length > 0,
  }), [field, accounts.length]);
  const response = useBondListFieldTransformApi({
    field: 'redacted',
    transformFunction,
  });
  return response;
};
