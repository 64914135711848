import ouiBase from '@goldwasserexchange/oui';
import { useFieldContent } from '../content';
import { useInputType } from '../inputType';

export function useChecked(): boolean | undefined {
  const inputType = useInputType();
  const primaryKey = ouiBase.utils.dimensions.useCurrentPrimaryDimensionValueByIndex();
  const fieldContent = useFieldContent();
  if (inputType === 'radio') {
    return `${fieldContent.get()}` === `${primaryKey}`;
  }
  if (inputType === 'checkbox') {
    return fieldContent.get() === true;
  }
  return undefined;
}

export type CheckedVariantNames = 'checked' | 'unChecked';

export function useCheckedVariant(): CheckedVariantNames {
  const isChecked = useChecked();
  return isChecked ? 'checked' : 'unChecked';
}
