import { useCallback } from 'react';
import ouiBase from '@goldwasserexchange/oui';
import { useInputContainerOnOutsideClick } from '../../../../context/inputRef';

export const useCloseOnClickOutside = () => {
  const [isOpen, setOpen] = ouiBase.List.useSelectOpenStateHandlers();
  const close = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  useInputContainerOnOutsideClick(isOpen ? close : undefined);
};
