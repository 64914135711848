import * as React from 'react';
import { IconContainer } from '../container';

export const NewsIcon = (props: {
  style?: React.SVGAttributes<SVGSVGElement>['style'],
}) => {
  const {
    style,
  } = props;
  return (
    <IconContainer
      style={style}
    >
      <path d="M62.5,69.4H12.5c-5.5,0-9.9-4.4-9.9-9.9v-14.4c0-5.5,4.4-9.9,9.9-9.9h4.9V8.1c0-1.4,1.1-2.5,2.5-2.5h50c1.4,0,2.5,1.1,2.5,2.5v51.4c0,5.5-4.4,9.9-9.9,9.9ZM21.1,64.4h41.4c2.7,0,4.9-2.2,4.9-4.9V10.6H22.4v48.9c0,1.8-.5,3.5-1.3,4.9ZM12.5,40.2c-2.7,0-4.9,2.2-4.9,4.9v14.4c0,2.7,2.2,4.9,4.9,4.9s4.9-2.2,4.9-4.9v-19.3h-4.9Z" />
      <path d="M59.4,40h-27.4c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h27.4c1.4,0,2.5,1.1,2.5,2.5s-1.1,2.5-2.5,2.5Z" />
      <path d="M69.9,13.2" />
      <path d="M53.5,53.9h-21.5c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h21.5c1.4,0,2.5,1.1,2.5,2.5s-1.1,2.5-2.5,2.5Z" />
      <path d="M53.5,26.1h-21.5c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h21.5c1.4,0,2.5,1.1,2.5,2.5s-1.1,2.5-2.5,2.5Z" />
    </IconContainer>
  );
};
