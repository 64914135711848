import { makeName } from '../../../../makeName';
var onboardingPath = 'onboarding';
var products = 'products';
var productSelection = 'selected';
export function makeSelectedLogicByFinancialProduct(props) {
    var financialProduct = props.financialProduct, alternatives = props.alternatives;
    var selected = alternatives.selected, notSelected = alternatives.notSelected;
    return {
        name: makeName(onboardingPath, products, financialProduct, productSelection),
        is: true,
        alternatives: {
            truthy: selected,
            falsy: notSelected,
        },
    };
}
