/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const sQuoteChartLabelContainerStyle: ouiStyle.InStyle = {
  backgroundColor: ouiStyle.Constants.Colors.inverted,
  padding: 'simple',
  borderWidth: 'simple',
  borderStyle: 'solid',
};

export const SQuoteChartLabelContainer = (props: React.PropsWithChildren<{
  borderColor: ouiStyle.InStyle['borderColor'],
}>) => {
  const {
    borderColor,
    children,
  } = props;
  const sQuoteChartLabelContainerCss = React.useMemo(() => ouiStyle.makeCss({
    ...sQuoteChartLabelContainerStyle,
    borderColor,
  }), [borderColor]);
  return (
    <div
      css={sQuoteChartLabelContainerCss}
    >
      {children}
    </div>
  );
};
