import { JsonLd } from './types';

export const makeJsonLd = (props: JsonLd | undefined) => {
  if (props == null) {
    return undefined;
  }
  const {
    type,
    headline,
    image,
    datePublished,
    dateModified,
    author,
  } = props;
  const text = JSON.stringify({
    '@context': 'https://schema.org',
    '@type': type,
    headline,
    image,
    datePublished,
    dateModified,
    author,
  });
  return text;
};
