import ouiBase from '@goldwasserexchange/oui';
import { RampWithNameTransform } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import { InputAutoCompleteProvider } from '../autocomplete';

export type DefaultBaseInputContextProviderProps = {
  fieldPath: string,
  dependenciesFieldPaths: string[],
  autocomplete?: boolean,
};

export type DefaultInputContextProviderProps = React.PropsWithChildren<DefaultBaseInputContextProviderProps & {
  type: ouiBase.Input.InputTypes,
}>;

export function DefaultInputContextProvider(
  props: DefaultInputContextProviderProps,
) {
  const {
    type,
    fieldPath,
    dependenciesFieldPaths,
    autocomplete,
    children,
  } = props;
  return (
    <RampWithNameTransform<{ type: ouiBase.Input.InputTypes }>
      rampName="TypeContextProvider"
      type={type}
    >
      <RampWithNameTransform<{ fieldPath: string }>
        rampName="FieldPathProvider"
        fieldPath={fieldPath}
      >
        <RampWithNameTransform<{ dependenciesFieldPaths: string[] }>
          rampName="DependenciesFieldPathsProvider"
          dependenciesFieldPaths={dependenciesFieldPaths}
        >
          <RampWithNameTransform rampName="FieldErrorsContextProvider">
            <RampWithNameTransform rampName="FocusWithHandlersContextProvider">
              <RampWithNameTransform rampName="TouchedContextProvider">
                <RampWithNameTransform rampName="ShowHelpTextContextProvider">
                  <InputAutoCompleteProvider autocomplete={autocomplete}>
                    {children}
                  </InputAutoCompleteProvider>
                </RampWithNameTransform>
              </RampWithNameTransform>
            </RampWithNameTransform>
          </RampWithNameTransform>
        </RampWithNameTransform>
      </RampWithNameTransform>
    </RampWithNameTransform>
  );
}
