import { GetCommand, GetCommandOutput } from '@aws-sdk/lib-dynamodb';
import { Locale } from '@goldwasserexchange/i18n';
import { fromUnixTime, isFuture } from 'date-fns';
import useSWR from 'swr';
import { useAtomValue } from 'jotai';
import { useSingleTableNameContext } from '../../../../Components/Auth';
import { pathnameUrlLanguageAtom } from '../../../../history';
import { getDynamoDbDocumentClient } from '../getDynamoDbClient';
import { BannerType } from './type';

const getBannerData = async (props: {
  tableName: string,
  language: Locale,
}) => {
  const {
    tableName,
    language,
  } = props;
  const { client } = await getDynamoDbDocumentClient();
  const results = await client.send(new GetCommand({
    TableName: tableName,
    Key: {
      pk: 'banner',
      sk: language,
    },
  })) as Omit<GetCommandOutput, 'Item'> & {
    Item?: BannerType,
  };
  client.destroy();
  if (results?.Item?.validFrom != null && isFuture(fromUnixTime(results.Item.validFrom))) {
    return undefined;
  }
  return results.Item;
};

type BannerArgArray = [tableName: string, language: Locale, cacheKey: 'banner'];

const getBannerDataFromArgArray = async (props: BannerArgArray) => {
  const [tableName, language] = props;
  return getBannerData({
    tableName,
    language,
  });
};

export const useBannerData = () => {
  const tableName = useSingleTableNameContext();
  const language = useAtomValue(pathnameUrlLanguageAtom);
  const argumentsArray: BannerArgArray | null = tableName != null ? [tableName, language, 'banner'] : null;
  const response = useSWR(argumentsArray, getBannerDataFromArgArray);
  return response;
};
