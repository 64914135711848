import * as React from 'react';
import { AccountAvailableCashParams } from '../type';
import { useAccountAvailableCashDefaultParams } from '../hooks';
import { accountsAvailableCashParamsContext } from '../context';

export const AccountsAvailableCashParamsProvider = (props: React.PropsWithChildren<{
  params: Partial<AccountAvailableCashParams>,
}>) => {
  const {
    params,
    children,
  } = props;
  const value = useAccountAvailableCashDefaultParams(params);
  return (
    <accountsAvailableCashParamsContext.Provider
      value={value}
    >
      {children}
    </accountsAvailableCashParamsContext.Provider>
  );
};
