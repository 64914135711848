import React from 'react';

import { OblisContainer } from './Components/OblisContainer';
import { Sections } from './Components/Sections';
import AppBar, { AppBarProvider } from './Components/AppBar';
import { Footer } from './Components/Footer';
import { useZIndex, ZIndexSetProvider } from './OUI/Form';
import { ModalContextProvider } from './Components/Modal';
import { AppContentContainer } from './Components/AppContentContainer';
import { Head } from './Components/head/head';
import { GEToaster } from './Components/geToaster';

const ZIndexContent = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  const currentZIndex = useZIndex();
  return (
    <ZIndexSetProvider zIndex={currentZIndex - 4}>
      {children}
    </ZIndexSetProvider>
  );
};

const AppContent = () => (
  <>
    <Head />
    <AppBarProvider>
      <AppBar />
      <ZIndexContent>
        <ModalContextProvider>
          <AppContentContainer>
            <Sections />
            <Footer />
          </AppContentContainer>
        </ModalContextProvider>
      </ZIndexContent>
    </AppBarProvider>
  </>
);

const MemoizedAppContent = React.memo(AppContent);

function App() {
  return (
    <OblisContainer>
      <MemoizedAppContent />
      <GEToaster />
    </OblisContainer>
  );
}

App.displayName = 'App';

export default App;
