import * as React from 'react';
import { Toggle } from '../../../../../../../../../../Components/toggle';
import { useUnlockRedactedOnClick } from '../../../../../../../../../components/display/loader/hooks';
import { BondFilterLabel } from '../../../labels';
import { useBondBooleanFilterDisabled, useBooleanFilterValue, useToggleBondFilterValueOnChange } from '../../hooks';
import { BooleanSearchAndFilterBondParams } from '../../type';

export const BooleanBondFilterToggle = <Field extends BooleanSearchAndFilterBondParams>(props: {
  field: Field,
}) => {
  const {
    field,
  } = props;
  const disabled = useBondBooleanFilterDisabled(field);
  const value = useBooleanFilterValue(field);
  const onChange = useToggleBondFilterValueOnChange(field);
  const disabledOnClick = useUnlockRedactedOnClick();
  return (
    <Toggle
      id={field}
      value={value}
      onChange={onChange}
      disabled={disabled}
      disabledOnClick={disabledOnClick}
    >
      <BondFilterLabel field={field} />
    </Toggle>
  );
};
