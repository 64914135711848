import { useAccountPositionsGesEstimDQtBySTypeGroupAndCCurrencyCode } from '../../../accounts';
import { useCCurrencyCode } from '../cCurrencyCode';
import { useTransformAmountHieDcTotal } from '../../../accounts/history/hooks';
import { STypeGrpIds } from '../../../../shared/utils/sTypeGrpIds';

export const useAmountHieDcTotalTransform = (date: Date) => {
  const cCurrencyCode = useCCurrencyCode();
  const {
    data: qt,
  } = useAccountPositionsGesEstimDQtBySTypeGroupAndCCurrencyCode(
    cCurrencyCode,
    STypeGrpIds.CASH,
  );
  const fn = useTransformAmountHieDcTotal(qt, date);
  return fn;
};
