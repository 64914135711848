import { AccountCashflow } from '../../../accounts/cashflows/type';
import { CashflowByCurrency } from '../type';

export const cashflowsGroupByCCurrencyCode = (cashflows: AccountCashflow[] | undefined): CashflowByCurrency[] | undefined => {
  if (!cashflows) {
    return undefined;
  }
  return Object.values(cashflows.reduce((acc, cashflow) => ({
    ...acc,
    [cashflow['C_CURRENCY.CODE']]: {
      'C_CURRENCY.CODE': cashflow['C_CURRENCY.CODE'],
      'EXRATE_HIS.EXRATE_GES': cashflow['EXRATE_HIS.EXRATE_GES'],
      cashflows: [
        ...acc[cashflow['C_CURRENCY.CODE']]?.cashflows ?? [],
        cashflow,
      ],

    },
  }), {}));
};
