import ouiBase from '@goldwasserexchange/oui';
import { useBondSameIssuerFieldApiBySAccId } from '../api';

export const useBondSameIssuerSAccGreen = () => {
  const sAccId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const response = useBondSameIssuerFieldApiBySAccId({
    sAccId,
    field: 'S_ACC.GREEN',
  });
  return response;
};
