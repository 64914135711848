import { useEffect, useState } from 'react';
import {
  forEachObjIndexed, reduce, toPairs,
} from 'ramda';
import throttle from 'raf-throttle';

const getValue = (mediaQueryLists: {
  [k: string]: MediaQueryList,
}) => {
  const matches = Object.fromEntries(Object.entries(mediaQueryLists).map(([key, mql]) => [key, mql.matches]));
  return matches;
};

const setValue = (mediaQueryList, mqMatches, setter) => {
  const vals = getValue(mediaQueryList);
  const shouldUpdate = reduce((acc, [key, value]) => acc || value !== mqMatches[key], false, toPairs(vals));
  if (shouldUpdate) {
    setter(vals);
  }
};

export const useMedia = (queries: Record<string, string>) => {
  const mediaQueryLists = Object.fromEntries(Object.entries(queries).map(([key, query]) => [key, window.matchMedia(query)]));
  const [mqMatches, setMqMatches] = useState(getValue(mediaQueryLists));
  const throttledSetValue = throttle(setValue);
  useEffect(
    () => {
      const handler = () => throttledSetValue(mediaQueryLists, mqMatches, setMqMatches);
      forEachObjIndexed((mql) => mql.addListener(handler), mediaQueryLists);
      return () => {
        forEachObjIndexed((mql) => mql.removeListener(handler), mediaQueryLists);
      };
    },
    [throttledSetValue, mediaQueryLists, mqMatches],
  );
  return mqMatches;
};
