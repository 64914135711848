import { useFilterByPrimaryDimensionDeviceId } from '../utils';
import { usePersonDeviceFieldApi } from './api';

export const usePersonDevicesIsp = () => {
  const filterPredicate = useFilterByPrimaryDimensionDeviceId();
  const result = usePersonDeviceFieldApi({
    filterPredicate,
    field: 'isp',
  });
  return result;
};
