import { setOpenGraphTag } from './setOpenGraphTag';
import { OpenGraphProps } from './types';

export const setOpenGraphTags = (props: {
  head: HTMLHeadElement,
  openGraph: OpenGraphProps | undefined,
}) => {
  const {
    head,
    openGraph,
  } = props;
  const {
    type,
    url,
    description,
    title,
    image,
    locale,
  } = openGraph ?? {};
  setOpenGraphTag({
    head,
    openGraph: {
      property: 'og:title',
      name: 'title',
      content: typeof title === 'string' || title == null
        ? title
        : title(),
    },
  });
  setOpenGraphTag({
    head,
    openGraph: {
      property: 'og:type',
      content: type,
    },
  });
  setOpenGraphTag({
    head,
    openGraph: {
      property: 'og:description',
      name: 'description',
      content: description?.substring(0, 200),
    },
  });
  setOpenGraphTag({
    head,
    openGraph: {
      property: 'og:image',
      name: 'image',
      content: image,
    },
  });
  setOpenGraphTag({
    head,
    openGraph: {
      property: 'og:url',
      name: 'url',
      content: url,
    },
  });
  setOpenGraphTag({
    head,
    openGraph: {
      property: 'og:locale',
      content: locale != null ? `${locale}_BE` : undefined,
    },
  });
};
