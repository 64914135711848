import { useContext, useCallback } from 'react';
import { stickyPositionHandlersContext } from './context';
import { useSelectedIndex } from '../../../../../shared/components/list/context';
import { useVisibleIndexesRefs } from '../visibleIndexes';
import { getStickyPosition, getStickyPositionVariants } from './utils';

export const useStickyPositionHandlers = () => {
  const stickyPositionHandlers = useContext(stickyPositionHandlersContext);
  return stickyPositionHandlers;
};

export const useStickyPosition = () => {
  const [stickyPosition] = useStickyPositionHandlers();
  return stickyPosition;
};

export const useStickyPositionVariant = () => {
  const stickyPosition = useStickyPosition();
  return getStickyPositionVariants(stickyPosition);
};

export const useSetStickyPosition = () => {
  const [, setStickyPosition] = useStickyPositionHandlers();
  return setStickyPosition;
};

export const useUpdateStickyPosition = (
  lastVisibleStartIndex: React.MutableRefObject<number>,
  lastVisibleStopIndex: React.MutableRefObject<number>,
) => {
  const selectedIndex = useSelectedIndex();
  const [stickyPosition, setStickyPosition] = useStickyPositionHandlers();
  const updateStickyPosition = useCallback(() => {
    const newStickyPosition = getStickyPosition(
      lastVisibleStartIndex.current,
      lastVisibleStopIndex.current,
      selectedIndex,
    );
    if (newStickyPosition !== stickyPosition) {
      setStickyPosition(newStickyPosition);
    }
  }, [
    lastVisibleStartIndex,
    lastVisibleStopIndex,
    selectedIndex,
    stickyPosition,
    setStickyPosition,
  ]);
  return updateStickyPosition;
};

export const useStickyPositionOnItemsRendered = () => {
  const [lastVisibleStartIndex, lastVisibleStopIndex] = useVisibleIndexesRefs();
  const selectedIndex = useSelectedIndex();
  const [stickyPosition, setStickyPosition] = useStickyPositionHandlers();
  const onItemsRendered = useCallback(
    ({ visibleStartIndex, visibleStopIndex }) => {
      lastVisibleStartIndex.current = visibleStartIndex;
      lastVisibleStopIndex.current = visibleStopIndex;

      const newStickyPosition = getStickyPosition(
        lastVisibleStartIndex.current,
        lastVisibleStopIndex.current,
        selectedIndex,
      );
      if (newStickyPosition !== stickyPosition) {
        setStickyPosition(newStickyPosition);
      }
    },
    [
      lastVisibleStartIndex,
      lastVisibleStopIndex,
      selectedIndex,
      stickyPosition,
      setStickyPosition,
    ],
  );
  return onItemsRendered;
};
