import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import ouiDom from '@goldwasserexchange/oui-dom';
import { Trans } from '@lingui/macro';
import { AccountType } from '@goldwasserexchange/oblis-frontend-utils';
import { useAtomValue } from 'jotai';
import { ValidSections, pathnameUrlLanguageAtom } from '../../../../../../history';

const accountTypeSections: Record<AccountType, ValidSections> = {
  [AccountType.RTO]: ValidSections.GOLD_ACCOUNT,
  [AccountType.CONSEIL]: ValidSections.PREMIUM_ACCOUNT,
  [AccountType.GESTION]: ValidSections.GLOBAL_PLUS_ACCOUNT,
};

export const LearnMoreButton = (props: {
  accountType: AccountType,
  marginVertical?: ouiStyle.InStyle['marginVertical'],
  marginBottom?: ouiStyle.InStyle['marginBottom'],
}) => {
  const {
    accountType,
    marginVertical,
    marginBottom,
  } = props;
  const language = useAtomValue(pathnameUrlLanguageAtom);
  const section = accountTypeSections[accountType];
  return (
    <ouiDom.A.AButton
      to={`/${language}/${section}`}
      fontSize="m"
      fontWeight={ouiStyle.Constants.FontWeightProp.bold}
      color={ouiStyle.Constants.Colors.inverted}
      backgroundColor={ouiStyle.Constants.Colors.primary}
      hoverBackgroundColor={ouiStyle.Constants.Colors.primaryShaded}
      borderColor={ouiStyle.Constants.Colors.transparent}
      marginHorizontal="auto"
      marginVertical={marginVertical}
      marginBottom={marginBottom}
    >
      <Trans>
        En savoir plus
      </Trans>
    </ouiDom.A.AButton>
  );
};
