/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const searchPlaceResultContainerStyle: ouiStyle.InStyle = {
  widthLines: 'full',
  position: 'absolute',
  backgroundColor: ouiStyle.Constants.Colors.inverted,
};

const searchPlaceResultContainerCss = ouiStyle.makeCss(searchPlaceResultContainerStyle);

export const SearchPlaceResultContainer = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  return (
    <ul
      css={searchPlaceResultContainerCss}
    >
      {children}
    </ul>
  );
};
