var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { usePCss } from '../../../P';
export var SanityNewsNormal = function (props) {
    var index = props.index, children = props.children;
    var cssBase = usePCss({
        textAlign: index !== 0 ? 'justify' : 'left',
        color: index !== 0 ? ouiStyle.Constants.Colors.greyDarkest : ouiStyle.Constants.Colors.accent,
    });
    var css = React.useMemo(function () { return ouiStyle.mediaQuery(__assign(__assign({}, cssBase), { lineHeight: 1.5 })); }, [cssBase]);
    return (jsx("p", { css: css }, children));
};
