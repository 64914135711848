import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import ouiBase from '@goldwasserexchange/oui';
import {
  DefaultOrderedListContainer,
  DefaultOrderedListElementContainer,
} from './components';

export const orderedListContainerTopModelsObject = {
  ListContainer: (
    <TopModel name="ListContainer">{DefaultOrderedListContainer}</TopModel>
  ),
};
export const orderedListSpecificDefaultTopModelsObject = {
  ListElementContainer: (
    <TopModel name="ListElementContainer">
      {DefaultOrderedListElementContainer}
    </TopModel>
  ),
};

export const orderedListDefaultTopModelsObject = {
  ...ouiBase.List.listDefaultTopModelsObject,
  ...orderedListContainerTopModelsObject,
  ...orderedListSpecificDefaultTopModelsObject,
};
