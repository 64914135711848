import { useFieldDisabledByFieldPath } from '../../../Form/context';
import { useGetFieldPath } from '../fieldPath';

export function useCurrentFieldDisabledState() {
  const fieldPath = useGetFieldPath();
  return useFieldDisabledByFieldPath(fieldPath);
}

export function useIsCurrentFieldDisabled() {
  const currentFieldDisabledState = useCurrentFieldDisabledState();
  return currentFieldDisabledState.get();
}

export type DisabledVariantsNames = 'disabled' | 'enabled';

export function useDisabledVariant(): DisabledVariantsNames {
  const isDisabled = useIsCurrentFieldDisabled();
  return isDisabled ? 'disabled' : 'enabled';
}
