import { makeAccountTypeLogic } from '../../../accountType/logic';
import { AccountType } from '../../../../types';
export function makeKERequiredLogic(props) {
    var alternatives = props.alternatives;
    var free = alternatives.free, notRequired = alternatives.notRequired;
    return makeAccountTypeLogic({
        is: AccountType.RTO,
        alternatives: {
            truthy: notRequired,
            falsy: free,
        },
    });
}
