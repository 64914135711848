import { gaussianJordanElimination } from './gaussianJordanElimination';
import { generateLeftMatrix } from './generateLeftMatrix';
import { generateRightMatrix } from './generateRightMatrix';
import { DataPoint } from './types';

/**
 * Calculates the coefficients for a polynomial curve that best fits the given data points.
 * @param data - An array of objects representing the data points, with `x` and `y` properties.
 * @param degree - The degree of the polynomial curve to fit to the data points.
 * @returns An array of coefficients for the polynomial curve, in order from highest degree to lowest degree.
 */
export const getCoefficients = (data: DataPoint[], degree: number) => {
  const leftMatrix = generateLeftMatrix(data, degree);
  const rightMatrix = generateRightMatrix(data, degree);
  const terms = gaussianJordanElimination(leftMatrix, rightMatrix);
  return terms;
};
