import * as React from 'react';
import qs from 'query-string';
import { SanityLinkContainer } from '../link/components';

export const SanityFileLink = (props: React.PropsWithChildren<{
  value?: {
    file?: {
      url?: string,
      filename?: string,
    },
  },
}>) => {
  const {
    value,
    children,
  } = props;
  const {
    file,
  } = value ?? {};
  const {
    url,
    filename,
  } = file ?? {};
  if (url == null || filename == null) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
  const querystring = qs.stringify({ dl: filename });
  return (
    <SanityLinkContainer
      href={`${url}?${querystring}`}
      target="_blank"
    >
      {children}
    </SanityLinkContainer>
  );
};
