/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { animated } from 'react-spring';
import { useToolbarDropdownList, useToolbarDropDownOpenSpring } from '../context';

const toolbarDropDownListContainerStyle: ouiStyle.InStyle = {
  position: 'absolute',
  display: ouiStyle.Constants.DisplayProp.flex,
  flexDirection: ouiStyle.Constants.InFlexDirectionProp.col,
  overflow: 'hidden',
};

const toolbarDropDownListElementStyle: ouiStyle.InStyle = {
  lines: 1,
  paddingVertical: 'simple',
};

const toolbarDropDownListElementCss = ouiStyle.makeCss(toolbarDropDownListElementStyle);

const toolbarDropDownListElementHeight = toolbarDropDownListElementCss.height as string;

export const ToolbarDropDownListContainer = (props: React.PropsWithChildren<{
  right: ouiStyle.InStyle['right'],
  left: ouiStyle.InStyle['left'],
  widthLines: ouiStyle.InStyle['widthLines'],
  container: ouiStyle.InStyle['container'],
}>) => {
  const {
    right,
    left,
    widthLines,
    container,
    children,
  } = props;
  const { length } = useToolbarDropdownList();
  const css = React.useMemo(() => ouiStyle.mediaQuery({
    ...ouiStyle.makeCss({
      ...toolbarDropDownListContainerStyle,
      right,
      left,
      widthLines,
      container,
    }),
    top: ['calc(100% + 1px)', 'calc(100% + 1px)', 'calc(100% + 1px)', 'calc(100% + 1px)', '100%', '100%'],
  }), [
    right,
    left,
    widthLines,
    container,
  ]);
  const open = useToolbarDropDownOpenSpring();
  const style = {
    height: open.to((v) => `${v * parseFloat(toolbarDropDownListElementHeight) * length}rem`),
    backgroundColor: open.to({
      range: [0, 1],
      output: [ouiStyle.Constants.Colors.logoBlue, ouiStyle.Constants.Colors.accent],
    }),
  };
  return (
    <animated.div
      css={css}
      style={style}
    >
      {children}
    </animated.div>
  );
};
