import { useMemo } from 'react';
import useSWR, { KeyedMutator } from 'swr';
import { getServiceUrl } from '../../../../shared';
import { ACTOR_DEDUPING_INTERVAL, isIdLoading } from '../../../shared';
import { useBondsCallStringifiedParams } from './defaultParams';
import { Bonds } from '../../enum';
import { fetchWithOptionalAuth } from '../../../shared/utils/fetchWithAuth';
import { BondsCall } from '../type';
import { memoFindByEvtEntrySId } from '../../shared';
import { makeApiUrl } from '../../../../../../aws';

const useMakeBondsCallUrl = () => {
  const stringifiedParams = useBondsCallStringifiedParams();
  const url = stringifiedParams !== null ? `${makeApiUrl('actor')}/${getServiceUrl(Bonds.calls)}${stringifiedParams}` : null;
  return url;
};

const fetchCalls = async (url: string | null) => {
  let response;
  if (url) {
    try {
      response = await fetchWithOptionalAuth(url);
    } catch (err) {
      if (err && typeof err === 'object' && 'status' in err && err.status === 401) {
        return [
          {
            'EVT_ENTRY_S.ID': undefined,
            'EVT_ENTRY_S.EVT_DATE': undefined,
            'EVT_ENTRY_S.CMT_HIST': undefined,
            'EVT_ENTRY_S.ID_EVT_CODE': 303,
            redacted: [
              'EVT_ENTRY_S.ID',
              'EVT_ENTRY_S.EVT_DATE',
              'EVT_ENTRY_S.CMT_HIST',
            ],
          },
        ];
      }
      throw err;
    }
    return response;
  }
  return undefined;
};

const useBondsCallSwr = (externalLoading?: boolean) => {
  const url = useMakeBondsCallUrl();
  const result = useSWR<BondsCall[], any>(
    !externalLoading ? url : null,
    fetchCalls,
    { dedupingInterval: ACTOR_DEDUPING_INTERVAL },
  );
  return result;
};

type BaseUseBondsCallApiResponse = {
  isLoading: boolean,
  error?: any,
  mutate: KeyedMutator<BondsCall[]>,
  isValidating: boolean,
}

type UseBondsCallLinesApiResponse = {
  data?: BondsCall[] | undefined,
} & BaseUseBondsCallApiResponse

type FilterPredicate = (bondsCall: BondsCall) => boolean;

type SortPredicate = (securityBondsCall: BondsCall, nextBondsCall: BondsCall) => number;

type UseBondsCallLinesApiProps = {
  filterPredicate?: FilterPredicate,
  sortPredicate?: SortPredicate,
};

export const useBondsCallLinesApi = (props: UseBondsCallLinesApiProps): UseBondsCallLinesApiResponse => {
  const {
    filterPredicate,
    sortPredicate,
  } = props;
  const result = useBondsCallSwr();
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    let { data } = result;
    if (data) {
      if (filterPredicate) {
        data = data.filter((line) => filterPredicate(line));
      }
      if (sortPredicate) {
        data = data.sort((prev, next) => sortPredicate(prev, next));
      }
    }
    return ({
      ...result,
      data,
      isLoading,
    });
  }, [result, filterPredicate, sortPredicate]);
  return resultWithLoading;
};

type TransformArrayFunction<T> = (bondsCall: BondsCall[] | undefined) => T

type UseBondsCallLinesApiTransformProps<T> = {
  transformFunction: TransformArrayFunction<T>,
} & UseBondsCallLinesApiProps

type UseBondsCallLinesApiTransformResponse<T> = {
  data: T | undefined,
} & BaseUseBondsCallApiResponse;

export function useBondsCallLinesTransformApi<T>(props: UseBondsCallLinesApiTransformProps<T>): UseBondsCallLinesApiTransformResponse<T> {
  const {
    filterPredicate,
    sortPredicate,
    transformFunction,
  } = props;
  const response = useBondsCallLinesApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: (!isLoading && !error) ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UseBondsCallApiResponse = {
  data: BondsCall | undefined,
} & BaseUseBondsCallApiResponse;

export const useBondsCallApi = (props: UseBondsCallLinesApiProps): UseBondsCallApiResponse => {
  const {
    filterPredicate,
    sortPredicate,
  } = props;
  const result = useBondsCallSwr();
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    // eslint-disable-next-line prefer-destructuring
    let data: BondsCall[] | BondsCall | undefined = result.data;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      if (filterPredicate) {
        data = data.find((securityBondCall) => filterPredicate(securityBondCall));
      }
      if (Array.isArray(data)) {
        // eslint-disable-next-line prefer-destructuring
        data = data[0];
      }
    }
    return ({
      ...result,
      data,
      isLoading,
    });
  }, [result, filterPredicate, sortPredicate]);
  return resultWithLoading;
};

type TransformFunction<T> = (securityBondCall: BondsCall | undefined) => T

type UseBondsCallApiTransformProps<T> = {
  transformFunction: TransformFunction<T>,
} & UseBondsCallLinesApiProps;

export function useBondsCallTransformApi<T>(props: UseBondsCallApiTransformProps<T>): UseBondsCallLinesApiTransformResponse<T> {
  const {
    filterPredicate,
    sortPredicate,
    transformFunction,
  } = props;
  const response = useBondsCallApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: !isLoading && !error ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UseBondsCallFieldApiResponse<Field extends keyof BondsCall> = {
  data: BondsCall[Field] | undefined,
} & BaseUseBondsCallApiResponse;

type UseBondsCallFieldApiProps<Field extends keyof BondsCall> = {
  field: Field,
} & UseBondsCallLinesApiProps

export function useBondsCallFieldApi<Field extends keyof BondsCall>(props: UseBondsCallFieldApiProps<Field>): UseBondsCallFieldApiResponse<Field> {
  const {
    filterPredicate,
    sortPredicate,
    field,
  } = props;
  const response = useBondsCallApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
    } = response;
    return {
      ...response,
      data: data ? data[field] : undefined,
    };
  }, [response, field]);
  return result;
}

type UseBondsCallFieldTransformApiProps<T, Field extends keyof BondsCall> = {
  field: Field,
  transformFn: (field: BondsCall[Field] | undefined) => T,
} & UseBondsCallLinesApiProps;

export function useBondsCallFieldTransformApi<T, Field extends keyof BondsCall>(props: UseBondsCallFieldTransformApiProps<T, Field>): UseBondsCallLinesApiTransformResponse<T> {
  const {
    sortPredicate,
    filterPredicate,
    transformFn,
    field,
  } = props;
  const response = useBondsCallApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
    } = response;
    return {
      ...response,
      data: data ? transformFn(data[field]) : undefined,
    };
  }, [response, field]);
  return result;
}

type UseBondsCallByEvtEntrySIdApiTransformProps<T> = {
  evtEntrySId: string,
  transformFunction: TransformFunction<T>,
  sortPredicate?: SortPredicate | undefined,
};

export function useBondsCallTransformApiByEvtEntrySId<T>(props: UseBondsCallByEvtEntrySIdApiTransformProps<T>): UseBondsCallLinesApiTransformResponse<T> {
  const {
    evtEntrySId,
    sortPredicate,
    transformFunction,
  } = props;
  const evtEntrySIdLoading = isIdLoading(evtEntrySId);
  const response = useBondsCallSwr(evtEntrySIdLoading);
  const result = useMemo(() => {
    const isLoading = !response.data && !response.error;
    // eslint-disable-next-line prefer-destructuring
    let data: BondsCall[] | BondsCall | undefined = response.data;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      data = memoFindByEvtEntrySId(data, evtEntrySId);
    }
    return {
      ...response,
      isLoading,
      data: data ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UseBondsCallFieldApiPropsByEvtEntrySId<Field extends keyof BondsCall> = {
  evtEntrySId: string,
  field: Field,
  sortPredicate?: SortPredicate | undefined,
}

export function useBondsCallFieldApiByEvtEntrySId<Field extends keyof BondsCall>(props: UseBondsCallFieldApiPropsByEvtEntrySId<Field>): UseBondsCallFieldApiResponse<Field> {
  const {
    evtEntrySId,
    sortPredicate,
    field,
  } = props;
  const evtEntrySIdLoading = isIdLoading(evtEntrySId);
  const response = useBondsCallSwr(evtEntrySIdLoading);
  const result = useMemo(() => {
    const isLoading = !response.data && !response.error;
    // eslint-disable-next-line prefer-destructuring
    let data: BondsCall[] | BondsCall | undefined = response.data;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      data = memoFindByEvtEntrySId(data, evtEntrySId);
    }
    return {
      ...response,
      isLoading,
      data: data ? data[field] : undefined,
    };
  }, [response, field]);
  return result;
}

type UseBondsCallFieldTransformApiPropsByEvtEntrySId<T, Field extends keyof BondsCall> = {
  evtEntrySId: string,
  field: Field,
  sortPredicate?: SortPredicate | undefined,
  transformFn: (field: BondsCall[Field] | undefined) => T,
}

export function useBondsCallFieldTransformApiByEvtEntrySId<T, Field extends keyof BondsCall>(props: UseBondsCallFieldTransformApiPropsByEvtEntrySId<T, Field>): UseBondsCallLinesApiTransformResponse<T> {
  const {
    evtEntrySId,
    sortPredicate,
    transformFn,
    field,
  } = props;
  const evtEntrySIdLoading = isIdLoading(evtEntrySId);
  const response = useBondsCallSwr(evtEntrySIdLoading);
  const result = useMemo(() => {
    const isLoading = !response.data && !response.error;
    // eslint-disable-next-line prefer-destructuring
    let data: BondsCall[] | BondsCall | undefined = response.data;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      data = memoFindByEvtEntrySId(data, evtEntrySId);
    }
    return {
      ...response,
      isLoading,
      data: data ? transformFn(data[field]) : undefined,
    };
  }, [response, field]);
  return result;
}
