/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const appContentContainerStyle: ouiStyle.InStyle = {
  display: ouiStyle.Constants.DisplayProp.flex,
  flexDirection: ouiStyle.Constants.InFlexDirectionProp.col,
  alignItems: ouiStyle.Constants.AlignItemsProp.stretch,
  minLines: 'screenHeight',
};

const appContentContainerCss = ouiStyle.makeCss(appContentContainerStyle);

export const AppContentContainer = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  return (
    <div
      css={appContentContainerCss}
    >
      {children}
    </div>
  );
};
