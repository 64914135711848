import React from 'react';
import { usePrimaryDimensionDataLength } from '../hooks';
import { primaryDimensionLengthContext } from './context';
export var PrimaryDimensionLengthProvider = function (props) {
    var length = props.length, children = props.children;
    return (React.createElement(primaryDimensionLengthContext.Provider, { value: length }, children));
};
export function PrimaryDimensionLengthProviderFromList(props) {
    var forcedLength = props.length, children = props.children;
    var listLength = usePrimaryDimensionDataLength();
    var length = forcedLength !== null && forcedLength !== void 0 ? forcedLength : listLength;
    return React.createElement(PrimaryDimensionLengthProvider, { length: length }, children);
}
