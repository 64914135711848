export const parseNumber = (potentialNumber: string | null | undefined) => {
  if (potentialNumber == null) {
    return null;
  }
  const parsedId = parseInt(potentialNumber, 10);
  if (Number.isNaN(parsedId)) {
    return null;
  }
  return parsedId;
};

export const parseNumberFromQs = (qsPotentialNumber: string | (string | null)[] | null) => {
  if (Array.isArray(qsPotentialNumber)) {
    return null;
  }
  const parsedId = parseNumber(qsPotentialNumber);
  return parsedId;
};

export const parsePositiveNumberFromQs = (qsPotentialNumber: string | (string | null)[] | null) => {
  const parsedId = parseNumberFromQs(qsPotentialNumber);
  if (parsedId == null || parsedId < 0) {
    return null;
  }
  return parsedId;
};
