import { GetBondsSameIssuerParams } from '@goldwasserexchange/actor/rest-services';
import { useContext, useMemo } from 'react';
import { useAuthIsSettle } from '../../../../../../Components/Auth';
import { useCognitoUserValidAccounts } from '../../../../../../Components/Sections/online/selectedAccount';
import { stringifyParams } from '../../../shared/utils/makeUrl';
import { securityBondSameIssuerParamsContext } from '../context';

export const useBondSameIssuerDefaultParams = (params: Partial<GetBondsSameIssuerParams>): GetBondsSameIssuerParams | null => {
  const {
    'S_ACC.ID': contextSAccId,
  } = useContext(securityBondSameIssuerParamsContext);
  const sAccId = params['S_ACC.ID'] ?? contextSAccId;
  const defaultParams = useMemo(() => (sAccId != null && sAccId >= 0
    ? ({
      'S_ACC.ID': sAccId,
    })
    : null
  ), [
    sAccId,
  ]);
  return defaultParams;
};

export const useBondSameIssuerStringifiedParams = () => {
  const contextParams = useBondSameIssuerDefaultParams({});
  const accounts = useCognitoUserValidAccounts();
  const isAuthMachineSettled = useAuthIsSettle();
  return contextParams !== null && isAuthMachineSettled ? stringifyParams({
    ...contextParams,
    CONNECTED: accounts.length > 0 ? 1 : 0,
  }) : null;
};
