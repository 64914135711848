import ouiBase from '@goldwasserexchange/oui';
import { useFieldContent } from '../../../../../../context/content';

export function useSelectedIndex() {
  const listData = ouiBase.utils.dimensions.usePrimaryDimensionList();
  const fieldContent = useFieldContent().get();
  return listData.findIndex(
    (elementData) => elementData === fieldContent,
  );
}
