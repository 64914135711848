import { useContext } from 'react';
import { articleListBoundariesContext } from './context';

export const useArticleListBoundaries = () => {
  const boundaries = useContext(articleListBoundariesContext);
  return boundaries;
};

export const useArticleListBoundariesLength = () => {
  const [start, stop] = useArticleListBoundaries();
  return stop !== start ? stop - start : 1;
};
