import * as React from 'react';
import { ActorApiNumberDisplay } from '../../../../../../components/display/number/component';
import { useAccountPositionsHistoryAmountQtHit } from '../../hooks';

export const AccountPositionsHistoryHisMvtQtHit = (props: {
  withSpaceAfter?: boolean,
  withSpaceBefore?: boolean,
}) => {
  const {
    withSpaceAfter,
    withSpaceBefore,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useAccountPositionsHistoryAmountQtHit();
  return (
    <ActorApiNumberDisplay
      data={data}
      isLoading={isLoading}
      error={error}
      format={{
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }}
      withSpaceAfter={withSpaceAfter}
      withSpaceBefore={withSpaceBefore}
    />
  );
};
