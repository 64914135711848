import ouiBase from '@goldwasserexchange/oui';
import ouiStyle from '@goldwasserexchange/oui-style';
import { getUnixTime } from 'date-fns';
import * as React from 'react';
import Bonds, { useBondListSAccOblEndDate, useBondSameIssuerSAccOblEndDate, useBondsTableTypeWithoutNull } from '../../../../../actor/api/Services/bonds';
import { parseActorDate } from '../../../../../actor/api/shared/utils/parseDates';
import { LikeBondButton } from './component';

export const LikeBondButtonOnPrimaryDimension = (props: {
  isFavoriteWhileLoading?: boolean,
  ttl: number | undefined,
  display?: ouiStyle.InStyleWithMediaQueries['display'],
  justifyContent?: ouiStyle.InStyleWithMediaQueries['justifyContent'],
  alignItems?: ouiStyle.InStyleWithMediaQueries['alignItems'],
  backgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
  favoriteBackgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
  color?: ouiStyle.InStyle['color'],
  favoriteColor?: ouiStyle.InStyle['color'],
  fillColor?: ouiStyle.Constants.Colors,
  favoriteFillColor?: ouiStyle.Constants.Colors,
  hoverBackgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
  favoriteHoverBackgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
  iconSize?: string | number,
  paddingHorizontal?: ouiStyle.InStyleWithMediaQueries['paddingHorizontal'],
  paddingVertical?: ouiStyle.InStyleWithMediaQueries['paddingVertical'],
  paddingTop?: ouiStyle.InStyleWithMediaQueries['paddingTop'],
  paddingBottom?: ouiStyle.InStyleWithMediaQueries['paddingBottom'],
  iconTransform?: ouiStyle.InStyle['transform'],
}) => {
  const {
    isFavoriteWhileLoading,
    ttl,
    display,
    alignItems,
    justifyContent,
    color,
    favoriteColor,
    backgroundColor,
    favoriteBackgroundColor,
    fillColor,
    favoriteFillColor,
    hoverBackgroundColor,
    favoriteHoverBackgroundColor,
    iconSize,
    paddingHorizontal,
    paddingVertical,
    paddingTop,
    paddingBottom,
    iconTransform,
  } = props;
  const sAccIdString = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const sAccId = parseInt(sAccIdString, 10);
  return (
    <LikeBondButton
      sAccId={sAccId}
      ttl={ttl}
      isFavoriteWhileLoading={isFavoriteWhileLoading}
      display={display}
      alignItems={alignItems}
      justifyContent={justifyContent}
      backgroundColor={backgroundColor}
      favoriteBackgroundColor={favoriteBackgroundColor}
      color={color}
      favoriteColor={favoriteColor}
      hoverBackgroundColor={hoverBackgroundColor}
      favoriteHoverBackgroundColor={favoriteHoverBackgroundColor}
      fillColor={fillColor}
      favoriteFillColor={favoriteFillColor}
      iconSize={iconSize}
      paddingHorizontal={paddingHorizontal}
      paddingVertical={paddingVertical}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      iconTransform={iconTransform}
    />
  );
};

export const LikeBondButtonOnPrimaryDimensionListOrFavorite = (props: {
  isFavoriteWhileLoading?: boolean,
  display?: ouiStyle.InStyleWithMediaQueries['display'],
  justifyContent?: ouiStyle.InStyleWithMediaQueries['justifyContent'],
  alignItems?: ouiStyle.InStyleWithMediaQueries['alignItems'],
  backgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
  favoriteBackgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
  color?: ouiStyle.InStyle['color'],
  favoriteColor?: ouiStyle.InStyle['color'],
  fillColor?: ouiStyle.Constants.Colors,
  favoriteFillColor?: ouiStyle.Constants.Colors,
  hoverBackgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
  favoriteHoverBackgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
  iconSize?: string | number,
  paddingHorizontal?: ouiStyle.InStyleWithMediaQueries['paddingHorizontal'],
  paddingVertical?: ouiStyle.InStyleWithMediaQueries['paddingVertical'],
  paddingTop?: ouiStyle.InStyleWithMediaQueries['paddingTop'],
  paddingBottom?: ouiStyle.InStyleWithMediaQueries['paddingBottom'],
  iconTransform?: ouiStyle.InStyle['transform'],
}) => {
  const {
    isFavoriteWhileLoading,
    display,
    alignItems,
    justifyContent,
    color,
    favoriteColor,
    backgroundColor,
    favoriteBackgroundColor,
    fillColor,
    favoriteFillColor,
    hoverBackgroundColor,
    favoriteHoverBackgroundColor,
    iconSize,
    paddingHorizontal,
    paddingVertical,
    paddingTop,
    paddingBottom,
    iconTransform,
  } = props;
  const {
    data,
  } = useBondListSAccOblEndDate();
  const ttl = data != null && data !== '∞' ? getUnixTime(parseActorDate(data)) : undefined;
  return (
    <LikeBondButtonOnPrimaryDimension
      ttl={ttl}
      isFavoriteWhileLoading={isFavoriteWhileLoading}
      display={display}
      alignItems={alignItems}
      justifyContent={justifyContent}
      backgroundColor={backgroundColor}
      favoriteBackgroundColor={favoriteBackgroundColor}
      color={color}
      favoriteColor={favoriteColor}
      hoverBackgroundColor={hoverBackgroundColor}
      favoriteHoverBackgroundColor={favoriteHoverBackgroundColor}
      fillColor={fillColor}
      favoriteFillColor={favoriteFillColor}
      iconSize={iconSize}
      paddingHorizontal={paddingHorizontal}
      paddingVertical={paddingVertical}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      iconTransform={iconTransform}
    />
  );
};

export const LikeBondButtonOnPrimaryDimensionSameIssuer = (props: {
  isFavoriteWhileLoading?: boolean,
  display?: ouiStyle.InStyleWithMediaQueries['display'],
  justifyContent?: ouiStyle.InStyleWithMediaQueries['justifyContent'],
  alignItems?: ouiStyle.InStyleWithMediaQueries['alignItems'],
  backgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
  favoriteBackgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
  color?: ouiStyle.InStyle['color'],
  favoriteColor?: ouiStyle.InStyle['color'],
  fillColor?: ouiStyle.Constants.Colors,
  favoriteFillColor?: ouiStyle.Constants.Colors,
  hoverBackgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
  favoriteHoverBackgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
  iconSize?: string | number,
  paddingHorizontal?: ouiStyle.InStyleWithMediaQueries['paddingHorizontal'],
  paddingVertical?: ouiStyle.InStyleWithMediaQueries['paddingVertical'],
  paddingTop?: ouiStyle.InStyleWithMediaQueries['paddingTop'],
  paddingBottom?: ouiStyle.InStyleWithMediaQueries['paddingBottom'],
  iconTransform?: ouiStyle.InStyle['transform'],
}) => {
  const {
    isFavoriteWhileLoading,
    display,
    alignItems,
    justifyContent,
    color,
    favoriteColor,
    backgroundColor,
    favoriteBackgroundColor,
    fillColor,
    favoriteFillColor,
    hoverBackgroundColor,
    favoriteHoverBackgroundColor,
    iconSize,
    paddingHorizontal,
    paddingVertical,
    paddingTop,
    paddingBottom,
    iconTransform,
  } = props;
  const {
    data,
  } = useBondSameIssuerSAccOblEndDate();
  const ttl = data != null && data !== '∞' ? getUnixTime(parseActorDate(data)) : undefined;
  return (
    <LikeBondButtonOnPrimaryDimension
      ttl={ttl}
      isFavoriteWhileLoading={isFavoriteWhileLoading}
      display={display}
      alignItems={alignItems}
      justifyContent={justifyContent}
      backgroundColor={backgroundColor}
      favoriteBackgroundColor={favoriteBackgroundColor}
      color={color}
      favoriteColor={favoriteColor}
      hoverBackgroundColor={hoverBackgroundColor}
      favoriteHoverBackgroundColor={favoriteHoverBackgroundColor}
      fillColor={fillColor}
      favoriteFillColor={favoriteFillColor}
      iconSize={iconSize}
      paddingHorizontal={paddingHorizontal}
      paddingVertical={paddingVertical}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      iconTransform={iconTransform}
    />
  );
};

export const LikeBondButtonOnPrimaryDimensionAndTablePageType = (props: {
  display?: ouiStyle.InStyleWithMediaQueries['display'],
  justifyContent?: ouiStyle.InStyleWithMediaQueries['justifyContent'],
  alignItems?: ouiStyle.InStyleWithMediaQueries['alignItems'],
  backgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
  favoriteBackgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
  color?: ouiStyle.InStyle['color'],
  favoriteColor?: ouiStyle.InStyle['color'],
  fillColor?: ouiStyle.Constants.Colors,
  favoriteFillColor?: ouiStyle.Constants.Colors,
  hoverBackgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
  favoriteHoverBackgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
  iconSize?: string | number,
  paddingHorizontal?: ouiStyle.InStyleWithMediaQueries['paddingHorizontal'],
  paddingVertical?: ouiStyle.InStyleWithMediaQueries['paddingVertical'],
  paddingTop?: ouiStyle.InStyleWithMediaQueries['paddingTop'],
  paddingBottom?: ouiStyle.InStyleWithMediaQueries['paddingBottom'],
  iconTransform?: ouiStyle.InStyle['transform'],
}) => {
  const {
    display,
    alignItems,
    justifyContent,
    color,
    favoriteColor,
    backgroundColor,
    favoriteBackgroundColor,
    fillColor,
    favoriteFillColor,
    hoverBackgroundColor,
    favoriteHoverBackgroundColor,
    iconSize,
    paddingHorizontal,
    paddingVertical,
    paddingTop,
    paddingBottom,
    iconTransform,
  } = props;
  const type = useBondsTableTypeWithoutNull();
  if (type === Bonds.Favorites || type === Bonds.List) {
    return (
      <LikeBondButtonOnPrimaryDimensionListOrFavorite
        isFavoriteWhileLoading={type === Bonds.Favorites}
        display={display}
        alignItems={alignItems}
        justifyContent={justifyContent}
        backgroundColor={backgroundColor}
        favoriteBackgroundColor={favoriteBackgroundColor}
        color={color}
        favoriteColor={favoriteColor}
        hoverBackgroundColor={hoverBackgroundColor}
        favoriteHoverBackgroundColor={favoriteHoverBackgroundColor}
        fillColor={fillColor}
        favoriteFillColor={favoriteFillColor}
        iconSize={iconSize}
        paddingHorizontal={paddingHorizontal}
        paddingVertical={paddingVertical}
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
        iconTransform={iconTransform}
      />
    );
  }
  if (type === Bonds.sameIssuer) {
    return (
      <LikeBondButtonOnPrimaryDimensionSameIssuer
        isFavoriteWhileLoading={false}
        display={display}
        alignItems={alignItems}
        justifyContent={justifyContent}
        backgroundColor={backgroundColor}
        favoriteBackgroundColor={favoriteBackgroundColor}
        color={color}
        favoriteColor={favoriteColor}
        hoverBackgroundColor={hoverBackgroundColor}
        favoriteHoverBackgroundColor={favoriteHoverBackgroundColor}
        fillColor={fillColor}
        favoriteFillColor={favoriteFillColor}
        iconSize={iconSize}
        paddingHorizontal={paddingHorizontal}
        paddingVertical={paddingVertical}
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
        iconTransform={iconTransform}
      />
    );
  }
  return null;
};
