import { Trans } from '@lingui/macro';
import ouiDom from '@goldwasserexchange/oui-dom';
import * as React from 'react';
import { ValidSections } from '../../../../../../../history';
import { StepContainer } from '../../../utils/Step';
import { MaxRiskClassInput } from './input';
import { useLinguiLocale } from '../../../../../../useLinguiLocale';

const getOblisSchoolHrefOrTo = (props: {
  locale: string,
  isBondsWhiteListed: boolean,
}) => {
  const {
    locale,
    isBondsWhiteListed,
  } = props;
  if (!isBondsWhiteListed) {
    return {
      href: locale !== 'nl'
        ? 'https://www.oblis.be/fr/school/la-classification-des-risques-chez-goldwasser-exchange-523588'
        : 'https://www.oblis.be/nl/school/de-risicoclassificatie-bij-goldwasser-exchange-523589',
    };
  }
  return {
    to: `/${locale}/${ValidSections.BONDS}/oblis-school/advanced/risk-classification`,
  };
};

export const MaxRiskClassStep = () => {
  const locale = useLinguiLocale();
  const toOrHref = getOblisSchoolHrefOrTo({
    locale,
    isBondsWhiteListed: false,
  });
  return (
    <StepContainer>
      <p>
        <Trans>
          Dans l'intérêt de ses clients, Goldwasser Exchange a élaboré une méthode de classification des instruments financiers en fonction du risque estimé que ces derniers font peser sur les capitaux investis en EUR. Ce classement comporte 5 classes de risque allant de 1 pour les produits les moins risqués à 5 pour les produits les plus risqués. Pour plus de détails concernant notre système de classe de risque,&#32;
          <ouiDom.A.A
            target="_blank"
            {...toOrHref}
          >
            consultez Oblis School
          </ouiDom.A.A>
          . Vous avez la possibilité de limiter les produits que vous pourrez acquérir en fonction de la classe de risque à laquelle ces produits appartiennent. Si vous ne souhaitez pas de limite, optez pour la possibilité d'acquérir tout type de produits.
        </Trans>
      </p>
      <MaxRiskClassInput />
    </StepContainer>
  );
};
