import * as React from 'react';
import { ElementIndexContext } from '../Index';

export const ListLengthContext = React.createContext(0);

export const ListLengthContextProvider = ({ data = [], children }) => {
  const listLenght = data.length;
  return (
    <ListLengthContext.Provider value={listLenght}>
      {children}
    </ListLengthContext.Provider>
  );
};

ListLengthContextProvider.displayName = 'ListLengthContextProvider';

export const useIsLast = () => {
  const size = React.useContext(ListLengthContext);
  const index = React.useContext(ElementIndexContext);
  return index === size - 1;
};
