import { imageQuery } from '../shared';

export const query = /* groq */ `
*[
  _type == "issuer" 
  && actorId.current == string($id)
] { 
  id, 
  images[] ${imageQuery}, 
}[0]
` as const;
