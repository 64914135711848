import { useContext, useMemo } from 'react';
import { GetAccountsIbansParams } from '@goldwasserexchange/actor/rest-services';
import { useTAccId } from '../../../shared';
import { stringifyParams } from '../../../shared/utils/makeUrl';
import { accountsIbansParamsContext } from '../context';
import { useIsConnected } from '../../../../../../Components/Auth';

export const useAccountIbansDefaultParams = (params: Partial<GetAccountsIbansParams>): Partial<GetAccountsIbansParams> => {
  const contextParams = useContext(accountsIbansParamsContext);
  const tAccIdString = useTAccId();
  const currentTAccId = tAccIdString ? parseInt(tAccIdString, 10) : undefined;
  const tAccId = params['T_ACC.ID'] ?? contextParams['T_ACC.ID'] ?? currentTAccId;
  const defaultParams = useMemo(() => ({
    'T_ACC.ID': tAccId,
  }), [tAccId]);
  return defaultParams;
};

const useAccountIbansParams = (): GetAccountsIbansParams | null => {
  const defaultParams = useAccountIbansDefaultParams({});
  const tAccId = defaultParams['T_ACC.ID'];
  const isConnected = useIsConnected();
  if (tAccId == null || !isConnected) {
    return null;
  }
  return {
    'T_ACC.ID': tAccId,
  };
};

export const useAccountIbansStringifiedParams = () => {
  const contextParams = useAccountIbansParams();
  return contextParams !== null ? stringifyParams(contextParams) : null;
};
