import React from 'react';
import { Trans } from '@lingui/macro';
import { dataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import ouiBase from '@goldwasserexchange/oui';

export const idCCountryProfessionLabel = <Trans>Pays d'exercice de l'activité professionnelle</Trans>;

export const tAddIdCCountryProfessionValidatorLogic: ouiBase.utils.hookstate.FormValueFilterLogic<'requiredNumber' | 'nilCast'> = dataStructure.T_ADD.fields.ID_C_COUNTRY_ACTI.logic.show.makeShowLogic({
  truthy: {
    result: 'requiredNumber',
  },
  falsy: {
    result: 'nilCast',
  },
});
