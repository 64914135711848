/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { Lock } from 'react-feather';

const disabledToggleIconStyle = {
  height: '1rem',
  width: '1rem',
  color: ouiStyle.Constants.Colors.accent,
};

export const DisabledToggleIconContent = () => (
  <Lock
    size="1rem"
    css={disabledToggleIconStyle}
  />
);
