/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { ScrollableListContainerDom } from '../../../../../../OUI-dom/Scrollable/components';

export const timeNavListContainer = (
  <TopModel
    name="ScrollableListContainer"
    flexGrow={1}
    flexShrink={false}
  >
    {ScrollableListContainerDom}
  </TopModel>
);
