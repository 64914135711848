/** @jsxRuntime classic */
/** @jsx jsx */
import { SearchForSuggestionsResult } from '@aws-sdk/client-location';
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { trySearchPlaceIndexForSuggestionsFetcher } from '../../../../../../aws';
import { usePlaceIndexName } from '../../../../../Auth';
import { useLinguiLocale } from '../../../../../useLinguiLocale';

const searchPlaceInputContentStyle: ouiStyle.InStyle = {
  display: ouiStyle.Constants.DisplayProp.block,
  borderStyle: 'none',
  borderWidth: 'none',
  fontSize: 's',
  padding: 'simple',
  flex: 'one',
  parentBorderBottomWidth: 'simple',
};

const searchPlaceInputContentCss = ouiStyle.makeCss(searchPlaceInputContentStyle);

type SearchPlaceInputContentProps = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setData: React.Dispatch<React.SetStateAction<SearchForSuggestionsResult[] | null>>,
  setFocus: React.Dispatch<React.SetStateAction<boolean>>,
}

export const SearchPlaceInputContent = React.forwardRef<HTMLInputElement, SearchPlaceInputContentProps>((props, ref) => {
  const {
    setOpen,
    setData,
    setIsLoading,
    setFocus,
  } = props;
  const timeout = React.useRef<NodeJS.Timeout | null>(null);
  const placeIndexName = usePlaceIndexName();
  const locale = useLinguiLocale();
  const onChange = React.useCallback<NonNullable<React.InputHTMLAttributes<HTMLInputElement>['onChange']>>(
    (e) => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      if (e.target.value != null && e.target.value !== '' && placeIndexName != null) {
        setIsLoading(true);
        timeout.current = setTimeout(async () => {
          const response = await trySearchPlaceIndexForSuggestionsFetcher({
            placeIndexName,
            locale,
            searchString: e.target.value,
          });
          setData(response ?? null);
          timeout.current = null;
          setIsLoading(false);
        }, 500);
      } else {
        setData(null);
        setIsLoading(false);
      }
      return () => {
        if (timeout.current) {
          clearTimeout(timeout.current);
        }
      };
    },
    [setIsLoading, setData, setOpen, timeout, placeIndexName, locale],
  );
  const onFocus = React.useCallback(() => {
    setOpen(true);
    setFocus(true);
  }, [setOpen, setFocus]);
  const onBlur = React.useCallback(() => {
    setFocus(false);
  }, [setFocus]);
  return (
    <input
      ref={ref}
      id="addressSelector"
      name="addressSelector"
      autoComplete="off"
      // eslint-disable-next-line jsx-a11y/no-interactive-element-to-noninteractive-role
      role="presentation"
      css={searchPlaceInputContentCss}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
});
