import * as React from 'react';
import { prefix, TopModelNameTransformProvider } from '@goldwasserexchange/react-topmodel';
import ouiBase from '@goldwasserexchange/oui';
import ouiStyle from '@goldwasserexchange/oui-style';
import {
  DefaultContextProviderContextsTopModelsProvider,
  DefaultInputErrorMessageTopModelsProvider,
  DefaultListInputTopModelsProvider,
  DefaultRadioListInputTopModelsProvider,
  SelectInputStructure,
  UseGetFnProps,
} from '../../../../OUI/Inputs';
import {
  DefaultIconContentDomTopModelsProvider,
  DefaultFooterDomTopModelsProvider,
  DefaultListInputDomTopModelsProvider,
} from '../shared';
import { DefaultRadioListInputDomTopModelsProvider } from '../radio';
import { selectInputDomDefaultTopModelsObject } from './topmodels';
import { SearchKeysProps } from '../../../../OUI/Inputs/components/shared/components/list/context/searchKeys/context';

export function SelectListInput(
  props: React.PropsWithChildren<
  {
    fieldPath: string,
    elementLines?: Exclude<ouiStyle.InStyle['lines'], 'auto' | 'full'>,
    dependenciesFieldPaths?: string[],
    useAutoSelect?: (autoSelect: boolean) => void,
    autoSelect?: boolean,
  } & SearchKeysProps & UseGetFnProps & ouiBase.List.ListProps & ouiBase.List.ListDataFilterAndSortProviderProps
  >,
) {
  const {
    fieldPath,
    dependenciesFieldPaths,
    list,
    length,
    elementLines,
    useGetFn,
    searchKeys,
    useFilterFn,
    useSortFn,
    useAutoSelect,
    autoSelect,
    children,
  } = props;
  return (
    <TopModelNameTransformProvider transformFn={prefix('Select')}>
      <DefaultContextProviderContextsTopModelsProvider>
        <DefaultIconContentDomTopModelsProvider>
          <DefaultFooterDomTopModelsProvider>
            <ouiBase.List.DefaultListContextTopModelsProvider
              transformBeforeFn={prefix('InputList')}
            >
              <ouiBase.List.DefaultListMapperTopModelsProvider
                transformBeforeFn={prefix('InputList')}
              >
                <ouiBase.List.DefaultListElementBaseTopModelsProvider
                  transformBeforeFn={prefix('InputList')}
                >
                  <ouiBase.List.DefaultListElementSubContextsTopModelsProvider
                    transformBeforeFn={prefix('InputList')}
                  >
                    <DefaultListInputTopModelsProvider>
                      <DefaultInputErrorMessageTopModelsProvider>
                        <DefaultListInputDomTopModelsProvider>
                          <DefaultRadioListInputTopModelsProvider>
                            <DefaultRadioListInputDomTopModelsProvider>
                              <SelectInputStructure
                                fieldPath={fieldPath}
                                dependenciesFieldPaths={dependenciesFieldPaths}
                                list={list}
                                length={length}
                                elementLines={elementLines}
                                topModelsOverride={
                                  selectInputDomDefaultTopModelsObject
                                }
                                useFilterFn={useFilterFn}
                                useSortFn={useSortFn}
                                useGetFn={useGetFn}
                                useAutoSelect={useAutoSelect}
                                autoSelect={autoSelect}
                                searchKeys={searchKeys}
                              >
                                {children}
                              </SelectInputStructure>
                            </DefaultRadioListInputDomTopModelsProvider>
                          </DefaultRadioListInputTopModelsProvider>
                        </DefaultListInputDomTopModelsProvider>
                      </DefaultInputErrorMessageTopModelsProvider>
                    </DefaultListInputTopModelsProvider>
                  </ouiBase.List.DefaultListElementSubContextsTopModelsProvider>
                </ouiBase.List.DefaultListElementBaseTopModelsProvider>
              </ouiBase.List.DefaultListMapperTopModelsProvider>
            </ouiBase.List.DefaultListContextTopModelsProvider>
          </DefaultFooterDomTopModelsProvider>
        </DefaultIconContentDomTopModelsProvider>
      </DefaultContextProviderContextsTopModelsProvider>
    </TopModelNameTransformProvider>
  );
}
