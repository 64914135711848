import { Ramp, TopModelChildrenMerge } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import { useAuthMachineStateMatches } from '../../context';

export type SignInStates = 'UserNamePassword' | 'InputCode' | 'ForgotPasswordInputUserName' | 'ForgotPasswordInputCodeAndNewPassword' | 'SignInLoading' | 'ReAuth' | 'ConfirmSignUp'

export const useSignInState = (): SignInStates | null => {
  const isInputUserNamePassword = useAuthMachineStateMatches('disconnected.signin.inputingUserNameAndPassword');
  const isInputCode = useAuthMachineStateMatches('disconnected.signin.challenge.MFAInput.inputMFACode');
  const isConfirmSignupCode = useAuthMachineStateMatches('disconnected.signin.confirmSignUp.inputSignUpConfirmationCode');
  const isConfirmSignupForgot = useAuthMachineStateMatches('disconnected.forgotPassword.confirmSignUp.inputSignUpConfirmationCode');
  const isForgotPasswordInputUserName = useAuthMachineStateMatches('disconnected.forgotPassword.inputUserName');
  const isForgotPasswordInputCodeAndNewPassword = useAuthMachineStateMatches('disconnected.forgotPassword.inputCodeAndNewPassword');
  const isDisconnected = useAuthMachineStateMatches('disconnected');
  const isConnectedReAuth = useAuthMachineStateMatches('connected.reAuth.inputingUserNameAndPassword');
  const isReAuthInputCode = useAuthMachineStateMatches('connected.reAuth.challenge.MFAInput.inputMFACode');
  const isReAuthLoading = useAuthMachineStateMatches('connected.reAuth');
  if (isInputUserNamePassword) {
    return 'UserNamePassword';
  }
  if (isConnectedReAuth) {
    return 'ReAuth';
  }
  if (isInputCode || isReAuthInputCode) {
    return 'InputCode';
  }
  if (isConfirmSignupCode || isConfirmSignupForgot) {
    return 'ConfirmSignUp';
  }
  if (isForgotPasswordInputUserName) {
    return 'ForgotPasswordInputUserName';
  }
  if (isForgotPasswordInputCodeAndNewPassword) {
    return 'ForgotPasswordInputCodeAndNewPassword';
  }
  if (isDisconnected || isReAuthLoading) {
    return 'SignInLoading';
  }
  return null;
};

const SignInStateSwitcherInner = (props: Record<string, any>) => {
  const signInState = useSignInState();
  if (signInState !== null) {
    return (
      <Ramp
        rampName={signInState}
        {...props}
      />
    );
  }
  return null;
};

export const SignInStateSwitcher = (props: React.PropsWithChildren<Record<string, any>>) => {
  const {
    children,
    ...rest
  } = props;
  return (
    <TopModelChildrenMerge>
      {children}
      <SignInStateSwitcherInner {...rest} />
    </TopModelChildrenMerge>
  );
};
