import { fromUnixTime, isValid, parseISO } from 'date-fns';
import * as React from 'react';
import { ActorApiDateDisplay } from '../../../../../actor/components/display/date';
import { useLastSubmittedOnboardingsBySk } from '../../../../utils/dynamoDb/onboarding/get';

export const OnboardingStatusDate = (props: {
  withPending?: boolean,
}) => {
  const {
    withPending,
  } = props;
  const {
    data: onboardingSave,
    isLoading,
    error,
  } = useLastSubmittedOnboardingsBySk({ withPending });
  const status = onboardingSave?.status;
  const sk = onboardingSave?.sk ?? '';
  const unixTimestamp = parseInt(sk.split('#')[1], 10);
  const skDate = Number.isNaN(unixTimestamp) ? undefined : fromUnixTime(unixTimestamp);
  if (status === 'PENDING') {
    return (
      <ActorApiDateDisplay
        data={skDate}
        isLoading={isLoading}
        error={error}
        formatMq={false}
      />
    );
  }
  const castedSubmittedDate = onboardingSave?.casted?.submittedDate;
  let parsedDate: Date | undefined = castedSubmittedDate != null ? parseISO(castedSubmittedDate) : undefined;
  if (!isValid(parsedDate)) {
    parsedDate = skDate;
  }
  return (
    <ActorApiDateDisplay
      data={parsedDate}
      isLoading={isLoading}
      error={error}
      formatMq={false}
    />
  );
};
