import * as React from 'react';
import { ActorApiNumberDisplay } from '../../../../../../components/display/number/component';
import { useCashflowByCurrencyExrateHisExrateGes } from '../../hooks';

export const CashflowsByCurrencyExRateHisExRateGes = () => {
  const {
    data,
    isLoading,
    error,
  } = useCashflowByCurrencyExrateHisExrateGes();
  return (
    <ActorApiNumberDisplay
      data={data}
      isLoading={isLoading}
      error={error}
      format={{
        style: 'decimal',
        minimumFractionDigits: 4,
        maximumFractionDigits: 4,
      }}
    />
  );
};
