import * as React from 'react';
import { StateFilter } from '../../../../../../../Machine';
import { Suspended } from '../../../../../../../Suspended';
import { LazyCapacityNOKExclusion } from './capacity';
import { LazyLiquidityNOKExclusion } from './liquidity';

export const GestionFinancialSituationExclusions = () => (
  <>
    <StateFilter is=".liquidityNOK.excluded">
      <Suspended>
        <LazyLiquidityNOKExclusion />
      </Suspended>
    </StateFilter>
    <StateFilter is=".capacityNOK.excluded">
      <Suspended>
        <LazyCapacityNOKExclusion />
      </Suspended>
    </StateFilter>
  </>
);
