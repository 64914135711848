import * as React from 'react';
import { ActorApiNumberDisplay } from '../../../../../../components/display/number/component';
import {
  useBondsBondIsRedacted,
  useBondsBondSMarketYieldExists,
  useBondsBondSQuotePrice,
  useBondsBondYieldCalculator,
} from '../../hooks';

export const BondsBondSMarketGrossYield = (props: {
  withRedactedIcon?: boolean,
}) => {
  const {
    withRedactedIcon,
  } = props;
  const {
    data: actorYieldExists,
    isLoading: actorYieldExistsIsLoading,
  } = useBondsBondSMarketYieldExists();
  const {
    data: yieldCalculator,
    isLoading,
    error,
  } = useBondsBondYieldCalculator();
  const {
    data: price,
    isLoading: priceIsLoading,
  } = useBondsBondSQuotePrice();
  const {
    data: redacted,
    isLoading: redactedIsLoading,
  } = useBondsBondIsRedacted('S_MARKET.YIELD');
  return (
    <ActorApiNumberDisplay
      data={yieldCalculator != null && price != null && actorYieldExists === true ? yieldCalculator(price) * 100 : undefined}
      isLoading={isLoading || redactedIsLoading || priceIsLoading || actorYieldExistsIsLoading}
      error={error}
      format={{
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 3,
      }}
      loadingValue={3.625}
      nullContent="–"
      isBlocked={redacted}
      withRedactedIcon={withRedactedIcon}
    />
  );
};
