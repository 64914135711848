import ouiBase from '@goldwasserexchange/oui';
import { useAccountPositionsHistoryFieldApiByHisMvtId } from '../api';

export const useAccountPositionsHistoryAmountHieDcByHisMvtId = () => {
  const hisMvtId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const result = useAccountPositionsHistoryFieldApiByHisMvtId({
    hisMvtId,
    field: 'HIS_MVT.AMOUNT_HIE_DC',
  });
  return result;
};
