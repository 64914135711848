export var CCountryIdCode3InputLmlStrings = {
    "ID_678": "JEY",
    "ID_738": "ZAR",
    "ID_28": "KAZ",
    "ID_30": "LBN",
    "ID_31": "LKA",
    "ID_32": "LBR",
    "ID_33": "LTU",
    "ID_35": "LBY",
    "ID_36": "MAR",
    "ID_38": "MDA",
    "ID_39": "MDG",
    "ID_40": "MNG",
    "ID_42": "MRT",
    "ID_194": "FIN",
    "ID_196": "NAM",
    "ID_197": "LUX",
    "ID_199": "SGS",
    "ID_200": "GUF",
    "ID_202": "MYT",
    "ID_203": "ESP",
    "ID_205": "MMR",
    "ID_206": "GRC",
    "ID_207": "MCO",
    "ID_208": "GEO",
    "ID_209": "AGO",
    "ID_210": "TJK",
    "ID_211": "ATF",
    "ID_212": "BEL",
    "ID_214": "UKR",
    "ID_215": "BIH",
    "ID_217": "AUT",
    "ID_218": "WLF",
    "ID_219": "AND",
    "ID_223": "REU",
    "ID_224": "PYF",
    "ID_225": "RUS",
    "ID_226": "SDN",
    "ID_204": "GLP",
    "ID_58": "TKL",
    "ID_43": "MLT",
    "ID_44": "MUS",
    "ID_45": "MDV",
    "ID_46": "MWI",
    "ID_47": "MEX",
    "ID_48": "MYS",
    "ID_49": "MOZ",
    "ID_50": "NGA",
    "ID_51": "NIC",
    "ID_52": "NOR",
    "ID_53": "BVT",
    "ID_54": "SJM",
    "ID_55": "NPL",
    "ID_57": "NZL",
    "ID_60": "NIU",
    "ID_61": "OMN",
    "ID_63": "PER",
    "ID_65": "PHL",
    "ID_66": "PAK",
    "ID_67": "POL",
    "ID_68": "PRY",
    "ID_70": "ROU",
    "ID_71": "RWA",
    "ID_73": "SLB",
    "ID_74": "SYC",
    "ID_76": "SGP",
    "ID_78": "SVN",
    "ID_79": "SVK",
    "ID_80": "SLE",
    "ID_227": "VAT",
    "ID_228": "KGZ",
    "ID_229": "NLD",
    "ID_230": "BGR",
    "ID_233": "COD",
    "ID_234": "HRV",
    "ID_236": "FJI",
    "ID_238": "GBR",
    "ID_146": "ARG",
    "ID_150": "AUS",
    "ID_160": "BDI",
    "ID_133": "BEN",
    "ID_107": "USA",
    "ID_56": "COK",
    "ID_59": "PCN",
    "ID_62": "PAN",
    "ID_64": "PNG",
    "ID_69": "QAT",
    "ID_72": "SAU",
    "ID_75": "SWE",
    "ID_77": "SHN",
    "ID_81": "SOM",
    "ID_83": "STP",
    "ID_93": "TWN",
    "ID_82": "SUR",
    "ID_84": "SLV",
    "ID_85": "SYR",
    "ID_86": "SWZ",
    "ID_87": "THA",
    "ID_88": "TKM",
    "ID_89": "TUN",
    "ID_90": "TON",
    "ID_91": "TUR",
    "ID_92": "TTO",
    "ID_94": "TZA",
    "ID_161": "BMU",
    "ID_162": "BRN",
    "ID_163": "BOL",
    "ID_164": "BRA",
    "ID_165": "BHS",
    "ID_166": "BWA",
    "ID_168": "BLZ",
    "ID_169": "CAN",
    "ID_171": "LIE",
    "ID_172": "CHL",
    "ID_174": "COL",
    "ID_175": "CRI",
    "ID_177": "CPV",
    "ID_178": "CYP",
    "ID_180": "DJI",
    "ID_181": "FRO",
    "ID_183": "DNK",
    "ID_184": "DOM",
    "ID_185": "DZA",
    "ID_187": "EGY",
    "ID_188": "ETH",
    "ID_190": "NCL",
    "ID_192": "ITA",
    "ID_195": "FRA",
    "ID_198": "MKD",
    "ID_201": "MTQ",
    "ID_213": "PSE",
    "ID_221": "DE",
    "ID_222": "SPM",
    "ID_231": "ERI",
    "ID_235": "PRT",
    "ID_237": "FLK",
    "ID_12": "ISR",
    "ID_16": "IRN",
    "ID_20": "JPN",
    "ID_22": "KHM",
    "ID_25": "KOR",
    "ID_29": "LAO",
    "ID_34": "LVA",
    "ID_37": "ESH",
    "ID_41": "MAC",
    "ID_359": "SMR",
    "ID_191": "UZB",
    "ID_1": "GHA",
    "ID_2": "GIB",
    "ID_3": "GMB",
    "ID_4": "GIN",
    "ID_5": "GTM",
    "ID_6": "GUY",
    "ID_7": "HKG",
    "ID_8": "HND",
    "ID_96": "TCA",
    "ID_97": "TLS",
    "ID_98": "ASM",
    "ID_100": "MHL",
    "ID_101": "GUM",
    "ID_102": "MNP",
    "ID_103": "PRI",
    "ID_105": "PLW",
    "ID_106": "FSM",
    "ID_109": "UMI",
    "ID_110": "URY",
    "ID_112": "VNM",
    "ID_113": "VUT",
    "ID_115": "CAF",
    "ID_95": "VGB",
    "ID_99": "VIR",
    "ID_104": "ECU",
    "ID_108": "IOT",
    "ID_111": "VEN",
    "ID_114": "WSM",
    "ID_116": "COG",
    "ID_118": "CMR",
    "ID_126": "KNA",
    "ID_130": "GNB",
    "ID_135": "CIV",
    "ID_139": "ZAF",
    "ID_147": "TUV",
    "ID_149": "CXR",
    "ID_152": "NRU",
    "ID_154": "NFK",
    "ID_167": "BLR",
    "ID_170": "CHE",
    "ID_173": "CHN",
    "ID_176": "CUB",
    "ID_179": "CZE",
    "ID_182": "GRL",
    "ID_186": "EST",
    "ID_189": "IRL",
    "ID_193": "UGA",
    "ID_579": "IMN",
    "ID_580": "MNE",
    "ID_581": "MAF",
    "ID_582": "SRB",
    "ID_583": "SXM",
    "ID_585": "BLM",
    "ID_586": "CUW",
    "ID_658": "GGY",
    "ID_155": "ABW",
    "ID_156": "AZE",
    "ID_157": "BRB",
    "ID_158": "BGD",
    "ID_159": "BHR",
    "ID_117": "GNQ",
    "ID_119": "GAB",
    "ID_120": "TCD",
    "ID_121": "DMA",
    "ID_122": "AIA",
    "ID_123": "LCA",
    "ID_124": "VCT",
    "ID_125": "ATG",
    "ID_127": "MSR",
    "ID_128": "GRD",
    "ID_129": "BFA",
    "ID_131": "NER",
    "ID_132": "TGO",
    "ID_134": "SEN",
    "ID_136": "MLI",
    "ID_137": "YEM",
    "ID_138": "LSO",
    "ID_140": "ZMB",
    "ID_141": "ZWE",
    "ID_142": "ARE",
    "ID_143": "AFG",
    "ID_144": "ALB",
    "ID_145": "ARM",
    "ID_148": "CCK",
    "ID_151": "HMD",
    "ID_153": "KIR",
    "ID_9": "HTI",
    "ID_10": "HUN",
    "ID_11": "IDN",
    "ID_13": "IND",
    "ID_14": "BTN",
    "ID_15": "IRQ",
    "ID_17": "ISL",
    "ID_18": "JAM",
    "ID_19": "JOR",
    "ID_21": "KEN",
    "ID_23": "COM",
    "ID_24": "PRK",
    "ID_26": "KWT",
    "ID_27": "CYM"
};
