import * as React from 'react';
import { useBondsCallEvtEntrySEvtDate, useBondsCallEvtEntrySEvtDateNextCall, useBondsCallIsRedacted } from '../../hooks';
import { ActorApiDateDisplay } from '../../../../../../components/display/date';

export const BondsCallEvtEntrySEvtDate = () => {
  const {
    data,
    isLoading: dataIsLoading,
    error,
  } = useBondsCallEvtEntrySEvtDate();
  const {
    data: redacted,
    isLoading,
  } = useBondsCallIsRedacted('EVT_ENTRY_S.EVT_DATE', dataIsLoading);
  return (
    <ActorApiDateDisplay
      data={data}
      isLoading={isLoading}
      error={error}
      isBlocked={redacted}
      formatMq={false}
      withRedactedIcon
    />
  );
};

export const BondsCallEvtEntrySEvtDateNextCall = (props: {
  withRedactedIcon?: boolean,
}) => {
  const {
    withRedactedIcon,
  } = props;
  const {
    data,
    isLoading: dataIsLoading,
    error,
  } = useBondsCallEvtEntrySEvtDateNextCall();
  const {
    data: redacted,
    isLoading,
  } = useBondsCallIsRedacted('EVT_ENTRY_S.EVT_DATE', dataIsLoading);
  return (
    <ActorApiDateDisplay
      data={data}
      isLoading={isLoading}
      error={error}
      isBlocked={redacted}
      formatMq={false}
      withRedactedIcon={withRedactedIcon}
    />
  );
};
