import { AccountRates } from '../../type';
import { useAccountRateTransformApi } from '../api';

const tAccRatesExist = (v: AccountRates | null | undefined) => !!(v && v['T_ACC.CPS_RATE'] && v['T_ACC.CPS_MIN'] && v['T_ACC.CPS_MAX']);

export const useAccountsRatesTAccCpsRatesExist = () => {
  const response = useAccountRateTransformApi({
    transformFunction: tAccRatesExist,
  });
  return response;
};
