var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import facepaint from 'facepaint';
export var breakpoints = [34, 40, 48, 62, 75];
export var mediaQueries = __spreadArray([0], __read(breakpoints), false);
export var mediaQuery = facepaint(breakpoints.map(function (bp) { return "@media only screen and (min-width: ".concat(bp, "rem)"); }));
export function mediaQuerizeProps(prop) {
    if (Array.isArray(prop)) {
        return prop.reduce(function (acc, individualProp) {
            if (individualProp === undefined) {
                var lastAcc = acc[acc.length - 1];
                return __spreadArray(__spreadArray([], __read(acc), false), [lastAcc], false);
            }
            return __spreadArray(__spreadArray([], __read(acc), false), [individualProp], false);
        }, []);
    }
    return mediaQueries.map(function () { return prop; });
}
