import * as React from 'react';

export type PopperContainerType = [
  React.MutableRefObject<HTMLDivElement | null>,
  React.CSSProperties,
  (
    | {
      [key: string]: string,
    }
    | undefined
  )
];

export const popperContainerContext = React.createContext<PopperContainerType | null>(null);
