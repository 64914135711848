import { dataStructure, AccountType } from '@goldwasserexchange/oblis-frontend-utils';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { Trans } from '@lingui/macro';
import * as React from 'react';
import ouiBase from '@goldwasserexchange/oui';

export const INVESTMENT_UNIVERSE = 'investmentUniverse';

const investmentUniverseLabels = {
  [AccountType.RTO]: {
    label: (
      <Trans>
        Actions, obligations,
        <br />
        fonds & trackers
      </Trans>
    ),
  },
  [AccountType.CONSEIL]: {
    label: (
      <Trans>
        Actions, obligations,
        <br />
        fonds & trackers
      </Trans>
    ),
  },
  [AccountType.GESTION]: {
    label: <Trans>Fonds et trackers</Trans>,
  },
};

const InvestmentUniverseLabel = (props: { accountType: dataStructure.accountType.listType }) => {
  const { accountType } = props;
  return investmentUniverseLabels[accountType].label;
};

const InvestmentUniverseLabelOnPrimaryDimension = () => {
  const accountType = ouiBase.utils.dimensions.useCurrentPrimaryDimension() as dataStructure.accountType.listType;
  return (
    <InvestmentUniverseLabel accountType={accountType} />
  );
};

const investmentUniverseLabel = (
  <Trans>
    Univers d'investissement
  </Trans>
);

export const InvestmentUniverseTopModels = (
  <>
    <TopModel name={`TableSecondaryTitleCellContent-${INVESTMENT_UNIVERSE}`}>
      {investmentUniverseLabel}
    </TopModel>
    <TopModel name={`TableBodyRowCellContent--${INVESTMENT_UNIVERSE}`}>
      {InvestmentUniverseLabelOnPrimaryDimension}
    </TopModel>
  </>
);
