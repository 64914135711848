import { useBondsBondFieldApi, useBondsBondFieldTransformFnApi } from '../api';

export const useBondsBondSMarketYield = () => {
  const response = useBondsBondFieldApi('S_MARKET.YIELD');
  return response;
};

const exists = (sMarketYield: number | null | undefined) => sMarketYield != null;

export const useBondsBondSMarketYieldExists = () => {
  const response = useBondsBondFieldTransformFnApi({
    field: 'S_MARKET.YIELD',
    transformFn: exists,
  });
  return response;
};
