import useSWR from 'swr';
import { convertQueryArguments, fetcher } from './utils';
import { useAtomValue } from 'jotai';
import { pathnameUrlLanguageAtom } from '../../../../history';

export const useSanityFeaturedHomeNews = () => {
  const locale = useAtomValue(pathnameUrlLanguageAtom);
  const swrArguments = convertQueryArguments({
    locale,
  });
  const response = useSWR(swrArguments, fetcher);
  return response;
};

export const useSanityHasFeaturedHomeNews = () => {
  const {
    data,
    isLoading,
  } = useSanityFeaturedHomeNews();
  return !isLoading && data != null;
};
