import { AccountPosition } from '../../type';
import { useAccountPositionsTransformApi } from '../api';

const transformFunction = (accountPositions: AccountPosition[] | undefined) => {
  if (!accountPositions || accountPositions.length === 0) {
    return undefined;
  }
  return accountPositions[0]['T_ACC.LASTREFRESH_DATE'];
};

export const useAccountPositionTAccLastRefreshDate = () => {
  const result = useAccountPositionsTransformApi({
    transformFunction,
  });
  return result;
};
