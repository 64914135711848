import { RampWithNameTransform } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import { useTableWrapping } from '../../context';
import { groupNamesByWrapping } from '../../context/wrapping/constants';
export var TableHeaderContent = function (props) {
    var noCornerTitle = props.noCornerTitle;
    var wrapping = useTableWrapping();
    return (React.createElement(RampWithNameTransform, { rampName: "Header".concat(groupNamesByWrapping[wrapping], "Container") },
        React.createElement(RampWithNameTransform, { rampName: "Header".concat(groupNamesByWrapping[wrapping], "Content"), noCornerTitle: noCornerTitle })));
};
