import React from 'react';
import ouiBase from '@goldwasserexchange/oui';
import ouiStyle from '@goldwasserexchange/oui-style';
import ouiDom from '@goldwasserexchange/oui-dom';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { useScrollEndNotVisible, useScrollStartNotVisible } from '../utils';

export const ScrollToTouch = (props: {
  position: 'left' | 'right',
  scrollTo: (pixels: number) => void,
  currentScrollLeft: number,
  elementWidth: number,
  clientWidth: number,
  totalWidth: number,
  display?: ouiStyle.InStyle['display'],
  additionalPaddingTop?: number,
}) => {
  const {
    position,
    scrollTo,
    currentScrollLeft,
    elementWidth,
    clientWidth,
    totalWidth,
    display,
    additionalPaddingTop = 0,
  } = props;
  const freezedPosition = ouiBase.utils.freeze.useFreeze(position);
  const onClick = () => scrollTo(position === 'left'
    ? currentScrollLeft - (elementWidth * 16)
    : currentScrollLeft + (elementWidth * 16));
  const visible = freezedPosition === 'left'
    ? useScrollStartNotVisible(currentScrollLeft) // eslint-disable-line react-hooks/rules-of-hooks
    : useScrollEndNotVisible(currentScrollLeft, totalWidth, clientWidth); // eslint-disable-line react-hooks/rules-of-hooks
  return visible ? (
    <ouiDom.Button.Button
      display={display}
      paddingTop={
        1 + additionalPaddingTop
      }
      position="absolute"
      lines="full"
      top={0}
      left={freezedPosition === 'left' ? 0 : undefined}
      right={freezedPosition === 'right' ? 0 : undefined}
      zIndex={2}
      borderTopStyle="none"
      borderBottomStyle="none"
      borderLeftStyle="none"
      borderRightStyle="none"
      type="button"
      onClick={onClick}
    >
      {position === 'left' ? <ChevronLeft />
        : <ChevronRight />}
    </ouiDom.Button.Button>
  ) : null;
};
