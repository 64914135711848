import * as React from 'react';
import { useAtomValue } from 'jotai';
import { DateDisplay } from '../../../../../../../../DateDisplay';
import { onlineHistoryTimeIntervalStartAtom } from '../atoms';

export const HistoryStartTime = (props: {
  spaceBefore?: boolean,
  spaceAfter?: boolean,
}) => {
  const {
    spaceBefore,
    spaceAfter,
  } = props;
  const startDate = useAtomValue(onlineHistoryTimeIntervalStartAtom);
  return (
    <DateDisplay
      date={startDate}
      spaceBefore={spaceBefore}
      spaceAfter={spaceAfter}
    />
  );
};
