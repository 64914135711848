import React from 'react';
import { SectionContent } from './sectionContent';
import {
  RouteContainer,
} from '../routeContainer';
import { useBannerHeight } from '../banner/context';

export const Sections = () => {
  const bannerHeight = useBannerHeight();
  return (
    <RouteContainer
      marginTop={`calc(3.75rem + ${bannerHeight}px)`}
    >

      <SectionContent />
    </RouteContainer>
  );
};

Sections.displayName = 'Sections';
