import { useContext } from 'react';
import { Bonds } from '../../enum';
import { bondsTableTypeContext } from './context';

export const useBondsTableType = () => {
  const type = useContext(bondsTableTypeContext);
  return type;
};

export const useBondsTableTypeWithoutNull = () => {
  const type = useBondsTableType();
  if (type == null) {
    throw new Error('no bonds page type context');
  }
  return type;
};

export const useBondsTableListOrFavoriteType = () => {
  const type = useBondsTableTypeWithoutNull();
  if (type !== Bonds.List && type !== Bonds.Favorites) {
    throw new Error(`BondsTablePageType is not ${Bonds.List} or ${Bonds.Favorites} but '${type}'`);
  }
  return type;
};
