import * as React from 'react';
import { StateFilter } from '../../../../../../Machine';
import { Suspended } from '../../../../../../Suspended';
import { LazyElevatedRiskLessThanOneYearExclusion } from './elevatedRiskLessThanOneYear';
import { LazyElevatedRiskOneToThreeYearsExclusion } from './elevatedRiskOneToThreeYears';
import { LazyElevatedRiskPreserveCapitalExclusion } from './elevatedRiskPreserveCapital';
import { LazyModeratedRiskLessThanOneYearExclusion } from './moderatedRiskLessThanOneYear';
import { LazyModeratedRiskPreserveCapital } from './moderatedRiskPreserveCapital';

export const CommonInvestmentGoalsExclusions = () => (
  <>
    <StateFilter is=".moderatedRiskLessThanOneYear.excluded">
      <Suspended>
        <LazyModeratedRiskLessThanOneYearExclusion />
      </Suspended>
    </StateFilter>
    <StateFilter is=".elevatedRiskLessThanOneYear.excluded">
      <Suspended>
        <LazyElevatedRiskLessThanOneYearExclusion />
      </Suspended>
    </StateFilter>
    <StateFilter is=".elevatedRiskOneToThreeYears.excluded">
      <Suspended>
        <LazyElevatedRiskOneToThreeYearsExclusion />
      </Suspended>
    </StateFilter>
    <StateFilter is=".moderatedRiskPreserveCapital.excluded">
      <Suspended>
        <LazyModeratedRiskPreserveCapital />
      </Suspended>
    </StateFilter>
    <StateFilter is=".elevatedRiskPreserveCapital.excluded">
      <Suspended>
        <LazyElevatedRiskPreserveCapitalExclusion />
      </Suspended>
    </StateFilter>
  </>
);
