import ouiBase from '@goldwasserexchange/oui';
import { RampWithNameTransform } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import {
  useSelectedIndex,
} from '../../../../../../../../../../OUI/Inputs';

export function DefaultSelectedInputListElementSelectedBase() {
  const isOpen = ouiBase.List.useSelectIsOpen();
  const selectedIndex = useSelectedIndex();
  const showedItems = ouiBase.List.useShowedItems();
  const length = ouiBase.utils.dimensions.usePrimaryDimensionDataLength();
  return isOpen === true && selectedIndex !== -1 && showedItems < length
    ? (
      <RampWithNameTransform<{ index: number }>
        rampName="ElementContextContainer"
        index={selectedIndex}
      >
        <RampWithNameTransform
          rampName="ElementSelectedContainer"
        >
          <RampWithNameTransform rampName="ElementContent" />
        </RampWithNameTransform>
      </RampWithNameTransform>
    )
    : null;
}
