import { EcplProf } from '../../../../types';
export var list = [
    EcplProf.ADMINISTRATOR,
    EcplProf.UNEMPLOYED,
    EcplProf.STUDENT,
    EcplProf.HOME,
    EcplProf.PUBLIC_AGENT,
    EcplProf.INDEPENDANT,
    EcplProf.LIBERAL_PROFESSION,
    EcplProf.PENSIONER,
    EcplProf.EMPLOYEE,
    EcplProf.RENTIER,
    EcplProf.WORKER,
    EcplProf.FIDUCIARY,
];
export var professionOptionalLastProfession = [
    EcplProf.RENTIER,
    EcplProf.HOME,
    EcplProf.UNEMPLOYED,
];
export var mayHavePreviousJobProfessionList = [
    EcplProf.PENSIONER,
    EcplProf.RENTIER,
    EcplProf.HOME,
    EcplProf.UNEMPLOYED,
];
export var notWorkingProfession = [
    EcplProf.PENSIONER,
    EcplProf.STUDENT,
    EcplProf.RENTIER,
    EcplProf.HOME,
    EcplProf.UNEMPLOYED,
];
