import { CognitoUserWithChallengeName } from '../../../Components/Auth/type';
import { getCognitoUserSignInUserSession } from './signInUserSession';

export const getCognitoUserIdToken = (cognitoUser: CognitoUserWithChallengeName | null) => {
  const userSession = getCognitoUserSignInUserSession(cognitoUser);
  const idToken = userSession?.getIdToken();
  return idToken ?? null;
};

export const getCognitoUserIdTokenPayload = (cognitoUser: CognitoUserWithChallengeName | null) => {
  const idToken = getCognitoUserIdToken(cognitoUser);
  return idToken?.payload ?? null;
};
