import ouiBase from '@goldwasserexchange/oui';
import { useCallback } from 'react';
import { AccountDocument } from '../type';

export const filterByArDocId = (arDocId: AccountDocument['AR_DOC.ID']) => (cashFlowLine: AccountDocument) => cashFlowLine['AR_DOC.ID'] === arDocId;

export const useFilterByArDocId = (arDocId: AccountDocument['AR_DOC.ID']) => {
  const filterFn = useCallback(filterByArDocId(arDocId), [arDocId]);
  return filterFn;
};

export const useFilterByPrimaryDimensionArDocId = () => {
  const idString = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const id = parseInt(idString, 10);
  const filterByGesEstimD = useFilterByArDocId(id);
  return filterByGesEstimD;
};
