import { History, Update } from 'history';
import { splitPathname } from '../../utils';
import { sectionsRoutesDefinition } from './router';
import { accountsRouting } from './accounts';
import { newsRouting } from './news';
import { bondsRouting } from './bonds';
import { onboardingsRouting } from './onboarding';
import { getValidSection } from './utils';

export const sectionsRouting = (_sourceHistory: History, currentUpdate: Update) => {
  const update = {
    action: currentUpdate.action,
    location: {
      ...currentUpdate.location,
    },
  };
  const { location } = update;
  const {
    pathname,
  } = location;
  const splittedPathname = splitPathname(pathname);
  const section = splittedPathname.at(1);
  const validSection = getValidSection(section);
  const {
    importFn,
  } = sectionsRoutesDefinition[validSection];
  importFn();
  if (validSection !== 'not-found' && splittedPathname.at(1) !== validSection) {
    update.location.pathname = `/${[splittedPathname.at(0), validSection, ...splittedPathname.slice(2)].join('/')}`;
  }
  switch (validSection) {
    case 'accounts':
      return accountsRouting(_sourceHistory, update);
    case 'news':
      return newsRouting(_sourceHistory, update);
    case 'bonds':
      return bondsRouting(_sourceHistory, update);
    case 'onboarding':
      return onboardingsRouting(_sourceHistory, update);
    default:
      return update;
  }
};
