/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiBase from '@goldwasserexchange/oui';
import ouiStyle from '@goldwasserexchange/oui-style';
import {
  useOnFocus,
  useOnBlur,
  useOnChange,
} from '../../../../../../../OUI/utils';
import {
  useChecked,
  useIsCurrentFieldDisabled,
  useInputRef,
  useRadioHtmlId,
  useGetFieldPath,
} from '../../../../../../../OUI/Inputs';

const defaultDomRadioListInputListElementCss = ouiStyle.makeCss({
  srOnly: true,
});

export function DefaultDomRadioListInputListElementInput() {
  const ref = useInputRef();
  const name = useGetFieldPath();
  const id = useRadioHtmlId();
  const value = ouiBase.utils.dimensions.useCurrentPrimaryDimensionValueByIndex();
  const disabled = useIsCurrentFieldDisabled();
  const onFocus = useOnFocus();
  const onBlur = useOnBlur();
  const onChange = useOnChange();
  const checked = useChecked();
  return (
    <input
      ref={ref}
      type="radio"
      name={name}
      id={id}
      css={defaultDomRadioListInputListElementCss}
      value={value}
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
}
