import { makeShowLogic } from './show';
var otherFiscRegistNumPath = 'TIN_US_SEC';
var natRegistNumPath = 'nat_REGIST_NUM';
export var pathLogic = makeShowLogic({
    national: {
        result: otherFiscRegistNumPath,
    },
    global: {
        result: otherFiscRegistNumPath,
    },
    invisible: {
        result: '',
    },
    nationalInNatNumber: {
        result: natRegistNumPath,
    },
});
