import * as React from 'react';
import { ActorApiDateDisplay } from '../../../../../../components/display/date';
import { useAccountTwrrStartDate } from '../../hooks';

export const AccountsTwrrTAccTwrrPStartDate = (props: {
  format?: string,
  withSpaceAfter?: boolean,
}) => {
  const {
    format,
    withSpaceAfter,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useAccountTwrrStartDate();
  return (
    <ActorApiDateDisplay
      data={data}
      isLoading={isLoading}
      error={error}
      format={format}
      withSpaceAfter={withSpaceAfter}
    />
  );
};
