import { AccountType } from '@goldwasserexchange/oblis-frontend-utils';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { Trans } from '@lingui/macro';
import * as React from 'react';

export const premiumAdvantages = (
  <>
    <TopModel name={`ListElementContent-primaryKey-0-${AccountType.CONSEIL}-section`}>
      <Trans>
        Recommandations personnalisées
      </Trans>
    </TopModel>
    <TopModel name={`ListElementContent-primaryKey-1-${AccountType.CONSEIL}-section`}>
      <Trans>
        Délégué commercial attitré
      </Trans>
    </TopModel>
    <TopModel name={`ListElementContent-primaryKey-2-${AccountType.CONSEIL}-section`}>
      <Trans>
        Assistance instantanée
      </Trans>
    </TopModel>
  </>
);
