import { getModalName } from '../../../../shared';
import { useFilterByPrimaryDimensionArDocId } from '../../utils';
import { useAccountDocumentTransformApi } from '../api';

export const useAccountDocumentArDocModalName = () => {
  const filterPredicate = useFilterByPrimaryDimensionArDocId();
  const response = useAccountDocumentTransformApi({
    filterPredicate,
    transformFunction: getModalName,
  });
  return response;
};
