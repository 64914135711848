import { mediaQuerizeProps } from '../mediaQuery';
import { space } from '../utils/sizes';
export function makeMargin(marginProp) {
    if (!Array.isArray(marginProp)) {
        if (marginProp === 'none') {
            return 0;
        }
        if (marginProp === 'half') {
            return "".concat(space / 2, "rem");
        }
        if (marginProp === 'auto') {
            return 'auto';
        }
        if (marginProp === 'simple') {
            return "".concat(space, "rem");
        }
        if (marginProp === 'double') {
            return "".concat(2 * space, "rem");
        }
        if (typeof marginProp === 'number') {
            return "".concat(marginProp * space, "rem");
        }
        throw new Error("invalid margin prop ".concat(marginProp));
    }
    return mediaQuerizeProps(marginProp).map(function (individualMarginProp) { return makeMargin(individualMarginProp); });
}
