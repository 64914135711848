import * as React from 'react';
import { ActorApiStringDisplay } from '../../../../../../components/display/string';
import { useBondsBondFullName, useBondsBondIsSomeRedacted } from '../../hooks';

export const BondsBondFullName = (props: {
  maxSIssuerName1Length?: number,
  withRedactedIcon?: boolean,
}) => {
  const {
    maxSIssuerName1Length,
    withRedactedIcon,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useBondsBondFullName({
    maxSIssuerName1Length,
  });
  const {
    data: redacted,
    isLoading: redactedIsLoading,
  } = useBondsBondIsSomeRedacted(['C_CURRENCY.CODE', 'S_ISSUER.NAME_1', 'S_ACC.INT_RATE', 'S_ACC.OBL_END_DATE']);
  return (
    <ActorApiStringDisplay
      data={data}
      isLoading={isLoading || redactedIsLoading}
      error={error}
      loadingValue="--- -------- ---- ------ -- --------"
      isBlocked={redacted}
      withRedactedIcon={withRedactedIcon}
    />
  );
};
