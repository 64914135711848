/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import ouiDom from '@goldwasserexchange/oui-dom';
import ouiStyle from '@goldwasserexchange/oui-style';

export const SanityNormal = (props: React.PropsWithChildren<{
  index: number,
}>) => {
  const { index, children } = props;
  const cssBase = ouiDom.P.usePCss({
    textAlign: index !== 0 ? 'justify' : 'left',
    color: index !== 0 ? ouiStyle.Constants.Colors.greyDarkest : ouiStyle.Constants.Colors.accent,
    lineFactor: index !== 0 ? 1.125 : 1.75,
  });
  const css = React.useMemo(() => ouiStyle.mediaQuery(({
    ...cssBase,
    lineHeight: index !== 0 ? 1.5 : [1.25, 1.25, 1.25, 1, 1, 1],
    fontWeight: index !== 0 ? 350 : 300,
  })), [cssBase, index]);
  return (
    <p
      css={css}
    >
      {children}
    </p>
  );
};
