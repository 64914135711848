/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { useFootnoteIndexByKey } from './context';
import { SanitySup } from './components';

const style: ouiStyle.InStyleWithMediaQueries = {
  color: ouiStyle.Constants.Colors.primary,
  textDecoration: 'none',
  fontWeight: ouiStyle.Constants.FontWeightProp.bold,
  fontSize: 'inherit',
};

const css = ouiStyle.mediaQuery(ouiStyle.makeCss(style));

export const SanityFootnote = (props: React.PropsWithChildren<{
  value: any,
}>) => {
  const {
    value,
    children,
  } = props;
  const {
    _key,
  } = value;
  const footnoteIndex = useFootnoteIndexByKey(_key);
  return (
    <span>
      {children}
      {footnoteIndex != null
        ? (
          <SanitySup>
            <a
              href={`#${_key}`}
              css={css}
            >
              {footnoteIndex + 1}
            </a>
          </SanitySup>
        )
        : null}
    </span>
  );
};
