/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const searchPlaceInputPlaceholderStyle: ouiStyle.InStyle = {
  widthLines: 'full',
};

const searchPlaceInputPlaceholderCss = ouiStyle.makeCss(searchPlaceInputPlaceholderStyle);

export const SearchPlaceInputPlaceholder = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  return (
    <div
      css={searchPlaceInputPlaceholderCss}
    >
      {children}
    </div>
  );
};
