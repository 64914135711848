import * as React from 'react';
import { useAccountHistoryHisMvtCmtConsult } from '../../hooks';
import { ActorApiStringDisplay } from '../../../../../../components/display/string';

export const AccountsHistoryHisMvtCmtConsult = () => {
  const {
    data,
    isLoading,
    error,
  } = useAccountHistoryHisMvtCmtConsult();
  return (
    <ActorApiStringDisplay
      data={data}
      isLoading={isLoading}
      error={error}
      loadingValue="-------- ---- ----------------"
    />
  );
};
