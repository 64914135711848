/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { BaseBuilderWithNameTransform } from '../../../../../../../../OUI/Inputs';
import { useOnMouseEnter, useOnMouseLeave, usePopperContainerContext } from '../../../../../../../../OUI/utils';
import { useZIndex } from '../../../../../../../../OUI/Form';

const helpInputPopperContainerStyle: ouiStyle.InStyle = {
  padding: 'simple',
  borderColor: ouiStyle.Constants.Colors.primary,
  borderStyle: 'solid',
  borderWidth: 'simple',
  backgroundColor: ouiStyle.Constants.Colors.inverted,
};

const helpInputPopperContainerCss = {
  ...ouiStyle.makeCss(helpInputPopperContainerStyle),
  minWidth: [`calc(100vw - (4 * ${ouiStyle.Constants.sizes.space}rem))`, `calc(100vw - (4 * ${ouiStyle.Constants.sizes.space}rem))`, `${ouiStyle.Constants.Container.sm}`],
};

export const HelpInputPopperContainer = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  const [popperRef, popperStyles, popperAttributes] = usePopperContainerContext() || [];
  const onMouseEnter = useOnMouseEnter();
  const onMouseLeave = useOnMouseLeave();
  const zIndex = useZIndex();
  const css = React.useMemo(() => ouiStyle.mediaQuery({
    ...helpInputPopperContainerCss,
    zIndex: zIndex + 2,
  }), [helpInputPopperContainerCss, zIndex]);
  return (
    <div
      ref={popperRef}
      css={css}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={popperStyles}
      {...popperAttributes}
    >
      {children}
      <BaseBuilderWithNameTransform rampName="InputHelpPopperArrow" />
    </div>
  );
};
