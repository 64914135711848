import memoize from 'memoizee';
import { STypeGrpIds } from '../../../../shared/utils/sTypeGrpIds';
import { AccountPosition } from '../../../accounts';
import { PositionSubGroup } from '../type';

export const S_GTYPE_ID_CASH = -1;

export const positionsGroupBySTypeGroup = (positions: AccountPosition[] | undefined): PositionSubGroup[] | undefined => {
  if (!positions) {
    return undefined;
  }
  return Object.values(positions.reduce((acc, position) => {
    const gesEstimDMcEstimType = position['GES_ESTIM_D.MC_ESTIMTYPE'];
    if (gesEstimDMcEstimType === 'TITRES') {
      const sTypeGrpId = position['S_TYPE_GRP.ID'];
      if (!sTypeGrpId) {
        throw new Error('S_TYPE_GRP.ID shouldn\'nt be null or undefined when GES_ESTIM_D.MC_ESTIMTYPE is \'TITRES\'');
      }
      const group = {
        'S_TYPE_GRP.ID': sTypeGrpId,
        'S_TYPE_GRP.SHORTCUT': position['S_TYPE_GRP.SHORTCUT'],
        'S_TYPE_GRP.LML_DESCRIPTION': position['S_TYPE_GRP.LML_DESCRIPTION'],
        'S_GTYPE.ID': position['S_GTYPE.ID'],
        positions: [
          ...acc[sTypeGrpId]?.positions ?? [],
          position,
        ],
      };
      return {
        ...acc,
        [sTypeGrpId]: group,
      };
    }
    if (gesEstimDMcEstimType === 'ESPECES') {
      const group = {
        'S_TYPE_GRP.ID': STypeGrpIds.CASH,
        'S_TYPE_GRP.SHORTCUT': 'ESPECES',
        'S_TYPE_GRP.LML_DESCRIPTION': '0=Liquidités\n1=Liquiditeiten\n',
        'S_GTYPE.ID': S_GTYPE_ID_CASH,
        positions: [
          ...acc[STypeGrpIds.CASH]?.positions ?? [],
          position,
        ],
      };
      return {
        ...acc,
        [STypeGrpIds.CASH]: group,
      };
    }
    throw new Error(`GES_ESTIM_D.MC_ESTIMTYPE should be 'TITRES' or 'ESPECES' and is '${gesEstimDMcEstimType}'`);
  }, {}));
};

export const memoPositionsGroupBySTypeGroup: typeof positionsGroupBySTypeGroup = memoize(positionsGroupBySTypeGroup);
