export const TOB_PERCENTAGE = 0.12;

export const getPriceWithTob = (props: {
  price: number | undefined,
  accrued: number | undefined,
  tob?: boolean,
}) => {
  const {
    price,
    accrued,
    tob = false,
  } = props;
  if (price == null || accrued == null) {
    return undefined;
  }
  if (tob === true) {
    return price + (TOB_PERCENTAGE * ((price / 100) + accrued));
  }
  return price;
};
