/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import React from 'react';

const sectionTitleStyle: ouiStyle.InStyle = {
  color: ouiStyle.Constants.Colors.accent,
  fontSize: 'xs',
};

const sectionTitleCss = ouiStyle.makeCss(sectionTitleStyle);

export const SectionTitle = (props: React.PropsWithChildren<{}>): JSX.Element => {
  const {
    children,
  } = props;
  return (
    <h2
      css={sectionTitleCss}
    >
      {children}
    </h2>
  );
};

const justifiedSectionTitleStyle: ouiStyle.InStyle = {
  ...sectionTitleStyle,
  padding: 'simple',
  textAlign: 'center',
};

const justifiedSectionTitleCss = ouiStyle.makeCss(justifiedSectionTitleStyle);

export const JustifiedSectionTitle = (props: React.PropsWithChildren<{}>): JSX.Element => {
  const {
    children,
  } = props;
  return (
    <h2
      css={justifiedSectionTitleCss}
    >
      {children}
    </h2>
  );
};
