import ouiStyle from '@goldwasserexchange/oui-style';
import { isNil } from 'ramda';
import * as React from 'react';
import { NumberDisplay } from '../../../../Components/NumberDisplay';
import { useSetActorApiLoading } from '../../context';
import { LoadingMask } from '../loader';

export const ActorApiNumberDisplayInner = (
  props: {
    data: number | null | undefined,
    isLoading: boolean,
    error: any,
    loadingValue?: number,
    format?: Partial<Omit<Intl.NumberFormatOptions, 'style'> & { style: Exclude<Intl.NumberFormatOptions['style'], 'currency'> }>,
    forcedSigned?: boolean,
    divider?: number,
    nullContent?: string | JSX.Element,
    withSpaceBefore?: boolean,
    withSpaceAfter?: boolean,
    isBlocked?: boolean,
    placeHolderOnBlocked?: boolean,
    withRedactedIcon?: boolean,
    lockBackgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
    lockWidthLines?: ouiStyle.InStyleWithMediaQueries['widthLines'],
    lockContainer?: ouiStyle.InStyleWithMediaQueries['container'],
    lockPaddingTop?: ouiStyle.InStyleWithMediaQueries['paddingTop'],
    lockPaddingBottom?: ouiStyle.InStyleWithMediaQueries['paddingBottom'],
    lockPaddingLeft?: ouiStyle.InStyleWithMediaQueries['paddingLeft'],
    lockPaddingRight?: ouiStyle.InStyleWithMediaQueries['paddingRight'],
    lockAlignItems?: ouiStyle.InStyleWithMediaQueries['alignItems'],
    LockChild?: () => JSX.Element | null,
  },
) => {
  const {
    data,
    isLoading: dataIsLoading,
    error,
    format = {},
    divider = format.style === 'percent' ? 100 : undefined,
    forcedSigned,
    loadingValue = 0,
    nullContent = null,
    withSpaceBefore = false,
    withSpaceAfter = false,
    isBlocked,
    placeHolderOnBlocked = true,
    withRedactedIcon,
    lockBackgroundColor,
    lockWidthLines,
    lockContainer,
    lockPaddingTop,
    lockPaddingBottom,
    lockPaddingLeft,
    lockPaddingRight,
    lockAlignItems,
    LockChild,
  } = props;
  const isLoading = dataIsLoading || !isNil(error);
  const setActorApiLoading = useSetActorApiLoading();
  React.useEffect(() => {
    setActorApiLoading(isLoading);
  }, [isLoading, setActorApiLoading]);
  if ((isNil(data) && !isLoading && !isBlocked)) {
    if (typeof nullContent === 'string') {
    // eslint-disable-next-line react/jsx-no-useless-fragment
      return <>{nullContent}</>;
    }
    return nullContent;
  }
  return (
    <LoadingMask
      isBlocked={isBlocked}
      withRedactedIcon={withRedactedIcon}
      lockBackgroundColor={lockBackgroundColor}
      lockWidthLines={lockWidthLines}
      lockContainer={lockContainer}
      lockPaddingTop={lockPaddingTop}
      lockPaddingBottom={lockPaddingBottom}
      lockPaddingLeft={lockPaddingLeft}
      lockPaddingRight={lockPaddingRight}
      lockAlignItems={lockAlignItems}
      LockChild={LockChild}
    >
      {withSpaceBefore ? ' ' : ''}
      <NumberDisplay
        value={data ?? loadingValue}
        format={format}
        forcedSigned={forcedSigned}
        divider={divider}
        placeholder={(isBlocked && placeHolderOnBlocked) || isLoading ? '\u2011' : undefined}
      />
      {withSpaceAfter ? ' ' : ''}
    </LoadingMask>
  );
};
