import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { sanityImageBuilder } from '../client/imageBuilder';
import { GESanityImage } from '../queries/shared';

export function getImageDimensions(image: GESanityImage) {
  const { asset } = image;
  const { metadata } = asset;
  const { dimensions } = metadata;
  return dimensions;
}

export function getImageLqip(image: GESanityImage | null | undefined) {
  if (image == null) {
    return undefined;
  }
  const { asset } = image;
  const { metadata } = asset;
  const {
    lqip,
  } = metadata;
  return lqip;
}

const LARGEST_VIEWPORT = 1920;

const DEFAULT_MIN_STEP = 0.1;

const DEFAULT_WIDTH_STEPS = [400, 600, 850, 1000, 1150];

const DEFAULT_FULL_WIDTH_STEPS = [360, 414, 768, 1366, 1536, 1920];

export const getImageProps = (props: {
  image: GESanityImage,
  width?: number,
  forcedRatio?: number,
}) => {
  const {
    image,
    width = LARGEST_VIEWPORT,
    forcedRatio,
  } = props;
  const builder = sanityImageBuilder.image(image).fit('max').auto('format').dpr(2);
  const imageDimensions = getImageDimensions(image);
  const baseSizes = [
    width,
    ...(width === LARGEST_VIEWPORT ? DEFAULT_FULL_WIDTH_STEPS : DEFAULT_WIDTH_STEPS),
  ];
  const {
    width: imageWidth,
    height: imageHeight,
    aspectRatio = forcedRatio ?? null,
  } = imageDimensions ?? {
    width: null,
    height: null,
    aspectRatio: forcedRatio,
  };
  const retinaSizes = [
    ...new Set([
      ...baseSizes,
      ...baseSizes.map((size) => size * 2),
      ...baseSizes.map((size) => size * 3),
    ]),
  ]
    .sort((a, b) => a - b)
    .filter(
      (size) => (
        imageWidth != null
          ? size <= imageWidth * 1.1
        && size <= width * 3
          : true
      ),
    )
    .filter((size, i, arr) => {
      const nextSize = arr[i + 1];
      if (nextSize) {
        return nextSize / size > DEFAULT_MIN_STEP + 1;
      }

      return true;
    });
  const lqip = getImageLqip(image);
  if (forcedRatio != null && imageWidth != null) {
    const forcedRatioHeight = Math.floor(imageWidth / forcedRatio);
    if (forcedRatioHeight < imageHeight) {
      const forcedRatioTop = Math.floor((imageHeight - forcedRatioHeight) / 2);
      return {
        src: builder.width(width).rect(0, forcedRatioTop, imageWidth, forcedRatioHeight).url(),
        srcSet: retinaSizes
          .map((size) => `${builder.width(size).rect(0, forcedRatioTop, imageWidth, forcedRatioHeight).url()} ${size}w`)
          .join(', '),
        sizes: `(max-width: ${width}px) 100vw, ${width}px`,
        ...(aspectRatio != null
          ? {
            width: retinaSizes[0],
            height: retinaSizes[0] / (forcedRatio ?? aspectRatio),
          }
          : {}),
        imageDimensions,
        lqip,
      };
    }
  }
  if (forcedRatio != null && imageHeight != null) {
    const forcedRatioWidth = Math.floor(imageHeight * forcedRatio);
    if (forcedRatioWidth < imageWidth) {
      const forcedRatioLeft = Math.floor((imageWidth - forcedRatioWidth) / 2);
      return {
        src: builder.width(width).rect(forcedRatioLeft, 0, forcedRatioWidth, imageHeight).url(),
        srcSet: retinaSizes
          .map((size) => `${builder.width(size).rect(forcedRatioLeft, 0, forcedRatioWidth, imageHeight).url()} ${size}w`)
          .join(', '),
        sizes: `(max-width: ${width}px) 100vw, ${width}px`,
        ...(aspectRatio != null
          ? {
            width: retinaSizes[0],
            height: retinaSizes[0] / (forcedRatio ?? aspectRatio),
          }
          : {}),
        imageDimensions,
        lqip,
      };
    }
  }
  return {
    src: builder.width(width).url(),
    srcSet: retinaSizes
      .map((size) => `${builder.width(size).url()} ${size}w`)
      .join(', '),
    sizes: `(max-width: ${width}px) 100vw, ${width}px`,
    ...(aspectRatio != null
      ? {
        width: retinaSizes[0],
        height: retinaSizes[0] / (forcedRatio ?? aspectRatio),
      }
      : {}),
    imageDimensions,
    lqip,
  };
};

export const loadingImageStyle = {
  height: '10px !important',
  width: '10px !important',
  position: 'absolute' as const,
  zIndex: -10,
  opacity: 0,
  pointerEvents: 'none' as const,
  userSelect: 'none' as const,
};

export const useImageOnLoad = () => {
  const handler = useState(false);
  const [loaded, setLoaded] = handler;
  const ref = useRef<HTMLImageElement>(null);
  const onLoad = useCallback(() => {
    setLoaded(true);
  }, [setLoaded]);
  useEffect(() => {
    if (ref.current?.complete) {
      onLoad();
    }
  }, [onLoad]);
  const result = useMemo(() => ({
    ref,
    loaded,
    onLoad,
  }), [
    ref,
    loaded,
    onLoad,
  ]);
  return result;
};

export const useSanityImageCss = (props: {
  style: ouiStyle.InStyle | ouiStyle.InStyleWithMediaQueries,
  loaded: boolean,
  hasLqip: boolean,
}) => {
  const {
    style,
    loaded,
    hasLqip,
  } = props;
  const cssObject = useMemo(
    () => ({
      css: ouiStyle.mediaQuery({
        ...ouiStyle.makeCss(style),
        ...(loaded === false
          ? loadingImageStyle
          : {}
        ),
      }),
      loaderCss: ouiStyle.mediaQuery(ouiStyle.makeCss({
        ...style,
        filter: hasLqip ? 'blur(10px)' : undefined,
      })),
    }),
    [
      style,
      hasLqip,
      loaded,
    ],
  );
  return cssObject;
};

export const useImageProps = (props: {
  image?: GESanityImage,
  width?: number,
  forcedRatio?: number,
}) => {
  const {
    image,
    width,
    forcedRatio,
  } = props;
  return useMemo(
    () => (image != null ? getImageProps({ image, width, forcedRatio }) : null),
    [image, width, forcedRatio],
  );
};
