export type EventTypeString = 'OPEN';

export const eventType: EventTypeString = 'OPEN';

export type EventType = {
  type: EventTypeString,
};

export const eventBuilder = (): EventType => ({
  type: eventType,
});
