var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a, _b, _c, _d;
import * as STOCK from './fields/STOCK';
import * as BOND from './fields/BOND';
import * as PERPETUAL_BOND from './fields/PERPETUAL_BOND';
import * as SUBORDINATED_BOND from './fields/SUBORDINATED_BOND';
import * as RAW_MATERIAL_TRACKER from './fields/RAW_MATERIAL_TRACKER';
import * as TRACKER from './fields/TRACKER';
import * as FUND from './fields/FUND';
import { AccountType, STypeGrp } from '../../types';
export var makeAllQuestionnaireInitialValues = function () { return (__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, STOCK.initialConseilGestionValues()), BOND.initialRTOValues()), BOND.initialConseilGestionValues()), PERPETUAL_BOND.initialRTOValues()), PERPETUAL_BOND.initialConseilGestionValues()), SUBORDINATED_BOND.initialRTOValues()), SUBORDINATED_BOND.initialConseilGestionValues()), FUND.initialConseilGestionValues()), TRACKER.initialConseilGestionValues()), RAW_MATERIAL_TRACKER.initialRTOValues()), RAW_MATERIAL_TRACKER.initialConseilGestionValues())); };
export var knowledgeQuestionnaireInitialValueByServiceAndSTypeGrp = (_a = {},
    _a[AccountType.RTO] = (_b = {},
        _b[STypeGrp.ACTIONS] = function () { return ({}); },
        _b[STypeGrp.OBLIGATIONS] = BOND.initialRTOValues,
        _b[STypeGrp.OBLIGATIONS_SUB] = SUBORDINATED_BOND.initialRTOValues,
        _b[STypeGrp.OBLIGATIONS_PERP] = PERPETUAL_BOND.initialRTOValues,
        _b[STypeGrp.FONDS] = function () { return ({}); },
        _b[STypeGrp.TRACKERS] = function () { return ({}); },
        _b[STypeGrp.TRACKERS_MAT] = RAW_MATERIAL_TRACKER.initialRTOValues,
        _b),
    _a[AccountType.CONSEIL] = (_c = {},
        _c[STypeGrp.ACTIONS] = STOCK.initialConseilGestionValues,
        _c[STypeGrp.OBLIGATIONS] = BOND.initialConseilGestionValues,
        _c[STypeGrp.OBLIGATIONS_SUB] = SUBORDINATED_BOND.initialConseilGestionValues,
        _c[STypeGrp.OBLIGATIONS_PERP] = PERPETUAL_BOND.initialConseilGestionValues,
        _c[STypeGrp.FONDS] = FUND.initialConseilGestionValues,
        _c[STypeGrp.TRACKERS] = TRACKER.initialConseilGestionValues,
        _c[STypeGrp.TRACKERS_MAT] = RAW_MATERIAL_TRACKER.initialConseilGestionValues,
        _c),
    _a[AccountType.GESTION] = (_d = {},
        _d[STypeGrp.ACTIONS] = STOCK.initialConseilGestionValues,
        _d[STypeGrp.OBLIGATIONS] = BOND.initialConseilGestionValues,
        _d[STypeGrp.OBLIGATIONS_SUB] = SUBORDINATED_BOND.initialConseilGestionValues,
        _d[STypeGrp.OBLIGATIONS_PERP] = PERPETUAL_BOND.initialConseilGestionValues,
        _d[STypeGrp.FONDS] = FUND.initialConseilGestionValues,
        _d[STypeGrp.TRACKERS] = TRACKER.initialConseilGestionValues,
        _d[STypeGrp.TRACKERS_MAT] = RAW_MATERIAL_TRACKER.initialConseilGestionValues,
        _d),
    _a);
