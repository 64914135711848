import * as React from 'react';
import { sameIssuerSortDirectionContext } from './context';

export const SameIssuerSortDirectionContext = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  const handlers = React.useState<'ASC' | 'DESC'>('ASC');
  return (
    <sameIssuerSortDirectionContext.Provider value={handlers}>
      {children}
    </sameIssuerSortDirectionContext.Provider>
  );
};
