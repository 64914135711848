import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { DefaultDomInputIconContent } from './content';
import { BaseBuilderWithNameTransformAndContainerStyle } from '../../../../utils';
import {
  DefaultDomInputValidIconContainer,
  DefaultDomInputValidIconContent,
} from './valid';
import {
  DomInputErrorIconContainer,
  DefaultDomInputErrorIconContent,
} from './error';
import {
  DefaultDomInputHelpIconBase,
  InputHelpIconContext,
  DomInputHelpIconContainer,
  DefaultDomInputHelpIconContent,
  HelpInputPopperContainer,
  HelpInputPopperArrowContainer,
  HelpInputPopperArrowContent,
} from './help';

export const inputIconContentDomDefaultTopModelsObject = {
  InputIconContent: (
    <TopModel name="InputIconContent">{DefaultDomInputIconContent}</TopModel>
  ),
  InputValidIconBase: (
    <TopModel name="InputValidIconBase" rampName="InputValidIcon">
      {BaseBuilderWithNameTransformAndContainerStyle}
    </TopModel>
  ),
  InputValidIconContainer: (
    <TopModel name="InputValidIconContainer">
      {DefaultDomInputValidIconContainer}
    </TopModel>
  ),
  InputValidIconContent: (
    <TopModel name="InputValidIconContent">
      {DefaultDomInputValidIconContent}
    </TopModel>
  ),
  InputErrorIconBase: (
    <TopModel name="InputErrorIconBase" rampName="InputErrorIcon">
      {BaseBuilderWithNameTransformAndContainerStyle}
    </TopModel>
  ),
  InputErrorIconContainer: (
    <TopModel name="InputErrorIconContainer">
      {DomInputErrorIconContainer}
    </TopModel>
  ),
  InputErrorIconContent: (
    <TopModel name="InputErrorIconContent">
      {DefaultDomInputErrorIconContent}
    </TopModel>
  ),
  InputHelpIconBase: (
    <TopModel name="InputHelpIconBase">{DefaultDomInputHelpIconBase}</TopModel>
  ),
  InputHelpIconContext: (
    <TopModel name="InputHelpIconContext">{InputHelpIconContext}</TopModel>
  ),
  InputHelpPopperContainer: (
    <TopModel name="InputHelpPopperContainer">
      {HelpInputPopperContainer}
    </TopModel>
  ),
  InputHelpPopperArrowContainer: (
    <TopModel name="InputHelpPopperArrowContainer">
      {HelpInputPopperArrowContainer}
    </TopModel>
  ),
  InputHelpPopperArrowContent: (
    <TopModel name="InputHelpPopperArrowContent">
      {HelpInputPopperArrowContent}
    </TopModel>
  ),
  InputHelpIconContainer: (
    <TopModel name="InputHelpIconContainer">
      {DomInputHelpIconContainer}
    </TopModel>
  ),
  InputHelpIconContent: (
    <TopModel name="InputHelpIconContent">
      {DefaultDomInputHelpIconContent}
    </TopModel>
  ),
};
