import * as React from 'react';
import ouiBase from '@goldwasserexchange/oui';
import { useUpdateStickyPosition } from '../hooks';
import { useVisibleIndexesRefs } from '../../visibleIndexes';
import { useSelectedIndex } from '../../../../../../shared/components/list/context';

export const useRefreshStickyPosition = () => {
  const [lastVisibleStartIndex, lastVisibleStopIndex] = useVisibleIndexesRefs();
  const updateStickyPosition = useUpdateStickyPosition(
    lastVisibleStartIndex,
    lastVisibleStopIndex,
  );
  const selectedIndex = useSelectedIndex();
  const open = ouiBase.List.useSelectIsOpen();
  React.useEffect(() => {
    updateStickyPosition();
  }, [selectedIndex, open]);
};
