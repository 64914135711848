import { useTransition } from 'react-spring';

const scaleEnterLeave = {
  from: {
    transform: 'scale(0)',
  },
  enter: {
    transform: 'scale(1)',
  },
  leave: {
    transform: 'scale(0)',
  },
};

export function useScaleEnterLeave(item: string) {
  const transition = useTransition(item, scaleEnterLeave);
  return transition;
}
