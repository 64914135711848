import * as React from 'react';

export function CheckboxListInputListElementLabelDisabledReasonContainer(props: React.PropsWithChildren<{}>) {
  const { children } = props;
  const openingBrace = '(';
  const closingBrace = ')';
  return (
    <span>
      {' '}
      {openingBrace}
      {children}
      {closingBrace}
    </span>
  );
}
