var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useTopModelExistByNameWithFallbacksAndNameTransform } from '@goldwasserexchange/react-topmodel';
import { useHeadingSectiondepth, useHeadingSectionIndex } from '../../../context';
import { useHeadingSectionName } from '../../../context/name';
export var useTitleContentRampNames = function () {
    var headingName = useHeadingSectionName();
    var headingDepth = useHeadingSectiondepth();
    var headingIndex = useHeadingSectionIndex();
    var result = [
        "TitleContent-".concat(headingName),
        [
            "TitleContent-".concat(headingDepth, "-").concat(headingIndex),
            "TitleContent-".concat(headingDepth),
            'TitleContent',
        ],
    ];
    return result;
};
export var useHasTitleContent = function () {
    var _a = __read(useTitleContentRampNames(), 2), rampName = _a[0], rampFallbackNames = _a[1];
    var exists = useTopModelExistByNameWithFallbacksAndNameTransform(rampName, rampFallbackNames);
    return exists;
};
export var useTitleOrderIndicatorRampNames = function () {
    var headingName = useHeadingSectionName();
    var headingDepth = useHeadingSectiondepth();
    var headingIndex = useHeadingSectionIndex();
    var result = [
        "TitleOrderIndicator-".concat(headingName),
        [
            "TitleOrderIndicator-".concat(headingDepth, "-").concat(headingIndex),
            "TitleOrderIndicator-".concat(headingDepth),
            'TitleOrderIndicator',
        ],
    ];
    return result;
};
export var useTitleContainerRampNames = function () {
    var headingName = useHeadingSectionName();
    var headingDepth = useHeadingSectiondepth();
    var headingIndex = useHeadingSectionIndex();
    var result = [
        "TitleContainer-".concat(headingName),
        [
            "TitleContainer-".concat(headingDepth, "-").concat(headingIndex),
            "TitleContainer-".concat(headingDepth),
            'TitleContainer',
        ],
    ];
    return result;
};
