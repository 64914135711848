import * as React from 'react';
import { TopModelMerge, TopModelNameTransformProvider } from '@goldwasserexchange/react-topmodel';
import { RippleEffectStructure } from './structure';
import { rippleEffectDefaultTopModelsObject } from './topmodels';
import { rippleEffectPrefixer } from './utils';
export function RippleEffect(props) {
    var sourceBackgroundColor = props.sourceBackgroundColor, children = props.children;
    return (React.createElement(TopModelNameTransformProvider, { transformFn: rippleEffectPrefixer },
        React.createElement(TopModelMerge, { topModels: rippleEffectDefaultTopModelsObject },
            React.createElement(RippleEffectStructure, { sourceBackgroundColor: sourceBackgroundColor }, children))));
}
