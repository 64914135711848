/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const stepperPlaceHolderInnerStyle: ouiStyle.InStyle = {
  lines: 'full',
};

const stepperPlaceHolderInnerCss = ouiStyle.makeCss(stepperPlaceHolderInnerStyle);

export const StepperPlaceholderInner = (props: React.PropsWithChildren<{
  totalWidth: number,
}>) => {
  const {
    totalWidth,
    children,
  } = props;
  return (
    <div
      css={stepperPlaceHolderInnerCss}
      style={{
        minWidth: `${totalWidth}rem`,
      }}
    >
      {children}
    </div>
  );
};
