import React from 'react';
import { Trans } from '@lingui/macro';
import ouiBase from '@goldwasserexchange/oui';
import { dataStructure, MarStatut } from '@goldwasserexchange/oblis-frontend-utils';
import { FilterCurrentUser } from '../../../Sections/onboarding/form/constants';
import { LowerAlphaListContainer } from '../../../lowerAlphaList';

export const tAddMarketReglementationTypeData = {
  [MarStatut.AUCUN]: { ID: 0, value: <Trans>Non</Trans> },
  [MarStatut.PCA]: {
    ID: 1,
    value: (
      <Trans>
        <FilterCurrentUser>
          Non mais je suis étroitement lié à une personne exerçant de telles responsabilités
        </FilterCurrentUser>
        <FilterCurrentUser isNegative>
          Non mais cette personne est étroitement liée à une personne exerçant de telles responsabilités
        </FilterCurrentUser>
      </Trans>
    ),
  },
  [MarStatut.PDMR]: { ID: 2, value: <Trans>Oui</Trans> },
};

export const tAddMarketRegulationTypeValidatorLogic: ouiBase.utils.hookstate.FormValueFilterLogic<'requiredString' | 'nilCast'> = dataStructure.T_ADD.fields.TYPE.logic.makeTAddIsNotMoralOrIgnored({
  truthy: {
    result: 'requiredString',
  },
  falsy: { result: 'nilCast' },
});

export const tAddMarketReglementationTypeLabel = (
  <>
    <FilterCurrentUser>
      <Trans>Êtes-vous une personne exerçant des responsabilités dirigeantes d'une société cotée sur un marché réglementé ou un système multilatéral de négociation ?</Trans>
    </FilterCurrentUser>
    <FilterCurrentUser isNegative>
      <Trans>S'agit-il d'une personne exerçant des responsabilités dirigeantes d'une société cotée sur un marché réglementé ou un système multilatéral de négociation ?</Trans>
    </FilterCurrentUser>
  </>
);

export const tAddMarketReglementationTypeHelpPopper = (
  <Trans>
    <div>
      <p>
        Une personne qui, au sein d'un émetteur, un participant au marché des quotas d'émission ou une autre entité visée à
        l'article 19, paragraphe 10 du Règlement n°596/2014, est:
      </p>
      <LowerAlphaListContainer>
        <li>
          un membre de l'organe d'administration, de gestion ou de surveillance de cette entité ; ou
        </li>
        <li>
          un responsable de haut niveau qui, sans être membre des organes visés au point a), dispose d'un accès régulier à des informations privilégiées concernant directement ou indirectement cette entité et du pouvoir de prendre des décisions de gestion concernant l'évolution future et la stratégie d'entreprise de cette entité.
        </li>
      </LowerAlphaListContainer>
      <p>
        Une personne étroitement liée est:
      </p>
      <LowerAlphaListContainer>
        <li>
          le conjoint ou un partenaire considéré comme l'équivalent du conjoint conformément au droit national ;
        </li>
        <li>
          l'enfant à charge conformément au droit national ;
        </li>
        <li>
          un parent qui appartient au même ménage depuis au moins un an à la date de la transaction concernée ;
        </li>
        <li>
          une personne morale dont les responsabilités dirigeantes sont exercées par une personne exerçant des responsabilités dirigeantes qui est directement ou indirectement contrôlé(e) par cette personne, qui a été constitué(e) au bénéfice de cette personne, ou dont les intérêts économiques sont substantiellement équivalents à ceux de cette personne.
        </li>
      </LowerAlphaListContainer>
    </div>
  </Trans>
);
