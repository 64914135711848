import useSWR, { KeyedMutator } from 'swr';
import { useMemo } from 'react';
import { Persons } from '../../enum';
import { PersonDevices } from '../type';
import { useIsConnected } from '../../../../../../Components/Auth';
import { useDeviceFetcherWithSend } from '../utils/fetcher';

type BaseUsePersonDevicesApiResponse = {
  isLoading: boolean,
  error?: any,
  mutate: KeyedMutator<PersonDevices[]>,
  isValidating: boolean,
}

type UsePersonDevicesApiResponse = {
  data: undefined | PersonDevices[],
} & BaseUsePersonDevicesApiResponse;

type FilterPredicate = (personDevice: PersonDevices) => boolean;

type SortPredicate = (prevPersonDevice: PersonDevices, nextPersonDevice: PersonDevices) => number;

type UsePersonDeviceApiProps = {
  filterPredicate?: FilterPredicate,
  sortPredicate?: SortPredicate,
};

export function usePersonDevicesApi(props: UsePersonDeviceApiProps): UsePersonDevicesApiResponse {
  const {
    filterPredicate,
    sortPredicate,
  } = props;
  const isConnected = useIsConnected();
  const fetcher = useDeviceFetcherWithSend();
  const result = useSWR<PersonDevices[], any>(
    isConnected ? Persons.Devices : null,
    fetcher,
  );
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    let { data } = result;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      if (filterPredicate) {
        data = data.filter((device) => filterPredicate(device));
      }
    }
    return ({
      ...result,
      data,
      isLoading,
    });
  }, [result, filterPredicate, sortPredicate]);
  return resultWithLoading;
}

type TransformArrayFunction<T> = (personDevices: PersonDevices[] | undefined) => T

type UsePersonDevicesApiTransformProps<T> = {
  transformFunction: TransformArrayFunction<T>,
} & UsePersonDeviceApiProps

type UsePersonDevicesApiTransformResponse<T> = {
  data: T | undefined,
} & BaseUsePersonDevicesApiResponse

export function usePersonDevicesTransformApi<T>(props: UsePersonDevicesApiTransformProps<T>): UsePersonDevicesApiTransformResponse<T> {
  const {
    filterPredicate,
    sortPredicate,
    transformFunction,
  } = props;
  const response = usePersonDevicesApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: !isLoading && !error ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UsePersonDeviceApiResponse = {
  data: PersonDevices | undefined,
} & BaseUsePersonDevicesApiResponse;

export const usePersonDeviceApi = (props: UsePersonDeviceApiProps): UsePersonDeviceApiResponse => {
  const {
    filterPredicate,
    sortPredicate,
  } = props;
  const isConnected = useIsConnected();
  const fetcher = useDeviceFetcherWithSend();
  const result = useSWR<PersonDevices[], any>(
    isConnected ? Persons.Devices : null,
    fetcher,
  );
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    // eslint-disable-next-line prefer-destructuring
    let data: PersonDevices[] | PersonDevices | undefined = result.data;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      if (filterPredicate) {
        data = data.find((device) => filterPredicate(device));
      }
      if (Array.isArray(data)) {
        // eslint-disable-next-line prefer-destructuring
        data = data[0];
      }
    }
    return ({
      ...result,
      data,
      isLoading,
    });
  }, [result, filterPredicate, sortPredicate]);
  return resultWithLoading;
};

type TransformFunction<T> = (personDevice: PersonDevices | undefined) => T

type UsePersonDeviceApiTransformProps<T> = {
  transformFunction: TransformFunction<T>,
} & UsePersonDeviceApiProps

export function usePersonDeviceTransformApi<T>(props: UsePersonDeviceApiTransformProps<T>): UsePersonDevicesApiTransformResponse<T> {
  const {
    filterPredicate,
    sortPredicate,
    transformFunction,
  } = props;
  const response = usePersonDeviceApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: !isLoading && !error ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UsePersonDeviceFieldApiResponse<Field extends keyof PersonDevices> = {
  data: PersonDevices[Field] | undefined,
} & BaseUsePersonDevicesApiResponse;

type UsePersonDeviceFieldApiProps<Field extends keyof PersonDevices> = {
  field: Field,
} & UsePersonDeviceApiProps

export function usePersonDeviceFieldApi<Field extends keyof PersonDevices>(props: UsePersonDeviceFieldApiProps<Field>): UsePersonDeviceFieldApiResponse<Field> {
  const {
    filterPredicate,
    sortPredicate,
    field,
  } = props;
  const response = usePersonDeviceApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
    } = response;
    return {
      ...response,
      data: data ? data[field] : undefined,
    };
  }, [response, field]);
  return result;
}
