import ouiBase from '@goldwasserexchange/oui';
import { useCallback } from 'react';
import { AccountAvailableCash } from '../../../availableCash';

export const useFilterByCCurrencyCode = () => {
  const primary = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const filterFn = useCallback(
    (accountAvailableCash: AccountAvailableCash) => accountAvailableCash['C_CURRENCY.CODE'] === primary,
    [primary],
  );
  return filterFn;
};
