import { Trans } from '@lingui/macro';
import { isNil } from 'ramda';
import * as React from 'react';
import { reach } from 'yup';
import { Space } from '../../../../../../../../Components/Space';
import { useFormValidator } from '../../../../../../../Form';
import { useGetFieldPath, minError } from '../../../../../../context';

const IsStringMinError = () => {
  const validationSchema = useFormValidator();
  const fieldPath = useGetFieldPath();
  const schema = validationSchema ? reach(validationSchema, fieldPath) : null;
  const decription = schema ? schema.describe() : null;
  const tests = decription ? decription.tests : [];
  const test = tests.find((t) => t.name === minError) ?? {};
  const {
    params,
  } = test;
  const {
    min,
  } = params ?? { min: null };
  return !isNil(min) ? (
    <Trans>
      Ce champs doit faire au moins
      <Space />
      {min}
      <Space />
      caractères
    </Trans>
  ) : (
    <Trans>
      Ce champs doit être plus long
    </Trans>
  );
};

export const inputMinErrorMessage = <IsStringMinError />;
