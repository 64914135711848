import { BondFrequency } from '@goldwasserexchange/common/lib/bond';
import { BondResponse } from '../type';

export const transformSAccJrsFreqToBondFrequency = (sAccJrsFreq: BondResponse['S_ACC_JRS.FREQ'] | undefined) => {
  if (sAccJrsFreq === 1) {
    return BondFrequency.ANNUAL;
  }
  if (sAccJrsFreq === 2) {
    return BondFrequency.SEMI_ANNUAL;
  }
  if (sAccJrsFreq === 4) {
    return BondFrequency.QUARTERLY;
  }
  return undefined;
};
