import { msg } from '@lingui/macro';
import ouiDom from '@goldwasserexchange/oui-dom';
import * as React from 'react';
import { useAtomValue } from 'jotai';
import { useLingui } from '@lingui/react';
import { I18n } from '@lingui/core';
import { ActorApiStringDisplay } from '../../../../../actor/components/display/string';
import { useLastSubmittedOnboardingsBySk } from '../../../../utils/dynamoDb/onboarding/get';
import { OnboardingSave } from '../../../../utils/dynamoDb/onboarding/type';
import { ValidSections, pathnameUrlLanguageAtom } from '../../../../../history';

const getStatus = (i18n: I18n, status?: OnboardingSave['status']) => {
  switch (status) {
    case 'PENDING':
      return i18n._(msg`En cours`);
    case 'SUBMITTED':
      return i18n._(msg`En attente de signature`);
    case 'SIGNED':
    case 'VALIDATED':
      return i18n._(msg`En attente de validation`);
    default:
      return '';
  }
};

export const OnboardingStatus = (props: {
  withPending?: boolean,
}) => {
  const {
    withPending,
  } = props;
  const {
    data: onboardingSave,
    isLoading,
    error,
  } = useLastSubmittedOnboardingsBySk({ withPending });
  const language = useAtomValue(pathnameUrlLanguageAtom);
  const {
    i18n,
  } = useLingui();
  const status = onboardingSave?.status;
  const label = getStatus(i18n, status);
  if (status === 'PENDING') {
    return (
      <ouiDom.A.A
        to={`/${language}/${ValidSections.ONBOARDING}/form`}
      >
        <ActorApiStringDisplay
          data={label !== '' ? label : undefined}
          isLoading={isLoading}
          error={error}
        />
      </ouiDom.A.A>
    );
  }
  return (
    <ActorApiStringDisplay
      data={label !== '' ? label : undefined}
      isLoading={isLoading}
      error={error}
    />
  );
};
