import { useTopModelNameTransformFn, prefix } from '@goldwasserexchange/react-topmodel';
import { useCallback } from 'react';

export const listPrefixer = prefix('List');

export const inputListPrefixerBuilder = (
  previousTransform: (str: string) => string,
) => (str: string) => previousTransform(prefix('InputList')(str));

export const useInputListPrefixer = () => {
  const parentTransformFn = useTopModelNameTransformFn();
  const listInputListPrefixer = useCallback(
    (str: string) => inputListPrefixerBuilder(parentTransformFn)(str),
    [parentTransformFn],
  );
  return listInputListPrefixer;
};
