import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import Accounts from '../../../../../accounts';
import { AccountHistoryFileIconModalButton } from './historyComponent';
import { AccountPositionsHistoryFileIconModalButton } from './positionsHistoryComponent';
import { AccountDocumentsFileIconModalButton } from './documentComponent';

export const FileIconModalButton = (props: {
  service: Accounts.History | Accounts.Documents | Accounts.PositionsHistory,
  hasFileName?: boolean,
  size?: string | number | undefined,
  transform?: ouiStyle.InStyle['transform'],
}) => {
  const {
    service,
    hasFileName,
    size,
    transform,
  } = props;
  if (service === Accounts.History) {
    return (
      <AccountHistoryFileIconModalButton
        hasFileName={hasFileName}
        size={size}
        transform={transform}
      />
    );
  }
  if (service === Accounts.Documents) {
    return (
      <AccountDocumentsFileIconModalButton
        hasFileName={hasFileName}
        size={size}
        transform={transform}
      />
    );
  }
  if (service === Accounts.PositionsHistory) {
    return (
      <AccountPositionsHistoryFileIconModalButton
        hasFileName={hasFileName}
        size={size}
        transform={transform}
      />
    );
  }
  return null;
};
