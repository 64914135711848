import * as React from 'react';
import { Toast, toast } from 'react-hot-toast';
import ouiDom from '@goldwasserexchange/oui-dom';
import ouiStyle from '@goldwasserexchange/oui-style';
import { X } from 'react-feather';
import { ToastDismissContainer } from './container';

export const GEToastDismiss = (props: {
  toast: Toast,
}) => {
  const {
    toast: t,
  } = props;
  const dismiss = React.useCallback(() => toast.dismiss(t.id), [t.id]);
  return (
    <ToastDismissContainer>
      <ouiDom.Button.Button
        type="button"
        onClick={dismiss}
        borderColor={ouiStyle.Constants.Colors.inverted}
        paddingTop="none"
        paddingBottom="none"
        paddingLeft="none"
        paddingRight="none"
      >
        <X />
      </ouiDom.Button.Button>
    </ToastDismissContainer>
  );
};
