/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { ChevronDown } from 'react-feather';
import { animated } from 'react-spring';
import { useToolbarDropDownOpenSpring } from '../context';

export const ToolbarDropDownToggleIcon = () => {
  const open = useToolbarDropDownOpenSpring();
  const style = {
    transform: open.to((v: number) => `translateX(-${v * 0.6}rem) rotate(${(1 - v) * 180}deg)`),
  };
  return (
    <animated.span
      style={style}
    >
      <ChevronDown size="1rem" />
    </animated.span>
  );
};
