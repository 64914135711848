import { useCallback, useRef } from 'react';

// inspired by https://gist.github.com/reecelucas/2f510e6b8504008deaaa52732202d2da
export const useScrollBlock = () => {
  const scrollBlocked = useRef(false);
  const setScrollBlock = useCallback((target: boolean) => {
    if (!document) return;
    const html = document.documentElement;
    const { body } = document;
    if (!body || !body.style) return;
    if (target === true && !scrollBlocked.current) {
      const scrollBarWidth = window.innerWidth - html.clientWidth;
      const bodyPaddingRight = parseInt(window.getComputedStyle(body).getPropertyValue('padding-right'), 10) || 0;
      /**
     * 1. Fixes a bug in iOS and desktop Safari whereby setting
     *    `overflow: hidden` on the html/body does not prevent scrolling.
     * 2. Fixes a bug in desktop Safari where `overflowY` does not prevent
     *    scroll if an `overflow-x` style is also applied to the body.
     */
      html.style.position = 'relative'; /* [1] */
      html.style.overflow = 'hidden'; /* [2] */
      body.style.position = 'relative'; /* [1] */
      body.style.overflow = 'hidden'; /* [2] */
      body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;
      scrollBlocked.current = true;
    }
    if (target === false && scrollBlocked.current) {
      html.style.position = '';
      html.style.overflow = '';
      body.style.position = '';
      body.style.overflow = '';
      body.style.paddingRight = '';
      scrollBlocked.current = false;
    }
  }, [scrollBlocked]);
  return setScrollBlock;
};
