import React from 'react';

import {
  StateFilter,
} from '../../../../Machine';
import { KYCShared } from './shared';
import { KYCRTO } from './RTO';
import { KYCConseil } from './conseil';
import { KYCGestion } from './gestion/components';

export * from './RTO';

export const KYC = () => (
  <>
    <StateFilter is="KYC.RTO">
      <KYCRTO />
    </StateFilter>
    <StateFilter is="KYC.Conseil">
      <KYCConseil />
    </StateFilter>
    <StateFilter is="KYC.Gestion">
      <KYCGestion />
    </StateFilter>
    <KYCShared />
  </>

);
