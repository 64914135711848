import { F } from 'ramda';
import { assign } from 'xstate';
import { AppBarEvent } from '../../../events';
import { AppBarMachineContext } from '../../../machineContext';

export const name = 'setClosing';

export const fn = assign<AppBarMachineContext, AppBarEvent>({
  closed: () => 1,
  openAction: () => 1,
  immediate: F,
});

export const action = {
  [name]: fn,
};

export default action;
