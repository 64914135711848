import { PersonType, } from '../../../../../types';
var onboardingPath = 'onboarding';
var personTypePath = 'personType';
export var logic = {
    name: "".concat(onboardingPath, ".").concat(personTypePath),
    is: PersonType.MORAL,
    alternatives: {
        truthy: {
            result: 'moral',
        },
        falsy: {
            result: 'physic',
        },
    },
};
