import { BondResponse } from '../../type';
import { useBondsBondFieldApi, useBondsBondTransformApi } from '../api';

export const useBondsBondSQuotePriceMax = () => {
  const response = useBondsBondFieldApi('S_QUOTE.PRICE_MAX');
  return response;
};

const priceMaxOrPriceClose = (bondsBond: BondResponse | undefined) => {
  if (bondsBond == null) {
    return undefined;
  }
  const price = bondsBond['S_QUOTE.PRICE_MAX'] ?? bondsBond['S_QUOTE.PRICE_CLOSE'];
  return price;
};

export const useBondsBondPriceMaxOrPriceClose = () => {
  const response = useBondsBondTransformApi(priceMaxOrPriceClose);
  return response;
};
