import * as React from 'react';
import { currentSameIssuerSAccIdContext } from './context';

export const CurrentSameIssuerSAccIdProvider = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  const handlers = React.useState('');
  return (
    <currentSameIssuerSAccIdContext.Provider value={handlers}>
      {children}
    </currentSameIssuerSAccIdContext.Provider>
  );
};
