var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import React, { useMemo } from 'react';
var imageStyle = {
    position: 'absolute',
    widthLines: 'full',
    lines: 'full',
    top: 0,
    left: 0,
};
var useImageOnLoad = function () {
    var _a = __read(React.useState(false), 2), loaded = _a[0], setLoaded = _a[1];
    var ref = React.useRef(null);
    var onLoad = React.useCallback(function () {
        setLoaded(true);
    }, [setLoaded]);
    React.useEffect(function () {
        var _a;
        if ((_a = ref.current) === null || _a === void 0 ? void 0 : _a.complete) {
            onLoad();
        }
    }, [onLoad]);
    return {
        ref: ref,
        loaded: loaded,
        onLoad: onLoad,
    };
};
export var ImageContent = function (props) {
    var src = props.src, alt = props.alt, lqip = props.lqip, aspectRatio = props.aspectRatio, loading = props.loading, decoding = props.decoding, srcSet = props.srcSet, sizes = props.sizes, width = props.width, height = props.height, objectFit = props.objectFit;
    var _a = useImageOnLoad(), ref = _a.ref, onLoad = _a.onLoad, loaded = _a.loaded;
    var imageCss = useMemo(function () { return ouiStyle.mediaQuery(__assign(__assign(__assign({}, (ouiStyle.makeCss(__assign(__assign({}, imageStyle), { objectFit: objectFit })))), (src == null
        ? {
            backgroundColor: ouiStyle.Constants.Colors.fade,
        }
        : {})), (loaded === false
        ? {
            height: '10px !important',
            width: '10px !important',
            position: 'absolute',
            zIndex: -10,
            opacity: 0,
            pointerEvents: 'none',
            userSelect: 'none',
        }
        : {
            aspectRatio: "".concat(aspectRatio),
        }))); }, [aspectRatio, loaded, src]);
    var loaderCss = useMemo(function () { return (__assign(__assign({}, ouiStyle.makeCss(imageStyle)), { aspectRatio: "".concat(aspectRatio), filter: lqip != null ? 'blur(10px)' : undefined, backgroundColor: ouiStyle.Constants.Colors.fade })); }, [
        lqip,
        aspectRatio,
    ]);
    return (jsx(React.Fragment, null,
        !loaded
            ? (jsx("img", { src: lqip, alt: alt, css: loaderCss }))
            : null,
        jsx("img", { ref: ref, src: src, css: imageCss, alt: alt, loading: loading, decoding: decoding, srcSet: srcSet, sizes: sizes, width: width, height: height, onLoad: onLoad })));
};
