import ouiBase from '@goldwasserexchange/oui';
import { makeKERequiredLogicRecord } from './logic';
export var getMakeKERequiredLogic = function (financialProduct) {
    var makeLogic = makeKERequiredLogicRecord[financialProduct];
    return makeLogic;
};
var onboardingPath = 'onboarding';
var selectedService = 'selectedService';
export var getKERequiredByService = function (financialProduct, service) {
    var _a;
    var logic = makeKERequiredLogicRecord[financialProduct]({
        alternatives: {
            required: {
                result: true,
            },
            free: {
                result: null,
            },
            notRequired: {
                result: false,
            },
        },
    });
    var states = (_a = {},
        _a["".concat(onboardingPath, ".").concat(selectedService)] = {
            get: function () { return service; },
        },
        _a);
    var result = ouiBase.utils.hookstate.executeFilterLogic(logic, states);
    return result;
};
