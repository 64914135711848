import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { useSetListOrFavoritesSearchTerm } from '../..';
import { ResetQsButton } from '../../../../../../../../Components/ResetQsButton';

export const BondResetQsButton = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  const setSearchTerm = useSetListOrFavoritesSearchTerm();
  const onReset = React.useCallback(() => {
    setSearchTerm('');
  }, [setSearchTerm]);
  return (
    <ResetQsButton
      color={ouiStyle.Constants.Colors.inverted}
      backgroundColor={ouiStyle.Constants.Colors.accent}
      borderColor={ouiStyle.Constants.Colors.accent}
      hoverBackgroundColor={ouiStyle.Constants.Colors.accentShaded}
      onReset={onReset}
    >
      {children}
    </ResetQsButton>
  );
};
