import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useAccountPositionsHistoryArDocFileNameExtension } from '../../hooks';

export const AccountPositionsHistoryArDocDocumentType = () => {
  const {
    data,
  } = useAccountPositionsHistoryArDocFileNameExtension();
  return (
    <Trans>
      Document&#32;
      {data}
    </Trans>
  );
};
