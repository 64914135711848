import ouiDom from '@goldwasserexchange/oui-dom';
import ouiStyle from '@goldwasserexchange/oui-style';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { Trans } from '@lingui/macro';
import * as React from 'react';
import { GEPhoneNumber } from '../PhoneNumber';

export const ErrorBoundaryContent = () => (
  <ouiDom.HeadingSection.HeadingSection
    index={0}
    name="Error"
    container={ouiStyle.Constants.Container.xl}
  >
    <TopModel
      name="HeadingSectionTitleContent-Error"
    >
      <Trans>Une erreur s'est produite</Trans>
    </TopModel>
    <TopModel
      name="HeadingSectionIntroContent-Error"
    >
      <Trans>
        Une erreur s'est produite pendant le chargement de cette page. Veuillez vérifier votre connexion à internet, réessayer plus tard ou nous contacter au&#32;
        <GEPhoneNumber />
      </Trans>
    </TopModel>
  </ouiDom.HeadingSection.HeadingSection>
);
