var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import find from 'ramda/es/find';
export var arrayMatcherNames;
(function (arrayMatcherNames) {
    arrayMatcherNames[arrayMatcherNames["every"] = 0] = "every";
    arrayMatcherNames[arrayMatcherNames["some"] = 1] = "some";
})(arrayMatcherNames || (arrayMatcherNames = {}));
export function matchEvery(isArray, matcher) {
    return isArray.every(function (is) { return matcher(is); });
}
export function matchSome(isArray, matcher) {
    return isArray.some(function (is) { return matcher(is); });
}
export var arrayMatchers = (_a = {},
    _a[arrayMatcherNames.every] = matchEvery,
    _a[arrayMatcherNames.some] = matchSome,
    _a);
export function matchArray(elementMatcher, isArray, options) {
    var _a = options || {}, _b = _a.isNegative, isNegative = _b === void 0 ? false : _b, _c = _a.matcher, matcher = _c === void 0 ? arrayMatcherNames.every : _c;
    var matchResult = arrayMatchers[matcher](isArray, elementMatcher);
    return !isNegative
        ? matchResult
        : !matchResult;
}
export var isStringArray = function (s) { return Array.isArray(s) && typeof s[0] === 'string'; };
export var isTupleArray = function (s) { return Array.isArray(s[0]); };
export function matchFindLabel(elementMatcher, isFindLabelList) {
    var _a = __read(find(function (_a) {
        var _b = __read(_a, 2), is = _b[1];
        return elementMatcher(is);
    }, isFindLabelList) || [null], 1), label = _a[0];
    return label;
}
export function match(elementMatcher, is, options) {
    if (!Array.isArray(is)) {
        var isNegative = (options || {}).isNegative;
        var result = elementMatcher(is);
        return !isNegative ? result : !result;
    }
    if (isTupleArray(is)) {
        return matchFindLabel(elementMatcher, is);
    }
    return matchArray(elementMatcher, is, options);
}
export function defaultElementMatcherFactory(value) {
    return function elementMatcher(is) {
        return value === is;
    };
}
export function useMatchOnHook(useValue, elementMatcher, // eslint-disable-line default-param-last
is, options) {
    if (elementMatcher === void 0) { elementMatcher = defaultElementMatcherFactory; }
    var _a = options || {}, _b = _a.hookArgs, hookArgs = _b === void 0 ? [] : _b, matchOptions = __rest(_a, ["hookArgs"]);
    var value = useValue.apply(void 0, __spreadArray([], __read(hookArgs), false));
    var matches = match(elementMatcher(value), is, matchOptions);
    return matches;
}
