var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useTopModelExistByNameWithFallbacksAndNameTransform } from '@goldwasserexchange/react-topmodel';
import { useMemo } from 'react';
import { useCurrentDimensionIndexByPrimaryAndRenderDirection, useCurrentDimensionNameByDirectionAndRenderDirection, useCurrentDimensionValueByPrimaryAndRenderDirection, useDimensionListByCurrentRenderDirection, useIsLastCurrentDimensionVariantByDirectionAndRenderDirection, } from '../../../utils/dimensions';
export var makeTitleRampNames = function (props) {
    var baseName = props.baseName, secondaryName = props.secondaryName, dimensionName = props.dimensionName, currentDimensionValue = props.currentDimensionValue, currentIndex = props.currentIndex, isLast = props.isLast;
    return {
        rampName: "".concat(dimensionName).concat(baseName, "-").concat(currentDimensionValue, "-index-").concat(isLast),
        rampFallbacks: __spreadArray([
            "".concat(dimensionName).concat(baseName, "-").concat(currentDimensionValue, "-index-").concat(currentIndex),
            "".concat(dimensionName).concat(baseName, "-").concat(currentDimensionValue),
            "".concat(dimensionName).concat(baseName, "-index-").concat(isLast),
            "".concat(dimensionName).concat(baseName, "-index-").concat(currentIndex),
            "".concat(dimensionName).concat(baseName),
            baseName
        ], __read((secondaryName
            ? [
                "".concat(dimensionName).concat(secondaryName, "-").concat(currentDimensionValue, "-index-").concat(isLast),
                "".concat(dimensionName).concat(secondaryName, "-").concat(currentDimensionValue, "-index-").concat(currentIndex),
                "".concat(dimensionName).concat(secondaryName, "-").concat(currentDimensionValue),
                "".concat(dimensionName).concat(secondaryName, "-index-").concat(isLast),
                "".concat(dimensionName).concat(secondaryName, "-index-").concat(currentIndex),
                "".concat(dimensionName).concat(secondaryName),
                secondaryName,
            ]
            : [])), false),
    };
};
export var useTitleRampNames = function (baseName, secondaryName) {
    var dimensionName = useCurrentDimensionNameByDirectionAndRenderDirection();
    var currentDimensionValue = useCurrentDimensionValueByPrimaryAndRenderDirection();
    var currentIndex = useCurrentDimensionIndexByPrimaryAndRenderDirection();
    var isLast = useIsLastCurrentDimensionVariantByDirectionAndRenderDirection();
    var ramps = useMemo(function () { return makeTitleRampNames({
        baseName: baseName,
        secondaryName: secondaryName,
        dimensionName: dimensionName,
        currentDimensionValue: currentDimensionValue,
        currentIndex: currentIndex,
        isLast: isLast,
    }); }, [
        baseName,
        secondaryName,
        dimensionName,
        currentDimensionValue,
        currentIndex,
        isLast,
    ]);
    return ramps;
};
export var useTitlesRampNames = function (baseName, secondaryName, cornerName) {
    var dimensionName = useCurrentDimensionNameByDirectionAndRenderDirection();
    var dimensionValues = useDimensionListByCurrentRenderDirection();
    var ramps = useMemo(function () { return dimensionValues.reduce(function (acc, v, i, arr) {
        var _a = makeTitleRampNames({
            baseName: baseName,
            secondaryName: secondaryName,
            dimensionName: dimensionName,
            currentDimensionValue: v,
            currentIndex: i,
            isLast: i === arr.length - 1 ? 'last' : 'notLast',
        }), rampName = _a.rampName, rampFallbacks = _a.rampFallbacks;
        return __spreadArray(__spreadArray(__spreadArray([], __read(acc), false), [
            rampName
        ], false), __read(rampFallbacks), false);
    }, [cornerName]); }, [
        baseName,
        secondaryName,
        cornerName,
        dimensionName,
        dimensionValues,
    ]);
    return ramps;
};
export var useTitleRampNamesExist = function (baseName, secondaryName, cornerName) {
    var _a = __read(useTitlesRampNames(baseName, secondaryName, cornerName)), name = _a[0], fallbacks = _a.slice(1);
    var exists = useTopModelExistByNameWithFallbacksAndNameTransform(name, fallbacks);
    return exists;
};
