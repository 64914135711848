/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { useInputContainerRef } from '../../../../../OUI/Inputs';

export const defaultDomInputContainerStyle: ouiStyle.InStyle = {
  container: ouiStyle.Constants.Container.sm,
  marginHorizontal: 'auto',
};

const defaultDomInputContainerCss = ouiStyle.makeCss(defaultDomInputContainerStyle);

export function DefaultDomInputContainer(props: React.PropsWithChildren<{}>) {
  const { children } = props;
  const inputContainerRef = useInputContainerRef();
  return (
    <section ref={inputContainerRef} css={defaultDomInputContainerCss}>
      {children}
    </section>
  );
}
