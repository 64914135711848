import { TopModel } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { ScrollableScrollButtonContainerDom } from '../../../../../../OUI-dom/Scrollable/components';
import { ScrollDirections } from '../../../../../../OUI/Scrollable';

const leftStyle: ouiStyle.InStyle = {
  borderRightWidth: 'simple',
  borderLeftWidth: 'none',
};

const rightStyle: ouiStyle.InStyle = {
  borderRightWidth: 'none',
  borderLeftWidth: 'simple',
};

const directionalStyle = {
  left: leftStyle,
  right: rightStyle,
};

const TimeNavScrollButton = (props: React.PropsWithChildren<{
  direction: ScrollDirections,
}>) => {
  const {
    direction,
    children,
  } = props;
  return (
    <ScrollableScrollButtonContainerDom
      direction={direction}
      top={0}
      bottom={0}
      borderColor={ouiStyle.Constants.Colors.accent}
      color={ouiStyle.Constants.Colors.accent}
      backgroundColor={ouiStyle.Constants.Colors.inverted}
      hoverBackgroundColor={ouiStyle.Constants.Colors.inverted}
      borderTopWidth="none"
      borderBottomWidth="none"
      paddingLeft="none"
      paddingRight="none"
      borderRadius="none"
      iconContainer
      borderRightWidth={directionalStyle[direction].borderRightWidth}
      borderLeftWidth={directionalStyle[direction].borderLeftWidth}
    >
      {children}
    </ScrollableScrollButtonContainerDom>
  );
};

export const scrollButtonContainer = (
  <TopModel
    name="ScrollableScrollButtonContainer"
  >
    {TimeNavScrollButton}
  </TopModel>
);
