import { dataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import ouiBase from '@goldwasserexchange/oui';
import { LearnMoreButton } from '../../../../Sections/home/components/ServiceSection/learnMore';
import { OnboardingButton } from '../../../buttons/Onboarding';
export const ONBOARDING_LINK = 'onboardingLink';

export const OnboardingLinkLabel = (props: {
  accountType: dataStructure.accountType.listType,
  currentAccountType: dataStructure.accountType.listType,
}) => {
  const {
    accountType,
    currentAccountType,
  } = props;
  if (accountType !== currentAccountType) {
    return (
      <LearnMoreButton
        accountType={accountType}
      />
    );
  }
  return (
    <OnboardingButton />
  );
};

export const OnboardingLinkLabelOnPrimaryDimension = (props: {
  currentAccountType: dataStructure.accountType.listType,
}) => {
  const {
    currentAccountType,
  } = props;
  const accountType = ouiBase.utils.dimensions.useCurrentPrimaryDimension() as dataStructure.accountType.listType;
  return (
    <OnboardingLinkLabel
      accountType={accountType}
      currentAccountType={currentAccountType}
    />
  );
};

export const onboardingLinkTopModels = (
  <TopModel name={`TableBodyRowCellContent--${ONBOARDING_LINK}`}>
    {OnboardingLinkLabelOnPrimaryDimension}
  </TopModel>
);
