import * as React from 'react';
import { ActorApiTable, ActorApiTableProps } from '../../../../../../components';
import { useAccountCashflowsList } from '../../hooks';
import { AccountCashflow } from '../../type';
import { makeLoadingIds } from '../../../../shared/utils';

const loadingValue = makeLoadingIds(10);

export const AccountCashflowsTable = (props: React.PropsWithChildren<Pick<ActorApiTableProps, 'direction' | 'wrapping' | 'multiBody' | 'secondaryDimensionList'> & {
  filterPredicate?: ((data: AccountCashflow) => boolean),
  sortPredicate?: (prev: AccountCashflow, next: AccountCashflow) => number,
}>) => {
  const {
    direction,
    wrapping,
    multiBody,
    secondaryDimensionList,
    filterPredicate,
    sortPredicate,
    children,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useAccountCashflowsList({
    filterPredicate,
    sortPredicate,
  });
  return (
    <ActorApiTable
      data={data}
      isLoading={isLoading}
      error={error}
      loadingValue={loadingValue}
      direction={direction}
      wrapping={wrapping}
      multiBody={multiBody}
      secondaryDimensionList={secondaryDimensionList}
    >
      {children}
    </ActorApiTable>
  );
};
