/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { useMemo } from 'react';

const style: ouiStyle.InStyleWithMediaQueries = {
  color: ouiStyle.Constants.Colors.primary,
  fontSize: 'xs',
  lineFactor: 1,
  fontWeight: ouiStyle.Constants.FontWeightProp.bold,
  padding: 'half',
  textAlign: 'center',
  borderRadius: 'full',
  widthLines: 1,
  marginRight: 'simple',
};

export const BondDetailsHeaderRiskClassIndicator = (props: React.PropsWithChildren<{
  isSelected: boolean,
}>) => {
  const {
    isSelected,
    children,
  } = props;
  const css = useMemo(() => ouiStyle.mediaQuery(ouiStyle.makeCss({
    ...style,
    backgroundColor: isSelected
      ? ouiStyle.Constants.Colors.accent
      : ouiStyle.Constants.Colors.fade,
    color: isSelected
      ? ouiStyle.Constants.Colors.inverted
      : ouiStyle.Constants.Colors.primary,
  })), [isSelected]);
  return (
    <div
      css={css}
    >
      {children}
    </div>
  );
};
