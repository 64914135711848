import useSWRInfinite from 'swr/infinite';
import { useCallback, useMemo } from 'react';
import ouiBase from '@goldwasserexchange/oui';
import { useAtomValue } from 'jotai';
import { pathnameUrlNewsArticleOrSection, pathnameUrlLanguageAtom } from '../../../../../../history';
import {
  BaseSwrArguments, QueryArguments, QueryResponse, SwrArguments,
} from '../../types';
import { convertQueryArguments, fetcher, makeLoadingSlugs } from '../../utils';
import { newsSearchParamsSearchTermAtom, useArticleListBoundaries } from '../../../shared';

const makeGetKey = (swrArguments: SwrArguments, newsSection: string, disable = false) => (index: number, previousPageData?: QueryResponse): BaseSwrArguments | null => {
  if (disable) {
    return null;
  }
  const cursor = previousPageData?.news?.at(-1)?.cursor ?? null;
  if (index > 0 && cursor == null) {
    return null;
  }
  // the quantity we need to fetch depends on the page because the page UI has blocks like rates, newletters, etc...
  // we may overfetch if there are promos but otherwise the request would be blocked while waiting for the actor call
  const [
    queryString,
    locale,
    searchTerm,
  ] = swrArguments;
  if (newsSection === 'search' && (searchTerm == null || searchTerm === '')) {
    return null;
  }
  if (index === 0 && !searchTerm) {
    return [queryString, 14, locale, searchTerm, cursor];
  }
  if (index === 1 && !searchTerm) {
    return [queryString, 11, locale, searchTerm, cursor];
  }
  return [queryString, 12, locale, searchTerm, cursor];
};

export const useSanityNewsListRaw = (props: QueryArguments, disable?: boolean) => {
  const swrArguments = convertQueryArguments(props);
  const newsSection = useAtomValue(pathnameUrlNewsArticleOrSection);
  const getKey = useCallback(makeGetKey(swrArguments, newsSection, disable), [swrArguments, newsSection]);
  const response = useSWRInfinite(getKey, fetcher);
  return response;
};

export const useSanityNewsListPromos = (disable?: boolean) => {
  const locale = useAtomValue(pathnameUrlLanguageAtom);
  const searchTerm = useAtomValue(newsSearchParamsSearchTermAtom);
  const originalResponse = useSanityNewsListRaw({
    locale,
    searchTerm,
    cursor: null,
  }, disable);
  const response = useMemo(() => {
    const {
      data,
      ...rest
    } = originalResponse;
    if (data == null) {
      return {
        ...rest,
        data: undefined,
      };
    }
    return {
      ...rest,
      data: data.at(0)?.promotions,
    };
  }, [originalResponse.data, originalResponse.error, originalResponse.isLoading]);
  return response;
};

export const useSanityNewsListBase = (props: QueryArguments) => {
  const originalResponse = useSanityNewsListRaw(props);
  const response = useMemo(
    () => {
      const {
        data,
        ...rest
      } = originalResponse;
      if (data == null) {
        return {
          ...rest,
          data: undefined,
        };
      }
      const transformedResponse = {
        ...rest,
        data: data.map((page) => page.news),
      };
      return transformedResponse;
    },
    [originalResponse.data, originalResponse.error, originalResponse.isLoading],
  );
  return response;
};

export const useSanityNewsList = (props: QueryArguments) => {
  const originalResponse = useSanityNewsListBase(props);
  const response = useMemo(() => {
    const {
      data,
      ...rest
    } = originalResponse;
    if (!data) {
      return {
        ...rest,
        data: undefined,
      };
    }
    const transformedResponse = {
      ...rest,
      data: data.reduce((acc, page, idx, total) => {
        const next = total.at(idx + 1);
        const lastPage = next != null && next.length === 0;
        const currentPage = lastPage === true
          ? page.map((el) => ({ ...el, lastPage }))
          : page;
        return [...acc, ...currentPage];
      }, []),
    };
    return transformedResponse;
  }, [originalResponse.data, originalResponse.error]);
  return response;
};

export const useLocalizedSanityNewsListWithSearchTermAndLimits = () => {
  const locale = useAtomValue(pathnameUrlLanguageAtom);
  const searchTerm = useAtomValue(newsSearchParamsSearchTermAtom);
  return useSanityNewsList({
    locale,
    searchTerm,
    cursor: null,
  });
};

export const useLocalizedSanityNewsListWithSearchTermAndLimitsSlugList = () => {
  const baseResponse = useLocalizedSanityNewsListWithSearchTermAndLimits();
  const [start, stop] = useArticleListBoundaries();
  const response = useMemo(
    () => {
      const {
        data,
        ...rest
      } = baseResponse;
      if (data == null) {
        return {
          ...rest,
          data: makeLoadingSlugs(start, stop),
        };
      }
      return {
        ...rest,
        data: data.map((article) => article.slug).slice(start, start !== stop ? stop : stop + 1),
      };
    },
    [
      baseResponse.data,
      baseResponse.isLoading,
      baseResponse.error,
      start,
      stop,
    ],
  );
  return response;
};

export const useLocalizedSanityNewsListWithSearchTermAndLimitsSlugListLength = () => {
  const baseResponse = useLocalizedSanityNewsListWithSearchTermAndLimitsSlugList();
  const response = useMemo(
    () => {
      const {
        data,
        ...rest
      } = baseResponse;
      return {
        ...rest,
        data: data.length,
      };
    },
    [baseResponse.data, baseResponse.isLoading, baseResponse.error],
  );
  return response;
};

export const useLocalizedSanityNewsListWithSearchTermAndLimitsByPrimaryDimensionSlug = () => {
  const slug = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const baseResponse = useLocalizedSanityNewsListWithSearchTermAndLimits();
  const response = useMemo(
    () => {
      const {
        data,
        ...rest
      } = baseResponse;
      if (data == null) {
        return {
          ...rest,
          data: undefined,
        };
      }
      return {
        ...rest,
        data: data.find((article) => article.slug === slug),
      };
    },
    [baseResponse.data, baseResponse.isLoading, baseResponse.error, slug],
  );
  return response;
};

export const useLastLocalizedSanityNewsListWithSearchTermAndLimits = () => {
  const baseResponse = useLocalizedSanityNewsListWithSearchTermAndLimits();
  const response = useMemo(
    () => {
      const {
        data,
        ...rest
      } = baseResponse;
      if (data == null) {
        return {
          ...rest,
          data: undefined,
        };
      }
      return {
        ...rest,
        data: data.at(-1),
      };
    },
    [baseResponse.data, baseResponse.isLoading, baseResponse.error],
  );
  return response;
};
