import React from 'react';
import { Trans } from '@lingui/macro';
import * as Yup from 'yup';
import {
  UncastedOnboarding, CastedOnboarding,
} from '@goldwasserexchange/oblis-frontend-utils';
import ouiDom from '@goldwasserexchange/oui-dom';
import {
  ActionFilter, StateFilter, useSendOnClick,
} from '../../../../../Machine';
import { SectionTitle } from '../../utils/SectionTitle';
import { OrientationQuestionnaireIntro } from '../../../../../TextIndex/OrientationQuestionnaireIntro';
import { StepContainer } from '../../utils/Step';
import { DecisionType, decisionTypeValidator } from './decisionType';
import { EvaluationFrequency, evaluationFrequencyValidator } from './evaluationFrequency';
import { MarketNewsFrequency, marketNewsFrequencyValidator } from './marketNewsFrequency';
import { ThreeYearInvestmentPlanInput, threeYearInvestmentPlanValidator } from './threeYearPlan';
import { ChangeButtonContainer } from './changeButton';
import {
  nilValidator, makeRequired, booleanValidator,
} from '../../../../../../OUI/Inputs';
import { makeWhenAlwaysCast } from '../../../../../Form/validations/whenAlwaysCast';

export * from './decisionType';
export * from './evaluationFrequency';
export * from './marketNewsFrequency';
export * from './threeYearPlan';

const OrientationTitle = <Trans>Questionnaire d'orientation</Trans>;

const dependencyPathSubmitted: (keyof UncastedOnboarding['orientation'])[] = [
  'submitted',
];

const orientationQuestionnaireValidatorShape: Record<keyof UncastedOnboarding['orientation'], any> = ({
  submitted: makeRequired(booleanValidator),
  decisionType: makeWhenAlwaysCast(dependencyPathSubmitted, (submitted: CastedOnboarding['orientation']['submitted']) => (submitted !== false ? decisionTypeValidator : nilValidator)),
  evaluationFrequency: makeWhenAlwaysCast(dependencyPathSubmitted, (submitted: CastedOnboarding['orientation']['submitted']) => (submitted !== false ? evaluationFrequencyValidator : nilValidator)),
  marketNewsFrequency: makeWhenAlwaysCast(dependencyPathSubmitted, (submitted: CastedOnboarding['orientation']['submitted']) => (submitted !== false ? marketNewsFrequencyValidator : nilValidator)),
  threeYearInvestmentPlan: makeWhenAlwaysCast(dependencyPathSubmitted, (submitted: CastedOnboarding['orientation']['submitted']) => (submitted !== false ? threeYearInvestmentPlanValidator : nilValidator)),
});

export const orientationQuestionnaireValidator = Yup.object().shape(orientationQuestionnaireValidatorShape);

const ChangeButton = () => {
  const onClick = useSendOnClick('CHANGE');
  return (
    <ActionFilter is="CHANGE">
      <ouiDom.Button.Button
        type="button"
        onClick={onClick}
      >
        <Trans>Modifiez vos réponses</Trans>
      </ouiDom.Button.Button>
    </ActionFilter>
  );
};

export const OrientationQuestionnaire = (): JSX.Element => (
  <StepContainer>
    <SectionTitle>
      {OrientationTitle}
    </SectionTitle>
    <OrientationQuestionnaireIntro />
    <StateFilter is=".submitted" options={{ isNegative: true }}>
      <DecisionType />
      <EvaluationFrequency />
      <MarketNewsFrequency />
      <ThreeYearInvestmentPlanInput />
    </StateFilter>
    <StateFilter is=".submitted">
      <p>
        <StateFilter is=".RTO">
          <Trans>Sur base de vos réponses nous vous recommandons le service Gold</Trans>
        </StateFilter>
        <StateFilter is=".conseil">
          <Trans>Sur base de vos réponses nous vous recommandons le service Premium</Trans>
        </StateFilter>
        <StateFilter is=".gestion">
          <Trans>Sur base de vos réponses nous vous recommandons le service Global +</Trans>
        </StateFilter>
      </p>
    </StateFilter>
    <ChangeButtonContainer>
      <ChangeButton />
    </ChangeButtonContainer>
  </StepContainer>
);
