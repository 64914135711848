import { DynamoDBClient } from '@aws-sdk/client-dynamodb';
import { DynamoDBDocumentClient } from '@aws-sdk/lib-dynamodb';
import { AuthSendType } from '../../../Components/Auth/context/context';
import { getEssentialCredentials } from '../credentials';

export const getDynamoDbClient = async (send?: AuthSendType) => {
  const credentials = await getEssentialCredentials(send);
  const { identityId } = credentials;
  const client = new DynamoDBClient({ region: 'eu-west-1', credentials });
  return { client, identityId };
};

export const getDynamoDbDocumentClient = async (send?: AuthSendType) => {
  const { client, identityId } = await getDynamoDbClient(send);
  const ddbDocClient = DynamoDBDocumentClient.from(client, {
    marshallOptions: {
      // Whether to automatically convert empty strings, blobs, and sets to `null`.
      // default: false
      // we used false because we don't want to change the type of strings in hookstate
      convertEmptyValues: false,
      // Whether to remove undefined values while marshalling.
      // default: false
      // we used true because dynamoDB doesn't support undefined values,
      // we force inject the fields from makeInitialValue in the load method to not have problems with hookstate
      removeUndefinedValues: true,
      // Whether to convert typeof object to map attribute.
      // default: false
      // we use false because we want to keep the compatibility with the currenc code in onboarding-pdf but we will investigate if we can unmarshal dates in onboarding-pdf
      convertClassInstanceToMap: false,
    },
    unmarshallOptions: {
      // Whether to return numbers as a string instead of converting them to native JavaScript numbers.
      // default: false
      // we need real numbers to keep the correct type for hookstate
      wrapNumbers: false,
    },
  });
  return {
    client: ddbDocClient,
    identityId,
    destroy: () => client.destroy(),
  };
};
