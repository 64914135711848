import { useContext, useMemo } from 'react';
import { GetDocumentsParams } from '@goldwasserexchange/actor/rest-services';
import { stringifyParams } from '../../../shared/utils/makeUrl';
import { documentGetS3ParamsContext } from '../context';
import { useIsConnected } from '../../../../../../Components/Auth';

export const useDocumentGetS3DefaultParam = (params: Partial<GetDocumentsParams>): Partial<GetDocumentsParams> => {
  const {
    'AR_DOC.ID': contextArDocId,
    'AR_DOC.MEMO': contextArDocMemo,
  } = useContext(documentGetS3ParamsContext);
  const arDocId = params['AR_DOC.ID'] ?? contextArDocId;
  const defaultParams = useMemo(() => ({
    'AR_DOC.ID': arDocId,
    'AR_DOC.MEMO': contextArDocMemo,
  }), [arDocId]);
  return defaultParams;
};

export const useDocumentGetS3Params = (): GetDocumentsParams | null => {
  const defaultParams = useDocumentGetS3DefaultParam({});
  const isConnected = useIsConnected();
  if (!isConnected) {
    return null;
  }
  return defaultParams;
};

export const useDocumentGetS3StringifiedParams = () => {
  const contextParams = useDocumentGetS3Params();
  return contextParams !== null ? stringifyParams(contextParams) : null;
};
