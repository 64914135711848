import * as React from 'react';
import { SameIssuerSortColumn, sameIssuerSortColumnContext } from './context';

export const SameIssuerSortColumnContext = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  const handlers = React.useState<SameIssuerSortColumn>('S_ACC.OBL_END_DATE');
  return (
    <sameIssuerSortColumnContext.Provider value={handlers}>
      {children}
    </sameIssuerSortColumnContext.Provider>
  );
};
