import { sanityClient } from '../../../client';
import { slugify } from '../../../utils';
import { query } from './query';
import {
  QueryResponse,
  BaseQueryResponse,
  SwrArguments,
  QueryArguments,
} from './types';

export const convertSwrArguments = (args: SwrArguments): QueryArguments => {
  const [, locale] = args;
  return {
    locale,
  };
};

export const convertQueryArguments = (args: QueryArguments): SwrArguments => {
  const {
    locale,
  } = args;
  return [query, locale];
};

export const fetcher = async (args: SwrArguments): Promise<QueryResponse> => {
  const [queryString] = args;
  const sanityArguments = convertSwrArguments(args);
  const baseResponse = await sanityClient.fetch<BaseQueryResponse, QueryArguments>(
    queryString,
    sanityArguments,
  );
  const {
    locale,
    title,
    date,
    id,
  } = baseResponse;
  const response = {
    ...baseResponse,
    slug: slugify({
      locale,
      title,
      date,
      id,
    }),
  };
  return response;
};
