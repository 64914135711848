import { QueryCommandOutput } from '@aws-sdk/client-dynamodb';
import { QueryCommand } from '@aws-sdk/lib-dynamodb';
import useSWR from 'swr';
import { useMemo } from 'react';
import memoizee from 'memoizee';
import ouiBase from '@goldwasserexchange/oui';
import { getUnixTime, subMonths } from 'date-fns';
import { getSub } from '../../auth';
import { getDynamoDbDocumentClient } from '../getDynamoDbClient';
import { OnboardingSave } from './type';
import { useAuthMachineStateMatches, useSingleTableNameContext } from '../../../../Components/Auth';

export const getLastOnboardingFromDynamoDb = async (tableName: string, status: string) => {
  const sub = await getSub();
  if (!sub) {
    return undefined;
  }
  const { client } = await getDynamoDbDocumentClient();
  const results: Omit<QueryCommandOutput, 'Items'> & {
    Items?: OnboardingSave[],
  } = await client.send(new QueryCommand({
    TableName: tableName,
    KeyConditionExpression: 'pk = :pk AND begins_with(sk, :sk)',
    ExpressionAttributeNames: {
      '#status': 'status',
    },
    ExpressionAttributeValues: {
      ':pk': `u#${sub}`,
      ':sk': 'onboarding#',
      ':status': status,
    },
    ScanIndexForward: false,
    FilterExpression: '#status = :status',
  })) as Omit<QueryCommandOutput, 'Items'> & {
    Items?: OnboardingSave[],
  };
  client.destroy();
  return results.Items?.[0];
};

export const getLastPendingOnboardingFromDynamoDb = async (tableName: string) => getLastOnboardingFromDynamoDb(tableName, 'PENDING');

export const getLastSubmittedOnboardingsFromDynamoDb = async (props: {
  tableName: string,
  withPending?: boolean,
}) => {
  const {
    tableName,
    withPending,
  } = props;
  const sub = await getSub();
  if (!sub) {
    return undefined;
  }
  const oneMonthAgo = getUnixTime(subMonths(new Date(), 1));
  const { client } = await getDynamoDbDocumentClient();
  const results: Omit<QueryCommandOutput, 'Items'> & {
    Items?: OnboardingSave[],
  } = await client.send(new QueryCommand({
    TableName: tableName,
    KeyConditionExpression: 'pk = :pk AND sk > :sk',
    ExpressionAttributeNames: {
      '#status': 'status',
    },
    ExpressionAttributeValues: {
      ':pk': `u#${sub}`,
      ':sk': `onboarding#${oneMonthAgo}`,
      ':submitted': 'SUBMITTED',
      ':signed': 'SIGNED',
      ':validated': 'VALIDATED',
      ...(withPending ? { ':pending': 'PENDING' } : {}),
    },
    ScanIndexForward: false,
    FilterExpression: [
      '#status = :submitted',
      '#status = :signed',
      '#status = :validated',
      ...withPending ? ['#status = :pending'] : [],
    ].join(' or '),
  })) as Omit<QueryCommandOutput, 'Items'> & {
    Items?: OnboardingSave[],
  };
  client.destroy();
  return results.Items;
};

const swrGetLastSubmittedOnboardingsFromDynamoDb = async (props: [tableName: string, withPending: boolean, key: string]) => {
  const [tableName, withPending] = props;
  return getLastSubmittedOnboardingsFromDynamoDb({ tableName, withPending });
};

export const useLastSubmittedOnboardings = (props?: {
  withPending?: boolean,
}) => {
  const {
    withPending = false,
  } = props ?? {};
  const tableName = useSingleTableNameContext();
  const isDisconnected = useAuthMachineStateMatches('disconnected');
  const isConnectedReAuth = useAuthMachineStateMatches('connected.reAuth');
  const shouldRun = (tableName && !isDisconnected && !isConnectedReAuth);
  const response = useSWR(shouldRun ? [tableName, withPending, 'submittedOnboardings'] : null, swrGetLastSubmittedOnboardingsFromDynamoDb);
  return response;
};

const findBySk = (data: OnboardingSave[] | undefined, sk: string) => {
  if (data == null || data.length === 0) {
    return undefined;
  }
  return data.find((save) => save.sk === sk);
};

const memoFindBySk = memoizee(findBySk);

export const useLastSubmittedOnboardingsBySk = (props?: {
  withPending?: boolean,
}) => {
  const {
    withPending,
  } = props ?? {};
  const {
    data,
    ...rest
  } = useLastSubmittedOnboardings({
    withPending,
  });
  const sk = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const skData = useMemo(() => memoFindBySk(data, sk), [data]);
  return {
    data: skData,
    ...rest,
  };
};
