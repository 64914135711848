import { useContext } from 'react';
import { usePrimaryDimensionLength } from '../../list/length/hooks';
import { usePrimaryDimensionListElementByIndex } from '../../list/hooks';
import { currentPrimaryDimensionIndexContext } from './context';
export var useCurrentPrimaryDimensionIndex = function () {
    var elementIndex = useContext(currentPrimaryDimensionIndexContext);
    return elementIndex;
};
export var useIsLastPrimaryDimensionIndex = function () {
    var index = useCurrentPrimaryDimensionIndex();
    var length = usePrimaryDimensionLength();
    return index === length - 1;
};
export function useIsLastPrimaryDimensionVariant() {
    var isLast = useIsLastPrimaryDimensionIndex();
    return isLast ? 'last' : 'notLast';
}
export var useCurrentPrimaryDimensionValueByIndex = function () {
    var index = useCurrentPrimaryDimensionIndex();
    var data = usePrimaryDimensionListElementByIndex(index);
    return data;
};
