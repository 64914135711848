import * as React from 'react';
import ouiBase from '@goldwasserexchange/oui';
import ouiStyle from '@goldwasserexchange/oui-style';
import ouiDom from '@goldwasserexchange/oui-dom';
import * as machine from '../../machine';
import * as animationController from '../../animation';

export const NavBarLink = (
  props: React.PropsWithChildren<ouiBase.A.BaseAProps>,
): JSX.Element => {
  const { target, children } = props;
  const mqDown = animationController.useAnimationmqDownSpringValue();
  const sendLinkClick = machine.useLinkClick();
  const animatedValues = {
    paddingLeft: mqDown.to((v) => {
      if (v <= 0.5) {
        return '20px';
      }
      return '10px';
    }),
    paddingRight: mqDown.to((v) => {
      if (v <= 0.5) {
        return '20px';
      }
      return '10px';
    }),
    justifyContent: mqDown.to((v) => {
      if (v <= 0.5) {
        return 'flex-start';
      }
      return 'center';
    }),
  };
  if ('href' in props) {
    const { href } = props;
    return (
      <ouiDom.A.A
        href={href}
        target={target}
        onClick={sendLinkClick}
        display={ouiStyle.Constants.DisplayProp.flex}
        fontSize="m"
        lines="full"
        flexDirection={ouiStyle.Constants.InFlexDirectionProp.row}
        alignItems={ouiStyle.Constants.AlignItemsProp.center}
        position="relative"
        color={ouiStyle.Constants.Colors.inverted}
        flexGrow={1}
        flexShrink
        whiteSpace={ouiStyle.Constants.WhiteSpaceProperty.noWrap}
        paddingTop="simple"
        paddingBottom="simple"
        widthLines="auto"
        textDecoration="none"
        animatedValues={animatedValues}
      >
        {children}
      </ouiDom.A.A>
    );
  }
  const {
    to,
    replace,
  } = props;
  return (
    <ouiDom.A.A
      to={to}
      replace={replace}
      target={target}
      onClick={sendLinkClick}
      display={ouiStyle.Constants.DisplayProp.flex}
      fontSize="m"
      lines="full"
      flexDirection={ouiStyle.Constants.InFlexDirectionProp.row}
      alignItems={ouiStyle.Constants.AlignItemsProp.center}
      position="relative"
      color={ouiStyle.Constants.Colors.inverted}
      flexGrow={1}
      flexShrink
      whiteSpace={ouiStyle.Constants.WhiteSpaceProperty.noWrap}
      paddingTop="simple"
      paddingBottom="simple"
      widthLines="auto"
      textDecoration="none"
      animatedValues={animatedValues}
    >
      {children}
    </ouiDom.A.A>
  );
};

export default NavBarLink;
