/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const style: ouiStyle.InStyleWithMediaQueries = {
  container: ouiStyle.Constants.Container.xl,
  paddingVertical: 'none',
  display: ouiStyle.Constants.DisplayProp.flex,
  flexDirection: [ouiStyle.Constants.InFlexDirectionProp.col, undefined, undefined, ouiStyle.Constants.InFlexDirectionProp.row, undefined, undefined],
  borderWidth: 'simple',
  borderStyle: 'solid',
  borderColor: ouiStyle.Constants.Colors.accent,
};

const css = ouiStyle.mediaQuery(ouiStyle.makeCss(style));

export const HistoryTimeNavContainer = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  return (
    <div
      css={css}
    >
      {children}
    </div>
  );
};
