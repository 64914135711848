import useSWR from 'swr';
import { useAtomValue } from 'jotai';
import { SwrArguments } from '../types';
import { convertQueryArguments, fetcher } from '../utils';
import { useBondSAccId } from '../../../../../actor/api/Services/bonds';
import { pathnameUrlLanguageAtom } from '../../../../../history';
import { useMemo } from 'react';

const useBaseSanityNewsByBondId = (disabled = false) => {
  const bondId = useBondSAccId();
  const locale = useAtomValue(pathnameUrlLanguageAtom);
  const args = {
    locale,
    bondId,
  };
  const swrArguments: SwrArguments | null = disabled === false ? convertQueryArguments(args) : null;
  const response = useSWR(swrArguments, fetcher);
  return response;
};

export const useSanityNewsByBondIdPromotions = (disabled?: boolean) => {
  const originalResponse = useBaseSanityNewsByBondId(disabled);
  const response = useMemo(() => {
    const {
      data,
      ...rest
    } = originalResponse;
    if (data == null) {
      return {
        ...rest,
        data: undefined,
      };
    }
    const {
      promotions,
    } = data;
    return {
      ...rest,
      data: promotions,
    };
  }, [
    originalResponse.isLoading,
    originalResponse.data,
    originalResponse.error,
  ]);
  return response;
};

export const useSanityNewsByBondId = (disabled?: boolean) => {
  const originalResponse = useBaseSanityNewsByBondId(disabled);
  const response = useMemo(() => {
    const {
      data,
      ...rest
    } = originalResponse;
    if (data == null) {
      return {
        ...rest,
        data: undefined,
      };
    }
    if (data.lastNewsByBondId == null) {
      return {
        ...rest,
        data: null,
      };
    }
    const {
      lastNewsByBondId,
    } = data;
    return {
      ...rest,
      data: lastNewsByBondId,
    };
  }, [
    originalResponse.isLoading,
    originalResponse.data,
    originalResponse.error,
  ]);
  return response;
};
