import { SimulationBuyAndSellCostData, SimulationBuyOrSellCostData } from '../type';

const productCostPcts: Record<SimulationBuyOrSellCostData['type'] | SimulationBuyAndSellCostData['type'], number> = {
  bonds: 0,
  stocks: 0,
  capitalisationFunds: 0.009,
  distibutionFunds: 0.009,
  trackers: 0.0025,
};

export type GetProductCostProps = {
  type: SimulationBuyOrSellCostData['type'] | SimulationBuyAndSellCostData['type'],
}

export const getProductCostPct = (props: GetProductCostProps) => {
  const {
    type,
  } = props;
  return productCostPcts[type];
};

export type ComputeProductCostAmountProps = {
  counterValue: SimulationBuyAndSellCostData['counterValue'],
  durationYears: SimulationBuyAndSellCostData['durationYears'],
} & GetProductCostProps

export const computeProductCostAmount = (props: ComputeProductCostAmountProps) => {
  const {
    counterValue,
    type,
    durationYears,
  } = props;
  const pct = getProductCostPct({ type });
  return counterValue * pct * durationYears;
};
