import { Locale } from '@goldwasserexchange/i18n';
import * as React from 'react';
import { ValidSections } from '../../../../../../../../history';
import { ToolbarDropDownListElementLink } from '../../../../../toolbarDropDown';

export const NewsHomeAndSearchLanguageToolbarDropDownListElementLink = (props: React.PropsWithChildren<{
  locale: Locale,
}>) => {
  const {
    locale,
    children,
  } = props;
  const to = `/${locale}/${ValidSections.NEWS}`;
  return (
    <ToolbarDropDownListElementLink
      to={to}
      textAlign="center"
      textTransform="uppercase"
    >
      {children}
    </ToolbarDropDownListElementLink>
  );
};
