import * as React from 'react';
import { useAccountHistoryHisMvtOperDate } from '../../hooks';
import { ActorApiDateDisplay } from '../../../../../../components/display/date';

export const AccountsHistoryHisMvtOperDate = () => {
  const {
    data,
    isLoading,
    error,
  } = useAccountHistoryHisMvtOperDate();
  return (
    <ActorApiDateDisplay
      data={data}
      isLoading={isLoading}
      error={error}
    />
  );
};
