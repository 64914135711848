import { QueryCommand } from '@aws-sdk/lib-dynamodb';
import useSWR from 'swr';
import memoize from 'memoizee';
import { useMemo } from 'react';
import { useCognitoUserValidAccounts } from '../../../../Components/Sections/online/selectedAccount';
import { getSub } from '../../auth';
import { getDynamoDbDocumentClient } from '../getDynamoDbClient';
import { Order, Side } from './type';

const filterBySAccIdTAccIdAndSide = (data: Order[], side: Order['side'], sAccId: Order['sAccId'], tAccId?: Order['tAccId'] | null): Order[] => data
  .filter((order) => order.side === side
  && order.sAccId === sAccId
   && (tAccId == null
    || (order.tAccId === tAccId)
   ));

const memoFilterBySaccIdTAccIdAndSide = memoize(filterBySAccIdTAccIdAndSide);

export const getOrders = async (props: [tableName: string, skPrefix: string, _accountNotEmpty: boolean]): Promise<Order[]> => {
  const [tableName, skPrefix] = props;
  const sub = await getSub();
  if (!sub) {
    return [];
  }
  const { client } = await getDynamoDbDocumentClient();
  const command = new QueryCommand({
    TableName: tableName,
    KeyConditionExpression: 'pk = :pk AND begins_with(sk, :sk)',
    ExpressionAttributeValues: {
      ':pk': `u#${sub}`,
      ':sk': `${skPrefix}#`,
    },
  });
  const results = await client.send(command);
  client.destroy();
  const items = results.Items as Order[] | undefined;
  return items ?? [];
};

export const useOrders = (props: {
  tableName: string | null,
  run?: boolean,
}) => {
  const {
    tableName,
    run = true,
  } = props;
  const accounts = useCognitoUserValidAccounts();
  const response = useSWR(run && typeof tableName === 'string' ? [tableName, 'order', accounts.length > 0] : null, getOrders);
  return response;
};

export const useFilteredOrders = (props: {
  tableName: string | null,
  side: Order['side'],
  sAccId: Order['sAccId'] | null | undefined,
  tAccId?: Order['tAccId'] | null,
  run?: boolean,
}) => {
  const {
    tableName,
    side,
    sAccId,
    tAccId,
    run = true,
  } = props;
  const canRun = sAccId != null && (side === Side.BUY || (tAccId != null && side === Side.SELL));
  const {
    data,
    ...rest
  } = useOrders({
    tableName,
    run: run && canRun,
  });
  const response = useMemo(() => ({
    data: data && canRun ? memoFilterBySaccIdTAccIdAndSide(data, side, sAccId, tAccId) : [],
    ...rest,
  }), [data, side, sAccId, tAccId, canRun]);
  return response;
};
