import * as React from 'react';
import { StateFilter } from '../../../../../../../Machine';
import { Suspended } from '../../../../../../../Suspended';
import { LazyGoalNOKExclusion } from './goal';

export const ConseilInvestmentGoalsExclusion = () => (
  <StateFilter is=".goalNOK.excluded">
    <Suspended>
      <LazyGoalNOKExclusion />
    </Suspended>
  </StateFilter>
);
