import { isAfter } from 'date-fns';
import { parseActorDate } from '../../../../../shared/utils/parseDates';
import { AccountHistory } from '../../type';
import { isHisMvtTableCountedFromHistoryLine } from './getHisMvtTableName';

export const transformAmountHieDcTotal = (qt: number | null | undefined, date: Date) => (d: AccountHistory | AccountHistory[] | null | undefined) => {
  if (d && Array.isArray(d) && d.length > 0) {
    const result = d.reduce((acc, line) => {
      const counted = isHisMvtTableCountedFromHistoryLine(line) && isAfter(parseActorDate(line['HIS_MVT.OPER_DATE']), date);
      if (counted) {
        return acc - line['HIS_MVT.AMOUNT_HIE_DC'];
      }
      return acc;
    }, qt ?? 0);
    return result;
  }
  return qt ?? 0;
};
