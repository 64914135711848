import { GetBondsFavoritesResponse, GetBondsListResponse } from '@goldwasserexchange/actor/rest-services';
import { useEffect } from 'react';
import { useAtomValue } from 'jotai';
import { useBondsListRedacted } from '../../../../../listOrFavorites';
import { useNavigateCleanBondParamsUrl } from '../../../clean';
import { bondSearchParamsAtom } from '../../../atoms';
import { BondSort } from '../../../sort';
import { SearchAndFilterBondParams } from '../../type';
import { bondFilterRedactedColumnsDict } from '../redacted';

const removeRedactedParams = (data: (keyof GetBondsListResponse | keyof GetBondsFavoritesResponse | 'CPS_HIS.ACCRUED')[]) => (qsParams: SearchAndFilterBondParams & BondSort) => Object.fromEntries(
  Object.entries(qsParams)
    .map(([key, value]) => {
      if (key === 'S_MARKET.YIELD') {
        return [key, undefined];
      }
      if (data.includes(bondFilterRedactedColumnsDict[key]) || key === 'CPS_HIS.LOW_ACCRUED') {
        return [key, false];
      }
      return [key, value];
    }),
);

export const useBooleanFilterRemoveRedacted = () => {
  const qsParams = useAtomValue(bondSearchParamsAtom);
  const {
    data,
  } = useBondsListRedacted();
  const navigate = useNavigateCleanBondParamsUrl();
  useEffect(() => {
    if (data) {
      navigate('replace', removeRedactedParams(data));
    }
  }, [qsParams, data]);
};
