import React from 'react';
import { dataStructure, AccountType } from '@goldwasserexchange/oblis-frontend-utils';
import { StateFilter } from '../../../../../../../Machine';
import { Suspended } from '../../../../../../../Suspended';
import { LazyRTOExperienceQuestion } from './experience';
import { LazyRTOKnowledgeQuestion } from './knowledge';

export * from './answers';

export const RTOQuestionnaire = (props: { sTypeGrp: dataStructure.financialProducts.listType }) => {
  const {
    sTypeGrp,
  } = props;
  return (
    <>
      <StateFilter is=".experience">
        <Suspended>
          <LazyRTOExperienceQuestion sTypeGrp={sTypeGrp} />
        </Suspended>
      </StateFilter>
      {dataStructure.financialProductsKnowledge.questionPaths.getQuestionRootIdsByServiceAndQuestionnaire(
        AccountType.RTO,
        sTypeGrp,
      ).map((questionKey) => (
        <StateFilter is={`.${questionKey}.`}>
          <Suspended>
            <LazyRTOKnowledgeQuestion
              questionKey={questionKey}
              sTypeGrp={sTypeGrp}
            />
          </Suspended>
        </StateFilter>
      ))}
    </>
  );
};
