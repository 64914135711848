/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import React from 'react';

const style: ouiStyle.InStyle = {
  borderBottomStyle: 'solid',
  borderBottomWidth: 'simple',
  borderBottomColor: ouiStyle.Constants.Colors.primary,
};

const css = ouiStyle.mediaQuery({
  ...ouiStyle.makeCss(style),
  '&:last-of-type': {
    borderBottomColor: ouiStyle.Constants.Colors.inverted,
    borderBottomStyle: 'none',
    borderBottomWidth: 'none',
  },
});

export const BoxContainer = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  return (
    <li
      css={css}
    >
      {children}
    </li>
  );
};
