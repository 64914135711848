import * as React from 'react';
import { TopModelMerge, TopModelNameTransformProvider } from '@goldwasserexchange/react-topmodel';
import { ListStructure } from './structure';
import { listDefaultTopModelsObject } from './topmodels';
import { DefaultListContextTopModelsProvider } from './context';
import { listPrefixer } from './utils';
import { DefaultListElementContextTopModelsProvider, DefaultListMapperTopModelsProvider, DefaultListElementBaseTopModelsProvider, } from './components';
export function List(props) {
    var list = props.list, length = props.length, children = props.children;
    return (React.createElement(TopModelNameTransformProvider, { transformFn: listPrefixer },
        React.createElement(DefaultListContextTopModelsProvider, null,
            React.createElement(DefaultListMapperTopModelsProvider, null,
                React.createElement(DefaultListElementBaseTopModelsProvider, null,
                    React.createElement(DefaultListElementContextTopModelsProvider, null,
                        React.createElement(TopModelMerge, { topModels: listDefaultTopModelsObject },
                            React.createElement(ListStructure, { list: list, length: length }, children))))))));
}
