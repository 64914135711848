import * as React from 'react';
import { StateFilter } from '../../../../../../Machine';
import { Suspended } from '../../../../../../Suspended';
import { BackToServiceSelection } from '../../../directNavigation/backToServiceSelection';
import { LazyFinancialSituationQuestionnaireStep } from '../../../questionnaires/FinancialSituationQuestionnaire/lazy';
import { StepContainer } from '../../../utils/Step';
import { GestionFinancialSituationExclusions } from './exclusions';
export const GestionFinancialSituation = () => (
  <>
    <StateFilter is=".questions">
      <Suspended>
        <LazyFinancialSituationQuestionnaireStep />
      </Suspended>
    </StateFilter>
    <StateFilter is=".exclusions">
      <StepContainer>
        <GestionFinancialSituationExclusions />
        <BackToServiceSelection />
      </StepContainer>
    </StateFilter>
  </>
);
