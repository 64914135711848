import * as React from 'react';
import ouiDom from '@goldwasserexchange/oui-dom';
import { Trans } from '@lingui/macro';
import ouiStyle from '@goldwasserexchange/oui-style';
import { useAuthMachineSend, useAuthMachineStateMatches } from '../context/hooks';
import { useSetCurrentModalValue } from '../../Modal';
import { SignInModal, SignInModalName } from './signinModal';

const AuthentifiedText = () => {
  const openModal = useSetCurrentModalValue(SignInModalName);
  return (
    <>
      <ouiDom.P.P
        textAlign="center"
        padding="simple"
      >
        <Trans>
          Cette section est réservée aux utilisateurs connectés.
        </Trans>
      </ouiDom.P.P>
      <div
        style={{ textAlign: 'center' }}
      >
        <ouiDom.Button.Button
          type="button"
          onClick={openModal}
          marginHorizontal="auto"
          backgroundColor={ouiStyle.Constants.Colors.accent}
          color={ouiStyle.Constants.Colors.inverted}
          hoverBackgroundColor={ouiStyle.Constants.Colors.accentShaded}
        >
          <Trans>
            Se connecter
          </Trans>
        </ouiDom.Button.Button>
      </div>
    </>
  );
};

export const Authentified = (props: React.PropsWithChildren<{
  withText?: boolean,
  canClose: boolean,
  initialClosed?: boolean,
}>) => {
  const {
    withText = true,
    canClose,
    initialClosed = false,
    children,
  } = props;
  const send = useAuthMachineSend();
  const isDisconnected = useAuthMachineStateMatches('disconnected');
  const isDisconnectedInitial = useAuthMachineStateMatches('disconnected.initial');
  const isConnectedReAuth = useAuthMachineStateMatches('connected.reAuth');
  const isForgotPassword = useAuthMachineStateMatches('disconnected.forgotPassword');
  const openModal = useSetCurrentModalValue(SignInModalName);
  const closeModal = useSetCurrentModalValue(null);
  React.useEffect(() => {
    if ((isDisconnected || isDisconnectedInitial || isConnectedReAuth)) {
      if (!isForgotPassword) {
        send('SIGN_IN');
      }
      if (!initialClosed) {
        openModal();
      }
    } else {
      closeModal();
    }
  }, [isDisconnected, isDisconnectedInitial, isConnectedReAuth, initialClosed, isForgotPassword]);
  return (
    <>
      {withText && isDisconnected ? <AuthentifiedText /> : null}
      {children}
      <SignInModal
        canClose={canClose}
      />
    </>
  );
};
