import { RampWithNameTransform } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import { BaseBuilderWithNameTransform } from '../../../../../utils';

export function DefaultTextInputMainContent() {
  return (
    <>
      <RampWithNameTransform rampName="InputComponentContainer" />
      <BaseBuilderWithNameTransform rampName="InputIcon" />
    </>
  );
}
