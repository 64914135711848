import { dataStructure, AccountType } from '@goldwasserexchange/oblis-frontend-utils';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { Trans } from '@lingui/macro';
import * as React from 'react';
import ouiBase from '@goldwasserexchange/oui';

export const BROKERAGE_FEES = 'brokerageFees';

const brokerageFeesLabels = {
  [AccountType.RTO]: {
    label: (
      <Trans>
        0,80%
        <br />
        (min. 50 EUR)
      </Trans>
    ),
  },
  [AccountType.CONSEIL]: {
    label: (
      <Trans>
        0,80%
        <br />
        (min. 50 EUR)
      </Trans>
    ),
  },
  [AccountType.GESTION]: {
    label: (
      <Trans>
        0,605%
        <br />
        (min. 30,25 EUR)
      </Trans>
    ),
  },
};

export const BrokerageFeesLabel = (props: { accountType: dataStructure.accountType.listType }) => {
  const { accountType } = props;
  return brokerageFeesLabels[accountType].label;
};

export const BrokerageFeesLabelOnPrimaryDimension = () => {
  const accountType = ouiBase.utils.dimensions.useCurrentPrimaryDimension() as dataStructure.accountType.listType;
  return (
    <BrokerageFeesLabel accountType={accountType} />
  );
};

export const brokerageFeesLabel = (
  <Trans>
    Frais de courtage sur achats / ventes
    <br />
    d'actions, fonds et obligations
  </Trans>
);

export const brokerageFeesTopModels = (
  <>
    <TopModel name={`TableSecondaryTitleCellContent-${BROKERAGE_FEES}`}>
      {brokerageFeesLabel}
    </TopModel>
    <TopModel name={`TableBodyRowCellContent--${BROKERAGE_FEES}`}>
      {BrokerageFeesLabelOnPrimaryDimension}
    </TopModel>
  </>
);
