/* eslint-disable no-underscore-dangle */
import { sanityClient } from '../../../client';
import { slugify } from '../../../utils';
import { filterAndTransformPromoQueryResponse } from '../../promos/utils';
import { query } from './query';
import {
  QueryArguments, SwrArguments, QueryResponse, BaseQueryResponse,
} from './types';

const convertSwrArguments = (args: SwrArguments): QueryArguments => {
  const [, locale, bondId] = args;
  return {
    locale,
    bondId,
  };
};

export const convertQueryArguments = (args: QueryArguments): SwrArguments => {
  const {
    locale,
    bondId,
  } = args;
  return [query, locale, bondId];
};

export const fetcher = async (args: SwrArguments): Promise<QueryResponse | null> => {
  const [queryString] = args;
  const sanityArguments = convertSwrArguments(args);
  const response = await sanityClient.fetch<BaseQueryResponse | null, QueryArguments>(queryString, sanityArguments);
  const baseLastNewsByBondId = response?.lastNewsByBondId;
  if (response == null) {
    return response;
  }
  const {
    promotions: basePromotions,
  } = response;
  const promotions = filterAndTransformPromoQueryResponse(basePromotions);
  return {
    ...response,
    lastNewsByBondId: baseLastNewsByBondId != null ? {
      ...baseLastNewsByBondId,
      slug: slugify({
        id: baseLastNewsByBondId._id,
        title: baseLastNewsByBondId.title,
        locale: baseLastNewsByBondId.language,
        date: baseLastNewsByBondId.publishDate,
      }),
    } : undefined,
    promotions,
  };
};
