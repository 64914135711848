/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { animated } from 'react-spring';
import { useToolbarDropDownOpenSpring, useToolbarDropDownSetOpen } from './context';
import { useClickOutside } from '../../../../OUI';

const toolbarDropDownContainerStyle: ouiStyle.InStyle = {
  position: 'relative',
  widthLines: 3,
  backgroundColor: ouiStyle.Constants.Colors.logoBlue,
  borderWidth: 'none',
  borderColor: ouiStyle.Constants.Colors.transparent,
  borderStyle: 'none',
  fontSize: 'm',
  color: ouiStyle.Constants.Colors.inverted,
  lines: 'full',
};

const toolbarDropDownContainerCss = {
  ...ouiStyle.makeCss(toolbarDropDownContainerStyle),
  '&:hover': {
    backgroundColor: ouiStyle.Constants.Colors.transparent,
  },
  '&:active': {
    background: ouiStyle.Constants.Colors.accent,
  },
};

export const ToolbarDropDownContainer = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  const ref = React.useRef<HTMLButtonElement>(null);
  const open = useToolbarDropDownOpenSpring();
  const setOpen = useToolbarDropDownSetOpen();
  const onClick = React.useCallback(() => setOpen((v) => !v), [setOpen]);
  const style = {
    backgroundColor: open.to({
      range: [0, 1],
      output: [ouiStyle.Constants.Colors.logoBlue, ouiStyle.Constants.Colors.accent],
    }),
  };
  useClickOutside(ref, () => setOpen(false));
  return (
    <animated.button
      ref={ref}
      type="button"
      css={toolbarDropDownContainerCss}
      style={style}
      onClick={onClick}
    >
      {children}
    </animated.button>
  );
};
