import {
  AlertTriangle,
  Check,
  Settings,
  Power,
  Menu,
  Info,
  User,
  Radio,
  Layers,
  List,
  Paperclip,
  Mail,
  HelpCircle,
  ChevronDown,
  RefreshCw,
  Loader,
  X,
  Printer,
  MessageSquare,
  Circle,
  ChevronLeft,
  ChevronRight,
  CheckCircle,
  UserCheck,
  Map as MapIcon,
  BookOpen,
  Users,
  Eye,
  UserPlus,
  Book,
  DollarSign,
  MoreHorizontal,
  Target,
  UserX,
  Save,
  Upload,
  Star,
  Compass,
  Crosshair,
  Bookmark,
  Key,
  PieChart,
  Plus,
  Activity,
  Feather,
} from 'react-feather';

export enum IconName {
  'AlertTriangle',
  'Check',
  'HelpCircle',
  'Settings',
  'Power',
  'Menu',
  'Info',
  'User',
  'Radio',
  'Layers',
  'List',
  'Paperclip',
  'Mail',
  'ChevronDown',
  'ChevronLeft',
  'ChevronRight',
  'RefreshCw',
  'Loader',
  'X',
  'Printer',
  'MessageSquare',
  'Circle',
  'CheckCircle',
  'UserCheck',
  'Map',
  'BookOpen',
  'Users',
  'Eye',
  'UserPlus',
  'Book',
  'Dollar',
  'More',
  'Target',
  'UserX',
  'Save',
  'Upload',
  'Star',
  'Compass',
  'Crosshair',
  'Bookmark',
  'Key',
  'PieChart',
  'Plus',
  'Activity',
  'Feather',
}

export const icons: Record<IconName, typeof AlertTriangle> = {
  [IconName.AlertTriangle]: AlertTriangle,
  [IconName.Check]: Check,
  [IconName.HelpCircle]: HelpCircle,
  [IconName.Settings]: Settings,
  [IconName.Power]: Power,
  [IconName.Menu]: Menu,
  [IconName.Info]: Info,
  [IconName.User]: User,
  [IconName.Radio]: Radio,
  [IconName.Layers]: Layers,
  [IconName.List]: List,
  [IconName.Paperclip]: Paperclip,
  [IconName.Mail]: Mail,
  [IconName.ChevronDown]: ChevronDown,
  [IconName.ChevronLeft]: ChevronLeft,
  [IconName.ChevronRight]: ChevronRight,
  [IconName.RefreshCw]: RefreshCw,
  [IconName.Loader]: Loader,
  [IconName.X]: X,
  [IconName.Printer]: Printer,
  [IconName.MessageSquare]: MessageSquare,
  [IconName.Circle]: Circle,
  [IconName.CheckCircle]: CheckCircle,
  [IconName.UserCheck]: UserCheck,
  [IconName.Map]: MapIcon,
  [IconName.BookOpen]: BookOpen,
  [IconName.Users]: Users,
  [IconName.Eye]: Eye,
  [IconName.UserPlus]: UserPlus,
  [IconName.Book]: Book,
  [IconName.Dollar]: DollarSign,
  [IconName.More]: MoreHorizontal,
  [IconName.Target]: Target,
  [IconName.UserX]: UserX,
  [IconName.Save]: Save,
  [IconName.Upload]: Upload,
  [IconName.Star]: Star,
  [IconName.Compass]: Compass,
  [IconName.Crosshair]: Crosshair,
  [IconName.Bookmark]: Bookmark,
  [IconName.Key]: Key,
  [IconName.PieChart]: PieChart,
  [IconName.Plus]: Plus,
  [IconName.Activity]: Activity,
  [IconName.Feather]: Feather,
};
