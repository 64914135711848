import { useContext, useCallback } from 'react';
import { inputRefContext } from './context';
import { useEnhanceHandler } from '../../../utils/hooks';

export function useInputRef() {
  const inputRef = useContext(inputRefContext);
  return inputRef;
}

export function useInputRefFocusOnClick() {
  const inputRef = useInputRef();
  const onClick = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  return onClick;
}

export function useInputRefClickOnClick() {
  const inputRef = useInputRef();
  const onClick = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, []);
  return onClick;
}

export function useInputRefClickAndFocusOnClick() {
  const focusOnClick = useInputRefFocusOnClick();
  const clickOnClick = useInputRefClickOnClick();
  const onClick = useEnhanceHandler(clickOnClick, focusOnClick);
  return onClick;
}
