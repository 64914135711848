/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiBase from '@goldwasserexchange/oui';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { defaultListInputListElementButtonPlaceHolderStyle } from '../../../../../../../shared/components/list/components';

export const defaultSelectListInputListElementButtonPlaceHolderStyle: ouiStyle.InStyle = {
  ...defaultListInputListElementButtonPlaceHolderStyle,
  parentBorderBottomWidth: 'simple',
  parentBorderBottomStyle: 'solid',
};

function useMakedefaultSelectListInputListElementButtonPlaceHolderCss() {
  const lines = ouiBase.List.useListInputElementLines();
  const css = React.useMemo(() => ouiStyle.mediaQuery(ouiStyle.makeCss({
    ...defaultSelectListInputListElementButtonPlaceHolderStyle,
    lines,
  })), [lines]);
  return css;
}

export function DefaultSelectListInputListElementButtonPlaceHolder(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  const css = useMakedefaultSelectListInputListElementButtonPlaceHolderCss();
  return <div css={css}>{children}</div>;
}
