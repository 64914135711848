import ouiBase from '@goldwasserexchange/oui';
import * as React from 'react';
import { inputTypeContext } from './context';

export const InputTypeContextProvider = (
  props: React.PropsWithChildren<{
    type: ouiBase.Input.InputTypes,
  }>,
) => {
  const { type, children } = props;
  return (
    <inputTypeContext.Provider value={type}>
      {children}
    </inputTypeContext.Provider>
  );
};
