import * as React from 'react';
import ouiDom from '@goldwasserexchange/oui-dom';
import ouiStyle from '@goldwasserexchange/oui-style';

export const BondListLengthNumberContainer = (props: React.PropsWithChildren<{
  fontWeight?: ouiStyle.InStyleWithMediaQueries['fontWeight'],
  fontSize?: ouiStyle.InStyleWithMediaQueries['fontSize'],
  lineFactor?: ouiStyle.InStyleWithMediaQueries['lineFactor'],
}>) => {
  const {
    fontWeight = ouiStyle.Constants.FontWeightProp.normal,
    fontSize = 'inherit',
    lineFactor = 1,
    children,
  } = props;
  return (
    <ouiDom.TextSnippet.TextSnippet
      fontSize={fontSize}
      lineFactor={lineFactor}
      color={ouiStyle.Constants.Colors.inherit}
      backgroundColor={ouiStyle.Constants.Colors.inherit}
      fontWeight={fontWeight}
    >
      {children}
    </ouiDom.TextSnippet.TextSnippet>
  );
};
