import { GetRatesResponse } from '@goldwasserexchange/actor/rest-services';
import { AccountAvailableCash, AccountPosition } from '../../../../actor/api/Services/accounts';
import { HistoryByCurrency } from '../../../../actor/api/Services/history';
import { makeCurrencySorter } from '../../../currencySorter';

export const apiCurrencySorter = (first: string) => (prev: AccountPosition | HistoryByCurrency | AccountAvailableCash | GetRatesResponse, next: AccountPosition | HistoryByCurrency | AccountAvailableCash | GetRatesResponse) => {
  const prevCurrency = prev['C_CURRENCY.CODE'];
  const nextCurrency = next['C_CURRENCY.CODE'];
  const currencySorter = makeCurrencySorter(first);
  return currencySorter(prevCurrency, nextCurrency);
};

export const defaultApiCurrencySorter = apiCurrencySorter('EUR');
