import { msg } from '@lingui/macro';
import { T_ADD_TABLE_NAME } from '../data/types';
import { GeSection } from './types';

export const geSections: Record<string, GeSection> = {
  type: {
    id: 'type',
    fields: [
      'ID_T_NATURE_LRP',
      'ID_T_PROC_TYPE',
      'ID_C_TIT_MORAL',
      'ID_C_TIT_MORALPrecision',
      'ID_T_ACC_AYDR',
    ],
    table: T_ADD_TABLE_NAME,
  },
  identification: {
    id: 'identification',
    title: msg`Identité`,
    fields: [
      'ID_C_TIT',
      'FIRSTNAME',
      'NAME_1',
      'BIRTH_DATE',
      'ID_C_COUNTRY_BIRTH',
      'BIRTH_PLACE',
      'ID_C_COUNTRY_NAT',
      'nat_REGIST_NUM',
    ],
    table: T_ADD_TABLE_NAME,
  },
  address: {
    id: 'address',
    title: msg`Adresse`,
    fields: [
      'ID_T_ADD_ADDRESS_KEY',
      'adressSelector',
      'T_ADD_ADDRESS_STREET_1',
      'T_ADD_ADDRESS_ZIP',
      'T_ADD_ADDRESS_CITY',
      'T_ADD_ADDRESS_ID_C_COUNTRY',
    ],
    table: T_ADD_TABLE_NAME,
  },
  contact: {
    id: 'contact',
    title: msg`Coordonnées de contact`,
    fields: [
      'T_ADD_EMAIL_PRINCIPAL',
      'T_ADD_GSM_PRINCIPAL',
      'T_ADD_TEL_PRINCIPAL',
    ],
    table: T_ADD_TABLE_NAME,
  },
  moralIdentificationNumbers: {
    id: 'moralIdentificationNumbers',
    fields: [
      'REGIST_NUM',
      'MIFID_ID',
    ],
    table: T_ADD_TABLE_NAME,
  },
  civilStatus: {
    id: 'civilStatus',
    title: msg`État civil`,
    fields: [
      'MC_CIVILSTATUS',
      'significantOther_FIRSTNAME',
      'significantOther_NAME1',
      'T_ADD_SIGNIFICANT_OTHER_BIRTH_DATE_COLUMN',
    ],
    table: T_ADD_TABLE_NAME,
  },
  relations: {
    id: 'relations',
    title: msg`Relations familliales`,
    fields: [
      'ID_T_ADD_FAMILY_LINK',
    ],
    table: T_ADD_TABLE_NAME,
  },
  profession: {
    id: 'profession',
    title: msg`Situation professionelle`,
    fields: [
      'ID_ECPL_PROF',
      'HadProfession',
      'DATE_CESS',
      'ID_ECPL_PROF_LAST',
      'ID_ECPL_SECTOR',
      'DESC_ECPL_SECTOR',
      'HasSameProfessionAndResidencyCountry',
      'ID_C_COUNTRY_ACTI',
    ],
    table: T_ADD_TABLE_NAME,
  },
  economicInfo: {
    id: 'economicInfo',
    title: msg`Informations économiques`,
    fields: [
      'fundsOrigin',
    ],
    table: T_ADD_TABLE_NAME,
  },
  fiscal: {
    id: 'fiscal',
    title: msg`Informations fiscales`,
    fields: [
      'fiscalResidencySameAsResidency',
      'fiscalAdressSelector',
      'T_ADD_FISCAL_ADDRESS_STREET_1',
      'T_ADD_FISCAL_ADDRESS_ZIP',
      'T_ADD_FISCAL_ADDRESS_CITY',
      'T_ADD_FISCAL_ADDRESS_ID_C_COUNTRY',
      'TIN_US',
      'onlyOneFiscalResidency',
      'secondaryFiscalAdressSelector',
      'T_ADD_SECONDARY_FISCAL_ADDRESS_STREET_1',
      'T_ADD_SECONDARY_FISCAL_ADDRESS_ZIP',
      'T_ADD_SECONDARY_FISCAL_ADDRESS_CITY',
      'T_ADD_SECONDARY_FISCAL_ADDRESS_ID_C_COUNTRY',
      'TIN_US_SEC',
    ],
    table: T_ADD_TABLE_NAME,
  },
  constraints: {
    id: 'constraints',
    title: msg`Autres informations réglementaires`,
    fields: [
      'MC_PEP',
      'MC_MAR_STATUT',
      'otherConstraints',
      'YN_US',
    ],
    table: T_ADD_TABLE_NAME,
  },
};

export const allTAddSections = Object.keys(geSections).filter((key) => geSections[key].table === T_ADD_TABLE_NAME);
