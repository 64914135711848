import {
  useCallback, Dispatch, SetStateAction, useMemo,
} from 'react';

export function useSetBooleanStateHandler(
  current: boolean,
  setter: Dispatch<SetStateAction<boolean>>,
  value: boolean,
) {
  const fn = useCallback((): void => {
    if (current !== value) {
      setter(value);
    }
  }, [setter, value, current]);
  return fn;
}

export function useSetBooleanToggleStateHandlers(
  current: boolean,
  setter: Dispatch<SetStateAction<boolean>>,
) {
  const toggleOn = useSetBooleanStateHandler(current, setter, true);
  const toggleOff = useSetBooleanStateHandler(current, setter, false);
  const result = useMemo(() => [toggleOn, toggleOff], [toggleOn, toggleOff]);
  return result;
}
