import * as React from 'react';
import { StateFilter } from '../../../../../Machine';
import { GestionDurability } from './durability';
import { GestionFinancialSituation } from './financialSituation/components';
import { GestionInvestmentGoals } from './investmentGoals/components';

export const KYCGestion = () => (
  <>
    <StateFilter is=".financialSituation">
      <GestionFinancialSituation />
    </StateFilter>
    <StateFilter is=".investmentGoals">
      <GestionInvestmentGoals />
    </StateFilter>
    <StateFilter is=".durability">
      <GestionDurability />
    </StateFilter>
  </>
);
