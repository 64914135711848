import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { ChartLoader } from '../chartLoader';

export const Suspended = (props: React.PropsWithChildren<{
  paddingTop?: ouiStyle.InStyleWithMediaQueries['paddingTop'],
}>) => {
  const {
    paddingTop,
    children,
  } = props;
  return (
    <React.Suspense fallback={(
      <ChartLoader
        paddingTop={paddingTop}
        widthLines={['1/3', undefined, undefined, '1/12', undefined, undefined]}
        lines={['1/3', undefined, undefined, '1/12', undefined, undefined]}
      />
    )}
    >
      {children}
    </React.Suspense>
  );
};
