import * as React from 'react';
import { StateFilter } from '../../../../../Machine';
import { Suspended } from '../../../../../Suspended';
import { LazyMaxRiskClassStep } from './MaxRiskClass/lazy';

export const KYCRTO = () => (
  <StateFilter is=".maxRiskClass">
    <Suspended>
      <LazyMaxRiskClassStep />
    </Suspended>
  </StateFilter>
);
