import { useContext, useCallback } from 'react';
import { bondInvalidSAccIdsContext } from './context';

export const useBondInvalidSAccIdsContext = () => {
  const context = useContext(bondInvalidSAccIdsContext);
  return context;
};

export const useBondInvalidSAccIds = () => {
  const [bondValidSAccIds] = useBondInvalidSAccIdsContext();
  return bondValidSAccIds;
};

export const useSetBondInvalidSAccIds = () => {
  const [, setBondInvalidSAccIds] = useBondInvalidSAccIdsContext();
  return setBondInvalidSAccIds;
};

export const useAddBondInvalidSAccIds = () => {
  const setBondInvalidSAccIds = useSetBondInvalidSAccIds();
  const add = useCallback((id: number) => { setBondInvalidSAccIds((invalidIds) => new Set([...invalidIds, id])); }, [setBondInvalidSAccIds]);
  return add;
};
