import { atom } from 'jotai';
import { pathnamePartFamilly } from '../../../atoms';
import { getValidAccountsSection } from './utils';

export const pathnameRawUrlAccountsSectionAtom = atom((get) => {
  const subSection = get(pathnamePartFamilly(2));
  if (subSection == null) {
    return '';
  }
  return subSection;
});

export const pathnameUrlAccountsSectionAtomWithoutAccounts = atom((get) => {
  const subSection = get(pathnameRawUrlAccountsSectionAtom);
  return getValidAccountsSection(subSection);
});
