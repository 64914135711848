import ouiBase from '@goldwasserexchange/oui';
import { useAccountHistoryTransformApiByHisMvtId } from '../api';
import { getId } from '../../../../shared';

export const useAccountHistoryArDocId = () => {
  const hisMvtId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const response = useAccountHistoryTransformApiByHisMvtId({
    hisMvtId,
    transformFunction: getId,
  });
  return response;
};
