/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
var useMakeDomButtonIconContainerCss = function (iconPosition) {
    var style = React.useMemo(function () {
        if (iconPosition === 'after') {
            return ouiStyle.makeCss({
                paddingLeft: 'simple',
            });
        }
        return ouiStyle.makeCss({
            paddingRight: 'simple',
        });
    }, [iconPosition]);
    return style;
};
export var DomButtonIconContainer = function (props) {
    var iconPosition = props.iconPosition, children = props.children;
    var css = useMakeDomButtonIconContainerCss(iconPosition);
    return (jsx("span", { css: css }, children));
};
