import { ActorServicesType } from '../../Services';
import Accounts from '../../Services/accounts';
import History from '../../Services/history';
import Positions from '../../Services/positions';
import Cashflows from '../../Services/cashflows';

export const getServiceUrl = <Service extends ActorServicesType>(service: Service) => {
  if (service === Positions.SubGroup) {
    return Accounts.Positions;
  }
  if (service === History.byCurrency) {
    return Accounts.History;
  }
  if (service === Cashflows.byCurrency) {
    return Accounts.Cashflows;
  }
  return service;
};
