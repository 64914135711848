/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { animated } from 'react-spring';
import * as animationController from '../../animation';

const navBarListStyle: ouiStyle.InStyle = {
  fontSize: 'm',
  display: ouiStyle.Constants.DisplayProp.flex,
  flexBasis: ouiStyle.Constants.FlexBasisProp.auto,
  flexShrink: true,
  flexGrow: true,
  listStyleType: ouiStyle.Constants.ListStyleTypeProps.none,
};

const navBarListCss = ouiStyle.makeCss(navBarListStyle);

export const NavBarList = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  const mqDown = animationController.useAnimationmqDownSpringValue();
  const style = {
    flexDirection: mqDown.to((v) => {
      if (v <= 0.5) {
        return 'column';
      }
      return 'row';
    }),
  };
  return <animated.ul css={navBarListCss} style={style}>{children}</animated.ul>;
};

export default NavBarList;
