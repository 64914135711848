import React from 'react';
import ouiBase from '@goldwasserexchange/oui';
import { stringIsPartial, stringIsNegative, trimNegativeFromString } from '../../Match/syntax';
import {
  useMatchOnState, MatcherOnState, MatcherOnStateProps,
} from './utils';

const matchPartialStateString = (state: any, is: string): boolean => state
  .toStrings()
  .some((currentStateString: string) => currentStateString.includes(is));

type matchStateStringOptions = {
  isNegative?: boolean,
  isPartial?: boolean,
};

const matchStateString = (is: string, state: any, options?: matchStateStringOptions): boolean => {
  const { isNegative = false, isPartial = false } = options || {};
  const match = isPartial
    ? matchPartialStateString(state, is)
    : state.matches(is);
  return !isNegative
    ? match
    : !match;
};

const matchStateStringWithSyntax = (state: any) => (
  is: string,
): boolean => {
  const isNegative = stringIsNegative(is);
  const trimmedIs = trimNegativeFromString(is);
  const isPartial = stringIsPartial(trimmedIs);
  return matchStateString(trimmedIs, state, { isNegative, isPartial });
};

export function useStateFilter<T>(is: string | string[] | ouiBase.utils.match.FindLabelList<T>, options?: ouiBase.utils.match.MatchArrayOptions): boolean | string | null {
  const matches = useMatchOnState(matchStateStringWithSyntax, is, options);
  return matches;
}

type StateFilterProps = Omit<MatcherOnStateProps, 'elementMatcher'>

export const StateFilter = (
  props: React.PropsWithChildren<StateFilterProps>,
): JSX.Element | null => {
  const { children, ...rest } = props;
  return (
    <MatcherOnState
      {...rest}
      elementMatcher={matchStateStringWithSyntax}
    >
      {children}
    </MatcherOnState>
  );
};
