var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { RampWithNameTransform } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import { useIntroContainerRampNames, useIntroContentRampNames } from './hooks';
export var HeadingSectionIntroBase = function () {
    var _a = __read(useIntroContainerRampNames(), 2), containerRampName = _a[0], containerRampFallbacks = _a[1];
    var _b = __read(useIntroContentRampNames(), 2), contentRampName = _b[0], contentRampFallbacks = _b[1];
    return (React.createElement(RampWithNameTransform, { rampName: containerRampName, rampFallbacks: containerRampFallbacks },
        React.createElement(RampWithNameTransform, { rampName: contentRampName, rampFallbacks: contentRampFallbacks })));
};
