var _a, _b, _c;
import { range } from 'ramda';
import bondQuestions from './bondQuestions.json';
import subordinatedBondQuestions from './subordinatedBondQuestions.json';
import perpetualBondQuestions from './perpetualBondQuestions.json';
import trackerAndETFQuestions from './trackerAndETFQuestions.json';
import { STypeGrp } from '../utils/types';
export var RTO_QUESTION_GROUPS = (_a = {},
    _a[STypeGrp.OBLIGATIONS] = range(0, bondQuestions.length),
    _a[STypeGrp.OBLIGATIONS_SUB] = range(0, subordinatedBondQuestions.length),
    _a[STypeGrp.OBLIGATIONS_PERP] = range(0, perpetualBondQuestions.length),
    _a[STypeGrp.TRACKERS] = [],
    _a[STypeGrp.FONDS] = [],
    _a[STypeGrp.ACTIONS] = [],
    _a[STypeGrp.TRACKERS_MAT] = range(0, trackerAndETFQuestions.length),
    _a);
export var RTO_QUESTION_GROUPS_LENGTH = (_b = {},
    _b[STypeGrp.OBLIGATIONS] = RTO_QUESTION_GROUPS[STypeGrp.OBLIGATIONS].length,
    _b[STypeGrp.OBLIGATIONS_SUB] = RTO_QUESTION_GROUPS[STypeGrp.OBLIGATIONS_SUB].length,
    _b[STypeGrp.OBLIGATIONS_PERP] = RTO_QUESTION_GROUPS[STypeGrp.OBLIGATIONS_PERP].length,
    _b[STypeGrp.TRACKERS] = RTO_QUESTION_GROUPS[STypeGrp.TRACKERS].length,
    _b[STypeGrp.FONDS] = RTO_QUESTION_GROUPS[STypeGrp.FONDS].length,
    _b[STypeGrp.ACTIONS] = RTO_QUESTION_GROUPS[STypeGrp.ACTIONS].length,
    _b[STypeGrp.TRACKERS_MAT] = RTO_QUESTION_GROUPS[STypeGrp.TRACKERS_MAT].length,
    _b);
export var RTOTrackerAndETFQuestionPrefix = 'RTOTrackerAndETFQuestion';
export var RTOBondQuestionPrefix = 'RTOBondQuestion';
export var RTOSubordinatedBondPrefix = 'RTOSubordinatedBondQuestion';
export var RTOPerpetualBondPrefix = 'RTOPerpetualBondQuestion';
export var RTOFundQuestionPrefix = 'RTOFundQuestion';
export var RTOStockQuestionPrefix = 'RTOStockQuestion';
export var RTORawMaterialTrackerQuestionPrefix = 'RTORawMaterialTrackerQuestion';
export var WasRetriedSuffix = 'WasRetried';
export var RTO_QUESTIONS_PREFIXES = (_c = {},
    _c[STypeGrp.OBLIGATIONS] = RTOBondQuestionPrefix,
    _c[STypeGrp.OBLIGATIONS_SUB] = RTOSubordinatedBondPrefix,
    _c[STypeGrp.OBLIGATIONS_PERP] = RTOPerpetualBondPrefix,
    _c[STypeGrp.TRACKERS] = RTOTrackerAndETFQuestionPrefix,
    _c[STypeGrp.FONDS] = RTOFundQuestionPrefix,
    _c[STypeGrp.ACTIONS] = RTOStockQuestionPrefix,
    _c[STypeGrp.TRACKERS_MAT] = RTORawMaterialTrackerQuestionPrefix,
    _c);
