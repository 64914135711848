/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { useIsHighlighted } from '../table/context/highlightContext';
import globalPlusIcon from './GE-icon-GLOBAL.svg';
import globalPlusIconNeg from './GE-icon-GLOBAL-neg.svg';

const globalPlusIconStyle: ouiStyle.InStyle = {
  lines: 6,
  widthLines: 6,
};

const globalPlusIconCss = ouiStyle.makeCss(globalPlusIconStyle);

export const GlobalPlusIcon = () => {
  const isHighlighted = useIsHighlighted();
  return (
    <img
      src={!isHighlighted ? globalPlusIcon : globalPlusIconNeg}
      alt="icone du compte Global +"
      css={globalPlusIconCss}
    />
  );
};
