import { Auth } from '@aws-amplify/auth';
import pThrottle from 'p-throttle';
import { AuthSendType } from '../../../Components/Auth/context/context';

const throttle = pThrottle({
  limit: 1,
  interval: 100,
});

const getTokens = async (send?: AuthSendType) => {
  try {
    const userSession = await Auth.currentSession();
    const accessToken = userSession.getAccessToken();
    const idToken = userSession.getIdToken();
    return {
      accessToken,
      idToken,
    };
  } catch (err) {
    if (send) {
      send('DISCONNECT');
    }
    throw err;
  }
};

export const throttledGetTokens = throttle(
  getTokens,
);
