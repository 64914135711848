import { dataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import * as React from 'react';
import { ActorApiStringDisplay } from '../../../../../actor/components/display/string';
import { useLastSubmittedOnboardingsBySk } from '../../../../utils/dynamoDb/onboarding/get';

export const OnboardingStatusType = (props: {
  withPending?: boolean,
}) => {
  const {
    withPending,
  } = props;
  const {
    data: onboardingSave,
    isLoading,
    error,
  } = useLastSubmittedOnboardingsBySk({ withPending });
  const type = onboardingSave?.data.personType;
  const label = dataStructure.personType.labels.useShortLabel(type);
  return (
    <ActorApiStringDisplay
      data={label !== '' ? label : undefined}
      isLoading={isLoading}
      error={error}
    />
  );
};
