/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { footerContentStyle } from './style';

const style: ouiStyle.InStyleWithMediaQueries = {
  lines: 1,
  position: 'relative',
  ...ouiStyle.getChildPaddings(footerContentStyle),
};

const css = ouiStyle.mediaQuery(ouiStyle.makeCss(style));

export function DefaultDomInputFooterContainer(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  return <footer css={css}>{children}</footer>;
}
