import { createPresignedPost } from '@aws-sdk/s3-presigned-post';
import { AuthSendType } from '../../../Components/Auth/context/context';
import { getSub } from '../auth';
import { getS3Client } from './getS3Client';

type StartsWithCondition = ['starts-with', string, string];

type RangeCondition = ['content-length-range', number, number];

type Condition = Record<string, string> | StartsWithCondition | RangeCondition;

export const getSignedS3PostUrl = async (props: {
  bucketName: string,
  subPrefix: string,
  filename?: string,
  send: AuthSendType,
}) => {
  const {
    bucketName,
    subPrefix,
    // eslint-disable-next-line no-template-curly-in-string
    filename = '${filename}',
    send,
  } = props;
  const sub = await getSub();
  const client = await getS3Client(send);
  const prefix = `${sub}/${subPrefix}/`;
  const startsWithCondition: StartsWithCondition = ['starts-with', '$key', prefix];
  const rangeCondition: RangeCondition = ['content-length-range', 1, 10485760];
  const contentTypeCondition: StartsWithCondition = ['starts-with', '$Content-Type', ''];
  const Conditions: Condition[] = [{ bucket: bucketName }, startsWithCondition, rangeCondition, contentTypeCondition];
  const Key = `${prefix}${filename}`;
  const presignedResponse = await createPresignedPost(client, {
    Bucket: bucketName,
    Key,
    Fields: {
      // see https://uppy.io/docs/aws-s3/#POST-uploads
      success_action_status: '201',
    },
    Conditions,
    Expires: 900,
  });
  const { url, fields } = presignedResponse;
  return ({
    url,
    fields,
  });
};
