import { assign } from 'xstate';
import { AppBarEvent } from '../../../events';
import { AppBarMachineContext } from '../../../machineContext';

export const name = 'setTargetOpenShrinked';

export const fn = assign<AppBarMachineContext, AppBarEvent>({
  target: () => 'openShrinked',
});

export const action = {
  [name]: fn,
};

export default action;
