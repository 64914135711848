/* eslint-disable jsx-a11y/label-has-associated-control */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { Trans } from '@lingui/macro';
import * as React from 'react';

const searchPlaceInputLabelStyle: ouiStyle.InStyle = {
  display: ouiStyle.Constants.DisplayProp.block,
  widthLines: 'full',
  padding: 'simple',
  color: ouiStyle.Constants.Colors.primary,
};

export const SearchPlaceInputLabel = (props: {
  focus: boolean,
}) => {
  const {
    focus,
  } = props;
  const searchPlaceInputLabelCss = React.useMemo(() => ouiStyle.makeCss({
    ...searchPlaceInputLabelStyle,
    color: !focus ? ouiStyle.Constants.Colors.primary : ouiStyle.Constants.Colors.accent,
  }), [focus]);
  return (
    <label
      css={searchPlaceInputLabelCss}
      htmlFor="addressSelector"
    >
      <Trans>
        Rechercher une adresse
      </Trans>
    </label>
  );
};
