import { AccountType } from '@goldwasserexchange/oblis-frontend-utils';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { Trans } from '@lingui/macro';
import * as React from 'react';

export const goldAdvantages = (
  <>
    <TopModel name={`ListElementContent-primaryKey-0-${AccountType.RTO}-section`}>
      <Trans>
        Accès complet au marché obligataire
      </Trans>
    </TopModel>
    <TopModel name={`ListElementContent-primaryKey-1-${AccountType.RTO}-section`}>
      <Trans>
        Plus de 1.000 fonds disponibles
      </Trans>
    </TopModel>
    <TopModel name={`ListElementContent-primaryKey-2-${AccountType.RTO}-section`}>
      <Trans>
        Exécution rapide des ordres de bourse
      </Trans>
    </TopModel>
  </>
);
