import { History, Update } from 'history';
import { splitPathname } from '../../../utils';
import { newsRoutesDefinition } from './router';
import { getNewsSectionType, getNewsSectionWithDefault } from './utils';

export const newsRouting = (_sourceHistory: History, currentUpdate: Update) => {
  const update = {
    action: currentUpdate.action,
    location: {
      ...currentUpdate.location,
    },
  };
  const { location } = update;
  const {
    pathname,
  } = location;
  const splittedPathname = splitPathname(pathname);
  const newsSection = splittedPathname.at(2);
  const validNewsSection = getNewsSectionWithDefault(newsSection);
  const newsSectionType = getNewsSectionType(validNewsSection);
  const {
    importFn,
  } = newsRoutesDefinition[newsSectionType];
  if (importFn && typeof importFn === 'function') {
    importFn();
  }
  if (validNewsSection !== splittedPathname.at(2)) {
    update.location.pathname = `/${[...splittedPathname.slice(0, 2), validNewsSection, ...splittedPathname.slice(3)].join('/')}`;
  }
  return update;
};
