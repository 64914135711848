import { PortableText } from '@portabletext/react';
import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { BannerContentNormal } from './components';
import { SanityLink } from '../../../oblisPortableText/components';

const components = {
  block: {
    normal: (props) => (
      <BannerContentNormal>
        {props.children}
      </BannerContentNormal>
    ),
  },
  marks: {
    link: (props) => (
      <SanityLink
        value={props.value}
        hoverColor={ouiStyle.Constants.Colors.inverted}
        textAlign="inherit"
      >
        {props.children}
      </SanityLink>
    ),
  },
};

export const BannerContent = (props: {
  content: {
    value: any[],
  },
}) => {
  const {
    content,
  } = props;
  return (
    <PortableText
      value={content.value}
      components={components}
    />
  );
};
