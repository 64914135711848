import ouiDom from '@goldwasserexchange/oui-dom';
import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useAuthMachineSend } from '../../../../context';

export const ForgotPasswordButton = () => {
  const send = useAuthMachineSend();
  const onClick = React.useCallback(() => {
    send('FORGOT_PASSWORD');
  }, [
    send,
  ]);
  return (
    <ouiDom.Button.ButtonA
      type="button"
      onClick={onClick}
      paddingVertical="none"
    >
      <Trans>
        Mot de passe oublié ?
      </Trans>
    </ouiDom.Button.ButtonA>
  );
};
