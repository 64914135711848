import ouiBase from '@goldwasserexchange/oui';
import { useAccountPositionFieldApiByGesEstimD } from '../api';

export const useAccountPositionGesEstimDMtInt = () => {
  const gesEstimDId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const result = useAccountPositionFieldApiByGesEstimD({
    gesEstimDId,
    field: 'GES_ESTIM_D.MT_INT',
  });
  return result;
};
