import { mediaQuerizeProps } from '../mediaQuery';
export var makeUserSelect = function (userSelect) { return (userSelect ? 'auto' : 'none'); };
var isWithMediaQuery = function (userSelect) { return Array.isArray(userSelect); };
export var makeUserSelectWithMediaQuery = function (userSelect) {
    if (isWithMediaQuery(userSelect)) {
        var userSelectWithNoHoles = mediaQuerizeProps(userSelect);
        var withMq = userSelectWithNoHoles.map(function (us) { return makeUserSelect(us); });
        return withMq;
    }
    return makeUserSelect(userSelect);
};
