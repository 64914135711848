import { useFilterByPrimaryDimensionTAccTwrrPId } from '../../utils';
import { AccountTwrrs } from '../../type';
import { useAccountTwrrTransformApi } from '../api';

const transformFunction = (accountTwrr?: AccountTwrrs | null | undefined) => (accountTwrr ? (accountTwrr['T_ACC_TWRR_P.SUM_OUT'] ?? 0) : 0);

export const useAccountTwrrPTAccTwrrPSumOut = () => {
  const filterPredicate = useFilterByPrimaryDimensionTAccTwrrPId();
  const result = useAccountTwrrTransformApi({
    filterPredicate,
    transformFunction,
  });
  return result;
};
