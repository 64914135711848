import { UniDimensionalMatrix } from './types';

/**
 * Predicts the value of y given a set of terms and an x value using polynomial regression.
 * @param {UniDimensionalMatrix} terms - An array of coefficients that define the polynomial.
 * @param {number} x - The x value to predict the y value for.
 * @returns {number} The predicted y value for the given x value.
 */
export const predictY = (terms: UniDimensionalMatrix, x: number) => {
  let result = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = terms.length - 1; i >= 0; i--) {
    if (i === 0) {
      result += terms[i];
    } else {
      result += terms[i] * x ** i;
    }
  }
  return result;
};
