import React from 'react';
import { msg, Trans } from '@lingui/macro';
import {
  dataStructure,
  UncastedPhysicalTAdd,
  CTitMoral,
  TAddType,
} from '@goldwasserexchange/oblis-frontend-utils';

export const tAddMandataryLabel = <Trans>Fonction au sein de la personne morale</Trans>;

export const tAddMandatarySpecifyLabel = <Trans>Veuillez préciser votre fonction au sein de la personne morale</Trans>;

export const tAddMandataryValidatorLogic = dataStructure.T_ADD.fields.TYPE.logic.makeTAddTypeFilterLogic<'requiredString' | 'nilCast'>({
  is: TAddType.MANDATARY,
  truthy: {
    result: 'requiredString',
  },
  falsy: {
    result: 'nilCast',
  },
});

export const tAddMandatarydata = {
  0: { ID: 0, value: msg`Gérant` },
  1: { ID: 1, value: msg`Administrateur` },
  2: { ID: 2, value: msg`Administrateur délégué` },
  3: { ID: 3, value: msg`Directeur` },
  4: { ID: 4, value: msg`Autre` },
};

const idCTitMoral: keyof Pick<UncastedPhysicalTAdd, 'ID_C_TIT_MORAL'> = 'ID_C_TIT_MORAL';

export const tAddSpecifyMandataryValidatorLogic = dataStructure.T_ADD.fields.TYPE.logic.makeTAddTypeFilterLogic<'requiredString' | 'nilCast'>({
  is: TAddType.MANDATARY,
  truthy: {
    name: `<parentName>.${idCTitMoral}`,
    is: CTitMoral.AUTRE,
    alternatives: {
      truthy: {
        result: 'requiredString',
      },
      falsy: {
        result: 'nilCast',
      },
    },
  },
  falsy: {
    result: 'nilCast',
  },
});
