import { DEFAULT_SECTION, VALID_SECTIONS, ValidSections } from './constants';

export const isValidSections = (section: string): section is ValidSections => VALID_SECTIONS.includes(section as ValidSections);

export const getValidSection = (section: string | undefined) => {
  if (section === '' || section == null) {
    return DEFAULT_SECTION;
  }
  if (!isValidSections(section)) {
    return 'not-found';
  }
  return section;
};
