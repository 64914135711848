import ouiBase from '@goldwasserexchange/oui';
import { useAccountCashflowFieldApiByCpsHisId } from '../api';

export const useAccountCashflowsCpsHisPaieDate = () => {
  const cpsHisId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const result = useAccountCashflowFieldApiByCpsHisId({
    cpsHisId,
    field: 'CPS_HIS.PAIE_DATE',
  });
  return result;
};
