import { Ramp } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';

export const ScrollableBase = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  return (
    <Ramp rampName="ScrollableContext">
      <Ramp rampName="ScrollableWrapper">
        <Ramp
          rampName="ScrollableScrollButton"
          direction="left"
        />
        <Ramp rampName="ScrollableContainer">
          <Ramp rampName="ScrollableListContainer">
            {children}
          </Ramp>
        </Ramp>
        <Ramp
          rampName="ScrollableScrollButton"
          direction="right"
        />
      </Ramp>
    </Ramp>
  );
};
