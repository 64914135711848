import { sanityClient } from '../../client';
import { query } from './query';
import { SwrArguments, QueryArguments, QueryResponse } from './types';

export const convertSwrArguments = (args: SwrArguments): QueryArguments => {
  const [, id] = args;
  return {
    id,
  };
};

export const convertQueryArguments = (args: Partial<QueryArguments>): SwrArguments | null => {
  const {
    id,
  } = args;
  if (id == null) {
    return null;
  }
  return [query, id];
};

export const fetcher = async (args: SwrArguments) => {
  const [queryString] = args;
  const sanityArguments = convertSwrArguments(args);
  const response = await sanityClient.fetch<QueryResponse | null, QueryArguments>(queryString, sanityArguments);
  return response;
};
