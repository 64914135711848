import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { AccountType } from '@goldwasserexchange/oblis-frontend-utils';
import { GlobalPlusIcon } from './icon';
import { globalPlusTwoLineTitleContent } from './title';
import { globalPlusAdvantages } from './advantages';

export const detailedAccountTypeLabelsHeadingContentGlobalPlus = (
  <>
    <TopModel name={`HeadingSectionIllustrationContent-${AccountType.GESTION}-section`}>
      {GlobalPlusIcon}
    </TopModel>
    <TopModel name={`HeadingSectionTitleContent-${AccountType.GESTION}-section`}>
      {globalPlusTwoLineTitleContent}
    </TopModel>
    {globalPlusAdvantages}
  </>
);
