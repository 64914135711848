/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { useClickOutside } from '../../../../OUI';

const searchPlaceContainerStyle: ouiStyle.InStyle = {
  position: 'relative',
  container: ouiStyle.Constants.Container.sm,
  marginHorizontal: 'auto',
  zIndex: 3000,
  paddingBottom: 2,
};

const searchPlaceContainerCss = ouiStyle.makeCss(searchPlaceContainerStyle);

export const SearchPlaceContainer = (props: React.PropsWithChildren<{
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
}>) => {
  const {
    setOpen,
    children,
  } = props;
  const ref = React.useRef<HTMLDivElement>(null);
  useClickOutside(
    ref,
    () => {
      setOpen(false);
    },
  );
  return (
    <div
      ref={ref}
      css={searchPlaceContainerCss}
    >
      {children}
    </div>
  );
};
