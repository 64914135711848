import ouiBase from '@goldwasserexchange/oui';
import { AccountPosition } from '../../type';
import { useAccountPositionFieldApiByGesEstimD, useAccountPositionTransformApiByGesEstimDId } from '../api';

export const useAccountPositionGesEstimDPrice = () => {
  const gesEstimDId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const result = useAccountPositionFieldApiByGesEstimD({
    gesEstimDId,
    field: 'GES_ESTIM_D.PRICE',
  });
  return result;
};

const iconTransformFn = (line: AccountPosition | null | undefined): 'positive' | 'negative' | 'flat' => {
  if (line != null) {
    if ((line['GES_ESTIM_D.PRICE'] ?? 0) > (line['GES_ESTIM_D.BEFORE_PRICE'] ?? 0)) {
      return 'positive';
    }
    return 'negative';
  }
  return 'flat';
};

export const useAccountPositionGesEstimDPriceIconName = () => {
  const gesEstimDId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const result = useAccountPositionTransformApiByGesEstimDId({
    gesEstimDId,
    transformFunction: iconTransformFn,
  });
  return result;
};
