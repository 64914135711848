import memoize from 'memoizee';
import { AccountHistory } from '../../../../accounts';
import { HistoryByCurrency } from '../../type';

export const historyGroupByCurrency = (accountHistory: AccountHistory[] | undefined): HistoryByCurrency[] | undefined => {
  if (!accountHistory) {
    return undefined;
  }
  return Object.values(accountHistory.reduce((acc, historyLine) => ({
    ...acc,
    [historyLine['C_CURRENCY.CODE']]: {
      'C_CURRENCY.CODE': historyLine['C_CURRENCY.CODE'],
      history: [
        ...acc[historyLine['C_CURRENCY.CODE']]?.history ?? [],
        historyLine,
      ],

    },
  }), {}));
};

export const memoHistoryGroupByCurrency: typeof historyGroupByCurrency = memoize(historyGroupByCurrency);
