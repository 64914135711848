import * as React from 'react';
import { useAccountPositionGesEstimDPriceBuy } from '../../hooks';
import { ActorApiNumberDisplay } from '../../../../../../components/display/number/component';

export const AccountsPositionsGesEstimDPriceBuy = () => {
  const {
    data,
    isLoading,
    error,
  } = useAccountPositionGesEstimDPriceBuy();
  return (
    <ActorApiNumberDisplay
      data={data}
      isLoading={isLoading}
      error={error}
      format={{
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }}
    />
  );
};
