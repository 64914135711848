var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from 'react';
import { groupBy } from 'ramda';
import { TopModel } from './components/TopModel';
export function getOtherTopModelProps(topModel) {
    if (topModel && typeof topModel === 'object' && 'props' in topModel && topModel.props) {
        var _a = topModel.props, name_1 = _a.name, isRenderFn = _a.isRenderFn, children = _a.children, otherProps = __rest(_a, ["name", "isRenderFn", "children"]);
        return otherProps;
    }
    return {};
}
export function getTopModelChildren(topModel) {
    if (topModel && typeof topModel === 'object' && 'props' in topModel && topModel.props) {
        return topModel.props.children;
    }
    return undefined;
}
export function getTopModelIsRenderFn(topModel) {
    if (topModel && typeof topModel === 'object' && 'props' in topModel && topModel.props) {
        var _a = topModel.props.isRenderFn, isRenderFn = _a === void 0 ? false : _a;
        return isRenderFn;
    }
    return false;
}
export function getTopModelName(child) {
    if (child && typeof child === 'object' && 'type' in child && child.type === TopModel && child.props && 'name' in child.props && typeof child.props.name === 'string') {
        var name_2 = child.props.name;
        return name_2;
    }
    return 'children';
}
export function extractFragment(child) {
    if (child && typeof child === 'object' && 'type' in child && child.type === React.Fragment) {
        var fragmentKey_1 = child.key;
        var fragmentChildren = child.props.children;
        var fragmentChildrenArray = React.Children.toArray(fragmentChildren);
        var extractedChildrenArray = fragmentChildrenArray.map(function (fragmentChild) { return ((fragmentChild && typeof fragmentChild === 'object' && 'key' in fragmentChild) ? (__assign(__assign({}, fragmentChild), { key: "".concat(fragmentKey_1).concat(fragmentChild.key) })) : fragmentChild); });
        return extractedChildrenArray.reduce(function (acc, extractedChild) { return __spreadArray(__spreadArray([], __read(acc), false), __read(extractFragment(extractedChild)), false); }, []);
    }
    return [child];
}
export function extractChildsFromFragments(children) {
    var extractedChildren = React.Children.toArray(children).reduce(function (acc, child) { return __spreadArray(__spreadArray([], __read(acc), false), __read((extractFragment(child))), false); }, []);
    return extractedChildren;
}
export function getTopModelFromChildren(incomingChildren) {
    var incomingChildrenArray = extractChildsFromFragments(incomingChildren);
    var slots = groupBy(getTopModelName, incomingChildrenArray);
    var children = slots.children, layout = __rest(slots, ["children"]);
    var entries = Object.entries(layout).map(function (_a) {
        var _b = __read(_a, 2), name = _b[0], layouts = _b[1];
        if (!layouts) {
            return null;
        }
        return [
            name,
            layouts[layouts.length - 1],
        ];
    }).filter(function (entry) { return entry != null; });
    var lastLayouts = Object.fromEntries(entries);
    return [children !== null && children !== void 0 ? children : [], lastLayouts];
}
export function prefix(pre) {
    if (pre === void 0) { pre = ''; }
    return function (str) { return "".concat(pre).concat(str); };
}
