import { Trans } from '@lingui/macro';
import * as React from 'react';
import { reach } from 'yup';
import { useFormValidator } from '../../../../../../../Form';
import { useGetFieldPath } from '../../../../../../context';

const IsNumberMinError = () => {
  const validationSchema = useFormValidator();
  const fieldPath = useGetFieldPath();
  const { min } = validationSchema ? reach(validationSchema, fieldPath).describe().meta : { min: null };
  return min ? (
    <Trans>
      Ce champ doit contenir une valeur supérieure ou égale à
      {' '}
      {min}
    </Trans>
  ) : null;
};

export const inputNumberMinErrorMessage = <IsNumberMinError />;
