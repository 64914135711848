export enum DateInterval {
  'three_months',
  'six_months',
  'nine_months',
  'one_year',
  'this_year',
  'last_year'
}

export const VALID_INTERVAL_INTS = Object.keys(DateInterval).map((v) => parseInt(v, 10)).filter((v) => !Number.isNaN(v));
