/* eslint-disable react/jsx-no-useless-fragment */
import * as React from 'react';
import ouiBase from '@goldwasserexchange/oui';
import { useHookstate } from '@hookstate/core';
export var IsPrintContextProvider = function (props) {
    var children = props.children, _a = props.isPrint, isPrint = _a === void 0 ? false : _a;
    var valueState = ouiBase.Form.useFormData();
    var isPrintState = useHookstate(valueState.onboarding.isPrint);
    if (isPrintState.get() !== isPrint) {
        isPrintState.set(isPrint);
    }
    return (React.createElement(React.Fragment, null, children));
};
