/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiBase from '@goldwasserexchange/oui';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { UncastedDataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import { Untracked } from '@hookstate/untracked';
import { useHookstate } from '@hookstate/core';

const checkBoxButtonContainerStyle: ouiStyle.InStyle = {
  lines: 1,
  widthLines: 1,
  borderStyle: 'solid',
  borderWidth: 'simple',
  borderColor: ouiStyle.Constants.Colors.primary,
  fontSize: 's',
  padding: 'none',
  margin: 'none',
  icon: true,
};

const checkBoxButtonContainerCss = ouiStyle.makeCss(checkBoxButtonContainerStyle);

export const CheckBoxButtonContainer = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const rememberDeviceState = useHookstate(valueState.auth.rememberDevice);
  const onClick = React.useCallback(() => {
    const value = Untracked(rememberDeviceState).get();
    if (value === '1') {
      rememberDeviceState.set('0');
    } else {
      rememberDeviceState.set('1');
    }
  }, [rememberDeviceState]);
  return (
    <button
      type="button"
      onClick={onClick}
      css={checkBoxButtonContainerCss}
    >
      {children}
    </button>
  );
};
