import ouiBase from '@goldwasserexchange/oui';
import { nextCallsFilterPredicate } from '../../utils/nextCall';
import { useBondsCallFieldApi, useBondsCallFieldApiByEvtEntrySId } from '../api';

export const useBondsCallEvtEntrySEvtDate = () => {
  const evtEntrySId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const result = useBondsCallFieldApiByEvtEntrySId({
    evtEntrySId,
    field: 'EVT_ENTRY_S.EVT_DATE',
  });
  return result;
};

export const useBondsCallEvtEntrySEvtDateNextCall = () => {
  const result = useBondsCallFieldApi({
    field: 'EVT_ENTRY_S.EVT_DATE',
    filterPredicate: nextCallsFilterPredicate,
  });
  return result;
};
