import { RampWithNameTransform } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import { useInputIconType } from '../../../../../../OUI/Inputs';
import { useScaleEnterLeave } from '../../../../utils';

export function DefaultDomInputIconContent() {
  const iconType = useInputIconType();
  const transition = useScaleEnterLeave(iconType);
  return transition((style, item) => {
    if (item === 'valid') {
      return (
        <RampWithNameTransform
          key={item}
          rampName="InputValidIconBase"
          style={style}
        />
      );
    }
    if (item === 'error') {
      return (
        <RampWithNameTransform
          key={item}
          rampName="InputErrorIconBase"
          style={style}
        />
      );
    }
    if (item === 'help') {
      return (
        <RampWithNameTransform
          key={item}
          rampName="InputHelpIconBase"
          style={style}
        />
      );
    }
    return null;
  });
}
