import * as React from 'react';
import ouiDom from '@goldwasserexchange/oui-dom';
import ouiStyle from '@goldwasserexchange/oui-style';
import { GESanityImage } from '../../../../sanity/queries/shared';
import { SanityImage } from '../../../SanityImage';
import { ErrorBoundary } from '../../../ErrorBoundary';

const SanityContentImageInner = (props: {
  value?: GESanityImage & { link?: string, caption?: string },
}) => {
  const {
    value,
  } = props;
  if (value == null) {
    return null;
  }
  const {
    link,
    caption,
    ...image
  } = value;
  const imageContent = (
    <>
      <SanityImage
        image={image}
        maxPotentialWidth={1280}
        containerMarginBottom={caption ? 'half' : 'simple'}
      />
      {caption && (
        <ouiDom.P.P
          paddingTop="none"
          paddingBottom="simple"
          color={ouiStyle.Constants.Colors.accent}
          lineFactor={0.9}
          textAlign="justify"
        >
          {caption}
        </ouiDom.P.P>
      )}
    </>
  );
  if (link) {
    const url = new URL(link);
    if (url.hostname === window.location.hostname) {
      return (
        <ouiDom.A.A
          to={url.pathname}
        >
          {imageContent}
        </ouiDom.A.A>
      );
    }
    return (
      <ouiDom.A.A
        href={link}
      >
        {imageContent}
      </ouiDom.A.A>
    );
  }
  return imageContent;
};

export const SanityContentImage = (props: {
  value?: GESanityImage & { link?: string, caption?: string },
}) => {
  const {
    value,
  } = props;
  return (
    <ErrorBoundary renderNull>
      <SanityContentImageInner
        value={value}
      />
    </ErrorBoundary>
  );
};
