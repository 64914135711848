/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import { animated } from 'react-spring';
import ouiBase from '@goldwasserexchange/oui';
import ouiStyle from '@goldwasserexchange/oui-style';
import {
  InputVariantsNames,
  useRadioHtmlId,
  useBaseVariant,
  useDisabledVariant,
  useValidationVariant,
  useHasFocusVariant,
  InputVariantHooksArray,
} from '../../../../../../../OUI/Inputs';
import {
  focusLabelStyleAdditionalProperties,
  noFocusLabelStyleAdditionalProperties,
  errorLabelStyleAdditionalProperties,
  disabledLabelStyleAdditionalProperties,
} from '../../../../shared';
import { useVariantSpring } from '../../../../../utils';

export const defaultRadioListInputListElementLabelContainerStyle: ouiStyle.InStyle = {
  display: ouiStyle.Constants.DisplayProp.block,
  fontSize: 's',
  paddingVertical: 'simple',
  flex: 'one',
};

export function useMakedefaultRadioListInputListElementLabelContainerInStyle(
  lines: ouiStyle.InStyle['lines'],
) {
  const inStyle = React.useMemo(
    (): ouiStyle.InStyle => ({
      ...defaultRadioListInputListElementLabelContainerStyle,
      lines,
    }),
    [lines],
  );
  return inStyle;
}

function useMakedefaultRadioListInputListElementLabelContainerCssFromLines(
  lines: ouiStyle.InStyle['lines'],
) {
  const inStyle = useMakedefaultRadioListInputListElementLabelContainerInStyle(
    lines,
  );
  const css = React.useMemo(() => ouiStyle.mediaQuery(ouiStyle.makeCss(inStyle)), [inStyle]);
  return css;
}

export function useMakedefaultRadioListInputListElementLabelContainerCss() {
  const lines = ouiBase.List.useListInputElementLines();
  const css = useMakedefaultRadioListInputListElementLabelContainerCssFromLines(
    lines,
  );
  return css;
}

function useDefaultRadioListInputListElementLabelVariants(): Record<
InputVariantsNames,
Partial<Pick<ouiStyle.Style, 'fontWeight' | 'color'>>
> {
  const lines = ouiBase.List.useListInputElementLines();
  const baseLabelstyleInStyle = useMakedefaultRadioListInputListElementLabelContainerInStyle(
    lines,
  );
  return React.useMemo(
    () => ({
      opened: {},
      closed: {},
      base: ouiStyle.makeCssAndExtract(baseLabelstyleInStyle, ['color']),
      hasContent: {},
      noContent: {},
      focus: ouiStyle.makeCssAndExtract(
        { ...baseLabelstyleInStyle, ...focusLabelStyleAdditionalProperties },
        ['fontWeight'],
      ),
      noFocus: ouiStyle.makeCssAndExtract(
        { ...baseLabelstyleInStyle, ...noFocusLabelStyleAdditionalProperties },
        ['fontWeight'],
      ),
      untouched: {},
      valid: {},
      error: ouiStyle.makeCssAndExtract(
        { ...baseLabelstyleInStyle, ...errorLabelStyleAdditionalProperties },
        ['color'],
      ),
      disabled: ouiStyle.makeCssAndExtract(
        { ...baseLabelstyleInStyle, ...disabledLabelStyleAdditionalProperties },
        ['color'],
      ),
      enabled: {},
      checked: {},
      unChecked: {},
      last: {},
      notLast: {},
      before: {},
      inside: {},
      after: {},
      noSelection: {},
      notCorrected: {},
      correct: {},
      incorrect: {},
      correctSelected: {},
      correctNotSelected: {},
      incorrectSelected: {},
      incorrectNotSelected: {},
    }),
    [baseLabelstyleInStyle],
  );
}

const defaultRadioListInputListElementLabelContainerInputHooksArray: InputVariantHooksArray = [
  useBaseVariant,
  useHasFocusVariant,
  useValidationVariant,
  useDisabledVariant,
];

export function DefaultRadioListInputListElementLabelContainer(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  const htmlFor = useRadioHtmlId();
  const css = useMakedefaultRadioListInputListElementLabelContainerCss();
  const labelVariants = useDefaultRadioListInputListElementLabelVariants();
  const spring = useVariantSpring(
    labelVariants,
    defaultRadioListInputListElementLabelContainerInputHooksArray,
  );
  return (
    <animated.label htmlFor={htmlFor} css={css} style={spring}>
      {children}
    </animated.label>
  );
}
