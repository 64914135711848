import { useCallback } from 'react';
import { useSimulationDataBuyAndSellContext } from '../../context/simulation/hooks';
import {
  computeBuyOrSellCourtage,
  computeBuyAndSellCourtage,
  computeBuyAndSellCourtagePct,
  computeBuyOrSellCourtagePct,
} from '../../utils';
import { useAccountRateTransformApi } from '../api';

export const useAccountRatesTAccRateCourtAmountBuyOrSellOnContext = () => {
  const {
    counterValue,
    type,
    refund,
  } = useSimulationDataBuyAndSellContext();
  const transformFunction = useCallback(computeBuyOrSellCourtage({
    grossAmountInEur: counterValue,
    type,
    currency: 'EUR',
  }), [
    counterValue,
    type,
    refund,
  ]);
  const result = useAccountRateTransformApi({
    transformFunction,
  });
  return result;
};

export const useAccountRatesTAccRateCourtPctBuyOrSellOnContext = () => {
  const {
    counterValue,
    type,
    refund,
  } = useSimulationDataBuyAndSellContext();
  const transformFunction = useCallback(computeBuyOrSellCourtagePct({
    grossAmountInEur: counterValue,
    type,
    currency: 'EUR',
  }), [
    counterValue,
    type,
    refund,
  ]);
  const result = useAccountRateTransformApi({
    transformFunction,
  });
  return result;
};

export const useAccountRatesTAccRateCourtAmountBuyAndSellOnContext = () => {
  const {
    counterValue,
    type,
    refund,
  } = useSimulationDataBuyAndSellContext();
  const transformFunction = useCallback(computeBuyAndSellCourtage({
    counterValue,
    type,
    refund,
  }), [
    counterValue,
    type,
    refund,
  ]);
  const result = useAccountRateTransformApi({
    transformFunction,
  });
  return result;
};

export const useAccountRatesTAccRateCourtAmountBuyAndSellPctOnContext = () => {
  const {
    counterValue,
    type,
    durationYears,
    refund,
  } = useSimulationDataBuyAndSellContext();
  const transformFunction = useCallback(computeBuyAndSellCourtagePct({
    counterValue,
    type,
    durationYears,
    refund,
  }), [
    counterValue,
    type,
    durationYears,
    refund,
  ]);
  const result = useAccountRateTransformApi({
    transformFunction,
  });
  return result;
};
