import * as React from 'react';
import { dataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import { StateFilter } from '../../../../../../../Machine';
import { LazyProductSuccessCard } from './success';
import { StepContainer } from '../../../../utils/Step';
import { LazyFailedStep } from './failed';
import { Suspended } from '../../../../../../../Suspended';

export const ResultCards = (props: { productName: dataStructure.financialProducts.listType }) => {
  const { productName } = props;
  return (
    <StepContainer>
      <StateFilter is=".failedKnowledge">
        <Suspended>
          <LazyFailedStep
            productName={productName}
          />
        </Suspended>
      </StateFilter>
      <StateFilter is=".success">
        <Suspended>
          <LazyProductSuccessCard productName={productName} />
        </Suspended>
      </StateFilter>
    </StepContainer>
  );
};
