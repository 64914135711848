import * as React from 'react';
import { ActorApiTable } from '../../../../../actor/components';
import { useLastSubmittedOnboardings } from '../../../../utils/dynamoDb/onboarding/get';

const loadingValue = [
  'loading-1',
  'loading-2',
  'loading-3',
  'loading-4',
  'loading-5',
];

export const OnboardingStatusTable = (props: React.PropsWithChildren<{
  secondaryDimensionList: string[],
  withPending?: boolean,
  size?: number,
  setSize?: (size: number | ((_size: number) => number)) => Promise<any[][] | undefined>,
  direction?: 'vertical' | 'horizontal',
  wrapping?: 'horizontal' | 'vertical' | 'none',
  multiBody?: boolean,
  topModels?: Record<string, React.ReactNode>,
}>) => {
  const {
    secondaryDimensionList,
    withPending,
    size,
    setSize,
    direction,
    wrapping,
    multiBody,
    topModels,
    children,
  } = props;
  const {
    data: onboardingSaves,
    isLoading,
    error,
  } = useLastSubmittedOnboardings({ withPending });
  const data = React.useMemo(
    () => onboardingSaves?.map(
      (onboardingSave) => onboardingSave.sk,
    ) ?? [],
    [onboardingSaves],
  );
  return (
    <ActorApiTable
      data={data}
      isLoading={isLoading}
      error={error}
      secondaryDimensionList={secondaryDimensionList}
      size={size}
      setSize={setSize}
      direction={direction}
      wrapping={wrapping}
      multiBody={multiBody}
      topModels={topModels}
      loadingValue={loadingValue}
    >
      {children}
    </ActorApiTable>
  );
};
