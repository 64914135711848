import * as React from 'react';
import {
  SQuoteAreaChartWithContainer,
} from '../../../../../../actor/api/Services/securities';

export const BondPriceGraphInner = (props: {
  hasRedacted: boolean | undefined,
  hasRedactedIsLoading: boolean,
  data: {
    x: number,
    y: number,
  }[] | undefined,
  isLoading: boolean,
}) => {
  const {
    hasRedacted,
    hasRedactedIsLoading,
    data,
    isLoading,
  } = props;
  if (!data || data.length === 0) {
    return null;
  }
  return (
    <SQuoteAreaChartWithContainer
      data={data}
      isLoading={isLoading}
      height={200}
      hasRedacted={hasRedacted}
      hasRedactedIsLoading={hasRedactedIsLoading}
    />
  );
};
