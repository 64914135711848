import * as React from 'react';
import { RampWithNameTransform, TopModelContextProvider } from '@goldwasserexchange/react-topmodel';
import ouiBase from '@goldwasserexchange/oui';
import ouiStyle from '@goldwasserexchange/oui-style';
import { UseGetFnProps } from '../shared';
import { SearchKeysProps } from '../shared/components/list/context/searchKeys/context';

export function SelectInputStructure<M>(
  props: React.PropsWithChildren<
  {
    fieldPath: string,
    topModelsOverride?: M,
    elementLines?: Exclude<ouiStyle.InStyle['lines'], 'auto' | 'full'>,
    dependenciesFieldPaths?: string[],
    useAutoSelect?: (autoSelect: boolean) => void,
    autoSelect?: boolean,
  } & SearchKeysProps & UseGetFnProps & ouiBase.List.ListProps & ouiBase.List.ListDataFilterAndSortProviderProps
  >,
) {
  const {
    fieldPath,
    dependenciesFieldPaths,
    topModelsOverride: defaultTopModels,
    list,
    length,
    useGetFn,
    searchKeys,
    children,
    elementLines = 1,
    useFilterFn,
    useSortFn,
    useAutoSelect,
    autoSelect,
  } = props;
  return (
    <TopModelContextProvider topModels={defaultTopModels}>
      {children}
      <RampWithNameTransform<
      {
        fieldPath: string,
        elementLines?: ouiStyle.InStyle['lines'],
        type: Extract<ouiBase.Input.InputTypes, 'radio'>,
        dependenciesFieldPaths?: string[],
        useAutoSelect?: (autoSelect: boolean) => void,
        autoSelect?: boolean,
      } & SearchKeysProps & UseGetFnProps & ouiBase.List.ListProps & ouiBase.List.ListDataFilterAndSortProviderProps
      >
        rampName="InputBase"
        type="radio"
        fieldPath={fieldPath}
        dependenciesFieldPaths={dependenciesFieldPaths}
        list={list}
        length={length}
        elementLines={elementLines}
        useGetFn={useGetFn}
        searchKeys={searchKeys}
        useFilterFn={useFilterFn}
        useSortFn={useSortFn}
        useAutoSelect={useAutoSelect}
        autoSelect={autoSelect}
      />
    </TopModelContextProvider>
  );
}
