import * as React from 'react';
import { RampWithNameTransform, TopModelContextProvider } from '@goldwasserexchange/react-topmodel';
import ouiBase from '@goldwasserexchange/oui';
import ouiStyle from '@goldwasserexchange/oui-style';
import { UseGetFnProps } from '../shared';
import { SearchKeysProps } from '../shared/components/list/context/searchKeys/context';

export function CheckboxListInputStructure<M>(
  props: React.PropsWithChildren<
  {
    fieldPath: string,
    elementFieldPathHook: () => string,
    topModelsOverride?: M,
    elementLines?: ouiStyle.InStyle['lines'],
    dependenciesFieldPaths?: string[],
    checkboxPotentialFieldPaths?: string[],
    validateFieldPath?: boolean,
  } & Partial<SearchKeysProps> & Partial<UseGetFnProps> & ouiBase.List.ListProps & ouiBase.List.ListDataFilterAndSortProviderProps
  >,
) {
  const {
    fieldPath,
    elementFieldPathHook,
    dependenciesFieldPaths,
    checkboxPotentialFieldPaths,
    validateFieldPath,
    topModelsOverride: defaultTopModels,
    list,
    length,
    children,
    elementLines,
    useFilterFn,
    useSortFn,
    useGetFn,
    searchKeys,
  } = props;
  return (
    <TopModelContextProvider topModels={defaultTopModels}>
      {children}
      <RampWithNameTransform<
      {
        fieldPath: string,
        elementFieldPathHook: () => string,
        elementLines?: ouiStyle.InStyle['lines'],
        type: Extract<ouiBase.Input.InputTypes, 'checkbox'>,
        dependenciesFieldPaths?: string[],
        checkboxPotentialFieldPaths?: string[],
        validateFieldPath?: boolean,
      } & Partial<SearchKeysProps> & Partial<UseGetFnProps> & ouiBase.List.ListProps
      >
        rampName="InputBase"
        type="checkbox"
        fieldPath={fieldPath}
        elementFieldPathHook={elementFieldPathHook}
        dependenciesFieldPaths={dependenciesFieldPaths}
        checkboxPotentialFieldPaths={checkboxPotentialFieldPaths}
        validateFieldPath={validateFieldPath}
        list={list}
        length={length}
        elementLines={elementLines}
        useFilterFn={useFilterFn}
        useSortFn={useSortFn}
        useGetFn={useGetFn}
        searchKeys={searchKeys}
      />
    </TopModelContextProvider>
  );
}
