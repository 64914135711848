import React from 'react';
import { useHookstate } from '@hookstate/core';
import ouiBase from '@goldwasserexchange/oui';
import ouiDom from '@goldwasserexchange/oui-dom';
import { Plural, Trans } from '@lingui/macro';
import { uniq } from 'ramda';
import {
  AccountType,
  UncastedDataStructure,
  dataStructure,
  STypeGrp,
} from '@goldwasserexchange/oblis-frontend-utils';
import { useLingui } from '@lingui/react';
import { StepContainer } from '../../utils/Step';
import { AccountTypeSelectionTable } from '../../../../../AccountType/table';
import { useStateFilter } from '../../../../../Machine';
import { stringValidator, makeRequired } from '../../../../../../OUI/Inputs';
import { accountTypeTitles } from '../../../../../AccountType';
import { WarningCard } from '../../../../../Card';

export const serviceSelectionValidator = makeRequired(stringValidator);

const useRecommendedService = () => {
  const isRto = useStateFilter('.RTO');
  const isConseil = useStateFilter('.conseil');
  const isGestion = useStateFilter('.gestion');
  if (isRto) {
    return AccountType.RTO;
  }
  if (isConseil) {
    return AccountType.CONSEIL;
  }
  if (isGestion) {
    return AccountType.GESTION;
  }
  return undefined;
};

const Separator = (props: { index: number, length: number }) => {
  const {
    index,
    length,
  } = props;
  if (index === length - 1) {
    return <>.</>;
  }
  if (index === length - 2) {
    return (
      <>
        {' '}
        <Trans>et</Trans>
      </>
    );
  }
  return <>,</>;
};

const HasInnaccessibleServiceWarning = () => {
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const servicesAccessibility = useHookstate(valueState.onboarding.serviceAccessibility).get();
  const innaccessibleServices: AccountType[] = Object.entries(servicesAccessibility).filter(([_, value]) => !value).map(([key]) => key as AccountType);
  const products = useHookstate(valueState.onboarding.products).get();
  const {
    i18n,
  } = useLingui();
  const requiredProducts = uniq(innaccessibleServices.reduce<STypeGrp[]>(
    (acc, innaccessibleService) => {
      const prod = dataStructure.financialProducts.fields.getFailedRequiredProductListFromAccountType(innaccessibleService, products);
      return [...acc, ...prod];
    },
    [],
  ));
  const shouldWait1Day = requiredProducts.some((requiredProduct) => products[requiredProduct].knowledgeQuestionnaires.conseilGestion.failureAndWasRetried);
  return innaccessibleServices.length > 0 && requiredProducts.length > 0
    ? (
      <WarningCard>
        <ouiDom.P.P>
          <Plural
            value={innaccessibleServices.length}
            _0=""
            one="Le service suivant est temporairement inaccessible :"
            other="Les services suivants sont temporairement inaccessibles :"
          />
          {innaccessibleServices.map((service, index, arr) => (
            <>
              {' '}
              {accountTypeTitles[service](i18n)}
              <Separator index={index} length={arr.length} />
            </>
          ))}
          {' '}
          <Plural
            value={requiredProducts.length}
            _0=""
            one="Vous avez raté le test de connaissances pour le produit requis suivant:"
            other="Vous avez raté les tests de connaissances pour les produits requis suivants:"
          />
          {requiredProducts.map((product, index, arr) => (
            <>
              {' '}
              <dataStructure.financialProducts.fields.labels.Label financialProduct={product} />
              <Separator index={index} length={arr.length} />
            </>
          ))}
          {' '}
          <Plural
            value={innaccessibleServices.length}
            _0=""
            one="Tant que ce test n'est pas réussi vous ne pourrez pas terminer l'ouverture d'un compte de ce type."
            other="Tant que ces tests ne sont pas réussis vous ne pourrez pas terminer l'ouverture d'un compte de ce type."
          />
          {' '}
          {shouldWait1Day ? (
            <Trans>
              Vous avez épuisé vos essais et devez attendre 24 heures avant de pouvoir repasser le test de connaissance.
            </Trans>
          ) : null}
        </ouiDom.P.P>
      </WarningCard>
    )
    : null;
};

export const ServiceSelection = () => {
  const highlight = useRecommendedService();
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const servicesAccessibility = useHookstate(valueState.onboarding.serviceAccessibility).get();
  const hasInnaccessibleService = Object.values(servicesAccessibility).some((serviceAccessibility) => serviceAccessibility === false);
  return (
    <StepContainer large>
      {hasInnaccessibleService ? <HasInnaccessibleServiceWarning /> : null}
      <AccountTypeSelectionTable
        highlight={highlight}
      />
    </StepContainer>
  );
};
