import ouiBase from '@goldwasserexchange/oui';
import * as preferences from '../preferences';
import * as taxonomy from '../taxonomy';
import * as taxonomyAlignment from '../taxonomyAlignment';
import * as taxonomyProportion from '../taxonomyProportion';
import * as sfdr from '../sfdr';
import * as sfdrDurableProportion from '../sfdrDurableProportion';
import * as sfdrDurableOnly from '../sfdrDurableOnly';
import * as sfdrSocialEnvironmentalOnly from '../sfdrSocialEnvironmentalOnly';
import * as sfdrSocialEnvironmentalProportion from '../sfdrSocialEnvironmentalProportion';
import * as incidence from '../incidence';
import * as incidenceType from '../incidenceType';
import * as incidenceProportion from '../incidenceProportion';
export var map = {
    preferences: preferences.logic.show.showLogic,
    taxonomy: taxonomy.logic.show.showLogic,
    taxonomyAlignment: taxonomyAlignment.logic.show.showLogic,
    taxonomyProportion: taxonomyProportion.logic.show.showLogic,
    sfdr: sfdr.logic.show.showLogic,
    sfdrDurableProportion: sfdrDurableProportion.logic.show.showLogic,
    sfdrSocialEnvironmentalProportion: sfdrSocialEnvironmentalProportion.logic.show.showLogic,
    sfdrDurableOnly: sfdrDurableOnly.logic.show.showLogic,
    sfdrSocialEnvironmentalOnly: sfdrSocialEnvironmentalOnly.logic.show.showLogic,
    incidence: incidence.logic.show.showLogic,
    incidenceType: incidenceType.logic.show.showLogic,
    incidenceProportion: incidenceProportion.logic.show.showLogic,
};
export var mapLogic = {
    map: [
        preferences.logic.show.showLogicBoolean,
        taxonomy.logic.show.showLogicBoolean,
        taxonomyAlignment.logic.show.showLogicBoolean,
        taxonomyProportion.logic.show.showLogicBoolean,
        sfdr.logic.show.showLogicBoolean,
        sfdrDurableProportion.logic.show.showLogicBoolean,
        sfdrSocialEnvironmentalProportion.logic.show.showLogicBoolean,
        sfdrDurableOnly.logic.show.showLogicBoolean,
        sfdrSocialEnvironmentalOnly.logic.show.showLogicBoolean,
        incidence.logic.show.showLogicBoolean,
        incidenceType.logic.show.showLogicBoolean,
        incidenceProportion.logic.show.showLogicBoolean,
    ],
};
export var useShowList = function () { return ouiBase.utils.hookstate.useFormFilterLogicMap(mapLogic, 'durability'); };
