import useSWR, { SWRResponse } from 'swr';
import { useAtomValue } from 'jotai';
import { QueryArguments, SwrArguments, QueryResponse } from '../../types';
import { convertQueryArguments, fetcher } from '../../utils';
import { pathnameUrlLanguageAtom } from '../../../../../history';

export const useFirstSanityBanner = (props: QueryArguments): SWRResponse<QueryResponse | null, any, any> => {
  const swrArguments: SwrArguments = convertQueryArguments(props);
  const response = useSWR(swrArguments, fetcher);
  return response;
};

export const useLocalizedFirstSanityBanner = (): SWRResponse<QueryResponse | null, any, any> => {
  const locale = useAtomValue(pathnameUrlLanguageAtom);
  return useFirstSanityBanner({ locale });
};
