import { useFilterByPrimaryDimensionTAccTwrrPId } from '../../utils';
import { useAccountTwrrFieldApi } from '../api';

export const useAccountTwrrPNavEnd = () => {
  const filterPredicate = useFilterByPrimaryDimensionTAccTwrrPId();
  const result = useAccountTwrrFieldApi({
    filterPredicate,
    field: 'T_ACC_TWRR_P.NAV_END',
  });
  return result;
};
