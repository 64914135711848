import { useMachine } from '@xstate/react';
import * as React from 'react';
import { useAtomValue } from 'jotai';
import { useAnimationApi } from '../../animation';
import { appBarMachine } from '../machine';
import { machineContext } from './context';
import { useEmitSectionChange } from './useEmitSectionChange';
import { pathnameUrlSectionAtom } from '../../../../history';

export const MachineContextProvider = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  const section = useAtomValue(pathnameUrlSectionAtom);
  const springApi = useAnimationApi();
  const initialSection = React.useMemo(() => section, []);
  const frozenMachine = React.useMemo(() => appBarMachine(springApi, initialSection), []);
  const [, , service] = useMachine(frozenMachine);
  useEmitSectionChange(service, section);
  return (
    <machineContext.Provider value={service}>
      {children}
    </machineContext.Provider>
  );
};
