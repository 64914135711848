import { atom } from 'jotai';
import { pathnamePartFamilly } from '../../../atoms';
import { getValidBondSection } from './utils';

export const pathnameRawUrlBondsSectionAtom = atom((get) => {
  const subSection = get(pathnamePartFamilly(2));
  if (subSection == null) {
    return '';
  }
  return subSection;
});

export const pathnameUrlBondsSectionAtom = atom((get) => {
  const subSection = get(pathnameRawUrlBondsSectionAtom);
  return getValidBondSection(subSection);
});
