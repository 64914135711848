import React from 'react';
import {
  dataStructure, TAddType,
} from '@goldwasserexchange/oblis-frontend-utils';
import { Trans } from '@lingui/macro';
import ouiBase from '@goldwasserexchange/oui';

export const tAddProfessionLabel = <Trans>Profession</Trans>;

export const showTAddIdEcplProfLogic = dataStructure.T_ADD.fields.TYPE.logic.makeTAddIsTypePhysicLogic<'visible' | 'invisible'>({
  alternatives: {
    truthy: {
      result: 'visible',
    },
    falsy: {
      result: 'invisible',
    },
  },
});

export const tAddIdEcpliProfValidatorLogic: ouiBase.utils.hookstate.FormValueFilterLogic<'requiredNumber' | 'nilCast'> = dataStructure.T_ADD.fields.TYPE.logic.makeTAddIsTypePhysicLogic({
  alternatives: {
    truthy: {
      result: 'requiredNumber',
    },
    falsy: {
      result: 'nilCast',
    },
  },
});

export const idEcplProfMayHaveProfessionHasLastProfessionFilterLogic: ouiBase.utils.hookstate.FormValueFilterLogic<'visible' | 'invisible'> = dataStructure.T_ADD.fields.ID_ECPL_PROF.logic.makeIdEcplProfMayHaveProfessionHasLastProfessionFilterLogic<'visible' | 'invisible'>({
  visible: {
    result: 'visible',
  },
  invisible: {
    result: 'invisible',
  },
});

export const makeIdEcplProfShouldHaveSectorFilterLogic = (
  visible: ouiBase.utils.hookstate.FormValueFilterLogic<'visible' | 'invisible'> = {
    result: 'visible',
  },
  invisible: ouiBase.utils.hookstate.FormValueFilterLogic<'visible' | 'invisible'> = {
    result: 'invisible',
  },
) => dataStructure.T_ADD.fields.TYPE.logic.makeTAddTypeFilterLogic({
  is: TAddType.MORAL,
  options: { isNegative: true },
  truthy: dataStructure.T_ADD.fields.ID_ECPL_PROF.logic.makeIdEcplProfShouldHaveProfessionLogic({ visible, invisible }),
  falsy: visible,
});
