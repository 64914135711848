import { useEffect, useCallback } from 'react';

const isInside = (domNode: HTMLElement | null, event: MouseEvent) => {
  if (domNode != null) {
    const target = event.target as Element;

    return domNode.contains(target);
  }
  return false;
};

export type useClickOutsideArgs = {
  onClickOutside?: (ev: MouseEvent) => void,
};

export const useClickOutside = <T extends HTMLElement>(
  ref: React.RefObject<T>,
  onClickOutside: ((ev: MouseEvent) => void) | undefined,
) => {
  const handleClick = useCallback(
    (event) => (
      onClickOutside != null
        && !isInside(ref.current, event)
        && onClickOutside(event)
    ),
    [onClickOutside],
  );
  useEffect(() => {
    if (onClickOutside != null) {
      document.addEventListener('mouseup', handleClick);
    }
    return () => {
      if (onClickOutside != null) {
        document.removeEventListener('mouseup', handleClick);
      }
    };
  }, [onClickOutside]);
};
