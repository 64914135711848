import { useCallback } from 'react';
import { useNumberFormatter } from '../../../../../../../Components/NumberDisplay';
import { transformAskOrMidPriceFn } from '../../../shared';
import { BondResponse } from '../../type';
import { useBondsBondTransformApi } from '../api';

export const useBondsBondSQuotePrice = () => {
  const response = useBondsBondTransformApi(transformAskOrMidPriceFn);
  return response;
};

const format = {
  format: {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 4,
  },
  divider: 100,
} as const;

export const useBondsBondSQuotePriceString = () => {
  const priceFormatter = useNumberFormatter(format);
  const bondPriceStringTransformFn = useCallback((bondsBond: BondResponse | undefined) => {
    if (bondsBond == null) {
      return undefined;
    }
    const priceMax = bondsBond['S_QUOTE.PRICE_MAX'];
    const priceClose = bondsBond['S_QUOTE.PRICE_CLOSE'];
    if (priceMax == null && priceClose == null) {
      return undefined;
    }
    if (priceMax != null) {
      return priceFormatter(priceMax);
    }
    if (priceClose == null) {
      return undefined;
    }
    return priceFormatter(priceClose);
  }, [priceFormatter]);
  const response = useBondsBondTransformApi(bondPriceStringTransformFn);
  return response;
};
