import { makeSelectedLogicByFinancialProduct } from '../../logic/selected/make';
import { STypeGrp } from '../../../../types';
export * from '../../../financialProducts/fields/BOND/logic';
export function makeSelectedLogic(props) {
    var alternatives = props.alternatives;
    return makeSelectedLogicByFinancialProduct({
        financialProduct: STypeGrp.OBLIGATIONS,
        alternatives: alternatives,
    });
}
