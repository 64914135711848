import { makeTAddIsNotMoralOrIgnored } from '../../TYPE/logic';
var onboardingPath = 'onboarding';
var isPrintPath = 'isPrint';
var fiscalResidencySameAsResidencyPath = 'fiscalResidencySameAsResidency';
var idCCountryNatPath = 'ID_C_COUNTRY_NAT';
var addressPath = 'address';
var fiscalAddressPath = 'fiscalAddress';
var idCCountryPath = 'ID_C_COUNTRY';
export function makeShowTAddFiscRegistNumAfterCountryLogic(props) {
    var national = props.national, global = props.global, invisible = props.invisible, nationalInNatNumber = props.nationalInNatNumber;
    return {
        truthy: {
            name: "<parentName>.".concat(idCCountryNatPath),
            is: 212,
            options: {
                isNegative: true,
            },
            alternatives: {
                truthy: national,
                falsy: {
                    name: "".concat(onboardingPath, ".").concat(isPrintPath),
                    is: false,
                    alternatives: {
                        truthy: invisible,
                        falsy: nationalInNatNumber,
                    },
                },
            },
        },
        falsy: global,
    };
}
export function makeShowLogic(props) {
    var national = props.national, global = props.global, invisible = props.invisible, _a = props.nationalInNatNumber, nationalInNatNumber = _a === void 0 ? invisible : _a;
    return makeTAddIsNotMoralOrIgnored({
        truthy: {
            name: "<parentName>.".concat(fiscalResidencySameAsResidencyPath),
            is: undefined,
            options: {
                isNegative: true,
            },
            alternatives: {
                truthy: {
                    name: "<parentName>.".concat(fiscalResidencySameAsResidencyPath),
                    is: true,
                    alternatives: {
                        truthy: {
                            name: "<parentName>.".concat(addressPath, ".").concat(idCCountryPath),
                            is: 212,
                            alternatives: makeShowTAddFiscRegistNumAfterCountryLogic({
                                national: national,
                                global: global,
                                invisible: invisible,
                                nationalInNatNumber: nationalInNatNumber,
                            }),
                        },
                        falsy: {
                            name: "<parentName>.".concat(fiscalAddressPath, ".").concat(idCCountryPath),
                            is: 212,
                            alternatives: makeShowTAddFiscRegistNumAfterCountryLogic({
                                national: national,
                                global: global,
                                invisible: invisible,
                                nationalInNatNumber: nationalInNatNumber,
                            }),
                        },
                    },
                },
                falsy: invisible,
            },
        },
    });
}
export var showLogic = makeShowLogic({
    national: {
        result: 'visible',
    },
    global: {
        result: 'visible',
    },
    invisible: {
        result: 'invisible',
    },
    nationalInNatNumber: {
        result: 'visible',
    },
});
export var showBooleanLogic = makeShowLogic({
    national: {
        result: true,
    },
    global: {
        result: true,
    },
    invisible: {
        result: false,
    },
    nationalInNatNumber: {
        result: true,
    },
});
