export var initialRTOValues = function () { return ({
    RTOSubordinatedBondQuestion0Answer: undefined,
    RTOSubordinatedBondQuestion0RemedialAnswer: undefined,
    RTOSubordinatedBondQuestion0IsCorrect: undefined,
    RTOSubordinatedBondQuestion0RemedialIsCorrect: undefined,
    RTOSubordinatedBondQuestion0WasRetried: undefined,
    RTOSubordinatedBondQuestion0IsCorrected: false,
    RTOSubordinatedBondQuestion0RemedialIsCorrected: false,
    RTOSubordinatedBondQuestion1Answer: undefined,
    RTOSubordinatedBondQuestion1RemedialAnswer: undefined,
    RTOSubordinatedBondQuestion1IsCorrect: undefined,
    RTOSubordinatedBondQuestion1RemedialIsCorrect: undefined,
    RTOSubordinatedBondQuestion1IsCorrected: false,
    RTOSubordinatedBondQuestion1RemedialIsCorrected: false,
    RTOSubordinatedBondQuestion1WasRetried: undefined,
}); };
export var initialConseilGestionValues = function () { return ({
    subordinatedBondDefinitionAnswer: undefined,
    subordinatedBondDefinitionIsCorrect: undefined,
    subordinatedBondDefinitionWasRetried: false,
    subordinatedBondDefinitionIsCorrected: false,
    subordinatedBondVariationAnswer: undefined,
    subordinatedBondVariationIsCorrect: undefined,
    subordinatedBondVariationWasRetried: false,
    subordinatedBondVariationIsCorrected: false,
}); };
