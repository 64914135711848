import ouiBase from '@goldwasserexchange/oui';
import { useFilterByContextSTypeGrpId, computeAverageYield } from '../../utils';
import { useAccountPositionFieldApiByGesEstimD, useAccountPositionsTransformApi } from '../api';

export const useAccountPositionGesEstimDYield = () => {
  const gesEstimDId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const result = useAccountPositionFieldApiByGesEstimD({
    gesEstimDId,
    field: 'GES_ESTIM_D.YIELD',
  });
  return result;
};

export const useAccountPositionGesEstimDYieldTotalBySTypeGrp = () => {
  const filterPredicate = useFilterByContextSTypeGrpId();
  const result = useAccountPositionsTransformApi({
    filterPredicate,
    transformFunction: computeAverageYield,
  });
  return result;
};
