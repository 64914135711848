import * as React from 'react';
import { Menu } from 'react-feather';
import { Trans } from '@lingui/macro';
import ouiStyle from '@goldwasserexchange/oui-style';
import HeaderContainer from './headerContainer';
import LogoContainer from './logoContainer';
import MenuButtonContainer from './menuButtonContainer';
import NavBar from './navBar';
import { NavBarContainer } from './navBarContainer';
import {
  NavBarButtonLinkElement,
  NavBarLinkElement,
} from './navBarLinkWithChildren';
import NavBarList from './navBarList';
import NavBarPlaceHolder from './navBarPlaceHolder';
import Skrim from './skrim';
import ToolBar from './toolBar';
import ToolBarList from './toolBarList';
import AppBarLanguageSelector from './languageSelector';
import Logo from './logo';
import { ValidSections } from '../../../history';
import ToolBarListElement from './toolBarListElement';
import { useIsConnected } from '../../Auth';
import { UserMenu } from './userMenu';
import { MediaContext } from '../../MediaContext';

export function AppBar(): JSX.Element {
  const isSignedIn = useIsConnected();
  const {
    upXl,
  } = React.useContext(MediaContext);
  return (
    <HeaderContainer>
      <Skrim />
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <NavBarPlaceHolder>
        <NavBarContainer>
          <NavBar>
            <NavBarList>
              <NavBarLinkElement
                section={ValidSections.HOME}
                iconName="home"
              >
                <Trans>
                  Accueil
                </Trans>
              </NavBarLinkElement>
              <NavBarLinkElement
                section={ValidSections.NEWS}
                iconName="news"
              >
                <Trans>
                  Actualités
                </Trans>
              </NavBarLinkElement>
              <NavBarLinkElement
                section={ValidSections.BONDS}
                iconName="bonds"
              >
                <Trans>
                  Obligations
                </Trans>
              </NavBarLinkElement>
              {upXl ? (
                <NavBarLinkElement
                  section={ValidSections.CONTACT}
                  iconName="contact"
                >
                  <Trans>
                    Contact
                  </Trans>
                </NavBarLinkElement>
              ) : null}
              {
                !isSignedIn
                  ? (
                    <NavBarButtonLinkElement
                      section={ValidSections.ONLINE}
                      backgroundColor={ouiStyle.Constants.Colors.primary}
                      hoverBackgroundColor={ouiStyle.Constants.Colors.primaryShaded}
                    >
                      <Trans>
                        Mon Compte
                      </Trans>
                    </NavBarButtonLinkElement>
                  ) : (
                    <NavBarLinkElement
                      section={ValidSections.ONLINE}
                      iconName="accounts"
                    >
                      <Trans>
                        Mon Compte
                      </Trans>
                    </NavBarLinkElement>
                  )
              }
              {
                !isSignedIn
                  ? (
                    <NavBarButtonLinkElement
                      section={ValidSections.ONBOARDING}
                      backgroundColor={ouiStyle.Constants.Colors.accent}
                      hoverBackgroundColor={ouiStyle.Constants.Colors.accentShaded}
                    >
                      <Trans>
                        Devenir client
                      </Trans>
                    </NavBarButtonLinkElement>
                  )
                  : (
                    <NavBarLinkElement
                      section={ValidSections.ONBOARDING}
                      iconName="onboarding"
                    >

                      <Trans>
                        Ouvrir un compte
                      </Trans>
                    </NavBarLinkElement>
                  )
              }
              {!upXl ? (
                <NavBarLinkElement
                  section={ValidSections.CONTACT}
                  iconName="contact"
                >
                  <Trans>
                    Contact
                  </Trans>
                </NavBarLinkElement>
              ) : null}
            </NavBarList>
          </NavBar>
          <ToolBar>
            <ToolBarList>
              {
                isSignedIn
                  ? (
                    <ToolBarListElement>
                      <UserMenu />
                    </ToolBarListElement>
                  ) : null
              }
              <ToolBarListElement>
                <AppBarLanguageSelector />
              </ToolBarListElement>
            </ToolBarList>
          </ToolBar>
        </NavBarContainer>
        <MenuButtonContainer>
          <Menu />
        </MenuButtonContainer>
      </NavBarPlaceHolder>
    </HeaderContainer>
  );
}
