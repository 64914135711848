import { useCallback } from 'react';
import { useAuthMachineSend } from '../../../../../Components/Auth';
import { AuthSendType } from '../../../../../Components/Auth/context/context';
import { throttledGetTokens } from '../../../../../aws';

const makeDebouncedSend = (status: string) => {
  let running = false;
  return (send: AuthSendType) => {
    if (!running) {
      running = true;
      send(status);
      setTimeout(() => {
        running = false;
      }, 100);
    }
  };
};

const debouncedSendReAuth = makeDebouncedSend('REAUTH');

const getHeaders = async (options?: {
  withAccessToken?: boolean,
  contentType?: string,
  send?: AuthSendType,
}) => {
  const {
    withAccessToken = true,
    contentType = 'application/json',
    send,
  } = options ?? {};
  const {
    accessToken,
    idToken,
  } = await throttledGetTokens(send);
  const headers = new Headers();
  headers.set('Authorization', idToken.getJwtToken());
  if (withAccessToken) {
    headers.set('X-Cognito-AccessToken', accessToken.getJwtToken());
  }
  headers.set('Content-Type', contentType);
  return headers;
};

export const fetchData = async (url: string, options: {
  headers: Headers,
  method?: RequestInit['method'],
  mode?: RequestInit['mode'],
  body?: RequestInit['body'],
  send?: AuthSendType,
}) => {
  const {
    method,
    mode = 'cors',
    headers,
    body,
    send,
  } = options;
  const response = await fetch(url, {
    method,
    mode,
    credentials: 'same-origin',
    headers,
    body,
  });
  if (!response.ok) {
    if (response.status === 401 && send) {
      debouncedSendReAuth(send);
    }
    throw response;
  }
  return response.json();
};

export const fetchWithAuth = async (url: string, options?: {
  withAccessToken?: boolean,
  method?: RequestInit['method'],
  mode?: RequestInit['mode'],
  body?: RequestInit['body'],
  contentType?: string,
  send?: AuthSendType,
}) => {
  const {
    withAccessToken,
    method = 'GET',
    mode,
    body,
    contentType,
    send,
  } = options ?? {};
  const headers = await getHeaders({
    withAccessToken,
    contentType,
    send,
  });
  return fetchData(url, {
    headers,
    method,
    mode,
    body,
    send,
  });
};

export const fetchWithOptionalAuth = async (url: string, options?: {
  withAccessToken?: boolean,
  method?: RequestInit['method'],
  mode?: RequestInit['mode'],
  body?: RequestInit['body'],
  contentType?: string,
}) => {
  const {
    withAccessToken,
    method = 'GET',
    mode,
    body,
    contentType,
  } = options ?? {};
  let headers: Headers | undefined;
  try {
    headers = await getHeaders({
      withAccessToken,
      contentType,
    });
  } catch (err) {
    headers = new Headers();
  }
  return fetchData(url, {
    headers,
    mode,
    method,
    body,
  });
};

export const makeFetchWithAuthWithSend = (send: AuthSendType) => (url: string, options?: {
  withAccessToken?: boolean,
  method?: RequestInit['method'],
  body?: RequestInit['body'],
  contentType?: string,
}) => fetchWithAuth(url, { ...options, send });

export const useFetchWithAuthWithSend = (withSend = true) => {
  const send = useAuthMachineSend();
  const fetcher = useCallback(withSend ? makeFetchWithAuthWithSend(send) : fetchWithAuth, [send, withSend]);
  return fetcher;
};
