import { computeDimension } from '../utils/dimensions/computeDimension';
export function makeWidth(props) {
    var widthLines = props.widthLines, fontSize = props.fontSize, paddingLeft = props.paddingLeft, paddingRight = props.paddingRight, childPaddingLeft = props.childPaddingLeft, childPaddingRight = props.childPaddingRight, parentBorderLeftWidth = props.parentBorderLeftWidth, parentBorderRightWidth = props.parentBorderRightWidth, icon = props.icon, lineFactor = props.lineFactor, minusWidth = props.minusWidth;
    return computeDimension({
        factor: widthLines,
        fontSize: fontSize,
        paddingStart: paddingLeft,
        paddingEnd: paddingRight,
        childPaddingStart: childPaddingLeft,
        childPaddingEnd: childPaddingRight,
        parentBorderStart: parentBorderLeftWidth,
        parentBorderEnd: parentBorderRightWidth,
        icon: icon,
        lineFactor: lineFactor,
        minus: minusWidth,
    });
}
