import * as React from 'react';
import { ActorApiStringDisplay } from '../../../../../components/display/string';
import { usePersonDevicesCountry } from '../hooks';

export const PersonsDevicesCountry = () => {
  const {
    data,
    isLoading,
    error,
  } = usePersonDevicesCountry();
  return (
    <ActorApiStringDisplay
      data={data}
      isLoading={isLoading}
      error={error}
    />
  );
};
