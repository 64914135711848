import { createContext } from 'react';
import {
  BaseActionObject, Interpreter, ResolveTypegenMeta, ServiceMap, TypegenDisabled,
} from 'xstate';
import { AppBarEvent } from '../events';
import { AppBarMachineContext } from '../machineContext';

export type AppBarMachineController = Interpreter<AppBarMachineContext, any, AppBarEvent, {
  value: any,
  context: AppBarMachineContext,
}, ResolveTypegenMeta<TypegenDisabled, AppBarEvent, BaseActionObject, ServiceMap>>;

export const machineContext = createContext<AppBarMachineController | null>(null);
