import { useCallback, useContext } from 'react';
import { popperOpenContext } from './context';

export const usePopperOpenContext = () => useContext(popperOpenContext);

export const usePopperOpen = () => {
  const [popperOpen] = usePopperOpenContext();
  return popperOpen;
};

export const useSetPopperOpenState = () => {
  const [, setPopperOpen] = usePopperOpenContext();
  return setPopperOpen;
};

export const useSetPopperToOpenHandler = () => {
  const [open, setOpen] = usePopperOpenContext();
  const setToOpen = useCallback(() => {
    if (!open) {
      setOpen(true);
    }
  }, [open, setOpen]);
  return setToOpen;
};

export const useSetPopperToClosedHandler = () => {
  const [open, setOpen] = usePopperOpenContext();
  const setToOpen = useCallback(() => {
    if (open === true) {
      setOpen(false);
    }
  }, [open, setOpen]);
  return setToOpen;
};

export const useTogglePopperOpen = () => {
  const setPopperOpen = useSetPopperOpenState();
  const togglePopperOpen = useCallback(() => {
    setPopperOpen((s) => !s);
  }, [setPopperOpen]);
  return togglePopperOpen;
};
