var _a;
/* eslint-disable quote-props */
import * as pathUtils from './pathUtils';
import { BOND_CURRENCY_RISK_KEY, BOND_DEFAULT_KEY, BOND_DEFINITION_KEY, BOND_SALE_KEY, BOND_VARIATION_KEY, FUND_CURRENCY_RISK_KEY, FUND_DEFAULT_KEY, FUND_DEFINITION_KEY, FUND_NET_ASSET_KEY, FUND_SALE_KEY, FUND_VARIATION_KEY, PERPETUAL_BOND_CALL_KEY, PERPETUAL_BOND_DEFINITION_KEY, PERPETUAL_BOND_VARIATION_KEY, RAW_MATERIAL_TRACKER_RISK_CAUSE_KEY, RAW_MATERIAL_TRACKER_RISK_KEY, STOCK_CURRENCY_RISK_KEY, STOCK_DEFAULT_KEY, STOCK_DEFINITION_KEY, STOCK_SALE_KEY, STOCK_VARIATION_KEY, SUBORDINATED_BOND_DEFINITION_KEY, SUBORDINATED_BOND_VARIATION_KEY, TRACKER_AND_ETF_CURRENCY_RISK_KEY, TRACKER_AND_ETF_DEFINITION_KEY, TRACKER_AND_ETF_SALE_KEY, TRACKER_AND_ETF_SYNTHETIC_KEY, TRACKER_AND_ETF_SYNTHETIC_RISK_KEY, TRACKER_AND_ETF_VARIATION_KEY, } from '../../knowledgeQuestionKeys';
import { STypeGrp } from '../../types';
export var questionnaireQuestions = (_a = {},
    _a[pathUtils.makeAnswerKey(BOND_DEFINITION_KEY)] = {
        id: pathUtils.makeAnswerKey(BOND_DEFINITION_KEY),
        questionnaires: [STypeGrp.OBLIGATIONS],
        correct: '2',
        parentName: 'knowledgeQuestionnaire',
    },
    _a[pathUtils.makeAnswerKey(BOND_VARIATION_KEY)] = {
        id: pathUtils.makeAnswerKey(BOND_VARIATION_KEY),
        questionnaires: [STypeGrp.OBLIGATIONS],
        correct: '0',
        parentName: 'knowledgeQuestionnaire',
    },
    _a[pathUtils.makeAnswerKey(BOND_DEFAULT_KEY)] = {
        id: pathUtils.makeAnswerKey(BOND_DEFAULT_KEY),
        questionnaires: [STypeGrp.OBLIGATIONS],
        correct: '2',
        parentName: 'knowledgeQuestionnaire',
    },
    _a[pathUtils.makeAnswerKey(BOND_SALE_KEY)] = {
        id: pathUtils.makeAnswerKey(BOND_SALE_KEY),
        questionnaires: [STypeGrp.OBLIGATIONS],
        correct: '0',
        parentName: 'knowledgeQuestionnaire',
    },
    _a[pathUtils.makeAnswerKey(BOND_CURRENCY_RISK_KEY)] = {
        id: pathUtils.makeAnswerKey(BOND_CURRENCY_RISK_KEY),
        questionnaires: [STypeGrp.OBLIGATIONS],
        correct: '0',
        parentName: 'knowledgeQuestionnaire',
    },
    _a[pathUtils.makeAnswerKey(STOCK_DEFINITION_KEY)] = {
        id: pathUtils.makeAnswerKey(STOCK_DEFINITION_KEY),
        questionnaires: [STypeGrp.ACTIONS],
        correct: '0',
        parentName: 'knowledgeQuestionnaire',
    },
    _a[pathUtils.makeAnswerKey(STOCK_VARIATION_KEY)] = {
        id: pathUtils.makeAnswerKey(STOCK_VARIATION_KEY),
        questionnaires: [STypeGrp.ACTIONS],
        correct: '0',
        parentName: 'knowledgeQuestionnaire',
    },
    _a[pathUtils.makeAnswerKey(STOCK_DEFAULT_KEY)] = {
        id: pathUtils.makeAnswerKey(STOCK_DEFAULT_KEY),
        questionnaires: [STypeGrp.ACTIONS],
        correct: '2',
        parentName: 'knowledgeQuestionnaire',
    },
    _a[pathUtils.makeAnswerKey(STOCK_SALE_KEY)] = {
        id: pathUtils.makeAnswerKey(STOCK_SALE_KEY),
        questionnaires: [STypeGrp.ACTIONS],
        correct: '0',
        parentName: 'knowledgeQuestionnaire',
    },
    _a[pathUtils.makeAnswerKey(STOCK_CURRENCY_RISK_KEY)] = {
        id: pathUtils.makeAnswerKey(STOCK_CURRENCY_RISK_KEY),
        questionnaires: [STypeGrp.ACTIONS],
        correct: '0',
        parentName: 'knowledgeQuestionnaire',
    },
    _a[pathUtils.makeAnswerKey(FUND_DEFINITION_KEY)] = {
        id: pathUtils.makeAnswerKey(FUND_DEFINITION_KEY),
        questionnaires: [STypeGrp.FONDS],
        correct: '0',
        parentName: 'knowledgeQuestionnaire',
    },
    _a[pathUtils.makeAnswerKey(FUND_VARIATION_KEY)] = {
        id: pathUtils.makeAnswerKey(FUND_VARIATION_KEY),
        questionnaires: [STypeGrp.FONDS],
        correct: '0',
        parentName: 'knowledgeQuestionnaire',
    },
    _a[pathUtils.makeAnswerKey(FUND_SALE_KEY)] = {
        id: pathUtils.makeAnswerKey(FUND_SALE_KEY),
        questionnaires: [STypeGrp.FONDS],
        correct: '0',
        parentName: 'knowledgeQuestionnaire',
    },
    _a[pathUtils.makeAnswerKey(FUND_DEFAULT_KEY)] = {
        id: pathUtils.makeAnswerKey(FUND_DEFAULT_KEY),
        questionnaires: [STypeGrp.FONDS],
        correct: '2',
        parentName: 'knowledgeQuestionnaire',
    },
    _a[pathUtils.makeAnswerKey(FUND_CURRENCY_RISK_KEY)] = {
        id: pathUtils.makeAnswerKey(FUND_CURRENCY_RISK_KEY),
        questionnaires: [STypeGrp.FONDS],
        correct: '0',
        parentName: 'knowledgeQuestionnaire',
    },
    _a[pathUtils.makeAnswerKey(FUND_NET_ASSET_KEY)] = {
        id: pathUtils.makeAnswerKey(FUND_NET_ASSET_KEY),
        questionnaires: [STypeGrp.FONDS],
        correct: '0',
        parentName: 'knowledgeQuestionnaire',
    },
    _a[pathUtils.makeAnswerKey(PERPETUAL_BOND_DEFINITION_KEY)] = {
        id: pathUtils.makeAnswerKey(PERPETUAL_BOND_DEFINITION_KEY),
        questionnaires: [STypeGrp.OBLIGATIONS_PERP],
        correct: '2',
        parentName: 'knowledgeQuestionnaire',
    },
    _a[pathUtils.makeAnswerKey(PERPETUAL_BOND_VARIATION_KEY)] = {
        id: pathUtils.makeAnswerKey(PERPETUAL_BOND_VARIATION_KEY),
        questionnaires: [STypeGrp.OBLIGATIONS_PERP],
        correct: '0',
        parentName: 'knowledgeQuestionnaire',
    },
    _a[pathUtils.makeAnswerKey(PERPETUAL_BOND_CALL_KEY)] = {
        id: pathUtils.makeAnswerKey(PERPETUAL_BOND_CALL_KEY),
        questionnaires: [STypeGrp.OBLIGATIONS_PERP],
        correct: '0',
        parentName: 'knowledgeQuestionnaire',
    },
    _a[pathUtils.makeAnswerKey(SUBORDINATED_BOND_DEFINITION_KEY)] = {
        id: pathUtils.makeAnswerKey(SUBORDINATED_BOND_DEFINITION_KEY),
        questionnaires: [STypeGrp.OBLIGATIONS_SUB],
        correct: '0',
        parentName: 'knowledgeQuestionnaire',
    },
    _a[pathUtils.makeAnswerKey(SUBORDINATED_BOND_VARIATION_KEY)] = {
        id: pathUtils.makeAnswerKey(SUBORDINATED_BOND_VARIATION_KEY),
        questionnaires: [STypeGrp.OBLIGATIONS_SUB],
        correct: '0',
        parentName: 'knowledgeQuestionnaire',
    },
    _a[pathUtils.makeAnswerKey(TRACKER_AND_ETF_DEFINITION_KEY)] = {
        id: pathUtils.makeAnswerKey(TRACKER_AND_ETF_DEFINITION_KEY),
        questionnaires: [STypeGrp.TRACKERS],
        correct: '0',
        parentName: 'knowledgeQuestionnaire',
    },
    _a[pathUtils.makeAnswerKey(TRACKER_AND_ETF_SYNTHETIC_KEY)] = {
        id: pathUtils.makeAnswerKey(TRACKER_AND_ETF_SYNTHETIC_KEY),
        questionnaires: [STypeGrp.TRACKERS],
        correct: '1',
        parentName: 'knowledgeQuestionnaire',
    },
    _a[pathUtils.makeAnswerKey(TRACKER_AND_ETF_SYNTHETIC_RISK_KEY)] = {
        id: pathUtils.makeAnswerKey(TRACKER_AND_ETF_SYNTHETIC_RISK_KEY),
        questionnaires: [STypeGrp.TRACKERS],
        correct: '2',
        parentName: 'knowledgeQuestionnaire',
    },
    _a[pathUtils.makeAnswerKey(TRACKER_AND_ETF_SALE_KEY)] = {
        id: pathUtils.makeAnswerKey(TRACKER_AND_ETF_SALE_KEY),
        questionnaires: [STypeGrp.TRACKERS],
        correct: '0',
        parentName: 'knowledgeQuestionnaire',
    },
    _a[pathUtils.makeAnswerKey(TRACKER_AND_ETF_VARIATION_KEY)] = {
        id: pathUtils.makeAnswerKey(TRACKER_AND_ETF_VARIATION_KEY),
        questionnaires: [STypeGrp.TRACKERS],
        correct: '0',
        parentName: 'knowledgeQuestionnaire',
    },
    _a[pathUtils.makeAnswerKey(TRACKER_AND_ETF_CURRENCY_RISK_KEY)] = {
        id: pathUtils.makeAnswerKey(TRACKER_AND_ETF_CURRENCY_RISK_KEY),
        questionnaires: [STypeGrp.TRACKERS],
        correct: '0',
        parentName: 'knowledgeQuestionnaire',
    },
    _a[pathUtils.makeAnswerKey(RAW_MATERIAL_TRACKER_RISK_KEY)] = {
        id: pathUtils.makeAnswerKey(RAW_MATERIAL_TRACKER_RISK_KEY),
        questionnaires: [STypeGrp.TRACKERS_MAT],
        correct: '1',
        parentName: 'knowledgeQuestionnaire',
    },
    _a[pathUtils.makeAnswerKey(RAW_MATERIAL_TRACKER_RISK_CAUSE_KEY)] = {
        id: pathUtils.makeAnswerKey(RAW_MATERIAL_TRACKER_RISK_CAUSE_KEY),
        questionnaires: [STypeGrp.TRACKERS_MAT],
        correct: '0',
        parentName: 'knowledgeQuestionnaire',
    },
    _a);
export var questionnairePotentialAnswers = {
    '0': {
        answerIndex: 3,
        questions: [
            pathUtils.makeAnswerKey(BOND_DEFINITION_KEY),
            pathUtils.makeAnswerKey(BOND_VARIATION_KEY),
            pathUtils.makeAnswerKey(BOND_SALE_KEY),
            pathUtils.makeAnswerKey(BOND_DEFAULT_KEY),
            pathUtils.makeAnswerKey(BOND_CURRENCY_RISK_KEY),
            pathUtils.makeAnswerKey(STOCK_DEFINITION_KEY),
            pathUtils.makeAnswerKey(STOCK_VARIATION_KEY),
            pathUtils.makeAnswerKey(STOCK_SALE_KEY),
            pathUtils.makeAnswerKey(STOCK_DEFAULT_KEY),
            pathUtils.makeAnswerKey(STOCK_CURRENCY_RISK_KEY),
            pathUtils.makeAnswerKey(FUND_DEFINITION_KEY),
            pathUtils.makeAnswerKey(FUND_VARIATION_KEY),
            pathUtils.makeAnswerKey(FUND_SALE_KEY),
            pathUtils.makeAnswerKey(FUND_DEFAULT_KEY),
            pathUtils.makeAnswerKey(FUND_CURRENCY_RISK_KEY),
            pathUtils.makeAnswerKey(FUND_NET_ASSET_KEY),
            pathUtils.makeAnswerKey(PERPETUAL_BOND_DEFINITION_KEY),
            pathUtils.makeAnswerKey(PERPETUAL_BOND_VARIATION_KEY),
            pathUtils.makeAnswerKey(PERPETUAL_BOND_CALL_KEY),
            pathUtils.makeAnswerKey(SUBORDINATED_BOND_DEFINITION_KEY),
            pathUtils.makeAnswerKey(SUBORDINATED_BOND_VARIATION_KEY),
            pathUtils.makeAnswerKey(TRACKER_AND_ETF_DEFINITION_KEY),
            pathUtils.makeAnswerKey(TRACKER_AND_ETF_SYNTHETIC_KEY),
            pathUtils.makeAnswerKey(TRACKER_AND_ETF_SYNTHETIC_RISK_KEY),
            pathUtils.makeAnswerKey(TRACKER_AND_ETF_SALE_KEY),
            pathUtils.makeAnswerKey(TRACKER_AND_ETF_VARIATION_KEY),
            pathUtils.makeAnswerKey(TRACKER_AND_ETF_CURRENCY_RISK_KEY),
            pathUtils.makeAnswerKey(RAW_MATERIAL_TRACKER_RISK_KEY),
            'RTOBondQuestion0Answer',
            'RTOBondQuestion1Answer',
            'RTOBondQuestion2Answer',
            'RTOBondQuestion3Answer',
            'RTOBondQuestion4Answer',
            'RTOBondQuestion5Answer',
            'RTOBondQuestion6Answer',
            'RTOBondQuestion7Answer',
            'RTOBondQuestion8Answer',
            'RTOBondQuestion9Answer',
            'RTOBondQuestion10Answer',
            'RTOBondQuestion11Answer',
            'RTOBondQuestion0RemedialAnswer',
            'RTOBondQuestion1RemedialAnswer',
            'RTOBondQuestion2RemedialAnswer',
            'RTOBondQuestion3RemedialAnswer',
            'RTOBondQuestion4RemedialAnswer',
            'RTOBondQuestion5RemedialAnswer',
            'RTOBondQuestion6RemedialAnswer',
            'RTOBondQuestion7RemedialAnswer',
            'RTOBondQuestion8RemedialAnswer',
            'RTOBondQuestion9RemedialAnswer',
            'RTOBondQuestion10RemedialAnswer',
            'RTOBondQuestion11RemedialAnswer',
            'RTOBondQuestion11RemedialAnswer',
            'RTOBondQuestion11RemedialAnswer',
            'RTOPerpetualBondQuestion0Answer',
            'RTOPerpetualBondQuestion0RemedialAnswer',
            'RTOPerpetualBondQuestion1Answer',
            'RTOPerpetualBondQuestion1RemedialAnswer',
            'RTOPerpetualBondQuestion2Answer',
            'RTOPerpetualBondQuestion2RemedialAnswer',
            'RTOSubordinatedBondQuestion0Answer',
            'RTOSubordinatedBondQuestion0RemedialAnswer',
            'RTOSubordinatedBondQuestion1Answer',
            'RTOSubordinatedBondQuestion1RemedialAnswer',
            'RTORawMaterialTrackerQuestion0Answer',
            'RTORawMaterialTrackerQuestion0RemedialAnswer',
            'RTORawMaterialTrackerQuestion1Answer',
            'RTORawMaterialTrackerQuestion1RemedialAnswer',
            'RTORawMaterialTrackerQuestion2Answer',
            'RTORawMaterialTrackerQuestion2RemedialAnswer',
            'RTORawMaterialTrackerQuestion3Answer',
            'RTORawMaterialTrackerQuestion3RemedialAnswer',
            'RTORawMaterialTrackerQuestion4Answer',
            'RTORawMaterialTrackerQuestion4RemedialAnswer',
            'RTORawMaterialTrackerQuestion5Answer',
            'RTORawMaterialTrackerQuestion5RemedialAnswer',
        ],
    },
    '1': {
        answerIndex: 0,
        questions: [pathUtils.makeAnswerKey(BOND_DEFINITION_KEY)],
    },
    '2': {
        answerIndex: 1,
        questions: [pathUtils.makeAnswerKey(BOND_DEFINITION_KEY)],
    },
    '3': {
        answerIndex: 2,
        questions: [pathUtils.makeAnswerKey(BOND_DEFINITION_KEY)],
    },
    '4': {
        answerIndex: 0,
        questions: [pathUtils.makeAnswerKey(BOND_VARIATION_KEY)],
    },
    '5': {
        answerIndex: 1,
        questions: [pathUtils.makeAnswerKey(BOND_VARIATION_KEY)],
    },
    '6': {
        answerIndex: 2,
        questions: [pathUtils.makeAnswerKey(BOND_VARIATION_KEY)],
    },
    '8': {
        answerIndex: 0,
        questions: [pathUtils.makeAnswerKey(BOND_SALE_KEY)],
    },
    '9': {
        answerIndex: 1,
        questions: [pathUtils.makeAnswerKey(BOND_SALE_KEY)],
    },
    '10': {
        answerIndex: 2,
        questions: [pathUtils.makeAnswerKey(BOND_SALE_KEY)],
    },
    '12': {
        answerIndex: 0,
        questions: [pathUtils.makeAnswerKey(BOND_DEFAULT_KEY)],
    },
    '13': {
        answerIndex: 1,
        questions: [pathUtils.makeAnswerKey(BOND_DEFAULT_KEY)],
    },
    '14': {
        answerIndex: 2,
        questions: [pathUtils.makeAnswerKey(BOND_DEFAULT_KEY)],
    },
    '15': {
        answerIndex: 0,
        questions: [pathUtils.makeAnswerKey(BOND_CURRENCY_RISK_KEY)],
    },
    '16': {
        answerIndex: 1,
        questions: [pathUtils.makeAnswerKey(BOND_CURRENCY_RISK_KEY)],
    },
    '17': {
        answerIndex: 2,
        questions: [pathUtils.makeAnswerKey(BOND_CURRENCY_RISK_KEY)],
    },
    '18': {
        answerIndex: 0,
        questions: [pathUtils.makeAnswerKey(STOCK_DEFINITION_KEY)],
    },
    '19': {
        answerIndex: 1,
        questions: [pathUtils.makeAnswerKey(STOCK_DEFINITION_KEY)],
    },
    '20': {
        answerIndex: 2,
        questions: [pathUtils.makeAnswerKey(STOCK_DEFINITION_KEY)],
    },
    '21': {
        answerIndex: 0,
        questions: [pathUtils.makeAnswerKey(STOCK_VARIATION_KEY)],
    },
    '22': {
        answerIndex: 1,
        questions: [pathUtils.makeAnswerKey(STOCK_VARIATION_KEY)],
    },
    '23': {
        answerIndex: 2,
        questions: [pathUtils.makeAnswerKey(STOCK_VARIATION_KEY)],
    },
    '24': {
        answerIndex: 0,
        questions: [pathUtils.makeAnswerKey(STOCK_SALE_KEY)],
    },
    '25': {
        answerIndex: 1,
        questions: [pathUtils.makeAnswerKey(STOCK_SALE_KEY)],
    },
    '26': {
        answerIndex: 2,
        questions: [pathUtils.makeAnswerKey(STOCK_SALE_KEY)],
    },
    '27': {
        answerIndex: 0,
        questions: [pathUtils.makeAnswerKey(STOCK_DEFAULT_KEY)],
    },
    '28': {
        answerIndex: 1,
        questions: [pathUtils.makeAnswerKey(STOCK_DEFAULT_KEY)],
    },
    '29': {
        answerIndex: 2,
        questions: [pathUtils.makeAnswerKey(STOCK_DEFAULT_KEY)],
    },
    '30': {
        answerIndex: 0,
        questions: [pathUtils.makeAnswerKey(STOCK_CURRENCY_RISK_KEY)],
    },
    '31': {
        answerIndex: 1,
        questions: [pathUtils.makeAnswerKey(STOCK_CURRENCY_RISK_KEY)],
    },
    '32': {
        answerIndex: 2,
        questions: [pathUtils.makeAnswerKey(STOCK_CURRENCY_RISK_KEY)],
    },
    '33': {
        answerIndex: 0,
        questions: [pathUtils.makeAnswerKey(FUND_DEFINITION_KEY)],
    },
    '34': {
        answerIndex: 1,
        questions: [pathUtils.makeAnswerKey(FUND_DEFINITION_KEY)],
    },
    '35': {
        answerIndex: 2,
        questions: [pathUtils.makeAnswerKey(FUND_DEFINITION_KEY)],
    },
    '36': {
        answerIndex: 0,
        questions: [pathUtils.makeAnswerKey(FUND_VARIATION_KEY)],
    },
    '37': {
        answerIndex: 1,
        questions: [pathUtils.makeAnswerKey(FUND_VARIATION_KEY)],
    },
    '38': {
        answerIndex: 2,
        questions: [pathUtils.makeAnswerKey(FUND_VARIATION_KEY)],
    },
    '39': {
        answerIndex: 0,
        questions: [pathUtils.makeAnswerKey(FUND_SALE_KEY)],
    },
    '40': {
        answerIndex: 1,
        questions: [pathUtils.makeAnswerKey(FUND_SALE_KEY)],
    },
    '41': {
        answerIndex: 2,
        questions: [pathUtils.makeAnswerKey(FUND_SALE_KEY)],
    },
    '42': {
        answerIndex: 0,
        questions: [pathUtils.makeAnswerKey(FUND_DEFAULT_KEY)],
    },
    '43': {
        answerIndex: 1,
        questions: [pathUtils.makeAnswerKey(FUND_DEFAULT_KEY)],
    },
    '44': {
        answerIndex: 2,
        questions: [pathUtils.makeAnswerKey(FUND_DEFAULT_KEY)],
    },
    '45': {
        answerIndex: 0,
        questions: [pathUtils.makeAnswerKey(FUND_CURRENCY_RISK_KEY)],
    },
    '46': {
        answerIndex: 1,
        questions: [pathUtils.makeAnswerKey(FUND_CURRENCY_RISK_KEY)],
    },
    '47': {
        answerIndex: 2,
        questions: [pathUtils.makeAnswerKey(FUND_CURRENCY_RISK_KEY)],
    },
    '48': {
        answerIndex: 0,
        questions: [pathUtils.makeAnswerKey(FUND_NET_ASSET_KEY)],
    },
    '49': {
        answerIndex: 1,
        questions: [pathUtils.makeAnswerKey(FUND_NET_ASSET_KEY)],
    },
    '50': {
        answerIndex: 2,
        questions: [pathUtils.makeAnswerKey(FUND_NET_ASSET_KEY)],
    },
    '51': {
        answerIndex: 0,
        questions: [pathUtils.makeAnswerKey(PERPETUAL_BOND_DEFINITION_KEY)],
    },
    '52': {
        answerIndex: 1,
        questions: [pathUtils.makeAnswerKey(PERPETUAL_BOND_DEFINITION_KEY)],
    },
    '53': {
        answerIndex: 2,
        questions: [pathUtils.makeAnswerKey(PERPETUAL_BOND_DEFINITION_KEY)],
    },
    '54': {
        answerIndex: 0,
        questions: [pathUtils.makeAnswerKey(PERPETUAL_BOND_VARIATION_KEY)],
    },
    '55': {
        answerIndex: 1,
        questions: [pathUtils.makeAnswerKey(PERPETUAL_BOND_VARIATION_KEY)],
    },
    '56': {
        answerIndex: 2,
        questions: [pathUtils.makeAnswerKey(PERPETUAL_BOND_VARIATION_KEY)],
    },
    '57': {
        answerIndex: 0,
        questions: [pathUtils.makeAnswerKey(PERPETUAL_BOND_CALL_KEY)],
    },
    '58': {
        answerIndex: 1,
        questions: [pathUtils.makeAnswerKey(PERPETUAL_BOND_CALL_KEY)],
    },
    '59': {
        answerIndex: 2,
        questions: [pathUtils.makeAnswerKey(PERPETUAL_BOND_CALL_KEY)],
    },
    '60': {
        answerIndex: 0,
        questions: [pathUtils.makeAnswerKey(SUBORDINATED_BOND_DEFINITION_KEY)],
    },
    '61': {
        answerIndex: 1,
        questions: [pathUtils.makeAnswerKey(SUBORDINATED_BOND_DEFINITION_KEY)],
    },
    '62': {
        answerIndex: 2,
        questions: [pathUtils.makeAnswerKey(SUBORDINATED_BOND_DEFINITION_KEY)],
    },
    '63': {
        answerIndex: 0,
        questions: [pathUtils.makeAnswerKey(SUBORDINATED_BOND_VARIATION_KEY)],
    },
    '64': {
        answerIndex: 1,
        questions: [pathUtils.makeAnswerKey(SUBORDINATED_BOND_VARIATION_KEY)],
    },
    '65': {
        answerIndex: 2,
        questions: [pathUtils.makeAnswerKey(SUBORDINATED_BOND_VARIATION_KEY)],
    },
    '66': {
        answerIndex: 0,
        questions: [pathUtils.makeAnswerKey(TRACKER_AND_ETF_DEFINITION_KEY)],
    },
    '67': {
        answerIndex: 1,
        questions: [pathUtils.makeAnswerKey(TRACKER_AND_ETF_DEFINITION_KEY)],
    },
    '68': {
        answerIndex: 2,
        questions: [pathUtils.makeAnswerKey(TRACKER_AND_ETF_DEFINITION_KEY)],
    },
    '69': {
        answerIndex: 0,
        questions: [pathUtils.makeAnswerKey(TRACKER_AND_ETF_SYNTHETIC_KEY)],
    },
    '70': {
        answerIndex: 1,
        questions: [pathUtils.makeAnswerKey(TRACKER_AND_ETF_SYNTHETIC_KEY)],
    },
    '71': {
        answerIndex: 2,
        questions: [pathUtils.makeAnswerKey(TRACKER_AND_ETF_SYNTHETIC_KEY)],
    },
    '72': {
        answerIndex: 0,
        questions: [pathUtils.makeAnswerKey(TRACKER_AND_ETF_SYNTHETIC_RISK_KEY)],
    },
    '73': {
        answerIndex: 1,
        questions: [pathUtils.makeAnswerKey(TRACKER_AND_ETF_SYNTHETIC_RISK_KEY)],
    },
    '74': {
        answerIndex: 2,
        questions: [pathUtils.makeAnswerKey(TRACKER_AND_ETF_SYNTHETIC_RISK_KEY)],
    },
    '75': {
        answerIndex: 0,
        questions: [pathUtils.makeAnswerKey(TRACKER_AND_ETF_SALE_KEY)],
    },
    '76': {
        answerIndex: 1,
        questions: [pathUtils.makeAnswerKey(TRACKER_AND_ETF_SALE_KEY)],
    },
    '77': {
        answerIndex: 2,
        questions: [pathUtils.makeAnswerKey(TRACKER_AND_ETF_SALE_KEY)],
    },
    '78': {
        answerIndex: 0,
        questions: [pathUtils.makeAnswerKey(TRACKER_AND_ETF_VARIATION_KEY)],
    },
    '79': {
        answerIndex: 1,
        questions: [pathUtils.makeAnswerKey(TRACKER_AND_ETF_VARIATION_KEY)],
    },
    '80': {
        answerIndex: 2,
        questions: [pathUtils.makeAnswerKey(TRACKER_AND_ETF_VARIATION_KEY)],
    },
    '81': {
        answerIndex: 0,
        questions: [pathUtils.makeAnswerKey(TRACKER_AND_ETF_CURRENCY_RISK_KEY)],
    },
    '82': {
        answerIndex: 1,
        questions: [pathUtils.makeAnswerKey(TRACKER_AND_ETF_CURRENCY_RISK_KEY)],
    },
    '83': {
        answerIndex: 2,
        questions: [pathUtils.makeAnswerKey(TRACKER_AND_ETF_CURRENCY_RISK_KEY)],
    },
    '84': {
        answerIndex: 0,
        questions: [pathUtils.makeAnswerKey(RAW_MATERIAL_TRACKER_RISK_KEY)],
    },
    '85': {
        answerIndex: 1,
        questions: [pathUtils.makeAnswerKey(RAW_MATERIAL_TRACKER_RISK_KEY)],
    },
    '86': {
        answerIndex: 2,
        questions: [pathUtils.makeAnswerKey(RAW_MATERIAL_TRACKER_RISK_KEY)],
    },
    '87': {
        answerIndex: 0,
        questions: [pathUtils.makeAnswerKey(RAW_MATERIAL_TRACKER_RISK_CAUSE_KEY)],
    },
    '88': {
        answerIndex: 1,
        questions: [pathUtils.makeAnswerKey(RAW_MATERIAL_TRACKER_RISK_CAUSE_KEY)],
    },
    '89': {
        answerIndex: 2,
        questions: [pathUtils.makeAnswerKey(RAW_MATERIAL_TRACKER_RISK_CAUSE_KEY)],
    },
    '90': {
        answerIndex: 0,
        questions: ['RTOBondQuestion0Answer'],
    },
    '91': {
        answerIndex: 1,
        questions: ['RTOBondQuestion0Answer'],
    },
    '92': {
        answerIndex: 2,
        questions: ['RTOBondQuestion0Answer'],
    },
    '93': {
        answerIndex: 0,
        questions: ['RTOBondQuestion1Answer'],
    },
    '94': {
        answerIndex: 1,
        questions: ['RTOBondQuestion1Answer'],
    },
    '95': {
        answerIndex: 2,
        questions: ['RTOBondQuestion1Answer'],
    },
    '96': {
        answerIndex: 0,
        questions: ['RTOBondQuestion2Answer'],
    },
    '97': {
        answerIndex: 1,
        questions: ['RTOBondQuestion2Answer'],
    },
    '98': {
        answerIndex: 2,
        questions: ['RTOBondQuestion2Answer'],
    },
    '99': {
        answerIndex: 0,
        questions: ['RTOBondQuestion3Answer'],
    },
    '100': {
        answerIndex: 1,
        questions: ['RTOBondQuestion3Answer'],
    },
    '101': {
        answerIndex: 2,
        questions: ['RTOBondQuestion3Answer'],
    },
    '102': {
        answerIndex: 0,
        questions: ['RTOBondQuestion4Answer'],
    },
    '103': {
        answerIndex: 1,
        questions: ['RTOBondQuestion4Answer'],
    },
    '104': {
        answerIndex: 2,
        questions: ['RTOBondQuestion4Answer'],
    },
    '105': {
        answerIndex: 0,
        questions: ['RTOBondQuestion5Answer'],
    },
    '106': {
        answerIndex: 1,
        questions: ['RTOBondQuestion5Answer'],
    },
    '107': {
        answerIndex: 2,
        questions: ['RTOBondQuestion5Answer'],
    },
    '108': {
        answerIndex: 0,
        questions: ['RTOBondQuestion6Answer'],
    },
    '109': {
        answerIndex: 1,
        questions: ['RTOBondQuestion6Answer'],
    },
    '110': {
        answerIndex: 2,
        questions: ['RTOBondQuestion6Answer'],
    },
    '111': {
        answerIndex: 0,
        questions: ['RTOBondQuestion7Answer'],
    },
    '112': {
        answerIndex: 1,
        questions: ['RTOBondQuestion7Answer'],
    },
    '113': {
        answerIndex: 2,
        questions: ['RTOBondQuestion7Answer'],
    },
    '114': {
        answerIndex: 0,
        questions: ['RTOBondQuestion8Answer'],
    },
    '115': {
        answerIndex: 1,
        questions: ['RTOBondQuestion8Answer'],
    },
    '116': {
        answerIndex: 2,
        questions: ['RTOBondQuestion8Answer'],
    },
    '117': {
        answerIndex: 0,
        questions: ['RTOBondQuestion9Answer'],
    },
    '118': {
        answerIndex: 1,
        questions: ['RTOBondQuestion9Answer'],
    },
    '119': {
        answerIndex: 2,
        questions: ['RTOBondQuestion9Answer'],
    },
    '120': {
        answerIndex: 0,
        questions: ['RTOBondQuestion10Answer'],
    },
    '121': {
        answerIndex: 1,
        questions: ['RTOBondQuestion10Answer'],
    },
    '122': {
        answerIndex: 2,
        questions: ['RTOBondQuestion10Answer'],
    },
    '123': {
        answerIndex: 0,
        questions: ['RTOBondQuestion11Answer'],
    },
    '124': {
        answerIndex: 1,
        questions: ['RTOBondQuestion11Answer'],
    },
    '125': {
        answerIndex: 2,
        questions: ['RTOBondQuestion11Answer'],
    },
    '126': {
        answerIndex: 0,
        questions: ['RTOBondQuestion0RemedialAnswer'],
    },
    '127': {
        answerIndex: 1,
        questions: ['RTOBondQuestion0RemedialAnswer'],
    },
    '128': {
        answerIndex: 2,
        questions: ['RTOBondQuestion0RemedialAnswer'],
    },
    '129': {
        answerIndex: 0,
        questions: ['RTOBondQuestion1RemedialAnswer'],
    },
    '130': {
        answerIndex: 1,
        questions: ['RTOBondQuestion1RemedialAnswer'],
    },
    '131': {
        answerIndex: 2,
        questions: ['RTOBondQuestion1RemedialAnswer'],
    },
    '132': {
        answerIndex: 0,
        questions: ['RTOBondQuestion2RemedialAnswer'],
    },
    '133': {
        answerIndex: 1,
        questions: ['RTOBondQuestion2RemedialAnswer'],
    },
    '134': {
        answerIndex: 2,
        questions: ['RTOBondQuestion2RemedialAnswer'],
    },
    '135': {
        answerIndex: 0,
        questions: ['RTOBondQuestion3RemedialAnswer'],
    },
    '136': {
        answerIndex: 1,
        questions: ['RTOBondQuestion3RemedialAnswer'],
    },
    '137': {
        answerIndex: 2,
        questions: ['RTOBondQuestion3RemedialAnswer'],
    },
    '138': {
        answerIndex: 0,
        questions: ['RTOBondQuestion4RemedialAnswer'],
    },
    '139': {
        answerIndex: 1,
        questions: ['RTOBondQuestion4RemedialAnswer'],
    },
    '140': {
        answerIndex: 2,
        questions: ['RTOBondQuestion4RemedialAnswer'],
    },
    '141': {
        answerIndex: 0,
        questions: ['RTOBondQuestion5RemedialAnswer'],
    },
    '142': {
        answerIndex: 1,
        questions: ['RTOBondQuestion5RemedialAnswer'],
    },
    '143': {
        answerIndex: 2,
        questions: ['RTOBondQuestion5RemedialAnswer'],
    },
    '144': {
        answerIndex: 0,
        questions: ['RTOBondQuestion6RemedialAnswer'],
    },
    '145': {
        answerIndex: 1,
        questions: ['RTOBondQuestion6RemedialAnswer'],
    },
    '146': {
        answerIndex: 2,
        questions: ['RTOBondQuestion6RemedialAnswer'],
    },
    '147': {
        answerIndex: 0,
        questions: ['RTOBondQuestion7RemedialAnswer'],
    },
    '148': {
        answerIndex: 1,
        questions: ['RTOBondQuestion7RemedialAnswer'],
    },
    '149': {
        answerIndex: 2,
        questions: ['RTOBondQuestion7RemedialAnswer'],
    },
    '150': {
        answerIndex: 0,
        questions: ['RTOBondQuestion8RemedialAnswer'],
    },
    '151': {
        answerIndex: 1,
        questions: ['RTOBondQuestion8RemedialAnswer'],
    },
    '152': {
        answerIndex: 2,
        questions: ['RTOBondQuestion8RemedialAnswer'],
    },
    '153': {
        answerIndex: 0,
        questions: ['RTOBondQuestion9RemedialAnswer'],
    },
    '154': {
        answerIndex: 1,
        questions: ['RTOBondQuestion9RemedialAnswer'],
    },
    '155': {
        answerIndex: 2,
        questions: ['RTOBondQuestion9RemedialAnswer'],
    },
    '156': {
        answerIndex: 0,
        questions: ['RTOBondQuestion10RemedialAnswer'],
    },
    '157': {
        answerIndex: 1,
        questions: ['RTOBondQuestion10RemedialAnswer'],
    },
    '158': {
        answerIndex: 2,
        questions: ['RTOBondQuestion10RemedialAnswer'],
    },
    '159': {
        answerIndex: 0,
        questions: ['RTOBondQuestion11RemedialAnswer'],
    },
    '160': {
        answerIndex: 1,
        questions: ['RTOBondQuestion11RemedialAnswer'],
    },
    '161': {
        answerIndex: 2,
        questions: ['RTOBondQuestion11RemedialAnswer'],
    },
    '162': {
        answerIndex: 0,
        questions: ['RTOPerpetualBondQuestion0Answer'],
    },
    '163': {
        answerIndex: 1,
        questions: ['RTOPerpetualBondQuestion0Answer'],
    },
    '164': {
        answerIndex: 2,
        questions: ['RTOPerpetualBondQuestion0Answer'],
    },
    '165': {
        answerIndex: 0,
        questions: ['RTOPerpetualBondQuestion0RemedialAnswer'],
    },
    '166': {
        answerIndex: 1,
        questions: ['RTOPerpetualBondQuestion0RemedialAnswer'],
    },
    '167': {
        answerIndex: 2,
        questions: ['RTOPerpetualBondQuestion0RemedialAnswer'],
    },
    '168': {
        answerIndex: 0,
        questions: ['RTOPerpetualBondQuestion1Answer'],
    },
    '169': {
        answerIndex: 1,
        questions: ['RTOPerpetualBondQuestion1Answer'],
    },
    '170': {
        answerIndex: 2,
        questions: ['RTOPerpetualBondQuestion1Answer'],
    },
    '171': {
        answerIndex: 0,
        questions: ['RTOPerpetualBondQuestion1RemedialAnswer'],
    },
    '172': {
        answerIndex: 1,
        questions: ['RTOPerpetualBondQuestion1RemedialAnswer'],
    },
    '173': {
        answerIndex: 2,
        questions: ['RTOPerpetualBondQuestion1RemedialAnswer'],
    },
    '174': {
        answerIndex: 0,
        questions: ['RTOPerpetualBondQuestion2Answer'],
    },
    '175': {
        answerIndex: 1,
        questions: ['RTOPerpetualBondQuestion2Answer'],
    },
    '176': {
        answerIndex: 2,
        questions: ['RTOPerpetualBondQuestion2Answer'],
    },
    '177': {
        answerIndex: 0,
        questions: ['RTOPerpetualBondQuestion2RemedialAnswer'],
    },
    '178': {
        answerIndex: 1,
        questions: ['RTOPerpetualBondQuestion2RemedialAnswer'],
    },
    '179': {
        answerIndex: 2,
        questions: ['RTOPerpetualBondQuestion2RemedialAnswer'],
    },
    '180': {
        answerIndex: 0,
        questions: ['RTOSubordinatedBondQuestion0Answer'],
    },
    '181': {
        answerIndex: 1,
        questions: ['RTOSubordinatedBondQuestion0Answer'],
    },
    '182': {
        answerIndex: 2,
        questions: ['RTOSubordinatedBondQuestion0Answer'],
    },
    '183': {
        answerIndex: 0,
        questions: ['RTOSubordinatedBondQuestion0RemedialAnswer'],
    },
    '184': {
        answerIndex: 1,
        questions: ['RTOSubordinatedBondQuestion0RemedialAnswer'],
    },
    '185': {
        answerIndex: 2,
        questions: ['RTOSubordinatedBondQuestion0RemedialAnswer'],
    },
    '186': {
        answerIndex: 0,
        questions: ['RTOSubordinatedBondQuestion1Answer'],
    },
    '187': {
        answerIndex: 1,
        questions: ['RTOSubordinatedBondQuestion1Answer'],
    },
    '188': {
        answerIndex: 2,
        questions: ['RTOSubordinatedBondQuestion1Answer'],
    },
    '189': {
        answerIndex: 0,
        questions: ['RTOSubordinatedBondQuestion1RemedialAnswer'],
    },
    '190': {
        answerIndex: 1,
        questions: ['RTOSubordinatedBondQuestion1RemedialAnswer'],
    },
    '191': {
        answerIndex: 2,
        questions: ['RTOSubordinatedBondQuestion1RemedialAnswer'],
    },
    '192': {
        answerIndex: 0,
        questions: ['RTORawMaterialTrackerQuestion0Answer'],
    },
    '193': {
        answerIndex: 1,
        questions: ['RTORawMaterialTrackerQuestion0Answer'],
    },
    '194': {
        answerIndex: 2,
        questions: ['RTORawMaterialTrackerQuestion0Answer'],
    },
    '195': {
        answerIndex: 0,
        questions: ['RTORawMaterialTrackerQuestion0RemedialAnswer'],
    },
    '196': {
        answerIndex: 1,
        questions: ['RTORawMaterialTrackerQuestion0RemedialAnswer'],
    },
    '197': {
        answerIndex: 2,
        questions: ['RTORawMaterialTrackerQuestion0RemedialAnswer'],
    },
    '198': {
        answerIndex: 0,
        questions: ['RTORawMaterialTrackerQuestion1Answer'],
    },
    '199': {
        answerIndex: 1,
        questions: ['RTORawMaterialTrackerQuestion1Answer'],
    },
    '200': {
        answerIndex: 2,
        questions: ['RTORawMaterialTrackerQuestion1Answer'],
    },
    '201': {
        answerIndex: 0,
        questions: ['RTORawMaterialTrackerQuestion1RemedialAnswer'],
    },
    '202': {
        answerIndex: 1,
        questions: ['RTORawMaterialTrackerQuestion1RemedialAnswer'],
    },
    '203': {
        answerIndex: 2,
        questions: ['RTORawMaterialTrackerQuestion1RemedialAnswer'],
    },
    '204': {
        answerIndex: 0,
        questions: ['RTORawMaterialTrackerQuestion2Answer'],
    },
    '205': {
        answerIndex: 1,
        questions: ['RTORawMaterialTrackerQuestion2Answer'],
    },
    '206': {
        answerIndex: 2,
        questions: ['RTORawMaterialTrackerQuestion2Answer'],
    },
    '207': {
        answerIndex: 0,
        questions: ['RTORawMaterialTrackerQuestion2RemedialAnswer'],
    },
    '208': {
        answerIndex: 1,
        questions: ['RTORawMaterialTrackerQuestion2RemedialAnswer'],
    },
    '209': {
        answerIndex: 2,
        questions: ['RTORawMaterialTrackerQuestion2RemedialAnswer'],
    },
    '210': {
        answerIndex: 0,
        questions: ['RTORawMaterialTrackerQuestion3Answer'],
    },
    '211': {
        answerIndex: 1,
        questions: ['RTORawMaterialTrackerQuestion3Answer'],
    },
    '212': {
        answerIndex: 2,
        questions: ['RTORawMaterialTrackerQuestion3Answer'],
    },
    '213': {
        answerIndex: 0,
        questions: ['RTORawMaterialTrackerQuestion3RemedialAnswer'],
    },
    '214': {
        answerIndex: 1,
        questions: ['RTORawMaterialTrackerQuestion3RemedialAnswer'],
    },
    '215': {
        answerIndex: 2,
        questions: ['RTORawMaterialTrackerQuestion3RemedialAnswer'],
    },
    '216': {
        answerIndex: 0,
        questions: ['RTORawMaterialTrackerQuestion4Answer'],
    },
    '217': {
        answerIndex: 1,
        questions: ['RTORawMaterialTrackerQuestion4Answer'],
    },
    '218': {
        answerIndex: 2,
        questions: ['RTORawMaterialTrackerQuestion4Answer'],
    },
    '219': {
        answerIndex: 0,
        questions: ['RTORawMaterialTrackerQuestion4RemedialAnswer'],
    },
    '220': {
        answerIndex: 1,
        questions: ['RTORawMaterialTrackerQuestion4RemedialAnswer'],
    },
    '221': {
        answerIndex: 2,
        questions: ['RTORawMaterialTrackerQuestion4RemedialAnswer'],
    },
    '222': {
        answerIndex: 0,
        questions: ['RTORawMaterialTrackerQuestion5Answer'],
    },
    '223': {
        answerIndex: 1,
        questions: ['RTORawMaterialTrackerQuestion5Answer'],
    },
    '224': {
        answerIndex: 2,
        questions: ['RTORawMaterialTrackerQuestion5Answer'],
    },
    '225': {
        answerIndex: 0,
        questions: ['RTORawMaterialTrackerQuestion5RemedialAnswer'],
    },
    '226': {
        answerIndex: 1,
        questions: ['RTORawMaterialTrackerQuestion5RemedialAnswer'],
    },
    '227': {
        answerIndex: 2,
        questions: ['RTORawMaterialTrackerQuestion5RemedialAnswer'],
    },
};
