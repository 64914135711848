import * as events from '../../../events';
import { makeSpringService } from '../utils';

export type AppBarClosingServiceMeta = {
  src: {
    type: 'ClosingService',
  },
};

export const name = 'closing';

export const makeService = makeSpringService(name, 'closed', 1, false, events.closingFinishedEvent.eventBuilder);

export default makeService;
