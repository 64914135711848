import React from 'react';
import { object, mixed } from 'yup';
import { UncastedDataStructure, PersonType, UncastedOnboarding } from '@goldwasserexchange/oblis-frontend-utils';
import {
  tAddYnPayPrecValidator,
} from '../../../../tAdd/inputs';
import { stringifyIs } from '../../../../utils/stringifyIs';
import { nilValidator } from '../../../../../OUI/Inputs';

const fiscalityShape: Record<keyof UncastedOnboarding['fiscality'], any> = {
  'YN_PAY_PREC ': tAddYnPayPrecValidator,
};

const noFiscalityShape: Record<keyof UncastedOnboarding['fiscality'], any> = {
  'YN_PAY_PREC ': nilValidator,
};

export const fiscalityValidator = mixed().when(['$'], (context) => {
  const root = context.rootValue as UncastedDataStructure;
  const {
    onboarding,
  } = root;
  const { personType, moralPerson, users } = onboarding;
  if (
    (
      personType !== stringifyIs(undefined)
      && personType !== stringifyIs(PersonType.MORAL)
      && (users || []).every(
        (user) => user.address.ID_C_COUNTRY !== stringifyIs(212),
      )
    )
    || (
      personType === stringifyIs(PersonType.MORAL)
      && moralPerson.address.ID_C_COUNTRY !== stringifyIs(212)
    )
  ) {
    return object().shape(fiscalityShape);
  }
  return object().shape(noFiscalityShape);
});

export const LazyFiscality = React.lazy(() => import('./component'));
