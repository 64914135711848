import { safeLocaleCompare } from '@goldwasserexchange/utils';
import { compareAsc } from 'date-fns';
import { useCallback } from 'react';
import { PositionSubGroup } from '../../type';
import { useSTypeGrpId } from '../../../../../../../Components/Sections/online/sections/details/positionDetails/sTypeGroupTable/context/sTypeGrpId';
import { lmlGetPositionsTitleCase } from '../../../../accounts';
import { parseActorDate } from '../../../../../shared/utils/parseDates';
import { usePositionsSubGroupsTransformApi, usePositionsSubGroupTransformApi } from '../api';
import { S_GTYPE_ID_CASH } from '../../utils';
import { useLinguiLocale } from '../../../../../../../Components/useLinguiLocale';
import { makeCurrencySorter } from '../../../../../../../Components/currencySorter';

const transformFunction = (positionSubGroups: PositionSubGroup[] | undefined) => (positionSubGroups ? positionSubGroups.map((positionSubGroup) => `${positionSubGroup['S_TYPE_GRP.ID']}`) : []);

const sortPredicate = (prev: PositionSubGroup, next: PositionSubGroup) => {
  if (prev['S_TYPE_GRP.ID'] === S_GTYPE_ID_CASH && next['S_TYPE_GRP.ID'] === S_GTYPE_ID_CASH) {
    return 0;
  }
  if (prev['S_TYPE_GRP.ID'] === S_GTYPE_ID_CASH) {
    return -1;
  }
  if (next['S_TYPE_GRP.ID'] === S_GTYPE_ID_CASH) {
    return 1;
  }
  return safeLocaleCompare(prev['S_TYPE_GRP.SHORTCUT'], next['S_TYPE_GRP.SHORTCUT']);
};

export const usePositionSubGroupList = (props: {
  filterPredicate?: ((data: PositionSubGroup) => boolean),
}) => {
  const {
    filterPredicate,
  } = props;
  const result = usePositionsSubGroupsTransformApi({
    sortPredicate,
    transformFunction,
    filterPredicate,
  });
  return result;
};

const makePositionsTransform = (language: string) => (subGroup: PositionSubGroup | null | undefined) => {
  const getPositionTitle = lmlGetPositionsTitleCase(language);
  const {
    positions = [],
  } = subGroup ?? {};
  return (positions ?? [])
    .sort((prev, next) => {
      const prevCurrency = prev['C_CURRENCY.CODE'];
      const nextCurrency = next['C_CURRENCY.CODE'];
      const prevEndDate = prev['GES_ESTIM_D.END_DATE'];
      const nextEndDate = next['GES_ESTIM_D.END_DATE'];
      const parsedPrevEndDate = prevEndDate ? parseActorDate(prevEndDate) : 0;
      const parsedNextEndDate = nextEndDate ? parseActorDate(nextEndDate) : 0;
      const prevLml2Description = prev['S_ACC.LML2_DESCRIPTION'];
      const nextLml2Description = next['S_ACC.LML2_DESCRIPTION'];
      const prevTitle = prevLml2Description ? getPositionTitle(prevLml2Description) : undefined;
      const nextTitle = nextLml2Description ? getPositionTitle(nextLml2Description) : undefined;
      return makeCurrencySorter('EUR')(prevCurrency, nextCurrency) || compareAsc(parsedPrevEndDate, parsedNextEndDate) || safeLocaleCompare(prevTitle, nextTitle);
    })
    .map((position) => `${position['GES_ESTIM_D.ID']}`);
};

const makeFilterPredicate = (sTypeGrpId: string | null) => (line: PositionSubGroup) => `${line['S_TYPE_GRP.ID']}` === `${sTypeGrpId}`;

export const usePositionsInSubGroupList = () => {
  const locale = useLinguiLocale();
  const sTypeGrpId = useSTypeGrpId();
  const positionBySTypeGrp = useCallback(makeFilterPredicate(sTypeGrpId), [sTypeGrpId]);
  const positionsTransform = useCallback(makePositionsTransform(locale), [locale]);
  const result = usePositionsSubGroupTransformApi({
    sortPredicate,
    transformFunction: positionsTransform,
    filterPredicate: positionBySTypeGrp,
  });
  return result;
};
