import { makeName } from '@goldwasserexchange/oblis-frontend-utils';
import {
  geColumns, geDataTables, geElementFieldPathHooks, geFormatters, geTAddColumnTypeRepresentations,
} from './store';
import {
  GeColumn, GeValidator, isGeTextColumn,
} from './types';

export const getColumnData = <InputProps>(tableName, column): GeColumn<InputProps> => {
  const key = makeName(tableName, column);
  const currentColumnData = geColumns[key];
  return currentColumnData;
};

export const getUseMakeFieldPath = (tableName, column) => {
  const { useMakeFieldPath } = getColumnData(tableName, column);
  const defaultUseMakeFieldPath = (parentName) => makeName(parentName, column);
  return useMakeFieldPath ?? defaultUseMakeFieldPath;
};

export const getTypeRepresentation = (tableName, column) => {
  const { type } = getColumnData(tableName, column);
  const representation = geTAddColumnTypeRepresentations[type];
  return representation;
};

export function getDataTableFromCurrentColumn<P>(currentColumnData: GeColumn<P>) {
  if (currentColumnData.inputType !== 'Select' && currentColumnData.inputType !== 'RadioList' && currentColumnData.inputType !== 'CheckboxList') {
    throw new Error(`${currentColumnData} should be in select, radioList or checkBoxList`);
  }
  const { dataTable: dataTableName } = currentColumnData;
  const dataTable = geDataTables[dataTableName];
  return dataTable;
}

export const getDataTable = (tableName, column) => {
  const currentColumnData = getColumnData(tableName, column);

  return getDataTableFromCurrentColumn(currentColumnData);
};

export const getElementFieldPathHooks = (tableName: string, column: string) => {
  const currentColumnData = getColumnData(tableName, column);
  if (currentColumnData.inputType !== 'CheckboxList') {
    throw new Error(`${makeName(tableName, column)} should be in select, radioList or checkBoxList`);
  }
  const { elementFieldPathHook } = currentColumnData;
  return geElementFieldPathHooks[elementFieldPathHook].useElementFieldPathHook;
};

export const getFormatter = (tableName: string, column: string): GeValidator => {
  const currentColumnData = getColumnData(tableName, column);
  if (!isGeTextColumn(currentColumnData)) {
    throw new Error('should be in Text');
  }
  const { formatter: formatterName } = currentColumnData;
  const formatter = formatterName ? geFormatters[formatterName] : {
    id: 'none',
    formatter: {},
  };
  return formatter;
};
