import { actorAccountCommunication } from '@goldwasserexchange/utils';
import { PersonAccounts } from '../../type';

export const getAccountCommunicationFromPersonAccounts = (d: PersonAccounts | null | undefined): string => {
  if (!(d && d['T_ACC.NUM_DISPLAY'])) {
    return '';
  }
  const numDisplay = actorAccountCommunication(d['T_ACC.NUM_DISPLAY']);
  return numDisplay;
};

export const getAccountCommunicationStartFromPersonAccounts = (d: PersonAccounts | null | undefined): string => {
  if (!d) {
    return '';
  }
  const numDisplay = getAccountCommunicationFromPersonAccounts(d);
  const rootStart = (d['T_ACC.ROOT'] || '').toString().length - 3;
  return numDisplay.slice(0, 14 - rootStart);
};

export const getAccountCommunicationRootStartFromPersonAccounts = (d: PersonAccounts | null | undefined): string => {
  if (!d) {
    return '';
  }
  const numDisplay = getAccountCommunicationFromPersonAccounts(d);
  const rootStart = (d['T_ACC.ROOT'] || '').toString().length - 3;
  return numDisplay.slice(14 - rootStart, 14);
};

export const getAccountCommunicationRootMiddleFromPersonAccounts = (d: PersonAccounts | null | undefined): string => {
  if (!d) {
    return '';
  }
  const numDisplay = getAccountCommunicationFromPersonAccounts(d);
  return numDisplay.slice(14, 17);
};

export const getAccountCommunicationRootEndFromPersonAccounts = (d: PersonAccounts | null | undefined): string => {
  if (!d) {
    return '';
  }
  const numDisplay = getAccountCommunicationFromPersonAccounts(d);
  return numDisplay.slice(17, -6);
};

export const getAccountCommunicationEndFromPersonAccounts = (d: PersonAccounts | null | undefined): string => {
  if (!d) {
    return '';
  }
  const numDisplay = getAccountCommunicationFromPersonAccounts(d);
  return numDisplay.slice(-6);
};
