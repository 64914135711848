import { CTit } from '../../../../types';
export var list = [
    CTit.MONSIEUR,
    CTit.MADAME,
    CTit.MADEMOISELLE,
    CTit.ASBL,
    CTit.SOCIETE,
    CTit.FONDATION_PRIVEE,
    CTit.FONDATION_D_UTILITE_PUBLIQUE,
];
export var physicList = [
    CTit.MONSIEUR,
    CTit.MADAME,
    CTit.MADEMOISELLE,
];
export var moralList = [
    CTit.ASBL,
    CTit.SOCIETE,
    CTit.FONDATION_PRIVEE,
    CTit.FONDATION_D_UTILITE_PUBLIQUE,
];
