import ouiBase from '@goldwasserexchange/oui';
import ouiStyle from '@goldwasserexchange/oui-style';
import ouiDom from '@goldwasserexchange/oui-dom';
import * as React from 'react';

export const AccountTypeBodyRowHeadingContainer = (props: React.PropsWithChildren<{ scope?: 'col' | 'row' } & ouiDom.Table.TableBodyRowCellInStyle>) => {
  const {
    children,
    ...rest
  } = props;
  const isLast = ouiBase.utils.dimensions.useIsLastSecondaryDimensionIndex();
  return (
    <ouiDom.Table.TableHeaderHeading
      display={[ouiStyle.Constants.DisplayProp.hidden, undefined, ouiStyle.Constants.DisplayProp.tableCell, undefined, undefined, undefined]}
      color={ouiStyle.Constants.Colors.primary}
      fontWeight={ouiStyle.Constants.FontWeightProp.normal}
      borderBottomWidth={!isLast ? ['none', undefined, 'double', undefined, undefined, undefined] : 'none'}
      borderBottomColor={!isLast ? [ouiStyle.Constants.Colors.transparent, undefined, ouiStyle.Constants.Colors.fade, undefined, undefined, undefined] : ouiStyle.Constants.Colors.transparent}
      {...rest}
    >
      {children}
    </ouiDom.Table.TableHeaderHeading>
  );
};
