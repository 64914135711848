/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import { animated } from 'react-spring';
import ouiStyle from '@goldwasserexchange/oui-style';
import {
  useGetFieldPath,
  InputVariantsNames,
  useHasContentVariant,
  useHasFocusVariant,
  useValidationVariant,
  useDisabledVariant,
  InputVariantHooksArray,
} from '../../../../../../../OUI/Inputs';
import { useVariantSpring } from '../../../../../utils';
import {
  labelStyle,
  focusLabelStyle,
  noFocusLabelStyle,
  errorLabelStyle,
  disabledLabelStyle,
} from '../../../../shared';

const labelCss = ouiStyle.makeCss(labelStyle);

const hasContentTextLabelStyle: ouiStyle.InStyle = {
  ...labelStyle,
  color: ouiStyle.Constants.Colors.primary,
  fontSize: 'xs',
  transform: 'translate3d(0,0rem,0)',
  pointerEvents: 'auto',
};

const hasContentLabelCss = ouiStyle.makeCssAndExtract(hasContentTextLabelStyle, [
  'color',
  'fontSize',
  'pointerEvents',
  'transform',
]);

const noContentTextLabelStyle: ouiStyle.InStyle = {
  ...labelStyle,
  color: ouiStyle.Constants.Colors.normal,
  fontSize: 's',
  transform: `translate3d(0,${ouiStyle.Constants.computedLineHeight + ouiStyle.Constants.space}rem,0)`,
  pointerEvents: 'none',
};

const noContentLabelCss = ouiStyle.makeCssAndExtract(noContentTextLabelStyle, [
  'color',
  'fontSize',
  'pointerEvents',
  'transform',
]);

const focusTextLabelStyle: ouiStyle.InStyle = {
  ...focusLabelStyle,
  fontSize: 'xs',
  transform: 'translate3d(0,0rem,0)',
  pointerEvents: 'auto',
};

const focusLabelCss = ouiStyle.makeCssAndExtract(focusTextLabelStyle, [
  'color',
  'fontSize',
  'fontWeight',
  'pointerEvents',
  'transform',
]);

const noFocusLabelCss = ouiStyle.makeCssAndExtract(noFocusLabelStyle, ['fontWeight']);

const errorLabelCss = ouiStyle.makeCssAndExtract(errorLabelStyle, ['color']);

const disabledLabelCss = ouiStyle.makeCssAndExtract(disabledLabelStyle, ['color']);

const labelVariants: Record<
InputVariantsNames,
Partial<
Pick<
ouiStyle.Style,
'fontSize' | 'fontWeight' | 'color' | 'pointerEvents' | 'transform'
>
>
> = {
  opened: {},
  closed: {},
  base: {},
  hasContent: hasContentLabelCss,
  noContent: noContentLabelCss,
  focus: focusLabelCss,
  noFocus: noFocusLabelCss,
  untouched: {},
  valid: {},
  error: errorLabelCss,
  disabled: disabledLabelCss,
  enabled: {},
  checked: {},
  unChecked: {},
  last: {},
  notLast: {},
  before: {},
  inside: {},
  after: {},
  noSelection: {},
  notCorrected: {},
  correct: {},
  incorrect: {},
  correctSelected: {},
  correctNotSelected: {},
  incorrectSelected: {},
  incorrectNotSelected: {},
};

const defaultTextInputLabelContainerInputVariantHooks: InputVariantHooksArray = [
  useHasContentVariant,
  useHasFocusVariant,
  useValidationVariant,
  useDisabledVariant,
];

export function DefaultTextInputLabelContainer(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  const fieldPath = useGetFieldPath();
  const spring = useVariantSpring(
    labelVariants,
    defaultTextInputLabelContainerInputVariantHooks,
  );
  return (
    <animated.label htmlFor={fieldPath} css={labelCss} style={spring}>
      {children}
    </animated.label>
  );
}
