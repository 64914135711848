import { getMakeKERequiredLogic } from '../../../financialProducts/logic/KERequired';
import { getMakeFinancialProductSelectedLogic } from '../selected/getter';
export function makeSelectedFinancialProductWithRequiredLogic(financialProduct) {
    return function makeSelectedFinancialProductWithRequiredLogicInner(alternatives) {
        var selected = alternatives.selected, notSelected = alternatives.notSelected, notRequired = alternatives.notRequired;
        var subLogic = getMakeFinancialProductSelectedLogic(financialProduct)({
            alternatives: {
                selected: selected,
                notSelected: notSelected,
            },
        });
        var logic = getMakeKERequiredLogic(financialProduct)({
            alternatives: {
                required: subLogic,
                free: subLogic,
                notRequired: notRequired,
            },
        });
        return logic;
    };
}
