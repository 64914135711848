import React from 'react';
import { useSecondaryDimensionDataLength } from '../hooks';
import { secondaryDimensionLengthContext } from './context';
export var SecondaryDimensionLengthProvider = function (props) {
    var length = props.length, children = props.children;
    return (React.createElement(secondaryDimensionLengthContext.Provider, { value: length }, children));
};
export function SecondaryDimensionLengthProviderFromList(props) {
    var forcedLength = props.length, children = props.children;
    var listLength = useSecondaryDimensionDataLength();
    var length = forcedLength !== null && forcedLength !== void 0 ? forcedLength : listLength;
    return React.createElement(SecondaryDimensionLengthProvider, { length: length }, children);
}
