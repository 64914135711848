var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useContext, useMemo } from 'react';
import { primaryDimensionListContext } from './context';
import { usePrimaryDimensionLength } from './length/hooks';
export var usePrimaryDimensionList = function () {
    var primaryDimensionList = useContext(primaryDimensionListContext);
    return primaryDimensionList;
};
export var usePrimaryDimensionListElementByIndex = function (index) {
    var listData = usePrimaryDimensionList();
    var memoizedIndex = useMemo(function () { return listData[index]; }, [listData, index]);
    return memoizedIndex !== null && memoizedIndex !== void 0 ? memoizedIndex : {};
};
export function usePrimaryDimensionDataLength() {
    var data = usePrimaryDimensionList();
    return data.length;
}
export function usePrimaryDimensionDataIndexArray() {
    var length = usePrimaryDimensionLength();
    var listIndexArray = useMemo(function () { return __spreadArray([], __read(new Array(length)), false).map(function (_, k) { return k; }); }, [length]);
    return listIndexArray;
}
