export const imageQueryContent = /* groq */`
  asset -> {
    _id,
    metadata {
      lqip,
      dimensions {
        width,
        aspectRatio,
        height
      }
    }
  },
  hotspot {
    width,
    height,
    x,
    y
  },
  crop {
    top,
    right,
    bottom,
    left,
  }
` as const;

export const imageQuery = /* groq */`{${imageQueryContent}}` as const;
