/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
var style = {
    paddingVertical: 'simple',
};
var css = ouiStyle.mediaQuery(ouiStyle.makeCss(style));
export var EmbeddedContainer = function (props) {
    var children = props.children;
    return (jsx("div", { css: css }, children));
};
