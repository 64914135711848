import * as TYPE from '../../TYPE';
import * as TIN_US from '../../TIN_US';
var onlyOneFiscalResidency = 'onlyOneFiscalResidency';
var secondaryFinancialAddressPath = 'secondaryFiscalAddress';
var idCCountryPath = 'ID_C_COUNTRY';
export function makeShowLogic(props) {
    var national = props.national, global = props.global, invisible = props.invisible, _a = props.nationalInNatNumber, nationalInNatNumber = _a === void 0 ? invisible : _a;
    return TYPE.logic.makeTAddIsNotMoralOrIgnored({
        truthy: {
            name: "<parentName>.".concat(onlyOneFiscalResidency),
            is: false,
            alternatives: {
                truthy: {
                    name: "<parentName>.".concat(secondaryFinancialAddressPath, ".").concat(idCCountryPath),
                    is: 212,
                    alternatives: TIN_US.logic.show.makeShowTAddFiscRegistNumAfterCountryLogic({
                        national: national,
                        global: global,
                        nationalInNatNumber: nationalInNatNumber,
                        invisible: invisible,
                    }),
                },
                falsy: invisible,
            },
        },
    });
}
export var showLogic = makeShowLogic({
    national: {
        result: 'visible',
    },
    global: {
        result: 'visible',
    },
    invisible: {
        result: 'invisible',
    },
    nationalInNatNumber: {
        result: 'visible',
    },
});
export var showBooleanLogic = makeShowLogic({
    national: {
        result: true,
    },
    global: {
        result: true,
    },
    invisible: {
        result: false,
    },
    nationalInNatNumber: {
        result: true,
    },
});
