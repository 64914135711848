/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { inputIconContainerStyle } from '../../../../iconContent';

const style: ouiStyle.InStyleWithMediaQueries = {
  ...inputIconContainerStyle,
  lines: 'auto',
  display: ouiStyle.Constants.DisplayProp.flex,
  flexDirection: ouiStyle.Constants.InFlexDirectionProp.col,
  alignItems: ouiStyle.Constants.AlignItemsProp.center,
  justifyContent: ouiStyle.Constants.JustifyContentProp.center,
};

const css = ouiStyle.mediaQuery(ouiStyle.makeCss(style));

export function ListInputIconContainer(props: React.PropsWithChildren<{}>) {
  const { children } = props;
  return <div css={css}>{children}</div>;
}
