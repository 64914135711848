import { useContext, useMemo } from 'react';
import { GetAccountsPositionsHistoryParams } from '@goldwasserexchange/actor/rest-services';
import { useTAccId } from '../../../shared';
import { stringifyParams } from '../../../shared/utils/makeUrl';
import { accountsPositionsHistoryParamsContext } from '../context';
import { useIsConnected } from '../../../../../../Components/Auth';

export const useAccountPositionsHistoryDefaultParams = (params: Partial<GetAccountsPositionsHistoryParams>): Partial<GetAccountsPositionsHistoryParams> => {
  const contextParams = useContext(accountsPositionsHistoryParamsContext) ?? {} as Partial<GetAccountsPositionsHistoryParams>;
  const tAccIdString = useTAccId();
  const currentTAccId = tAccIdString ? parseInt(tAccIdString, 10) : undefined;
  const tAccId = params['T_ACC.ID'] ?? contextParams['T_ACC.ID'] ?? currentTAccId;
  const sAccId = params['S_ACC.ID'] ?? contextParams['S_ACC.ID'];
  const defaultParams = useMemo(() => ({
    'T_ACC.ID': tAccId,
    'S_ACC.ID': sAccId,
  }), [tAccId]);
  return defaultParams;
};

const useAccountPositionsHistoryParams = (): GetAccountsPositionsHistoryParams | null => {
  const defaultParams = useAccountPositionsHistoryDefaultParams({});
  const tAccId = defaultParams['T_ACC.ID'];
  const isConnected = useIsConnected();
  if (tAccId == null || !isConnected) {
    return null;
  }
  const sAccId = defaultParams['S_ACC.ID'];
  if (sAccId == null) {
    return null;
  }
  return {
    'T_ACC.ID': tAccId,
    'S_ACC.ID': sAccId,
  };
};

export const useAccountPositionsHistoryStringifiedParams = () => {
  const contextParams = useAccountPositionsHistoryParams();
  return contextParams !== null ? stringifyParams(contextParams) : null;
};
