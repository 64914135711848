import * as React from 'react';
import { ActorApiMapper } from '../../../../../../components';
import { PositionSubGroup } from '../../type';
import { usePositionSubGroupList } from '../../hooks/shared';
import { STypeGrpIds } from '../../../../../shared/utils/sTypeGrpIds';

const defaultLoadingList = [
  STypeGrpIds.CASH, STypeGrpIds.ACTIONS, STypeGrpIds.OBLIGATIONS, 22,
];

export const PositionsSubGroupMapper = (props: React.PropsWithChildren<{
  filterPredicate?: (data: PositionSubGroup) => boolean,
  limit?: number,
  loadingList?: any[],
}>) => {
  const {
    filterPredicate,
    loadingList = defaultLoadingList,
    limit,
    children,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = usePositionSubGroupList({
    filterPredicate,
  });
  return (
    <ActorApiMapper
      data={data}
      isLoading={isLoading}
      error={error}
      loadingList={loadingList}
      limit={limit}
    >
      {children}
    </ActorApiMapper>
  );
};
