import { useFilterByTAccId } from '../../utils/tAcc/filterByTAccId';
import { PersonAccounts } from '../../type';
import { usePersonAccountTransformApi } from '../api';

const hasSIndice = (personAccounts: PersonAccounts | undefined | null) => personAccounts && personAccounts['S_INDICE.ID'] != null;

export const usePersonAccountsSIndicePresence = () => {
  const filterBySelectedTAccId = useFilterByTAccId();
  const sIndicePresenceApiResponse = usePersonAccountTransformApi({
    transformFunction: hasSIndice,
    filterPredicate: filterBySelectedTAccId,
  });
  return sIndicePresenceApiResponse;
};
