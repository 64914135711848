/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, keyframes } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { ToggleViewBoxSize } from '../../../../../../constants';

const circleStyle: ouiStyle.InStyleWithMediaQueries = {
  display: ouiStyle.Constants.DisplayProp.inline,
};

const baseCircleCss = ouiStyle.makeCss(circleStyle);

const circleCss = {
  ...baseCircleCss,
  animationDuration: '0.1s',
  animationTimingFunction: 'linear',
  animationIterationCount: 1,
  animationFillMode: 'forwards',
};

const circleCssActivateKeyFrames = keyframes`
  0% {
    transform: translate3d(0rem,0,0);
    stroke: ${ouiStyle.Constants.Colors.primary};
    fill: ${ouiStyle.Constants.Colors.primary};
  }

  100% {
    transform: translate3d(5rem,0,0);
    stroke: ${ouiStyle.Constants.Colors.inverted};
    fill: ${ouiStyle.Constants.Colors.inverted};
  }
`;

const circleCssActiveTouched = [
  circleCss,
  {
    animationName: circleCssActivateKeyFrames,
  },
];

const circleCssActiveUntouched = [
  circleCss,
  {
    transform: 'translate3d(5rem,0,0)',
    stroke: ouiStyle.Constants.Colors.inverted,
    fill: ouiStyle.Constants.Colors.inverted,
  },
];

const circleCssDeactivateKeyFrames = keyframes`
  0% {
    transform: translate3d(5rem,0,0);
    stroke: ${ouiStyle.Constants.Colors.inverted};
    fill: ${ouiStyle.Constants.Colors.inverted};
  }

  100% {
    transform: translate3d(0rem,0,0);
    stroke: ${ouiStyle.Constants.Colors.primary};
    fill: ${ouiStyle.Constants.Colors.primary};
  }
`;

const circleCssInactiveTouched = [
  circleCss,
  {
    animationName: circleCssDeactivateKeyFrames,
  },
];

const circleCssInactiveUntouched = [
  circleCss,
  {
    transform: 'translate3d(0rem,0,0)',
    stroke: ouiStyle.Constants.Colors.primary,
    fill: ouiStyle.Constants.Colors.primary,
  },
];

const getCss = (props: {
  value: boolean,
  touched: boolean,
}) => {
  const {
    value,
    touched,
  } = props;
  if (touched) {
    if (value) {
      return circleCssActiveTouched;
    }
    return circleCssInactiveTouched;
  }
  if (value) {
    return circleCssActiveUntouched;
  }
  return circleCssInactiveUntouched;
};

export const ToggleIconCircle = (props: {
  value: boolean,
  touched: boolean,
}) => {
  const {
    value,
    touched,
  } = props;
  const css = getCss({
    touched,
    value,
  });
  return (
    <circle
      cx={(ToggleViewBoxSize[1] / 2)}
      cy={(ToggleViewBoxSize[1] / 2)}
      css={ouiStyle.mediaQuery(css)}
      r={ToggleViewBoxSize[1] * 0.35}
      strokeWidth="1"
    />
  );
};
