import throttle from 'lodash.throttle';
import * as events from '../../../events';

export type AppBarScrollServiceMeta = {
  src: {
    type: 'scrollService',
  },
};

export const name = 'watchScroll';

export const makeFn = () => () => (callback) => {
  let lastScrollTop = 0;
  let lastScrollDirection = 'down';
  let lastAboveCollapse = true;
  const listener = () => {
    if (document) {
      const scrollTop = window.scrollY;
      const isScrollingDown = scrollTop > lastScrollTop;
      const scrollDirection = isScrollingDown ? 'down' : 'up';
      const isAboveCollapse = scrollTop < 200;
      if (lastScrollDirection !== scrollDirection || isAboveCollapse !== lastAboveCollapse) {
        callback(events.scrollEvent.eventBuilder({
          scrollDirection, isAboveCollapse,
        }));
      }
      lastScrollTop = scrollTop;
      lastScrollDirection = scrollDirection;
      lastAboveCollapse = isAboveCollapse;
    }
  };
  const throttledListener = throttle(listener, 200, { leading: false, trailing: true });
  if (document) {
    document.addEventListener(
      'scroll',
      throttledListener,
      true,
    );
  }
  return () => document && document.removeEventListener('scroll', throttledListener, true);
};

export const makeService = () => ({
  [name]: makeFn(),
});

export default makeService;
