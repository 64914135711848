import React, {
  MouseEventHandler,
  useState,
} from 'react';
import { useCallbackRef } from 'use-callback-ref';
import ouiStyle from '@goldwasserexchange/oui-style';
import { SelectionMap, ListLengthContextProvider } from '../BaseComponents/List';
import {
  useClientWidth, useOnScrollLeft, useScrollToLeft, useHorizontalScrollBarHeight,
} from './utils';
import { ScrollToTouch } from './Touch/scrollToTouch';
import {
  StepperPlaceholderContainer,
  StepperPlaceholderComponentWithRef,
  StepperPlaceholderInner,
} from './placeHolder';

export const Scrollable = (props: {
  data: string[],
  elementWidth: number,
  height: string,
  onScroll?: () => void,
  onMouseEnter?: MouseEventHandler<HTMLDivElement>,
  scrollButtonDisplay?: ouiStyle.InStyle['display'],
  scrollButtonAdditionalPaddingTop?: number,
  children: (d: string, ...p: any[]) => JSX.Element | null,
}) => {
  const {
    data,
    elementWidth,
    height,
    onScroll,
    onMouseEnter,
    scrollButtonDisplay,
    scrollButtonAdditionalPaddingTop,
    children,
  } = props;
  const [refVersion, setRefVersion] = useState(0);
  const placeHolderRef = useCallbackRef<HTMLDivElement>(null, () => {
    if (onScroll) {
      onScroll();
    }
    setRefVersion((v) => v + 1);
  });
  const totalWidth = data.length * elementWidth;
  const [scrollLeft, onScrollLeft] = useOnScrollLeft(placeHolderRef, refVersion);
  const scrollTo = useScrollToLeft(placeHolderRef);
  const clientWidth = useClientWidth(placeHolderRef, refVersion);
  const scrollBarHeight = useHorizontalScrollBarHeight(placeHolderRef, refVersion);
  return (
    <ListLengthContextProvider data={data}>
      <StepperPlaceholderContainer
        height={height}
        onMouseEnter={onMouseEnter}
      >
        <ScrollToTouch
          position="left"
          scrollTo={scrollTo}
          currentScrollLeft={scrollLeft}
          elementWidth={elementWidth}
          totalWidth={totalWidth}
          clientWidth={clientWidth}
          display={scrollButtonDisplay}
          additionalPaddingTop={scrollButtonAdditionalPaddingTop}
        />
        <StepperPlaceholderComponentWithRef
          ref={placeHolderRef}
          onScrollLeft={onScrollLeft}
          scrollBarHeight={scrollBarHeight}
        >
          <StepperPlaceholderInner
            totalWidth={totalWidth}
          >
            <SelectionMap data={data}>
              {(d, index) => children(d, clientWidth, totalWidth, elementWidth, scrollLeft, scrollTo, index)}
            </SelectionMap>
          </StepperPlaceholderInner>
        </StepperPlaceholderComponentWithRef>
        <ScrollToTouch
          position="right"
          scrollTo={scrollTo}
          currentScrollLeft={scrollLeft}
          elementWidth={elementWidth}
          totalWidth={totalWidth}
          clientWidth={clientWidth}
          display={scrollButtonDisplay}
          additionalPaddingTop={scrollButtonAdditionalPaddingTop}
        />
      </StepperPlaceholderContainer>
    </ListLengthContextProvider>
  );
};
