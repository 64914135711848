import * as React from 'react';
import { GetAccountsCashflowsParams } from '@goldwasserexchange/actor/rest-services';
import { useAccountCashflowsDefaultParams } from '../hooks';
import { accountsCashflowsParamsContext } from '../context';

export const AccountsCashflowsParamsProvider = (props: React.PropsWithChildren<{
  params: Partial<GetAccountsCashflowsParams>,
}>) => {
  const {
    params,
    children,
  } = props;
  const value = useAccountCashflowsDefaultParams(params);
  return (
    <accountsCashflowsParamsContext.Provider
      value={value}
    >
      {children}
    </accountsCashflowsParamsContext.Provider>
  );
};
