import { AccountRates, SimulationBuyAndSellCostData, SimulationBuyOrSellCostData } from '../type';
import { computeOrGetGrossAmount, GetGrossAmountProps } from './computeGrossAmount';
import { yearlyPct } from './yearlyPct';

export type ComputeExchangeFeesProps = SimulationBuyOrSellCostData | ({
  currency: SimulationBuyOrSellCostData['currency'],
} & GetGrossAmountProps);

export const computeExchangeFees = (props: ComputeExchangeFeesProps) => (frcRate: AccountRates['T_ACC.FRC_RATE']) => {
  const {
    currency = 'EUR',
  } = props;
  if (currency !== 'EUR') {
    const grossAmountInEur = computeOrGetGrossAmount(props);
    return grossAmountInEur * frcRate;
  }
  return 0;
};

export const computeExchangeFeesPct = (props: ComputeBuyAndSellExchangeFeesProps) => (frcRate: AccountRates['T_ACC.FRC_RATE']) => {
  const {
    counterValue,
    foreignCurrency,
  } = props;
  const exchangeFees = computeExchangeFees({
    grossAmountInEur: counterValue,
    currency: !foreignCurrency ? 'EUR' : 'USD',
  })(frcRate);
  return exchangeFees / counterValue;
};

export const computeAmountAfterExchangeFees = (props: ComputeExchangeFeesProps) => (frcRate: AccountRates['T_ACC.FRC_RATE']) => {
  const grossAmountInEur = computeOrGetGrossAmount(props);
  const exchangeFees = computeExchangeFees(props)(frcRate);
  return grossAmountInEur - exchangeFees;
};

export type ComputeBuyAndSellExchangeFeesProps = {
  counterValue: SimulationBuyAndSellCostData['counterValue'],
  foreignCurrency: SimulationBuyAndSellCostData['foreignCurrency'],
  refund: SimulationBuyAndSellCostData['refund'],
  type: SimulationBuyAndSellCostData['type'],
}

export const computeBuyAndSellExchangeFees = (props: ComputeBuyAndSellExchangeFeesProps) => (frcRate: AccountRates['T_ACC.FRC_RATE']) => {
  const {
    counterValue,
    foreignCurrency,
    refund,
    type,
  } = props;
  const fees = computeExchangeFees({
    grossAmountInEur: counterValue,
    currency: !foreignCurrency ? 'EUR' : 'USD',
  })(frcRate);
  return (!(refund && type === 'bonds') ? 2 : 1) * fees;
};

export type ComputeBuyAndSellExchangeFeesPctProps = {
  durationYears: SimulationBuyAndSellCostData['durationYears'],
} & ComputeBuyAndSellExchangeFeesProps

export const computeBuyAndSellExchangeFeesPct = (props: ComputeBuyAndSellExchangeFeesPctProps) => (frcRate: AccountRates['T_ACC.FRC_RATE']) => {
  const totalFees = computeBuyAndSellExchangeFees(props)(frcRate);
  return yearlyPct(props)(totalFees);
};
