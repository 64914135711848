import * as MediaQueryChangeDown from './mediaQueryChangeDown';
import * as MediaQueryChangeUp from './mediaQueryChangeUp';

export * as MediaQueryChangeDown from './mediaQueryChangeDown';
export * as MediaQueryChangeUp from './mediaQueryChangeUp';

export type EventTypeString = MediaQueryChangeDown.MediaQueryChangeDown | MediaQueryChangeUp.MediaQueryChangeUp;

export const eventTypes = [MediaQueryChangeDown.eventType, MediaQueryChangeUp.eventType];

export const eventTypeStrings: string[] = eventTypes as string[];

export type EventType = MediaQueryChangeDown.EventType | MediaQueryChangeUp.EventType

export const eventBuilder = (matcher: MediaQueryList): EventType => (matcher.matches ? ({
  type: MediaQueryChangeUp.eventType,
}) : ({
  type: MediaQueryChangeDown.eventType,
}));
