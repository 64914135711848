import * as React from 'react';
import { AuthErrorStrings } from '@aws-amplify/auth';
import ouiDom from '@goldwasserexchange/oui-dom';
import { Trans } from '@lingui/macro';
import ouiStyle from '@goldwasserexchange/oui-style';
import ouiBase from '@goldwasserexchange/oui';
import { UncastedDataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import { useHookstate } from '@hookstate/core';
import {
  useAuthMachineErrorName,
  useAuthMachineErrorMessage,
} from '../../../context';
import { GEPhoneNumber } from '../../../../PhoneNumber';

const ErrorMessageGePhoneNumber = (props: {
  spaceBefore?: boolean,
  spaceAfter?: boolean,
}) => {
  const {
    spaceBefore = false,
    spaceAfter = false,
  } = props;
  return (
    <>
      {spaceBefore ? ' ' : null}
      <GEPhoneNumber
        paddingTop="none"
        paddingBottom="none"
      />
      {spaceAfter ? ' ' : null}
    </>
  );
};

const requiredPhoneMessage = (
  <Trans>
    Le téléphone est un champs obligatoire
  </Trans>
);

const unknownProblem = (
  <Trans>
    Un problème inconnu s'est produit, veuillez réessayer plus tard ou nous contacter au&#32;
    <ErrorMessageGePhoneNumber />
  </Trans>
);

const userNameOrPasswordIncorrect = (
  <Trans>
    Nom d'utilisateur ou mot de passe incorrect
  </Trans>
);

const passwordIncorrect = (
  <Trans>
    Mot de passe incorrect
  </Trans>
);

const smsError = (
  <Trans>
    Le serveur n'a pas réussi à vous envoyer de sms, merci de nous contacter au&#32;
    <ErrorMessageGePhoneNumber />
  </Trans>
);

const tooManyAttempts = (
  <Trans>
    Limite du nombre d'essais dépassée, veuillez réessayer dans quelque temps
  </Trans>
);

export const errorMessages: Record<AuthErrorStrings | string, JSX.Element | Record<AuthErrorStrings | string, JSX.Element>> = {
  [AuthErrorStrings.DEFAULT_MSG]: unknownProblem,
  [AuthErrorStrings.EMPTY_EMAIL]: (
    <Trans>
      L'email est un champs obligatoire
    </Trans>
  ),
  [AuthErrorStrings.EMPTY_PHONE]: requiredPhoneMessage,
  [AuthErrorStrings.EMPTY_USERNAME]: requiredPhoneMessage,
  [AuthErrorStrings.INVALID_USERNAME]: requiredPhoneMessage,
  [AuthErrorStrings.EMPTY_PASSWORD]: (
    <Trans>
      Le mot de passe est un champs obligatoire
    </Trans>
  ),
  [AuthErrorStrings.EMPTY_CODE]: (
    <Trans>
      Le code de sécurité est un champs obligatoire
    </Trans>
  ),
  [AuthErrorStrings.SIGN_UP_ERROR]: unknownProblem,
  [AuthErrorStrings.NO_MFA]: unknownProblem,
  [AuthErrorStrings.INVALID_MFA]: unknownProblem,
  [AuthErrorStrings.EMPTY_CHALLENGE]: unknownProblem,
  [AuthErrorStrings.NO_USER_SESSION]: unknownProblem,
  [AuthErrorStrings.NETWORK_ERROR]: (
    <Trans>
      Une erreur réseau s'est produite, êtes vous connecté à internet?
    </Trans>
  ),
  [AuthErrorStrings.DEVICE_CONFIG]: unknownProblem,
  'Incorrect username or password.': userNameOrPasswordIncorrect,
  'Incorrect password': (
    <Trans>
      Le mot de passe actuel est incorrect
    </Trans>
  ),
  'Password attempts exceeded': (
    <Trans>
      Limite du nombre d'essais de mot de passe dépassée, veuillez réessayer dans quelque temps
    </Trans>
  ),
  // this is not a real cognito error, but it is used to represent the fact that the user has no email in the forgot password flow
  'Missing email for forgot password': (
    <Trans>
      Pas d'adresse email disponible pour réinitialiser votre mot de passe
    </Trans>
  ),
  CodeMismatchException: (
    <Trans>
      Le code de confirmation rentré est invalide
    </Trans>
  ),
  ExpiredCodeException: (
    <Trans>
      Le code de confirmation rentré a expiré
    </Trans>
  ),
  CodeDeliveryFailureException: (
    <Trans>
      Le code de confirmation n'a pu vous être envoyé, merci de nous contacter au&#32;
      <ErrorMessageGePhoneNumber />
    </Trans>
  ),
  InvalidPasswordException: userNameOrPasswordIncorrect,
  InternalErrorException: unknownProblem,
  InvalidSmsRoleAccessPolicyException: smsError,
  InvalidLambdaResponseException: unknownProblem,
  InvalidParameterException: (
    <Trans>
      L'un des paramètres envoyés au serveur est invalide, merci de nous contacter au&#32;
      <ErrorMessageGePhoneNumber />
    </Trans>
  ),
  InvalidSmsRoleTrustRelationshipException: smsError,
  NotAuthorizedException: (
    <Trans>
      Cette action n'est pas autorisée, si vous pensez qu'il s'agit d'une erreur, merci de nous contacter au&#32;
      <ErrorMessageGePhoneNumber />
    </Trans>
  ),
  ResourceNotFoundException: (
    <Trans>
      Le serveur n'a pas trouvé la ressource demandée, merci de nous contacter au&#32;
      <ErrorMessageGePhoneNumber />
    </Trans>
  ),
  TooManyFailedAttemptsException: tooManyAttempts,
  LimitExceededException: tooManyAttempts,
  TooManyRequestsException: tooManyAttempts,
  UnexpectedLambdaException: (
    <Trans>
      La réponse reçue du serveur est invalide, merci de nous contacter au&#32;
      <ErrorMessageGePhoneNumber />
    </Trans>
  ),
  UserLambdaValidationException: (
    <Trans>
      Le serveur est temporairement inaccessible, veuillez réessayer plus tard.
    </Trans>
  ),
  UserNotConfirmedException: (
    <Trans>
      Votre utilisateur n'est pas confirmé
    </Trans>
  ),
  UserNotFoundException: (
    <Trans>
      Aucun compte pour ce numéro de téléphone portable, merci de vous inscrire.
    </Trans>
  ),
  PasswordResetRequiredException: unknownProblem,
  InvalidEmailRoleAccessPolicyException: (
    <Trans>
      Le serveur n'a pas réussi à vous envoyer d'e-mail, merci de nous contacter au&#32;
      <ErrorMessageGePhoneNumber />
    </Trans>
  ),
  MFAMethodNotFoundException: (
    <Trans>
      Le serveur n'a pas réussi à réaliser l'authentification multi-facteur, merci de nous contacter au&#32;
      <ErrorMessageGePhoneNumber />
    </Trans>
  ),
  AliasExistsException: (
    <Trans>
      Ce numéro de téléphone existe déjà
    </Trans>
  ),
  onlyNumbers: (
    <Trans>
      Les informations fournies sont incorrectes
    </Trans>
  ),
  InvalidUserPoolConfigurationException: unknownProblem,
  UsernameExistsException: (
    <Trans>
      Vous êtes déjà inscrit à l'accès en ligne
    </Trans>
  ),
  invalidSignupInfo: (
    <Trans>
      Les informations fournies sont incorrectes
    </Trans>
  ),
  Forbidden: (
    <Trans>
      Vous n'avez pas le droit d'effectuer cette action, merci de nous contacter au&#32;
      <ErrorMessageGePhoneNumber spaceAfter />
      si vous pensez qu'il s'agit d'une erreur
    </Trans>
  ),
  minLines: unknownProblem,
};

export const getErrorMessage = (errorName: string | null, errorMessage: string | null, withUserName = true): React.ReactNode => {
  if (errorMessage && errorMessage.indexOf('PreSignUp failed') !== -1) {
    return errorMessages.invalidSignupInfo as React.ReactNode;
  }
  if (errorMessage && errorMessages[errorMessage]) {
    if (errorMessage === 'Incorrect username or password.' && !withUserName) {
      return passwordIncorrect;
    }
    return errorMessages[errorMessage] as React.ReactNode;
  }
  if (errorName && errorMessages[errorName]) {
    if (errorName === 'InvalidPasswordException' && !withUserName) {
      return passwordIncorrect;
    }
    return errorMessages[errorName] as React.ReactNode;
  }
  if (errorName === 'ValidationError') {
    return null;
  }
  return errorMessages[AuthErrorStrings.DEFAULT_MSG] as React.ReactNode;
};

export const AuthErrorMessage = (props: {
  submitType: string,
  withUserName?: boolean,
}) => {
  const {
    submitType,
    withUserName,
  } = props;
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const submitTypeState = useHookstate(valueState.submitType);
  const errorName = useAuthMachineErrorName();
  const errorMessage = useAuthMachineErrorMessage();
  if (submitTypeState.get() === submitType && (errorName || errorMessage)) {
    const errorMessageString = getErrorMessage(errorName, errorMessage, withUserName) as string | null;
    return errorMessageString != null ? (
      <ouiDom.P.P
        container={ouiStyle.Constants.Container.sm}
        backgroundColor={ouiStyle.Constants.Colors.warning}
        paddingHorizontal="simple"
        textAlign="center"
      >
        {errorMessageString}
      </ouiDom.P.P>
    ) : null;
  }
  return null;
};
