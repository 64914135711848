import { createState } from '@hookstate/core';
import { Untracked } from '@hookstate/untracked';
import * as React from 'react';
import { formDataContext } from './context';
import { SubscribePlugin } from './subscriptionPlugin';
import { DebouncePlugin } from './debouncePlugin';
export function FormDataProvider(props) {
    var initial = props.initial, children = props.children;
    var formState = React.useMemo(function () {
        var s = createState(initial);
        s.attach(Untracked);
        s.attach(SubscribePlugin);
        s.attach(DebouncePlugin);
        return s;
    }, []);
    return (React.createElement(formDataContext.Provider, { value: formState }, children));
}
