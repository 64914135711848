import { useContext, useMemo } from 'react';
import { GetPersonsAccountsParams } from '@goldwasserexchange/actor/rest-services';
import { stringifyParams } from '../../../shared/utils/makeUrl';
import { personAccountsParamsContext } from '../context';
import { useCognitoUserTAddId } from '../../../../../../Components/Sections/online/tAddId';
import { useIsConnected } from '../../../../../../Components/Auth';

export const usePersonAccountsDefaultParam = (params: Partial<GetPersonsAccountsParams>): Partial<GetPersonsAccountsParams> => {
  const {
    'T_ADD.ID': contextTAddId,
  } = useContext(personAccountsParamsContext);
  const cognitoUsertAddId = useCognitoUserTAddId();
  const tAddId = params['T_ADD.ID'] ?? cognitoUsertAddId ?? contextTAddId;
  const defaultParams = useMemo(() => ({
    'T_ADD.ID': tAddId,
  }), [tAddId]);
  return defaultParams;
};

const usePersonAccountsParams = (): GetPersonsAccountsParams | null => {
  const defaultParams = usePersonAccountsDefaultParam({});
  const tAddId = defaultParams['T_ADD.ID'];
  const isConnected = useIsConnected();
  if (tAddId == null || !isConnected) {
    return null;
  }
  return {
    'T_ADD.ID': tAddId,
  };
};

export const usePersonAccountsStringifiedParams = () => {
  const contextParams = usePersonAccountsParams();
  return contextParams !== null ? stringifyParams(contextParams) : null;
};
