/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiBase from '@goldwasserexchange/oui';
import ouiDom from '@goldwasserexchange/oui-dom';
import ouiStyle from '@goldwasserexchange/oui-style';
import { Check } from 'react-feather';
import { UncastedDataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import { useHookstate } from '@hookstate/core';

export const CheckBoxButtonContent = () => {
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const rememberDeviceState = useHookstate(valueState.auth.rememberDevice);
  if (rememberDeviceState.get() === '1') {
    return (
      <ouiDom.TextSnippet.TextSnippet
        color={ouiStyle.Constants.Colors.primary}
      >
        <Check size={22} />
      </ouiDom.TextSnippet.TextSnippet>
    );
  }
  return null;
};
