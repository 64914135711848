import { dataStructure, makeName, UncastedDataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import { isNil } from 'ramda';

const onboardingPath: keyof Pick<UncastedDataStructure, 'onboarding'> = 'onboarding';

export const makeQuestionnaireAnswers = () => Object.keys(dataStructure.financialProductsKnowledge.data.questionnaireQuestions).map((key) => key.replace(dataStructure.financialProductsKnowledge.pathUtils.ANSWER_SUFFIX, '')).reduce((acc, key) => ({
  ...acc,
  ...(
    !isNil(dataStructure.financialProductsKnowledge.data.questionnaireQuestions[dataStructure.financialProductsKnowledge.pathUtils.makeAnswerKey(key)].correct)
      ? {
        [makeName(onboardingPath, dataStructure.financialProductsKnowledge.data.questionnaireQuestions[dataStructure.financialProductsKnowledge.pathUtils.makeAnswerKey(key)].parentName, key)]: dataStructure.financialProductsKnowledge.data.questionnaireQuestions[dataStructure.financialProductsKnowledge.pathUtils.makeAnswerKey(key)].correct,
      }
      : {}
  ),
}), {});
