import { useCallback, useContext } from 'react';

import { currentModalContext } from './context';

export const useCurrentModalContextHandlers = () => {
  const handlers = useContext(currentModalContext);
  return handlers;
};

export const useCurrentModal = () => {
  const [currentModal] = useCurrentModalContextHandlers();
  return currentModal;
};

export const useSetCurrentModal = () => {
  const [, setCurrentModal] = useCurrentModalContextHandlers();
  return setCurrentModal;
};

export const useSetCurrentModalValue = (modal: string | null, voidClick = false) => {
  const setCurrentModal = useSetCurrentModal();
  const setCurrentModalValue = useCallback(() => {
    if (!voidClick) {
      setCurrentModal(modal);
    }
  }, [modal, voidClick]);
  return setCurrentModalValue;
};

export const useIsCurrentModal = (modal: string | null) => {
  const currentModal = useCurrentModal();
  const is = currentModal === modal;
  return is;
};
