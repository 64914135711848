import * as React from 'react';
import { ActorApiNumberDisplay } from '../../../../../../components/display/number/component';
import { useAccountCashflowsGesEstimDQt } from '../../hooks';

export const AccountsCashflowsGesEstimDQt = () => {
  const {
    data,
    isLoading,
    error,
  } = useAccountCashflowsGesEstimDQt();
  return (
    <ActorApiNumberDisplay
      data={data}
      isLoading={isLoading}
      error={error}
      format={{
        minimumFractionDigits: 0,
        maximumFractionDigits: 4,
      }}
    />
  );
};
