import * as React from 'react';
import { ActorApiNumberDisplayCurrency } from '../../../../../../components/display/number/currency';
import { usePersonAccountsTAccEstimGloIntTotal } from '../../hooks';

export const PersonAccountsEstimGloIntTotal = () => {
  const {
    data,
    isLoading,
    error,
  } = usePersonAccountsTAccEstimGloIntTotal();
  return (
    <ActorApiNumberDisplayCurrency
      data={data}
      isLoading={isLoading}
      error={error}
      currencyData="EUR"
      currencyIsLoading={false}
      currencyError={null}
    />
  );
};
