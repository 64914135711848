var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useContext, useMemo } from 'react';
import { getTopModelFromChildren } from '../../utils';
import { topModelContext } from './context';
import { usePrefixTopModels } from '../nameTransform';
export var MergeOrder;
(function (MergeOrder) {
    MergeOrder[MergeOrder["before"] = 0] = "before";
    MergeOrder[MergeOrder["after"] = 1] = "after";
})(MergeOrder || (MergeOrder = {}));
export function TopModelMerge(props) {
    var propsTopModels = props.topModels, _a = props.order, order = _a === void 0 ? MergeOrder.after : _a, log = props.log, children = props.children;
    var currentTopModels = useContext(topModelContext);
    var topModels = useMemo(function () {
        if (order === MergeOrder.after) {
            return __assign(__assign({}, currentTopModels), propsTopModels);
        }
        return __assign(__assign({}, propsTopModels), currentTopModels);
    }, [currentTopModels, propsTopModels, order]);
    if (log) {
        // eslint-disable-next-line no-console
        console.log(topModels);
    }
    return (React.createElement(topModelContext.Provider, { value: topModels }, children));
}
// eslint-disable-next-line space-before-function-paren
export function TopModelMergeNameTransformed(props) {
    var propsTopModels = props.topModels, order = props.order, transformBeforeFn = props.transformBeforeFn, log = props.log, children = props.children;
    var topModels = usePrefixTopModels(propsTopModels, transformBeforeFn);
    if (log) {
        // eslint-disable-next-line no-console
        console.log(topModels);
    }
    return (React.createElement(TopModelMerge, { topModels: topModels, order: order, log: log }, children));
}
export function TopModelChildrenMerge(props) {
    var incomingChildren = props.children, _a = props.order, order = _a === void 0 ? MergeOrder.after : _a, log = props.log;
    var currentTopModels = useContext(topModelContext);
    var _b = __read(useMemo(function () { return getTopModelFromChildren(incomingChildren); }, [incomingChildren]), 2), children = _b[0], childTopModels = _b[1];
    if (log) {
        // eslint-disable-next-line no-console
        console.log(currentTopModels, childTopModels);
    }
    return (React.createElement(TopModelMerge, { topModels: childTopModels, order: order, log: log }, children));
}
export function TopModelContextProvider(props) {
    var defaultTopModels = props.topModels, contextMergeOrder = props.contextMergeOrder, childrenMergeOrder = props.childrenMergeOrder, log = props.log, children = props.children;
    return (React.createElement(TopModelMerge, { topModels: defaultTopModels, order: contextMergeOrder },
        React.createElement(TopModelChildrenMerge, { order: childrenMergeOrder, log: log }, children)));
}
