import ouiBase from '@goldwasserexchange/oui';
import { useCallback } from 'react';
import { AccountCashflow } from '../type';

export const filterByCpsHisId = (cpsHisId: AccountCashflow['CPS_HIS.ID']) => (cashFlowLine: AccountCashflow) => cashFlowLine['CPS_HIS.ID'] === cpsHisId;

export const useFilterByCpsHisId = (cpsHisId: AccountCashflow['CPS_HIS.ID']) => {
  const filterFn = useCallback(filterByCpsHisId(cpsHisId), [cpsHisId]);
  return filterFn;
};

export const useFilterByPrimaryDimensionCpsHisId = () => {
  const idString = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const id = parseInt(idString, 10);
  const filterByGesEstimD = useFilterByCpsHisId(id);
  return filterByGesEstimD;
};
