import { StickyPosition } from './stickyPositionEnum';

export const getStickyPosition = (
  visibleStartIndex: number,
  visibleStopIndex: number,
  selectedIndex: number,
) => {
  if (selectedIndex === -1) {
    return StickyPosition.noSelection;
  } if (selectedIndex < visibleStartIndex) {
    return StickyPosition.before;
  } if (
    visibleStartIndex <= selectedIndex
    && selectedIndex <= visibleStopIndex
  ) {
    return StickyPosition.inside;
  } if (visibleStopIndex < selectedIndex) {
    return StickyPosition.after;
  }
  return StickyPosition.noSelection;
};
