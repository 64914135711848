import ouiDom from '@goldwasserexchange/oui-dom';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { X } from 'react-feather';
import { useCanCloseCurrentModal } from './context/canCloseModal';
import { useSetCurrentModalValue } from './context/currentModal/hooks';

export const CloseButon = (props: {
  onClick?: () => void,
}) => {
  const {
    onClick,
  } = props;
  const canClose = useCanCloseCurrentModal();
  const closeModal = useSetCurrentModalValue(null, !canClose);
  const closeModalOnClick = React.useCallback(() => {
    closeModal();
    if (onClick) {
      onClick();
    }
  }, [closeModal, onClick]);
  return canClose ? (
    <ouiDom.Button.Button
      type="button"
      onClick={closeModalOnClick}
      position="absolute"
      color={ouiStyle.Constants.Colors.primary}
      top={0}
      right={0}
      paddingLeft="simple"
      paddingRight="simple"
      paddingTop="simple"
      paddingBottom="simple"
      borderWidth="none"
      iconContainer

    >
      <X />
    </ouiDom.Button.Button>
  ) : null;
};
