import { Trans } from '@lingui/macro';
import * as React from 'react';
import ouiDom from '@goldwasserexchange/oui-dom';
import { ActionFilter, useSendOnClick } from '../../../../Machine';
import { BackToServiceSelectionButtonContainer } from './container';

export const BackToServiceSelection = () => {
  const onClick = useSendOnClick('GOTOorientation_serviceSelection');
  return (
    <BackToServiceSelectionButtonContainer>
      <ActionFilter
        is="GOTOorientation_serviceSelection"
      >
        <ouiDom.Button.Button
          type="button"
          onClick={onClick}
        >
          <Trans>
            Retourner à la sélection du service
          </Trans>
        </ouiDom.Button.Button>
      </ActionFilter>
    </BackToServiceSelectionButtonContainer>
  );
};
