import { ValidSections } from '../../../../../history';

export type EventTypeString = 'SECTION_CHANGE';

export const eventType: EventTypeString = 'SECTION_CHANGE';

export type EventPayload = {
  section: ValidSections | 'not-found',
};

export type EventType = {
  type: EventTypeString,
} & EventPayload;

export const eventBuilder = (payload: EventPayload): EventType => ({
  type: eventType,
  ...payload,
});
