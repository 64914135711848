var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useTopModelByName, useTopModelNameByNameWithFallbacks, useTopModelNamesTransform, useTopModelNameTransform, } from '../context';
import { getTopModelChildren, getTopModelIsRenderFn, getOtherTopModelProps, } from '../utils';
export function Ramp(props) {
    var name = props.rampName, _a = props.rampFallbacks, rampFallbacks = _a === void 0 ? [] : _a, log = props.log, rest = __rest(props, ["rampName", "rampFallbacks", "log"]);
    var topModelName = useTopModelNameByNameWithFallbacks(name, rampFallbacks);
    var topModel = useTopModelByName(topModelName);
    var topModelChildren = getTopModelChildren(topModel);
    var topModelProps = getOtherTopModelProps(topModel);
    if (process.env.NODE_ENV !== 'production') {
        if (log) {
            // eslint-disable-next-line no-console
            console.log(name, topModelName, topModel, topModelChildren);
        }
        if (log && topModelChildren == null) {
            // eslint-disable-next-line no-console
            console.log('missing', name, topModelName, topModel);
        }
    }
    if (typeof topModelChildren === 'function') {
        if (getTopModelIsRenderFn(topModel)) {
            return topModelChildren(__assign(__assign({}, rest), topModelProps));
        }
        var TopModelComponent = topModelChildren;
        return React.createElement(TopModelComponent, __assign({}, rest, topModelProps));
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return React.createElement(React.Fragment, null, topModelChildren);
}
export function RampWithNameTransform(props) {
    var sourceName = props.rampName, transformBeforeFn = props.transformBeforeFn, sourceRampFallbacks = props.rampFallbacks, children = props.children, rest = __rest(props, ["rampName", "transformBeforeFn", "rampFallbacks", "children"]);
    var name = useTopModelNameTransform(sourceName, transformBeforeFn);
    var rampFallbacks = useTopModelNamesTransform(sourceRampFallbacks);
    return (React.createElement(Ramp, __assign({}, rest, { rampName: name, rampFallbacks: rampFallbacks }), children));
}
