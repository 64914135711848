import * as React from 'react';
import {
  PortableTextProps,
} from '@portabletext/react';
import { reduceFootnotes } from '../utils';
import { footnotesContext } from './context';

export const FootnotesProvider = (props: React.PropsWithChildren<{
  blocks: PortableTextProps['value'],
}>) => {
  const {
    blocks,
    children,
  } = props;
  const footnotes = React.useMemo(() => reduceFootnotes(blocks), [blocks]);
  return (
    <footnotesContext.Provider value={footnotes}>
      {children}
    </footnotesContext.Provider>
  );
};
