/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const buttonContainerStyle: ouiStyle.InStyle = {
  display: ouiStyle.Constants.DisplayProp.flex,
  justifyContent: ouiStyle.Constants.JustifyContentProp.end,
  alignItems: ouiStyle.Constants.AlignItemsProp.center,
  flex: 'one',
  flexDirection: ouiStyle.Constants.InFlexDirectionProp.col,
};

export const ButtonContainer = (props: React.PropsWithChildren<{
  paddingVertical?: ouiStyle.InStyle['paddingVertical'],
}>) => {
  const { paddingVertical, children } = props;
  const buttonContainerCss = React.useMemo(() => ouiStyle.makeCss({
    ...buttonContainerStyle,
    paddingVertical,
  }), [paddingVertical]);
  return (
    <div
      css={buttonContainerCss}
    >
      {children}
    </div>
  );
};
