import { GetBondsPromotionsResponse } from '@goldwasserexchange/actor/rest-services';
import { AccountPosition } from '../../../accounts';
import { BondList } from '../../../bonds';
import { BondSameIssuerResponse } from '../../../bonds/sameIssuer/type';

export const spRatingWithDefault = (value?:
| AccountPosition['GES_RAT_VAL_SP.SHORTCUT']
| BondList['GES_RAT_VAL_SP.SHORTCUT']
| GetBondsPromotionsResponse['GES_RAT_VAL_SP.SHORTCUT']
| BondSameIssuerResponse['GES_RAT_VAL_SP.SHORTCUT']) => value ?? 'NR';
