import { mediaQueries, mediaQuerizeProps } from '../../mediaQuery';
export var InFlexDirectionProp;
(function (InFlexDirectionProp) {
    InFlexDirectionProp["row"] = "row";
    InFlexDirectionProp["col"] = "column";
})(InFlexDirectionProp || (InFlexDirectionProp = {}));
export var flexDirectionDefault = InFlexDirectionProp.row;
export var flexReverseDefault = false;
export var makeFlexDirection = function (props) {
    var flexDirection = props.flexDirection, flexReverse = props.flexReverse;
    if (!Array.isArray(flexDirection) && !Array.isArray(flexReverse)) {
        return "".concat(flexDirection).concat(flexReverse ? '-reverse' : '');
    }
    var flexDirectionMQ = mediaQuerizeProps(flexDirection);
    var flexReverseMQ = mediaQuerizeProps(flexReverse);
    var result = mediaQueries.map(function (_, index) { return makeFlexDirection({
        flexDirection: flexDirectionMQ[index],
        flexReverse: flexReverseMQ[index],
    }); });
    return result;
};
