import {
  dataStructure, STypeGrp, UncastedDataStructure, UncastedOnboarding, UncastedProduct,
} from '@goldwasserexchange/oblis-frontend-utils';
import ouiBase from '@goldwasserexchange/oui';
import { producSelectedDisableReasonMaps, productSelectedDisabledReasonDependencies } from './data';

const makeSelectedFieldFromProductId = (product: STypeGrp): `${keyof Pick<UncastedDataStructure, 'onboarding'>}.${keyof Pick<UncastedOnboarding, 'products'>}.${keyof UncastedOnboarding['products']}.${keyof Pick<UncastedProduct, 'selected'>}` => `onboarding.products.${product}.selected`;

export const useQuestionnaireSelectedDisabledReasonByProductNames = (productName: dataStructure.financialProducts.listType): string => {
  const fields = (productSelectedDisabledReasonDependencies[productName] ?? [])
    .map((product) => makeSelectedFieldFromProductId(product));
  const fieldSelectedStates = ouiBase.Form.useFieldValuesByPaths(fields);
  const firstSelectedIndex = fieldSelectedStates.findIndex((selected) => selected.get() === true);
  const selectedDisabledReasonMap = producSelectedDisableReasonMaps[productName] ?? [];
  const reason = firstSelectedIndex === -1 ? '' : selectedDisabledReasonMap[firstSelectedIndex];
  return reason ?? '';
};
