import { RampWithNameTransform } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import { useHeadingSectionIllustrationPosition, useHeadingSectionIntroFirst } from '../../context';
import { useHeadingSectionName } from '../../context/name';
import { useHasIllustrationContent } from './illustration';
import { useHasIntroContent } from './intro';
import { useHasTitleContent } from './title';
export var HeadingSectionHeadingBase = function () {
    var hasTitle = useHasTitleContent();
    var hasIntro = useHasIntroContent();
    var hasIllustration = useHasIllustrationContent();
    var introFirst = useHeadingSectionIntroFirst();
    var illustrationPosition = useHeadingSectionIllustrationPosition();
    var headingName = useHeadingSectionName();
    var title = hasTitle
        ? React.createElement(RampWithNameTransform, { rampName: "HeadingTitleBase" })
        : null;
    var intro = hasIntro
        ? React.createElement(RampWithNameTransform, { rampName: "HeadingIntroBase" })
        : null;
    var illustration = hasIllustration
        ? React.createElement(RampWithNameTransform, { rampName: "HeadingIllustrationBase" })
        : null;
    return hasTitle || hasIntro
        ? (React.createElement(RampWithNameTransform, { rampName: "HeadingContainer-".concat(headingName), rampFallbacks: [
                'HeadingContainer',
            ] },
            illustrationPosition === 'before' ? illustration : null,
            !introFirst ? title : intro,
            illustrationPosition === 'between' ? illustration : null,
            !introFirst ? intro : title,
            illustrationPosition === 'after' ? illustration : null,
            React.createElement(RampWithNameTransform, { rampName: "HeadingAdditionalContent-".concat(headingName), rampFallbacks: [
                    'HeadingAdditionalContent',
                ] })))
        : null;
};
