import { useAtomValue } from 'jotai';
import { useCmsCategory } from '../../../../../../cms';
import { pathnameUrlOblisSchoolArticleAtom, pathnameUrlOblisSchoolLevelAtom } from '../../../../../../../history';

export const useOblisSchoolCategoryList = () => {
  const oblisSchoolLevel = useAtomValue(pathnameUrlOblisSchoolLevelAtom);
  const category = `oblis-school/${oblisSchoolLevel}`;
  const response = useCmsCategory(category);
  return response;
};

export const useOblisSchoolArticleName = (): string | null => {
  const oblisSchoolArticle = useAtomValue(pathnameUrlOblisSchoolArticleAtom);
  const {
    data,
  } = useOblisSchoolCategoryList();
  const name = data ? data[0].name : null;
  return oblisSchoolArticle ?? name;
};

export const useOblisSchoolArticle = () => {
  const oblisSchoolArticleName = useOblisSchoolArticleName();
  const {
    data,
  } = useOblisSchoolCategoryList();
  return data ? data.find((el) => el.name === oblisSchoolArticleName) : null;
};

export const useOblisSchoolNextInCategory = () => {
  const oblisSchoolLevel = useAtomValue(pathnameUrlOblisSchoolLevelAtom);
  const name = useOblisSchoolArticleName();
  const category = `oblis-school/${oblisSchoolLevel}`;
  const response = useCmsCategory(category);
  const {
    data,
    ...rest
  } = response;
  if (!data) {
    return {
      ...rest,
      data: undefined,
      error: undefined,
    };
  }
  const index = data.findIndex((d) => d.name === name);
  if (index === -1 || data[index + 1] == null) {
    return {
      ...rest,
      data: undefined,
      error: undefined,
    };
  }
  return {
    ...rest,
    data: data[index + 1],
  };
};

export const useOblisSchoolPrevInCategory = () => {
  const oblisSchoolLevel = useAtomValue(pathnameUrlOblisSchoolLevelAtom);
  const name = useOblisSchoolArticleName();
  const category = `oblis-school/${oblisSchoolLevel}`;
  const response = useCmsCategory(category);
  const {
    data,
    ...rest
  } = response;
  if (!data) {
    return {
      ...rest,
      data: undefined,
      error: undefined,
    };
  }
  const index = data.findIndex((d) => d.name === name);
  if (index === -1 || data[index - 1] == null) {
    return {
      ...rest,
      data: undefined,
      error: undefined,
    };
  }
  return {
    ...rest,
    data: data[index - 1],
  };
};
