import * as React from 'react';
import { AccountType } from '@goldwasserexchange/oblis-frontend-utils';
import { Trans } from '@lingui/macro';
import { AccountTypeMobileSelectorButton } from './button';
import { AccountTypeMobileSelectorContainer } from './container';
import { useMobileSelectedAccountType } from '../context';

export const MobileAccountTypeSelector = (props: {
  highlight?: AccountType,
}) => {
  const {
    highlight,
  } = props;
  const mobileSelectedAccountType = useMobileSelectedAccountType();
  const isCurrentHighlighted = highlight === mobileSelectedAccountType;
  return (
    <AccountTypeMobileSelectorContainer>
      <AccountTypeMobileSelectorButton
        index={0}
        currentAccountType={AccountType.RTO}
        isCurrentHighlighted={isCurrentHighlighted}
        isHighlight={highlight === AccountType.RTO}
      >
        <Trans>Gold</Trans>
      </AccountTypeMobileSelectorButton>
      <AccountTypeMobileSelectorButton
        index={1}
        currentAccountType={AccountType.CONSEIL}
        isCurrentHighlighted={isCurrentHighlighted}
        isHighlight={highlight === AccountType.CONSEIL}
      >
        <Trans>Premium</Trans>
      </AccountTypeMobileSelectorButton>
      <AccountTypeMobileSelectorButton
        index={2}
        currentAccountType={AccountType.GESTION}
        isCurrentHighlighted={isCurrentHighlighted}
        isHighlight={highlight === AccountType.GESTION}
      >
        <Trans>Global+</Trans>
      </AccountTypeMobileSelectorButton>
    </AccountTypeMobileSelectorContainer>
  );
};
