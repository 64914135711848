import { atom } from 'jotai';
import { getBondSearchParams } from './get';
import { locationSearchParsedAtom } from '../../../../../../history';
import { SearchAndFilterBondParams } from './filter';
import { BondSort, initialBondSortContext } from './sort';

export const bondSearchParamsAtom = atom((get) => {
  const searchParsed = get(locationSearchParsedAtom);
  return getBondSearchParams(searchParsed);
});

export const bondSearchParamsSearchTermAtom = atom((get) => {
  const bondSearchParams = get(bondSearchParamsAtom);
  return bondSearchParams.SEARCH_TERM;
});

export const bondSearchParamsSortColumnAtom = atom((get) => {
  const bondSearchParams = get(bondSearchParamsAtom);
  return bondSearchParams.sortColumn;
});

export const bondSearchParamsSortDirectionAtom = atom((get) => {
  const bondSearchParams = get(bondSearchParamsAtom);
  return bondSearchParams.sortDirection;
});

export const bondSearchParamsPageAtom = atom((get) => {
  const bondSearchParams = get(bondSearchParamsAtom);
  return bondSearchParams.page;
});

export const cleanBondParams = (params: Partial<SearchAndFilterBondParams & BondSort>) => {
  const {
    SEARCH_TERM = '',
    'S_MARKET.YIELD': minYield,
    'S_MARKET.SMALL_QT_MIN': smallDenominiation = false,
    'CPS_HIS.LOW_ACCRUED': lowAccruedInterests = false,
    'S_MARKET.PRICE_CLOSE_LOW': lowPrice = false,
    'S_ACC.MATURITY': maturity = undefined,
    'RATING_SP.INVESTMENT_GRADE': investmentGrade = false,
    'S_ACC.IS_GREEN': sAccIsGreen = false,
    'C_CURRENCY.CODE': cCurrencyCode = undefined,
    sortColumn,
    sortDirection,
    page = 1,
  } = params;
  return {
    SEARCH_TERM: SEARCH_TERM !== '' ? SEARCH_TERM : undefined,
    'S_MARKET.YIELD': minYield,
    'S_MARKET.SMALL_QT_MIN': smallDenominiation !== false ? smallDenominiation : undefined,
    'CPS_HIS.LOW_ACCRUED': lowAccruedInterests !== false ? lowAccruedInterests : undefined,
    'S_MARKET.PRICE_CLOSE_LOW': lowPrice !== false ? lowPrice : undefined,
    'S_ACC.MATURITY': maturity != null && (maturity === 'perpetual' || maturity === 'lessThanFiveYears') ? maturity : undefined,
    'RATING_SP.INVESTMENT_GRADE': investmentGrade !== false ? investmentGrade : undefined,
    'S_ACC.IS_GREEN': sAccIsGreen !== false ? sAccIsGreen : undefined,
    'C_CURRENCY.CODE': cCurrencyCode != null && (cCurrencyCode === 'EUR' || cCurrencyCode === 'USD' || cCurrencyCode === 'foreign') ? cCurrencyCode : undefined,
    sortColumn: sortColumn !== initialBondSortContext.sortColumn ? sortColumn : undefined,
    sortDirection: sortDirection !== initialBondSortContext.sortDirection ? sortDirection : undefined,
    page: (page !== 1 && page !== 0) ? page : undefined,
  };
};

export const cleanedBondSearchParamsAtom = atom((get) => {
  const bondSearchParams = get(bondSearchParamsAtom);
  const cleaned = cleanBondParams(bondSearchParams);
  return Object.fromEntries(Object.entries(cleaned).filter(([, value]) => value != null));
});

export const hasCleanedFilterBondSearchParamsAtom = atom((get) => {
  const cleanedBondSearchParams = get(cleanedBondSearchParamsAtom);
  return Object
    .entries(cleanedBondSearchParams)
    .filter(([key]) => (
      key !== 'page'
    && key !== 'sortDirection'
    && key !== 'sortColumn'
    && key !== 'SEARCH_TERM'
    ))
    .length > 0;
});
