import { Ramp } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import { ToolbarDropDownLabelContainer } from './container';
import { ToolbarDropDownLabelTextContainer } from './content';
import { ToolbarDropDownToggleIcon } from './toggleIcon';

export const ToolbarDropDownLabel = () => (
  <ToolbarDropDownLabelContainer>
    <ToolbarDropDownLabelTextContainer>
      <Ramp rampName="ToolbarDropDownLabelTextContent" />
    </ToolbarDropDownLabelTextContainer>
    <ToolbarDropDownToggleIcon />
  </ToolbarDropDownLabelContainer>
);
