import * as React from 'react';
import { RatioContainer } from '../../../RatioContainer';
export var FlourishEmbed = function (props) {
    var description = props.description, src = props.src;
    return (React.createElement(RatioContainer, { aspectRatio: 16 / 9 },
        React.createElement("iframe", { src: src, title: description, style: {
                position: 'absolute',
                top: 0,
                width: '100%',
                height: '100%',
            }, sandbox: "allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation" })));
};
