import { DeleteCommand } from '@aws-sdk/lib-dynamodb';
import { KeyedMutator } from 'swr';
import { getSub } from '../../auth';
import { getDynamoDbDocumentClient } from '../getDynamoDbClient';
import { optimisticUpdateFavorites } from './optimisticUpdateFavorites';

type RemoveFavoriteProps = {
  data: string[] | undefined,
  tableName: string,
  sAccId: number,
}

export const removeFavorite = async (props: RemoveFavoriteProps) => {
  const {
    data,
    tableName,
    sAccId,
  } = props;
  const { client } = await getDynamoDbDocumentClient();
  const sub = await getSub();
  if (!sub) {
    return [];
  }
  await client.send(new DeleteCommand({
    TableName: tableName,
    Key: { pk: `u#${sub}`, sk: `favorite#${sAccId}` },
  }));
  client.destroy();
  return optimisticUpdateFavorites({
    data,
    element: `${sAccId}`,
    operation: 'DELETE',
  });
};

export const removeFavoriteWithMutate = async (props: RemoveFavoriteProps & {
  mutate: KeyedMutator<string[]>,
}) => {
  const {
    data,
    tableName,
    sAccId,
    mutate,
  } = props;
  return mutate(
    removeFavorite({
      data,
      tableName,
      sAccId,
    }),
    {
      optimisticData: optimisticUpdateFavorites({
        data,
        element: `${sAccId}`,
        operation: 'DELETE',
      }),
      rollbackOnError: true,
      populateCache: true,
      revalidate: false,
    },
  );
};
