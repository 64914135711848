import * as React from 'react';
import { TopModelMerge, TopModelNameTransformProvider } from '@goldwasserexchange/react-topmodel';
import { TextSnippetStructure } from './structure';
import { textSnippetDefaultTopModelsObject } from './topmodels';
import { textSnippetPrefixer } from './utils';
export function TextSnippet(props) {
    var display = props.display, flexDirection = props.flexDirection, justifyContent = props.justifyContent, flex = props.flex, flexGrow = props.flexGrow, flexShrink = props.flexShrink, flexBasis = props.flexBasis, alignItems = props.alignItems, fontSize = props.fontSize, lineFactor = props.lineFactor, fontWeight = props.fontWeight, color = props.color, textAlign = props.textAlign, textTransform = props.textTransform, textDecoration = props.textDecoration, letterSpacing = props.letterSpacing, backgroundColor = props.backgroundColor, padding = props.padding, paddingHorizontal = props.paddingHorizontal, paddingVertical = props.paddingVertical, paddingTop = props.paddingTop, paddingRight = props.paddingRight, paddingBottom = props.paddingBottom, paddingLeft = props.paddingLeft, childPaddingTop = props.childPaddingTop, childPaddingRight = props.childPaddingRight, childPaddingBottom = props.childPaddingBottom, childPaddingLeft = props.childPaddingLeft, iconContainer = props.iconContainer, baselinePadding = props.baselinePadding, widthLines = props.widthLines, parentBorderLeftWidth = props.parentBorderLeftWidth, parentBorderRightWidth = props.parentBorderRightWidth, icon = props.icon, minusWidth = props.minusWidth, opacity = props.opacity, children = props.children;
    return (React.createElement(TopModelNameTransformProvider, { transformFn: textSnippetPrefixer },
        React.createElement(TopModelMerge, { topModels: textSnippetDefaultTopModelsObject },
            React.createElement(TextSnippetStructure, { display: display, flexDirection: flexDirection, justifyContent: justifyContent, flex: flex, flexGrow: flexGrow, flexShrink: flexShrink, flexBasis: flexBasis, alignItems: alignItems, fontSize: fontSize, lineFactor: lineFactor, fontWeight: fontWeight, color: color, textAlign: textAlign, backgroundColor: backgroundColor, textTransform: textTransform, textDecoration: textDecoration, letterSpacing: letterSpacing, padding: padding, paddingHorizontal: paddingHorizontal, paddingVertical: paddingVertical, paddingTop: paddingTop, paddingRight: paddingRight, paddingBottom: paddingBottom, paddingLeft: paddingLeft, childPaddingTop: childPaddingTop, childPaddingRight: childPaddingRight, childPaddingBottom: childPaddingBottom, childPaddingLeft: childPaddingLeft, iconContainer: iconContainer, baselinePadding: baselinePadding, widthLines: widthLines, parentBorderLeftWidth: parentBorderLeftWidth, parentBorderRightWidth: parentBorderRightWidth, icon: icon, minusWidth: minusWidth, opacity: opacity }, children))));
}
