import React, { createContext, useMemo } from 'react';
import { useMachine } from '@xstate/react';
import {
  AnyEventObject, Interpreter,
} from 'xstate';

export type MachineSendType = Interpreter<unknown, any, AnyEventObject>['send']
type voidType = (() => void);

export type MachineContextType = [any, MachineSendType | voidType];

export const MachineContext = createContext<MachineContextType>([null, () => { }]);

export const MachineDefinitionContext = createContext([undefined, {}]);

type MachineContextProviderType = ({
  machine: any,
  options: any,
});

export const MachineContextProvider = (props: React.PropsWithChildren<MachineContextProviderType>): JSX.Element => {
  const { machine, options, children } = props;
  const [state, send] = useMachine(machine, options);
  const valueState = useMemo(() => [state, send] as MachineContextType, [state, send]);
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <MachineDefinitionContext.Provider value={[machine, options]}>
      <MachineContext.Provider value={valueState}>
        {children}
      </MachineContext.Provider>
    </MachineDefinitionContext.Provider>
  );
};
