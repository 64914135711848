import React, { createContext, useContext } from 'react';
import { ElementIndexContext } from '../Index';

export const ListPrimaryKeyContext = createContext(null);

export const ListPrimaryKeyContextProvider = ({ primaryKeyFn, children }) => (
  <ListPrimaryKeyContext.Provider value={primaryKeyFn}>
    {children}
  </ListPrimaryKeyContext.Provider>
);

ListPrimaryKeyContextProvider.displayName = 'ListPrimaryKeyContextProvider';

export const usePrimaryKeyIndex = (data, id) => {
  const primaryKeyFn = useContext(ListPrimaryKeyContext);
  const index = data.map((x) => primaryKeyFn(x)).indexOf(id);
  return index;
};

export const ElementPrimaryKeyContext = createContext();

export const ElementPrimaryKeyContextProvider = ({ data, children }) => {
  const getPrimaryKey = useContext(ListPrimaryKeyContext);
  const primaryKey = getPrimaryKey(data);
  return (
    <ElementPrimaryKeyContext.Provider value={primaryKey}>
      {children}
    </ElementPrimaryKeyContext.Provider>
  );
};

ElementPrimaryKeyContextProvider.displayName = 'ElementPrimaryKeyContextProvider';

export const ElementPrimaryKeyIndexContextProvider = ({ children }) => {
  const primaryKey = useContext(ElementIndexContext);
  return (
    <ElementPrimaryKeyContext.Provider value={primaryKey}>
      {children}
    </ElementPrimaryKeyContext.Provider>
  );
};

ElementPrimaryKeyIndexContextProvider.displayName = 'ElementPrimaryKeyIndexContextProvider';

export const usePrimaryKey = () => {
  const primaryKey = useContext(ElementPrimaryKeyContext);
  return primaryKey;
};
