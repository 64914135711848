import { useCallback } from 'react';
import { useOuiStyleMediaContextByIndex } from '../../../../../../../Components/MediaContext';
import { makeGetOblEndDateDifference, makeGetOblEndDateWithPerpetualAndDifference } from '../../../../shared/utils/getOblEndDateDifference';
import { getOblEndDateWithPerpetual } from '../../../../shared/utils/getOblEndDateWithPerpetual';
import { useBondsBondTransformApi } from '../api';

export const useBondsBondSAccOblEndDate = () => {
  const response = useBondsBondTransformApi(getOblEndDateWithPerpetual);
  return response;
};

export const useBondsBondSAccOblEndDateAndDifference = (mediaQueryIndex: number) => {
  const up = useOuiStyleMediaContextByIndex(mediaQueryIndex);
  const getOblEndDateWithPerpetualDifference = useCallback(
    makeGetOblEndDateWithPerpetualAndDifference(up),
    [
      up,
    ],
  );
  const response = useBondsBondTransformApi(getOblEndDateWithPerpetualDifference);
  return response;
};

export const useBondsBondSAccOblEndDateDifference = (props: {
  withParentheses?: boolean,
}) => {
  const {
    withParentheses = false,
  } = props;
  const getOblEndDateDifference = useCallback(makeGetOblEndDateDifference(withParentheses), [withParentheses]);
  const response = useBondsBondTransformApi(getOblEndDateDifference);
  return response;
};
