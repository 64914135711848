/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { icons, IconName } from '../../Icons';

const arrayListIconStyle: ouiStyle.InStyle = {
  backgroundColor: ouiStyle.Constants.Colors.inverted,
  color: ouiStyle.Constants.Colors.primary,
  borderRadius: 'full',
  overflow: 'hidden',
  borderStyle: 'solid',
  borderWidth: 'simple',
  borderColor: ouiStyle.Constants.Colors.primary,
  lines: 'full',
  widthLines: 'full',
  padding: 'simple',
  icon: true,
  display: ouiStyle.Constants.DisplayProp.inlineBlock,
};

const arrayListIconCss = ouiStyle.makeCss(arrayListIconStyle);

export const ArrayListIcon = (props: { iconName: IconName }) => {
  const {
    iconName,
  } = props;
  const Component = icons[iconName];
  return (
    <Component
      css={arrayListIconCss}
    />
  );
};
