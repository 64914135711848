import { dataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import * as React from 'react';
import { highlightContext } from './context';

export const HighlightContextProvider = (props: React.PropsWithChildren<{
  highlight?: dataStructure.accountType.listType,
}>) => {
  const {
    highlight,
    children,
  } = props;
  return (
    <highlightContext.Provider value={highlight}>
      {children}
    </highlightContext.Provider>
  );
};
