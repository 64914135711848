import { BooleanSearchAndFilterBondParams } from './type';

export const booleanSearchAndFilterBondParams: BooleanSearchAndFilterBondParams[] = [
  'eur',
  'usd',
  'foreignCurrencies',
  'S_MARKET.SMALL_QT_MIN',
  'perpetual',
  'lessThanFiveYears',
  'S_ACC.IS_GREEN',
  'S_MARKET.PRICE_CLOSE_LOW',
  'S_MARKET.YIELD',
  'CPS_HIS.LOW_ACCRUED',
  'RATING_SP.INVESTMENT_GRADE',
];
