import { compareAsc } from 'date-fns';
import { AccountTwrrs } from '../../type';
import { getTAccTwrrPDate } from './utils/getDate';
import { useAccountTwrrsTransformApi } from '../api';

const transformFunction = (data: AccountTwrrs[] | null | undefined) => {
  // We need to exclude the first data point to calculate the cumulative performance
  // because it is only shown for information as a "start value" on the graph.
  const result = data ? data
    .sort((a, b) => compareAsc(getTAccTwrrPDate(a), getTAccTwrrPDate(b)))
    .slice(1)
    .reduce((acc, el) => (1 + acc) * (1 + el['T_ACC_TWRR_P.RDT'] / 100) - 1, 0) : 0;
  return result;
};

export const useAccountTwrrCumulativePerformance = () => {
  const result = useAccountTwrrsTransformApi({
    transformFunction,
  });
  return result;
};
