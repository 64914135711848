import * as React from 'react';
import { onChangeContext } from './context';
import { useEnhanceHandler, FnMergeOrder } from '../../hooks';
import { useCreateOnChange } from './hooks';
import { OnChangeContextType } from './type';
import { useFieldContent, useChecked } from '../../../Inputs';

export function OnChangeSetContextProvider(
  props: React.PropsWithChildren<{
    onChange: OnChangeContextType,
  }>,
) {
  const { onChange, children } = props;
  return (
    <onChangeContext.Provider value={onChange}>
      {children}
    </onChangeContext.Provider>
  );
}

export function OnChangeUnsetContextProvider(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  return (
    <onChangeContext.Provider value={undefined}>
      {children}
    </onChangeContext.Provider>
  );
}

export function OnChangeEnhanceContextProvider(
  props: React.PropsWithChildren<{
    onChange: OnChangeContextType,
    order?: FnMergeOrder,
  }>,
) {
  const {
    onChange: onChangeProps,
    order = FnMergeOrder.before,
    children,
  } = props;
  const contextonChange = React.useContext(onChangeContext);
  const onChange = useEnhanceHandler(contextonChange, onChangeProps, order);
  return (
    <onChangeContext.Provider value={onChange}>
      {children}
    </onChangeContext.Provider>
  );
}

export function OnChangeDefaultHandlerSetContext(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  const onChange = useCreateOnChange();
  return (
    <OnChangeSetContextProvider onChange={onChange}>
      {children}
    </OnChangeSetContextProvider>
  );
}

export function OnChangeDefaultHandlerEnhanceContext(
  props: React.PropsWithChildren<{ order?: FnMergeOrder }>,
) {
  const { order = FnMergeOrder.before, children } = props;
  const onChange = useCreateOnChange();
  return (
    <OnChangeEnhanceContextProvider onChange={onChange} order={order}>
      {children}
    </OnChangeEnhanceContextProvider>
  );
}

export const useCreateOnNumberChange = () => {
  const fieldContent = useFieldContent();
  const checked = useChecked();
  const onChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked && !checked) {
        fieldContent.set(parseInt(e.target.value, 10));
      }
    },
    [fieldContent, checked],
  );
  return onChange;
};

export function OnChangeNumberHandlerEnhanceContext(
  props: React.PropsWithChildren<{ order?: FnMergeOrder }>,
) {
  const { order = FnMergeOrder.before, children } = props;
  const onChange = useCreateOnNumberChange();
  return (
    <OnChangeEnhanceContextProvider onChange={onChange} order={order}>
      {children}
    </OnChangeEnhanceContextProvider>
  );
}
