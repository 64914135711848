import toPath from 'lodash.topath';
import { init } from 'ramda';
import { addMethod, mixed } from 'yup';

const getCastedDeps = (context: { at: boolean, validator, rootValue: Record<string, unknown> }, deps: string[], depsResults: any[]) => {
  const { validator, rootValue, at } = context || {};
  const value = depsResults[depsResults.length - 2];
  const schema = depsResults[depsResults.length - 1];
  const { path } = schema;
  const pathArray = toPath(path);
  return at
    ? [
      ...deps.map((dep, index) => {
        if (dep.startsWith('$')) {
          return depsResults[index];
        }
        try {
          return validator.validateSyncAt(
            [...init(pathArray), dep].join('.'),
            rootValue,
            { context },
          );
        } catch (err) {
          return depsResults[index];
        }
      }),
      value,
      schema,
    ]
    : depsResults;
};

export const addWhenAlwaysCast = () => addMethod(mixed, 'whenAlwaysCast', function whenAlwaysCast(deps, fn) {
  return this.when(['$', ...deps], (context, ...depsResults) => {
    const castedDeps = getCastedDeps(context, deps, depsResults);
    return fn(...castedDeps);
  });
});

addWhenAlwaysCast();

export const makeWhenAlwaysCast = (deps: string[], fn: (...args) => any, validator?: any) => (validator ?? mixed()).whenAlwaysCast(deps, fn);
