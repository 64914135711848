import * as React from 'react';
import { GetAccountsPositionsHistoryParams } from '@goldwasserexchange/actor/rest-services';
import { useAccountPositionsHistoryDefaultParams } from '../hooks';
import { accountsPositionsHistoryParamsContext } from '../context';

export const AccountsPositionHistoryParamsProvider = (props: React.PropsWithChildren<{
  params: Partial<GetAccountsPositionsHistoryParams>,
}>) => {
  const {
    params,
    children,
  } = props;
  const value = useAccountPositionsHistoryDefaultParams(params);
  return (
    <accountsPositionsHistoryParamsContext.Provider
      value={value}
    >
      {children}
    </accountsPositionsHistoryParamsContext.Provider>
  );
};
