import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { scrollableBaseTopModels } from '../../OUI/Scrollable';
import {
  ScrollableContainerDom,
  ScrollableListContainerDom,
  ScrollableScrollButtonContainerDom,
  ScrollableScrollButtonIconDom,
  ScrollableWrapperDom,
} from './components';

export const scrollableDomTopModels = {
  ...scrollableBaseTopModels,
  ScrollableWrapper: (
    <TopModel name="ScrollableWrapper">
      {ScrollableWrapperDom}
    </TopModel>
  ),
  ScrollableContainer: (
    <TopModel name="ScrollableContainer">
      {ScrollableContainerDom}
    </TopModel>
  ),
  ScrollableListContainer: (
    <TopModel name="ScrollableListContainer">
      {ScrollableListContainerDom}
    </TopModel>
  ),
  ScrollableScrollButtonContainer: (
    <TopModel name="ScrollableScrollButtonContainer">
      {ScrollableScrollButtonContainerDom}
    </TopModel>
  ),
  ScrollableScrollButtonIcon: (
    <TopModel name="ScrollableScrollButtonIcon">
      {ScrollableScrollButtonIconDom}
    </TopModel>
  ),
};
