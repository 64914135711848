import * as React from 'react';
import { AccountType, dataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import { mobileSelectedAccountTypeContext } from './context';

export const MobileSelectedAccountTypeContextProvider = (props: React.PropsWithChildren<{
  initialValue?: AccountType,
}>) => {
  const {
    initialValue,
    children,
  } = props;
  const mobileSelectedAccountTypeState = React.useState(initialValue ?? dataStructure.accountType.list[0]);
  return (
    <mobileSelectedAccountTypeContext.Provider value={mobileSelectedAccountTypeState}>
      {children}
    </mobileSelectedAccountTypeContext.Provider>
  );
};
