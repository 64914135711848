import { GetBondsFavoritesParams, GetBondsListParams } from '@goldwasserexchange/actor/rest-services';

export type SearchAndFilterBondParams = {
  'SEARCH_TERM': NonNullable<GetBondsListParams['SEARCH_TERM'] | GetBondsFavoritesParams['SEARCH_TERM']>,
  'S_MARKET.YIELD': GetBondsListParams['S_MARKET.YIELD'] | GetBondsFavoritesParams['S_MARKET.YIELD'],
  'S_MARKET.SMALL_QT_MIN': NonNullable<GetBondsListParams['S_MARKET.SMALL_QT_MIN'] | GetBondsFavoritesParams['S_MARKET.SMALL_QT_MIN']>,
  'CPS_HIS.LOW_ACCRUED': NonNullable<GetBondsListParams['CPS_HIS.LOW_ACCRUED'] | GetBondsFavoritesParams['CPS_HIS.LOW_ACCRUED']>,
  'S_MARKET.PRICE_CLOSE_LOW': NonNullable<GetBondsListParams['S_MARKET.PRICE_CLOSE_LOW'] | GetBondsFavoritesParams['S_MARKET.PRICE_CLOSE_LOW']>,
  'S_ACC.MATURITY': NonNullable<GetBondsListParams['S_ACC.MATURITY'] | GetBondsFavoritesParams['S_ACC.MATURITY']> | undefined,
  'RATING_SP.INVESTMENT_GRADE': NonNullable<GetBondsListParams['RATING_SP.INVESTMENT_GRADE'] | GetBondsFavoritesParams['RATING_SP.INVESTMENT_GRADE']>,
  'C_CURRENCY.CODE': NonNullable<GetBondsListParams['C_CURRENCY.CODE'] | GetBondsFavoritesParams['C_CURRENCY.CODE']> | undefined,
  'S_ACC.IS_GREEN': NonNullable<GetBondsListParams['S_ACC.IS_GREEN']>,
  page: number,
}

export const searchAndFilterBondParamsKeys: string[] = [
  'SEARCH_TERM',
  'S_MARKET.YIELD',
  'S_MARKET.SMALL_QT_MIN',
  'CPS_HIS.LOW_ACCRUED',
  'S_MARKET.PRICE_CLOSE_LOW',
  'S_ACC.MATURITY',
  'RATING_SP.INVESTMENT_GRADE',
  'C_CURRENCY.CODE',
  'S_ACC.IS_GREEN',
  'page',
];
