import { dataStructure, AccountType } from '@goldwasserexchange/oblis-frontend-utils';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { Trans } from '@lingui/macro';
import * as React from 'react';
import ouiBase from '@goldwasserexchange/oui';
import { noIcon } from '../components/yesNoIcons';

export const MANAGEMENT_FEE = 'managmentFee';

const managmentFeesLabelOrIcons: Record<dataStructure.accountType.listType, { type: 'label', label: any } | { type: 'icon', icon: JSX.Element }> = {
  [AccountType.RTO]: {
    icon: noIcon,
    type: 'icon',
  },
  [AccountType.CONSEIL]: {
    icon: noIcon,
    type: 'icon',
  },
  [AccountType.GESTION]: {
    label: (
      <Trans>1% par an</Trans>
    ),
    type: 'label',
  },
};

export const useManagmentFeesLabel = (accountType: dataStructure.accountType.listType) => {
  const accountLabelOrIcons = managmentFeesLabelOrIcons[accountType];
  const label = 'label' in accountLabelOrIcons ? accountLabelOrIcons.label : (<Trans>N/A</Trans>);
  const translated = label;
  return translated;
};

export const ManagmentFeesLabel = (props: { accountType: dataStructure.accountType.listType }) => {
  const { accountType } = props;
  const translated = useManagmentFeesLabel(accountType);
  return translated;
};

export const ManagmentFeesLabelOrIcon = (props: { accountType: dataStructure.accountType.listType }) => {
  const { accountType } = props;
  const accountLabelOrIcons = managmentFeesLabelOrIcons[accountType];
  if (accountLabelOrIcons.type === 'label') {
    return <ManagmentFeesLabel accountType={accountType} />;
  }
  if ('icon' in accountLabelOrIcons) {
    return accountLabelOrIcons.icon;
  }
  return null;
};

export const ManagmentFeesLabelOnPrimaryDimension = () => {
  const accountType = ouiBase.utils.dimensions.useCurrentPrimaryDimension() as dataStructure.accountType.listType;
  return (
    <ManagmentFeesLabelOrIcon accountType={accountType} />
  );
};

const managementFeesLabel = (
  <Trans>
    Commission de gestion
  </Trans>

);

export const managmentFeesTopModels = (
  <>
    <TopModel name={`TableSecondaryTitleCellContent-${MANAGEMENT_FEE}`}>
      {managementFeesLabel}
    </TopModel>
    <TopModel name={`TableBodyRowCellContent--${MANAGEMENT_FEE}`}>
      {ManagmentFeesLabelOnPrimaryDimension}
    </TopModel>
  </>
);
