import { makeName } from '../../../../../makeName';
var hasSameProfessionAndResidencyCountryPath = 'HasSameProfessionAndResidencyCountry';
var idCCountryProfessionPath = 'ID_C_COUNTRY_ACTI';
var addressPath = 'address';
var idCCountryPath = 'ID_C_COUNTRY';
export var pathLogic = {
    name: makeName('<parentName>', hasSameProfessionAndResidencyCountryPath),
    is: false,
    alternatives: {
        truthy: {
            result: idCCountryProfessionPath,
        },
        falsy: {
            result: "".concat(addressPath, ".").concat(idCCountryPath),
        },
    },
};
