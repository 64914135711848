var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
import * as React from 'react';
import { i18n } from '@goldwasserexchange/i18n';
import ouiBase from '@goldwasserexchange/oui';
import { DurabilityIncidence, DurabilityIncidenceProportion, DurabilityIncidenceType, DurabilityPreferences, DurabilitySFDR, DurabilitySFDRDurableOnly, DurabilitySFDRDurableProportion, DurabilitySFDRSocialEnvironmentalOnly, DurabilitySFDRSocialEnvironmentalProportion, DurabilityTaxonomy, DurabilityTaxonomyAlignment, DurabilityTaxonomyProportion, } from '../../types';
import * as fields from './fields';
var labelsData = {
    preferences: {
        label: {
            defaultLabel: function () { return i18n._('Avez-vous des préférences en matière de durabilité ?'); },
        },
        values: (_a = {},
            _a[DurabilityPreferences.FALSE] = function () { return i18n._('Non, je n’ai pas de préférence'); },
            _a[DurabilityPreferences.NON_SPECIFIC_TRUE] = function () { return i18n._('Oui, mais je n’ai pas de préférence spécifique'); },
            _a[DurabilityPreferences.SPECIFIC_TRUE] = function () { return i18n._('Oui, j’ai des préférences spécifiques'); },
            _a),
    },
    taxonomy: {
        label: {
            defaultLabel: function () { return i18n._('Avez-vous des préférences pour des investissements durables sur le plan environnemental au sens de la taxonomie ?'); },
        },
        values: (_b = {},
            _b[DurabilityTaxonomy.YES] = function () { return i18n._('Oui'); },
            _b[DurabilityTaxonomy.NO] = function () { return i18n._('Non'); },
            _b),
    },
    taxonomyAlignment: {
        label: {
            defaultLabel: function () { return i18n._('Quel niveau d\'alignement avec la taxonomie exigez-vous pour vos investissements ?'); },
        },
        values: (_c = {},
            _c[DurabilityTaxonomyAlignment.FIVE_PERCENT] = function () { return i18n._('5%'); },
            _c[DurabilityTaxonomyAlignment.TEN_PERCENT] = function () { return i18n._('10%'); },
            _c[DurabilityTaxonomyAlignment.FIFTEEN_PERCENT] = function () { return i18n._('15%'); },
            _c[DurabilityTaxonomyAlignment.TWENTY_PERCENT] = function () { return i18n._('20%'); },
            _c),
    },
    taxonomyProportion: {
        label: {
            defaultLabel: function () { return i18n._('Quelle portion de votre compte devra respecter cette contrainte d\'alignement avec la taxonomie ?'); },
        },
        values: (_d = {},
            _d[DurabilityTaxonomyProportion.MIN_TWENTY_FIVE_PERCENT] = function () { return i18n._('Minimum 25%'); },
            _d[DurabilityTaxonomyProportion.MIN_FIFTY_PERCENT] = function () { return i18n._('Minimum 50%'); },
            _d[DurabilityTaxonomyProportion.MIN_SEVENTY_FIVE_PERCENT] = function () { return i18n._('Minimum 75%'); },
            _d[DurabilityTaxonomyProportion.FULL] = function () { return i18n._('Minimum 100%'); },
            _d),
    },
    sfdr: {
        label: {
            defaultLabel: function () { return i18n._('Avez-vous des préférences pour des investissements durables au sens du SFDR ?'); },
        },
        values: (_e = {},
            _e[DurabilitySFDR.YES] = function () { return i18n._('Oui'); },
            _e[DurabilitySFDR.NO] = function () { return i18n._('Non'); },
            _e),
    },
    sfdrDurableProportion: {
        label: {
            defaultLabel: function () { return i18n._('Quelle portion de votre compte devra être investie dans instruments financiers ayant un objectif d’investissement durable ?'); },
        },
        values: (_f = {},
            _f[DurabilitySFDRDurableProportion.MIN_TWENTY_FIVE_PERCENT] = function () { return i18n._('Minimum 25%'); },
            _f[DurabilitySFDRDurableProportion.MIN_FIFTY_PERCENT] = function () { return i18n._('Minimum 50%'); },
            _f[DurabilitySFDRDurableProportion.MIN_SEVENTY_FIVE_PERCENT] = function () { return i18n._('Minimum 75%'); },
            _f[DurabilitySFDRDurableProportion.FULL] = function () { return i18n._('Minimum 100%'); },
            _f),
    },
    sfdrSocialEnvironmentalProportion: {
        label: {
            defaultLabel: function () { return i18n._('Quelle portion de votre compte devra être investie dans instruments financiers qui intègrent des caractéristiques environnementales et/ou sociales ?'); },
        },
        values: (_g = {},
            _g[DurabilitySFDRSocialEnvironmentalProportion.MIN_TWENTY_FIVE_PERCENT] = function () { return i18n._('Minimum 25%'); },
            _g[DurabilitySFDRSocialEnvironmentalProportion.MIN_FIFTY_PERCENT] = function () { return i18n._('Minimum 50%'); },
            _g[DurabilitySFDRSocialEnvironmentalProportion.MIN_SEVENTY_FIVE_PERCENT] = function () { return i18n._('Minimum 75%'); },
            _g[DurabilitySFDRSocialEnvironmentalProportion.FULL] = function () { return i18n._('Minimum 100%'); },
            _g),
    },
    sfdrDurableOnly: {
        label: {
            defaultLabel: function () { return i18n._('Je ne souhaite investir que dans des instruments financiers ayant un objectif d’investissement durable'); },
        },
        values: (_h = {},
            _h[DurabilitySFDRDurableOnly.YES] = function () { return i18n._('Oui'); },
            _h[DurabilitySFDRDurableOnly.NO] = function () { return i18n._('Non'); },
            _h),
    },
    sfdrSocialEnvironmentalOnly: {
        label: {
            defaultLabel: function () { return i18n._('Je ne souhaite investir que dans des instruments financiers ayant un objectif d’investissement durable et/ou dans des instruments financiers qui intègrent des caractéristiques environnementales et/ou sociales'); },
        },
        values: (_j = {},
            _j[DurabilitySFDRSocialEnvironmentalOnly.YES] = function () { return i18n._('Oui'); },
            _j[DurabilitySFDRSocialEnvironmentalOnly.NO] = function () { return i18n._('Non'); },
            _j),
    },
    incidence: {
        label: {
            defaultLabel: function () { return i18n._('Avez-vous des préférences pour des investissements qui prennent en compte les principales incidences négatives ?'); },
        },
        values: (_k = {},
            _k[DurabilityIncidence.YES] = function () { return i18n._('Oui'); },
            _k[DurabilityIncidence.NO] = function () { return i18n._('Non'); },
            _k),
    },
    incidenceType: {
        label: {
            defaultLabel: function () { return i18n._('Quelle sont les incidences négatives à prendre en compte pour vos investissements dans des actions de sociétés ?'); },
        },
        values: (_l = {},
            _l[DurabilityIncidenceType.GREENHOUSE_GAS] = function () { return i18n._('Emission de gaz à effet de serre'); },
            _l[DurabilityIncidenceType.BIODIVERSITY] = function () { return i18n._('Biodiversité'); },
            _l[DurabilityIncidenceType.WATER] = function () { return i18n._('Eau'); },
            _l[DurabilityIncidenceType.TRASH] = function () { return i18n._('Déchets'); },
            _l[DurabilityIncidenceType.SOCIAL_AND_PERSON] = function () { return i18n._('Questions sociales et de personne'); },
            _l),
    },
    incidenceProportion: {
        label: {
            defaultLabel: function () { return i18n._('Quelle portion de votre compte devra être investie dans instruments financiers prenant en compte les incidences négatives séléctionnées ?'); },
        },
        values: (_m = {},
            _m[DurabilityIncidenceProportion.MIN_TWENTY_FIVE_PERCENT] = function () { return i18n._('Minimum 25%'); },
            _m[DurabilityIncidenceProportion.MIN_FIFTY_PERCENT] = function () { return i18n._('Minimum 50%'); },
            _m[DurabilityIncidenceProportion.MIN_SEVENTY_FIVE_PERCENT] = function () { return i18n._('Minimum 75%'); },
            _m[DurabilityIncidenceProportion.FULL] = function () { return i18n._('Minimum 100%'); },
            _m),
    },
};
export var useLabelData = function (question) {
    var labelsQuestionData = labelsData[question];
    return labelsQuestionData;
};
var durabilityPath = 'durability';
export var useLabel = function (question) {
    var logic = fields.logic.label.map[question];
    var labelType = ouiBase.utils.hookstate.useFormFilterLogic(logic, durabilityPath);
    var labelData = useLabelData(question);
    var labels = labelData.label;
    return labels[labelType]();
};
export var Label = function (props) {
    var question = props.question;
    var label = useLabel(question);
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return React.createElement(React.Fragment, null, label);
};
export var useValueLabel = function (question, primaryKey) {
    var labelData = useLabelData(question);
    var values = labelData.values;
    return values[primaryKey]();
};
export var ValueLabel = function (props) {
    var question = props.question, primaryKey = props.primaryKey;
    var label = useValueLabel(question, primaryKey);
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return React.createElement(React.Fragment, null, label);
};
