import * as HORIZON from './HORIZON';
import * as RISK_LEVEL from './RISK_LEVEL';
import * as MAX_RECOVERY_PERIOD from './MAX_RECOVERY_PERIOD';
import * as FOREIGN_CURRENCIES from './FOREIGN_CURRENCIES';
import * as INVESTMENT_GOAL from './INVESTMENT_GOAL';
import { InvestmentGoalsForeignCurrencies, InvestmentGoalsHorizon, InvestmentGoalsInvestmentGoals, InvestmentGoalsMaxRecoveryPeriod, InvestmentGoalsRiskLevel, } from '../../../types';
export var lists = {
    horizon: HORIZON.list,
    riskLevel: RISK_LEVEL.list,
    maxRecoveryPeriod: MAX_RECOVERY_PERIOD.list,
    foreignCurrencies: FOREIGN_CURRENCIES.list,
    investmentGoal: INVESTMENT_GOAL.list,
};
export var listEnums = {
    horizon: InvestmentGoalsHorizon,
    riskLevel: InvestmentGoalsRiskLevel,
    maxRecoveryPeriod: InvestmentGoalsMaxRecoveryPeriod,
    foreignCurrencies: InvestmentGoalsForeignCurrencies,
    investmentGoal: InvestmentGoalsInvestmentGoals,
};
