export var Table;
(function (Table) {
    Table["financialSituation"] = "financialSituation";
    Table["investmentGoals"] = "investmentGoals";
    Table["durability"] = "durability";
})(Table || (Table = {}));
export var pathTableMap = {
    'onboarding.financialSituation': Table.financialSituation,
    'onboarding.investmentGoals': Table.investmentGoals,
    'onboarding.durability': Table.durability,
};
