import * as React from 'react';
import { Ramp } from '@goldwasserexchange/react-topmodel';
import { ToolbarDropDownListWrapper } from './list';
import { ToolbarDropDownListElement } from './element';
import { useToolbarDropdownList } from '../context';

export const ToolbarDropDownList = () => {
  const list = useToolbarDropdownList();
  return (
    <Ramp rampName="ToolbarDropDownListContainer">
      <ToolbarDropDownListWrapper>
        {list.map((listElement) => (
          <ToolbarDropDownListElement
            key={listElement}
          >
            <Ramp
              rampName="ToolbarDropDownListElementLink"
              rampFallbacks={[
                `ToolbarDropDownListElementLink-${listElement}`,
              ]}
            >
              <Ramp
                rampName="ToolbarDropDownListElementContent"
                rampFallbacks={[
                  `ToolbarDropDownListElementContent-${listElement}`,
                ]}
              />
            </Ramp>
          </ToolbarDropDownListElement>
        ))}
      </ToolbarDropDownListWrapper>
    </Ramp>
  );
};
