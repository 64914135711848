import * as React from 'react';
import { StateFilter } from '../../../../../Machine';
import { Suspended } from '../../../../../Suspended';
import { LazyProductSelection } from '../../ProductSelection';
import { KnowledgeAndExperienceQuestionnaire } from '../../questionnaires/KnowledgeAndExperienceQuestionnaire';
import { LazyKnowledgeAndExperienceSummary } from './KnowledgeAndExperienceSummary';

export const KYCShared = () => (
  <>
    <StateFilter is=".productSelection">
      <Suspended>
        <LazyProductSelection />
      </Suspended>
    </StateFilter>
    <StateFilter is=".knowledgeQuestionnaire">
      <KnowledgeAndExperienceQuestionnaire />
    </StateFilter>
    <StateFilter is=".confirmation">
      <Suspended>
        <LazyKnowledgeAndExperienceSummary />
      </Suspended>
    </StateFilter>
  </>
);
