import { dataStructure, AccountType } from '@goldwasserexchange/oblis-frontend-utils';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { Trans } from '@lingui/macro';
import * as React from 'react';
import ouiBase from '@goldwasserexchange/oui';

export const MINIMAL_INVESTMENT = 'minimalInvestment';

const minimalInvestmentsLabels = {
  [AccountType.RTO]: {
    label: (
      <Trans>
        10.000 EUR
        <br />
        par transaction
      </Trans>
    ),
  },
  [AccountType.CONSEIL]: {
    label: (
      <Trans>
        10.000 EUR
        <br />
        par transaction
      </Trans>
    ),
  },
  [AccountType.GESTION]: {
    label: (
      <Trans>
        200.000 EUR/USD
        <br />
        par compte
      </Trans>
    ),
  },
};

export const MinimalInvestmentLabel = (props: { accountType: dataStructure.accountType.listType }) => {
  const { accountType } = props;
  return minimalInvestmentsLabels[accountType].label;
};

export const MinimalInvestmentLabelOnPrimaryDimension = () => {
  const accountType = ouiBase.utils.dimensions.useCurrentPrimaryDimension() as dataStructure.accountType.listType;
  return (
    <MinimalInvestmentLabel accountType={accountType} />
  );
};

const minmalInvestmentLabel = (
  <Trans>
    Montant minimum
  </Trans>
);

export const minimalInvestmentTopModels = (
  <>
    <TopModel name={`TableSecondaryTitleCellContent-${MINIMAL_INVESTMENT}`}>
      {minmalInvestmentLabel}
    </TopModel>
    <TopModel name={`TableBodyRowCellContent--${MINIMAL_INVESTMENT}`}>
      {MinimalInvestmentLabelOnPrimaryDimension}
    </TopModel>
  </>
);
