import React from 'react';
import { Trans } from '@lingui/macro';
import ouiStyle from '@goldwasserexchange/oui-style';
import ouiDom from '@goldwasserexchange/oui-dom';
import {
  dataStructure, UncastedDataStructure, UncastedPhysicalTAdd, PersonType, UncastedOnboarding, TAddType,
} from '@goldwasserexchange/oblis-frontend-utils';
import ouiBase from '@goldwasserexchange/oui';

export const tAddTProcTypeLabel = <Trans>Type de procuration</Trans>;

export const tAddTProcTypeInfoPopper = (
  <Trans>
    <div>
      <p>
        <ouiDom.TextSnippet.TextSnippet
          fontWeight={ouiStyle.Constants.FontWeightProp.bold}
        >
          La procuration générale&#32;
        </ouiDom.TextSnippet.TextSnippet>
        confère au mandataire le pouvoir d'effectuer des apports financiers et d'en disposer librement; de déposer et retirer des titres, d'encaisser des coupons, d'effectuer toute opération sur titres et liquidités ; de retirer les titres achetés et toucher le produit des titres vendus, d'effectuer tout acte de gestion ou de disposition, de contracter tous engagements, conventions ou compromis de donner toutes garanties personnelles ou réelles, de signer valablement tous contrats, chèques, quittances, et autres documents, de collecter des informations relatives au compte par voie téléphonique, e-mail, télécopie ou autre et ce, tant pour des opérations réalisées avant qu'après l'entrée en vigueur de la procuration
      </p>
      <p>
        <ouiDom.TextSnippet.TextSnippet
          fontWeight={ouiStyle.Constants.FontWeightProp.bold}
        >
          La procuration de gestion&#32;
        </ouiDom.TextSnippet.TextSnippet>
        confère au mandataire le pouvoir d'acheter, de vendre ou de souscrire des titres, de prendre des décisions relatives à d'éventuels « corporate actions » ainsi que de collecter des informations relatives au compte par voie téléphonique, e-mail, télécopie ou autre et ce, tant pour des opérations réalisées avant qu'après l'entrée en vigueur de la procuration.
      </p>
      <p>
        <ouiDom.TextSnippet.TextSnippet
          fontWeight={ouiStyle.Constants.FontWeightProp.bold}
        >
          La procuration de collecte d'information&#32;
        </ouiDom.TextSnippet.TextSnippet>
        confère au mandataire le pouvoir collecter des informations relatives au compte par voie téléphonique, télécopie ou autre et ce, tant pour des opérations réalisées avant qu'après l'entrée en vigueur de la procuration. Le mandataire ne pourra accomplir aucun acte de gestion ou de disposition.
      </p>
      <ouiDom.P.P
        fontWeight={ouiStyle.Constants.FontWeightProp.bold}
      >
        Dans l'hypothèse où plusieurs procurations seraient établies, il est précisé que chaque mandataire pourra agir individuellement dans la limite des pouvoirs qui lui ont été accordés.
      </ouiDom.P.P>
    </div>
  </Trans>
);

const onboardingPath: keyof Pick<UncastedDataStructure, 'onboarding'> = 'onboarding';

const personType: keyof Pick<UncastedOnboarding, 'personType'> = 'personType';

const current: keyof Pick<UncastedPhysicalTAdd, 'current'> = 'current';

const tAddType: keyof Pick<UncastedPhysicalTAdd, 'T_ADD_type'> = 'T_ADD_type';

export const tAddIdTProcTypeValidatorLogic: ouiBase.utils.hookstate.FormValueFilterLogic<'requiredNumber' | 'nilCast'> = {
  name: `${onboardingPath}.${personType}`,
  is: PersonType.FOR_MINORS,
  options: {
    isNegative: true,
  },
  alternatives: {
    truthy: dataStructure.T_ADD.fields.ID_T_PROC_TYPE.logic.helpers.makeShowIdTProcTypeLogic({
      truthy: {
        result: 'requiredNumber',
      },
      falsy: {
        result: 'nilCast',
      },
    }),
    falsy: {
      name: `<parentName>.${tAddType}`,
      is: TAddType.PROCURATION,
      options: {
        isNegative: true,
      },
      alternatives: {
        truthy: {
          name: `<parentName>.${current}`,
          is: true,
          alternatives: {
            truthy: {
              result: 'requiredNumber',
            },
            falsy: {
              result: 'nilCast',
            },
          },
        },
        falsy: {
          result: 'requiredNumber',
        },
      },
    },
  },
};

export const procFilter = (props: {ids: string[], isTitulaire: boolean, isCurrentUser: boolean, isMinor: boolean | null}) => {
  const {
    ids,
    isTitulaire,
    isCurrentUser,
    isMinor,
  } = props;
  return ids.filter((id) => {
    if (isMinor) {
      return false;
    }
    if (isTitulaire) {
      return id === '3';
    }
    if (isCurrentUser) {
      return id !== '84';
    }
    return true;
  });
};
