import { MakeAppBarGuardTypeFromMatcher, MakeAppBarGuardObjectTypeFromMatcher } from '../type';

export const name = 'isMqDown';

export const makefn: MakeAppBarGuardTypeFromMatcher = (matcher) => () => !matcher.matches;

export const makeGuard: MakeAppBarGuardObjectTypeFromMatcher = (matcher) => ({
  [name]: makefn(matcher),
});

export default makeGuard;
