import * as React from 'react';
import { OnClickSetContextProvider } from '../../onClick';
import { OnMouseEnterSetContextProvider } from '../../onMouseEnter';
import { OnMouseLeaveSetContextProvider } from '../../onMouseLeave';
import { popperOpenContext } from './context';
import { useSetPopperToClosedHandler, useSetPopperToOpenHandler, useTogglePopperOpen } from './hooks';

export function PopperOpenContextProvider(props: React.PropsWithChildren<{}>) {
  const { children } = props;
  const popperOpenState = React.useState(false);
  return (
    <popperOpenContext.Provider value={popperOpenState}>
      {children}
    </popperOpenContext.Provider>
  );
}

export function PopperOpenClickToggleContextProvider(props: React.PropsWithChildren<{}>) {
  const { children } = props;
  const togglePopper = useTogglePopperOpen();
  return (
    <OnClickSetContextProvider onClick={togglePopper}>
      {children}
    </OnClickSetContextProvider>
  );
}

export function PopperOpenOnMouseEnterContextProvider(props: React.PropsWithChildren<{}>) {
  const { children } = props;
  const setPopperToOpen = useSetPopperToOpenHandler();
  return (
    <OnMouseEnterSetContextProvider onMouseEnter={setPopperToOpen}>
      {children}
    </OnMouseEnterSetContextProvider>
  );
}

export function PopperClosedOnMouseLeaveContextProvider(props: React.PropsWithChildren<{}>) {
  const { children } = props;
  const setPopperToClosed = useSetPopperToClosedHandler();
  return (
    <OnMouseLeaveSetContextProvider onMouseLeave={setPopperToClosed}>
      {children}
    </OnMouseLeaveSetContextProvider>
  );
}

export function PopperOpenInteractionContext(props: React.PropsWithChildren<{}>) {
  const { children } = props;
  return (
    <PopperOpenClickToggleContextProvider>
      <PopperOpenOnMouseEnterContextProvider>
        <PopperClosedOnMouseLeaveContextProvider>
          {children}
        </PopperClosedOnMouseLeaveContextProvider>
      </PopperOpenOnMouseEnterContextProvider>
    </PopperOpenClickToggleContextProvider>
  );
}

export function PopperOpenWithInteractionContext(props: React.PropsWithChildren<{}>) {
  const { children } = props;
  return (
    <PopperOpenContextProvider>
      <PopperOpenInteractionContext>
        {children}
      </PopperOpenInteractionContext>
    </PopperOpenContextProvider>
  );
}
