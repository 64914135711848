import { JsonLd, setJsonLd } from './jsonLd';
import { OpenGraphProps, setOpenGraphTags } from './openGraph';

export const setMetadataTags = (props: {
  head: HTMLHeadElement,
  openGraph?: OpenGraphProps,
  jsonLd?: JsonLd,
}) => {
  const {
    head,
    openGraph,
    jsonLd,
  } = props;
  setOpenGraphTags({
    head,
    openGraph,
  });
  setJsonLd({
    head,
    jsonLd,
  });
};
