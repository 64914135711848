/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
export var SanityRow = function (props) {
    var _a = props.separator, separator = _a === void 0 ? false : _a, _b = props.headerRow, headerRow = _b === void 0 ? false : _b, _c = props.last, last = _c === void 0 ? false : _c, children = props.children;
    var css = React.useMemo(function () { return ouiStyle.makeCss(!last ? {
        borderBottomColor: separator
            ? ouiStyle.Constants.Colors.accent
            : ouiStyle.Constants.Colors.fade,
        borderBottomWidth: 'simple',
        borderBottomStyle: 'solid',
    } : {
        borderBottomWidth: 'none',
        borderBottomStyle: 'none',
    }); }, [separator, last]);
    if (headerRow) {
        return (jsx("thead", null,
            jsx("tr", { css: css }, children)));
    }
    return (jsx("tr", { css: css }, children));
};
