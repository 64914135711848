import * as React from 'react';
import { useHookstate } from '@hookstate/core';
import { formDataContext } from './context';
import { getInHookState } from '../../../utils/hookstate/getInHookState';
import { useFreezeArrayOnLength } from '../../../utils/freeze/useFreezeArrayOnLength';
import { objectify } from '../../../utils/objectify';
export function useFormData() {
    var formData = React.useContext(formDataContext);
    return formData;
}
export function useFieldValueByPath(fieldPath) {
    var data = useFormData();
    if (data === null) {
        throw new Error('there should be form data if you want to use useFieldValueByPath');
    }
    var nestedValue = getInHookState(data, fieldPath);
    var state = useHookstate(nestedValue);
    return state;
}
export function useGetFieldValueByPath(fieldPath) {
    var state = useFieldValueByPath(fieldPath);
    return state.get();
}
export function useFieldValuesByPaths(fieldPaths) {
    var frozenFieldPaths = useFreezeArrayOnLength(fieldPaths, 'fieldPaths');
    var fieldValues = frozenFieldPaths.map(function (frozenFieldPath) { return useFieldValueByPath(frozenFieldPath); }); // eslint-disable-line react-hooks/rules-of-hooks
    return fieldValues;
}
export function useNamedFieldValuesByPaths(fieldPaths) {
    var frozenFieldPaths = useFreezeArrayOnLength(fieldPaths, 'fieldPaths');
    var fieldValues = Object.fromEntries(frozenFieldPaths.map(function (frozenFieldPath) { return [frozenFieldPath, useFieldValueByPath(frozenFieldPath)]; })); // eslint-disable-line react-hooks/rules-of-hooks
    return fieldValues;
}
export var replaceTaggedString = function (start, end, tagText, replacer, source) {
    if (start === void 0) { start = '<'; }
    if (end === void 0) { end = '>'; }
    return source.replace(// eslint-disable-line default-param-last
    new RegExp("".concat(start).concat(tagText).concat(end), 'g'), replacer).replace(/^\.|\.$/g, '');
};
export function replaceParentNameTag(parentName, source) {
    return (!Array.isArray(source)
        ? replaceTaggedString(undefined, undefined, 'parentName', parentName, source)
        : source.map(function (src) { return replaceTaggedString(undefined, undefined, 'parentName', parentName, src); }));
}
var makeName = function (path) { return (!Array.isArray(path) ? path : path.join('.')); };
export function useNamedFieldValuesByPathsWithParentName(parentName, fieldPaths) {
    var frozenFieldPaths = useFreezeArrayOnLength(fieldPaths, 'fieldPaths');
    var fieldValues = objectify(makeName, function (fieldPath) { return useFieldValueByPath(replaceParentNameTag(parentName, fieldPath)); }, // eslint-disable-line react-hooks/rules-of-hooks
    frozenFieldPaths);
    return fieldValues;
}
