import { createMachine, MachineConfig } from 'xstate';
import { ValidSections } from '../../../history';
import { AppBarSpringRef } from '../animation/context/context';
import * as events from './events';
import { AppBarMachineContext } from './machineContext';
import makeOptions, { actions, guards, services } from './options';
import * as states from './states';

const machineDefinition: MachineConfig<AppBarMachineContext, states.AppBarMachineStates, events.AppBarEvent> = {
  id: 'appBar',
  initial: states.initial.name,
  invoke: [{
    id: services.watchMediaQuery.name,
    src: services.watchMediaQuery.name,
  }, {
    id: services.watchScroll.name,
    src: services.watchScroll.name,
  }],
  states: {
    [states.initial.name]: {
      always: [
        {
          target: states.closed.name,
          cond: guards.isMqDown.name,
          actions: [actions.setTargetClosed.name],
        },
        {
          target: states.closed.name,
          cond: guards.shouldLogoExpand.name,
          actions: [actions.setTargetOpenExpanded.name],
        },
        {
          target: states.closed.name,
          cond: guards.isMqUp.name,
          actions: [actions.setTargetOpenShrinked.name],
        },
      ],
    },
    [states.closed.name]: {
      entry: [actions.setClosed.name],
      on: {
        [events.openEvent.eventType]: [{
          target: states.logoExpanding.name,
          actions: actions.setTargetOpenExpanded.name,
          cond: guards.shouldLogoExpand.name,
        }, {
          target: states.opening.name,
          actions: actions.setTargetOpenShrinked.name,
        }],
        [events.mediaQueryChangeEvent.MediaQueryChangeDown.eventType]: [
          {
            target: states.logoShrinking.name,
            actions: actions.setTargetClosed.name,
            cond: guards.shouldLogoShrink.name,
          },
          {
            target: `.${states.closed.states.initial.name}`,
            actions: actions.setTargetClosed.name,
          },
        ],
        [events.mediaQueryChangeEvent.MediaQueryChangeUp.eventType]: [
          {
            target: `.${states.closed.states.initial.name}`,
            actions: actions.setTargetOpenExpanded.name,
            cond: guards.shouldLogoExpand.name,
          },
          {
            target: `.${states.closed.states.initial.name}`,
            actions: actions.setTargetOpenShrinked.name,
          },
        ],
        [events.scrollEvent.ScrollDown.BellowCollapse.eventType]: [
          {
            target: states.logoShrinking.name,
            actions: [
              actions.setTargetCollapsed.name,
            ],
            cond: guards.shouldLogoShrink.name,
          },
          {
            target: states.collapsing.name,
            actions: [
              actions.setTargetCollapsed.name,
            ],
          },
        ],
        [events.scrollEvent.ScrollDown.AboveCollapse.eventType]: [
          {
            target: states.logoShrinking.name,
            actions: [
              actions.setTargetClosed.name,
            ],
            cond: guards.shouldLogoShrink.name,
          },
          {
            actions: [
              actions.setTargetClosed.name,
            ],
          },
        ],
        [events.scrollEvent.ScrollUp.AboveCollapse.eventType]: [
          {
            target: states.logoExpanding.name,
            actions: [actions.setTargetOpenExpanded.name],
            cond: guards.shouldLogoExpand.name,
          },
          {
            target: states.opening.name,
            actions: [actions.setTargetOpenShrinked.name],
            cond: guards.isMqUp.name,
          },
          {
            actions: [actions.setTargetClosed.name],
          },
        ],
        [events.scrollEvent.ScrollUp.BellowCollapse.eventType]: [
          {
            actions: [actions.setTargetClosed.name],
          },
        ],
      },
      initial: states.closed.states.initial.name,
      states: {
        [states.closed.states.initial.name]: {
          always: [
            {
              target: states.closed.states.mqUp.name,
              cond: guards.isMqUp.name,
            },
            {
              target: states.closed.states.mqDown.name,
              cond: guards.isMqDown.name,
            },
          ],
        },
        [states.closed.states.mqUp.name]: {
          invoke: [{
            id: services.animateMqUp.name,
            src: services.animateMqUp.name,
          }],
          initial: states.closed.states.mqUp.states.initial.name,
          states: {
            [states.closed.states.mqUp.states.initial.name]: {
              always: [{
                target: states.closed.states.mqUp.states.done.name,
                cond: guards.isMqAnimatedUp.name,
              },
              {
                target: states.closed.states.mqUp.states.waitForAnimation.name,
                cond: guards.isMqAnimatingUp.name,
              },
              {
                target: states.closed.states.mqUp.states.startAnimation.name,
              }],
            },
            [states.closed.states.mqUp.states.startAnimation.name]: {
              entry: [actions.setMqUp.name],
              always: [{
                target: states.closed.states.mqUp.states.waitForAnimation.name,
              }],
            },
            [states.closed.states.mqUp.states.waitForAnimation.name]: {
              on: {
                [events.mqChangedEvent.eventType]: [{
                  target: states.closed.states.mqUp.states.done.name,
                }],
              },
            },
            [states.closed.states.mqUp.states.done.name]: {
              type: 'final' as const,
            },
          },
          onDone: {
            target: states.closed.states.done.name,
          },
        },
        [states.closed.states.mqDown.name]: {
          initial: states.closed.states.mqDown.states.initial.name,
          invoke: [{
            id: services.animateMqDown.name,
            src: services.animateMqDown.name,
          }],
          states: {
            [states.closed.states.mqDown.states.initial.name]: {
              always: [{
                target: states.closed.states.mqDown.states.done.name,
                cond: guards.isMqAnimatedDown.name,
              },
              {
                target: states.closed.states.mqDown.states.waitForAnimation.name,
                cond: guards.isMqAnimatingDown.name,
              },
              {
                target: states.closed.states.mqDown.states.startAnimation.name,
              }],
            },
            [states.closed.states.mqDown.states.startAnimation.name]: {
              entry: [actions.setMqDown.name],
              always: [{
                target: states.closed.states.mqDown.states.waitForAnimation.name,
              }],
            },
            [states.closed.states.mqDown.states.waitForAnimation.name]: {
              on: {
                [events.mqChangedEvent.eventType]: [{
                  target: states.closed.states.mqDown.states.done.name,
                }],
              },
            },
            [states.closed.states.mqDown.states.done.name]: {
              type: 'final' as const,
            },
          },
          onDone: {
            target: states.closed.states.done.name,
          },
        },
        [states.closed.states.done.name]: {
          entry: [actions.resetMqAction.name],
          type: 'final' as const,
        },
      },
      onDone: [
        {
          target: states.logoExpanding.name,
          cond: guards.isTargetOpenExpanded.name,
        },
        {
          target: states.opening.name,
          cond: guards.isTargetOpenShrinked.name,
        },
        {
          target: states.collapsing.name,
          cond: guards.isTargetCollapsed.name,
        },
      ],
    },
    [states.opening.name]: {
      invoke: [{
        id: services.opening.name,
        src: services.opening.name,
      }],
      entry: [actions.setOpening.name],
      on: {
        [events.closeEvent.eventType]: [
          {
            target: states.logoShrinking.name,
            cond: guards.shouldLogoShrink.name,
            actions: [actions.setTargetClosed.name],
          },
          {
            target: states.closing.name,
            actions: [actions.setTargetClosed.name],
          },
        ],
        [events.mediaQueryChangeEvent.MediaQueryChangeDown.eventType]: [
          {
            target: states.logoShrinking.name,
            actions: actions.setTargetClosed.name,
            cond: guards.shouldLogoShrink.name,
          },
          {
            target: states.closing.name,
            actions: actions.setTargetClosed.name,
          },
        ],
        [events.mediaQueryChangeEvent.MediaQueryChangeUp.eventType]: [
          {
            target: states.closing.name,
            actions: actions.setTargetOpenExpanded.name,
            cond: guards.shouldLogoExpand.name,
          },
          {
            target: states.closing.name,
            actions: actions.setTargetOpenShrinked.name,
          },
        ],
        [events.scrollEvent.ScrollDown.BellowCollapse.eventType]: [
          {
            target: states.logoShrinking.name,
            actions: [
              actions.setTargetCollapsed.name,
            ],
            cond: guards.shouldLogoShrink.name,
          },
          {
            target: states.closing.name,
            actions: [
              actions.setTargetCollapsed.name,
            ],
          },
        ],
        [events.scrollEvent.ScrollDown.AboveCollapse.eventType]: [
          {
            target: states.logoShrinking.name,
            actions: [actions.setTargetClosed.name],
            cond: guards.shouldLogoShrink.name,
          },
          {
            target: states.closing.name,
            actions: [actions.setTargetClosed.name],
          },
        ],
        [events.scrollEvent.ScrollUp.AboveCollapse.eventType]: [
          {
            actions: [actions.setTargetOpenExpanded.name],
            cond: guards.shouldLogoExpand.name,
          },
          {
            actions: [actions.setTargetOpenShrinked.name],
          },
        ],
        [events.scrollEvent.ScrollUp.BellowCollapse.eventType]: [
          {
            actions: [actions.setTargetOpenExpanded.name],
            cond: guards.shouldLogoExpand.name,
          },
          {
            actions: [actions.setTargetOpenShrinked.name],
          },
        ],
        [events.openingFinishedEvent.eventType]: {
          target: states.open.name,
        },
        [events.linkClickEvent.eventType]: [
          {
            target: states.closing.name,
            actions: [actions.setTargetClosed.name],
            cond: guards.isMqDown.name,
          },
        ],
      },
    },
    [states.open.name]: {
      on: {
        [events.closeEvent.eventType]: {
          target: states.closing.name,
          actions: [actions.setTargetClosed.name],
        },
        [events.mediaQueryChangeEvent.MediaQueryChangeDown.eventType]: [
          {
            target: states.logoShrinking.name,
            actions: actions.setTargetClosed.name,
            cond: guards.shouldLogoShrink.name,
          },
          {
            target: states.closing.name,
            actions: actions.setTargetClosed.name,
          },
        ],
        [events.mediaQueryChangeEvent.MediaQueryChangeUp.eventType]: [
          {
            target: states.closing.name,
            actions: actions.setTargetOpenExpanded.name,
            cond: guards.shouldLogoExpand.name,
          },
          {
            target: states.closing.name,
            actions: actions.setTargetOpenShrinked.name,
          },
        ],
        [events.scrollEvent.ScrollDown.BellowCollapse.eventType]: [
          {
            target: states.logoShrinking.name,
            actions: [
              actions.setTargetCollapsed.name,
            ],
            cond: guards.shouldLogoShrink.name,
          },
          {
            target: states.closing.name,
            actions: [
              actions.setTargetCollapsed.name,
            ],
          },
        ],
        [events.scrollEvent.ScrollDown.AboveCollapse.eventType]: [
          {
            target: states.logoShrinking.name,
            actions: [actions.setTargetClosed.name],
            cond: guards.shouldLogoShrink.name,
          },
          {
            target: states.closing.name,
            actions: [actions.setTargetClosed.name],
          },
        ],
        [events.scrollEvent.ScrollUp.AboveCollapse.eventType]: [
          {
            target: states.logoExpanding.name,
            actions: [actions.setTargetOpenExpanded.name],
            cond: guards.shouldLogoExpand.name,
          },
          {
            actions: [actions.setTargetOpenShrinked.name],
          },
        ],
        [events.scrollEvent.ScrollUp.BellowCollapse.eventType]: [
          {
            actions: [actions.setTargetOpenShrinked.name],
          },
        ],
        [events.linkClickEvent.eventType]: [
          {
            target: states.closing.name,
            actions: [actions.setTargetClosed.name],
            cond: guards.isMqDown.name,
          },
        ],
      },
    },
    [states.closing.name]: {
      invoke: [{
        id: services.closing.name,
        src: services.closing.name,
      }],
      entry: [actions.setClosing.name],
      on: {
        [events.openEvent.eventType]: [{
          target: states.logoExpanding.name,
          actions: actions.setTargetOpenExpanded.name,
          cond: guards.shouldLogoExpand.name,
        },
        {
          target: states.opening.name,
          actions: actions.setTargetOpenShrinked.name,
        }],
        [events.mediaQueryChangeEvent.MediaQueryChangeDown.eventType]: [
          {
            actions: actions.setTargetClosed.name,
          },
        ],
        [events.mediaQueryChangeEvent.MediaQueryChangeUp.eventType]: [
          {
            actions: actions.setTargetOpenExpanded.name,
            cond: guards.shouldLogoExpand.name,
          },
          {
            actions: actions.setTargetOpenShrinked.name,
          },
        ],
        [events.scrollEvent.ScrollDown.BellowCollapse.eventType]: [
          {
            actions: [
              actions.setTargetCollapsed.name,
            ],
          },
        ],
        [events.scrollEvent.ScrollDown.AboveCollapse.eventType]: [
          {
            actions: [actions.setTargetClosed.name],
          },
        ],
        [events.scrollEvent.ScrollUp.AboveCollapse.eventType]: [
          {
            target: states.logoExpanding.name,
            actions: [actions.setTargetOpenExpanded.name],
            cond: guards.shouldLogoExpand.name,
          },
          {
            target: states.opening.name,
            actions: [actions.setTargetOpenShrinked.name],
            cond: guards.isMqUp.name,
          },
          {
            actions: [actions.setTargetClosed.name],
          },
        ],
        [events.scrollEvent.ScrollUp.BellowCollapse.eventType]: [
          {
            actions: [actions.setTargetClosed.name],
          },
        ],
        [events.closingFinishedEvent.eventType]: {
          target: states.closed.name,
        },
        [events.linkClickEvent.eventType]: [
          {
            target: states.closing.name,
            actions: [actions.setTargetClosed.name],
            cond: guards.isMqDown.name,
          },
        ],
      },
    },
    [states.collapsing.name]: {
      invoke: [{
        id: services.collapsing.name,
        src: services.collapsing.name,
      }],
      entry: [actions.setCollapse.name],
      on: {
        [events.scrollEvent.ScrollDown.BellowCollapse.eventType]: [
          {
            actions: [
              actions.setTargetCollapsed.name,
            ],
          },
        ],
        [events.scrollEvent.ScrollDown.AboveCollapse.eventType]: [
          {
            target: states.logoShrinking.name,
            actions: [
              actions.setTargetClosed.name,
            ],
            cond: guards.shouldLogoShrink.name,
          },
          {
            target: states.uncollapsing.name,
            actions: [
              actions.setTargetClosed.name,
            ],
          },
        ],
        [events.scrollEvent.ScrollUp.AboveCollapse.eventType]: [
          {
            target: states.uncollapsing.name,
            actions: [actions.setTargetOpenExpanded.name],
            cond: guards.shouldLogoExpand.name,
          },
          {
            target: states.uncollapsing.name,
            actions: [actions.setTargetOpenShrinked.name],
            cond: guards.isMqUp.name,
          },
          {
            target: states.uncollapsing.name,
            actions: [actions.setTargetClosed.name],
          },
        ],
        [events.scrollEvent.ScrollUp.BellowCollapse.eventType]: [
          {
            target: states.uncollapsing.name,
            actions: [actions.setTargetClosed.name],
          },
        ],
        [events.collapsingFinished.eventType]: {
          target: states.collapsed.name,
        },
      },
    },
    [states.collapsed.name]: {
      entry: [actions.resetCollapseAction.name],
      on: {
        [events.scrollEvent.ScrollDown.BellowCollapse.eventType]: [
          {
            actions: [
              actions.setTargetCollapsed.name,
            ],
          },
        ],
        [events.scrollEvent.ScrollDown.AboveCollapse.eventType]: [
          {
            target: states.logoShrinking.name,
            actions: [
              actions.setTargetClosed.name,
            ],
            cond: guards.shouldLogoShrink.name,
          },
          {
            target: states.uncollapsing.name,
            actions: [
              actions.setTargetClosed.name,
            ],
          },
        ],
        [events.scrollEvent.ScrollUp.AboveCollapse.eventType]: [
          {
            target: states.uncollapsing.name,
            actions: [actions.setTargetOpenExpanded.name],
            cond: guards.shouldLogoExpand.name,
          },
          {
            target: states.uncollapsing.name,
            actions: [actions.setTargetOpenShrinked.name],
            cond: guards.isMqUp.name,
          },
          {
            target: states.uncollapsing.name,
            actions: [actions.setTargetClosed.name],
          },
        ],
        [events.scrollEvent.ScrollUp.BellowCollapse.eventType]: [
          {
            target: states.uncollapsing.name,
            actions: [actions.setTargetClosed.name],
          },
        ],
        [events.sectionChange.eventType]: [
          {
            target: states.uncollapsing.name,
            actions: [
              actions.setTargetOpenExpanded.name,
            ],
            cond: guards.shouldLogoExpand.name,
          },
          {
            target: states.uncollapsing.name,
            actions: [
              actions.setTargetOpenShrinked.name,
            ],
            cond: guards.shouldLogoShrink.name,
          },
        ],
      },
    },
    [states.uncollapsing.name]: {
      invoke: [{
        id: services.uncollapsing.name,
        src: services.uncollapsing.name,
      }],
      entry: [actions.setUncollapse.name],
      on: {
        [events.scrollEvent.ScrollDown.BellowCollapse.eventType]: [
          {
            target: states.logoShrinking.name,
            actions: [actions.setTargetCollapsed.name],
            cond: guards.shouldLogoShrink.name,
          },
          {
            target: states.collapsing.name,
            actions: [actions.setTargetCollapsed.name],
          },
        ],
        [events.scrollEvent.ScrollDown.AboveCollapse.eventType]: [
          {
            actions: [
              actions.setTargetClosed.name,
            ],
          },
        ],
        [events.scrollEvent.ScrollUp.AboveCollapse.eventType]: [
          {
            actions: [actions.setTargetOpenExpanded.name],
            cond: guards.shouldLogoExpand.name,
          },
          {
            actions: [actions.setTargetOpenShrinked.name],
            cond: guards.isMqUp.name,
          },
          {
            actions: [actions.setTargetClosed.name],
          },
        ],
        [events.scrollEvent.ScrollUp.BellowCollapse.eventType]: [
          {
            actions: [actions.setTargetClosed.name],
          },
        ],
        [events.collapsingFinished.eventType]: {
          target: states.closed.name,
          actions: actions.resetCollapseAction.name,
        },
      },
    },
    [states.logoExpanding.name]: {
      invoke: [{
        id: services.animateLogoExpanding.name,
        src: services.animateLogoExpanding.name,
      }],
      entry: [actions.setLogoExpanding.name],
      on: {
        [events.logoExpandingFinished.eventType]: [{
          target: states.opening.name,
          cond: guards.isTargetOpen.name,
          actions: [actions.resetLogoExpandingAction.name],
        }, {
          target: states.closing.name,
          cond: guards.isTargetClosed.name,
          actions: [actions.resetLogoExpandingAction.name],
        }, {
          target: states.closing.name,
          cond: guards.isTargetCollapsed.name,
          actions: [actions.resetLogoExpandingAction.name],
        }],
        [events.scrollEvent.ScrollDown.BellowCollapse.eventType]: [
          {
            target: states.logoShrinking.name,
            actions: [
              actions.setTargetCollapsed.name,
            ],
          },
        ],
        [events.scrollEvent.ScrollDown.AboveCollapse.eventType]: [
          {
            target: states.logoShrinking.name,
            actions: [actions.setTargetClosed.name],
          },
        ],
        [events.scrollEvent.ScrollUp.BellowCollapse.eventType]: [
          {
            actions: [actions.setTargetClosed.name],
          },
        ],
        [events.scrollEvent.ScrollUp.AboveCollapse.eventType]: [
          {
            actions: [actions.setTargetOpenExpanded.name],
            cond: guards.shouldLogoExpand.name,
          },
          {
            actions: [actions.setTargetOpenShrinked.name],
            cond: guards.shouldLogoShrink.name,
          },
          {
            actions: [actions.setTargetClosed.name],
          },
        ],
      },
    },
    [states.logoShrinking.name]: {
      invoke: [{
        id: services.animateLogoShrinking.name,
        src: services.animateLogoShrinking.name,
      }],
      entry: [actions.setLogoShrinking.name],
      on: {
        [events.logoExpandingFinished.eventType]: [{
          target: states.opening.name,
          cond: guards.isTargetOpen.name,
          actions: [actions.resetLogoExpandingAction.name],
        }, {
          target: states.closing.name,
          cond: guards.isTargetClosed.name,
          actions: [actions.resetLogoExpandingAction.name],
        }, {
          target: states.closing.name,
          cond: guards.isTargetCollapsed.name,
          actions: [actions.resetLogoExpandingAction.name],
        }],
        [events.scrollEvent.ScrollDown.BellowCollapse.eventType]: [
          {
            actions: [
              actions.setTargetCollapsed.name,
            ],
          },
        ],
        [events.scrollEvent.ScrollDown.AboveCollapse.eventType]: [
          {
            actions: [actions.setTargetClosed.name],
          },
        ],
        [events.scrollEvent.ScrollUp.BellowCollapse.eventType]: [
          {
            actions: [actions.setTargetClosed.name],
          },
        ],
        [events.scrollEvent.ScrollUp.AboveCollapse.eventType]: [
          {
            target: states.logoExpanding.name,
            actions: [actions.setTargetOpenExpanded.name],
            cond: guards.shouldLogoExpand.name,
          },
          {
            actions: [actions.setTargetOpenShrinked.name],
            cond: guards.shouldLogoShrink.name,
          },
          {
            actions: [actions.setTargetClosed.name],
          },
        ],
      },
    },
  },
  on: {
    [events.sectionChange.eventType]: [{
      target: states.logoExpanding.name,
      cond: guards.shouldLogoExpand.name,
    }, {
      target: states.logoShrinking.name,
      cond: guards.shouldLogoShrink.name,
    }],
  },
};

export const appBarMachine = (springApi: AppBarSpringRef, initialSection: ValidSections | 'not-found') => {
  const matcher = window.matchMedia('only screen and (min-width: 75rem)');
  return createMachine(machineDefinition, makeOptions(matcher, springApi)).withContext({
    logoExpanded: matcher.matches && initialSection === ValidSections.HOME ? 1 : 0,
    closed: 1,
    mqUp: 0,
    collapse: 0,
    logoExpandedAction: 0,
    openAction: 0,
    mqAction: 0,
    collapsingAction: 0,
    immediate: false,
    target: 'closed',
  });
};
