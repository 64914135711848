/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { useBondSameIssuerSAccGreen } from '../../hooks';
import { Space } from '../../../../../../../Components/Space';
import { GreenLeaf } from '../../../../../../../Components/GreenLeaf';

const style: ouiStyle.InStyleWithMediaQueries = {
  display: ouiStyle.Constants.DisplayProp.inlineFlex,
  justifyContent: ouiStyle.Constants.JustifyContentProp.center,
  alignItems: [
    ouiStyle.Constants.AlignItemsProp.start,
    undefined,
    undefined,
    ouiStyle.Constants.AlignItemsProp.center,
    undefined,
    undefined,
  ],
  transform: 'translateY(2px)',
};

const css = ouiStyle.mediaQuery(ouiStyle.makeCss(style));

export const BondSameIssuerSAccGreen = (props: {
  size: string | number,
  spaceBefore?: boolean,
  spaceAfter?: boolean,
}) => {
  const {
    spaceBefore,
    spaceAfter,
    size,
  } = props;
  const {
    data,
  } = useBondSameIssuerSAccGreen();
  return `${data}` === '1' ? (
    <React.Fragment>
      {spaceBefore
        ? <Space />
        : null}
      <div
        css={css}
      >
        <GreenLeaf
          size={size}
        />
      </div>
      {spaceAfter
        ? <Space />
        : null}
    </React.Fragment>
  ) : null;
};
