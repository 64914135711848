import * as React from 'react';
import { TopModelMerge, TopModelNameTransformProvider } from '@goldwasserexchange/react-topmodel';
import { TableStructure } from './structure';
import { tableDefaultTopModelsObject } from './topmodels';
import { tablePrefixer } from './utils';
export function Table(props) {
    var primaryDimensionList = props.primaryDimensionList, secondaryDimensionList = props.secondaryDimensionList, direction = props.direction, wrapping = props.wrapping, children = props.children;
    return (React.createElement(TopModelNameTransformProvider, { transformFn: tablePrefixer },
        React.createElement(TopModelMerge, { topModels: tableDefaultTopModelsObject },
            React.createElement(TableStructure, { primaryDimensionList: primaryDimensionList, secondaryDimensionList: secondaryDimensionList, direction: direction, wrapping: wrapping }, children))));
}
