import * as React from 'react';
import { useSelectIsOpen } from '../open/hooks';
import { showedItemsContext } from './context';
import { useComputeCappedShowedItems } from './hooks';
export var ShowedItemsContextProvider = function (props) {
    var showedItems = props.showedItems, children = props.children;
    var cappedShowedItems = useComputeCappedShowedItems(showedItems);
    return (React.createElement(showedItemsContext.Provider, { value: cappedShowedItems }, children));
};
export var CollapsibleShowedItemsContextProvider = function (props) {
    var showedItems = props.showedItems, children = props.children;
    var isOpen = useSelectIsOpen();
    return (React.createElement(ShowedItemsContextProvider, { showedItems: isOpen ? showedItems : 0 }, children));
};
