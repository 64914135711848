import React from 'react';
import { Trans } from '@lingui/macro';
import { dataStructure, UncastedPhysicalTAdd } from '@goldwasserexchange/oblis-frontend-utils';

export const tAddFiscalResidencyOtherLabel = <Trans>Autre résidence fiscale</Trans>;

const ONLY_ONE_FISCAL_RESIDENCY: keyof Pick<UncastedPhysicalTAdd, 'onlyOneFiscalResidency'> = 'onlyOneFiscalResidency';

export const tAddFiscalResidencyOtherValidatorLogic = dataStructure.T_ADD.fields.TYPE.logic.makeTAddIsNotMoralOrIgnored({
  truthy: {
    name: `<parentName>.${ONLY_ONE_FISCAL_RESIDENCY}`,
    is: false,
    alternatives: {
      truthy: {
        result: 'requiredNumber',
      },
      falsy: {
        result: 'nilCast',
      },
    },
  },
  falsy: {
    result: 'nilCast',
  },
});
