import { useCallback } from 'react';
import { AccountHistory } from '../../../accounts/history/type';
import { AccountPosition } from '../../../accounts/positions/type';
import { HistoryByCurrency } from '../../../history/byCurrency/type';
import { useCCurrencyCode } from '../../hooks';
import { CashflowByCurrency } from '../../../cashflows';
import { BondSameIssuerResponse } from '../../../bonds/sameIssuer/type';

export const filterByCCurrencyCode = (cCurrencyCode: HistoryByCurrency['C_CURRENCY.CODE'] | AccountPosition['C_CURRENCY.CODE'] | AccountHistory['C_CURRENCY.CODE'] | CashflowByCurrency['C_CURRENCY.CODE'] | BondSameIssuerResponse['C_CURRENCY.CODE'] | 'All' | null) => (line: HistoryByCurrency | AccountPosition | AccountHistory | CashflowByCurrency | BondSameIssuerResponse) => (
  cCurrencyCode !== 'All'
    ? line['C_CURRENCY.CODE'] === cCurrencyCode
    : true
);

export const useFilterByCCurrencyCode = (cCurrencyCode: HistoryByCurrency['C_CURRENCY.CODE'] | AccountPosition['C_CURRENCY.CODE'] | AccountHistory['C_CURRENCY.CODE'] | CashflowByCurrency['C_CURRENCY.CODE'] | BondSameIssuerResponse['C_CURRENCY.CODE'] | 'All' | null) => {
  const filterFn = useCallback(filterByCCurrencyCode(cCurrencyCode), [cCurrencyCode]);
  return filterFn;
};

export const useFilterByContextCCurrencyCode = () => {
  const cCurrencyCode = useCCurrencyCode();
  const filterFn = useFilterByCCurrencyCode(cCurrencyCode);
  return filterFn;
};
