import { cashflowPriceWithRefundInAccountCurrency, useFilterByPrimaryDimensionCpsHisId } from '../../utils';
import { useAccountCashflowsTransformApi } from '../api';

export const useAccountCashflowWithRefundInAccountCurrency = () => {
  const filterPredicate = useFilterByPrimaryDimensionCpsHisId();
  const response = useAccountCashflowsTransformApi({
    transformFunction: cashflowPriceWithRefundInAccountCurrency,
    filterPredicate,
  });
  return response;
};
