/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';

const accountTypeButtonInnerContainerStyle: ouiStyle.InStyle = {
  lines: 'full',
  widthLines: 'full',
  paddingVertical: 'simple',
  paddingHorizontal: 'double',
  textAlign: 'center',
  fontWeight: ouiStyle.Constants.FontWeightProp.bold,
};

const makeAccounTypeButtonInnerContainerCss = (props: {
  isSelected: boolean,
  isCurrentHighlighted: boolean,
  isHighlight: boolean,
}) => {
  const {
    isSelected,
    isCurrentHighlighted,
    isHighlight,
  } = props;
  if (isCurrentHighlighted && isSelected) {
    return ouiStyle.makeCss({
      ...accountTypeButtonInnerContainerStyle,
      color: ouiStyle.Constants.Colors.inverted,
      backgroundColor: ouiStyle.Constants.Colors.accent,
    });
  }
  if (isSelected) {
    return ouiStyle.makeCss({
      ...accountTypeButtonInnerContainerStyle,
      color: ouiStyle.Constants.Colors.inverted,
      backgroundColor: ouiStyle.Constants.Colors.primary,
    });
  }
  if (isHighlight) {
    return ouiStyle.makeCss({
      ...accountTypeButtonInnerContainerStyle,
      color: ouiStyle.Constants.Colors.accent,
      backgroundColor: ouiStyle.Constants.Colors.inverted,
    });
  }
  return ouiStyle.makeCss({
    ...accountTypeButtonInnerContainerStyle,
    color: ouiStyle.Constants.Colors.primary,
    backgroundColor: ouiStyle.Constants.Colors.inverted,
  });
};

export const AccountTypeButtonInnerContainer = (props: React.PropsWithChildren<{
  isSelected: boolean,
  isCurrentHighlighted: boolean,
  isHighlight: boolean,
}>) => {
  const {
    isSelected,
    isCurrentHighlighted,
    isHighlight,
    children,
  } = props;
  const css = makeAccounTypeButtonInnerContainerCss({ isSelected, isCurrentHighlighted, isHighlight });
  return (
    <div
      css={css}
    >
      {children}
    </div>
  );
};
