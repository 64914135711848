export type EventTypeString = 'SCROLL_DOWN_BELLOW_COLLAPSE';

export const eventType: EventTypeString = 'SCROLL_DOWN_BELLOW_COLLAPSE';

export type EventPayload = {
  scrollDirection: 'up' | 'down',
  isAboveCollapse: boolean,
};

export type EventType = {
  type: EventTypeString,
} & EventPayload;

export const eventBuilder = (payload: EventPayload): EventType => ({
  type: eventType,
  ...payload,
});
