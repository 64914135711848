import * as React from 'react';
import ouiDom from '@goldwasserexchange/oui-dom';
import { dataStructure, AccountType } from '@goldwasserexchange/oblis-frontend-utils';
import ouiStyle from '@goldwasserexchange/oui-style';
import { AccountTypeButtonInnerContainer } from './innerContainer';
import { useMobileSelectedAccountType, useSetMobileSelectedAccountType } from '../../context';

export const AccountTypeMobileSelectorButton = (props: React.PropsWithChildren<{
  index: number,
  currentAccountType: AccountType,
  isCurrentHighlighted: boolean,
  isHighlight: boolean,
}>): JSX.Element => {
  const {
    index,
    currentAccountType,
    isCurrentHighlighted,
    isHighlight,
    children,
  } = props;
  const mobileSelectedAccountType = useMobileSelectedAccountType();
  const setMobileSelectedAccountType = useSetMobileSelectedAccountType();
  const isSelected = mobileSelectedAccountType === currentAccountType;
  const onClick = React.useCallback(() => {
    setMobileSelectedAccountType(currentAccountType);
  }, [currentAccountType, setMobileSelectedAccountType]);
  const isFirst = index === 0;
  const isLast = index === (dataStructure.accountType.list.length - 1);
  const borderColor = isCurrentHighlighted ? ouiStyle.Constants.Colors.accent : ouiStyle.Constants.Colors.primary;
  return (
    <ouiDom.Button.Button
      type="button"
      flexGrow={1}
      paddingHorizontal="none"
      paddingVertical="none"
      borderRadius="none"
      borderColor={borderColor}
      borderLeftColor={!isFirst && !isSelected ? ouiStyle.Constants.Colors.transparent : borderColor}
      borderTopLeftRadius={isFirst ? 'rounded' : 'none'}
      borderTopRightRadius={isLast ? 'rounded' : 'none'}
      onClick={onClick}
    >
      <AccountTypeButtonInnerContainer
        isSelected={isSelected}
        isCurrentHighlighted={isCurrentHighlighted}
        isHighlight={isHighlight}
      >
        {children}
      </AccountTypeButtonInnerContainer>
    </ouiDom.Button.Button>
  );
};
