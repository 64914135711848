import * as CASH from '../CASH';
import * as MONTHLY_INCOME from '../MONTHLY_INCOME';
import * as NET_WORTH from '../NET_WORTH';
import * as OWNER from '../OWNER';
export var map = {
    netWorth: NET_WORTH.logic.label.logic,
    monthlyExpenses: NET_WORTH.logic.label.logic,
    monthlyIncome: MONTHLY_INCOME.logic.label.logic,
    cash: CASH.logic.label.logic,
    owner: OWNER.logic.label.logic,
};
