import {
  replace,
} from 'ramda';
import lmlGet from '@goldwasserexchange/utils/lib/lmlGet';
import { useCallback } from 'react';
import { getIdkZLang } from '../../../../../../../Components/LmlText';

const splitWords = (title: string) => {
  const words = title.split(' ').filter((word) => word !== '');
  return words;
};

const titleCase = (word: string) => {
  const [firstLetter, ...otherLetters] = [...word];
  return [(firstLetter ?? '').toUpperCase(), ...otherLetters.map((letter) => letter.toLowerCase())].join('');
};

const currencyTitleCase = (title: string) => {
  const words = splitWords(title);
  const [firstWord, ...lastWords] = words;
  const uppercased = (firstWord ?? '').toUpperCase();
  const lowercased = lastWords.map((word) => titleCase(word));
  return [uppercased, ...lowercased].join(' ');
};

const classicTitleCase = (title: string) => {
  const words = splitWords(title);
  const titleCased = words.map((word) => titleCase(word));
  return titleCased.join(' ');
};

const removeStartYear = replace(/\d{4}-/, '');

const positionsTitleCase = (title: string) => {
  const splitted = title.split(' ');
  if ((splitted[0] ?? '').length === 3) {
    const currencyTitle = currencyTitleCase(title);
    return removeStartYear(currencyTitle);
  }
  return classicTitleCase(title);
};

export const lmlGetPositionsTitleCase = (language: string) => (value?: string): string => {
  const idkZLang = getIdkZLang(language);
  return value ? positionsTitleCase(lmlGet(idkZLang, value)) : '';
};

export const useLmlGetPositionsTitleCase = (language: string) => {
  const lmlGetPositionsTitleCaseFn = useCallback(lmlGetPositionsTitleCase(language), [language]);
  return lmlGetPositionsTitleCaseFn;
};
