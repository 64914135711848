import React from 'react';
import { Trans } from '@lingui/macro';
import ouiBase from '@goldwasserexchange/oui';
import {
  dataStructure, TAddType, UncastedDataStructure, PersonType, UncastedOnboarding,
} from '@goldwasserexchange/oblis-frontend-utils';

const onboardingPath: keyof Pick<UncastedDataStructure, 'onboarding'> = 'onboarding';

const personTypeKey: keyof Pick<UncastedOnboarding, 'personType'> = 'personType';

export const tAddMoralLeiCodeLabel = <Trans>Code LEI</Trans>;

export const tAddMoralLeiCodeHelpText = <Trans>Legal Entity Identifier</Trans>;

export const tAddMoralLeiCodeValidatorLogic: ouiBase.utils.hookstate.FormValueFilterLogic<'leiCode' | 'nilCast'> = dataStructure.T_ADD.fields.TYPE.logic.makeTAddTypeFilterLogic({
  is: TAddType.MORAL,
  truthy: {
    name: `${onboardingPath}.${personTypeKey}`,
    is: PersonType.MORAL,
    alternatives: {
      truthy: {
        result: 'leiCode',
      },
      falsy: {
        result: 'nilCast',
      },
    },
  },
  falsy: {
    result: 'nilCast',
  },
});
