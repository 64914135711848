import * as React from 'react';
import ouiDom from '@goldwasserexchange/oui-dom';
import ouiStyle from '@goldwasserexchange/oui-style';
import { Trans } from '@lingui/macro';

export const RecommendationText = () => (
  <ouiDom.P.P
    fontSize="s"
    color={ouiStyle.Constants.Colors.accent}
    fontWeight={ouiStyle.Constants.FontWeightProp.intermediary}
    textTransform="uppercase"
    paddingHorizontal="simple"
    textAlign={['center', undefined, 'left', undefined, undefined, undefined]}
    paddingVertical={['none', undefined, 'simple', undefined, undefined, undefined]}
  >
    <Trans>
      Service&#32;
      <br />
      recommandé
    </Trans>
  </ouiDom.P.P>
);
