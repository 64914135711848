import { compareAsc } from 'date-fns';
import { AccountTwrrs } from '../../type';
import { getTAccTwrrPDate } from './utils/getDate';
import { useAccountTwrrsTransformApi } from '../api';

const transformFn = (d?: AccountTwrrs[]) => {
  if (d && d.length > 0) {
    return d.map((el) => ({
      id: el['T_ACC_TWRR_P.ID'],
      date: getTAccTwrrPDate(el),
      sumIn: el['T_ACC_TWRR_P.SUM_IN'] ?? 0,
      sumOut: el['T_ACC_TWRR_P.SUM_OUT'] ?? 0,
      navEnd: el['T_ACC_TWRR_P.NAV_END'],
    })).sort((prev, next) => compareAsc(prev.date, next.date));
  }
  return [];
};

export const useAccountsTwrrGraphData = () => {
  const lineData = useAccountTwrrsTransformApi({
    transformFunction: transformFn,
  });
  return lineData;
};
