import { sanityClient } from '../../client';
import { query } from './query';
import { QueryArguments, QueryResponseElement, SwrArguments } from './types';

export const convertSwrArguments = (args: SwrArguments): QueryArguments => {
  const [, locale] = args;
  return {
    locale,
  };
};

export const convertQueryArguments = (args: QueryArguments): SwrArguments => {
  const {
    locale,
  } = args;
  return [query, locale];
};

export const fetcher = async (args: SwrArguments) => {
  const [queryString] = args;
  const sanityArguments = convertSwrArguments(args);
  const response = await sanityClient.fetch<QueryResponseElement[], QueryArguments>(queryString, sanityArguments);
  return response;
};
