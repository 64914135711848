import * as React from 'react';
import { inputAutocompleteContext } from './context';

export const InputAutoCompleteProvider = (props: React.PropsWithChildren<{ autocomplete?: boolean}>) => {
  const {
    autocomplete = true,
    children,
  } = props;
  return (
    <inputAutocompleteContext.Provider value={autocomplete}>
      {children}
    </inputAutocompleteContext.Provider>
  );
};
