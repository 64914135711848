import { useEffect } from 'react';
import isNil from 'ramda/es/isNil';
import { UncastedDataStructure, TNatureLrp, TProcType } from '@goldwasserexchange/oblis-frontend-utils';
import { isEmpty } from 'ramda';
import ouiBase from '@goldwasserexchange/oui';
import { useHookstate } from '@hookstate/core';

export const TNatureLRPProcTypeUpdater = (props: { index: number }) => {
  const { index } = props;
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const tNatureLrp = useHookstate(valueState.onboarding.users[index].ID_T_NATURE_LRP).get();
  const currentUser = useHookstate(valueState.onboarding.users[index].current).get();
  const tProcTypeState = useHookstate(valueState.onboarding.users[index].ID_T_PROC_TYPE);
  const tProcType = tProcTypeState.get();
  useEffect(() => {
    if (!isNil(tNatureLrp)) {
      if (tNatureLrp === TNatureLrp.TITULAIRE_INCAPABLE && tProcType !== '') {
        tProcTypeState.set('');
      } else if (tNatureLrp === TNatureLrp.TITULAIRE && tProcType !== `${TProcType.GENERAL}`) {
        tProcTypeState.set(`${TProcType.GENERAL}`);
      } else if (currentUser === true && tProcType === `${TProcType.CONSULTATION}`) {
        tProcTypeState.set(`${TProcType.GESTION}`);
      } else if (isEmpty(tProcType)) {
        tProcTypeState.set(`${TProcType.GESTION}`);
      }
    }
  }, [tNatureLrp, currentUser]);
  return null;
};
