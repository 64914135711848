import * as React from 'react';
import ouiBase from '@goldwasserexchange/oui';
import { UncastedDataStructure, dataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { Trans } from '@lingui/macro';
import { RadioListInput } from '../../../../OUI-dom/Inputs';
import { AccountTypeMobileInputContainer } from './container';

const labelText = <TopModel name="RadioListInputLabelContent"><Trans>Votre choix de service</Trans></TopModel>;

const defaultRiskClassElementLabel = (
  <TopModel name="RadioListInputListElementLabelContent">
    {dataStructure.accountType.labels.LabelOnPrimaryDimension}
  </TopModel>
);

export const AccountTypeMobileInput = () => {
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  return (
    <AccountTypeMobileInputContainer>
      <RadioListInput
        fieldPath={valueState.onboarding.selectedService.path.join('.')}
        list={dataStructure.accountType.list}
      >
        {labelText}
        {defaultRiskClassElementLabel}
      </RadioListInput>
    </AccountTypeMobileInputContainer>
  );
};
