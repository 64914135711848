/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Trans } from '@lingui/macro';
import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { InfoMailLink } from '../../../MailLinks';
import { GEPhoneNumber } from '../../../PhoneNumber';

export const NotFoundIsErrorContact = (props: {
  color?: ouiStyle.Constants.Colors,
}) => {
  const {
    color,
  } = props;
  const css = React.useMemo(() => ({
    color,
  }), [color]);
  return (
    <Trans>
      <p
        css={css}
      >
        Pour plus d'informations
      </p>
      <p
        css={css}
      >
        Contactez-nous
      </p>
      <ul>
        <li
          css={css}
        >
          par mail à&nbsp;
          <InfoMailLink />
        </li>
        <li
          css={css}
        >
          par téléphone au&nbsp;
          <GEPhoneNumber />
        </li>
      </ul>
    </Trans>
  );
};
