export type MediaQueryChangeDown = 'MEDIA_QUERY_CHANGE_DOWN';

export const eventType: MediaQueryChangeDown = 'MEDIA_QUERY_CHANGE_DOWN';

export type EventType = {
  type: MediaQueryChangeDown,
};

export const eventBuilder = (): EventType => ({
  type: eventType,
});
