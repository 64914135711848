import { Order } from './type';

export const optimisticUpdateOrders = (props: {
  data: Order[] | undefined,
  element: Order,
}) => {
  const {
    data,
    element,
  } = props;
  return [...(data ?? []), element];
};
