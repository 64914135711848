var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useContext, useMemo } from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { useVerticalChildPaddings } from './elementPadding';
import { listInputElementLinesContext } from './context';
export function useListInputElementLines() {
    var listInputElementLines = useContext(listInputElementLinesContext);
    return listInputElementLines;
}
export var defaultListFontSize = {
    fontSize: 'xs',
};
export function useListInputElementHeight() {
    var listInputElementLines = useListInputElementLines();
    var listInputElementVerticalPadding = useVerticalChildPaddings();
    var height = useMemo(function () { return ouiStyle.makeCss(__assign(__assign(__assign({}, defaultListFontSize), listInputElementVerticalPadding), { lines: listInputElementLines })); }, [listInputElementLines, listInputElementVerticalPadding]).height;
    var currentElementHeight = !Array.isArray(height)
        ? parseFloat(height.replace(/rem/g, '')) * 16
        : parseFloat(height[0].replace(/rem/g, '')) * 16;
    return currentElementHeight;
}
