import { TwoDimensionalMatrix } from './types';

/**
 * This function performs forward elimination on a matrix.
 * @param {TwoDimensionalMatrix} combinedMatrix The matrix to perform forward elimination on.
 * @returns {TwoDimensionalMatrix} A new matrix resulting from forward elimination of the input matrix.
 */
export const forwardElimination = (combinedMatrix: TwoDimensionalMatrix): TwoDimensionalMatrix => {
  const rows = combinedMatrix.length;
  const cols = combinedMatrix[0].length;
  const matrix: TwoDimensionalMatrix = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < rows; i++) {
    matrix.push([]);
    // eslint-disable-next-line no-plusplus
    for (let j = 0; j < cols; j++) {
      matrix[i][j] = combinedMatrix[i][j];
    }
  }
  // eslint-disable-next-line no-plusplus
  for (let x = 0; x < rows - 1; x++) {
    // eslint-disable-next-line no-plusplus
    for (let z = x; z < rows - 1; z++) {
      const numerator = matrix[z + 1][x];
      const denominator = matrix[x][x];
      const result = numerator / denominator;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < cols; i++) {
        matrix[z + 1][i] = matrix[z + 1][i] - result * matrix[x][i];
      }
    }
  }
  return matrix;
};
