import ouiBase from '@goldwasserexchange/oui';
import { makeTAddTypeFilterLogic } from '../../TYPE/logic';
import { PersonType, TAddType, } from '../../../../../types';
var onboardingPath = 'onboarding';
var personTypePath = 'personType';
export var showLogic = {
    name: "".concat(onboardingPath, ".").concat(personTypePath),
    is: [
        PersonType.INDIVIDUAL,
        PersonType.JOINT,
        PersonType.INDIVISION,
        PersonType.FOR_MINORS,
    ],
    options: {
        isNegative: true,
        matcher: ouiBase.utils.match.arrayMatcherNames.some,
    },
    alternatives: {
        truthy: makeTAddTypeFilterLogic({
            is: TAddType.TITULAR,
        }),
        falsy: {
            result: 'invisible',
        },
    },
};
