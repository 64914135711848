import * as React from 'react';
import { Trans } from '@lingui/macro';
import { CheckBoxButton } from './button';
import { LabelContainer } from './labelContainer';
import { Container } from './container';

export const RememberDeviceInput = () => (
  <Container>
    <CheckBoxButton />
    <LabelContainer>
      <Trans>Ne plus me demander de code de sécurité sur cet appareil à l'avenir</Trans>
    </LabelContainer>
  </Container>
);
