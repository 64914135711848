/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const clearFixStyle: ouiStyle.InStyle = {
  clearfix: true,
};

const clearFixCss = ouiStyle.makeCss(clearFixStyle);

export const ClearFixDiv = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  return (
    <div
      css={clearFixCss}
    >
      {children}
    </div>
  );
};

export const ClearFixSection = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  return (
    <section
      css={clearFixCss}
    >
      {children}
    </section>
  );
};
