import { useState, useEffect, useCallback } from 'react';
import throttle from 'lodash.throttle';

export const useIsScrollStart = (scrollLeft: number): boolean => {
  const isScrollStart = scrollLeft === 0;
  return isScrollStart;
};

export const useIsScrollEnd = (scrollLeft: number, totalWidth: number, clientWidth: number): boolean => Math.ceil(scrollLeft) >= (totalWidth * 16) - clientWidth;

export const useScrollStartNotVisible = (scrollLeft: number): boolean => {
  const isScrollStart = useIsScrollStart(scrollLeft);
  const [visible, setVisible] = useState(!isScrollStart);
  useEffect(() => {
    if (!isScrollStart && !visible) {
      setVisible(true);
    } else if (isScrollStart && visible) {
      setVisible(false);
    }
  }, [visible, scrollLeft]);
  return visible;
};

export const useScrollEndNotVisible = (scrollLeft: number, totalWidth: number, clientWidth: number): boolean => {
  const isScrollEnd = useIsScrollEnd(scrollLeft, totalWidth, clientWidth);
  const [visible, setVisible] = useState(!isScrollEnd);
  useEffect(() => {
    if (!isScrollEnd && !visible) {
      setVisible(true);
    } else if (isScrollEnd && visible) {
      setVisible(false);
    }
  }, [visible, isScrollEnd]);
  return visible;
};

export const useClientWidth = (ref: React.MutableRefObject<HTMLElement | HTMLDivElement | null>, refVersion: number): number => {
  const [clientWidth, setClientWidth] = useState(0);
  const onResize = (): void => {
    if (ref.current) {
      setClientWidth(ref.current.clientWidth);
    }
  };
  const throttledOnResize = throttle(onResize, 200);
  useEffect(() => {
    if (ref.current) {
      onResize();
      window.addEventListener('resize', throttledOnResize);
      return (): void => {
        if (ref.current) {
          window.removeEventListener('resize', throttledOnResize);
        }
      };
    }
    return (): void => { };
  }, [refVersion]);
  return clientWidth;
};

export const useClientHeight = (ref: React.MutableRefObject<HTMLElement | HTMLDivElement | null>, refVersion: number): number => {
  const [clientHeight, setClientHeight] = useState(0);
  const onResize = (): void => {
    if (ref.current) {
      setClientHeight(ref.current.clientHeight);
    }
  };
  const throttledOnResize = throttle(onResize, 200);
  useEffect(() => {
    if (ref.current) {
      onResize();
      window.addEventListener('resize', throttledOnResize);
      return (): void => {
        if (ref.current) {
          window.removeEventListener('resize', throttledOnResize);
        }
      };
    }
    return (): void => { };
  }, [refVersion]);
  return clientHeight;
};

export const useOffsetHeight = (ref: React.MutableRefObject<HTMLElement | HTMLDivElement | null>, refVersion: number): number => {
  const [offsetHeight, setOffsetHeight] = useState(0);
  const onResize = (): void => {
    if (ref.current) {
      setOffsetHeight(ref.current.offsetHeight);
    }
  };
  const throttledOnResize = throttle(onResize, 200);
  useEffect(() => {
    if (ref.current) {
      onResize();
      window.addEventListener('resize', throttledOnResize);
      return (): void => {
        if (ref.current) {
          window.removeEventListener('resize', throttledOnResize);
        }
      };
    }
    return (): void => { };
  }, [refVersion]);
  return offsetHeight;
};

export const useHorizontalScrollBarHeight = (ref: React.MutableRefObject<HTMLElement | HTMLDivElement | null>, refVersion: number): number => {
  const offSetHeight = useOffsetHeight(ref, refVersion);
  const clientHeight = useClientHeight(ref, refVersion);
  return offSetHeight - clientHeight;
};

export const useOnScrollLeft = (ref, refVersion): [number, (any) => void] => {
  const [scrollLeft, setScrollLeft] = useState(0);
  const throttledSetScrollLeft = useCallback(() => {
    if (ref.current) {
      throttle(setScrollLeft, 200)(ref.current.scrollLeft);
    }
  }, [setScrollLeft, refVersion]);
  useEffect(() => {
    if (ref.current) {
      window.addEventListener('resize', throttledSetScrollLeft);
      return (): void => {
        if (ref.current) {
          window.removeEventListener('resize', throttledSetScrollLeft);
        }
      };
    }
    return (): void => { };
  }, [throttledSetScrollLeft]);
  return [scrollLeft, (): void => {
    throttledSetScrollLeft();
  }];
};

export const useScrollToLeft = (ref: React.MutableRefObject<HTMLElement | HTMLDivElement | null>): ((pixels: number) => void) => {
  const scrollToLeft = useCallback((pixels: number): void => {
    if (ref.current) {
      ref.current.scrollTo({
        left: pixels,
        behavior: 'smooth',
      });
    }
  }, [ref]);
  return scrollToLeft;
};
