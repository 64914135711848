import { makeTAddTypeFilterLogic } from '../../TYPE/logic';
import { makeIdTNatureLrpDependentLogic } from '../../ID_T_NATURE_LRP/logic/helpers';
import { TAddType, TNatureLrp } from '../../../../../types';
export var showLogic = makeTAddTypeFilterLogic({
    is: TAddType.TITULAR,
    truthy: makeIdTNatureLrpDependentLogic({
        is: TNatureLrp.REPRESENTANT_LEGAL,
        options: { isNegative: true },
    }),
});
