import * as React from 'react';
import { useAccountPositionGesEstimDYield, useAccountPositionGesEstimDYieldTotalBySTypeGrp } from '../../hooks';
import { ActorApiNumberDisplay } from '../../../../../../components/display/number/component';

export const AccountsPositionsGesEstimDYield = () => {
  const {
    data,
    isLoading,
    error,
  } = useAccountPositionGesEstimDYield();
  return (
    <ActorApiNumberDisplay
      data={data}
      isLoading={isLoading}
      error={error}
      format={{
        style: 'percent',
      }}
    />
  );
};

export const AccountsPositionsGesEstimDYieldTotalBySTypeGrp = () => {
  const {
    data,
    isLoading,
    error,
  } = useAccountPositionGesEstimDYieldTotalBySTypeGrp();
  return (
    <ActorApiNumberDisplay
      data={data}
      isLoading={isLoading}
      error={error}
      format={{
        style: 'percent',
      }}
    />
  );
};
