import * as React from 'react';
import { ErrorBoundary } from '../../../../../../Components/ErrorBoundary';
import { ActorApiNumberDisplay } from '../../../../../components/display/number/component';
import { useBondsBondIsSomeRedacted } from '../../bond';
import { useBondsCallIsSomeRedacted } from '../../calls';
import { useBondsYtc } from '../hooks';

const BondsBondYtcInner = () => {
  const {
    data,
    isLoading,
    error,
  } = useBondsYtc();
  const {
    data: callsIsRedacted,
    isLoading: callsIsRedactedIsLoading,
  } = useBondsCallIsSomeRedacted(['EVT_ENTRY_S.EVT_DATE', 'EVT_ENTRY_S.CMT_HIST']);
  const {
    data: bondIsRedacted,
    isLoading: bondIsLoading,
  } = useBondsBondIsSomeRedacted(['S_ACC.INT_RATE', 'S_ACC_JRS.FREQ', 'S_QUOTE.PRICE_CLOSE']);
  return (
    <ActorApiNumberDisplay
      data={data}
      isLoading={isLoading ? callsIsRedactedIsLoading || bondIsLoading : isLoading}
      error={error}
      format={{
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 3,
      }}
      loadingValue={3.625}
      divider={1}
      isBlocked={callsIsRedacted || bondIsRedacted}
      withRedactedIcon
    />
  );
};

export const BondsBondYtc = () => (
  <ErrorBoundary
    renderNull
  >
    <BondsBondYtcInner />
  </ErrorBoundary>
);
