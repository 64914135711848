/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import { animated } from 'react-spring';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as animationController from '../../animation';
import { useZIndex } from '../../../../OUI/Form';
import { AppBarMenuWrapper } from './menuWrapper';
import { Banner } from '../../../banner';

const headerContainerStyle: ouiStyle.InStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
};

const useHeaderContainerCss = () => {
  const zIndex = useZIndex() - 3;
  const css = React.useMemo(() => ouiStyle.makeCss({
    ...headerContainerStyle,
    zIndex,
  }), [zIndex]);
  return css;
};

export const HeaderContainer = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  const collapse = animationController.useAnimationCollapseSpringValue();
  const css = useHeaderContainerCss();
  return (
    <animated.header
      css={css}
      style={{
        transform: collapse.to((v: number) => `translateY(-${v * 100}%)`),
      }}
    >
      <Banner />
      <AppBarMenuWrapper>
        {children}
      </AppBarMenuWrapper>
    </animated.header>
  );
};

export default HeaderContainer;
