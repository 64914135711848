import * as React from 'react';
import { ActorApiMapper } from '../../../../../../components';
import { AccountHistory } from '../../type';
import { useAccountHistoryList } from '../../hooks';
import { useMakeLoadingIds } from '../../../../shared';

export const AccountHistoryMapper = (props: React.PropsWithChildren<{
  filterPredicate?: (data: AccountHistory) => boolean,
  transformFunction?: (data: AccountHistory[] | undefined) => string[],
  limit?: number,
}>) => {
  const {
    filterPredicate,
    transformFunction,
    limit,
    children,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useAccountHistoryList({ filterPredicate, transformFunction });
  const loadingList = useMakeLoadingIds(limit ?? 0);
  return (
    <ActorApiMapper
      data={data}
      isLoading={isLoading}
      error={error}
      loadingList={loadingList}
      limit={limit}
    >
      {children}
    </ActorApiMapper>
  );
};
