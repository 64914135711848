import { getHistoryLineId } from '../../../../shared';
import { AccountHistory } from '../../type';
import { useAccountHistoryLinesTransformApi } from '../api';

const defaultTransformFunction = (data: AccountHistory[] | undefined) => (
  data
    ? data.map((d) => getHistoryLineId(d))
    : []
);

export const useAccountHistoryList = (props: {
  filterPredicate?: ((data: AccountHistory) => boolean),
  transformFunction?: ((data: AccountHistory[] | undefined) => string[]),
}) => {
  const {
    filterPredicate,
    transformFunction,
  } = props;
  const result = useAccountHistoryLinesTransformApi({
    transformFunction: transformFunction ?? defaultTransformFunction,
    filterPredicate,
  });
  return result;
};
