import * as React from 'react';
import { bondsTableTypeContext } from './context';
import { useBondsTableType } from './hooks';
import { BondsTableType } from './type';

export const BondsTableTypeContextProvider = (props: React.PropsWithChildren<{
  type?: BondsTableType,
}>) => {
  const parentType = useBondsTableType();
  const {
    type,
    children,
  } = props;
  const typeWithParent = type ?? parentType;
  if (typeWithParent == null) {
    throw new Error('BondsTablePageTypeContextProvider received no type and has no parent type');
  }
  return (
    <bondsTableTypeContext.Provider
      value={type ?? parentType}
    >
      {children}
    </bondsTableTypeContext.Provider>
  );
};
