import React, {
  useEffect, useRef,
} from 'react';
import { Trans } from '@lingui/macro';
import { WasRetriedSuffix, UncastedDataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import ouiBase from '@goldwasserexchange/oui';
import ouiDom from '@goldwasserexchange/oui-dom';
import { State, useHookstate } from '@hookstate/core';
import {
  IconName,
} from '../../../../../Icons';
import { ActionFilter, useSendOnClick } from '../../../../../Machine';
import { Slot } from '../../../../../Layout/components';
import { Card } from '../../../../../Card';
import { useAppRef } from '../../../../../AppContainer/context';
import {
  useFieldIsCorrectedByFieldPath,
} from '../../../../../../OUI/Form';
import { ButtonContainer } from '../../../../shared/imageHeadingSection/buttonContainer';

export * from './modifyAnswers';
export * from './questionGroupTitle';

export const Correction = (props: React.PropsWithChildren<{
  questionKey: string,
}>) => {
  const appRef = useAppRef();
  const {
    questionKey,
    children,
  } = props;
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const parentName = [...valueState.onboarding.knowledgeQuestionnaire.path, questionKey].join('.');
  const explanationRef = useRef<HTMLDivElement>(null);
  const isCorrectedState = useFieldIsCorrectedByFieldPath(parentName);
  const isCorrected = isCorrectedState.get();
  useEffect(() => {
    if (isCorrected && explanationRef.current && appRef.current) {
      appRef.current.scrollTo({ top: explanationRef.current.offsetTop, left: 0, behavior: 'smooth' });
    }
  }, [isCorrected]);
  return (
    <Card innerRef={explanationRef} iconName={IconName.AlertTriangle} successState="error">
      <Slot slot="title">
        <Trans>Mauvaise réponse</Trans>
      </Slot>
      <Slot slot="content">
        {children}
      </Slot>
    </Card>
  );
};

export const RetryButton = (props: {
  questionKey: string,
}) => {
  const { questionKey } = props;
  const wasRetriedPath = `${questionKey}${WasRetriedSuffix}`;
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const parentName = [...valueState.onboarding.knowledgeQuestionnaire.path, questionKey].join('.');
  const wasRetriedState = useHookstate(valueState.onboarding.knowledgeQuestionnaire[wasRetriedPath]) as State<boolean | undefined>;
  const onClick = useSendOnClick('RETRY', { parentName });
  return !wasRetriedState.get() ? (
    <ActionFilter is="RETRY">
      <ButtonContainer
        paddingVertical="simple"
      >
        <ouiDom.Button.Button
          type="button"
          onClick={onClick}
        >
          <Trans>Réessayer</Trans>
        </ouiDom.Button.Button>
      </ButtonContainer>
    </ActionFilter>
  ) : null;
};
