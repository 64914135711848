import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { DomAButtonContainer, DomAContainer, DomButtonLinkContainer, DomLinkContainer, } from './components';
import { RippleEffect } from '../RippleEffect';
export var aDefaultDomTopModelsObject = {
    AContainer: (React.createElement(TopModel, { name: "AContainer" }, DomAContainer)),
    AContainerLink: (React.createElement(TopModel, { name: "AContainerLink" }, DomLinkContainer)),
};
export var aButtonDefaultDomTopModelsObject = {
    AContainer: (React.createElement(TopModel, { name: "AContainer" }, DomAButtonContainer)),
    AContainerLink: (React.createElement(TopModel, { name: "AContainerLink" }, DomButtonLinkContainer)),
    ARippleEffect: (React.createElement(TopModel, { name: "ARippleEffect" }, RippleEffect)),
};
