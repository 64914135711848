import {
  endOfDay,
  endOfYear,
  startOfDay,
  startOfYear,
  subMonths,
  subYears,
} from 'date-fns';
import { atom } from 'jotai';
import {
  DateInterval,
  locationSearchParsedAtom,
  parseValidIntervalWithDefault,
} from '../../../../../../../../history';
import { formatApiParamDate } from '../../../../../../../../actor/api/shared';

export const parsedSearchOnlineHistoryValidIntervalAtom = atom((get) => {
  const { interval } = get(locationSearchParsedAtom);
  const parsedInterval = parseValidIntervalWithDefault(interval);
  return parsedInterval;
});

const transformInterval = (interval: DateInterval): [Date, Date] => {
  const currentDate = new Date();
  if (interval === DateInterval.three_months) {
    return [subMonths(currentDate, 3), currentDate];
  }
  if (interval === DateInterval.six_months) {
    return [subMonths(currentDate, 6), currentDate];
  }
  if (interval === DateInterval.nine_months) {
    return [subMonths(currentDate, 9), currentDate];
  }
  if (interval === DateInterval.one_year) {
    return [subYears(currentDate, 1), currentDate];
  }
  if (interval === DateInterval.this_year) {
    return [startOfYear(currentDate), currentDate];
  }
  if (interval === DateInterval.last_year) {
    const lastYearCurrentDate = subYears(currentDate, 1);
    return [startOfYear(lastYearCurrentDate), endOfYear(lastYearCurrentDate)];
  }
  throw new Error('invalid interval');
};

const startEndLimit = (interval: [Date, Date]): [Date, Date] => {
  const [start, end] = interval;
  return [
    startOfDay(start),
    endOfDay(end),
  ];
};

export const onlineHistoryTimeIntervalAtom = atom((get) => {
  const time = get(parsedSearchOnlineHistoryValidIntervalAtom);
  const interval = startEndLimit(transformInterval(time));
  return interval;
});

export const onlineHistoryTimeIntervalStartAtom = atom((get) => {
  const [startTime] = get(onlineHistoryTimeIntervalAtom);
  return startTime;
});

export const onlineHistoryTimeIntervalStartParamAtom = atom((get) => {
  const startTime = get(onlineHistoryTimeIntervalStartAtom);
  return formatApiParamDate(startTime);
});

export const onlineHistoryTimeIntervalEndAtom = atom((get) => {
  const [, endTime] = get(onlineHistoryTimeIntervalAtom);
  return endTime;
});
