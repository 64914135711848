/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const backToServiceSelectionButtonContainerStyle: ouiStyle.InStyle = {
  padding: 'double',
  margin: 'double',
  textAlign: 'center',
};

const backToServiceSelectionButtonContainerCss = ouiStyle.makeCss(backToServiceSelectionButtonContainerStyle);

export const BackToServiceSelectionButtonContainer = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  return (
    <div
      css={backToServiceSelectionButtonContainerCss}
    >
      {children}
    </div>
  );
};
