import * as React from 'react';
import {
  makeName,
  dataStructure,
  AccountType,
  STypeGrp,
  UncastedOnboarding,
} from '@goldwasserexchange/oblis-frontend-utils';
import voca from 'voca';
import { Trans } from '@lingui/macro';
import { IconName } from '../../../../../../../Icons';
import {
  makeGoTo, makeNext, makePrev, NavAction,
} from '../../../../StateMachine/utils';
import { makeGuardOrSkip } from '../../../../StateMachine/utils/guardOrSkip';
import { makeQuestion, makeQuestionWithRemedial } from '../../../../StateMachine/utils/productQuestionnaire';
import { makeExperienceQuestionnaireStep } from '../../ExperienceQuestionnaire';
import { makeQuestionnaireSkips } from '../../KnowledgeQuestionnaire';
import { makeQuestionnaireDone } from '../../KnowledgeQuestionnaire/stateMachine/makeQuestionnaireDone';
import { productNamesMap } from '../productNamesMap';

export const makeInitialKnowledgeQuestion = (product: dataStructure.financialProducts.listType, service: dataStructure.accountType.listType) => {
  const questionList = dataStructure.financialProductsKnowledge.questionPaths.getQuestionRootIdsByServiceAndQuestionnaire(service, product);
  return [
    ...questionList.map((questionKey) => ({
      target: questionKey,
      cond: {
        type: 'formFilterLogic',
        logic: dataStructure.financialProductsKnowledge.logic.hasKnowledge.make.makeIsQuestionCorrectLogic(
          product,
          {
            correctKey: dataStructure.financialProductsKnowledge.pathUtils.makeIsCorrectKey(questionKey),
            remedialIsCorrectKey: service === AccountType.RTO ? dataStructure.financialProductsKnowledge.pathUtils.makeIsCorrectKey(dataStructure.financialProductsKnowledge.pathUtils.makeRemedialKey(questionKey)) : undefined,
            wasRetriedKey: dataStructure.financialProductsKnowledge.pathUtils.makeWasRetried(questionKey),
          },
          {
            notAnswered: {
              result: true,
            },
            success: {
              result: false,
            },
            failure: {
              result: true,
            },
            failureWithRetryAvailable: {
              result: true,
            },
            failureWithRetryAvailableOnRequired: {
              result: true,
            },
            retriedFailureOnRequired: {
              result: true,
            },
          },
        ),
      },
    })),
    {
      target: 'experience',
    },
  ];
};

const makeSerialPrevNext = (service: dataStructure.accountType.listType, index: number, src: string[], productName: dataStructure.financialProducts.listType) => {
  const prevQuestionId = index !== 0 ? src[index - 1] : 'experience';
  const nextQuestionId = index !== (src.length - 1) ? src[index + 1] : null;
  const questionKey = src[index];
  return ({
    on: {
      ...makePrev(prevQuestionId),
    },
    onDone: [
      ...(nextQuestionId
        ? [{
          target: nextQuestionId,
          cond: {
            type: 'formFilterLogic',
            logic: dataStructure.financialProductsKnowledge.logic.hasKnowledge.make.makeIsQuestionCorrectLogic(
              productName,
              {
                correctKey: dataStructure.financialProductsKnowledge.pathUtils.makeIsCorrectKey(questionKey),
                remedialIsCorrectKey: service === AccountType.RTO ? dataStructure.financialProductsKnowledge.pathUtils.makeIsCorrectKey(dataStructure.financialProductsKnowledge.pathUtils.makeRemedialKey(questionKey)) : undefined,
                wasRetriedKey: dataStructure.financialProductsKnowledge.pathUtils.makeWasRetried(questionKey),
              },
              {
                notAnswered: {
                  result: false,
                },
                failure: {
                  result: false,
                },
                failureWithRetryAvailable: {
                  result: false,
                },
                failureWithRetryAvailableOnRequired: {
                  result: false,
                },
                retriedFailureOnRequired: {
                  result: false,
                },
                success: {
                  result: true,
                },
              },
            ),
          },
        }]
        : []),
      { target: 'done' },
    ],
  });
};

const knowledgeQuestionnaire: keyof Pick<UncastedOnboarding, 'knowledgeQuestionnaire'> = 'knowledgeQuestionnaire';

const makeProductQuestions = (product: dataStructure.financialProducts.listType, service: dataStructure.accountType.listType) => {
  const questionList = dataStructure.financialProductsKnowledge.questionPaths.getQuestionRootIdsByServiceAndQuestionnaire(service, product);
  return questionList.reduce((acc, questionId, index, src) => ({
    ...acc,
    [questionId]: service === AccountType.RTO
      ? {
        ...makeQuestionWithRemedial(makeName(knowledgeQuestionnaire, questionId), product, service),
        ...makeSerialPrevNext(service, index, src, product),
      }
      : {
        ...makeQuestion(makeName(knowledgeQuestionnaire, questionId), product, service, true),
      },
  }), {});
};

const makeProductQuestionnaire = (product: dataStructure.financialProducts.listType, service: dataStructure.accountType.listType) => ({
  initial: 'initial',
  states: (dataStructure.financialProducts.logic.KERequired.getKERequiredByService(product, service) !== false
    ? {
      initial: {
        on: {
          '': [
            {
              target: 'directionForward',
              cond: 'directionForward',
            },
            {
              target: 'directionBackward',
              cond: 'directionBackward',
            },
          ],
        },
      },
      directionForward: {
        on: {
          '': [
            {
              target: 'questions',
              cond: {
                type: 'formFilterLogic',
                logic: dataStructure.financialProductsKnowledge.logic.hasKnowledge.make.makeIsQuestionnaireCorrectLogic(
                  product,
                  {
                    noQuestions: {
                      result: false,
                    },
                    notSelected: {
                      result: false,
                    },
                    notAnswered: {
                      result: true,
                    },
                    success: {
                      result: false,
                    },
                    failure: {
                      result: true,
                    },
                    failureWithRetryAvailable: {
                      result: true,
                    },
                    failureWithRetryAvailableOnRequired: {
                      result: true,
                    },
                    retriedFailureOnRequired: {
                      result: false,
                    },
                    truthy: {
                      result: true,
                    },
                    falsy: {
                      result: false,
                    },
                  },
                ),
              },
            },
            {
              target: 'success',
              cond: {
                type: 'formFilterLogic',
                logic: dataStructure.financialProductsKnowledge.logic.hasKnowledge.make.makeIsQuestionnaireCorrectLogic(
                  product,
                  {
                    noQuestions: {
                      result: false,
                    },
                    notSelected: {
                      result: false,
                    },
                    notAnswered: {
                      result: false,
                    },
                    success: {
                      result: true,
                    },
                    failure: {
                      result: false,
                    },
                    failureWithRetryAvailable: {
                      result: false,
                    },
                    failureWithRetryAvailableOnRequired: {
                      result: false,
                    },
                    retriedFailureOnRequired: {
                      result: false,
                    },
                    truthy: {
                      result: true,
                    },
                    falsy: {
                      result: false,
                    },
                  },
                ),
              },
            },
            {
              target: 'checkRequired',
              cond: {
                type: 'formFilterLogic',
                logic: dataStructure.financialProductsKnowledge.logic.hasKnowledge.make.makeIsQuestionnaireCorrectLogic(
                  product,
                  {
                    noQuestions: {
                      result: false,
                    },
                    notSelected: {
                      result: false,
                    },
                    notAnswered: {
                      result: false,
                    },
                    success: {
                      result: false,
                    },
                    failure: {
                      result: true,
                    },
                    failureWithRetryAvailable: {
                      result: false,
                    },
                    failureWithRetryAvailableOnRequired: {
                      result: false,
                    },
                    retriedFailureOnRequired: {
                      result: true,
                    },
                    truthy: {
                      result: true,
                    },
                    falsy: {
                      result: false,
                    },
                  },
                ),
              },
            },
          ],
        },
      },
      directionBackward: {
        on: {
          '': [
            {
              target: 'success',
              cond: {
                type: 'formFilterLogic',
                logic: dataStructure.financialProductsKnowledge.logic.hasKnowledge.make.makeIsQuestionnaireCorrectLogic(
                  product,
                  {
                    noQuestions: {
                      result: false,
                    },
                    notSelected: {
                      result: false,
                    },
                    notAnswered: {
                      result: false,
                    },
                    success: {
                      result: true,
                    },
                    failure: {
                      result: false,
                    },
                    failureWithRetryAvailable: {
                      result: false,
                    },
                    failureWithRetryAvailableOnRequired: {
                      result: false,
                    },
                    retriedFailureOnRequired: {
                      result: false,
                    },
                    truthy: {
                      result: true,
                    },
                    falsy: {
                      result: false,
                    },
                  },
                ),
              },
            },
            {
              target: 'checkRequired',
              cond: {
                type: 'formFilterLogic',
                logic: dataStructure.financialProductsKnowledge.logic.hasKnowledge.make.makeIsQuestionnaireCorrectLogic(
                  product,
                  {
                    noQuestions: {
                      result: false,
                    },
                    notSelected: {
                      result: false,
                    },
                    notAnswered: {
                      result: false,
                    },
                    success: {
                      result: false,
                    },
                    failure: {
                      result: true,
                    },
                    failureWithRetryAvailable: {
                      result: false,
                    },
                    failureWithRetryAvailableOnRequired: {
                      result: false,
                    },
                    retriedFailureOnRequired: {
                      result: true,
                    },
                    truthy: {
                      result: true,
                    },
                    falsy: {
                      result: false,
                    },
                  },
                ),
              },
            },
            {
              target: 'questions',
              cond: {
                type: 'formFilterLogic',
                logic: dataStructure.financialProductsKnowledge.logic.hasKnowledge.make.makeIsQuestionnaireCorrectLogic(
                  product,
                  {
                    noQuestions: {
                      result: false,
                    },
                    notSelected: {
                      result: false,
                    },
                    notAnswered: {
                      result: true,
                    },
                    success: {
                      result: false,
                    },
                    failure: {
                      result: false,
                    },
                    failureWithRetryAvailable: {
                      result: false,
                    },
                    failureWithRetryAvailableOnRequired: {
                      result: false,
                    },
                    retriedFailureOnRequired: {
                      result: false,
                    },
                    truthy: {
                      result: true,
                    },
                    falsy: {
                      result: false,
                    },
                  },
                ),
              },
            },
          ],
        },
      },
      questions: {
        ...(service === AccountType.RTO
          ? {
            initial: 'initial',
          }
          : {
            type: 'parallel' as const,
          }
        ),
        states: {
          ...(service === AccountType.RTO
            ? {
              initial: {
                on: {
                  '': [
                    {
                      target: 'experience',
                      cond: {
                        type: 'formFilterLogic',
                        logic: dataStructure.financialProductsExperience.logic.hasExperience.getMakeHasFinancialProductExperienceLogic(product)({
                          alternatives: {
                            hasExperience: {
                              result: false,
                            },
                            hasNotExperience: {
                              result: false,
                            },
                            notAnswered: {
                              result: true,
                            },
                          },
                        }),
                      },
                    },
                    ...makeInitialKnowledgeQuestion(product, service),
                  ],
                },
              },
              done: {
                type: 'final' as const,
              },
            }
            : {}
          ),
          ...makeExperienceQuestionnaireStep(product, service === AccountType.RTO ? `RTO${voca.capitalize(productNamesMap[product])}Question0` : 'done', service !== AccountType.RTO),
          ...makeProductQuestions(product, service),
        },
        onDone: makeQuestionnaireDone(product),
      },
      checkRequired: {
        initial: 'initial',
        states: {
          initial: {
            on: {
              '': [{
                target: 'loading',
                cond: {
                  type: 'formFilterLogic',
                  logic: dataStructure.financialProducts.logic.KERequired.getMakeKERequiredLogic(product)({
                    alternatives: {
                      required: {
                        result: true,
                      },
                      free: {
                        result: false,
                      },
                      notRequired: {
                        result: false,
                      },
                    },
                  }),
                  parentName: '',
                },
              },
              {
                target: 'done',
              }],
            },
          },
          loading: {
            initial: 'initial',
            states: {
              initial: {
                on: {
                  '': [
                    {
                      target: 'setting',
                      cond: {
                        type: 'isProductAccessible',
                        product: AccountType.RTO,
                        is: false,
                      },
                    },
                    {
                      target: 'done',
                    },
                  ],
                },
              },
              setting: {
                invoke: {
                  src: {
                    type: 'blockRtoServiceAccessibility',
                  },
                  onDone: 'done',
                },
              },
              done: {
                type: 'final' as const,
              },
            },
            onDone: 'done',

          },
          done: {
            type: 'final' as const,
          },
        },
        onDone: [
          {
            target: 'failedKnowledge',
            cond: {
              type: 'formFilterLogic',
              logic: dataStructure.financialProducts.logic.KERequired.getMakeKERequiredLogic(product)({
                alternatives: {
                  required: {
                    result: true,
                  },
                  free: {
                    result: false,
                  },
                  notRequired: {
                    result: false,
                  },
                },
              }),
              parentName: '',
            },
          },
          {
            target: 'failedKnowledge',
            cond: {
              type: 'formFilterLogic',
              logic: dataStructure.financialProductsKnowledge.logic.hasKnowledge.make.makeIsQuestionnaireCorrectLogic(
                product,
                {
                  noQuestions: {
                    result: false,
                  },
                  notSelected: {
                    result: false,
                  },
                  notAnswered: {
                    result: false,
                  },
                  success: {
                    result: false,
                  },
                  failure: {
                    result: true,
                  },
                  failureWithRetryAvailable: {
                    result: true,
                  },
                  failureWithRetryAvailableOnRequired: {
                    result: true,
                  },
                  retriedFailureOnRequired: {
                    result: true,
                  },
                  truthy: {
                    result: true,
                  },
                  falsy: {
                    result: false,
                  },
                },
              ),
            },
          },
          {
            target: 'done',
          },
        ],
      },
      failedKnowledge: {
        initial: 'setValue',
        states: {
          setValue: {
            invoke: {
              id: 'setFailedKnowledge',
              src: {
                type: 'setHasKnowledge',
                product,
                value: false,
              },
              onDone: 'checkRequired',
            },
          },
          checkRequired: {
            on: {
              '': [{
                target: 'required',
                cond: {
                  type: 'formFilterLogic',
                  logic: dataStructure.financialProducts.logic.KERequired.getMakeKERequiredLogic(product)({
                    alternatives: {
                      required: {
                        result: true,
                      },
                      free: {
                        result: false,
                      },
                      notRequired: {
                        result: false,
                      },
                    },
                  }),
                  parentName: '',
                },
              },
              {
                target: 'canFail',
              }],
            },
          },
          required: {
            initial: 'setValue',
            states: {
              setValue: {
                invoke: {
                  src: 'setServiceAccessibilityFalse',
                  onDone: 'done',
                  onError: 'done',
                },
              },
              done: {},
            },
            on: {
              ...makeGoTo('#onboarding.orientation', 'serviceSelection', { fullPath: true }),
            },
          },
          canFail: {
            on: makeNext('done'),
          },
          done: {
            type: 'final' as const,
          },
        },
        on: {
          [NavAction.PREVIOUS]: [
            makePrev('done', {
              cond: {
                type: 'formFilterLogic',
                logic: dataStructure.financialProductsKnowledge.logic.hasKnowledge.make.makeIsQuestionnaireCorrectLogic(
                  product,
                  {
                    noQuestions: {
                      result: false,
                    },
                    notSelected: {
                      result: false,
                    },
                    notAnswered: {
                      result: false,
                    },
                    success: {
                      result: false,
                    },
                    failure: {
                      result: true,
                    },
                    failureWithRetryAvailable: {
                      result: true,
                    },
                    failureWithRetryAvailableOnRequired: {
                      result: true,
                    },
                    retriedFailureOnRequired: {
                      result: true,
                    },
                    truthy: {
                      result: true,
                    },
                    falsy: {
                      result: false,
                    },
                  },
                ),
                parentName: '',
              },
            })[NavAction.PREVIOUS],
            makePrev('questions')[NavAction.PREVIOUS],
          ],
        },
        onDone: 'done',
      },
      success: {
        initial: 'setValue',
        states: {
          setValue: {
            invoke: {
              id: 'setKnowledgeSuccess',
              src: {
                type: 'setHasKnowledge',
                product,
                value: true,
              },
              onDone: 'done',
            },
          },
          done: {
            type: 'final' as const,
          },
        },
        on: {
          [NavAction.PREVIOUS]: [
            makePrev('done', {
              cond: {
                type: 'formFilterLogic',
                logic: dataStructure.financialProductsKnowledge.logic.hasKnowledge.make.makeIsQuestionnaireCorrectLogic(
                  product,
                  {
                    noQuestions: {
                      result: false,
                    },
                    notSelected: {
                      result: false,
                    },
                    notAnswered: {
                      result: false,
                    },
                    success: {
                      result: false,
                    },
                    failure: {
                      result: true,
                    },
                    failureWithRetryAvailable: {
                      result: true,
                    },
                    failureWithRetryAvailableOnRequired: {
                      result: true,
                    },
                    retriedFailureOnRequired: {
                      result: true,
                    },
                    truthy: {
                      result: true,
                    },
                    falsy: {
                      result: false,
                    },
                  },
                ),
                parentName: '',
              },
            })[NavAction.PREVIOUS],
            makePrev('questions')[NavAction.PREVIOUS],
          ],
          ...makeNext('done'),
        },
      },
      done: {
        type: 'final' as const,
      },
    }
    : {
      initial: {
        type: 'final' as const,
      },
    }),
});

export const makeProductGuardOrSkip = (questionnaireName: dataStructure.financialProducts.listType, service: dataStructure.accountType.listType, product: STypeGrp) => makeGuardOrSkip(
  makeQuestionnaireSkips(questionnaireName, product),
  'notSkipped',
  makeProductQuestionnaire(questionnaireName, service),
);

export const makeKnowledgeAndExperience = (service: dataStructure.accountType.listType) => ({
  initial: 'initialize',
  meta: {
    step: true,
    title: () => <Trans>Connaissance et expérience</Trans>,
    icon: IconName.BookOpen,
  },
  states: {
    initialize: {
      invoke: {
        src: 'resetOneDayOldFailedQuestionnaires',
        onDone: {
          target: 'directionChoice',
          actions: ['save'],
        },
        onError: {
          target: 'directionChoice',
          actions: ['save'],
        },
      },
    },
    directionChoice: {
      on: {
        '': [
          {
            target: 'stockQuestionnaire',
            cond: 'directionForward',
          },
          {
            target: 'trackerAndETFQuestionnaire',
            cond: 'directionBackward',
          },
        ],
      },
    },
    stockQuestionnaire: {
      ...makeProductGuardOrSkip(STypeGrp.ACTIONS, service, STypeGrp.ACTIONS),
      id: `${service}stockQuestionnaire`,
      on: {
        ...makePrev('bondQuestionnaire'),
      },
      onDone: [
        {
          target: 'fundQuestionnaire',
          cond: 'directionForward',
        },
        {
          target: 'done',
          cond: 'directionBackward',
        },
      ],
    },
    fundQuestionnaire: {
      ...makeProductGuardOrSkip(STypeGrp.FONDS, service, STypeGrp.FONDS),
      id: `${service}fundQuestionnaire`,
      on: {
        ...makePrev('stockQuestionnaire'),
      },
      onDone: [
        {
          target: 'bondQuestionnaire',
          cond: 'directionForward',
        },
        {
          target: 'stockQuestionnaire',
          cond: 'directionBackward',
        },
      ],
    },
    bondQuestionnaire: {
      ...makeProductGuardOrSkip(STypeGrp.OBLIGATIONS, service, STypeGrp.OBLIGATIONS),
      id: `${service}bondQuestionnaire`,
      onDone: [
        { target: 'subordinatedBondQuestionnaire', cond: 'directionForward' },
        { target: 'fundQuestionnaire', cond: 'directionBackward' },
      ],
    },
    subordinatedBondQuestionnaire: {
      ...makeProductGuardOrSkip(STypeGrp.OBLIGATIONS_SUB, service, STypeGrp.OBLIGATIONS_SUB),
      id: `${service}subordinatedBondQuestionnaire`,
      on: {
        ...makePrev('bondQuestionnaire'),
      },
      onDone: [
        { target: 'perpetualBondQuestionnaire', cond: 'directionForward' },
        { target: 'bondQuestionnaire', cond: 'directionBackward' },
      ],
    },
    perpetualBondQuestionnaire: {
      ...makeProductGuardOrSkip(STypeGrp.OBLIGATIONS_PERP, service, STypeGrp.OBLIGATIONS_PERP),
      id: `${service}perpetualBondQuestionnaire`,
      on: {
        ...makePrev('subordinatedBondQuestionnaire'),
      },
      onDone: [
        { target: 'trackerAndETFQuestionnaire', cond: 'directionForward' },
        { target: 'subordinatedBondQuestionnaire', cond: 'directionBackward' },
      ],
    },
    trackerAndETFQuestionnaire: {
      ...makeProductGuardOrSkip(STypeGrp.TRACKERS, service, STypeGrp.TRACKERS),
      id: `${service}trackerAndETFQuestionnaire`,
      on: {
        ...makePrev('perpetualBondQuestionnaire'),
      },
      onDone: [
        { target: 'rawMaterialTrackerQuestionnaire', cond: 'directionForward' },
        { target: 'perpetualBondQuestionnaire', cond: 'directionBackward' },
      ],
    },
    rawMaterialTrackerQuestionnaire: {
      ...makeProductGuardOrSkip(STypeGrp.TRACKERS_MAT, service, STypeGrp.TRACKERS_MAT),
      id: `${service}rawMaterialTrackerQuestionnaire`,
      on: {
        ...makePrev('trackerAndETFQuestionnaire'),
      },
      onDone: [
        { target: 'done', cond: 'directionForward' },
        { target: 'trackerAndETFQuestionnaire', cond: 'directionBackward' },
      ],
    },
    done: {
      ...(service === AccountType.RTO
        ? {}
        : {
          entry: [{ type: 'setStepReached', stepName: 'info_questionnaire' }],
        }),
      type: 'final' as const,
    },
  },
});
