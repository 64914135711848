/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { useIsHighlighted } from '../table/context/highlightContext';
import goldIcon from './GE-icon-GOLD.svg';
import goldIconNeg from './GE-icon-GOLD-neg.svg';

const goldIconStyle: ouiStyle.InStyle = {
  lines: 6,
  widthLines: 6,
};

const goldIconCss = ouiStyle.makeCss(goldIconStyle);

export const GoldIcon = () => {
  const isHighlighted = useIsHighlighted();
  return (
    <img
      src={!isHighlighted ? goldIcon : goldIconNeg}
      alt="icone du compte Gold"
      css={goldIconCss}
    />
  );
};
