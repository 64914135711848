import * as React from 'react';
import { RelativeTimeToNow } from '../../../../../../Components/relativeTime';
import { usePersonDeviceParseLastAuthenticationDate } from '../hooks';

export const PersonsDevicesLastAuthenticationRelativeToNow = () => {
  const {
    data,
  } = usePersonDeviceParseLastAuthenticationDate();
  return data && data !== 'no date' ? (
    <RelativeTimeToNow
      startDate={data}
      styleFormat="long"
    />
  ) : null;
};
