import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import {
  DefaultTextInputContent,
  DefaultTextInputPlaceHolderContent,
  DefaultTextInputMainContent,
  DefaultTextInputComponentContainer,
  DefaultTextInputContextProvider,
  DefaultTextInputBase,
} from '../components';
import { DefaultInputFooterErrorContent, inputRequiredErrorMessage } from '../../shared';
import { InputRefContextProvider } from '../../../context';
import { OnChangeDefaultHandlerEnhanceContext } from '../../../../utils';
import { RifmContextProvider } from '../context';

export const textInputDefaultTopModelsObject = {
  InputBase: <TopModel name="InputBase">{DefaultTextInputBase}</TopModel>,
  InputContextProvider: (
    <TopModel name="InputContextProvider">
      {DefaultTextInputContextProvider}
    </TopModel>
  ),
  RefContextProvider: (
    <TopModel name="RefContextProvider">{InputRefContextProvider}</TopModel>
  ),
  OnChangeContextProvider: (
    <TopModel name="OnChangeContextProvider">
      {OnChangeDefaultHandlerEnhanceContext}
    </TopModel>
  ),
  RifmContextProvider: (
    <TopModel name="RifmContextProvider">
      {RifmContextProvider}
    </TopModel>
  ),
  InputContent: (
    <TopModel name="InputContent">{DefaultTextInputContent}</TopModel>
  ),
  InputPlaceHolderContent: (
    <TopModel name="InputPlaceHolderContent">
      {DefaultTextInputPlaceHolderContent}
    </TopModel>
  ),
  InputMainContent: (
    <TopModel name="InputMainContent">{DefaultTextInputMainContent}</TopModel>
  ),
  InputComponentContainer: (
    <TopModel name="InputComponentContainer">
      {DefaultTextInputComponentContainer}
    </TopModel>
  ),
  InputFooterErrorContent: (
    <TopModel name="InputFooterErrorContent">
      {DefaultInputFooterErrorContent}
    </TopModel>
  ),
  InputRequiredError: (
    <TopModel name="InputRequiredError">{inputRequiredErrorMessage}</TopModel>
  ),
};
