import * as React from 'react';
import { ErrorBoundary } from '../../../../../../../Components/ErrorBoundary';
import { ActorApiDateDisplay } from '../../../../../../components/display/date';
import { useBondsBondCalculator } from '../../hooks';

const BondsBondNextCouponInner = (props: {
  withRedactedIcon?: boolean,
}) => {
  const {
    withRedactedIcon,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useBondsBondCalculator();
  const nextCoupon = data ? data.nextCoupon : data;
  return (
    <ActorApiDateDisplay
      data={nextCoupon}
      isLoading={isLoading}
      error={error}
      formatMq={false}
      withRedactedIcon={withRedactedIcon}
    />
  );
};

export const BondsBondNextCoupon = (props: {
  withRedactedIcon?: boolean,
}) => {
  const {
    withRedactedIcon,
  } = props;
  return (
    <ErrorBoundary renderNull>
      <BondsBondNextCouponInner withRedactedIcon={withRedactedIcon} />
    </ErrorBoundary>
  );
};
