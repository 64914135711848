import * as React from 'react';
import { useAccountPositionsHistoryHisMvtPriceOpe, useAccountPositionsHistoryCCurrencyCodeByHisMvtId } from '../../hooks';
import { ActorApiNumberDisplayCurrency } from '../../../../../../components/display/number/currency';

export const AccountPositionsHistoryHisMvtAmountPriceOpe = (props: {
  withSpaceAfter?: boolean,
  withSpaceBefore?: boolean,
}) => {
  const {
    withSpaceAfter,
    withSpaceBefore,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useAccountPositionsHistoryHisMvtPriceOpe();
  const {
    data: currencyData,
    isLoading: currencyIsLoading,
    error: currencyError,
  } = useAccountPositionsHistoryCCurrencyCodeByHisMvtId();
  return (
    <ActorApiNumberDisplayCurrency
      data={data}
      isLoading={isLoading}
      error={error}
      currencyData={currencyData}
      currencyIsLoading={currencyIsLoading}
      currencyError={currencyError}
      withSpaceAfter={withSpaceAfter}
      withSpaceBefore={withSpaceBefore}
    />
  );
};
