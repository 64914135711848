import ouiBase from '@goldwasserexchange/oui';
import { AccountType, DurabilityPreferences, } from '../../../../../types';
var onboardingPath = 'onboarding';
var selectedService = 'selectedService';
var durability = 'durability';
var preferences = 'preferences';
var makeLogic = function (truthy, falsy) { return ({
    name: "".concat(onboardingPath, ".").concat(selectedService),
    is: [AccountType.CONSEIL, AccountType.GESTION],
    options: {
        matcher: ouiBase.utils.match.arrayMatcherNames.some,
    },
    alternatives: {
        truthy: {
            name: "".concat(onboardingPath, ".").concat(durability, ".").concat(preferences),
            is: DurabilityPreferences.SPECIFIC_TRUE,
            alternatives: {
                truthy: {
                    result: truthy,
                },
                falsy: {
                    result: falsy,
                },
            },
        },
        falsy: {
            result: falsy,
        },
    },
}); };
export var showLogic = makeLogic('visible', 'invisible');
export var showLogicBoolean = makeLogic(true, false);
