/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
var youtubeVideoStyle = {
    position: 'absolute',
    widthLines: 'full',
    lines: 'full',
    top: 0,
    left: 0,
};
var youtubeVideoCss = ouiStyle.makeCss(youtubeVideoStyle);
var InternalYoutubeContent = function (props) {
    var v = props.v, description = props.description;
    return (jsx("iframe", { css: youtubeVideoCss, src: "https://www.youtube.com/embed/".concat(v), title: description, allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share", allowFullScreen: true }));
};
var tryGetVideoId = function (link) {
    var url = new URL(link);
    switch (url.hostname) {
        case 'www.youtube.com':
            return url.searchParams.get('v');
        case 'youtu.be':
            return url.pathname.slice(1);
        default:
            return null;
    }
};
export var YoutubeContent = function (props) {
    var link = props.link, description = props.description;
    var v = tryGetVideoId(link);
    if (!v) {
        throw new Error("Cannot extract video id from link: ".concat(link));
    }
    return (jsx(InternalYoutubeContent, { v: v, description: description }));
};
