import { GetBondsCouponFormulasResponse } from '@goldwasserexchange/actor/rest-services';
import memoize from 'memoizee';
import { BondsCall } from '../calls/type';

export function findByEvtEntrySId(data: BondsCall[], evtEntrySId: string): BondsCall | undefined
export function findByEvtEntrySId(data: GetBondsCouponFormulasResponse[], evtEntrySId: string): GetBondsCouponFormulasResponse | undefined
export function findByEvtEntrySId(data: (BondsCall | GetBondsCouponFormulasResponse)[], evtEntrySId: string): BondsCall | GetBondsCouponFormulasResponse | undefined {
  return data.find((securityBondLine) => `${securityBondLine['EVT_ENTRY_S.ID']}` === evtEntrySId);
}

export const memoFindByEvtEntrySId = memoize(findByEvtEntrySId);
