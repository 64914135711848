export type EventTypeString = 'LINK_CLICK';

export const eventType: EventTypeString = 'LINK_CLICK';

export type EventType = {
  type: EventTypeString,
};

export const eventBuilder = (): EventType => ({
  type: eventType,
});
