import * as React from 'react';
import { useBondsBondIsRedacted, useBondsBondSAccIssueDate } from '../../hooks';
import { ActorApiDateDisplay } from '../../../../../../components/display/date';

export const BondsBondSAccIssueDate = (props: {
  withRedactedIcon?: boolean,
}) => {
  const {
    withRedactedIcon,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useBondsBondSAccIssueDate();
  const {
    data: redacted,
    isLoading: redactedIsLoading,
  } = useBondsBondIsRedacted('S_ACC.ISSUE_DATE');
  return (
    <ActorApiDateDisplay
      data={data}
      isLoading={isLoading || redactedIsLoading}
      error={error}
      formatMq={false}
      isBlocked={redacted}
      withRedactedIcon={withRedactedIcon}
    />
  );
};
