import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { Locale } from '@goldwasserexchange/i18n';
import {
  createPath,
} from 'history';
import { locationAtom, pathnamePartFamilly, splittedLocationPathnameAtom } from '../../atoms';
import { getStorageLanguage, isLocale } from './utils';

export const pathnameRawUrlLanguageAtom = atom((get) => {
  const language = get(pathnamePartFamilly(0));
  if (language == null) {
    return '';
  }
  return language;
});

export const pathnameUrlLanguageAtom = atom((get) => {
  const rawUrlLanguage = get(pathnameRawUrlLanguageAtom);
  const language = rawUrlLanguage !== '' && isLocale(rawUrlLanguage)
    ? rawUrlLanguage
    : getStorageLanguage();
  return language;
});

const createLocalePathNameAtom = (locale: Locale) => atom((get) => {
  const location = get(locationAtom);
  const splittedPathname = get(splittedLocationPathnameAtom);
  const newLocation = {
    ...location,
    pathname: `/${[locale, ...splittedPathname.slice(1)].join('/')}`,
  };
  return createPath(newLocation);
});

export const localePathFamilyAtom = atomFamily((locale: Locale) => createLocalePathNameAtom(locale));

export const localePathFrAtom = localePathFamilyAtom(Locale.FR);

export const localePathNlAtom = localePathFamilyAtom(Locale.NL);
