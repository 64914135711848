import { ChangeEventHandler, InputHTMLAttributes, useCallback } from 'react';
import { OverrideParams, useNavigateCleanBondParamsUrl } from '../../../clean';
import { isCurrencyFilter } from '../currencyFilters';
import { isMaturityFilter } from '../maturityFilters';
import { BooleanSearchAndFilterBondParams } from '../type';
import { useBooleanFilterValue } from './useBooleanFilterValue';

const MIN_YIELD = 4;

const getFieldValue = (field: BooleanSearchAndFilterBondParams) => {
  if (isCurrencyFilter(field)) {
    return 'C_CURRENCY.CODE';
  }
  if (isMaturityFilter(field)) {
    return 'S_ACC.MATURITY';
  }
  return field;
};

const getValue = (checked: boolean, field: BooleanSearchAndFilterBondParams) => {
  if (!checked) {
    return undefined;
  }
  if (field === 'eur') {
    return 'EUR';
  }
  if (field === 'usd') {
    return 'USD';
  }
  if (field === 'foreignCurrencies') {
    return 'foreign';
  }
  if (field === 'perpetual') {
    return 'perpetual';
  }
  if (field === 'lessThanFiveYears') {
    return 'lessThanFiveYears';
  }
  if (field === 'S_MARKET.YIELD') {
    return MIN_YIELD;
  }
  return 'true';
};

const makeToggleBondFilterValueOnChange: (props: {
  value: boolean,
  field: BooleanSearchAndFilterBondParams,
  navigate: (type: 'push' | 'replace', overrideParams: OverrideParams) => void,
}) => ChangeEventHandler<HTMLInputElement> = (props) => (e) => {
  const {
    value,
    field,
    navigate,
  } = props;
  if (value !== e.target.checked) {
    navigate('replace', {
      [getFieldValue(field)]: getValue(e.target.checked, field),
      page: 1,
    });
  }
};

export const useToggleBondFilterValueOnChange = (field: BooleanSearchAndFilterBondParams): InputHTMLAttributes<HTMLInputElement>['onChange'] => {
  const value = useBooleanFilterValue(field);
  const navigate = useNavigateCleanBondParamsUrl();
  const onChange = useCallback(makeToggleBondFilterValueOnChange({
    value,
    field,
    navigate,
  }), [value, field, navigate]);
  return onChange;
};
