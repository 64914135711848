import React from 'react';
import { makeName, dataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import ouiBase from '@goldwasserexchange/oui';
import { ParentNameProps } from '../../../../Form/utils';
import { RadioListInput } from '../../../../../OUI-dom/Inputs';
import { OnChangeNumberHandlerEnhanceContext } from '../../../../../OUI';

const isFinancialSituationListType = (question: dataStructure.financialSituation.listType | dataStructure.investmentGoals.listType): question is dataStructure.financialSituation.listType => dataStructure.financialSituation.list.includes(question as dataStructure.financialSituation.listType);

const isInvestmentGoalsListType = (question: dataStructure.financialSituation.listType | dataStructure.investmentGoals.listType): question is dataStructure.investmentGoals.listType => dataStructure.investmentGoals.list.includes(question as dataStructure.investmentGoals.listType);

function InfoQuestionnaireLabelOnPrimaryKey<T extends dataStructure.financialSituation.listType | dataStructure.investmentGoals.listType>(props: { question: T, tableName: typeof dataStructure.pathTableMap[keyof typeof dataStructure.pathTableMap] }) {
  const { question, tableName } = props;
  const primaryKey = ouiBase.utils.dimensions.useCurrentPrimaryDimension() as T extends dataStructure.financialSituation.listType ? keyof dataStructure.financialSituation.labels.LabelsType[T]['values'] : T extends dataStructure.investmentGoals.listType ? keyof dataStructure.investmentGoals.labels.LabelsType[T]['values'] : never;
  if (tableName === 'financialSituation' && isFinancialSituationListType(question)) {
    return <dataStructure.financialSituation.labels.ValueLabel question={question} primaryKey={primaryKey} />;
  }
  if (tableName === 'investmentGoals' && isInvestmentGoalsListType(question)) {
    return <dataStructure.investmentGoals.labels.ValueLabel question={question} primaryKey={primaryKey} />;
  }
  return null;
}

const numberPaths: (dataStructure.financialSituation.listType | dataStructure.investmentGoals.listType)[] = [
  'netWorth',
  'monthlyExpenses',
  'monthlyIncome',
  'cash',
];

export const InfoQuestionnaireInput = (props: ParentNameProps & { column: dataStructure.financialSituation.listType | dataStructure.investmentGoals.listType }): JSX.Element => {
  const { parentName, column } = props;
  const fieldPath = makeName(parentName, column);
  const tableName = dataStructure.pathTableMap[parentName];
  return (
    <RadioListInput
      fieldPath={fieldPath}
      list={dataStructure[tableName].fields.lists[column]}
    >
      <TopModel name="RadioListInputLabelContent" question={column}>
        {dataStructure[tableName].labels.Label}
      </TopModel>
      <TopModel name="RadioListInputListElementLabelContent" tableName={tableName} question={column}>
        {InfoQuestionnaireLabelOnPrimaryKey}
      </TopModel>
      {numberPaths.includes(column) ? (
        <TopModel name="RadioListInputListElementOnChangeContextProvider">
          {OnChangeNumberHandlerEnhanceContext}
        </TopModel>
      ) : null}
    </RadioListInput>
  );
};
