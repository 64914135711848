/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { BondSAccIdProvider } from '../../../../../../actor/api/Services/bonds';

const style: ouiStyle.InStyleWithMediaQueries = {};

export const SanityBondFieldContainer = (props: React.PropsWithChildren<{
  id: number,
  bold?: boolean,
  italic?: boolean,
  underline?: boolean,
  strike?: boolean,
}>) => {
  const {
    id,
    bold = false,
    italic = false,
    underline = false,
    strike = false,
    children,
  } = props;
  const css = React.useMemo(
    () => ouiStyle.mediaQuery(ouiStyle.makeCss({
      ...style,
      color: ouiStyle.Constants.Colors.inherit,
      fontSize: 'inherit',
      fontWeight: bold ? ouiStyle.Constants.FontWeightProp.intermediary : ouiStyle.Constants.FontWeightProp.inherit,
      fontStyle: italic ? 'italic' : 'inherit',
      // eslint-disable-next-line no-nested-ternary
      textDecoration: underline ? 'underline' : strike ? 'line-through' : 'inherit',
    })),
    [
      bold,
      italic,
      underline,
      strike,
    ],
  );
  return (
    <span
      css={css}
    >
      <BondSAccIdProvider id={id}>
        {children}
      </BondSAccIdProvider>
    </span>
  );
};
