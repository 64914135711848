import { FlourishChart, FlourishStory, Facebook, Instagram, Linkedin, Pinterest, TikTok, TwitterOrX, Youtube, } from '../components';
var flourishVisualisationStart = 'https://public.flourish.studio/visualisation/';
var flourishStoryStart = 'https://public.flourish.studio/story/';
var facebookStart = 'https://www.facebook.com';
var instagramStart = 'https://www.instagram.com/';
var linkedinStart = 'https://www.linkedin.com';
var pinterestStart = 'https://www.pinterest.com/pin/';
var tiktokStart = 'https://www.tiktok.com';
var twitterStart = 'https://twitter.com';
var xStart = 'https://x.com/';
var youtubeStart = 'https://www.youtube.com';
var alternateYoutubeStart = 'https://youtu.be/';
var starts = [
    flourishVisualisationStart,
    flourishStoryStart,
    facebookStart,
    instagramStart,
    linkedinStart,
    pinterestStart,
    tiktokStart,
    twitterStart,
    xStart,
    youtubeStart,
    alternateYoutubeStart,
];
var linkStartsWith = function (link, start) { return link.startsWith(start); };
export var isFlourishVisualisation = function (link) { return linkStartsWith(link, flourishVisualisationStart); };
export var isFlourishStory = function (link) { return linkStartsWith(link, flourishStoryStart); };
export var isFacebook = function (link) { return linkStartsWith(link, facebookStart); };
export var isInstagram = function (link) { return linkStartsWith(link, instagramStart); };
export var isLinkedin = function (link) { return linkStartsWith(link, linkedinStart); };
export var isPinterest = function (link) { return linkStartsWith(link, pinterestStart); };
export var isTiktok = function (link) { return linkStartsWith(link, tiktokStart); };
var isTwitter = function (link) { return linkStartsWith(link, twitterStart); };
var isX = function (link) { return linkStartsWith(link, xStart); };
export var isTwitterOrX = function (link) { return isTwitter(link) || isX(link); };
export var isYoutubeBase = function (link) { return linkStartsWith(link, youtubeStart); };
export var isAlternateYoutube = function (link) { return linkStartsWith(link, alternateYoutubeStart); };
export var isYoutube = function (link) { return linkStartsWith(link, youtubeStart) || linkStartsWith(link, alternateYoutubeStart); };
export var isValidStarts = function (link) { return starts.some(function (start) { return linkStartsWith(link, start); }); };
export var isValidStartsOrThrow = function (link) {
    if (link == null) {
        throw new Error('no link given to Embedded');
    }
    if (!isValidStarts(link)) {
        throw new Error("invalid link given to Embedded: ".concat(link));
    }
    return true;
};
export var getComponent = function (link) {
    if (isFlourishVisualisation(link)) {
        return FlourishChart;
    }
    if (isFlourishStory(link)) {
        return FlourishStory;
    }
    if (isFacebook(link)) {
        return Facebook;
    }
    if (isInstagram(link)) {
        return Instagram;
    }
    if (isLinkedin(link)) {
        return Linkedin;
    }
    if (isPinterest(link)) {
        return Pinterest;
    }
    if (isTiktok(link)) {
        return TikTok;
    }
    if (isTwitterOrX(link)) {
        return TwitterOrX;
    }
    if (isYoutube(link)) {
        return Youtube;
    }
    throw new Error('unsupported embed');
};
