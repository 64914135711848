import { string, ref as refFn, StringSchema } from 'yup';
import { onlyDigits } from '@goldwasserexchange/utils';
import {
  pipe,
  splitAt,
  splitEvery,
  slice,
} from 'ramda';
import { checkVAT, countries } from 'jsvat';
import { UncastedDataStructure, UncastedPhysicalTAdd } from '@goldwasserexchange/oblis-frontend-utils';
import { format } from 'date-fns';
import voca from 'voca';
import {
  notAStringError,
  minError,
  maxError,
  lengthError,
  onlyNumbersError,
  hasAtLeastOneDigitError,
  hasAtLeastOneLowerCaseError,
  hasAtLeastOneUpperCaseError,
  hasAtLeastOneSpecialCharError,
  phoneError,
  equalsRefError,
  tvaNumberError,
  invalidBirthdateError,
  emailError,
  nationalRegisterAndBirthdateMismatch,
  nationalRegisterError,
  wrongLeiFormatError,
  invalidLeiError,
} from '../errornames';
import { makeRequired } from './mixed';
import { parseEuroDate } from './euroDate';

const birthdatePath: keyof Pick<UncastedPhysicalTAdd, 'BIRTH_DATE'> = 'BIRTH_DATE';

export const stringValidator = string().ensure().trim().typeError(notAStringError);

export const requiredStringValidator = makeRequired(stringValidator);

export const makeStringMin = (min: number) => (validator: StringSchema) => {
  const stringMin = validator.min(min, minError);
  return stringMin;
};

export const makeStringMax = (max: number) => (validator: StringSchema) => {
  const stringMax = validator.max(max, maxError);
  return stringMax;
};

export const makeStringLength = (length: number) => (
  validator: StringSchema,
) => {
  const stringLength = validator.length(length, lengthError);
  return stringLength;
};

export const isOnlyNumber = /^\d+$/;

export const makeStringOnlyNumbers = (validator: StringSchema) => {
  const stringOnlyNumbers = validator.matches(isOnlyNumber, {
    message: onlyNumbersError,
    excludeEmptyString: true,
  });
  return stringOnlyNumbers;
};

export const makeTransformOnlyDigits = (validator: StringSchema) => validator.transform((currentValue) => onlyDigits(currentValue));

const hasAtLeastOneDigitRegExp = /\d/;

export const makeStringHasAtLeastOneDigit = (validator: StringSchema) => {
  const stringHasAtLeastOneDigit = validator.matches(hasAtLeastOneDigitRegExp, {
    message: hasAtLeastOneDigitError,
    excludeEmptyString: true,
  });
  return stringHasAtLeastOneDigit;
};

const hasAtLeastOneLowerCaseRegExp = /[a-z]/;

export const makeStringHasAtLeastOneLowerCase = (validator: StringSchema) => {
  const stringHasAtLeastOneLowerCase = validator.matches(
    hasAtLeastOneLowerCaseRegExp,
    {
      message: hasAtLeastOneLowerCaseError,
      excludeEmptyString: true,
    },
  );
  return stringHasAtLeastOneLowerCase;
};

const hasAtLeastOneUpperCaseRegExp = /[A-Z]/;

export const makeStringHasAtLeastOneUpperCase = (validator: StringSchema) => {
  const stringHasAtLeastOneUpperCase = validator.matches(
    hasAtLeastOneUpperCaseRegExp,
    {
      message: hasAtLeastOneUpperCaseError,
      excludeEmptyString: true,
    },
  );
  return stringHasAtLeastOneUpperCase;
};

const hasAtLeastOneSpecialCharRegExp = /[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`]/; // eslint-disable-line unicorn/better-regex

// const specialChars = '^ $ * . [ ] { } ( ) ? - " ! @ # % & / \\ , > < \' : ; | _ ~ `';

export const makeStringHasAtLeastOneSpecialChar = (validator: StringSchema) => {
  const stringHasAtLeastOneSpecialChar = validator.matches(
    hasAtLeastOneSpecialCharRegExp,
    {
      message: hasAtLeastOneSpecialCharError,
      excludeEmptyString: true,
    },
  );
  return stringHasAtLeastOneSpecialChar;
};

export const replaceLocalPhoneNumberPrefix = (value: string) => {
  if (value.startsWith('0') && !value.startsWith('00')) {
    return value.replace(/^0/, '+32');
  }
  if (value.startsWith('00')) {
    return value.replace(/^00/, '+');
  }
  return value;
};

const phoneRegExp = /^((\+|00)[1-9]\d{8,14}|0[1-9]\d{8})$/;

export const makeStringPhoneNumber = (validator: StringSchema) => {
  const stringPhoneNumber = validator.transform(replaceLocalPhoneNumberPrefix).matches(phoneRegExp, {
    message: phoneError,
    excludeEmptyString: true,
  });
  return stringPhoneNumber;
};

const mobilePhoneRegExp = /^((\+|00)[1-9]\d{8,14}|04\d{8})$/;

export const makeStringMobilePhoneNumber = (validator) => {
  const stringMobilePhoneNumber = validator.transform(replaceLocalPhoneNumberPrefix).matches(
    mobilePhoneRegExp,
    {
      message: phoneError,
      excludeEmptyString: true,
    },
  );
  return stringMobilePhoneNumber;
};

export const makeStringEqualsRef = (ref: string) => (
  validator: StringSchema,
) => {
  const stringEqualsRef = validator.oneOf([refFn(ref), null], equalsRefError);
  return stringEqualsRef;
};

export const transformUppercase = (validator: StringSchema) => validator.uppercase();

export const passwordValidator = pipe(
  makeStringMin(8),
  makeStringHasAtLeastOneDigit,
  makeStringHasAtLeastOneLowerCase,
  makeStringHasAtLeastOneUpperCase,
  makeStringHasAtLeastOneSpecialChar,
)(stringValidator);

export const requiredPasswordValidator = makeRequired(passwordValidator);

export const repeatPasswordValidator = (ref: keyof UncastedDataStructure['auth']) => makeStringEqualsRef(ref)(stringValidator);

export const requiredRepeatPasswordValidator = (ref) => makeRequired(repeatPasswordValidator(ref));

const SANITIZE_LEI_REGEXP = /[^\dA-Z]/g;

const sanitizeLei = (value: string): string => {
  if (value === '') {
    return value;
  }
  return value.toUpperCase().replace(SANITIZE_LEI_REGEXP, '');
};

const VALID_LEI_FORMAT = /^[\dA-Z]{18}\d{2}$/;

const isCorrectLeiFormat = (value: string): boolean => {
  if (value === '') {
    return true;
  }
  return VALID_LEI_FORMAT.test(value);
};

const CHARCODE_0 = '0'.charCodeAt(0);
const CHARCODE_0_OFFSET = CHARCODE_0;
const CHARCODE_A = 'A'.charCodeAt(0);
const CHARCODE_A_OFFSET = CHARCODE_A - 10;

const convertCharToNumberString = (letter: string): string => {
  const charCode = letter.charCodeAt(0);
  if (charCode >= CHARCODE_A) {
    return `${charCode - CHARCODE_A_OFFSET}`;
  }
  return `${charCode - CHARCODE_0_OFFSET}`;
};

const mapStringByChar = (
  fn: (char: string, index: number, source: string[]) => string,
  str: string,
): string => [...str]
  .map((char, index, source) => fn(char, index, source))
  .join('');

const convertStringToCharCodeNumber = (str: string) => mapStringByChar(convertCharToNumberString, str);

const isValidLei = (value: string): boolean => {
  if (value === '') {
    return true;
  }
  const bigIntString = convertStringToCharCodeNumber(value);
  const moduloRemainder = BigInt(bigIntString) % BigInt(97);
  return moduloRemainder === BigInt(1);
};

export const leiValidator = stringValidator
  .transform(sanitizeLei)
  .test(wrongLeiFormatError, wrongLeiFormatError, isCorrectLeiFormat)
  .test(invalidLeiError, invalidLeiError, isValidLei);

export const requiredLeiValidator = makeRequired(leiValidator);

export const makeStringEmail = (validator) => validator.email(emailError);

export const emailValidator = (validator) => makeStringEmail(validator);

export const emailWithMaxLengthValidator = (validator) => makeStringMax(80)(emailValidator(validator));

export const requiredEmailWithMaxLengthValidator = (validator) => makeRequired(emailWithMaxLengthValidator(validator));

export const makeValidOrNotSupportedVAT = (validator) => validator.test(
  'isValidOrNotSupportedVAT',
  tvaNumberError,
  (value) => {
    if (!value) {
      return true;
    }
    const validationResult = checkVAT(value, countries);
    return validationResult.isSupportedCountry === false || validationResult.isValid === true;
  },
);

export const vatValidator = pipe(
  transformUppercase,
  makeValidOrNotSupportedVAT,
)(stringValidator);

const createCheckNumber = (value: number): number => 97 - (value % 97);

const isValidDayNumber = (dayNumber: number): boolean => dayNumber >= 1 && dayNumber <= 31;

const isValidMonthNumber = (monthNumber: number): boolean => monthNumber >= 1 && monthNumber <= 12;

const extractMonthAndDayNumbers = (registerNumber: string): number[] => {
  const [, monthString, dayString] = splitEvery(2, registerNumber);
  return [monthString, dayString].map((stringToParse: string): number => parseInt(stringToParse, 10));
};

const isValidModulo = (checkNumber: number, controlNumbers: number[]): boolean => controlNumbers
  .some((controlNumber: number): boolean => createCheckNumber(controlNumber) === checkNumber);

export const isValidBirthdate = (registerNumber: string): boolean => {
  const [monthNumber, dayNumber] = extractMonthAndDayNumbers(registerNumber);
  return isValidMonthNumber(monthNumber) && isValidDayNumber(dayNumber);
};

export const validBelgianRegisterNumber = (registerNumber: string): boolean => {
  const [toControlString, checkString] = splitAt(9, registerNumber);
  const checkNumber = parseInt(checkString, 10);
  const [toControl, toControl2k] = [parseInt(toControlString, 10), parseInt(`2${toControlString}`, 10)];
  return isValidModulo(checkNumber, [toControl, toControl2k]);
};

export const areMatchingBirthdateAndRegisterNumber = (registerNumber: string, birthdate: string): boolean => {
  const [registerNumberYearString, registerNumberMonthString, registerNumberDayString] = splitEvery(2, registerNumber);
  const [, birthYearString, birthMonthString, birthDayString] = splitEvery(2, birthdate);
  if (registerNumberYearString !== birthYearString
    || registerNumberMonthString !== birthMonthString
    || registerNumberDayString !== birthDayString
  ) {
    return false;
  }
  return true;
};

export const validBelgianRegisterNumberWithBirthdate = (registerNumber: string, birthdate: string): boolean => {
  const birthdateOnlyNumbers = onlyDigits(birthdate);
  const birthCenturyString = slice(0, 2, birthdateOnlyNumbers as string);
  const [toControlString, checkString] = splitAt(9, registerNumber);
  const checkNumber = parseInt(checkString, 10);
  const toControlNumber = birthCenturyString === '19'
    ? parseInt(toControlString, 10)
    : parseInt(`2${toControlString}`, 10);
  return isValidModulo(checkNumber, [toControlNumber]);
};

export const requiredNationalRegisterValidator = makeTransformOnlyDigits(
  makeStringLength(11)(
    makeRequired(stringValidator).test(
      'invalidBirthdateInRegisterNumber',
      invalidBirthdateError,
      (value: string) => isValidBirthdate(value),
    ).test(
      'registerNumberAndBirthdateMismatchInRegisterNumber',
      nationalRegisterAndBirthdateMismatch,
      function nationalRegistarAndBirthdateMismatchValidator(this: any, value: string) {
        return areMatchingBirthdateAndRegisterNumber(value, this.parent[birthdatePath] ? format(parseEuroDate(this.parent[birthdatePath]), 'yyyyMMdd') : '');
      },
    ).test(
      'nationalRegisterAndBirthdateMismatch',
      nationalRegisterError,
      function nationalRegisterErrorValidator(this: any, value: string) {
        return validBelgianRegisterNumberWithBirthdate(value, this.parent[birthdatePath] ? format(parseEuroDate(this.parent[birthdatePath]), 'yyyyMMdd') : '');
      },
    ),
  ),
);

type CapitalizeOptions = {
  restToLower?: boolean,
  notWithSpace?: boolean,
};

const capitalizeString = (options: CapitalizeOptions) => (value: string): string => {
  const {
    restToLower = true,
    notWithSpace = false,
  } = options;
  if (value === '' || (notWithSpace && value.includes(' '))) {
    return value;
  }
  if (restToLower) {
    return voca.titleCase(voca.lowerCase(value));
  }
  return voca.titleCase(value);
};

export const makeCapitalized = (options: CapitalizeOptions, validator) => validator.transform(capitalizeString(options));
