import { Trans } from '@lingui/macro';
import * as React from 'react';

export const premiumIntro = (
  <Trans>
    Vous cherchez un partenaire qui vous conseille
  </Trans>
);

export const premiumTwoLinesIntro = (
  <Trans>
    Vous cherchez un partenaire
    <br />
    qui vous conseille
  </Trans>
);
