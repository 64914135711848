var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as React from 'react';
import { scrollTopContext } from './context';
export function ScrollTopContextProvider(props) {
    var children = props.children;
    var _a = __read(React.useState(0), 2), scrollTop = _a[0], setScrollTop = _a[1];
    var onScroll = React.useCallback(function (e) {
        var _a;
        if (e.target && 'scrollTop' in e.target) {
            setScrollTop((_a = e.target.scrollTop) !== null && _a !== void 0 ? _a : 0);
        }
    }, [setScrollTop]);
    var returnValue = React.useMemo(function () { return [scrollTop, onScroll, setScrollTop]; }, [scrollTop, onScroll, setScrollTop]);
    return (React.createElement(scrollTopContext.Provider, { value: returnValue }, children));
}
