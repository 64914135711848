/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { IconName } from '../../Icons';
import { ArrayListIcon } from './icon';

const boxAsideStyle: ouiStyle.InStyle = {
  alignSelf: ouiStyle.Constants.AlignSelfProp.center,
  padding: 'simple',
  textAlign: 'center',
};

const boxAsideCss = ouiStyle.makeCss(boxAsideStyle);

export const BoxAside = (props: React.PropsWithChildren<{
  iconName: IconName,
  cardWidth: number,
}>) => {
  const {
    iconName,
    cardWidth,
    children,
  } = props;
  return (
    <aside
      css={boxAsideCss}
      style={{ width: `${cardWidth}rem` }}
    >
      <ArrayListIcon
        iconName={iconName}
      />
      {children}
    </aside>
  );
};
