import * as React from 'react';
import { ActorApiStringDisplay } from '../../../../../../components/display/string';
import { useBondsBondIsRedacted, useBondsBondSAccIsin } from '../../hooks';

export const BondsBondSAccIsin = (props: {
  withRedactedIcon?: boolean,
}) => {
  const {
    withRedactedIcon,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useBondsBondSAccIsin();
  const {
    data: redacted,
    isLoading: redactedIsLoading,
  } = useBondsBondIsRedacted('S_ACC.ISIN');
  return (
    <ActorApiStringDisplay
      data={data}
      isLoading={isLoading || redactedIsLoading}
      error={error}
      loadingValue="----------"
      isBlocked={redacted}
      withRedactedIcon={withRedactedIcon}
      placeHolderOnBlocked={false}
    />
  );
};
