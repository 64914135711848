/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const stepperPlaceHolderContainerStyle: ouiStyle.InStyle = {
  position: 'relative',
  overflow: 'hidden',
};

const stepperPlaceHolderContainerCss = ouiStyle.makeCss(stepperPlaceHolderContainerStyle);

export const StepperPlaceholderContainer = (props: React.PropsWithChildren<{
  height: string,
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>,
}>) => {
  const {
    height,
    onMouseEnter,
    children,
  } = props;
  return (
    <div
      css={stepperPlaceHolderContainerCss}
      style={{
        height,
      }}
      onMouseEnter={onMouseEnter}
    >
      {children}
    </div>
  );
};
