/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Trans } from '@lingui/macro';
// import { Space } from '../../../../../../../../../Components/Space';
// import { OnboardingStatusLink } from '../link';

export const MultipleOnboardingMessage = (_props: {
  openInNewWindow?: boolean,
}) => (
  <Trans>
    Vous avez des demandes d'ouvertures de comptes en attente.
  </Trans>
);
