/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const routeSlideStyle: ouiStyle.InStyle = {
  overflowX: 'hidden',
  overflowY: 'hidden',
  flex: 'one',
  widthLines: 'full',
};

export const RouteContainer = (props: React.PropsWithChildren<{
  marginTop?: string,
}>) => {
  const {
    marginTop,
    children,
  } = props;
  const routeSlideCss = React.useMemo(
    () => ({
      ...ouiStyle.makeCss(routeSlideStyle),
      marginTop,
    }),
    [marginTop],
  );
  return (
    <div
      css={routeSlideCss}
    >
      {children}
    </div>
  );
};
