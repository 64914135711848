import * as React from 'react';
import { bondInvalidSAccIdsContext, initialInvalidBondIds } from './context';

export const BondInvalidSAccIdsProvider = (props: {
  children: React.ReactNode,
}) => {
  const {
    children,
  } = props;
  const value = React.useState<Set<number>>(initialInvalidBondIds);
  return (
    <bondInvalidSAccIdsContext.Provider value={value}>
      {children}
    </bondInvalidSAccIdsContext.Provider>
  );
};
