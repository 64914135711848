/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';

const LogoStyle: ouiStyle.InStyle = {
  lines: 'full',
};

const LogoCss = ouiStyle.makeCss(LogoStyle);

export const Logo = () => (
  <svg
    version="1.1"
    viewBox="0 250 2678.74 477.95"
    css={LogoCss}
  >
    <g>
      <g
        style={{
          fill: ouiStyle.Constants.Colors.logoText,
        }}
      >
        <path d="M196.988,482.402c-3.855,0-10.044,0.352-13.679,0.352c-3.499,0-10.87-0.352-16.95-0.352 c-1.628,0-2.453,0.246-2.453,1.164c0,0.703,0.703,0.949,1.768,0.949c1.626,0,3.61,0.105,4.553,0.338 c3.862,0.832,4.903,2.701,5.021,6.672c0.125,3.506,0.125,7.957,0.125,13.795v10.979c0,1.986-0.483,3.5-2.227,4.322 c-2.81,1.295-6.892,1.295-9.94,1.295c-24.649,0-40.662-18.814-40.662-43.951c0-14.377,4.205-23.139,10.751-28.643 c8.174-6.773,16.705-7.225,23.135-7.225c13.213,0,22.555,5.371,26.06,9.107c3.042,3.271,3.628,7.021,3.971,9.709 c0.123,0.918,0.363,1.504,1.182,1.504c0.925,0,1.282-0.586,1.282-2.453c0-14.598,0.813-17.867,0.813-19.154 c0-0.83-0.228-1.281-1.632-1.414c-2.69-0.119-6.787-0.816-9.237-1.285c-2.33-0.451-12.616-1.867-20.685-1.867 c-9.811,0-20.212,0.832-31.436,7.59c-10.629,6.438-20.336,19.533-20.336,38.217c0,32.262,25.355,45.809,53.997,45.809 c9.115,0,20.569-1.285,28.159-5.256c1.409-0.801,1.527-1.055,1.527-4.205v-12.846c0-5.855,0-10.521,0.116-14.027 c0.111-4.104,1.278-6.086,4.444-6.672c1.389-0.232,2.214-0.338,3.153-0.338c0.805,0,1.514-0.246,1.514-0.949 C199.323,482.648,198.511,482.402,196.988,482.402" />
        <path d="M308.75,522.021c-22.448,0-35.882-19.289-35.882-43.592c0-29.107,15.305-36.578,28.75-36.578 c19.157,0,34.245,16.244,34.245,42.418C335.862,518.066,316.923,522.021,308.75,522.021 M305.01,436.244 c-35.057,0-48.268,25.838-48.268,45.807c0,20.682,13.557,45.809,47.1,45.809c29.686,0,48.386-21.152,48.386-47.908 C352.228,453.207,334.111,436.244,305.01,436.244" />
        <path d="M472.63,508.006c-0.941,0-1.167,0.705-1.282,1.75c-0.245,2.219-1.63,5.492-3.041,7.006 c-2.925,3.174-7.485,3.273-13.674,3.273c-9.117,0-12.152-0.805-14.141-2.568c-2.451-2.088-2.451-10.166-2.451-25.111v-20.582 c0-17.766,0-21.033,0.238-24.773c0.228-4.102,1.171-6.084,4.324-6.67c1.4-0.217,3.271-0.332,4.553-0.332 c1.394,0,2.094-0.252,2.094-1.07c0-0.803-0.808-1.049-2.451-1.049c-4.553,0-13.095,0.363-15.891,0.363 c-3.153,0-10.526-0.363-16.004-0.363c-1.752,0-2.57,0.246-2.57,1.049c0,0.818,0.7,1.07,1.986,1.07c1.51,0,2.91,0.115,3.851,0.332 c3.862,0.818,4.792,2.688,5.026,6.67c0.241,3.74,0.241,7.008,0.241,24.773v20.582c0,10.746,0,19.973-0.602,24.889 c-0.458,3.377-1.042,5.941-3.378,6.424c-1.059,0.223-2.468,0.473-4.212,0.473c-1.409,0-1.866,0.332-1.866,0.932 c0,0.801,0.825,1.168,2.328,1.168c2.348,0,5.384-0.232,8.069-0.232c2.814-0.135,5.389-0.135,6.675-0.135 c4.781,0,8.999,0.135,14.239,0.367c5.262,0.1,11.349,0.35,19.766,0.35c6.067,0,6.647-0.582,7.355-3.168 c0.941-3.387,1.744-12.146,1.744-13.434C473.556,508.588,473.455,508.006,472.63,508.006" />
        <path d="M599.229,513.375c-7.015,6.092-14.961,8.41-26.88,8.41c-9.231,0-13.795-2.451-15.305-4.668 c-0.943-1.287-1.171-5.957-1.287-8.879c-0.116-2.215-0.241-11.225-0.241-23.605v-14.727c0-9.24,0-19.4,0.125-23.373 c0-1.297,0.344-1.867,1.403-2.336c0.925-0.467,5.249-0.83,7.596-0.83c9.115,0,22.437,1.416,33.18,11.693 c5.023,4.801,11.227,14.363,11.227,29.227C609.047,496.309,606.706,506.951,599.229,513.375 M613.37,451.205 c-12.738-13.312-32.26-13.312-46.858-13.312c-7.015,0-14.388,0.338-18.002,0.338c-3.278,0-10.642-0.338-16.829-0.338 c-1.752,0-2.578,0.219-2.578,1.035c0,0.836,0.707,1.053,1.993,1.053c1.641,0,3.612,0.117,4.56,0.367 c3.855,0.814,4.78,2.684,5.023,6.641c0.234,3.752,0.234,7.033,0.234,24.785v20.57c0,10.74,0,19.984-0.578,24.891 c-0.481,3.387-1.065,5.969-3.393,6.424c-1.053,0.246-2.453,0.465-4.206,0.465c-1.4,0-1.881,0.367-1.881,0.949 c0,0.816,0.825,1.15,2.348,1.15c2.33,0,5.366-0.215,8.058-0.215c2.812-0.119,5.382-0.119,6.665-0.119 c2.912,0,7.008,0.334,11.453,0.701c4.438,0.352,9.233,0.684,13.437,0.684c21.614,0,33.781-8.289,39.152-13.779 c6.546-6.67,12.729-17.881,12.729-32.598C624.696,466.975,619.216,457.279,613.37,451.205" />
        <path d="M702.339,440.447c0.831-0.449,1.989-0.449,2.916-0.449c1.182,0,2.112-0.135,2.112-1.07 c0-0.686-0.825-1.049-2.455-1.049c-6.776,0-11.914,0.363-12.972,0.363c-1.05,0-7.474-0.363-13.561-0.363 c-1.624,0-2.567,0.246-2.567,1.049c0,0.936,0.703,1.07,1.865,1.07c1.059,0,3.398,0.215,4.684,1.035 c1.636,1.166,2.923,3.984,4.319,8.307l24.778,74.213c1.052,3.037,1.514,4.324,2.695,4.324c1.385,0,1.982-1.418,4.197-6.191 c5.028-10.773,20.57-52.131,22.804-58.098h0.214l24.304,58.564c1.768,4.307,2.468,5.725,3.638,5.725 c1.512,0,2.111-1.418,3.396-5.506c5.723-17.52,18-54.684,23.364-69.414c2.448-6.658,3.735-11.34,6.546-12.381 c1.28-0.463,2.91-0.578,4.091-0.578c1.046,0,1.63-0.369,1.63-1.172c0-0.701-0.818-0.947-2.102-0.947 c-3.86,0-8.888,0.363-9.706,0.363c-1.045,0-5.949-0.363-12.158-0.363c-1.739,0-2.669,0.246-2.669,1.049 c0,0.701,0.805,1.07,1.973,1.07c4.56,0,5.619,1.619,5.619,3.486c0,1.65-0.356,4.1-1.168,6.787 c-4.091,13.662-13.451,44.523-15.536,52.348h-0.37l-24.306-58.771c-1.526-3.734-2.098-5.502-3.391-5.502 c-0.928,0-1.746,0.932-2.915,4.221l-22.675,60.871h-0.344c-2.111-6.541-12.979-41.955-14.965-49.662 c-1.396-5.271-2.566-9.227-2.566-10.758C701.059,441.734,701.3,440.916,702.339,440.447" />
        <path d="M890.425,489.891h-21.499c-0.481,0-0.584-0.352-0.481-0.818l10.769-28.756 c0.111-0.469,0.336-1.053,0.696-1.053c0.343,0,0.461,0.584,0.584,1.053l10.515,28.855 C891.121,489.539,891.009,489.891,890.425,489.891 M902.111,524.008c-1.17,0.115-1.632,0.363-1.632,1.064 c0,0.801,1.392,0.801,3.496,0.918c9.358,0.25,17.887,0.25,22.096,0.25c4.318,0,5.365-0.25,5.365-1.168 c0-0.818-0.578-0.949-1.508-0.949c-1.402,0-3.157-0.115-4.917-0.455c-2.448-0.582-5.833-2.232-10.281-12.744 c-7.476-17.766-26.176-65.439-28.968-71.881c-1.19-2.668-1.884-3.732-2.937-3.732c-1.164,0-1.871,1.283-3.275,4.67l-29.324,73.395 c-2.352,5.855-4.56,9.811-9.944,10.516c-0.921,0.117-2.571,0.232-3.617,0.232c-0.943,0-1.405,0.248-1.405,0.949 c0,0.918,0.703,1.168,2.23,1.168c6.064,0,12.495-0.367,13.781-0.367c3.619,0,8.651,0.367,12.392,0.367 c1.287,0,1.989-0.25,1.989-1.168c0-0.701-0.365-0.949-1.748-0.949h-1.766c-3.619,0-4.665-1.52-4.665-3.605 c0-1.42,0.584-4.219,1.755-7.143l6.305-16.715c0.241-0.695,0.456-0.928,1.04-0.928h26.071c0.584,0,0.93,0.232,1.17,0.812 l9.345,24.773C903.76,522.721,902.936,523.771,902.111,524.008" />
        <path d="M1001.595,522.268c-7.249,0-14.141-3.152-16.711-11.807c-0.584-2.004-1.052-4.438-1.052-5.973 c0-0.92-0.111-1.969-1.158-1.969c-0.947,0-1.291,1.281-1.409,3.033c-0.116,1.871-0.584,8.877-0.584,15.301 c0,2.568,0.344,3.154,2.208,3.986c5.028,2.205,10.29,3.037,17.077,3.037c6.189,0,12.735-1.055,18.582-4.791 c8.419-5.502,10.983-13.561,10.983-20.354c0-11.102-5.032-19.166-19.166-29.789l-3.28-2.453 c-9.692-7.357-12.381-11.461-12.381-16.832c0-7.021,5.262-12.041,13.906-12.041c7.588,0,10.983,3.518,12.731,5.723 c2.468,3.049,3.046,6.668,3.046,7.721c0,1.4,0.35,2.104,1.168,2.104c0.93,0,1.287-0.92,1.287-3.506 c0-9.109,0.468-12.51,0.468-14.014c0-0.818-0.584-1.182-1.63-1.402c-3.054-0.832-7.958-1.984-15.549-1.984 c-16.246,0-26.995,9.209-26.995,22.07c0,9.479,4.438,17.65,17.298,27.695l5.365,4.219c9.697,7.59,11.463,12.977,11.463,18.701 C1017.261,515.135,1012.117,522.268,1001.595,522.268" />
        <path d="M1111.713,522.268c-7.255,0-14.146-3.152-16.718-11.807c-0.584-2.004-1.039-4.438-1.039-5.973 c0-0.92-0.129-1.969-1.168-1.969c-0.943,0-1.287,1.281-1.409,3.033c-0.118,1.871-0.58,8.877-0.58,15.301 c0,2.568,0.345,3.154,2.216,3.986c5.02,2.205,10.281,3.037,17.062,3.037c6.189,0,12.738-1.055,18.584-4.791 c8.417-5.502,10.983-13.561,10.983-20.354c0-11.102-5.034-19.166-19.162-29.789l-3.286-2.453 c-9.69-7.357-12.372-11.461-12.372-16.832c0-7.021,5.253-12.041,13.899-12.041c7.592,0,10.983,3.518,12.736,5.723 c2.461,3.049,3.045,6.668,3.045,7.721c0,1.4,0.346,2.104,1.171,2.104c0.928,0,1.287-0.92,1.287-3.506 c0-9.109,0.459-12.51,0.459-14.014c0-0.818-0.584-1.182-1.63-1.402c-3.052-0.832-7.958-1.984-15.55-1.984 c-16.243,0-26.994,9.209-26.994,22.07c0,9.479,4.444,17.65,17.298,27.695l5.365,4.219c9.696,7.59,11.464,12.977,11.464,18.701 C1127.374,515.135,1122.229,522.268,1111.713,522.268" />
        <path d="M1204.996,437.879c-1.748,0-2.566,0.246-2.566,1.049c0,0.818,0.703,1.07,1.989,1.07 c1.623,0,3.616,0.1,4.56,0.332c3.851,0.832,4.78,2.701,5.019,6.67c0.241,3.74,0.241,7.008,0.241,24.773v20.57 c0,10.74,0,19.984-0.584,24.891c-0.482,3.387-1.066,5.951-3.39,6.434c-1.065,0.223-2.457,0.473-4.223,0.473 c-1.383,0-1.864,0.332-1.864,0.918c0,0.83,0.818,1.182,2.345,1.182c2.331,0,5.365-0.232,8.061-0.232 c2.812-0.119,5.378-0.119,6.662-0.119c3.494,0,7.133,0.119,12.154,0.352c5.019,0.1,11.452,0.35,20.212,0.35 c4.437,0,5.5,0,6.308-3.037c0.597-2.35,1.524-10.291,1.524-12.979c0-1.164,0-2.1-0.928-2.1c-0.824,0-1.059,0.451-1.287,1.736 c-0.824,4.801-2.227,7.256-5.262,8.656c-3.039,1.414-7.948,1.414-10.981,1.414c-11.933,0-13.797-1.752-14.024-9.82 c-0.123-3.404,0-14.152,0-18.117v-9.227c0-0.701,0.118-1.182,0.702-1.182c2.792,0,14.359,0.115,16.589,0.363 c4.319,0.455,5.962,2.205,6.546,4.557c0.343,1.504,0.343,3.035,0.468,4.201c0,0.586,0.24,1.039,1.046,1.039 c1.065,0,1.065-1.273,1.065-2.205c0-0.834,0.344-5.488,0.459-7.84c0.462-5.955,0.923-7.809,0.923-8.523 c0-0.717-0.343-0.936-0.805-0.936c-0.578,0-1.059,0.584-1.87,1.52c-1.059,1.166-2.814,1.516-5.625,1.75 c-2.682,0.234-16.121,0.234-18.68,0.234c-0.7,0-0.818-0.469-0.818-1.27v-29.59c0-0.805,0.228-1.168,0.818-1.168 c2.332,0,14.602,0.248,16.595,0.48c5.839,0.688,7.008,2.088,7.82,4.207c0.597,1.398,0.702,3.383,0.702,4.219 c0,1.033,0.241,1.621,1.059,1.621c0.805,0,1.046-0.703,1.162-1.287c0.24-1.4,0.468-6.541,0.584-7.707 c0.346-5.389,0.943-6.775,0.943-7.594c0-0.582-0.118-1.064-0.703-1.064c-0.584,0-1.286,0.35-1.863,0.482 c-0.941,0.221-2.812,0.453-5.041,0.699c-2.208,0.117-25.472,0.117-29.207,0.117c-1.644,0-4.322-0.117-7.374-0.234 C1211.549,438.008,1208.153,437.879,1204.996,437.879" />
        <path d="M1366.881,482.285c-2.097,1.533-3.62,2.002-8.289,2.002c-2.936,0-6.423-0.354-8.878-1.17 c-0.818-0.363-1.062-0.832-1.062-2.119v-35.514c0-0.936,0.244-1.416,1.062-1.637c1.168-0.363,3.272-0.463,6.191-0.463 c6.889,0,17.866,4.787,17.866,21.367C1373.771,474.346,1370.403,479.717,1366.881,482.285 M1413.858,524.123 c-0.936,0-1.986-0.115-3.265-0.352c-1.754-0.35-6.541-1.168-13.084-7.941c-6.891-7.242-14.951-17.646-25.37-30.729 c11.824-9.59,15.442-17.529,15.442-26.172c0-7.84-4.688-13.795-7.974-16.031c-6.304-4.318-14.125-5.02-21.484-5.02 c-3.624,0-12.511,0.363-16.488,0.363c-2.446,0-9.802-0.363-16.007-0.363c-1.754,0-2.573,0.232-2.573,1.064 c0,0.803,0.705,1.037,1.992,1.037c1.641,0,3.636,0.133,4.559,0.35c3.855,0.818,4.8,2.688,5.019,6.67 c0.245,3.74,0.245,7.008,0.245,24.762v20.582c0,10.758,0,19.984-0.582,24.891c-0.481,3.387-1.068,5.951-3.4,6.434 c-1.041,0.223-2.446,0.455-4.201,0.455c-1.4,0-1.869,0.367-1.869,0.949c0,0.801,0.81,1.168,2.337,1.168 c4.678,0,12.034-0.367,14.266-0.367c1.737,0,10.383,0.367,17.17,0.367c1.52,0,2.332-0.367,2.332-1.168 c0-0.582-0.459-0.949-1.395-0.949c-1.405,0-3.979-0.232-5.742-0.455c-3.487-0.482-4.191-3.047-4.542-6.434 c-0.594-4.906-0.594-14.133-0.594-25.008v-1.637c0-0.699,0.362-0.932,0.944-0.932l10.165,0.232c0.817,0,1.514,0.117,2.1,0.814 c1.52,1.756,6.892,9.695,11.562,16.133c6.537,8.992,11.01,14.48,16.028,17.17c3.041,1.646,5.956,2.232,12.498,2.232h11.092 c1.404,0,2.237-0.25,2.237-1.168C1415.277,524.49,1414.808,524.123,1413.858,524.123" />
        <path d="M1546.438,517.234c-0.454,3.396-1.051,5.969-3.383,6.424c-1.054,0.246-2.454,0.465-4.209,0.465 c-1.405,0-1.873,0.367-1.873,0.949c0,0.816,0.818,1.168,2.341,1.168c2.351,0,5.369-0.232,8.073-0.232 c2.783-0.119,5.356-0.119,6.643-0.119c3.536,0,7.138,0.119,12.16,0.352c5.033,0.113,11.457,0.35,20.221,0.35 c4.447,0,5.488,0,6.319-3.049c0.581-2.324,1.53-10.279,1.53-12.98c0-1.15,0-2.086-0.949-2.086c-0.83,0-1.049,0.469-1.299,1.75 c-0.791,4.805-2.219,7.256-5.252,8.643c-3.04,1.404-7.945,1.404-10.982,1.404c-11.915,0-13.78-1.75-14.024-9.811 c-0.118-3.391,0-14.135,0-18.117v-9.242c0-0.699,0.131-1.166,0.695-1.166c2.818,0,14.38,0.129,16.603,0.363 c4.318,0.467,5.955,2.217,6.537,4.539c0.367,1.533,0.367,3.053,0.469,4.219c0,0.586,0.236,1.053,1.066,1.053 c1.034,0,1.034-1.287,1.034-2.219c0-0.818,0.35-5.504,0.468-7.826c0.468-5.969,0.944-7.836,0.944-8.537 c0-0.703-0.363-0.947-0.83-0.947c-0.582,0-1.034,0.578-1.865,1.531c-1.036,1.166-2.804,1.516-5.605,1.75 c-2.687,0.234-16.12,0.234-18.685,0.234c-0.7,0-0.831-0.469-0.831-1.287V445.25c0-0.832,0.244-1.168,0.831-1.168 c2.319,0,14.594,0.234,16.58,0.453c5.842,0.715,7.01,2.115,7.824,4.221c0.582,1.385,0.717,3.383,0.717,4.201 c0,1.051,0.216,1.648,1.034,1.648c0.83,0,1.054-0.713,1.172-1.311c0.232-1.387,0.477-6.527,0.607-7.709 c0.339-5.373,0.925-6.771,0.925-7.59c0-0.586-0.131-1.053-0.714-1.053c-0.586,0-1.287,0.35-1.873,0.467 c-0.936,0.236-2.8,0.482-5.019,0.701c-2.224,0.119-25.463,0.119-29.222,0.119c-1.623,0-4.328-0.119-7.348-0.234 c-2.937,0-6.318-0.104-9.478-0.104c-1.746,0-2.564,0.219-2.564,1.035c0,0.818,0.7,1.053,1.982,1.053 c1.637,0,3.623,0.117,4.561,0.367c3.851,0.814,4.786,2.67,5.031,6.641c0.224,3.734,0.224,7.02,0.224,24.785v20.57 C1547.024,503.084,1547.024,512.328,1546.438,517.234" />
        <path d="M1702.956,523.541c-1.05,0.244-1.637,0.582-1.637,1.299c0,0.814,0.937,1.033,3.97,1.15 c8.996,0.25,18.237,0.25,21.052,0.25h3.505c1.282,0,2.215-0.367,2.215-1.168c0-0.701-0.701-0.949-1.511-0.949 c-1.055,0-2.46-0.115-4.445-0.701c-3.033-0.918-4.813-2.318-6.8-4.9c-2.901-3.742-21.836-35.404-25.577-41.143l19.153-27.576 c3.522-5.137,5.842-8.406,8.542-9.121c1.648-0.453,3.019-0.701,4.214-0.701c1.172,0,1.872-0.336,1.872-1.037 c0-0.713-0.818-1.064-2.117-1.064c-4.306,0-9.119,0.352-11.444,0.352c-2.234,0-7.141-0.352-11.691-0.352 c-1.636,0-2.455,0.352-2.455,1.064c0,0.803,0.701,1.037,1.401,1.037c0.936,0,2.573,0,3.736,0.35 c1.168,0.352,1.987,1.299,1.987,2.102c0,0.953-0.352,2.818-1.287,4.439c-1.746,3.283-12.498,19.529-15.879,24.67 c-4.553-7.953-9.808-16.467-14.862-25.84c-0.69-1.283-1.168-2.684-1.168-3.387c0-0.686,0.363-1.504,1.4-1.867 c0.95-0.35,2.455-0.467,3.151-0.467c1.085,0,1.755-0.234,1.755-1.037c0-0.832-0.67-1.064-2.333-1.064 c-4.323,0-10.864,0.352-12.497,0.352c-5.487,0-14.952-0.352-17.761-0.352c-1.511,0-2.329,0.232-2.329,0.936 c0,0.83,0.455,1.166,1.155,1.166c1.174,0,3.051,0.248,4.919,0.83c4.087,1.287,6.893,4.09,9.691,8.76l20.104,33.299l-22.316,31.91 c-3.978,5.605-6.205,7.824-9.583,8.762c-1.76,0.467-3.515,0.582-4.319,0.582c-0.937,0-1.649,0.367-1.649,1.049 c0,0.701,0.586,1.068,1.755,1.068h1.986c1.864,0,7.604-0.367,9.819-0.367c2.923,0,10.278,0.367,10.979,0.367h2.118 c1.505,0,2.323-0.25,2.323-1.068c0-0.682-0.7-1.049-1.4-1.049c-0.923,0-1.99-0.115-2.69-0.219 c-1.051-0.236-2.096-0.938-2.215-1.988c-0.117-1.285,0.469-3.049,1.628-4.918l17.307-27.926 c5.383,8.76,12.043,20.338,18.466,31.781C1704.024,522.389,1703.543,523.422,1702.956,523.541" />
        <path d="M1830.6,441.98c10.76,0,19.878,3.154,23.738,7.227c2.919,3.049,4.438,7.154,4.556,10.758 c0,1.633,0.231,2.586,1.168,2.586c1.046,0,1.277-0.953,1.396-2.703c0.232-2.32,0.118-8.758,0.35-12.613 c0.237-4.102,0.587-5.5,0.587-6.424c0-0.697-0.468-1.281-1.751-1.414c-3.505-0.354-7.241-1.4-11.696-1.986 c-5.355-0.701-9.675-1.166-16.93-1.166c-17.416,0-28.521,4.672-36.944,12.393c-11.212,10.293-13.666,24.072-13.666,32.029 c0,11.328,3.031,24.42,14.497,34.469c10.638,9.236,24.072,12.725,40.078,12.725c7.479,0,16.37-0.805,21.039-2.688 c1.987-0.801,2.454-1.387,3.041-3.369c1.159-3.986,2.564-14.146,2.564-15.547c0-0.949-0.232-1.768-1.051-1.768 c-0.933,0-1.163,0.48-1.514,2.35c-0.468,2.105-1.987,5.49-3.978,7.711c-4.67,5.387-10.975,6.555-21.38,6.555 c-15.667,0-36.823-13.811-36.823-41.621c0-11.326,2.21-22.438,10.765-30.275C1813.782,444.549,1820.438,441.98,1830.6,441.98" />
        <path d="M1994.49,517.234c-0.455,3.396-1.041,5.969-3.403,6.424c-1.033,0.246-2.45,0.465-4.188,0.465 c-1.417,0-1.863,0.367-1.863,0.949c0,0.816,0.797,1.168,2.445,1.168c4.557,0,11.912-0.352,14.615-0.352 c3.265,0,10.624,0.352,18.217,0.352c1.418,0,2.342-0.352,2.342-1.168c0-0.582-0.587-0.949-1.873-0.949 c-1.755,0-4.32-0.219-5.955-0.465c-3.506-0.455-4.201-3.027-4.552-6.311c-0.587-5.02-0.587-14.264-0.587-25.004v-20.57 c0-17.766,0-21.051,0.233-24.785c0.236-4.072,1.172-6.059,4.323-6.641c1.399-0.25,2.445-0.367,3.382-0.367 c1.4,0,2.104-0.234,2.104-1.053c0-0.816-0.817-1.035-2.454-1.035c-4.557,0-11.916,0.338-14.726,0.338 c-3.158,0-10.514-0.338-16.838-0.338c-1.628,0-2.446,0.219-2.446,1.035c0,0.818,0.7,1.053,1.983,1.053 c1.648,0,3.636,0.117,4.56,0.367c3.863,0.814,4.808,2.67,5.019,6.641c0.244,3.734,0.244,7.02,0.244,24.785v2.455 c0,0.58-0.336,0.814-0.699,0.814h-44.643c-0.363,0-0.7-0.234-0.7-0.814v-2.455c0-17.766,0-21.051,0.254-24.785 c0.215-4.072,1.165-6.059,4.32-6.641c1.395-0.25,2.449-0.367,3.368-0.367c1.404,0,2.104-0.234,2.104-1.053 c0-0.816-0.817-1.035-2.44-1.035c-4.564,0-11.938,0.338-14.743,0.338c-3.151,0-10.511-0.338-16.815-0.338 c-1.637,0-2.451,0.219-2.451,1.035c0,0.818,0.695,1.053,1.982,1.053c1.637,0,3.622,0.117,4.551,0.367 c3.859,0.814,4.796,2.67,5.028,6.641c0.235,3.734,0.235,7.02,0.235,24.785v20.57c0,10.74,0,19.984-0.586,24.891 c-0.468,3.396-1.05,5.969-3.404,6.424c-1.037,0.246-2.434,0.465-4.188,0.465c-1.418,0-1.873,0.367-1.873,0.949 c0,0.816,0.822,1.168,2.341,1.168c4.669,0,12.028-0.352,14.711-0.352c3.274,0,10.647,0.352,18.229,0.352 c1.423,0,2.354-0.352,2.354-1.168c0-0.582-0.599-0.949-1.864-0.949c-1.768,0-4.341-0.219-5.954-0.465 c-3.536-0.455-4.235-3.027-4.573-6.311c-0.587-5.02-0.587-14.264-0.587-25.004v-10.279c0-0.58,0.337-0.697,0.7-0.697h44.643 c0.363,0,0.699,0.219,0.699,0.697v10.279C1995.072,503.084,1995.072,512.328,1994.49,517.234" />
        <path d="M2123.315,489.891h-21.498c-0.47,0-0.587-0.352-0.47-0.818l10.758-28.756 c0.113-0.469,0.336-1.053,0.699-1.053c0.337,0,0.469,0.584,0.586,1.053l10.507,28.873 C2124.015,489.539,2123.897,489.891,2123.315,489.891 M2162.816,524.123c-1.4,0-3.151-0.115-4.901-0.455 c-2.455-0.582-5.842-2.232-10.278-12.744c-7.5-17.766-26.186-65.439-28.994-71.881c-1.169-2.668-1.864-3.732-2.915-3.732 c-1.169,0-1.873,1.283-3.286,4.67l-29.326,73.395c-2.337,5.855-4.552,9.811-9.93,10.516c-0.932,0.117-2.582,0.232-3.631,0.232 c-0.95,0-1.405,0.248-1.405,0.949c0,0.918,0.704,1.168,2.236,1.168c6.073,0,12.496-0.367,13.784-0.367 c3.614,0,8.637,0.367,12.37,0.367c1.286,0,1.991-0.25,1.991-1.168c0-0.701-0.342-0.949-1.738-0.949h-1.754 c-3.637,0-4.682-1.52-4.682-3.605c0-1.42,0.577-4.219,1.75-7.143l6.305-16.715c0.246-0.695,0.469-0.928,1.063-0.928h26.058 c0.588,0,0.938,0.232,1.169,0.812l9.354,24.773c0.588,1.402-0.244,2.453-1.066,2.689c-1.16,0.115-1.628,0.363-1.628,1.064 c0,0.801,1.417,0.801,3.501,0.918c9.373,0.25,17.901,0.25,22.103,0.25c4.317,0,5.372-0.25,5.372-1.168 C2164.338,524.254,2163.752,524.123,2162.816,524.123" />
        <path d="M2300.636,437.879c-5.735,0-10.177,0.363-11.455,0.363c-2.455,0-8.06-0.363-14.134-0.363 c-1.754,0-3.054,0.129-3.054,1.049c0,0.701,0.484,1.07,1.886,1.07c1.637,0,4.441,0,6.188,0.697 c2.805,1.168,3.859,3.373,3.977,10.51l1.16,52.479h-0.577c-1.987-1.869-17.783-18.699-25.708-26.652 c-17.07-16.936-34.832-35.998-36.249-37.52c-1.987-2.086-3.021-3.617-4.306-3.617c-1.168,0-1.531,1.633-1.531,3.984l-1.056,69.182 c-0.113,10.156-0.582,13.543-3.614,14.492c-1.637,0.465-3.741,0.588-5.255,0.588c-1.169,0-1.874,0.219-1.874,0.918 c0,1.062,1.055,1.182,2.573,1.182c6.073,0,11.574-0.352,12.628-0.352c2.202,0,6.893,0.352,14.498,0.352 c1.628,0,2.683-0.232,2.683-1.182c0-0.699-0.705-0.918-2-0.918c-1.873,0-4.073-0.123-6.061-0.832 c-2.333-0.822-3.854-3.859-4.101-12.971l-1.285-49.074h0.363c2.073,2.205,15.069,16.816,27.799,29.441 c12.139,12.027,27.116,27.344,34.477,34.354c1.287,1.281,2.688,2.564,3.986,2.564c1.033,0,1.501-1.033,1.501-3.605l1.287-73.746 c0.117-6.541,1.054-8.992,4.341-9.941c1.383-0.332,2.327-0.332,3.5-0.332c1.4,0,2.105-0.369,2.105-1.07 C2303.328,438.008,2302.143,437.879,2300.636,437.879" />
        <path d="M2452.836,482.402c-3.859,0-10.046,0.352-13.678,0.352c-3.506,0-10.861-0.352-16.935-0.352 c-1.649,0-2.455,0.246-2.455,1.164c0,0.703,0.691,0.949,1.755,0.949c1.632,0,3.618,0.105,4.556,0.338 c3.837,0.832,4.899,2.701,5.019,6.672c0.117,3.506,0.117,7.957,0.117,13.795v10.979c0,1.986-0.468,3.5-2.213,4.322 c-2.823,1.295-6.893,1.295-9.96,1.295c-24.64,0-40.638-18.814-40.638-43.951c0-14.377,4.188-23.139,10.742-28.643 c8.17-6.773,16.698-7.225,23.135-7.225c13.211,0,22.549,5.371,26.059,9.107c3.032,3.271,3.619,7.021,3.969,9.709 c0.117,0.918,0.35,1.504,1.168,1.504c0.937,0,1.299-0.586,1.299-2.453c0-14.598,0.807-17.867,0.807-19.154 c0-0.83-0.219-1.281-1.637-1.414c-2.692-0.119-6.774-0.816-9.229-1.285c-2.333-0.451-12.607-1.867-20.693-1.867 c-9.802,0-20.207,0.832-31.432,7.59c-10.633,6.438-20.335,19.533-20.335,38.217c0,32.262,25.366,45.809,53.993,45.809 c9.124,0,20.571-1.285,28.163-5.256c1.399-0.801,1.53-1.055,1.53-4.205v-12.846c0-5.855,0-10.521,0.106-14.027 c0.118-4.104,1.299-6.086,4.45-6.672c1.387-0.232,2.219-0.338,3.168-0.338c0.788,0,1.487-0.246,1.487-0.949 C2455.155,482.648,2454.368,482.402,2452.836,482.402" />
        <path d="M2571.605,508.475c-0.836,0-1.037,0.469-1.287,1.75c-0.831,4.787-2.206,7.242-5.273,8.654 c-3.031,1.402-7.938,1.402-10.974,1.402c-11.915,0-13.778-1.76-14.012-9.82c-0.118-3.391,0-14.152,0-18.117v-9.242 c0-0.699,0.118-1.166,0.696-1.166c2.805,0,14.365,0.129,16.588,0.363c4.332,0.467,5.956,2.205,6.538,4.557 c0.367,1.516,0.367,3.035,0.494,4.201c0,0.586,0.201,1.053,1.042,1.053c1.032,0,1.032-1.287,1.032-2.219 c0-0.818,0.381-5.504,0.499-7.826c0.446-5.969,0.915-7.836,0.915-8.537c0-0.703-0.338-0.936-0.832-0.936 c-0.582,0-1.037,0.584-1.868,1.52c-1.033,1.182-2.787,1.516-5.605,1.768c-2.683,0.217-16.117,0.217-18.686,0.217 c-0.695,0-0.813-0.469-0.813-1.287v-29.572c0-0.818,0.233-1.154,0.813-1.154c2.338,0,14.599,0.234,16.585,0.453 c5.842,0.701,7.001,2.115,7.841,4.221c0.565,1.398,0.696,3.383,0.696,4.201c0,1.064,0.219,1.639,1.037,1.639 c0.832,0,1.08-0.703,1.172-1.287c0.247-1.4,0.491-6.541,0.583-7.707c0.363-5.389,0.949-6.787,0.949-7.594 c0-0.582-0.131-1.064-0.699-1.064c-0.586,0-1.3,0.367-1.874,0.482c-0.949,0.221-2.787,0.467-5.031,0.686 c-2.223,0.119-25.464,0.119-29.209,0.119c-1.637,0-4.327-0.119-7.36-0.223c-2.936,0-6.318-0.115-9.479-0.115 c-1.75,0-2.563,0.219-2.563,1.035c0,0.836,0.696,1.07,1.999,1.07c1.62,0,3.607,0.1,4.543,0.35c3.851,0.814,4.8,2.684,5.045,6.641 c0.21,3.752,0.21,7.033,0.21,24.785v20.57c0,10.74,0,19.984-0.586,24.891c-0.455,3.387-1.041,5.969-3.392,6.424 c-1.045,0.246-2.446,0.465-4.2,0.465c-1.405,0-1.874,0.367-1.874,0.949c0,0.816,0.818,1.15,2.342,1.15 c2.35,0,5.383-0.215,8.073-0.215c2.783-0.119,5.369-0.119,6.656-0.119c3.513,0,7.137,0.119,12.159,0.334 c5.02,0.131,11.457,0.367,20.217,0.367c4.438,0,5.479,0,6.311-3.049c0.582-2.338,1.531-10.279,1.531-12.967 C2572.555,509.393,2572.555,508.475,2571.605,508.475" />
      </g>
      <g
        style={{
          fill: ouiStyle.Constants.Colors.logoYellow,
        }}
      >
        <path d="M2052.186,589.006l-50.531,123.912l-510.55-3.125l-7.041-14.041c0,0,33.782,0.611,49.26-11.361 c15.438-12.012,10.419-33.045,10.419-107.072h122.829v117.684h186.908c116.887,0,186.24-117.684,186.24-117.684L2052.186,589.006z" />
        <path d="M1973.504,274.18l59.183,111.424l-9.347,6.219c0,0-39.731-45.168-98.928-74.023 c-59.254-28.834-141.877-26.498-254.087-26.498v95.09h-123.109v-66.246c0-29.588-45.224-30.391-63.135-30.391V274.18H1973.504z" />
        <path d="M1278.343,577.318v12.48h-78.688v71.676c0,0-74.116,47.367-256.438,48.275l-0.046-11.693 c160.111-2.629,139.58-106.713,139.58-106.713h-85.749v-14.025H1278.343z" />
        <path d="M1194.973,274.18v113.773h-43.679c0,0-63.861-106.752-218.172-106.752 c-154.296,0-204.193,106.752-204.193,106.752H624.521c40.823-56.582,146.49-122.387,279.757-122.387 c133.259,0,210.403,39.748,238.467,39.748c28.06,0,34.276-31.135,34.276-31.135H1194.973z" />
        <path d="M937.001,698.1c2.065,0,4.117,0,6.177-0.043l0.046,11.68c-1.774,0.043-3.6,0.043-5.424,0.043 c-186.288,0-294.113-66.205-334.097-132.461h117.373C721.076,577.318,735.938,698.1,937.001,698.1" />
      </g>
    </g>
  </svg>
);

export default Logo;
