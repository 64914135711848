/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { AppRefProvider } from './context';

export const id = 'appContainer';

const appContainerStyle: ouiStyle.InStyle = {
  position: 'relative',
  lines: 'full',
  overflowX: 'hidden',
};

const appContainerCss = ouiStyle.makeCss(appContainerStyle);

export const AppContainer = (props: React.PropsWithChildren<{}>): JSX.Element => {
  const {
    children,
  } = props;
  const appRef = React.useRef<HTMLDivElement>(null);
  return (
    <AppRefProvider appRef={appRef}>
      <div
        ref={appRef}
        id={id}
        css={appContainerCss}
      >
        {children}
      </div>
    </AppRefProvider>
  );
};

AppContainer.displayName = 'AppContainer';
