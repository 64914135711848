import * as React from 'react';
import ouiBase from '@goldwasserexchange/oui';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { Label as DefaultMainLabel } from '../labels';
import { getMakeHasFinancialProductExperienceWithSelectedLogic } from '../logic/hasExperience';
var getIconLogic = function (financialProduct) { return getMakeHasFinancialProductExperienceWithSelectedLogic(financialProduct, {
    hasExperience: {
        result: 'truthyIcon',
    },
    hasNotExperience: {
        result: 'falsyIcon',
    },
    notAnswered: {
        result: 'notApplicable',
    },
    notSelected: {
        result: 'notApplicable',
    },
}); };
export var IconOnFinancialProduct = function (props) {
    var financialProduct = props.financialProduct;
    var iconLogic = getIconLogic(financialProduct);
    return (React.createElement(ouiBase.utils.hookstate.FormFilterLogic, { parentName: "", logic: iconLogic }));
};
export var IconOnFinancialProductPrimaryDimension = function () {
    var financialProduct = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
    return (React.createElement(IconOnFinancialProduct, { financialProduct: financialProduct }));
};
export var IconOnFinancialProductPrimaryDimensionTopModels = (React.createElement(React.Fragment, null,
    React.createElement(TopModel, { name: "TableSecondaryTitleCellContent-ExperienceQuestionnaire" }, DefaultMainLabel),
    React.createElement(TopModel, { name: "TableBodyRowCellContent--ExperienceQuestionnaire" }, IconOnFinancialProductPrimaryDimension)));
