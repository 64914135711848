/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { animated } from 'react-spring';
import * as animationController from '../../animation';

const navBarLinkIconContainerStyle: ouiStyle.InStyle = {
  paddingVertical: 'simple',
  iconContainer: true,
  lines: 1,
  color: ouiStyle.Constants.Colors.inverted,
};

const navBarLinkIconContainerCss = ouiStyle.makeCss(navBarLinkIconContainerStyle);

export const NavBarLinkIconContainer = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  const mqDown = animationController.useAnimationmqDownSpringValue();
  const style = {
    display: mqDown.to((v) => {
      if (v <= 0.5) {
        return 'block';
      }
      return 'none';
    }),
  };
  return <animated.span css={navBarLinkIconContainerCss} style={style}>{children}</animated.span>;
};

export default NavBarLinkIconContainer;
