import * as React from 'react';
import { useHookstate } from '@hookstate/core';
import { disabledContext } from './context';

export const FormDisabledContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  const disabledState = useHookstate<Record<string, boolean>>({});
  return (
    <disabledContext.Provider value={disabledState}>
      {children}
    </disabledContext.Provider>
  );
};
