import { atom } from 'jotai';
import memoizee from 'memoizee';
import { times } from 'ramda';
import { locationSearchParsedAtom, parsePositiveNumberFromQs } from '../../../../../../history';

export const newsSearchParamsPageAtom = atom((get) => {
  const searchParsed = get(locationSearchParsedAtom);
  const {
    page,
  } = searchParsed;
  const parsedPage = parsePositiveNumberFromQs(page);
  if (parsedPage == null) {
    return 0;
  }
  return parsedPage;
});

const makePagesHome = (length: number) => times((i) => i + 1, length);

const memoMakePagesHome = memoizee(makePagesHome);

export const newsSearchParamsPagesHomeArrayAtom = atom((get) => {
  const page = get(newsSearchParamsPageAtom);
  const pages = memoMakePagesHome(page);
  return pages;
});

const makePagesSearch = (length: number) => times((i) => i + 2, Math.max(length - 1, 0));

const memoMakePagesSearch = memoizee(makePagesSearch);

export const newsSearchParamsPagesSearchArrayAtom = atom((get) => {
  const page = get(newsSearchParamsPageAtom);
  const pages = memoMakePagesSearch(page);
  return pages;
});

export const newsSearchParamsSearchTermAtom = atom((get) => {
  const searchParsed = get(locationSearchParsedAtom);
  const {
    search: searchTerm,
  } = searchParsed;
  if (searchTerm == null || Array.isArray(searchTerm)) {
    return '';
  }
  return searchTerm;
});
