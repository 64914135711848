import {
  none, State, useHookstate,
} from '@hookstate/core';
import { useCallback, useContext } from 'react';
import { disabledContext } from './context';
import { NoneForced, useFieldDisabledPreviousValueByFieldPath } from './previousValue';
import { useFieldDisabledReasonByFieldPath } from './reason';

export function useDisabled() {
  const disabled = useContext(disabledContext);
  return useHookstate(disabled);
}

export function useFieldDisabledByFieldPath(fieldPath: string) {
  const disabled = useDisabled();
  const key = disabled.keys.findIndex((disabledKey) => disabledKey === fieldPath);
  if (key === -1) {
    disabled.nested(fieldPath).set(false);
  }
  return useHookstate(disabled.nested(fieldPath));
}

export function useSetForcedValueFromState<T extends Record<string, unknown>>(state: State<any>) {
  const dataState = useHookstate(state);
  const fieldPath = dataState.path.join('.');
  const disabledState = useFieldDisabledByFieldPath(fieldPath);
  const disabledReasonState = useFieldDisabledReasonByFieldPath(fieldPath);
  const disabledPreviousValueState = useFieldDisabledPreviousValueByFieldPath(fieldPath);
  return useCallback((forced: boolean, forcedValue: T | typeof NoneForced, reason: string) => {
    if (disabledState.get() !== forced) {
      if (forced) {
        const previousValue = dataState.get();
        if (forcedValue !== NoneForced) {
          disabledPreviousValueState.set(previousValue);
          dataState.set(forcedValue);
        }
        disabledState.set(forced);
      } else {
        if (disabledPreviousValueState.ornull) {
          const previousValue = disabledPreviousValueState.get();
          dataState.set(previousValue);
          disabledPreviousValueState.set(none);
        }
        disabledState.set(forced);
      }
    }
    if (disabledReasonState.get() !== reason) {
      if (forced) {
        disabledReasonState.set(reason);
      } else {
        disabledReasonState.set('');
      }
    }
  }, [dataState, disabledState, disabledReasonState, disabledPreviousValueState]);
}
