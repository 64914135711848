import * as events from '../../../events';
import { makeSpringService } from '../utils';

export type AppBarUncollapsingServiceMeta = {
  src: {
    type: 'UncollapsingService',
  },
};

export const name = 'uncollapsing';

export const makeService = makeSpringService(name, 'collapse', 0, false, events.collapsingFinished.eventBuilder);

export default makeService;
