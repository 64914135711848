import * as TYPE from '../../TYPE';
import * as ID_ECPL_PROF from '../../ID_ECPL_PROF';
import { TAddType } from '../../../../../types';
export var showLogic = TYPE.logic.makeTAddTypeLogic({
    is: TAddType.MORAL,
    alternatives: {
        truthy: {
            result: 'visible',
        },
        falsy: ID_ECPL_PROF.logic.makeIdEcplProfShouldHaveProfessionLogic({
            visible: TYPE.logic.makeTAddIsTypePhysicLogic({
                alternatives: {
                    truthy: {
                        result: 'visible',
                    },
                    falsy: {
                        result: 'invisible',
                    },
                },
            }),
        }),
    },
});
