import {
  dataStructure,
  AccountType,
  UncastedDataStructure,
} from '@goldwasserexchange/oblis-frontend-utils';
import * as React from 'react';
import ouiBase from '@goldwasserexchange/oui';
import { useHookstate } from '@hookstate/core';
import { StepContainer } from '../../../utils/Step';
import { RTOQuestionnaire } from './rto';
import { ModifyAnswers } from '../../utils';
import { LazyConseilGestionKnowledgeAndExperienceQuestionnaire } from './conseilGestion';
import { Suspended } from '../../../../../../Suspended';

export const ProductQuestionnaire = (props: { sTypeGrp: dataStructure.financialProducts.listType }) => {
  const {
    sTypeGrp,
  } = props;
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const selectedService = useHookstate(valueState.onboarding.selectedService).get();
  if (selectedService !== AccountType.RTO) {
    return (
      <Suspended>
        <LazyConseilGestionKnowledgeAndExperienceQuestionnaire
          parentName={valueState.onboarding.knowledgeQuestionnaire.path.join('.')}
          sTypeGrp={sTypeGrp}
        />
      </Suspended>
    );
  }
  return (
    <StepContainer>
      <RTOQuestionnaire
        sTypeGrp={sTypeGrp}
      />
      <ModifyAnswers
        state="CHANGE"
      />
    </StepContainer>
  );
};
