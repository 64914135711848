import { isNil } from 'ramda';
import { PersonAccounts } from '../../type';
import { usePersonAccountsTransformApi } from '../api';

const hasOne = (d: PersonAccounts[] | undefined) => isNil(d) || d.length > 1;

export const usePersonAccountsHasMoreThanOneTAcc = () => {
  const response = usePersonAccountsTransformApi({
    transformFunction: hasOne,
  });
  return {
    ...response,
    data: response.data ?? response.isLoading,
  };
};
