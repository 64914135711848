import { useContext, useMemo } from 'react';
import { endOfDay } from 'date-fns';
import { GetAccountsHistoryParams } from '@goldwasserexchange/actor/rest-services';
import { useTAccId } from '../../../shared';
import { stringifyParams } from '../../../shared/utils/makeUrl';
import { accountsHistoryParamsContext } from '../context';
import { formatApiParamDate } from '../../../../shared';
import { useIsConnected } from '../../../../../../Components/Auth';

export const useAccountHistoryDefaultParams = (params: Partial<GetAccountsHistoryParams>): Partial<GetAccountsHistoryParams> & { 'HIS_MVT.OPER_DATE_END': GetAccountsHistoryParams['HIS_MVT.OPER_DATE_END'] } => {
  const {
    'T_ACC.ID': contextTAccId,
    'HIS_MVT.OPER_DATE_END': contextHisMvtOperDateEnd,
    'HIS_MVT.OPER_DATE_START': contextHisMvtOperDateStart,
    LIMIT: contextLimit,
  } = useContext(accountsHistoryParamsContext);
  const tAccIdString = useTAccId();
  const currentTAccId = tAccIdString ? parseInt(tAccIdString, 10) : undefined;
  const tAccId = params['T_ACC.ID'] ?? contextTAccId ?? currentTAccId;
  const hisMvtOperDateStart = params['HIS_MVT.OPER_DATE_START'] ?? contextHisMvtOperDateStart;
  const hisMvtOperDateEnd = params['HIS_MVT.OPER_DATE_END'] ?? contextHisMvtOperDateEnd ?? formatApiParamDate(endOfDay(Date.now()));
  const limit = params.LIMIT ?? contextLimit;
  const defaultParams = useMemo(() => ({
    'T_ACC.ID': tAccId,
    'HIS_MVT.OPER_DATE_END': hisMvtOperDateEnd,
    'HIS_MVT.OPER_DATE_START': hisMvtOperDateStart,
    LIMIT: limit,
  }), [
    tAccId,
    hisMvtOperDateEnd,
    hisMvtOperDateStart,
    limit,
  ]);
  return defaultParams;
};

const useAccountHistoryParams = (): GetAccountsHistoryParams | null => {
  const defaultParams = useAccountHistoryDefaultParams({});
  const tAccId = defaultParams['T_ACC.ID'];
  const isConnected = useIsConnected();
  if (tAccId == null || !isConnected) {
    return null;
  }
  return {
    'T_ACC.ID': tAccId,
    'HIS_MVT.OPER_DATE_START': defaultParams['HIS_MVT.OPER_DATE_START'],
    'HIS_MVT.OPER_DATE_END': defaultParams['HIS_MVT.OPER_DATE_END'],
    LIMIT: defaultParams.LIMIT,
  };
};

export const useAccountHistoryStringifiedParams = () => {
  const contextParams = useAccountHistoryParams();
  return contextParams !== null ? stringifyParams(contextParams) : null;
};
