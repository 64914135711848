import { useCallback } from 'react';
import { msg } from '@lingui/macro';
import { MessageDescriptor } from '@lingui/core';
import { useLingui } from '@lingui/react';
import { useFilterByTAccId } from '../../utils/tAcc/filterByTAccId';
import { PersonAccounts } from '../../type';
import { usePersonAccountTransformApi } from '../api';

export const relationDescriptionMessages: Record<'TITULAIRE' | 'GENERALE' | 'LIMITEE' | 'UNKNOWN', MessageDescriptor> = {
  TITULAIRE: msg`Titulaire`,
  GENERALE: msg`Procuration générale`,
  LIMITEE: msg`Procuration limitée`,
  UNKNOWN: msg`Procuration`,
};

const useRelationDescriptionTransformFunctionFromAccountPosition = () => {
  const {
    i18n,
  } = useLingui();
  const transformFn = useCallback((personAccount: PersonAccounts | undefined | null) => {
    if (!personAccount) {
      return '';
    }
    const relationDescriptionKey = personAccount['T_NATURE_LRP.SHORTCUT'] ?? personAccount['T_PROC_TYPE.SHORTCUT'] ?? 'UNKNOWN';
    const messageDescriptor: MessageDescriptor = relationDescriptionMessages[relationDescriptionKey] ?? relationDescriptionMessages.UNKNOWN;
    return i18n._(messageDescriptor);
  }, [i18n]);
  return transformFn;
};

export const usePersonAccountstNatureLrpOrTProcTypeShortcut = () => {
  const filterPredicate = useFilterByTAccId();
  const transformFunction = useRelationDescriptionTransformFunctionFromAccountPosition();
  const personAccountsCCurrencyTAccCodeApiResponse = usePersonAccountTransformApi({
    filterPredicate,
    transformFunction,
  });
  return personAccountsCCurrencyTAccCodeApiResponse;
};
