import * as React from 'react';
import { dataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import ouiDom from '@goldwasserexchange/oui-dom';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import {
  accountTypeBaseSecondaryDimension,
  accountTypeTableSecondaryDimensionTopModels,
  getSecondaryDimension,
} from './secondaryDimension';
import { accountTypePresentationTablePrimaryDimensionTopModels, accountTypeSelectionTablePrimaryDimensionTopModels, upSmAccountTypeSelectionTablePrimaryDimensionTopModels } from './primaryDimension';
import { useStateFilter } from '../../Machine';
import { useOuiStyleMediaContextByIndex } from '../../MediaContext';
import { OnboardingLinkLabelOnPrimaryDimension, ONBOARDING_LINK } from './secondaryDimension/onboardingLink';
import {
  HighlightContextProvider, HighlightListContextProvider, MobileSelectedAccountTypeContextProvider, useMobileSelectedAccountType,
} from './context';
import { MobileAccountTypeSelector } from './mobileSelector';
import { OrientationInformationContent } from '../../TextIndex/OrientationInformation';
import { AccountTypeMobileInput } from './mobileInput';

const AccountTypeTableSecondaryDimension = (props: React.PropsWithChildren<{
  withSelect: boolean,
  withRecommendation: boolean,
  withOnboardingLink: boolean,
  highlight?: dataStructure.accountType.listType,
  highlightWhiteList?: string[],
  highlightBlackList?: string[],
}>) => {
  const {
    withSelect = false,
    withRecommendation = false,
    withOnboardingLink = false,
    highlight,
    highlightWhiteList = accountTypeBaseSecondaryDimension,
    highlightBlackList = [],
    children,
  } = props;
  const selectedAccountType = useMobileSelectedAccountType();
  const upMq = useOuiStyleMediaContextByIndex(2);
  const primaryDimension = React.useMemo(() => {
    if (upMq || !selectedAccountType) {
      return dataStructure.accountType.list;
    }
    return [selectedAccountType];
  }, [selectedAccountType, upMq]);
  const secondaryDimension = getSecondaryDimension(withSelect, withRecommendation, withOnboardingLink, upMq);
  return (
    <HighlightContextProvider
      highlight={highlight}
    >
      <HighlightListContextProvider
        whiteList={highlightWhiteList}
        blackList={highlightBlackList}
      >
        <ouiDom.Table.Table
          primaryDimensionList={primaryDimension}
          secondaryDimensionList={secondaryDimension}
          direction={upMq ? 'horizontal' : 'vertical'}
        >
          {children}
          {accountTypeTableSecondaryDimensionTopModels}
        </ouiDom.Table.Table>
      </HighlightListContextProvider>
    </HighlightContextProvider>
  );
};

export const AccountTypeSelectionTable = (props: {
  highlight?: dataStructure.accountType.listType,
}) => {
  const {
    highlight,
  } = props;
  const upMq = useOuiStyleMediaContextByIndex(2);
  const withRecommendation = useStateFilter('.withRecommendation') as boolean;
  return (
    <MobileSelectedAccountTypeContextProvider
      initialValue={highlight}
    >
      <div>
        {!upMq ? (
          <ouiDom.P.P>
            <OrientationInformationContent />
          </ouiDom.P.P>
        ) : null}
        <MobileAccountTypeSelector
          highlight={highlight}
        />
        <AccountTypeTableSecondaryDimension
          highlight={highlight}
          withSelect
          withRecommendation={withRecommendation}
          withOnboardingLink={false}
        >
          {accountTypeSelectionTablePrimaryDimensionTopModels}
          {upMq ? upSmAccountTypeSelectionTablePrimaryDimensionTopModels : null}
        </AccountTypeTableSecondaryDimension>
      </div>
      {!upMq ? (
        <AccountTypeMobileInput />
      ) : null}
    </MobileSelectedAccountTypeContextProvider>
  );
};

export const AccountTypePresentationTable = (props: {
  highlight?: dataStructure.accountType.listType,
}) => {
  const { highlight } = props;
  return (
    <MobileSelectedAccountTypeContextProvider
      initialValue={highlight}
    >
      <div>
        <MobileAccountTypeSelector
          highlight={highlight}
        />
        <AccountTypeTableSecondaryDimension
          highlight={highlight}
          withSelect={false}
          withRecommendation={false}
          withOnboardingLink={false}
        >
          {accountTypePresentationTablePrimaryDimensionTopModels}
        </AccountTypeTableSecondaryDimension>
      </div>
    </MobileSelectedAccountTypeContextProvider>
  );
};

export const AccountTypeWithOnboardingTable = (props: {
  currentAccountType: dataStructure.accountType.listType,
}) => {
  const {
    currentAccountType,
  } = props;
  return (
    <MobileSelectedAccountTypeContextProvider
      initialValue={currentAccountType}
    >
      <div>
        <MobileAccountTypeSelector
          highlight={currentAccountType}
        />
        <AccountTypeTableSecondaryDimension
          highlight={currentAccountType}
          withSelect={false}
          withRecommendation={false}
          withOnboardingLink
        >
          {accountTypePresentationTablePrimaryDimensionTopModels}
          <TopModel
            name={`TableBodyRowCellContent--${ONBOARDING_LINK}`}
            currentAccountType={currentAccountType}
          >
            {OnboardingLinkLabelOnPrimaryDimension}
          </TopModel>
        </AccountTypeTableSecondaryDimension>
      </div>
    </MobileSelectedAccountTypeContextProvider>
  );
};
