import * as React from 'react';
import { currentPrimaryDimensionContext } from './context';
import { useCurrentPrimaryDimensionValueByIndex } from './indexes/hooks';
export var CurrentPrimaryDimensionContextProvider = function (props) {
    var currentPrimaryDimension = props.currentPrimaryDimension, children = props.children;
    return (React.createElement(currentPrimaryDimensionContext.Provider, { value: currentPrimaryDimension }, children));
};
export var CurrentPrimaryDimensionByIndexContextProvider = function (props) {
    var children = props.children;
    var primaryKey = useCurrentPrimaryDimensionValueByIndex();
    return (React.createElement(CurrentPrimaryDimensionContextProvider, { currentPrimaryDimension: primaryKey }, children));
};
