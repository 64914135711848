import React from 'react';
import { Trans } from '@lingui/macro';
import {
  dataStructure, UncastedPhysicalTAdd,
} from '@goldwasserexchange/oblis-frontend-utils';
import ouiBase from '@goldwasserexchange/oui';

export const hasSameProfessionAndResidencyCountryLabel = <Trans>L'essentiel de l'activité professionnelle a lieu dans le pays de résidence ?</Trans>;

export const showTAddHasSameProfessionAndResidencyCountryLogic = dataStructure.T_ADD.fields.ID_ECPL_PROF.logic.makeIdEcplProfShouldHaveProfessionLogic<'visible' | 'invisible'>({});

export const tAddHasSameProfessionAndResidencyCountryValidatorLogic: ouiBase.utils.hookstate.FormValueFilterLogic<'requiredBoolean' | 'nilCast'> = dataStructure.T_ADD.fields.ID_ECPL_PROF.logic.makeIdEcplProfShouldHaveProfessionLogic({
  visible: {
    result: 'requiredBoolean',
  },
  invisible: {
    result: 'nilCast',
  },
});

const hasSameProfessionAndResidencyCountry: keyof Pick<UncastedPhysicalTAdd, 'HasSameProfessionAndResidencyCountry'> = 'HasSameProfessionAndResidencyCountry';

export const makeHasSameProfessionAndResidencyCountryFilterLogic = (props: { is: boolean | boolean[], alternatives: Record<'truthy' | 'falsy', ouiBase.utils.hookstate.FormValueFilterLogic<'visible' | 'invisible'>>, options?: ouiBase.utils.match.MatchArrayOptions }): ouiBase.utils.hookstate.FormValueFilterLogic<'visible' | 'invisible'> => {
  const { is, alternatives, options } = props;
  return ({
    name: `<parentName>.${hasSameProfessionAndResidencyCountry}`,
    is,
    options: {
      ...options,
      matcher: ouiBase.utils.match.arrayMatcherNames.some,
    },
    alternatives,
  });
};
