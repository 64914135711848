import { useMemo } from 'react';
import ouiBase from '@goldwasserexchange/oui';
import { useHasFocusVariant } from '../../focus';
import { useHasContentVariant } from '../../content';
import { useDisabledVariant } from '../../disabled';
import { useValidationVariant } from '../../valid';
import { useBaseVariant } from './useBaseVariant';
import { InputVariantsNames, InputVariantHook } from './inputVariants';

export type InputVariantHooksArray = InputVariantHook[];

const defaultInputVariantHooksArray: InputVariantHooksArray = [
  useBaseVariant,
  useHasContentVariant,
  useHasFocusVariant,
  useValidationVariant,
  useDisabledVariant,
];

export const useInputCurrentVariantNames = (
  inputVariantHooksArray: InputVariantHooksArray = defaultInputVariantHooksArray,
): InputVariantsNames[] => {
  const freezedInputVariantHooksArray = ouiBase.utils.freeze.useFreezeArray(
    inputVariantHooksArray,
    'inputVariantHooksArray',
  );
  const variantNames = freezedInputVariantHooksArray.map((useGetVariantName) => useGetVariantName()); // eslint-disable-line react-hooks/rules-of-hooks
  const result = useMemo((): InputVariantsNames[] => variantNames, [
    ...variantNames,
  ]);
  return result;
};
