import { GetAccountsPositionsHistoryResponse } from '@goldwasserexchange/actor/rest-services';
import memoize from 'memoizee';
import { AccountHistory } from '../../../accounts/history/type';

const isHisMvtId = (hisMvtId: string) => (historyLine: AccountHistory | GetAccountsPositionsHistoryResponse) => historyLine['HIS_MVT.ID'] === hisMvtId;

function findByHisMvtId(data: GetAccountsPositionsHistoryResponse[], hisMvtId: string): GetAccountsPositionsHistoryResponse | undefined
function findByHisMvtId(data: AccountHistory[], hisMvtId: string): AccountHistory | undefined
function findByHisMvtId(data: (AccountHistory | GetAccountsPositionsHistoryResponse)[], hisMvtId: string): AccountHistory | GetAccountsPositionsHistoryResponse | undefined {
  return data
    .find(isHisMvtId(hisMvtId));
}

export const memoFindByHisMvtId: typeof findByHisMvtId = memoize(findByHisMvtId);
