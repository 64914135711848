import * as React from 'react';
import ouiBase from '@goldwasserexchange/oui';
import { UncastedDataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import {
  FormTouchedContextProvider,
} from './touched';
import {
  FormDisabledContextProvider,
  FormDisabledPreviousValueContextProvider,
  FormDisabledReasonContextProvider,
} from './disabled';
import {
  FormErrorsContextProvider,
  FormValidatorContext,
} from './validation';
import {
  FormCorrectAnswerContextProvider,
} from './correct';

export const BaseFormContext = (props: React.PropsWithChildren<{
  makeInitialValues: () => UncastedDataStructure,
  validator,
}>) => {
  const {
    makeInitialValues,
    validator,
    children,
  } = props;
  return (
    <ouiBase.Form.FormDataProvider<UncastedDataStructure>
      initial={makeInitialValues()}
    >
      <FormValidatorContext validator={validator}>
        <FormDisabledContextProvider>
          <FormDisabledReasonContextProvider>
            <FormDisabledPreviousValueContextProvider>
              <FormErrorsContextProvider>
                <FormTouchedContextProvider>
                  {children}
                </FormTouchedContextProvider>
              </FormErrorsContextProvider>
            </FormDisabledPreviousValueContextProvider>
          </FormDisabledReasonContextProvider>
        </FormDisabledContextProvider>
      </FormValidatorContext>
    </ouiBase.Form.FormDataProvider>
  );
};

export const FormQuizzContext = (props: React.PropsWithChildren<{
  correctAnswers: Record<string, string>,
}>) => {
  const {
    correctAnswers,
    children,
  } = props;
  return (
    <FormCorrectAnswerContextProvider correctAnswers={correctAnswers}>
      {children}
    </FormCorrectAnswerContextProvider>
  );
};

export const FullFormContext = (props: React.PropsWithChildren<{
  makeInitialValues: () => UncastedDataStructure,
  validator,
  correctAnswers: Record<string, string>,
}>) => {
  const {
    makeInitialValues,
    validator,
    correctAnswers,
    children,
  } = props;
  return (
    <BaseFormContext
      makeInitialValues={makeInitialValues}
      validator={validator}
    >
      <FormQuizzContext
        correctAnswers={correctAnswers}
      >
        {children}
      </FormQuizzContext>
    </BaseFormContext>
  );
};
