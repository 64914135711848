import ouiStyle from '@goldwasserexchange/oui-style';
import { getUnixTime } from 'date-fns';
import * as React from 'react';
import { useBondSAccId, useBondsBondSAccOblEndDate } from '../../../../../actor/api/Services/bonds';
import { parseActorDate } from '../../../../../actor/api/shared/utils/parseDates';
import { LikeBondButton } from './component';

export const LikeBondButtonOnBondSAccId = (props: {
  isFavoriteWhileLoading?: boolean,
  display?: ouiStyle.InStyleWithMediaQueries['display'],
  justifyContent?: ouiStyle.InStyleWithMediaQueries['justifyContent'],
  alignItems?: ouiStyle.InStyleWithMediaQueries['alignItems'],
  backgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
  favoriteBackgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
  color?: ouiStyle.InStyle['color'],
  favoriteColor?: ouiStyle.InStyle['color'],
  fillColor?: ouiStyle.Constants.Colors,
  favoriteFillColor?: ouiStyle.Constants.Colors,
  hoverBackgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
  favoriteHoverBackgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
  iconSize?: string | number,
  paddingHorizontal?: ouiStyle.InStyleWithMediaQueries['paddingHorizontal'],
  paddingVertical?: ouiStyle.InStyleWithMediaQueries['paddingVertical'],
  paddingTop?: ouiStyle.InStyleWithMediaQueries['paddingTop'],
  paddingBottom?: ouiStyle.InStyleWithMediaQueries['paddingBottom'],
  iconTransform?: ouiStyle.InStyle['transform'],
  marginLeft?: ouiStyle.InStyleWithMediaQueries['marginLeft'],
  marginRight?: ouiStyle.InStyleWithMediaQueries['marginRight'],
  position?: ouiStyle.InStyleWithMediaQueries['position'],
  top?: ouiStyle.InStyleWithMediaQueries['top'],
  left?: ouiStyle.InStyleWithMediaQueries['left'],
  right?: ouiStyle.InStyleWithMediaQueries['right'],
  bottom?: ouiStyle.InStyleWithMediaQueries['bottom'],
}) => {
  const {
    isFavoriteWhileLoading,
    display,
    alignItems,
    justifyContent,
    color,
    favoriteColor,
    backgroundColor,
    favoriteBackgroundColor,
    fillColor,
    favoriteFillColor,
    hoverBackgroundColor,
    favoriteHoverBackgroundColor,
    iconSize,
    paddingHorizontal,
    paddingVertical,
    paddingTop,
    paddingBottom,
    iconTransform,
    marginLeft,
    marginRight,
    position,
    top,
    left,
    right,
    bottom,
  } = props;
  const sAccId = useBondSAccId();
  const {
    data,
  } = useBondsBondSAccOblEndDate();
  const ttl = data != null && data !== '∞' ? getUnixTime(parseActorDate(data)) : undefined;
  return (
    <LikeBondButton
      sAccId={sAccId}
      ttl={ttl}
      isFavoriteWhileLoading={isFavoriteWhileLoading}
      display={display}
      alignItems={alignItems}
      justifyContent={justifyContent}
      backgroundColor={backgroundColor}
      favoriteBackgroundColor={favoriteBackgroundColor}
      color={color}
      favoriteColor={favoriteColor}
      hoverBackgroundColor={hoverBackgroundColor}
      favoriteHoverBackgroundColor={favoriteHoverBackgroundColor}
      fillColor={fillColor}
      favoriteFillColor={favoriteFillColor}
      iconSize={iconSize}
      paddingHorizontal={paddingHorizontal}
      paddingVertical={paddingVertical}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      iconTransform={iconTransform}
      marginLeft={marginLeft}
      marginRight={marginRight}
      position={position}
      top={top}
      left={left}
      right={right}
      bottom={bottom}
    />
  );
};
