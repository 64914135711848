import useSWR, { KeyedMutator } from 'swr';
import { useMemo } from 'react';
import { HistoryByCurrency } from '../type';
import { History } from '../../enum';
import { AccountHistory, useAccountHistoryStringifiedParams } from '../../../accounts';
import { memoHistoryGroupByCurrency } from '../utils';
import { getServiceUrl } from '../../../../shared';
import { ACTOR_DEDUPING_INTERVAL } from '../../../shared';
import { useFetchWithAuthWithSend } from '../../../shared/utils/fetchWithAuth';
import { makeApiUrl } from '../../../../../../aws';

const useMakeHistoryByCurrencyUrl = () => {
  const stringifiedParams = useAccountHistoryStringifiedParams();
  const url = stringifiedParams !== null ? `${makeApiUrl('actor')}/${getServiceUrl(History.byCurrency)}${stringifiedParams}` : null;
  return url;
};

type BaseUseHistoryByCurrencyApiResponse = {
  isLoading: boolean,
  error?: any,
  mutate: KeyedMutator<AccountHistory[]>,
  isValidating: boolean,
}

type UseHistoryByCurrencyLinesApiResponse = {
  data: undefined | HistoryByCurrency[],
} & BaseUseHistoryByCurrencyApiResponse;

type FilterPredicate = (historyByCurrency: HistoryByCurrency) => boolean;

type SortPredicate = (prevHistoryByCurrency: HistoryByCurrency, nextHistoryByCurrency: HistoryByCurrency) => number;

type UseHistoryByCurrencyLinesApiProps = {
  filterPredicate?: FilterPredicate,
  sortPredicate?: SortPredicate,
};

export const useHistoryByCurrencyLinesApi = (props: UseHistoryByCurrencyLinesApiProps): UseHistoryByCurrencyLinesApiResponse => {
  const {
    filterPredicate,
    sortPredicate,
  } = props;
  const url = useMakeHistoryByCurrencyUrl();
  const fetcher = useFetchWithAuthWithSend();
  const result = useSWR<AccountHistory[], any>(
    url,
    fetcher,
    { dedupingInterval: ACTOR_DEDUPING_INTERVAL },
  );
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    let data = memoHistoryGroupByCurrency(result.data);
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      if (filterPredicate) {
        data = data.filter((historyByCurrency) => filterPredicate(historyByCurrency));
      }
    }
    return ({
      ...result,
      data,
      isLoading,
    });
  }, [result, filterPredicate, sortPredicate]);
  return resultWithLoading;
};

type TransformArrayFunction<T> = (historyByCurrencyLines: HistoryByCurrency[] | undefined) => T

type UseHistoryByCurrencyLinesApiTransformProps<T> = {
  transformFunction: TransformArrayFunction<T>,
} & UseHistoryByCurrencyLinesApiProps

type UseHistoryByCurrencyApiTransformResponse<T> = {
  data: T | undefined,
} & BaseUseHistoryByCurrencyApiResponse;

export function useHistoryByCurrencyLinesTransformApi<T>(props: UseHistoryByCurrencyLinesApiTransformProps<T>): UseHistoryByCurrencyApiTransformResponse<T> {
  const {
    filterPredicate,
    sortPredicate,
    transformFunction,
  } = props;
  const response = useHistoryByCurrencyLinesApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: (!isLoading && !error) ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UseHistoryByCurrencyApiResponse = {
  data: HistoryByCurrency | undefined,
} & BaseUseHistoryByCurrencyApiResponse;

export const useHistoryByCurrencyApi = (props: UseHistoryByCurrencyLinesApiProps): UseHistoryByCurrencyApiResponse => {
  const {
    filterPredicate,
    sortPredicate,
  } = props;
  const url = useMakeHistoryByCurrencyUrl();
  const fetcher = useFetchWithAuthWithSend();
  const result = useSWR<AccountHistory[], any>(
    url,
    fetcher,
    { dedupingInterval: ACTOR_DEDUPING_INTERVAL },
  );
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;

    let data: HistoryByCurrency[] | HistoryByCurrency | undefined = memoHistoryGroupByCurrency(result.data);
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      if (filterPredicate) {
        data = data.find((historyByCurrency) => filterPredicate(historyByCurrency));
      }
      if (Array.isArray(data)) {
        // eslint-disable-next-line prefer-destructuring
        data = data[0];
      }
    }
    return ({
      ...result,
      data,
      isLoading,
    });
  }, [result, filterPredicate, sortPredicate]);
  return resultWithLoading;
};

type TransformFunction<T> = (historyByCurrency: HistoryByCurrency | undefined) => T

type UseHistoryByCurrencyApiTransformProps<T> = {
  transformFunction: TransformFunction<T>,
} & UseHistoryByCurrencyLinesApiProps;

export function useHistoryByCurrencyTransformApi<T>(props: UseHistoryByCurrencyApiTransformProps<T>): UseHistoryByCurrencyApiTransformResponse<T> {
  const {
    filterPredicate,
    sortPredicate,
    transformFunction,
  } = props;
  const response = useHistoryByCurrencyApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: !isLoading && !error ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UseHistoryByCurrencyFieldApiResponse<Field extends keyof HistoryByCurrency> = {
  data: HistoryByCurrency[Field] | undefined,
} & BaseUseHistoryByCurrencyApiResponse;

type UseHistoryByCurrencyFieldApiProps<Field extends keyof HistoryByCurrency> = {
  field: Field,
} & UseHistoryByCurrencyLinesApiProps

export function useHistoryByCurrencyFieldApi<Field extends keyof HistoryByCurrency>(props: UseHistoryByCurrencyFieldApiProps<Field>): UseHistoryByCurrencyFieldApiResponse<Field> {
  const {
    filterPredicate,
    sortPredicate,
    field,
  } = props;
  const response = useHistoryByCurrencyApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
    } = response;
    return {
      ...response,
      data: data ? data[field] : undefined,
    };
  }, [response, field]);
  return result;
}
