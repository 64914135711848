import { Trans } from '@lingui/macro';
import { format, subYears } from 'date-fns';
import * as React from 'react';
import { DefaultScrollableDom } from '../../../../../../OUI-dom/Scrollable';
import { MediaContext } from '../../../../../MediaContext';
import { HistoryTimeNavContainer } from './container';
import { HistoryTimeNavListElementContainer } from './elementContainer';
import { timeNavListContainer } from './listContainer';
import { scrollButtonContainer } from './scrollButtonContainer';
import { HistoryTimeNavTitleElementContainer } from './titleElement';
import { timeNavWrapper } from './wrapper';
import { DateInterval } from '../../../../../../history';

export const TimeNav = () => {
  const currentDate = Date.now();
  const previousYear = subYears(currentDate, 1);
  const {
    upMd,
  } = React.useContext(MediaContext);
  return (
    <HistoryTimeNavContainer>
      {upMd ? (
        <HistoryTimeNavTitleElementContainer>
          <Trans>
            Sélectionner la période
          </Trans>
        </HistoryTimeNavTitleElementContainer>
      ) : null}
      <DefaultScrollableDom>
        {timeNavWrapper}
        {timeNavListContainer}
        {scrollButtonContainer}
        <HistoryTimeNavListElementContainer
          time={DateInterval.three_months}
        >
          <Trans>
            3 mois
          </Trans>
        </HistoryTimeNavListElementContainer>
        <HistoryTimeNavListElementContainer
          time={DateInterval.six_months}
        >
          <Trans>
            6 mois
          </Trans>
        </HistoryTimeNavListElementContainer>
        <HistoryTimeNavListElementContainer
          time={DateInterval.nine_months}
        >
          <Trans>
            9 mois
          </Trans>
        </HistoryTimeNavListElementContainer>
        <HistoryTimeNavListElementContainer
          time={DateInterval.one_year}
        >
          <Trans>
            1 an
          </Trans>
        </HistoryTimeNavListElementContainer>
        <HistoryTimeNavListElementContainer
          time={DateInterval.this_year}
        >
          {format(currentDate, 'yyyy')}
        </HistoryTimeNavListElementContainer>
        <HistoryTimeNavListElementContainer
          time={DateInterval.last_year}
          isLast
        >
          {format(previousYear, 'yyyy')}
        </HistoryTimeNavListElementContainer>
      </DefaultScrollableDom>
    </HistoryTimeNavContainer>
  );
};
