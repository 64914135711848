import { addDays, differenceInCalendarDays, parse } from 'date-fns';
import { useMemo } from 'react';
import { times } from 'ramda';
import { SecuritiesQuotes } from '../../type';
import { parseActorDate } from '../../../../../shared/utils/parseDates';
import { useSecuritiesQuotesTransformApi } from '../api';
import { useSecuritiesQuotesDefaultParams } from '../defaultParams';

export const useMakeSecurityQuotesLoadingGraphData = (baseLoadingPrice: number | null | undefined) => {
  const {
    'S_QUOTE.ARCHIVE_DATE_START': startDate,
    'S_QUOTE.ARCHIVE_DATE_END': endDate,
  } = useSecuritiesQuotesDefaultParams({});
  const dateStart = useMemo(() => (startDate ? parse(startDate, 'yyyyMMdd', new Date()) : 0), [startDate]);
  const dateEnd = useMemo(() => (endDate ? parse(endDate, 'yyyyMMdd', new Date()) : 0), [endDate]);
  const numberOfDays = differenceInCalendarDays(dateEnd, dateStart);
  const loadingData = useMemo(() => times((i) => ({
    x: addDays(dateStart, i).getTime(),
  }), numberOfDays).reduce<{
    x: number,
    y: number,
  }[]>((acc, el) => {
    const prevY = (acc.at(-1)?.y) ?? baseLoadingPrice ?? 100;
    const random = (Math.random() * 0.5);
    return [
      ...acc,
      {
        ...el,
        y: Math.random() < 0.5 ? prevY + random : prevY - random,
      },
    ];
  }, []), [dateStart]);
  return loadingData;
};

const securityQuotesToGraphData = (quotes: SecuritiesQuotes[] | null | undefined) => (quotes ? quotes.map((quote) => ({ x: parseActorDate(quote['S_QUOTE.ARCHIVE_DATE']).getTime(), y: quote['S_QUOTE.PRICE_CLOSE'] })) : []);

export const useSecurityQuotesGraphData = (withSend?: boolean) => {
  const response = useSecuritiesQuotesTransformApi({
    transformFunction: securityQuotesToGraphData,
    withSend,
  });
  return response;
};

export const useSecurityQuotesGraphDataWithLoadingData = (
  externalLoading: boolean,
  baseLoadingPrice?: number | null,
  withSend?: boolean,
) => {
  const response = useSecurityQuotesGraphData(withSend);
  const loadingData = useMakeSecurityQuotesLoadingGraphData(baseLoadingPrice);
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      ...rest
    } = response;
    const dataWithLoading = !isLoading && !externalLoading ? data : loadingData;
    return {
      data: dataWithLoading,
      isLoading: isLoading || externalLoading,
      ...rest,
    };
  }, [response, externalLoading, loadingData]);
  return result;
};
