import setOpening from './setOpening';
import setClosing from './setClosing';
import setClosed from './setClosed';
import setOpen from './setOpen';
import setMqUp from './setMqUp';
import setMqDown from './setMqDown';
import resetMqAction from './resetMqAction';
import setCollapse from './setCollapse';
import setUncollapse from './setUncollapse';
import resetCollapseAction from './resetCollapseAction';
import setTargetOpenExpanded from './setTargetOpenExpanded';
import setTargetOpenShrinked from './setTargetOpenShrinked';
import setTargetClosed from './setTargetClosed';
import setTargetCollapsed from './setTargetCollapsed';
import resetLogoExpandingAction from './resetLogoExpandingAction';
import setLogoExpanding from './setLogoExpanding';
import setLogoShrinking from './setLogoShrinking';

export * as setOpening from './setOpening';
export * as setClosing from './setClosing';
export * as setClosed from './setClosed';
export * as setOpen from './setOpen';
export * as setMqUp from './setMqUp';
export * as setMqDown from './setMqDown';
export * as resetMqAction from './resetMqAction';
export * as setCollapse from './setCollapse';
export * as setUncollapse from './setUncollapse';
export * as resetCollapseAction from './resetCollapseAction';
export * as setTargetOpenExpanded from './setTargetOpenExpanded';
export * as setTargetOpenShrinked from './setTargetOpenShrinked';
export * as setTargetClosed from './setTargetClosed';
export * as setTargetCollapsed from './setTargetCollapsed';
export * as resetLogoExpandingAction from './resetLogoExpandingAction';
export * as setLogoExpanding from './setLogoExpanding';
export * as setLogoShrinking from './setLogoShrinking';

export const actions = {
  ...setOpening,
  ...setClosing,
  ...setClosed,
  ...setOpen,
  ...setMqUp,
  ...setMqDown,
  ...resetMqAction,
  ...setCollapse,
  ...setUncollapse,
  ...resetCollapseAction,
  ...setTargetOpenExpanded,
  ...setTargetOpenShrinked,
  ...setTargetClosed,
  ...setTargetCollapsed,
  ...resetLogoExpandingAction,
  ...setLogoExpanding,
  ...setLogoShrinking,
};

export default actions;
