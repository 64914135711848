import * as React from 'react';
import { useCallback } from 'react';
import ouiDom from '@goldwasserexchange/oui-dom';
import ouiStyle from '@goldwasserexchange/oui-style';
import { ScrollDirections, useScrollAdd } from '../../../../OUI/Scrollable';

export const ScrollableScrollButtonContainerDom = (props: React.PropsWithChildren<{
  direction: ScrollDirections,
  scrollPixels?: number,
  hoverBackgroundColor?: ouiStyle.ButtonStyleWithMediaQueries['hoverBackgroundColor'],
} & ouiStyle.InStyleWithMediaQueries>) => {
  const {
    direction,
    children,
    position = 'absolute',
    left = direction === 'left' ? 0 : undefined,
    right = direction === 'right' ? 0 : undefined,
    scrollPixels = 100,
    ...buttonStyleProps
  } = props;
  const scrollAdd = useScrollAdd();
  const onClick = useCallback(() => {
    if (direction === 'left') {
      scrollAdd(-scrollPixels);
    } else if (direction === 'right') {
      scrollAdd(scrollPixels);
    }
  }, [direction, scrollAdd, scrollPixels]);
  return (
    <ouiDom.Button.Button
      {...buttonStyleProps}
      type="button"
      onClick={onClick}
      position={position}
      left={left}
      right={right}
      cursor="pointer"
    >
      {children}
    </ouiDom.Button.Button>
  );
};
