/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import React from 'react';

export const defaultOrderedListElementContainerStyle: ouiStyle.InStyle = {
  display: ouiStyle.Constants.DisplayProp.listItem,
  listStyleType: ouiStyle.Constants.ListStyleTypeProps.ordered,
};

const defaultOrderedListElementContainerCss = ouiStyle.makeCss(
  defaultOrderedListElementContainerStyle,
);

export function DefaultOrderedListElementContainer(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  return <li css={defaultOrderedListElementContainerCss}>{children}</li>;
}
