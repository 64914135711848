import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { AccountType } from '@goldwasserexchange/oblis-frontend-utils';
import { PremiumIcon } from './icon';
import { premiumTwoLineTitleContent } from './title';
import { premiumAdvantages } from './advantages';

export const detailedAccountTypeLabelsHeadingContentPremium = (
  <>
    <TopModel name={`HeadingSectionIllustrationContent-${AccountType.CONSEIL}-section`}>
      {PremiumIcon}
    </TopModel>
    <TopModel name={`HeadingSectionTitleContent-${AccountType.CONSEIL}-section`}>
      {premiumTwoLineTitleContent}
    </TopModel>
    {premiumAdvantages}
  </>
);
