/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, keyframes } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { ToggleRemSize, ToggleViewBoxSize } from '../../../../../../constants';

const toggleIconBorderStyle: ouiStyle.InStyle = {
  borderColor: ouiStyle.Constants.Colors.inverted,
  borderStyle: 'none',
  borderWidth: 'none',
};

const toggleIconBorderCss = ouiStyle.makeCss(toggleIconBorderStyle);

const toggleIconCss = {
  borderRadius: '9999px',
  minWidth: `${(ToggleRemSize / ToggleViewBoxSize[1]) * ToggleViewBoxSize[0]}rem`,
  maxWidth: `${(ToggleRemSize / ToggleViewBoxSize[1]) * ToggleViewBoxSize[0]}rem`,
  width: `${(ToggleRemSize / ToggleViewBoxSize[1]) * ToggleViewBoxSize[0]}rem`,
  height: `${ToggleRemSize}rem`,
  minHeight: `${ToggleRemSize}rem`,
  maxHeight: `${ToggleRemSize}rem`,
  borderTopColor: toggleIconBorderCss.borderTopColor,
  borderBottomColor: toggleIconBorderCss.borderBottomColor,
  borderLeftColor: toggleIconBorderCss.borderLeftColor,
  borderRightColor: toggleIconBorderCss.borderRightColor,
  borderTopStyle: toggleIconBorderCss.borderTopStyle,
  borderBottomStyle: toggleIconBorderCss.borderBottomStyle,
  borderLeftStyle: toggleIconBorderCss.borderLeftStyle,
  borderRightStyle: toggleIconBorderCss.borderRightStyle,
  borderTopWidth: toggleIconBorderCss.borderTopWidth,
  borderBottomWidth: toggleIconBorderCss.borderBottomWidth,
  borderLeftWidth: toggleIconBorderCss.borderLeftWidth,
  borderRightWidth: toggleIconBorderCss.borderRightWidth,
  animationDuration: '0.1s',
  animationTimingFunction: 'linear',
  animationIterationCount: 1,
  animationFillMode: 'forwards',
};

const activateKeyFrames = keyframes`
  0% {
    background-color: ${ouiStyle.Constants.Colors.accent};
  }

  100% {
    background-color: ${ouiStyle.Constants.Colors.fadeDarker};
  }
`;

const toggleActiveTouched = {
  ...toggleIconCss,
  animationName: activateKeyFrames,
  pointer: 'cursor',
};

const toggleActiveUntouched = {
  ...toggleIconCss,
  backgroundColor: ouiStyle.Constants.Colors.fadeDarker,
  pointer: 'cursor',
};

const deactivateKeyFrames = keyframes`
  0% {
    background-color: ${ouiStyle.Constants.Colors.fadeDarker};
  }

  100% {
    background-color: ${ouiStyle.Constants.Colors.accent};
  }
`;

const toggleInactiveTouched = {
  ...toggleIconCss,
  animationName: deactivateKeyFrames,
};

const toggleInactiveUntouched = {
  ...toggleIconCss,
  backgroundColor: ouiStyle.Constants.Colors.accent,
};

const getCss = (props: {
  touched: boolean,
  value: boolean,
}) => {
  const {
    touched,
    value,
  } = props;
  if (touched) {
    if (value) {
      return toggleActiveTouched;
    }
    return toggleInactiveTouched;
  }
  if (value) {
    return toggleActiveUntouched;
  }
  return toggleInactiveUntouched;
};

export const ToggleIconContainer = (props: React.PropsWithChildren<{
  value: boolean,
  touched: boolean,
}>) => {
  const {
    value,
    touched,
    children,
  } = props;
  const css = getCss({
    touched,
    value,
  });
  return (
    <svg
      viewBox={`0 0 ${ToggleViewBoxSize[0]} ${ToggleViewBoxSize[1]}`}
      xmlns="http://www.w3.org/2000/svg"
      css={css}
    >
      {children}
    </svg>
  );
};
