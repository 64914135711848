export enum STypeGrpIds {
  CASH = -1,
  FONDS = 66,
  OBLIGATIONS_SUB = 124,
  OBLIGATIONS = 63,
  TRACKERS = 65,
  OBLIGATIONS_PERP = 123,
  TRACKERS_MAT = 203,
  ACTIONS = 64,
}
