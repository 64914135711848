import * as React from 'react';
import { isAfter, isBefore, parseISO } from 'date-fns';
import { BannerContainer, BannerContent } from './components';
import { useLocalizedFirstSanityBanner } from '../../sanity';

export const Banner = () => {
  const {
    data,
  } = useLocalizedFirstSanityBanner();
  if (data == null || isAfter(parseISO(data.from), Date.now()) || isBefore(parseISO(data.to), Date.now())) {
    return null;
  }
  return (
    <BannerContainer>
      <BannerContent
        content={data.content}
      />
    </BannerContainer>
  );
};
