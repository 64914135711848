import * as React from 'react';
import { prefix, TopModelNameTransformProvider } from '@goldwasserexchange/react-topmodel';
import ouiBase from '@goldwasserexchange/oui';
import ouiStyle from '@goldwasserexchange/oui-style';
import {
  CheckboxListInputStructure,
  checkboxListInputPrefixer,
  DefaultContextProviderContextsTopModelsProvider,
  DefaultListInputTopModelsProvider,
  DefaultCheckboxListInputTopModelsProvider,
  DefaultInputErrorMessageTopModelsProvider,
} from '../../../../OUI/Inputs';
import {
  DefaultIconContentDomTopModelsProvider,
  DefaultFooterDomTopModelsProvider,
  DefaultListInputDomTopModelsProvider,
} from '../shared';
import { checkboxListInputDomDefaultTopModelsObject } from './topmodels';
import { UseGetFnProps } from '../../../../OUI/Inputs/components/shared/components/list/context/getFn/context';
import { SearchKeysProps } from '../../../../OUI/Inputs/components/shared/components/list/context/searchKeys/context';

export function CheckboxListInput(
  props: React.PropsWithChildren<
  {
    fieldPath: string,
    validateFieldPath?: boolean,
    elementFieldPathHook: () => string,
    elementLines?: ouiStyle.InStyle['lines'],
    dependenciesFieldPaths?: string[],
    checkboxPotentialFieldPaths?: string[],
  } & Partial<SearchKeysProps> & Partial<UseGetFnProps> & ouiBase.List.ListProps & ouiBase.List.ListDataFilterAndSortProviderProps
  >,
) {
  const {
    fieldPath,
    validateFieldPath,
    elementFieldPathHook,
    list,
    length,
    elementLines,
    dependenciesFieldPaths,
    checkboxPotentialFieldPaths,
    useFilterFn,
    useSortFn,
    useGetFn,
    searchKeys,
    children,
  } = props;
  return (
    <TopModelNameTransformProvider transformFn={checkboxListInputPrefixer}>
      <DefaultContextProviderContextsTopModelsProvider>
        <DefaultIconContentDomTopModelsProvider>
          <DefaultFooterDomTopModelsProvider>
            <ouiBase.List.DefaultListContextTopModelsProvider
              transformBeforeFn={prefix('InputList')}
            >
              <ouiBase.List.DefaultListMapperTopModelsProvider
                transformBeforeFn={prefix('InputList')}
              >
                <ouiBase.List.DefaultListElementBaseTopModelsProvider
                  transformBeforeFn={prefix('InputList')}
                >
                  <ouiBase.List.DefaultListElementSubContextsTopModelsProvider
                    transformBeforeFn={prefix('InputList')}
                  >
                    <DefaultListInputTopModelsProvider>
                      <DefaultInputErrorMessageTopModelsProvider>
                        <DefaultListInputDomTopModelsProvider>
                          <DefaultCheckboxListInputTopModelsProvider>
                            <CheckboxListInputStructure
                              fieldPath={fieldPath}
                              validateFieldPath={validateFieldPath}
                              elementFieldPathHook={elementFieldPathHook}
                              dependenciesFieldPaths={dependenciesFieldPaths}
                              checkboxPotentialFieldPaths={
                                checkboxPotentialFieldPaths
                              }
                              list={list}
                              length={length}
                              elementLines={elementLines}
                              useFilterFn={useFilterFn}
                              useSortFn={useSortFn}
                              useGetFn={useGetFn}
                              searchKeys={searchKeys}
                              topModelsOverride={
                                checkboxListInputDomDefaultTopModelsObject
                              }
                            >
                              {children}
                            </CheckboxListInputStructure>
                          </DefaultCheckboxListInputTopModelsProvider>
                        </DefaultListInputDomTopModelsProvider>
                      </DefaultInputErrorMessageTopModelsProvider>
                    </DefaultListInputTopModelsProvider>
                  </ouiBase.List.DefaultListElementSubContextsTopModelsProvider>
                </ouiBase.List.DefaultListElementBaseTopModelsProvider>
              </ouiBase.List.DefaultListMapperTopModelsProvider>
            </ouiBase.List.DefaultListContextTopModelsProvider>
          </DefaultFooterDomTopModelsProvider>
        </DefaultIconContentDomTopModelsProvider>
      </DefaultContextProviderContextsTopModelsProvider>
    </TopModelNameTransformProvider>
  );
}
