import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { DefaultListMapper, DefaultVirtualizedHorizontalListMapper, DefaultVirtualizedListMapper } from '../components';
export var listMapperTopModelsObject = {
    Mapper: React.createElement(TopModel, { name: "Mapper" }, DefaultListMapper),
};
export var virtualizedListMapperTopModelsObject = {
    Mapper: React.createElement(TopModel, { name: "Mapper" }, DefaultVirtualizedListMapper),
};
export var virtualizedHorizontalListMapperTopModelsObject = {
    Mapper: React.createElement(TopModel, { name: "Mapper" }, DefaultVirtualizedHorizontalListMapper),
};
