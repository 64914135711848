import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { Trans } from '@lingui/macro';
import { UncastedDataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import ouiBase from '@goldwasserexchange/oui';
import { TextInput } from '../../../../OUI-dom/Inputs';

export const PasswordInput = (props: {
  withRepeat?: boolean,
}) => {
  const {
    withRepeat = false,
  } = props;
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  return (
    <>
      <TextInput
        fieldPath={valueState.auth.password.path.join('.')}
        type="password"
      >
        <TopModel name="TextInputLabelContent">
          <Trans>Mot de passe</Trans>
        </TopModel>
      </TextInput>
      {withRepeat ? (
        <TextInput
          fieldPath={valueState.auth.passwordRepeat.path.join('.')}
          type="password"
          dependenciesFieldPaths={[
            valueState.auth.password.path.join('.'),
          ]}
        >
          <TopModel name="TextInputLabelContent">
            <Trans>Répétition du mot de passe</Trans>
          </TopModel>
          <TopModel name="TextInputEqualsRefError">
            <Trans>Différent du mot de passe</Trans>
          </TopModel>
        </TextInput>
      ) : null}
    </>
  );
};
