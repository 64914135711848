import {
  UncastedDataStructure,
  dataStructure,
  UncastedProduct,
  STypeGrp,
  UncastedKnowledgeQuestionnaire,
  AccountType,
  makeName,
  getIn,
  UncastedOnboarding,
} from '@goldwasserexchange/oblis-frontend-utils';
import { object } from 'yup';
import ouiBase from '@goldwasserexchange/oui';
import { makeWhenAlwaysCast } from '../../../../../../Form/validations/whenAlwaysCast';
import {
  makeRequired, booleanValidator, nilValidator, stringValidator, makeTrueCast, makeFalseCast,
} from '../../../../../../../OUI/Inputs';

const selectedValidator = makeRequired(booleanValidator);

const experienceDependencyPaths: (keyof UncastedProduct | '$')[] = ['$', 'selected', 'ID_S_TYPE_GRP'];

const experienceValidator = makeWhenAlwaysCast(experienceDependencyPaths, (context: { rootValue: UncastedDataStructure }, selected, ID_S_TYPE_GRP) => {
  const {
    rootValue,
  } = context;
  const {
    onboarding,
  } = rootValue;
  const {
    selectedService,
    knowledgeQuestionnaire,
  } = onboarding;
  const dependencySuccess = selectedService ? dataStructure.financialProductsKnowledge.questionPaths.getIsDisabledByStatus(
    selectedService,
    ID_S_TYPE_GRP,
    knowledgeQuestionnaire as unknown as Record<string, boolean>,
  ) : false;
  const required = selectedService ? dataStructure.financialProductsSelection.logic.KERequired.getKERequiredByService(
    ID_S_TYPE_GRP,
    selectedService,
  ) : false;
  const hasProductSelection = selected === true || selected === '1';
  if (!((required === true) || (hasProductSelection && dependencySuccess && required !== false))) {
    return nilValidator;
  }
  return makeRequired(booleanValidator);
});

const knowledgeDependencyPaths: (keyof UncastedProduct | '$')[] = ['$', 'ID_S_TYPE_GRP'];

const knowledgeValidator = makeWhenAlwaysCast(knowledgeDependencyPaths, (context: { rootValue: UncastedDataStructure }, ID_S_TYPE_GRP) => {
  const {
    rootValue,
  } = context;
  const logic = dataStructure.financialProductsKnowledge.logic.hasKnowledge.make.makeIsQuestionnaireCorrectLogic<
  | 'success'
  | 'failure'
  | 'notNeeded'
  >(
    ID_S_TYPE_GRP,
    {
      noQuestions: {
        result: 'notNeeded',
      },
      notSelected: {
        result: 'notNeeded',
      },
      notAnswered: {
        result: 'notNeeded',
      },
      success: {
        result: 'success',
      },
      failure: {
        result: 'failure',
      },
      failureWithRetryAvailable: {
        result: 'failure',
      },
      failureWithRetryAvailableOnRequired: {
        result: 'failure',
      },
      retriedFailureOnRequired: {
        result: 'failure',
      },
      truthy: {
        result: 'success',
      },
      falsy: {
        result: 'failure',
      },
    },
  );
  const paths = ouiBase.utils.hookstate.extractPathsFromLogic(logic);
  const states = ouiBase.utils.objectify(makeName, (fieldPath) => ({
    get: () => getIn(rootValue, ouiBase.Form.replaceParentNameTag('', makeName(fieldPath))),
  }), paths);
  const result = ouiBase.utils.hookstate.executeFilterLogic(logic, states);
  if (result === 'success') {
    return makeRequired(makeTrueCast(booleanValidator));
  }
  if (result === 'failure') {
    return makeRequired(makeFalseCast(booleanValidator));
  }
  return nilValidator;
});

const knowledgeQuestionnaireValidatorShape: Record<keyof UncastedKnowledgeQuestionnaire, any> = {
  success: makeRequired(booleanValidator),
  lastFailedDate: stringValidator,
  failureAndWasRetried: makeRequired(booleanValidator),
};

const knowledgeQuestionnaireNullValidatorShape: Record<keyof UncastedKnowledgeQuestionnaire, any> = {
  success: nilValidator,
  lastFailedDate: nilValidator,
  failureAndWasRetried: nilValidator,
};

const knowledgeQuestionnaireNullValidator = object().shape(knowledgeQuestionnaireNullValidatorShape);

const knowledgeQuestionnairesValidatorDependencyPaths: (keyof UncastedProduct | '$')[] = ['$', 'ID_S_TYPE_GRP'];

const knowledgeQuestionnairesValidator = makeWhenAlwaysCast(knowledgeQuestionnairesValidatorDependencyPaths, (context: { rootValue: UncastedDataStructure }, ID_S_TYPE_GRP) => {
  const {
    rootValue,
  } = context;
  const {
    onboarding,
  } = rootValue;
  const {
    selectedService,
  } = onboarding;
  const logic = dataStructure.financialProductsKnowledge.logic.hasKnowledge.make.makeIsQuestionnaireCorrectLogic<
  | 'success'
  | 'failure'
  | 'notNeeded'
  >(
    ID_S_TYPE_GRP,
    {
      noQuestions: {
        result: 'notNeeded',
      },
      notSelected: {
        result: 'notNeeded',
      },
      notAnswered: {
        result: 'notNeeded',
      },
      success: {
        result: 'success',
      },
      failure: {
        result: 'failure',
      },
      failureWithRetryAvailable: {
        result: 'failure',
      },
      failureWithRetryAvailableOnRequired: {
        result: 'failure',
      },
      retriedFailureOnRequired: {
        result: 'failure',
      },
      truthy: {
        result: 'success',
      },
      falsy: {
        result: 'failure',
      },
    },
  );
  const paths = ouiBase.utils.hookstate.extractPathsFromLogic(logic);
  const states = ouiBase.utils.objectify(makeName, (fieldPath) => ({
    get: () => getIn(rootValue, ouiBase.Form.replaceParentNameTag('', makeName(fieldPath))),
  }), paths);
  const result = ouiBase.utils.hookstate.executeFilterLogic(logic, states);
  if (result === 'success') {
    const successValidator = makeRequired(makeTrueCast(booleanValidator));
    const knowledgeQuestionnaireValidator = object().shape({
      ...knowledgeQuestionnaireValidatorShape,
      success: successValidator,
    });
    return object().shape({
      conseilGestion: selectedService === AccountType.CONSEIL || selectedService === AccountType.GESTION
        ? knowledgeQuestionnaireValidator
        : knowledgeQuestionnaireNullValidator,
      RTO: selectedService === AccountType.RTO
        ? knowledgeQuestionnaireValidator
        : knowledgeQuestionnaireNullValidator,
    });
  }
  if (result === 'failure') {
    const failureValidator = makeRequired(makeFalseCast(booleanValidator));
    const knowledgeQuestionnaireValidator = object().shape({
      ...knowledgeQuestionnaireValidatorShape,
      success: failureValidator,
    });
    return object().shape({
      conseilGestion: selectedService === AccountType.CONSEIL || selectedService === AccountType.GESTION
        ? knowledgeQuestionnaireValidator
        : knowledgeQuestionnaireNullValidator,
      RTO: selectedService === AccountType.RTO
        ? knowledgeQuestionnaireValidator
        : knowledgeQuestionnaireNullValidator,
    });
  }
  return object().shape({
    conseilGestion: knowledgeQuestionnaireNullValidator,
    RTO: knowledgeQuestionnaireNullValidator,
  });
});

const productValidatorShape: Record<keyof UncastedProduct, any> = {
  ID_S_TYPE_GRP: makeRequired(stringValidator),
  selected: selectedValidator,
  experience: experienceValidator,
  knowledge: knowledgeValidator,
  knowledgeQuestionnaires: knowledgeQuestionnairesValidator,
};

const productValidator = object().shape(productValidatorShape);

const productValidatorsShape: Record<keyof UncastedOnboarding['products'], typeof productValidator> = {
  [STypeGrp.OBLIGATIONS]: productValidator,
  [STypeGrp.OBLIGATIONS_SUB]: productValidator,
  [STypeGrp.OBLIGATIONS_PERP]: productValidator,
  [STypeGrp.ACTIONS]: productValidator,
  [STypeGrp.FONDS]: productValidator,
  [STypeGrp.TRACKERS]: productValidator,
  [STypeGrp.TRACKERS_MAT]: productValidator,
};

const serviceAccessibilityShape: Record<keyof UncastedOnboarding['serviceAccessibility'], any> = {
  [AccountType.RTO]: booleanValidator,
  [AccountType.CONSEIL]: booleanValidator,
  [AccountType.GESTION]: booleanValidator,
};

export const serviceAccessibilityValidator = object().shape(serviceAccessibilityShape);

export const productsValidator = object().shape(productValidatorsShape);
