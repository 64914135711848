import { TopModel } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import { SignInStateSwitcher, useSignInState } from '../signInStateSwitcher';
import { InputForgotPasswordCodeAndNewPassword, ForgotPasswordInputUserNameFormComponent } from './forgotPassword';
import { InputCode } from './inputCode';
import { UserNamePassword } from './userNamePassword';
import { AuthErrorMessage } from './errorMessage';
import { ChartLoader } from '../../../../chartLoader';

const signInModalSubmitTypes = {
  UserNamePassword: 'userNamePasswordSignin',
  ReAuth: 'userNamePasswordSignin',
  InputCode: 'inputMFACode',
  ForgotPasswordInputUserName: 'forgotPasswordInputUserName',
  ForgotPasswordInputCodeAndNewPassword: 'forgotPasswordInputCodeAndNewPassword',
  SignInLoading: null,
};

const SigninModalErrorMessage = (props: {
  withUserName: boolean,
}) => {
  const {
    withUserName,
  } = props;
  const state = useSignInState();
  if (state) {
    const submitType = signInModalSubmitTypes[state];
    if (submitType) {
      return (
        <AuthErrorMessage
          submitType={submitType}
          withUserName={withUserName}
        />
      );
    }
    return null;
  }
  return null;
};

const ReAuthContent = (props: {
  withUserNameState: [boolean, React.Dispatch<React.SetStateAction<boolean>>],
}) => {
  const {
    withUserNameState,
  } = props;
  const [withUserName, setWithUserName] = withUserNameState;
  return (
    <UserNamePassword
      withUserName={withUserName}
      withSignUp={withUserName}
      withChangeUser={!withUserName}
      setWithUserName={setWithUserName}
    />
  );
};

export const SignInModalContent = () => {
  const signInState = useSignInState();
  const withUserNameState = React.useState(signInState === 'UserNamePassword');
  const [withUserName] = withUserNameState;
  return (
    <>
      <SigninModalErrorMessage withUserName={withUserName} />
      <SignInStateSwitcher>
        <TopModel
          name="UserNamePassword"
        >
          {UserNamePassword}
        </TopModel>
        <TopModel
          name="ReAuth"
          withUserNameState={withUserNameState}
        >
          {ReAuthContent}
        </TopModel>
        <TopModel name="InputCode">
          {InputCode}
        </TopModel>
        <TopModel
          name="ConfirmSignUp"
          saveDevice={false}
          confirm
        >
          {InputCode}
        </TopModel>
        <TopModel name="ForgotPasswordInputUserName">
          {ForgotPasswordInputUserNameFormComponent}
        </TopModel>
        <TopModel name="ForgotPasswordInputCodeAndNewPassword">
          {InputForgotPasswordCodeAndNewPassword}
        </TopModel>
        <TopModel name="SignInLoading">
          <ChartLoader
            widthLines={['1/3', undefined, undefined, '1/12', undefined, undefined]}
            lines={['1/3', undefined, undefined, '1/12', undefined, undefined]}
          />
        </TopModel>
      </SignInStateSwitcher>
    </>
  );
};
