import {
  UncastedDataStructure, UncastedMoralTAdd, UncastedOnboarding, UncastedPhysicalTAdd,
} from '@goldwasserexchange/oblis-frontend-utils';
import ouiBase from '@goldwasserexchange/oui';
import { State } from '@hookstate/core';
import { useParentFieldPath } from '../../../OUI/Inputs';

const userPath: keyof Pick<UncastedOnboarding, 'users'> = 'users';

export const getUserFromParentName = (parentName: string, valueState: State<UncastedDataStructure>) => {
  const splittedParentName = parentName.split('.');
  const userState: State<UncastedPhysicalTAdd | UncastedMoralTAdd> = splittedParentName[1] === userPath
    ? valueState.onboarding.users[splittedParentName[2]]
    : valueState.onboarding.moralPerson;
  return userState;
};

export const useUserFromParentName = (parentName: string) => {
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const userState: State<UncastedPhysicalTAdd | UncastedMoralTAdd> = getUserFromParentName(parentName, valueState);
  return userState;
};

export const useUser = () => {
  const parentName = useParentFieldPath();
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const userState: State<UncastedPhysicalTAdd | UncastedMoralTAdd> = getUserFromParentName(parentName, valueState);
  return userState;
};
