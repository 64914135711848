/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const navBarListElementStyle: ouiStyle.InStyle = {
  backgroundColor: ouiStyle.Constants.Colors.logoBlue,
  flexBasis: ouiStyle.Constants.FlexBasisProp.auto,
  flexShrink: true,
  flexGrow: true,
  display: ouiStyle.Constants.DisplayProp.flex,
  justifyContent: ouiStyle.Constants.JustifyContentProp.center,
  alignItems: ouiStyle.Constants.AlignItemsProp.center,
  fontSize: 'm',
};

const navBarListElementCss = ouiStyle.makeCss(navBarListElementStyle);

export const NavBarListElement = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  return <li css={navBarListElementCss}>{children}</li>;
};

export default NavBarListElement;
