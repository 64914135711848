/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import { animated } from 'react-spring';
import ouiStyle from '@goldwasserexchange/oui-style';
import { textInputIconContainerStyle } from './style';
import {
  InputVariantsNames,
  InputVariantHooksArray,
  useBaseVariant,
  useHasFocusVariant,
} from '../../../../../../../../OUI/Inputs';
import { useVariantSpring } from '../../../../../../utils';

const textInputIconContainerCss = ouiStyle.makeCss({
  ...textInputIconContainerStyle,
});

const textInputIconContainerBaseCss = ouiStyle.makeCssAndExtract(
  textInputIconContainerStyle,
  ['height', 'paddingBottom'],
);

const textInputIconContainerFocusStyle: ouiStyle.InStyle = {
  ...textInputIconContainerStyle,
  parentBorderBottomWidth: 'double',
};

const textInputIconContainerFocusCss = ouiStyle.makeCssAndExtract(
  textInputIconContainerFocusStyle,
  ['height', 'paddingBottom'],
);

const textInputIconContainerVariants: Record<InputVariantsNames, Partial<Pick<ouiStyle.Style, 'height' | 'paddingBottom'>>> = {
  opened: {},
  closed: {},
  base: textInputIconContainerBaseCss,
  hasContent: {},
  noContent: {},
  focus: textInputIconContainerFocusCss,
  noFocus: {},
  untouched: {},
  valid: {},
  error: {},
  enabled: {},
  disabled: {},
  checked: {},
  unChecked: {},
  last: {},
  notLast: {},
  before: {},
  inside: {},
  after: {},
  noSelection: {},
  notCorrected: {},
  correct: {},
  incorrect: {},
  correctSelected: {},
  correctNotSelected: {},
  incorrectSelected: {},
  incorrectNotSelected: {},
};

const defaultTextInputIconContainerInputVariantHooks: InputVariantHooksArray = [
  useBaseVariant,
  useHasFocusVariant,
];

export function DefaultTextInputIconContainer(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  const spring = useVariantSpring(
    textInputIconContainerVariants,
    defaultTextInputIconContainerInputVariantHooks,
  );
  return (
    <animated.div css={textInputIconContainerCss} style={spring}>
      {children}
    </animated.div>
  );
}
