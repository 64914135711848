import { useFilterByContextCCurrencyCode } from '../../../../shared';
import { useCashflowByCurrencyFieldApi } from '../api';

export const useCashflowByCurrencyExrateHisExrateGes = () => {
  const filterPredicate = useFilterByContextCCurrencyCode();
  const result = useCashflowByCurrencyFieldApi({
    filterPredicate,
    field: 'EXRATE_HIS.EXRATE_GES',
  });
  return result;
};
