import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import ouiStyle from '@goldwasserexchange/oui-style';
import { DomTableContainer, TableBodyContainer, TableBodyRowContainer, TableHeaderContainer, TableRowContainer, TableFooterContainer, } from './components';
import { TableBodyRowCellContainer } from './components/body/row/cell';
import { TableHeaderHeading } from './components/header/heading/container';
export var tableDefaultDomTopModelsObject = {
    TableContainer: (React.createElement(TopModel, { name: "TableContainer" }, DomTableContainer)),
    TableHeaderContainer: (React.createElement(TopModel, { name: "TableHeaderContainer" }, TableHeaderContainer)),
    TableHeaderRowContainer: (React.createElement(TopModel, { name: "TableHeaderRowContainer" }, TableRowContainer)),
    TableHeaderCornerTitleContainer: (React.createElement(TopModel, { name: "TableHeaderCornerTitleContainer" }, TableHeaderHeading)),
    TableHeaderTitleCellContainer: (React.createElement(TopModel, { name: "TableHeaderTitleCellContainer" }, TableHeaderHeading)),
    TableBodyContainer: (React.createElement(TopModel, { name: "TableBodyContainer" }, TableBodyContainer)),
    TableBodyRowContainer: (React.createElement(TopModel, { name: "TableBodyRowContainer" }, TableBodyRowContainer)),
    TableBodyRowHeadingContainer: (React.createElement(TopModel, { name: "TableBodyRowHeadingContainer", fontWeight: ouiStyle.Constants.FontWeightProp.bold, color: ouiStyle.Constants.Colors.primary, borderBottomColor: ouiStyle.Constants.Colors.fade }, TableHeaderHeading)),
    'TableBodyRowHeadingContainer--index-last': (React.createElement(TopModel, { name: "TableBodyRowHeadingContainer--index-last", fontWeight: ouiStyle.Constants.FontWeightProp.bold, color: ouiStyle.Constants.Colors.primary, borderBottomColor: ouiStyle.Constants.Colors.transparent }, TableHeaderHeading)),
    TableBodyRowCellContainer: (React.createElement(TopModel, { name: "TableBodyRowCellContainer" }, TableBodyRowCellContainer)),
    'TableBodyRowCellContainer--index-last': (React.createElement(TopModel, { name: "TableBodyRowCellContainer--index-last", borderBottomColor: ouiStyle.Constants.Colors.transparent }, TableBodyRowCellContainer)),
    TableFooterContainer: (React.createElement(TopModel, { name: "TableFooterContainer" }, TableFooterContainer)),
    TableFooterRowContainer: (React.createElement(TopModel, { name: "TableFooterRowContainer" }, TableRowContainer)),
    TableFooterCornerContainer: (React.createElement(TopModel, { name: "TableFooterCornerContainer" }, TableHeaderHeading)),
    TableFooterCellContainer: (React.createElement(TopModel, { name: "TableFooterCellContainer" }, TableBodyRowCellContainer)),
};
