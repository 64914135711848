import * as React from 'react';
import { ActorApiStringDisplay } from '../../../../../../components/display/string';
import { useBondsBondIsSomeRedacted, useBondsBondGesFcRankLmlDescription } from '../../hooks';

export const BondsBondRank = (props: {
  withRedactedIcon?: boolean,
}) => {
  const {
    withRedactedIcon,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useBondsBondGesFcRankLmlDescription();
  const {
    data: redacted,
    isLoading: redactedIsLoading,
  } = useBondsBondIsSomeRedacted(['S_TYPE_GRP.ID', 'GES_FC_RANK.LML_DESCRIPTION']);
  return (
    <ActorApiStringDisplay
      data={data}
      isLoading={isLoading || redactedIsLoading}
      error={error}
      loadingValue="--------------"
      isBlocked={redacted}
      withRedactedIcon={withRedactedIcon}
    />
  );
};
