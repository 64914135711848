import { useMemo } from 'react';
import useSWR, { KeyedMutator } from 'swr';
import { Documents } from '../../enum';
import { DocumentGetS3 } from '../type';
import { getServiceUrl } from '../../../../shared';
import { useDocumentGetS3StringifiedParams } from './defaultParams';
import { useFetchWithAuthWithSend } from '../../../shared/utils/fetchWithAuth';
import { makeApiUrl } from '../../../../../../aws';

const useMakeDocumentsGetS3Url = () => {
  const stringifiedParams = useDocumentGetS3StringifiedParams();
  const url = stringifiedParams !== null ? `${makeApiUrl('actor')}/${getServiceUrl(Documents.getS3)}${stringifiedParams}` : null;
  return url;
};

type BaseUseDocumentGetS3ApiResponse = {
  isLoading: boolean,
  error?: any,
  mutate: KeyedMutator<DocumentGetS3>,
  isValidating: boolean,
}

type UseAccountCashflowApiResponse = {
  data?: DocumentGetS3 | undefined,
} & BaseUseDocumentGetS3ApiResponse;

export function useDocumentGetS3Api(): UseAccountCashflowApiResponse {
  const url = useMakeDocumentsGetS3Url();
  const fetcher = useFetchWithAuthWithSend();
  const result = useSWR<DocumentGetS3, any>(
    url,
    fetcher,
    { dedupingInterval: 300000 },
  );
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    return {
      ...result,
      isLoading,
    };
  }, [result]);
  return resultWithLoading;
}

type UseAccountCashflowFieldApiResponse<Field extends keyof DocumentGetS3> = {
  data: DocumentGetS3[Field] | undefined,
} & BaseUseDocumentGetS3ApiResponse;

export function useDocumentGetS3FieldApi<Field extends keyof DocumentGetS3>(props: {
  field: Field,
}): UseAccountCashflowFieldApiResponse<Field> {
  const {
    field,
  } = props;
  const response = useDocumentGetS3Api();
  const result = useMemo(() => {
    const {
      data,
    } = response;
    return {
      ...response,
      data: data ? data[field] : undefined,
    };
  }, [response, field]);
  return result;
}
