import { mediaQuerizeProps } from '../../mediaQuery';
var isNotMediaQuery = function (flexGrow) { return !Array.isArray(flexGrow); };
export function makeFlexGrow(props) {
    var flexGrow = props.flexGrow;
    if (!flexGrow) {
        return 0;
    }
    if (isNotMediaQuery(flexGrow)) {
        if (typeof flexGrow === 'boolean') {
            return flexGrow ? 1 : 0;
        }
        return flexGrow;
    }
    return mediaQuerizeProps(flexGrow).map(function (element) { return makeFlexGrow({
        flexGrow: element,
    }); });
}
