/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const searchPlaceInputContainerStyle: ouiStyle.InStyle = {
  display: ouiStyle.Constants.DisplayProp.flex,
  position: 'relative',
  borderStyle: 'solid',
  borderColor: ouiStyle.Constants.Colors.primary,
  borderWidth: 'simple',
  childPaddingTop: 'simple',
  childPaddingBottom: 'simple',
  childPaddingLeft: 'simple',
  childPaddingRight: 'simple',
  lines: 1,
};

export const SearchPlaceInputContainer = (props: React.PropsWithChildren<{
  focus: boolean,
}>) => {
  const {
    focus,
    children,
  } = props;
  const searchPlaceInputContainerCss = React.useMemo(
    () => ouiStyle.makeCss({
      ...searchPlaceInputContainerStyle,
      borderColor: !focus ? ouiStyle.Constants.Colors.primary : ouiStyle.Constants.Colors.accent,
    }),
    [focus],
  );
  return (
    <div
      css={searchPlaceInputContainerCss}
    >
      {children}
    </div>
  );
};
