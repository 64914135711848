export var AlignSelfProp;
(function (AlignSelfProp) {
    AlignSelfProp["normal"] = "normal";
    AlignSelfProp["auto"] = "auto";
    AlignSelfProp["stretch"] = "stretch";
    AlignSelfProp["start"] = "flex-start";
    AlignSelfProp["gridStart"] = "start";
    AlignSelfProp["center"] = "center";
    AlignSelfProp["end"] = "flex-end";
    AlignSelfProp["gridEnd"] = "end";
})(AlignSelfProp || (AlignSelfProp = {}));
