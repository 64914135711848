import { msg } from '@lingui/macro';
import { MessageDescriptor } from '@lingui/core';
import { BondsSection } from '../../../router';

export const BOND_SECTION_TITLES: Record<BondsSection, MessageDescriptor> = {
  list: msg`Obligations`,
  details: msg`Obligations - Détails d'une obligation`,
  favorites: msg`Favoris`,
  'oblis-school': msg`Oblis School`,
};
