import { atom } from 'jotai';
import {
  createPath,
} from 'history';
import qs from 'query-string';
import { atomFamily } from 'jotai/utils';
import memoizee from 'memoizee';
import { splitPathname } from './utils';
import { historyRootAtom } from './rootAtom';

export const historyAtom = atom((get) => get(historyRootAtom));

export const locationAtom = atom((get) => get(historyAtom).location);

export const pathAtom = atom((get) => createPath(get(locationAtom)));

export const actionAtom = atom((get) => get(historyAtom).action);

export const locationPathnameAtom = atom((get) => get(locationAtom).pathname);

export const splittedLocationPathnameAtom = atom((get) => {
  const pathname = get(locationPathnameAtom);
  return splitPathname(pathname);
});

export const createPathnamePartAtom = (index: number) => atom((get): string | undefined => get(splittedLocationPathnameAtom).at(index));

export const pathnamePartFamilly = atomFamily((index: number) => createPathnamePartAtom(index));

export const locationSearchAtom = atom((get) => get(locationAtom).search);

const memoizedSearchParse = memoizee((search: string) => qs.parse(search));

export const locationSearchParsedAtom = atom((get) => {
  const search = get(locationSearchAtom);
  return memoizedSearchParse(search);
});

export const locationHashAtom = atom((get) => get(locationAtom).hash);

export const locationStateAtom = atom((get) => get(locationAtom).state);

export const locationKeyAtom = atom((get) => get(locationAtom).key);
