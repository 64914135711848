import { makeName } from '../../../../../makeName';
import * as TYPE from '../../TYPE';
import { TAddType, } from '../../../../../types';
var onboardingPath = 'onboarding';
var mifidIdPath = 'MIFID_ID';
var isPrintPath = 'isPrint';
export var showLogic = TYPE.logic.makeTAddTypeFilterLogic({
    is: TAddType.MORAL,
    truthy: {
        name: "".concat(onboardingPath, ".").concat(isPrintPath),
        is: false,
        alternatives: {
            truthy: {
                result: 'visible',
            },
            falsy: {
                name: makeName('<parentName>', mifidIdPath),
                is: null,
                options: {
                    isNegative: true,
                },
                alternatives: {
                    truthy: {
                        result: 'visible',
                    },
                    falsy: {
                        result: 'invisible',
                    },
                },
            },
        },
    },
    falsy: {
        result: 'invisible',
    },
});
