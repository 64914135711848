import React from 'react';
import {
  object,
} from 'yup';
import {
  dataStructure,
  AccountType,
  UncastedDataStructure,
  UncastedOnboarding,
} from '@goldwasserexchange/oblis-frontend-utils';

import { Trans } from '@lingui/macro';
import ouiBase from '@goldwasserexchange/oui';
import ouiDom from '@goldwasserexchange/oui-dom';
import {
  StateFilter, useSendOnClick, ActionFilter,
} from '../../../../../Machine';
import { ProductQuestionnaire } from './KnowledgeQuestionnaire';
import { useProductFilterFn } from '../../ProductSelection';
import { ResultCards } from './shared/components';
import { ButtonContainer } from '../../../../shared/imageHeadingSection/buttonContainer';
import {
  booleanValidator, makeFalseCast, makeRequired, nilValidator, numberValidator,
} from '../../../../../../OUI/Inputs';
import { makeWhenAlwaysCast } from '../../../../../Form/validations/whenAlwaysCast';
import { productNamesMap } from './shared/productNamesMap';

export * from './shared';
export * from './KnowledgeQuestionnaire';
export * from './ExperienceQuestionnaire';

const makeRTOQuestionValidator = (
  accountType: dataStructure.accountType.listType,
  financialProduct: dataStructure.financialProducts.listType,
  accountTypeTarget: dataStructure.accountType.listType[],
  productSelection: boolean,
  value: any,
  answerKey: string,
  correctKey: string,
  remedialAnswerKey?: string,
  remedialCorrectKey?: string,
  previousCorrectKey?: string,
  previousRemedialCorrectKey?: string,
) => {
  const dependencySuccess = dataStructure.financialProductsKnowledge.questionPaths.getIsDisabledByStatus(
    accountType,
    financialProduct,
    value,
  );
  const isAccountTypeTarget = accountTypeTarget.includes(accountType);
  const noPrevious = !previousCorrectKey;
  const sequential = accountType === AccountType.RTO;
  const hasCorrectPreviousOrNone = !sequential || noPrevious || ((previousCorrectKey && value[previousCorrectKey] === true) || (previousRemedialCorrectKey && value[previousRemedialCorrectKey] === true));
  return ({
    [answerKey]: (dependencySuccess && isAccountTypeTarget && productSelection && hasCorrectPreviousOrNone)
      ? makeRequired(numberValidator)
      : nilValidator,
    [correctKey]: (dependencySuccess && isAccountTypeTarget && productSelection && hasCorrectPreviousOrNone)
      ? makeRequired(booleanValidator)
      : makeFalseCast(booleanValidator),
    ...(remedialAnswerKey !== undefined && remedialCorrectKey !== undefined ? {
      [remedialAnswerKey]: (dependencySuccess && isAccountTypeTarget && productSelection && hasCorrectPreviousOrNone && value[correctKey] !== true)
        ? makeRequired(numberValidator)
        : nilValidator,
      [remedialCorrectKey]: (dependencySuccess && isAccountTypeTarget && productSelection && hasCorrectPreviousOrNone && value[correctKey] !== true)
        ? makeRequired(booleanValidator)
        : makeFalseCast(booleanValidator),
    } : {}),
  });
};

export const makeRTOQuestionsValidator = (
  accountType: dataStructure.accountType.listType,
  financialProduct: dataStructure.financialProducts.listType,
  productSelection: boolean,
  value,
) => {
  const rtoShape = dataStructure.financialProductsKnowledge.questionPaths.getRtoQuestionRootIdsByQuestionnaire(financialProduct).reduce((acc, el, index, src) => {
    const rtoQuestionShape = makeRTOQuestionValidator(
      accountType,
      financialProduct,
      [AccountType.RTO],
      productSelection,
      value,
      dataStructure.financialProductsKnowledge.pathUtils.makeAnswerKey(el),
      dataStructure.financialProductsKnowledge.pathUtils.makeIsCorrectKey(el),
      dataStructure.financialProductsKnowledge.pathUtils.makeAnswerKey(dataStructure.financialProductsKnowledge.pathUtils.makeRemedialKey(el)),
      dataStructure.financialProductsKnowledge.pathUtils.makeIsCorrectKey(dataStructure.financialProductsKnowledge.pathUtils.makeRemedialKey(el)),
      index !== 0 ? dataStructure.financialProductsKnowledge.pathUtils.makeIsCorrectKey(src[index - 1]) : undefined,
      index !== 0 ? dataStructure.financialProductsKnowledge.pathUtils.makeIsCorrectKey(dataStructure.financialProductsKnowledge.pathUtils.makeRemedialKey(src[index - 1])) : undefined,
    );
    return {
      ...acc,
      ...rtoQuestionShape,
    };
  }, {});
  const nonRtoShape = dataStructure.financialProductsKnowledge.questionPaths.getQuestionRootIdsByQuestionnaire(financialProduct).reduce((acc, el, index, src) => {
    const nonrtoQuestionShape = makeRTOQuestionValidator(
      accountType,
      financialProduct,
      [AccountType.CONSEIL, AccountType.GESTION],
      productSelection,
      value,
      dataStructure.financialProductsKnowledge.pathUtils.makeAnswerKey(el),
      dataStructure.financialProductsKnowledge.pathUtils.makeIsCorrectKey(el),
      undefined,
      undefined,
      index !== 0 ? dataStructure.financialProductsKnowledge.pathUtils.makeIsCorrectKey(src[index - 1]) : undefined,
      undefined,
    );
    return {
      ...acc,
      ...nonrtoQuestionShape,
    };
  }, {});
  return {
    ...rtoShape,
    ...nonRtoShape,
  };
};

const knowledgeQuestionnaireValidatorDependencyPaths: ((keyof UncastedOnboarding) | '$')[] = [
  '$',
  'selectedService',
];

export const knowledgeQuestionnaireValidator = makeWhenAlwaysCast(
  knowledgeQuestionnaireValidatorDependencyPaths,
  (context: { rootValue: UncastedDataStructure }, serviceSelection: dataStructure.accountType.listType, _, current) => {
    const { rootValue } = context;
    const {
      onboarding,
    } = rootValue;
    const {
      products,
    } = onboarding;
    const shape = dataStructure.financialProducts.list.reduce((acc, financialProduct) => {
      const currentShape = makeRTOQuestionsValidator(
        serviceSelection,
        financialProduct,
        products[financialProduct].selected === true,
        current.value,
      );
      return {
        ...acc,
        ...currentShape,
      };
    }, {});
    return object().shape(shape);
  },
);

const ProductKnowledgeAndExperienceQuestionnaire = (props: { sTypeGrp: dataStructure.financialProducts.listType }) => {
  const {
    sTypeGrp,
  } = props;
  return (
    <StateFilter
      is={`.${productNamesMap[sTypeGrp]}Questionnaire.notSkipped`}
    >
      <StateFilter
        is=".questions"
      >
        <ProductQuestionnaire sTypeGrp={sTypeGrp} />
      </StateFilter>
      <ResultCards productName={sTypeGrp} />
    </StateFilter>
  );
};

const RetryButton = () => {
  const onClick = useSendOnClick('RETRY');
  return (
    <ActionFilter is="RETRY">
      <ouiDom.Button.Button
        type="button"
        onClick={onClick}
      >
        <Trans>Réessayer</Trans>
      </ouiDom.Button.Button>
    </ActionFilter>
  );
};

export const KnowledgeAndExperienceQuestionnaire = (): JSX.Element => {
  const filteredProductSelectionList = useProductFilterFn(dataStructure.financialProducts.list);
  return (
    <div>
      {filteredProductSelectionList.map((productName) => (
        <ProductKnowledgeAndExperienceQuestionnaire sTypeGrp={productName} />
      ))}
      <StateFilter is={['.Conseil.', '.Gestion.']} options={{ matcher: ouiBase.utils.match.arrayMatcherNames.some }}>
        <ButtonContainer>
          <RetryButton />
        </ButtonContainer>
      </StateFilter>
    </div>
  );
};
