import ouiBase from '@goldwasserexchange/oui';
import * as FOREIGN_CURRENCIES from '../FOREIGN_CURRENCIES';
import * as RISK_LEVEL from '../RISK_LEVEL';
import * as MAX_RECOVERY_PERIOD from '../MAX_RECOVERY_PERIOD';
import * as HORIZON from '../HORIZON';
import * as INVESTMENT_GOAL from '../INVESTMENT_GOAL';
export var map = {
    horizon: HORIZON.logic.show.showLogic,
    riskLevel: RISK_LEVEL.logic.show.showLogic,
    maxRecoveryPeriod: MAX_RECOVERY_PERIOD.logic.show.showLogic,
    foreignCurrencies: FOREIGN_CURRENCIES.logic.show.showLogic,
    investmentGoal: INVESTMENT_GOAL.logic.show.showLogic,
};
export var mapLogic = {
    map: [
        HORIZON.logic.show.showLogicBoolean,
        RISK_LEVEL.logic.show.showLogicBoolean,
        MAX_RECOVERY_PERIOD.logic.show.showLogicBoolean,
        FOREIGN_CURRENCIES.logic.show.showLogicBoolean,
        INVESTMENT_GOAL.logic.show.showLogicBoolean,
    ],
};
export var useShowList = function (parentName) { return ouiBase.utils.hookstate.useFormFilterLogicMap(mapLogic, parentName); };
