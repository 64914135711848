import { RampWithNameTransform } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import { useInputFooterType } from '../../../../../../OUI/Inputs';
import { useScaleEnterLeave } from '../../../../utils/useScaleEnterLeave';

export function DomInputFooterContent() {
  const footerType = useInputFooterType();
  const transition = useScaleEnterLeave(footerType);
  return transition((style, item) => {
    if (item === 'help') {
      return (
        <RampWithNameTransform
          key={item}
          rampName="InputFooterHelpBase"
          style={style}
        />
      );
    }
    if (item === 'error') {
      return (
        <RampWithNameTransform
          key={item}
          rampName="InputFooterErrorBase"
          style={style}
        />
      );
    }
    return null;
  });
}
