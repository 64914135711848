export const onboardingRoutesDefinition = {
  welcome: {
    importFn: () => import('../../../../Components/Sections/onboarding/welcome/component'),
  },
  form: {
    importFn: () => import('../../../../Components/Sections/onboarding/form/default'),
  },
  status: {
    importFn: () => import('../../../../Components/Sections/onboarding/status/component'),
  },
  'not-found': {
    importFn: () => import('../../../../Components/Sections/onboarding/notFound/component'),
  },
};
