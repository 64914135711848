import { SimulationBuyAndSellCostData, SimulationBuyOrSellCostData } from '../type';

const productTrailerFeesPcts: Record<SimulationBuyOrSellCostData['type'] | SimulationBuyAndSellCostData['type'], number> = {
  bonds: 0,
  stocks: 0,
  capitalisationFunds: 0.006,
  distibutionFunds: 0.006,
  trackers: 0,
};

export type GetProductTrailerFeesProps = {
  type: SimulationBuyOrSellCostData['type'] | SimulationBuyAndSellCostData['type'],
}

export const getProductTrailerFeesPct = (props: GetProductTrailerFeesProps) => {
  const {
    type,
  } = props;
  return productTrailerFeesPcts[type];
};

export type ComputeProductTrailerFeesAmountProps = {
  counterValue: SimulationBuyAndSellCostData['counterValue'],
  durationYears: SimulationBuyAndSellCostData['durationYears'],
} & GetProductTrailerFeesProps

export const computeProductTrailerFeesAmount = (props: ComputeProductTrailerFeesAmountProps) => {
  const {
    counterValue,
    type,
    durationYears,
  } = props;
  const pct = getProductTrailerFeesPct({ type });
  return counterValue * pct * durationYears;
};
