import * as React from 'react';
import { useLingui } from '@lingui/react';
import { getGeSectionByName } from '../../../getters';

export const SectionHeading = (props: { sectionName: string }) => {
  const { sectionName, ...rest } = props;
  const sectionData = getGeSectionByName(sectionName);
  const {
    i18n,
  } = useLingui();
  if ('title' in sectionData) {
    return <>{i18n._(sectionData.title)}</>;
  }
  if ('TitleComponent' in sectionData) {
    return <sectionData.TitleComponent {...rest} />;
  }
  return null;
};
