import ouiBase from '@goldwasserexchange/oui';
import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import * as hasKnowledge from '../logic/hasKnowledge';
export var getIconLogic = function (financialProduct) { return hasKnowledge.make.makeIsQuestionnaireCorrectLogic(financialProduct, {
    notSelected: {
        result: 'notApplicable',
    },
    noQuestions: {
        result: 'notApplicable',
    },
    notAnswered: {
        result: 'notApplicable',
    },
    success: {
        result: true,
    },
    failure: {
        result: false,
    },
    failureWithRetryAvailable: {
        result: false,
    },
    failureWithRetryAvailableOnRequired: {
        result: false,
    },
    retriedFailureOnRequired: {
        result: false,
    },
    truthy: {
        result: 'truthyIcon',
    },
    falsy: {
        result: 'falsyIcon',
    },
}); };
export var IconOnFinancialProduct = function (props) {
    var financialProduct = props.financialProduct;
    var logic = getIconLogic(financialProduct);
    return (React.createElement(ouiBase.utils.hookstate.FormFilterLogic, { parentName: "", logic: logic }));
};
export var IconOnFinancialProductPrimaryDimension = function () {
    var financialProduct = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
    return (React.createElement(IconOnFinancialProduct, { financialProduct: financialProduct }));
};
var knowledgeQuestionnairePath = 'knowledgeQuestionnaire';
export var IconOnFinancialProductPrimaryDimensionTopModels = (React.createElement(TopModel, { name: "TableBodyRowCellContent--".concat(knowledgeQuestionnairePath) }, IconOnFinancialProductPrimaryDimension));
