import { mediaQueries, mediaQuerizeProps } from '../mediaQuery';
import { borderSize, computedLineHeight, getSpaceValue, iconSize, space, } from '../utils';
export var defaultPadding = 'none';
var iconPaddingDifferential = (iconSize - computedLineHeight) / 2;
export function makeHorizontalPadding(props) {
    var _a = props.padding, padding = _a === void 0 ? defaultPadding : _a, _b = props.borderWidth, borderWidth = _b === void 0 ? 'none' : _b, _c = props.parentBorderWidth, parentBorderWidth = _c === void 0 ? 'none' : _c, _d = props.iconContainer, iconContainer = _d === void 0 ? false : _d;
    if (!Array.isArray(padding)
        && !Array.isArray(borderWidth)
        && !Array.isArray(parentBorderWidth)
        && !Array.isArray(iconContainer)) {
        var paddingFactor = getSpaceValue(padding);
        var borderFactor = getSpaceValue(borderWidth);
        var parentBorderFactor = getSpaceValue(parentBorderWidth);
        var iconTerm = iconContainer ? iconPaddingDifferential : 0;
        var value = paddingFactor * space
            - (borderFactor + parentBorderFactor) * borderSize
            - iconTerm;
        return value > 0 ? "".concat(value, "rem") : '0';
    }
    var paddingMQ = mediaQuerizeProps(padding);
    var borderWidthMQ = mediaQuerizeProps(borderWidth);
    var parentBorderWidthMQ = mediaQuerizeProps(parentBorderWidth);
    var iconContainerMQ = mediaQuerizeProps(iconContainer);
    var result = mediaQueries.map(function (_, index) { return makeHorizontalPadding({
        padding: paddingMQ[index],
        borderWidth: borderWidthMQ[index],
        parentBorderWidth: parentBorderWidthMQ[index],
        iconContainer: iconContainerMQ[index],
    }); });
    return result;
}
export function makeTopPadding(props) {
    var _a = props.padding, padding = _a === void 0 ? defaultPadding : _a, _b = props.borderWidth, borderWidth = _b === void 0 ? 'none' : _b, _c = props.parentBorderWidth, parentBorderWidth = _c === void 0 ? 'none' : _c, _d = props.iconContainer, iconContainer = _d === void 0 ? false : _d, _e = props.baselinePadding, baselinePadding = _e === void 0 ? false : _e;
    if (!Array.isArray(padding)
        && !Array.isArray(borderWidth)
        && !Array.isArray(parentBorderWidth)
        && !Array.isArray(iconContainer)
        && !Array.isArray(baselinePadding)) {
        var paddingFactor = getSpaceValue(padding);
        var borderFactor = getSpaceValue(borderWidth);
        var parentBorderFactor = getSpaceValue(parentBorderWidth);
        var iconTerm = iconContainer ? iconPaddingDifferential : 0;
        var baseLinePaddingTerm = baselinePadding ? borderSize : 0;
        var value = paddingFactor * space
            - (borderFactor + parentBorderFactor) * borderSize
            - iconTerm
            + baseLinePaddingTerm;
        return value > 0 ? "".concat(value, "rem") : '0';
    }
    var paddingMQ = mediaQuerizeProps(padding);
    var borderWidthMQ = mediaQuerizeProps(borderWidth);
    var parentBorderWidthMQ = mediaQuerizeProps(parentBorderWidth);
    var iconContainerMQ = mediaQuerizeProps(iconContainer);
    var baselinePaddingMQ = mediaQuerizeProps(baselinePadding);
    var result = mediaQueries.map(function (_, index) { return makeTopPadding({
        padding: paddingMQ[index],
        borderWidth: borderWidthMQ[index],
        parentBorderWidth: parentBorderWidthMQ[index],
        iconContainer: iconContainerMQ[index],
        baselinePadding: baselinePaddingMQ[index],
    }); });
    return result;
}
export function makeBottomPadding(props) {
    var _a = props.padding, padding = _a === void 0 ? defaultPadding : _a, _b = props.borderWidth, borderWidth = _b === void 0 ? 'none' : _b, _c = props.parentBorderWidth, parentBorderWidth = _c === void 0 ? 'none' : _c, _d = props.iconContainer, iconContainer = _d === void 0 ? false : _d, _e = props.baselinePadding, baselinePadding = _e === void 0 ? false : _e;
    if (!Array.isArray(padding)
        && !Array.isArray(borderWidth)
        && !Array.isArray(parentBorderWidth)
        && !Array.isArray(iconContainer)
        && !Array.isArray(baselinePadding)) {
        var paddingFactor = getSpaceValue(padding);
        var borderFactor = getSpaceValue(borderWidth);
        var parentBorderFactor = getSpaceValue(parentBorderWidth);
        var iconTerm = iconContainer ? iconPaddingDifferential : 0;
        var baseLinePaddingTerm = baselinePadding ? borderSize : 0;
        var value = paddingFactor * space
            - (borderFactor + parentBorderFactor) * borderSize
            - iconTerm
            - baseLinePaddingTerm;
        return value > 0 ? "".concat(value, "rem") : '0';
    }
    var paddingMQ = mediaQuerizeProps(padding);
    var borderWidthMQ = mediaQuerizeProps(borderWidth);
    var parentBorderWidthMQ = mediaQuerizeProps(parentBorderWidth);
    var iconContainerMQ = mediaQuerizeProps(iconContainer);
    var baselinePaddingMQ = mediaQuerizeProps(baselinePadding);
    var result = mediaQueries.map(function (_, index) { return makeBottomPadding({
        padding: paddingMQ[index],
        borderWidth: borderWidthMQ[index],
        parentBorderWidth: parentBorderWidthMQ[index],
        iconContainer: iconContainerMQ[index],
        baselinePadding: baselinePaddingMQ[index],
    }); });
    return result;
}
