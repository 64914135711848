import { useContext } from 'react';
import { useSecondaryDimensionListElementByIndex } from '../../list/hooks';
import { useSecondaryDimensionLength } from '../../list/length/hooks';
import { currentSecondaryDimensionIndexContext } from './context';
export var useCurrentSecondaryDimensionIndex = function () {
    var elementIndex = useContext(currentSecondaryDimensionIndexContext);
    return elementIndex;
};
export var useIsLastSecondaryDimensionIndex = function () {
    var index = useCurrentSecondaryDimensionIndex();
    var length = useSecondaryDimensionLength();
    return index === length - 1;
};
export function useIsLastSecondaryDimensionVariant() {
    var isLast = useIsLastSecondaryDimensionIndex();
    return isLast ? 'last' : 'notLast';
}
export var useCurrentSecondaryDimensionValueByIndex = function () {
    var index = useCurrentSecondaryDimensionIndex();
    var data = useSecondaryDimensionListElementByIndex(index);
    return data;
};
