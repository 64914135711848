/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const defaultDomSelectInputListMainContainerStyle: ouiStyle.InStyle = {
  display: ouiStyle.Constants.DisplayProp.flex,
  lines: 1,
  childPaddingBottom: 'simple',
  childPaddingLeft: 'simple',
  childPaddingRight: 'simple',
  childPaddingTop: 'simple',
};

const defaultDomSelectInputListMainContainerCss = ouiStyle.makeCss(
  defaultDomSelectInputListMainContainerStyle,
);

export function DefaultDomSelectInputListMainContainer(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  return <div css={defaultDomSelectInputListMainContainerCss}>{children}</div>;
}
