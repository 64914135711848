import ouiBase from '@goldwasserexchange/oui';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import { i18n } from '@goldwasserexchange/i18n';
import { makeSelectedFinancialProductWithRequiredLogic } from '../../financialProductsSelection/logic/selectedWithRequired';
import { Label as DefaultMainLabel } from '../labels';
export var labels = {
    notRequired: {
        ID: 'notRequired',
        label: function () { return i18n._('Non requis'); },
    },
    notSelected: {
        ID: 'notSelected',
        label: function () { return i18n._('Ignoré'); },
    },
    selected: {
        ID: 'selected',
        label: function () { return i18n._('Validé'); },
    },
};
export var useLabel = function (infoGatheringStatus) {
    var translated = labels[infoGatheringStatus].label();
    return translated;
};
export var Label = function (props) {
    var infoGatheringStatus = props.infoGatheringStatus;
    var translated = useLabel(infoGatheringStatus);
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return React.createElement(React.Fragment, null, translated);
};
var getLabelLogic = function (financialProduct) { return makeSelectedFinancialProductWithRequiredLogic(financialProduct)({
    selected: {
        result: 'informationGatheringStatusSelectedLabel',
    },
    notSelected: {
        result: 'informationGatheringStatusNotSelectedLabel',
    },
    notRequired: {
        result: 'informationGatheringStatusNotRequiredLabel',
    },
}); };
export var LabelOnFinancialProduct = function (props) {
    var financialProduct = props.financialProduct;
    var logic = getLabelLogic(financialProduct);
    return (React.createElement(ouiBase.utils.hookstate.FormFilterLogic, { parentName: "", logic: logic }));
};
export var LabelOnFinancialProductPrimaryDimension = function () {
    var financialProduct = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
    return (React.createElement(LabelOnFinancialProduct, { financialProduct: financialProduct }));
};
export var labelTopModels = (React.createElement(React.Fragment, null,
    React.createElement(TopModel, { name: "TableSecondaryTitleCellContent-INFO_GATHERING_STATUS_KEY" }, DefaultMainLabel),
    React.createElement(TopModel, { name: "TableBodyRowCellContent--INFO_GATHERING_STATUS_KEY" }, LabelOnFinancialProductPrimaryDimension),
    React.createElement(TopModel, { name: "informationGatheringStatusNotRequiredLabel", infoGatheringStatus: "notRequired" }, Label),
    React.createElement(TopModel, { name: "informationGatheringStatusNotSelectedLabel", infoGatheringStatus: "notSelected" }, Label),
    React.createElement(TopModel, { name: "informationGatheringStatusSelectedLabel", infoGatheringStatus: "selected" }, Label)));
