import { BondResponse } from '../../type';
import { useBondsBondFieldTransformFnApi } from '../api';

const transformFn = (value: BondResponse['GES_RAT_VAL_GE.SHORTCUT'] | undefined) => (value != null ? parseInt(value, 10) : undefined);

export const useBondsBondGesRatValGeShortcut = () => {
  const response = useBondsBondFieldTransformFnApi({
    field: 'GES_RAT_VAL_GE.SHORTCUT',
    transformFn,
  });
  return response;
};
