/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import ouiDom from '@goldwasserexchange/oui-dom';

export const SanityLinkContainer = (props: React.PropsWithChildren<{
  to: string,
  textAlign?: ouiStyle.InStyleWithMediaQueries['textAlign'],
  hoverColor?: ouiStyle.Constants.Colors,
} | {
  href: string,
  target?: string,
  textAlign?: ouiStyle.InStyleWithMediaQueries['textAlign'],
  hoverColor?: ouiStyle.Constants.Colors,
}>) => {
  const {
    children,
    textAlign,
    hoverColor = ouiStyle.Constants.Colors.logoBlue,
    ...rest
  } = props;
  const baseCss = ouiStyle.useACss({
    fontSize: 'inherit',
    fontStyle: 'inherit',
    fontWeight: ouiStyle.Constants.FontWeightProp.slightlyBold,
    textDecoration: 'underline',
    color: ouiStyle.Constants.Colors.inherit,
    paddingVertical: 'none',
    textAlign,
  });
  const css = React.useMemo(() => ouiStyle.mediaQuery({
    ...baseCss,
    textDecorationColor: ouiStyle.Constants.Colors.greyLigther,
    ':hover': {
      textDecorationColor: hoverColor,
      color: hoverColor,
    },
  }), [baseCss]);
  if ('to' in rest) {
    const {
      to,
    } = rest;
    return (
      <ouiDom.A.Link
        href={to}
        css={css}
      >
        {children}
      </ouiDom.A.Link>
    );
  }
  if ('href' in rest) {
    const {
      href,
      target,
    } = rest;
    return (
      <a
        href={href}
        target={target}
        css={css}
      >
        {children}
      </a>
    );
  }
  throw new Error('not a correct link');
};
