import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { useTeamMemberId } from '../../context';
import { QueryArguments, QueryResponseElement } from '../../types';
import { useSanityTeamMembers } from '../list';
import { pathnameUrlLanguageAtom } from '../../../../../history';

type IdParam = {
  id: QueryResponseElement['_id'],
}

export const useSanityTeamMemberById = (props: QueryArguments & IdParam) => {
  const {
    id,
    locale,
  } = props;
  const teamMembersResponse = useSanityTeamMembers({ locale });
  const response = useMemo(() => {
    const {
      data,
      ...rest
    } = teamMembersResponse;
    if (data == null) {
      return {
        data: undefined,
        ...rest,
      };
    }
    // eslint-disable-next-line no-underscore-dangle
    const employee = data.find((element) => element._id === id);
    return {
      data: employee,
      ...rest,
    };
  }, [
    id,
    teamMembersResponse.data,
    teamMembersResponse.isLoading,
    teamMembersResponse.error,
  ]);
  return response;
};

export const useLocalizedSanityTeamMemberById = (props: IdParam) => {
  const {
    id,
  } = props;
  const locale = useAtomValue(pathnameUrlLanguageAtom);
  return useSanityTeamMemberById({ id, locale });
};

export const useLocalizedSanityTeamMemberByIdFromContext = () => {
  const id = useTeamMemberId();
  return useLocalizedSanityTeamMemberById({ id });
};
