import * as React from 'react';
import { BaseBuilderWithNameTransform } from '../../../utils';

export function DefaultTextInputContent() {
  return (
    <>
      <BaseBuilderWithNameTransform rampName="InputPlaceHolder" />
      <BaseBuilderWithNameTransform rampName="InputFooter" />
    </>
  );
}
