import * as React from 'react';
import { animated } from 'react-spring';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as animationController from '../../animation';

export const NavBarPlaceHolder = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  const notScrolled = animationController.useAnimationLogoExpandedSpringValue();
  const mqDown = animationController.useAnimationmqDownSpringValue();
  return (
    <animated.div
      style={{
        display: 'flex',
        position: mqDown.to((v: number) => {
          if (v <= 0.5) {
            return 'static';
          }
          return 'relative';
        }),
        flexGrow: 1,
        flexShrink: 0,
        flexBasis: '0%',
        backgroundColor: ouiStyle.Constants.Colors.logoBlue,
        height: notScrolled.to((v: number) => `${3.75 + (v * (3.75 / 2))}rem`),
      }}
    >
      {children}
    </animated.div>
  );
};

export default NavBarPlaceHolder;
