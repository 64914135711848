import { UncastedDataStructure, UncastedMoralTAdd, UncastedPhysicalTAdd } from '@goldwasserexchange/oblis-frontend-utils';
import { Place } from '@aws-sdk/client-location';
import ouiBase from '@goldwasserexchange/oui';
import * as React from 'react';
import { State } from '@hookstate/core';
import { CCountryIdCode3InputLmlStrings } from '@goldwasserexchange/oblis-frontend-utils/src/actor/C_COUNTRY/IdCode3InputLmlStrings.generated';
import { SearchPlace } from './component';
import { useUnhookedTouchedContext } from '../../OUI/Form';
import { getUserFromParentName } from '../tAdd';

export const SearchPlaceSetAddress = (props: {
  parentName: string,
  addressPath: keyof Pick<UncastedPhysicalTAdd | UncastedMoralTAdd, 'address' | 'fiscalAddress' | 'secondaryFiscalAddress'>,
}) => {
  const {
    parentName,
    addressPath,
  } = props;
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const touchedState = useUnhookedTouchedContext();
  const onSelect = React.useCallback(
    (e: Place) => {
      const tAdd: undefined | State<UncastedPhysicalTAdd> = getUserFromParentName(parentName, valueState);
      if (tAdd) {
        const addressState = tAdd.nested(addressPath);
        const street1State = addressState.STREET_1;
        street1State.set([e.Street ?? '', e.AddressNumber ?? '', e.UnitNumber ?? ''].filter((part) => part !== '').join(' '));
        touchedState.nested(street1State.path.join('.')).set(true);
        const zipState = addressState.ZIP;
        zipState.set(e.PostalCode ?? '');
        touchedState.nested(zipState.path.join('.')).set(true);
        const cityState = addressState.CITY;
        cityState.set(e.Municipality ?? '');
        touchedState.nested(cityState.path.join('.')).set(true);
        const countryId = (
          Object
            .entries(CCountryIdCode3InputLmlStrings)
            .find(([, value]) => value === e.Country)
          ?? []
        )[0]?.replace('ID_', '');
        if (countryId) {
          const idCCountryState = addressState.ID_C_COUNTRY;
          idCCountryState.set(countryId);
          touchedState.nested(idCCountryState.path.join('.')).set(true);
        }
      }
    },
    [
      valueState,
      parentName,
    ],
  );
  return <SearchPlace onSelect={onSelect} />;
};
