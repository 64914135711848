import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import {
  ResponsiveContainer,
  LineChart,
  XAxis,
  YAxis,
  Line,
  Dot,
  Tooltip,
} from 'recharts';
import { format } from 'date-fns';
import { SQuoteChartGradient } from './gradient';
import { SQuoteChartLabel } from '../label';
import { AccountPosition } from '../../../../accounts';
import { Domain, SQuoteChartDataElement } from '../type';
import { getDomain } from '../utils/getDomain';

export const SQuoteLineChart = (props: {
  data: SQuoteChartDataElement[],
  height?: string | number,
  priceBuy: AccountPosition['GES_ESTIM_D.PRICE_BUY'],
  isin: AccountPosition['S_ACC.ISIN'],
  isLoading: boolean,
  hideXAxis?: boolean,
  xAxisDomain?: Domain,
  xAxisLine?: boolean,
  xAxisOrientation?: 'bottom' | 'top',
  xTicks?: number[],
  xTickSize?: number,
  xTickLine?: boolean,
  xMinTickGap?: number,
  xInterval?: number,
  hideYAxis?: boolean,
  yAxisDomain?: Domain,
  yAxisLine?: boolean,
  yAxisOrientation?: 'left' | 'right',
  yTicks?: number[],
  yTickSize?: number,
  yTickLine?: boolean,
  yMinTickGap?: number,
  yInterval?: number,
}) => {
  const {
    data,
    height,
    priceBuy = 0,
    isin,
    isLoading,
    hideXAxis = true,
    xAxisLine = !hideXAxis,
    xAxisDomain = ['dataMin', 'dataMax'],
    xAxisOrientation = 'bottom',
    xTicks,
    xTickSize,
    xTickLine,
    xMinTickGap = 0,
    xInterval,
    hideYAxis = true,
    yAxisLine = !hideYAxis,
    yAxisDomain = ['dataMin', 'dataMax'],
    yAxisOrientation,
    yTicks,
    yTickSize,
    yTickLine,
    yMinTickGap = 0,
    yInterval,
  } = props;
  const id = `gradient-${isin}`;
  const domain = React.useMemo(() => getDomain(data, xAxisDomain, yAxisDomain), [data]);
  const {
    y: [domainMinY, domainMaxY],
  } = domain;
  const hasPriceBuyDifference = domainMaxY - domainMinY !== 0;
  return (
    <ResponsiveContainer
      width="100%"
      height={height}
    >
      <LineChart
        data={data}
      >
        {hasPriceBuyDifference ? (
          <defs>
            <SQuoteChartGradient
              priceBuy={priceBuy}
              id={id}
              domain={domain}
              isLoading={isLoading}
            />
          </defs>
        ) : null}
        <XAxis
          dataKey="x"
          hide={hideXAxis}
          type="number"
          scale="time"
          domain={xAxisDomain}
          axisLine={xAxisLine}
          tickFormatter={(v) => {
            const tickDate = new Date(v);
            return format(tickDate, 'dd/MM');
          }}
          orientation={xAxisOrientation}
          tickSize={xTickSize}
          tickLine={xTickLine}
          ticks={xTicks}
          minTickGap={xMinTickGap}
          interval={xInterval}
        />
        <YAxis
          dataKey="y"
          type="number"
          hide={hideYAxis}
          domain={yAxisDomain}
          axisLine={yAxisLine}
          orientation={yAxisOrientation}
          tickSize={yTickSize}
          tickLine={yTickLine}
          ticks={yTicks}
          minTickGap={yMinTickGap}
          interval={yInterval}
        />
        <Line
          type="monotoneX"
          dataKey="y"
          stroke={hasPriceBuyDifference ? `url(#${id})` : ouiStyle.Constants.Colors.success}
          strokeWidth={2}
          dot={false}
          // eslint-disable-next-line react/no-unstable-nested-components
          activeDot={(p) => <Dot {...p} stroke={p.fill} r={2} />}
        />
        <Tooltip
          content={(
            <SQuoteChartLabel priceBuy={priceBuy} />
          )}
          offset={50}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
