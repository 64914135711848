import React from 'react';
import { Trans } from '@lingui/macro';
import ouiBase from '@goldwasserexchange/oui';
import { dataStructure } from '@goldwasserexchange/oblis-frontend-utils';

export const tAddFirstnameLabel = <Trans>Prénom</Trans>;

export const tAddSignificantOtherFirstnameLabel = <Trans>Prénom du partenaire</Trans>;

export const tAddFirstNameValidatorLogic: ouiBase.utils.hookstate.FormValueFilterLogic<'requiredCapitalizedString' | 'nilCast'> = dataStructure.T_ADD.fields.TYPE.logic.makeTAddIsNotMoralOrIgnored({
  truthy: {
    result: 'requiredCapitalizedString',
  },
  falsy: {
    result: 'nilCast',
  },
});
