import ouiBase from '@goldwasserexchange/oui';
import ouiDom from '@goldwasserexchange/oui-dom';
import { Untracked } from '@hookstate/untracked';
import { useMachine } from '@xstate/react';
import * as React from 'react';
import { UncastedDataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import { useHookstate } from '@hookstate/core';
import { useFormValidator, useUnhookedTouchedContext } from '../../../OUI/Form';
import { authMachine } from '../machine';
import {
  authErrorMessageContext,
  authErrorNameContext,
  authMachineContext,
  AuthSendType,
  cognitoUserContext,
  placeIndexNameContext,
  singleTableNameContext,
  codeDeliveryDetailsContext,
} from './context';
import { useCognitoUserTAddId } from '../../Sections/online/tAddId';

const SetTAddId = () => {
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const cognitoUsertAddId = useCognitoUserTAddId();
  const tAddIdState = useHookstate(valueState.tAddId);
  React.useEffect(() => {
    tAddIdState.set(`${cognitoUsertAddId}`);
  }, [cognitoUsertAddId, tAddIdState]);
  return null;
};

export const AuthContextProvider = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  const valuesState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const validator = useFormValidator();
  const touched = useUnhookedTouchedContext();
  const history = ouiDom.A.useHistory();
  const machine = React.useMemo(() => authMachine(valuesState, validator, touched, history), []);
  const [current, send] = useMachine(machine);
  const state = current.toStrings();
  const lastState = state[state.length - 1];
  const value = React.useMemo(() => [lastState, send] as unknown as [string, AuthSendType], [lastState, send]);
  const bucketName: string | null = current.context.config?.userBucketName ?? null;
  const singleTableName = current.context.config?.singleTableName ?? null;
  React.useEffect(() => {
    if (Untracked(valuesState.auth.bucketName).get() !== bucketName) {
      valuesState.auth.bucketName.set(bucketName);
    }
  }, [
    valuesState,
    bucketName,
  ]);
  React.useEffect(() => {
    if (Untracked(valuesState.auth.singleTableName).get() !== singleTableName) {
      valuesState.auth.singleTableName.set(singleTableName);
    }
  }, [
    valuesState,
    singleTableName,
  ]);
  return (
    <authMachineContext.Provider value={value}>
      <cognitoUserContext.Provider value={current.context.user}>
        <authErrorNameContext.Provider value={current.context.errorName}>
          <authErrorMessageContext.Provider value={current.context.errorMessage}>
            <placeIndexNameContext.Provider value={current.context.config?.placeIndexName ?? null}>
              <singleTableNameContext.Provider value={current.context.config?.singleTableName ?? null}>
                <codeDeliveryDetailsContext.Provider value={current.context.codeDeliveryDetails}>
                  <SetTAddId />
                  {children}
                </codeDeliveryDetailsContext.Provider>
              </singleTableNameContext.Provider>
            </placeIndexNameContext.Provider>
          </authErrorMessageContext.Provider>
        </authErrorNameContext.Provider>
      </cognitoUserContext.Provider>
    </authMachineContext.Provider>
  );
};
