import { MakeAppBarGuardObjectTypeFromMatcher, MakeAppBarGuardTypeFromMatcher } from '../type';

export const name = 'isMqUp';

export const makefn: MakeAppBarGuardTypeFromMatcher = (matcher) => () => matcher.matches;

export const makeGuard: MakeAppBarGuardObjectTypeFromMatcher = (matcher) => ({
  [name]: makefn(matcher),
});

export default makeGuard;
