import { dataStructure, TAddType } from '@goldwasserexchange/oblis-frontend-utils';
import { arrayMatcherNames } from '@goldwasserexchange/oui/src/utils/match';

export const tAddTNatureLrpValidatorLogic = dataStructure.T_ADD.fields.TYPE.logic.makeTAddTypeFilterLogic<'requiredString' | 'nilCast'>({
  is: [
    TAddType.TITULAR,
    TAddType.ONLY_REPRESENTATIVE,
  ],
  options: {
    matcher: arrayMatcherNames.some,
  },
  truthy: {
    result: 'requiredString',
  },
  falsy: {
    result: 'nilCast',
  },
});
