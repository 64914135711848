import * as React from 'react';

import { StateFilter } from '../../Machine';

export const Service = () => (
  <>
    <StateFilter is=".RTO">
      Gold
    </StateFilter>
    <StateFilter is=".conseil">
      Premium
    </StateFilter>
    <StateFilter is=".gestion">
      Global+
    </StateFilter>
  </>
);
