/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
export var SanityTableCell = function (props) {
    var verticalTitle = props.verticalTitle, horizontalTitle = props.horizontalTitle, cellDefinition = props.cellDefinition, _a = props.separator, separator = _a === void 0 ? false : _a, columnIndex = props.columnIndex;
    var css = React.useMemo(function () { return ouiStyle.makeCss({
        lineFactor: 1.125,
        borderRightColor: separator
            ? ouiStyle.Constants.Colors.accent
            : ouiStyle.Constants.Colors.fade,
        padding: 'simple',
        color: verticalTitle || horizontalTitle ? ouiStyle.Constants.Colors.accent : ouiStyle.Constants.Colors.primary,
        fontWeight: verticalTitle ? ouiStyle.Constants.FontWeightProp.bold : ouiStyle.Constants.FontWeightProp.normal,
        textAlign: columnIndex === 0 ? 'left' : 'right',
    }); }, [columnIndex, separator, verticalTitle, horizontalTitle]);
    return !(verticalTitle || horizontalTitle) ? (jsx("td", { key: cellDefinition.content, rowSpan: cellDefinition.rowSpan, colSpan: cellDefinition.colSpan, css: css }, cellDefinition.content)) : (jsx("th", { key: cellDefinition.content, rowSpan: cellDefinition.rowSpan, colSpan: cellDefinition.colSpan, css: css }, cellDefinition.content));
};
