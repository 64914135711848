import { Trans } from '@lingui/macro';
import * as React from 'react';
import { usePersonAccountsCCurrencyTAccCode } from '../../hooks';
import { ActorApiStringDisplay } from '../../../../../../components/display/string';

export const PersonsAccountsCCurrencyTAccCode = () => {
  const {
    data,
    isLoading,
    error,
  } = usePersonAccountsCCurrencyTAccCode();
  return (
    <Trans>
      En&#32;
      <ActorApiStringDisplay
        data={data}
        isLoading={isLoading}
        error={error}
        loadingValue="EUR"
        initialLoading={false}
      />
    </Trans>
  );
};
