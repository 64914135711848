/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { Lock } from 'react-feather';
import { useUnlockRedactedOnClick } from '../../../../../../../components/display/loader/hooks';

const wrapperStyle: ouiStyle.InStyle = {
  position: 'relative',
};

export const SQuoteChartContainer = (props: React.PropsWithChildren<{
  hasRedacted?: boolean,
  hasRedactedIsLoading: boolean,
}>) => {
  const {
    hasRedacted,
    hasRedactedIsLoading,
    children,
  } = props;
  const onClick = useUnlockRedactedOnClick();
  const wrapperCss = React.useMemo(() => ouiStyle.makeCss({
    ...wrapperStyle,
    cursor: hasRedacted && !hasRedactedIsLoading ? 'pointer' : undefined,
  }), [hasRedacted, hasRedactedIsLoading]);
  const css = React.useMemo(() => ({
    filter: hasRedacted && !hasRedactedIsLoading ? 'blur(5px)' : undefined,
  }), [hasRedacted, hasRedactedIsLoading]);
  const lockCss = React.useMemo(
    () => ouiStyle.makeCss({
      position: 'absolute' as const,
      top: '1rem',
      left: '0',
      bottom: '0',
      right: '0',
      margin: 'auto',
      color: ouiStyle.Constants.Colors.accent,
      widthLines: 1.2,
      lines: 1.2,
      zIndex: 3,
    }),
    [],
  );
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      css={wrapperCss}
      onClick={hasRedacted && !hasRedactedIsLoading ? onClick : undefined}
    >
      {hasRedacted && !hasRedactedIsLoading ? <div css={lockCss}><Lock stroke={ouiStyle.Constants.Colors.accent} size="100%" /></div> : null}
      <div
        css={css}
      >
        {children}
      </div>
    </div>
  );
};
