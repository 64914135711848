import * as React from 'react';
import { inputContainerRefContext } from './context';

export const InputContainerRefContextProvider = (
  props: React.PropsWithChildren<{}>,
) => {
  const { children } = props;
  const inputContainerRef = React.useRef<HTMLInputElement>(null);
  return (
    <inputContainerRefContext.Provider value={inputContainerRef}>
      {children}
    </inputContainerRefContext.Provider>
  );
};
