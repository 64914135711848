import { getIn, UncastedPhysicalTAdd } from '@goldwasserexchange/oblis-frontend-utils';
import ouiBase from '@goldwasserexchange/oui';
import { mixed } from 'yup';
import { getColumnData } from '../../tAdd';
import { stringifyIs } from '../../utils/stringifyIs';
import { ValidationsNames, validationsStore } from './store';

function makeFilterLogic(siblingPaths: string[], parentPaths: string[], logic: ouiBase.utils.hookstate.FormValueFilterLogic<ValidationsNames>) {
  const siblingsWithoutParentName = siblingPaths.map((path) => ouiBase.Form.replaceParentNameTag('', path));
  return mixed().when(['$', ...siblingsWithoutParentName], (context, ...deps) => {
    const { rootValue } = context;
    const schema = deps[deps.length - 1];
    const { parent } = schema;
    const states = Object.fromEntries([
      ...parentPaths.map((path) => [path, { get: () => stringifyIs(getIn(rootValue, path)) }]),
      ...siblingPaths.map((path, index) => [path, { get: () => stringifyIs(getIn(parent, siblingsWithoutParentName[index])) }]),
    ]);
    const result = ouiBase.utils.hookstate.executeFilterLogic(logic, states);
    return validationsStore[result];
  });
}

export function makeFilterLogicFromTableNameAndColumn(tableName, column: keyof UncastedPhysicalTAdd) {
  const {
    validators,
  } = getColumnData(tableName, column);
  if (!validators) {
    return 'any';
  }
  const {
    logic,
  } = validators;
  const paths = ouiBase.utils.hookstate.extractPathsFromLogic(logic);
  const siblingPaths = paths.filter((path) => path.includes('<parentName'));
  const parentPaths = paths.filter((path) => !path.includes('<parentName'));
  return makeFilterLogic(siblingPaths, parentPaths, logic);
}
