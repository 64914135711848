import ouiBase from '@goldwasserexchange/oui';
import { computePositionLatentCapitalGain } from '../../utils';
import { useAccountPositionTransformApiByGesEstimDId } from '../api';

export const useAccountPositionGesEstimDLatentCapitalGain = () => {
  const gesEstimDId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const result = useAccountPositionTransformApiByGesEstimDId({
    gesEstimDId,
    transformFunction: computePositionLatentCapitalGain,
  });
  return result;
};
