import { useCallback, useContext } from 'react';
import { Untracked } from '@hookstate/untracked';
import ouiBase from '@goldwasserexchange/oui';
import { UncastedDataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import { onSubmitContext } from './context';
import { useFormValidator } from '../validation';
import { useLinguiLocale } from '../../../../Components/useLinguiLocale';

const useOnSubmitContext = () => {
  const onSubmitContextValue = useContext(onSubmitContext);
  return onSubmitContextValue;
};

export const useOnSubmit = () => {
  const onSubmitContextValue = useOnSubmitContext();
  const values = ouiBase.Form.useFormData<UncastedDataStructure>();
  const validator = useFormValidator();
  const locale = useLinguiLocale();
  const onSubmit = useCallback(() => {
    if (onSubmitContextValue) {
      onSubmitContextValue(Untracked(values).get(), validator, locale);
    }
  }, [onSubmitContextValue, values, validator, locale]);
  return onSubmit;
};
