import { PersonAccounts } from '../../type';
import { personAccountsLoadingData } from '../../loadingData';
import { usePersonAccountsTransformApi } from '../api';

const getLength = (accounts: PersonAccounts[] | undefined) => (accounts ? accounts.length : 0);

export const usePersonAccountsLength = () => {
  const personAccountsLengthOriginalApiResponse = usePersonAccountsTransformApi({
    transformFunction: getLength,
  });
  const {
    data = personAccountsLoadingData.length,
    ...rest
  } = personAccountsLengthOriginalApiResponse;
  const personAccountsLengthApiResponse = {
    data,
    ...rest,
  };
  return personAccountsLengthApiResponse;
};
