import { parseISO } from 'date-fns';
import * as React from 'react';
import { BondSAccIdProvider } from '../../../../../../actor/api/Services/bonds';
import { SecurityQuotesParamsProvider } from '../../../../../../actor/api/Services/securities';
import { formatApiParamDate } from '../../../../../../actor/api/shared';

export const SanitySecurityPriceGraphProvider = (props: React.PropsWithChildren<{
  startDate: {
    utc: string,
  },
  endDate: {
    utc: string,
  },
  securityId: number,
}>) => {
  const {
    startDate,
    endDate,
    securityId,
    children,
  } = props;
  if (startDate.utc > endDate.utc) {
    return null;
  }
  const params = React.useMemo(() => { // eslint-disable-line react-hooks/rules-of-hooks
    const {
      utc: startDateUtc,
    } = startDate;
    const startDateParsed = parseISO(startDateUtc);
    const {
      utc: endDateUtc,
    } = endDate;
    const endDateParsed = parseISO(endDateUtc);
    return ({
      'S_ACC.ID': securityId,
      'S_QUOTE.ARCHIVE_DATE_START': formatApiParamDate(startDateParsed),
      'S_QUOTE.ARCHIVE_DATE_END': formatApiParamDate(endDateParsed),
    });
  }, [
    endDate,
    securityId,
  ]);
  return (
    <BondSAccIdProvider
      id={securityId}
    >
      <SecurityQuotesParamsProvider
        params={params}
      >
        {children}
      </SecurityQuotesParamsProvider>
    </BondSAccIdProvider>
  );
};
