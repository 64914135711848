/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { ArrowUp } from 'react-feather';

const style: ouiStyle.InStyleWithMediaQueries = {
  padding: ['none', undefined, 'simple', undefined, undefined, undefined],
};

const css = ouiStyle.mediaQuery(ouiStyle.makeCss(style));

export const RecommendationArrow = () => (
  <div
    css={css}
  >
    <ArrowUp
      size="48"
      color={ouiStyle.Constants.Colors.accent}
    />
  </div>
);
