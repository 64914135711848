import { Locale } from '@goldwasserexchange/i18n';
import React from 'react';
import lmlGet from '@goldwasserexchange/utils/lib/lmlGet';
import { useAtomValue } from 'jotai';
import { pathnameUrlLanguageAtom } from '../../history';

export const idkZLangs = {
  [Locale.FR]: 0,
  [Locale.NL]: 1,
};

export const getIdkZLang = (locale: string) => idkZLangs[locale] ?? 0;

export const LmlText = ({ children }) => {
  const language = useAtomValue(pathnameUrlLanguageAtom);
  const idk = idkZLangs[language];
  return <>{lmlGet(idk, children)}</>;
};

export const sortLml = (idk: number) => (prev, next) => lmlGet(idk, prev).localeCompare(lmlGet(idk, next));
