/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { useMemo } from 'react';
import { SuccessState } from './type';
import { IconName } from '../Icons';
import { CardIcon } from './icon';
import { CardTitle } from './title';

const cardHeaderStyle: ouiStyle.InStyle = {
  paddingVertical: 'half',
};

const useCardContainerCss = (hasIcon: boolean) => {
  const css = useMemo(() => {
    if (hasIcon) {
      return {
        ...ouiStyle.makeCss({
          ...cardHeaderStyle,
          clearfix: true,
          icon: true,
        }),
      };
    }
    return ouiStyle.makeCss(cardHeaderStyle);
  }, [hasIcon]);
  return css;
};

export const CardHeader = (props: React.PropsWithChildren<{
  successState?: SuccessState,
  iconName?: IconName,
}>) => {
  const {
    successState,
    iconName,
    children,
  } = props;
  const hasIcon = iconName != null;
  const css = useCardContainerCss(hasIcon);
  return (
    <header
      css={css}
    >
      {iconName != null
        ? <CardIcon iconName={iconName} successState={successState} />
        : null}
      <CardTitle
        successState={successState}
        hasIcon={hasIcon}
      >
        {children}
      </CardTitle>
    </header>
  );
};
