/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { useOnClick } from '../../../../../../../../../../OUI/utils';

const selectInputDomListElementButtonContainerStyle: ouiStyle.InStyle = {
  lines: 1,
  widthLines: 1,
  fontSize: 's',
  padding: 'none',
  margin: 'none',
  icon: true,
};

const selectInputDomListElementButtonContainerCss = ouiStyle.makeCss(
  selectInputDomListElementButtonContainerStyle,
);

export function SelectInputDomListElementButtonContainer(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  const onClick = useOnClick();
  return (
    <button
      type="button"
      css={selectInputDomListElementButtonContainerCss}
      onClick={onClick}
      tabIndex={-1}
    >
      {children}
    </button>
  );
}
