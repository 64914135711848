/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { useTopModelExistByName } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';

const makeMaxHeight = (spaces: number) => `calc(100vh - ${spaces * ouiStyle.Constants.space}rem)`;

const maxHeightWithIcon = [makeMaxHeight(17), makeMaxHeight(17), makeMaxHeight(17), makeMaxHeight(18), makeMaxHeight(18), makeMaxHeight(18)];

const maxHeighWithoutIcon = makeMaxHeight(2);

export const ModalScrollContainer = (props: React.PropsWithChildren<{
  textAlign?: ouiStyle.InStyle['textAlign'],
}>) => {
  const {
    textAlign,
    children,
  } = props;
  const hasIcon = useTopModelExistByName('ModalIconContent');
  const css = React.useMemo(() => ouiStyle.mediaQuery({
    maxHeight: hasIcon ? maxHeightWithIcon : maxHeighWithoutIcon,
    textAlign,
    overflowY: 'auto',
    overflowX: 'hidden',
  }), [hasIcon, textAlign]);
  return (
    <div
      css={css}
    >
      {children}
    </div>
  );
};
