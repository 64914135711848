import * as React from 'react';
import { IconContainer } from '../container';

export const AccountsIcon = (props: {
  style?: React.SVGAttributes<SVGSVGElement>['style'],
}) => {
  const {
    style,
  } = props;
  return (
    <IconContainer
      style={style}
    >
      <path d="M69.9,69.4h-14.4c-1.4,0-2.5-1.1-2.5-2.5V25.5c0-1.4,1.1-2.5,2.5-2.5h14.4c1.4,0,2.5,1.1,2.5,2.5v41.4c0,1.4-1.1,2.5-2.5,2.5ZM58,64.4h9.4V28h-9.4v36.4Z" />
      <path d="M44.7,69.4h-14.4c-1.4,0-2.5-1.1-2.5-2.5V8.1c0-1.4,1.1-2.5,2.5-2.5h14.4c1.4,0,2.5,1.1,2.5,2.5v58.8c0,1.4-1.1,2.5-2.5,2.5ZM32.8,64.4h9.4V10.6h-9.4v53.8Z" />
      <path d="M19.5,69.4H5.1c-1.4,0-2.5-1.1-2.5-2.5v-26.2c0-1.4,1.1-2.5,2.5-2.5h14.4c1.4,0,2.5,1.1,2.5,2.5v26.2c0,1.4-1.1,2.5-2.5,2.5ZM7.6,64.4h9.4v-21.2H7.6v21.2Z" />
    </IconContainer>
  );
};
