export const bondsRouteDefinition = {
  list: {
    importFn: () => import('../../../../Components/Sections/bonds/components/sections/bondList'),
  },
  details: {
    importFn: () => import('../../../../Components/Sections/bonds/components/sections/bondDetails'),
  },
  favorites: {
    importFn: () => import('../../../../Components/Sections/bonds/components/sections/favorites'),
  },
  'not-found': {
    importFn: () => import('../../../../Components/Sections/bonds/components/sections/notFound'),
  },
  'oblis-school': {
    importFn: () => import('../../../../Components/Sections/bonds/components/sections/oblisSchool'),
  },
};
