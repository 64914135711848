import * as React from 'react';
import { ActorApiStringDisplay } from '../../../../../../components/display/string';
import { useBondsBondIsRedacted, useBondsBondSIssuerName1 } from '../../hooks';

export const BondsBondSIssuerName1 = (props: {
  withRedactedIcon?: boolean,
}) => {
  const {
    withRedactedIcon,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useBondsBondSIssuerName1();
  const {
    data: redacted,
    isLoading: redactedIsLoading,
  } = useBondsBondIsRedacted('S_ISSUER.NAME_1');
  return (
    <ActorApiStringDisplay
      data={data}
      isLoading={isLoading || redactedIsLoading}
      error={error}
      loadingValue="-------- ---- ------ --"
      isBlocked={redacted}
      withRedactedIcon={withRedactedIcon}
    />
  );
};
