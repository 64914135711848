import * as React from 'react';
import { ChevronsLeft, ChevronsRight } from 'react-feather';
import { ScrollDirections } from '../../../../OUI/Scrollable';

export const ScrollableScrollButtonIconDom = (props: {
  direction: ScrollDirections,
}) => {
  const {
    direction,
  } = props;
  return direction === 'left' ? <ChevronsLeft /> : <ChevronsRight />;
};
