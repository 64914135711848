import { useContext } from 'react';
import { sameIssuerSortDirectionContext } from './context';

export const useSameIssuerSortDirectionContextHandlers = () => {
  const handlers = useContext(sameIssuerSortDirectionContext);
  return handlers;
};

export const useSameIssuerSortDirection = () => {
  const [sortDirection] = useSameIssuerSortDirectionContextHandlers();
  return sortDirection;
};

export const useSetSameIssuerSortDirection = () => {
  const [, setSortDirection] = useSameIssuerSortDirectionContextHandlers();
  return setSortDirection;
};
