import { AccountType, UncastedDataStructure, UncastedOnboarding } from '@goldwasserexchange/oblis-frontend-utils';
import ouiBase from '@goldwasserexchange/oui';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { useHookstate } from '@hookstate/core';
import { Trans } from '@lingui/macro';
import * as React from 'react';
import { YesNoInput } from '../../../OUI-dom/Inputs/components/yesNo';
import { booleanValidator, makeRequired, nilValidator } from '../../../OUI/Inputs';
import { makeWhenAlwaysCast } from '../../Form/validations/whenAlwaysCast';

const openPremiumAccountValidatorDeps: `${keyof UncastedOnboarding}`[] = ['selectedService'];

export const openPremiumAccountValidator = makeWhenAlwaysCast(openPremiumAccountValidatorDeps, (selectedService: UncastedOnboarding['selectedService']) => {
  if (selectedService === AccountType.GESTION) {
    return makeRequired(booleanValidator);
  }
  return nilValidator;
});

const label = (
  <TopModel name="RadioListInputLabelContent">
    <Trans>
      Souhaitez-vous également ouvrir un compte Premium ?
    </Trans>
  </TopModel>
);

export const OpenPremiumAccount = () => {
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const selectedService = useHookstate(valueState.onboarding.selectedService);
  if (selectedService.get() !== AccountType.GESTION) {
    return null;
  }
  return (
    <YesNoInput
      fieldPath={valueState.onboarding.openPremiumAccount.path.join('.')}
    >
      {label}
    </YesNoInput>
  );
};
