import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import {
  DefaultRadioListInputBase,
  DefaultRadioListInputElementContextContainer,
} from '../components';

export const radioListInputDefaultTopModelsObject = {
  InputBase: <TopModel name="InputBase">{DefaultRadioListInputBase}</TopModel>,
  InputListElementContextContainer: (
    <TopModel name="ElementContextContainer">
      {DefaultRadioListInputElementContextContainer}
    </TopModel>
  ),
};
