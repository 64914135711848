import { PersonDevices } from '../type';
import { parseLastAuthenticationDate, useFilterByPrimaryDimensionDeviceId } from '../utils';
import { usePersonDeviceTransformApi } from './api';

const transformFunction = (personDevice: PersonDevices | undefined) => (
  personDevice
    ? parseLastAuthenticationDate(personDevice.lastAuthentificationDate)
    : undefined
);

export const usePersonDeviceParseLastAuthenticationDate = () => {
  const filterPredicate = useFilterByPrimaryDimensionDeviceId();
  const response = usePersonDeviceTransformApi({
    transformFunction,
    filterPredicate,
  });
  return response;
};
