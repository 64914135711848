import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { ScrollableBase, ScrollButton } from './components';
import { ScrollableContextProvider } from './context';

export const scrollableBaseTopModels = {
  ScrollableBase: (
    <TopModel name="ScrollableBase">
      {ScrollableBase}
    </TopModel>
  ),
  ScrollableContext: (
    <TopModel name="ScrollableContext">
      {ScrollableContextProvider}
    </TopModel>
  ),
  ScrollableScrollButton: (
    <TopModel name="ScrollableScrollButton">
      {ScrollButton}
    </TopModel>
  ),
};
