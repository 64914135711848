var _a;
import { makeHasExperienceLogic as bondMakeHasExperienceLogic } from '../../fields/BOND/logic';
import { makeHasExperienceLogic as fundMakeHasExperienceLogic } from '../../fields/FUND/logic';
import { makeHasExperienceLogic as perpetualBondMakeHasExperienceLogic } from '../../fields/PERPETUAL_BOND/logic';
import { makeHasExperienceLogic as stockMakeHasExperienceLogic } from '../../fields/STOCK/logic';
import { makeHasExperienceLogic as subordinatedBondMakeHasExperienceLogic } from '../../fields/SUBORDINATED_BOND/logic';
import { makeHasExperienceLogic as trackerMakeHasExperienceLogic } from '../../fields/TRACKER/logic';
import { makeHasExperienceLogic as rawMaterialTrackerMakeHasExperienceLogic } from '../../fields/RAW_MATERIAL_TRACKER/logic';
import { STypeGrp } from '../../../../types';
export var makeHasFinancialProductExperienceLogicRecord = (_a = {},
    _a[STypeGrp.OBLIGATIONS] = bondMakeHasExperienceLogic,
    _a[STypeGrp.FONDS] = fundMakeHasExperienceLogic,
    _a[STypeGrp.OBLIGATIONS_PERP] = perpetualBondMakeHasExperienceLogic,
    _a[STypeGrp.ACTIONS] = stockMakeHasExperienceLogic,
    _a[STypeGrp.OBLIGATIONS_SUB] = subordinatedBondMakeHasExperienceLogic,
    _a[STypeGrp.TRACKERS] = trackerMakeHasExperienceLogic,
    _a[STypeGrp.TRACKERS_MAT] = rawMaterialTrackerMakeHasExperienceLogic,
    _a);
