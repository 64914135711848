import { createContext, Dispatch, SetStateAction } from 'react';

export type SameIssuerSortColumn = 'S_ACC.INT_RATE'
| 'S_ACC.OBL_END_DATE'
| 'S_QUOTE.PRICE_CLOSE'
| 'S_MARKET.YIELD'
| 'S_MARKET.QT_MIN'
| 'GES_RAT_VAL_SP.SHORTCUT'

export const sameIssuerSortColumnContext = createContext<[SameIssuerSortColumn, Dispatch<SetStateAction<SameIssuerSortColumn>>]>([
  'S_ACC.OBL_END_DATE',
  () => {
    throw new Error('not is sameIssuerSortColumn context');
  },
]);
