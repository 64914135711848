import ouiStyle from '@goldwasserexchange/oui-style';
import { animated, SpringValue } from 'react-spring';
import * as React from 'react';

export const SvgContainer = (props: React.PropsWithChildren<{
  size?: string | number,
  stroke?: ouiStyle.Constants.Colors | SpringValue<ouiStyle.Constants.Colors>,
  fill?: ouiStyle.Constants.Colors | SpringValue<ouiStyle.Constants.Colors>,
}>) => {
  const {
    size = 24,
    stroke,
    fill = stroke,
    children,
  } = props;
  return (
    <animated.svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill={fill}
      stroke={stroke}
      strokeWidth={1}
    >
      <g>
        {children}
      </g>
    </animated.svg>
  );
};
