import * as React from 'react';
import { useAtomValue } from 'jotai';
import { pathnameUrlLanguageAtom, ValidSections } from '../../../../history';
import { SanityLinkContainer } from '../link/components';

export const SanityInternalLink = (props: React.PropsWithChildren<{
  value?: {
    reference?: {
      slug?: string,
      _type?: string,
    },
  },
}>) => {
  const {
    value,
    children,
  } = props;
  const locale = useAtomValue(pathnameUrlLanguageAtom);
  const {
    reference = {},
  } = value ?? {};
  if (reference == null) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
  const {
    slug,
    _type,
  } = reference;
  if (slug == null || _type == null) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
  const to = _type === 'news'
    ? `/${locale}/${ValidSections.NEWS}/${slug}`
    : null;
  if (to == null) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
  return (
    <SanityLinkContainer
      to={to}
    >
      {children}
    </SanityLinkContainer>
  );
};
