import { Trans } from '@lingui/macro';
import * as React from 'react';

export const globalPlusIntro = (
  <Trans>
    Vous souhaitez être pris en main complètement
  </Trans>
);

export const globalPlusTwoLinesIntro = (
  <Trans>
    Vous souhaitez être pris
    <br />
    en main complètement
  </Trans>
);
