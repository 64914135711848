import { TopModel } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import { SignInModalName } from './name';
import { useAuthMachineSend } from '../../context';
import { Modal } from '../../../Modal';
import { SignInModalContent } from './content';
import { SignInModalIcon } from './icon';
import { SignInStates, useSignInState } from './signInStateSwitcher';

const backActions: Record<SignInStates | 'none', string | undefined> = {
  UserNamePassword: undefined,
  ReAuth: undefined,
  InputCode: 'BACK',
  ForgotPasswordInputUserName: 'BACK',
  ForgotPasswordInputCodeAndNewPassword: 'BACK',
  SignInLoading: undefined,
  none: undefined,
  ConfirmSignUp: undefined,
};

export const SignInModal = (props: {
  canClose?: boolean,
}) => {
  const {
    canClose,
  } = props;
  const signInState = useSignInState() ?? 'none';
  const backAction = backActions[signInState];
  const send = useAuthMachineSend();
  const goBackOnClick = React.useCallback(() => {
    if (backAction) {
      send(backAction);
    }
  }, [send, backAction]);
  return (
    <Modal
      modal={SignInModalName}
      canClose={canClose}
      goBackOnClick={goBackOnClick}
      hasBack={!!backAction}
    >
      <TopModel
        name="ModalIconContent"
      >
        {SignInModalIcon}
      </TopModel>
      <SignInModalContent />
    </Modal>
  );
};
