/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { Lock } from 'react-feather';

const style: ouiStyle.InStyle = {
  position: 'absolute',
  display: ouiStyle.Constants.DisplayProp.flex,
  alignItems: ouiStyle.Constants.AlignItemsProp.center,
  justifyContent: ouiStyle.Constants.JustifyContentProp.center,
  lines: 'full',
  widthLines: 'full',
  top: 0,
  left: 0,
  textAlign: 'center',
  color: ouiStyle.Constants.Colors.accent,
};

const LockIcon = (props: { size: number | string}) => {
  const {
    size,
  } = props;
  return (
    <Lock
      size={size}
    />
  );
};

export const LockRedacted = (props: {
  size?: string,
  backgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
  widthLines?: ouiStyle.InStyleWithMediaQueries['widthLines'],
  container?: ouiStyle.InStyleWithMediaQueries['container'],
  paddingTop?: ouiStyle.InStyleWithMediaQueries['paddingTop'],
  paddingBottom?: ouiStyle.InStyleWithMediaQueries['paddingBottom'],
  paddingLeft?: ouiStyle.InStyleWithMediaQueries['paddingLeft'],
  paddingRight?: ouiStyle.InStyleWithMediaQueries['paddingRight'],
  alignItems?: ouiStyle.InStyleWithMediaQueries['alignItems'],
  LockChild?: (props: any) => JSX.Element | null,
}) => {
  const {
    size = '1rem',
    backgroundColor = style.backgroundColor,
    widthLines = style.widthLines,
    container = style.container,
    paddingTop = style.paddingTop,
    paddingBottom = style.paddingBottom,
    paddingLeft = style.paddingLeft,
    paddingRight = style.paddingRight,
    alignItems = style.alignItems,
    LockChild = LockIcon,
  } = props;
  const css = React.useMemo(() => ouiStyle.mediaQuery(ouiStyle.makeCss({
    ...style,
    backgroundColor,
    widthLines,
    container,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    alignItems,
  })), [
    backgroundColor,
    widthLines,
    container,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    alignItems,
  ]);
  return (
    <span
      css={css}
    >
      <LockChild size={size} />
    </span>
  );
};
