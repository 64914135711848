import * as React from 'react';
import { createState } from '@hookstate/core';
import { Untracked } from '@hookstate/untracked';
import { touchedContext } from './context';

export const FormTouchedContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  const touchedState = React.useMemo(() => {
    const state = createState<Record<string, boolean>>({});
    state.attach(Untracked);
    return state;
  }, []);
  return (
    <touchedContext.Provider value={touchedState}>
      {children}
    </touchedContext.Provider>
  );
};
