import * as React from 'react';
import { getFnContext, UseGetFnProps, defaultGetFn } from './context';

export function GetFnSetContextProvider(
  props: React.PropsWithChildren<UseGetFnProps>,
) {
  const { useGetFn = () => undefined, children } = props;
  const getFn = useGetFn() ?? defaultGetFn;
  return (
    <getFnContext.Provider value={getFn}>{children}</getFnContext.Provider>
  );
}
