import ouiBase from '@goldwasserexchange/oui';
import { useContext } from 'react';
import { sameIssuerSortColumnContext } from './context';

export const useSameIssuerSortColumnContextHandlers = () => {
  const handlers = useContext(sameIssuerSortColumnContext);
  return handlers;
};

export const useSameIssuerSortColumn = () => {
  const [sortColumn] = useSameIssuerSortColumnContextHandlers();
  return sortColumn;
};

export const useIsSameIssuerColumnSorted = (column: string) => {
  const sortColumn = useSameIssuerSortColumn();
  return sortColumn === column;
};

export const useIsSameIssuerCurrentColumnSorted = () => {
  const secondaryDimension = ouiBase.utils.dimensions.useCurrentSecondaryDimension();
  const isSorted = useIsSameIssuerColumnSorted(secondaryDimension);
  return isSorted;
};

export const useSetSameIssuerSortColumn = () => {
  const [, setSortColumn] = useSameIssuerSortColumnContextHandlers();
  return setSortColumn;
};
