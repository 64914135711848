import { plural } from '@lingui/macro';
import {
  differenceInDays, differenceInMonths, differenceInYears, startOfDay, subMonths, subYears,
} from 'date-fns';
import { isNil } from 'ramda';

export const getTimeDifferenceString = (to: Date) => {
  const now = startOfDay(new Date());
  const years = differenceInYears(to, now);
  const dMinusYears = subYears(to, years);
  const months = differenceInMonths(dMinusYears, now);
  const dMinusMonths = subMonths(dMinusYears, months);
  const days = differenceInDays(dMinusMonths, now);
  const yearString = years > 0
    ? plural(years, {
      one: '# an',
      other: '# ans',
    })
    : undefined;
  const monthString = months > 0
    ? plural(months, {
      one: '# mois',
      other: '# mois',
    })
    : undefined;
  const dayString = days > 0
    ? plural(days, {
      one: '# jour',
      other: '# jours',
    })
    : undefined;
  const parts = [yearString, monthString, dayString].filter((v): v is string => !isNil(v));
  return parts.join(', ');
};
