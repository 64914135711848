import { imageQuery } from '../shared';

export const query = /* groq */`
*[
  _type == "teamMember"
]
| order(upper(lastName) asc, firstName asc)
{
  _id,
  firstName,
  lastName,
  image ${imageQuery},
  "title": title[_key == $locale][0].value,
}
` as const;
