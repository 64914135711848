import ouiBase from '@goldwasserexchange/oui';
import { getModalName } from '../../../../shared';
import { useAccountHistoryTransformApiByHisMvtId } from '../api';

export const useAccountHistoryArDocModalName = () => {
  const hisMvtId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const response = useAccountHistoryTransformApiByHisMvtId({
    hisMvtId,
    transformFunction: getModalName,
  });
  return response;
};
