import { AccountPosition } from '../../type';
import { useAccountPositionsTransformApi } from '../api';

const defaultTransformFunction = (positions: AccountPosition[] | undefined): string[] => (positions ? positions.map((position) => `${position['GES_ESTIM_D.ID']}`) : []);

export const useAccountPositionsList = (props: {
  filterPredicate?: ((data: AccountPosition) => boolean),
  sortPredicate?: ((prev: AccountPosition, next: AccountPosition) => number),
  transformFunction?: (accountPositions: AccountPosition[] | undefined) => string[],
}) => {
  const {
    filterPredicate,
    sortPredicate,
    transformFunction = defaultTransformFunction,
  } = props;
  const result = useAccountPositionsTransformApi({
    sortPredicate,
    transformFunction,
    filterPredicate,
  });
  return result;
};
