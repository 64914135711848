import ouiBase from '@goldwasserexchange/oui';
import { useBondListFieldApiBySAccId } from '../api';

export const useBondListSMarketYield = () => {
  const sAccId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const response = useBondListFieldApiBySAccId({
    sAccId,
    field: 'S_MARKET.YIELD',
  });
  return response;
};
