import { msg } from '@lingui/macro';
import { I18n } from '@lingui/core';
import { STypeGrpIds } from '../../../../shared/utils/sTypeGrpIds';
import { BondResponse } from '../type';

export const makeGetSubordinationRank = (i18n: I18n) => (sTypeGrpId: BondResponse['S_TYPE_GRP.ID'] | undefined) => {
  if (sTypeGrpId == null) {
    return undefined;
  }
  switch (sTypeGrpId) {
    case STypeGrpIds.OBLIGATIONS_PERP: return i18n._(msg`Perpétuel`);
    case STypeGrpIds.OBLIGATIONS_SUB: return i18n._(msg`Subordonné`);
    default: return i18n._(msg`Non subordonné`);
  }
};
