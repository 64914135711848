import { AppBarGuardType, AppBarGuardObjectType } from '../type';

export const name = 'isMqAnimatedUp';

export const fn: AppBarGuardType = (context) => context.mqUp === 1 && context.mqAction === 0;

export const guard: AppBarGuardObjectType = {
  [name]: fn,
};

export default guard;
