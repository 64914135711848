/* eslint-disable react/jsx-no-useless-fragment */
import * as React from 'react';
import { useAtomValue } from 'jotai';
import { bondSAccIdContext } from './context';
import { pathnameUrlBondDetailsIdAtom } from '../../../../../../history';

export const BondSAccIdProvider = (props: React.PropsWithChildren<{
  id: number,
}>) => {
  const {
    id,
    children,
  } = props;
  return (
    <bondSAccIdContext.Provider value={id}>
      {children}
    </bondSAccIdContext.Provider>
  );
};

export const BondSAccIdFromUrlProvider = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  const id = useAtomValue(pathnameUrlBondDetailsIdAtom);
  if (!id) {
    return (
      <>
        {children}
      </>
    );
  }
  return (
    <BondSAccIdProvider id={id}>
      {children}
    </BondSAccIdProvider>
  );
};
