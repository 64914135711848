import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const getColor = ({ price, minVal, maxVal }) => {
  if (!price) {
    return [ouiStyle.Constants.Colors.primary, ouiStyle.Constants.Colors.primary];
  }
  if (price < maxVal && price > minVal) {
    return [ouiStyle.Constants.Colors.danger, ouiStyle.Constants.Colors.success];
  } if (price <= minVal) {
    return [ouiStyle.Constants.Colors.success, ouiStyle.Constants.Colors.success];
  }
  return [ouiStyle.Constants.Colors.danger, ouiStyle.Constants.Colors.danger];
};

export const SQuoteChartGradient = (props: {
  priceBuy?: number | null,
  id: string,
  domain?: any,
  isLoading: boolean,
}) => {
  const {
    priceBuy,
    id,
    domain,
    isLoading,
  } = props;
  const { y } = domain ?? {};
  const [minVal, maxVal] = y ?? [];
  const priceBuyPct = priceBuy ? (1 - ((priceBuy - minVal) / (maxVal - minVal))) * 100 : 0;
  const color = !isLoading ? getColor({
    price: priceBuy,
    minVal,
    maxVal,
  }) : [ouiStyle.Constants.Colors.fade, ouiStyle.Constants.Colors.fade];
  return (
    <svg style={{ height: 0 }}>
      <defs>
        <linearGradient
          id={id}
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0%" stopColor={color[1]} />
          {priceBuy ? <stop offset={`${priceBuyPct}%`} stopColor={color[1]} /> : null}
          {priceBuy ? <stop offset={`${priceBuyPct}%`} stopColor={color[0]} /> : null}
          <stop offset="100%" stopColor={color[0]} />
        </linearGradient>
      </defs>
    </svg>
  );
};
