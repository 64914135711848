import ouiBase from '@goldwasserexchange/oui';
import { useAccountPositionsHistoryTransformApiByHisMvtId } from '../api';
import { getId } from '../../../../shared';

export const useAccountPositionsHistoryArDocId = () => {
  const hisMvtId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const response = useAccountPositionsHistoryTransformApiByHisMvtId({
    hisMvtId,
    transformFunction: getId,
  });
  return response;
};
