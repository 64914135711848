import { dataStructure, AccountType } from '@goldwasserexchange/oblis-frontend-utils';
import ouiBase from '@goldwasserexchange/oui';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { Trans } from '@lingui/macro';
import * as React from 'react';
import { noIcon, yesIcon } from '../components/yesNoIcons';

export const PERSONAL_INVESTMENT_RECOMMENDATIONS = 'personalInvestmentRecommendations';

const personalInvestmentRecomendationsIcon = {
  [AccountType.RTO]: {
    icon: noIcon,
  },
  [AccountType.CONSEIL]: {
    icon: yesIcon,
  },
  [AccountType.GESTION]: {
    icon: noIcon,
  },
};

export const PersonalInvestmentRecomendationsIcon = (props: { accountType: dataStructure.accountType.listType }) => {
  const { accountType } = props;
  return personalInvestmentRecomendationsIcon[accountType].icon;
};

export const PersonalInvestmentRecomendationsIconOnPrimaryDimension = () => {
  const accountType = ouiBase.utils.dimensions.useCurrentPrimaryDimension() as dataStructure.accountType.listType;
  return (
    <PersonalInvestmentRecomendationsIcon accountType={accountType} />
  );
};

const personalInvestmentRecomendationsLabel = (
  <Trans>
    Recommandations personnalisées d'investissement
  </Trans>
);

export const personalInvestmentRecomendationsTopModel = (
  <>
    <TopModel name={`TableSecondaryTitleCellContent-${PERSONAL_INVESTMENT_RECOMMENDATIONS}`}>
      {personalInvestmentRecomendationsLabel}
    </TopModel>
    <TopModel name={`TableBodyRowCellContent--${PERSONAL_INVESTMENT_RECOMMENDATIONS}`}>
      {PersonalInvestmentRecomendationsIconOnPrimaryDimension}
    </TopModel>
  </>
);
