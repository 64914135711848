import * as React from 'react';
import { PassThrough, TopModel } from '@goldwasserexchange/react-topmodel';
import { FormBase } from './components';
import { FormContextContainer, FormDataProvider } from './context';
export var formDefaultTopModelsObject = {
    FormBase: (React.createElement(TopModel, { name: "FormBase" }, FormBase)),
    FormContextContainer: (React.createElement(TopModel, { name: "FormContextContainer" }, FormContextContainer)),
    FormDataProvider: (React.createElement(TopModel, { name: "DataProvider" }, FormDataProvider)),
};
export var formOnGlobalDsDefaultTopModelsObject = {
    FormBase: (React.createElement(TopModel, { name: "FormBase" }, FormBase)),
    FormContextContainer: (React.createElement(TopModel, { name: "FormContextContainer" }, FormContextContainer)),
    FormDataProvider: (React.createElement(TopModel, { name: "DataProvider" }, PassThrough)),
};
