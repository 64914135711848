import * as React from 'react';
import {
  ToggleContainer,
  ToggleIcon,
  ToggleInput,
  ToggleTextContainer,
} from './components';

export const Toggle = (props: React.PropsWithChildren<{
  id: string,
  value: boolean,
  onChange: React.InputHTMLAttributes<HTMLInputElement>['onChange'],
  disabled?: boolean,
  disabledContainer?: boolean,
  disabledInput?: boolean,
  disabledIcon?: boolean,
  disabledOnClick?: React.DOMAttributes<HTMLLabelElement>['onClick'],
}>) => {
  const {
    id,
    value,
    onChange: sourceOnChange,
    disabled,
    disabledContainer = disabled,
    disabledInput = disabled,
    disabledIcon = disabled,
    disabledOnClick,
    children,
  } = props;
  const [touched, setTouched] = React.useState(false);
  const onChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      if (sourceOnChange) {
        sourceOnChange(e);
        setTouched(true);
      }
    },
    [
      sourceOnChange,
      setTouched,
    ],
  );
  return (
    <ToggleContainer
      id={id}
      disabled={disabledContainer}
      disabledOnClick={disabledOnClick}
    >
      <ToggleInput
        id={id}
        value={value}
        disabled={disabledInput}
        onChange={onChange}
      />
      <ToggleIcon
        value={value}
        disabled={disabledIcon}
        touched={touched}
      />
      <ToggleTextContainer>
        {children}
      </ToggleTextContainer>
    </ToggleContainer>
  );
};
