/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const style: ouiStyle.InStyleWithMediaQueries = {
  flexGrow: 2,
  flexShrink: false,
  padding: 'simple',
  borderBottomStyle: 'solid',
  borderBottomWidth: ['simple', undefined, undefined, 'none', undefined, undefined],
  borderBottomColor: ouiStyle.Constants.Colors.accent,
  borderRightStyle: 'solid',
  borderRightWidth: ['none', undefined, undefined, 'simple', undefined, undefined],
  borderRightColor: ouiStyle.Constants.Colors.accent,
  textAlign: 'center',
  color: ouiStyle.Constants.Colors.accent,
};

const css = ouiStyle.mediaQuery(ouiStyle.makeCss(style));

export const HistoryTimeNavTitleElementContainer = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  return (
    <div
      css={css}
    >
      {children}
    </div>
  );
};
