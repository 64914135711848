import { getId } from '../../../../shared';
import { useFilterByPrimaryDimensionArDocId } from '../../utils';
import { useAccountDocumentTransformApi } from '../api';

export const useAccountDocumentArDocId = () => {
  const filterPredicate = useFilterByPrimaryDimensionArDocId();
  const response = useAccountDocumentTransformApi({
    filterPredicate,
    transformFunction: getId,
  });
  return response;
};
