import { useCallback } from 'react';
import { useFilterByTAccId } from '../../utils/tAcc/filterByTAccId';
import { PersonAccounts } from '../../type';
import { useLmlGet } from '../../../../../shared';
import { usePersonAccountTransformApi } from '../api';
import { useLinguiLocale } from '../../../../../../../Components/useLinguiLocale';

const makeTransformFunction = (lmlGet: ((lml: string) => string)) => (personAccount: PersonAccounts | undefined | null) => (personAccount && personAccount['S_INDICE.LML_DESCRIPTION'] ? lmlGet(personAccount['S_INDICE.LML_DESCRIPTION']) : '');

export const usePersonAccountsSIndiceDescription = () => {
  const tAccFilterById = useFilterByTAccId();
  const locale = useLinguiLocale();
  const lmlGet = useLmlGet(locale);
  const transformFunction = useCallback(makeTransformFunction(lmlGet), [lmlGet]);
  const personAccountsCCurrencyTAccCodeApiResponse = usePersonAccountTransformApi({
    filterPredicate: tAccFilterById,
    transformFunction,
  });
  return personAccountsCCurrencyTAccCodeApiResponse;
};
