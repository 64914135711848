import { AppBarGuardType, AppBarGuardObjectType } from '../type';

export const name = 'isTargetOpenShrinked';

export const fn: AppBarGuardType = (context) => context.target === 'openShrinked';

export const guard: AppBarGuardObjectType = {
  [name]: fn,
};

export default guard;
