import ouiStyle from '@goldwasserexchange/oui-style';

export const labelStyle: ouiStyle.InStyle = {
  display: ouiStyle.Constants.DisplayProp.block,
  padding: 'simple',
  paddingBottom: 'none',
  lines: 1,
  color: ouiStyle.Constants.Colors.primary,
};

export const focusLabelStyleAdditionalProperties: ouiStyle.InStyle = {
  color: ouiStyle.Constants.Colors.accent,
  fontWeight: ouiStyle.Constants.FontWeightProp.bold,
};

export const focusLabelStyle: ouiStyle.InStyle = {
  ...labelStyle,
  ...focusLabelStyleAdditionalProperties,
};

export const noFocusLabelStyleAdditionalProperties: ouiStyle.InStyle = {
  fontWeight: ouiStyle.Constants.FontWeightProp.normal,
};

export const noFocusLabelStyle: ouiStyle.InStyle = {
  ...labelStyle,
  ...noFocusLabelStyleAdditionalProperties,
};

export const errorLabelStyleAdditionalProperties: ouiStyle.InStyle = {
  color: ouiStyle.Constants.Colors.danger,
};
export const errorLabelStyle: ouiStyle.InStyle = {
  ...labelStyle,
  ...errorLabelStyleAdditionalProperties,
};

export const disabledLabelStyleAdditionalProperties: ouiStyle.InStyle = {
  color: ouiStyle.Constants.Colors.disabled,
};

export const disabledLabelStyle: ouiStyle.InStyle = {
  ...labelStyle,
  ...disabledLabelStyleAdditionalProperties,
};
