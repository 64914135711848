import { Trans } from '@lingui/macro';
import type { CodeDeliveryDetails } from 'amazon-cognito-identity-js';
import * as React from 'react';
import { Space } from '../../../../../../../../Space';
import { codeDeliveryDetailsContext } from '../../../../../../../context/context';

export const ForgotPasswordIntroInner = (props: {
  codeDeliveryDetails: CodeDeliveryDetails | null,
}) => {
  const {
    codeDeliveryDetails,
  } = props;
  if (codeDeliveryDetails == null) {
    throw new Error('no code delivery details available');
  }
  const {
    DeliveryMedium,
  } = codeDeliveryDetails;
  if (DeliveryMedium === 'SMS') {
    return (
      <Trans>
        Entrez le code de sécurité reçu par SMS :
      </Trans>
    );
  }
  if (DeliveryMedium === 'EMAIL') {
    const {
      Destination,
    } = codeDeliveryDetails;
    return (
      <Trans>
        Entrez le code de sécurité reçu par e-mail à l'adresse
        <Space />
        {Destination}
        <Space />
        :
      </Trans>
    );
  }
  throw new Error(`unsupported delivery medium ${DeliveryMedium}`);
};

export const ForgotPasswordIntro = () => {
  const codeDeliveryDetails = React.useContext(codeDeliveryDetailsContext);
  return <ForgotPasswordIntroInner codeDeliveryDetails={codeDeliveryDetails} />;
};
