import { useFilterByPrimaryDimensionDeviceId, getDeviceModel } from '../utils';
import { usePersonDeviceTransformApi } from './api';

export const usePersonDevicesModel = () => {
  const filterPredicate = useFilterByPrimaryDimensionDeviceId();
  const result = usePersonDeviceTransformApi({
    filterPredicate,
    transformFunction: getDeviceModel,
  });
  return result;
};
