import * as React from 'react';
import { AnyObjectSchema } from 'yup';
import { formValidatorContext } from './context';

export function FormValidatorContext<
  V extends AnyObjectSchema
>(props: React.PropsWithChildren<{ validator: V }>) {
  const { validator, children } = props;
  return (
    <formValidatorContext.Provider value={validator}>
      {children}
    </formValidatorContext.Provider>
  );
}
