import { format as dateFnsFormat, Locale } from 'date-fns';
import { useNumberFormatter } from '../../../../../../../Components/NumberDisplay';
import { useBondsBondTransformApi } from '../api';
import { parseActorDate } from '../../../../../shared/utils/parseDates';
import { useGetDateFnsLocale } from '../../../../../../../Components/getDateLocale';
import { BondResponse } from '../../type';
import { STypeGrpIds } from '../../../../../shared/utils/sTypeGrpIds';

const makeTransformFn = (props: {
  intRateFormatter: (value: number) => string,
  locale: Locale,
  maxSIssuerName1Length?: number,
}) => (bondsBond: BondResponse | undefined) => {
  const { intRateFormatter, locale, maxSIssuerName1Length } = props;
  if (bondsBond == null) {
    return '';
  }
  const cCurrencyCode = bondsBond['C_CURRENCY.CODE'];
  const sIssuerName1 = bondsBond['S_ISSUER.NAME_1'];
  const sAccIntRate = intRateFormatter(bondsBond['S_ACC.INT_RATE']);
  const isPerpetual = bondsBond['S_TYPE_GRP.ID'] === STypeGrpIds.OBLIGATIONS_PERP;
  const sAccOblEndDate = !isPerpetual
    ? dateFnsFormat(parseActorDate(bondsBond['S_ACC.OBL_END_DATE']), 'dd/MM/yyyy', { locale })
    : '∞';
  const sIsuerName1Abbreviation = maxSIssuerName1Length != null && sIssuerName1.length > maxSIssuerName1Length
    ? `${sIssuerName1.slice(0, maxSIssuerName1Length)}.`
    : sIssuerName1;
  return `${cCurrencyCode} ${sIsuerName1Abbreviation} ${sAccIntRate} ${sAccOblEndDate}`;
};

const intRateFormat = {
  format: {
    style: 'percent' as const,
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  },
  divider: 100,
};

export const useBondsBondFullName = (props?: {
  maxSIssuerName1Length?: number,
}) => {
  const {
    maxSIssuerName1Length,
  } = props ?? {};
  const intRateFormatter = useNumberFormatter(intRateFormat);
  const locale = useGetDateFnsLocale();
  const transformFn = makeTransformFn({
    intRateFormatter,
    locale,
    maxSIssuerName1Length,
  });
  const response = useBondsBondTransformApi(transformFn);
  return response;
};
