import * as events from '../../../events';
import { makeSpringService } from '../utils';

export type AppBarAnimateMqUpServiceMeta = {
  src: {
    type: 'AnimateMqUpService',
  },
};

export const name = 'animateMqUp';

export const makeService = makeSpringService(name, 'mqUp', 1, true, events.mqChangedEvent.eventBuilder);

export default makeService;
