import { CashflowByCurrency } from '../../type';
import { useCashflowByCurrencyLinesTransformApi } from '../api';

const transformFunction = (cashflows: CashflowByCurrency[] | undefined) => (
  cashflows
    ? cashflows.map((cashflow) => cashflow['C_CURRENCY.CODE'])
    : []
);

export const useCashflowsByCurrencyList = (props: {
  filterPredicate?: ((data: CashflowByCurrency) => boolean),
  sortPredicate?: ((prev: CashflowByCurrency, next: CashflowByCurrency) => number),
}) => {
  const {
    filterPredicate,
    sortPredicate,
  } = props;
  const result = useCashflowByCurrencyLinesTransformApi({
    sortPredicate,
    transformFunction,
    filterPredicate,
  });
  return result;
};
