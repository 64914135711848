/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const style: ouiStyle.InStyleWithMediaQueries = {
  display: ouiStyle.Constants.DisplayProp.flex,
  flexDirection: ouiStyle.Constants.InFlexDirectionProp.row,
  justifyContent: [ouiStyle.Constants.JustifyContentProp.center, undefined, ouiStyle.Constants.JustifyContentProp.normal, undefined, undefined, undefined],
};

const css = ouiStyle.mediaQuery(ouiStyle.makeCss(style));

export const RecommendationContainer = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  return (
    <div
      css={css}
    >
      {children}
    </div>
  );
};
