/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import React from 'react';

const arrayListStyle: ouiStyle.InStyle = {
  padding: 'none',
  listStyleType: ouiStyle.Constants.ListStyleTypeProps.none,
};

const arrayListCss = ouiStyle.makeCss(arrayListStyle);

export const ArrayList = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  return (
    <ul
      css={arrayListCss}
    >
      {children}
    </ul>
  );
};
