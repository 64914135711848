import {
  differenceInDays, differenceInHours, differenceInMinutes, differenceInMonths, differenceInQuarters, differenceInSeconds, differenceInWeeks, differenceInYears,
} from 'date-fns';
import * as React from 'react';
import { useLinguiLocale } from '../useLinguiLocale';

export type TimeFormatUnit =
  | 'year'
  | 'quarter'
  | 'month'
  | 'week'
  | 'day'
  | 'hour'
  | 'minute'
  | 'second';

const differenceFnMap: Record<TimeFormatUnit, (dateLeft: number | Date, dateRight: number | Date) => number> = {
  year: differenceInYears,
  quarter: differenceInQuarters,
  month: differenceInMonths,
  week: differenceInWeeks,
  day: differenceInDays,
  hour: differenceInHours,
  minute: differenceInMinutes,
  second: differenceInSeconds,
};

export const getRelativeTimeDifferenceFn = (timeFormatUnit: TimeFormatUnit) => differenceFnMap[timeFormatUnit];

export const useRelativeTimeFormatter = (language: string, style?: 'short' | 'narrow' | 'long') => {
  const relativeFormatter = React.useMemo(() => new Intl.RelativeTimeFormat(language, { style }), [language, style]);
  return React.useCallback((diff: number, timeFormatUnit: TimeFormatUnit) => relativeFormatter.format(diff, timeFormatUnit), [relativeFormatter]);
};

export const getDefaultTimeFormatUnit = (
  startDate: Date,
  endDate: Date,
): TimeFormatUnit => {
  if (differenceInMinutes(endDate, startDate) < 1) {
    return 'second';
  }
  if (differenceInHours(endDate, startDate) < 1) {
    return 'minute';
  }
  if (differenceInDays(endDate, startDate) < 1) {
    return 'hour';
  }
  if (differenceInWeeks(endDate, startDate) < 1) {
    return 'day';
  }
  if (differenceInMonths(endDate, startDate) < 1) {
    return 'week';
  }
  if (differenceInYears(endDate, startDate) < 1) {
    return 'month';
  }
  return 'year';
};

const getFormatterFromDates = (formatter: (diff: number, timeFormatUnit: TimeFormatUnit) => string) => (
  startDate: Date,
  endDate: Date,
  timeFormatUnit: TimeFormatUnit,
) => {
  const defaultTimeFormatUnit = timeFormatUnit;
  const difference = getRelativeTimeDifferenceFn(defaultTimeFormatUnit);
  return formatter(difference(startDate, endDate), defaultTimeFormatUnit);
};

export const useRelativeTimeFormatterFromDates = (language: string, style?: 'short' | 'narrow' | 'long') => {
  const formatter = useRelativeTimeFormatter(language, style);
  const formatterFromDates = React.useCallback(getFormatterFromDates(formatter), [formatter]);
  return formatterFromDates;
};

export const useGetRelativeTimeString = (props: {
  language: string,
  startDate: Date,
  endDate: Date,
  timeFormatUnit: TimeFormatUnit,
  style?: 'short' | 'narrow' | 'long',
}) => {
  const {
    language,
    startDate,
    endDate,
    timeFormatUnit,
    style = 'long',
  } = props;
  const relativeFormatter = useRelativeTimeFormatterFromDates(language, style);
  const relativeTimeString = relativeFormatter(startDate, endDate, timeFormatUnit);
  return relativeTimeString;
};

export const RelativeTime = (props: {
  startDate: Date,
  endDate: Date,
  timeFormatUnit: TimeFormatUnit,
  style?: 'short' | 'narrow' | 'long',
}) => {
  const locale = useLinguiLocale();
  const {
    startDate,
    endDate,
    timeFormatUnit,
    style = 'long',
  } = props;
  const relativeTimeString = useGetRelativeTimeString({
    language: locale,
    startDate,
    endDate,
    timeFormatUnit,
    style,
  });
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{relativeTimeString}</>;
};
