import * as React from 'react';
import { ActorApiNumberDisplay } from '../../../../../../components/display/number/component';
import { useAccountTwrrCumulativePerformance } from '../../hooks/tAccTwrrP/rdt';

export const AccountsTwrrTAccTwrrCumulativePerformance = (props: {
  withSpaceBefore?: boolean,
}) => {
  const {
    withSpaceBefore,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useAccountTwrrCumulativePerformance();
  return (
    <ActorApiNumberDisplay
      data={data}
      isLoading={isLoading}
      error={error}
      format={{
        style: 'percent',
      }}
      divider={1}
      withSpaceBefore={withSpaceBefore}
      forcedSigned
    />
  );
};
