import { createContext } from 'react';
import { UncastedPhysicalTAdd } from '@goldwasserexchange/oblis-frontend-utils';

export type SearchKey = keyof UncastedPhysicalTAdd | 'value' | 'socialForm' | 'T_REL_FIN.NUM_FIN' | 'C_CURRENCY.CODE' | 'LML_DESCRIPTION' | 'LML_NATIONALITY';

export type SearchKeysProps = {
  searchKeys?: SearchKey[],
}

export const searchKeysContext = createContext<SearchKey[]>([]);
