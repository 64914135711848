import {
  LocationClient,
} from '@aws-sdk/client-location';
import { AuthSendType } from '../../../Components/Auth/context/context';
import { getEssentialCredentials } from '../credentials';

export const getLocationClient = async (send?: AuthSendType) => {
  const credentials = await getEssentialCredentials(send);
  const client = new LocationClient({
    credentials,
    region: 'eu-west-1',
  });
  return client;
};
