import * as fields from './fields';
export var fieldTypes = {
    preferences: fields.preferences.fieldType,
    taxonomy: fields.taxonomy.fieldType,
    taxonomyAlignment: fields.taxonomyAlignment.fieldType,
    taxonomyProportion: fields.taxonomyProportion.fieldType,
    sfdr: fields.sfdr.fieldType,
    sfdrDurableProportion: fields.sfdrDurableProportion.fieldType,
    sfdrSocialEnvironmentalProportion: fields.sfdrSocialEnvironmentalProportion.fieldType,
    sfdrDurableOnly: fields.sfdrDurableOnly.fieldType,
    sfdrSocialEnvironmentalOnly: fields.sfdrSocialEnvironmentalOnly.fieldType,
    incidence: fields.incidence.fieldType,
    incidenceType: fields.incidenceType.fieldType,
    incidenceProportion: fields.incidenceProportion.fieldType,
};
