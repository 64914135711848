import { useFilterByTAccId } from '../../utils/tAcc/filterByTAccId';
import { usePersonAccountFieldApi } from '../api';

export const usePersonAccountsCCurrencyTAccCode = () => {
  const tAccFilterById = useFilterByTAccId();
  const personAccountsCCurrencyTAccCodeApiResponse = usePersonAccountFieldApi({
    field: 'C_CURRENCY_T_ACC.CODE',
    filterPredicate: tAccFilterById,
  });
  return personAccountsCCurrencyTAccCodeApiResponse;
};
