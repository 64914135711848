import {
  dataStructure, UncastedPhysicalTAdd, PersonType, PhysicalTAdd, TAddType, SignificantOther,
} from '@goldwasserexchange/oblis-frontend-utils';
import {
  object, date,
} from 'yup';
import {
  nilValidator,
  stringValidator,
  euroDateValidator,
  fromEuroDate,
  euroDateError,
  requiredStringValidator,
  makeRequired,
  makeDateInThePast,
  makeDateNotMinor,
} from '../../../../../OUI/Inputs';
import { makeWhenAlwaysCast } from '../../../../Form/validations/whenAlwaysCast';
import { ValidationContextType } from '../../../../Form/validations/contextType';

export const T_ADD_SIGNIFICANT_OTHER_FIRSTNAME_COLUMN = 'significantOther_FIRSTNAME';
export const T_ADD_SIGNIFICANT_OTHER_NAME1_COLUMN = 'significantOther_NAME1';
export const T_ADD_SIGNIFICANT_OTHER_BIRTH_DATE_COLUMN = 'T_ADD_SIGNIFICANT_OTHER_BIRTH_DATE_COLUMN';

const significantOtherValidatorDependencyPath: ((keyof UncastedPhysicalTAdd) | '$')[] = [
  '$',
  'MC_CIVILSTATUS',
  'isMinor',
];

const makeSignificantOtherValidator = (shape: Record<keyof UncastedPhysicalTAdd['significantOther'], any>) => makeWhenAlwaysCast(significantOtherValidatorDependencyPath, (context: ValidationContextType, MC_CIVILSTATUS: PhysicalTAdd['MC_CIVILSTATUS'], isMinor: PhysicalTAdd['isMinor']) => {
  const { rootValue, at } = context;
  const { onboarding } = rootValue;
  const { personType } = onboarding;
  const nilValidatorShape = Object.keys(shape).reduce((acc, key) => ({ ...acc, [key]: nilValidator }), {});
  if (isMinor === true || personType === PersonType.MORAL || MC_CIVILSTATUS === null || !dataStructure.T_ADD.fields.MC_CIVILSTATUS.hasSignificantOtherList.includes(MC_CIVILSTATUS)) {
    return at ? object().shape(nilValidatorShape) : nilValidator;
  }
  return object().shape(shape);
});

const significantOtherFirstNameValidatorWhenPaths: ((keyof SignificantOther) | '$')[] = ['$', 'parentUuid'];

const makeSignificantOtherFieldValidator = (
  field: keyof Exclude<PhysicalTAdd['significantOther'], null>,
  type: 'string' | 'birthdate',
) => makeWhenAlwaysCast(significantOtherFirstNameValidatorWhenPaths, (context: ValidationContextType, parentUuid: PhysicalTAdd['id']) => {
  const { rootValue } = context;
  const {
    onboarding,
  } = rootValue;
  const {
    personType,
  } = onboarding;
  if (personType === PersonType.JOINT) {
    const {
      users,
    } = onboarding;
    const titularUsers = users.filter((user) => user.T_ADD_type === TAddType.TITULAR);
    const otherUser = titularUsers.find((user) => user.id !== parentUuid);
    if (!otherUser) {
      return nilValidator;
    }
    if (type === 'birthdate') {
      if (otherUser[field] === '') {
        return makeRequired(stringValidator.transform(() => otherUser[field].trim()));
      }

      return makeRequired(makeDateNotMinor(makeDateInThePast(date().transform(function castDate(value) {
        return fromEuroDate.apply(this, [value, otherUser[field]]);
      }).typeError(euroDateError))));
    }
    return makeRequired(stringValidator.transform(() => otherUser[field].trim()));
  }
  if (type === 'birthdate') {
    return makeRequired(makeDateNotMinor(makeDateInThePast(euroDateValidator)));
  }
  return makeRequired(stringValidator);
});

export const significantOtherValidator = makeSignificantOtherValidator({
  parentUuid: requiredStringValidator,
  FIRSTNAME: makeSignificantOtherFieldValidator('FIRSTNAME', 'string'),
  NAME_1: makeSignificantOtherFieldValidator('NAME_1', 'string'),
  BIRTH_DATE: makeSignificantOtherFieldValidator('BIRTH_DATE', 'birthdate'),
});
