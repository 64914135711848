var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { animated } from 'react-spring';
import { useLinkController } from './controller';
export var Link = function (props) {
    var ref = props.ref, propsHref = props.href, propsTarget = props.target, propsOnClick = props.onClick, replace = props.replace, animatedValues = props.animatedValues, children = props.children, rest = __rest(props, ["ref", "href", "target", "onClick", "replace", "animatedValues", "children"]);
    var _a = useLinkController({
        href: propsHref,
        target: propsTarget,
        onClick: propsOnClick,
        replace: replace,
    }), href = _a.href, onClick = _a.onClick, target = _a.target;
    if (animatedValues != null) {
        return (React.createElement(animated.a, __assign({}, rest, { ref: ref, href: href, onClick: onClick, target: target, style: animatedValues }), children));
    }
    return (React.createElement("a", __assign({}, rest, { ref: ref, href: href, onClick: onClick, target: target }), children));
};
