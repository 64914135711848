import * as React from 'react';
import { ActorApiNumberDisplay } from '../../../../../../components/display/number/component';
import { ActorApiStringDisplay } from '../../../../../../components/display/string';
import {
  useBondsCallEvtEntrySCmtHistPct,
  useBondsCallEvtEntrySCmtHistNextCall,
  useBondsCallIsRedacted,
  useBondsCallEvtEntrySCmtHistPostPlanningCall,
  useBondsCallEvtEntrySCmtHistLast,
} from '../../hooks';

export const BondsCallEvtEntrySCmtHistPct = () => {
  const {
    data,
    isLoading: dataIsLoading,
    error,
  } = useBondsCallEvtEntrySCmtHistPct();
  const {
    data: redacted,
    isLoading,
  } = useBondsCallIsRedacted('EVT_ENTRY_S.CMT_HIST', dataIsLoading);
  return (
    <ActorApiNumberDisplay
      data={data}
      isLoading={isLoading}
      error={error}
      format={{
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 5,
      }}
      isBlocked={redacted}
      withRedactedIcon
    />
  );
};

export const BondsCallEvtEntrySCmtHistNextCall = (props: {
  withRedactedIcon?: boolean,
}) => {
  const {
    withRedactedIcon,
  } = props;
  const {
    data,
    isLoading: dataIsLoading,
    error,
  } = useBondsCallEvtEntrySCmtHistNextCall();
  const {
    data: redacted,
    isLoading,
  } = useBondsCallIsRedacted('EVT_ENTRY_S.CMT_HIST', dataIsLoading);
  return (
    <ActorApiNumberDisplay
      data={data}
      isLoading={isLoading}
      error={error}
      format={{
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 5,
      }}
      isBlocked={redacted}
      withRedactedIcon={withRedactedIcon}
    />
  );
};

export const BondsCallEvtEntrySCmtHistLastCall = (props: {
  withRedactedIcon?: boolean,
}) => {
  const {
    withRedactedIcon,
  } = props;
  const {
    data,
    isLoading: dataIsLoading,
    error,
  } = useBondsCallEvtEntrySCmtHistLast();
  const {
    data: redacted,
    isLoading,
  } = useBondsCallIsRedacted('EVT_ENTRY_S.CMT_HIST', dataIsLoading);
  return (
    <ActorApiNumberDisplay
      data={data}
      isLoading={isLoading}
      error={error}
      format={{
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 5,
      }}
      isBlocked={redacted}
      withRedactedIcon={withRedactedIcon}
    />
  );
};

export const BondsCallEvtEntrySCmtHistPostPlanningCall = () => {
  const {
    data,
    isLoading: dataIsLoading,
    error,
  } = useBondsCallEvtEntrySCmtHistPostPlanningCall();
  const {
    data: redacted,
    isLoading,
  } = useBondsCallIsRedacted('EVT_ENTRY_S.CMT_HIST', dataIsLoading);
  return (
    <ActorApiStringDisplay
      data={data}
      isLoading={isLoading}
      error={error}
      isBlocked={redacted}
      withRedactedIcon
    />
  );
};
