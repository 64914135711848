import * as React from 'react';
import { ActorApiStringDisplay } from '../../../../../../components/display/string';
import { useBondSameIssuerSIssuerName1, useBondsSameIssuerIsRedacted } from '../../hooks';

export const BondSameIssuersSIssuerName1 = (props: {
  withRedactedIcon?: boolean,
}) => {
  const {
    withRedactedIcon,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useBondSameIssuerSIssuerName1();
  const {
    data: redacted,
    isLoading: redactedIsLoading,
  } = useBondsSameIssuerIsRedacted('S_ISSUER.NAME_1');
  return (
    <ActorApiStringDisplay
      data={data}
      isLoading={isLoading || redactedIsLoading}
      error={error}
      loadingValue="-------- ---- ------ --"
      isBlocked={redacted}
      withRedactedIcon={withRedactedIcon}
    />
  );
};
