import { getTopModelFromChildren, Ramp, TopModelMerge } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';

export const ScrollableStructure = (props: React.PropsWithChildren<{}>) => {
  const {
    children: incomingChildren,
  } = props;
  const [children, topModelChildren] = getTopModelFromChildren(incomingChildren);
  return (
    <TopModelMerge topModels={topModelChildren}>
      <Ramp
        rampName="ScrollableBase"
      >
        {children}
      </Ramp>
    </TopModelMerge>
  );
};
