import { useCallback } from 'react';
import {
  computeBuyAndSellCustodyFeesAmountBuyAndSell,
  computeBuyAndSellCustodyFeesBuyAndSellPct,
} from '../../utils';
import { AccountRates } from '../../type';
import { useAccountRateFieldApi, useAccountRateTransformApi } from '../api';
import { useSimulationDataBuyAndSellContext } from '../../context/simulation/hooks';

export const useAccountRatesTAccRateDg = () => {
  const result = useAccountRateFieldApi({
    field: 'T_ACC.RATE_DG',
  });
  return result;
};

export const useAccountRatesTAccRateDgAmountBuyAndSellOnContext = () => {
  const {
    counterValue,
    durationYears,
  } = useSimulationDataBuyAndSellContext();
  const transformFunction = useCallback((data: AccountRates | null | undefined) => (data ? computeBuyAndSellCustodyFeesAmountBuyAndSell({
    counterValue,
    durationYears,
  })(data['T_ACC.RATE_DG']) : 0), [
    counterValue,
    durationYears,
  ]);
  const result = useAccountRateTransformApi({
    transformFunction,
  });
  return result;
};

export const useAccountRatesTAccRateDgAmountBuyAndSellPctOnContext = () => {
  const {
    counterValue,
    durationYears,
  } = useSimulationDataBuyAndSellContext();
  const transformFunction = useCallback((data: AccountRates | null | undefined) => (data ? computeBuyAndSellCustodyFeesBuyAndSellPct({
    counterValue,
    durationYears,
  })(data['T_ACC.RATE_DG']) : 0), [
    counterValue,
    durationYears,
  ]);
  const result = useAccountRateTransformApi({
    transformFunction,
  });
  return result;
};
