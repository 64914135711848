import { useContext } from 'react';
import { actorApiLoadingContext } from './context';

export const useActorApiLoadingHandlers = () => {
  const handlers = useContext(actorApiLoadingContext);
  return handlers;
};

export const useActorApiLoading = () => {
  const [loading] = useActorApiLoadingHandlers();
  return loading;
};

export const useSetActorApiLoading = () => {
  const [, setLoading] = useActorApiLoadingHandlers();
  return setLoading;
};
