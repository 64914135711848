import useSWR, { KeyedMutator } from 'swr';
import { useMemo } from 'react';
import { PersonAccounts } from '../type';
import { Persons } from '../../enum';
import { getServiceUrl } from '../../../../shared';
import { usePersonAccountsStringifiedParams } from './defaultParams';
import { ACTOR_DEDUPING_INTERVAL } from '../../../shared';
import { useFetchWithAuthWithSend } from '../../../shared/utils/fetchWithAuth';
import { makeApiUrl } from '../../../../../../aws';

const useMakePersonAccountsUrl = () => {
  const stringifiedParams = usePersonAccountsStringifiedParams();
  const url = stringifiedParams !== null ? `${makeApiUrl('actor')}/${getServiceUrl(Persons.Accounts)}${stringifiedParams}` : null;
  return url;
};

type BaseUsePersonAccountsApiResponse = {
  isLoading: boolean,
  error?: any,
  mutate: KeyedMutator<PersonAccounts[]>,
  isValidating: boolean,
}

type UsePersonAccountsApiResponse = {
  data: undefined | PersonAccounts[],
} & BaseUsePersonAccountsApiResponse;

type FilterPredicate = (personAccounts: PersonAccounts) => boolean;

type SortPredicate = (prevPersonAccounts: PersonAccounts, nextPersonAccounts: PersonAccounts) => number;

type UsePersonAccountsApiProps = {
  filterPredicate?: FilterPredicate,
  sortPredicate?: SortPredicate,
};

export const usePersonAccountsApi = (props: UsePersonAccountsApiProps): UsePersonAccountsApiResponse => {
  const {
    filterPredicate,
    sortPredicate,
  } = props;
  const url = useMakePersonAccountsUrl();
  const fetcher = useFetchWithAuthWithSend();
  const result = useSWR<PersonAccounts[], any>(
    url,
    fetcher,
    { dedupingInterval: ACTOR_DEDUPING_INTERVAL },
  );
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    let { data } = result;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      if (filterPredicate) {
        data = data.filter((account) => filterPredicate(account));
      }
    }
    return ({
      ...result,
      data,
      isLoading,
    });
  }, [result, filterPredicate, sortPredicate]);
  return resultWithLoading;
};

type TransformArrayFunction<T> = (personAccounts: PersonAccounts[] | undefined) => T

type UsePersonAccountsApiTransformProps<T> = {
  transformFunction: TransformArrayFunction<T>,
} & UsePersonAccountsApiProps

type UsePersonAccountsApiTransformResponse<T> = {
  data: T | undefined,
} & BaseUsePersonAccountsApiResponse;

export function usePersonAccountsTransformApi<T>(props: UsePersonAccountsApiTransformProps<T>): UsePersonAccountsApiTransformResponse<T> {
  const {
    filterPredicate,
    sortPredicate,
    transformFunction,
  } = props;
  const response = usePersonAccountsApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: (!isLoading && !error) ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UsePersonAccountApiResponse = {
  data: PersonAccounts | undefined,
} & BaseUsePersonAccountsApiResponse;

export const usePersonAccountApi = (props: UsePersonAccountsApiProps): UsePersonAccountApiResponse => {
  const {
    filterPredicate,
    sortPredicate,
  } = props;
  const url = useMakePersonAccountsUrl();
  const fetcher = useFetchWithAuthWithSend();
  const result = useSWR<PersonAccounts[], any>(
    url,
    fetcher,
    { dedupingInterval: ACTOR_DEDUPING_INTERVAL },
  );
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    // eslint-disable-next-line prefer-destructuring
    let data: PersonAccounts[] | PersonAccounts | undefined = result.data;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      if (filterPredicate) {
        data = data.find((account) => filterPredicate(account));
      }
      if (Array.isArray(data)) {
        // eslint-disable-next-line prefer-destructuring
        data = data[0];
      }
    }
    return ({
      ...result,
      data,
      isLoading,
    });
  }, [result, filterPredicate, sortPredicate]);
  return resultWithLoading;
};

type TransformFunction<T> = (personAccount: PersonAccounts | undefined) => T

type UsePersonAccountApiTransformProps<T> = {
  transformFunction: TransformFunction<T>,
} & UsePersonAccountsApiProps;

export function usePersonAccountTransformApi<T>(props: UsePersonAccountApiTransformProps<T>): UsePersonAccountsApiTransformResponse<T> {
  const {
    filterPredicate,
    sortPredicate,
    transformFunction,
  } = props;
  const response = usePersonAccountApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: !isLoading && !error ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UsePersonAccountFieldApiResponse<Field extends keyof PersonAccounts> = {
  data: PersonAccounts[Field] | undefined,
} & BaseUsePersonAccountsApiResponse;

type UsePersonAccountFieldApiProps<Field extends keyof PersonAccounts> = {
  field: Field,
} & UsePersonAccountsApiProps

export function usePersonAccountFieldApi<Field extends keyof PersonAccounts>(props: UsePersonAccountFieldApiProps<Field>): UsePersonAccountFieldApiResponse<Field> {
  const {
    filterPredicate,
    sortPredicate,
    field,
  } = props;
  const response = usePersonAccountApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
    } = response;
    return {
      ...response,
      data: data ? data[field] : undefined,
    };
  }, [response, field]);
  return result;
}
