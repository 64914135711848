import * as React from 'react';
import { SameIssuerSortColumnContext } from './column';
import { SameIssuerSortDirectionContext } from './direction';

export const SameIssuerSortProvider = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  return (
    <SameIssuerSortColumnContext>
      <SameIssuerSortDirectionContext>
        {children}
      </SameIssuerSortDirectionContext>
    </SameIssuerSortColumnContext>
  );
};
