import * as React from 'react';
import { createContext } from 'react';
import { CUSTOM_TABLE_NAME, GeTables } from './types';

const tableContext = createContext<keyof GeTables>(CUSTOM_TABLE_NAME);

export const TableContextProvider = (props: React.PropsWithChildren<{ tableName: keyof GeTables }>) => {
  const { tableName, children } = props;
  return (
    <tableContext.Provider value={tableName}>
      {children}
    </tableContext.Provider>
  );
};

export const useTableName = () => {
  const tableName = React.useContext(tableContext);
  return tableName;
};

const columnContext = createContext<string | null>(null);

export const ColumnContextProvider = (props: React.PropsWithChildren<{ column: string }>) => {
  const { column, children } = props;
  return (
    <columnContext.Provider value={column}>
      {children}
    </columnContext.Provider>
  );
};

export const useColumn = () => {
  const column = React.useContext(columnContext);
  return column;
};

export const ActorContextProvider = (props: React.PropsWithChildren<{
  tableName: keyof GeTables,
  column: string,
}>) => {
  const { tableName, column, children } = props;
  return (
    <TableContextProvider tableName={tableName}>
      <ColumnContextProvider column={column}>
        {children}
      </ColumnContextProvider>
    </TableContextProvider>
  );
};
