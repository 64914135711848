/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { SuccessState } from './type';

const cardTitleStyle: ouiStyle.InStyle = {
  margin: 'none',
  fontWeight: ouiStyle.Constants.FontWeightProp.bold,
  paddingVertical: 'half',
};

const useCardTitleCss = (hasIcon, successState?: SuccessState) => {
  const css = React.useMemo(() => {
    const iconStyle: ouiStyle.InStyle = hasIcon
      ? {}
      : {
        paddingLeft: 'none',
        icon: true,
      };
    const cardTitleStyleWithIcon: ouiStyle.InStyle = {
      ...cardTitleStyle,
      ...iconStyle,
    };
    switch (successState) {
      case 'success':
        return ouiStyle.makeCss({
          ...cardTitleStyleWithIcon,
          color: ouiStyle.Constants.Colors.success,
        });
      case 'warning':
        return ouiStyle.makeCss({
          ...cardTitleStyleWithIcon,
          color: ouiStyle.Constants.Colors.warning,
        });
      case 'error':
        return ouiStyle.makeCss({
          ...cardTitleStyleWithIcon,
          color: ouiStyle.Constants.Colors.danger,
        });
      default:
        return ouiStyle.makeCss(cardTitleStyleWithIcon);
    }
  }, [successState]);
  return css;
};

export const CardTitle = (props: React.PropsWithChildren<{
  hasIcon: boolean,
  successState?: SuccessState,
}>) => {
  const {
    hasIcon,
    successState,
    children,
  } = props;
  const css = useCardTitleCss(hasIcon, successState);
  return (
    <p
      css={css}
    >
      {children}
    </p>
  );
};
