import { useRef, useEffect } from 'react';
import warning from 'warning';
import { useFreeze } from './useFreeze';
export function useFreezeArray(array, name) {
    var previousArray = useRef(array);
    useEffect(function () {
        warning(array.length === previousArray.current.length, "you should not change the ".concat(name, " array, those changes won't be reflected"));
        var isSame = array.every(function (v, k) { return v === previousArray.current[k]; });
        warning(isSame, "you should not change the content of the ".concat(name, " array, those changes won't be reflected"));
    }, [array]);
    var freezedArray = useFreeze(array);
    return freezedArray;
}
