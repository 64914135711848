export var REM_FACTOR = 1 / 16;
// base font size
export var fontSize = 1;
export var lineHeight = 1.25;
export var computedLineHeight = fontSize * lineHeight;
var step = (computedLineHeight - fontSize) / 4;
// smallest font size
export var smallestFontSize = fontSize - (2 * step);
export var smallestLineHeight = lineHeight / smallestFontSize;
export var smallestComputedLineHeight = smallestFontSize * smallestLineHeight;
// smaller font size
export var smallerFontSize = fontSize - step;
export var smallerLineHeight = lineHeight / smallerFontSize;
export var smallerComputedLineHeight = smallerFontSize * smallerLineHeight;
// base plus font size
export var basePlusFontSize = fontSize + step;
export var basePlusLineHeight = lineHeight / basePlusFontSize;
export var basePlusComputedLineHeight = basePlusFontSize * basePlusLineHeight;
// medium font size
export var mediumFontSize = fontSize + (2 * step);
export var mediumLineHeight = lineHeight / mediumFontSize;
export var mediumComputedLineHeight = mediumFontSize * mediumLineHeight;
// medium plus font size
export var mediumPlusFontSize = fontSize + (3 * step);
export var mediumPlusLineHeight = lineHeight / mediumPlusFontSize;
export var mediumPlusComputedLineHeight = mediumPlusFontSize * mediumPlusLineHeight;
export var space = computedLineHeight / 2;
export var borderRadius = space / 2;
export var fullHeight = computedLineHeight + 2 * space;
export var iconSize = 24 / 16;
export var borderSize = fontSize / 16;
export var averageNameWidth = 9.375;
var REM_TO_PX_MULTIPLIER = 9.6;
export var convertRemToPxValue = function (remValue) { return remValue * REM_TO_PX_MULTIPLIER; };
export var spacePDF = convertRemToPxValue(space);
