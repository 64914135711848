import { AppBarGuardType, AppBarGuardObjectType } from '../type';

export const name = 'isMqAnimatedDown';

export const fn: AppBarGuardType = (context) => context.mqUp === 0 && context.mqAction === 0;

export const guard: AppBarGuardObjectType = {
  [name]: fn,
};

export default guard;
