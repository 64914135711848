import * as React from 'react';
import { CurrentModalContextProvider } from './currentModal/component';
import { ModalSkrim } from '../skrim';
import { ModalZIndexProvider } from './zIndex';
import { CanCloseCurrentModalContextProvider } from './canCloseModal';

export const ModalContextProvider = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  return (
    <CurrentModalContextProvider>
      <CanCloseCurrentModalContextProvider>
        <ModalZIndexProvider>
          <ModalSkrim />
          {children}
        </ModalZIndexProvider>
      </CanCloseCurrentModalContextProvider>
    </CurrentModalContextProvider>
  );
};
