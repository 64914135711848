import { TwoDimensionalMatrix, UniDimensionalMatrix } from './types';

/**
 * This function performs backward substitution on a matrix and returns an array.
 * @param {TwoDimensionalMatrix} matrix The matrix to perform backward substitution on.
 * @param {UniDimensionalMatrix} arr The array to return.
 * @param {number} row The row number to begin the substitution.
 * @param {number} col The column number to begin the substitution.
 * @returns {UniDimensionalMatrix} An array containing the results of the substitution.
 */
export const backwardSubstitution = (
  matrix: TwoDimensionalMatrix,
  arr: UniDimensionalMatrix,
  row: number,
  col: number,
): UniDimensionalMatrix => {
  if (row < 0 || col < 0) {
    return arr;
  }
  const rows = matrix.length;
  const cols = matrix[0].length - 1;
  let current = 0;
  let counter = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = cols - 1; i >= col; i--) {
    if (i === col) {
      current = matrix[row][cols] / matrix[row][i];
    } else {
      // eslint-disable-next-line no-param-reassign
      matrix[row][cols] -= matrix[row][i] * arr[rows - 1 - counter];
      // eslint-disable-next-line no-plusplus
      counter++;
    }
  }
  // eslint-disable-next-line no-param-reassign
  arr[row] = current;
  return backwardSubstitution(matrix, arr, row - 1, col - 1);
};
