import { AccountType, DurabilityPreferences, DurabilitySFDR, } from '../../../../../types';
var onboardingPath = 'onboarding';
var selectedService = 'selectedService';
var durability = 'durability';
var preferences = 'preferences';
var sfdr = 'sfdr';
var makeLogic = function (truthy, falsy) { return ({
    name: "".concat(onboardingPath, ".").concat(selectedService),
    is: AccountType.CONSEIL,
    alternatives: {
        truthy: {
            name: "".concat(onboardingPath, ".").concat(durability, ".").concat(preferences),
            is: DurabilityPreferences.SPECIFIC_TRUE,
            alternatives: {
                truthy: {
                    name: "".concat(onboardingPath, ".").concat(durability, ".").concat(sfdr),
                    is: DurabilitySFDR.YES,
                    alternatives: {
                        truthy: {
                            result: truthy,
                        },
                        falsy: {
                            result: falsy,
                        },
                    },
                },
                falsy: {
                    result: falsy,
                },
            },
        },
        falsy: {
            result: falsy,
        },
    },
}); };
export var showLogic = makeLogic('visible', 'invisible');
export var showLogicBoolean = makeLogic(true, false);
