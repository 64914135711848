import { useCallback } from 'react';
import { useChecked } from '../../../../Inputs/context/checked';
import { useFieldContent } from '../../../../Inputs/context/content';

export const useCreateOnChange = () => {
  const fieldContent = useFieldContent();
  const checked = useChecked();
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement> | string) => {
      if (typeof e === 'string') {
        fieldContent.set(e);
      } else if (
        (e.target.type === 'radio' && e.target.checked && !checked)
        || (e.target.type !== 'radio' && e.target.type !== 'checkbox')
      ) {
        fieldContent.set(e.target.value);
      } else if (e.target.type === 'checkbox') {
        fieldContent.set(e.target.checked);
      }
    },
    [fieldContent, checked],
  );
  return onChange;
};
