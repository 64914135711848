import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { DefaultDomInputFooterContainer } from './footer';
import { DomInputFooterContent } from './content';
import { DomInputFooterHelpContainer } from './helpText';
import { DomInputFooterErrorContainer } from './error';
import { BaseBuilderWithNameTransformAndContainerStyle } from '../../../../utils';

export const inputFooterDomDefaultTopModelsObject = {
  InputFooterContainer: (
    <TopModel name="InputFooterContainer">
      {DefaultDomInputFooterContainer}
    </TopModel>
  ),
  InputFooterContent: (
    <TopModel name="InputFooterContent">{DomInputFooterContent}</TopModel>
  ),
  InputFooterHelpBase: (
    <TopModel name="InputFooterHelpBase" rampName="InputFooterHelp">
      {BaseBuilderWithNameTransformAndContainerStyle}
    </TopModel>
  ),
  InputFooterHelpContainer: (
    <TopModel name="InputFooterHelpContainer">
      {DomInputFooterHelpContainer}
    </TopModel>
  ),
  InputFooterErrorBase: (
    <TopModel name="InputFooterErrorBase" rampName="InputFooterError">
      {BaseBuilderWithNameTransformAndContainerStyle}
    </TopModel>
  ),
  InputFooterErrorContainer: (
    <TopModel name="InputFooterErrorContainer">
      {DomInputFooterErrorContainer}
    </TopModel>
  ),
};
