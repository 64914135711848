import { Trans } from '@lingui/macro';
import ouiDom from '@goldwasserexchange/oui-dom';
import * as React from 'react';
import { useRemark } from 'react-remark';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import ouiStyle from '@goldwasserexchange/oui-style';
import { useCmsArticle } from '../../hooks';
import { remarkOptions } from './options';
import { NotFoundIsErrorContact } from '../../../NotFound';
import { ChartLoader } from '../../../chartLoader';

const InnerCmsArticle = (props: {
  data: string,
}) => {
  const {
    data,
  } = props;
  const [reactContent, setMarkdownSource] = useRemark(remarkOptions);
  React.useEffect(() => {
    setMarkdownSource(data);
  }, [data]);
  return (
    reactContent
  );
};

const NotFoundTitle = () => <Trans>Article introuvable</Trans>;

const NotFound = (props: {
  color?: ouiStyle.Constants.Colors,
}) => {
  const {
    color,
  } = props;
  return (
    <ouiDom.HeadingSection.HeadingSection
      index={0}
      name="ArticleNotFound"
    >
      <TopModel name="HeadingSectionTitleContent-ArticleNotFound">
        {NotFoundTitle}
      </TopModel>
      <NotFoundIsErrorContact
        color={color}
      />
    </ouiDom.HeadingSection.HeadingSection>
  );
};

export const CmsArticle = (props: {
  name: string | null,
  errorColor?: ouiStyle.Constants.Colors,
}) => {
  const {
    name,
    errorColor,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useCmsArticle(name);
  if (error) {
    return <NotFound color={errorColor} />;
  }
  if (isLoading || !data) {
    return (
      <ChartLoader
        widthLines={['1/3', undefined, undefined, '1/12', undefined, undefined]}
        lines={['1/3', undefined, undefined, '1/12', undefined, undefined]}
      />
    );
  }
  return (
    <InnerCmsArticle data={data} />
  );
};
