/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const arrayLabelStyle: ouiStyle.InStyle = {
  color: ouiStyle.Constants.Colors.accent,
  padding: 'simple',
};

const arrayLabelCss = ouiStyle.makeCss(arrayLabelStyle);

export const ArrayLabel = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  return (
    <p
      css={arrayLabelCss}
    >
      {children}
    </p>
  );
};
