import { msg } from '@lingui/macro';

export const ONLINE_SECTIONS_TITLES = {
  details: msg`Mon compte - Détails`,
  history: msg`Mon compte - Historique`,
  timetable: msg`Mon compte - Échéancier`,
  documents: msg`Mon compte - Documents`,
  transfers: msg`Mon compte - Transferts`,
  rates: msg`Mon compte - Tarifs`,
  'not-found': msg`Mon compte`,
};
