import useSWR, { KeyedMutator } from 'swr';
import { useMemo } from 'react';
import memoize from 'memoizee';
import { AccountCashflow } from '../type';
import { Accounts } from '../../enum';
import { useAccountCashflowsStringifiedParams } from './defaultParams';
import { getServiceUrl } from '../../../../shared';
import { ACTOR_DEDUPING_INTERVAL, isIdLoading } from '../../../shared';
import { useFetchWithAuthWithSend } from '../../../shared/utils/fetchWithAuth';
import { makeApiUrl } from '../../../../../../aws';

const useMakeAccountsCashflowsUrl = () => {
  const stringifiedParams = useAccountCashflowsStringifiedParams();
  const url = stringifiedParams !== null ? `${makeApiUrl('actor')}/${getServiceUrl(Accounts.Cashflows)}${stringifiedParams}` : null;
  return url;
};

type BaseUseAccountCashflowApiResponse = {
  isLoading: boolean,
  error?: any,
  mutate: KeyedMutator<AccountCashflow[]>,
  isValidating: boolean,
}

type UseAccountCashflowsApiResponse = {
  data: undefined | AccountCashflow[],
} & BaseUseAccountCashflowApiResponse;

type FilterPredicate = (accountsCashflow: AccountCashflow) => boolean;

type SortPredicate = (prevAccountCashflow: AccountCashflow, nextAccountCashflow: AccountCashflow) => number;

type UseAccountCashflowsApiProps = {
  filterPredicate?: FilterPredicate,
  sortPredicate?: SortPredicate,
};

export const useAccountCashflowsApi = (props: UseAccountCashflowsApiProps): UseAccountCashflowsApiResponse => {
  const {
    filterPredicate,
    sortPredicate,
  } = props;
  const url = useMakeAccountsCashflowsUrl();
  const fetcher = useFetchWithAuthWithSend();
  const result = useSWR<AccountCashflow[], any>(
    url,
    fetcher,
    { dedupingInterval: ACTOR_DEDUPING_INTERVAL },
  );
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    let { data } = result;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      if (filterPredicate) {
        data = data.filter((cashflow) => filterPredicate(cashflow));
      }
    }
    return ({
      ...result,
      data,
      isLoading,
    });
  }, [result, filterPredicate, sortPredicate]);
  return resultWithLoading;
};

type TransformArrayFunction<T> = (accountCashflows: AccountCashflow[] | undefined) => T

type UseAccountCashflowsApiTransformProps<T> = {
  transformFunction: TransformArrayFunction<T>,
} & UseAccountCashflowsApiProps

type UseAccountCashflowApiTransformResponse<T> = {
  data: T | undefined,
} & BaseUseAccountCashflowApiResponse;

export function useAccountCashflowsTransformApi<T>(props: UseAccountCashflowsApiTransformProps<T>): UseAccountCashflowApiTransformResponse<T> {
  const {
    filterPredicate,
    sortPredicate,
    transformFunction,
  } = props;
  const response = useAccountCashflowsApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: (!isLoading && !error) ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

const memoFindByCpsHisId: (data: AccountCashflow[], cpsHisId: string) => AccountCashflow | undefined = memoize((data: AccountCashflow[], cpsHisId: string) => data.find((cashflowLine) => cashflowLine['CPS_HIS.ID'] === parseInt(cpsHisId, 10)));

type UseAccountCashflowApiResponse = {
  data: AccountCashflow | undefined,
} & BaseUseAccountCashflowApiResponse;

export const useAccountCashflowApi = (props: UseAccountCashflowsApiProps): UseAccountCashflowApiResponse => {
  const {
    filterPredicate,
    sortPredicate,
  } = props;
  const url = useMakeAccountsCashflowsUrl();
  const fetcher = useFetchWithAuthWithSend();
  const result = useSWR<AccountCashflow[], any>(
    url,
    fetcher,
    { dedupingInterval: ACTOR_DEDUPING_INTERVAL },
  );
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    // eslint-disable-next-line prefer-destructuring
    let data: AccountCashflow[] | AccountCashflow | undefined = result.data;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      if (filterPredicate) {
        data = data.find((cashflow) => filterPredicate(cashflow));
      }
      if (Array.isArray(data)) {
        // eslint-disable-next-line prefer-destructuring
        data = data[0];
      }
    }
    return ({
      ...result,
      data,
      isLoading,
    });
  }, [result, filterPredicate, sortPredicate]);
  return resultWithLoading;
};

type TransformFunction<T> = (accountCashflow: AccountCashflow | undefined) => T

type UseAccountCashflowApiTransformProps<T> = {
  transformFunction: TransformFunction<T>,
} & UseAccountCashflowsApiProps;

export function useAccountCashflowTransformApi<T>(props: UseAccountCashflowApiTransformProps<T>): UseAccountCashflowApiTransformResponse<T> {
  const {
    filterPredicate,
    sortPredicate,
    transformFunction,
  } = props;
  const response = useAccountCashflowApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: !isLoading && !error ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UseAccountCashflowsApiByCpsHisIdProps = {
  cpsHisId: string,
  sortPredicate?: SortPredicate,
}

type UseAccountCashflowApiTransformByCpsHisIdProps<T> = {
  transformFunction: TransformFunction<T>,
} & UseAccountCashflowsApiByCpsHisIdProps;

export function useAccountCashflowTransformApiByCpsHisId<T>(props: UseAccountCashflowApiTransformByCpsHisIdProps<T>): UseAccountCashflowApiTransformResponse<T> {
  const {
    cpsHisId,
    sortPredicate,
    transformFunction,
  } = props;
  const url = useMakeAccountsCashflowsUrl();
  const cpsHisIdLoading = isIdLoading(cpsHisId);
  const fetcher = useFetchWithAuthWithSend();
  const response = useSWR<AccountCashflow[], any>(
    !cpsHisIdLoading ? url : null,
    fetcher,
    { dedupingInterval: ACTOR_DEDUPING_INTERVAL },
  );
  const result = useMemo(() => {
    let data: AccountCashflow[] | AccountCashflow | undefined = response.data as AccountCashflow[] | undefined;
    const isLoading = !response.data && !response.error;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      data = memoFindByCpsHisId(data, cpsHisId);
    }
    return {
      ...response,
      isLoading,
      data: data ? transformFunction(data) : undefined,
    };
  }, [response, sortPredicate, cpsHisId, transformFunction]);
  return result;
}

type UseAccountCashflowFieldApiResponse<Field extends keyof AccountCashflow> = {
  data: AccountCashflow[Field] | undefined,
} & BaseUseAccountCashflowApiResponse;

type UseAccountCashflowFieldApiProps<Field extends keyof AccountCashflow> = {
  field: Field,
} & UseAccountCashflowsApiProps

export function useAccountCashflowFieldApi<Field extends keyof AccountCashflow>(props: UseAccountCashflowFieldApiProps<Field>): UseAccountCashflowFieldApiResponse<Field> {
  const {
    filterPredicate,
    sortPredicate,
    field,
  } = props;
  const response = useAccountCashflowApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
    } = response;
    return {
      ...response,
      data: data ? data[field] : undefined,
    };
  }, [response, field]);
  return result;
}

type UseAccountCashflowApiFieldByCpsHisIdProps<Field extends keyof AccountCashflow> = {
  field: Field,
} & UseAccountCashflowsApiByCpsHisIdProps;

export function useAccountCashflowFieldApiByCpsHisId<Field extends keyof AccountCashflow>(props: UseAccountCashflowApiFieldByCpsHisIdProps<Field>): UseAccountCashflowFieldApiResponse<Field> {
  const {
    cpsHisId,
    sortPredicate,
    field,
  } = props;
  const url = useMakeAccountsCashflowsUrl();
  const cpsHisIdLoading = isIdLoading(cpsHisId);
  const fetcher = useFetchWithAuthWithSend();
  const response = useSWR<AccountCashflow[], any>(
    !cpsHisIdLoading ? url : null,
    fetcher,
    { dedupingInterval: ACTOR_DEDUPING_INTERVAL },
  );
  const result = useMemo(() => {
    let data: AccountCashflow[] | AccountCashflow | undefined = response.data as AccountCashflow[] | undefined;
    const isLoading = !response.data && !response.error;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      data = memoFindByCpsHisId(data, cpsHisId);
    }
    return {
      ...response,
      isLoading,
      data: data ? data[field] : undefined,
    };
  }, [response, sortPredicate, cpsHisId, field]);
  return result;
}
