import * as React from 'react';
import { Loader } from 'react-feather';
import {
  DeviceTypeIcons,
} from '../utils';
import { Question } from '../../../../../../Components/QuestionIcon';
import { usePersonDevicesType, usePersonDevicesTypeWithTranslation } from '../hooks';
import { ActorApiStringDisplay } from '../../../../../components/display/string';

export const PersonsDevicesType = () => {
  const {
    data,
    isLoading,
    error,
  } = usePersonDevicesTypeWithTranslation();
  return (
    <ActorApiStringDisplay
      data={data}
      isLoading={isLoading}
      error={error}
    />
  );
};

export const PersonsDevicesTypeIcon = () => {
  const {
    data,
    isLoading,
  } = usePersonDevicesType();
  if (isLoading) {
    return (
      <Loader />
    );
  }
  if (data) {
    const Component = DeviceTypeIcons[data];
    return (
      <Component />
    );
  }
  return <Question />;
};
