import useSWR, { KeyedMutator } from 'swr';
import { useMemo } from 'react';
import { GetAccountsPositionsHistoryResponse } from '@goldwasserexchange/actor/rest-services';
import { Accounts } from '../../enum';
import { useFetchWithAuthWithSend } from '../../../shared/utils/fetchWithAuth';
import { getServiceUrl } from '../../../../shared';
import { useAccountPositionsHistoryStringifiedParams } from './defaultParams';
import { ACTOR_DEDUPING_INTERVAL, isIdLoading, memoFindByHisMvtId } from '../../../shared';
import { makeApiUrl } from '../../../../../../aws';

const useMakeAccountsPositionsHistoryUrl = () => {
  const stringifiedParams = useAccountPositionsHistoryStringifiedParams();
  const url = stringifiedParams !== null ? `${makeApiUrl('actor')}/${getServiceUrl(Accounts.PositionsHistory)}${stringifiedParams}` : null;
  return url;
};

type BaseUseAccountPositionsHistoryApiResponse = {
  isLoading: boolean,
  error?: any,
  mutate: KeyedMutator<GetAccountsPositionsHistoryResponse[]>,
  isValidating: boolean,
}

type UseAccountPositionsHistoryLinesApiResponse = {
  data: undefined | GetAccountsPositionsHistoryResponse[],
} & BaseUseAccountPositionsHistoryApiResponse;

type FilterPredicate = (accountsPositionsHistory: GetAccountsPositionsHistoryResponse) => boolean;

type SortPredicate = (prevAccountPosition: GetAccountsPositionsHistoryResponse, nextAccountPosition: GetAccountsPositionsHistoryResponse) => number;

type UseAccountPositionsHistoryLinesApiProps = {
  filterPredicate?: FilterPredicate,
  sortPredicate?: SortPredicate,
};

export const useAccountPositionsHistoryLinesApi = (props: UseAccountPositionsHistoryLinesApiProps): UseAccountPositionsHistoryLinesApiResponse => {
  const {
    filterPredicate,
    sortPredicate,
  } = props;
  const url = useMakeAccountsPositionsHistoryUrl();
  const fetcher = useFetchWithAuthWithSend();
  const result = useSWR<GetAccountsPositionsHistoryResponse[], any>(
    url,
    fetcher,
    { dedupingInterval: ACTOR_DEDUPING_INTERVAL },
  );
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    let { data } = result;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      if (filterPredicate) {
        data = data.filter((position) => filterPredicate(position));
      }
    }
    return ({
      ...result,
      data,
      isLoading,
    });
  }, [result, filterPredicate, sortPredicate]);
  return resultWithLoading;
};

type TransformArrayFunction<T> = (accountPositionHistory: GetAccountsPositionsHistoryResponse[] | undefined) => T

type UseAccountPositionsHistoryLinesApiTransformProps<T> = {
  transformFunction: TransformArrayFunction<T>,
} & UseAccountPositionsHistoryLinesApiProps

type UseAccountPositionsHistoryLinesApiTransformResponse<T> = {
  data: T | undefined,
} & BaseUseAccountPositionsHistoryApiResponse;

export function useAccountPositionsHistoryLinesTransformApi<T>(props: UseAccountPositionsHistoryLinesApiTransformProps<T>): UseAccountPositionsHistoryLinesApiTransformResponse<T> {
  const {
    filterPredicate,
    sortPredicate,
    transformFunction,
  } = props;
  const response = useAccountPositionsHistoryLinesApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: (!isLoading && !error) ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UseAccountPositionHistoryApiResponse = {
  data: GetAccountsPositionsHistoryResponse | undefined,
} & BaseUseAccountPositionsHistoryApiResponse;

export const useAccountPositionHistoryApi = (props: UseAccountPositionsHistoryLinesApiProps): UseAccountPositionHistoryApiResponse => {
  const {
    filterPredicate,
    sortPredicate,
  } = props;
  const url = useMakeAccountsPositionsHistoryUrl();
  const fetcher = useFetchWithAuthWithSend();
  const result = useSWR<GetAccountsPositionsHistoryResponse[], any>(
    url,
    fetcher,
    { dedupingInterval: ACTOR_DEDUPING_INTERVAL },
  );
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    // eslint-disable-next-line prefer-destructuring
    let data: GetAccountsPositionsHistoryResponse[] | GetAccountsPositionsHistoryResponse | undefined = result.data;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      if (filterPredicate) {
        data = data.find((position) => filterPredicate(position));
      }
      if (Array.isArray(data)) {
        // eslint-disable-next-line prefer-destructuring
        data = data[0];
      }
    }
    return ({
      ...result,
      data,
      isLoading,
    });
  }, [result, filterPredicate, sortPredicate]);
  return resultWithLoading;
};

type TransformFunction<T> = (accountPositionHistory: GetAccountsPositionsHistoryResponse | undefined) => T

type UseAccountPositionHistoryApiTransformProps<T> = {
  transformFunction: TransformFunction<T>,
} & UseAccountPositionsHistoryLinesApiProps;

export function useAccountPositionsHistoryTransformApi<T>(props: UseAccountPositionHistoryApiTransformProps<T>): UseAccountPositionsHistoryLinesApiTransformResponse<T> {
  const {
    filterPredicate,
    sortPredicate,
    transformFunction,
  } = props;
  const response = useAccountPositionHistoryApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: !isLoading && !error ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UseAccountPositionHistoryFieldApiResponse<Field extends keyof GetAccountsPositionsHistoryResponse> = {
  data: GetAccountsPositionsHistoryResponse[Field] | undefined,
} & BaseUseAccountPositionsHistoryApiResponse;

type UseAccountPositionHistoryFieldApiProps<Field extends keyof GetAccountsPositionsHistoryResponse> = {
  field: Field,
} & UseAccountPositionsHistoryLinesApiProps

export function useAccountPositionHistoryFieldApi<Field extends keyof GetAccountsPositionsHistoryResponse>(props: UseAccountPositionHistoryFieldApiProps<Field>): UseAccountPositionHistoryFieldApiResponse<Field> {
  const {
    filterPredicate,
    sortPredicate,
    field,
  } = props;
  const response = useAccountPositionHistoryApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
    } = response;
    return {
      ...response,
      data: data ? data[field] : undefined,
    };
  }, [response, field]);
  return result;
}

type UseAccountPositionHistoryApiTransformByHisMvtIdProps<T> = {
  transformFunction: TransformFunction<T>,
  sortPredicate?: SortPredicate,
  hisMvtId: string,
};

export function useAccountPositionsHistoryTransformApiByHisMvtId<T>(props: UseAccountPositionHistoryApiTransformByHisMvtIdProps<T>): UseAccountPositionsHistoryLinesApiTransformResponse<T> {
  const {
    sortPredicate,
    transformFunction,
    hisMvtId,
  } = props;
  const url = useMakeAccountsPositionsHistoryUrl();
  const hisMvtIdLoading = isIdLoading(hisMvtId);
  const fetcher = useFetchWithAuthWithSend();
  const response = useSWR<GetAccountsPositionsHistoryResponse[], any>(
    !hisMvtIdLoading ? url : null,
    fetcher,
    { dedupingInterval: ACTOR_DEDUPING_INTERVAL },
  );
  const result = useMemo(() => {
    const isLoading = !response.data && !response.error;

    let data: GetAccountsPositionsHistoryResponse[] | GetAccountsPositionsHistoryResponse | undefined = response.data as GetAccountsPositionsHistoryResponse[] | undefined;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      data = memoFindByHisMvtId(data, hisMvtId);
    }
    return {
      ...response,
      isLoading,
      data: data ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UseAccountPositionsHistoryFieldByHisMvtIdApiProps<Field extends keyof GetAccountsPositionsHistoryResponse> = {
  field: Field,
  sortPredicate?: SortPredicate,
  hisMvtId: string,
}

export function useAccountPositionsHistoryFieldApiByHisMvtId<Field extends keyof GetAccountsPositionsHistoryResponse>(props: UseAccountPositionsHistoryFieldByHisMvtIdApiProps<Field>): UseAccountPositionHistoryFieldApiResponse<Field> {
  const {
    sortPredicate,
    field,
    hisMvtId,
  } = props;
  const url = useMakeAccountsPositionsHistoryUrl();
  const hisMvtIdLoading = isIdLoading(hisMvtId);
  const fetcher = useFetchWithAuthWithSend();
  const result = useSWR<GetAccountsPositionsHistoryResponse[], any>(
    !hisMvtIdLoading ? url : null,
    fetcher,
    { dedupingInterval: ACTOR_DEDUPING_INTERVAL },
  );
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    // eslint-disable-next-line prefer-destructuring
    let data: GetAccountsPositionsHistoryResponse[] | GetAccountsPositionsHistoryResponse | undefined = result.data;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      data = memoFindByHisMvtId(data, hisMvtId);
    }
    return ({
      ...result,
      data: data ? data[field] : undefined,
      isLoading,
    });
  }, [result, hisMvtId, sortPredicate]);
  return resultWithLoading;
}
