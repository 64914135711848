import { TopModel } from '@goldwasserexchange/react-topmodel';
import { UncastedDataStructure, UncastedOnboarding } from '@goldwasserexchange/oblis-frontend-utils';
import * as Yup from 'yup';
import * as React from 'react';
import { Trans, msg } from '@lingui/macro';
import ouiBase from '@goldwasserexchange/oui';
import { useLingui } from '@lingui/react';
import { CheckboxListInput } from '../../../../../../../OUI-dom/Inputs';
import { booleanValidator, makeAtLeastOneTrue, makeRequired } from '../../../../../../../OUI/Inputs';

const onboardingPath: keyof Pick<UncastedDataStructure, 'onboarding'> = 'onboarding';

const howDidYouHearAboutUsPath: keyof Pick<UncastedOnboarding, 'howDidYouHearAboutUs'> = 'howDidYouHearAboutUs';

const list: (keyof UncastedOnboarding['howDidYouHearAboutUs'])[] = [
  'internetSearch',
  'socialNetworks',
  'companyPublication',
  'press',
  'wordOfMouth',
  'other',
];

const elementFieldPathHook = () => {
  const primaryKey = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  return `${onboardingPath}.${howDidYouHearAboutUsPath}.${primaryKey}`;
};

const checkboxPotentialFieldPaths = list.map((element) => `${onboardingPath}.${howDidYouHearAboutUsPath}.${element}`);

const labels = {
  internetSearch: msg`Recherche internet`,
  socialNetworks: msg`Réseaux sociaux`,
  companyPublication: msg`Publications Goldwasser Exchange`,
  press: msg`Presse`,
  wordOfMouth: msg`Bouche à oreille`,
  other: msg`Autre`,
};

const ElementLabel = () => {
  const primaryKey = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const {
    i18n,
  } = useLingui();
  const label = labels[primaryKey];
  if (!label) {
    return null;
  }
  return <>{i18n._(label)}</>;
};

const labelContent = (
  <TopModel name="CheckboxListInputListElementLabelContent">
    {ElementLabel}
  </TopModel>
);

export const howDidYouHearAboutUsValidator = makeAtLeastOneTrue(Yup.object().shape({
  internetSearch: makeRequired(booleanValidator),
  socialNetworks: makeRequired(booleanValidator),
  companyPublication: makeRequired(booleanValidator),
  press: makeRequired(booleanValidator),
  wordOfMouth: makeRequired(booleanValidator),
  other: makeRequired(booleanValidator),
}));

export const HowDidYouHearAboutUs = () => {
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  return (
    <CheckboxListInput
      fieldPath={valueState.onboarding.howDidYouHearAboutUs.path.join('.')}
      list={list}
      elementFieldPathHook={elementFieldPathHook}
      checkboxPotentialFieldPaths={checkboxPotentialFieldPaths}
      validateFieldPath
    >
      <TopModel name="CheckboxListInputLabelContent">
        <Trans>
          Comment avez-vous entendu parler de nous ?
        </Trans>
      </TopModel>
      {labelContent}
    </CheckboxListInput>
  );
};
