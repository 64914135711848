import { RampWithNameTransform } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
export var TableHeaderRowContent = function (props) {
    var _a = props.noCornerTitle, noTitle = _a === void 0 ? false : _a;
    return (React.createElement(React.Fragment, null,
        !noTitle
            ? React.createElement(RampWithNameTransform, { rampName: "HeaderCornerTitleBase" })
            : null,
        React.createElement(RampWithNameTransform, { rampName: "HeaderTitlesMapperBase" })));
};
