var _a, _b, _c, _d, _e;
import * as React from 'react';
import ouiBase from '@goldwasserexchange/oui';
import { i18n } from '@goldwasserexchange/i18n';
import * as fields from './fields';
import { FinancialSituationNetWorth, FinancialSituationMonthlyExpenses, FinancialSituationCash, FinancialSituationOwner, FinancialSituationMonthlyIncome, } from '../../types';
var financialSituationPath = 'financialSituation';
var netWorthPath = 'netWorth';
var monthlyIncomePath = 'monthlyIncome';
var monthlyExpensesPath = 'monthlyExpenses';
var cashPath = 'cash';
var ownerPath = 'owner';
var labelDataObject = {
    netWorth: {
        label: {
            physic: function () { return i18n._('A combien estimez-vous la valeur nette globale de votre patrimoine (en prenant en compte votre épargne, vos investissements en produits financiers ainsi que l\'immobilier et tout autre type d\'actifs dont vous déduisez d\'éventuels emprunts) ?'); },
            moral: function () { return i18n._('A combien estimez-vous la valeur de vos actifs nets ?'); },
        },
        values: (_a = {},
            _a[FinancialSituationNetWorth.FROM_0_TO_200K] = function () { return i18n._('Entre 0 et 200 000 €'); },
            _a[FinancialSituationNetWorth.FROM_200K_TO_500K] = function () { return i18n._('Entre 200 000 € et 500 000 €'); },
            _a[FinancialSituationNetWorth.FROM_500K_TO_800K] = function () { return i18n._('Entre 500 000 € et 800 000 €'); },
            _a[FinancialSituationNetWorth.FROM_800K_TO_1M] = function () { return i18n._('Entre 800 000 € et 1 000 000 €'); },
            _a[FinancialSituationNetWorth.ABOVE_1M] = function () { return i18n._('Supérieure à 1 000 000 €'); },
            _a),
    },
    monthlyExpenses: {
        label: {
            physic: function () { return i18n._('A combien s\'élèvent vos dépenses mensuelles régulières (logement, alimentation, santé, impôts, enfants, mobilité, communication, loisirs, remboursement de dette…) ?'); },
            moral: function () { return i18n._('A combien s\'élèvent vos charges mensuelles régulières ?'); },
        },
        values: (_b = {},
            _b[FinancialSituationMonthlyExpenses.BETWEEN_1K_AND_2K] = function () { return i18n._('Entre 1 000 et 2 000 €'); },
            _b[FinancialSituationMonthlyExpenses.BETWEEN_2K_AND_5K] = function () { return i18n._('Entre 2 000 € et 5 000 €'); },
            _b[FinancialSituationMonthlyExpenses.BETWEEN_5K_AND_8K] = function () { return i18n._('Entre 5 000 € et 8 000 €'); },
            _b[FinancialSituationMonthlyExpenses.ABOVE_8K] = function () { return i18n._('Plus de 8 000 €'); },
            _b),
    },
    monthlyIncome: {
        label: {
            physic: function () { return i18n._('A combien s\'élèvent vos revenus nets mensuels réguliers (salaires, revenus locatifs, pensions, prestations, revenus d\'activités indépendantes, dividendes) ?'); },
            moral: function () { return i18n._('A combien s\'élève votre chiffre d\'affaires mensuel ?'); },
        },
        values: (_c = {},
            _c[FinancialSituationMonthlyIncome.FROM_0_TO_2K] = function () { return i18n._('Entre 0 et 2 000 €'); },
            _c[FinancialSituationMonthlyIncome.BETWEEN_2K_AND_5K] = function () { return i18n._('Entre 2 000 € et 5 000 €'); },
            _c[FinancialSituationMonthlyIncome.BETWEEN_5K_AND_8K] = function () { return i18n._('Entre 5 000 € et 8 000 €'); },
            _c[FinancialSituationMonthlyIncome.BETWEEN_8K_AND_10K] = function () { return i18n._('Entre 8 000 € et 10 000 €'); },
            _c[FinancialSituationMonthlyIncome.ABOVE_10K] = function () { return i18n._('Plus de 10 000 €'); },
            _c),
    },
    cash: {
        label: {
            defaultLabel: function () { return i18n._('Combien de liquidités avez-vous l\'habitude de conserver sur un compte bancaire ?'); },
        },
        values: (_d = {},
            _d[FinancialSituationCash.LESS_THAN_15K] = function () { return i18n._('Moins de 15 000 €'); },
            _d[FinancialSituationCash.BETWEEN_15K_AND_40K] = function () { return i18n._('Entre 15 000 € et 40 000 €'); },
            _d[FinancialSituationCash.BETWEEN_40K_AND_60K] = function () { return i18n._('Entre 40 000 € et 60 000 €'); },
            _d[FinancialSituationCash.ABOVE_60K] = function () { return i18n._('Plus de 60 000 €'); },
            _d),
    },
    owner: {
        label: {
            physic: function () { return i18n._('Etes-vous propriétaire ou usufruitier de votre logement ?'); },
        },
        values: (_e = {},
            _e[FinancialSituationOwner.NO] = function () { return i18n._('Non'); },
            _e[FinancialSituationOwner.IS_OWNER_WITH_CREDIT] = function () { return i18n._('Oui, mais je n\'ai pas fini de rembourser l\'emprunt hypothécaire'); },
            _e[FinancialSituationOwner.IS_OWNER] = function () { return i18n._('Oui, j\'ai fini de rembourser l\'emprunt hypothécaire'); },
            _e),
    },
};
export var useLabelData = function (question) {
    var labelsData = labelDataObject[question];
    return labelsData;
};
export var useLabel = function (question) {
    var logic = fields.logic.label.map[question];
    var labelType = ouiBase.utils.hookstate.useFormFilterLogic(logic, financialSituationPath);
    var labelData = useLabelData(question);
    var labels = labelData.label;
    return labels[labelType]();
};
export var Label = function (props) {
    var question = props.question;
    var label = useLabel(question);
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return React.createElement(React.Fragment, null, label);
};
export var useValueLabel = function (question, primaryKey) {
    var labelData = useLabelData(question);
    var values = labelData.values;
    return values[primaryKey]();
};
export var ValueLabel = function (props) {
    var question = props.question, primaryKey = props.primaryKey;
    var label = useValueLabel(question, primaryKey);
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return React.createElement(React.Fragment, null, label);
};
