import { makeTAddTypeFilterLogic } from '../../TYPE/logic';
import { TAddType, TNatureLrp } from '../../../../../types';
var idTNatureLrpPath = 'ID_T_NATURE_LRP';
export function makeShowTAddFamillyLinkLogic(props) {
    var _a = props.truthy, truthy = _a === void 0 ? {
        result: 'visible',
    } : _a, _b = props.falsy, falsy = _b === void 0 ? {
        result: 'invisible',
    } : _b, options = props.options;
    return makeTAddTypeFilterLogic({
        is: TAddType.ONLY_REPRESENTATIVE,
        options: options,
        truthy: truthy,
        falsy: makeTAddTypeFilterLogic({
            is: TAddType.TITULAR,
            truthy: {
                name: "<parentName>.".concat(idTNatureLrpPath),
                is: TNatureLrp.REPRESENTANT_LEGAL,
                options: options,
                alternatives: {
                    truthy: truthy,
                    falsy: falsy,
                },
            },
            falsy: makeTAddTypeFilterLogic({
                is: TAddType.PROCURATION,
                options: options,
                truthy: truthy,
                falsy: falsy,
            }),
        }),
    });
}
