import * as React from 'react';
import { Trans } from '@lingui/macro';

export const OrientationQuestionnaireIntro = (): JSX.Element => (
  <Trans>
    Goldwasser Exchange offre à ses clients différents services d'investissement qui
    {' '}
    comportent chacun des caractéristiques propres en termes de protection et d'accompagnement
    {' '}
    du Client. Afin de vous aider à choisir le service qui vous conviendra le mieux, nous vous
    {' '}
    proposons de compléter un questionnaire d'orientation (étape facultative).
  </Trans>
);
