/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import React from 'react';

const defaultUnorderedListContainerStyle: ouiStyle.InStyle = {
  listStyleType: ouiStyle.Constants.ListStyleTypeProps.unordered,
  paddingLeft: 'double',
};

const defaultUnorderedListContainerCss = ouiStyle.makeCss(
  defaultUnorderedListContainerStyle,
);

export function DefaultUnorderedListContainer(
  props: React.PropsWithChildren<{
    style?: React.CSSProperties,
  }>,
) {
  const { style = {}, children } = props;
  return (
    <ul
      css={defaultUnorderedListContainerCss}
      style={style}
    >
      {children}
    </ul>
  );
}

export const defaultUnorderedListWithNoMarkContainerStyle: ouiStyle.InStyle = {
  listStyleType: ouiStyle.Constants.ListStyleTypeProps.none,
};

export const defaultUnorderedListWithNoMarkContainerCss = ouiStyle.makeCss(
  defaultUnorderedListWithNoMarkContainerStyle,
);

export function DefaultUnorderedListWithNoMarkContainer(
  props: React.PropsWithChildren<{
    style?: React.CSSProperties,
  } & ouiStyle.Constants.InPadding>,
) {
  const {
    style = {},
    padding,
    paddingHorizontal,
    paddingVertical,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    childPaddingTop,
    childPaddingRight,
    childPaddingBottom,
    childPaddingLeft,
    iconContainer,
    baselinePadding,
    children,
  } = props;
  const css = React.useMemo(() => ouiStyle.makeCss(({
    ...defaultUnorderedListWithNoMarkContainerStyle,
    padding,
    paddingHorizontal,
    paddingVertical,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    childPaddingTop,
    childPaddingRight,
    childPaddingBottom,
    childPaddingLeft,
    iconContainer,
    baselinePadding,
  })), [
    padding,
    paddingHorizontal,
    paddingVertical,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    childPaddingTop,
    childPaddingRight,
    childPaddingBottom,
    childPaddingLeft,
    iconContainer,
    baselinePadding,
  ]);
  return (
    <ul
      css={css}
      style={style}
    >
      {children}
    </ul>
  );
}
