/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const accountTypeMobileInputContainerStyle: ouiStyle.InStyle = {
  padding: 'double',
};

const accountTypeMobileInputContainerCss = ouiStyle.makeCss(accountTypeMobileInputContainerStyle);

export const AccountTypeMobileInputContainer = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  return (
    <div
      css={accountTypeMobileInputContainerCss}
    >
      {children}
    </div>
  );
};
