import { dataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import ouiBase from '@goldwasserexchange/oui';

const questionnaireStatusAlternatives = {
  notSelected: {
    result: 'unchecked',
  },
  noQuestions: {
    result: '',
  },
  notAnswered: {
    result: 'notValidated',
  },
  success: {
    result: true,
  },
  failure: {
    result: false,
  },
  failureWithRetryAvailable: {
    result: false,
  },
  failureWithRetryAvailableOnRequired: {
    result: false,
  },
  retriedFailureOnRequired: {
    result: false,
  },
  truthy: {
    result: 'success',
  },
  falsy: {
    result: 'failure',
  },

};

export const useQuestionnaireStatusByProductNames = (
  productNames: dataStructure.financialProducts.listType[],
) => {
  const logics = productNames.map((productName) => dataStructure.financialProductsKnowledge.logic.hasKnowledge.make.makeIsQuestionnaireCorrectLogic(productName, questionnaireStatusAlternatives));
  const pathSets = logics.reduce((acc, logic) => {
    const paths = ouiBase.utils.hookstate.extractPathsSetFromLogic(logic);
    return new Set<string>([...acc, ...paths]);
  }, new Set<string>([]));
  const paths = [...pathSets];
  const states = ouiBase.Form.useNamedFieldValuesByPathsWithParentName('', paths);
  const result = logics.map((logic) => ouiBase.utils.hookstate.executeFilterLogic(logic, states));
  return result as ('success' | 'failure' | 'notValidated' | 'unchecked' | '')[];
};
