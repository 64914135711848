import ouiBase from '@goldwasserexchange/oui';
import * as React from 'react';
import {
  OnClickOrContextProvider,
  OnClickInputRefFocusEnhanceContextProvider,
} from '../../../../../../utils';

export function SelectInputListButtonContextProvider(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  const [isOpen, setOpen] = ouiBase.List.useSelectOpenStateHandlers();
  const onClick = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  return (
    <OnClickInputRefFocusEnhanceContextProvider>
      <OnClickOrContextProvider is={isOpen} onClick={onClick}>
        {children}
      </OnClickOrContextProvider>
    </OnClickInputRefFocusEnhanceContextProvider>
  );
}
