/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const lowerAlphaListContainerStyle: ouiStyle.InStyle = {
  listStyleType: ouiStyle.Constants.ListStyleTypeProps.lowerAlpha,
};

const lowerAlphaListContainerCss = ouiStyle.makeCss(lowerAlphaListContainerStyle);

export const LowerAlphaListContainer = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  return (
    <ol
      css={lowerAlphaListContainerCss}
    >
      {children}
    </ol>
  );
};
