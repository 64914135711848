import { SimulationBuyOrSellCostData } from '../type';

export const constantExchangeRate: Record<Exclude<SimulationBuyOrSellCostData['currency'], undefined>, number> = {
  USD: 1.15,
};

export const getExchangeRate = (props: {
  currency?: string,
}): number => {
  const {
    currency = 'EUR',
  } = props;
  return constantExchangeRate[currency] ?? 1;
};
