import React, { useContext } from 'react';
import lmlGet from '@goldwasserexchange/utils/lib/lmlGet';
import isEmpty from 'ramda/es/isEmpty';
import isNil from 'ramda/es/isNil';
import identity from 'ramda/es/identity';
import ouiBase from '@goldwasserexchange/oui';
import { UncastedDataStructure, UncastedPhysicalTAdd } from '@goldwasserexchange/oblis-frontend-utils';
import { useHookstate } from '@hookstate/core';
import { isString } from 'util';
import { Locale } from '@goldwasserexchange/i18n';
import { useAtomValue } from 'jotai';
import { useLingui } from '@lingui/react';
import { ListLengthContext, SelectionListContext } from '../../BaseComponents/List';
import { getIdkZLang } from '../../LmlText';
import { FieldValueTextProps } from './fieldValueText';
import { pathnameUrlLanguageAtom } from '../../../history';

export * from './fieldValueText';

export const FieldValueSnippet = (props: FieldValueTextProps): JSX.Element => {
  const {
    fieldState,
    defaultValue = null,
    transformValueFn = identity,
  } = props;
  const value = useHookstate(fieldState).get();
  const { i18n } = useLingui();
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isNil(value) || (isString(value) ? isEmpty(value) : false) ? defaultValue : transformValueFn(value, i18n)}
    </>
  );
};

export const transformLmlFn = (value, inputList, lmlField, language: Locale) => {
  const idk = getIdkZLang(language);
  const lml = (inputList.edges.find((edge) => `${edge.node.ID}` === value) || { node: { [lmlField]: '' } }).node[lmlField];
  return lmlGet(idk, lml);
};

type LmlFieldValueSnippetProps = FieldValueTextProps & {
  inputList: any,
  lmlField?: string,
}

export const LmlFieldValueSnippet = (props: LmlFieldValueSnippetProps): JSX.Element => {
  const {
    inputList,
    lmlField = 'LML_DESCRIPTION',
    ...rest
  } = props;
  const language = useAtomValue(pathnameUrlLanguageAtom);
  return (
    <FieldValueSnippet {...rest} transformValueFn={(value) => transformLmlFn(value, inputList, lmlField, language)} />
  );
};

const useFormArrayLength = () => {
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const arrayState = useHookstate(valueState.onboarding.users);
  return arrayState.get()?.length ?? 0;
};

export const FormFieldArrayLengthProvider = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  const arrayLength = useFormArrayLength();
  return (
    <ListLengthContext.Provider value={arrayLength}>
      {children}
    </ListLengthContext.Provider>
  );
};

export const usePushFieldArray = (value) => {
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const arrayState = useHookstate(valueState.onboarding.users);
  const userLength = arrayState.get().length;
  const [, setSelected] = useContext(SelectionListContext);
  const valueSetter = () => {
    arrayState.merge([value]);
    setSelected(`${userLength}`)();
  };
  return valueSetter;
};

export const useFilterFieldArray = (filterFn) => {
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const state = useHookstate(valueState.onboarding.users);
  const ids = state.keys.map((key) => `${key}`);
  const value = state.get();
  if (filterFn) {
    return ids.filter((id) => filterFn(value[id]));
  }
  return ids;
};

export type FormArrayFilterFn = (element: unknown) => boolean;

export const useFormArrayWithFilter = (filterFn?: FormArrayFilterFn | ((tAdd: UncastedPhysicalTAdd) => boolean)): string[] => {
  const filteredData = useFilterFieldArray(filterFn);
  return filteredData;
};

export type ParentNameProps = {
  parentName: string,
}
