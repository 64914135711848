import React, { useMemo } from 'react';
import { Trans } from '@lingui/macro';
import {
  makeName,
  dataStructure,
  UncastedDataStructure,
  UncastedPhysicalTAdd,
  PersonType,
  GesEvalType,
  UncastedOnboarding,
} from '@goldwasserexchange/oblis-frontend-utils';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import ouiBase from '@goldwasserexchange/oui';
import { useHookstate } from '@hookstate/core';
import {
  ParentNameProps,
} from '../../Form/utils';
import { UserPostalAddress } from '../PostalAddress';
import { useFieldDisabledByFieldPath } from '../../../OUI/Form';
import { CheckboxListInput } from '../../../OUI-dom/Inputs';
import { isMandatary, isTitular } from '../utils';

const onboardingPath: keyof Pick<UncastedDataStructure, 'onboarding'> = 'onboarding';

const tAcc: keyof Pick<UncastedOnboarding, 'T_ACC'> = 'T_ACC';

const idGesEvalType: keyof Pick<UncastedOnboarding['T_ACC'], 'idGesEvalType'> = 'idGesEvalType';

export const tAddIsTRelMailVisibleLogic: ouiBase.utils.hookstate.FormValueFilterLogic<'visible' | 'invisible'> = {
  name: `${onboardingPath}.${tAcc}.${idGesEvalType}`,
  is: GesEvalType.MAIL,
  alternatives: {
    truthy: {
      result: 'visible',
    },
    falsy: {
      result: 'invisible',
    },
  },
};

const current: keyof Pick<UncastedPhysicalTAdd, 'current'> = 'current';

export const tAddIsTRelMailValidatorLogic: ouiBase.utils.hookstate.FormValueFilterLogic<'requiredBoolean' | 'nilCast'> = {
  ...tAddIsTRelMailVisibleLogic,
  alternatives: {
    truthy: {
      name: `<parentName>.${current}`,
      is: true,
      alternatives: {
        truthy: {
          result: 'requiredBoolean',
        },
        falsy: {
          result: 'nilCast',
        },
      },
    },
    falsy: {
      result: 'nilCast',
    },
  },
};

const label = (
  <TopModel name="CheckboxListInputLabelContent">
    <Trans>Coordonnées de contact</Trans>
  </TopModel>
);

const useTAddIsTRelElementFieldPathHook = () => {
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const primaryKey = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  return valueState.onboarding.users[primaryKey].isTRelMail.path.join('.');
};

const TAddIsTRelElementLabel = () => {
  const primaryKey = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  return <UserPostalAddress userKey={primaryKey} />;
};

const elementLabel = (
  <TopModel name="CheckboxListInputListElementLabelContent">
    {TAddIsTRelElementLabel}
  </TopModel>
);

const isTRelMail: keyof Pick<UncastedPhysicalTAdd, 'isTRelMail'> = 'isTRelMail';

const TAddIsTRelMailDisabled = (props: ParentNameProps) => {
  const { parentName } = props;
  const currentUserIndex = dataStructure.T_ADD.fields.CURRENT.hooks.useCurrentUserIndex();
  const currentUserKey = makeName(parentName, currentUserIndex, isTRelMail);
  const disabled = useFieldDisabledByFieldPath(currentUserKey);
  if (disabled.get() !== true) {
    disabled.set(true);
  }
  return null;
};

export const TAddIsTRelMail = (props: React.PropsWithChildren<ParentNameProps>): JSX.Element | null => {
  const {
    parentName,
  } = props;
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const usersState = useHookstate(valueState.onboarding.users);
  const usersKeys = usersState.keys.map((id) => `${id}`);
  const personTypeState = useHookstate(valueState.onboarding.personType);
  const useFilterFn = (list) => {
    const personType = personTypeState.get();
    const users = usersState;
    const filteredList = useMemo(() => list.filter((x) => (personType !== PersonType.MORAL ? isTitular(users.nested(x).get()) : isMandatary(users.nested(x).get()))), [list, personType]);
    return filteredList;
  };
  const potentialFieldPaths = usersKeys.map((primaryKey) => valueState.onboarding.users[primaryKey].isTRelMail.path.join('.'));
  const firstNamePathArray = valueState.onboarding.users[0].FIRSTNAME.path;
  const firstNameKey = firstNamePathArray[firstNamePathArray.length - 1] as 'FIRSTNAME';
  const name1PathArray = valueState.onboarding.users[0].NAME_1.path;
  const name1Key = name1PathArray[name1PathArray.length - 1] as 'NAME_1';
  return (
    <>
      <TAddIsTRelMailDisabled parentName={parentName} />
      <CheckboxListInput
        fieldPath={valueState.onboarding.users.path.join('.')}
        list={usersKeys}
        elementFieldPathHook={useTAddIsTRelElementFieldPathHook}
        searchKeys={[
          firstNameKey,
          name1Key,
        ]}
        useGetFn={() => () => ''}
        useFilterFn={useFilterFn}
        checkboxPotentialFieldPaths={potentialFieldPaths}
      >
        {label}
        {elementLabel}
      </CheckboxListInput>
    </>
  );
};
