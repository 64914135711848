import { useSpring } from 'react-spring';
import { useMemo } from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import {
  InputVariantsNames,
  useInputCurrentVariantNames,
  InputVariantHooksArray,
} from '../../../OUI/Inputs';

export function useVariantSpring(
  variants: Record<InputVariantsNames, Partial<ouiStyle.Style>>,
  inputVariantHooksArray?: InputVariantHooksArray,
) {
  const variantNames = useInputCurrentVariantNames(inputVariantHooksArray);
  const mergedVariants = useMemo(
    () => variantNames.reduce(
      (acc, variantName) => ({ ...acc, ...variants[variantName] }),
      {} as Partial<ouiStyle.Style>,
    ),
    [variants, variantNames],
  );
  const spring = useSpring(mergedVariants);
  return spring;
}
