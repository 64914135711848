import { MakeAppBarGuardObjectTypeFromMatcher, MakeAppBarGuardTypeFromMatcher } from '../type';
import {
  makefn as makeIsMqUpFn,
} from '../isMqUp';
import {
  fn as isExpandedSectionFn,
} from '../isExpandedSection';

export const name = 'shouldLogoShrink';

export const makefn: MakeAppBarGuardTypeFromMatcher = (matcher) => {
  const isMqUpFn = makeIsMqUpFn(matcher);
  return (context, event) => {
    const isMqUp = isMqUpFn(context, event);
    const isExpandedSection = isExpandedSectionFn(context, event);
    const isScrolledTop = (window ? window.scrollY === 0 : true);
    return !isMqUp || !isExpandedSection || !isScrolledTop;
  };
};

export const makeGuard: MakeAppBarGuardObjectTypeFromMatcher = (matcher) => ({
  [name]: makefn(matcher),
});

export default makeGuard;
