import * as React from 'react';
import { useHookstate } from '@hookstate/core';
import { correctAnswerContext } from './context';

export function FormCorrectAnswerContextProvider(props: React.PropsWithChildren<{ correctAnswers: Record<string, string> }>) {
  const { correctAnswers, children } = props;
  const correctState = useHookstate<Record<string, unknown>>(correctAnswers);
  return (
    <correctAnswerContext.Provider value={correctState}>
      {children}
    </correctAnswerContext.Provider>
  );
}
