import { defaultApiCurrencySorter } from '../../../../../../../Components/Sections/online/utils/currencySorter';
import { AccountAvailableCash } from '../../type';
import { cCurrencyCodeKeyFn } from '../../utils';
import { useAccountAvailableCashLinesTransformApi } from '../api';

const transformFunction = (data: AccountAvailableCash[] | undefined): string[] => (data ? data.map((d) => cCurrencyCodeKeyFn(d)) : []);

export const useAccountAvailableCashList = (props: {
  filterPredicate?: ((data: AccountAvailableCash) => boolean),
}) => {
  const {
    filterPredicate,
  } = props;
  const result = useAccountAvailableCashLinesTransformApi({
    sortPredicate: defaultApiCurrencySorter,
    transformFunction,
    filterPredicate,
  });
  return result;
};
