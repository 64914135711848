import * as React from 'react';
import { useLingui } from '@lingui/react';
import { ActorApiStringDisplay } from '../../../../../../components/display/string';
import { useBondsBondIsRedacted, useBondsBondSAccJrsFreqText } from '../../hooks';

export const BondsBondSAccJrsFreq = (props: {
  withRedactedIcon?: boolean,
}) => {
  const {
    withRedactedIcon,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useBondsBondSAccJrsFreqText();
  const {
    i18n,
  } = useLingui();
  const {
    data: redacted,
    isLoading: redactedIsLoading,
  } = useBondsBondIsRedacted('S_ACC_JRS.FREQ');
  return (
    <ActorApiStringDisplay
      data={data != null ? i18n._(data) : undefined}
      isLoading={isLoading || redactedIsLoading}
      error={error}
      loadingValue="--------"
      isBlocked={redacted}
      withRedactedIcon={withRedactedIcon}
    />
  );
};
