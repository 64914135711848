import { useContext } from 'react';
import { footnotesContext } from './context';

export const useFootnotes = () => {
  const footnotes = useContext(footnotesContext);
  return footnotes;
};

export const useFootnoteIndexByKey = (_key: string) => {
  const footnotes = useFootnotes();
  if (footnotes == null) {
    return null;
  }
  // eslint-disable-next-line no-underscore-dangle
  const index = footnotes.findIndex((note) => note._key === _key);
  if (index < 0) {
    return null;
  }
  return index;
};
