import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import ouiDom from '@goldwasserexchange/oui-dom';
import { Trans } from '@lingui/macro';
import { useAtomValue } from 'jotai';
import { cookieFirstLoadedAtom } from '../../history';

declare global {
  interface Window { CookieFirst: { // eslint-disable-line @typescript-eslint/consistent-type-definitions
    changeLanguage: (language: string) => void,
    openPanel: () => void,
  }, }
}

export const CookiePanelLink = () => {
  const cookieFirstInitialised = useAtomValue(cookieFirstLoadedAtom);
  const onClick = React.useCallback(() => {
    if (cookieFirstInitialised) {
      window.CookieFirst.openPanel();
    }
  }, [
    cookieFirstInitialised,
  ]);
  return (
    <ouiDom.Button.ButtonA
      type="button"
      onClick={onClick}
      color={ouiStyle.Constants.Colors.fadeDarker}
      paddingBottom="none"
      paddingTop={['half', undefined, undefined, 'none', undefined, undefined]}
      fontWeight={ouiStyle.Constants.FontWeightProp.bold}
      textTransform="uppercase"
      textAlign="center"
    >
      <Trans>
        Cookies
      </Trans>
    </ouiDom.Button.ButtonA>
  );
};
