import { BondResponse } from '../../type';
import { useBondsBondFieldTransformFnApi } from '../api';

const transformFn = (value: BondResponse['GES_RAT_VAL_SP.SHORTCUT'] | undefined) => (value != null ? value : 'NR');

export const useBondsBondGesRatValSpShortcut = () => {
  const response = useBondsBondFieldTransformFnApi({
    field: 'GES_RAT_VAL_SP.SHORTCUT',
    transformFn,
  });
  return response;
};
