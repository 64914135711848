var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var _a, _b, _c, _d;
import { AccountType, STypeGrp, } from '../../../types';
export var requiredRecord = (_a = {},
    _a[AccountType.RTO] = (_b = {},
        _b[STypeGrp.ACTIONS] = false,
        _b[STypeGrp.OBLIGATIONS] = false,
        _b[STypeGrp.OBLIGATIONS_SUB] = false,
        _b[STypeGrp.OBLIGATIONS_PERP] = false,
        _b[STypeGrp.FONDS] = false,
        _b[STypeGrp.TRACKERS] = false,
        _b[STypeGrp.TRACKERS_MAT] = false,
        _b),
    _a[AccountType.CONSEIL] = (_c = {},
        _c[STypeGrp.ACTIONS] = false,
        _c[STypeGrp.OBLIGATIONS] = false,
        _c[STypeGrp.OBLIGATIONS_SUB] = false,
        _c[STypeGrp.OBLIGATIONS_PERP] = false,
        _c[STypeGrp.FONDS] = false,
        _c[STypeGrp.TRACKERS] = false,
        _c[STypeGrp.TRACKERS_MAT] = false,
        _c),
    _a[AccountType.GESTION] = (_d = {},
        _d[STypeGrp.ACTIONS] = true,
        _d[STypeGrp.OBLIGATIONS] = true,
        _d[STypeGrp.OBLIGATIONS_SUB] = false,
        _d[STypeGrp.OBLIGATIONS_PERP] = false,
        _d[STypeGrp.FONDS] = true,
        _d[STypeGrp.TRACKERS] = false,
        _d[STypeGrp.TRACKERS_MAT] = false,
        _d),
    _a);
export var getRequiredProductListFromAccountType = function (service) { return Object.entries(requiredRecord[service])
    .filter(function (_a) {
    var _b = __read(_a, 2), _ = _b[0], value = _b[1];
    return value;
})
    .map(function (_a) {
    var _b = __read(_a, 1), key = _b[0];
    return key;
}); };
export var getFailedRequiredProductListFromAccountType = function (service, products) { return getRequiredProductListFromAccountType(service)
    .filter(function (key) {
    var product = products[key];
    var knowledgeQuestionnaires = product.knowledgeQuestionnaires;
    var conseilGestion = knowledgeQuestionnaires.conseilGestion, RTO = knowledgeQuestionnaires.RTO;
    var questionnaire = service === AccountType.RTO ? RTO : conseilGestion;
    var success = questionnaire.success;
    return success === false;
}); };
