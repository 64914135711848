import { History, Update } from 'history';
import { splitPathname } from '../../../utils';
import { bondsRouteDefinition } from './router';
import { getValidBondSection } from './utils';
import { bondsDetailsRouting } from './details';
import { bondsOblisSchoolRouting } from './oblisSchool';

export const bondsRouting = (sourceHistory: History, currentUpdate: Update) => {
  const update = {
    action: currentUpdate.action,
    location: {
      ...currentUpdate.location,
    },
  };
  const { location } = update;
  const {
    pathname,
  } = location;
  const splittedPathname = splitPathname(pathname);
  const accountsSection = splittedPathname.at(2);
  const validBondsSection = getValidBondSection(accountsSection);
  const {
    importFn,
  } = bondsRouteDefinition[validBondsSection];
  if (importFn && typeof importFn === 'function') {
    importFn();
  }
  if (validBondsSection !== 'not-found' && validBondsSection !== splittedPathname.at(2)) {
    update.location.pathname = `/${[...splittedPathname.slice(0, 2), validBondsSection, ...splittedPathname.slice(3)].join('/')}`;
  }
  switch (validBondsSection) {
    case 'details':
      return bondsDetailsRouting(sourceHistory, update);
    case 'oblis-school':
      return bondsOblisSchoolRouting(sourceHistory, update);
    default:
      return update;
  }
};
