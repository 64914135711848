import * as React from 'react';
import { tAccIdContext } from './context';

export const TAccIdContextProvider = (props: React.PropsWithChildren<{
  tAccId: string,
}>) => {
  const {
    tAccId,
    children,
  } = props;
  const tAccIdWithoutLoading = parseInt(tAccId, 10) >= 0 ? tAccId : null;
  return (
    <tAccIdContext.Provider value={tAccIdWithoutLoading}>
      {children}
    </tAccIdContext.Provider>
  );
};
