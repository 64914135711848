import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import {
  DefaultCheckboxListInputElementContextContainer,
  DefaultCheckboxListInputBase,
  CheckboxFieldErrorsContextProvider,
  CheckboxInputContextProvider,
} from '../components';
import { InputCheckboxPotentialFieldPathsProvider } from '../../../context';
import { ElementFieldPathHookProvider } from '../context';
import { ValidateFieldPathProvider } from '../context/validateFieldPath';

export const checkboxListInputDefaultTopModelsObject = {
  InputBase: (
    <TopModel name="InputBase">{DefaultCheckboxListInputBase}</TopModel>
  ),
  InputContextProvider: (
    <TopModel name="InputContextProvider">
      {CheckboxInputContextProvider}
    </TopModel>
  ),
  ElementFieldPathHookContext: (
    <TopModel name="ElementFieldPathHookContext">
      {ElementFieldPathHookProvider}
    </TopModel>
  ),
  InputCheckboxValidateFieldPathProvider: (
    <TopModel name="InputCheckboxValidateFieldPathProvider">
      {ValidateFieldPathProvider}
    </TopModel>
  ),
  InputCheckboxPotentialFieldPathsProvider: (
    <TopModel name="InputCheckboxPotentialFieldPathsProvider">
      {InputCheckboxPotentialFieldPathsProvider}
    </TopModel>
  ),
  FieldErrorsContextProvider: (
    <TopModel name="FieldErrorsContextProvider">
      {CheckboxFieldErrorsContextProvider}
    </TopModel>
  ),
  InputListElementContextContainer: (
    <TopModel name="ElementContextContainer">
      {DefaultCheckboxListInputElementContextContainer}
    </TopModel>
  ),
};
