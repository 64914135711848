import * as React from 'react';
import ouiBase from '@goldwasserexchange/oui';
import ouiDom from '@goldwasserexchange/oui-dom';
import ouiStyle from '@goldwasserexchange/oui-style';
import { Locale } from '@goldwasserexchange/i18n';
import { useLinguiLocale } from '../useLinguiLocale';

export const MailLink = (props: React.PropsWithChildren<{ mail: string } & Omit<ouiBase.A.AnchorProps, 'href'>>) => {
  const {
    mail,
    children,
    ...rest
  } = props;
  return (
    <ouiDom.A.A
      href={`mailto:${mail}`}
      whiteSpace={ouiStyle.Constants.WhiteSpaceProperty.noWrap}
      {...rest}
    >
      {children || mail}
    </ouiDom.A.A>
  );
};

MailLink.displayName = 'MailLink';

const infoMail = 'info@goldwasserexchange.be';

export const InfoMailLink = (props: React.PropsWithChildren<Omit<ouiBase.A.AnchorProps, 'href'>>) => {
  const {
    children,
    ...rest
  } = props;
  return (
    <MailLink mail={infoMail} {...rest}>
      {children}
    </MailLink>
  );
};

const securitiesTransferEmails = {
  [Locale.FR]: 'titres@goldwasserexchange.be',
  [Locale.NL]: 'effecten@goldwasserexchange.be',
};

export const SecuritiesTransferMailLink = (props: React.PropsWithChildren<Omit<ouiBase.A.AnchorProps, 'href'>>) => {
  const {
    children,
    ...rest
  } = props;
  const locale = useLinguiLocale();
  return (
    <MailLink mail={securitiesTransferEmails[locale] ?? securitiesTransferEmails[Locale.FR]} {...rest}>
      {children}
    </MailLink>
  );
};

InfoMailLink.displayName = 'InfoMailLink';
