import * as events from '../../../events';
import { makeSpringService } from '../utils';

export type AppBarOpeningServiceMeta = {
  src: {
    type: 'OpeningService',
  },
};

export const name = 'opening';

export const makeService = makeSpringService(name, 'closed', 0, false, events.openingFinishedEvent.eventBuilder);

export default makeService;
