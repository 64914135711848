import * as Yup from 'yup';
import { F, T } from 'ramda';

import { booleanError, booleanIsFalseError, booleanIsTrueError } from '../errornames';

export const booleanValidator = Yup
  .boolean().transform((value, originalValue) => (originalValue === '' ? undefined : value)).typeError(booleanError);

export const makeIsTrue = (validator) => validator.oneOf([true], booleanIsTrueError);

export const makeIsFalse = (validator) => validator.oneOf([false], booleanIsFalseError);

export const makeFalseCast = (validator) => validator.transform(F);

export const makeTrueCast = (validator) => validator.transform(T);
