import { isNil, isEmpty } from 'ramda';
import { useFieldContent } from '../hooks';

export function useHasContent() {
  const fieldContent = useFieldContent();
  const value = fieldContent.get();
  const hasContent = !isNil(value) && !isEmpty(value);
  return hasContent;
}

export type ContentVariantsNames = 'hasContent' | 'noContent';

export function useHasContentVariant(): ContentVariantsNames {
  const hasContent = useHasContent();
  return hasContent ? 'hasContent' : 'noContent';
}
