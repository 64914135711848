import { UncastedDataStructure, OrientationDecisionType } from '@goldwasserexchange/oblis-frontend-utils';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { Trans } from '@lingui/macro';
import * as React from 'react';
import ouiBase from '@goldwasserexchange/oui';
import { RadioListInput } from '../../../../../../OUI-dom/Inputs';
import { stringValidator, makeRequired } from '../../../../../../OUI/Inputs';
import { GetComponentKeyByElementPrimaryKey } from '../../utils/ElementLabel';

export const decisionTypeValidator = makeRequired(stringValidator);

const decisionData: Record<OrientationDecisionType, { ID: OrientationDecisionType, value: JSX.Element }> = {
  [OrientationDecisionType.AUTONOMOUS]: {
    ID: OrientationDecisionType.AUTONOMOUS,
    value: <Trans>Je compte prendre mes décisions d'investissement de façon totalement autonome</Trans>,
  },
  [OrientationDecisionType.RECOMMENDATION]: {
    ID: OrientationDecisionType.RECOMMENDATION,
    value: <Trans>Je souhaiterais bénéficier de recommandations d'investissement et d'un certain accompagnement pour la sélection de mes investissements</Trans>,
  },
  [OrientationDecisionType.DELEGATE]: {
    ID: OrientationDecisionType.DELEGATE,
    value: <Trans>Je souhaiterais déléguer cette tâche à un professionnel de la finance</Trans>,
  },
};

const decisionList = Object.keys(decisionData);

const decisionLabel = (
  <TopModel name="RadioListInputLabelContent">
    <Trans>Comment projetez-vous de prendre vos décisions d'investissements ?</Trans>
  </TopModel>
);

const decisionElementLabel = (
  <TopModel name="RadioListInputListElementLabelContent" data={decisionData} valueKey="value">
    {GetComponentKeyByElementPrimaryKey}
  </TopModel>
);

export const DecisionType = (): JSX.Element => {
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  return (
    <RadioListInput
      fieldPath={valueState.onboarding.orientation.decisionType.path.join('.')}
      dependenciesFieldPaths={[valueState.onboarding.orientation.submitted.path.join('.')]}
      list={decisionList}
    >
      {decisionLabel}
      {decisionElementLabel}
    </RadioListInput>
  );
};
