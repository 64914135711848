import * as React from 'react';
import { useBondsBondIsRedacted, useBondsBondSQuoteArchiveDate } from '../../hooks';
import { ActorApiDateDisplay } from '../../../../../../components/display/date';

export const BondsBondSQuoteArchiveDate = (props: {
  withRedactedIcon?: boolean,
}) => {
  const {
    withRedactedIcon,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useBondsBondSQuoteArchiveDate();
  const {
    data: redacted,
    isLoading: redactedIsLoading,
  } = useBondsBondIsRedacted('S_QUOTE.ARCHIVE_DATE');
  return (
    <ActorApiDateDisplay
      data={data}
      isLoading={isLoading || redactedIsLoading}
      error={error}
      formatMq
      isBlocked={redacted}
      withRedactedIcon={withRedactedIcon}
    />
  );
};
