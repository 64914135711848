import { TNatureLrp } from '../../../../types';
export var list = [
    TNatureLrp.SOCIETE_SIMPLE,
    TNatureLrp.TITULAIRE,
    TNatureLrp.USUFRUITIER,
    TNatureLrp.NU_PROPRIETAIRE,
    TNatureLrp.INDIVISAIRE,
    TNatureLrp.TITULAIRE_INCAPABLE,
    TNatureLrp.REPRESENTANT_LEGAL,
];
export var physicList = [
    TNatureLrp.TITULAIRE,
    TNatureLrp.USUFRUITIER,
    TNatureLrp.NU_PROPRIETAIRE,
    TNatureLrp.INDIVISAIRE,
    TNatureLrp.TITULAIRE_INCAPABLE,
    TNatureLrp.REPRESENTANT_LEGAL,
];
export var usufruitierNuProprietaireList = [
    TNatureLrp.USUFRUITIER,
    TNatureLrp.NU_PROPRIETAIRE,
];
