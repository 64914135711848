import * as React from 'react';
import ouiDom from '@goldwasserexchange/oui-dom';
import { ErrorBoundary } from '../../../ErrorBoundary';

export const SanityEmbedded = (props: {
  value?: any,
}) => {
  const {
    value,
  } = props;
  const {
    title,
    link,
    description,
  } = value ?? {};
  return (
    <ErrorBoundary
      renderNull
    >
      <ouiDom.Embedded.Embedded
        title={title}
        link={link}
        description={description}
      />
    </ErrorBoundary>
  );
};
