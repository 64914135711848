import * as React from 'react';
import { useBondSameIssuerSAccOblEndDate, useBondsSameIssuerIsRedacted } from '../../hooks';
import { ActorApiDateDisplay } from '../../../../../../components/display/date';

export const BondSameIssuersSAccOblEndDate = (props: React.PropsWithChildren<{
  format?: string | undefined,
  withRedactedIcon?: boolean,
}>) => {
  const {
    format,
    withRedactedIcon,
    children = '∞',
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useBondSameIssuerSAccOblEndDate();
  const {
    data: redacted,
    isLoading: redactedIsLoading,
  } = useBondsSameIssuerIsRedacted('S_ACC.OBL_END_DATE');
  return data === '∞' && !redactedIsLoading
    ? (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {children}
      </>
    )
    : (
      <ActorApiDateDisplay
        data={data}
        isLoading={isLoading || redactedIsLoading}
        error={error}
        format={format}
        formatMq={false}
        isBlocked={redacted}
        withRedactedIcon={withRedactedIcon}
      />
    );
};
