import * as React from 'react';
import { RampWithNameTransform } from '@goldwasserexchange/react-topmodel';
export function DefaultListBase(props) {
    var type = props.type, list = props.list, length = props.length;
    return (React.createElement(RampWithNameTransform, { rampName: "ContextContainer", type: type, list: list, length: length },
        React.createElement(RampWithNameTransform, { rampName: "Container" },
            React.createElement(RampWithNameTransform, { rampName: "Mapper" }))));
}
export function DefaultVirtualizedListBase(props) {
    var type = props.type, list = props.list, _a = props.elementLines, elementLines = _a === void 0 ? 1 : _a, _b = props.elementWidthLines, elementWidthLines = _b === void 0 ? 1 : _b, _c = props.elementChildPaddingTop, elementChildPaddingTop = _c === void 0 ? 'none' : _c, _d = props.elementChildPaddingRight, elementChildPaddingRight = _d === void 0 ? 'none' : _d, _e = props.elementChildPaddingBottom, elementChildPaddingBottom = _e === void 0 ? 'none' : _e, _f = props.elementChildPaddingLeft, elementChildPaddingLeft = _f === void 0 ? 'none' : _f, length = props.length, showedItems = props.showedItems;
    return (React.createElement(RampWithNameTransform, { rampName: "ContextContainer", type: type, list: list, length: length, showedItems: showedItems, elementLines: elementLines, elementWidthLines: elementWidthLines, elementChildPaddingTop: elementChildPaddingTop, elementChildPaddingRight: elementChildPaddingRight, elementChildPaddingBottom: elementChildPaddingBottom, elementChildPaddingLeft: elementChildPaddingLeft },
        React.createElement(RampWithNameTransform, { rampName: "Mapper" })));
}
