import { useFilterByPrimaryDimensionArDocId } from '../../utils';
import { useAccountDocumentFieldApi } from '../api';

export const useAccountDocumentsArDocDocDate = () => {
  const filterPredicate = useFilterByPrimaryDimensionArDocId();
  const result = useAccountDocumentFieldApi({
    filterPredicate,
    field: 'AR_DOC.DOC_DATE',
  });
  return result;
};
