import { GetBondsSameIssuerResponse } from '@goldwasserexchange/actor/rest-services';
import ouiBase from '@goldwasserexchange/oui';
import { useCallback } from 'react';
import { BondSameIssuerResponse } from '../../type';
import {
  useBondSameIssuerFieldTransformApiBySAccId,
  useBondSameIssuerTransformApi,
} from '../api';

const getRedacted = (securityBondSameIssuer: BondSameIssuerResponse | undefined) => {
  if (!securityBondSameIssuer) {
    return [];
  }
  return securityBondSameIssuer.redacted;
};

export const useBondsSameIssuerRedacted = () => {
  const response = useBondSameIssuerTransformApi({
    transformFunction: getRedacted,
  });
  return response;
};

export const useBondsSameIssuerIsRedacted = (field: keyof GetBondsSameIssuerResponse) => {
  const transformFn = useCallback((redacted: BondSameIssuerResponse['redacted'] | undefined) => {
    if (!redacted) {
      return false;
    }
    return redacted.includes(field);
  }, [field]);
  const sAccId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const response = useBondSameIssuerFieldTransformApiBySAccId({
    sAccId,
    field: 'redacted',
    transformFn,
  });
  return response;
};
