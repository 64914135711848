import { setupI18n } from '@lingui/core';
import * as full from './full';

export enum Locale {
  FR = 'fr',
  NL = 'nl'
}

export const i18n = setupI18n();

export function activateLocale(locale: Locale): void {
  i18n.load(locale, full[locale].messages);
  i18n.activate(locale);
}
