import ouiBase from '@goldwasserexchange/oui';
import { spRatingWithDefault } from '../../../../shared';
import { useBondSameIssuerFieldTransformApiBySAccId } from '../api';

export const useBondSameIssuerGesRatValSpShortcut = () => {
  const sAccId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const response = useBondSameIssuerFieldTransformApiBySAccId({
    sAccId,
    field: 'GES_RAT_VAL_SP.SHORTCUT',
    transformFn: spRatingWithDefault,
  });
  return response;
};
