var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import ouiBase from '@goldwasserexchange/oui';
var onboardingPath = 'onboarding';
var selectedService = 'selectedService';
export function makeAccountTypeLogic(props) {
    var is = props.is, alternatives = props.alternatives, options = props.options;
    return {
        name: "".concat(onboardingPath, ".").concat(selectedService),
        is: is,
        options: __assign(__assign({}, options), { matcher: ouiBase.utils.match.arrayMatcherNames.some }),
        alternatives: alternatives,
    };
}
