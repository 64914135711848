export const newsRoutesDefinition = {
  home: {
    importFn: () => import('../../../../Components/Sections/news/components/sections/home'),
  },
  article: {
    importFn: () => import('../../../../Components/Sections/news/components/sections/article'),
  },
  search: {
    importFn: () => import('../../../../Components/Sections/news/components/sections/search'),
  },
};
