/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { OnboardingSave } from '../../../../../../utils/dynamoDb/onboarding/type';
import { OnboardingStateDescription } from '../../../onboardingStateDescription';
import { MultipleOnboardingMessage } from './components';

export const OnboardingStatusBannerContent = (props: {
  saves: OnboardingSave[],
  openInNewWindow?: boolean,
}) => {
  const {
    saves,
    openInNewWindow,
  } = props;
  if (saves.length === 1) {
    return (
      <OnboardingStateDescription
        onboardingSave={saves[0]}
      />
    );
  }
  return (
    <MultipleOnboardingMessage
      openInNewWindow={openInNewWindow}
    />
  );
};
