import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { useSetCurrentModalValue } from '../../../../../../../../Components/Modal';
import Accounts, { useAccountHistoryArDocModalName } from '../../../../../accounts';
import { FileIconModalButtonContainer } from './container';
import { ArDocFileIcon } from '../../fileIcon';

export const AccountHistoryFileIconModalButton = (props: {
  hasFileName?: boolean,
  size?: string | number | undefined,
  transform?: ouiStyle.InStyle['transform'],
}) => {
  const {
    hasFileName,
    transform,
    size,
  } = props;
  const {
    data,
  } = useAccountHistoryArDocModalName();
  const onClick = useSetCurrentModalValue(data ?? null, !hasFileName);
  return (
    <FileIconModalButtonContainer
      onClick={onClick}
      hasFileName={hasFileName}
      transform={transform}
    >
      <ArDocFileIcon
        service={Accounts.History}
        size={size}
      />
    </FileIconModalButtonContainer>
  );
};
