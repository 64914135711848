import * as React from 'react';
import { ActorApiStringDisplay } from '../../../../../../components/display/string';
import { useBondsSameIssuerCCurrencyCode, useBondsSameIssuerIsRedacted } from '../../hooks';

export const BondsSameIssuersCCurrencyCode = (props: {
  withRedactedIcon?: boolean,
}) => {
  const {
    withRedactedIcon,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useBondsSameIssuerCCurrencyCode();
  const {
    data: redacted,
    isLoading: redactedIsLoading,
  } = useBondsSameIssuerIsRedacted('C_CURRENCY.CODE');
  return (
    <ActorApiStringDisplay
      data={data}
      isLoading={isLoading || redactedIsLoading}
      error={error}
      loadingValue="---"
      isBlocked={redacted}
      withRedactedIcon={withRedactedIcon}
    />
  );
};
