var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import ouiBase from '@goldwasserexchange/oui';
import { makeName } from '../../../../makeName';
import { TAddType } from '../../../../types';
var tAddTypePath = 'T_ADD_type';
export function makeTAddTypeFilterLogic(props) {
    var is = props.is, _a = props.truthy, truthy = _a === void 0 ? {
        result: 'visible',
    } : _a, _b = props.falsy, falsy = _b === void 0 ? {
        result: 'invisible',
    } : _b, _c = props.options, options = _c === void 0 ? {} : _c, _d = props.parentNameAdditionalPaths, parentNameAdditionalPaths = _d === void 0 ? [] : _d;
    var parentName = makeName.apply(void 0, __spreadArray(['<parentName>'], __read(parentNameAdditionalPaths), false));
    return ({
        name: "".concat(parentName, ".").concat(tAddTypePath),
        is: is,
        options: __assign(__assign({}, options), { matcher: ouiBase.utils.match.arrayMatcherNames.some }),
        alternatives: {
            truthy: truthy,
            falsy: falsy,
        },
    });
}
export function makeTAddIsNotMoralOrIgnored(props) {
    var truthy = props.truthy, falsy = props.falsy, _a = props.options, options = _a === void 0 ? {
        isNegative: false,
    } : _a, parentNameAdditionalPaths = props.parentNameAdditionalPaths;
    return makeTAddTypeFilterLogic({
        is: [TAddType.MORAL, TAddType.IGNORED],
        truthy: truthy,
        falsy: falsy,
        options: __assign(__assign({}, options), { matcher: ouiBase.utils.match.arrayMatcherNames.some, isNegative: !options.isNegative }),
        parentNameAdditionalPaths: parentNameAdditionalPaths,
    });
}
export function makeTAddTypeLogic(props) {
    var is = props.is, alternatives = props.alternatives, options = props.options, _a = props.parentNameAdditionalPaths, parentNameAdditionalPaths = _a === void 0 ? [] : _a;
    return {
        name: makeName.apply(void 0, __spreadArray(__spreadArray(['<parentName>'], __read(parentNameAdditionalPaths), false), [tAddTypePath], false)),
        is: is,
        options: options,
        alternatives: alternatives,
    };
}
export var isMoralPersonLogic = {
    name: "<parentName>.".concat(tAddTypePath),
    is: TAddType.MORAL,
    alternatives: {
        truthy: {
            result: 'moral',
        },
        falsy: {
            result: 'physic',
        },
    },
};
export function makeTAddIsTypePhysicLogic(props) {
    var alternatives = props.alternatives, _a = props.options, options = _a === void 0 ? {} : _a, _b = props.exclude, exclude = _b === void 0 ? [] : _b;
    return makeTAddTypeLogic({
        is: [TAddType.TITULAR, TAddType.PROCURATION, TAddType.ONLY_REPRESENTATIVE].filter(function (el) { return !exclude.includes(el); }),
        alternatives: alternatives,
        options: __assign(__assign({}, options), { matcher: ouiBase.utils.match.arrayMatcherNames.some }),
    });
}
