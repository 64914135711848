/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
var rippleContainerStyle = {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: ouiStyle.Constants.Colors.transparent,
};
var rippleContainerCss = ouiStyle.makeCss(rippleContainerStyle);
export var DomRippleEffectContainer = function (props) {
    var onClick = props.onClick, children = props.children;
    return (jsx("div", { css: ouiStyle.mediaQuery(rippleContainerCss), onClick: onClick }, children));
};
