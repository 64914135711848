import memoizee from 'memoizee';
import { times } from 'ramda';
import { BondList } from '../../type';
import { PAGE_SIZE, useBondsListTransformApi } from '../api';

const getLength = (bondList: BondList[] | undefined) => {
  if (!bondList) {
    return 0;
  }
  const {
    TOTAL_ROWS,
  } = bondList[0] ?? { TOTAL_ROWS: 0 };
  return TOTAL_ROWS;
};

export const useBondListLength = () => {
  const response = useBondsListTransformApi({
    transformFunction: getLength,
  });
  return response;
};

const getPageQuantity = (bondList: BondList[] | undefined) => {
  const length = getLength(bondList);
  return Math.ceil(length / PAGE_SIZE);
};

export const useBondListPageQuantity = () => {
  const response = useBondsListTransformApi({
    transformFunction: getPageQuantity,
  });
  return response;
};

const makePages = (pageQuantity: number) => times((i) => i, pageQuantity);

export const memoizedMakePages = memoizee(makePages);

const getPages = (bondList: BondList[] | undefined) => {
  const pageQuantity = getPageQuantity(bondList);
  return memoizedMakePages(pageQuantity);
};

export const useBondListPages = () => {
  const response = useBondsListTransformApi({
    transformFunction: getPages,
  });
  return response;
};
