import { Placement } from '@popperjs/core';
import * as React from 'react';
import { popperArrowContext } from './arrow';
import { popperContainerContext } from './container';
import { useMemoisedPopper } from './hooks';
import { PopperOpenWithInteractionContext } from './open';
import { usePopperOpen } from './open/hooks';
import { PopperReferenceContextProvider } from './reference/components';

export const MemoisedPopperContextProvider = (
  props: React.PropsWithChildren<{
    placement?: Placement,
    fallbackPlacements?: Placement[],
  }>,
) => {
  const {
    placement = 'bottom', fallbackPlacements = ['top'], children,
  } = props;
  const [popperProps, arrowProps] = useMemoisedPopper(
    placement,
    fallbackPlacements,
  );
  return (
    <popperContainerContext.Provider value={popperProps}>
      <popperArrowContext.Provider value={arrowProps}>
        {children}
      </popperArrowContext.Provider>
    </popperContainerContext.Provider>
  );
};

export const PopperInReferenceContextProvider = (
  props: React.PropsWithChildren<{
    placement?: Placement,
    fallbackPlacements?: Placement[],
  }>,
) => {
  const {
    placement = 'bottom', fallbackPlacements = ['top'], children,
  } = props;
  const open = usePopperOpen();
  return open ? (
    <MemoisedPopperContextProvider placement={placement} fallbackPlacements={fallbackPlacements}>
      {children}
    </MemoisedPopperContextProvider>
  ) : null;
};

export const PopperReferenceWithInteractionContext = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  return (
    <PopperReferenceContextProvider>
      <PopperOpenWithInteractionContext>
        {children}
      </PopperOpenWithInteractionContext>
    </PopperReferenceContextProvider>
  );
};
