import * as React from 'react';
import { RampWithNameTransform } from '@goldwasserexchange/react-topmodel';
import ouiBase from '@goldwasserexchange/oui';
import ouiStyle from '@goldwasserexchange/oui-style';
import {
  DefaultInputContextProviderProps,
  DefaultInputContextProvider,
  InputRefContextProvider,
  InputContainerRefContextProvider,
} from '../../../context';
import {
  useInputListPrefixer, TemporaryNameTransformRamp, GetFnSetContextProvider, UseGetFnProps,
} from '../../shared';
import { OpenOnSearchFocusProvider } from './open';
import { SearchKeysSetContext } from '../../shared/components/list/context/searchKeys';
import { SearchKeysProps } from '../../shared/components/list/context/searchKeys/context';
import { SelectInputAutoSelectProvider } from './autoSelect';

export function DefaultSelectListInputContextProvider(
  props: {
    useAutoSelect?: (autoSelect: boolean) => void,
    autoSelect?: boolean,
    elementLines: ouiStyle.InStyle['lines'],
    elementChildPaddingTop?: ouiStyle.InStyle['childPaddingTop'],
    elementChildPaddingRight?: ouiStyle.InStyle['childPaddingRight'],
    elementChildPaddingBottom?: ouiStyle.InStyle['childPaddingBottom'],
    elementChildPaddingLeft?: ouiStyle.InStyle['childPaddingLeft'],
    type: Extract<ouiBase.Input.InputTypes, 'radio'>,
  } & SearchKeysProps & UseGetFnProps & DefaultInputContextProviderProps &
  ouiBase.List.ListProps,
) {
  const {
    type,
    fieldPath,
    useGetFn,
    dependenciesFieldPaths,
    list,
    length,
    elementLines,
    elementChildPaddingTop = 'simple',
    elementChildPaddingRight = 'simple',
    elementChildPaddingBottom = 'simple',
    elementChildPaddingLeft = 'simple',
    searchKeys,
    useFilterFn,
    useSortFn,
    useAutoSelect,
    autoSelect,
    children,
  } = props;
  const inputListPrefixer = useInputListPrefixer();
  return (
    <DefaultInputContextProvider
      type={type}
      fieldPath={fieldPath}
      dependenciesFieldPaths={dependenciesFieldPaths}
    >
      <GetFnSetContextProvider useGetFn={useGetFn}>
        <SearchKeysSetContext searchKeys={searchKeys}>
          <ouiBase.List.OpenStateProvider>
            <InputContainerRefContextProvider>
              <InputRefContextProvider>
                <OpenOnSearchFocusProvider>
                  <ouiBase.utils.dimensions.PrimaryDimensionListContextProvider primaryDimensionList={list}>
                    <ouiBase.List.ListDataFilterAndSortProvider
                      useFilterFn={useFilterFn}
                      useSortFn={useSortFn}
                    >
                      <SelectInputAutoSelectProvider
                        useAutoSelect={useAutoSelect}
                        autoSelect={autoSelect}
                      >
                        <TemporaryNameTransformRamp
                          transformFn={inputListPrefixer}
                          rampName="ContextContainer"
                          length={length}
                        >
                          <RampWithNameTransform<{
                            elementLines?: ouiStyle.InStyle['lines'],
                          }>
                            rampName="InputElementLinesContext"
                            elementLines={elementLines}
                          >
                            <RampWithNameTransform<ouiBase.List.ListElementChildPaddingProviderProps>
                              rampName="InputElementChildPaddingsContext"
                              elementChildPaddingTop={elementChildPaddingTop}
                              elementChildPaddingRight={elementChildPaddingRight}
                              elementChildPaddingBottom={elementChildPaddingBottom}
                              elementChildPaddingLeft={elementChildPaddingLeft}
                            >
                              <ouiBase.List.CollapsibleShowedItemsContextProvider showedItems={7}>
                                {children}
                              </ouiBase.List.CollapsibleShowedItemsContextProvider>
                            </RampWithNameTransform>
                          </RampWithNameTransform>
                        </TemporaryNameTransformRamp>
                      </SelectInputAutoSelectProvider>
                    </ouiBase.List.ListDataFilterAndSortProvider>
                  </ouiBase.utils.dimensions.PrimaryDimensionListContextProvider>
                </OpenOnSearchFocusProvider>
              </InputRefContextProvider>
            </InputContainerRefContextProvider>
          </ouiBase.List.OpenStateProvider>
        </SearchKeysSetContext>
      </GetFnSetContextProvider>
    </DefaultInputContextProvider>
  );
}
