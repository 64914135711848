import * as React from 'react';
import { GetAccountsTwrrParams } from '@goldwasserexchange/actor/rest-services';
import { useAccountTwrrDefaultParams } from '../hooks';
import { accountsTwrrParamsContext } from '../context';

export const AccountsTwrrsParamsProvider = (props: React.PropsWithChildren<{
  params: Partial<GetAccountsTwrrParams>,
}>) => {
  const {
    params,
    children,
  } = props;
  const value = useAccountTwrrDefaultParams(params);
  return (
    <accountsTwrrParamsContext.Provider
      value={value}
    >
      {children}
    </accountsTwrrParamsContext.Provider>
  );
};
