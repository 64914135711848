/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import { animated } from 'react-spring';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as animationController from '../../animation';
import * as machine from '../../machine';
import { useZIndex } from '../../../../OUI/Form';
import { useOuiStyleMediaContextByIndex } from '../../../MediaContext';

const menuButtonContainerStyle: ouiStyle.InStyle = {
  position: 'absolute',
  right: 0,
  lines: 1,
  paddingVertical: 'double',
  paddingHorizontal: 'half',
  iconContainer: true,
  backgroundColor: ouiStyle.Constants.Colors.logoBlue,
  color: ouiStyle.Constants.Colors.inverted,
  borderStyle: 'none',
  borderWidth: 'none',
  outline: 'none',
  borderRadius: 'none',
  backgroundImage: 'none',
  textAlign: 'center',
  paddingRight: 1.5,
};

const useMenuButtonContainerCss = () => {
  const zIndex = useZIndex();
  const css = React.useMemo(() => ouiStyle.makeCss({
    ...menuButtonContainerStyle,
    zIndex,
  }), [zIndex]);
  return css;
};

export const MenuButtonContainer = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  const sendOpen = machine.useOpen();
  const closed = animationController.useAnimationClosedSpringValue();
  const css = useMenuButtonContainerCss();
  const isUp = useOuiStyleMediaContextByIndex(5);
  return (
    <animated.button
      css={css}
      style={{
        transform: !isUp ? closed.to((v) => `translateX(${100 - (v * 100)}%)`) : 'translateX(100%)',
      }}
      onClick={sendOpen}
    >
      {children}
    </animated.button>
  );
};

export default MenuButtonContainer;
