import { makeTAddTypeFilterLogic } from '../../TYPE/logic';
import { makeMoralSocialFormFilter } from '../../ID_C_TIT/logic/helpers';
import { moralList } from '../../ID_C_TIT';
import { TAddType } from '../../../../../types';
export function makeIdTAccAyDrShowLogic(props) {
    var truthy = props.truthy, falsy = props.falsy, notMoral = props.notMandataryOrUBO;
    return makeTAddTypeFilterLogic({
        is: [TAddType.MANDATARY, TAddType.UBO],
        truthy: makeMoralSocialFormFilter({ is: moralList, truthy: truthy, falsy: falsy }),
        falsy: notMoral,
    });
}
