import * as React from 'react';
import { GetAccountsPositionsHistoryResponse } from '@goldwasserexchange/actor/rest-services';
import { ActorApiMapper } from '../../../../../../components';
import { useAccountsPositionsHistoryList } from '../../hooks';
import { useMakeLoadingIds } from '../../../../shared';

export const AccountsPositionsHistoryMapper = (props: React.PropsWithChildren<{
  filterPredicate?: (data: GetAccountsPositionsHistoryResponse) => boolean,
  limit?: number,
}>) => {
  const {
    filterPredicate,
    limit,
    children,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useAccountsPositionsHistoryList({ filterPredicate });
  const loadingList = useMakeLoadingIds(limit ?? 0);
  return (
    <ActorApiMapper
      data={data}
      isLoading={isLoading}
      error={error}
      loadingList={loadingList}
      limit={limit}
    >
      {children}
    </ActorApiMapper>
  );
};
