import useSWR, { KeyedMutator } from 'swr';
import { useMemo } from 'react';
import { AccountHistory } from '../type';
import { Accounts } from '../../enum';
import { getServiceUrl } from '../../../../shared';
import { useAccountHistoryStringifiedParams } from './defaultParams';
import { ACTOR_DEDUPING_INTERVAL, isIdLoading } from '../../../shared';
import { memoFindByHisMvtId } from '../../../shared/utils/hisMvt/findById';
import { useFetchWithAuthWithSend } from '../../../shared/utils/fetchWithAuth';
import { makeApiUrl } from '../../../../../../aws';

const useMakeAccountsHistoryUrl = () => {
  const stringifiedParams = useAccountHistoryStringifiedParams();
  const url = stringifiedParams !== null ? `${makeApiUrl('actor')}/${getServiceUrl(Accounts.History)}${stringifiedParams}` : null;
  return url;
};

type BaseUseAccountHistoryApiResponse = {
  isLoading: boolean,
  error?: any,
  mutate: KeyedMutator<AccountHistory[]>,
  isValidating: boolean,
}

type UseAccountHistoryLinesApiResponse = {
  data: undefined | AccountHistory[],
} & BaseUseAccountHistoryApiResponse;

type FilterPredicate = (accountsHistory: AccountHistory) => boolean;

type SortPredicate = (prevAccountHistory: AccountHistory, nextAccountHistory: AccountHistory) => number;

type UseAccountHistoryLinesApiProps = {
  filterPredicate?: FilterPredicate,
  sortPredicate?: SortPredicate,
};

export const useAccountHistoryLinesApi = (props: UseAccountHistoryLinesApiProps): UseAccountHistoryLinesApiResponse => {
  const {
    filterPredicate,
    sortPredicate,
  } = props;
  const url = useMakeAccountsHistoryUrl();
  const fetcher = useFetchWithAuthWithSend();
  const result = useSWR<AccountHistory[], any>(
    url,
    fetcher,
    { dedupingInterval: ACTOR_DEDUPING_INTERVAL },
  );
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    let { data } = result;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      if (filterPredicate) {
        data = data.filter((history) => filterPredicate(history));
      }
    }
    return ({
      ...result,
      data,
      isLoading,
    });
  }, [result, filterPredicate, sortPredicate]);
  return resultWithLoading;
};

type TransformArrayFunction<T> = (accountHistoryLines: AccountHistory[] | undefined) => T

type UseAccountHistoryLinesApiTransformProps<T> = {
  transformFunction: TransformArrayFunction<T>,
} & UseAccountHistoryLinesApiProps

type UseAccountHistoryApiTransformResponse<T> = {
  data: T | undefined,
} & BaseUseAccountHistoryApiResponse;

export function useAccountHistoryLinesTransformApi<T>(props: UseAccountHistoryLinesApiTransformProps<T>): UseAccountHistoryApiTransformResponse<T> {
  const {
    filterPredicate,
    sortPredicate,
    transformFunction,
  } = props;
  const response = useAccountHistoryLinesApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: (!isLoading && !error) ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UseAccountHistoryApiResponse = {
  data: AccountHistory | undefined,
} & BaseUseAccountHistoryApiResponse;

export const useAccountHistoryApi = (props: UseAccountHistoryLinesApiProps): UseAccountHistoryApiResponse => {
  const {
    filterPredicate,
    sortPredicate,
  } = props;
  const url = useMakeAccountsHistoryUrl();
  const fetcher = useFetchWithAuthWithSend();
  const result = useSWR<AccountHistory[], any>(
    url,
    fetcher,
    { dedupingInterval: ACTOR_DEDUPING_INTERVAL },
  );
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    // eslint-disable-next-line prefer-destructuring
    let data: AccountHistory[] | AccountHistory | undefined = result.data;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      if (filterPredicate) {
        data = data.find((history) => filterPredicate(history));
      }
      if (Array.isArray(data)) {
        // eslint-disable-next-line prefer-destructuring
        data = data[0];
      }
    }
    return ({
      ...result,
      data,
      isLoading,
    });
  }, [result, filterPredicate, sortPredicate]);
  return resultWithLoading;
};

type TransformFunction<T> = (accountHistory: AccountHistory | undefined) => T

type UseAccountHistoryApiTransformProps<T> = {
  transformFunction: TransformFunction<T>,
} & UseAccountHistoryLinesApiProps;

export function useAccountHistoryTransformApi<T>(props: UseAccountHistoryApiTransformProps<T>): UseAccountHistoryApiTransformResponse<T> {
  const {
    filterPredicate,
    sortPredicate,
    transformFunction,
  } = props;
  const response = useAccountHistoryApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: !isLoading && !error ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UseAccountHistoryApiTransformByHisMvtIdProps<T> = {
  transformFunction: TransformFunction<T>,
  sortPredicate?: SortPredicate,
  hisMvtId: string,
};

export function useAccountHistoryTransformApiByHisMvtId<T>(props: UseAccountHistoryApiTransformByHisMvtIdProps<T>): UseAccountHistoryApiTransformResponse<T> {
  const {
    sortPredicate,
    transformFunction,
    hisMvtId,
  } = props;
  const url = useMakeAccountsHistoryUrl();
  const hisMvtIdLoading = isIdLoading(hisMvtId);
  const fetcher = useFetchWithAuthWithSend();
  const response = useSWR<AccountHistory[], any>(
    !hisMvtIdLoading ? url : null,
    fetcher,
    { dedupingInterval: ACTOR_DEDUPING_INTERVAL },
  );
  const result = useMemo(() => {
    const isLoading = !response.data && !response.error;

    let data: AccountHistory[] | AccountHistory | undefined = response.data as AccountHistory[] | undefined;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      data = memoFindByHisMvtId(data, hisMvtId);
    }
    return {
      ...response,
      isLoading,
      data: data ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UseAccountHistoryFieldApiResponse<Field extends keyof AccountHistory> = {
  data: AccountHistory[Field] | undefined,
} & BaseUseAccountHistoryApiResponse;

type UseAccountHistoryFieldApiProps<Field extends keyof AccountHistory> = {
  field: Field,
} & UseAccountHistoryLinesApiProps

export function useAccountHistoryFieldApi<Field extends keyof AccountHistory>(props: UseAccountHistoryFieldApiProps<Field>): UseAccountHistoryFieldApiResponse<Field> {
  const {
    filterPredicate,
    sortPredicate,
    field,
  } = props;
  const response = useAccountHistoryApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
    } = response;
    return {
      ...response,
      data: data ? data[field] : undefined,
    };
  }, [response, field]);
  return result;
}

type UseAccountHistoryFieldByHisMvtIdApiProps<Field extends keyof AccountHistory> = {
  field: Field,
  sortPredicate?: SortPredicate,
  hisMvtId: string,
}

export function useAccountHistoryFieldApiByHisMvtId<Field extends keyof AccountHistory>(props: UseAccountHistoryFieldByHisMvtIdApiProps<Field>): UseAccountHistoryFieldApiResponse<Field> {
  const {
    sortPredicate,
    field,
    hisMvtId,
  } = props;
  const url = useMakeAccountsHistoryUrl();
  const hisMvtIdLoading = isIdLoading(hisMvtId);
  const fetcher = useFetchWithAuthWithSend();
  const result = useSWR<AccountHistory[], any>(
    !hisMvtIdLoading ? url : null,
    fetcher,
    { dedupingInterval: ACTOR_DEDUPING_INTERVAL },
  );
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    // eslint-disable-next-line prefer-destructuring
    let data: AccountHistory[] | AccountHistory | undefined = result.data;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      data = memoFindByHisMvtId(data, hisMvtId);
    }
    return ({
      ...result,
      data: data ? data[field] : undefined,
      isLoading,
    });
  }, [result, hisMvtId, sortPredicate]);
  return resultWithLoading;
}
