import { Trans } from '@lingui/macro';
import ouiDom from '@goldwasserexchange/oui-dom';
import { dataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import { fromUnixTime, isValid, parseISO } from 'date-fns';
import * as React from 'react';
import { useAtomValue } from 'jotai';
import { OnboardingSave } from '../../../../utils/dynamoDb/onboarding/type';
import { DateDisplay } from '../../../../../Components/DateDisplay';
import { Space } from '../../../../../Components/Space';
import { ValidSections, pathnameUrlLanguageAtom } from '../../../../../history';

export const OnboardingStateDescription = (props: {
  onboardingSave: OnboardingSave,
}) => {
  const {
    onboardingSave,
  } = props;
  const {
    sk,
    status,
    data,
    casted,
  } = onboardingSave;
  const language = useAtomValue(pathnameUrlLanguageAtom);
  const unixTimestamp = parseInt(sk.split('#')[1], 10);
  const skDate = Number.isNaN(unixTimestamp) ? undefined : fromUnixTime(unixTimestamp);
  if (status === 'PENDING') {
    if (skDate == null) {
      return null;
    }
    return (
      <Trans>
        Votre
        <Space />
        <ouiDom.A.A
          to={`/${language}/${ValidSections.ONBOARDING}/form`}
        >
          demande d'ouverture de compte
          {data.selectedService != null ? (
            <>
              <Space />
              <dataStructure.accountType.labels.Label accountType={data.selectedService} />
            </>
          ) : null}
        </ouiDom.A.A>
        <Space />
        commencée le
        <Space />
        <DateDisplay date={skDate} format="dd/MM/yyyy" />
        <Space />
        à
        <Space />
        <DateDisplay date={skDate} format="H:mm" />
        <Space />
        doit encore être complétée.
      </Trans>
    );
  }
  if (casted == null) {
    return null;
  }
  const {
    submittedDate,
    selectedService,
  } = casted;
  let parsedDate: Date | undefined = parseISO(submittedDate);
  if (!isValid(parsedDate)) {
    parsedDate = skDate;
  }
  if (parsedDate == null) {
    return null;
  }
  const date = (
    <DateDisplay
      date={parsedDate}
      format="dd/MM/yyyy"
    />
  );
  const time = (
    <DateDisplay
      date={parsedDate}
      format="H:mm"
    />
  );
  const selectedServiceLabel = (
    <dataStructure.accountType.labels.Label accountType={selectedService} />
  );
  if (status === 'SUBMITTED') {
    return (
      <Trans>
        Votre demande d'ouverture de compte
        <Space />
        {selectedServiceLabel}
        <Space />
        du
        <Space />
        {date}
        <Space />
        à
        <Space />
        {time}
        <Space />
        est toujours en attente de signature.
      </Trans>
    );
  }
  if (status === 'SIGNED' || status === 'VALIDATED') {
    return (
      <Trans>
        Votre demande d'ouverture de compte
        <Space />
        {selectedServiceLabel}
        <Space />
        du
        <Space />
        {date}
        <Space />
        à
        <Space />
        {time}
        <Space />
        est en cours de vérification.
      </Trans>
    );
  }
  return null;
};
