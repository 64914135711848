import * as React from 'react';
import ouiDom from '@goldwasserexchange/oui-dom';
import { Trans } from '@lingui/macro';
import { useAtomValue } from 'jotai';
import { ValidSections, pathnameUrlLanguageAtom } from '../../../../../../history';
import { Space } from '../../../../../Space';
import { CenteredButtonContainer } from '../../../../../CenteredButtonContainer';

export const SignUpLink = () => {
  const language = useAtomValue(pathnameUrlLanguageAtom);
  const toSignup = `/${language}/${ValidSections.ONBOARDING}`;
  const toActivate = `/${language}/${ValidSections.SIGN_UP}`;
  return (
    <>
      <CenteredButtonContainer
        paddingVertical="half"
      >
        <Trans>
          Pas encore client ?
          <Space />
          <ouiDom.A.A
            to={toSignup}
            paddingVertical="none"
          >
            Ouvrez un compte
          </ouiDom.A.A>
        </Trans>
      </CenteredButtonContainer>
      <CenteredButtonContainer
        paddingVertical="half"
      >
        <Trans>
          Déjà client ?
          <Space />
          <ouiDom.A.A
            to={toActivate}
            paddingVertical="none"
          >
            Activez votre accès en ligne
          </ouiDom.A.A>
        </Trans>
      </CenteredButtonContainer>
    </>
  );
};
