import * as React from 'react';
import {
  useBondsBondIsRedacted, useBondsBondSAccOblEndDate, useBondsBondSAccOblEndDateAndDifference, useBondsBondSAccOblEndDateDifference,
} from '../../hooks';
import { ActorApiDateDisplay } from '../../../../../../components/display/date';
import { ActorApiStringDisplay } from '../../../../../../components/display/string';

export const BondsBondSAccOblEndDate = (props: {
  withRedactedIcon?: boolean,
}) => {
  const {
    withRedactedIcon,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useBondsBondSAccOblEndDate();
  const {
    data: redacted = false,
    isLoading: redactedIsLoading,
  } = useBondsBondIsRedacted('S_ACC.OBL_END_DATE');
  return data === '∞' && !redacted
    ? (
      <>
        ∞
      </>
    )
    : (
      <ActorApiDateDisplay
        data={data}
        isLoading={isLoading || redactedIsLoading}
        error={error}
        formatMq={false}
        isBlocked={redacted}
        withRedactedIcon={withRedactedIcon}
      />
    );
};

export const BondsBondSAccOblEndDateDifference = (props: {
  withRedactedIcon?: boolean,
  withParentheses?: boolean,
}) => {
  const {
    withRedactedIcon,
    withParentheses,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useBondsBondSAccOblEndDateDifference({
    withParentheses,
  });
  const {
    data: redacted = false,
    isLoading: redactedIsLoading,
  } = useBondsBondIsRedacted('S_ACC.ISSUE_DATE');
  return !data && !isLoading && !redacted
    ? null
    : (
      <ActorApiStringDisplay
        data={data}
        isLoading={isLoading || redactedIsLoading}
        error={error}
        isBlocked={redacted}
        withRedactedIcon={withRedactedIcon}
      />
    );
};

export const BondsBondSAccOblEndDateAndDifference = (props: {
  mediaQueryIndex: number,
  withRedactedIcon?: boolean,
}) => {
  const {
    mediaQueryIndex,
    withRedactedIcon,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useBondsBondSAccOblEndDateAndDifference(mediaQueryIndex);
  const {
    data: redacted = false,
    isLoading: redactedIsLoading,
  } = useBondsBondIsRedacted('S_ACC.ISSUE_DATE');
  return !data && !isLoading && !redacted
    ? null
    : (
      <ActorApiStringDisplay
        data={data}
        isLoading={isLoading || redactedIsLoading}
        error={error}
        isBlocked={redacted}
        withRedactedIcon={withRedactedIcon}
      />
    );
};
