import { QueryCommand } from '@aws-sdk/lib-dynamodb';
import useSWR from 'swr';
import { useCognitoUserValidAccounts } from '../../../../Components/Sections/online/selectedAccount';
import { getSub } from '../../auth';
import { getDynamoDbDocumentClient } from '../getDynamoDbClient';

const getFavoriteBonds = async (props: [tableName: string, skPrefix: string, _accountNotEmpty: boolean]) => {
  const [tableName, skPrefix] = props;
  const sub = await getSub();
  if (!sub) {
    return [];
  }
  const { client } = await getDynamoDbDocumentClient();
  const results = await client.send(new QueryCommand({
    TableName: tableName,
    KeyConditionExpression: 'pk = :pk AND begins_with(sk, :sk)',
    ExpressionAttributeValues: {
      ':pk': `u#${sub}`,
      ':sk': `${skPrefix}#`,
    },
  }));
  client.destroy();
  return results.Items?.map((item) => item.sk.split('#')[1]).filter<string>((id): id is string => id != null) ?? [];
};

export const useFavoriteBonds = (tableName: string, run = true) => {
  const accounts = useCognitoUserValidAccounts();
  const response = useSWR(run ? [tableName, 'favorite', accounts.length > 0] : null, getFavoriteBonds);
  return response;
};
