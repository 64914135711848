import { questionnaireDependenciesByStatus } from './data';
import { financialProductsLabels } from '../../../financialProducts/fields/labels';
export var getFinancialProductDependenciesByStatus = function (financialProduct) { return questionnaireDependenciesByStatus[financialProduct] || []; };
export var useProductSelectionSort = function (list) { return list.sort(function (prev, next) {
    if ((getFinancialProductDependenciesByStatus(prev) || []).includes(next)) {
        return 1;
    }
    if ((getFinancialProductDependenciesByStatus(next) || []).includes(prev)) {
        return -1;
    }
    var prevLabel = financialProductsLabels[prev].label();
    var nextLabel = financialProductsLabels[next].label();
    return prevLabel.localeCompare(nextLabel);
}); };
