import * as React from 'react';
import { RampWithNameTransform } from '@goldwasserexchange/react-topmodel';
import { msg } from '@lingui/macro';
import ouiBase from '@goldwasserexchange/oui';
import { useLingui } from '@lingui/react';
import { useSearchValueMethods } from '../../../../context';
import { OnChangeSetContextProvider } from '../../../../../../../utils';

export function DefaultSelectInputListSearchInputContext() {
  const searchTermHookState = useSearchValueMethods();
  const {
    i18n,
  } = useLingui();
  const isOpen = ouiBase.List.useSelectIsOpen();
  const onChange = React.useCallback(
    // eslint-disable-next-line consistent-return
    (e) => {
      if (isOpen) {
        return searchTermHookState && searchTermHookState.set(e.target.value);
      }
    },
    [searchTermHookState, isOpen],
  );
  const placeholder = isOpen !== true ? i18n._(msg`Sélection`) : i18n._(msg`Rechercher`);
  return (
    <OnChangeSetContextProvider onChange={onChange}>
      <RampWithNameTransform
        rampName="SearchInputElement"
        placeholder={placeholder}
      />
    </OnChangeSetContextProvider>
  );
}
