import { RampWithNameTransform } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import { DefaultDomVirtualizedListContainerPlaceHolder } from '../../../../../List';

export function DefaultDomSelectInputListContainerPlaceHolder(props: React.PropsWithChildren<{
  style: React.CSSProperties,
  onScroll: ((event: React.UIEvent<HTMLDivElement, UIEvent>) => void),
}>) {
  const {
    style,
    children,
    onScroll,
  } = props;
  return (
    <DefaultDomVirtualizedListContainerPlaceHolder
      style={style}
      onScroll={onScroll}
    >
      <RampWithNameTransform
        rampName="ElementSelectedBase"
      />
      {children}
    </DefaultDomVirtualizedListContainerPlaceHolder>
  );
}
