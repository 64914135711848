import { GetBondsCouponFormulasResponse } from '@goldwasserexchange/actor/rest-services';
import { BondsCall } from '../calls/type';

const getEvtEntrySId = (line: BondsCall | GetBondsCouponFormulasResponse) => `${line['EVT_ENTRY_S.ID']}`;

export const mapEvtEntrySId = (lines: BondsCall[] | GetBondsCouponFormulasResponse[] | undefined) => {
  if (!lines) {
    return undefined;
  }
  return lines.map((line: BondsCall | GetBondsCouponFormulasResponse) => getEvtEntrySId(line));
};
