import { PersonAccounts } from '../../type';
import { usePersonAccountsTransformApi } from '../api';

const estimGloIntTotal = (lines: PersonAccounts[] | undefined) => (
  lines
    ? lines.reduce((acc, line) => acc + line['T_ACC.ESTIM_GLO_INT'], 0)
    : 0
);

export const usePersonAccountsTAccEstimGloIntTotal = () => {
  const result = usePersonAccountsTransformApi({
    transformFunction: estimGloIntTotal,
  });
  return result;
};
