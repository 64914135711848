import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import ouiDom from '@goldwasserexchange/oui-dom';
import { Trans } from '@lingui/macro';
import { useAtomValue } from 'jotai';
import { ValidSections, pathnameUrlLanguageAtom } from '../../../history';

export const OnboardingButton = () => {
  const language = useAtomValue(pathnameUrlLanguageAtom);
  return (
    <ouiDom.A.AButton
      to={`/${language}/${ValidSections.ONBOARDING}`}
      fontSize="m"
      fontWeight={ouiStyle.Constants.FontWeightProp.bold}
      color={ouiStyle.Constants.Colors.inverted}
      backgroundColor={ouiStyle.Constants.Colors.accent}
      hoverBackgroundColor={ouiStyle.Constants.Colors.accentShaded}
      borderColor={ouiStyle.Constants.Colors.transparent}
      marginHorizontal="auto"
    >
      <Trans>
        Devenir client
      </Trans>
    </ouiDom.A.AButton>
  );
};
