/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
export function DomIllustrationContainer(props) {
    var position = props.position, backgroundColor = props.backgroundColor, lines = props.lines, widthLines = props.widthLines, textAlign = props.textAlign, padding = props.padding, paddingHorizontal = props.paddingHorizontal, paddingVertical = props.paddingVertical, paddingTop = props.paddingTop, paddingRight = props.paddingRight, paddingBottom = props.paddingBottom, paddingLeft = props.paddingLeft, childPaddingTop = props.childPaddingTop, childPaddingRight = props.childPaddingRight, childPaddingBottom = props.childPaddingBottom, childPaddingLeft = props.childPaddingLeft, iconContainer = props.iconContainer, baselinePadding = props.baselinePadding, display = props.display, flex = props.flex, justifyContent = props.justifyContent, alignItems = props.alignItems, overflow = props.overflow, overflowX = props.overflowX, overflowY = props.overflowY, children = props.children;
    var css = React.useMemo(function () { return ouiStyle.makeCss({
        position: position,
        backgroundColor: backgroundColor,
        lines: lines,
        widthLines: widthLines,
        textAlign: textAlign,
        padding: padding,
        paddingHorizontal: paddingHorizontal,
        paddingVertical: paddingVertical,
        paddingTop: paddingTop,
        paddingRight: paddingRight,
        paddingBottom: paddingBottom,
        paddingLeft: paddingLeft,
        childPaddingTop: childPaddingTop,
        childPaddingRight: childPaddingRight,
        childPaddingBottom: childPaddingBottom,
        childPaddingLeft: childPaddingLeft,
        iconContainer: iconContainer,
        baselinePadding: baselinePadding,
        display: display,
        flex: flex,
        justifyContent: justifyContent,
        alignItems: alignItems,
        overflow: overflow,
        overflowX: overflowX,
        overflowY: overflowY,
    }); }, [
        position,
        backgroundColor,
        lines,
        widthLines,
        textAlign,
        padding,
        paddingHorizontal,
        paddingVertical,
        paddingTop,
        paddingRight,
        paddingBottom,
        paddingLeft,
        childPaddingTop,
        childPaddingRight,
        childPaddingBottom,
        childPaddingLeft,
        iconContainer,
        baselinePadding,
        display,
        flex,
        justifyContent,
        alignItems,
        overflow,
        overflowX,
        overflowY,
    ]);
    return (jsx("figure", { css: ouiStyle.mediaQuery(css) }, children));
}
