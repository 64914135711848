import ouiBase from '@goldwasserexchange/oui';
import { useCallback } from 'react';
import { AccountHistory } from '../../type';

export const filterByHisMvtId = (hisMvtHid: AccountHistory['HIS_MVT.ID']) => (historyLine: AccountHistory) => historyLine['HIS_MVT.ID'] === hisMvtHid;

export const useFilterByHisMvtId = (hisMvtId: AccountHistory['HIS_MVT.ID']) => {
  const filterFn = useCallback(filterByHisMvtId(hisMvtId), [hisMvtId]);
  return filterFn;
};

export const useFilterByPrimaryDimensionHisMvtId = () => {
  const hisMvtHid = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const filterFn = useFilterByHisMvtId(hisMvtHid);
  return filterFn;
};
