/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { useInputContainerRef } from '../../../../../OUI/Inputs';
import { defaultDomInputContainerStyle } from '../../shared';

const defaultDomTextInputContainerStyle: ouiStyle.InStyle = {
  ...defaultDomInputContainerStyle,
  lines: 5,
};

const defaultDomTextInputContainerCss = ouiStyle.makeCss(defaultDomTextInputContainerStyle);

export function DefaultDomTextInputContainer(props: React.PropsWithChildren<{}>) {
  const { children } = props;
  const inputContainerRef = useInputContainerRef();
  return (
    <section ref={inputContainerRef} css={defaultDomTextInputContainerCss}>
      {children}
    </section>
  );
}
