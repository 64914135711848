import { useFilterByPrimaryDimensionTAccTwrrPId } from '../../utils';
import { useAccountTwrrFieldApi } from '../api';

export const useAccountTwrrCCurrencyCode = () => {
  const filterByPrimaryDimensionGesEstimDId = useFilterByPrimaryDimensionTAccTwrrPId();
  const acountPositionsCCurrencyCodeApiResponse = useAccountTwrrFieldApi({
    field: 'C_CURRENCY.CODE',
    filterPredicate: filterByPrimaryDimensionGesEstimDId,
  });
  return acountPositionsCCurrencyCodeApiResponse;
};
