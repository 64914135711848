import * as React from 'react';
import { useHookstate } from '@hookstate/core';
import { searchValueContext } from './context';

export function SearchValueProvider(props: React.PropsWithChildren<{}>) {
  const { children } = props;
  const searchValue = useHookstate('');
  return (
    <searchValueContext.Provider value={searchValue}>
      {children}
    </searchValueContext.Provider>
  );
}
