import * as React from 'react';
import { ActorApiNumberDisplay } from '../../../../../../components/display/number/component';
import { useBondSameIssuerSAccIntRate, useBondsSameIssuerIsRedacted } from '../../hooks';

export const BondSameIssuersSAccIntRate = (props: {
  withRedactedIcon?: boolean,
}) => {
  const {
    withRedactedIcon,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useBondSameIssuerSAccIntRate();
  const {
    data: redacted,
    isLoading: redactedIsLoading,
  } = useBondsSameIssuerIsRedacted('S_ACC.INT_RATE');
  return (
    <ActorApiNumberDisplay
      data={data}
      isLoading={isLoading || redactedIsLoading}
      error={error}
      format={{
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 3,
      }}
      loadingValue={3.625}
      isBlocked={redacted}
      withRedactedIcon={withRedactedIcon}
    />
  );
};
