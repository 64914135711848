const styleFormatting: Record<Exclude<NonNullable<Intl.NumberFormatOptions['style']>, 'unit'>, Intl.NumberFormatOptions> = {
  currency: {
    style: 'currency',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currencyDisplay: 'code',
    currency: 'EUR',
  },
  percent: {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
  decimal: {
    style: undefined,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
};

export const getFormat = (format: Partial<Intl.NumberFormatOptions>): Intl.NumberFormatOptions => {
  const {
    style = 'decimal',
    ...rest
  } = format;
  return {
    ...styleFormatting[style],
    ...rest,
  };
};
