import { useCallback } from 'react';
import { transformAskOrMidPriceFn } from '../../../shared';
import { BondResponse } from '../../type';
import { makeGrossBondCalculator } from '../../utils/makeGrossBondCalculator';
import { useBondsBondTransformApi } from '../api';
import { useNumberFormatter } from '../../../../../../../Components/NumberDisplay';
import { TOB_PERCENTAGE } from '../../utils';

const makeTransformAmountFor = (props: {
  nominal: number,
  tob?: boolean,
  formatNumber: (value: number, formatOptions?: Partial<Intl.NumberFormatOptions>) => string,
}) => (bondsBond: BondResponse | undefined) => {
  const {
    nominal,
    tob = false,
    formatNumber,
  } = props;
  if (bondsBond == null) {
    return undefined;
  }
  const price = transformAskOrMidPriceFn(bondsBond);
  if (price == null) {
    return undefined;
  }
  const calculator = makeGrossBondCalculator(bondsBond);
  const {
    accrued,
  } = calculator;
  const value = (nominal * ((price / 100) + accrued)) * (1 + (tob ? (TOB_PERCENTAGE / 100) : 0));
  const formattedValue = formatNumber(value, {
    currency: bondsBond['C_CURRENCY.CODE'],
  });
  return formattedValue;
};

const format: Parameters<typeof useNumberFormatter>[0] = {
  format: {
    style: 'currency',
  },
};

export const useAmountFor = (nominal: number, tob?: boolean) => {
  const formatNumber = useNumberFormatter(format);
  const transformGrossAmountFor10K = useCallback(makeTransformAmountFor({ nominal, tob, formatNumber }), [nominal, tob]);
  const response = useBondsBondTransformApi(transformGrossAmountFor10K);
  return response;
};
