import * as React from 'react';
import {
  PassThrough,
  Ramp, TopModel, TopModelChildrenMerge, TopModelMerge,
} from '@goldwasserexchange/react-topmodel';
import { isNil } from 'ramda';

const sliceLimit = (limit: number | undefined) => (arr: string[] | undefined | null) => {
  if (!isNil(limit)) {
    return (Array.isArray(arr) ? arr.slice(0, limit) : []);
  }
  return (Array.isArray(arr) ? arr : []);
};

const useSliceLimit = (limit: number | undefined) => {
  const sliceFn = React.useCallback(sliceLimit(limit), [limit]);
  return sliceFn;
};

const ActorApiMapperInner = (
  props: {
    data: string[] | undefined | null,
    isLoading: boolean,
    error: any,
    loadingList?: string[],
    limit?: number,
  },
) => {
  const {
    data,
    isLoading: dataIsLoading,
    error,
    loadingList = [],
    limit,
  } = props;
  const limiter = useSliceLimit(limit);
  const isLoading = dataIsLoading || !isNil(error);
  const mapped = !isLoading ? limiter(data) : limiter(loadingList);
  if ((mapped && Array.isArray(mapped) && mapped.length > 0)) {
    return (
      <Ramp
        rampName="ActorAPIMapperContainer"
      >
        {mapped.map((value, index, arr) => (
          <Ramp
            key={value}
            rampName="ActorAPIMapperElement"
            value={value}
            index={index}
            arr={arr}
          />
        ))}
      </Ramp>
    );
  }
  return (
    <Ramp
      rampName="ActorAPIMapperEmptyContainer"
      rampFallbacks={['ActorAPIMapperContainer']}
    >
      <Ramp rampName="ActorAPIMapperEmpty" />
    </Ramp>
  );
};

const apiMapperDefaultTopModels = {
  ActorAPIMapperContainer: (
    <TopModel name="ActorAPIMapperContainer">
      {PassThrough}
    </TopModel>
  ),
};

export const ActorApiMapper = (
  props: React.PropsWithChildren<{
    data: string[] | undefined | null,
    isLoading: boolean,
    error: any,
    loadingList?: string[],
    limit?: number,
  }>,
) => {
  const {
    children,
    ...rest
  } = props;
  return (
    <TopModelMerge
      topModels={apiMapperDefaultTopModels}
    >
      <TopModelChildrenMerge>
        {children}
        <ActorApiMapperInner
          {...rest}
        />
      </TopModelChildrenMerge>
    </TopModelMerge>
  );
};
