import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { InputIconContent } from './components';
import { BaseBuilderWithNameTransform } from '../../../../utils';

export const inputIconContentDefaultTopModelsObject = {
  InputIconContent: (
    <TopModel name="InputIconContent">{InputIconContent}</TopModel>
  ),
  InputHelpIconBase: (
    <TopModel name="InputHelpIconBase" rampName="InputHelpIcon">
      {BaseBuilderWithNameTransform}
    </TopModel>
  ),
  InputErrorIconBase: (
    <TopModel name="InputErrorIconBase" rampName="InputErrorIcon">
      {BaseBuilderWithNameTransform}
    </TopModel>
  ),
  InputValidIconBase: (
    <TopModel name="InputValidIconBase" rampName="InputValidIcon">
      {BaseBuilderWithNameTransform}
    </TopModel>
  ),
};
