import * as React from 'react';
import { appRefContext } from './context';

export const AppRefProvider = (props: React.PropsWithChildren<{
  appRef: React.RefObject<HTMLDivElement>,
}>): JSX.Element => {
  const {
    appRef,
    children,
  } = props;
  return (
    <appRefContext.Provider value={appRef}>
      {children}
    </appRefContext.Provider>
  );
};
