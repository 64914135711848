import React from 'react';
import { Trans } from '@lingui/macro';
import ouiBase from '@goldwasserexchange/oui';
import { dataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import { FilterCurrentUser } from '../../../Sections/onboarding/form/constants';

export const tAddOtherConstraintsLabel = (
  <>
    <FilterCurrentUser>
      <Trans>Êtes-vous de par votre fonction, une personne soumise à des restrictions sur certains titres pendant une certaine période en vertu de dispositions légales ou de dispositions propres à une société que vous dirigez ou dont vous êtes employés ?</Trans>
    </FilterCurrentUser>
    <FilterCurrentUser isNegative>
      <Trans>S'agit-il de par sa fonction, d'une personne soumise à des restrictions sur certains titres pendant une certaine période en vertu de dispositions légales ou de dispositions propres à une société qu'elle dirige ou dont elle est l'employée ?</Trans>
    </FilterCurrentUser>
  </>
);

export const tAddOtherConstraintsValidatorLogic: ouiBase.utils.hookstate.FormValueFilterLogic<'requiredBoolean' | 'nilCast'> = dataStructure.T_ADD.fields.TYPE.logic.makeTAddIsNotMoralOrIgnored({
  truthy: {
    result: 'requiredBoolean',
  },
  falsy: {
    result: 'nilCast',
  },
});
