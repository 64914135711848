import * as React from 'react';
import { usePersonAccountsTCatShortcutRespName } from '../../hooks/tCat/tCatShortcut';
import { ActorApiStringDisplay } from '../../../../../../components/display/string';

export const PersonAccountsTRespName = () => {
  const {
    data,
    isLoading,
    error,
  } = usePersonAccountsTCatShortcutRespName();
  return (
    <ActorApiStringDisplay
      data={data}
      isLoading={isLoading}
      error={error}
      loadingValue="-------- ----------"
    />
  );
};
