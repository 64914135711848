import { createContext } from 'react';

export type OnBlurContextType =
  | ((e: React.FocusEvent<HTMLInputElement | HTMLButtonElement>) => void)
  | undefined;

export const onBlurContext = createContext<
|((e: React.FocusEvent<HTMLInputElement | HTMLButtonElement>) => void)
| undefined
>(undefined);
