import React, { createContext, useContext } from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { useMedia } from '../useMedia';

export const MediaContext = createContext<{
  upSm: boolean,
  upMd: boolean,
  upContainerXl: boolean,
  upLg: boolean,
  upXl: boolean,
  print: boolean,
}>({
  upSm: false,
  upMd: false,
  upContainerXl: false,
  upLg: false,
  upXl: false,
  print: false,
});

export const mediaQueries = {
  upSm: 'only screen and (min-width: 34rem)',
  upMd: 'only screen and (min-width: 48rem)',
  upContainerXl: `only screen and (min-width: ${ouiStyle.Constants.Container.xl})`,
  upLg: 'only screen and (min-width: 62rem)',
  upXl: 'only screen and (min-width: 75rem)',
  print: 'print',
};

export const MediaContextProvider = ({ children }) => {
  const mediaQuery = useMedia(mediaQueries) as {
    upSm: boolean,
    upMd: boolean,
    upContainerXl: boolean,
    upLg: boolean,
    upXl: boolean,
    print: boolean,
  };
  return (
    <MediaContext.Provider value={mediaQuery}>
      {children}
    </MediaContext.Provider>
  );
};

const ouiStyleMediaQueries = ouiStyle.breakpoints.reduce((acc, breakpointRem, index) => ({
  ...acc,
  [`${index}`]: `only screen and (min-width: ${breakpointRem}rem)`,
}), {});

const ouiStyleDefaultMediaQueries = ouiStyle.breakpoints.reduce((acc, _, index) => ({
  ...acc,
  [`${index}`]: index === 0,
}), {});

export const OuiStyleMediaContext = createContext<Record<string, boolean>>(ouiStyleDefaultMediaQueries);

export const OuiStyleMediaContextProvider = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  const mediaQuery = useMedia(ouiStyleMediaQueries);
  return (
    <OuiStyleMediaContext.Provider value={mediaQuery}>
      {children}
    </OuiStyleMediaContext.Provider>
  );
};

export const useOuiStyleMediaContext = () => {
  const context = useContext(OuiStyleMediaContext);
  return context;
};

export const useOuiStyleMediaContextByIndex = (index: number) => {
  const context = useContext(OuiStyleMediaContext);
  return index !== 0 ? context[`${index - 1}`] : true;
};
