import * as React from 'react';
import { PrimaryDimensionDirectionContextProvider, PrimaryDimensionLengthProviderFromList, PrimaryDimensionListContextProvider, } from '../../utils/dimensions/primaryDimension';
import { SecondaryDimensionLengthProviderFromList, SecondaryDimensionListContextProvider } from '../../utils/dimensions/secondaryDimension';
import { CurrentRenderDirectionContextByWrappingProvider } from './directionDimension';
import { TableMultiBodyContextProvider } from './multiBody';
import { TableWrappingContextProvider } from './wrapping';
export var TableContext = function (props) {
    var primaryDimensionList = props.primaryDimensionList, secondaryDimensionList = props.secondaryDimensionList, direction = props.direction, wrapping = props.wrapping, multiBody = props.multiBody, children = props.children;
    return (React.createElement(PrimaryDimensionDirectionContextProvider, { primaryDimensionDirection: direction },
        React.createElement(TableWrappingContextProvider, { wrapping: wrapping },
            React.createElement(PrimaryDimensionListContextProvider, { primaryDimensionList: primaryDimensionList },
                React.createElement(PrimaryDimensionLengthProviderFromList, null,
                    React.createElement(SecondaryDimensionListContextProvider, { secondaryDimensionList: secondaryDimensionList },
                        React.createElement(SecondaryDimensionLengthProviderFromList, null,
                            React.createElement(TableMultiBodyContextProvider, { multiBody: multiBody },
                                React.createElement(CurrentRenderDirectionContextByWrappingProvider, null, children)))))))));
};
