import { useCallback } from 'react';
import { getSign } from './utils/getSign';
import { getFormat } from './utils/getFormat';

const formatNumber = (props: {
  forcedSigned?: boolean,
  format?: Partial<Intl.NumberFormatOptions>,
  divider?: number,
}) => (value: number) => {
  const {
    forcedSigned = false,
    format,
    divider = 1,
  } = props;
  const dividedValue = value / divider;
  const sign = getSign(
    dividedValue,
    forcedSigned,
  );
  const intlNumberFormatter = new Intl.NumberFormat('fr', getFormat(format ?? {}));
  return `${sign}${intlNumberFormatter.format(dividedValue)}`;
};

export type NumberFormatterProps = {
  forcedSigned?: boolean,
  format?: Partial<Intl.NumberFormatOptions>,
  divider?: number,
}

export const useNumberFormatter = (props: {
  forcedSigned?: boolean,
  format?: Partial<Intl.NumberFormatOptions>,
  divider?: number,
}) => {
  const {
    forcedSigned,
    format,
    divider,
  } = props;
  const numberFormatter = useCallback((value: number, formatOptions?: Partial<Intl.NumberFormatOptions>) => formatNumber({
    forcedSigned,
    format: {
      ...format,
      ...(formatOptions ?? {}),
    },
    divider,
  })(value), [
    forcedSigned,
    format,
    divider,
  ]);
  return numberFormatter;
};

export const useNumberFormat = (props: {
  value?: number,
  forcedSigned?: boolean,
  format?: Partial<Intl.NumberFormatOptions>,
  divider?: number,
}) => {
  const {
    value,
    forcedSigned,
    format,
    divider,
  } = props;
  const numberFormatter = useNumberFormatter({
    forcedSigned,
    format,
    divider,
  });
  if (value == null) {
    return undefined;
  }
  const intlFormatted = numberFormatter(value);
  return intlFormatted;
};
