import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { DefaultDomInputContainer } from '../../container';
import {
  ListInputLabelInfoContainer,
  ListInputLabelContainer,
  ListInputIconContainer,
  DefaultListInputListElementButtonPlaceHolder,
  DefaultListInputListElementContainer,
} from '../components';
import { DefaultUnorderedListWithNoMarkContainer } from '../../../../../../List';

export const listInputDomDefaultTopModelsObject = {
  InputContainer: (
    <TopModel name="InputContainer">{DefaultDomInputContainer}</TopModel>
  ),
  InputLabelInfoContainer: (
    <TopModel name="InputLabelInfoContainer">
      {ListInputLabelInfoContainer}
    </TopModel>
  ),
  InputLabelContainer: (
    <TopModel name="InputLabelContainer">{ListInputLabelContainer}</TopModel>
  ),
  InputIconContainer: (
    <TopModel name="InputIconContainer">{ListInputIconContainer}</TopModel>
  ),
  InputListContainer: (
    <TopModel name="InputListContainer">
      {DefaultUnorderedListWithNoMarkContainer}
    </TopModel>
  ),
  InputListElementContainer: (
    <TopModel name="ElementContainer">
      {DefaultListInputListElementContainer}
    </TopModel>
  ),
  InputListElementButtonPlaceHolder: (
    <TopModel name="InputListElementButtonPlaceHolder">
      {DefaultListInputListElementButtonPlaceHolder}
    </TopModel>
  ),
};
