import React, { Children } from 'react';
import groupBy from 'ramda/es/groupBy';
import pathOr from 'ramda/es/pathOr';

export const useLayout = (children: React.ReactNode): Record<string, React.ReactNode[] | null> => {
  const childrenArray = Children.toArray(children);
  const layout = groupBy(pathOr('', ['props', 'slot']), childrenArray) as Record<string, React.ReactNode[] | null>;
  return layout;
};

export const renderSlot = (children, args): React.ReactNode => {
  const childrenArray = Children.toArray(children);
  return childrenArray.map((child) => (
    typeof child === 'object' && 'props' in child && 'children' in child.props && typeof child.props.children === 'function'
      ? child.props.children(...args)
      : null
  ));
};
