import { ListObjectsV2Command, ListObjectsV2CommandOutput, _Object } from '@aws-sdk/client-s3';
import { compareDesc } from 'date-fns';
import { AuthSendType } from '../../../Components/Auth/context/context';
import { getSub } from '../auth';
import { getS3Client } from './getS3Client';

type GetS3FileListProps = {
  bucketName: string,
  subPrefix: string,
  send?: AuthSendType,
}

type S3ObjectWithKey = (Omit<_Object, 'Key'> & { Key: string });

export const getS3FileList = async (props: GetS3FileListProps) => {
  const {
    bucketName,
    subPrefix,
    send,
  } = props;
  const client = await getS3Client(send);
  const sub = await getSub();
  if (!sub) {
    return [];
  }
  const Prefix = `${sub}/${subPrefix}`;
  const input = {
    Bucket: bucketName,
    Prefix,
  };
  const command = new ListObjectsV2Command(input);
  const response = await client.send(command);
  const {
    Contents,
  } = response;
  return Contents;
};

const sortS3ListByDate = (contents: ListObjectsV2CommandOutput['Contents']): _Object[] | undefined => {
  if (!contents) {
    return contents;
  }
  return contents.sort((prev, next) => {
    const prevDate = prev.LastModified ?? new Date();
    const nextDate = next.LastModified ?? new Date();
    return compareDesc(prevDate, nextDate);
  });
};

const filterEmptyS3Files = (contents: ListObjectsV2CommandOutput['Contents']): S3ObjectWithKey[] | undefined => {
  if (!contents) {
    return contents;
  }
  return contents.filter<S3ObjectWithKey>((element): element is S3ObjectWithKey => element.Key != null);
};

const getFileNameFromS3Object = (contents: S3ObjectWithKey[] | undefined) => {
  if (!contents) {
    return contents;
  }
  return contents.map((content) => {
    const {
      Key,
    } = content;
    return Key;
  });
};

export const removeSubFromFileName = (fileName: string) => fileName.split('/').slice(1).join('/');

export const removeSubFromFileNames = (fileNames: string[] | undefined) => {
  if (!fileNames) {
    return [];
  }
  return fileNames.map((fileName) => removeSubFromFileName(fileName));
};

export const getS3NonEmptyFileNamesListSortedByDate = async (props: GetS3FileListProps) => {
  const contents = await getS3FileList(props);
  const sortedContents = sortS3ListByDate(contents);
  const nonEmptyContents = filterEmptyS3Files(sortedContents);
  const fileNames = getFileNameFromS3Object(nonEmptyContents);
  return fileNames;
};
