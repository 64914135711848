import React from 'react';
import { Trans } from '@lingui/macro';
import {
  TAddType,
} from '@goldwasserexchange/oblis-frontend-utils';
import { useHookstate } from '@hookstate/core';
import { useUser } from '../utils';

const TAddStreet1LabelMoralPart = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  const userState = useUser();
  const tAddTypeState = useHookstate(userState.T_ADD_type);
  if (tAddTypeState.get() !== TAddType.MORAL) {
    return null;
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export const tAddStreet1Label = (
  <Trans>
    Adresse (rue, numéro, boîte)
    <TAddStreet1LabelMoralPart>&#32;du siège social</TAddStreet1LabelMoralPart>
  </Trans>
);
