import ouiBase from '@goldwasserexchange/oui';
import ouiStyle from '@goldwasserexchange/oui-style';
import { RampWithNameTransform, TopModelNameTransformProvider, useTopModelNameTransformFn } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import { DefaultInputContextProvider, DefaultInputContextProviderProps } from '../../../../../context';
import { GetFnSetContextProvider, SearchKeysSetContext, UseGetFnProps } from '../context';
import { SearchKeysProps } from '../context/searchKeys/context';
import { useInputListPrefixer } from '../utils';

export function TemporaryNameTransformRamp<P>(
  props: React.PropsWithChildren<{ transformFn: (str: string) => string } & P>,
) {
  const { transformFn, children, ...rest } = props;
  const parentTransformFn = useTopModelNameTransformFn();
  return (
    <TopModelNameTransformProvider transformFn={transformFn}>
      <RampWithNameTransform<any> {...rest}>
        <TopModelNameTransformProvider transformFn={parentTransformFn}>
          {children}
        </TopModelNameTransformProvider>
      </RampWithNameTransform>
    </TopModelNameTransformProvider>
  );
}

export function DefaultListInputContextProvider(
  props: {
    elementLines?: ouiStyle.InStyle['lines'],
    type: Extract<ouiBase.Input.InputTypes, 'radio' | 'checkbox'>,
    elementChildPaddingTop?: ouiStyle.InStyle['childPaddingTop'],
    elementChildPaddingRight?: ouiStyle.InStyle['childPaddingRight'],
    elementChildPaddingBottom?: ouiStyle.InStyle['childPaddingBottom'],
    elementChildPaddingLeft?: ouiStyle.InStyle['childPaddingLeft'],
  } & SearchKeysProps & UseGetFnProps & DefaultInputContextProviderProps &
  ouiBase.List.ListProps,
) {
  const {
    type,
    fieldPath,
    dependenciesFieldPaths,
    list,
    length,
    elementLines,
    elementChildPaddingTop = 'simple',
    elementChildPaddingRight = 'simple',
    elementChildPaddingBottom = 'simple',
    elementChildPaddingLeft = 'simple',
    useFilterFn,
    useSortFn,
    useGetFn,
    searchKeys,
    children,
  } = props;
  const listInputListPrefixer = useInputListPrefixer();
  return (
    <DefaultInputContextProvider
      type={type}
      fieldPath={fieldPath}
      dependenciesFieldPaths={dependenciesFieldPaths}
    >
      <GetFnSetContextProvider useGetFn={useGetFn}>
        <SearchKeysSetContext searchKeys={searchKeys}>
          <TemporaryNameTransformRamp
            transformFn={listInputListPrefixer}
            rampName="ContextContainer"
            list={list}
            useFilterFn={useFilterFn}
            useSortFn={useSortFn}
            length={length}
          >
            <RampWithNameTransform<{
              elementLines?: ouiStyle.InStyle['lines'],
            }>

              rampName="InputElementLinesContext"
              elementLines={elementLines}
            >
              <RampWithNameTransform<ouiBase.List.ListElementChildPaddingProviderProps>
                rampName="InputElementChildPaddingsContext"
                elementChildPaddingTop={elementChildPaddingTop}
                elementChildPaddingRight={elementChildPaddingRight}
                elementChildPaddingBottom={elementChildPaddingBottom}
                elementChildPaddingLeft={elementChildPaddingLeft}
              >
                {children}
              </RampWithNameTransform>
            </RampWithNameTransform>
          </TemporaryNameTransformRamp>
        </SearchKeysSetContext>
      </GetFnSetContextProvider>
    </DefaultInputContextProvider>
  );
}
