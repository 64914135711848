import { STypeGrp } from '@goldwasserexchange/oblis-frontend-utils';

export const productNamesMap: Record<STypeGrp, string> = {
  [STypeGrp.OBLIGATIONS]: 'bond',
  [STypeGrp.OBLIGATIONS_SUB]: 'subordinatedBond',
  [STypeGrp.OBLIGATIONS_PERP]: 'perpetualBond',
  [STypeGrp.ACTIONS]: 'stock',
  [STypeGrp.FONDS]: 'fund',
  [STypeGrp.TRACKERS]: 'trackerAndETF',
  [STypeGrp.TRACKERS_MAT]: 'rawMaterialTracker',
};
