import { useFilterByTAccId } from '../../utils/tAcc/filterByTAccId';
import { PersonAccounts } from '../../type';
import { usePersonAccountTransformApi } from '../api';

const hasTransferAccountRights = (line: PersonAccounts | null | undefined) => {
  const tNatureLrp = line?.['T_NATURE_LRP.SHORTCUT'];
  const tProcType = line?.['T_PROC_TYPE.SHORTCUT'];
  const hasCorrectAccountRights = (tNatureLrp === 'TITULAIRE' || tProcType === 'GENERALE');
  return hasCorrectAccountRights;
};

export const useHasAccountTransferRights = () => {
  const filterBySelectedTAccId = useFilterByTAccId();
  const accountTransferRightsApiResult = usePersonAccountTransformApi({
    transformFunction: hasTransferAccountRights,
    filterPredicate: filterBySelectedTAccId,
  });
  return accountTransferRightsApiResult;
};
