import ouiBase from '@goldwasserexchange/oui';
import { getOblEndDateWithPerpetual } from '../../../../shared/utils/getOblEndDateWithPerpetual';
import { useBondSameIssuerTransformApiBySAccId } from '../api';

export const useBondSameIssuerSAccOblEndDate = () => {
  const sAccId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const response = useBondSameIssuerTransformApiBySAccId({
    sAccId,
    transformFunction: getOblEndDateWithPerpetual,
  });
  return response;
};
