import { RampWithNameTransform } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import { useFieldDisabledReasonByFieldPath } from '../../../../../../../../../../../../Form';
import { useGetFieldPath } from '../../../../../../../../../../../context';

export function DefaultListElementLabelDisabledReasonContentSwitcher(props: { baseRampName: string }) {
  const { baseRampName } = props;
  const fieldPath = useGetFieldPath();
  const disabledReason = useFieldDisabledReasonByFieldPath(fieldPath);
  const reason = disabledReason.get();
  return (
    <RampWithNameTransform
      key={`${baseRampName}ElementLabelDisabledReasonContent${reason}`}
      rampName={`${baseRampName}ElementLabelDisabledReasonContent${reason}`}
    />
  );
}
