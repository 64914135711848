import { useContext, useMemo } from 'react';
import { GetAccountsTwrrParams } from '@goldwasserexchange/actor/rest-services';
import { useTAccId } from '../../../shared';
import { stringifyParams } from '../../../shared/utils/makeUrl';
import { accountsTwrrParamsContext } from '../context';
import { useIsConnected } from '../../../../../../Components/Auth';

export const useAccountTwrrDefaultParams = (params: Partial<GetAccountsTwrrParams>): Partial<GetAccountsTwrrParams> => {
  const {
    'T_ACC.ID': contextTAccId,
    'TWRR_PER.DATE_FIN_START': contextTwrrPerDateFinStart,
    'TWRR_PER.DATE_FIN_END': contextTwrrPerDateFinEnd,
  } = useContext(accountsTwrrParamsContext);
  const tAccIdString = useTAccId();
  const currentTAccId = tAccIdString ? parseInt(tAccIdString, 10) : undefined;
  const tAccId = params['T_ACC.ID'] ?? contextTAccId ?? currentTAccId;
  const twrrPerDateFinStart = params['TWRR_PER.DATE_FIN_START'] ?? contextTwrrPerDateFinStart;
  const twrrPerDateFinEnd = params['TWRR_PER.DATE_FIN_END'] ?? contextTwrrPerDateFinEnd;
  const defaultParams = useMemo(() => ({
    'TWRR_PER.DATE_FIN_START': twrrPerDateFinStart,
    'TWRR_PER.DATE_FIN_END': twrrPerDateFinEnd,
    'T_ACC.ID': tAccId,
  }), [
    tAccId,
    twrrPerDateFinStart,
    twrrPerDateFinEnd,
  ]);
  return defaultParams;
};

const useAccountTwrrParams = (): GetAccountsTwrrParams | null => {
  const defaultParams = useAccountTwrrDefaultParams({});
  const tAccId = defaultParams['T_ACC.ID'];
  const isConnected = useIsConnected();
  if (tAccId == null || !isConnected) {
    return null;
  }
  return {
    'T_ACC.ID': tAccId,
    'TWRR_PER.DATE_FIN_START': defaultParams['TWRR_PER.DATE_FIN_START'],
    'TWRR_PER.DATE_FIN_END': defaultParams['TWRR_PER.DATE_FIN_END'],
  };
};

export const useAccountTwrrStringifiedParams = () => {
  const contextParams = useAccountTwrrParams();
  return contextParams !== null ? stringifyParams(contextParams) : null;
};
