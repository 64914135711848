import { UAParser } from 'ua-parser-js';
import { msg } from '@lingui/macro';
import {
  Cpu, Icon, Monitor, Smartphone, Tablet, Tv, Watch,
} from 'react-feather';
import { I18n, MessageDescriptor } from '@lingui/core';
import { PersonDevices } from '../type';
import { Question } from '../../../../../../Components/QuestionIcon';

const getDevice = (line: PersonDevices | undefined | null): UAParser.IDevice => {
  if (line) {
    const parser = new UAParser(line.value);
    const device = parser.getDevice();
    return {
      ...device,
      type: device.type ?? 'computer',
    };
  }
  return {
    type: undefined,
    model: undefined,
    vendor: undefined,
  };
};

type DeviceTypes = 'console' | 'mobile' | 'tablet' | 'smarttv' | 'wearable' | 'embedded' | 'computer' | 'unknown';

const deviceTypes: DeviceTypes[] & string[] = ['console', 'mobile', 'tablet', 'smarttv', 'wearable', 'embedded', 'computer', 'unknown'];

const isDeviceType = (type: string): type is DeviceTypes => deviceTypes.includes(type);

export const getDeviceType = (line: PersonDevices | undefined | null): DeviceTypes => {
  const device = getDevice(line);
  if (device.type && isDeviceType(device.type)) {
    return device.type;
  }
  return 'unknown';
};

const devices: Record<DeviceTypes, MessageDescriptor> = {
  console: msg`Console`,
  mobile: msg`Mobile`,
  tablet: msg`Tablette`,
  smarttv: msg`Smart TV`,
  wearable: msg`Wearable`,
  embedded: msg`Embedded`,
  computer: msg`Ordinateur`,
  unknown: msg`Appareil inconnu`,
};

export const makeGetDeviceTypeTranslation = (i18n: I18n) => (line: PersonDevices | undefined | null) => {
  const deviceType = getDeviceType(line);
  return i18n._(devices[deviceType]);
};

export const getDeviceModel = (line: PersonDevices | undefined | null) => {
  const device = getDevice(line);
  if (line) {
    if (device.type === 'mobile') {
      if (device.vendor && device.model) {
        return `(${device.vendor} ${device.model})`;
      }
      if (device.vendor) {
        return `(${device.vendor})`;
      }
    }
  }
  return '';
};

export const DeviceTypeIcons: Record<DeviceTypes, Icon | (() => JSX.Element)> = {
  console: Cpu,
  mobile: Smartphone,
  tablet: Tablet,
  smarttv: Tv,
  wearable: Watch,
  embedded: Cpu,
  computer: Monitor,
  unknown: Question,
};

export const getBrowser = (line: PersonDevices | null | undefined): UAParser.IBrowser => {
  if (line) {
    const parser = new UAParser(line.value);
    return parser.getBrowser();
  }
  return {
    name: undefined,
    version: undefined,
    major: undefined,
  };
};

export const makeGetBrowserNameWithTranslation = (i18n: I18n) => (line: PersonDevices | null | undefined) => {
  const browser = getBrowser(line);
  return browser.name ?? i18n._(msg`Navigateur inconnu`);
};

export const getOs = (line: PersonDevices | null | undefined): UAParser.IOS => {
  if (line) {
    const parser = new UAParser(line.value);
    return parser.getOS();
  }
  return {
    name: undefined,
    version: undefined,
  };
};

export const makeGetOSNameWithTranslation = (i18n: I18n) => (line: PersonDevices | null | undefined) => {
  const os = getOs(line);
  return os.name ?? i18n._(msg`OS inconnu`);
};
