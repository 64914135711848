/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { useMemo } from 'react';
import { IconName, icons } from '../Icons';
import { SuccessState } from './type';

const cardIconStyle: ouiStyle.InStyle = {
  float: 'left',
  paddingHorizontal: 'simple',
};

const useCardIconCss = (successState?: SuccessState) => {
  const css = useMemo(() => {
    switch (successState) {
      case 'success':
        return ouiStyle.makeCss({
          ...cardIconStyle,
          color: ouiStyle.Constants.Colors.success,
        });
      case 'warning':
        return ouiStyle.makeCss({
          ...cardIconStyle,
          color: ouiStyle.Constants.Colors.warning,
        });
      case 'error':
        return ouiStyle.makeCss({
          ...cardIconStyle,
          color: ouiStyle.Constants.Colors.danger,
        });
      default:
        return ouiStyle.makeCss(cardIconStyle);
    }
  }, [successState]);
  return css;
};

export const CardIcon = (props: {
  successState?: SuccessState,
  iconName: IconName,
}) => {
  const {
    successState,
    iconName,
  } = props;
  const css = useCardIconCss(successState);
  const Component = icons[iconName];
  return (
    <Component
      css={css}
    />
  );
};
