import {
  dataStructure, makeName, WasRetriedSuffix, UncastedDataStructure, STypeGrp, UncastedOnboarding,
} from '@goldwasserexchange/oblis-frontend-utils';
import { makeNext, NavAction } from '..';

const onboardingPath: keyof Pick<UncastedDataStructure, 'onboarding'> = 'onboarding';

const knowledgeQuestionnaire: keyof Pick<UncastedOnboarding, 'knowledgeQuestionnaire'> = 'knowledgeQuestionnaire';

export const makeQuestion = (name: string, product: STypeGrp, prefix = '', canRetry?: boolean) => { // eslint-disable-line default-param-last
  const nameParts = name.split('.');
  const key = nameParts[nameParts.length - 1];
  const id = `${prefix}${key}`;
  const askNext = makeNext('correction', {
    cond: {
      type: 'formikValuesAreValid',
      names: [dataStructure.financialProductsKnowledge.pathUtils.makeAnswerKey(`${onboardingPath}.${name}`)],
    },
  });
  return ({
    initial: 'initial',
    id,
    states: {
      initial: {
        on: {
          '': [{
            target: 'correction',
            cond: {
              type: 'isAnswerCorrected',
              key,
            },
          }, {
            target: 'ask',
          }],
        },
      },
      ask: {
        on: {
          [NavAction.NEXT]: [askNext[NavAction.NEXT], {
            actions: [{
              type: 'touch',
              names: [
                dataStructure.financialProductsKnowledge.pathUtils.makeAnswerKey(name),
                dataStructure.financialProductsKnowledge.pathUtils.makeIsCorrectKey(name),
              ],
            }],
          }],
        },
      },
      correction: {
        initial: 'initial',
        states: {
          initial: {
            invoke: {
              src: {
                type: 'setDisableds',
                kv: [
                  [makeName(onboardingPath, knowledgeQuestionnaire, dataStructure.financialProductsKnowledge.pathUtils.makeAnswerKey(key)), true],
                ],
              },
              onDone: 'setIsCorrected',
            },
          },
          setIsCorrected: {
            invoke: {
              src: {
                type: 'setIsCorrecteds',
                kv: [
                  [key, true],
                ],
              },
              onDone: [{
                target: 'setCorrect',
                cond: {
                  type: 'isCorrectAnswer',
                  key: `${onboardingPath}.${name}`,
                },
              }, {
                target: 'setWrong',
              }],
            },
          },
          setCorrect: {
            invoke: {
              src: {
                type: 'setKnowledgeQuestionnaireValue',
                kv: [
                  [dataStructure.financialProductsKnowledge.pathUtils.makeIsCorrectKey(key), true],
                ],
              },
              onDone: 'isCorrect',
            },
          },
          setWrong: {
            invoke: {
              src: {
                type: 'setKnowledgeQuestionnaireValue',
                kv: [
                  [dataStructure.financialProductsKnowledge.pathUtils.makeIsCorrectKey(key), false],
                ],
              },
              onDone: 'isWrong',
            },
          },
          isCorrect: {
            on: {
              ...makeNext('doneCorrect'),
            },
          },
          isWrong: {
            initial: 'initial',
            states: {
              initial: {
                on: {
                  '': canRetry ? [{
                    target: 'canRetry',
                    cond: {
                      type: 'knowledgeQuestionnaireRetried',
                      key,
                      is: false,
                    },
                  }, {
                    target: 'canNotRetry',
                    cond: {
                      type: 'knowledgeQuestionnaireRetried',
                      key,
                      is: true,
                    },
                  }] : 'canNotRetry',
                },
              },
              canRetry: {
                on: {
                  RETRY: {
                    target: 'retry',
                  },
                },
              },
              canNotRetry: {},
              retry: {
                type: 'final' as const,
              },
            },
            on: {
              ...makeNext('doneWrong'),
            },
            onDone: 'unTouching',
          },
          unTouching: {
            invoke: {
              src: {
                type: 'setIsTouched',
                kv: [
                  [makeName(onboardingPath, knowledgeQuestionnaire, dataStructure.financialProductsKnowledge.pathUtils.makeAnswerKey(key)), false],
                ],
              },
              onDone: 'resetting',
            },
          },
          resetting: {
            invoke: {
              src: {
                type: 'setKnowledgeQuestionnaireValue',
                kv: [
                  [dataStructure.financialProductsKnowledge.pathUtils.makeAnswerKey(key), undefined],
                  [dataStructure.financialProductsKnowledge.pathUtils.makeIsCorrectKey(key), undefined],
                  [dataStructure.financialProductsKnowledge.pathUtils.makeWasRetried(key), true],
                ],
              },
              onDone: 'resetProduct',
            },
          },
          resetProduct: {
            invoke: {
              src: {
                type: 'resetProduct',
                product,
              },
              onDone: 'resetIsCorrected',
            },
          },
          resetIsCorrected: {
            invoke: {
              src: {
                type: 'setIsCorrecteds',
                kv: [
                  [key, false],
                ],
              },
              onDone: 'enabling',
            },
          },
          enabling: {
            invoke: {
              src: {
                type: 'setDisableds',
                kv: [
                  [makeName(onboardingPath, knowledgeQuestionnaire, `${dataStructure.financialProductsKnowledge.pathUtils.makeAnswerKey(key)}`), false],
                ],
              },
              onDone: `#${id}`,
            },
          },
          doneCorrect: {
            type: 'final' as const,
          },
          doneWrong: {
            type: 'final' as const,
          },
        },
        onDone: 'done',
      },
      done: {
        type: 'final' as const,
      },
    },
    on: {},
  });
};

export const makeQuestionWithRemedial = (name: string, product: STypeGrp, prefix = '') => {
  const question = makeQuestion(name, product, prefix);
  const remedialQuestion = makeQuestion(dataStructure.financialProductsKnowledge.pathUtils.makeRemedialKey(name), product, prefix);
  const nameParts = name.split('.');
  const key = nameParts[nameParts.length - 1];
  const id = `${prefix}${key}`;
  const result = {
    ...question,
    states: {
      ...question.states,
      correction: {
        ...question.states.correction,
        states: {
          ...question.states.correction.states,
          isWrong: {
            ...remedialQuestion,
            states: {
              ...remedialQuestion.states,
              correction: {
                ...remedialQuestion.states.correction,
                states: {
                  ...remedialQuestion.states.correction.states,
                  isWrong: {
                    ...remedialQuestion.states.correction.states.isWrong,
                    on: {
                      ...remedialQuestion.states.correction.states.isWrong.on,
                      RETRY: {
                        target: 'unTouching',
                      },
                    },
                  },
                  unTouching: {
                    invoke: {
                      src: {
                        type: 'setIsTouched',
                        kv: [
                          [makeName(onboardingPath, knowledgeQuestionnaire, dataStructure.financialProductsKnowledge.pathUtils.makeAnswerKey(key)), false],
                          [makeName(onboardingPath, knowledgeQuestionnaire, dataStructure.financialProductsKnowledge.pathUtils.makeAnswerKey(`${key}Remedial`)), false],
                        ],
                      },
                      onDone: 'resetting',
                    },
                  },
                  resetting: {
                    invoke: {
                      src: {
                        type: 'setKnowledgeQuestionnaireValue',
                        kv: [
                          [dataStructure.financialProductsKnowledge.pathUtils.makeAnswerKey(key), undefined],
                          [dataStructure.financialProductsKnowledge.pathUtils.makeAnswerKey(dataStructure.financialProductsKnowledge.pathUtils.makeRemedialKey(key)), undefined],
                          [dataStructure.financialProductsKnowledge.pathUtils.makeIsCorrectKey(key), undefined],
                          [dataStructure.financialProductsKnowledge.pathUtils.makeIsCorrectKey(dataStructure.financialProductsKnowledge.pathUtils.makeRemedialKey(key)), undefined],
                          [`${key}${WasRetriedSuffix}`, true],
                        ],
                      },
                      onDone: 'resetProduct',
                    },
                  },
                  resetProduct: {
                    invoke: {
                      src: {
                        type: 'resetProduct',
                        product,
                      },
                      onDone: 'resetIsCorrected',
                    },
                  },
                  resetIsCorrected: {
                    invoke: {
                      src: {
                        type: 'setIsCorrecteds',
                        kv: [
                          [key, false],
                          [dataStructure.financialProductsKnowledge.pathUtils.makeRemedialKey(key), false],
                        ],
                      },
                      onDone: 'enabling',
                    },
                  },
                  enabling: {
                    invoke: {
                      src: {
                        type: 'setDisableds',
                        kv: [
                          [makeName(onboardingPath, knowledgeQuestionnaire, `${dataStructure.financialProductsKnowledge.pathUtils.makeAnswerKey(key)}`), false],
                          [makeName(onboardingPath, knowledgeQuestionnaire, dataStructure.financialProductsKnowledge.pathUtils.makeAnswerKey(`${key}Remedial`)), false],
                        ],
                      },
                      onDone: `#${id}`,
                    },
                  },
                },
              },
            },
            onDone: question.states.correction.states.isWrong.on.NEXT.target,
          },
        },
      },
    },
  };
  return result;
};
