import * as React from 'react';
import { GetSecuritiesQuotesParams } from '@goldwasserexchange/actor/rest-services';
import { useSecuritiesQuotesDefaultParams } from '../hooks';
import { securityQuotesParamsContext } from '../context';

export const SecurityQuotesParamsProvider = (props: React.PropsWithChildren<{
  params: Partial<GetSecuritiesQuotesParams>,
}>) => {
  const {
    params,
    children,
  } = props;
  const value = useSecuritiesQuotesDefaultParams(params);
  return (
    <securityQuotesParamsContext.Provider
      value={value}
    >
      {children}
    </securityQuotesParamsContext.Provider>
  );
};
