import * as React from 'react';
import { ActorApiNumberDisplayCurrency } from '../../../../../../components/display/number/currency';
import {
  useAccountHistoryAmountHieDcByHisMvtId,
  useAccountHistoryCCurrencyCodeByHisMvtId,
  useAccountHistoryAmountHieDcAtDateStart,
  useAccountHistoryCCurrencyCodeByCurrencyCode,
  useAccountHistoryAmountHieDcAtDateEnd,
} from '../../hooks';

export const AccountsHistoryHisMvtAmountHieDc = () => {
  const {
    data,
    isLoading,
    error,
  } = useAccountHistoryAmountHieDcByHisMvtId();
  const {
    data: currencyData,
    isLoading: currencyIsLoading,
    error: currencyError,
  } = useAccountHistoryCCurrencyCodeByHisMvtId();
  return (
    <ActorApiNumberDisplayCurrency
      data={data}
      isLoading={isLoading}
      error={error}
      currencyData={currencyData}
      currencyIsLoading={currencyIsLoading}
      currencyError={currencyError}
    />
  );
};

export const AccountsHistoryHisMvtAmountHieDcAtDateStart = () => {
  const {
    data,
    isLoading,
    error,
  } = useAccountHistoryAmountHieDcAtDateStart();
  const {
    data: currencyData,
    isLoading: currencyIsLoading,
    error: currencyError,
  } = useAccountHistoryCCurrencyCodeByCurrencyCode();
  return (
    <ActorApiNumberDisplayCurrency
      data={data}
      isLoading={isLoading}
      error={error}
      currencyData={currencyData}
      currencyIsLoading={currencyIsLoading}
      currencyError={currencyError}
    />
  );
};

export const AccountsHistoryHisMvtAmountHieDcAtDateEnd = () => {
  const {
    data,
    isLoading,
    error,
  } = useAccountHistoryAmountHieDcAtDateEnd();
  const {
    data: currencyData,
    isLoading: currencyIsLoading,
    error: currencyError,
  } = useAccountHistoryCCurrencyCodeByCurrencyCode();
  return (
    <ActorApiNumberDisplayCurrency
      data={data}
      isLoading={isLoading}
      error={error}
      currencyData={currencyData}
      currencyIsLoading={currencyIsLoading}
      currencyError={currencyError}
    />
  );
};
