import {
  correctBondAnswers,
  correctTrackerAndETFAnswers,
  correctPerpetualBondAnswers,
  correctSubordinatedBondAnswers,
} from '../Sections/onboarding/form/questionnaires/KnowledgeAndExperienceQuestionnaire/KnowledgeQuestionnaire/rto';
import { makeQuestionnaireAnswers } from '../Sections/onboarding/form/questionnaires/KnowledgeAndExperienceQuestionnaire';

export const correctAnswers = {
  ...correctBondAnswers,
  ...correctSubordinatedBondAnswers,
  ...correctPerpetualBondAnswers,
  ...correctTrackerAndETFAnswers,
  ...makeQuestionnaireAnswers(),
};
