/* eslint-disable react/no-array-index-key */
import { head, tail } from 'ramda';
import * as React from 'react';
import { ElementTitle } from '../elementTitle';
import { SanityRow, SanityTableCell, SanityTableContainer } from './components';
import { compactTable } from './utils';
export var SanityTable = function (props) {
    var value = props.value;
    var tableData = compactTable(value.content);
    if (value.horizontalTitle === true) {
        var headerRow = head(tableData);
        var rows = tail(tableData);
        return (React.createElement(React.Fragment, null,
            value.title != null
                ? (React.createElement(ElementTitle, { lineFactor: 1.2, paddingBottom: "double" }, value.title))
                : null,
            React.createElement(SanityTableContainer, null,
                React.createElement(SanityRow, { headerRow: true }, headerRow === null || headerRow === void 0 ? void 0 : headerRow.map(function (cell, cellIndex) {
                    var _a, _b;
                    return (cell != null
                        ? (React.createElement(SanityTableCell, { key: "0-".concat(cellIndex), cellDefinition: cell, horizontalTitle: true, columnIndex: cellIndex, separator: ((_b = (_a = value === null || value === void 0 ? void 0 : value.verticalSeparators) === null || _a === void 0 ? void 0 : _a.includes(cellIndex + 1 - (value.verticalTitle === true ? 1 : 0))) !== null && _b !== void 0 ? _b : false) }))
                        : null);
                })),
                React.createElement("tbody", null, rows.map(function (row, rowIndex, bodyRows) {
                    var _a, _b;
                    return (row.length > 0 ? (React.createElement(SanityRow, { key: "".concat(rowIndex), separator: ((_b = (_a = value === null || value === void 0 ? void 0 : value.horizontalSeparators) === null || _a === void 0 ? void 0 : _a.includes(rowIndex + 1)) !== null && _b !== void 0 ? _b : false), last: rowIndex === bodyRows.length - 1 }, row.map(function (cell, cellIndex) {
                        var _a, _b;
                        return (cell != null
                            ? (React.createElement(SanityTableCell, { key: "".concat(cellIndex), cellDefinition: cell, verticalTitle: value.verticalTitle === true && cellIndex === 0, columnIndex: cellIndex, separator: ((_b = (_a = value === null || value === void 0 ? void 0 : value.verticalSeparators) === null || _a === void 0 ? void 0 : _a.includes(cellIndex + 1 - (value.verticalTitle === true ? 1 : 0))) !== null && _b !== void 0 ? _b : false) }))
                            : null);
                    }))) : null);
                })))));
    }
    return (React.createElement(React.Fragment, null,
        value.title != null
            ? React.createElement(ElementTitle, null, value.title)
            : null,
        React.createElement("table", null,
            React.createElement("tbody", null, tableData.map(function (row, rowIndex) {
                var _a, _b;
                return (row.length > 0 ? (React.createElement(SanityRow, { key: "".concat(rowIndex), separator: ((_b = (_a = value === null || value === void 0 ? void 0 : value.horizontalSeparators) === null || _a === void 0 ? void 0 : _a.includes(rowIndex + 1 - (value.horizontalTitle === true ? 1 : 0))) !== null && _b !== void 0 ? _b : false) }, (row.map(function (cell, cellIndex) {
                    var _a, _b;
                    return (cell != null
                        ? (React.createElement(SanityTableCell, { key: "".concat(cellIndex), cellDefinition: cell, columnIndex: cellIndex, verticalTitle: value.verticalTitle === true && cellIndex === 0, separator: ((_b = (_a = value === null || value === void 0 ? void 0 : value.verticalSeparators) === null || _a === void 0 ? void 0 : _a.includes(cellIndex + 1 - (value.verticalTitle === true ? 1 : 0))) !== null && _b !== void 0 ? _b : false) }))
                        : null);
                })))) : null);
            })))));
};
