import ouiBase from '@goldwasserexchange/oui';
import * as ID_ECPL_PROF from '../../ID_ECPL_PROF';
var onboardingPath = 'onboarding';
var hasSameProfessionAndResidencyCountryPath = 'HasSameProfessionAndResidencyCountry';
var isPrintPath = 'isPrint';
var idEcplProfPath = 'ID_ECPL_PROF';
export function makeShowLogic(props) {
    var _a = props.truthy, truthy = _a === void 0 ? {
        result: 'visible',
    } : _a, _b = props.falsy, falsy = _b === void 0 ? {
        result: 'invisible',
    } : _b, _c = props.withPrint, withPrint = _c === void 0 ? false : _c;
    var visibleNoPrint = {
        name: "<parentName>.".concat(hasSameProfessionAndResidencyCountryPath),
        is: false,
        alternatives: {
            truthy: truthy,
            falsy: falsy,
        },
    };
    var visiblePrint = {
        name: "".concat(onboardingPath, ".").concat(isPrintPath),
        is: true,
        alternatives: {
            truthy: truthy,
            falsy: visibleNoPrint,
        },
    };
    var visible = !withPrint ? visibleNoPrint : visiblePrint;
    return ID_ECPL_PROF.logic.makeIdEcplProfShouldHaveProfessionLogic({
        visible: visible,
        invisible: falsy,
    });
}
export var showLogic = makeShowLogic({ withPrint: true });
export var labelLogic = {
    name: "<parentName>.".concat(idEcplProfPath),
    is: ID_ECPL_PROF.mayHavePreviousJobProfessionList,
    options: {
        isNegative: true,
        matcher: ouiBase.utils.match.arrayMatcherNames.some,
    },
    alternatives: {
        truthy: {
            result: 'current',
        },
        falsy: {
            result: 'past',
        },
    },
};
