import { computeDimension } from '../utils/dimensions/computeDimension';
export var minLinesDefault = 'auto';
export function makeMinHeight(props) {
    var minLines = props.minLines, minFontSize = props.minFontSize, minPaddingTop = props.minPaddingTop, minPaddingBottom = props.minPaddingBottom, minChildPaddingTop = props.minChildPaddingTop, minChildPaddingBottom = props.minChildPaddingBottom, minParentBorderTopWidth = props.minParentBorderTopWidth, minParentBorderBottomWidth = props.minParentBorderBottomWidth, minIcon = props.minIcon, lineFactor = props.lineFactor, minMinus = props.minMinus;
    return computeDimension({
        factor: minLines,
        fontSize: minFontSize,
        paddingStart: minPaddingTop,
        paddingEnd: minPaddingBottom,
        childPaddingStart: minChildPaddingTop,
        childPaddingEnd: minChildPaddingBottom,
        parentBorderStart: minParentBorderTopWidth,
        parentBorderEnd: minParentBorderBottomWidth,
        icon: minIcon,
        lineFactor: lineFactor,
        minus: minMinus,
    });
}
