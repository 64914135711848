/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import { animated } from 'react-spring';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as animationController from '../../animation';

const navBarStyle: ouiStyle.InStyle = {
  display: ouiStyle.Constants.DisplayProp.flex,
  fontSize: 'm',
};

const navBarCss = ouiStyle.makeCss(navBarStyle);

export const NavBar = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  const mqDown = animationController.useAnimationmqDownSpringValue();
  const style = {
    order: mqDown.to((v) => {
      if (v <= 0.5) {
        return 1;
      }
      return 0;
    }),
  };
  return <animated.nav css={navBarCss} style={style}>{children}</animated.nav>;
};

export default NavBar;
