export var initialConseilGestionValues = function () { return ({
    fundCurrencyRiskAnswer: undefined,
    fundCurrencyRiskIsCorrect: undefined,
    fundCurrencyRiskWasRetried: false,
    fundCurrencyRiskIsCorrected: false,
    fundDefaultAnswer: undefined,
    fundDefaultIsCorrect: undefined,
    fundDefaultWasRetried: false,
    fundDefaultIsCorrected: false,
    fundDefinitionAnswer: undefined,
    fundDefinitionIsCorrect: undefined,
    fundDefinitionWasRetried: false,
    fundDefinitionIsCorrected: false,
    fundNetAssetValueAnswer: undefined,
    fundNetAssetValueIsCorrect: undefined,
    fundNetAssetValueWasRetried: false,
    fundNetAssetValueIsCorrected: false,
    fundSaleAnswer: undefined,
    fundSaleIsCorrect: undefined,
    fundSaleWasRetried: false,
    fundSaleIsCorrected: false,
    fundVariationAnswer: undefined,
    fundVariationIsCorrect: undefined,
    fundVariationWasRetried: false,
    fundVariationIsCorrected: false,
}); };
