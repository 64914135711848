/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { animated } from 'react-spring';
import { footerContentStyle } from '../style';

const footerHelpStyle: ouiStyle.InStyle = footerContentStyle;

const footerHelpCss = ouiStyle.makeCss(footerHelpStyle);

export function DomInputFooterHelpContainer(
  props: React.PropsWithChildren<{ style: any }>,
) {
  const { style, children } = props;
  return (
    <animated.p css={footerHelpCss} style={style}>
      {children}
    </animated.p>
  );
}
