import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import ouiBase from '@goldwasserexchange/oui';
import { orderedListContainerTopModelsObject } from '../Ordered';
import { DefaultUnorderedListContainer, DefaultUnorderedListWithNoMarkContainer } from '../Unordered';
import {
  DefaultDomHorizontalScrollerContainerPlaceHolder,
  DefaultDomVirtualizedHorizontalListContainerPlaceHolder,
  DefaultDomVirtualizedListContainerPlaceHolder, DefaultVirtualizedOrderedListElementContainer, DefaultVirtualizedUnorderedHorizontalListWithNoMarkElementContainer, DefaultVirtualizedUnorderedListElementContainer, DefaultVirtualizedUnorderedListWithNoMarkElementContainer,
} from './components';

export const domVirtualizedOrderedListDefaultTopModelsObject = {
  ...orderedListContainerTopModelsObject,
  ...ouiBase.List.virtualizedListDefaultTopModelsObject,
  ListContainerPlaceHolder: (
    <TopModel name="ListContainerPlaceHolder">
      {DefaultDomVirtualizedListContainerPlaceHolder}
    </TopModel>
  ),
  ListElementContainer: (
    <TopModel name="ListElementContainer">
      {DefaultVirtualizedOrderedListElementContainer}
    </TopModel>
  ),
};

export const domVirtualizedUnorderedListDefaultTopModelsObject = {
  ...ouiBase.List.virtualizedListDefaultTopModelsObject,
  ListContainer: (
    <TopModel name="ListContainer">{DefaultUnorderedListContainer}</TopModel>
  ),
  ListContainerPlaceHolder: (
    <TopModel name="ListContainerPlaceHolder">
      {DefaultDomVirtualizedListContainerPlaceHolder}
    </TopModel>
  ),
  ListElementContainer: (
    <TopModel name="ListElementContainer">
      {DefaultVirtualizedUnorderedListElementContainer}
    </TopModel>
  ),
};

export const domVirtualizedUnorderedListWithNoMarkDefaultTopModelsObject = {
  ...ouiBase.List.virtualizedListDefaultTopModelsObject,
  ListContainer: (
    <TopModel name="ListContainer">
      {DefaultUnorderedListWithNoMarkContainer}
    </TopModel>
  ),
  ListContainerPlaceHolder: (
    <TopModel name="ListContainerPlaceHolder">
      {DefaultDomVirtualizedListContainerPlaceHolder}
    </TopModel>
  ),
  ListElementContainer: (
    <TopModel name="ListElementContainer">
      {DefaultVirtualizedUnorderedListWithNoMarkElementContainer}
    </TopModel>
  ),
};

export const domVirtualizedUnorderedHorizontalListWithNoMarkDefaultTopModelsObject = {
  ...ouiBase.List.virtualizedListDefaultTopModelsObject,
  ListContainer: (
    <TopModel name="ListContainer">
      {DefaultUnorderedListWithNoMarkContainer}
    </TopModel>
  ),
  ListContainerPlaceHolder: (
    <TopModel name="ListContainerPlaceHolder">
      {DefaultDomVirtualizedHorizontalListContainerPlaceHolder}
    </TopModel>
  ),
  ListElementContainer: (
    <TopModel name="ListElementContainer">
      {DefaultVirtualizedUnorderedHorizontalListWithNoMarkElementContainer}
    </TopModel>
  ),
};

export const domHorizontalScrrollerTopModelsObject = {
  ...ouiBase.List.virtualizedListDefaultTopModelsObject,
  ListContainer: (
    <TopModel name="ListContainer">
      {DefaultUnorderedListWithNoMarkContainer}
    </TopModel>
  ),
  ListContainerPlaceHolder: (
    <TopModel name="ListContainerPlaceHolder">
      {DefaultDomHorizontalScrollerContainerPlaceHolder}
    </TopModel>
  ),
  ListElementContainer: (
    <TopModel name="ListElementContainer">
      {DefaultVirtualizedUnorderedHorizontalListWithNoMarkElementContainer}
    </TopModel>
  ),
};
