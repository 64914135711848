import * as React from 'react';
import { useAccountPositionTAccLastRefreshDate } from '../../hooks';
import { ActorApiDateDisplay } from '../../../../../../components/display/date';

export const AccountPositionTAccLastRefreshDate = () => {
  const {
    data,
    isLoading,
    error,
  } = useAccountPositionTAccLastRefreshDate();
  return (
    <ActorApiDateDisplay
      data={data}
      isLoading={isLoading}
      error={error}
      format="dd/MM/yyyy, HH:mm:SS"
    />
  );
};
