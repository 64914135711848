import * as React from 'react';
import { Search, X } from 'react-feather';
import { SearchPlaceIconContainer } from './components';

export const SearchPlaceIcon = React.forwardRef((props: {
  inputRef: React.RefObject<HTMLInputElement>,
  focus: boolean,
  open: boolean,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
}) => {
  const {
    inputRef,
    focus,
    open,
    setOpen,
  } = props;
  return (
    <SearchPlaceIconContainer
      inputRef={inputRef}
      open={open}
      focus={focus}
      setOpen={setOpen}
    >
      {
        open
          ? <X />
          : <Search />
      }
    </SearchPlaceIconContainer>
  );
});
