/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { usePopperArrowContext, usePopperContainerPlacementStart } from '../../../../../../../../../OUI/utils';

const helpInputPopperArrowContainerStyle: ouiStyle.InStyle = {
  position: 'absolute',
};

const helpInputPopperArrowContainerCss = ouiStyle.makeCss(helpInputPopperArrowContainerStyle);

export const HelpInputPopperArrowContainer = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  const placementStart = usePopperContainerPlacementStart();
  const [setArrowElement, arrowStyles = {}] = usePopperArrowContext() ?? [];
  const css = React.useMemo(() => {
    const offsetLength = 'calc(-0.5rem + 1px)';
    return ({
      ...arrowStyles,
      ...helpInputPopperArrowContainerCss,
      ...(placementStart === 'top' ? { bottom: offsetLength } : {}),
      ...(placementStart === 'bottom' ? { top: offsetLength } : {}),
      ...(placementStart === 'left' ? { right: offsetLength } : {}),
      ...(placementStart === 'right' ? { left: offsetLength } : {}),
    });
  }, [placementStart, helpInputPopperArrowContainerCss, arrowStyles]);
  return (
    <div ref={setArrowElement} css={css} style={{ ...arrowStyles }}>
      {children}
    </div>
  );
};
