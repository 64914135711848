/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';

const languageSelectoListStyle: ouiStyle.InStyle = {};

const toolbarDropDownListCss = ouiStyle.makeCss(languageSelectoListStyle);

export const ToolbarDropDownListWrapper = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  return (
    <ul
      css={toolbarDropDownListCss}
    >
      {children}
    </ul>
  );
};
