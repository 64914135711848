var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useContext } from 'react';
import { scrollTopContext } from './context';
export var useScrollTop = function () {
    var _a = __read(useContext(scrollTopContext), 1), scrollTop = _a[0];
    return scrollTop;
};
export var useOnScroll = function () {
    var _a = __read(useContext(scrollTopContext), 2), onScroll = _a[1];
    return onScroll;
};
export var useSetScrollTop = function () {
    var _a = __read(useContext(scrollTopContext), 3), setScrollTop = _a[2];
    return setScrollTop;
};
