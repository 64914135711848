import * as Yup from 'yup';
import { UncastedDataStructure, UncastedOnboarding } from '@goldwasserexchange/oblis-frontend-utils';
import { isRepresentativeExistingTitularValidator } from '../Sections/onboarding/form/AML/physic/representative/isExistingTitularInput';
import { representativeSelectInputValidator } from '../Sections/onboarding/form/AML/physic/representative/representativeSelectInput';
import { fiscalityValidator } from '../Sections/onboarding/form/Fiscality';
import { idRiskPrRiskKeyValidator } from '../Sections/onboarding/form/KYC';
import {
  consentValidator,
} from '../Sections/onboarding/form/orientation/Confirmation';
import {
  serviceSelectionValidator,
} from '../Sections/onboarding/form/orientation/ServiceSelection';
import {
  personTypeValidator,
} from '../Sections/onboarding/form/personType';
import {
  tAccPreferenceValidator,
} from '../Sections/onboarding/form/preferences';
import {
  financialSituationValidator,
} from '../Sections/onboarding/form/questionnaires/FinancialSituationQuestionnaire';
import {
  investmentGoalsValidator,
} from '../Sections/onboarding/form/questionnaires/InvestmentGoalsQuestionnaire';
import {
  knowledgeQuestionnaireValidator,
} from '../Sections/onboarding/form/questionnaires/KnowledgeAndExperienceQuestionnaire';
import {
  productsValidator, serviceAccessibilityValidator,
} from '../Sections/onboarding/form/questionnaires/KnowledgeAndExperienceQuestionnaire/products';
import {
  communicationMethodsValidator,
} from '../tAcc/inputs/communicationMethods';
import {
  makeTAddsValidator,
  TAddValidator,
} from '../tAdd';
import { addWhenAlwaysCast } from '../Form/validations/whenAlwaysCast';
import {
  submitTypeValidator, transferValidator, tAccIdValidator, tAddIdValidator,
} from '../Sections/onboarding/form/auth';
import { orientationQuestionnaireValidator } from '../Sections/onboarding/form/orientation/Questionnaire';
import { authValidator } from '../Sections/onboarding/form/auth/validator';
import {
  booleanValidator, stringValidator, numberValidator,
} from '../../OUI/Inputs';
import { openPremiumAccountValidator } from '../AccountType/openPremiumAccount';
import { durabilityValidator } from '../Sections/onboarding/form/questionnaires/DurabilityQuestionnaire';
import { howDidYouHearAboutUsValidator } from '../Sections/onboarding/form/preferences/components';

addWhenAlwaysCast();

type OnboardingValidatorShape = Record<keyof UncastedOnboarding, any>;

const onboardingShape: OnboardingValidatorShape = {
  version: numberValidator,
  language: stringValidator,
  onboardingId: stringValidator,
  save: booleanValidator,
  isPrint: booleanValidator,
  serviceAccessibility: serviceAccessibilityValidator,
  users: makeTAddsValidator(),
  orientation: orientationQuestionnaireValidator,
  selectedService: serviceSelectionValidator,
  consent: consentValidator,
  ID_RISK_PR_RISK: idRiskPrRiskKeyValidator,
  products: productsValidator,
  knowledgeQuestionnaire: knowledgeQuestionnaireValidator,
  financialSituation: financialSituationValidator,
  investmentGoals: investmentGoalsValidator,
  durability: durabilityValidator,
  personType: personTypeValidator,
  moralPerson: TAddValidator,
  fiscality: fiscalityValidator,
  T_ACC: tAccPreferenceValidator,
  communicationType: communicationMethodsValidator,
  isRepresentativeExistingTitular: isRepresentativeExistingTitularValidator,
  legalRepresentative: representativeSelectInputValidator,
  howDidYouHearAboutUs: howDidYouHearAboutUsValidator,
  stepReachedKYC_RTO_Product_Selection: booleanValidator,
  stepReachedKYC_productSelection: booleanValidator,
  stepReachedAML_moral_mandatariesBeneficiaries: booleanValidator,
  stepReachedinfo_questionnaire: booleanValidator,
  stepReachedAML_physic_titularsProcuration: booleanValidator,
  stepReachedAML_moral_beneficiaries: booleanValidator,
  stepReachedAML_physic_procurations: booleanValidator,
  stepReachedKYC_confirmation: booleanValidator,
  stepReachedActivation: booleanValidator,
  onboardingSubmitted: booleanValidator,
  openPremiumAccount: openPremiumAccountValidator,
  submittedDate: Yup.string().transform(() => new Date().toISOString()),
};

type ValidatorShape = Record<keyof UncastedDataStructure, any>;

const validatorShape: ValidatorShape = {
  submitType: submitTypeValidator,
  transfer: transferValidator,
  tAccId: tAccIdValidator,
  tAddId: tAddIdValidator,
  auth: authValidator,
  onboarding: Yup.object().shape(onboardingShape),
};

export const validator = Yup.object().shape(validatorShape);
