import * as React from 'react';
import { Trans } from '@lingui/macro';
import {
  UncastedDataStructure, UncastedOnboarding,
} from '@goldwasserexchange/oblis-frontend-utils';
import { mixed } from 'yup';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { tAddTitularsNeedRepresentative, isNoneOfRepresentativeMinorOrDisabledTitular } from '../../../../../../tAdd/utils';
import { YesNoInput } from '../../../../../../../OUI-dom/Inputs/components/yesNo';
import {
  booleanValidator, makeFalseCast, makeRequired, nilValidator,
} from '../../../../../../../OUI/Inputs';

const isRepresentativeExistingTitularValidatorDependencyPath: (keyof UncastedOnboarding)[] = [
  'users',
];

export const isRepresentativeExistingTitularValidator = mixed().when(isRepresentativeExistingTitularValidatorDependencyPath, (users) => {
  const needRepresentative = tAddTitularsNeedRepresentative(users);
  const availableTitularsLength = users.filter((x) => isNoneOfRepresentativeMinorOrDisabledTitular(x)).length;
  if (availableTitularsLength === 0) {
    return makeFalseCast(booleanValidator);
  }
  if (needRepresentative) {
    return makeRequired(booleanValidator);
  }
  return nilValidator;
});

const isRepresentativeExistingTitularLabel = <Trans>Le représentant légal est une personne déjà existante dans la relation</Trans>;

const label = (
  <TopModel name="RadioListInputLabelContent">
    {isRepresentativeExistingTitularLabel}
  </TopModel>
);

const isExistingTitularFieldPath: `${keyof Pick<UncastedDataStructure, 'onboarding'>}.${keyof (Pick<UncastedOnboarding, 'isRepresentativeExistingTitular'>)}` = 'onboarding.isRepresentativeExistingTitular';

export const IsRepresentativeExistingTitularInput = (): JSX.Element => (
  <YesNoInput
    fieldPath={isExistingTitularFieldPath}
  >
    {label}
  </YesNoInput>
);
