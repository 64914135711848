import { useContext } from 'react';
import {
  toolbarDropDownOpenSpringContext,
  toolbarDropDownOpenContext,
  toolbarDropDownSetOpenContext,
  toolbarDropDownListContext,
} from './context';

export const useToolbarDropDownOpenSpring = () => {
  const openSpring = useContext(toolbarDropDownOpenSpringContext);
  if (openSpring === null) {
    throw new Error('you should be in the toolbarDropDownOpenContext to use the open value');
  }
  return openSpring;
};

export const useToolbarDropDownOpen = () => {
  const open = useContext(toolbarDropDownOpenContext);
  if (open === null) {
    throw new Error('you should be in the toolbarDropDownOpenContext to use the open value');
  }
  return open;
};

export const useToolbarDropDownSetOpen = () => {
  const setOpen = useContext(toolbarDropDownSetOpenContext);
  return setOpen;
};

export const useToolbarDropdownList = () => {
  const list = useContext(toolbarDropDownListContext);
  return list;
};
