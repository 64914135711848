import * as React from 'react';
import ouiDom from '@goldwasserexchange/oui-dom';
import ouiStyle from '@goldwasserexchange/oui-style';

export const SanityDel = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  return (
    <ouiDom.TextSnippet.TextSnippet
      color={ouiStyle.Constants.Colors.inherit}
      fontSize="inherit"
      fontStyle="inherit"
      textDecoration="line-through"
      fontWeight={ouiStyle.Constants.FontWeightProp.inherit}
    >
      {children}
    </ouiDom.TextSnippet.TextSnippet>
  );
};
