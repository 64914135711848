import { useCallback } from 'react';
import { useLingui } from '@lingui/react';
import { useFilterByPrimaryDimensionDeviceId, makeGetBrowserNameWithTranslation } from '../utils';
import { usePersonDeviceTransformApi } from './api';

export const usePersonDevicesBrowsername = () => {
  const {
    i18n,
  } = useLingui();
  const transformFunction = useCallback(makeGetBrowserNameWithTranslation(i18n), [i18n]);
  const filterPredicate = useFilterByPrimaryDimensionDeviceId();
  const result = usePersonDeviceTransformApi({
    filterPredicate,
    transformFunction,
  });
  return result;
};
