import * as React from 'react';
import ouiBase from '@goldwasserexchange/oui';
import { showLogic as firstNameShowLogic } from '../FIRSTNAME/logic/show';
import { makeName } from '../../../../makeName';
var firstNamePath = 'FIRSTNAME';
var name1Path = 'NAME_1';
export var Label = function (props) {
    var selectedUserPath = props.selectedUserPath, _a = props.upperCase, upperCase = _a === void 0 ? false : _a;
    var FIRSTNAME = ouiBase.Form.useFieldValueByPath(makeName(selectedUserPath, firstNamePath)).get();
    var NAME_1 = ouiBase.Form.useFieldValueByPath(makeName(selectedUserPath, name1Path)).get();
    var firstNameString = upperCase ? FIRSTNAME.toUpperCase() : FIRSTNAME;
    var nameString = upperCase ? NAME_1.toUpperCase() : NAME_1;
    return (React.createElement(React.Fragment, null,
        React.createElement(ouiBase.utils.hookstate.FormFilterLogicVisible, { logic: firstNameShowLogic, parentName: selectedUserPath },
            firstNameString,
            ' '),
        nameString));
};
