import { Locale } from '@goldwasserexchange/i18n';
import { setLink } from './setLink';

export const setLinks = (props: {
  head: HTMLHeadElement,
  href?: string,
  frHref?: string,
  nlHref?: string,
}) => {
  const {
    head,
    href,
    frHref,
    nlHref,
  } = props;
  setLink({
    head,
    rel: 'alternate',
    href: frHref,
    language: Locale.FR,
  });
  setLink({
    head,
    rel: 'alternate',
    href: nlHref,
    language: Locale.NL,
  });
  setLink({
    head,
    rel: 'alternate',
    href: frHref ?? nlHref,
    language: 'x-default',
  });
  setLink({
    head,
    rel: 'canonical',
    href,
  });
};
