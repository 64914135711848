var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { PassThrough } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
export var DomHeadingSectionHeadingContainer = PassThrough;
var domHeadingSectionHeadingContainerWithBackgroundStyle = {
    backgroundSize: 'cover',
    backgroundRepeat: ouiStyle.Constants.BackgroundRepeatProps.noRepeat,
    paddingVertical: ['simple', undefined, undefined, 'double', undefined, undefined],
    paddingHorizontal: 'simple',
    textAlign: 'center',
};
var domHeadingContentStyle = {
    container: ouiStyle.Constants.Container.lg,
    textAlign: 'center',
    paddingHorizontal: 'simple',
    paddingTop: ['none', undefined, undefined, 'double', undefined, undefined],
    paddingBottom: ['none', undefined, undefined, 'double', undefined, undefined],
    lines: 'full',
};
export var DomHeadingSectionHeadingContainerWithBackground = function (props) {
    var display = props.display, flexDirection = props.flexDirection, alignItems = props.alignItems, justifyContent = props.justifyContent, backgroundImage = props.backgroundImage, _a = props.backgroundSize, backgroundSize = _a === void 0 ? domHeadingSectionHeadingContainerWithBackgroundStyle.backgroundSize : _a, backgroundPosition = props.backgroundPosition, _b = props.imagePaddingTop, imagePaddingTop = _b === void 0 ? domHeadingSectionHeadingContainerWithBackgroundStyle.paddingTop : _b, _c = props.imagePaddingBottom, imagePaddingBottom = _c === void 0 ? domHeadingSectionHeadingContainerWithBackgroundStyle.paddingBottom : _c, _d = props.paddingTop, paddingTop = _d === void 0 ? domHeadingContentStyle.paddingTop : _d, _e = props.paddingBottom, paddingBottom = _e === void 0 ? domHeadingContentStyle.paddingBottom : _e, height = props.height, children = props.children;
    var css = React.useMemo(function () { return (__assign(__assign({}, ouiStyle.makeCss(__assign(__assign({}, domHeadingSectionHeadingContainerWithBackgroundStyle), { backgroundImage: backgroundImage, backgroundSize: backgroundSize, backgroundPosition: backgroundPosition, paddingTop: imagePaddingTop, paddingBottom: imagePaddingBottom }))), { height: height })); }, [
        backgroundImage,
        backgroundSize,
        backgroundPosition,
        imagePaddingTop,
        imagePaddingBottom,
    ]);
    var contentCss = React.useMemo(function () { return (__assign({}, ouiStyle.makeCss(__assign(__assign({}, domHeadingContentStyle), { display: display, flexDirection: flexDirection, alignItems: alignItems, justifyContent: justifyContent, paddingTop: paddingTop, paddingBottom: paddingBottom })))); }, [
        display,
        flexDirection,
        alignItems,
        justifyContent,
        paddingTop,
        paddingBottom,
    ]);
    return (jsx("div", { css: ouiStyle.mediaQuery(css) },
        jsx("div", { css: ouiStyle.mediaQuery(contentCss) }, children)));
};
