import React, { useMemo } from 'react';
import {
  dataStructure, UncastedDataStructure,
} from '@goldwasserexchange/oblis-frontend-utils';
import ouiBase from '@goldwasserexchange/oui';
import { useHookstate } from '@hookstate/core';

export const LazyProductSelection = React.lazy(() => import('./component'));

export const useProductFilterFn = (list: dataStructure.financialProductsSelection.listType[]) => {
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const selectedService = useHookstate(valueState.onboarding.selectedService).get();
  const filteredList = useMemo(
    () => (selectedService ? dataStructure.financialProducts.filter.filterProductListByService(list, selectedService) : list),
    [list, selectedService],
  );
  return filteredList;
};
