import { RampWithNameTransform } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';

export function DefaultListInputListElementButtonHandler() {
  return (
    <>
      <RampWithNameTransform rampName="ElementInput" />
      <RampWithNameTransform rampName="ElementButtonContainerContext">
        <RampWithNameTransform rampName="ElementButtonContainer">
          <RampWithNameTransform rampName="ElementButtonContent" />
        </RampWithNameTransform>
      </RampWithNameTransform>
    </>
  );
}
