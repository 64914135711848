var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { i18n } from '@goldwasserexchange/i18n';
import ouiBase from '@goldwasserexchange/oui';
import { Ramp, TopModelChildrenMerge } from '@goldwasserexchange/react-topmodel';
import { AccountType, DurabilityPreferences, } from '../../types';
var onboardingPath = 'onboarding';
var isPrintPath = 'isPrint';
var selectedService = 'selectedService';
var durability = 'durability';
var preferences = 'preferences';
export var resultLogic = {
    name: "".concat(onboardingPath, ".").concat(isPrintPath),
    is: false,
    alternatives: {
        truthy: {
            name: "".concat(onboardingPath, ".").concat(selectedService),
            is: AccountType.GESTION,
            alternatives: {
                truthy: {
                    name: "".concat(onboardingPath, ".").concat(durability, ".").concat(preferences),
                    is: DurabilityPreferences.FALSE,
                    alternatives: {
                        truthy: {
                            result: 'onlineGlobalPlus1',
                        },
                        falsy: {
                            name: "".concat(onboardingPath, ".").concat(durability, ".").concat(preferences),
                            is: DurabilityPreferences.NON_SPECIFIC_TRUE,
                            alternatives: {
                                truthy: {
                                    result: 'onlineGlobalPlus2',
                                },
                                falsy: {
                                    name: "".concat(onboardingPath, ".").concat(durability, ".").concat(preferences),
                                    is: DurabilityPreferences.SPECIFIC_TRUE,
                                    alternatives: {
                                        truthy: {
                                            result: 'onlineGlobalPlus3',
                                        },
                                        falsy: {
                                            result: 'none',
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
                falsy: {
                    name: "".concat(onboardingPath, ".").concat(selectedService),
                    is: AccountType.CONSEIL,
                    alternatives: {
                        truthy: {
                            name: "".concat(onboardingPath, ".").concat(durability, ".").concat(preferences),
                            is: DurabilityPreferences.FALSE,
                            alternatives: {
                                truthy: {
                                    result: 'onlinePremium1',
                                },
                                falsy: {
                                    name: "".concat(onboardingPath, ".").concat(durability, ".").concat(preferences),
                                    is: DurabilityPreferences.NON_SPECIFIC_TRUE,
                                    alternatives: {
                                        truthy: {
                                            result: 'onlinePremium2',
                                        },
                                        falsy: {
                                            name: "".concat(onboardingPath, ".").concat(durability, ".").concat(preferences),
                                            is: DurabilityPreferences.SPECIFIC_TRUE,
                                            alternatives: {
                                                truthy: {
                                                    result: 'onlinePremium3',
                                                },
                                                falsy: {
                                                    result: 'none',
                                                },
                                            },
                                        },
                                    },
                                },
                            },
                        },
                        falsy: {
                            result: 'none',
                        },
                    },
                },
            },
        },
        falsy: {
            name: "".concat(onboardingPath, ".").concat(selectedService),
            is: AccountType.GESTION,
            alternatives: {
                truthy: {
                    name: "".concat(onboardingPath, ".").concat(durability, ".").concat(preferences),
                    is: DurabilityPreferences.FALSE,
                    alternatives: {
                        truthy: {
                            result: 'printGlobalPlus1',
                        },
                        falsy: {
                            name: "".concat(onboardingPath, ".").concat(durability, ".").concat(preferences),
                            is: DurabilityPreferences.NON_SPECIFIC_TRUE,
                            alternatives: {
                                truthy: {
                                    result: 'printGlobalPlus2',
                                },
                                falsy: {
                                    name: "".concat(onboardingPath, ".").concat(durability, ".").concat(preferences),
                                    is: DurabilityPreferences.SPECIFIC_TRUE,
                                    alternatives: {
                                        truthy: {
                                            result: 'printGlobalPlus3',
                                        },
                                        falsy: {
                                            result: 'none',
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
                falsy: {
                    name: "".concat(onboardingPath, ".").concat(selectedService),
                    is: AccountType.CONSEIL,
                    alternatives: {
                        truthy: {
                            name: "".concat(onboardingPath, ".").concat(durability, ".").concat(preferences),
                            is: DurabilityPreferences.FALSE,
                            alternatives: {
                                truthy: {
                                    result: 'printPremium1',
                                },
                                falsy: {
                                    name: "".concat(onboardingPath, ".").concat(durability, ".").concat(preferences),
                                    is: DurabilityPreferences.NON_SPECIFIC_TRUE,
                                    alternatives: {
                                        truthy: {
                                            result: 'printPremium2',
                                        },
                                        falsy: {
                                            name: "".concat(onboardingPath, ".").concat(durability, ".").concat(preferences),
                                            is: DurabilityPreferences.SPECIFIC_TRUE,
                                            alternatives: {
                                                truthy: {
                                                    result: 'printPremium3',
                                                },
                                                falsy: {
                                                    result: 'none',
                                                },
                                            },
                                        },
                                    },
                                },
                            },
                        },
                        falsy: {
                            result: 'none',
                        },
                    },
                },
            },
        },
    },
};
var resultTexts = {
    onlineGlobalPlus1: function () { return [
        i18n._('A défaut de préférences en matière de durabilité, Goldwasser Exchange appliquera dans le cadre de son service de gestion discrétionnaire sa propre politique en matière de durabilité. Cette politique est détaillée dans la fiche technique de Global+ sous la rubrique «Durabilité». Le client reconnait avoir pris connaissance et accepté cette politique.'),
    ]; },
    printGlobalPlus1: function () { return [
        i18n._('Selon les informations récoltées, le Client n’a pas de préférence en matière de durabilité des investissements.'),
        i18n._('A défaut de préférences en matière de durabilité, Goldwasser Exchange appliquera dans le cadre de son service de gestion discrétionnaire sa propre politique en matière de durabilité. Cette politique est détaillée dans la fiche technique de Global+ sous la rubrique «Durabilité». Le client reconnait avoir pris connaissance et accepté cette politique.'),
        i18n._('Les informations récoltées concernant les préférences du Client en matière de durabilité des investissements sont reprises dans le document intitulé “Questionnaire concernant vos préférences en matière de durabilité des investissements” repris en annexe (voir annexe 1, partie IV)'),
    ]; },
    onlineGlobalPlus2: function () { return [
        i18n._('Compte tenu de la préférence (non spécifique) exprimée par le client pour les produits «durables», Goldwasser Exchange appliquera dans le cadre de son service de gestion discrétionnaire sa propre politique en matière de durabilité. Cette politique est détaillée dans la fiche technique de Global+ sous la rubrique «Durabilité». Le client accepte reconnais avoir pris connaissance et accepté cette politique.'),
    ]; },
    printGlobalPlus2: function () { return [
        i18n._('Selon les informations récoltées, le client a une préférence (non spécifique) pour les produits durables.'),
        i18n._('Compte tenu de la préférence (non spécifique) exprimée par le client pour les produits «durables», Goldwasser Exchange appliquera dans le cadre de son service de gestion discrétionnaire sa propre politique en matière de durabilité. Cette politique est détaillée dans la fiche technique de Global+ sous la rubrique «Durabilité». Le client accepte reconnais avoir pris connaissance et accepté cette politique.'),
        i18n._('Les informations récoltées concernant les préférences du Client en matière de durabilité des investissements sont reprises dans le document intitulé “Questionnaire concernant vos préférences en matière de durabilité des investissements” repris en annexe (voir annexe 1, partie IV)'),
    ]; },
    onlineGlobalPlus3: function () { return [
        i18n._('En raison du manque de données disponibles sur le marché concernant la « durabilité » des entreprises et produits et en raison de l’incertitude réglementaire qui entoure certains éléments à mettre en œuvre, Goldwasser Exchange n’est pas en mesure de tenir compte des préférences spécifiques exprimées par le Client dans le cadre de son service de gestion discrétionnaire. Sauf contrordre, Goldwasser Exchange appliquera dans le cadre de ce service sa propre politique en matière de durabilité. Cette politique est détaillée dans la fiche technique de Global+ sous la rubrique « Durabilité ». Le client reconnait avoir pris connaissance et accepté cette politique.'),
    ]; },
    printGlobalPlus3: function () { return [
        i18n._('Selon les informations récoltées, le client a une préférence spécifique pour les produits durables.'),
        i18n._('En raison du manque de données disponibles sur le marché concernant la « durabilité » des entreprises et produits et en raison de l’incertitude réglementaire qui entoure certains éléments à mettre en œuvre, Goldwasser Exchange n’est pas en mesure de tenir compte des préférences spécifiques exprimées par le Client dans le cadre de son service de gestion discrétionnaire. Sauf contrordre, Goldwasser Exchange appliquera dans le cadre de ce service sa propre politique en matière de durabilité. Cette politique est détaillée dans la fiche technique de Global+ sous la rubrique « Durabilité ». Le client reconnait avoir pris connaissance et accepté cette politique.'),
        i18n._('Les informations récoltées concernant les préférences du Client en matière de durabilité des investissements sont reprises dans le document intitulé “Questionnaire concernant vos préférences en matière de durabilité des investissements” repris en annexe (voir annexe 1, partie IV)'),
    ]; },
    onlinePremium1: function () { return [
        i18n._('A défaut de préférences en matière de durabilité, le caractère «durable» ou «non durable» des investissements à réaliser sur le présent compte ne sera pas pris en considération dans le cadre de l’évaluation du caractère adéquat desdits investissements au regard du profil d’investisseur du Client. Il ne sera pas non plus tenu compte du caractère «durable» ou «non durable» des investissements dans le cadre des Recommandations Personnalisées d’Investissement qui seront fournies par Goldwasser Exchange au Client.'),
    ]; },
    printPremium1: function () { return [
        i18n._('Selon les informations récoltées, le Client n’a pas de préférence en matière de durabilité des investissements.'),
        i18n._('A défaut de préférences en matière de durabilité, le caractère «durable» ou «non durable» des investissements à réaliser sur le présent compte ne sera pas pris en considération dans le cadre de l’évaluation du caractère adéquat desdits investissements au regard du profil d’investisseur du Client. Il ne sera pas non plus tenu compte du caractère «durable» ou «non durable» des investissements dans le cadre des Recommandations Personnalisées d’Investissement qui seront fournies par Goldwasser Exchange au Client.'),
        i18n._('Les informations récoltées concernant les préférences du Client en matière de durabilité des investissements sont reprises dans le document intitulé “Questionnaire concernant vos préférences en matière de durabilité des investissements” repris en annexe (voir annexe 1, partie IV)'),
    ]; },
    onlinePremium2: function () { return [
        i18n._('Compte tenu de la préférence (non spécifique) exprimée par le client pour les produits «durables», Goldwasser Exchange s’efforcera, dans le cadre des Recommandations Personnalisées d’Investissement qui seront fournies au client, de privilégier des investissements reconnus comme durables au regard de la Taxonomie européenne, de la règlementation SFDR ou de tout autre label de durabilité ou système de classification largement reconnu.'),
        i18n._('Il est en outre précisé qu’en raison du manque de données disponibles sur le marché concernant la « durabilité » des entreprises et produits et en raison de l’incertitude réglementaire qui entoure certains éléments à mettre en œuvre, le caractère «durable» ou «non durable» des investissements à réaliser sur le présent compte ne sera pas pris en considération dans le cadre de l’évaluation du caractère adéquat desdits investissements au regard du profil d’investisseur du Client.'),
    ]; },
    printPremium2: function () { return [
        i18n._('Selon les informations récoltées, le client a une préférence (non spécifique) pour les produits durables.'),
        i18n._('Compte tenu de la préférence (non spécifique) exprimée par le client pour les produits «durables», Goldwasser Exchange s’efforcera, dans le cadre des Recommandations Personnalisées d’Investissement qui seront fournies au client, de privilégier des investissements reconnus comme durables au regard de la Taxonomie européenne, de la règlementation SFDR ou de tout autre label de durabilité ou système de classification largement reconnu.'),
        i18n._('Il est en outre précisé qu’en raison du manque de données disponibles sur le marché concernant la « durabilité » des entreprises et produits et en raison de l’incertitude réglementaire qui entoure certains éléments à mettre en œuvre, le caractère «durable» ou «non durable» des investissements à réaliser sur le présent compte ne sera pas pris en considération dans le cadre de l’évaluation du caractère adéquat desdits investissements au regard du profil d’investisseur du Client.'),
        i18n._('Les informations récoltées concernant les préférences du Client en matière de durabilité des investissements sont reprises dans le document intitulé “Questionnaire concernant vos préférences en matière de durabilité des investissements” repris en annexe (voir annexe 1, partie IV)'),
    ]; },
    onlinePremium3: function () { return [
        i18n._('Compte tenu des préférences spécifiques exprimées par le Client pour les produits durables, Goldwasser Exchange s’efforcera de privilégier dans le cadre des Recommandations Personnalisées d’Investissement qui seront fournies au Client des investissements qui correspondent, si possibles, aux préférences spécifiques exprimées par le client.'),
        i18n._('Il est en outre précisé qu’en raison du manque de données disponibles sur le marché concernant la « durabilité » des entreprises et produits et en raison de l’incertitude réglementaire qui entoure certains éléments à mettre en œuvre, le caractère «durable» ou «non durable» des investissements à réaliser sur le présent compte ne sera pas pris en considération dans le cadre de l’évaluation du caractère adéquat desdits investissements au regard du profil d’investisseur du Client.'),
    ]; },
    printPremium3: function () { return [
        i18n._('Compte tenu des préférences spécifiques exprimées par le Client pour les produits durables, Goldwasser Exchange s’efforcera de privilégier dans le cadre des Recommandations Personnalisées d’Investissement qui seront fournies au Client des investissements qui correspondent, si possibles, aux préférences spécifiques exprimées par le client.'),
        i18n._('Il est en outre précisé qu’en raison du manque de données disponibles sur le marché concernant la « durabilité » des entreprises et produits et en raison de l’incertitude réglementaire qui entoure certains éléments à mettre en œuvre, le caractère «durable» ou «non durable» des investissements à réaliser sur le présent compte ne sera pas pris en considération dans le cadre de l’évaluation du caractère adéquat desdits investissements au regard du profil d’investisseur du Client.'),
        i18n._('Les informations récoltées concernant les préférences du Client en matière de durabilité des investissements sont reprises dans le document intitulé “Questionnaire concernant vos préférences en matière de durabilité des investissements” repris en annexe (voir annexe 1, partie IV)'),
    ]; },
    none: function () { return null; },
};
export var useResultTexts = function (resultTextsOverride) {
    var key = ouiBase.utils.hookstate.useFormFilterLogic(resultLogic, durability);
    var mergedResultTexts = __assign(__assign({}, resultTexts), (resultTextsOverride !== null && resultTextsOverride !== void 0 ? resultTextsOverride : {}));
    var texts = mergedResultTexts[key]();
    return texts;
};
var InnerResultTexts = function (props) {
    var resultTextsOverride = props.resultTextsOverride;
    var texts = useResultTexts(resultTextsOverride);
    if (texts == null) {
        return null;
    }
    return (React.createElement(React.Fragment, null, texts.map(function (text, id) {
        var key = typeof text === 'string' ? text : id;
        return (React.createElement(Ramp, { key: key, rampName: "ResultParagraph" }, text));
    })));
};
export var ResultTexts = function (props) {
    var resultTextsOverride = props.resultTextsOverride, children = props.children;
    return (React.createElement(TopModelChildrenMerge, null,
        children,
        React.createElement(InnerResultTexts, { resultTextsOverride: resultTextsOverride })));
};
