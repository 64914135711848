import { useContext, useMemo } from 'react';
import { GetAccountsPositionsParams } from '@goldwasserexchange/actor/rest-services';
import { useTAccId } from '../../../shared';
import { stringifyParams } from '../../../shared/utils/makeUrl';
import { accountsPositionsParamsContext } from '../context';
import { useIsConnected } from '../../../../../../Components/Auth';

export const useAccountPositionsDefaultParams = (params: Partial<GetAccountsPositionsParams>): Partial<GetAccountsPositionsParams> => {
  const contextParams = useContext(accountsPositionsParamsContext) ?? {} as Partial<GetAccountsPositionsParams>;
  const tAccIdString = useTAccId();
  const currentTAccId = tAccIdString ? parseInt(tAccIdString, 10) : undefined;
  const tAccId = params['T_ACC.ID'] ?? contextParams['T_ACC.ID'] ?? currentTAccId;
  const defaultParams = useMemo(() => ({
    'T_ACC.ID': tAccId,
  }), [tAccId]);
  return defaultParams;
};

const useAccountPositionsParams = (): GetAccountsPositionsParams | null => {
  const defaultParams = useAccountPositionsDefaultParams({});
  const tAccId = defaultParams['T_ACC.ID'];
  const isConnected = useIsConnected();
  if (tAccId == null || !isConnected) {
    return null;
  }
  return {
    'T_ACC.ID': tAccId,
    'GES_ESTIM_D.MC_ESTIMTYPE': defaultParams['GES_ESTIM_D.MC_ESTIMTYPE'],
  };
};

export const useAccountPositionsStringifiedParams = () => {
  const contextParams = useAccountPositionsParams();
  return contextParams !== null ? stringifyParams(contextParams) : null;
};
