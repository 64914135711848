import * as React from 'react';
import { invertedContext } from './context';

export const InvertedContextProvider = (props: React.PropsWithChildren<{ inverted?: boolean }>) => {
  const { inverted = false, children } = props;
  return (
    <invertedContext.Provider value={inverted}>
      {children}
    </invertedContext.Provider>
  );
};
