import * as TYPE from '../../TYPE';
var onlyOneFiscalResidency = 'onlyOneFiscalResidency';
export var showLogic = TYPE.logic.makeTAddIsNotMoralOrIgnored({
    truthy: {
        name: "<parentName>.".concat(onlyOneFiscalResidency),
        is: false,
        alternatives: {
            truthy: {
                result: 'visible',
            },
            falsy: {
                result: 'invisible',
            },
        },
    },
});
export var showBooleanLogic = TYPE.logic.makeTAddIsNotMoralOrIgnored({
    truthy: {
        name: "<parentName>.".concat(onlyOneFiscalResidency),
        is: false,
        alternatives: {
            truthy: {
                result: true,
            },
            falsy: {
                result: false,
            },
        },
    },
});
