import * as React from 'react';
import { dataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import { Trans } from '@lingui/macro';
import { IconName } from '../../../../../../Icons';
import { makeGoTo, makeNext, makePrev } from '../../../StateMachine/utils';
import { productNamesMap } from '../../../questionnaires/KnowledgeAndExperienceQuestionnaire/shared/productNamesMap';

export const knowledgeAndExperienceSummaryStateMachine = (service: dataStructure.accountType.listType, prev: string) => ({
  meta: {
    step: true,
    title: () => <Trans>Confirmation</Trans>,
    icon: IconName.Check,
  },
  entry: [{ type: 'setStepReached', stepName: 'KYC_confirmation' }],
  initial: 'initial',
  states: {
    initial: {
      on: {
        '': [
          {
            target: 'setServiceAccessibilityTrue',
            cond: {
              type: 'formFilterLogic',
              logic: dataStructure.financialProductsKnowledge.logic.hasKnowledge.make.makeIsQuestionnairesRequiredSuccessLogic(),
              parentName: '',
            },
          },
          {
            target: 'setServiceAccessibilityFalse',
          },
        ],
      },
    },
    setServiceAccessibilityFalse: {
      invoke: {
        src: 'setServiceAccessibilityFalse',
        onDone: 'failed',
        onError: 'failed',
      },
    },
    setServiceAccessibilityTrue: {
      invoke: {
        src: 'setServiceAccessibilityTrue',
        onDone: 'notFailed',
        onError: 'notFailed',
      },
    },
    failed: {
      on: makeGoTo('#onboarding.orientation', 'serviceSelection', { fullPath: true }),
    },
    notFailed: {
      on: makeNext('done'),
    },
    done: {
      type: 'final' as const,
    },
  },
  onDone: 'done',
  on: {
    // we never want to go back to productQuestionnaire from confirmation
    ...makePrev(prev),
    ...(dataStructure.financialProducts.filter.filterProductListByService(dataStructure.financialProducts.list, service).reduce((acc, product) => ({
      ...acc,
      [`VIEW_QUESTIONNAIRE_${product.toUpperCase()}`]: {
        target: `#${service}${productNamesMap[product]}Questionnaire.notSkipped.questions`,
      },
    }), {})),
  },
});
