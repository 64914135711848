import { atom } from 'jotai';
import { pathnamePartFamilly } from '../../../atoms';
import { getValidOnboardingSection } from './utils';

export const pathnameRawUrlOnboardingSectionAtom = atom((get) => {
  const subSection = get(pathnamePartFamilly(2));
  if (subSection == null) {
    return '';
  }
  return subSection;
});

export const pathnameUrlOnboardingSectionAtom = atom((get) => {
  const subSection = get(pathnameRawUrlOnboardingSectionAtom);
  return getValidOnboardingSection(subSection);
});
