import { dataStructure, STypeGrp } from '@goldwasserexchange/oblis-frontend-utils';

export const makeQuestionnaireSkips = (questionnaireName: dataStructure.financialProducts.listType, product: STypeGrp) => [
  {
    type: 'questionnaireFailedAndRetried',
    product,
  },
  {
    type: 'formFilterLogic',
    logic: dataStructure.financialProducts.logic.KERequired.getMakeKERequiredLogic(questionnaireName)({
      alternatives: {
        required: {
          result: false,
        },
        free: {
          result: false,
        },
        notRequired: {
          result: true,
        },
      },
    }),
    parentName: '',
  },
  {
    type: 'formFilterLogic',
    logic: dataStructure.financialProductsKnowledge.logic.hasKnowledge.make.makeIsQuestionnaireCorrectLogic(
      questionnaireName,
      {
        noQuestions: {
          result: false,
        },
        notSelected: {
          result: false,
        },
        notAnswered: {
          result: false,
        },
        success: {
          result: true,
        },
        failure: {
          result: false,
        },
        failureWithRetryAvailable: {
          result: false,
        },
        failureWithRetryAvailableOnRequired: {
          result: false,
        },
        retriedFailureOnRequired: {
          result: true,
        },
        truthy: {
          result: true,
        },
        falsy: {
          result: false,
        },
      },
    ),
    parentName: '',
  },
  ...(dataStructure.financialProductsSelection.dependencies.byStatus.getter.getFinancialProductDependenciesByStatus(questionnaireName)).map((statusDependency) => ({
    type: 'formFilterLogic',
    logic: dataStructure.financialProductsKnowledge.logic.hasKnowledge.make.makeIsQuestionnaireCorrectLogic(
      statusDependency,
      {
        noQuestions: {
          result: false,
        },
        notSelected: {
          result: false,
        },
        notAnswered: {
          result: false,
        },
        success: {
          result: false,
        },
        failure: {
          result: false,
        },
        failureWithRetryAvailable: {
          result: false,
        },
        failureWithRetryAvailableOnRequired: {
          result: false,
        },
        retriedFailureOnRequired: {
          result: true,
        },
        truthy: {
          result: true,
        },
        falsy: {
          result: false,
        },
      },
    ),
    parentName: '',
  })),
  {
    type: 'isProductSelection',
    product: questionnaireName,
    is: true,
    options: { isNegative: true },
  },
];
