import * as React from 'react';
import { ActiveToggleIcon, DisabledToggleIcon } from './components';

export const ToggleIcon = (props: {
  value: boolean,
  disabled?: boolean,
  touched: boolean,
}) => {
  const {
    value,
    disabled,
    touched,
  } = props;
  return !disabled
    ? (
      <ActiveToggleIcon value={value} touched={touched} />
    )
    : (
      <DisabledToggleIcon />
    );
};
