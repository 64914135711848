import * as React from 'react';
import { TopModelMergeNameTransformed, } from '@goldwasserexchange/react-topmodel';
import { defaultListElementContextContainerTopModelsObject, defaultListElementSubContextsTopModelsObject, } from './topmodels';
export function DefaultListElementContextContainerTopModelsProvider(props) {
    var children = props.children, order = props.order;
    return (React.createElement(TopModelMergeNameTransformed, { topModels: defaultListElementContextContainerTopModelsObject, order: order }, children));
}
export function DefaultListElementSubContextsTopModelsProvider(props) {
    var children = props.children, order = props.order, transformBeforeFn = props.transformBeforeFn;
    return (React.createElement(TopModelMergeNameTransformed, { topModels: defaultListElementSubContextsTopModelsObject, order: order, transformBeforeFn: transformBeforeFn }, children));
}
export function DefaultListElementContextTopModelsProvider(props) {
    var children = props.children;
    return (React.createElement(DefaultListElementContextContainerTopModelsProvider, null,
        React.createElement(DefaultListElementSubContextsTopModelsProvider, null, children)));
}
