import { dataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import ouiBase from '@goldwasserexchange/oui';

export const useQuestionnaireServiceSelectionDisabledReasonByProductNames = (productName: dataStructure.financialProducts.listType, ignore?: ('Required' | 'NotRequired')[]) => {
  const frozenLogic = ouiBase.utils.freeze.useFreeze(dataStructure.financialProducts.logic.KERequired.getMakeKERequiredLogic(productName)<'Required' | 'NotRequired' | ''>({
    alternatives: {
      required: {
        result: 'Required',
      },
      free: {
        result: '',
      },
      notRequired: {
        result: 'NotRequired',
      },
    },
  }));
  const result = ouiBase.utils.hookstate.useFormFilterLogic(frozenLogic, '');
  if (result !== '' && ignore && ignore.includes(result)) {
    return '';
  }
  return result;
};
