import * as React from 'react';
import { ArticleListBoundaries, articleListBoundariesContext } from './context';

export const ArticleListBoundariesProvider = (props: React.PropsWithChildren<{
  value: ArticleListBoundaries,
}>) => {
  const {
    value,
    children,
  } = props;
  return (
    <articleListBoundariesContext.Provider value={value}>
      {children}
    </articleListBoundariesContext.Provider>
  );
};
