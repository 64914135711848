import { useCallback } from 'react';
import ouiDom from '@goldwasserexchange/oui-dom';
import { useAtomValue } from 'jotai';
import { useAuthMachineSend, useIsConnected } from '../../../../Components/Auth';
import { SignInModalName } from '../../../../Components/Auth/components/signinModal';
import { useSetCurrentModalValue } from '../../../../Components/Modal';
import { useCognitoUserValidAccounts } from '../../../../Components/Sections/online/selectedAccount';
import { ValidSections, pathnameUrlLanguageAtom } from '../../../../history';

export const useUnlockRedactedOnClick = () => {
  const send = useAuthMachineSend();
  const openSignInModal = useSetCurrentModalValue(SignInModalName);
  const history = ouiDom.A.useHistory();
  const language = useAtomValue(pathnameUrlLanguageAtom);
  const navigateToOnboarding = useCallback(() => {
    history.push(`/${language}/${ValidSections.ONBOARDING}`);
  }, [language, history]);
  const isConnected = useIsConnected();
  const accounts = useCognitoUserValidAccounts();
  const onClick = useCallback(() => {
    if (!isConnected) {
      send('SIGN_IN');
      openSignInModal();
    } else if (accounts.length === 0) {
      navigateToOnboarding();
    }
  }, [send, openSignInModal, isConnected, navigateToOnboarding, accounts]);
  return onClick;
};
