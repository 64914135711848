import * as React from 'react';
import { RampWithNameTransform } from '@goldwasserexchange/react-topmodel';
import ouiBase from '@goldwasserexchange/oui';
import ouiStyle from '@goldwasserexchange/oui-style';
import { UseGetFnProps } from '../../shared';
import { SearchKeysProps } from '../../shared/components/list/context/searchKeys/context';

export function DefaultCheckboxListInputBase(
  props: React.PropsWithChildren<
  {
    fieldPath: string,
    elementFieldPathHook: () => string,
    dependenciesFieldPaths?: string[],
    checkboxPotentialFieldPaths?: string[],
    type: Extract<ouiBase.Input.InputTypes, 'checkbox'>,
    elementLines?: ouiStyle.InStyle['lines'],
    validateFieldPath?: boolean,
  } & SearchKeysProps & UseGetFnProps & ouiBase.List.ListProps & ouiBase.List.ListDataFilterAndSortProviderProps
  >,
) {
  const {
    fieldPath,
    elementFieldPathHook,
    dependenciesFieldPaths = [],
    checkboxPotentialFieldPaths = [],
    validateFieldPath,
    type,
    list,
    length,
    elementLines,
    useFilterFn,
    useSortFn,
    useGetFn,
    searchKeys,
  } = props;
  return (
    <RampWithNameTransform<
    {
      type: Extract<ouiBase.Input.InputTypes, 'radio' | 'checkbox'>,
      fieldPath: string,
      elementFieldPathHook: () => string,
      dependenciesFieldPaths: string[],
      checkboxPotentialFieldPaths?: string[],
      validateFieldPath?: boolean,
      elementLines?: ouiStyle.InStyle['lines'],
    } & SearchKeysProps & UseGetFnProps & ouiBase.List.ListProps
    >
      rampName="InputContextProvider"
      type={type}
      fieldPath={fieldPath}
      elementFieldPathHook={elementFieldPathHook}
      dependenciesFieldPaths={dependenciesFieldPaths}
      validateFieldPath={validateFieldPath}
      checkboxPotentialFieldPaths={checkboxPotentialFieldPaths}
      list={list}
      length={length}
      elementLines={elementLines}
      useFilterFn={useFilterFn}
      useSortFn={useSortFn}
      useGetFn={useGetFn}
      searchKeys={searchKeys}
    >
      <RampWithNameTransform rampName="InputContainer">
        <RampWithNameTransform rampName="InputContent" />
      </RampWithNameTransform>
    </RampWithNameTransform>
  );
}
