import {
  Update,
} from 'history';
import { MessageDescriptor } from '@lingui/core';
import { HeadProps } from '../../type';
import { splitPathname } from '../../../utils';
import { getValidAccountsSection } from '../../../router';
import { ONLINE_SECTIONS_TITLES } from './titles';
import { createHrefs } from '../../utils';
import { getTitleFromMessageDescriptor } from '../../utils/getTitle';

export const makeAccountsSectionHeadProps = (currentUpdate: Update, currentHeadProps: HeadProps) => {
  const headProps = {
    ...currentHeadProps,
  };
  const {
    location,
  } = currentUpdate;
  const {
    pathname,
  } = location;
  const splittedPathname = splitPathname(pathname);
  const {
    language,
  } = headProps;
  const accountsSection = getValidAccountsSection(splittedPathname.at(2));
  const titleMessageDescriptor = ONLINE_SECTIONS_TITLES[accountsSection] as MessageDescriptor;
  headProps.title = getTitleFromMessageDescriptor(titleMessageDescriptor, language);
  const {
    href,
    frHref,
    nlHref,
  } = createHrefs(currentUpdate);
  headProps.href = href;
  headProps.frHref = frHref;
  headProps.nlHref = nlHref;
  return headProps;
};
