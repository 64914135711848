import * as React from 'react';
import ouiDom from '@goldwasserexchange/oui-dom';
import { Provider } from 'jotai';
import { history, store } from './main';

export const HistoryProvider = (props: {
  children: React.ReactNode,
}) => {
  const {
    children,
  } = props;
  return (
    <Provider store={store}>
      <ouiDom.A.HistoryProvider history={history}>
        {children}
      </ouiDom.A.HistoryProvider>
    </Provider>
  );
};
