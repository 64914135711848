import { AccountPosition } from '../../type';

export const latentCapitalGain = (valuation: number, performance?: number) => {
  if (performance == null) {
    return null;
  }
  return (valuation / (1 + (performance / 100))) * (performance / 100);
};

export const computePositionLatentCapitalGain = (position: AccountPosition | null | undefined) => (position ? latentCapitalGain(position['GES_ESTIM_D.ESTIM'], position['GES_ESTIM_D.PC_PV_GLO'] ?? undefined) : 0);

export const sortPositionsByLatentCapitalGain = (prev: AccountPosition, next: AccountPosition) => {
  const prevLatentCapitalGain = computePositionLatentCapitalGain(prev);
  const nextLatentCapitalGain = computePositionLatentCapitalGain(next);
  if (prevLatentCapitalGain === null && nextLatentCapitalGain !== null) {
    return 1;
  }
  if (nextLatentCapitalGain === null && prevLatentCapitalGain !== null) {
    return -1;
  }
  if (prevLatentCapitalGain === null && nextLatentCapitalGain === null) {
    return 0;
  }
  if (prevLatentCapitalGain !== null && nextLatentCapitalGain !== null) {
    return (nextLatentCapitalGain - prevLatentCapitalGain);
  }
  return 0;
};

export const strongestLatentCapitalGainFilter = (position: AccountPosition) => position['GES_ESTIM_D.MC_ESTIMTYPE'] !== 'ESPECES' && (computePositionLatentCapitalGain(position) ?? -1) > 0;
