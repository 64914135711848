import * as TYPE from '../../TYPE';
export var showLogic = TYPE.logic.makeTAddIsNotMoralOrIgnored({});
export var showBooleanLogic = TYPE.logic.makeTAddIsNotMoralOrIgnored({
    truthy: {
        result: true,
    },
    falsy: {
        result: false,
    },
});
