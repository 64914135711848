import React from 'react';
import { Trans } from '@lingui/macro';
import { dataStructure, UncastedPhysicalTAdd } from '@goldwasserexchange/oblis-frontend-utils';

export const tAddCountryFiscalResLabel = <Trans>Résidence fiscale</Trans>;

const FISCAL_RESIDENCY_SAME_AS_RESIDENCY_KEY: keyof Pick<UncastedPhysicalTAdd, 'fiscalResidencySameAsResidency'> = 'fiscalResidencySameAsResidency';

export const tAddCountryFiscalResValidatorLogic = dataStructure.T_ADD.fields.TYPE.logic.makeTAddIsNotMoralOrIgnored({
  truthy: {
    name: `<parentName>.${FISCAL_RESIDENCY_SAME_AS_RESIDENCY_KEY}`,
    is: false,
    alternatives: {
      truthy: {
        result: 'requiredNumber',
      },
      falsy: {
        result: 'nilCast',
      },
    },
  },
  falsy: {
    result: 'nilCast',
  },
});
