import { RampWithNameTransform } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import { useFieldDisabledByFieldPath, useFieldDisabledReasonByFieldPath } from '../../../../../../../../../../../../Form';
import { useGetFieldPath } from '../../../../../../../../../../../context';

export function DefaultElementLabelDisabledReasonSwitcher(props: { baseRampName: string }) {
  const { baseRampName } = props;
  const fieldPath = useGetFieldPath();
  const disabled = useFieldDisabledByFieldPath(fieldPath);
  const disabledReason = useFieldDisabledReasonByFieldPath(fieldPath);
  const reason = disabledReason.get();
  return disabled.get() && reason !== '' ? (
    <RampWithNameTransform rampName={`${baseRampName}ElementLabelDisabledReasonContainer`}>
      <RampWithNameTransform rampName={`${baseRampName}ElementLabelDisabledReasonContentSwitcher`} baseRampName={baseRampName} />
    </RampWithNameTransform>
  ) : null;
}
