import * as React from 'react';
import { AnyObjectSchema, ValidationError } from 'yup';
import { Untracked } from '@hookstate/untracked';
import ouiBase from '@goldwasserexchange/oui';
import { UncastedDataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import { State } from '@hookstate/core';
import {
  useDependenciesFieldPaths,
  useGetFieldPath,
  useCheckboxPotentialFieldPaths,
} from '../../../context';
import { useFormValidator } from '../../../../Form';
import { fieldErrorsContext } from '../../../context/valid/context';
import { validateAt } from '../../../../../Components/Form/validations/validate';
import { useShouldValidateFieldPath } from '../context/validateFieldPath';

const initialErrorArray: string[] = [];

const makeValidate = (props: {
  dataState: State<UncastedDataStructure>,
  validator: AnyObjectSchema,
  fieldPaths: string[],
  errors: string[],
  setErrors: React.Dispatch<React.SetStateAction<string[]>>,
}) => () => {
  const {
    dataState,
    validator,
    fieldPaths,
    errors,
    setErrors,
  } = props;
  const data = Untracked(dataState).get();
  if (validator) {
    Promise.all(
      [...fieldPaths.map((path) => validateAt({
        validator,
        path,
        values: data,
      })
        .then(() => null)
        .catch((err: ValidationError) => {
          const { errors: yupErrors } = err;
          return yupErrors;
        }))],
    ).then((yupCheckBoxPotentialFieldPathsErrors) => {
      const filteredErrors = yupCheckBoxPotentialFieldPathsErrors
        .filter(
          (
            yupCheckBoxPotentialFieldPathsError,
          ): yupCheckBoxPotentialFieldPathsError is string[] => yupCheckBoxPotentialFieldPathsError != null,
        )
        .reduce((acc, el) => [...acc, ...el], []);
      if (filteredErrors.length === 0) {
        setErrors(initialErrorArray);
      }
      if (
        !filteredErrors.every(
          (filteredError, idx) => filteredError === errors[idx],
        )
      ) {
        setErrors(filteredErrors);
      }
    });
  }
};

export function CheckboxFieldErrorsContextProvider(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  const dependenciesFieldPaths = useDependenciesFieldPaths();
  const checkboxPotentialFieldPaths = useCheckboxPotentialFieldPaths();
  const [errors, setErrors] = React.useState(initialErrorArray);
  const fieldPath = useGetFieldPath();
  const validateFieldPath = useShouldValidateFieldPath();
  const fieldPaths = validateFieldPath ? [fieldPath, ...checkboxPotentialFieldPaths] : checkboxPotentialFieldPaths;
  const dataState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const validator = useFormValidator();
  const validate = React.useCallback(
    makeValidate({
      dataState,
      validator,
      fieldPaths,
      errors,
      setErrors,
    }),
    [
      dataState,
      validator,
      fieldPaths,
      errors,
      setErrors,
    ],
  );
  React.useEffect(() => {
    validate();
    ouiBase.Form.SubscribePlugin(dataState).subscribeMultiple([fieldPath, ...dependenciesFieldPaths, ...checkboxPotentialFieldPaths], fieldPath, () => {
      validate();
    });
  }, [
    validate,
    checkboxPotentialFieldPaths.join('-'),
  ]);
  return (
    <fieldErrorsContext.Provider value={errors}>
      {children}
    </fieldErrorsContext.Provider>
  );
}
