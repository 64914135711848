import ouiBase from '@goldwasserexchange/oui';
import { useMemo } from 'react';
import { isHisMvtTableCountedFromHistoryLine } from '../../utils';
import { useAccountHistoryTransformApiByHisMvtId } from '../api';

export const useAccountsHistoryHisMvtIsCounted = () => {
  const hisMvtId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const response = useAccountHistoryTransformApiByHisMvtId({
    hisMvtId,
    transformFunction: isHisMvtTableCountedFromHistoryLine,
  });
  const result = useMemo(() => {
    const {
      data,
    } = response;
    return {
      ...response,
      data: data ?? true,
    };
  }, [response]);
  return result;
};
