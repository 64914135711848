import { createContext } from 'react';
import {
  PortableTextProps,
} from '@portabletext/react';

export type Footnote = {
  _type: string,
  _key: string,
  text: PortableTextProps['value'],
}

export const footnotesContext = createContext<Footnote[] | null>([]);
