import * as React from 'react';
import { useAtomValue } from 'jotai';
import { bondSearchParamsSearchTermAtom } from '../../../shared';
import { listOrFavoritesSearchTermContext } from './context';

export const ListOrFavoritesSearchTermContextProvider = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  const urlSearchTerm = useAtomValue(bondSearchParamsSearchTermAtom);
  const handlers = React.useState(urlSearchTerm);
  return (
    <listOrFavoritesSearchTermContext.Provider value={handlers}>
      {children}
    </listOrFavoritesSearchTermContext.Provider>
  );
};
