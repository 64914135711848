/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';

const scrollableWrapperDomStyle: ouiStyle.InStyleWithMediaQueries = {
  position: 'relative',
  minWidthLines: 0,
};

export const ScrollableWrapperDom = (props: React.PropsWithChildren<{
  position?: ouiStyle.InStyleWithMediaQueries['position'],
  minWidthLines?: ouiStyle.InStyleWithMediaQueries['minWidthLines'],
  flex?: ouiStyle.InStyleWithMediaQueries['flex'],
  flexGrow?: ouiStyle.InStyleWithMediaQueries['flexGrow'],
  flexShrink?: ouiStyle.InStyleWithMediaQueries['flexShrink'],
  flexBasis?: ouiStyle.InStyleWithMediaQueries['flexBasis'],
  paddingVertical?: ouiStyle.InStyleWithMediaQueries['paddingVertical'],
  paddingHorizontal?: ouiStyle.InStyleWithMediaQueries['paddingHorizontal'],
}>) => {
  const {
    position = scrollableWrapperDomStyle.position,
    minWidthLines = scrollableWrapperDomStyle.minWidthLines,
    flex = scrollableWrapperDomStyle.flex,
    flexGrow = scrollableWrapperDomStyle.flexGrow,
    flexShrink = scrollableWrapperDomStyle.flexShrink,
    flexBasis = scrollableWrapperDomStyle.flexBasis,
    paddingVertical = scrollableWrapperDomStyle.paddingVertical,
    paddingHorizontal = scrollableWrapperDomStyle.paddingHorizontal,
    children,
  } = props;
  const scrollableWrapperDomCss = React.useMemo(() => ouiStyle.mediaQuery(ouiStyle.makeCss({
    position,
    minWidthLines,
    flex,
    flexGrow,
    flexShrink,
    flexBasis,
    paddingVertical,
    paddingHorizontal,
  })), [
    position,
    minWidthLines,
    flex,
    flexGrow,
    flexShrink,
    flexBasis,
    paddingVertical,
    paddingHorizontal,
  ]);
  return (
    <div
      css={scrollableWrapperDomCss}
    >
      {children}
    </div>
  );
};
