import { Locale } from '@goldwasserexchange/i18n';
import { useLingui } from '@lingui/react';

export const useLinguiLocale = () => {
  const {
    i18n,
  } = useLingui();
  const {
    locale,
  } = i18n;
  if (locale !== Locale.FR && locale !== Locale.NL) {
    throw new Error('not a known locale');
  }
  return locale;
};
