import * as Yup from 'yup';
import {
  cond, is, pipe, T, always, gt,
} from 'ramda';
import {
  isValid, parse, differenceInYears, addHours, subYears,
} from 'date-fns';

import {
  euroDateError,
  notInTheFutureError,
  notInThePastError,
  dateMinorError,
  dateNotMinorError,
} from '../errornames';
import { makeRequired } from './mixed';

const isObject = is(Object);
const isString = is(String);
const invalidDate = new Date('');

export const parseEuroDate = (euroDateString, hours = 0) => {
  if (euroDateString instanceof Date) {
    return addHours(euroDateString, hours);
  }
  if (typeof euroDateString === 'string' && /^[0-3]\d\/[01]\d\/\d{4}$/.test(euroDateString)) {
    return addHours(parse(euroDateString, 'dd/MM/yyyy', new Date()), hours);
  }
  return NaN;
};

export const ageFromEuroBirthdate = (euroBirthdateString: string): number => {
  const parsed = parseEuroDate(euroBirthdateString);
  const age = differenceInYears(Date.now(), parsed);
  return age;
};

export const isMinorFromEuroBirthdate = (euroBirthdateString) => pipe(
  ageFromEuroBirthdate,
  gt(18),
)(euroBirthdateString);

export function fromEuroDate(this: any, value, originalValue) {
  return cond([
    [
      isString,
      pipe(
        (dateString) => parseEuroDate(dateString, 12),
        (date) => (isValid(date) ? date : invalidDate),
      ),
    ],
    [
      isObject,
      always(this.isType(value) ? value : invalidDate),
    ],
    [
      T,
      always(invalidDate),
    ],
  ])(originalValue);
}

export const euroDateValidator = Yup
  .date()
  .transform(fromEuroDate)
  .typeError(euroDateError);

export const requiredEuroDateValidator = makeRequired(euroDateValidator);

export const makeDateInThePast = (validator: Yup.DateSchema) => {
  const dateNotInThePast = validator.max(
    new Date(),
    notInThePastError,
  );
  return dateNotInThePast;
};

export const makeDateInTheFuture = (validator: Yup.DateSchema) => {
  const dateInTheFuture = validator.min(
    new Date(),
    notInTheFutureError,
  );
  return dateInTheFuture;
};

export const birthdateValidator = makeDateInThePast(euroDateValidator);

export const requiredBirthdateValidator = makeRequired(birthdateValidator);

export const makeDateNotMinor = (validator: Yup.DateSchema) => {
  const dateNotMinor = validator.max(
    subYears(Date.now(), 18),
    dateMinorError,
  );
  return dateNotMinor;
};

export const makeDateMinor = (validator: Yup.DateSchema) => {
  const dateMinor = validator.min(
    subYears(Date.now(), 18),
    dateNotMinorError,
  );
  return dateMinor;
};
