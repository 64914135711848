import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { TableBase, TableContent, TableHeaderBase, TableHeaderContent, TableHeaderRowContent, TableHeaderCornerTitleBase, TableHeaderTitlesMapperBase, TableBodyBase, TableBodyMapper, TableBodyRowBase, TableBodyRowHeadingBase, TableBodyRowMapper, TableBodyRowCellBase, TableHeaderTitleBase, TableBodyRowContent, } from './components';
import { TableContext } from './context/components';
import { TableFooterBase, TableFooterCellBase, TableFooterCellsMapperBase, TableFooterContent, TableFooterCornerBase, TableFooterRowContent, } from './components/footer';
export var tableDefaultTopModelsObject = {
    TableBase: (React.createElement(TopModel, { name: "TableBase" }, TableBase)),
    TableContextContainer: (React.createElement(TopModel, { name: "TableContextContainer" }, TableContext)),
    TableContent: (React.createElement(TopModel, { name: "TableContent" }, TableContent)),
    TableHeaderBase: (React.createElement(TopModel, { name: "TableHeaderBase" }, TableHeaderBase)),
    TableHeaderContent: (React.createElement(TopModel, { name: "TableHeaderContent" }, TableHeaderContent)),
    TableHeaderRowContent: (React.createElement(TopModel, { name: "TableHeaderRowContent" }, TableHeaderRowContent)),
    TableHeaderCornerTitleBase: (React.createElement(TopModel, { name: "TableHeaderCornerTitleBase" }, TableHeaderCornerTitleBase)),
    TableHeaderTitlesMapperBase: (React.createElement(TopModel, { name: "TableHeaderTitlesMapperBase" }, TableHeaderTitlesMapperBase)),
    TableHeaderTitleBase: (React.createElement(TopModel, { name: "TableHeaderTitleBase" }, TableHeaderTitleBase)),
    TableBodyBase: (React.createElement(TopModel, { name: "TableBodyBase" }, TableBodyBase)),
    TableBodyMapper: (React.createElement(TopModel, { name: "TableBodyMapper" }, TableBodyMapper)),
    TableBodyRowBase: (React.createElement(TopModel, { name: "TableBodyRowBase" }, TableBodyRowBase)),
    TableBodyRowContent: (React.createElement(TopModel, { name: "TableBodyRowContent" }, TableBodyRowContent)),
    TableBodyRowHeadingBase: (React.createElement(TopModel, { name: "TableBodyRowHeadingBase" }, TableBodyRowHeadingBase)),
    TableBodyRowMapper: (React.createElement(TopModel, { name: "TableBodyRowMapper" }, TableBodyRowMapper)),
    TableBodyRowCellBase: (React.createElement(TopModel, { name: "TableBodyRowCellBase" }, TableBodyRowCellBase)),
    TableFooterBase: (React.createElement(TopModel, { name: "TableFooterBase" }, TableFooterBase)),
    TableFooterContent: (React.createElement(TopModel, { name: "TableFooterContent" }, TableFooterContent)),
    TableFooterRowContent: (React.createElement(TopModel, { name: "TableFooterRowContent" }, TableFooterRowContent)),
    TableFooterCornerBase: (React.createElement(TopModel, { name: "TableFooterCornerBase" }, TableFooterCornerBase)),
    TableFooterCellsMapperBase: (React.createElement(TopModel, { name: "TableFooterCellsMapperBase" }, TableFooterCellsMapperBase)),
    TableFooterCellBase: (React.createElement(TopModel, { name: "TableHeaderTitleBase" }, TableFooterCellBase)),
};
