/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { useTopModelExistByName } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import { useHookstate } from '@hookstate/core';
import { Untracked } from '@hookstate/untracked';
import { useAnimationCollapseSpringValue } from '../AppBar/animation';
import { useBannerHeight } from '../banner/context';
import { useModalZIndex } from './context/zIndex';

const downMdTop = `${ouiStyle.Constants.space * 6}rem`;

const style: ouiStyle.InStyleWithMediaQueries = {
  position: 'fixed',
  left: '50%',
  transform: ['translateX(-50%)', undefined, undefined, 'translateX(-50%) translateY(-50%)', undefined, undefined],
  backgroundColor: ouiStyle.Constants.Colors.inverted,
  widthLines: ['full', undefined, undefined, 25, undefined, undefined],
  lines: ['full', undefined, undefined, 'auto', undefined, undefined],
  boxShadow: '0 0 10px 3px rgba(0, 0, 0, 0.1)',
};

const hasIconMaxHeight = ['100vh', '100vh', '100vh', `calc(100vh - ${21 * ouiStyle.Constants.space}rem)`, `calc(100vh - ${21 * ouiStyle.Constants.space}rem)`, `calc(100vh - ${21 * ouiStyle.Constants.space}rem)`];

export const ModalWrapper = (props: React.PropsWithChildren<{
  widthLines?: ouiStyle.InStyle['widthLines'],
}>) => {
  const {
    widthLines = 25,
    children,
  } = props;
  const zIndex = useModalZIndex();
  const hasIcon = useTopModelExistByName('ModalIconContent');
  const collapse = useAnimationCollapseSpringValue();
  const bannerHeight = useBannerHeight();
  const isUncollapsed = useHookstate(collapse.get() === 0);
  isUncollapsed.attach(Untracked);
  React.useEffect(() => {
    const interval = setInterval(() => {
      const uncollapsed = collapse.get() === 0;
      if (Untracked(isUncollapsed).get() !== uncollapsed) {
        isUncollapsed.set(uncollapsed);
      }
    }, 50);
    return () => { clearInterval(interval); };
  });
  const css = React.useMemo(() => ouiStyle.mediaQuery({
    ...ouiStyle.makeCss({
      ...style,
      widthLines: ['full', undefined, undefined, widthLines, undefined, undefined],
      zIndex,
      top: [isUncollapsed.get() ? `calc(${downMdTop} + ${bannerHeight}px)` : 0, undefined, undefined, '50%', undefined, undefined],
      minus: isUncollapsed.get() ? [`${downMdTop} - ${bannerHeight}px`, undefined, undefined, '0', undefined, undefined] : '0',
    }),
    maxHeight: hasIcon ? hasIconMaxHeight : '100vh',
  }), [widthLines, zIndex, hasIcon, collapse, bannerHeight, isUncollapsed.get()]);
  return (
    <div
      css={css}
    >
      {children}
    </div>
  );
};
