import useSWR, { KeyedMutator } from 'swr';
import { useMemo } from 'react';
import { CashflowByCurrency } from '../type';
import { Cashflows } from '../../enum';
import { AccountCashflow } from '../../../accounts';
import { cashflowsGroupByCCurrencyCode } from '../utils';
import { useAccountCashflowsStringifiedParams } from '../../../accounts/cashflows/hooks/defaultParams';
import { getServiceUrl } from '../../../../shared';
import { ACTOR_DEDUPING_INTERVAL } from '../../../shared';
import { useFetchWithAuthWithSend } from '../../../shared/utils/fetchWithAuth';
import { makeApiUrl } from '../../../../../../aws';

const useMakeCashflowsByCurrencyUrl = () => {
  const stringifiedParams = useAccountCashflowsStringifiedParams();
  const url = stringifiedParams !== null ? `${makeApiUrl('actor')}/${getServiceUrl(Cashflows.byCurrency)}${stringifiedParams}` : null;
  return url;
};

type BaseUseCashflowByCurrencyApiResponse = {
  isLoading: boolean,
  error?: any,
  mutate: KeyedMutator<AccountCashflow[]>,
  isValidating: boolean,
}

type UseCashflowByCurrencyLinesApiResponse = {
  data: undefined | CashflowByCurrency[],
} & BaseUseCashflowByCurrencyApiResponse;

type FilterPredicate = (cashflowByCurrency: CashflowByCurrency) => boolean;

type SortPredicate = (prevCashflowByCurrency: CashflowByCurrency, nextCashflowByCurrency: CashflowByCurrency) => number;

type UseCashflowByCurrencyLinesApiProps = {
  filterPredicate?: FilterPredicate,
  sortPredicate?: SortPredicate,
};

export const useCashflowByCurrencyLinesApi = (props: UseCashflowByCurrencyLinesApiProps): UseCashflowByCurrencyLinesApiResponse => {
  const {
    filterPredicate,
    sortPredicate,
  } = props;
  const url = useMakeCashflowsByCurrencyUrl();
  const fetcher = useFetchWithAuthWithSend();
  const result = useSWR<AccountCashflow[], any>(
    url,
    fetcher,
    { dedupingInterval: ACTOR_DEDUPING_INTERVAL },
  );
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    let data = cashflowsGroupByCCurrencyCode(result.data);
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      if (filterPredicate) {
        data = data.filter((cashflowByCurrency) => filterPredicate(cashflowByCurrency));
      }
    }
    return ({
      ...result,
      data,
      isLoading,
    });
  }, [result, filterPredicate, sortPredicate]);
  return resultWithLoading;
};

type TransformArrayFunction<T> = (cashflowByCurrencyLines: CashflowByCurrency[] | undefined) => T

type UseCashflowByCurrencyLinesApiTransformProps<T> = {
  transformFunction: TransformArrayFunction<T>,
} & UseCashflowByCurrencyLinesApiProps

type UseCashflowByCurrencyApiTransformResponse<T> = {
  data: T | undefined,
} & BaseUseCashflowByCurrencyApiResponse;

export function useCashflowByCurrencyLinesTransformApi<T>(props: UseCashflowByCurrencyLinesApiTransformProps<T>): UseCashflowByCurrencyApiTransformResponse<T> {
  const {
    filterPredicate,
    sortPredicate,
    transformFunction,
  } = props;
  const response = useCashflowByCurrencyLinesApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: (!isLoading && !error) ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UseCashflowByCurrencyApiResponse = {
  data: CashflowByCurrency | undefined,
} & BaseUseCashflowByCurrencyApiResponse;

export const useCashflowByCurrencyApi = (props: UseCashflowByCurrencyLinesApiProps): UseCashflowByCurrencyApiResponse => {
  const {
    filterPredicate,
    sortPredicate,
  } = props;
  const url = useMakeCashflowsByCurrencyUrl();
  const fetcher = useFetchWithAuthWithSend();
  const result = useSWR<AccountCashflow[], any>(
    url,
    fetcher,
    { dedupingInterval: ACTOR_DEDUPING_INTERVAL },
  );
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;

    let data: CashflowByCurrency[] | CashflowByCurrency | undefined = cashflowsGroupByCCurrencyCode(result.data);
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      if (filterPredicate) {
        data = data.find((cashflowByCurrency) => filterPredicate(cashflowByCurrency));
      }
      if (Array.isArray(data)) {
        // eslint-disable-next-line prefer-destructuring
        data = data[0];
      }
    }
    return ({
      ...result,
      data,
      isLoading,
    });
  }, [result, filterPredicate, sortPredicate]);
  return resultWithLoading;
};

type TransformFunction<T> = (cashflowByCurrency: CashflowByCurrency | undefined) => T

type UseCashflowByCurrencyApiTransformProps<T> = {
  transformFunction: TransformFunction<T>,
} & UseCashflowByCurrencyLinesApiProps;

export function useCashflowByCurrencyTransformApi<T>(props: UseCashflowByCurrencyApiTransformProps<T>): UseCashflowByCurrencyApiTransformResponse<T> {
  const {
    filterPredicate,
    sortPredicate,
    transformFunction,
  } = props;
  const response = useCashflowByCurrencyApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: !isLoading && !error ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UseCashflowByCurrencyFieldApiResponse<Field extends keyof CashflowByCurrency> = {
  data: CashflowByCurrency[Field] | undefined,
} & BaseUseCashflowByCurrencyApiResponse;

type UseCashflowByCurrencyFieldApiProps<Field extends keyof CashflowByCurrency> = {
  field: Field,
} & UseCashflowByCurrencyLinesApiProps

export function useCashflowByCurrencyFieldApi<Field extends keyof CashflowByCurrency>(props: UseCashflowByCurrencyFieldApiProps<Field>): UseCashflowByCurrencyFieldApiResponse<Field> {
  const {
    filterPredicate,
    sortPredicate,
    field,
  } = props;
  const response = useCashflowByCurrencyApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
    } = response;
    return {
      ...response,
      data: data ? data[field] : undefined,
    };
  }, [response, field]);
  return result;
}
