import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { DomHeadingSectionContainer, DomHeadingSectionHeadingContainer, DomH1, DomH2, DomH3, DomIllustrationContainer, } from './components';
import { DomPContainer } from '../P/components';
export var headingSectionDefaultDomTopModelsObject = {
    HeadingSectionContainer: (React.createElement(TopModel, { name: "HeadingSectionContainer" }, DomHeadingSectionContainer)),
    HeadingSectionHeadingContainer: (React.createElement(TopModel, { name: "HeadingSectionHeadingContainer" }, DomHeadingSectionHeadingContainer)),
    'HeadingSectionTitleContainer-1': (React.createElement(TopModel, { name: "HeadingSectionTitleContainer-1" }, DomH1)),
    'HeadingSectionTitleContainer-2': (React.createElement(TopModel, { name: "HeadingSectionTitleContainer-2" }, DomH2)),
    'HeadingSectionTitleContainer-3': (React.createElement(TopModel, { name: "HeadingSectionTitleContainer-3" }, DomH3)),
    HeadingSectionIntroContainer: (React.createElement(TopModel, { name: "HeadingSectionIntroContainer" }, DomPContainer)),
    HeadingSectionIllustrationContainer: (React.createElement(TopModel, { name: "HeadingSectionIllustrationContainer" }, DomIllustrationContainer)),
};
