import { Update } from 'history';
import { Locale } from '@goldwasserexchange/i18n';
import { HeadProps } from './type';
import { applyHead } from './apply';
import { makeLanguageHeadProps } from './language';
import { makeSectionHeadProps } from './sections';

export const headListener = (currentUpdate: Update) => {
  let headProps: HeadProps = {
    title: 'Goldwasser Exchange',
    language: Locale.FR,
  };
  headProps = makeLanguageHeadProps(currentUpdate, headProps);
  headProps = makeSectionHeadProps(currentUpdate, headProps);
  applyHead(headProps);
};
