var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { TopModelMerge, TopModelNameTransformProvider } from '@goldwasserexchange/react-topmodel';
import ouiBase from '@goldwasserexchange/oui';
import { tableDefaultDomTopModelsObject } from './topmodels';
export var Table = function (props) {
    var primaryDimensionList = props.primaryDimensionList, secondaryDimensionList = props.secondaryDimensionList, direction = props.direction, wrapping = props.wrapping, multiBody = props.multiBody, topModels = props.topModels, children = props.children;
    var topModelsToMerge = React.useMemo(function () { return (__assign(__assign(__assign({}, ouiBase.Table.tableDefaultTopModelsObject), tableDefaultDomTopModelsObject), (topModels !== null && topModels !== void 0 ? topModels : {}))); }, [topModels]);
    return (React.createElement(TopModelNameTransformProvider, { transformFn: ouiBase.Table.tablePrefixer },
        React.createElement(TopModelMerge, { topModels: topModelsToMerge },
            React.createElement(ouiBase.Table.TableStructure, { primaryDimensionList: primaryDimensionList, secondaryDimensionList: secondaryDimensionList, direction: direction, wrapping: wrapping, multiBody: multiBody }, children))));
};
