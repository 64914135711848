import { useCallback } from 'react';
import ouiBase from '@goldwasserexchange/oui';
import { useAccountCashflowTransformApiByCpsHisId } from '../api';
import { AccountCashflow } from '../../type';
import { useLmlGetPositionsTitleCase } from '../../../positions';
import { useLinguiLocale } from '../../../../../../../Components/useLinguiLocale';

export const useAccountCashflowsSAccLml2Description = () => {
  const cpsHisId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const locale = useLinguiLocale();
  const titleCaser = useLmlGetPositionsTitleCase(locale);
  const transformFunction = useCallback((accountCashflow: AccountCashflow | undefined) => {
    if (!accountCashflow) {
      return '';
    }
    return titleCaser(accountCashflow['S_ACC.LML2_DESCRIPTION']);
  }, [titleCaser]);
  const response = useAccountCashflowTransformApiByCpsHisId({
    cpsHisId,
    transformFunction,
  });
  return response;
};
