import { initialBondSortContext } from '../initial';
import { BondSortColumns } from './type';
import { isBondSortColumnValid } from './valid';

export const getBondSortColumn = (sortColumn: string | (string | null)[] | null): BondSortColumns => {
  if (!sortColumn || Array.isArray(sortColumn) || !isBondSortColumnValid(sortColumn)) {
    return initialBondSortContext.sortColumn;
  }
  return sortColumn;
};
