export const optimisticUpdateFavorites = (props: {
  data: string[] | undefined,
  element: string,
  operation: 'ADD' | 'DELETE',
}) => {
  const {
    data,
    element,
    operation,
  } = props;
  if (operation === 'DELETE') {
    return (data ?? []).filter((id) => id !== element);
  }
  if (operation === 'ADD') {
    return [...(data ?? []), element];
  }
  throw new Error(`operation should be ADD or DELETE but is ${operation}`);
};
