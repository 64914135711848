import { Trans } from '@lingui/macro';
import * as React from 'react';
import { reach } from 'yup';
import { Untracked } from '@hookstate/untracked';
import ouiBase from '@goldwasserexchange/oui';
import { UncastedDataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import { useGetFieldPath } from '../../../../../../context';
import { useFormValidator } from '../../../../../../../Form';
import { Space } from '../../../../../../../../Components/Space';

const LengthError = () => {
  const validator = useFormValidator();
  const values = ouiBase.Form.useFormData<UncastedDataStructure>();
  const fieldPath = useGetFieldPath();
  const { tests } = validator ? reach(validator, fieldPath, Untracked(values).get()).describe() : { tests: [] };
  const lengthTest = tests.find(({ name }) => name === 'length') ?? { params: { length: 0 } };
  const { params: { length } } = lengthTest;
  return (
    <Trans>
      Ce champs doit faire
      <Space />
      {length}
      <Space />
      caractères
    </Trans>
  );
};

export const inputLengthErrorMessage = <LengthError />;
