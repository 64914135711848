import { useCallback } from 'react';
import {
  computeBuyAndSellExchangeFees, computeBuyAndSellExchangeFeesPct, computeExchangeFees, computeExchangeFeesPct,
} from '../../utils';
import { AccountRates } from '../../type';
import { useAccountRateFieldApi, useAccountRateTransformApi } from '../api';
import { useSimulationDataBuyAndSellContext } from '../../context/simulation/hooks';

export const useAccountRatesTAccFrcRate = () => {
  const result = useAccountRateFieldApi({
    field: 'T_ACC.FRC_RATE',
  });
  return result;
};

export const useAccountRatesTAccFrcRateAmountBuyOrSell = () => {
  const {
    counterValue,
    foreignCurrency,
  } = useSimulationDataBuyAndSellContext();
  const transformFunction = useCallback((data: AccountRates | null | undefined) => computeExchangeFees({
    grossAmountInEur: counterValue,
    currency: !foreignCurrency ? 'EUR' : 'USD',
  })(data ? data['T_ACC.FRC_RATE'] : 0), [
    counterValue,
    foreignCurrency,
  ]);
  const result = useAccountRateTransformApi({
    transformFunction,
  });
  return result;
};

export const useAccountRatesTAccFrcRatePctBuyOrSell = () => {
  const {
    counterValue,
    foreignCurrency,
    refund,
    type,
  } = useSimulationDataBuyAndSellContext();
  const transformFunction = useCallback((data: AccountRates | null | undefined) => computeExchangeFeesPct({
    counterValue,
    foreignCurrency,
    refund,
    type,
  })(data ? data['T_ACC.FRC_RATE'] : 0), [
    counterValue,
    foreignCurrency,
    refund,
    type,
  ]);
  const result = useAccountRateTransformApi({
    transformFunction,
  });
  return result;
};

export const useAccountRatesTAccFrcRateAmountBuyAndSellOnContext = () => {
  const {
    counterValue,
    foreignCurrency,
    refund,
    type,
  } = useSimulationDataBuyAndSellContext();
  const transformFunction = useCallback((data: AccountRates | null | undefined) => computeBuyAndSellExchangeFees({
    counterValue,
    foreignCurrency,
    refund,
    type,
  })(data ? data['T_ACC.FRC_RATE'] : 0), [
    counterValue,
    foreignCurrency,
    refund,
    type,
  ]);
  const result = useAccountRateTransformApi({
    transformFunction,
  });
  return result;
};

export const useAccountRatesTAccFrcRateAmountBuyAndSellPctOnContext = () => {
  const {
    counterValue,
    foreignCurrency,
    durationYears,
    refund,
    type,
  } = useSimulationDataBuyAndSellContext();
  const transformFunction = useCallback((data: AccountRates | null | undefined) => computeBuyAndSellExchangeFeesPct({
    counterValue,
    foreignCurrency,
    durationYears,
    refund,
    type,
  })(data ? data['T_ACC.FRC_RATE'] : 0), [
    counterValue,
    foreignCurrency,
    durationYears,
    refund,
    type,
  ]);
  const result = useAccountRateTransformApi({
    transformFunction,
  });
  return result;
};
