import React from 'react';
import { Trans } from '@lingui/macro';
import {
  dataStructure, TAddType, UncastedDataStructure,
} from '@goldwasserexchange/oblis-frontend-utils';
import { useHookstate } from '@hookstate/core';
import { useUser } from '../utils';
import { useParentFieldPath } from '../../../OUI/Inputs';

export const tAddName1ValidatorLogic = dataStructure.personType.logic.show.makeShowMoralField({
  visible: {
    result: 'requiredCapitalizedNotWithSpaceString',
  },
  invisible: {
    result: 'nilCast',
  },
});

const TAddNonAuthName1Label = () => {
  const userState = useUser();
  const tAddTypeState = useHookstate(userState.T_ADD_type);
  if (tAddTypeState.get() === TAddType.MORAL) {
    return (
      <Trans>
        Dénomination sociale
      </Trans>
    );
  }
  return (
    <Trans>
      Nom
    </Trans>
  );
};

const authPath: keyof Pick<UncastedDataStructure, 'auth'> = 'auth';

const TAddName1Label = () => {
  const parentName = useParentFieldPath();
  if (parentName !== authPath) {
    return (
      <TAddNonAuthName1Label />
    );
  }
  return (
    <Trans>
      Nom
    </Trans>
  );
};

export const tAddName1Label = <TAddName1Label />;

export const tAddSignificantOtherName1Label = <Trans>Nom du partenaire</Trans>;
