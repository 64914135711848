import * as events from '../../../events';

export type AppBarMediaQueryServiceMeta = {
  src: {
    type: 'mediaQueryService',
  },
};

export const name = 'watchMediaQuery';

export const makeFn = (matcher: MediaQueryList) => () => (callback) => {
  const listener = () => {
    callback(events.mediaQueryChangeEvent.eventBuilder(matcher));
  };
  matcher.addListener(listener);

  return () => matcher.removeListener(listener);
};

export const makeService = (matcher: MediaQueryList) => ({
  [name]: makeFn(matcher),
});

export default makeService;
