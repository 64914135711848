var _a;
import { makeKERequiredLogic as bondLogic } from '../../fields/BOND/logic';
import { makeKERequiredLogic as fundLogic } from '../../fields/FUND/logic';
import { makeKERequiredLogic as perpetualBondLogic } from '../../fields/PERPETUAL_BOND/logic';
import { makeKERequiredLogic as stockLogic } from '../../fields/STOCK/logic';
import { makeKERequiredLogic as subordinatedBondLogic } from '../../fields/SUBORDINATED_BOND/logic';
import { makeKERequiredLogic as trackerLogic } from '../../fields/TRACKER/logic';
import { makeKERequiredLogic as rawMaterialTracked } from '../../fields/RAW_MATERIAL_TRACKER/logic';
import { STypeGrp } from '../../../../types';
export var makeKERequiredLogicRecord = (_a = {},
    _a[STypeGrp.OBLIGATIONS] = bondLogic,
    _a[STypeGrp.FONDS] = fundLogic,
    _a[STypeGrp.OBLIGATIONS_PERP] = perpetualBondLogic,
    _a[STypeGrp.ACTIONS] = stockLogic,
    _a[STypeGrp.OBLIGATIONS_SUB] = subordinatedBondLogic,
    _a[STypeGrp.TRACKERS] = trackerLogic,
    _a[STypeGrp.TRACKERS_MAT] = rawMaterialTracked,
    _a);
