var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { isNil, last, times } from 'ramda';
import * as financialProducts from '../financialProducts';
import * as financialProductsSelection from '../financialProductsSelection';
import * as data from './data';
import * as pathUtils from './pathUtils';
import { RTO_QUESTIONS_PREFIXES, RTO_QUESTION_GROUPS_LENGTH } from '../../../RTO';
import { AccountType } from '../../types';
export var getQuestionIdsByQuestionnaire = function (QuestionnaireId) { return Object.keys(data.questionnaireQuestions).filter(function (questionKey) { return data.questionnaireQuestions[questionKey].questionnaires.includes(QuestionnaireId); }); };
export var getQuestionRootIdsByQuestionnaire = function (QuestionnaireId) { return getQuestionIdsByQuestionnaire(QuestionnaireId).map(function (key) { return key.replace(pathUtils.ANSWER_SUFFIX, ''); }); };
export var getRtoQuestionRootIdsByQuestionnaire = function (QuestionnaireId) { return (financialProducts.filter.filterProductListByService(financialProducts.list, AccountType.RTO).includes(QuestionnaireId) ? times(function (index) { return "".concat(RTO_QUESTIONS_PREFIXES[QuestionnaireId]).concat(index); }, RTO_QUESTION_GROUPS_LENGTH[QuestionnaireId]) : []); };
export var getQuestionRootIdsByServiceAndQuestionnaire = function (service, QuestionnaireId) {
    var isRTO = service === AccountType.RTO;
    return !isRTO
        ? getQuestionRootIdsByQuestionnaire(QuestionnaireId)
        : getRtoQuestionRootIdsByQuestionnaire(QuestionnaireId);
};
export var getLastQuestionRootIdByAccountTypeAndFinancialProduct = function (currentAccountType, financialProduct) { return last(getQuestionRootIdsByServiceAndQuestionnaire(currentAccountType, financialProduct)); };
export var getLastQuestionCorrectnessKeysByAccountTypeAndFinancialProduct = function (currentAccountType, financialProduct) {
    var lastQuestionRootId = getLastQuestionRootIdByAccountTypeAndFinancialProduct(currentAccountType, financialProduct);
    if (!lastQuestionRootId) {
        return undefined;
    }
    var isCorrect = pathUtils.makeIsCorrectKey(lastQuestionRootId);
    var remedialIsCorrect = currentAccountType === AccountType.RTO ? pathUtils.makeIsCorrectKey(pathUtils.makeRemedialKey(lastQuestionRootId)) : undefined;
    var correctnessKeys = [
        isCorrect,
        remedialIsCorrect,
    ];
    return correctnessKeys;
};
export var getStatusDisabledCorrectnessKeysByAccountTypeAndFinancialProduct = function (currentAccountType, financialProduct) {
    var statusDisabledDependencies = financialProductsSelection.dependencies.byStatus.getter.getFinancialProductDependenciesByStatus(financialProduct);
    var statusDisabledDependencyKeys = statusDisabledDependencies.map(function (dependencyProduct) {
        var l = getLastQuestionCorrectnessKeysByAccountTypeAndFinancialProduct(currentAccountType, dependencyProduct);
        return l;
    }).filter(function (x) { return !isNil(x); });
    return statusDisabledDependencyKeys;
};
export var getIsDisabledByStatus = function (currentAccountType, financialProduct, answers) {
    var dependencyKeys = getStatusDisabledCorrectnessKeysByAccountTypeAndFinancialProduct(currentAccountType, financialProduct);
    var dependencySuccess = !dependencyKeys
        ? true
        : dependencyKeys.every(function (_a) {
            var _b = __read(_a, 2), dependencyCorrect = _b[0], dependencyRemedialCorrect = _b[1];
            return answers[dependencyCorrect] === true || (dependencyRemedialCorrect && answers[dependencyRemedialCorrect] === true);
        });
    return dependencySuccess;
};
