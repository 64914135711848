var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { ListContextContainer, VirtualizedListContextContainer } from '../components';
import { ListDataFilterAndSortProvider } from '../data';
import { PrimaryDimensionLengthProviderFromList, PrimaryDimensionListContextProvider } from '../../../utils/dimensions';
var innerListDefaultContexts = {
    DataProvider: React.createElement(TopModel, { name: "DataProvider" }, PrimaryDimensionListContextProvider),
    FilterAndSortDataProvider: React.createElement(TopModel, { name: "FilterAndSortDataProvider" }, ListDataFilterAndSortProvider),
    LengthProvider: (React.createElement(TopModel, { name: "LengthProvider" }, PrimaryDimensionLengthProviderFromList)),
};
export var listContextDefaultTopModelsObject = __assign({ ContextContainer: (React.createElement(TopModel, { name: "ContextContainer" }, ListContextContainer)) }, innerListDefaultContexts);
export var virtualizedListContextDefaultTopModelsObject = __assign({ ContextContainer: (React.createElement(TopModel, { name: "ContextContainer" }, VirtualizedListContextContainer)) }, innerListDefaultContexts);
