import {
  PhysicalTAdd, TAddType, PersonType,
} from '@goldwasserexchange/oblis-frontend-utils';
import { makeWhenAlwaysCast } from '../../../Form/validations/whenAlwaysCast';
import { ValidationContextType } from '../../../Form/validations/contextType';
import {
  nilValidator, makeRequired, stringValidator,
} from '../../../../OUI/Inputs';

const mcCivilStatusValidatorWhenPaths: (keyof PhysicalTAdd | '$')[] = [
  '$',
  'T_ADD_type',
  'isMinor',
  'current',
];

export const mcCivilStatusValidator = makeWhenAlwaysCast(mcCivilStatusValidatorWhenPaths, (context: ValidationContextType, tAddType: PhysicalTAdd['T_ADD_type'], isMinor: PhysicalTAdd['isMinor'], current: PhysicalTAdd['current']) => {
  const {
    rootValue,
  } = context;
  const {
    onboarding,
  } = rootValue;
  const {
    personType,
    users,
  } = onboarding;
  if ((tAddType !== TAddType.TITULAR && tAddType !== TAddType.PROCURATION) || isMinor === true) {
    return nilValidator;
  }
  if (personType === PersonType.JOINT && current !== true) {
    const currentUser = users.find((user) => user.current === true);
    if (!currentUser) {
      return nilValidator;
    }
    return stringValidator.transform(() => currentUser.MC_CIVILSTATUS).required();
  }
  return makeRequired(stringValidator);
});
