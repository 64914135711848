import ouiBase from '@goldwasserexchange/oui';
import { makeShowMoralField } from '../../../../../personType/logic/show';
import { makeIdEcplProfShouldHaveProfessionLogic } from '../../../ID_ECPL_PROF/logic';
import { mayHavePreviousJobProfessionList } from '../../../ID_ECPL_PROF/list';
import { TAddType } from '../../../../../../types';
var tAddTypePath = 'T_ADD_type';
var idEcplProfPath = 'ID_ECPL_PROF';
export function makeShowLogic(props) {
    var _a = props.visible, visible = _a === void 0 ? {
        result: 'visible',
    } : _a, _b = props.invisible, invisible = _b === void 0 ? {
        result: 'invisible',
    } : _b;
    return makeShowMoralField({
        visible: {
            name: "<parentName>.".concat(tAddTypePath),
            is: TAddType.MORAL,
            options: { isNegative: true },
            alternatives: {
                truthy: makeIdEcplProfShouldHaveProfessionLogic({
                    visible: visible,
                    invisible: invisible,
                }),
                falsy: visible,
            },
        },
        invisible: invisible,
    });
}
export var showLogic = makeShowLogic({});
export var labelLogic = {
    name: "<parentName>.".concat(idEcplProfPath),
    is: mayHavePreviousJobProfessionList,
    options: {
        isNegative: true,
        matcher: ouiBase.utils.match.arrayMatcherNames.some,
    },
    alternatives: {
        truthy: {
            result: 'current',
        },
        falsy: {
            result: 'past',
        },
    },
};
