import { dataStructure, AccountType } from '@goldwasserexchange/oblis-frontend-utils';
import ouiBase from '@goldwasserexchange/oui';
import ouiDom from '@goldwasserexchange/oui-dom';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { Trans } from '@lingui/macro';
import * as React from 'react';
export const COUPON_CASHING_FEE = 'couponCashingFee';

const couponCashingFeesLabels = {
  [AccountType.RTO]: {
    label: (
      <Trans>
        2%&#32;
        <ouiDom.TextSnippet.TextSnippet
          fontSize="xxxs"
        >
          (min. 2 EUR; max. 90 EUR)
        </ouiDom.TextSnippet.TextSnippet>
      </Trans>
    ),
  },
  [AccountType.CONSEIL]: {
    label: (
      <Trans>
        2%&#32;
        <ouiDom.TextSnippet.TextSnippet
          fontSize="xxxs"
        >
          (min. 2 EUR; max. 90 EUR)
        </ouiDom.TextSnippet.TextSnippet>
      </Trans>
    ),
  },
  [AccountType.GESTION]: {
    label: (
      <Trans>
        2%&#32;
        <ouiDom.TextSnippet.TextSnippet
          fontSize="xxxs"
        >
          (min. 2 EUR; max. 90 EUR)
        </ouiDom.TextSnippet.TextSnippet>
      </Trans>
    ),
  },
};

export const CouponCashingFeesLabel = (props: { accountType: dataStructure.accountType.listType }) => {
  const { accountType } = props;
  return couponCashingFeesLabels[accountType].label;
};

export const CouponCashingFeesLabelOnPrimaryDimension = () => {
  const accountType = ouiBase.utils.dimensions.useCurrentPrimaryDimension() as dataStructure.accountType.listType;
  return (
    <CouponCashingFeesLabel accountType={accountType} />
  );
};

const couponCashingFeesLabel = (
  <Trans>
    Encaissement de coupon
  </Trans>
);

export const couponCashingFeesTopModels = (
  <>
    <TopModel name={`TableSecondaryTitleCellContent-${COUPON_CASHING_FEE}`}>
      {couponCashingFeesLabel}
    </TopModel>
    <TopModel name={`TableBodyRowCellContent--${COUPON_CASHING_FEE}`}>
      {CouponCashingFeesLabelOnPrimaryDimension}
    </TopModel>
  </>
);
