import { AppBarGuardType, AppBarGuardObjectType } from '../type';
import { ValidSections } from '../../../../../../history';

export const name = 'isExpandedSection';

export const fn: AppBarGuardType = () => {
  const { pathname } = window.location;
  const section = pathname.split('/')[2] ?? ValidSections.HOME;
  return section === ValidSections.HOME;
};

export const guard: AppBarGuardObjectType = {
  [name]: fn,
};

export default guard;
