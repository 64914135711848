import ouiBase from '@goldwasserexchange/oui';
import ouiStyle from '@goldwasserexchange/oui-style';
import { RampWithNameTransform } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import { DefaultInputContextProviderProps } from '../../../context';
import { DefaultListInputContextProvider, UseGetFnProps } from '../../shared';
import { SearchKeysProps } from '../../shared/components/list/context/searchKeys/context';

export function CheckboxInputContextProvider(
  props: {
    elementLines?: ouiStyle.InStyle['lines'],
    type: Extract<ouiBase.Input.InputTypes, 'radio' | 'checkbox'>,
    checkboxPotentialFieldPaths?: string[],
    elementFieldPathHook: () => string,
    validateFieldPath?: boolean,
  } & SearchKeysProps & UseGetFnProps & DefaultInputContextProviderProps &
  ouiBase.List.ListProps,
) {
  const {
    type,
    fieldPath,
    elementFieldPathHook,
    dependenciesFieldPaths,
    list,
    length,
    elementLines,
    checkboxPotentialFieldPaths = [],
    validateFieldPath,
    useFilterFn,
    useSortFn,
    useGetFn,
    searchKeys,
    children,
  } = props;
  return (
    <RampWithNameTransform<{
      elementFieldPathHook: () => string,
    }>
      rampName="ElementFieldPathHookContext"
      elementFieldPathHook={elementFieldPathHook}
    >
      <RampWithNameTransform<{
        validateFieldPath?: boolean,
      }>
        rampName="InputCheckboxValidateFieldPathProvider"
        validateFieldPath={validateFieldPath}
      >
        <RampWithNameTransform<{
          checkboxPotentialFieldPaths: string[],
        }>
          rampName="InputCheckboxPotentialFieldPathsProvider"
          checkboxPotentialFieldPaths={checkboxPotentialFieldPaths}
        >
          <DefaultListInputContextProvider
            type={type}
            fieldPath={fieldPath}
            dependenciesFieldPaths={dependenciesFieldPaths}
            list={list}
            length={length}
            elementLines={elementLines}
            useFilterFn={useFilterFn}
            useSortFn={useSortFn}
            useGetFn={useGetFn}
            searchKeys={searchKeys}
          >
            {children}
          </DefaultListInputContextProvider>
        </RampWithNameTransform>
      </RampWithNameTransform>
    </RampWithNameTransform>
  );
}
