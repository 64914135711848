import { makeShowLogic } from './show';
var fiscRegistNumPath = 'TIN_US';
var natRegistNumPath = 'nat_REGIST_NUM';
export var pathLogic = makeShowLogic({
    national: {
        result: fiscRegistNumPath,
    },
    global: {
        result: fiscRegistNumPath,
    },
    invisible: {
        result: '',
    },
    nationalInNatNumber: {
        result: natRegistNumPath,
    },
});
