import ouiBase from '@goldwasserexchange/oui';
import {
  computePositionValue, computePositionsValue, useFilterByContextSTypeGrpId,
} from '../../utils';
import { useAccountPositionsTransformApi, useAccountPositionTransformApiByGesEstimDId } from '../api';

export const useAccountPositionEstim = () => {
  const gesEstimDId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const result = useAccountPositionTransformApiByGesEstimDId({
    gesEstimDId,
    transformFunction: computePositionValue,
  });
  return result;
};

export const useAccountPositionEstimTotalBySTypeGroup = () => {
  const filterPredicate = useFilterByContextSTypeGrpId();
  const result = useAccountPositionsTransformApi({
    filterPredicate,
    transformFunction: computePositionsValue,
  });
  return result;
};

export const useAccountPositionEstimTotal = () => {
  const result = useAccountPositionsTransformApi({
    transformFunction: computePositionsValue,
  });
  return result;
};
