import { isEmpty } from 'ramda';
import qs from 'query-string';
import {
  GetAccountsCashflowsParams, GetAccountsDocumentsParams, GetAccountsHistoryParams, GetAccountsIbansParams, GetAccountsPositionsParams, GetAccountsRatesParams, GetAccountsTwrrParams, GetDocumentsParams, GetPersonsAccountsParams, GetSecuritiesQuotesParams,
} from '@goldwasserexchange/actor/rest-services';
import { AccountAvailableCashParams } from '../../accounts';

type Params =
  | AccountAvailableCashParams
  | GetAccountsCashflowsParams
  | Omit<GetAccountsDocumentsParams, 'ROW_NUMBER_START' | 'ROW_NUMBER_END'> & {
    ROW_NUMBER_START?: number | undefined,
    ROW_NUMBER_END?: number | undefined,
  }
  | GetAccountsHistoryParams
  | GetAccountsIbansParams
  | GetAccountsPositionsParams
  | GetAccountsRatesParams
  | GetAccountsTwrrParams
  | GetDocumentsParams
  | GetPersonsAccountsParams
  | GetSecuritiesQuotesParams

export const stringifyParams = (params?: Params) => {
  const stringifiedParams = params && !isEmpty(params) ? `?${qs.stringify(params)}` : '';
  return stringifiedParams;
};
