import { makeShowMoralField } from '../../../../personType/logic/show';
var tAddAddressPath = 'ID_T_ADD_ADDRESS_KEY';
var onboardingPath = 'onboarding';
var isPrintPath = 'isPrint';
export var showLogic = makeShowMoralField({
    visible: {
        name: "".concat(onboardingPath, ".").concat(isPrintPath),
        is: false,
        alternatives: {
            truthy: {
                name: "<parentName>.".concat(tAddAddressPath),
                is: '',
                options: {
                    isNegative: true,
                },
                alternatives: {
                    truthy: {
                        result: 'visible',
                    },
                    falsy: {
                        result: 'invisible',
                    },
                },
            },
            falsy: {
                result: 'visible',
            },
        },
    },
});
export var showBooleanLogic = makeShowMoralField({
    visible: {
        name: "".concat(onboardingPath, ".").concat(isPrintPath),
        is: false,
        alternatives: {
            truthy: {
                name: "<parentName>.".concat(tAddAddressPath),
                is: '',
                options: {
                    isNegative: true,
                },
                alternatives: {
                    truthy: {
                        result: true,
                    },
                    falsy: {
                        result: false,
                    },
                },
            },
            falsy: {
                result: true,
            },
        },
    },
    invisible: {
        result: false,
    },
});
