import { RampWithNameTransform } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';

export function DefaultDomSelectInputListSearchInputContainer() {
  return (
    <>
      <RampWithNameTransform rampName="SelectedIconPlaceHolder">
        <RampWithNameTransform rampName="SelectedIconContent" />
      </RampWithNameTransform>
      <RampWithNameTransform rampName="SearchInputContext" />
    </>
  );
}
