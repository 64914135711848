import { useTopModelExistByNameWithNameTransform } from '@goldwasserexchange/react-topmodel';
import { useIsTouched, useIsValid } from '../../../../context';

export type IconType = 'valid' | 'error' | 'help' | 'none';

export const useInputIconType = (): IconType => {
  const isTouched = useIsTouched();
  const isValid = useIsValid();
  const hasHelpContentTopModel = useTopModelExistByNameWithNameTransform('InputHelpPopperContent');
  if (isTouched) {
    if (isValid) {
      return 'valid';
    }
    return 'error';
  }
  if (hasHelpContentTopModel) {
    return 'help';
  }
  return 'none';
};
