import * as React from 'react';
import { StateFilter } from '../../../../../../Machine';
import { Suspended } from '../../../../../../Suspended';
import { BackToServiceSelection } from '../../../directNavigation/backToServiceSelection';
import { LazyFinancialSituationQuestionnaireStep } from '../../../questionnaires/InvestmentGoalsQuestionnaire';
import { StepContainer } from '../../../utils/Step';
import { CommonInvestmentGoalsExclusions } from '../../shared';
import { GestionInvetmentGoalsExclusions } from './exclusions';

export const GestionInvestmentGoals = () => (
  <>
    <StateFilter is=".questions">
      <Suspended>
        <LazyFinancialSituationQuestionnaireStep />
      </Suspended>
    </StateFilter>
    <StateFilter is=".exclusions">
      <StepContainer>
        <CommonInvestmentGoalsExclusions />
        <GestionInvetmentGoalsExclusions />
        <BackToServiceSelection />
      </StepContainer>
    </StateFilter>
  </>
);
