import ouiStyle from '@goldwasserexchange/oui-style';

export const footerContentStyle: ouiStyle.InStyle = {
  position: 'absolute',
  widthLines: 'full',
  paddingTop: 'none',
  paddingRight: 'simple',
  paddingBottom: 'simple',
  paddingLeft: 'simple',
  transformOrigin: ouiStyle.Constants.TransformOriginProp.left,
};
