var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useContext } from 'react';
import { listOpenStateHandlersContext } from './context';
export var useSelectOpenStateHandlers = function () {
    var selectOpenStateHandlers = useContext(listOpenStateHandlersContext);
    return selectOpenStateHandlers;
};
export var useSelectIsOpen = function () {
    var _a = __read(useSelectOpenStateHandlers(), 1), isOpen = _a[0];
    return isOpen;
};
