var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { RampWithNameTransform } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import { useRipple } from '../context';
export var DomRippleEffectBase = function (props) {
    var sourceBackgroundColor = props.sourceBackgroundColor;
    var _a = __read(useRipple(), 2), style = _a[0], onClick = _a[1];
    return (React.createElement(RampWithNameTransform, { rampName: "Container", onClick: onClick },
        React.createElement(RampWithNameTransform, { rampName: "Content", style: style, sourceBackgroundColor: sourceBackgroundColor })));
};
