import ouiBase from '@goldwasserexchange/oui';
import { RampWithNameTransform } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import {
  DefaultBaseInputContextProviderProps,
  DefaultInputContextProvider,
} from '../../../context';
import { LocalRifmArgs } from '../context/rifm/context';

export type DefaultTextInputContextProviderProps = DefaultBaseInputContextProviderProps & LocalRifmArgs & {
  type?: Exclude<ouiBase.Input.InputTypes, 'radio' | 'checkbox'>,
}

export function DefaultTextInputContextProvider(
  props: React.PropsWithChildren<DefaultTextInputContextProviderProps>,
) {
  const {
    fieldPath,
    dependenciesFieldPaths,
    format,
    replace,
    append,
    mask,
    accept,
    type = 'text',
    autocomplete,
    children,
  } = props;
  return (
    <RampWithNameTransform rampName="RefContextProvider">
      <DefaultInputContextProvider
        type={type}
        fieldPath={fieldPath}
        dependenciesFieldPaths={dependenciesFieldPaths}
        autocomplete={autocomplete}
      >
        <RampWithNameTransform rampName="OnChangeContextProvider">
          <RampWithNameTransform<LocalRifmArgs>
            rampName="RifmContextProvider"
            format={format}
            replace={replace}
            append={append}
            mask={mask}
            accept={accept}
          >
            {children}
          </RampWithNameTransform>
        </RampWithNameTransform>
      </DefaultInputContextProvider>
    </RampWithNameTransform>
  );
}
