import { GetBondsBondResponse } from '@goldwasserexchange/actor/rest-services';
import { isNil } from 'ramda';
import { useCallback } from 'react';
import { STypeGrpIds } from '../../../../../shared/utils/sTypeGrpIds';
import { BondResponse } from '../../type';
import { useBondsBondTransformApi } from '../api';

const makeTransformFn = (fields: (keyof GetBondsBondResponse)[]) => (bondsBond: BondResponse | undefined) => {
  if (isNil(bondsBond)) {
    return undefined;
  }
  return fields.filter((field) => {
    const {
      redacted,
    } = bondsBond;
    if (field === 'S_ACC.GREEN' && `${bondsBond['S_ACC.GREEN']}` !== '1') {
      return false;
    }
    if (field === 'S_ACC.OBL_END_PRICE' && bondsBond['S_TYPE_GRP.ID'] === STypeGrpIds.OBLIGATIONS_PERP) {
      return false;
    }
    if ((redacted ?? []).includes(field)) {
      return true;
    }
    return !isNil(bondsBond[field]);
  });
};

export const useBondsBondFieldsAvailable = (fields: (keyof GetBondsBondResponse)[]) => {
  const transformFn = useCallback(makeTransformFn(fields), [fields]);
  const response = useBondsBondTransformApi(transformFn);
  return response;
};
