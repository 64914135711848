/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import useDimensions from 'react-cool-dimensions';
import { useSetBannerHeight } from '../../context';

const style: ouiStyle.InStyleWithMediaQueries = {
  display: ouiStyle.Constants.DisplayProp.flex,
  justifyContent: ouiStyle.Constants.JustifyContentProp.center,
  alignItems: ouiStyle.Constants.AlignItemsProp.center,
  flexDirection: [ouiStyle.Constants.InFlexDirectionProp.col, undefined, undefined, ouiStyle.Constants.InFlexDirectionProp.row, undefined, undefined],
  backgroundColor: ouiStyle.Constants.Colors.accent,
  color: ouiStyle.Constants.Colors.inverted,
  paddingHorizontal: 'simple',
  paddingVertical: 'half',
  textAlign: 'center',
};

const css = ouiStyle.mediaQuery(ouiStyle.makeCss(style));

export const BannerContainer = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  const setBannerHeight = useSetBannerHeight();
  const { observe } = useDimensions<HTMLElement>({
    useBorderBoxSize: true,
    polyfill: ResizeObserver,
    onResize: ({ height: elementHeight }) => {
      setBannerHeight(elementHeight);
    },
  });
  return (
    <div
      ref={observe}
      css={css}
    >
      {children}
    </div>
  );
};
