import { isNil } from 'ramda';

export function stringifyIs(is: number | boolean | undefined | string | null): string
export function stringifyIs(is: (number | boolean | undefined | string | null)[]): string[]
export function stringifyIs(is: number | boolean | undefined | string | null | (number | boolean | undefined | string | null)[]): string | string[] {
  if (Array.isArray(is)) {
    return is.map((individualIs) => stringifyIs(individualIs));
  }
  if (isNil(is)) {
    return '';
  }
  if (typeof is === 'boolean') {
    if (is === true) {
      return '1';
    }
    return '0';
  }
  return `${is}`;
}
