import * as React from 'react';
import { ActorApiStringDisplay } from '../../../../../../components/display/string';
import { useBondsBondGesRatValSpShortcut, useBondsBondIsRedacted } from '../../hooks';

export const BondsBondGesRatValSpShortcut = (props: {
  withRedactedIcon?: boolean,
}) => {
  const {
    withRedactedIcon,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useBondsBondGesRatValSpShortcut();
  const {
    data: redacted,
    isLoading: redactedIsLoading,
  } = useBondsBondIsRedacted('GES_RAT_VAL_SP.SHORTCUT');
  return (
    <ActorApiStringDisplay
      data={data}
      isLoading={isLoading || redactedIsLoading}
      error={error}
      loadingValue="--"
      isBlocked={redacted}
      withRedactedIcon={withRedactedIcon}
    />
  );
};
