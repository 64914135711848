import {
  differenceInHours, differenceInMonths, endOfDay, endOfMonth, getDate, getMonth, getYear, isBefore, isEqual, max,
} from 'date-fns';

export interface BondProps {
  settlement?: Date,
  maturity: Date,
  issue?: Date,
  firstCoupon?: Date,
  rate: number,
  redemption?: number,
  frequency?: BondFrequency,
  convention?: DayCountConvention,
}

export enum BondFrequency {
  ANNUAL = 'ANNUAL',
  SEMI_ANNUAL = 'SEMI_ANNUAL',
  QUARTERLY = 'QUARTERLY'
}

export enum DayCountConvention {
  US_30_360 = 'US_30_360',
  ACTUAL_ACTUAL = 'ACTUAL_ACTUAL',
  ACTUAL_360 = 'ACTUAL_360',
  ACTUAL_365 = 'ACTUAL_365',
  EU_30_360 = 'EU_30_360',
}

export class Bond {
  public readonly settlement: Date;

  public readonly maturity: Date;

  public readonly rate: number;

  public readonly redemption: number;

  public readonly frequency: number;

  public readonly convention: DayCountConvention;

  public readonly previousCoupon?: Date;

  public readonly nextCoupon: Date;

  public readonly accruedDays: number;

  public readonly accrued: number;

  public readonly couponsNumber: number;

  private readonly issue?: Date;

  private readonly firstCoupon?: Date;

  private readonly accruedStart: Date;

  private readonly DSC: number;

  private readonly E: number;

  constructor(props: BondProps) {
    this.settlement = props.settlement ?? new Date();
    this.maturity = props.maturity;
    if (this.settlement >= this.maturity) {
      throw new Error('Settlement must be before maturity');
    }

    this.rate = props.rate;
    this.redemption = props.redemption ?? 100;
    this.frequency = toNumericFrequency(props.frequency ?? BondFrequency.ANNUAL);
    this.convention = props.convention ?? DayCountConvention.US_30_360;
    this.issue = props.issue;
    this.firstCoupon = props.firstCoupon;
    if (this.issue && this.firstCoupon && this.issue >= this.firstCoupon) {
      throw new Error('Issue must be before first coupon');
    }

    const coupons = this.coupons();

    if (coupons.previous) {
      this.previousCoupon = coupons.previous;
      this.accruedStart = coupons.previous;
    } else if (this.issue) {
      this.accruedStart = this.issue;
    } else {
      throw new Error('Cannot determine accrued start date');
    }

    this.nextCoupon = coupons.next;
    this.couponsNumber = coupons.number;
    this.accruedDays = Math.max(0, this.differenceInDays(this.settlement, this.accruedStart));
    this.E = this.couponDays(this.accruedStart, this.nextCoupon);
    this.DSC = this.E - this.accruedDays;
    this.accrued = (this.accruedDays / this.E) * (this.rate / this.frequency);
  }

  public price(yld: number): number {
    if (this.couponsNumber === 1) {
      const T1 = 100 * (this.rate / this.frequency) + this.redemption;
      const T2 = (yld / this.frequency) * (this.DSC / this.E) + 1;
      const T3 = 100 * (this.rate / this.frequency) * (this.accruedDays / this.E);
      return (T1 / T2) - T3;
    }

    let price = this.redemption / ((1 + yld / this.frequency) ** ((this.couponsNumber - 1) + (this.DSC / this.E))) - (100 * (this.rate / this.frequency) * (this.accruedDays / this.E));

    for (let k = 1; k <= this.couponsNumber; k++) { // eslint-disable-line no-plusplus
      price += (100 * (this.rate / this.frequency)) / ((1 + yld / this.frequency) ** (k - 1 + (this.DSC / this.E)));
    }

    return price;
  }

  public yield(price: number): number {
    if (this.couponsNumber === 1) {
      const DSR = this.differenceInDays(this.maturity, this.settlement);
      const E = this.differenceInDays(this.nextCoupon, this.accruedStart);
      const T1 = (this.redemption / 100) + (this.rate / this.frequency) - (price / 100) - ((this.accruedDays / E) * (this.rate / this.frequency));
      const T2 = (price / 100 + ((this.accruedDays / E) * (this.rate / this.frequency)));
      const T3 = (this.frequency * E) / DSR;
      return (T1 / T2) * T3;
    }

    return newton({
      f: (yld) => this.price(yld) - price,
      fp: (yld) => this.dPrice(yld),
      guess: this.rate,
    });
  }

  // Price derivative
  private dPrice(yld: number): number {
    let price = -(this.redemption / this.frequency) * (this.couponsNumber - 1 + (this.DSC / this.E)) * ((1 + yld / this.frequency) ** (-(this.couponsNumber + (this.DSC / this.E))));

    for (let k = 1; k <= this.couponsNumber; k++) { // eslint-disable-line no-plusplus
      price -= ((100 * (this.rate / this.frequency)) ** 2) * (k - 1 + (this.DSC / this.E)) * ((1 + yld / this.frequency) ** (-(k + (this.DSC / this.E))));
    }

    return price;
  }

  private coupons(): { previous?: Date, next: Date, number: number } {
    let previous: Date | undefined;
    let next: Date;

    if (this.firstCoupon) {
      next = this.firstCoupon;
      while (next < this.settlement) {
        next = addMonths(next, 12 / this.frequency);
      }
      previous = addMonths(next, -12 / this.frequency);
    } else {
      previous = this.maturity;
      const startDate = this.issue ? max([this.issue, this.settlement]) : this.settlement;
      while (previous > startDate) {
        previous = addMonths(previous, -12 / this.frequency);
      }
      next = addMonths(previous, 12 / this.frequency);
    }

    if (isEndOfMonth(this.maturity)) {
      previous = endOfMonth(previous);
      previous.setUTCHours(0, 0, 0, 0);
      next = endOfMonth(next);
      next.setUTCHours(0, 0, 0, 0);
    }

    const number = Math.ceil(differenceInMonths(this.maturity, next) / (12 / this.frequency)) + 1;

    if ((this.firstCoupon && isBefore(previous, this.firstCoupon)) || (this.issue && isBefore(previous, this.issue))) {
      previous = undefined;
    }

    return {
      previous,
      next,
      number,
    };
  }

  private couponDays(previous: Date, next: Date): number {
    switch (this.convention) {
      case DayCountConvention.ACTUAL_365:
        return 365 / this.frequency;
      case DayCountConvention.ACTUAL_ACTUAL:
        return Math.floor(differenceInHours(next, previous) / 24);
      case DayCountConvention.US_30_360:
      case DayCountConvention.EU_30_360:
      case DayCountConvention.ACTUAL_360:
        return 360 / this.frequency;
      default:
        throw new Error('Unknown day count convention');
    }
  }

  private differenceInDays(left: Date, right: Date): number {
    switch (this.convention) {
      case DayCountConvention.ACTUAL_360:
      case DayCountConvention.ACTUAL_365:
      case DayCountConvention.ACTUAL_ACTUAL:
        return Math.floor(differenceInHours(left, right) / 24);
      case DayCountConvention.EU_30_360:
        return differenceInDays30360(toDMY(left), toDMY(right));
      case DayCountConvention.US_30_360: {
        const leftDMY = toDMY(left);
        const rightDMY = toDMY(right);
        if (isEndOfFebruary(right)) {
          rightDMY.day = 30;
        }
        if (isEndOfFebruary(right) && isEndOfFebruary(left)) {
          leftDMY.day = 30;
        }
        return differenceInDays30360(leftDMY, rightDMY);
      }
      default:
        throw new Error('Unknown day count convention');
    }
  }
}

function toNumericFrequency(frequency: BondFrequency): number {
  switch (frequency) {
    case BondFrequency.ANNUAL:
      return 1;
    case BondFrequency.SEMI_ANNUAL:
      return 2;
    case BondFrequency.QUARTERLY:
      return 4;
    default:
      throw new Error('Unknown frequency');
  }
}

function isEndOfMonth(date: Date): boolean {
  return isEqual(endOfDay(date), endOfMonth(date));
}

function isEndOfFebruary(date: Date): boolean {
  return isEndOfMonth(date) && getMonth(date) === 1;
}

interface DMY {
  day: number,
  month: number,
  year: number,
}

function toDMY(date: Date): DMY {
  return {
    day: getDate(date),
    month: getMonth(date),
    year: getYear(date),
  };
}

function differenceInDays30360(left: DMY, right: DMY): number {
  return (left.year - right.year) * 360
    + (left.month - right.month) * 30
    + Math.min(30, left.day) - Math.min(30, right.day);
}

function addMonths(date: Date, months: number): Date {
  const res = new Date(date);
  res.setUTCMonth(res.getUTCMonth() + months);
  if (res.getDate() !== date.getDate()) {
    res.setDate(0);
  }
  return res;
}

interface NewtonOptions {
  f: (x: number) => number,
  fp: (x: number) => number,
  guess: number,
  tolerance?: number,
  maxIterations?: number,
}

// Newton iteration to find the root of a function
function newton(options: NewtonOptions): number {
  const maxIterations = options.maxIterations ?? 100;
  const tolerance = options.tolerance ?? 1e-6;

  let x = options.guess;

  for (let i = 0; i < maxIterations; i++) { // eslint-disable-line no-plusplus
    const fx = options.f(x);
    if (Math.abs(fx) < tolerance) {
      return x;
    }

    const fpx = options.fp(x);
    if (Math.abs(fpx) < tolerance) {
      throw new Error('Derivative is too small');
    }

    x -= fx / fpx;
  }

  return x;
}
