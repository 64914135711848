import { useFilterByTAccId } from '../../utils/tAcc/filterByTAccId';
import { PersonAccounts } from '../../type';
import {
  getAccountCommunicationFromPersonAccounts,
  getAccountCommunicationStartFromPersonAccounts,
  getAccountCommunicationRootStartFromPersonAccounts,
  getAccountCommunicationRootMiddleFromPersonAccounts,
  getAccountCommunicationRootEndFromPersonAccounts,
  getAccountCommunicationEndFromPersonAccounts,
} from '../../utils';
import { usePersonAccountTransformApi } from '../api';

const unformatNumDisplay = (personAccount: PersonAccounts | undefined | null) => {
  if (!personAccount) {
    return '';
  }
  const numDisplay = personAccount['T_ACC.NUM_DISPLAY'];
  return numDisplay.replaceAll('/', '');
};

export const usePersonAccountsUnformattedTAccNumDisplay = () => {
  const tAccFilterById = useFilterByTAccId();
  const result = usePersonAccountTransformApi({
    filterPredicate: tAccFilterById,
    transformFunction: unformatNumDisplay,
  });
  return result;
};

export const usePersonAccountsFormattedTAccNumDisplay = () => {
  const tAccFilterById = useFilterByTAccId();
  const result = usePersonAccountTransformApi({
    filterPredicate: tAccFilterById,
    transformFunction: getAccountCommunicationFromPersonAccounts,
  });
  return result;
};

export const usePersonAccountsTAccNumDisplayStart = () => {
  const tAccFilterById = useFilterByTAccId();
  const result = usePersonAccountTransformApi({
    filterPredicate: tAccFilterById,
    transformFunction: getAccountCommunicationStartFromPersonAccounts,
  });
  return result;
};

export const usePersonAccountsTAccNumDisplayRootStart = () => {
  const tAccFilterById = useFilterByTAccId();
  const result = usePersonAccountTransformApi({
    filterPredicate: tAccFilterById,
    transformFunction: getAccountCommunicationRootStartFromPersonAccounts,
  });
  return result;
};

export const usePersonAccountsTAccNumDisplayRootMiddle = () => {
  const tAccFilterById = useFilterByTAccId();
  const result = usePersonAccountTransformApi({
    filterPredicate: tAccFilterById,
    transformFunction: getAccountCommunicationRootMiddleFromPersonAccounts,
  });
  return result;
};

export const usePersonAccountsTAccNumDisplayRootEnd = () => {
  const tAccFilterById = useFilterByTAccId();
  const result = usePersonAccountTransformApi({
    filterPredicate: tAccFilterById,
    transformFunction: getAccountCommunicationRootEndFromPersonAccounts,
  });
  return result;
};

export const usePersonAccountsTAccNumDisplayEnd = () => {
  const tAccFilterById = useFilterByTAccId();
  const result = usePersonAccountTransformApi({
    filterPredicate: tAccFilterById,
    transformFunction: getAccountCommunicationEndFromPersonAccounts,
  });
  return result;
};
