import * as React from 'react';
import { StateFilter } from '../../../../../../../Machine';
import { Suspended } from '../../../../../../../Suspended';
import { LazyHorizonNOKExclusion } from './horizon';
import { LazyRiskLevelNOKExclusion } from './riskLevel';
import { LazyRiskChangeNOKExclusion } from './riskChange';
import { LazyGoalNOKExclusion } from './goal';

export const GestionInvetmentGoalsExclusions = () => (
  <>
    <StateFilter is=".horizonNOK.excluded">
      <Suspended>
        <LazyHorizonNOKExclusion />
      </Suspended>
    </StateFilter>
    <StateFilter is=".riskLevelNOK.excluded">
      <Suspended>
        <LazyRiskLevelNOKExclusion />
      </Suspended>
    </StateFilter>
    <StateFilter is=".riskChangeNOK.excluded">
      <Suspended>
        <LazyRiskChangeNOKExclusion />
      </Suspended>
    </StateFilter>
    <StateFilter is=".goalNOK.excluded">
      <Suspended>
        <LazyGoalNOKExclusion />
      </Suspended>
    </StateFilter>
  </>
);
