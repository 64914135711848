import * as React from 'react';
import { useOuiStyleMediaContextByIndex } from '../../../../../../MediaContext';
import { currentPromoLimitContext } from './context';

export const CurrentPromoLimitProvider = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  const isUpSm = useOuiStyleMediaContextByIndex(2);
  return (
    <currentPromoLimitContext.Provider value={isUpSm ? 4 : null}>
      {children}
    </currentPromoLimitContext.Provider>
  );
};
