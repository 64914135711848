import { SimulationBuyAndSellCostData } from '../type';

export const yearlyPct = (props: {
  counterValue: SimulationBuyAndSellCostData['counterValue'],
  durationYears: SimulationBuyAndSellCostData['durationYears'],
}) => (value: number) => {
  const {
    counterValue,
    durationYears,
  } = props;
  return value / counterValue / durationYears;
};
