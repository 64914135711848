import * as React from 'react';
import { IconContainer } from '../container';

export const HomeIcon = (props: {
  style?: React.SVGAttributes<SVGSVGElement>['style'],
}) => {
  const {
    style,
  } = props;
  return (
    <IconContainer
      style={style}
    >
      <path d="M37.5,72.4c-19.2,0-34.9-15.7-34.9-34.9S18.3,2.6,37.5,2.6s34.9,15.7,34.9,34.9-15.7,34.9-34.9,34.9ZM37.5,7.6c-16.5,0-29.9,13.4-29.9,29.9s13.4,29.9,29.9,29.9,29.9-13.4,29.9-29.9S54,7.6,37.5,7.6Z" />
      <g>
        <path d="M37.5,58.2c-1.4,0-2.5-1.1-2.5-2.5v-21c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v21c0,1.4-1.1,2.5-2.5,2.5Z" />
        <path d="M37.5,25c-2.3,0-4.1-1.8-4.1-4.1s1.8-4.1,4.1-4.1,4.1,1.8,4.1,4.1-1.8,4.1-4.1,4.1Z" />
      </g>
    </IconContainer>
  );
};
