import * as React from 'react';
import { useMemo } from 'react';
import warning from 'warning';
export function useFreezeArrayOnLength(array, name) {
    var previousLength = React.useRef(array.length);
    React.useEffect(function () {
        warning(array.length === previousLength.current, "you should not change the ".concat(name, "  array, those changes won't be reflected"));
    }, [array]);
    var freezedArray = useMemo(function () { return array; }, array);
    return freezedArray;
}
