import { createClient, ClientConfig } from '@sanity/client';
import { baseSanityConfig } from './baseConfig';

const clientConfig: ClientConfig = {
  ...baseSanityConfig,
  useCdn: true,
  apiVersion: '2024-02-06',
  perspective: 'published',
};

export const sanityClient = createClient(clientConfig);
