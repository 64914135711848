import * as React from 'react';
import ouiDom from '@goldwasserexchange/oui-dom';
import { isNil } from 'ramda';
import { SetSizeContextProvider } from './context';
import { SizeContextProvider } from './context/size';

export type ActorApiTableProps = {
  data: string[] | undefined | null,
  isLoading: boolean,
  error: any,
  secondaryDimensionList: string[],
  size?: number,
  setSize?: (size: number | ((_size: number) => number)) => Promise<any[][] | undefined>,
  direction?: 'vertical' | 'horizontal',
  wrapping?: 'horizontal' | 'vertical' | 'none',
  multiBody?: boolean,
  loadingValue?: string[],
  topModels?: Record<string, React.ReactNode>,
}

export const ActorApiTable = (props: React.PropsWithChildren<ActorApiTableProps>) => {
  const {
    loadingValue = [],
    isLoading: dataIsLoading,
    error,
    data,
    size,
    setSize,
    secondaryDimensionList,
    direction,
    wrapping,
    multiBody,
    topModels,
    children,
  } = props;
  const isLoading = dataIsLoading || !isNil(error);
  return (
    <SizeContextProvider
      size={size}
    >
      <SetSizeContextProvider
        size={size}
        setSize={setSize}
      >
        <ouiDom.Table.Table
          wrapping={wrapping}
          direction={direction}
          multiBody={multiBody}
          primaryDimensionList={(!isLoading && data ? data : loadingValue)}
          secondaryDimensionList={secondaryDimensionList}
          topModels={topModels}
        >
          {children}
        </ouiDom.Table.Table>
      </SetSizeContextProvider>
    </SizeContextProvider>
  );
};
