import { T } from 'ramda';
import { assign } from 'xstate';
import { AppBarEvent } from '../../../events';
import { AppBarMachineContext } from '../../../machineContext';

export const name = 'setMqDown';

export const fn = assign<AppBarMachineContext, AppBarEvent>({
  mqUp: () => 0,
  mqAction: () => 1,
  immediate: T,
});

export const action = {
  [name]: fn,
};

export default action;
