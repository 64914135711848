var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { RampWithNameTransform } from '@goldwasserexchange/react-topmodel';
import { useShowedItems, } from '../../../context/showedItems/hooks';
import { useListInputElementWidth, } from '../../../context/elementLines/elementWidthLines/hooks';
import { useListInputElementHeight, } from '../../../context/elementLines/hooks';
import { usePrimaryDimensionLength } from '../../../../utils/dimensions/primaryDimension/list/length/hooks';
import { usePrimaryDimensionDataIndexArray } from '../../../../utils/dimensions/primaryDimension/list/hooks';
var ListContainerRamp = function (props) { return React.createElement(RampWithNameTransform, __assign({ rampName: "Container" }, props)); };
var ListContainerPlaceHolderRamp = function (props) { return React.createElement(RampWithNameTransform, __assign({ rampName: "ContainerPlaceHolder" }, props)); };
export function DefaultVirtualizedListMapper() {
    var listData = usePrimaryDimensionDataIndexArray();
    var itemCount = usePrimaryDimensionLength();
    var showedItems = useShowedItems();
    var currentElementHeight = useListInputElementHeight();
    return (React.createElement(FixedSizeList, { outerElementType: ListContainerPlaceHolderRamp, innerElementType: ListContainerRamp, height: currentElementHeight * showedItems, itemCount: itemCount, itemSize: currentElementHeight, itemKey: function (index) { var _a; return (_a = listData[index]) !== null && _a !== void 0 ? _a : index; }, width: "100%" }, function (_a) {
        var index = _a.index, style = _a.style;
        return (React.createElement(RampWithNameTransform, { key: index, rampName: "ElementBase", index: index, style: style }));
    }));
}
export function DefaultVirtualizedHorizontalListMapper() {
    var listData = usePrimaryDimensionDataIndexArray();
    var itemCount = usePrimaryDimensionLength();
    var currentElementHeight = useListInputElementHeight();
    var currentElementWidth = useListInputElementWidth();
    return (React.createElement(AutoSizer, { disableHeight: true, style: {
            width: '100%',
        } }, function (props) { return props.width && (React.createElement(FixedSizeList, { direction: "horizontal", outerElementType: ListContainerPlaceHolderRamp, innerElementType: ListContainerRamp, height: currentElementHeight, itemCount: itemCount, itemSize: currentElementWidth, itemKey: function (index) { return (listData[index] != null ? "".concat(listData[index]) : "".concat(index)); }, width: props.width }, function (_a) {
        var index = _a.index, style = _a.style;
        return (React.createElement(RampWithNameTransform, { key: index, rampName: "ElementBase", index: index, style: style }));
    })); }));
}
