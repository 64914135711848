import * as React from 'react';
import { createState } from '@hookstate/core';
import { Untracked } from '@hookstate/untracked';
import { errorsContext } from './context';

export const FormErrorsContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  const errorsState = React.useMemo(() => {
    const state = createState<Record<string, string[]>>({});
    state.attach(Untracked);
    return state;
  }, []);
  return (
    <errorsContext.Provider value={errorsState}>
      {children}
    </errorsContext.Provider>
  );
};
