import { mediaQueries, mediaQuerizeProps } from './mediaQuery';
import { basePlusFontSize, fontSize as baseFontSize, mediumFontSize, mediumPlusFontSize, smallerFontSize, smallestFontSize, } from './utils';
var createFontSizeMap = function () {
    var newFontSizeMap = new Map();
    newFontSizeMap.set('xxxs', smallestFontSize);
    newFontSizeMap.set('xxs', smallerFontSize);
    newFontSizeMap.set('xs', baseFontSize);
    newFontSizeMap.set('s', basePlusFontSize);
    newFontSizeMap.set('m', mediumFontSize);
    newFontSizeMap.set('xm', mediumPlusFontSize);
    return newFontSizeMap;
};
export var fontSizeMap = createFontSizeMap();
export var computeFontSize = function (fontSize, // eslint-disable-line default-param-last
lineFactor) {
    if (fontSize === void 0) { fontSize = 'xs'; }
    if (!Array.isArray(fontSize) && !Array.isArray(lineFactor)) {
        if (fontSize === 'inherit') {
            return fontSize;
        }
        return "".concat(fontSizeMap.get(fontSize) * lineFactor, "rem");
    }
    var fontSizeMQ = mediaQuerizeProps(fontSize);
    var lineFactorMQ = mediaQuerizeProps(lineFactor);
    return mediaQueries.map(function (_, index) { return computeFontSize(fontSizeMQ[index], lineFactorMQ[index]); });
};
