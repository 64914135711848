import * as React from 'react';
import { RampWithNameTransform } from '@goldwasserexchange/react-topmodel';
import ouiBase from '@goldwasserexchange/oui';
import {
  SearchValueProvider,
  FuzzySearchIndexerRefProvider,
} from '../../../context';
import { VisibleIndexesRefContextProvider } from './visibleIndexes';
import {
  StickyPositionRefreshProvider,
  StickyPositionHandlersContextProvider,
} from './stickyPosition';

export function SelectInputListContextProvider(
  props: React.PropsWithChildren<{
    length?: number,
  }>,
) {
  const { length, children } = props;
  return (
    <SearchValueProvider>
      <FuzzySearchIndexerRefProvider>
        <RampWithNameTransform rampName="DataProvider">

          <RampWithNameTransform<{ length?: number }>
            rampName="LengthProvider"
            length={length}
          >
            <VisibleIndexesRefContextProvider>
              <StickyPositionHandlersContextProvider>
                <StickyPositionRefreshProvider>
                  <ouiBase.List.ScrollTopContextProvider>
                    {children}
                  </ouiBase.List.ScrollTopContextProvider>
                </StickyPositionRefreshProvider>
              </StickyPositionHandlersContextProvider>
            </VisibleIndexesRefContextProvider>
          </RampWithNameTransform>
        </RampWithNameTransform>
      </FuzzySearchIndexerRefProvider>
    </SearchValueProvider>
  );
}
