/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-useless-fragment */
import * as React from 'react';
import ouiDom from '@goldwasserexchange/oui-dom';
import { baseSanityConfig } from '../../../../sanity/client';

export const SanityAssetReference = (props: React.PropsWithChildren<{ value?: { file?: { asset?: { _ref?: string } } } }>) => {
  const {
    value,
    children,
  } = props;
  const {
    projectId,
    dataset,
  } = baseSanityConfig;
  const _ref = value?.file?.asset?._ref;
  if (_ref == null) {
    return (
      <>{children}</>
    );
  }
  const [, id, extension] = _ref.split('-');
  const href = `https://cdn.sanity.io/files/${projectId}/${dataset}/${id}.${extension}?dl=${id}.${extension}`;
  return (
    <ouiDom.A.A
      href={href}
      target="_blank"
      textDecoration="underline"
    >
      {children}
    </ouiDom.A.A>
  );
};
