import { useContext } from 'react';
import { usePrimaryDimensionLength } from '../../../utils/dimensions';
import { showedItemsContext } from './context';
export var useShowedItems = function () {
    var showedItems = useContext(showedItemsContext);
    return showedItems;
};
export var useComputeCappedShowedItems = function (showedItems) {
    var itemCount = usePrimaryDimensionLength();
    var cappedShowedItems = Math.min(itemCount, showedItems);
    return cappedShowedItems;
};
