import React from 'react';
import {
  dataStructure,
} from '@goldwasserexchange/oblis-frontend-utils';
import { Trans } from '@lingui/macro';
import ouiBase from '@goldwasserexchange/oui';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { useParentFieldPath } from '../../../../OUI/Inputs';

const ActivitySectorLabel = (): JSX.Element => {
  const parentName = useParentFieldPath();
  return (
    <ouiBase.utils.hookstate.FormFilterLogic
      logic={dataStructure.T_ADD.fields.ID_ECPL_SECTOR.logic.show.labelLogic}
      parentName={parentName}
    >
      <TopModel name="current">
        <Trans>Secteur d'activité</Trans>
      </TopModel>
      <TopModel name="past">
        <Trans>Dernier secteur d'activité</Trans>
      </TopModel>
    </ouiBase.utils.hookstate.FormFilterLogic>
  );
};

export const tAddIdEcplSectorLabel = <ActivitySectorLabel />;

export const tAddIdEcplSectorValidatorLogic: ouiBase.utils.hookstate.FormValueFilterLogic<'requiredNumber' | 'nilCast'> = dataStructure.T_ADD.fields.ID_ECPL_SECTOR.logic.show.makeShowLogic({
  visible: {
    result: 'requiredNumber',
  },
  invisible: { result: 'nilCast' },
});
