import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { DefaultInputFooterContent } from './content';
import { BaseBuilderWithNameTransform } from '../../../../utils';

export const inputFooterDefaultTopModelsObject = {
  InputFooterContent: (
    <TopModel name="InputFooterContent">{DefaultInputFooterContent}</TopModel>
  ),
  InputFooterErrorBase: (
    <TopModel name="InputFooterErrorBase" rampName="InputFooterError">
      {BaseBuilderWithNameTransform}
    </TopModel>
  ),
  InputFooterHelpBase: (
    <TopModel name="InputFooterHelpBase" rampName="InputFooterHelp">
      {BaseBuilderWithNameTransform}
    </TopModel>
  ),
};
