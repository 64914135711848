import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

export const GreenLeaf = (props: {
  size?: string | number,
}) => {
  const {
    size,
  } = props;
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 28.3 28.3"
      height={size}
      width={size}
      fill={ouiStyle.Constants.Colors.success}
    >
      <g>
        <g>
          <path d="M27.4,1c-0.5,4.6-2.3,14.2-9.4,18.5c-2.7,1.8-6,2.4-9.2,1.6c-1.5-0.5-3.2,0.2-3.9,1.6c-0.7,1.4-1.2,2.9-1.5,4.5
c-0.1,0.6-0.7,1.1-1.3,1c-0.6-0.1-1.1-0.7-1-1.3c0.1-0.5,1.7-10.7,13.1-17.3c-4.4,0.5-8.4,2.6-11.4,5.8C2,13.6,2,11.7,2.6,10
C4.5,4.2,10.3,4.2,18,4.2c3.1-0.1,6-1.5,8.1-3.8c0.2-0.3,0.6-0.3,0.9-0.2C27.2,0.3,27.4,0.7,27.4,1L27.4,1z M27.4,1"
          />
        </g>
      </g>
    </svg>
  );
};
