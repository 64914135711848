import React from 'react';
import { Trans } from '@lingui/macro';
import ouiBase from '@goldwasserexchange/oui';
import {
  dataStructure, CTit, TAddType, UncastedDataStructure,
} from '@goldwasserexchange/oblis-frontend-utils';
import { useHookstate } from '@hookstate/core';
import { useParentFieldPath } from '../../../../../OUI/Inputs';

export const tAddMoralBeneficiariesControlTypeValidatorLogic = dataStructure.T_ADD.fields.ID_T_ACC_AYDR.logic.helpers.makeIdTAccAyDrShowLogic({
  truthy: dataStructure.T_ADD.fields.TYPE.logic.makeTAddTypeFilterLogic({
    is: [TAddType.MANDATARY],
    truthy: {
      result: 'number',
    },
    falsy: {
      result: 'requiredNumber',
    },
  }),
  falsy: { result: 'nilCast' },
  notMandataryOrUBO: {
    result: 'nilCast',
  },
});

const TAddMoralBeneficiariesControlTypeLabel = () => {
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const moralSocialForm = useHookstate(valueState.onboarding.moralPerson.ID_C_TIT).get();
  const parentName = useParentFieldPath();
  const unprefixedParentName = parentName.replace('onboarding.', '');
  const userIndex = parseInt(unprefixedParentName.split('.')[1], 10);
  const tAddType = useHookstate(valueState.onboarding.users[userIndex].T_ADD_type);
  if (tAddType.get() === TAddType.MANDATARY) {
    return (
      <>
        {moralSocialForm === `${CTit.ASBL}`
          ? <Trans>Le mandataire est-il un bénéficiaire effectif de l'ASBL ?</Trans>
          : null}
        {moralSocialForm === `${CTit.SOCIETE}`
          ? <Trans>Le mandataire est-il un bénéficiaire effectif de la Société ?</Trans>
          : null}
        {moralSocialForm === `${CTit.FONDATION_PRIVEE}` || moralSocialForm === `${CTit.FONDATION_D_UTILITE_PUBLIQUE}`
          ? <Trans>Le mandataire est-il un bénéficiaire effectif de la Fondation ?</Trans>
          : null}
      </>
    );
  }
  return (
    <>
      {moralSocialForm === `${CTit.ASBL}`
        ? <Trans>La personne contrôle l'ASBL en tant que :</Trans>
        : null}
      {moralSocialForm === `${CTit.SOCIETE}`
        ? <Trans>La personne contrôle la Société en tant que :</Trans>
        : null}
      {moralSocialForm === `${CTit.FONDATION_PRIVEE}` || moralSocialForm === `${CTit.FONDATION_D_UTILITE_PUBLIQUE}`
        ? <Trans>La personne contrôle la Fondation en tant que :</Trans>
        : null}
    </>
  );
};

export const tAddMoralBeneficiariesControlTypeLabel = <TAddMoralBeneficiariesControlTypeLabel />;

export const tAddMoralBeneficiariesData = {
  0: { ID: 0, value: <Trans>Non</Trans>, socialForm: [CTit.ASBL, CTit.FONDATION_PRIVEE, CTit.FONDATION_PRIVEE, CTit.SOCIETE] },
  1: { ID: 1, value: <Trans>Oui, car il s'agit d'un administrateur ou d'un administrateur délégué à la gestion journalière de l'ASBL</Trans>, socialForm: [CTit.ASBL] },
  2: { ID: 2, value: <Trans>Oui, car il s'agit d'une personne habilitée à représenter l'ASBL</Trans>, socialForm: [CTit.ASBL] },
  3: { ID: 3, value: <Trans>Oui, car il s'agit d'une personne chargée de la gestion journalière de l'ASBL</Trans>, socialForm: [CTit.ASBL] },
  6: { ID: 6, value: <Trans>Oui, car il possède directement ou indirectement un pourcentage suffisant de droit de vote ou une participation suffisante dans le capital de la Société (min 25%)</Trans>, socialForm: [CTit.SOCIETE] },
  7: { ID: 7, value: <Trans>Oui, car il contrôle la Société par d'autres moyens (e.g. pacte d'actionnaires, droit de nommer les membres du conseil d'administration, droit de véto)</Trans>, socialForm: [CTit.SOCIETE] },
  8: { ID: 8, value: <Trans>Oui, car il occupe au sein de la Société une position de dirigeant principal</Trans>, socialForm: [CTit.SOCIETE] },
  9: { ID: 9, value: <Trans>Oui, car il s'agit d'un administrateur ou d'un administrateur délégué à la gestion journalière de la Fondation</Trans>, socialForm: [CTit.FONDATION_PRIVEE, CTit.FONDATION_D_UTILITE_PUBLIQUE] },
  10: { ID: 10, value: <Trans>Oui, car il s'agit d'une personne habilitée à représenter la Fondation</Trans>, socialForm: [CTit.FONDATION_PRIVEE, CTit.FONDATION_D_UTILITE_PUBLIQUE] },
  11: { ID: 11, value: <Trans>Oui, car il s'agit d'une personne chargée de la gestion journalière de la Fondation</Trans>, socialForm: [CTit.FONDATION_PRIVEE, CTit.FONDATION_D_UTILITE_PUBLIQUE] },
  12: { ID: 12, value: <Trans>Oui, car il s'agit d'un fondateur</Trans>, socialForm: [CTit.FONDATION_PRIVEE, CTit.FONDATION_D_UTILITE_PUBLIQUE] },
  13: { ID: 13, value: <Trans>Oui, pour une autre raison</Trans>, socialForm: [CTit.ASBL, CTit.FONDATION_PRIVEE, CTit.FONDATION_D_UTILITE_PUBLIQUE] },
};
