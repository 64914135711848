import useSWR, { KeyedMutator } from 'swr';
import { useMemo } from 'react';
import { AccountTwrrs } from '../type';
import { Accounts } from '../../enum';
import { getServiceUrl } from '../../../../shared';
import { useAccountTwrrStringifiedParams } from './defaultParams';
import { ACTOR_DEDUPING_INTERVAL } from '../../../shared';
import { useFetchWithAuthWithSend } from '../../../shared/utils/fetchWithAuth';
import { makeApiUrl } from '../../../../../../aws';

const useMakeAccountsRatesUrl = () => {
  const stringifiedParams = useAccountTwrrStringifiedParams();
  const url = stringifiedParams !== null ? `${makeApiUrl('actor')}/${getServiceUrl(Accounts.Twrr)}${stringifiedParams}` : null;
  return url;
};

type BaseUseAccountTwrrApiResponse = {
  isLoading: boolean,
  error?: any,
  mutate: KeyedMutator<AccountTwrrs[]>,
  isValidating: boolean,
}

type UseAccountTwrrsApiResponse = {
  data: undefined | AccountTwrrs[],
} & BaseUseAccountTwrrApiResponse;

type FilterPredicate = (accountsTwrr: AccountTwrrs) => boolean;

type SortPredicate = (prevAccountTwrr: AccountTwrrs, nextAccountTwrr: AccountTwrrs) => number;

type UseAccountTwrrsApiProps = {
  filterPredicate?: FilterPredicate,
  sortPredicate?: SortPredicate,
};

export const useAccountTwrrsApi = (props: UseAccountTwrrsApiProps): UseAccountTwrrsApiResponse => {
  const {
    filterPredicate,
    sortPredicate,
  } = props;
  const url = useMakeAccountsRatesUrl();
  const fetcher = useFetchWithAuthWithSend();
  const result = useSWR<AccountTwrrs[], any>(
    url,
    fetcher,
    { dedupingInterval: ACTOR_DEDUPING_INTERVAL },
  );
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    let { data } = result;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      if (filterPredicate) {
        data = data.filter((twrr) => filterPredicate(twrr));
      }
    }
    return ({
      ...result,
      data,
      isLoading,
    });
  }, [result, filterPredicate, sortPredicate]);
  return resultWithLoading;
};

type TransformArrayFunction<T> = (accountTwrrs: AccountTwrrs[] | undefined) => T

type UseAccountTwrrsApiTransformProps<T> = {
  transformFunction: TransformArrayFunction<T>,
} & UseAccountTwrrsApiProps

type UseAccountTwrrApiTransformResponse<T> = {
  data: T | undefined,
} & BaseUseAccountTwrrApiResponse;

export function useAccountTwrrsTransformApi<T>(props: UseAccountTwrrsApiTransformProps<T>): UseAccountTwrrApiTransformResponse<T> {
  const {
    filterPredicate,
    sortPredicate,
    transformFunction,
  } = props;
  const response = useAccountTwrrsApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: (!isLoading && !error) ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UseAccountTwrrApiResponse = {
  data: AccountTwrrs | undefined,
} & BaseUseAccountTwrrApiResponse;

export const useAccountTwrrApi = (props: UseAccountTwrrsApiProps): UseAccountTwrrApiResponse => {
  const {
    filterPredicate,
    sortPredicate,
  } = props;
  const url = useMakeAccountsRatesUrl();
  const fetcher = useFetchWithAuthWithSend();
  const result = useSWR<AccountTwrrs[], any>(
    url,
    fetcher,
    { dedupingInterval: ACTOR_DEDUPING_INTERVAL },
  );
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    // eslint-disable-next-line prefer-destructuring
    let data: AccountTwrrs[] | AccountTwrrs | undefined = result.data;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      if (filterPredicate) {
        data = data.find((twrr) => filterPredicate(twrr));
      }
      if (Array.isArray(data)) {
        // eslint-disable-next-line prefer-destructuring
        data = data[0];
      }
    }
    return ({
      ...result,
      data,
      isLoading,
    });
  }, [result, filterPredicate, sortPredicate]);
  return resultWithLoading;
};

type TransformFunction<T> = (accountTwrr: AccountTwrrs | undefined) => T

type UseAccountTwrrApiTransformProps<T> = {
  transformFunction: TransformFunction<T>,
} & UseAccountTwrrsApiProps;

export function useAccountTwrrTransformApi<T>(props: UseAccountTwrrApiTransformProps<T>): UseAccountTwrrApiTransformResponse<T> {
  const {
    filterPredicate,
    sortPredicate,
    transformFunction,
  } = props;
  const response = useAccountTwrrApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: !isLoading && !error ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UseAccountTwrrFieldApiResponse<Field extends keyof AccountTwrrs> = {
  data: AccountTwrrs[Field] | undefined,
} & BaseUseAccountTwrrApiResponse;

type UseAccountTwrrFieldApiProps<Field extends keyof AccountTwrrs> = {
  field: Field,
} & UseAccountTwrrsApiProps

export function useAccountTwrrFieldApi<Field extends keyof AccountTwrrs>(props: UseAccountTwrrFieldApiProps<Field>): UseAccountTwrrFieldApiResponse<Field> {
  const {
    filterPredicate,
    sortPredicate,
    field,
  } = props;
  const response = useAccountTwrrApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
    } = response;
    return {
      ...response,
      data: data ? data[field] : undefined,
    };
  }, [response, field]);
  return result;
}
