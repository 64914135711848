import ouiBase from '@goldwasserexchange/oui';
import { isPostCall } from '../../utils/isPostCall';
import { useBondsCallFieldTransformApiByEvtEntrySId } from '../api';

export const useSecuritiesBondCallIsPostCall = () => {
  const evtEntrySId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const response = useBondsCallFieldTransformApiByEvtEntrySId({
    evtEntrySId,
    field: 'EVT_ENTRY_S.ID_EVT_CODE',
    transformFn: isPostCall,
  });
  return response;
};
