import { currentPrimaryDimensionIndexContext } from '../primaryDimension/current/indexes/context';
import { primaryDimensionLengthContext } from '../primaryDimension/list/length/context';
import { primaryDimensionListContext } from '../primaryDimension/list/context';
import { currentPrimaryDimensionContext } from '../primaryDimension/current/context';
import { currentSecondaryDimensionIndexContext } from '../secondaryDimension/current/indexes/context';
import { secondaryDimensionListContext } from '../secondaryDimension/list/context';
import { currentSecondaryDimensionContext } from '../secondaryDimension/current/context';
import { secondaryDimensionLengthContext } from '../secondaryDimension/list/length/context';
export var dimensionListContextByDirectionMap = {
    vertical: {
        vertical: primaryDimensionListContext,
        horizontal: secondaryDimensionListContext,
    },
    horizontal: {
        vertical: secondaryDimensionListContext,
        horizontal: primaryDimensionListContext,
    },
};
export var dimensionLengthContextByDirectionMap = {
    vertical: {
        vertical: primaryDimensionLengthContext,
        horizontal: secondaryDimensionLengthContext,
    },
    horizontal: {
        vertical: secondaryDimensionLengthContext,
        horizontal: primaryDimensionLengthContext,
    },
};
export var dimensionCurrentContextByDirectionMap = {
    vertical: {
        vertical: currentPrimaryDimensionContext,
        horizontal: currentSecondaryDimensionContext,
    },
    horizontal: {
        vertical: currentSecondaryDimensionContext,
        horizontal: currentPrimaryDimensionContext,
    },
};
export var dimensionCurrentIndexContextByDirectionMap = {
    vertical: {
        vertical: currentPrimaryDimensionIndexContext,
        horizontal: currentSecondaryDimensionIndexContext,
    },
    horizontal: {
        vertical: currentSecondaryDimensionIndexContext,
        horizontal: currentPrimaryDimensionIndexContext,
    },
};
