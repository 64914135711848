import getRatingDescriptionSP from 'get-credit-rating-description-sp';
import { AccountPosition } from '../../type';
import { spRatingWithDefault } from '../../../../shared';

export const getSPRatingDescription = (rating?: AccountPosition['GES_RAT_VAL_SP.SHORTCUT']) => {
  if (!rating) {
    return '';
  }
  const ratingWithDefault = spRatingWithDefault(rating);
  const ratingDescription = getRatingDescriptionSP.getDetailedDescription(ratingWithDefault);
  return ratingDescription;
};

export const getSPRatingDescriptionFormAccountPosition = (accountPosition: AccountPosition | null | undefined) => (accountPosition ? getSPRatingDescription(accountPosition['GES_RAT_VAL_SP.SHORTCUT']) : '');
