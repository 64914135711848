import { useMemo } from 'react';
import { useCurrentPrimaryDimension, useCurrentPrimaryDimensionIndex, useIsLastPrimaryDimensionVariant, } from '../../../utils/dimensions/primaryDimension';
import { useCurrentSecondaryDimension, useCurrentSecondaryDimensionIndex, useIsLastSecondaryDimensionVariant, } from '../../../utils/dimensions/secondaryDimension';
export var useCellRampNames = function (baseName) {
    var primaryDimension = useCurrentPrimaryDimension();
    var secondaryDimension = useCurrentSecondaryDimension();
    var primaryDimensionIndex = useCurrentPrimaryDimensionIndex();
    var secondaryDimensionIndex = useCurrentSecondaryDimensionIndex();
    var primaryDimensionIsLast = useIsLastPrimaryDimensionVariant();
    var secondaryDimensionIsLast = useIsLastSecondaryDimensionVariant();
    var ramps = useMemo(function () { return ({
        rampName: "".concat(baseName, "-").concat(primaryDimension, "-index-").concat(primaryDimensionIsLast, "-").concat(secondaryDimension, "-index-").concat(secondaryDimensionIsLast),
        rampFallbacks: [
            "".concat(baseName, "-").concat(primaryDimension, "-index-").concat(primaryDimensionIndex, "-").concat(secondaryDimension, "-index-").concat(secondaryDimensionIsLast),
            "".concat(baseName, "-").concat(primaryDimension, "-index-").concat(primaryDimensionIsLast, "-").concat(secondaryDimension, "-index-").concat(secondaryDimensionIndex),
            "".concat(baseName, "-").concat(primaryDimension, "-index-").concat(primaryDimensionIndex, "-").concat(secondaryDimension, "-index-").concat(secondaryDimensionIndex),
            "".concat(baseName, "-").concat(primaryDimension, "-index-").concat(primaryDimensionIsLast, "-").concat(secondaryDimension),
            "".concat(baseName, "-").concat(primaryDimension, "-index-").concat(primaryDimensionIndex, "-").concat(secondaryDimension),
            "".concat(baseName, "-").concat(primaryDimension, "-").concat(secondaryDimension, "-index-").concat(secondaryDimensionIsLast),
            "".concat(baseName, "-").concat(primaryDimension, "-").concat(secondaryDimension, "-index-").concat(secondaryDimensionIndex),
            "".concat(baseName, "-").concat(primaryDimension, "-").concat(secondaryDimension),
            "".concat(baseName, "-").concat(primaryDimension, "-index-").concat(primaryDimensionIsLast),
            "".concat(baseName, "-").concat(primaryDimension, "-index-").concat(primaryDimensionIndex),
            "".concat(baseName, "-index-").concat(primaryDimensionIsLast),
            "".concat(baseName, "-index-").concat(primaryDimensionIndex),
            "".concat(baseName, "-").concat(primaryDimension),
            "".concat(baseName, "--").concat(secondaryDimension, "-index-").concat(secondaryDimensionIsLast),
            "".concat(baseName, "--").concat(secondaryDimension, "-index-").concat(secondaryDimensionIndex),
            "".concat(baseName, "--index-").concat(secondaryDimensionIsLast),
            "".concat(baseName, "--index-").concat(secondaryDimensionIndex),
            "".concat(baseName, "--").concat(secondaryDimension),
            baseName,
        ],
    }); }, [
        baseName,
        primaryDimension,
        primaryDimensionIndex,
        primaryDimensionIsLast,
        secondaryDimension,
        secondaryDimensionIndex,
        secondaryDimensionIsLast,
    ]);
    return ramps;
};
