import isNil from 'ramda/es/isNil';
import { isDate, format } from 'date-fns';
import { isEmpty } from 'ramda';
import { UncastedPhysicalTAdd, TAddType, TNatureLrp } from '@goldwasserexchange/oblis-frontend-utils';
import ouiBase from '@goldwasserexchange/oui';
import { isMinorFromEuroBirthdate } from '../../../OUI/Inputs';

export const isRepresentative = (tAdd: UncastedPhysicalTAdd): boolean => ouiBase.utils.hookstate.stringifyIs(tAdd.ID_T_NATURE_LRP) === TNatureLrp.REPRESENTANT_LEGAL;

export const isOnlyRepresentative = (tAdd: UncastedPhysicalTAdd): boolean => tAdd.T_ADD_type === TAddType.ONLY_REPRESENTATIVE;

export const isNotRepresentative = (tAdd: UncastedPhysicalTAdd): boolean => !isRepresentative(tAdd);

export const isTitular = (tAdd: UncastedPhysicalTAdd): boolean => tAdd.T_ADD_type === TAddType.TITULAR;

export const isMajorTitular = (tAdd: UncastedPhysicalTAdd): boolean => tAdd.T_ADD_type === TAddType.TITULAR && tAdd.isMinor !== true;

export const isProcurationOnly = (tAdd: UncastedPhysicalTAdd): boolean => tAdd.T_ADD_type === TAddType.PROCURATION && !isRepresentative(tAdd);

export const isProcuration = (tAdd: UncastedPhysicalTAdd): boolean => (!isNil(tAdd.ID_T_PROC_TYPE) && !isEmpty(tAdd.ID_T_PROC_TYPE)) && !isRepresentative(tAdd);

export const isProcurationOrPotentialProcuration = (tAdd: UncastedPhysicalTAdd): boolean => isProcuration(tAdd) || isProcurationOnly(tAdd);

export const isUbo = (tAdd: UncastedPhysicalTAdd): boolean => tAdd.T_ADD_type === TAddType.UBO;

export const isMandatary = (tAdd: UncastedPhysicalTAdd): boolean => tAdd.T_ADD_type === TAddType.MANDATARY;

const cleanDate = (date: number | Date | string): date is number | Date => isDate(date);

export const isTAddMinor = (tAdd: UncastedPhysicalTAdd): boolean => {
  const birthdateSource = tAdd.BIRTH_DATE as number | Date | string;
  const birthdate = cleanDate(birthdateSource) ? format(birthdateSource, 'dd/MM/yyyy') : birthdateSource;
  return isMinorFromEuroBirthdate(birthdate);
};

export const isTAddDisabledTitular = (tAdd: UncastedPhysicalTAdd): boolean => ouiBase.utils.hookstate.stringifyIs(tAdd.ID_T_NATURE_LRP) === '6';

export const tAddTitularsNeedRepresentative = (tAdds: UncastedPhysicalTAdd[]): boolean => tAdds.filter((x) => isTitular(x)).some((tAdd) => {
  const isDisabledTitular = isTAddDisabledTitular(tAdd);
  if (isDisabledTitular) {
    return true;
  }
  const isMinor = isTAddMinor(tAdd);
  return isMinor;
});

export const tAddTitularsNeedRepresentativeAndHasNoRepresentative = (tAdds: UncastedPhysicalTAdd[]): boolean => tAddTitularsNeedRepresentative(tAdds) && !tAdds.some((tAdd) => isRepresentative(tAdd));

export const isNoneOfRepresentativeMinorOrDisabledTitular = (tAdd: UncastedPhysicalTAdd): boolean => isNotRepresentative(tAdd) && !isTAddMinor(tAdd) && !isTAddDisabledTitular(tAdd);

export * from './useUser';
