import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { ActorApiLoadingProvider } from '../../context';
import { ActorApiNumberDisplayInner } from './number';

export const ActorApiNumberDisplay = (
  props: {
    data: number | null | undefined,
    isLoading: boolean,
    error: any,
    loadingValue?: number,
    format?: Partial<Omit<Intl.NumberFormatOptions, 'style'> & { style: Exclude<Intl.NumberFormatOptions['style'], 'currency'> }>,
    forcedSigned?: boolean,
    divider?: number,
    nullContent?: string | JSX.Element,
    ownLoadingContext?: boolean,
    initialLoading?: boolean,
    withSpaceBefore?: boolean,
    withSpaceAfter?: boolean,
    isBlocked?: boolean,
    placeHolderOnBlocked?: boolean,
    withRedactedIcon?: boolean,
    lockBackgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
    lockWidthLines?: ouiStyle.InStyleWithMediaQueries['widthLines'],
    lockContainer?: ouiStyle.InStyleWithMediaQueries['container'],
    lockPaddingTop?: ouiStyle.InStyleWithMediaQueries['paddingTop'],
    lockPaddingBottom?: ouiStyle.InStyleWithMediaQueries['paddingBottom'],
    lockPaddingLeft?: ouiStyle.InStyleWithMediaQueries['paddingLeft'],
    lockPaddingRight?: ouiStyle.InStyleWithMediaQueries['paddingRight'],
    lockAlignItems?: ouiStyle.InStyleWithMediaQueries['alignItems'],
    LockChild?: () => JSX.Element | null,
  },
) => {
  const {
    data,
    isLoading,
    error,
    loadingValue,
    format,
    forcedSigned,
    divider,
    nullContent,
    ownLoadingContext = true,
    initialLoading,
    withSpaceBefore,
    withSpaceAfter,
    isBlocked,
    placeHolderOnBlocked,
    withRedactedIcon,
    lockBackgroundColor,
    lockWidthLines,
    lockContainer,
    lockPaddingTop,
    lockPaddingBottom,
    lockPaddingLeft,
    lockPaddingRight,
    lockAlignItems,
    LockChild,
  } = props;
  const inner = (
    <ActorApiNumberDisplayInner
      data={data}
      isLoading={isLoading}
      error={error}
      loadingValue={loadingValue}
      format={format}
      forcedSigned={forcedSigned}
      divider={divider}
      nullContent={nullContent}
      withSpaceBefore={withSpaceBefore}
      withSpaceAfter={withSpaceAfter}
      isBlocked={isBlocked}
      placeHolderOnBlocked={placeHolderOnBlocked}
      withRedactedIcon={withRedactedIcon}
      lockBackgroundColor={lockBackgroundColor}
      lockWidthLines={lockWidthLines}
      lockContainer={lockContainer}
      lockPaddingTop={lockPaddingTop}
      lockPaddingBottom={lockPaddingBottom}
      lockPaddingLeft={lockPaddingLeft}
      lockPaddingRight={lockPaddingRight}
      lockAlignItems={lockAlignItems}
      LockChild={LockChild}
    />
  );
  return ownLoadingContext ? (
    <ActorApiLoadingProvider
      initialLoading={initialLoading}
    >
      {inner}
    </ActorApiLoadingProvider>
  ) : inner;
};
