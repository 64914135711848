import { Auth } from '@aws-amplify/auth';
import pThrottle from 'p-throttle';
import { AuthSendType } from '../../../Components/Auth/context/context';

const throttle = pThrottle({
  limit: 1,
  interval: 100,
});

const getCurrentCredentials = async (send?: AuthSendType) => {
  try {
    const credentials = await Auth.currentCredentials();
    return credentials;
  } catch (err) {
    if (send) {
      send('DISCONNECT');
    }
    throw err;
  }
};

export const throttledGetCurrentCredentials = throttle(getCurrentCredentials);

export const getEssentialCredentials = async (send?: AuthSendType) => {
  const allCredentials = await throttledGetCurrentCredentials(send);
  return Auth.essentialCredentials(allCredentials);
};
