import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { GetObjectCommand } from '@aws-sdk/client-s3';
import { useCallback } from 'react';
import useSWR from 'swr';
import { useAuthMachineSend } from '../../../Components/Auth';
import { getS3Client } from './getS3Client';
import { AuthSendType } from '../../../Components/Auth/context/context';

const makeSignedS3GetUrl = async (
  bucket: string,
  key: string,
  send?: AuthSendType,
) => {
  const command = new GetObjectCommand({
    Bucket: bucket,
    Key: key,
  });
  const client = await getS3Client(send);
  const url = await getSignedUrl(client, command);
  return url;
};

const useMakeSignedS3GetUrlWithSend = () => {
  const send = useAuthMachineSend();
  const fetcher = useCallback((
    args: [
      bucket: string,
      key: string,
    ],
  ) => {
    const [bucket, key] = args;
    return makeSignedS3GetUrl(bucket, key, send);
  }, [send]);
  return fetcher;
};

export const useSignedS3GetUrlSwr = (props: {
  bucket?: string | null,
  key?: string,
  refreshInterval?: number,
}) => {
  const {
    bucket,
    key,
    refreshInterval = 15 * 60 * 1000,
  } = props;
  const urlParams = bucket && key
    ? [bucket, key]
    : null;
  const fetcher = useMakeSignedS3GetUrlWithSend();
  const response = useSWR(urlParams, fetcher, {
    refreshInterval,
  });
  return response;
};
