import { makeAccountTypeLogic } from '../../../accountType/logic';
import { AccountType } from '../../../../types';
export function makeKERequiredLogic(props) {
    var alternatives = props.alternatives;
    var required = alternatives.required, free = alternatives.free;
    return makeAccountTypeLogic({
        is: AccountType.GESTION,
        alternatives: {
            truthy: required,
            falsy: free,
        },
    });
}
