/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';

const toggleTextContainerStyle: ouiStyle.InStyle = {
  color: ouiStyle.Constants.Colors.inverted,
  fontWeight: ouiStyle.Constants.FontWeightProp.intermediary,
  paddingLeft: 'simple',
  flexShrink: true,
  userSelect: false,
};

const toggleTextContainerCss = ouiStyle.makeCss(toggleTextContainerStyle);

export const ToggleTextContainer = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  return (
    <div
      css={toggleTextContainerCss}
    >
      {children}
    </div>
  );
};
