import * as React from 'react';
import { HeadingSectiondepthProvider } from './depth';
import { HeadingSectionIllustrationPositionProvider } from './illustrationPosition';
import { HeadingSectionIndexProvider } from './indexContext';
import { HeadingSectionIntroFirstProvider } from './introFirst';
import { HeadingSectionNameProvider } from './name';
export var HeadingSectionContextProvider = function (props) {
    var index = props.index, name = props.name, introFirst = props.introFirst, illustrationPosition = props.illustrationPosition, children = props.children;
    return (React.createElement(HeadingSectionNameProvider, { name: name },
        React.createElement(HeadingSectiondepthProvider, null,
            React.createElement(HeadingSectionIndexProvider, { index: index },
                React.createElement(HeadingSectionIllustrationPositionProvider, { illustrationPosition: illustrationPosition },
                    React.createElement(HeadingSectionIntroFirstProvider, { introFirst: introFirst }, children))))));
};
