import * as React from 'react';
import { PersonsDevicesCountry, PersonsDevicesISP } from '.';

export const PersonsDevicesCountryWithISP = () => (
  <>
    <PersonsDevicesCountry />
    {' '}
    (
    <PersonsDevicesISP />
    )
  </>
);
