/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import { animated, useSpring } from 'react-spring';
import ouiBase from '@goldwasserexchange/oui';
import ouiStyle from '@goldwasserexchange/oui-style';
import { defaultListInputListElementButtonPlaceHolderStyle } from '../../../../../../../shared/components/list/components';

export const defaultDomSelectInputListSearchInputIconPlaceHolderStyle: ouiStyle.InStyle = {
  ...defaultListInputListElementButtonPlaceHolderStyle,
  parentBorderTopWidth: 'simple',
  parentBorderBottomWidth: 'double',
  paddingRight: 'none',
  parentBorderTopStyle: 'solid',
  parentBorderBottomStyle: 'solid',
  widthLines: 1,
  lines: 1,
};

function useMakedefaultDomSelectInputListSearchInputIconPlaceHolderCss() {
  const lines = ouiBase.List.useListInputElementLines();
  const css = React.useMemo(() => ouiStyle.makeCss({
    ...defaultDomSelectInputListSearchInputIconPlaceHolderStyle,
    lines,
  }), [lines]);
  return css;
}

export function DefaultDomSelectInputSelectInputListSearchInputIconPlaceHolder(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  const isOpen = ouiBase.List.useSelectIsOpen();
  const css = useMakedefaultDomSelectInputListSearchInputIconPlaceHolderCss();
  const style = useSpring({
    width: isOpen ? css.width : '0rem',
  });
  const cssWithMediaQueries = React.useMemo(() => ouiStyle.mediaQuery(css), [css]);
  return (
    <animated.div css={cssWithMediaQueries} style={style}>
      {children}
    </animated.div>
  );
}
