import React, { useContext, useCallback } from 'react';
import { MachineContext } from './context';

export const useSendOnClick = (state: string, payload?: {}, beforeOnClick?: React.MouseEventHandler<HTMLButtonElement>, afterOnClick?:React.MouseEventHandler<HTMLButtonElement>) => {
  const [, send] = useContext(MachineContext);
  const onClick = useCallback((event) => {
    if (beforeOnClick) {
      beforeOnClick(event);
    }
    send(state, payload);
    if (afterOnClick) {
      afterOnClick(event);
    }
  }, [send, beforeOnClick, afterOnClick]);
  return onClick;
};
