var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as React from 'react';
import { topModelNameTransformContext } from './context';
export function useTopModelNameTransformFn() {
    var transformFn = React.useContext(topModelNameTransformContext);
    return transformFn;
}
export function useTopModelNameTransform(name, transformBeforeFn) {
    var transformFn = useTopModelNameTransformFn();
    return !transformBeforeFn
        ? transformFn(name)
        : transformFn(transformBeforeFn(name));
}
export function useTopModelNamesTransform(names, transformBeforeFn) {
    var transformFn = useTopModelNameTransformFn();
    if (!names) {
        return undefined;
    }
    return names.map(function (name) { return (!transformBeforeFn ? transformFn(name) : transformFn(transformBeforeFn(name))); });
}
// eslint-disable-next-line space-before-function-paren, function-paren-newline
export function usePrefixTopModels(sourceTopModels, transformBeforeFn) {
    var transformFn = useTopModelNameTransformFn();
    return React.useMemo(function () { return Object.fromEntries(Object.entries(sourceTopModels).map(function (_a) {
        var _b = __read(_a, 2), key = _b[0], value = _b[1];
        return [
            !transformBeforeFn
                ? transformFn(key)
                : transformFn(transformBeforeFn(key)),
            __assign(__assign({}, value), { props: __assign(__assign({}, value.props), { name: transformFn(value.props.name) }) }),
        ];
    })); }, [sourceTopModels, transformFn, transformBeforeFn]);
}
