import { computeDimension } from '../utils/dimensions/computeDimension';
export function makeMinWidth(props) {
    var minWidthLines = props.minWidthLines, fontSize = props.fontSize, paddingLeft = props.paddingLeft, paddingRight = props.paddingRight, childPaddingLeft = props.childPaddingLeft, childPaddingRight = props.childPaddingRight, parentBorderLeftWidth = props.parentBorderLeftWidth, parentBorderRightWidth = props.parentBorderRightWidth, icon = props.icon, lineFactor = props.lineFactor, minMinusWidth = props.minMinusWidth;
    return computeDimension({
        factor: minWidthLines,
        fontSize: fontSize,
        paddingStart: paddingLeft,
        paddingEnd: paddingRight,
        childPaddingStart: childPaddingLeft,
        childPaddingEnd: childPaddingRight,
        parentBorderStart: parentBorderLeftWidth,
        parentBorderEnd: parentBorderRightWidth,
        icon: icon,
        lineFactor: lineFactor,
        minus: minMinusWidth,
    });
}
