var onboardingPath = 'onboarding';
var selectedServicePath = 'selectedService';
var durabilityPath = 'durability';
var preferencePath = 'preferences';
var incidencePath = 'incidence';
export var dependencyFieldPaths = [
    "".concat(onboardingPath, ".").concat(selectedServicePath),
    "".concat(onboardingPath, ".").concat(durabilityPath, ".").concat(preferencePath),
    "".concat(durabilityPath, ".").concat(incidencePath),
];
