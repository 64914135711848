import * as React from 'react';
import { TopModelMerge, TopModelNameTransformProvider } from '@goldwasserexchange/react-topmodel';
import { FormStructure } from './structure';
import { formDefaultTopModelsObject, formOnGlobalDsDefaultTopModelsObject } from './topmodels';
import { formPrefixer } from './utils';
export function Form(props) {
    var initial = props.initial, children = props.children;
    return (React.createElement(TopModelNameTransformProvider, { transformFn: formPrefixer },
        React.createElement(TopModelMerge, { topModels: formDefaultTopModelsObject },
            React.createElement(FormStructure, { initial: initial }, children))));
}
export function FormOnGlobalDs(props) {
    var children = props.children;
    return (React.createElement(TopModelNameTransformProvider, { transformFn: formPrefixer },
        React.createElement(TopModelMerge, { topModels: formOnGlobalDsDefaultTopModelsObject },
            React.createElement(FormStructure, null, children))));
}
