import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import ouiBase from '@goldwasserexchange/oui';
import {
  OnChangeDefaultHandlerEnhanceContext,
  OnClickInputRefClickAndFocusEnhanceContextProvider,
} from '../../../../../../utils';
import {
  FocusWithHandlersEnhanceContextProvider,
  InputRefContextProvider,
} from '../../../../../context';
import { DefaultInputFooterErrorContent } from '../../footer';
import {
  ListInputLabelInfoContent,
  DefaultListInputContextProvider,
  ListInputListBase,
  DefaultListInputListElementContent,
  DefaultListInputElementContextContainer,
  ListInputContent,
  DefaultListInputListElementButtonHandler,
  DefaultElementLabelDisabledReasonSwitcher,
  DefaultListElementLabelDisabledReasonContentSwitcher,
} from '../components';

export const listInputDefaultTopModelsObject = {
  InputContextProvider: (
    <TopModel name="InputContextProvider">
      {DefaultListInputContextProvider}
    </TopModel>
  ),
  InputElementLinesContext: (
    <TopModel name="InputElementLinesContext">
      {ouiBase.List.ListInputElementLinesProvider}
    </TopModel>
  ),
  InputElementChildPaddingsContext: (
    <TopModel name="InputElementChildPaddingsContext">
      {ouiBase.List.ListElementChildPaddingProvider}
    </TopModel>
  ),
  InputContent: <TopModel name="InputContent">{ListInputContent}</TopModel>,
  InputLabelInfoContent: (
    <TopModel name="InputLabelInfoContent">
      {ListInputLabelInfoContent}
    </TopModel>
  ),
  InputFooterErrorContent: (
    <TopModel name="InputFooterErrorContent">
      {DefaultInputFooterErrorContent}
    </TopModel>
  ),
  InputListBase: (
    <TopModel name="InputListContainer">{ListInputListBase}</TopModel>
  ),
  InputListElementContextContainer: (
    <TopModel name="ElementContextContainer">
      {DefaultListInputElementContextContainer}
    </TopModel>
  ),
  InputListRefContextProvider: (
    <TopModel name="InputListRefContextProvider">
      {InputRefContextProvider}
    </TopModel>
  ),
  InputListElementFocusWithHandlersContextProvider: (
    <TopModel name="InputListElementFocusWithHandlersContextProvider">
      {FocusWithHandlersEnhanceContextProvider}
    </TopModel>
  ),
  InputListElementContent: (
    <TopModel name="InputListElementContent">
      {DefaultListInputListElementContent}
    </TopModel>
  ),
  InputListElementButtonHandler: (
    <TopModel name="InputListElementButtonHandler">
      {DefaultListInputListElementButtonHandler}
    </TopModel>
  ),
  InputListElementLabelDisabledReasonSwitcher: (
    <TopModel name="InputListElementLabelDisabledReasonSwitcher">
      {DefaultElementLabelDisabledReasonSwitcher}
    </TopModel>
  ),
  InputListElementLabelDisabledReasonContentSwitcher: (
    <TopModel name="InputListElementLabelDisabledReasonContentSwitcher">
      {DefaultListElementLabelDisabledReasonContentSwitcher}
    </TopModel>
  ),
  InputListElementButtonContainerContext: (
    <TopModel name="InputListElementButtonContainerContext">
      {OnClickInputRefClickAndFocusEnhanceContextProvider}
    </TopModel>
  ),
  InputListElementOnChangeContextProvider: (
    <TopModel name="InputListElementOnChangeContextProvider">
      {OnChangeDefaultHandlerEnhanceContext}
    </TopModel>
  ),
};
