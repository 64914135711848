import ouiBase from '@goldwasserexchange/oui';
import { useFilterByContextCCurrencyCode } from '../../../../shared';
import { useAccountHistoryFieldApi, useAccountHistoryFieldApiByHisMvtId } from '../api';

export const useAccountHistoryCCurrencyCodeByHisMvtId = () => {
  const hisMvtId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const result = useAccountHistoryFieldApiByHisMvtId({
    hisMvtId,
    field: 'C_CURRENCY.CODE',
  });
  return result;
};

export const useAccountHistoryCCurrencyCodeByCurrencyCode = () => {
  const filterPredicate = useFilterByContextCCurrencyCode();
  const result = useAccountHistoryFieldApi({
    filterPredicate,
    field: 'C_CURRENCY.CODE',
  });
  return result;
};
