/* eslint-disable no-underscore-dangle */
import { parseISO } from 'date-fns';
import { useMemo } from 'react';
import useSWR from 'swr';
import { useAtomValue } from 'jotai';
import { QueryArguments } from '../../types';
import { convertQueryArguments, fetcher } from '../../utils';
import { pathnameUrlNewsArticleId } from '../../../../../../history';

export const useSanityNewsById = (props: QueryArguments) => {
  const swrArguments = convertQueryArguments(props);
  const response = useSWR(swrArguments, fetcher);
  return response;
};

export const useSanityNewsByUrlId = () => {
  const id = useAtomValue(pathnameUrlNewsArticleId);
  return useSanityNewsById({
    id,
  });
};

export const useSanityLatestNewsById = (id: string) => {
  const response = useSanityNewsByUrlId();
  const result = useMemo(
    () => {
      const {
        data,
        ...rest
      } = response;
      if (!data) {
        return {
          ...rest,
          data: undefined,
        };
      }
      return {
        ...rest,
        data: data?.latestNews.find((news) => news._id === id),
      };
    },
    [
      response.data,
      response.isLoading,
      response.error,
      id,
    ],
  );
  return result;
};

export const useSanityLatestNewsPublishDateById = (id: string) => {
  const response = useSanityLatestNewsById(id);
  const result = useMemo(
    () => {
      const {
        data,
        ...rest
      } = response;
      if (!data) {
        return {
          ...rest,
          data: undefined,
        };
      }
      return {
        ...rest,
        data: parseISO(data.publishDate),
      };
    },
    [
      response.data,
      response.isLoading,
      response.error,
    ],
  );
  return result;
};
