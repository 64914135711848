/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import qs from 'query-string';
import * as React from 'react';
import ouiDom from '@goldwasserexchange/oui-dom';
import { useAtomValue } from 'jotai';
import {
  pathnameUrlLanguageAtom, DateInterval, ValidSections, locationSearchParsedAtom,
} from '../../../../../../history';
import { parsedSearchOnlineHistoryValidIntervalAtom } from './atoms';

const style: ouiStyle.InStyle = {
  display: ouiStyle.Constants.DisplayProp.flex,
  alignItems: ouiStyle.Constants.AlignItemsProp.stretch,
  justifyContent: ouiStyle.Constants.JustifyContentProp.stretch,
  flexGrow: 1,
  flexShrink: false,
  borderRightStyle: 'solid',
  borderRightWidth: 'simple',
  borderRightColor: ouiStyle.Constants.Colors.accent,
  textAlign: 'center',
  color: ouiStyle.Constants.Colors.accent,
};

export const HistoryTimeNavListElementContainer = (props: React.PropsWithChildren<{
  time: DateInterval,
  isLast?: boolean,
}>) => {
  const {
    time,
    isLast,
    children,
  } = props;
  const currentTime = useAtomValue(parsedSearchOnlineHistoryValidIntervalAtom);
  const isCurrentTime = time === currentTime;
  const css = React.useMemo(() => ouiStyle.mediaQuery(ouiStyle.makeCss({
    ...style,
    ...(
      isLast
        ? {
          borderRightWidth: 'none',
        }
        : {}
    ),
    ...(
      isCurrentTime
        ? {
          backgroundColor: ouiStyle.Constants.Colors.accent,
          color: ouiStyle.Constants.Colors.inverted,
        }
        : {}
    ),
  })), [isLast, isCurrentTime]);
  const parsedSearch = useAtomValue(locationSearchParsedAtom);
  const newParsedSearch = {
    ...parsedSearch,
    interval: time,
  };
  const newSearch = qs.stringify(newParsedSearch);
  const language = useAtomValue(pathnameUrlLanguageAtom);
  const to = `/${language}/${ValidSections.ONLINE}/history?${newSearch}`;
  return (
    <li
      css={css}
    >
      <ouiDom.A.A
        to={to}
        padding="simple"
        lines="full"
        textAlign="center"
        widthLines="full"
        color={ouiStyle.Constants.Colors.inherit}
      >
        {children}
      </ouiDom.A.A>
    </li>
  );
};
