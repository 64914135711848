import * as React from 'react';
import { useNumberFormat } from './hook';

export const NumberDisplay = (props: {
  value: number,
  forcedSigned?: boolean,
  format?: Partial<Intl.NumberFormatOptions>,
  divider?: number,
  placeholder?: string,
  spaceBefore?: boolean,
  spaceAfter?: boolean,
}) => {
  const {
    value,
    forcedSigned,
    format,
    divider,
    placeholder,
    spaceBefore,
    spaceAfter,
  } = props;
  const number = useNumberFormat({
    value,
    forcedSigned,
    format,
    divider,
  });
  return (
    <>
      {spaceBefore ? ' ' : null}
      {placeholder === undefined || number == null ? number : number.replace(/[\dA-Za-z\u00C0-\u024F\u1E00-\u1EFF]/g, placeholder)}
      {spaceAfter ? ' ' : null}
    </>
  );
};
