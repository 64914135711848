import { createContext } from 'react';
import { SpringValue } from 'react-spring';

export const toolbarDropDownOpenSpringContext = createContext<SpringValue<number> | null>(null);

export const toolbarDropDownOpenContext = createContext<boolean>(false);

export const toolbarDropDownSetOpenContext = createContext<React.Dispatch<React.SetStateAction<boolean>>>(() => {
  throw new Error('you\'re not in the toolbarDropDown setOpen context');
});

export const toolbarDropDownListContext = createContext<string[]>([]);
