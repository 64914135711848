/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { animated } from 'react-spring';
import * as animationController from '../../animation';

const style: ouiStyle.InStyleWithMediaQueries = {
  display: ouiStyle.Constants.DisplayProp.flex,
  fontSize: 'm',
  lines: [3, undefined, undefined, undefined, undefined, 'full'],
  flexShrink: false,
  flexGrow: false,
  borderBottomColor: ouiStyle.Constants.Colors.inverted,
};

const css = ouiStyle.mediaQuery({
  ...ouiStyle.makeCss(style),
  flexBasis: '3.75rem',
});

export const ToolBar = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  const mqDown = animationController.useAnimationmqDownSpringValue();
  const animatedStyle = {
    borderBottomWidth: mqDown.to((v) => {
      if (v <= 0.5) {
        return '1px';
      }
      return 0;
    }),
    borderBottomStyle: mqDown.to((v) => {
      if (v <= 0.5) {
        return 'solid';
      }
      return 'none';
    }),
    order: mqDown.to((v) => {
      if (v <= 0.5) {
        return 0;
      }
      return 1;
    }),
    justifyContent: mqDown.to((v) => {
      if (v <= 0.5) {
        return 'center';
      }
      return 'normal';
    }),
  };
  return <animated.nav css={css} style={animatedStyle}>{children}</animated.nav>;
};

export default ToolBar;
