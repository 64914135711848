/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
export var TableHeaderContainer = function (props) {
    var display = props.display, children = props.children;
    var css = React.useMemo(function () { return ouiStyle.makeCss({
        display: display,
    }); }, [
        display,
    ]);
    return (jsx("thead", { css: ouiStyle.mediaQuery(css) }, children));
};
