/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { ToggleRemSize, ToggleViewBoxSize } from '../../../../../../constants';

const disabledToggleIconContainerStyle = {
  minWidth: `${(ToggleRemSize / ToggleViewBoxSize[1]) * ToggleViewBoxSize[0]}rem`,
  maxWidth: `${(ToggleRemSize / ToggleViewBoxSize[1]) * ToggleViewBoxSize[0]}rem`,
  width: `${(ToggleRemSize / ToggleViewBoxSize[1]) * ToggleViewBoxSize[0]}rem`,
  height: `${ToggleRemSize}rem`,
  minHeight: `${ToggleRemSize}rem`,
  maxHeight: `${ToggleRemSize}rem`,
  paddingLeft: `${ouiStyle.Constants.space}rem`,
  paddingRight: `${ouiStyle.Constants.space}rem`,
};

export const DisabledToggleIconContainer = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  return (
    <div
      css={disabledToggleIconContainerStyle}
    >
      {children}
    </div>
  );
};
