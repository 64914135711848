import * as React from 'react';
import { Modal } from '../../../../../../../../Components/Modal';
import { useAccountHistoryArDocModalName, useAccountPositionsHistoryArDocModalName } from '../../../../../accounts';

export const AccountHistoryDocumentModalContainer = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  const { data } = useAccountHistoryArDocModalName();
  return data ? (
    <Modal modal={data}>
      {children}
    </Modal>
  ) : null;
};

export const AccountPositionsHistoryDocumentModalContainer = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  const { data } = useAccountPositionsHistoryArDocModalName();
  return data ? (
    <Modal modal={data}>
      {children}
    </Modal>
  ) : null;
};
