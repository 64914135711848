import ouiStyle from '@goldwasserexchange/oui-style';
import { isNil } from 'ramda';
import * as React from 'react';
import { ActorApiLoadingProvider, useSetActorApiLoading } from '../context';
import { LoadingMask } from './loader';

type ActorApiStringDisplayInnerProps = {
  data: string | undefined | null,
  isLoading: boolean,
  error: any,
  loadingValue?: string,
  isBlocked?: boolean,
  placeHolderOnBlocked?: boolean,
  withRedactedIcon?: boolean,
  lockBackgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
  lockWidthLines?: ouiStyle.InStyleWithMediaQueries['widthLines'],
  lockContainer?: ouiStyle.InStyleWithMediaQueries['container'],
  lockPaddingTop?: ouiStyle.InStyleWithMediaQueries['paddingTop'],
  lockPaddingBottom?: ouiStyle.InStyleWithMediaQueries['paddingBottom'],
  lockPaddingLeft?: ouiStyle.InStyleWithMediaQueries['paddingLeft'],
  lockPaddingRight?: ouiStyle.InStyleWithMediaQueries['paddingRight'],
  lockAlignItems?: ouiStyle.InStyleWithMediaQueries['alignItems'],
  LockChild?: () => JSX.Element | null,
}

const ActorApiStringDisplayInner = (props: ActorApiStringDisplayInnerProps) => {
  const {
    data,
    isLoading: dataIsLoading,
    error,
    loadingValue = '---',
    isBlocked = false,
    placeHolderOnBlocked = true,
    withRedactedIcon,
    lockWidthLines,
    lockBackgroundColor,
    lockContainer,
    lockPaddingTop,
    lockPaddingBottom,
    lockPaddingLeft,
    lockPaddingRight,
    lockAlignItems,
    LockChild,
  } = props;
  const setActorApiLoading = useSetActorApiLoading();
  const isLoading = dataIsLoading || !isNil(error);
  React.useEffect(() => {
    setActorApiLoading(isLoading);
  }, [isLoading, setActorApiLoading]);
  if ((isNil(data) && !isLoading && !isBlocked) || error) {
    return null;
  }
  const formatted = !isNil(data) ? `${data}` : loadingValue;
  const withPlaceHolder = !isLoading && !(isBlocked && placeHolderOnBlocked)
    ? formatted
    : formatted.replace(/[\dA-Za-z\u00C0-\u024F\u1E00-\u1EFF-]/g, '\u00F7');
  return (
    <LoadingMask
      withRedactedIcon={withRedactedIcon}
      isBlocked={isBlocked}
      lockBackgroundColor={lockBackgroundColor}
      lockWidthLines={lockWidthLines}
      lockContainer={lockContainer}
      lockPaddingTop={lockPaddingTop}
      lockPaddingBottom={lockPaddingBottom}
      lockPaddingLeft={lockPaddingLeft}
      lockPaddingRight={lockPaddingRight}
      lockAlignItems={lockAlignItems}
      LockChild={LockChild}
    >
      {withPlaceHolder}
    </LoadingMask>
  );
};

type ActorApiStringDisplayProps = ActorApiStringDisplayInnerProps & {
  ownLoadingContext?: boolean,
  initialLoading?: boolean,
}

export const ActorApiStringDisplay = (props: ActorApiStringDisplayProps) => {
  const {
    data: value,
    isLoading: isValueLoading,
    error: valueError,
    loadingValue,
    ownLoadingContext = true,
    initialLoading,
    isBlocked,
    placeHolderOnBlocked,
    withRedactedIcon,
    lockBackgroundColor,
    lockWidthLines,
    lockContainer,
    lockPaddingTop,
    lockPaddingBottom,
    lockPaddingLeft,
    lockPaddingRight,
    lockAlignItems,
    LockChild,
  } = props;
  const inner = (
    <ActorApiStringDisplayInner
      data={value}
      isLoading={isValueLoading}
      error={valueError}
      loadingValue={loadingValue}
      isBlocked={isBlocked}
      placeHolderOnBlocked={placeHolderOnBlocked}
      withRedactedIcon={withRedactedIcon}
      lockBackgroundColor={lockBackgroundColor}
      lockWidthLines={lockWidthLines}
      lockContainer={lockContainer}
      lockPaddingTop={lockPaddingTop}
      lockPaddingBottom={lockPaddingBottom}
      lockPaddingLeft={lockPaddingLeft}
      lockPaddingRight={lockPaddingRight}
      lockAlignItems={lockAlignItems}
      LockChild={LockChild}
    />
  );
  return ownLoadingContext ? (
    <ActorApiLoadingProvider
      initialLoading={initialLoading}
    >
      {inner}
    </ActorApiLoadingProvider>
  ) : inner;
};
