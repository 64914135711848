import * as React from 'react';
import { TopModelMerge, TopModelNameTransformProvider } from '@goldwasserexchange/react-topmodel';
import { PStructure } from './structure';
import { pDefaultTopModelsObject } from './topmodels';
import { pPrefixer } from './utils';
export function P(props) {
    var children = props.children;
    return (React.createElement(TopModelNameTransformProvider, { transformFn: pPrefixer },
        React.createElement(TopModelMerge, { topModels: pDefaultTopModelsObject },
            React.createElement(PStructure, null, children))));
}
