import { object } from 'yup';
import { UncastedTAddPhone, UncastedPhysicalTAdd, CPhone } from '@goldwasserexchange/oblis-frontend-utils';
import { TAddPhoneValidator } from './inputs/phone/validator';
export * from './inputs';

const tAddPhoneValidatorShape: Record<keyof Pick<UncastedPhysicalTAdd, 'PHONE'>, any> = {
  PHONE: TAddPhoneValidator,
};

export const tAddPhoneValidator = object().shape(tAddPhoneValidatorShape);

export const addPhones = (userUuid: string, moral?: boolean): UncastedTAddPhone[] => [
  {
    moral: moral ?? false,
    userUuid,
    PHONE: '',
    ID_C_PHONE: CPhone.EMAIL_PRINCIPAL,
    PHONEConfirm: '',
  },
  {
    moral: moral ?? false,
    userUuid,
    PHONE: '',
    ID_C_PHONE: CPhone.GSM_PRINCIPAL,
    PHONEConfirm: '',
  },
  {
    moral: moral ?? false,
    userUuid,
    PHONE: '',
    ID_C_PHONE: CPhone.TEL_PRINCIPAL,
    PHONEConfirm: '',
  },
];
