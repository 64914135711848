import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { DefaultListElementContextContainer } from '../provider';
import { CurrentPrimaryDimensionIndexProvider } from '../../../../../utils/dimensions/primaryDimension/current/indexes';
import { CurrentPrimaryDimensionByIndexContextProvider } from '../../../../../utils/dimensions';
export var defaultListElementSubContextsTopModelsObject = {
    ElementIndexProvider: (React.createElement(TopModel, { name: "ListElementIndexProvider" }, CurrentPrimaryDimensionIndexProvider)),
    ElementPrimaryKeyProvider: (React.createElement(TopModel, { name: "ListElementPrimaryKeyProvider" }, CurrentPrimaryDimensionByIndexContextProvider)),
};
export var defaultListElementContextContainerTopModelsObject = {
    ElementContextContainer: (React.createElement(TopModel, { name: "ListElementContextContainer" }, DefaultListElementContextContainer)),
};
