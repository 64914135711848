import * as React from 'react';
import { Trans } from '@lingui/macro';
import { UncastedOnboarding } from '@goldwasserexchange/oblis-frontend-utils';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { UserSelect } from '../../../../../../tAdd';
import { isNoneOfRepresentativeMinorOrDisabledTitular, tAddTitularsNeedRepresentative } from '../../../../../../tAdd/utils';
import {
  makeRequired, nilValidator, numberValidator,
} from '../../../../../../../OUI/Inputs';
import { makeWhenAlwaysCast } from '../../../../../../Form/validations/whenAlwaysCast';

const representativeSelectInputValidatorDependencyPaths: (keyof UncastedOnboarding)[] = [
  'users',
];

export const representativeSelectInputValidator = makeWhenAlwaysCast(representativeSelectInputValidatorDependencyPaths, (users) => {
  const needRepresentative = tAddTitularsNeedRepresentative(users);
  if (needRepresentative) {
    return makeRequired(numberValidator);
  }
  return nilValidator;
});

const representativeSelectLabel = <Trans>Sélectionnez un représentant légal</Trans>;

const label = (
  <TopModel name="SelectInputLabelContent">
    {representativeSelectLabel}
  </TopModel>
);

export const RepresentativeSelectInput = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  return (
    <UserSelect
      filterFn={isNoneOfRepresentativeMinorOrDisabledTitular}
    >
      {label}
      {children}
    </UserSelect>
  );
};
