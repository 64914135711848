import { useCallback } from 'react';
import { isBefore, endOfDay } from 'date-fns';
import { useAtomValue } from 'jotai';
import { HistoryByCurrency } from '../../type';
import { defaultApiCurrencySorter } from '../../../../../../../Components/Sections/online/utils/currencySorter';
import { useFilterByCCurrencyCode } from '../../../../shared/utils/cCurrencyCode';
import { onlineHistoryTimeIntervalEndAtom } from '../../../../../../../Components/Sections/online/sections/history/timeNav';
import { parseActorDate } from '../../../../../shared/utils/parseDates';
import { useHistoryByCurrencyLinesTransformApi, useHistoryByCurrencyTransformApi } from '../api';

const transformFunction = (historyByCurrencies: HistoryByCurrency[] | undefined) => (
  historyByCurrencies
    ? historyByCurrencies.map((historyByCurrency) => historyByCurrency['C_CURRENCY.CODE'])
    : []
);

export const useHistoryByCurrencyList = (props: {
  filterPredicate?: ((data: HistoryByCurrency) => boolean),
}) => {
  const {
    filterPredicate,
  } = props;
  const result = useHistoryByCurrencyLinesTransformApi({
    sortPredicate: defaultApiCurrencySorter,
    transformFunction,
    filterPredicate,
  });
  return result;
};

const makeTransformByEndDate = (endDate: Date) => (historyByCurrency: HistoryByCurrency | null | undefined) => (historyByCurrency
  ? historyByCurrency.history.filter((line) => isBefore(parseActorDate(line['HIS_MVT.OPER_DATE']), endOfDay(endDate))).map((line) => line['HIS_MVT.ID'])
  : []
);

export const useHistoryByCurrencyListWithCurrencyAndEndDate = (
  currency: string,
) => {
  const filterPredicate = useFilterByCCurrencyCode(currency);
  const endDate = useAtomValue(onlineHistoryTimeIntervalEndAtom);
  const transformByEndDateFunction = useCallback(makeTransformByEndDate(endDate), [endDate]);
  const result = useHistoryByCurrencyTransformApi({
    sortPredicate: defaultApiCurrencySorter,
    transformFunction: transformByEndDateFunction,
    filterPredicate,
  });
  return result;
};
