export var Container;
(function (Container) {
    Container["full"] = "100%";
    Container["none"] = "none";
    Container["halfColumn"] = "240px";
    Container["threeFourthColumn"] = "360px";
    Container["column"] = "480px";
    Container["sm"] = "640px";
    Container["slim"] = "730px";
    Container["md"] = "768px";
    Container["lg"] = "1024px";
    Container["xl"] = "1280px";
})(Container || (Container = {}));
