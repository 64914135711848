import ouiBase from '@goldwasserexchange/oui';
import * as React from 'react';
import { useSpring, animated } from 'react-spring';

export function DefaultDomSelectInputListButtonContent() {
  const isOpen = ouiBase.List.useSelectIsOpen();
  const style = useSpring({
    transform: !isOpen ? 'rotate(0deg)' : 'rotate(180deg)',
  });
  return (
    <animated.svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={style}
    >
      <polyline points="6 9 12 15 18 9" />
    </animated.svg>
  );
}
