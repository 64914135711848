import { CognitoUserWithChallengeName } from '../../../Auth/type';
import { useAuthMachineCognitoUser } from '../../../Auth';
import { getCognitoUserIdTokenPayload } from '../../../../aws';

export const getCognitoUserTAddId = (cognitoUser: CognitoUserWithChallengeName | null): {
  id?: number,
  isLoading: boolean,
} => {
  const payload = getCognitoUserIdTokenPayload(cognitoUser);
  if (!payload) {
    return { id: undefined, isLoading: true };
  }
  const id = payload['custom:actor'];
  if (!(typeof id === 'string')) {
    return { id: undefined, isLoading: true };
  }
  const intId = parseInt(id, 10);
  if (Number.isNaN(intId)) {
    return { id: undefined, isLoading: true };
  }
  return { id: intId, isLoading: false };
};

export const useCognitoUserTAddId = (): number | undefined => {
  const cognitoUser = useAuthMachineCognitoUser();
  const { id } = getCognitoUserTAddId(cognitoUser);
  return id;
};

export const useCognitoUserTAddIdIsLoading = (): boolean => {
  const cognitoUser = useAuthMachineCognitoUser();
  const { isLoading } = getCognitoUserTAddId(cognitoUser);
  return isLoading;
};
