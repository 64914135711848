import * as React from 'react';
import { SanitySecurityPriceGraphProvider } from './components';
import { SecurityPriceGraphContent } from './components/content/component';

export const SanitySecurityPriceGraph = (props: {
  value?: any,
}) => {
  const {
    value,
  } = props;
  if (!value) {
    return null;
  }
  const {
    startDate,
    endDate,
    securityId,
    alternateTitle,
  } = value;
  if (endDate == null || startDate == null || securityId == null) {
    return null;
  }
  return (
    <SanitySecurityPriceGraphProvider
      securityId={securityId}
      startDate={startDate}
      endDate={endDate}
    >
      <SecurityPriceGraphContent
        alternateTitle={alternateTitle}
      />
    </SanitySecurityPriceGraphProvider>
  );
};
