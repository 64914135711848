import React from 'react';
import { Trans } from '@lingui/macro';
import ouiBase from '@goldwasserexchange/oui';
import ouiStyle from '@goldwasserexchange/oui-style';
import ouiDom from '@goldwasserexchange/oui-dom';
import { dataStructure, Pep } from '@goldwasserexchange/oblis-frontend-utils';
import { FilterCurrentUser } from '../../../Sections/onboarding/form/constants';
import { LowerAlphaListContainer } from '../../../lowerAlphaList';

export const McPepData = {
  [Pep.NON]: { ID: 0, value: <Trans>Non</Trans> },
  [Pep.LIE]: {
    ID: 1,
    value: (
      <Trans>
        <FilterCurrentUser>
          Non mais je suis lié à une personne politiquement exposée
        </FilterCurrentUser>
        <FilterCurrentUser isNegative>
          Non mais cette personne est liée à une personne politiquement exposée
        </FilterCurrentUser>
      </Trans>
    ),
  },
  [Pep.ASSOCIE]: {
    ID: 2,
    value: (
      <Trans>
        <FilterCurrentUser>
          Non mais je suis associé à une personne politiquement exposée
        </FilterCurrentUser>
        <FilterCurrentUser isNegative>
          Non mais cette personne est associée à une personne politiquement exposée
        </FilterCurrentUser>
      </Trans>
    ),
  },
  [Pep.OUI]: { ID: 3, value: <Trans>Oui</Trans> },
};

export const tAddPepTypeHelpPopper = (
  <Trans>
    <p>
      Est considérée comme une&#32;
      <ouiDom.TextSnippet.TextSnippet
        fontWeight={ouiStyle.Constants.FontWeightProp.bold}
      >
        Personne Politiquement Exposée&#32;
      </ouiDom.TextSnippet.TextSnippet>
      toute personne physique qui occupe ou a occupé une fonction publique importante et, notamment:
    </p>
    <LowerAlphaListContainer>
      <li>
        les chefs d'État, les chefs de gouvernement, les ministres et les secrétaires d'État;
      </li>
      <li>
        les parlementaires ou les membres d'organes législatifs similaires ;
      </li>
      <li>
        les membres des organes dirigeants des partis politiques ;
      </li>
      <li>
        les membres des cours suprêmes, des cours constitutionnelles ou d'autres hautes juridictions, y compris administratives, dont les décisions ne sont pas susceptibles de recours, sauf circonstances exceptionnelles ;
      </li>
      <li>
        les membres des cours des comptes ou des conseils ou directoires des banques centrales ;
      </li>
      <li>
        les ambassadeurs, les consuls, les chargés d'affaires et les officiers supérieurs des forces armées ;
      </li>
      <li>
        les membres des organes d'administration, de direction ou de surveillance des entreprises publiques ;
      </li>
      <li>
        les directeurs, les directeurs adjoints et les membres du conseil d'une organisation internationale, ou les personnes qui occupent une position équivalente en son sein ;
      </li>
    </LowerAlphaListContainer>
    <p>
      Sont visées par le terme &ldquo;associée&rdquo;:
    </p>
    <div>
      <LowerAlphaListContainer>
        <li>
          les personnes physiques qui, conjointement avec une personne politiquement exposée, sont les bénéficiaires effectifs d'une entité personne morale, ou qui sont connues pour entretenir toute autre relation d'affaires étroite avec une personne politiquement exposé ;
        </li>
        <li>
          les personnes physiques qui sont les seuls bénéficiaires effectifs d'une entité personne morale, connue pour avoir été créée, dans les faits, dans l'intérêt d'une personne politiquement exposée ;
        </li>
      </LowerAlphaListContainer>
    </div>
    <div>
      <p>
        Sous le terme « liée », il y a lieu de comprendre une personne qui serait&#32;
        <ouiDom.TextSnippet.TextSnippet
          fontWeight={ouiStyle.Constants.FontWeightProp.bold}
        >
          “membre de la famille” d'une Personne Politiquement Exposée&#32;
        </ouiDom.TextSnippet.TextSnippet>
        , à savoir:
      </p>
      <LowerAlphaListContainer>
        <li>
          le conjoint ou une personne considérée comme l'équivalent d'un conjoint;
        </li>
        <li>
          les enfants et leurs conjoints, ou les personnes considérées comme l'équivalent d'un conjoint;
        </li>
        <li>
          les parents ;
        </li>
      </LowerAlphaListContainer>
    </div>
  </Trans>
);

export const tAddPepTypeLabel = (
  <>
    <FilterCurrentUser>
      <Trans>Êtes-vous une personne politiquement exposée ?</Trans>
    </FilterCurrentUser>
    <FilterCurrentUser isNegative>
      <Trans>S'agit-il d'une personne politiquement exposée ?</Trans>
    </FilterCurrentUser>
  </>
);

export const tAddPepTypeValidatorLogic: ouiBase.utils.hookstate.FormValueFilterLogic<'requiredString' | 'nilCast'> = dataStructure.T_ADD.fields.TYPE.logic.makeTAddIsNotMoralOrIgnored({
  truthy: {
    result: 'requiredString',
  },
  falsy: {
    result: 'nilCast',
  },
});
