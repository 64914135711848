import { isNil } from 'ramda';
import { useCallback } from 'react';
import { useTAccId } from '../../../../shared';
import { PersonAccounts } from '../../type';

export const filterByTAccId = (tAccId?: PersonAccounts['T_ACC.ID'] | string | null) => (response: PersonAccounts) => !isNil(tAccId) && `${response['T_ACC.ID']}` === `${tAccId}`;

export const useFilterByTAccId = () => {
  const tAccId = useTAccId();
  const fn = useCallback(filterByTAccId(tAccId), [tAccId]);
  return fn;
};
