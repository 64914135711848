import { RampWithNameTransform } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import { DefaultListInputElementContextContainer } from '../../../../shared';

export function DefaultRadioListInputElementContextContainer(
  props: React.PropsWithChildren<{ index: number }>,
) {
  const { index, children } = props;
  return (
    <DefaultListInputElementContextContainer index={index}>
      <RampWithNameTransform rampName="ElementOnChangeContextProvider">
        {children}
      </RampWithNameTransform>
    </DefaultListInputElementContextContainer>
  );
}
