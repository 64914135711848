/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';

const toolbarDropDownListElementStyle: ouiStyle.InStyle = {
};

const toolbarDropDownListElementCss = ouiStyle.makeCss(toolbarDropDownListElementStyle);

export const ToolbarDropDownListElement = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  return (
    <li
      css={toolbarDropDownListElementCss}
    >
      {children}
    </li>
  );
};
