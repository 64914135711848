import * as React from 'react';
import ouiDom from '@goldwasserexchange/oui-dom';
import ouiStyle from '@goldwasserexchange/oui-style';
import { CodeInputContent } from './content';

export const InputCode = (props: {
  saveDevice?: boolean,
  confirm?: boolean,
}) => {
  const {
    saveDevice,
    confirm,
  } = props;
  return (
    <ouiDom.HeadingSection.HeadingSection
      name="InputCodeSignIn"
      index={1}
      backgroundColor={ouiStyle.Constants.Colors.transparent}
      container={ouiStyle.Constants.Container.slim}
      paddingHorizontal="double"
    >
      <CodeInputContent
        saveDevice={saveDevice}
        confirm={confirm}
      />
    </ouiDom.HeadingSection.HeadingSection>
  );
};
