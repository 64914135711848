export enum Accounts {
  Positions = 'accounts.positions',
  History = 'accounts.history',
  Cashflows = 'accounts.cashflows',
  Documents = 'accounts.documents',
  AvailableCash = 'accounts.availableCash',
  Ibans = 'accounts.ibans',
  Rates = 'accounts.rates',
  Twrr = 'accounts.twrr',
  PositionsHistory = 'accounts.positions.history',
}
