import * as React from 'react';
import { format as dateFnsFormat } from 'date-fns';
import voca from 'voca';
import { MediaContext } from '../MediaContext';
import { useGetDateFnsLocale } from '../getDateLocale';

export const useDateDisplayFormatter = (format: string, capitalized?: boolean) => {
  const locale = useGetDateFnsLocale();
  const formatter = (date?: number | Date | undefined | null) => {
    if (date == null) {
      return '';
    }
    const formatted = dateFnsFormat(date, format, {
      locale,
    });
    const withCapitalize = !capitalized ? formatted : voca.capitalize(formatted);
    return withCapitalize;
  };
  return formatter;
};

export const DateDisplay = (props: {
  date: Date,
  format?: string,
  capitalized?: boolean,
  placeholder?: string,
  spaceBefore?: boolean,
  spaceAfter?: boolean,
  formatMq?: boolean,
}) => {
  const {
    date,
    format = 'dd/MM/yyyy',
    capitalized = false,
    placeholder,
    spaceBefore = false,
    spaceAfter = false,
    formatMq = true,
  } = props;
  const {
    upMd,
  } = React.useContext(MediaContext);
  const mqFormat = !(!upMd && formatMq && format === 'dd/MM/yyyy') ? format : 'dd/MM';
  const formatter = useDateDisplayFormatter(mqFormat, capitalized);
  const formatted = formatter(date);
  const withPlaceHolder = placeholder === undefined ? formatted : formatted.replace(/[\dA-Za-z\u00C0-\u024F\u1E00-\u1EFF]/g, placeholder);
  return (
    <>
      {spaceBefore ? ' ' : ''}
      {withPlaceHolder}
      {spaceAfter ? ' ' : ''}
    </>
  );
};
