import * as React from 'react';
import { ActorApiStringDisplay } from '../../../../../../components/display/string';
import { useBondsBondExRateHisExRateGesInvertedString, useBondsBondIsRedacted } from '../../hooks';

export const BondsBondExRateGesString = (props: {
  mediaQueryIndex: number,
  withRedactedIcon?: boolean,
}) => {
  const {
    mediaQueryIndex,
    withRedactedIcon,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useBondsBondExRateHisExRateGesInvertedString(mediaQueryIndex);
  const {
    data: redacted,
    isLoading: redactedIsLoading,
  } = useBondsBondIsRedacted('EXRATE_HIS.EXRATE_GES');
  return (
    <ActorApiStringDisplay
      data={data}
      isLoading={isLoading || redactedIsLoading}
      error={error}
      loadingValue="---"
      isBlocked={redacted}
      withRedactedIcon={withRedactedIcon}
    />
  );
};
