import { dataStructure, AccountType } from '@goldwasserexchange/oblis-frontend-utils';
import ouiBase from '@goldwasserexchange/oui';
import ouiStyle from '@goldwasserexchange/oui-style';
import ouiDom from '@goldwasserexchange/oui-dom';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import { StateFilter } from '../../../../Machine';
import { AccountTypeBodyRowCellContainer, AccountTypeBodyRowCellContainerProps } from '../shared';
import { useOuiStyleMediaContextByIndex } from '../../../../MediaContext';
import { RecommendationArrow, RecommendationContainer, RecommendationText } from './components';

export const RECOMMENDATION = 'recommendation';

const accountTypeStateMap = {
  [AccountType.RTO]: '.RTO',
  [AccountType.CONSEIL]: '.conseil',
  [AccountType.GESTION]: '.gestion',
};

export const RecommendationOnPrimaryDimension = () => {
  const upMq = useOuiStyleMediaContextByIndex(2);
  const accountType = ouiBase.utils.dimensions.useCurrentPrimaryDimension() as dataStructure.accountType.listType;
  if (upMq) {
    return (
      <StateFilter is={accountTypeStateMap[accountType]}>
        <RecommendationContainer>
          <RecommendationArrow />
          <RecommendationText />
        </RecommendationContainer>
      </StateFilter>
    );
  }
  return (
    <StateFilter is={accountTypeStateMap[accountType]}>
      <RecommendationText />
    </StateFilter>
  );
};

const RecommendationAccountTypeBodyRowCellContainer = (props: React.PropsWithChildren<AccountTypeBodyRowCellContainerProps>) => {
  const {
    children,
    ...rest
  } = props;
  return (
    <AccountTypeBodyRowCellContainer
      display={[ouiStyle.Constants.DisplayProp.block, undefined, ouiStyle.Constants.DisplayProp.tableCell, undefined, undefined, undefined]}
      borderBottomWidth={['simple', undefined, 'none', undefined, undefined, undefined]}
      borderBottomColor={[ouiStyle.Constants.Colors.fade, undefined, ouiStyle.Constants.Colors.transparent, undefined, undefined, undefined]}
      {...rest}
    >
      {children}
    </AccountTypeBodyRowCellContainer>
  );
};

export const recommendationTopModels = (
  <>
    <TopModel
      name={`TableSecondaryTitleCellContainer-${RECOMMENDATION}`}
      display={[ouiStyle.Constants.DisplayProp.block, undefined, ouiStyle.Constants.DisplayProp.tableCell, undefined, undefined, undefined]}
      borderBottomWidth="none"
      borderBottomColor={ouiStyle.Constants.Colors.transparent}
    >
      {ouiDom.Table.TableHeaderHeading}
    </TopModel>
    <TopModel
      name={`TableBodyRowCellContainer--${RECOMMENDATION}`}
    >
      {RecommendationAccountTypeBodyRowCellContainer}
    </TopModel>
    <TopModel name={`TableBodyRowCellContent--${RECOMMENDATION}`}>
      {RecommendationOnPrimaryDimension}
    </TopModel>
  </>
);
