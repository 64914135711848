var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useContext, useMemo } from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { useHorizontalChildPaddings } from '../elementPadding';
import { defaultListFontSize, useListInputElementLines } from '../hooks';
import { listInputElementWidthLinesContext } from './context';
export function useListInputElementWidthLines() {
    var listInputElementLines = useContext(listInputElementWidthLinesContext);
    return listInputElementLines;
}
export function useListInputElementWidth() {
    var listInputElementLines = useListInputElementLines();
    var listInputElementWidthLines = useListInputElementWidthLines();
    var listInputElementHorizontalPadding = useHorizontalChildPaddings();
    var width = useMemo(function () { return ouiStyle.makeCss(__assign(__assign(__assign({}, defaultListFontSize), listInputElementHorizontalPadding), { lines: listInputElementLines, widthLines: listInputElementWidthLines })); }, [listInputElementWidthLines, listInputElementHorizontalPadding]).width;
    var currentElementWidth = !Array.isArray(width)
        ? parseFloat(width.replace(/rem/g, '')) * 16
        : parseFloat(width[0].replace(/rem/g, '')) * 16;
    return currentElementWidth;
}
