import * as React from 'react';
import { useFieldTouchedByFieldPath } from '../../../Form';
import { OnBlurEnhanceContextProvider } from '../../../utils';
import { useGetFieldPath } from '../fieldPath';

export function TouchedContext(props: React.PropsWithChildren<{}>) {
  const { children } = props;
  const fieldPath = useGetFieldPath();
  const touchedState = useFieldTouchedByFieldPath(fieldPath);
  const touchOnBlur = React.useCallback(() => {
    touchedState.set(true);
  }, [touchedState]);
  return (
    <OnBlurEnhanceContextProvider onBlur={touchOnBlur}>
      {children}
    </OnBlurEnhanceContextProvider>
  );
}
