import Fuse from 'fuse.js';

export const fuzySections = (sections: string[]) => new Fuse(sections, {
  shouldSort: true,
  threshold: 0.4,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
});
