import * as React from 'react';
import { onBlurContext, OnBlurContextType } from './context';
import { useEnhanceHandler, FnMergeOrder } from '../../hooks';

export function OnBlurSetContextProvider(
  props: React.PropsWithChildren<{
    onBlur: OnBlurContextType,
  }>,
) {
  const { onBlur, children } = props;
  return (
    <onBlurContext.Provider value={onBlur}>{children}</onBlurContext.Provider>
  );
}

export function OnBlurUnsetContextProvider(props: React.PropsWithChildren<{}>) {
  const { children } = props;
  return (
    <onBlurContext.Provider value={undefined}>
      {children}
    </onBlurContext.Provider>
  );
}

export function OnBlurEnhanceContextProvider(
  props: React.PropsWithChildren<{
    onBlur: OnBlurContextType,
    order?: FnMergeOrder,
  }>,
) {
  const { onBlur: onBlurProps, order = FnMergeOrder.before, children } = props;
  const contextonBlur = React.useContext(onBlurContext);
  const onBlur = useEnhanceHandler(contextonBlur, onBlurProps, order);
  return (
    <onBlurContext.Provider value={onBlur}>{children}</onBlurContext.Provider>
  );
}
