import { dataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import * as React from 'react';
import { ActorApiStringDisplay } from '../../../../../actor/components/display/string';
import { useLastSubmittedOnboardingsBySk } from '../../../../utils/dynamoDb/onboarding/get';

export const OnboardingStatusService = (props: {
  withPending?: boolean,
}) => {
  const {
    withPending,
  } = props;
  const {
    data: onboardingSave,
    isLoading,
    error,
  } = useLastSubmittedOnboardingsBySk({ withPending });
  const service = onboardingSave?.data.selectedService;
  const label = dataStructure.accountType.labels.useLabel(service);
  return (
    <ActorApiStringDisplay
      data={label !== '' ? label : undefined}
      isLoading={isLoading}
      error={error}
    />
  );
};
