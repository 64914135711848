import * as React from 'react';
import { onMouseLeaveContext, OnMouseLeaveContextType } from './context';
import { useEnhanceHandler, FnMergeOrder } from '../../hooks';

export function OnMouseLeaveSetContextProvider(
  props: React.PropsWithChildren<{
    onMouseLeave: OnMouseLeaveContextType,
  }>,
) {
  const { onMouseLeave, children } = props;
  return (
    <onMouseLeaveContext.Provider value={onMouseLeave}>{children}</onMouseLeaveContext.Provider>
  );
}

export function OnMouseLeaveUnsetContextProvider(props: React.PropsWithChildren<{}>) {
  const { children } = props;
  return (
    <onMouseLeaveContext.Provider value={undefined}>
      {children}
    </onMouseLeaveContext.Provider>
  );
}

export function OnMouseLeaveEnhanceContextProvider(
  props: React.PropsWithChildren<{
    onMouseLeave: OnMouseLeaveContextType,
    order?: FnMergeOrder,
  }>,
) {
  const { onMouseLeave: onMouseLeaveProps, order = FnMergeOrder.before, children } = props;
  const contextonMouseLeave = React.useContext(onMouseLeaveContext);
  const onMouseLeave = useEnhanceHandler(contextonMouseLeave, onMouseLeaveProps, order);
  return (
    <onMouseLeaveContext.Provider value={onMouseLeave}>{children}</onMouseLeaveContext.Provider>
  );
}
