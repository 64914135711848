/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
var sanityTableContainerStyle = {
    widthLines: 'full',
    paddingTop: 'simple',
    paddingBottom: 'simple',
    borderCollapse: 'collapse',
};
var sanityTableContainerCss = ouiStyle.makeCss(sanityTableContainerStyle);
export var SanityTableContainer = function (props) {
    var children = props.children;
    return (jsx("table", { css: sanityTableContainerCss }, children));
};
