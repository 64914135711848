import { Locale } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import nlLocale from 'date-fns/locale/nl';
import { useLinguiLocale } from '../useLinguiLocale';

const locales = {
  fr: frLocale,
  nl: nlLocale,
};

export const useGetDateFnsLocale = (): Locale => {
  const locale = useLinguiLocale();
  return locales[locale] ?? locales.fr;
};
