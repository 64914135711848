import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { SanityLinkContainer } from './components';

export const SanityLink = (props: React.PropsWithChildren<{
  value?: {
    href?: string,
  },
  textAlign?: ouiStyle.InStyleWithMediaQueries['textAlign'],
  hoverColor?: ouiStyle.Constants.Colors,
}>) => {
  const {
    value,
    textAlign,
    hoverColor,
    children,
  } = props;
  const {
    href,
  } = value ?? {};
  if (href == null) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
  const url = new URL(href);
  if (url.hostname === window.location.hostname) {
    return (
      <SanityLinkContainer
        to={`${url.pathname}${url.search}${url.hash}`}
        textAlign={textAlign}
        hoverColor={hoverColor}
      >
        {children}
      </SanityLinkContainer>
    );
  }
  return (
    <SanityLinkContainer
      href={href}
      target="_blank"
      textAlign={textAlign}
      hoverColor={hoverColor}
    >
      {children}
    </SanityLinkContainer>
  );
};
