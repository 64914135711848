import { HeadProps } from '../type';
import { isCookieFirstLoaded } from '../../cookieFirst/utils';
import { setTitle } from './title';
import { setLinks } from './link';
import { setMetadataTags } from './metaTag';

export const applyHead = (headProps: HeadProps) => {
  const {
    language,
  } = headProps;
  if (isCookieFirstLoaded()) {
    window.CookieFirst.changeLanguage(language);
  }
  const html = document.querySelector('html');
  if (html != null) {
    const {
      title,
      href,
      frHref,
      nlHref,
      openGraph: openGraphOptionals,
      jsonLd,
    } = headProps;
    html.setAttribute('lang', language);
    setTitle(title);
    const head = html.querySelector('head');
    if (head) {
      setMetadataTags({
        head,
        openGraph: title != null && href != null ? {
          ...(openGraphOptionals ?? {}),
          locale: language,
          url: href,
          title,
        } : undefined,
        jsonLd,
      });
      setLinks({
        head,
        href,
        frHref,
        nlHref,
      });
    }
  }
};
