import * as TYPE from '../../TYPE';
var idCCountryNat = 'ID_C_COUNTRY_NAT';
export var showLogic = TYPE.logic.makeTAddIsNotMoralOrIgnored({
    truthy: {
        name: "<parentName>.".concat(idCCountryNat),
        is: 212,
        alternatives: {
            truthy: {
                result: 'visible',
            },
            falsy: {
                result: 'invisible',
            },
        },
    },
});
