import { TopModelMerge } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import { ScrollableStructure } from '../../OUI/Scrollable';
import { scrollableDomTopModels } from './topModels';

export const DefaultScrollableDom = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  return (
    <TopModelMerge topModels={scrollableDomTopModels}>
      <ScrollableStructure>
        {children}
      </ScrollableStructure>
    </TopModelMerge>
  );
};
