import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { DefaultRadioListInputListElementLabelContainer } from '../radio/components';
import {
  CheckboxListInputDomListElementButtonContainer,
  CheckboxListInputDomListElementButtonContent,
  CheckboxListInputListElementLabelDisabledReasonContainer,
  DefaultDomCheckboxListInputListElementInput,
} from './components';

export const checkboxListInputDomDefaultTopModelsObject = {
  CheckboxListInputListElementLabelContainer: (
    <TopModel name="CheckboxListInputListElementLabelContainer">
      {DefaultRadioListInputListElementLabelContainer}
    </TopModel>
  ),
  CheckboxListInputListElementInput: (
    <TopModel name="CheckboxListInputListElementInput">
      {DefaultDomCheckboxListInputListElementInput}
    </TopModel>
  ),
  CheckboxListInputListElementLabelDisabledReasonContainer: (
    <TopModel name="CheckboxListInputListElementLabelDisabledReasonContainer">
      {CheckboxListInputListElementLabelDisabledReasonContainer}
    </TopModel>
  ),
  CheckboxListInputListElementButtonContainer: (
    <TopModel name="CheckboxListInputListElementButtonContainer">
      {CheckboxListInputDomListElementButtonContainer}
    </TopModel>
  ),
  CheckboxListInputListElementButtonContent: (
    <TopModel name="CheckboxListInputDomListElementButtonContent">
      {CheckboxListInputDomListElementButtonContent}
    </TopModel>
  ),
};
