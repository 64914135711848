var _a;
import ouiBase from '@goldwasserexchange/oui';
import * as React from 'react';
import { useHookstate } from '@hookstate/core';
import { i18n } from '@goldwasserexchange/i18n';
import { AccountType } from '../../types';
export var labels = (_a = {},
    _a[AccountType.RTO] = {
        ID: AccountType.RTO,
        label: function () { return i18n._('Gold'); },
    },
    _a[AccountType.CONSEIL] = {
        ID: AccountType.CONSEIL,
        label: function () { return i18n._('Premium'); },
    },
    _a[AccountType.GESTION] = {
        ID: AccountType.GESTION,
        label: function () { return i18n._('Global+'); },
    },
    _a);
export var useLabel = function (accountType) {
    if (!accountType) {
        return '';
    }
    var translated = labels[accountType].label();
    return translated;
};
export var Label = function (props) {
    var accountType = props.accountType;
    var translated = useLabel(accountType);
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return React.createElement(React.Fragment, null, translated);
};
export var LabelOnPrimaryDimension = function () {
    var accountType = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
    return (React.createElement(Label, { accountType: accountType }));
};
export var LabelOnFieldPath = function () {
    var valueState = ouiBase.Form.useFormData();
    var accountType = useHookstate(valueState.onboarding.selectedService).get();
    return React.createElement(Label, { accountType: accountType });
};
