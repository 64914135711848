/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { animated, useSpring } from 'react-spring';
import ouiStyle from '@goldwasserexchange/oui-style';
import { useAtomValue } from 'jotai';
import * as animationController from '../../animation';
import { ValidSections, pathnameUrlSectionAtom } from '../../../../history';

const navBarLinkSelectedBarStyle: ouiStyle.InStyle = {
  backgroundColor: ouiStyle.Constants.Colors.accent,
  position: 'absolute',
  bottom: 0,
  left: 0,
};

const navBarLinkSelectedBarCss = ouiStyle.makeCss(navBarLinkSelectedBarStyle);

export const NavBarLinkSelectedBar = (props: {
  section: ValidSections,
}) => {
  const {
    section,
  } = props;
  const currentSection = useAtomValue(pathnameUrlSectionAtom);
  const selected = currentSection === section;
  const mqDown = animationController.useAnimationmqDownSpringValue();
  const {
    opacity,
  } = useSpring({
    opacity: selected ? 1 : 0,
  });
  const style = {
    opacity,
    top: mqDown.to((v) => {
      if (v <= 0.5) {
        return 'calc(0% - 0rem)';
      }
      return 'calc(100% - 0.25rem)';
    }),
    right: mqDown.to((v) => {
      if (v <= 0.5) {
        return 'calc(100% - 0.25rem)';
      }
      return 'calc(0% - 0rem)';
    }),
  };
  return (
    <animated.span
      css={navBarLinkSelectedBarCss}
      style={style}
    />
  );
};

export default NavBarLinkSelectedBar;
