export var initialRTOValues = function () { return ({
    RTORawMaterialTrackerQuestion0Answer: undefined,
    RTORawMaterialTrackerQuestion0RemedialAnswer: undefined,
    RTORawMaterialTrackerQuestion0IsCorrect: undefined,
    RTORawMaterialTrackerQuestion0RemedialIsCorrect: undefined,
    RTORawMaterialTrackerQuestion0IsCorrected: false,
    RTORawMaterialTrackerQuestion0RemedialIsCorrected: false,
    RTORawMaterialTrackerQuestion0WasRetried: false,
    RTORawMaterialTrackerQuestion1Answer: undefined,
    RTORawMaterialTrackerQuestion1RemedialAnswer: undefined,
    RTORawMaterialTrackerQuestion1IsCorrect: undefined,
    RTORawMaterialTrackerQuestion1RemedialIsCorrect: undefined,
    RTORawMaterialTrackerQuestion1WasRetried: false,
    RTORawMaterialTrackerQuestion1IsCorrected: false,
    RTORawMaterialTrackerQuestion1RemedialIsCorrected: false,
    RTORawMaterialTrackerQuestion2Answer: undefined,
    RTORawMaterialTrackerQuestion2RemedialAnswer: undefined,
    RTORawMaterialTrackerQuestion2IsCorrect: undefined,
    RTORawMaterialTrackerQuestion2RemedialIsCorrect: undefined,
    RTORawMaterialTrackerQuestion2WasRetried: false,
    RTORawMaterialTrackerQuestion2IsCorrected: false,
    RTORawMaterialTrackerQuestion2RemedialIsCorrected: false,
    RTORawMaterialTrackerQuestion3Answer: undefined,
    RTORawMaterialTrackerQuestion3RemedialAnswer: undefined,
    RTORawMaterialTrackerQuestion3IsCorrect: undefined,
    RTORawMaterialTrackerQuestion3RemedialIsCorrect: undefined,
    RTORawMaterialTrackerQuestion3WasRetried: false,
    RTORawMaterialTrackerQuestion3IsCorrected: false,
    RTORawMaterialTrackerQuestion3RemedialIsCorrected: false,
    RTORawMaterialTrackerQuestion4Answer: undefined,
    RTORawMaterialTrackerQuestion4RemedialAnswer: undefined,
    RTORawMaterialTrackerQuestion4IsCorrect: undefined,
    RTORawMaterialTrackerQuestion4RemedialIsCorrect: undefined,
    RTORawMaterialTrackerQuestion4WasRetried: false,
    RTORawMaterialTrackerQuestion4IsCorrected: false,
    RTORawMaterialTrackerQuestion4RemedialIsCorrected: false,
    RTORawMaterialTrackerQuestion5Answer: undefined,
    RTORawMaterialTrackerQuestion5RemedialAnswer: undefined,
    RTORawMaterialTrackerQuestion5IsCorrect: undefined,
    RTORawMaterialTrackerQuestion5RemedialIsCorrect: undefined,
    RTORawMaterialTrackerQuestion5WasRetried: false,
    RTORawMaterialTrackerQuestion5IsCorrected: false,
    RTORawMaterialTrackerQuestion5RemedialIsCorrected: false,
}); };
export var initialConseilGestionValues = function () { return ({
    rawMaterialTrackerRiskAnswer: undefined,
    rawMaterialTrackerRiskCauseAnswer: undefined,
    rawMaterialTrackerRiskCauseIsCorrect: undefined,
    rawMaterialTrackerRiskCauseIsCorrected: false,
    rawMaterialTrackerRiskCauseWasRetried: false,
    rawMaterialTrackerRiskIsCorrect: undefined,
    rawMaterialTrackerRiskIsCorrected: false,
    rawMaterialTrackerRiskWasRetried: false,
}); };
