import { useMemo } from 'react';
import { useSanityNewsByUrlId } from '../byId';
import { useBondInvalidSAccIds } from './context/hooks';

export const useLocalizedSanityNewsBondsByUrlId = () => {
  const baseResponse = useSanityNewsByUrlId();
  const response = useMemo(
    () => {
      const {
        data,
        ...rest
      } = baseResponse;
      if (data == null) {
        return {
          ...rest,
          data: null,
        };
      }
      return {
        ...rest,
        data: data
          ?.bondIds ?? [],
      };
    },
    [baseResponse.data, baseResponse.isLoading, baseResponse.error],
  );
  return response;
};

export const useLocalizedSanityNewsBondsByUrlIdWithoutInvalid = () => {
  const baseResponse = useLocalizedSanityNewsBondsByUrlId();
  const invalidSAccIds = useBondInvalidSAccIds();
  const response = useMemo(
    () => {
      const {
        data,
        ...rest
      } = baseResponse;
      if (data == null) {
        return {
          ...rest,
          data: null,
        };
      }
      return {
        ...rest,
        data: data.filter((id) => !invalidSAccIds.has(id)),
      };
    },
    [invalidSAccIds, baseResponse.data, baseResponse.isLoading, baseResponse.error],
  );
  return response;
};
