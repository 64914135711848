import { isNil } from 'ramda';
import { useFilterByTAccId } from '../../utils/tAcc/filterByTAccId';
import { usePersonAccountTransformApi } from '../api';
import { PersonAccounts } from '../../type';

const exists = (personAccounts: PersonAccounts | undefined) => personAccounts && !isNil(personAccounts['T_ACC.ID']);

export const usePersonAccountsHasTAcc = () => {
  const filterFn = useFilterByTAccId();
  const response = usePersonAccountTransformApi({
    transformFunction: exists,
    filterPredicate: filterFn,
  });
  return {
    ...response,
    data: response.data ?? response.isLoading,
  };
};
