import ouiStyle from '@goldwasserexchange/oui-style';
import { Trans } from '@lingui/macro';
import * as React from 'react';
import { UncastedDataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import ouiBase from '@goldwasserexchange/oui';
import {
  SubmitButton,
} from '../../../../../../OUI/Form';

const connectText = (
  <Trans>
    Se connecter
  </Trans>
);

export const UserNamePasswordSubmitButton = (props: React.PropsWithChildren<{
  withUserName?: boolean,
}>) => {
  const {
    withUserName = false,
    children = connectText,
  } = props;
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const paths = React.useMemo(() => [
    withUserName ? valueState.auth.username.path.join('.') : null,
    valueState.auth.password.path.join('.'),
  ].filter<string>((path: string | null): path is string => path != null), [withUserName]);
  return (
    <SubmitButton
      paths={paths}
      backgroundColor={ouiStyle.Constants.Colors.accent}
      color={ouiStyle.Constants.Colors.inverted}
      hoverBackgroundColor={ouiStyle.Constants.Colors.accentShaded}
    >
      {children ?? connectText}
    </SubmitButton>
  );
};
