import { ParsedQuery } from 'query-string';
import { castBooleanQsParam } from '../../../../../../Components/utils/castBooleanQsParam';
import {
  castBondCCurrencyCode, castMaturity, castNumber, SearchAndFilterBondParams,
} from './filter';
import { BondSort, getBondSortColumn, getBondSortDirection } from './sort';

export const getBondSearchParams = (qsParams: ParsedQuery<string>): SearchAndFilterBondParams & BondSort => {
  const {
    SEARCH_TERM = '',
    'S_MARKET.YIELD': minYield,
    'S_MARKET.SMALL_QT_MIN': smallDenominiation = 'false',
    'CPS_HIS.LOW_ACCRUED': lowAccruedInterests = 'false',
    'S_MARKET.PRICE_CLOSE_LOW': lowPrice = 'false',
    'S_ACC.MATURITY': maturity = undefined,
    'RATING_SP.INVESTMENT_GRADE': investmentGrade = 'false',
    'S_ACC.IS_GREEN': sAccIsGreen = 'false',
    'C_CURRENCY.CODE': cCurrencyCode = undefined,
    sortColumn,
    sortDirection,
    page = '1',
  } = qsParams;
  const parsedPage = page && !Array.isArray(page) ? parseInt(page, 10) : 0;
  return ({
    SEARCH_TERM: SEARCH_TERM && !Array.isArray(SEARCH_TERM) ? SEARCH_TERM : '',
    page: !Number.isNaN(parsedPage) ? parsedPage : 0,
    'S_MARKET.YIELD': castNumber(minYield),
    'S_MARKET.SMALL_QT_MIN': castBooleanQsParam(smallDenominiation),
    'CPS_HIS.LOW_ACCRUED': castBooleanQsParam(lowAccruedInterests),
    'S_MARKET.PRICE_CLOSE_LOW': castBooleanQsParam(lowPrice),
    'S_ACC.MATURITY': castMaturity(maturity),
    'RATING_SP.INVESTMENT_GRADE': castBooleanQsParam(investmentGrade),
    'C_CURRENCY.CODE': castBondCCurrencyCode(cCurrencyCode),
    'S_ACC.IS_GREEN': castBooleanQsParam(sAccIsGreen),
    sortColumn: getBondSortColumn(sortColumn),
    sortDirection: getBondSortDirection(sortDirection),
  });
};
