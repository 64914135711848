/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const mainStyle: ouiStyle.InStyle = {
  position: 'relative',
  display: ouiStyle.Constants.DisplayProp.flex,
};

const mainCss = ouiStyle.makeCss(mainStyle);

export function DefaultTextInputMainContainer(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  return <div css={mainCss}>{children}</div>;
}
