import React from 'react';
import { Trans } from '@lingui/macro';
import {
  dataStructure, CCountry,
} from '@goldwasserexchange/oblis-frontend-utils';
import { useHookstate } from '@hookstate/core';
import { tAddNationalRegisterLabel } from './nationalRegister';
import { useUser } from '../../../utils';

const tAddRegistNumOtherLabel = <Trans>Autre numéro d'identification fiscale</Trans>;

export const tAddFiscRegistNumOtherValidatorLogic = dataStructure.T_ADD.fields.TIN_US_SEC.logic.show.makeShowLogic({
  national: {
    result: 'requiredNationalRegister',
  },
  global: {
    result: 'requiredString',
  },
  invisible: {
    result: 'nilCast',
  },
  nationalInNatNumber: {
    result: 'nilCast',
  },
});

const TAddFiscOtherRegistNumLabel = () => {
  const userState = useUser();
  const idCCountryResOther = useHookstate(userState.secondaryFiscalAddress.ID_C_COUNTRY).get();
  return idCCountryResOther === CCountry.BELGIUM
    // eslint-disable-next-line react/jsx-no-useless-fragment
    ? <>{tAddNationalRegisterLabel}</>
    // eslint-disable-next-line react/jsx-no-useless-fragment
    : <>{tAddRegistNumOtherLabel}</>;
};

export const tAddFiscOtherRegistNumLabel = <TAddFiscOtherRegistNumLabel />;

export const FISCAL_NUMBER_OTHER_KEY = 'fiscalNumberOther';
