import { makeHasFinancialProductExperienceLogicRecord } from './logic';
import { makeSelectedLogicByFinancialProduct } from '../../../financialProductsSelection/logic/selected';
export var getMakeHasFinancialProductExperienceLogic = function (financialProduct) {
    var makeLogic = makeHasFinancialProductExperienceLogicRecord[financialProduct];
    return makeLogic;
};
export function getMakeHasFinancialProductExperienceWithSelectedLogic(financialProduct, alternatives) {
    var notSelected = alternatives.notSelected, hasExperience = alternatives.hasExperience, hasNotExperience = alternatives.hasNotExperience, notAnswered = alternatives.notAnswered;
    return makeSelectedLogicByFinancialProduct({
        financialProduct: financialProduct,
        alternatives: {
            selected: getMakeHasFinancialProductExperienceLogic(financialProduct)({
                alternatives: {
                    hasExperience: hasExperience,
                    hasNotExperience: hasNotExperience,
                    notAnswered: notAnswered,
                },
            }),
            notSelected: notSelected,
        },
    });
}
