/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { useLastSubmittedOnboardings } from '../../../../utils/dynamoDb/onboarding/get';
import { OnboardingStatusBannerContainer } from './components';
import { OnboardingStatusBannerContent } from './components/content';

export const OnboardingStatusBanner = (props: {
  openInNewWindow?: boolean,
  container?: ouiStyle.InStyle['container'],
  marginVertical?: ouiStyle.InStyle['marginVertical'],
}) => {
  const {
    openInNewWindow = false,
    container,
    marginVertical,
  } = props;
  const {
    data,
  } = useLastSubmittedOnboardings();
  if (!data || data.length === 0) {
    return null;
  }
  return (
    <OnboardingStatusBannerContainer
      container={container}
      marginVertical={marginVertical}
    >
      <OnboardingStatusBannerContent
        saves={data}
        openInNewWindow={openInNewWindow}
      />
    </OnboardingStatusBannerContainer>
  );
};
