import * as React from 'react';
import { headingSectiondepthContext } from './context';
import { useHeadingSectiondepth } from './hooks';
export function HeadingSectiondepthProvider(props) {
    var children = props.children;
    var currentDepth = useHeadingSectiondepth();
    return (React.createElement(headingSectiondepthContext.Provider, { value: currentDepth + 1 }, children));
}
export function HeadingSectionDepthSetProvider(props) {
    var depth = props.depth, children = props.children;
    return (React.createElement(headingSectiondepthContext.Provider, { value: depth }, children));
}
