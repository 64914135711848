/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import React from 'react';

const defaultOrderedListContainerStyle: ouiStyle.InStyle = {
  listStyleType: ouiStyle.Constants.ListStyleTypeProps.ordered,
  paddingLeft: 'double',
};

const defaultOrderedListContainerCss = ouiStyle.makeCss(
  defaultOrderedListContainerStyle,
);

export function DefaultOrderedListContainer(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  return <ol css={defaultOrderedListContainerCss}>{children}</ol>;
}
