import { dataStructure, AccountType } from '@goldwasserexchange/oblis-frontend-utils';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { Trans } from '@lingui/macro';
import * as React from 'react';
import ouiBase from '@goldwasserexchange/oui';
import { noIcon, yesIcon } from '../components/yesNoIcons';

export const FULL_INVESTMENT_MANAGMENT = 'fullInvestmentManagement';

const fullInvestmentManagementIcons = {
  [AccountType.RTO]: {
    icon: noIcon,
  },
  [AccountType.CONSEIL]: {
    icon: noIcon,
  },
  [AccountType.GESTION]: {
    icon: yesIcon,
  },
};

export const FullInvestmentManagmentIcon = (props: { accountType: dataStructure.accountType.listType }) => {
  const { accountType } = props;
  return fullInvestmentManagementIcons[accountType].icon;
};

export const FullInvestmentManagmentIconOnPrimaryDimension = () => {
  const accountType = ouiBase.utils.dimensions.useCurrentPrimaryDimension() as dataStructure.accountType.listType;
  return (
    <FullInvestmentManagmentIcon accountType={accountType} />
  );
};

const fullInvestmentManagmentLabel = (
  <Trans>
    Prise en charge complète des investissements
  </Trans>
);

export const fullInvestmentMangementTopModels = (
  <>
    <TopModel name={`TableSecondaryTitleCellContent-${FULL_INVESTMENT_MANAGMENT}`}>
      {fullInvestmentManagmentLabel}
    </TopModel>
    <TopModel name={`TableBodyRowCellContent--${FULL_INVESTMENT_MANAGMENT}`}>
      {FullInvestmentManagmentIconOnPrimaryDimension}
    </TopModel>
  </>
);
