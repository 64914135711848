import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { selectInputDefaultTopModelsObject } from '../../../../OUI/Inputs';
import {
  DefaultDomSelectInputListContainerPlaceHolder,
  DefaultDomSelectInputListMainContainer,
  DefaultDomSelectInputListButtonContainer,
  DefaultDomSelectInputListButtonContent,
  DefaultDomSelectInputListMapperPlaceHolder,
  DefaultDomSelectInputListMapperContainer,
  DefaultDomSelectInputListSearchInputPlaceHolder,
  DefaultDomSelectInputListSearchInputElement,
  DefaultDomSelectInputSelectInputListSearchInputIconPlaceHolder,
  DefaultDomSelectInputListSearchInputContainer,
  DefaultDomSelectInputListSearchInputIconContent,
  DefaultSelectInputListElementContainer,
  DefaultSelectedInputListElementSelectedBase,
  DefaultSelectInputListElementSelectedContainer,
  DefaultSelectListInputListElementButtonPlaceHolder,
  DefaultSelectInputListElementLabelContainer,
  SelectInputDomListElementButtonContainer,
} from './components';

import { CheckboxListInputDomListElementButtonContent } from '../checkbox';
import { DefaultUnorderedListWithNoMarkContainer } from '../../../List';

export const selectInputDomDefaultTopModelsObject = {
  ...selectInputDefaultTopModelsObject,
  SelectInputListContainer: (
    <TopModel name="SelectInputListContainer">
      {DefaultUnorderedListWithNoMarkContainer}
    </TopModel>
  ),
  SelectInputListContainerPlaceHolder: (
    <TopModel name="SelectInputListContainerPlaceHolder">
      {DefaultDomSelectInputListContainerPlaceHolder}
    </TopModel>
  ),
  SelectInputListMainContainer: (
    <TopModel name="SelectInputListContainerPlaceHolder">
      {DefaultDomSelectInputListMainContainer}
    </TopModel>
  ),
  SelectInputListMapperPlaceHolder: (
    <TopModel name="SelectInputListMapperPlaceHolder">
      {DefaultDomSelectInputListMapperPlaceHolder}
    </TopModel>
  ),
  SelectInputListMapperContainer: (
    <TopModel name="SelectInputListMapperContainer">
      {DefaultDomSelectInputListMapperContainer}
    </TopModel>
  ),
  SelectInputListSearchInputPlaceHolder: (
    <TopModel name="SelectInputListSearchInputPlaceHolder">
      {DefaultDomSelectInputListSearchInputPlaceHolder}
    </TopModel>
  ),
  SelectInputListSearchInputContainer: (
    <TopModel name="SelectInputListSearchInputContainer">
      {DefaultDomSelectInputListSearchInputContainer}
    </TopModel>
  ),
  SelectInputListSelectedIconPlaceHolder: (
    <TopModel name="SelectInputListSearchIconPlaceHolder">
      {DefaultDomSelectInputSelectInputListSearchInputIconPlaceHolder}
    </TopModel>
  ),
  SelectInputListSelectedIconContent: (
    <TopModel name="SelectInputListSearchIconContent">
      {DefaultDomSelectInputListSearchInputIconContent}
    </TopModel>
  ),
  SelectInputListSearchInputElement: (
    <TopModel name="SelectInputListSearchInputElement">
      {DefaultDomSelectInputListSearchInputElement}
    </TopModel>
  ),
  SelectInputListButtonContainer: (
    <TopModel name="SelectInputListContainerPlaceHolder">
      {DefaultDomSelectInputListButtonContainer}
    </TopModel>
  ),
  SelectInputListButtonContent: (
    <TopModel name="SelectInputListContainerPlaceHolder">
      {DefaultDomSelectInputListButtonContent}
    </TopModel>
  ),
  SelectInputListElementSelectedBase: (
    <TopModel name="SelectInputListElementSelectedBase">
      {DefaultSelectedInputListElementSelectedBase}
    </TopModel>
  ),
  SelectInputListElementSelectedContainer: (
    <TopModel name="SelectInputListElementSelectedContainer">
      {DefaultSelectInputListElementSelectedContainer}
    </TopModel>
  ),
  SelectInputListElementContainer: (
    <TopModel name="ElementContainer">
      {DefaultSelectInputListElementContainer}
    </TopModel>
  ),
  SelectInputListElementButtonPlaceHolder: (
    <TopModel name="InputListElementButtonPlaceHolder">
      {DefaultSelectListInputListElementButtonPlaceHolder}
    </TopModel>
  ),
  SelectInputListElementButtonContainer: (
    <TopModel name="SelectInputListElementButtonContainer">
      {SelectInputDomListElementButtonContainer}
    </TopModel>
  ),
  SelectInputListElementButtonContent: (
    <TopModel name="CheckboxListInputListElementButtonContent">
      {CheckboxListInputDomListElementButtonContent}
    </TopModel>
  ),
  SelectInputListElementLabelContainer: (
    <TopModel name="SelectInputListElementLabelContainer">
      {DefaultSelectInputListElementLabelContainer}
    </TopModel>
  ),
};
