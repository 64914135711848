import React from 'react';
import { Trans } from '@lingui/macro';
import ouiBase from '@goldwasserexchange/oui';
import {
  dataStructure, TAddType,
} from '@goldwasserexchange/oblis-frontend-utils';
import { useHookstate } from '@hookstate/core';
import { useUser } from '../utils';

const TAddCTitLabel = () => {
  const userState = useUser();
  const tAddTypeState = useHookstate(userState.T_ADD_type);
  if (tAddTypeState.get() === TAddType.MORAL) {
    return <Trans>Forme sociale</Trans>;
  }
  return <Trans>Titre</Trans>;
};

export const tAddCTitLabel = <TAddCTitLabel />;

export const tAddIdCTitValidatorLogic: ouiBase.utils.hookstate.FormValueFilterLogic<'requiredNumber' | 'nilCast'> = dataStructure.personType.logic.show.makeShowMoralField<'requiredNumber' | 'nilCast'>({
  visible: {
    result: 'requiredNumber',
  },
  invisible: {
    result: 'nilCast',
  },
});
