import * as React from 'react';
import { ActorApiMapper } from '../../../../../../components';
import { HistoryByCurrency } from '../../type'; import { useHistoryByCurrencyList } from '../../hooks';

const defaultLoadingList = ['EUR', 'USD'];

export const HistoryByCurrencyMapper = (props: React.PropsWithChildren<{
  filterPredicate?: (data: HistoryByCurrency) => boolean,
  limit?: number,
  loadingList?: any[],
}>) => {
  const {
    filterPredicate,
    loadingList = defaultLoadingList,
    limit,
    children,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useHistoryByCurrencyList({ filterPredicate });
  return (
    <ActorApiMapper
      data={data}
      isLoading={isLoading}
      error={error}
      loadingList={loadingList}
      limit={limit}
    >
      {children}
    </ActorApiMapper>
  );
};
