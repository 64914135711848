import { History, Update, createPath } from 'history';
import qs from 'query-string';
import { DateInterval } from './constants';
import { isValidDateInterval } from './utils';

export const accountsHistoryRouting = (sourceHistory: History, currentUpdate: Update) => {
  const update = {
    action: currentUpdate.action,
    location: {
      ...currentUpdate.location,
    },
  };
  const {
    location,
  } = update;
  const { search } = location;
  const searchParsed = qs.parse(search);
  const {
    interval,
  } = searchParsed;
  if (Array.isArray(interval)) {
    update.location.search = `?${qs.stringify({ ...searchParsed, interval: DateInterval.three_months })}`;
    sourceHistory.replace(createPath(update.location));
  } else if (interval == null) {
    update.location.search = `?${qs.stringify({ ...searchParsed, interval: DateInterval.three_months })}`;
  } else {
    const parsedInterval = parseInt(interval, 10);
    if (Number.isNaN(parsedInterval) || !isValidDateInterval(parsedInterval)) {
      update.location.search = `?${qs.stringify({ ...searchParsed, interval: DateInterval.three_months })}`;
      sourceHistory.replace(createPath(update.location));
    }
  }
  return update;
};
