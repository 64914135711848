import { TopModel } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import { TextInput } from '../../../../../../OUI-dom/Inputs';
import { CenteredButtonContainer } from '../../../../../CenteredButtonContainer';
import { tAddPhoneGsmLabel } from '../../../../../tAddPhone';
import { ForgotPasswordButton } from './forgotPasswordButton';
import { UserNamePasswordForm } from './form';
import { SignUpLink } from './signUpLink';
import { UserNamePasswordSubmitButton } from './submitButton';
import { PasswordInput } from '../../../passwordInput';
import { UserInfo } from './userInfo';
import { ChangeUser } from './changeUser';

export const UserNamePasswordInputContent = (props: React.PropsWithChildren<{
  withUserName?: boolean,
  withForgot?: boolean,
  withSignUp?: boolean,
  withChangeUser?: boolean,
  setWithUserName?: React.Dispatch<React.SetStateAction<boolean>>,
}>) => {
  const {
    withUserName = true,
    withForgot = false,
    withSignUp = false,
    withChangeUser = false,
    setWithUserName,
    children,
  } = props;
  return (
    <UserNamePasswordForm>
      {withChangeUser ? (<UserInfo />) : null}
      {withUserName ? (
        <TextInput
          fieldPath="auth.username"
        >
          <TopModel name="TextInputLabelContent">
            {tAddPhoneGsmLabel}
          </TopModel>
        </TextInput>
      ) : null}
      <PasswordInput />
      <CenteredButtonContainer
        paddingVertical="half"
      >
        <UserNamePasswordSubmitButton
          // we need to change the key to render a new component and avoid a hooks error
          key={withUserName ? 'withUserName' : 'withoutUsername'}
          withUserName={withUserName}
        >
          {children}
        </UserNamePasswordSubmitButton>
      </CenteredButtonContainer>
      {withForgot ? (
        <CenteredButtonContainer
          paddingVertical="half"
        >
          <ForgotPasswordButton />
        </CenteredButtonContainer>
      ) : null}
      {withChangeUser && setWithUserName ? (
        <CenteredButtonContainer
          paddingVertical="half"
        >
          <ChangeUser setWithUserName={setWithUserName} />
        </CenteredButtonContainer>
      ) : null}
      {withSignUp ? (
        <SignUpLink />
      ) : null}
    </UserNamePasswordForm>
  );
};
