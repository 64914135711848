import { useContext } from 'react';
import {
  sectionScrollRefCallbacksContext,
} from './context';

export const useSectionNavScrollRefCallbacks = () => {
  const refCallbacks = useContext(sectionScrollRefCallbacksContext);
  return refCallbacks;
};

export const useSectionNavScrollContainerRefCallback = () => {
  const [containerRefCallback] = useSectionNavScrollRefCallbacks();
  return containerRefCallback;
};

export const useListSectionNavScrollContainerRefCallback = () => {
  const [, listContainerRefCallback] = useSectionNavScrollRefCallbacks();
  return listContainerRefCallback;
};
