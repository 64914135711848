export const getNewsSectionWithDefault = (section: string | undefined) => {
  if (section == null || section === '') {
    return 'home';
  }
  return section;
};

export const getNewsSectionType = (section: string) => {
  if (section === 'home' || section === 'search') {
    return section;
  }
  return 'article';
};
