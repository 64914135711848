import ouiBase from '@goldwasserexchange/oui';
import * as React from 'react';
import { dependenciesFieldPathsContext } from './context';

export const InputDependenciesFieldPathsProvider = (
  props: React.PropsWithChildren<{
    dependenciesFieldPaths: string[],
  }>,
) => {
  const { dependenciesFieldPaths, children } = props;
  // the dependenciesFieldPaths prop uses a trick that could break the rule of hooks if it's length changed,
  // we need to do this to allow input depending on other inputs for validation to update
  // to avoid an hard error we memoize the array here and send a warning to the developper if the length changes
  const freezedDependenciesFieldPaths = ouiBase.utils.freeze.useFreezeArrayOnLength(
    dependenciesFieldPaths,
    'dependenciesFieldPaths',
  );
  return (
    <dependenciesFieldPathsContext.Provider
      value={freezedDependenciesFieldPaths}
    >
      {children}
    </dependenciesFieldPathsContext.Provider>
  );
};
