import * as React from 'react';
import { Trans } from '@lingui/macro';
import { dataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import { FilterCurrentUser } from '../../../../Sections/onboarding/form/constants';

export const tAddFiscalResidencySameAsResidencyLabel = (
  <>
    <FilterCurrentUser>
      <Trans>Votre pays de résidence est-il le même que votre pays de résidence fiscale ?</Trans>
    </FilterCurrentUser>
    <FilterCurrentUser isNegative>
      <Trans>Le pays de résidence de la personne est-il le même que son pays de résidence fiscale ?</Trans>
    </FilterCurrentUser>
  </>
);

export const tAddFiscalResidencySameAsResidencyValidatorLogic = dataStructure.T_ADD.fields.TYPE.logic.makeTAddIsNotMoralOrIgnored<'requiredBoolean' | 'nilCast'>({
  truthy: {
    result: 'requiredBoolean',
  },
  falsy: {
    result: 'nilCast',
  },
});
