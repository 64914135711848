import { dataStructure } from '@goldwasserexchange/oblis-frontend-utils';

export const makeQuestionnaireDone = (product: dataStructure.financialProducts.listType) => (
  [
    {
      target: 'checkRequired',
      cond: {
        type: 'formFilterLogic',
        logic: dataStructure.financialProductsKnowledge.logic.hasKnowledge.make.makeIsQuestionnaireCorrectLogic(
          product,
          {
            noQuestions: {
              result: false,
            },
            notSelected: {
              result: false,
            },
            notAnswered: {
              result: false,
            },
            success: {
              result: false,
            },
            failure: {
              result: true,
            },
            failureWithRetryAvailable: {
              result: true,
            },
            failureWithRetryAvailableOnRequired: {
              result: true,
            },
            retriedFailureOnRequired: {
              result: true,
            },
            truthy: {
              result: true,
            },
            falsy: {
              result: false,
            },
          },
        ),
      },
    },
    {
      target: 'success',
      cond: {
        type: 'formFilterLogic',
        logic: dataStructure.financialProductsKnowledge.logic.hasKnowledge.make.makeIsQuestionnaireCorrectLogic(
          product,
          {
            noQuestions: {
              result: false,
            },
            notSelected: {
              result: false,
            },
            notAnswered: {
              result: false,
            },
            success: {
              result: true,
            },
            failure: {
              result: false,
            },
            failureWithRetryAvailable: {
              result: false,
            },
            failureWithRetryAvailableOnRequired: {
              result: false,
            },
            retriedFailureOnRequired: {
              result: false,
            },
            truthy: {
              result: true,
            },
            falsy: {
              result: false,
            },
          },
        ),
      },
    },
    {
      target: 'done',
    }]
);
