import { dataStructure, AccountType } from '@goldwasserexchange/oblis-frontend-utils';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { Trans } from '@lingui/macro';
import * as React from 'react';
import ouiBase from '@goldwasserexchange/oui';
import { noIcon, yesIcon } from '../components/yesNoIcons';

export const INSTANT_ASSIST = 'instantAssist';

const instantAssistIcon = {
  [AccountType.RTO]: {
    icon: noIcon,
  },
  [AccountType.CONSEIL]: {
    icon: yesIcon,
  },
  [AccountType.GESTION]: {
    icon: yesIcon,
  },
};

export const InstantAssistIcon = (props: { accountType: dataStructure.accountType.listType }) => {
  const { accountType } = props;
  return instantAssistIcon[accountType].icon;
};

export const InstantAssistIconOnPrimaryDimension = () => {
  const accountType = ouiBase.utils.dimensions.useCurrentPrimaryDimension() as dataStructure.accountType.listType;
  return (
    <InstantAssistIcon accountType={accountType} />
  );
};

const instantAssistLabel = (
  <Trans>
    Assistance instantanée
  </Trans>
);

export const instantAssistTopModels = (
  <>
    <TopModel name={`TableSecondaryTitleCellContent-${INSTANT_ASSIST}`}>
      {instantAssistLabel}
    </TopModel>
    <TopModel name={`TableBodyRowCellContent--${INSTANT_ASSIST}`}>
      {InstantAssistIconOnPrimaryDimension}
    </TopModel>
  </>
);
