import { createContext } from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
export var listElementChildPaddingTopContext = createContext(ouiStyle.Constants.defaultPadding);
export var listElementChildPaddingRightContext = createContext(ouiStyle.Constants.defaultPadding);
export var listElementChildPaddingBottomContext = createContext(ouiStyle.Constants.defaultPadding);
export var listElementChildPaddingLeftContext = createContext(ouiStyle.Constants.defaultPadding);
export var listElementChildPaddingByDirection = {
    top: listElementChildPaddingTopContext,
    right: listElementChildPaddingRightContext,
    bottom: listElementChildPaddingBottomContext,
    left: listElementChildPaddingLeftContext,
};
