import * as React from 'react';
import {
  MergeOrder,
  TopModelMergeNameTransformed,
} from '@goldwasserexchange/react-topmodel';
import { inputIconContentDomDefaultTopModelsObject } from '../topModels';

export function DefaultIconContentDomTopModelsProvider(
  props: React.PropsWithChildren<{ order?: MergeOrder }>,
) {
  const { children, order } = props;
  return (
    <TopModelMergeNameTransformed
      topModels={inputIconContentDomDefaultTopModelsObject}
      order={order}
    >
      {children}
    </TopModelMergeNameTransformed>
  );
}
