import { isNil } from 'ramda';
import { useCallback } from 'react';
import { useFilterByPrimaryDimensionTAccTwrrPId } from '../../utils';
import { AccountTwrrs } from '../../type';
import { useAccountTwrrTransformApi } from '../api';

const makeExists = (field: 'T_ACC_TWRR_P.SUM_IN' | 'T_ACC_TWRR_P.SUM_OUT') => (v: AccountTwrrs | null | undefined) => !v || !isNil(v[field]);

export const useAccountsTwrrSumExist = (
  field: 'T_ACC_TWRR_P.SUM_IN' | 'T_ACC_TWRR_P.SUM_OUT',
) => {
  const filterFn = useFilterByPrimaryDimensionTAccTwrrPId();
  const transformFunction = useCallback(makeExists(field), [field]);
  const response = useAccountTwrrTransformApi({
    transformFunction,
    filterPredicate: filterFn,
  });
  return response;
};
