import { useFilterByTAccId } from '../../utils/tAcc/filterByTAccId';
import { PersonAccounts } from '../../type';
import { usePersonAccountTransformApi } from '../api';

const tCatIsGestion = (personAccount: PersonAccounts | null | undefined) => {
  const tCatShortcut = personAccount ? personAccount['T_CAT.SHORTCUT'] : '';
  return tCatShortcut === 'GESTION';
};

export const usePersonsAccountsTCatShortcutIsGestion = () => {
  const tAccFilter = useFilterByTAccId();
  const response = usePersonAccountTransformApi({
    transformFunction: tCatIsGestion,
    filterPredicate: tAccFilter,
  });
  return response;
};
