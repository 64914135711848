import { createContext } from 'react';
import {
  AnyEventObject, Interpreter,
} from 'xstate';
import { AuthMachineContext } from '../machine';
import { CognitoUserWithChallengeName } from '../type';

export type AuthSendType = Interpreter<AuthMachineContext, any, AnyEventObject>['send']

export const authMachineContext = createContext<null | [string, AuthSendType]>(null);

export const cognitoUserContext = createContext<CognitoUserWithChallengeName | null>(null);

export const placeIndexNameContext = createContext<string | null>(null);

export const singleTableNameContext = createContext<string | null>(null);

export const authErrorNameContext = createContext<string | null>(null);

export const authErrorMessageContext = createContext<string | null>(null);

export const codeDeliveryDetailsContext = createContext<AuthMachineContext['codeDeliveryDetails']>(null);
