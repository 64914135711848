import { useContext, useMemo } from 'react';
import { GetAccountsRatesParams } from '@goldwasserexchange/actor/rest-services';
import { useTAccId } from '../../../shared';
import { stringifyParams } from '../../../shared/utils/makeUrl';
import { accountsRatesParamsContext } from '../context';
import { useIsConnected } from '../../../../../../Components/Auth';

export const useAccountRatesDefaultParams = (params: Partial<GetAccountsRatesParams>): Partial<GetAccountsRatesParams> => {
  const contextParams = useContext(accountsRatesParamsContext);
  const tAccIdString = useTAccId();
  const currentTAccId = tAccIdString ? parseInt(tAccIdString, 10) : undefined;
  const tAccId = params['T_ACC.ID'] ?? contextParams['T_ACC.ID'] ?? currentTAccId;
  const defaultParams = useMemo(() => ({
    'T_ACC.ID': tAccId,
  }), [tAccId]);
  return defaultParams;
};

const useAccountRatesParams = (): GetAccountsRatesParams | null => {
  const defaultParams = useAccountRatesDefaultParams({});
  const tAccId = defaultParams['T_ACC.ID'];
  const isConnected = useIsConnected();
  if (tAccId == null || !isConnected) {
    return null;
  }
  return {
    'T_ACC.ID': tAccId,
  };
};

export const useAccountRatesStringifiedParams = () => {
  const contextParams = useAccountRatesParams();
  return contextParams !== null ? stringifyParams(contextParams) : null;
};
