import { isNil } from 'ramda';
import { AccountTwrrs } from '../../type';
import { useAccountTwrrsTransformApi } from '../api';

const transformFn = (d?: AccountTwrrs[]) => {
  if (d && d.length > 0) {
    return d.some((el) => !isNil(el['T_ACC_TWRR_P.SUM_IN']));
  }
  return false;
};

export const useAccountsTwrrHasSumIn = () => {
  const hasSumIn = useAccountTwrrsTransformApi({
    transformFunction: transformFn,
  });
  return hasSumIn;
};
