import React from 'react';
import { Trans } from '@lingui/macro';
import ouiBase from '@goldwasserexchange/oui';
import { dataStructure, TAddType } from '@goldwasserexchange/oblis-frontend-utils';

export const tAddMoralVatLabel = <Trans>Numéro de TVA / entreprise</Trans>;

export const tAddMoralVatHelpText = <Trans>Doit commencer par les 2 lettres du code pays</Trans>;

export const tAddMoralVatCodeValidatorLogic: ouiBase.utils.hookstate.FormValueFilterLogic<'vat' | 'nilCast'> = dataStructure.personType.logic.show.makeShowMoralField({
  visible: dataStructure.T_ADD.fields.TYPE.logic.makeTAddTypeFilterLogic({
    is: TAddType.MORAL,
    truthy: {
      result: 'vat',
    },
    falsy: {
      result: 'nilCast',
    },
  }),
  invisible: {
    result: 'nilCast',
  },
});
