import * as React from 'react';
import { ActorApiTable, ActorApiTableProps } from '../../../../../../components';
import { useBondsSameIssuerSAccIdListByCurrency } from '../../hooks';
import { useMakeLoadingIds } from '../../../../shared';

export const BondSameIssuerApiTable = (props: React.PropsWithChildren<React.PropsWithChildren<Pick<ActorApiTableProps, 'direction' | 'wrapping' | 'multiBody' | 'secondaryDimensionList' | 'topModels'>>>) => {
  const {
    direction,
    wrapping,
    multiBody,
    secondaryDimensionList,
    topModels,
    children,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useBondsSameIssuerSAccIdListByCurrency();
  const sAccIdsLoadingValues = useMakeLoadingIds(20);
  return (
    <ActorApiTable
      data={data ?? sAccIdsLoadingValues}
      isLoading={isLoading}
      error={error}
      loadingValue={sAccIdsLoadingValues}
      direction={direction}
      wrapping={wrapping}
      multiBody={multiBody}
      secondaryDimensionList={secondaryDimensionList}
      topModels={topModels}
    >
      {children}
    </ActorApiTable>
  );
};
