import { STypeGrp, UncastedOnboarding } from '@goldwasserexchange/oblis-frontend-utils';
import { StatusDisabledReasonMap } from './getter';

export const defaultQuestionnaireStatusDisabledMap: StatusDisabledReasonMap[] = [
  {
    correct: 'Validated',
    failure: 'Failed',
  },
];

export const questionnaireStatusDisabledReasonMaps: Partial<Record<keyof UncastedOnboarding['products'], StatusDisabledReasonMap[]>> = {
  [STypeGrp.OBLIGATIONS_PERP]: [
    ...defaultQuestionnaireStatusDisabledMap,
    {
      failure: 'BondFailed',
    },
    {
      failure: 'SubordinatedBondFailed',
    },
  ],
  [STypeGrp.OBLIGATIONS_SUB]: [
    ...defaultQuestionnaireStatusDisabledMap,
    {
      failure: 'BondFailed',
    },
  ],
  [STypeGrp.TRACKERS_MAT]: [
    ...defaultQuestionnaireStatusDisabledMap,
    {
      failure: 'TrackerFailed',
    },
  ],
};
