import * as React from 'react';
import ouiDom from '@goldwasserexchange/oui-dom';
import ouiStyle from '@goldwasserexchange/oui-style';
import {
  useFavoriteBonds,
  useToggleBondFavorite,
} from '../../../../utils';
import { useSingleTableNameContext } from '../../../../../Components/Auth';
import { LikeBondButtonIcon } from './components';

const InnerLikeBondButton = (props: {
  tableName: string,
  sAccId: number,
  ttl: number | undefined,
  isFavoriteWhileLoading?: boolean,
  display?: ouiStyle.InStyleWithMediaQueries['display'],
  justifyContent?: ouiStyle.InStyleWithMediaQueries['justifyContent'],
  alignItems?: ouiStyle.InStyleWithMediaQueries['alignItems'],
  backgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
  favoriteBackgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
  color?: ouiStyle.InStyle['color'],
  favoriteColor?: ouiStyle.InStyle['color'],
  fillColor?: ouiStyle.Constants.Colors,
  favoriteFillColor?: ouiStyle.Constants.Colors,
  hoverBackgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
  favoriteHoverBackgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
  iconSize?: string | number,
  paddingHorizontal?: ouiStyle.InStyleWithMediaQueries['paddingHorizontal'],
  paddingVertical?: ouiStyle.InStyleWithMediaQueries['paddingVertical'],
  paddingTop?: ouiStyle.InStyleWithMediaQueries['paddingTop'],
  paddingBottom?: ouiStyle.InStyleWithMediaQueries['paddingBottom'],
  iconTransform?: ouiStyle.InStyle['transform'],
  marginLeft?: ouiStyle.InStyleWithMediaQueries['marginLeft'],
  marginRight?: ouiStyle.InStyleWithMediaQueries['marginRight'],
  position?: ouiStyle.InStyleWithMediaQueries['position'],
  top?: ouiStyle.InStyleWithMediaQueries['top'],
  left?: ouiStyle.InStyleWithMediaQueries['left'],
  right?: ouiStyle.InStyleWithMediaQueries['right'],
  bottom?: ouiStyle.InStyleWithMediaQueries['bottom'],
}) => {
  const {
    tableName,
    sAccId,
    ttl,
    isFavoriteWhileLoading = false,
    display,
    justifyContent,
    alignItems,
    backgroundColor,
    favoriteBackgroundColor = backgroundColor,
    color,
    favoriteColor = color,
    hoverBackgroundColor,
    favoriteHoverBackgroundColor = hoverBackgroundColor,
    fillColor = ouiStyle.Constants.Colors.transparent,
    favoriteFillColor = favoriteColor,
    iconSize,
    paddingHorizontal = 'half',
    paddingVertical,
    paddingTop,
    paddingBottom,
    iconTransform,
    marginLeft,
    marginRight,
    position,
    top,
    left,
    right,
    bottom,
  } = props;
  const {
    data,
    mutate,
  } = useFavoriteBonds(tableName);
  const sAccIdString = `${sAccId}`;
  const isFavorite = data != null && !Number.isNaN(sAccId)
    ? data.includes(sAccIdString)
    : isFavoriteWhileLoading;
  const onClick = useToggleBondFavorite({
    tableName,
    sAccId,
    data,
    ttl,
    mutate,
  });
  return (
    <ouiDom.Button.Button
      type="button"
      onClick={onClick}
      display={display}
      justifyContent={justifyContent}
      alignItems={alignItems}
      backgroundColor={!isFavorite ? backgroundColor : favoriteBackgroundColor}
      color={!isFavorite ? color : favoriteColor}
      borderColor={ouiStyle.Constants.Colors.transparent}
      hoverBackgroundColor={!isFavorite ? hoverBackgroundColor : favoriteHoverBackgroundColor}
      iconContainer
      paddingHorizontal={paddingHorizontal}
      paddingVertical={paddingVertical}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      marginLeft={marginLeft}
      marginRight={marginRight}
      position={position}
      top={top}
      left={left}
      right={right}
      bottom={bottom}
    >
      <LikeBondButtonIcon
        isFavorite={isFavorite}
        color={color}
        favoriteColor={favoriteColor}
        fillColor={fillColor}
        favoriteFillColor={favoriteFillColor}
        size={iconSize}
        transform={iconTransform}
      />
    </ouiDom.Button.Button>
  );
};

export const LikeBondButton = (props: {
  sAccId: number,
  ttl: number | undefined,
  isFavoriteWhileLoading?: boolean,
  display?: ouiStyle.InStyleWithMediaQueries['display'],
  justifyContent?: ouiStyle.InStyleWithMediaQueries['justifyContent'],
  alignItems?: ouiStyle.InStyleWithMediaQueries['alignItems'],
  backgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
  favoriteBackgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
  color?: ouiStyle.InStyle['color'],
  favoriteColor?: ouiStyle.InStyle['color'],
  fillColor?: ouiStyle.Constants.Colors,
  favoriteFillColor?: ouiStyle.Constants.Colors,
  hoverBackgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
  favoriteHoverBackgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
  iconSize?: string | number,
  paddingHorizontal?: ouiStyle.InStyleWithMediaQueries['paddingHorizontal'],
  paddingVertical?: ouiStyle.InStyleWithMediaQueries['paddingVertical'],
  paddingTop?: ouiStyle.InStyleWithMediaQueries['paddingTop'],
  paddingBottom?: ouiStyle.InStyleWithMediaQueries['paddingBottom'],
  iconTransform?: ouiStyle.InStyle['transform'],
  marginLeft?: ouiStyle.InStyleWithMediaQueries['marginLeft'],
  marginRight?: ouiStyle.InStyleWithMediaQueries['marginRight'],
  position?: ouiStyle.InStyleWithMediaQueries['position'],
  top?: ouiStyle.InStyleWithMediaQueries['top'],
  left?: ouiStyle.InStyleWithMediaQueries['left'],
  right?: ouiStyle.InStyleWithMediaQueries['right'],
  bottom?: ouiStyle.InStyleWithMediaQueries['bottom'],
}) => {
  const {
    sAccId,
    ttl,
    isFavoriteWhileLoading,
    display,
    justifyContent,
    alignItems,
    backgroundColor,
    favoriteBackgroundColor,
    color,
    favoriteColor,
    fillColor,
    favoriteFillColor,
    hoverBackgroundColor,
    favoriteHoverBackgroundColor,
    iconSize,
    paddingHorizontal,
    paddingVertical,
    paddingTop,
    paddingBottom,
    iconTransform,
    marginLeft,
    marginRight,
    position,
    top,
    left,
    right,
    bottom,
  } = props;
  const tableName = useSingleTableNameContext();
  if (!tableName) {
    return null;
  }
  return (
    <InnerLikeBondButton
      tableName={tableName}
      sAccId={sAccId}
      ttl={ttl}
      display={display}
      alignItems={alignItems}
      justifyContent={justifyContent}
      backgroundColor={backgroundColor}
      favoriteBackgroundColor={favoriteBackgroundColor}
      color={color}
      favoriteColor={favoriteColor}
      hoverBackgroundColor={hoverBackgroundColor}
      favoriteHoverBackgroundColor={favoriteHoverBackgroundColor}
      fillColor={fillColor}
      favoriteFillColor={favoriteFillColor}
      iconSize={iconSize}
      paddingHorizontal={paddingHorizontal}
      paddingVertical={paddingVertical}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      iconTransform={iconTransform}
      isFavoriteWhileLoading={isFavoriteWhileLoading}
      marginLeft={marginLeft}
      marginRight={marginRight}
      position={position}
      top={top}
      left={left}
      right={right}
      bottom={bottom}
    />
  );
};
