import { useContext } from 'react';
import { currentSameIssuerSAccIdContext } from './context';

export const useCurrentSameIssuerSAccIdContext = () => {
  const handlers = useContext(currentSameIssuerSAccIdContext);
  return handlers;
};

export const useCurrentSameIssuerSAccId = () => {
  const [sAccId] = useCurrentSameIssuerSAccIdContext();
  return sAccId;
};

export const useCurrentSameIssuerSetSAccId = () => {
  const [, setSAccId] = useCurrentSameIssuerSAccIdContext();
  return setSAccId;
};
