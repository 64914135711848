import * as React from 'react';
import { TopModelMerge, TopModelNameTransformProvider } from '@goldwasserexchange/react-topmodel';
import ouiBase from '@goldwasserexchange/oui';
import { rippleEffectDefaultDomTopModelsObject } from './topmodels';
export var RippleEffect = function (props) {
    var sourceBackgroundColor = props.sourceBackgroundColor, children = props.children;
    return (React.createElement(TopModelNameTransformProvider, { transformFn: ouiBase.RippleEffect.rippleEffectPrefixer },
        React.createElement(TopModelMerge, { topModels: ouiBase.RippleEffect.rippleEffectDefaultTopModelsObject },
            React.createElement(TopModelMerge, { topModels: rippleEffectDefaultDomTopModelsObject },
                React.createElement(ouiBase.RippleEffect.RippleEffectStructure, { sourceBackgroundColor: sourceBackgroundColor }, children)))));
};
