import * as React from 'react';
import { useCallback } from 'react';
import { setSizeContext } from './context';

export const SetSizeContextProvider = (props: React.PropsWithChildren<{
  size?: number,
  setSize: any,
}>) => {
  const {
    size,
    setSize,
    children,
  } = props;
  const setSizeFn = useCallback(() => {
    if (setSize) {
      setSize((size ?? 0) + 1);
    }
  }, [size, setSize]);
  return (
    <setSizeContext.Provider value={setSizeFn}>
      {children}
    </setSizeContext.Provider>
  );
};
