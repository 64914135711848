import { compareAsc, startOfMonth } from 'date-fns';
import { getTAccTwrrPDate } from '../tAccTwrrP/utils/getDate';
import { AccountTwrrs } from '../../type';
import { useAccountTwrrsTransformApi } from '../api';

const transformFunction = (list: AccountTwrrs[] | undefined) => {
  if (list && list.length > 0) {
    const sortedList = list.sort((prev, next) => {
      const prevDate = getTAccTwrrPDate(prev);
      const nextDate = getTAccTwrrPDate(next);
      return compareAsc(prevDate, nextDate);
    });
    return startOfMonth(getTAccTwrrPDate(sortedList[1]));
  }
  return '';
};

export const useAccountTwrrStartDate = () => {
  const result = useAccountTwrrsTransformApi({
    transformFunction,
  });
  return result;
};
