import { createStore } from 'jotai';
import { createBrowserHistory } from 'history';
import {
  initialiseRouterListener, routerListener,
} from './router';
import { historyAtomListener } from './rootAtom';
import { headListener } from './head';
import { makeCookieFirstLoadedListener } from './cookieFirst';
import { shouldScrollOnPathChange } from './utils';
import { locationPathnameAtom } from './atoms';

let initialLoad = true;

export const createHistory = () => {
  const sourceHistory = createBrowserHistory();
  const jotaiStore = createStore();
  makeCookieFirstLoadedListener(jotaiStore)();
  let initializedUpdate = initialiseRouterListener(sourceHistory);
  initializedUpdate = historyAtomListener(jotaiStore)(initializedUpdate);
  initialLoad = false;
  headListener(initializedUpdate);
  sourceHistory.listen((currentUpdate) => {
    const previousPathname = jotaiStore.get(locationPathnameAtom);
    let update = routerListener(sourceHistory)(currentUpdate);
    update = historyAtomListener(jotaiStore)(update);
    const pathname = jotaiStore.get(locationPathnameAtom);
    const shouldScroll = shouldScrollOnPathChange({
      initialLoad,
      action: currentUpdate.action,
      previousPathname,
      pathname,
    });
    if (shouldScroll) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    headListener(update);
  });

  return {
    sourceHistory,
    jotaiStore,
  };
};

export const {
  sourceHistory: history,
  jotaiStore: store,
} = createHistory();
