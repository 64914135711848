import React from 'react';
import {
  dataStructure,
  UncastedDataStructure,
  STypeGrp,
} from '@goldwasserexchange/oblis-frontend-utils';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import ouiBase from '@goldwasserexchange/oui';
import { YesNoInput } from '../../../../../../../OUI-dom/Inputs/components/yesNo';
import {
  useGetFieldPathArray,
} from '../../../../../../../OUI/Inputs';

export * from './stateMachine';

type ExperienceYesNoProps = {
  sTypeGrp: STypeGrp,
};

const ExperienceLabelText = () => {
  const fieldPathArray = useGetFieldPathArray();
  const product = fieldPathArray[2] as dataStructure.financialProducts.listType;
  return <dataStructure.financialProductsExperience.labels.QuestionLabel financialProduct={product} />;
};

const labelText = (
  <TopModel name="RadioListInputLabelContent">
    {ExperienceLabelText}
  </TopModel>
);

export const ExperienceYesNo = (props: React.PropsWithChildren<ExperienceYesNoProps>): JSX.Element => {
  const {
    sTypeGrp,
    children,
  } = props;
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  return (
    <YesNoInput
      fieldPath={valueState.onboarding.products[sTypeGrp].experience.path.join('.')}
    >
      {labelText}
      {children}
    </YesNoInput>
  );
};
