import { mediaQuerizeProps } from '../../mediaQuery';
import { borderRadius } from '../../utils';
export function makeBorderRadius(inBorderRadius) {
    if (Array.isArray(inBorderRadius)) {
        var filledInBorderRadius = mediaQuerizeProps(inBorderRadius);
        var result = filledInBorderRadius.map(function (ibr) { return makeBorderRadius(ibr); });
        return result;
    }
    switch (inBorderRadius) {
        case 'none': {
            return '0';
        }
        case 'rounded': {
            return "".concat(borderRadius, "rem");
        }
        case 'full': {
            return '9999px';
        }
        default: {
            throw new Error("you shouldn't have a borderRadius of ".concat(inBorderRadius));
        }
    }
}
