/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { Ramp, useTopModelExistByName } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import { useModalZIndex } from './context/zIndex';
import { ModalIcon } from './icon';
import { ModalWrapper } from './wrapper';
import { ModalScrollContainer } from './scrollContainer';

const style: ouiStyle.InStyle = {
  position: 'relative',
  lines: 'full',
  widthLines: 'full',
  paddingHorizontal: 'simple',
  paddingBottom: 'simple',
  boxShadow: '0 0 10px 3px rgba(0, 0, 0, 0.1)',
};

export const ModalContainer = (props: React.PropsWithChildren<{
  wrapperWidthLines?: ouiStyle.InStyle['widthLines'],
  textAlign?: ouiStyle.InStyle['textAlign'],
  goBackOnClick?: React.MouseEventHandler<HTMLButtonElement>,
  hasBack?: boolean,
}>) => {
  const {
    wrapperWidthLines,
    textAlign,
    goBackOnClick,
    hasBack,
    children,
  } = props;
  const zIndex = useModalZIndex();
  const hasIcon = useTopModelExistByName('ModalIconContent');
  const css = React.useMemo(() => ouiStyle.mediaQuery(ouiStyle.makeCss({
    ...style,
    paddingTop: hasIcon ? 5 : 4,
    zIndex,
  })), [zIndex, hasIcon]);
  return (
    <ModalWrapper
      widthLines={wrapperWidthLines}
    >
      <div
        css={css}
      >
        <Ramp rampName="ModalCloseButton" />
        {hasBack ? (
          <Ramp
            rampName="ModalBackButton"
            onClick={goBackOnClick}
          />
        ) : null}
        {hasIcon ? <ModalIcon /> : null}
        <ModalScrollContainer
          textAlign={textAlign}
        >
          <Ramp
            rampName="ModalContent"
          />
          {children}
        </ModalScrollContainer>
      </div>
    </ModalWrapper>
  );
};
