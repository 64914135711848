import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useAccountDocumentArDocFileNameExtension } from '../../hooks';

export const AccountDocumentArDocDocumentType = () => {
  const {
    data,
  } = useAccountDocumentArDocFileNameExtension();
  return (
    <Trans>
      Document&#32;
      {data}
    </Trans>
  );
};
