var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { getTopModelFromChildren, RampWithNameTransform, TopModelMerge, } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
export function RippleEffectStructure(props) {
    var sourceBackgroundColor = props.sourceBackgroundColor, incomingChildren = props.children;
    var _a = __read(getTopModelFromChildren(incomingChildren), 2), children = _a[0], topModelChildren = _a[1];
    return (React.createElement(TopModelMerge, { topModels: topModelChildren },
        React.createElement(RampWithNameTransform, { rampName: "Base", sourceBackgroundColor: sourceBackgroundColor }, children)));
}
