import { useContext } from 'react';
import { bannerHeightContext } from './context';

export const useBannerHeightContext = () => {
  const bannerHeight = useContext(bannerHeightContext);
  return bannerHeight;
};

export const useBannerHeight = () => {
  const [bannerHeight] = useBannerHeightContext();
  return bannerHeight;
};

export const useSetBannerHeight = () => {
  const [, setBannerHeight] = useBannerHeightContext();
  return setBannerHeight;
};
