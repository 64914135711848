import { TAddType } from '@goldwasserexchange/oblis-frontend-utils';
import * as React from 'react';
import { ActorApiStringDisplay } from '../../../../../actor/components/display/string';
import { useLastSubmittedOnboardingsBySk } from '../../../../utils/dynamoDb/onboarding/get';

export const OnboardingStatusNames = (props: {
  withPending?: boolean,
}) => {
  const {
    withPending,
  } = props;
  const {
    data: onboardingSave,
    isLoading,
    error,
  } = useLastSubmittedOnboardingsBySk({ withPending });
  const rawMoralName = onboardingSave?.data?.moralPerson?.NAME_1?.trim();
  const moralName = rawMoralName !== '' ? rawMoralName : undefined;
  const names = moralName ?? onboardingSave?.data?.users
    ?.filter((user) => user.T_ADD_type === TAddType.TITULAR && user.FIRSTNAME.trim() !== '' && user.NAME_1.trim() !== '')
    ?.map((user) => `${user.FIRSTNAME} ${user.NAME_1}`)
    ?.join(' - ') ?? undefined;
  return (
    <ActorApiStringDisplay
      data={names}
      isLoading={isLoading}
      error={error}
    />
  );
};
