import { Placement } from '@popperjs/core';
import * as React from 'react';
import {
  BaseBuilderWithNameTransform,
  FocusWithHandlersSetContextProvider,
} from '../../../../../../../OUI/Inputs';
import { PopperInReferenceContextProvider, PopperReferenceWithInteractionContext } from '../../../../../../../OUI/utils';

export function InputHelpIconContext(props: React.PropsWithChildren<{
  popperPlacement?: Placement,
  fallbackPopperPlacements?: Placement[],
}>) {
  const {
    popperPlacement = 'bottom-end',
    fallbackPopperPlacements = ['top-end'],
    children,
  } = props;
  return (
    <PopperReferenceWithInteractionContext>
      <FocusWithHandlersSetContextProvider>
        {children}
      </FocusWithHandlersSetContextProvider>
      <PopperInReferenceContextProvider
        placement={popperPlacement}
        fallbackPlacements={fallbackPopperPlacements}
      >
        <BaseBuilderWithNameTransform rampName="InputHelpPopper" />
      </PopperInReferenceContextProvider>
    </PopperReferenceWithInteractionContext>
  );
}
