import ouiBase from '@goldwasserexchange/oui';
import * as React from 'react';
import { useFieldContent } from '../../../../context/content';

const useAutoSelectDefault = (autoSelect: boolean) => {
  const list = ouiBase.utils.dimensions.usePrimaryDimensionList();
  const selectedState = useFieldContent();
  const selected = selectedState.get();
  React.useEffect(() => {
    if ((selected === '' || list.indexOf(selected) === -1) && ((list.length > 0 && autoSelect) || list.length === 1)) {
      selectedState.set(list[0]);
    }
    if (selected !== '' && list.length === 0) {
      selectedState.set('');
    }
  }, [list, selectedState, selected]);
};

export const SelectInputAutoSelectProvider = (props: React.PropsWithChildren<{
  useAutoSelect?: (autoSelect: boolean) => void,
  autoSelect?: boolean,
}>) => {
  const {
    useAutoSelect = useAutoSelectDefault,
    autoSelect = false,
    children,
  } = props;
  useAutoSelect(autoSelect);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
