import ouiBase from '@goldwasserexchange/oui';
import { isNil } from 'ramda';
import { useCallback } from 'react';
import { AccountTwrrs } from '../../type';

export const filterByTAccTwrrPId = (TAccTwrrPId?: AccountTwrrs['T_ACC_TWRR_P.ID']) => (positionLine: AccountTwrrs) => !isNil(TAccTwrrPId) && positionLine['T_ACC_TWRR_P.ID'] === TAccTwrrPId;

export const useFilterByTAccTwrrPId = (TAccTwrrPId?: AccountTwrrs['T_ACC_TWRR_P.ID']) => {
  const filterFn = useCallback(filterByTAccTwrrPId(TAccTwrrPId), [TAccTwrrPId]);
  return filterFn;
};

export const useFilterByPrimaryDimensionTAccTwrrPId = () => {
  const id = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const parsedId = parseInt(id, 10);
  const validId = !Number.isNaN(parsedId) ? parsedId : undefined;
  const filterByTAccTwrrP = useFilterByTAccTwrrPId(validId);
  return filterByTAccTwrrP;
};
