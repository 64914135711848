import { Update } from 'history';
import { Locale } from '@goldwasserexchange/i18n';
import { HeadProps } from '../type';
import { splitPathname } from '../../utils';
import { isLocale } from '../../router';

export const makeLanguageHeadProps = (currentUpdate: Update, currentHeadProps: HeadProps) => {
  const headProps = {
    ...currentHeadProps,
  };
  const {
    location,
  } = currentUpdate;
  const {
    pathname,
  } = location;
  const splittedPathname = splitPathname(pathname);
  const language = splittedPathname.at(0) ?? Locale.FR;
  headProps.language = isLocale(language) ? language : Locale.FR;
  return headProps;
};
