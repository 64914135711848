import {
  Bond, BondFrequency, BondProps, DayCountConvention,
} from '@goldwasserexchange/common/lib/bond';
import { startOfDay } from 'date-fns';
import memoizee from 'memoizee';

const createBond = (props: BondProps) => new Bond(props);

export const memoCreateBond = memoizee(createBond, {
  normalizer: (args) => {
    const [props] = args;
    const {
      settlement,
      maturity,
      rate,
      redemption,
      frequency,
      convention,
      issue,
    } = props;
    return `${startOfDay(settlement ?? new Date())}~${maturity.toISOString()}~${rate}~${redemption ?? 100}~${frequency ?? BondFrequency.ANNUAL}~${convention ?? DayCountConvention.US_30_360}~${issue}`;
  },
});

const getYield = (bond: Bond, price: number) => bond.yield(price);

export const memoGetYield = memoizee(getYield);

const getPrice = (bond: Bond, yld: number) => bond.price(yld);

export const memoGetPrice = memoizee(getPrice);
