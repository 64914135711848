import { msg } from '@lingui/macro';
import getRatingDescriptionSP from 'get-credit-rating-description-sp';
import { I18n, MessageDescriptor } from '@lingui/core';
import { useLingui } from '@lingui/react';
import { useCallback } from 'react';
import { AccountPosition } from '../../type';
import { useAccountPositionsTransformApi } from '../api';

type InvestmentGrades = 'Investment grade' | 'High-yield' | 'Not rated';

type GraphData = {
  x: string,
  y: number,
}

const descriptions: Record<InvestmentGrades, MessageDescriptor> = {
  'Investment grade': msg`Investment grade`,
  'High-yield': msg`High-yield`,
  'Not rated': msg`Sans rating`,
};

export const accountPositionsSumPctByRatingSpLoadingValues = (i18n: I18n): GraphData[] => Object.values(descriptions).map((description) => ({
  x: i18n._(description),
  y: 100,
}));

const makeSumPctByRatingSP = (i18n: I18n) => (positions: AccountPosition[] | undefined): GraphData[] => {
  if (!positions) {
    return [];
  }
  return Object.values(positions.reduce((acc, position) => {
    const rating = position['GES_RAT_VAL_SP.SHORTCUT'] || 'NR';
    const ratingDescription: InvestmentGrades = getRatingDescriptionSP.getGradeDescription(rating);
    const translatedRatingDescription = i18n._(descriptions[ratingDescription]);
    return ({
      ...acc,
      [translatedRatingDescription]: {
        x: translatedRatingDescription,
        y: (acc[translatedRatingDescription] || { y: 0 }).y + position['GES_ESTIM_D.PC_PORT_INT'],
      },
    });
  }, {}));
};

export const useAccountPositionsSumPctByRatingSp = () => {
  const {
    i18n,
  } = useLingui();
  const sumPctByRatingSP = useCallback((positions: AccountPosition[] | undefined) => makeSumPctByRatingSP(i18n)(positions), [i18n]);
  const sumPctByRatingSpApiResponse = useAccountPositionsTransformApi({
    transformFunction: sumPctByRatingSP,
  });
  return sumPctByRatingSpApiResponse;
};
