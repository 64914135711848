import { useContext } from 'react';
import ouiBase from '@goldwasserexchange/oui';
import { highlightContext } from './context';

export const useCurrentHighlight = () => {
  const currentHighlight = useContext(highlightContext);
  return currentHighlight;
};

export const useIsHighlighted = () => {
  const currentHighlight = useCurrentHighlight();
  const currentDimension = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  return currentHighlight === currentDimension;
};
