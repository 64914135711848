import {
  useFilterByPrimaryDimensionTAccTwrrPId,
} from '../../utils';
import { getTAccTwrrPDate } from '../tAccTwrrP/utils/getDate';
import { AccountTwrrs } from '../../type';
import { useAccountTwrrTransformApi } from '../api';

const transformFunction = (el: AccountTwrrs | null | undefined) => (el ? getTAccTwrrPDate(el) : '');

export const useAccountTwrrDate = () => {
  const filterPredicate = useFilterByPrimaryDimensionTAccTwrrPId();
  const result = useAccountTwrrTransformApi({
    filterPredicate,
    transformFunction,
  });
  return result;
};
