import { useHookstate } from '@hookstate/core';
import { dataStructure, UncastedDataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import ouiBase from '@goldwasserexchange/oui';

export function useFieldIsCorrectedByFieldPath(fieldPath: string) {
  const [,, answerKey] = fieldPath.split('.');
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const questionKey = answerKey.replace(
    dataStructure.financialProductsKnowledge.pathUtils.ANSWER_SUFFIX,
    '',
  );
  const isCorrectedKey = dataStructure.financialProductsKnowledge.pathUtils.makeIsCorrected(questionKey);
  return useHookstate(valueState.onboarding.knowledgeQuestionnaire[isCorrectedKey]);
}
