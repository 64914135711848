import * as React from 'react';
import { ActorApiNumberDisplayCurrency } from '../../../../../../components/display/number/currency';
import {
  useAccountPositionsHistoryAmountHieDcByHisMvtId,
  useAccountPositionsHistoryCCurrencyCodeByHisMvtId,
} from '../../hooks';

export const AccountsPositionsHistoryHisMvtAmountHieDc = () => {
  const {
    data,
    isLoading,
    error,
  } = useAccountPositionsHistoryAmountHieDcByHisMvtId();
  const {
    data: currencyData,
    isLoading: currencyIsLoading,
    error: currencyError,
  } = useAccountPositionsHistoryCCurrencyCodeByHisMvtId();
  return (
    <ActorApiNumberDisplayCurrency
      data={data}
      isLoading={isLoading}
      error={error}
      currencyData={currencyData}
      currencyIsLoading={currencyIsLoading}
      currencyError={currencyError}
    />
  );
};
