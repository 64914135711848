import * as React from 'react';
import { stickyPositionHandlersContext } from './context';
import { StickyPosition } from './utils';

export function StickyPositionHandlersContextProvider(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  const stickyPositionHandlers = React.useState<StickyPosition>(
    StickyPosition.noSelection,
  );
  return (
    <stickyPositionHandlersContext.Provider value={stickyPositionHandlers}>
      {children}
    </stickyPositionHandlersContext.Provider>
  );
}
