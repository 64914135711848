/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import ouiDom from '@goldwasserexchange/oui-dom';
import { useAtomValue } from 'jotai';
import * as animationController from '../../animation';
import { ValidSections, pathnameUrlLanguageAtom } from '../../../../history';
import { useZIndex } from '../../../../OUI/Form';

export const LogoContainer = (props: React.PropsWithChildren<{
}>) => {
  const {
    children,
  } = props;
  const notScrolled = animationController.useAnimationLogoExpandedSpringValue();
  const style = {
    width: notScrolled.to((v) => `${245 + (v * (245 / 2))}px`),
    height: notScrolled.to((v) => `${60 + (v * (60 / 2))}px`),
  };
  const zIndex = useZIndex() - 3;
  const language = useAtomValue(pathnameUrlLanguageAtom);
  return (
    <ouiDom.A.A
      to={`/${language}/${ValidSections.HOME}`}
      paddingHorizontal="simple"
      paddingVertical="half"
      fontSize="xm"
      lineFactor={2}
      textDecoration="none"
      color={ouiStyle.Constants.Colors.accent}
      transformOrigin={ouiStyle.Constants.TransformOriginProp.topLeft}
      zIndex={zIndex}
      animatedValues={style}
    >
      {children}
    </ouiDom.A.A>
  );
};

export default LogoContainer;
