import { createContext } from 'react';
import warning from 'warning';
import { StickyPosition } from './utils';

export const stickyPositionHandlersContext = createContext<[StickyPosition, React.Dispatch<React.SetStateAction<StickyPosition>>]>(
  [
    StickyPosition.noSelection,
    () => {
      warning(true, 'you are not in the sticky position context');
    },
  ],
);
