import { useCallback } from 'react';
import { KeyedMutator } from 'swr';
import { useAuthMachineSend, useIsConnected } from '../../../../Components/Auth';
import { SignInModalName } from '../../../../Components/Auth/components/signinModal';
import { useSetCurrentModalValue } from '../../../../Components/Modal';
import { useCognitoUserValidAccounts } from '../../../../Components/Sections/online/selectedAccount';
import { addFavoriteWithMutate } from './addFavorite';
import { removeFavoriteWithMutate } from './removeFavorite';

export const useToggleBondFavorite = (props: {
  tableName: string,
  sAccId: number,
  ttl: number | undefined,
  data: string[] | undefined,
  mutate: KeyedMutator<string[]>,
}) => {
  const {
    tableName,
    sAccId,
    ttl,
    data,
    mutate,
  } = props;
  const isConnected = useIsConnected();
  const accounts = useCognitoUserValidAccounts();
  const notConnectedOrNoAccounts = !(accounts.length > 0 && isConnected);
  const send = useAuthMachineSend();
  const openSignInModal = useSetCurrentModalValue(SignInModalName);
  const toggle = useCallback(async () => {
    const sAccIdString = `${sAccId}`;
    const isFavorite = (data ?? []).includes(sAccIdString);
    if (notConnectedOrNoAccounts) {
      send('SIGN_IN');
      openSignInModal();
    } else if (isFavorite) {
      removeFavoriteWithMutate({
        data,
        tableName,
        sAccId,
        mutate,
      });
    } else {
      addFavoriteWithMutate({
        data,
        tableName,
        sAccId,
        mutate,
        ttl,
      });
    }
  }, [
    tableName,
    sAccId,
    data,
    mutate,
    notConnectedOrNoAccounts,
    send,
    ttl,
  ]);
  return toggle;
};
