import * as React from 'react';
import { Accounts } from '../../../accounts/enum';
import { AccountHistoryArDocDocumentType } from '../../../accounts/history';
import { AccountDocumentArDocDocumentType } from '../../../accounts';
import { AccountPositionsHistoryArDocDocumentType } from '../../../accounts/positionsHistory/components/arDoc';

export const ArDocDocumentType = <Service extends Accounts.History | Accounts.Documents | Accounts.PositionsHistory>(
  props: {
    service: Service,
  },
) => {
  const {
    service,
  } = props;
  if (service === Accounts.History) {
    return <AccountHistoryArDocDocumentType />;
  }
  if (service === Accounts.Documents) {
    return <AccountDocumentArDocDocumentType />;
  }
  if (service === Accounts.PositionsHistory) {
    return <AccountPositionsHistoryArDocDocumentType />;
  }
  return null;
};
