import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { useBondListSAccOblEndDate, useBondsListIsRedacted } from '../../hooks';
import { ActorApiDateDisplay } from '../../../../../../components/display/date';
import { useOuiStyleMediaContextByIndex } from '../../../../../../../Components/MediaContext';

export const BondListSAccOblEndDate = (props: React.PropsWithChildren<{
  format?: string | undefined,
  withRedactedIcon?: boolean,
  lockBackgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
  lockWidthLines?: ouiStyle.InStyleWithMediaQueries['widthLines'],
  lockContainer?: ouiStyle.InStyleWithMediaQueries['container'],
  lockPaddingTop?: ouiStyle.InStyleWithMediaQueries['paddingTop'],
  lockPaddingBottom?: ouiStyle.InStyleWithMediaQueries['paddingBottom'],
  lockPaddingLeft?: ouiStyle.InStyleWithMediaQueries['paddingLeft'],
  lockPaddingRight?: ouiStyle.InStyleWithMediaQueries['paddingRight'],
  lockAlignItems?: ouiStyle.InStyleWithMediaQueries['alignItems'],
  LockChild?: () => JSX.Element | null,
}>) => {
  const {
    format,
    withRedactedIcon,
    lockBackgroundColor,
    lockWidthLines,
    lockContainer,
    lockPaddingTop,
    lockPaddingBottom,
    lockPaddingLeft,
    lockPaddingRight,
    lockAlignItems,
    LockChild,
    children = '∞',
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useBondListSAccOblEndDate();
  const {
    data: redacted = false,
    isLoading: redactedIsLoading,
  } = useBondsListIsRedacted('S_ACC.INT_RATE');
  return data === '∞' && !redacted
    ? (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {children}
      </>
    )
    : (
      <ActorApiDateDisplay
        data={data}
        isLoading={isLoading || redactedIsLoading}
        error={error}
        isBlocked={redacted}
        format={format}
        formatMq={false}
        withRedactedIcon={withRedactedIcon}
        lockBackgroundColor={lockBackgroundColor}
        lockWidthLines={lockWidthLines}
        lockContainer={lockContainer}
        lockPaddingTop={lockPaddingTop}
        lockPaddingBottom={lockPaddingBottom}
        lockPaddingLeft={lockPaddingLeft}
        lockPaddingRight={lockPaddingRight}
        lockAlignItems={lockAlignItems}
        LockChild={LockChild}
      />
    );
};

export const BondListSAccOblEndDateWithMediaQueryRedactedIcon = (props: React.PropsWithChildren<{
  mediaQueryIndex: number,
  withRedactedIcon?: boolean,
  format?: string | undefined,
  lockWidthLines?: ouiStyle.InStyleWithMediaQueries['widthLines'],
  lockContainer?: ouiStyle.InStyleWithMediaQueries['container'],
  lockPaddingTop?: ouiStyle.InStyleWithMediaQueries['paddingTop'],
  lockPaddingBottom?: ouiStyle.InStyleWithMediaQueries['paddingBottom'],
  lockPaddingLeft?: ouiStyle.InStyleWithMediaQueries['paddingLeft'],
  lockPaddingRight?: ouiStyle.InStyleWithMediaQueries['paddingRight'],
  lockAlignItems?: ouiStyle.InStyleWithMediaQueries['alignItems'],
  LockChild?: () => JSX.Element | null,
}>) => {
  const {
    mediaQueryIndex,
    withRedactedIcon,
    format,
    lockWidthLines,
    lockContainer,
    lockPaddingTop,
    lockPaddingBottom,
    lockPaddingLeft,
    lockPaddingRight,
    lockAlignItems,
    LockChild,
    children,
  } = props;
  const up = useOuiStyleMediaContextByIndex(mediaQueryIndex);
  return (
    <BondListSAccOblEndDate
      withRedactedIcon={withRedactedIcon || !up}
      format={format}
      lockWidthLines={lockWidthLines}
      lockContainer={lockContainer}
      lockPaddingTop={lockPaddingTop}
      lockPaddingBottom={lockPaddingBottom}
      lockPaddingLeft={lockPaddingLeft}
      lockPaddingRight={lockPaddingRight}
      lockAlignItems={lockAlignItems}
      LockChild={LockChild}
    >
      {children}
    </BondListSAccOblEndDate>
  );
};
