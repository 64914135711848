export const requiredError = 'required';

export const nilError = 'notNil';

export const booleanError = 'boolean';

export const booleanIsTrueError = 'booleanIsTrue';

export const booleanIsFalseError = 'booleanIsFalse';

export const euroDateError = 'notAEuroDate';

export const dateMinorError = 'dateMinorError';

export const dateNotMinorError = 'dateNotMinorError';

export const notInThePastError = 'notInThePast';

export const notInTheFutureError = 'notInTheFuture';

export const notANumberError = 'notANumber';

export const numberMinError = 'numberMin';

export const numberMinRefError = 'numberMinRef';

export const numberMaxError = 'numberMax';

export const numberMaxRefError = 'numberMaxRef';

export const numberMoreThanError = 'numberMoreThan';

export const numberMoreThanRefError = 'numberMoreThanRef';

export const numberLessThanError = 'numberLessThan';

export const numberLessThanRefError = 'numberLessThanRef';

export const numberPositiveError = 'numberPositive';

export const numberNegativeError = 'numberNegative';

export const notAStringError = 'notAString';

export const minError = 'min';

export const maxError = 'max';

export const lengthError = 'length';

export const onlyNumbersError = 'onlyNumbers';

export const hasAtLeastOneDigitError = 'hasNoDigit';

export const hasAtLeastOneLowerCaseError = 'hasNoLowerCase';

export const hasAtLeastOneUpperCaseError = 'hasNoUpperCase';

export const hasAtLeastOneSpecialCharError = 'hasNoSpecialChar';

export const phoneError = 'notAPhoneNumber';

export const emailError = 'notAnEmail';

export const equalsRefError = 'notEqual';

export const equalsError = 'equal';

export const wrongLeiFormatError = 'WrongLeiFormat';

export const invalidLeiError = 'invalidLei';

export const tvaNumberError = 'NotAValidVATNumber';

export const nationalRegisterError = 'NotAValidNationalRegisterNumber';

export const nationalRegisterAndBirthdateMismatch = 'birthdateRegisterNumberMismatch';

export const invalidBirthdateError = 'invalidBirthdateErrorName';

export const multiChoiceAtLeastOne = 'multiChoiceAtLeastOne';

export const invalidDurabilityError = 'invalidDurabilityError';
