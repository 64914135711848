import React from 'react';
import { Trans } from '@lingui/macro';
import ouiStyle from '@goldwasserexchange/oui-style';
import ouiDom from '@goldwasserexchange/oui-dom';

export const tAddTNatureLrpLabel = <Trans>En quelle qualité la personne identifiée ci-dessous intervient-elle sur le compte ?</Trans>;

export const tAddTNatureLrpInfoPopper = (
  <Trans>
    <div>
      <p>
        <ouiDom.TextSnippet.TextSnippet
          fontWeight={ouiStyle.Constants.FontWeightProp.bold}
        >
          Titulaire&#32;
        </ouiDom.TextSnippet.TextSnippet>
        : choisissez cette option si vous êtes seul titulaire du compte ou que vous le partagez avec votre époux/cohabitant légal
      </p>
      <p>
        <ouiDom.TextSnippet.TextSnippet
          fontWeight={ouiStyle.Constants.FontWeightProp.bold}
        >
          Usufruitier&#32;
        </ouiDom.TextSnippet.TextSnippet>
        : choisissez cette option si vous disposez d'un droit d'usufruit sur les avoirs qui seront inscrits au crédit du compte à ouvrir. Plusieurs personnes peuvent être enregistrées en qualité d'usufruitier ;
      </p>
      <p>
        <ouiDom.TextSnippet.TextSnippet
          fontWeight={ouiStyle.Constants.FontWeightProp.bold}
        >
          Nu-propriétaire&#32;
        </ouiDom.TextSnippet.TextSnippet>
        : choisissez cette option si vous êtes nu-propriétaire des avoirs qui seront inscrits au crédit du compte à ouvrir. Plusieurs personnes peuvent être enregistrées en qualité de nu-propriétaire ;
      </p>
      <p>
        <ouiDom.TextSnippet.TextSnippet
          fontWeight={ouiStyle.Constants.FontWeightProp.bold}
        >
          Indivisaire&#32;
        </ouiDom.TextSnippet.TextSnippet>
        : choisissez cette option si vous partager la propriété des avoirs qui seront inscrits au crédit du compte avec une ou plusieurs personnes qui ne sont pas votre époux/cohabitant légal. Plusieurs personnes peuvent être enregistrées en qualité d'indivisaire ;
      </p>
      <p>
        <ouiDom.TextSnippet.TextSnippet
          fontWeight={ouiStyle.Constants.FontWeightProp.bold}
        >
          Titulaire incapable&#32;
        </ouiDom.TextSnippet.TextSnippet>
        : choisissez cette option si le titulaire du compte est une personne ne disposant pas de la capacité juridique (mineur, administration provisoire, autre cause d'incapacité)
      </p>
      <p>
        <ouiDom.TextSnippet.TextSnippet
          fontWeight={ouiStyle.Constants.FontWeightProp.bold}
        >
          Représentant légal&#32;
        </ouiDom.TextSnippet.TextSnippet>
        : choisissez cette option si vous êtes le représentant légal d'un titulaire incapable (parent d'un titulaire mineur);
      </p>
    </div>
  </Trans>
);
