import { identity } from 'ramda';
import * as React from 'react';
import { LocalRifmArgs, rifmContext } from './context';

export const RifmContextProvider = (props: React.PropsWithChildren<LocalRifmArgs>) => {
  const {
    accept = /./g,
    format = identity,
    replace,
    append,
    mask,
    children,
  } = props;
  const rifm = React.useMemo(() => ({
    accept,
    format,
    replace,
    append,
    mask,
  }), [
    accept,
    format,
    replace,
    append,
    mask,
  ]);
  return (
    <rifmContext.Provider value={rifm}>
      {children}
    </rifmContext.Provider>
  );
};
