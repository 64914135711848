/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const toolBarListStyle: ouiStyle.InStyle = {
  listStyleType: ouiStyle.Constants.ListStyleTypeProps.none,
  display: ouiStyle.Constants.DisplayProp.flex,
  flexBasis: ouiStyle.Constants.FlexBasisProp.auto,
  flexGrow: true,
  flexShrink: true,
  lines: 'full',
  justifyContent: ouiStyle.Constants.JustifyContentProp.end,
};

const toolBarListCss = ouiStyle.makeCss(toolBarListStyle);

export const ToolBarList = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  return <ul css={toolBarListCss}>{children}</ul>;
};

export default ToolBarList;
