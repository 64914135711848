/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, keyframes } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { LockRedacted } from '../../../../Components/lockRedacted';
import { useActorApiLoading } from '../../context';
import { useUnlockRedactedOnClick } from './hooks';

const loadingKeyFrames = keyframes`
  0% {
    transform: translate3d(-100%,0,0);
  }

  100% {
    transform: translate3d(200%,0,0)
  }
`;

const maskCss = {
  position: 'absolute' as const,
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  background: `linear-gradient(to right, ${ouiStyle.Constants.Colors.fade} 10%, ${ouiStyle.Constants.Colors.fadeDarker} 50%, ${ouiStyle.Constants.Colors.fade} 100%)`,
  animationDuration: '1.25s',
  animationFillMode: 'forwards',
  animationIterationCount: 'infinite',
  animationTimingFunction: 'linear',
  animationName: loadingKeyFrames,
};

const Mask = () => (
  <span
    css={maskCss}
  />
);

const baseStyle = {
  position: 'relative' as const,
};

const baseBlurStyle = {
  color: ouiStyle.Constants.Colors.inherit,
  backgroundColor: ouiStyle.Constants.Colors.inherit,
};

export const LoadingMask = (props: React.PropsWithChildren<{
  isBlocked?: boolean,
  withRedactedIcon?: boolean,
  lockBackgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
  lockWidthLines?: ouiStyle.InStyleWithMediaQueries['widthLines'],
  lockContainer?: ouiStyle.InStyleWithMediaQueries['container'],
  lockPaddingTop?: ouiStyle.InStyleWithMediaQueries['paddingTop'],
  lockPaddingBottom?: ouiStyle.InStyleWithMediaQueries['paddingBottom'],
  lockPaddingLeft?: ouiStyle.InStyleWithMediaQueries['paddingLeft'],
  lockPaddingRight?: ouiStyle.InStyleWithMediaQueries['paddingRight'],
  lockAlignItems?: ouiStyle.InStyleWithMediaQueries['alignItems'],
  LockChild?: () => JSX.Element | null,
}>) => {
  const {
    isBlocked = false,
    withRedactedIcon = false,
    lockBackgroundColor,
    lockWidthLines,
    lockContainer,
    lockPaddingTop,
    lockPaddingBottom,
    lockPaddingLeft,
    lockPaddingRight,
    lockAlignItems,
    LockChild,
    children,
  } = props;
  const loading = useActorApiLoading();
  const loadingMaskCss = React.useMemo(() => ({
    ...(loading ? {
      ...baseStyle,
      display: 'inline-block',
      verticalAlign: 'top',
      color: ouiStyle.Constants.Colors.transparent,
      backgroundColor: ouiStyle.Constants.Colors.fade,
      overflow: 'hidden',
      userSelect: 'none' as const,
    } : baseStyle),
    ...(isBlocked ? { cursor: 'pointer' } : {}),
  }), [loading, isBlocked]);
  const blurMaskCss = React.useMemo(() => (isBlocked ? {
    ...baseBlurStyle,
    filter: isBlocked ? 'blur(4px)' : undefined,
    userSelect: isBlocked ? 'none' as const : undefined,
  } : baseBlurStyle), [isBlocked]);
  const onClick = useUnlockRedactedOnClick();
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <span
      css={loadingMaskCss}
      onClick={isBlocked && withRedactedIcon ? onClick : undefined}
      translate="no"
    >
      {isBlocked && withRedactedIcon ? (
        <LockRedacted
          backgroundColor={lockBackgroundColor}
          widthLines={lockWidthLines}
          container={lockContainer}
          paddingTop={lockPaddingTop}
          paddingBottom={lockPaddingBottom}
          paddingLeft={lockPaddingLeft}
          paddingRight={lockPaddingRight}
          alignItems={lockAlignItems}
          LockChild={LockChild}
        />
      ) : null}
      <span css={blurMaskCss}>
        {loading ? <Mask /> : null}
        {children}
      </span>
    </span>
  );
};
