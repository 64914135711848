import { useContext } from 'react';
import { inputContainerRefContext } from './context';
import { useClickOutside } from '../../../../utils';

export function useInputContainerRef() {
  const inputContainerRef = useContext(inputContainerRefContext);
  return inputContainerRef;
}

export function useInputContainerOnOutsideClick(
  callback: ((ev: MouseEvent) => void) | undefined,
) {
  const inputContainerRef = useInputContainerRef();
  useClickOutside(inputContainerRef, callback);
}
