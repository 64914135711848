/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiBase from '@goldwasserexchange/oui';
import ouiStyle from '@goldwasserexchange/oui-style';
import { RampWithNameTransform } from '@goldwasserexchange/react-topmodel';
import React from 'react';

export const defaultUnorderedListElementContainerStyle: ouiStyle.InStyle = {
  display: ouiStyle.Constants.DisplayProp.listItem,
  listStyleType: ouiStyle.Constants.ListStyleTypeProps.unordered,
};

const defaultUnorderedListElementContainerCss = ouiStyle.makeCss(
  defaultUnorderedListElementContainerStyle,
);

export function DefaultUnorderedListElementContainer(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  return <li css={defaultUnorderedListElementContainerCss}>{children}</li>;
}

export const defaultUnorderedListElementWithNoMarkContainerStyle: ouiStyle.InStyle = {
  display: ouiStyle.Constants.DisplayProp.block,
  listStyleType: ouiStyle.Constants.ListStyleTypeProps.none,
};

const defaultUnorderedListElementWithNoMarkContainerCss = ouiStyle.makeCss(
  defaultUnorderedListElementWithNoMarkContainerStyle,
);

export function DefaultUnorderedListElementWithNoMarkContainer(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  return (
    <li css={defaultUnorderedListElementWithNoMarkContainerCss}>{children}</li>
  );
}

export function DefaultUnorderedListElementWithColoredMarkContainer(
  props: React.PropsWithChildren<{
    color: ouiStyle.InStyle['color'],
    fontSize: ouiStyle.InStyle['fontSize'],
    bulletColor: ouiStyle.InStyle['color'],
  }>,
) {
  const {
    color,
    fontSize,
    bulletColor,
    children,
  } = props;
  const containerCss = React.useMemo(() => ouiStyle.makeCss({
    ...defaultUnorderedListElementWithNoMarkContainerStyle,
    fontSize,
    color,
  }), [fontSize, color]);
  const bulletCss = React.useMemo(() => ({
    display: ouiStyle.Constants.DisplayProp.inlineBlock,
    color: bulletColor,
    fontWeight: ouiStyle.Constants.FontWeightProp.bold,
    width: '1rem',
  }), [bulletColor]);
  return (
    <li css={containerCss}>
      <span css={bulletCss}>•</span>
      {children}
    </li>
  );
}

export const defaultUnorderedListElementWithBarStyle: ouiStyle.InStyle = {
  display: ouiStyle.Constants.DisplayProp.block,
  listStyleType: ouiStyle.Constants.ListStyleTypeProps.none,
  // this doesn't respect the vertical rythm!!!
  paddingTop: 'half',
  paddingBottom: 'none',
};

export const barStyle: ouiStyle.InStyle = {
  borderBottomColor: ouiStyle.Constants.Colors.accent,
  borderBottomStyle: 'solid',
  borderBottomWidth: 'simple',
  paddingTop: 'half',
  marginHorizontal: 'auto',
  widthLines: 4,
};

export function DefaultUnorderedListElementWithBar(
  props: React.PropsWithChildren<{
    color: ouiStyle.InStyle['color'],
    textAlign: ouiStyle.InStyle['textAlign'],
    fontSize: ouiStyle.InStyle['fontSize'],
    fontWeight: ouiStyle.InStyle['fontWeight'],
    barWidthLines?: ouiStyle.InStyle['widthLines'],
    barColor?: ouiStyle.InStyle['borderBottomColor'],
  }>,
) {
  const {
    color,
    fontSize,
    fontWeight,
    textAlign,
    barWidthLines,
    barColor,
    children,
  } = props;
  const containerCss = React.useMemo(() => ouiStyle.makeCss({
    ...defaultUnorderedListElementWithBarStyle,
    color,
    textAlign,
    fontSize,
    fontWeight,
  }), [
    color,
    textAlign,
    fontSize,
    fontWeight,
  ]);
  const barCss = React.useMemo(() => ouiStyle.makeCss({
    ...barStyle,
    widthLines: barWidthLines ?? barStyle.widthLines,
    borderBottomColor: barColor ?? barStyle.borderBottomColor,
  }), [
    barWidthLines,
    barColor,
  ]);
  const isLast = ouiBase.utils.dimensions.useIsLastPrimaryDimensionIndex();
  return (
    <li
      css={containerCss}
    >
      {children}
      {(
        !isLast
          ? (
            <hr
              css={barCss}
            />
          )
          : null
      )}
    </li>
  );
}

export const IconBase = () => {
  const index = ouiBase.utils.dimensions.useCurrentPrimaryDimensionIndex();
  const primaryKey = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  return (
    <RampWithNameTransform<{ style?: React.CSSProperties }>
      rampName={`ElementIconContainer-primaryKey-${primaryKey}`}
      rampFallbacks={[
        `ElementIconContainer-index-${index}`,
        'ElementIconContainer',
      ]}
    >
      <RampWithNameTransform
        rampName={`ElementIconContent-primaryKey-${primaryKey}`}
        rampFallbacks={[
          `ElementIconContent-index-${index}`,
          'ElementIconContent',
        ]}
      />
    </RampWithNameTransform>
  );
};

const iconContainerStyle: ouiStyle.InStyle = {
  paddingHorizontal: 'simple',
  iconContainer: true,
};

const iconContainerCss = ouiStyle.makeCss(iconContainerStyle);

export const IconContainer = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  return (
    <span css={iconContainerCss}>
      {children}
    </span>
  );
};

export function DefaultUnorderedListElementWithBarAndIcon(
  props: React.PropsWithChildren<{
    color: ouiStyle.InStyle['color'],
    textAlign: ouiStyle.InStyle['textAlign'],
    fontSize: ouiStyle.InStyle['fontSize'],
    fontWeight: ouiStyle.InStyle['fontWeight'],
    barWidthLines?: ouiStyle.InStyle['widthLines'],
    barColor?: ouiStyle.InStyle['borderBottomColor'],
  }>,
) {
  const {
    color,
    fontSize,
    fontWeight,
    textAlign,
    barWidthLines,
    barColor,
    children,
  } = props;
  const containerCss = React.useMemo(() => ouiStyle.makeCss({
    ...defaultUnorderedListElementWithBarStyle,
    color,
    textAlign,
    fontSize,
    fontWeight,
  }), [
    color,
    textAlign,
    fontSize,
    fontWeight,
  ]);
  const barCss = React.useMemo(() => ouiStyle.makeCss({
    ...barStyle,
    widthLines: barWidthLines ?? barStyle.widthLines,
    borderBottomColor: barColor ?? barStyle.borderBottomColor,
  }), [
    barWidthLines,
    barColor,
  ]);
  const isLast = ouiBase.utils.dimensions.useIsLastPrimaryDimensionIndex();
  return (
    <li
      css={containerCss}
    >
      <RampWithNameTransform rampName="ElementIconBase" />
      {children}
      {(
        !isLast
          ? (
            <hr
              css={barCss}
            />
          )
          : null
      )}
    </li>
  );
}
