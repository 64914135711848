import React from 'react';
import { Trans } from '@lingui/macro';

export const tAddTelPrincipalLabel = (
  <Trans>
    Numéro de téléphone fixe
  </Trans>
);

export const tAddTelPrincipalConfirmLabel = (
  <Trans>
    Confirmation du numéro de téléphone fixe
  </Trans>
);
