import { Locale } from '@goldwasserexchange/i18n';
import {
  Update,
  createPath,
} from 'history';
import { splitPathname } from '../../utils';

export const makeHref = (path: string) => `${window.location.origin}${path}`;

export const createLanguagePathnames = (pathname: string) => {
  const splittedPathname = splitPathname(pathname);
  const frPathname = `/${[Locale.FR, ...splittedPathname.slice(1)].join('/')}`;
  const nlPathname = `/${[Locale.NL, ...splittedPathname.slice(1)].join('/')}`;
  return {
    frPathname,
    nlPathname,
  };
};

export const toHrefPaths = (pathnames: {
  path: string,
  frPath: string,
  nlPath: string,
}) => {
  const {
    path,
    frPath,
    nlPath,
  } = pathnames;
  return {
    href: makeHref(path),
    frHref: makeHref(frPath),
    nlHref: makeHref(nlPath),
  };
};

export const createHrefs = (currentUpdate: Update) => {
  const path = createPath(currentUpdate.location);
  const {
    frPathname,
    nlPathname,
  } = createLanguagePathnames(currentUpdate.location.pathname);
  const frPath = createPath({
    ...currentUpdate.location,
    pathname: frPathname,
  });
  const nlPath = createPath({
    ...currentUpdate.location,
    pathname: nlPathname,
  });
  const paths = toHrefPaths({
    path,
    frPath,
    nlPath,
  });
  return paths;
};
