import * as React from 'react';
import ouiDom from '@goldwasserexchange/oui-dom';
import ouiStyle from '@goldwasserexchange/oui-style';

export const SanityH2 = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  return (
    <ouiDom.HeadingSection.DomH2
      lineFactor={1.35}
      color={ouiStyle.Constants.Colors.accent}
      paddingTop={['simple', undefined, undefined, 'simple', undefined, undefined]}
      paddingBottom={['simple', undefined, undefined, 'none', undefined, undefined]}
    >
      {children}
    </ouiDom.HeadingSection.DomH2>
  );
};
