var _a, _b, _c, _d, _e;
import ouiBase from '@goldwasserexchange/oui';
import * as React from 'react';
import { i18n } from '@goldwasserexchange/i18n';
import * as fields from './fields';
import { InvestmentGoalsInvestmentGoals, InvestmentGoalsForeignCurrencies, InvestmentGoalsMaxRecoveryPeriod, InvestmentGoalsRiskLevel, InvestmentGoalsHorizon, } from '../../types';
var labelsData = {
    horizon: {
        label: {
            defaultLabel: function () { return i18n._('Quel est votre horizon d\'investissement ? L\'horizon d\'investissement est la période durant laquelle un investisseur peut investir une somme d\'argent dont il dispose sans devoir, à un moment ou l\'autre, y recourir pour d\'autres objectifs que ses investissements. Pendant quelle période pouvez-vous ne pas recourir à la somme que vous investissez chez Goldwasser Exchange pour d\'autres dépenses ou objectifs que vos investissements ?'); },
        },
        values: (_a = {},
            _a[InvestmentGoalsHorizon.SHORT] = function () { return i18n._('J\'ai un horizon d\'investissement à court terme (moins de deux ans)'); },
            _a[InvestmentGoalsHorizon.MEDIUM] = function () { return i18n._('J\'ai un horizon d\'investissement à moyen terme (entre deux ans et cinq ans)'); },
            _a[InvestmentGoalsHorizon.LONG] = function () { return i18n._('J\'ai un horizon d\'investissement à long terme (plus de cinq ans)'); },
            _a),
    },
    riskLevel: {
        label: {
            defaultLabel: function () { return i18n._('Quel niveau de risque êtes-vous prêt(e) à prendre pour vos investissements ?'); },
        },
        values: (_b = {},
            _b[InvestmentGoalsRiskLevel.LOW] = function () { return i18n._('Faible'); },
            _b[InvestmentGoalsRiskLevel.MEDIUM] = function () { return i18n._('Modéré'); },
            _b[InvestmentGoalsRiskLevel.HIGH] = function () { return i18n._('Elevé'); },
            _b),
    },
    maxRecoveryPeriod: {
        label: {
            defaultLabel: function () { return i18n._('En cas de baisse de la valeur de vos investissements, combien de temps seriez-vous prêt(e) à attendre avant que votre portefeuille regagne sa valeur initiale ?'); },
        },
        values: (_c = {},
            _c[InvestmentGoalsMaxRecoveryPeriod.LESS_THAN_ONE_YEAR] = function () { return i18n._('Moins d\'un an'); },
            _c[InvestmentGoalsMaxRecoveryPeriod.BETWEEN_ONE_AND_THREE_YEARS] = function () { return i18n._('Entre un et trois ans'); },
            _c[InvestmentGoalsMaxRecoveryPeriod.MORE_THAN_THREE_YEARS] = function () { return i18n._('Plus de trois ans'); },
            _c),
    },
    foreignCurrencies: {
        label: {
            defaultLabel: function () { return i18n._('Souhaitez-vous investir en devises étrangères (autre que l\'euro) et vous exposer ainsi au risque de change ?'); },
        },
        values: (_d = {},
            _d[InvestmentGoalsForeignCurrencies.ALLOW] = function () { return i18n._('Oui'); },
            _d[InvestmentGoalsForeignCurrencies.DENY] = function () { return i18n._('Non'); },
            _d),
    },
    investmentGoal: {
        label: {
            defaultLabel: function () { return i18n._('Quel est le but de votre démarche d\'investissement ?'); },
        },
        values: (_e = {},
            _e[InvestmentGoalsInvestmentGoals.GROW] = function () { return i18n._('Faire croître mon patrimoine'); },
            _e[InvestmentGoalsInvestmentGoals.PRESERVE] = function () { return i18n._('Préserver mon capital'); },
            _e[InvestmentGoalsInvestmentGoals.COMPLETE_REVENUE] = function () { return i18n._('Compléter mes revenus par un revenu régulier, en évitant dans la mesure du possible de toucher à mon capital'); },
            _e),
    },
};
export var useLabelData = function (question) {
    var labelsQuestionData = labelsData[question];
    return labelsQuestionData;
};
var investmentGoalsPath = 'investmentGoals';
export var useLabel = function (question) {
    var logic = fields.logic.label.map[question];
    var labelType = ouiBase.utils.hookstate.useFormFilterLogic(logic, investmentGoalsPath);
    var labelData = useLabelData(question);
    var labels = labelData.label;
    return labels[labelType]();
};
export var Label = function (props) {
    var question = props.question;
    var label = useLabel(question);
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return React.createElement(React.Fragment, null, label);
};
export var useValueLabel = function (question, primaryKey) {
    var labelData = useLabelData(question);
    var values = labelData.values;
    return values[primaryKey]();
};
export var ValueLabel = function (props) {
    var question = props.question, primaryKey = props.primaryKey;
    var label = useValueLabel(question, primaryKey);
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return React.createElement(React.Fragment, null, label);
};
