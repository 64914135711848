import * as React from 'react';
import { useAccountPositionsGesEstimDQtBySTypeGroupAndCCurrencyCode, useAccountPositionsGesEstimDQtByPrimaryDimensionGesEstimD } from '../../hooks';
import { ActorApiNumberDisplayCurrency } from '../../../../../../components/display/number/currency';
import { ActorApiNumberDisplay } from '../../../../../../components/display/number/component';
import { STypeGrpIds } from '../../../../../shared/utils/sTypeGrpIds';

export const AccountsPositionsQtLiquidValue = (props: {
  currencyCode: string,
}) => {
  const {
    currencyCode,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useAccountPositionsGesEstimDQtBySTypeGroupAndCCurrencyCode(currencyCode, STypeGrpIds.CASH);
  return (
    <ActorApiNumberDisplayCurrency
      data={data}
      isLoading={isLoading}
      error={error}
      currencyData={currencyCode}
      currencyIsLoading={false}
      currencyError={null}
    />
  );
};

export const AccountsPositionsGesEstimDQt = () => {
  const {
    data,
    isLoading,
    error,
  } = useAccountPositionsGesEstimDQtByPrimaryDimensionGesEstimD();
  return (
    <ActorApiNumberDisplay
      data={data}
      isLoading={isLoading}
      error={error}
      format={{
        minimumFractionDigits: 0,
        maximumFractionDigits: 4,
      }}
    />
  );
};
