import ouiBase from '@goldwasserexchange/oui';
import {
  useFuzzySearchIndexerRef,
  useSearchValueMethods,
  useContainsSelectedFn,
  useFindSelectedFnInListData,
} from '../../../../context';

function flattenSearchResult(searchResult: {
  item: { ID: string },
  matches,
  score,
}) {
  // TODO: create a matches and score state
  const { item /* , matches, score */ } = searchResult;

  return item.ID;
}

function search(fuzzySearch, searchTermValue) {
  if (fuzzySearch.current != null) {
    return fuzzySearch.current.search(searchTermValue).map((x) => flattenSearchResult(x));
  }
  return [];
}

export function useSearch() {
  const sourceData = ouiBase.utils.dimensions.usePrimaryDimensionList();
  const fuzzySearch = useFuzzySearchIndexerRef();
  const searchTerm = useSearchValueMethods()?.get();
  const containsSelectedFn = useContainsSelectedFn();
  const findSelectedFn = useFindSelectedFnInListData();
  const result = search(fuzzySearch, searchTerm);
  const containsSelected = containsSelectedFn(result);
  const selected = findSelectedFn();
  let final = result;
  if (result.length === 0) {
    final = sourceData;
  }
  if (result.length > 0 && !containsSelected) {
    final = [...result, ...selected];
  }
  return final;
}
