import * as React from 'react';
import { ErrorBoundaryContent } from './content';

export class ErrorBoundary extends React.Component<{ renderNull?: boolean, children?: React.ReactNode }, { hasError?: boolean }, {}> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError() {
    return { hasError: true };
  }

  public componentDidCatch(error) {
    if (window.awsRum) {
      window.awsRum.recordError(error);
    }
  }

  public render() {
    const {
      hasError,
    } = this.state;
    const {
      renderNull = false,
    } = this.props;
    if (hasError) {
      if (renderNull) {
        return null;
      }
      return <ErrorBoundaryContent />;
    }
    const {
      children,
    } = this.props;
    return children;
  }
}
