import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import ouiDom from '@goldwasserexchange/oui-dom';
import { useAtomValue } from 'jotai';
import * as machine from '../../machine';
import * as animationController from '../../animation';
import { ValidSections, pathnameUrlLanguageAtom } from '../../../../history';

const NavBarLinkButton = (props: React.PropsWithChildren<{
  section: ValidSections,
  replace?: boolean,
  backgroundColor: ouiStyle.InStyle['backgroundColor'],
  hoverBackgroundColor: ouiStyle.InStyle['backgroundColor'],
}>) => {
  const {
    section,
    replace,
    backgroundColor,
    hoverBackgroundColor,
    children,
  } = props;
  const sendLinkClick = machine.useLinkClick();
  const mqDown = animationController.useAnimationmqDownSpringValue();
  const animatedValues = {
    flexGrow: mqDown.to((v) => {
      if (v <= 0.5) {
        return '1';
      }
      return '0';
    }),
    marginLeft: mqDown.to((v) => {
      if (v <= 0.5) {
        return '10px';
      }
      return '0';
    }),
    marginRight: mqDown.to((v) => {
      if (v <= 0.5) {
        return '10px';
      }
      return '0';
    }),
  };
  const language = useAtomValue(pathnameUrlLanguageAtom);
  return (
    <ouiDom.A.AButton
      to={`/${language}/${section}`}
      replace={replace}
      backgroundColor={backgroundColor}
      fontSize="m"
      hoverBackgroundColor={hoverBackgroundColor}
      borderColor={backgroundColor}
      color={ouiStyle.Constants.Colors.inverted}
      fontWeight={ouiStyle.Constants.FontWeightProp.normal}
      flexBasis={ouiStyle.Constants.FlexBasisProp.auto}
      flexShrink
      animatedValues={animatedValues}
      onClick={sendLinkClick}
    >
      {children}
    </ouiDom.A.AButton>
  );
};

export const NavBarAnchorButton = (
  props: React.PropsWithChildren<{
    backgroundColor: ouiStyle.InStyle['backgroundColor'],
    hoverBackgroundColor: ouiStyle.InStyle['backgroundColor'],
  } & ({ section: ValidSections, replace?: boolean } | { href: string, target: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>['target'] })>,
): JSX.Element => {
  const sendLinkClick = machine.useLinkClick();
  const mqDown = animationController.useAnimationmqDownSpringValue();
  const animatedValues = {
    flexGrow: mqDown.to((v) => {
      if (v <= 0.5) {
        return '1';
      }
      return '0';
    }),
    marginLeft: mqDown.to((v) => {
      if (v <= 0.5) {
        return '10px';
      }
      return '0';
    }),
    marginRight: mqDown.to((v) => {
      if (v <= 0.5) {
        return '10px';
      }
      return '0';
    }),
  };
  const {
    backgroundColor, hoverBackgroundColor, children,
  } = props;
  if ('href' in props) {
    const {
      href, target,
    } = props;
    return (
      <ouiDom.A.AButton
        href={href}
        target={target}
        backgroundColor={backgroundColor}
        hoverBackgroundColor={hoverBackgroundColor}
        borderColor={backgroundColor}
        color={ouiStyle.Constants.Colors.inverted}
        fontSize="m"
        fontWeight={ouiStyle.Constants.FontWeightProp.normal}
        flexBasis={ouiStyle.Constants.FlexBasisProp.auto}
        flexShrink
        animatedValues={animatedValues}
        onClick={sendLinkClick}
        textAlign="center"
      >
        {children}
      </ouiDom.A.AButton>
    );
  }
  const {
    section,
    replace,
  } = props;
  return (
    <NavBarLinkButton
      section={section}
      replace={replace}
      backgroundColor={backgroundColor}
      hoverBackgroundColor={hoverBackgroundColor}
    >
      {children}
    </NavBarLinkButton>
  );
};

export default NavBarAnchorButton;
