/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const style: ouiStyle.InStyleWithMediaQueries = {
  position: 'absolute',
  display: ouiStyle.Constants.DisplayProp.flex,
  backgroundColor: ouiStyle.Constants.Colors.primary,
  widthLines: [2, undefined, undefined, 4, undefined, undefined],
  lines: [2, undefined, undefined, 4, undefined, undefined],
  left: '50%',
  transform: ['translateX(-50%)', undefined, undefined, `translateX(-50%) translateY(calc(-50% - ${ouiStyle.Constants.space}rem))`, undefined, undefined],
  top: `${ouiStyle.Constants.space}rem`,
  borderRadius: 'full',
  overflow: 'hidden',
  alignItems: ouiStyle.Constants.AlignItemsProp.center,
  justifyContent: ouiStyle.Constants.JustifyContentProp.center,
  color: ouiStyle.Constants.Colors.fadeDarker,
  boxShadow: '0 0 10px 3px rgba(0, 0, 0, 0.1)',
};

const css = ouiStyle.mediaQuery(ouiStyle.makeCss(style));

export const ModalIconContainer = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  return (
    <div
      css={css}
    >
      {children}
    </div>
  );
};
