import { CivilStatus } from '../../../../types';
export var list = [
    CivilStatus.CELIBATAIRE,
    CivilStatus.SEPARE,
    CivilStatus.MARIE,
    CivilStatus.COHLEG,
    CivilStatus.DIVORCE,
    CivilStatus.VEUF,
];
export var hasSignificantOtherList = [
    CivilStatus.MARIE,
    CivilStatus.COHLEG,
];
