import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { Accounts } from '../../../accounts/enum';
import { AccountHistoryArDocFileIcon, AccountDocumentArDocFileIcon, AccountPositionsHistoryArDocFileIcon } from '../../../accounts';

export const ArDocFileIcon = <Service extends Accounts.History | Accounts.Documents | Accounts.PositionsHistory>(props: {
  service: Service,
  size?: string | number | undefined,
  stroke?: ouiStyle.Constants.Colors,
  loadingStrokeStart?: ouiStyle.Constants.Colors,
  loadingStrokeEnd?: ouiStyle.Constants.Colors,
}) => {
  const {
    service,
    size,
    stroke,
    loadingStrokeEnd,
    loadingStrokeStart,
  } = props;
  if (service === Accounts.History) {
    return (
      <AccountHistoryArDocFileIcon
        size={size}
        stroke={stroke}
        loadingStrokeStart={loadingStrokeStart}
        loadingStrokeEnd={loadingStrokeEnd}
      />
    );
  }
  if (service === Accounts.Documents) {
    return (
      <AccountDocumentArDocFileIcon
        size={size}
        stroke={stroke}
        loadingStrokeStart={loadingStrokeStart}
        loadingStrokeEnd={loadingStrokeEnd}
      />
    );
  }
  if (service === Accounts.PositionsHistory) {
    return (
      <AccountPositionsHistoryArDocFileIcon
        size={size}
        stroke={stroke}
        loadingStrokeStart={loadingStrokeStart}
        loadingStrokeEnd={loadingStrokeEnd}
      />
    );
  }
  return null;
};
