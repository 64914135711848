import * as React from 'react';
import { GetAccountsHistoryParams } from '@goldwasserexchange/actor/rest-services';
import { useAccountHistoryDefaultParams } from '../hooks';
import { accountsHistoryParamsContext } from '../context';

export const AccountsHistoryParamsProvider = (props: React.PropsWithChildren<{
  params: Partial<GetAccountsHistoryParams>,
}>) => {
  const {
    params,
    children,
  } = props;
  const value = useAccountHistoryDefaultParams(params);
  return (
    <accountsHistoryParamsContext.Provider
      value={value}
    >
      {children}
    </accountsHistoryParamsContext.Provider>
  );
};
