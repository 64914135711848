/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import { animated } from 'react-spring';
import ouiStyle from '@goldwasserexchange/oui-style';
import { footerContentStyle } from '../style';

const footerErrorStyle: ouiStyle.InStyle = {
  ...footerContentStyle,
  color: ouiStyle.Constants.Colors.danger,
};

const footerErrorCss = ouiStyle.makeCss(footerErrorStyle);

export function DomInputFooterErrorContainer(
  props: React.PropsWithChildren<{ style: any }>,
) {
  const { style, children } = props;
  return (
    <animated.p css={footerErrorCss} style={style}>
      {children}
    </animated.p>
  );
}
