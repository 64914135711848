import { parsePositiveNumberFromQs } from '../../bonds';
import { DateInterval, VALID_INTERVAL_INTS } from './constants';

export const isValidDateInterval = (interval: number): interval is DateInterval => VALID_INTERVAL_INTS.includes(interval);

export const parseValidIntervalWithDefault = (interval: string | (string | null)[] | null) => {
  const parsedInterval = parsePositiveNumberFromQs(interval);
  if (parsedInterval == null) {
    return DateInterval.three_months;
  }
  if (!isValidDateInterval(parsedInterval)) {
    return DateInterval.three_months;
  }
  return parsedInterval;
};
