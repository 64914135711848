import * as events from '../../../events';
import { makeSpringService } from '../utils';

export type AppBarAnimateMqDownServiceMeta = {
  src: {
    type: 'AnimateMqDownService',
  },
};

export const name = 'animateMqDown';

export const makeService = makeSpringService(name, 'mqUp', 0, true, events.mqChangedEvent.eventBuilder);

export default makeService;
