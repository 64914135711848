/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { animated } from 'react-spring';
import { inputIconInnerContainerStyle } from '../style';

const TextInputValidIconContainerStyle: ouiStyle.InStyle = {
  ...inputIconInnerContainerStyle,
  color: ouiStyle.Constants.Colors.success,
};

const TextInputValidIconContainerCss = ouiStyle.makeCss(
  TextInputValidIconContainerStyle,
);

export function DefaultDomInputValidIconContainer(
  props: React.PropsWithChildren<{ style: any }>,
) {
  const { style, children } = props;
  return (
    <animated.div css={TextInputValidIconContainerCss} style={style}>
      {children}
    </animated.div>
  );
}
