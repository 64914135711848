import React from 'react';
import { Trans } from '@lingui/macro';
import ouiBase from '@goldwasserexchange/oui';
import {
  dataStructure, UncastedPhysicalTAdd, UncastedDataStructure, PersonType, TAddType, TNatureLrp, UncastedOnboarding,
} from '@goldwasserexchange/oblis-frontend-utils';

export const TAddBirthdateLabel = <Trans>Date de naissance</Trans>;

export const TAddBirthdateSignificantOtherLabel = <Trans>Date de naissance du partenaire</Trans>;

const tAddType: keyof Pick<UncastedPhysicalTAdd, 'T_ADD_type'> = 'T_ADD_type';

const current: keyof Pick<UncastedPhysicalTAdd, 'current'> = 'current';

const idTNatureLrp: keyof Pick<UncastedPhysicalTAdd, 'ID_T_NATURE_LRP'> = 'ID_T_NATURE_LRP';

const onboardingPath: keyof Pick<UncastedDataStructure, 'onboarding'> = 'onboarding';

const personType: keyof Pick<UncastedOnboarding, 'personType'> = 'personType';

export const tAddBirthdateValidatorLogic: ouiBase.utils.hookstate.FormValueFilterLogic<'requiredMinorBirthdate' | 'requiredNotMinorBirthdate' | 'requiredPastDate' | 'nilCast'> = dataStructure.T_ADD.fields.TYPE.logic.makeTAddIsNotMoralOrIgnored<'requiredMinorBirthdate' | 'requiredNotMinorBirthdate' | 'requiredPastDate' | 'nilCast'>({
  truthy: {
    name: `<parentName>.${tAddType}`,
    is: TAddType.ONLY_REPRESENTATIVE,
    alternatives: {
      truthy: {
        result: 'requiredNotMinorBirthdate',
      },
      falsy: {
        name: `<parentName>.${tAddType}`,
        is: TAddType.TITULAR,
        alternatives: {
          truthy: {
            name: `<parentName>.${current}`,
            is: true,
            alternatives: {
              truthy: {
                result: 'requiredNotMinorBirthdate',
              },
              falsy: {
                name: `<parentName>.${idTNatureLrp}`,
                is: TNatureLrp.REPRESENTANT_LEGAL,
                alternatives: {
                  truthy: {
                    result: 'requiredNotMinorBirthdate',
                  },
                  falsy: {
                    name: `${onboardingPath}.${personType}`,
                    is: PersonType.FOR_MINORS,
                    alternatives: {
                      truthy: {
                        result: 'requiredMinorBirthdate',
                      },
                      falsy: {
                        result: 'requiredPastDate',
                      },
                    },
                  },
                },
              },
            },
          },
          falsy: {
            name: `<parentName>.${tAddType}`,
            is: [TAddType.PROCURATION, TAddType.MANDATARY],
            options: {
              matcher: ouiBase.utils.match.arrayMatcherNames.some,
            },
            alternatives: {
              truthy: {
                result: 'requiredNotMinorBirthdate',
              },
              falsy: {
                result: 'requiredPastDate',
              },
            },
          },
        },
      },
    },
  },
  falsy: {
    result: 'nilCast',
  },
});
