import useSWR from 'swr';
import { QueryResponse } from './types';
import { convertQueryArguments, fetcher } from './utils';
import memoizee from 'memoizee';
import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import {
  pathnameUrlBondsSectionAtom, pathnameUrlLanguageAtom, pathnameUrlNewsArticleOrSection, pathnameUrlSectionAtom,
} from '../../../history';
import { useCurrentPromoLimit } from '../../../Components/Sections/bonds/components/promo/context';
import { useBondSAccId } from '../../../actor/api/Services/bonds';
import { NumberFormatterProps, useNumberFormatter } from '../../../Components/NumberDisplay';
import { useLingui } from '@lingui/react';
import { msg } from '@lingui/macro';
import { isValid, parseISO } from 'date-fns';
import { useSanityNewsByBondIdPromotions, useSanityNewsListPromos } from '../news';

export const useSanityPromos = () => {
  const locale = useAtomValue(pathnameUrlLanguageAtom);
  const section = useAtomValue(pathnameUrlSectionAtom);
  const newsSection = useAtomValue(pathnameUrlNewsArticleOrSection);
  const bondSection = useAtomValue(pathnameUrlBondsSectionAtom);
  const isNewsHome = section === 'news' && newsSection === 'home';
  const isBondDetails = section === 'bonds' && bondSection === 'details';
  const swrArguments = !(isNewsHome || isBondDetails) ? convertQueryArguments({ locale }) : null;
  const promoResponse = useSWR(swrArguments, fetcher);
  const newsResponse = useSanityNewsListPromos(!isNewsHome);
  const latestNewsByBondIdResponse = useSanityNewsByBondIdPromotions(!isBondDetails);
  if (isNewsHome) {
    return newsResponse;
  }
  if (isBondDetails) {
    return latestNewsByBondIdResponse;
  }
  return promoResponse;
};

export const useSanityPromosList = () => {
  const originalResponse = useSanityPromos();
  const response = useMemo(
    () => {
      const {
        data,
        ...rest
      } = originalResponse;
      if (data == null) {
        return {
          ...rest,
          data: undefined,
        };
      }
      return {
        ...rest,
        data: data
          .map((promo) => promo.bondId),
      };
    },
    [
      originalResponse.data,
      originalResponse.isLoading,
      originalResponse.error,
    ],
  );
  return response;
};

export const useSanityPromosListIncludesBondId = () => {
  const bondId = useBondSAccId();
  const originalResponse = useSanityPromosList();
  const response = useMemo(
    () => {
      const {
        data,
        ...rest
      } = originalResponse;
      if (data == null) {
        return {
          ...rest,
          data: undefined,
        };
      }
      return {
        ...rest,
        data: data.includes(bondId),
      };
    },
    [
      bondId,
      originalResponse.data,
      originalResponse.error,
      originalResponse.isLoading,
    ],
  );
  return response;
};

export const useSanityPromosListWithLimit = (props: {
  limit: number | null,
}) => {
  const {
    limit,
  } = props;
  const originalResponse = useSanityPromosList();
  const response = useMemo(
    () => {
      const {
        data,
        ...rest
      } = originalResponse;
      if (originalResponse.data == null) {
        return {
          ...rest,
          data: undefined,
        };
      }
      return {
        ...rest,
        data: limit != null ? originalResponse.data.slice(0, limit) : originalResponse.data,
      };
    },
    [
      limit,
      originalResponse.data,
      originalResponse.error,
      originalResponse.isLoading,
    ],
  );
  return response;
};

export const useSanityPromosListWithCurrentLimit = () => {
  const limit = useCurrentPromoLimit();
  const response = useSanityPromosListWithLimit({ limit });
  return response;
};

const getLength = (data: number[] | undefined) => {
  if (data == null) {
    return undefined;
  }
  return data.length;
};

export const useSanityPromosListWithLimitLength = (props: {
  limit: number | null,
}) => {
  const originalResponse = useSanityPromosListWithLimit(props);
  const response = useMemo(
    () => ({
      ...originalResponse,
      data: getLength(originalResponse.data),
    }),
    [
      originalResponse.data,
      originalResponse.error,
      originalResponse.isLoading,
    ],
  );
  return response;
};

export const useSanityPromosListWithCurrentLimitLength = () => {
  const originalResponse = useSanityPromosListWithCurrentLimit();
  const response = useMemo(
    () => ({
      ...originalResponse,
      data: getLength(originalResponse.data),
    }),
    [
      originalResponse.data,
      originalResponse.error,
      originalResponse.isLoading,
    ],
  );
  return response;
};

const findByBondId = (bondId: number, promos: QueryResponse[]) => promos.find((promo) => promo.bondId === bondId);

const memoFindByPromoId = memoizee(findByBondId);

export const useSanityPromoByBondId = (props: { bondId: number }) => {
  const {
    bondId,
  } = props;
  const originalResponse = useSanityPromos();
  const response = useMemo(() => {
    const {
      data,
      ...rest
    } = originalResponse;
    if (!data) {
      return {
        ...rest,
        data: undefined,
      };
    }
    return {
      ...rest,
      data: memoFindByPromoId(bondId, data),
    };
  }, [
    bondId,
    originalResponse.data,
    originalResponse.error,
    originalResponse.isLoading,
  ]);
  return response;
};

export const useSanityPromoByContextBondId = () => {
  const bondId = useBondSAccId();
  const response = useSanityPromoByBondId({ bondId });
  return response;
};

const courtRateFormat: NumberFormatterProps = {
  format: {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
  divider: 100,
};

export const useSanityPromoByContextBondIdBrokerageFeesText = (props?: {
  showNoCustodyFeesMessage?: boolean,
}) => {
  const {
    showNoCustodyFeesMessage = true,
  } = props ?? {};
  const originalResponse = useSanityPromoByContextBondId();
  const courtRateFormatter = useNumberFormatter(courtRateFormat);
  const {
    _,
  } = useLingui();
  const response = useMemo(() => {
    const {
      data,
      ...rest
    } = originalResponse;
    if (data == null) {
      return {
        ...rest,
        data: undefined,
      };
    }
    const {
      brokerageFees,
      custodyFees,
    } = data;
    if (brokerageFees === 0) {
      if (custodyFees === false && showNoCustodyFeesMessage === true) {
        return {
          ...rest,
          data: _(msg`Pas de frais d'achat et pas de droits de garde`),
        };
      }
      return {
        ...rest,
        data: _(msg`Pas de frais d'achat`),
      };
    }
    if (custodyFees === false && showNoCustodyFeesMessage === true) {
      return {
        ...rest,
        data: _(msg`${courtRateFormatter(brokerageFees)} de frais d'achat et pas de droits de garde`),
      };
    }
    return {
      ...rest,
      data: _(msg`${courtRateFormatter(brokerageFees)} de frais d'achat`),
    };
  }, [
    _,
    courtRateFormatter,
    originalResponse.data,
    originalResponse.error,
    originalResponse.isLoading,
    showNoCustodyFeesMessage,
  ]);
  return response;
};

export const useSanityPromoByContextBondIdEndDate = () => {
  const originalResponse = useSanityPromoByContextBondId();
  const response = useMemo(() => {
    const {
      data,
      ...rest
    } = originalResponse;
    if (data == null) {
      return {
        ...rest,
        data: undefined,
      };
    }
    const {
      endDate,
    } = data;
    const parsed = parseISO(endDate);
    if (!isValid(parsed)) {
      return {
        ...rest,
        data: undefined,
      };
    }
    return {
      ...rest,
      data: parseISO(endDate),
    };
  }, [
    originalResponse.data,
    originalResponse.error,
    originalResponse.isLoading,
  ]);
  return response;
};

export const useSanityPromoByContextBondIdTo = () => {
  const originalResponse = useSanityPromoByContextBondId();
  const response = useMemo(() => {
    const {
      data,
      ...rest
    } = originalResponse;
    if (data == null) {
      return {
        ...rest,
        data: undefined,
      };
    }
    const {
      language,
      news,
    } = data;
    const slug = news?.slug;
    if (slug == null) {
      const {
        bondId,
      } = data;
      return {
        ...rest,
        data: `/${language}/bonds/details/${bondId}`,
      };
    }
    return {
      ...rest,
      data: `/${language}/news/${slug}`,
    };
  }, [
    originalResponse.data,
    originalResponse.error,
    originalResponse.isLoading,
  ]);
  return response;
};

export const useSanityPromoByContextBondIdToNews = () => {
  const originalResponse = useSanityPromoByContextBondId();
  const response = useMemo(() => {
    const {
      data,
      ...rest
    } = originalResponse;
    if (data == null) {
      return {
        ...rest,
        data: undefined,
      };
    }
    const {
      language,
      news,
    } = data;
    const slug = news?.slug;
    if (slug == null) {
      return {
        ...rest,
        data: undefined,
      };
    }
    return {
      ...rest,
      data: `/${language}/news/${slug}`,
    };
  }, [
    originalResponse.data,
    originalResponse.error,
    originalResponse.isLoading,
  ]);
  return response;
};
