import * as React from 'react';
import * as ElementTitle from '../elementTitle';
import { getComponent, isValidStartsOrThrow } from './data';
import { EmbeddedContainer } from './components';
export var Embedded = function (props) {
    var title = props.title, link = props.link, _a = props.description, description = _a === void 0 ? '' : _a;
    if (!isValidStartsOrThrow(link)) {
        throw new Error('invalid link');
    }
    var EmbeddedComponent = getComponent(link);
    return (React.createElement(EmbeddedContainer, null,
        title
            ? (React.createElement(ElementTitle.ElementTitle, null, title))
            : null,
        React.createElement(EmbeddedComponent, { link: link, description: description })));
};
