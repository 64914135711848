var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { parsePath } from 'history';
import { useHistory } from '../../context';
export var useLinkController = function (props) {
    var _a, _b;
    var href = props.href, target = props.target, propsOnClick = props.onClick, _c = props.replace, replace = _c === void 0 ? false : _c;
    var history = useHistory();
    var onClick = React.useCallback(function (e) {
        var prevented = false;
        if (propsOnClick) {
            var preventDefault = function () {
                e.preventDefault();
                prevented = true;
            };
            propsOnClick(__assign(__assign({}, e), { preventDefault: preventDefault }));
        }
        if (!prevented) {
            if (href && !(e.metaKey || e.altKey || e.ctrlKey || e.shiftKey)) {
                e.preventDefault();
                if (replace) {
                    history.replace(href);
                }
                else {
                    history.push(href);
                }
            }
        }
    }, [
        href,
        propsOnClick,
        history,
        replace,
    ]);
    var parsed = href ? parsePath(href) : undefined;
    var lastPathNamePart = (_b = (_a = parsed === null || parsed === void 0 ? void 0 : parsed.pathname) === null || _a === void 0 ? void 0 : _a.split('/')) === null || _b === void 0 ? void 0 : _b.at(-1);
    var isHistoryLink = (!target || target === '_self')
        && href
        && href.startsWith('/')
        && (lastPathNamePart != null && !(lastPathNamePart === null || lastPathNamePart === void 0 ? void 0 : lastPathNamePart.includes('.')));
    return {
        href: href,
        onClick: isHistoryLink ? onClick : undefined,
        target: target,
    };
};
