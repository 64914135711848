import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import {
  DefaultTextInputPlaceHolderContainer,
  DefaultTextInputLabelContainer,
  DefaultTextInputMainContainer,
  DefaultTextInputComponent,
  DefaultTextInputIconContainer,
  DefaultDomTextInputContainer,
} from './components';

export const textInputDomDefaultTopModelsObject = {
  TextInputContainer: (
    <TopModel name="TextInputContainer">{DefaultDomTextInputContainer}</TopModel>
  ),
  TextInputPlaceHolderContainer: (
    <TopModel name="TextInputPlaceHolderContainer">
      {DefaultTextInputPlaceHolderContainer}
    </TopModel>
  ),
  TextInputLabelContainer: (
    <TopModel name="TextInputLabelContainer">
      {DefaultTextInputLabelContainer}
    </TopModel>
  ),
  TextInputMainContainer: (
    <TopModel name="TextInputMainContainer">
      {DefaultTextInputMainContainer}
    </TopModel>
  ),
  TextInputComponent: (
    <TopModel name="TextInputComponent">{DefaultTextInputComponent}</TopModel>
  ),
  TextInputIconContainer: (
    <TopModel name="TextInputIconContainer">
      {DefaultTextInputIconContainer}
    </TopModel>
  ),
};
