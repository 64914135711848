import * as React from 'react';
import { Place, SearchForSuggestionsResult } from '@aws-sdk/client-location';
import { SearchPlaceContainer, SearchPlaceInput, SearchPlaceResult } from './components';

export const SearchPlace = (props: {
  onSelect: (result: Place) => void,
}) => {
  const {
    onSelect,
  } = props;
  const ref = React.useRef<HTMLInputElement>(null);
  const [focus, setFocus] = React.useState(false);
  const [baseOpen, setOpen] = React.useState(false);
  const [data, setData] = React.useState<SearchForSuggestionsResult[] | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const open = (isLoading || data != null) && baseOpen;
  return (
    <SearchPlaceContainer
      setOpen={setOpen}
    >
      <SearchPlaceInput
        ref={ref}
        open={open}
        focus={focus}
        setOpen={setOpen}
        setIsLoading={setIsLoading}
        setData={setData}
        setFocus={setFocus}
      />
      <SearchPlaceResult
        open={open}
        focus={focus}
        data={data}
        isLoading={isLoading}
        setOpen={setOpen}
        onSelect={onSelect}
      />
    </SearchPlaceContainer>
  );
};
