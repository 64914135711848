import * as React from 'react';
import { useBooleanFilterRemoveRedacted } from '../hooks';
import { BooleanBondFilterTogglesContainer, BooleanBondFilterTogglesContainerPadding } from './container';
import { BooleanBondFilterToggleMapper } from './mapper';

export const BooleanBondFilterToggles = (props: BooleanBondFilterTogglesContainerPadding) => {
  const {
    padding,
    paddingHorizontal,
    paddingVertical,
    paddingTop,
    paddingLeft,
    paddingBottom,
    paddingRight,
  } = props;
  useBooleanFilterRemoveRedacted();
  return (
    <BooleanBondFilterTogglesContainer
      padding={padding}
      paddingHorizontal={paddingHorizontal}
      paddingVertical={paddingVertical}
      paddingTop={paddingTop}
      paddingLeft={paddingLeft}
      paddingBottom={paddingBottom}
      paddingRight={paddingRight}
    >
      <BooleanBondFilterToggleMapper />
    </BooleanBondFilterTogglesContainer>
  );
};
