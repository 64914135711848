import { Trans } from '@lingui/macro';
import * as React from 'react';

export const goldIntro = (
  <Trans>
    Vous êtes autonome dans vos choix
  </Trans>
);

export const goldTwoLinesIntro = (
  <Trans>
    Vous êtes autonome
    <br />
    dans vos choix
  </Trans>
);
