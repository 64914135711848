/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const navBarButtonListElementStyle: ouiStyle.InStyle = {
  backgroundColor: ouiStyle.Constants.Colors.logoBlue,
  flexBasis: ouiStyle.Constants.FlexBasisProp.auto,
  flexShrink: true,
  flexGrow: true,
  display: ouiStyle.Constants.DisplayProp.flex,
  alignItems: ouiStyle.Constants.AlignItemsProp.center,
  justifyContent: ouiStyle.Constants.JustifyContentProp.center,
  fontSize: 'm',
  paddingVertical: 'simple',
  paddingHorizontal: 'half',
};

const navBarButtonListElementCss = ouiStyle.makeCss(navBarButtonListElementStyle);

export const NavBarButtonListElement = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  return <li css={navBarButtonListElementCss}>{children}</li>;
};

export default NavBarButtonListElement;
