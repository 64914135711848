/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';

const toolbarDropDownLabelContainerStyle: ouiStyle.InStyle = {
  fontSize: 'm',
  color: ouiStyle.Constants.Colors.inverted,
  display: ouiStyle.Constants.DisplayProp.flex,
  flexDirection: ouiStyle.Constants.InFlexDirectionProp.row,
  alignItems: ouiStyle.Constants.AlignItemsProp.center,
  justifyContent: ouiStyle.Constants.JustifyContentProp.center,
  lines: 'full',
};

const toolbarDropDownLabelContainerCss = ouiStyle.makeCss(toolbarDropDownLabelContainerStyle);

export const ToolbarDropDownLabelContainer = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  return (
    <div
      css={toolbarDropDownLabelContainerCss}
    >
      {children}
    </div>
  );
};
