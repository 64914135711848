import * as React from 'react';
import { ActorApiMapper } from '../../../../../../components';
import { AccountAvailableCash } from '../../type';
import { useAccountAvailableCashList } from '../../hooks';

const defaultLoadingList = [
  'EUR',
  'USD',
];

export const AccountAvailableCashMapper = (props: React.PropsWithChildren<{
  filterPredicate?: (data: AccountAvailableCash) => boolean,
  limit?: number,
  loadingList?: any[],
}>) => {
  const {
    filterPredicate,
    loadingList = defaultLoadingList,
    limit,
    children,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useAccountAvailableCashList({ filterPredicate });
  return (
    <ActorApiMapper
      data={data}
      isLoading={isLoading}
      error={error}
      loadingList={loadingList}
      limit={limit}
    >
      {children}
    </ActorApiMapper>
  );
};
