/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const style: ouiStyle.InStyleWithMediaQueries = {
  padding: 'simple',
};

export type BooleanBondFilterTogglesContainerPadding = Omit<ouiStyle.Constants.InPaddingWithMediaQueries, 'iconContainer' | 'baselinePadding' | 'childPaddingTop' | 'childPaddingLeft' | 'childPaddingBottom' | 'childPaddingRight'>

export const BooleanBondFilterTogglesContainer = (props: React.PropsWithChildren<BooleanBondFilterTogglesContainerPadding>) => {
  const {
    padding,
    paddingHorizontal,
    paddingVertical,
    paddingTop,
    paddingLeft,
    paddingBottom,
    paddingRight,
    children,
  } = props;
  const css = React.useMemo(() => ouiStyle.mediaQuery({
    ...ouiStyle.makeCss({
      ...style,
      padding: padding ?? style.padding,
      paddingHorizontal: paddingHorizontal ?? style.paddingHorizontal,
      paddingVertical: paddingVertical ?? style.paddingVertical,
      paddingTop: paddingTop ?? style.paddingTop,
      paddingLeft: paddingLeft ?? style.paddingLeft,
      paddingBottom: paddingBottom ?? style.paddingBottom,
      paddingRight: paddingRight ?? style.paddingRight,
    }),
    display: 'grid',
    gridTemplateColumns: [
      'repeat(2, auto)',
      'repeat(2, auto)',
      'repeat(3, auto)',
      undefined,
      'repeat(4, auto)',
      'repeat(5, auto)',
    ],
    gridAutoRows: '1fr',
    gridColumnGap: '0',
    gridRowGap: '0',
  }), [
    padding,
    paddingHorizontal,
    paddingVertical,
    paddingTop,
    paddingLeft,
    paddingBottom,
    paddingRight,
  ]);
  return (
    <div
      css={css}
    >
      {children}
    </div>
  );
};
