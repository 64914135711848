import * as React from 'react';
import { sTypeGrpIdContext } from './context';

export const STypeGrpIdContextProvider = (props: React.PropsWithChildren<{
  sTypeGrpId: string,
}>) => {
  const {
    sTypeGrpId,
    children,
  } = props;
  return (
    <sTypeGrpIdContext.Provider value={sTypeGrpId}>
      {children}
    </sTypeGrpIdContext.Provider>
  );
};
