import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useAccountHistoryArDocFileNameExtension } from '../../hooks';

export const AccountHistoryArDocDocumentType = () => {
  const {
    data,
  } = useAccountHistoryArDocFileNameExtension();
  return (
    <Trans>
      Document&#32;
      {data}
    </Trans>
  );
};
