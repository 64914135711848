/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import { useMemo } from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { useListSectionNavScrollContainerRefCallback } from '../../../OUI/Scrollable';

const scrollableListContainerStyle: ouiStyle.InStyle = {
  display: ouiStyle.Constants.DisplayProp.flex,
  container: ouiStyle.Constants.Container.none,
};

type ScrollableListContainerDomPropsWithoutMediaQueries = {
  display?: ouiStyle.InStyle['display'],
  container?: ouiStyle.InStyle['container'],
  flexGrow?: ouiStyle.InStyle['flexGrow'],
  flexShrink?: ouiStyle.InStyle['flexShrink'],
  flexBasis?: ouiStyle.InStyle['flexBasis'],
} & ouiStyle.Constants.InMargin

type ScrollableListContainerDomPropsWithMediaQueries = {
  display?: ouiStyle.InStyleWithMediaQueries['display'],
  container?: ouiStyle.InStyleWithMediaQueries['container'],
  flexGrow?: ouiStyle.InStyleWithMediaQueries['flexGrow'],
  flexShrink?: ouiStyle.InStyleWithMediaQueries['flexShrink'],
  flexBasis?: ouiStyle.InStyleWithMediaQueries['flexBasis'],
} & ouiStyle.Constants.InMarginWithMediaQueries

export const ScrollableListContainerDom = (props: React.PropsWithChildren<ScrollableListContainerDomPropsWithoutMediaQueries | ScrollableListContainerDomPropsWithMediaQueries>) => {
  const {
    display = scrollableListContainerStyle.display,
    container = scrollableListContainerStyle.container,
    margin = scrollableListContainerStyle.margin,
    marginHorizontal = scrollableListContainerStyle.marginHorizontal,
    marginVertical = scrollableListContainerStyle.marginVertical,
    marginTop = scrollableListContainerStyle.marginTop,
    marginRight = scrollableListContainerStyle.marginRight,
    marginBottom = scrollableListContainerStyle.marginBottom,
    marginLeft = scrollableListContainerStyle.marginLeft,
    flexGrow = scrollableListContainerStyle.flexGrow,
    flexShrink = scrollableListContainerStyle.flexShrink,
    flexBasis = scrollableListContainerStyle.flexBasis,
    children,
  } = props;
  const refCallback = useListSectionNavScrollContainerRefCallback();
  const css = useMemo(() => ouiStyle.mediaQuery(ouiStyle.makeCss({
    display,
    container,
    margin,
    marginHorizontal,
    marginVertical,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    flexGrow,
    flexShrink,
    flexBasis,
  })), [
    display,
    container,
    margin,
    marginHorizontal,
    marginVertical,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    flexGrow,
    flexShrink,
    flexBasis,
  ]);
  return (
    <ul
      ref={refCallback}
      css={css}
    >
      {children}
    </ul>
  );
};
