import React from 'react';
import ouiBase from '@goldwasserexchange/oui';
import ouiDom from '@goldwasserexchange/oui-dom';
import ouiStyle from '@goldwasserexchange/oui-style';

const formatPhoneNumber = (number) => {
  const to = number
    .replace(/^00/g, '+')
    .replace(/\(\d+\)/g, '')
    .replace(/[^\d+]/g, '');
  return to.includes('+') ? to : `+32${parseInt(to, 10)}`;
};

export const PhoneNumber = (props: React.PropsWithChildren<{ number } & Omit<ouiBase.A.AnchorProps, 'href'>>) => {
  const {
    number,
    children,
    ...rest
  } = props;
  return (
    <ouiDom.A.A
      href={`tel:${formatPhoneNumber(number)}`}
      whiteSpace={ouiStyle.Constants.WhiteSpaceProperty.noWrap}
      {...rest}
    >
      {children || number}
    </ouiDom.A.A>
  );
};

PhoneNumber.displayName = 'PhoneNumber';

export const GEPhoneNumber = (props: React.PropsWithChildren<Omit<ouiBase.A.AnchorProps, 'href'> & {
  spaceBefore?: boolean,
  spaceAfter?: boolean,
}>) => {
  const {
    spaceBefore,
    spaceAfter,
    children,
    ...rest
  } = props;
  return (
    <>
      {spaceBefore ? ' ' : null}
      <PhoneNumber
        number="+32 (0)2 533 22 40"
        {...rest}
      >
        {children}
      </PhoneNumber>
      {spaceAfter ? ' ' : null}
    </>
  );
};

GEPhoneNumber.displayName = 'GEPhoneNumber';
