/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const searchPlaceIconContainerStyle: ouiStyle.InStyle = {
  flex: 'none',
  padding: 'simple',
  iconContainer: true,
  color: ouiStyle.Constants.Colors.accent,
  borderLeftWidth: 'simple',
  borderLeftStyle: 'solid',
  borderLeftColor: ouiStyle.Constants.Colors.primary,
  lines: 1,
  widthLines: 1,
};

export const SearchPlaceIconContainer = (props: React.PropsWithChildren<{
  inputRef: React.RefObject<HTMLInputElement>,
  open: boolean,
  focus: boolean,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
}>) => {
  const {
    open,
    focus,
    inputRef,
    setOpen,
    children,
  } = props;
  const searchPlaceIconContainerCss = React.useMemo(
    () => ouiStyle.makeCss({
      ...searchPlaceIconContainerStyle,
      color: !focus ? ouiStyle.Constants.Colors.accent : ouiStyle.Constants.Colors.primary,
      borderLeftColor: !focus ? ouiStyle.Constants.Colors.primary : ouiStyle.Constants.Colors.accent,
    }),
    [focus],
  );
  const onClick = React.useCallback(() => {
    if (open) {
      setOpen(false);
    } else {
      if (inputRef.current) {
        inputRef.current.focus();
      }
      setOpen(true);
    }
  }, [setOpen, open]);
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      css={searchPlaceIconContainerCss}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
