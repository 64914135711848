import * as React from 'react';
import { useOuiStyleMediaContextByIndex } from '../../../../../../../../Components/MediaContext';
import { ActorApiNumberDisplay } from '../../../../../../../components/display/number/component';
import { useBondsBondGesRatValGeShortcut } from '../../../hooks';
import { BondDetailsHeaderRiskClassContainer } from './container';
import { BondDetailsRiskClassIndicators } from './indicators';

export const BondsBondRiskClass = () => {
  const {
    data,
    isLoading,
    error,
  } = useBondsBondGesRatValGeShortcut();
  return (
    <ActorApiNumberDisplay
      data={data}
      isLoading={isLoading}
      error={error}
      format={{
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }}
    />
  );
};

export const BondsBondRiskClassScale = () => (
  <BondDetailsHeaderRiskClassContainer>
    <BondDetailsRiskClassIndicators />
  </BondDetailsHeaderRiskClassContainer>
);

export const BondsBondRiskClassWithMediaQuery = (props: {
  mediaQueryIndex: number,
}) => {
  const {
    mediaQueryIndex,
  } = props;
  const up = useOuiStyleMediaContextByIndex(mediaQueryIndex);
  if (up) {
    return (
      <BondsBondRiskClassScale />
    );
  }
  return <BondsBondRiskClass />;
};
