var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as React from 'react';
import { useSpring, } from 'react-spring';
import ouiStyle from '@goldwasserexchange/oui-style';
var rippleStyle = {
    transform: 'scale(0)',
    borderRadius: 'full',
    position: 'absolute',
    opacity: 0.75,
    backgroundColor: ouiStyle.Constants.Colors.inverted,
    lines: 'full',
    widthLines: 'full',
};
var rippleBaseCss = ouiStyle.makeCssAndExtract(rippleStyle, ['transform', 'opacity']);
var rippleClickedStyle = __assign(__assign({}, rippleStyle), { transform: 'scale(2)', opacity: 0 });
var rippleClickedCss = ouiStyle.makeCssAndExtract(rippleClickedStyle, ['transform', 'opacity']);
export var useRipple = function () {
    var _a = __read(useSpring(function () { return ({
        from: rippleBaseCss,
        to: rippleBaseCss,
        reset: true,
        immediate: false,
    }); }), 2), style = _a[0], springApi = _a[1];
    var onClick = React.useCallback(function () {
        springApi.start({
            from: rippleBaseCss,
            to: rippleClickedCss,
        });
    }, [springApi]);
    return [style, onClick];
};
