import lmlGet from '@goldwasserexchange/utils/lib/lmlGet';
import { useCallback } from 'react';
import { getIdkZLang } from '../../../../Components/LmlText';

export const makeLmlGet = (language: string) => (lml: string): string => {
  const idk = getIdkZLang(language);
  return lmlGet(idk, lml);
};

export const useLmlGet = (language: string) => {
  const lmlGetTransform = useCallback(makeLmlGet(language), [language]);
  return lmlGetTransform;
};
