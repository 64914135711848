import { dataStructure, AccountType } from '@goldwasserexchange/oblis-frontend-utils';
import ouiBase from '@goldwasserexchange/oui';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { Trans } from '@lingui/macro';
import * as React from 'react';
import { noIcon, yesIcon } from '../components/yesNoIcons';

export const PERSONAL_SALESPERSON = 'personalSalesPerson';

const personalSalesPersonIcon = {
  [AccountType.RTO]: {
    icon: noIcon,
  },
  [AccountType.CONSEIL]: {
    icon: yesIcon,
  },
  [AccountType.GESTION]: {
    icon: yesIcon,
  },
};

export const PersonalSalesPersonIcon = (props: { accountType: dataStructure.accountType.listType }) => {
  const { accountType } = props;
  return personalSalesPersonIcon[accountType].icon;
};

export const PersonalSalesPersonIconOnPrimaryDimension = () => {
  const accountType = ouiBase.utils.dimensions.useCurrentPrimaryDimension() as dataStructure.accountType.listType;
  return (
    <PersonalSalesPersonIcon accountType={accountType} />
  );
};

const personalSalesPersonLabel = (
  <Trans>
    Délégué commercial attitré
  </Trans>
);

export const personalSalesPersonTopModels = (
  <>
    <TopModel name={`TableSecondaryTitleCellContent-${PERSONAL_SALESPERSON}`}>
      {personalSalesPersonLabel}
    </TopModel>
    <TopModel name={`TableBodyRowCellContent--${PERSONAL_SALESPERSON}`}>
      {PersonalSalesPersonIconOnPrimaryDimension}
    </TopModel>
  </>
);
