import { useFilterByBySTypeGrpId } from '../../utils';
import { usePositionsSubGroupFieldApi } from '../api';

export const usePositionSubGroupSGTypeId = () => {
  const filterPredicate = useFilterByBySTypeGrpId();
  const result = usePositionsSubGroupFieldApi({
    filterPredicate,
    field: 'S_GTYPE.ID',
  });
  return result;
};
