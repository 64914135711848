export var compactTable = function (value) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var compactedTable = [];
    var currentColSpan = 0;
    var rowLength = value.rows.length - 1;
    while (rowLength >= 0) {
        if (!compactedTable[rowLength]) {
            compactedTable[rowLength] = [];
        }
        var cells = value.rows[rowLength].cells;
        var cellLength = cells.length - 1;
        while (cellLength >= 0) {
            var cell = cells[cellLength];
            if (cell === '<') {
                if (cellLength - 1 < 0) {
                    throw new Error('try to colspan out of table');
                }
                compactedTable[rowLength][cellLength] = undefined;
                currentColSpan += 1;
            }
            else if (cell === '^') {
                if (rowLength - 1 < 0) {
                    throw new Error('try to rowspan out of table');
                }
                if (!compactedTable[rowLength - 1]) {
                    compactedTable[rowLength - 1] = [];
                }
                compactedTable[rowLength - 1][cellLength] = {
                    content: '',
                    rowSpan: ((_c = (_b = (_a = compactedTable[rowLength - 1]) === null || _a === void 0 ? void 0 : _a[cellLength]) === null || _b === void 0 ? void 0 : _b.rowSpan) !== null && _c !== void 0 ? _c : 1) + ((_f = (_e = (_d = compactedTable[rowLength]) === null || _d === void 0 ? void 0 : _d[cellLength]) === null || _e === void 0 ? void 0 : _e.rowSpan) !== null && _f !== void 0 ? _f : 1),
                };
                compactedTable[rowLength][cellLength] = undefined;
            }
            else {
                compactedTable[rowLength][cellLength] = {
                    content: cell,
                    colSpan: currentColSpan + 1,
                    rowSpan: (_j = (_h = (_g = compactedTable === null || compactedTable === void 0 ? void 0 : compactedTable[rowLength]) === null || _g === void 0 ? void 0 : _g[cellLength]) === null || _h === void 0 ? void 0 : _h.rowSpan) !== null && _j !== void 0 ? _j : 1,
                };
                currentColSpan = 0;
            }
            cellLength -= 1;
        }
        currentColSpan = 0;
        rowLength -= 1;
    }
    return compactedTable.map(function (row) { return (row.some(function (cell) { return cell != null; }) ? row : []); });
};
