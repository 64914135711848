/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import React from 'react';
import {
  C_COUNTRY, C_TIT, UncastedDataStructure,
} from '@goldwasserexchange/oblis-frontend-utils';
import ouiBase from '@goldwasserexchange/oui';
import { LmlFieldValueSnippet, FieldValueSnippet } from '../../Form/utils';

const postalAddressParagraphStyle: ouiStyle.InStyle = {
  minLines: 2,
  paddingLeft: 'double',
};

const postalAddressParagraphCss = ouiStyle.makeCss(postalAddressParagraphStyle);

type PostalAddressprops = {
  userKey: string | number,
  withoutName?: boolean,
};

export const UserPostalAddress = (props: PostalAddressprops) => {
  const {
    userKey,
    withoutName = false,
  } = props;
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const userState = valueState.onboarding.users[userKey];
  return (
    <React.Fragment>
      {!withoutName && (
        <p css={postalAddressParagraphCss}>
          <LmlFieldValueSnippet
            fieldState={userState.ID_C_TIT}
            inputList={C_TIT.IdLmlDescriptionInputPhysicPerson}
          />
          {' '}
          <FieldValueSnippet
            fieldState={userState.FIRSTNAME}
          />
          {' '}
          <FieldValueSnippet
            fieldState={userState.NAME_1}
          />
        </p>
      )}
      <p css={postalAddressParagraphCss}>
        <FieldValueSnippet
          fieldState={userState.address.STREET_1}
        />
      </p>
      <p css={postalAddressParagraphCss}>
        <FieldValueSnippet
          fieldState={userState.address.ZIP}
        />
        {' '}
        <FieldValueSnippet
          fieldState={userState.address.CITY}
        />
      </p>
      <p css={postalAddressParagraphCss}>
        <LmlFieldValueSnippet
          fieldState={userState.address.ID_C_COUNTRY}
          inputList={C_COUNTRY.IdLmlDescriptionInput}
        />
      </p>
    </React.Fragment>
  );
};
