import { atom } from 'jotai';
import { pathnamePartFamilly } from '../../../../atoms';

export const pathnameUrlBondDetailsIdAtom = atom((get) => {
  const subSubSection = get(pathnamePartFamilly(3));
  if (subSubSection == null) {
    return null;
  }
  const parsedId = parseInt(subSubSection, 10);
  if (Number.isNaN(parsedId)) {
    return null;
  }
  return parsedId;
});
