import { AccountPosition } from '../type';

export const computePositionValue = (position: AccountPosition | undefined | null) => (position ? position['GES_ESTIM_D.ESTIM'] + (position['GES_ESTIM_D.MT_INT'] ?? 0) : 0);

export const computePositionsValue = (positions: AccountPosition[] | undefined | null) => (positions && Array.isArray(positions) ? positions.reduce((acc, position) => acc + computePositionValue(position), 0) : 0);

export const computePositionsValueForYield = (positions: AccountPosition[] | undefined | null) => (positions && Array.isArray(positions)
  ? positions.reduce((acc, position) => (acc + (position['GES_ESTIM_D.YIELD'] ? computePositionValue(position) : 0)), 0)
  : 0
);

export const computePositionsYieldWeightedByValue = (positions: AccountPosition[] | undefined | null) => (positions && Array.isArray(positions)
  ? positions.reduce((acc, line) => {
    const positionValueWithMtInt = computePositionValue(line);
    const wheightedValuationWithMtInt = (line['GES_ESTIM_D.YIELD'] ?? 0) * positionValueWithMtInt;
    return acc + wheightedValuationWithMtInt;
  }, 0)
  : 0);
