import { Locale } from '@goldwasserexchange/i18n';
import * as React from 'react';
import { useAtomValue } from 'jotai';
import { pathnameUrlLanguageAtom, locationPathnameAtom, locationSearchAtom } from '../../../../../../history';
import { ToolbarDropDownListElementLink } from '../../../toolbarDropDown';

export const NormalLanguageToolbarDropDownListElementLink = (props: React.PropsWithChildren<{
  locale: Locale,
}>) => {
  const {
    locale,
    children,
  } = props;
  const currentLanguage = useAtomValue(pathnameUrlLanguageAtom);
  const pathname = useAtomValue(locationPathnameAtom);
  const search = useAtomValue(locationSearchAtom);
  const localeRegExp = new RegExp(`/${currentLanguage}`);
  const localeReplace = `/${locale}`;
  const localeReplaced = pathname !== '/' ? pathname.replace(localeRegExp, localeReplace) : localeReplace;
  const to = `${localeReplaced}${search}`;
  return (
    <ToolbarDropDownListElementLink
      to={to}
      textAlign="center"
      textTransform="uppercase"
    >
      {children}
    </ToolbarDropDownListElementLink>
  );
};
