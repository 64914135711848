import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import ouiDom from '@goldwasserexchange/oui-dom';

export const FileIconModalButtonContainer = (props: React.PropsWithChildren<{
  onClick: React.MouseEventHandler<HTMLButtonElement>,
  hasFileName?: boolean,
  transform?: ouiStyle.InStyle['transform'],
}>) => {
  const {
    onClick,
    hasFileName,
    transform,
    children,
  } = props;
  return (
    <ouiDom.Button.Button
      type="button"
      onClick={onClick}
      paddingTop="none"
      paddingLeft="none"
      paddingRight="none"
      paddingBottom="none"
      borderWidth="none"
      cursor="pointer"
      transform={transform}
      disabled={!hasFileName}
    >
      {children}
    </ouiDom.Button.Button>
  );
};
