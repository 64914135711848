/* eslint-disable camelcase */
import { Auth } from '@aws-amplify/auth';
import { uniq, isNil } from 'ramda';
import { useCallback } from 'react';
import { throttledGetTokens } from '../../../../../../aws';
import { CognitoUserWithChallengeName } from '../../../../../../Components/Auth/type';
import { AuthSendType } from '../../../../../../Components/Auth/context/context';
import { useAuthMachineSend } from '../../../../../../Components/Auth';

type Device = {
  DeviceKey: string,
  DeviceAttributes: { Name: string, Value: string }[],
  DeviceLastAuthenticatedDate: number,
};

const findDeviceAttribute = (
  device: Device,
  name: string,
) => {
  const found = device.DeviceAttributes.find((deviceAttirbute) => deviceAttirbute.Name === name);
  return found;
};

const fetchIpInfo = async (ips: string[]) => {
  const headers = new Headers();
  headers.append('Content-type', 'application/json');
  const body = JSON.stringify(ips.map((query) => ({
    query,
    lang: 'en',
    fields: 'country,isp,query',
  })));
  const response = await fetch('https://na3jy3104j.execute-api.eu-west-1.amazonaws.com/prod/batch', {
    method: 'POST',
    headers,
    body,
  });
  return response.json() as Promise<{
    query: string,
    country: string,
    isp: string,
  }[]>;
};

const listDevices = (user: CognitoUserWithChallengeName, limit: number, paginationToken: string | null) => new Promise<{
  Devices?: Device[],
} | undefined>((res, rej) => {
  user.listDevices(limit, paginationToken, {
    onSuccess: (data) => res(data),
    onFailure: (err) => rej(err),
  });
});

export const deviceFetcher = async (_url: string, send?: AuthSendType) => {
  await throttledGetTokens(send);
  const user = await Auth.currentAuthenticatedUser() as CognitoUserWithChallengeName;
  const data = await listDevices(user, 60, null);
  if (data && data.Devices) {
    const ips = data.Devices
      .map((device) => findDeviceAttribute(device, 'last_ip_used')?.Value)
      .filter<string>((ip): ip is string => !isNil(ip));
    const uniqueIps = uniq(ips);
    const ipInfos = await fetchIpInfo(uniqueIps);
    const result = data.Devices
      .map((device) => {
        const last_ip_used = findDeviceAttribute(device, 'last_ip_used')?.Value ?? '';
        const ipInfo = ipInfos.find((info) => info.query === last_ip_used) ?? {
          country: '',
          isp: '',
        };
        const {
          country,
          isp,
        } = ipInfo;
        const formatted = ({
          id: device.DeviceKey,
          value: findDeviceAttribute(device, 'device_name')?.Value ?? '',
          last_ip_used,
          country,
          isp,
          lastAuthentificationDate: device.DeviceLastAuthenticatedDate,
        });
        return formatted;
      });
    return result;
  }
  return [];
};

export const makeDeviceFetcher = (send: AuthSendType) => (_url: string) => deviceFetcher(_url, send);

export const useDeviceFetcherWithSend = () => {
  const send = useAuthMachineSend();
  const fetcher = useCallback(makeDeviceFetcher(send), [send]);
  return fetcher;
};
