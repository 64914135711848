import {
  AccountType,
  dataStructure,
  DurabilityPreferences,
  DurabilityTaxonomy,
  DurabilitySFDR,
  DurabilityIncidence,
  DurabilityIncidenceType,
  UncastedOnboarding,
  UncastedDataStructure,
} from '@goldwasserexchange/oblis-frontend-utils';
import { object } from 'yup';
import {
  booleanValidator,
  invalidDurabilityError,
  makeRequired,
  nilValidator,
  numberValidator,
  stringValidator,
} from '../../../../../../OUI/Inputs';
import { makeWhenAlwaysCast } from '../../../../../Form/validations/whenAlwaysCast';

export const durabilityPreferencesValidator = makeWhenAlwaysCast(['$'], (context: { rootValue: UncastedDataStructure}) => {
  const {
    rootValue,
  } = context;
  const {
    onboarding,
  } = rootValue;
  const {
    durability,
  } = onboarding;
  const {
    preferences,
    taxonomy,
    sfdr,
    incidence,
  } = durability;
  if (preferences === DurabilityPreferences.SPECIFIC_TRUE && taxonomy === '0' && sfdr === '0' && incidence === '0') {
    return makeRequired(stringValidator).test(
      'invalidBirthdateInRegisterNumber',
      invalidDurabilityError,
      () => false,
    );
  }
  return makeRequired(stringValidator);
});

export const durabilityTaxonomyValidator = makeRequired(booleanValidator);

export const durabilitySfdrValidator = makeRequired(booleanValidator);

export const durabilityIncidenceValidator = makeRequired(booleanValidator);

export const durabilityTaxonomyAlignmentValidator = makeRequired(numberValidator);

export const durabilityTaxonomyProportionValidator = makeRequired(numberValidator);

export const durabilitySfdrDurableProportion = makeRequired(numberValidator);

export const durabilitySfdrSocialEnvironmentalProportion = makeRequired(numberValidator);

export const durabilitySfdrDurableOnlyValidator = makeRequired(booleanValidator);

export const durabilitySfdrSocialEnvironmentalOnlyValidator = makeRequired(booleanValidator);

export const durabilityIncidenceTypeValidator = makeRequired(stringValidator);

export const durabilityIncidenceProportionValidator = makeRequired(numberValidator);

const durabilityValidatorDependencyFields: (keyof UncastedOnboarding)[] = [
  'selectedService',
];

export const durabilityValidator = makeWhenAlwaysCast(
  durabilityValidatorDependencyFields,
  (serviceSelection: dataStructure.accountType.listType, _, { value }) => {
    if (serviceSelection !== AccountType.CONSEIL && serviceSelection !== AccountType.GESTION) {
      return nilValidator;
    }
    const {
      preferences,
      taxonomy,
      sfdr,
      incidence,
    } = value;
    const shape: Record<keyof UncastedOnboarding['durability'], any> = {
      preferences: durabilityPreferencesValidator,
      taxonomy: preferences === DurabilityPreferences.SPECIFIC_TRUE
        ? durabilityTaxonomyValidator
        : nilValidator,
      taxonomyAlignment: (
        preferences === DurabilityPreferences.SPECIFIC_TRUE
        && taxonomy === DurabilityTaxonomy.YES
      )
        ? durabilityTaxonomyAlignmentValidator
        : nilValidator,
      taxonomyProportion: (
        preferences === DurabilityPreferences.SPECIFIC_TRUE
        && taxonomy === DurabilityTaxonomy.YES
        && serviceSelection === AccountType.GESTION
      )
        ? durabilityTaxonomyProportionValidator
        : nilValidator,
      sfdr: preferences === DurabilityPreferences.SPECIFIC_TRUE
        ? durabilitySfdrValidator
        : nilValidator,
      sfdrDurableProportion: (
        preferences === DurabilityPreferences.SPECIFIC_TRUE
        && sfdr === DurabilitySFDR.YES
        && serviceSelection === AccountType.GESTION
      )
        ? durabilitySfdrDurableProportion
        : nilValidator,
      sfdrSocialEnvironmentalProportion: (
        preferences === DurabilityPreferences.SPECIFIC_TRUE
        && sfdr === DurabilitySFDR.YES
        && serviceSelection === AccountType.GESTION
      )
        ? durabilitySfdrSocialEnvironmentalProportion
        : nilValidator,
      sfdrDurableOnly: (
        preferences === DurabilityPreferences.SPECIFIC_TRUE
        && sfdr === DurabilitySFDR.YES
        && serviceSelection === AccountType.CONSEIL
      )
        ? durabilitySfdrDurableOnlyValidator
        : nilValidator,
      sfdrSocialEnvironmentalOnly: (
        preferences === DurabilityPreferences.SPECIFIC_TRUE
        && sfdr === DurabilitySFDR.YES
        && serviceSelection === AccountType.CONSEIL
      )
        ? durabilitySfdrSocialEnvironmentalOnlyValidator
        : nilValidator,
      incidence: preferences === DurabilityPreferences.SPECIFIC_TRUE
        ? durabilityIncidenceValidator
        : nilValidator,
      incidenceType: (
        preferences === DurabilityPreferences.SPECIFIC_TRUE
        && incidence === DurabilityIncidence.YES
      )
        ? object().shape({
          [DurabilityIncidenceType.BIODIVERSITY]: makeRequired(booleanValidator),
          [DurabilityIncidenceType.GREENHOUSE_GAS]: makeRequired(booleanValidator),
          [DurabilityIncidenceType.TRASH]: makeRequired(booleanValidator),
          [DurabilityIncidenceType.WATER]: makeRequired(booleanValidator),
          [DurabilityIncidenceType.SOCIAL_AND_PERSON]: makeRequired(booleanValidator),
        })
        : nilValidator,
      incidenceProportion: (
        preferences === DurabilityPreferences.SPECIFIC_TRUE
        && incidence === DurabilityIncidence.YES
        && serviceSelection === AccountType.GESTION
      )
        ? durabilityIncidenceProportionValidator
        : nilValidator,
    };
    return object().shape(shape);
  },
);

export const DEFAULT_DURABILITY_INITIAL_VALUES: UncastedOnboarding['durability'] = {
  preferences: '',
  taxonomy: '',
  taxonomyAlignment: '',
  taxonomyProportion: '',
  sfdr: '',
  sfdrDurableProportion: '',
  sfdrSocialEnvironmentalProportion: '',
  sfdrDurableOnly: '',
  sfdrSocialEnvironmentalOnly: '',
  incidence: '',
  incidenceType: {
    [DurabilityIncidenceType.BIODIVERSITY]: '0',
    [DurabilityIncidenceType.GREENHOUSE_GAS]: '0',
    [DurabilityIncidenceType.TRASH]: '0',
    [DurabilityIncidenceType.WATER]: '0',
    [DurabilityIncidenceType.SOCIAL_AND_PERSON]: '0',
  },
  incidenceProportion: '',
};
