import { PersonDevices } from '../type';
import { usePersonDevicesTransformApi } from './api';

const transformFunction = (v: PersonDevices[] | undefined) => (v ? v.map((line) => line.id) : []);

const sortPredicate = (prev: PersonDevices, next: PersonDevices) => next.lastAuthentificationDate - prev.lastAuthentificationDate;

export const useDeviceList = () => {
  const result = usePersonDevicesTransformApi({
    sortPredicate,
    transformFunction,
  });
  return result;
};
