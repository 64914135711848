/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { ScrollableWrapperDom } from '../../../../../../OUI-dom/Scrollable/components';

export const timeNavWrapper = (
  <TopModel
    name="ScrollableWrapper"
    flexGrow={6}
    flexShrink={false}
  >
    {ScrollableWrapperDom}
  </TopModel>
);
