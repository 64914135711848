import { PersonAccounts } from '../../type';
import { usePersonAccountsTransformApi } from '../api';

const transformFunction = (personAccounts: PersonAccounts[] | undefined) => (personAccounts ? personAccounts.map((personAccount) => `${personAccount['T_ACC.ID']}`) : []);

const sortPredicate = (prev: PersonAccounts, next: PersonAccounts) => {
  const prevRoot = prev['T_ACC.ROOT'];
  const nextRoot = next['T_ACC.ROOT'];
  return prevRoot - nextRoot;
};

export const usePersonsAccountsList = (props: {
  filterPredicate?: ((data: PersonAccounts) => boolean),
}) => {
  const {
    filterPredicate,
  } = props;
  const result = usePersonAccountsTransformApi({
    sortPredicate,
    transformFunction,
    filterPredicate,
  });
  return result;
};
