import React, {
  useState, PropsWithChildren,
} from 'react';
import identity from 'ramda/es/identity';
import F from 'ramda/es/F';
import {
  makeName, UncastedDataStructure, UncastedOnboarding, UncastedPhysicalTAdd,
} from '@goldwasserexchange/oblis-frontend-utils';
import { UserPlus, UserX } from 'react-feather';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { SelectionListContext, SelectionMap } from '../../BaseComponents/List/Selection';
import {
  FormFieldArrayLengthProvider, FormArrayFilterFn, useFormArrayWithFilter,
} from '../utils';
import { ScrollableIconWithLegendSelectAndAdd, ScrollableIconWithLegendSelectWithAddProps } from '../../IconWithLegend';
import { useLayout, renderSlot } from '../../Layout/helpers';
import {
  ListPrimaryKeyContextProvider,
} from '../../BaseComponents/List';
import { ClearFixDiv } from '../../clearfix';
import { FormArrayRemoveButton, BottomAddButton, FormArrayRemoveButtonProps } from '../BottomAddRemoveButtons';
import { IconName } from '../../Icons';
import { ArrayLabel } from './label';
import { BoxAside } from './aside';
import { ArrayList } from './list';
import { BoxContainer } from './boxContainer';
import { InputBox } from './box';
import { InputMain } from './main';

type ArrayInputBaseProps = {
  iconName: IconName,
  filterFn?: FormArrayFilterFn | ((tAdd: UncastedPhysicalTAdd) => boolean),
}

export type ArrayInputProps = ArrayInputBaseProps & {
  addIconName: IconName,
  height: string,
  hasAdd?: boolean,
  hasRemove?: boolean,
  elementWidth: number,
  gap: number,
  addValue: ScrollableIconWithLegendSelectWithAddProps['addValue'],
  resetUnlessFn?: FormArrayRemoveButtonProps['resetUnlessFn'],
  minLength?: number,
  whiteListColumns?: string[],
  blackListColumns?: string[],
}

const onboardingPath: keyof Pick<UncastedDataStructure, 'onboarding'> = 'onboarding';

const usersPath: keyof Pick<UncastedOnboarding, 'users'> = 'users';

export const ArrayInput = (props: PropsWithChildren<ArrayInputProps>): JSX.Element => {
  const {
    hasAdd = true,
    hasRemove = true,
    filterFn,
    iconName,
    addIconName,
    height,
    elementWidth,
    gap,
    addValue,
    resetUnlessFn = F,
    minLength = 0,
    whiteListColumns = [],
    blackListColumns = [],
    children,
  } = props;
  const {
    label, legend, addText, input, removeText,
  } = useLayout(children);
  const [index, setIndexBase] = useState<null | number | string>(null);
  const setIndex = (indexParam: null | number | string) => () => setIndexBase(indexParam);
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <SelectionListContext.Provider value={[index, setIndex]}>
      <ArrayLabel>
        {label}
      </ArrayLabel>
      <ListPrimaryKeyContextProvider primaryKeyFn={identity}>
        <FormFieldArrayLengthProvider>
          <ScrollableIconWithLegendSelectAndAdd
            iconName={iconName}
            addIconName={addIconName}
            height={height}
            elementWidth={elementWidth}
            gap={gap}
            filterFn={filterFn}
            addValue={addValue}
            hasAdd={hasAdd}
            whiteListColumns={whiteListColumns}
            blackListColumns={blackListColumns}
          >
            {legend}
            {addText}
          </ScrollableIconWithLegendSelectAndAdd>
          <ClearFixDiv>
            {index != null ? renderSlot(input, [makeName(onboardingPath, usersPath, index), index]) : null}
          </ClearFixDiv>
          {hasAdd && (
            <BottomAddButton
              addValue={addValue}
            >
              <TopModel name="ButtonIconContent">
                <UserPlus />
              </TopModel>
              {addText}
            </BottomAddButton>
          )}
          {hasRemove ? (
            <FormArrayRemoveButton
              resetUnlessFn={resetUnlessFn}
              filterFn={filterFn || F}
              minLength={minLength}
            >
              <TopModel name="ButtonIconContent">
                <UserX />
              </TopModel>
              {removeText}
            </FormArrayRemoveButton>
          ) : null}
        </FormFieldArrayLengthProvider>
      </ListPrimaryKeyContextProvider>
    </SelectionListContext.Provider>
  );
};

export type ArrayListInputBaseProps = ArrayInputBaseProps & {
  cardWidth: number,
}

const ArrayListInputBase = (props: PropsWithChildren<ArrayListInputBaseProps>): JSX.Element => {
  const {
    filterFn,
    iconName,
    cardWidth,
    children,
  } = props;
  const data = useFormArrayWithFilter(filterFn);
  const {
    label, input, legend, elementTitle,
  } = useLayout(children);
  return (
    <div>
      <ArrayLabel>
        {label}
      </ArrayLabel>
      <ArrayList>
        <ListPrimaryKeyContextProvider primaryKeyFn={identity}>
          <SelectionMap data={data}>
            {(index) => (
              <BoxContainer>
                {elementTitle}
                <InputBox>
                  <BoxAside iconName={iconName} cardWidth={cardWidth}>
                    {legend}
                  </BoxAside>
                  <InputMain>
                    {renderSlot(input, [makeName(onboardingPath, usersPath, index), index])}
                  </InputMain>
                </InputBox>
              </BoxContainer>
            )}
          </SelectionMap>
        </ListPrimaryKeyContextProvider>
      </ArrayList>
    </div>
  );
};

export const ArrayListInput = (props: PropsWithChildren<ArrayListInputBaseProps>): JSX.Element => {
  const {
    filterFn,
    iconName,
    cardWidth,
    children,
  } = props;
  return (
    <FormFieldArrayLengthProvider>
      <ul>
        <ArrayListInputBase filterFn={filterFn} iconName={iconName} cardWidth={cardWidth}>
          {children}
        </ArrayListInputBase>
      </ul>
    </FormFieldArrayLengthProvider>
  );
};
