import { BondsCall } from '../../type';
import { useBondsCallLinesTransformApi } from '../api';

const transformFunction = (bondsCall: BondsCall[] | undefined) => {
  if (bondsCall == null) {
    return false;
  }
  return bondsCall.length > 0;
};

export const useBondsHasCalls = () => {
  const result = useBondsCallLinesTransformApi({
    transformFunction,
  });
  return result;
};
