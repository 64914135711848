import useSWR, { KeyedMutator } from 'swr';
import { useMemo } from 'react';
import { AccountIbans } from '../type';
import { Accounts } from '../../enum';
import { useAccountIbansStringifiedParams } from './defaultParams';
import { getServiceUrl } from '../../../../shared';
import { ACTOR_DEDUPING_INTERVAL } from '../../../shared';
import { useFetchWithAuthWithSend } from '../../../shared/utils/fetchWithAuth';
import { makeApiUrl } from '../../../../../../aws';

const useMakeAccountsIbansUrl = () => {
  const stringifiedParams = useAccountIbansStringifiedParams();
  const url = stringifiedParams !== null ? `${makeApiUrl('actor')}/${getServiceUrl(Accounts.Ibans)}${stringifiedParams}` : null;
  return url;
};

type BaseUseAccountIbanApiResponse = {
  isLoading: boolean,
  error?: any,
  mutate: KeyedMutator<AccountIbans[]>,
  isValidating: boolean,
}

type UseAccountIbansApiResponse = {
  data: undefined | AccountIbans[],
} & BaseUseAccountIbanApiResponse;

type FilterPredicate = (accountsIban: AccountIbans) => boolean;

type SortPredicate = (prevAccountIban: AccountIbans, nextAccountIban: AccountIbans) => number;

type UseAccountIbansApiProps = {
  filterPredicate?: FilterPredicate,
  sortPredicate?: SortPredicate,
};

export const useAccountIbansApi = (props: UseAccountIbansApiProps): UseAccountIbansApiResponse => {
  const {
    filterPredicate,
    sortPredicate,
  } = props;
  const url = useMakeAccountsIbansUrl();
  const fetcher = useFetchWithAuthWithSend();
  const result = useSWR<AccountIbans[], any>(
    url,
    fetcher,
    { dedupingInterval: ACTOR_DEDUPING_INTERVAL },
  );
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    let { data } = result;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      if (filterPredicate) {
        data = data.filter((iban) => filterPredicate(iban));
      }
    }
    return ({
      ...result,
      data,
      isLoading,
    });
  }, [result, filterPredicate, sortPredicate]);
  return resultWithLoading;
};

type TransformArrayFunction<T> = (accountIbans: AccountIbans[] | undefined) => T

type UseAccountIbansApiTransformProps<T> = {
  transformFunction: TransformArrayFunction<T>,
} & UseAccountIbansApiProps

type UseAccountIbanApiTransformResponse<T> = {
  data: T | undefined,
} & BaseUseAccountIbanApiResponse;

export function useAccountIbansTransformApi<T>(props: UseAccountIbansApiTransformProps<T>): UseAccountIbanApiTransformResponse<T> {
  const {
    filterPredicate,
    sortPredicate,
    transformFunction,
  } = props;
  const response = useAccountIbansApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: (!isLoading && !error) ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UseAccountIbanApiResponse = {
  data: AccountIbans | undefined,
} & BaseUseAccountIbanApiResponse;

export const useAccountIbanApi = (props: UseAccountIbansApiProps): UseAccountIbanApiResponse => {
  const {
    filterPredicate,
    sortPredicate,
  } = props;
  const url = useMakeAccountsIbansUrl();
  const fetcher = useFetchWithAuthWithSend();
  const result = useSWR<AccountIbans[], any>(
    url,
    fetcher,
    { dedupingInterval: ACTOR_DEDUPING_INTERVAL },
  );
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    // eslint-disable-next-line prefer-destructuring
    let data: AccountIbans[] | AccountIbans | undefined = result.data;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      if (filterPredicate) {
        data = data.find((iban) => filterPredicate(iban));
      }
      if (Array.isArray(data)) {
        // eslint-disable-next-line prefer-destructuring
        data = data[0];
      }
    }
    return ({
      ...result,
      data,
      isLoading,
    });
  }, [result, filterPredicate, sortPredicate]);
  return resultWithLoading;
};

type TransformFunction<T> = (accountIban: AccountIbans | undefined) => T

type UseAccountIbanApiTransformProps<T> = {
  transformFunction: TransformFunction<T>,
} & UseAccountIbansApiProps;

export function useAccountIbanTransformApi<T>(props: UseAccountIbanApiTransformProps<T>): UseAccountIbanApiTransformResponse<T> {
  const {
    filterPredicate,
    sortPredicate,
    transformFunction,
  } = props;
  const response = useAccountIbanApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: !isLoading && !error ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UseAccountIbanFieldApiResponse<Field extends keyof AccountIbans> = {
  data: AccountIbans[Field] | undefined,
} & BaseUseAccountIbanApiResponse;

type UseAccountIbanFieldApiProps<Field extends keyof AccountIbans> = {
  field: Field,
} & UseAccountIbansApiProps

export function useAccountIbanFieldApi<Field extends keyof AccountIbans>(props: UseAccountIbanFieldApiProps<Field>): UseAccountIbanFieldApiResponse<Field> {
  const {
    filterPredicate,
    sortPredicate,
    field,
  } = props;
  const response = useAccountIbanApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
    } = response;
    return {
      ...response,
      data: data ? data[field] : undefined,
    };
  }, [response, field]);
  return result;
}
