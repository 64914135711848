import * as React from 'react';
import { onClickContext } from './context';
import { useEnhanceHandler, FnMergeOrder } from '../../hooks';
import {
  useInputRefClickAndFocusOnClick,
  useInputRefFocusOnClick,
  useInputRefClickOnClick,
} from '../../../Inputs/context/inputRef/hooks';

export function OnClickSetContextProvider(
  props: React.PropsWithChildren<{
    onClick:
    | ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined,
  }>,
) {
  const { onClick, children } = props;
  return (
    <onClickContext.Provider value={onClick}>
      {children}
    </onClickContext.Provider>
  );
}

export function OnClickUnsetContextProvider(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  return (
    <onClickContext.Provider value={undefined}>
      {children}
    </onClickContext.Provider>
  );
}

export function OnClickEnhanceContextProvider(
  props: React.PropsWithChildren<{
    onClick:
    | ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined,
    order?: FnMergeOrder,
  }>,
) {
  const {
    onClick: onClickProps,
    order = FnMergeOrder.before,
    children,
  } = props;
  const contextOnClick = React.useContext(onClickContext);
  const onClick = useEnhanceHandler(contextOnClick, onClickProps, order);
  return (
    <onClickContext.Provider value={onClick}>
      {children}
    </onClickContext.Provider>
  );
}

export function OnClickOrContextProvider(
  props: React.PropsWithChildren<{
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    is: boolean,
  }>,
) {
  const { onClick: onClickProps, is, children } = props;
  const contextOnClick = React.useContext(onClickContext);
  const onClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (is === true) {
        onClickProps(e);
      } else if (contextOnClick) {
        contextOnClick(e);
      }
    },
    [is, onClickProps, contextOnClick],
  );
  return (
    <OnClickSetContextProvider onClick={onClick}>
      {children}
    </OnClickSetContextProvider>
  );
}

export function OnClickInputRefClickAndFocusSetContextProvider(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  const onClick = useInputRefClickAndFocusOnClick();
  return (
    <OnClickSetContextProvider onClick={onClick}>
      {children}
    </OnClickSetContextProvider>
  );
}

export function OnClickInputRefClickAndFocusEnhanceContextProvider(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  const onClick = useInputRefClickAndFocusOnClick();
  return (
    <OnClickEnhanceContextProvider onClick={onClick}>
      {children}
    </OnClickEnhanceContextProvider>
  );
}

export function OnClickInputRefFocusSetContextProvider(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  const onClick = useInputRefFocusOnClick();
  return (
    <OnClickSetContextProvider onClick={onClick}>
      {children}
    </OnClickSetContextProvider>
  );
}

export function OnClickInputRefFocusEnhanceContextProvider(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  const onClick = useInputRefFocusOnClick();
  return (
    <OnClickEnhanceContextProvider onClick={onClick}>
      {children}
    </OnClickEnhanceContextProvider>
  );
}

export function OnClickInputRefClickSetContextProvider(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  const onClick = useInputRefClickOnClick();
  return (
    <OnClickSetContextProvider onClick={onClick}>
      {children}
    </OnClickSetContextProvider>
  );
}

export function OnClickInputRefClickEnhanceContextProvider(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  const onClick = useInputRefClickOnClick();
  return (
    <OnClickEnhanceContextProvider onClick={onClick}>
      {children}
    </OnClickEnhanceContextProvider>
  );
}
