import * as React from 'react';
import { inputRefContext } from './context';

export const InputRefContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  const inputRef = React.useRef<HTMLInputElement>(null);
  return (
    <inputRefContext.Provider value={inputRef}>
      {children}
    </inputRefContext.Provider>
  );
};
