import * as React from 'react';
import { IconContainer } from '../container';

export const ContactIcon = (props: {
  style?: React.SVGAttributes<SVGSVGElement>['style'],
}) => {
  const {
    style,
  } = props;
  return (
    <IconContainer
      style={style}
    >
      <g>
        <path d="M69.5,33.6c-1.4,0-2.5-1.1-2.5-2.5,0-12.7-10.4-23.1-23.1-23.1-1.4,0-2.5-1.1-2.5-2.5,0-1.4,1.1-2.5,2.5-2.5h0c15.5,0,28.1,12.6,28.1,28.1,0,1.4-1.1,2.5-2.5,2.5h0Z" />
        <path d="M57.9,33.6c-1.3,0-2.3-.9-2.5-2.2-.3-2.9-1.7-5.6-3.8-7.7,0,0-.1-.1-.2-.2-1.9-2.3-4.7-3.8-7.8-4-1.4,0-2.4-1.3-2.3-2.7,0-1.4,1.3-2.4,2.7-2.3,4.3.3,8.4,2.3,11.2,5.7,2.9,2.8,4.7,6.6,5.2,10.6.2,1.4-.8,2.6-2.2,2.8,0,0-.2,0-.3,0Z" />
      </g>
      <path d="M52.6,72s-.1,0-.1,0c-3.8,0-7.5-.9-10.9-2.4-3.8-1.7-7.5-3.8-10.9-6.3-3.6-2.6-7-5.6-10.2-8.7-3.2-3.2-6.1-6.6-8.7-10.2-2.5-3.4-4.6-7-6.2-10.8-1.5-3.5-2.3-7.2-2.4-10.9,0-2.4.4-4.7,1.3-6.9.9-2.3,2.3-4.4,4.1-6,2.1-2.2,5.1-3.5,8.1-3.6,0,0,0,0,0,0,1.2,0,2.5.3,3.6.8,1.2.6,2.3,1.5,3.1,2.6l7,9.9c.6.8,1.1,1.7,1.5,2.7.4.9.6,1.8.6,2.8,0,1.3-.4,2.6-1.1,3.7-.6.9-1.3,1.8-2.1,2.7l-1.8,1.8c.7,1.1,1.5,2.1,2.3,3.1,1.5,1.7,2.9,3.2,4.3,4.7,1.8,1.7,3.2,3.1,4.5,4.2,1,.9,2,1.6,3.1,2.3l1.9-1.8c.8-.9,1.8-1.6,2.9-2.2,1.9-1.1,4.3-1.2,6.3-.3.9.4,1.8.9,2.6,1.4l10,7.1c1.1.8,2,1.8,2.6,3,.5,1.2.7,2.3.7,3.5,0,1.6-.4,3.1-1,4.5-.6,1.3-1.5,2.6-2.5,3.8-1.6,1.8-3.7,3.3-5.9,4.2-2.2.9-4.5,1.4-6.8,1.4ZM16.7,11.1c-1.7,0-3.4.8-4.6,2,0,0-.1.1-.2.2-1.3,1.2-2.3,2.6-2.9,4.2-.6,1.6-.9,3.3-.9,5,0,3.1.7,6.1,2,9,1.5,3.5,3.4,6.8,5.7,9.9,2.5,3.4,5.3,6.6,8.2,9.6,3,2.9,6.2,5.7,9.6,8.2,3.1,2.3,6.4,4.2,10,5.7,2.9,1.3,5.9,2,9,2,1.7,0,3.4-.3,5-1,1.6-.6,3-1.7,4.1-2.9.7-.8,1.3-1.7,1.7-2.6.4-.8.5-1.6.5-2.4,0-.5-.1-1-.3-1.5-.1-.3-.4-.6-.8-.9,0,0-.1,0-.2-.1l-10-7.1c-.5-.4-1.1-.7-1.7-.9-.6-.3-1.3-.3-1.8,0-.7.4-1.3.8-1.8,1.3h-.1c0,.1-2.2,2.2-2.2,2.2-1.4,1.3-3.6,1.6-5.3.6-1.5-.9-3-1.9-4.3-3.1-1.4-1.2-2.9-2.6-4.8-4.4-1.5-1.6-3-3.1-4.5-4.9-1.1-1.2-2-2.5-2.9-3.9-.2-.2-.3-.5-.4-.8,0,0,0-.2,0-.3-.6-1.6-.2-3.4,1-4.6l2.1-2.1c.6-.6,1-1.1,1.4-1.7.2-.4.3-.7.3-1,0-.3,0-.6-.2-.9-.3-.7-.6-1.2-1-1.7l-7-9.9c-.3-.4-.7-.8-1.1-1-.5-.2-1-.4-1.5-.4h0Z" />
    </IconContainer>
  );
};
