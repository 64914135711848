import * as React from 'react';
import { booleanSearchAndFilterBondParams } from '../../list';
import { BooleanBondFilterToggle } from '../toggle';

export const BooleanBondFilterToggleMapper = () => (
  <>
    {booleanSearchAndFilterBondParams.map((field) => (
      <BooleanBondFilterToggle
        key={field}
        field={field}
      />
    ))}
  </>
);
