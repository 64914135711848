import * as React from 'react';
import { Place, SearchForSuggestionsResult } from '@aws-sdk/client-location';
import { SearchPlaceResultContainer, SearchPlaceResultElement } from './components';

const loadingTextValue = '\u00F7\u00F7\u00F7\u00F7\u00F7\u00F7\u00F7 \u00F7\u00F7\u00F7\u00F7\u00F7\u00F7 \u00F7\u00F7\u00F7\u00F7\u00F7 \u00F7\u00F7\u00F7\u00F7';

const loadingData: SearchForSuggestionsResult[] = [
  {
    Text: loadingTextValue,
    PlaceId: 'loading-0',
  },
  {
    Text: loadingTextValue,
    PlaceId: 'loading-1',
  },
  {
    Text: loadingTextValue,
    PlaceId: 'loading-2',
  },
  {
    Text: loadingTextValue,
    PlaceId: 'loading-3',
  },
  {
    Text: loadingTextValue,
    PlaceId: 'loading-4',
  },
];

export const SearchPlaceResult = (props: {
  data: SearchForSuggestionsResult[] | null,
  isLoading: boolean,
  open: boolean,
  focus: boolean,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  onSelect: (result: Place) => void,
}) => {
  const {
    data,
    isLoading,
    open,
    focus,
    setOpen,
    onSelect,
  } = props;
  return (isLoading || data != null) && (open || focus)
    ? (
      <SearchPlaceResultContainer>
        {(!isLoading ? (data ?? loadingData) : loadingData).map((element, i) => (
          <SearchPlaceResultElement
            key={element.PlaceId ?? `placeindex-${i}`}
            placeId={element.PlaceId}
            text={element.Text}
            setOpen={setOpen}
            focus={focus}
            onSelect={onSelect}
          />
        ))}
      </SearchPlaceResultContainer>
    )
    : null;
};
