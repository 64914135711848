import { getFileNameExtensionFromArDoc } from '../../../../shared';
import { useFilterByPrimaryDimensionArDocId } from '../../utils';
import { useAccountDocumentTransformApi } from '../api';

export const useAccountDocumentArDocFileNameExtension = () => {
  const filterPredicate = useFilterByPrimaryDimensionArDocId();
  const response = useAccountDocumentTransformApi({
    filterPredicate,
    transformFunction: getFileNameExtensionFromArDoc,
  });
  return response;
};
