import { Locale } from '@goldwasserexchange/i18n';
import * as React from 'react';
import { useSanityNewsByUrlId } from '../../../../../../../../sanity';
import { ValidSections } from '../../../../../../../../history';
import { ToolbarDropDownListElementLink } from '../../../../../toolbarDropDown';

export const NewsArticleLanguageToolbarDropDownListElementLink = (props: React.PropsWithChildren<{
  locale: Locale,
}>) => {
  const {
    locale,
    children,
  } = props;
  const {
    data,
  } = useSanityNewsByUrlId();
  const slug = data?.translations?.find((translation) => translation.language === locale)?.slug;
  const to = `/${locale}/${ValidSections.NEWS}/${slug != null ? slug : ''}`;
  return (
    <ToolbarDropDownListElementLink
      to={to}
      textAlign="center"
      textTransform="uppercase"
    >
      {children}
    </ToolbarDropDownListElementLink>
  );
};
