import React, {
  useContext,
} from 'react';
import { T } from 'ramda';
import {
  usePrimaryKey,
  ElementPrimaryKeyContextProvider,
  ElementPrimaryKeyIndexContextProvider,
  ListPrimaryKeyContext,
} from '../PrimaryKey';
import { ListMap } from '../base';
import { SelectionListContext } from './context';

export * from './context';

export const useSelectedId = () => {
  const [selectedId] = useContext(SelectionListContext);
  return selectedId;
};

export const useIsSelected = () => {
  const primaryKey = usePrimaryKey();
  const selectedId = useSelectedId();
  return primaryKey === selectedId;
};

export const useSetSelected = () => {
  const [, setSelected] = useContext(SelectionListContext);
  const primaryKey = usePrimaryKey();
  return setSelected(primaryKey);
};

type SelectionMapProps = {
  data?: string[],
  renderFilterFn?: (dataElement: {}, index: number) => boolean,
  children: (d: string, index: number) => React.ReactNode | null,
}

export const SelectionMap = (props: SelectionMapProps) => {
  const {
    data = [] as string[],
    renderFilterFn = T,
    children,
  } = props;
  const primaryKeyFn = useContext(ListPrimaryKeyContext);
  return (
    <ListMap data={data} renderFilterFn={renderFilterFn}>
      {(elementData, index) => (primaryKeyFn
        ? (
          <ElementPrimaryKeyContextProvider data={elementData}>
            {children(elementData, index)}
          </ElementPrimaryKeyContextProvider>
        )
        : (
          <ElementPrimaryKeyIndexContextProvider>
            {children(elementData, index)}
          </ElementPrimaryKeyIndexContextProvider>
        )
      )}
    </ListMap>
  );
};

SelectionMap.displayName = 'SelectionMap';
