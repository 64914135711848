var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { makeName } from '../../../../makeName';
import * as financialProducts from '../../../financialProducts';
import * as pathUtils from '../../pathUtils';
import * as questionPaths from '../../questionPaths';
import * as financialProductsSelection from '../../../financialProductsSelection';
import { AccountType } from '../../../../types';
function compareAlternatives(alternativeA, alternativeB) {
    if (alternativeA === alternativeB) {
        return true;
    }
    if ('result' in alternativeA && 'result' in alternativeB && alternativeA.result === alternativeB.result) {
        return true;
    }
    return false;
}
function makeBooleanOrEmptyLogic(logic) {
    var name = logic.name, alternatives = logic.alternatives;
    var emptyAndTruthyEqual = compareAlternatives(alternatives.empty, alternatives.truthy);
    var falsyAndEmptyEqual = compareAlternatives(alternatives.empty, alternatives.falsy);
    var truthyAndFalsyEqual = compareAlternatives(alternatives.truthy, alternatives.falsy);
    if (emptyAndTruthyEqual && falsyAndEmptyEqual && truthyAndFalsyEqual) {
        return alternatives.empty;
    }
    if (truthyAndFalsyEqual && !emptyAndTruthyEqual) {
        return {
            name: name,
            is: undefined,
            alternatives: {
                truthy: alternatives.empty,
                falsy: alternatives.truthy,
            },
        };
    }
    if (falsyAndEmptyEqual && !truthyAndFalsyEqual) {
        return {
            name: name,
            is: true,
            alternatives: {
                truthy: alternatives.truthy,
                falsy: alternatives.empty,
            },
        };
    }
    if (emptyAndTruthyEqual && !falsyAndEmptyEqual) {
        return {
            name: name,
            is: false,
            alternatives: {
                truthy: alternatives.falsy,
                falsy: alternatives.empty,
            },
        };
    }
    return {
        name: name,
        is: undefined,
        alternatives: {
            truthy: alternatives.empty,
            falsy: {
                name: name,
                is: true,
                alternatives: {
                    truthy: alternatives.truthy,
                    falsy: alternatives.falsy,
                },
            },
        },
    };
}
var onboardingPath = 'onboarding';
var knowledgeQuestionnairePath = 'knowledgeQuestionnaire';
export function makeIsQuestionCorrectLogic(productName, keys, alternatives) {
    var hasRetriedFailureDifference = !compareAlternatives(alternatives.failure, alternatives.retriedFailureOnRequired);
    var hasNotRetriedFailureDifference = !compareAlternatives(alternatives.failureWithRetryAvailable, alternatives.failureWithRetryAvailableOnRequired);
    var hasWithRetryDifferenceOnFailure = (hasRetriedFailureDifference || hasNotRetriedFailureDifference) && !compareAlternatives(alternatives.failure, alternatives.failureWithRetryAvailable);
    var failure = hasWithRetryDifferenceOnFailure ? {
        name: makeName(onboardingPath, knowledgeQuestionnairePath, keys.wasRetriedKey),
        is: true,
        alternatives: {
            truthy: hasRetriedFailureDifference
                ? financialProducts.logic.KERequired.getMakeKERequiredLogic(productName)({
                    alternatives: {
                        required: alternatives.retriedFailureOnRequired,
                        free: alternatives.failure,
                        notRequired: alternatives.failure,
                    },
                })
                : alternatives.failure,
            falsy: hasNotRetriedFailureDifference
                ? financialProducts.logic.KERequired.getMakeKERequiredLogic(productName)({
                    alternatives: {
                        required: alternatives.failureWithRetryAvailableOnRequired,
                        free: alternatives.failureWithRetryAvailable,
                        notRequired: alternatives.failureWithRetryAvailable,
                    },
                })
                : alternatives.failureWithRetryAvailable,
        },
    } : alternatives.failure;
    var remedialQuestion = !keys.remedialIsCorrectKey ? failure : makeBooleanOrEmptyLogic({
        name: makeName(onboardingPath, knowledgeQuestionnairePath, keys.remedialIsCorrectKey),
        alternatives: {
            empty: alternatives.notAnswered,
            truthy: alternatives.success,
            falsy: failure,
        },
    });
    var questionLogic = makeBooleanOrEmptyLogic({
        name: makeName(onboardingPath, knowledgeQuestionnairePath, keys.correctKey),
        alternatives: {
            empty: alternatives.notAnswered,
            truthy: alternatives.success,
            falsy: remedialQuestion,
        },
    });
    return questionLogic;
}
export function makeIsQuestionnaireCorrectLogicFromQuestionKeys(productName, questionKeys, remedial, alternatives) {
    var until = questionKeys.map(function (questionKey, index, src) { return makeIsQuestionCorrectLogic(productName, {
        correctKey: pathUtils.makeIsCorrectKey(questionKey),
        remedialIsCorrectKey: remedial ? pathUtils.makeIsCorrectKey(pathUtils.makeRemedialKey(questionKey)) : undefined,
        wasRetriedKey: pathUtils.makeWasRetried(questionKey),
    }, __assign(__assign({}, alternatives), { success: (index !== src.length - 1 ? {
            result: 'next',
        } : alternatives.success) })); });
    return {
        until: until,
        alternatives: {
            truthy: alternatives.truthy,
            falsy: alternatives.falsy,
        },
    };
}
var selectedServicePath = 'selectedService';
export function makeIsQuestionnaireCorrectLogic(productName, alternatives) {
    var rtoQuestions = questionPaths.getRtoQuestionRootIdsByQuestionnaire(productName);
    var hasRtoQuestions = rtoQuestions.length;
    return financialProductsSelection.logic.selected.getMakeFinancialProductSelectedLogic(productName)({
        alternatives: {
            selected: {
                name: "".concat(onboardingPath, ".").concat(selectedServicePath),
                is: AccountType.RTO,
                alternatives: {
                    truthy: hasRtoQuestions ? makeIsQuestionnaireCorrectLogicFromQuestionKeys(productName, questionPaths.getRtoQuestionRootIdsByQuestionnaire(productName), true, alternatives) : alternatives.noQuestions,
                    falsy: makeIsQuestionnaireCorrectLogicFromQuestionKeys(productName, questionPaths.getQuestionRootIdsByQuestionnaire(productName), false, alternatives),
                },
            },
            notSelected: alternatives.notSelected,
        },
    });
}
export var makeIsQuestionnairesRequiredSuccessLogic = function (isNegative) {
    if (isNegative === void 0) { isNegative = false; }
    return ({
        until: financialProducts.list.map(function (financialProduct, index, src) { return financialProducts.logic.KERequired.getMakeKERequiredLogic(financialProduct)({
            alternatives: {
                required: makeIsQuestionnaireCorrectLogic(financialProduct, {
                    noQuestions: {
                        result: true,
                    },
                    notSelected: {
                        result: false,
                    },
                    notAnswered: {
                        result: false,
                    },
                    success: {
                        result: true,
                    },
                    failure: {
                        result: false,
                    },
                    failureWithRetryAvailable: {
                        result: false,
                    },
                    failureWithRetryAvailableOnRequired: {
                        result: false,
                    },
                    retriedFailureOnRequired: {
                        result: false,
                    },
                    truthy: index !== src.length - 1 ? {
                        result: 'next',
                    } : {
                        result: !isNegative,
                    },
                    falsy: {
                        result: !!isNegative,
                    },
                }),
                notRequired: index !== src.length - 1 ? {
                    result: 'next',
                } : {
                    result: true,
                },
                free: index !== src.length - 1 ? {
                    result: 'next',
                } : {
                    result: true,
                },
            },
        }); }),
        alternatives: {
            truthy: {
                result: !isNegative,
            },
            falsy: {
                result: !!isNegative,
            },
        },
    });
};
