import * as React from 'react';
import { GetBondsSameIssuerParams } from '@goldwasserexchange/actor/rest-services';
import { useBondSameIssuerDefaultParams } from '../hooks';
import { securityBondSameIssuerParamsContext } from '../context';

export const BondSameIssuerParamsProvider = (props: React.PropsWithChildren<{
  params: Partial<GetBondsSameIssuerParams>,
}>) => {
  const {
    params,
    children,
  } = props;
  const value = useBondSameIssuerDefaultParams(params);
  return value != null ? (
    <securityBondSameIssuerParamsContext.Provider
      value={value}
    >
      {children}
    </securityBondSameIssuerParamsContext.Provider>
  ) : null;
};
