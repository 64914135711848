import { UncastedDataStructure, OrientationEvaluationFrequency } from '@goldwasserexchange/oblis-frontend-utils';
import { Trans } from '@lingui/macro';
import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import ouiBase from '@goldwasserexchange/oui';
import { makeRequired, stringValidator } from '../../../../../../OUI/Inputs';
import { RadioListInput } from '../../../../../../OUI-dom/Inputs';
import { GetComponentKeyByElementPrimaryKey } from '../../utils/ElementLabel';

export const evaluationFrequencyValidator = makeRequired(stringValidator);

const evaluationFrequencyData: Record<OrientationEvaluationFrequency, { ID: OrientationEvaluationFrequency, value: JSX.Element }> = {
  [OrientationEvaluationFrequency.REGULARLY]: {
    ID: OrientationEvaluationFrequency.REGULARLY,
    value: <Trans>Régulièrement</Trans>,
  },
  [OrientationEvaluationFrequency.OCCASIONALY]: {
    ID: OrientationEvaluationFrequency.OCCASIONALY,
    value: <Trans>Occasionnellement</Trans>,
  },
  [OrientationEvaluationFrequency.RARELY]: {
    ID: OrientationEvaluationFrequency.RARELY,
    value: <Trans>Rarement</Trans>,
  },
};

const evaluationFrequencyList = Object.keys(evaluationFrequencyData);

const evaluationFrequencyLabel = (
  <TopModel name="RadioListInputLabelContent">
    <Trans>A quelle fréquence évaluez-vous l'évolution de vos investissements ?</Trans>
  </TopModel>
);

const evaluationFrequencyElementLabel = (
  <TopModel name="RadioListInputListElementLabelContent" data={evaluationFrequencyData} valueKey="value">
    {GetComponentKeyByElementPrimaryKey}
  </TopModel>
);

export const EvaluationFrequency = (): JSX.Element => {
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  return (
    <RadioListInput
      fieldPath={valueState.onboarding.orientation.evaluationFrequency.path.join('.')}
      dependenciesFieldPaths={[valueState.onboarding.orientation.submitted.path.join('.')]}
      list={evaluationFrequencyList}
    >
      {evaluationFrequencyLabel}
      {evaluationFrequencyElementLabel}
    </RadioListInput>
  );
};
