import { useAtomValue } from 'jotai';
import { bondSearchParamsAtom } from '../../../atoms';
import { isCurrencyFilter } from '../currencyFilters';
import { isMaturityFilter } from '../maturityFilters';
import { BooleanSearchAndFilterBondParams } from '../type';

export const useBooleanFilterValue = (field: BooleanSearchAndFilterBondParams) => {
  const qsParams = useAtomValue(bondSearchParamsAtom);
  if (isMaturityFilter(field)) {
    const {
      'S_ACC.MATURITY': value,
    } = qsParams;
    if (value === 'perpetual' && field === 'perpetual') {
      return true;
    }
    if (value === 'lessThanFiveYears' && field === 'lessThanFiveYears') {
      return true;
    }
    return false;
  }
  if (isCurrencyFilter(field)) {
    const {
      'C_CURRENCY.CODE': value,
    } = qsParams;
    if (value === 'EUR' && field === 'eur') {
      return true;
    }
    if (value === 'USD' && field === 'usd') {
      return true;
    }
    if (value === 'foreign' && field === 'foreignCurrencies') {
      return true;
    }
    return false;
  }
  if (field === 'S_MARKET.YIELD') {
    const {
      'S_MARKET.YIELD': value,
    } = qsParams;
    return value != null;
  }
  const {
    [field]: value,
  } = qsParams;
  return value;
};
