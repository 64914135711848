import { SearchForSuggestionsResult } from '@aws-sdk/client-location';
import * as React from 'react';
import {
  SearchPlaceInputPlaceholder,
  SearchPlaceInputContainer,
  SearchPlaceInputContent,
  SearchPlaceIcon,
  SearchPlaceInputLabel,
} from './components';

type SearchPlaceInputProps = {
  open: boolean,
  focus: boolean,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setData: React.Dispatch<React.SetStateAction<SearchForSuggestionsResult[] | null>>,
  setFocus: React.Dispatch<React.SetStateAction<boolean>>,
}

export const SearchPlaceInput = React.forwardRef<HTMLInputElement, SearchPlaceInputProps>((props, ref) => {
  const {
    open,
    focus,
    setOpen,
    setIsLoading,
    setData,
    setFocus,
  } = props;
  return (
    <SearchPlaceInputPlaceholder>
      <SearchPlaceInputLabel
        focus={focus}
      />
      <SearchPlaceInputContainer
        focus={focus}
      >
        <SearchPlaceInputContent
          ref={ref}
          setOpen={setOpen}
          setData={setData}
          setIsLoading={setIsLoading}
          setFocus={setFocus}
        />
        <SearchPlaceIcon
          inputRef={ref as React.RefObject<HTMLInputElement>}
          focus={focus}
          open={open}
          setOpen={setOpen}
        />
      </SearchPlaceInputContainer>
    </SearchPlaceInputPlaceholder>
  );
});
