/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { GreenLeaf } from '../../../../../../../Components/GreenLeaf';
import { ActorApiStringDisplay } from '../../../../../../components/display/string';
import { useBondsBondSAccGreenBoolean, useBondsBondSAccGreenText } from '../../hooks';

const style: ouiStyle.InStyleWithMediaQueries = {
  display: ouiStyle.Constants.DisplayProp.inlineFlex,
  justifyContent: ouiStyle.Constants.JustifyContentProp.center,
  alignItems: [
    ouiStyle.Constants.AlignItemsProp.start,
    undefined,
    undefined,
    ouiStyle.Constants.AlignItemsProp.center,
    undefined,
    undefined,
  ],
  paddingLeft: 'half',
  paddingBottom: [0.8, undefined, undefined, 0.8, undefined, undefined],
  paddingTop: [1.2, undefined, undefined, 0.8, undefined, undefined],
};

const css = ouiStyle.mediaQuery(ouiStyle.makeCss(style));

export const BondsBondSAccGreen = (props: {
  size?: string | number,
}) => {
  const {
    size,
  } = props;
  const {
    data,
  } = useBondsBondSAccGreenBoolean();
  return data === true ? (
    <span
      css={css}
    >
      <GreenLeaf size={size} />
    </span>
  ) : null;
};

export const BondsBondSAccGreenText = () => {
  const {
    data,
    isLoading,
    error,
  } = useBondsBondSAccGreenText();
  return (
    <ActorApiStringDisplay
      data={data}
      isLoading={isLoading}
      error={error}
      loadingValue="---"
    />
  );
};
