import { useContext, useMemo } from 'react';
import { listElementChildPaddingByDirection } from './context';
export var useChildPaddingByDirection = function (direction) {
    var context = listElementChildPaddingByDirection[direction];
    var childPadding = useContext(context);
    return childPadding;
};
export var useVerticalChildPaddings = function () {
    var childPaddingTop = useChildPaddingByDirection('top');
    var childPaddingBottom = useChildPaddingByDirection('bottom');
    var childPaddingVertical = useMemo(function () { return ({
        childPaddingTop: childPaddingTop,
        childPaddingBottom: childPaddingBottom,
    }); }, [
        childPaddingTop,
        childPaddingBottom,
    ]);
    return childPaddingVertical;
};
export var useHorizontalChildPaddings = function () {
    var childPaddingLeft = useChildPaddingByDirection('left');
    var childPaddingRight = useChildPaddingByDirection('right');
    var childPaddingHorizontal = useMemo(function () { return ({
        childPaddingTop: childPaddingLeft,
        childPaddingBottom: childPaddingRight,
    }); }, [
        childPaddingLeft,
        childPaddingRight,
    ]);
    return childPaddingHorizontal;
};
export var useChildPaddings = function () {
    var childPaddingTop = useChildPaddingByDirection('top');
    var childPaddingRight = useChildPaddingByDirection('right');
    var childPaddingBottom = useChildPaddingByDirection('bottom');
    var childPaddingLeft = useChildPaddingByDirection('left');
    var childPaddings = useMemo(function () { return ({
        childPaddingTop: childPaddingTop,
        childPaddingRight: childPaddingRight,
        childPaddingBottom: childPaddingBottom,
        childPaddingLeft: childPaddingLeft,
    }); }, [
        childPaddingTop,
        childPaddingRight,
        childPaddingBottom,
        childPaddingLeft,
    ]);
    return childPaddings;
};
