import ouiBase from '@goldwasserexchange/oui';
import {
  computeCashflowAfterTaxAndComInAccountCurrency,
  computeCashflowAfterTaxAndCom,
  computeCashflowAfterTaxAndComInAccountCurrencyTotal,
  computeRefundValueFromLine,
  computeRefundValueFromLineInAccountCurrency,
  cashflowPriceWithRefundInAccountCurrency,
} from '../../utils';
import {
  useAccountCashflowsTransformApi,
  useAccountCashflowTransformApiByCpsHisId,
} from '../api';

export const useAccountCashflowsCpsHisValueAfterTaxAndCom = () => {
  const cpsHisId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const result = useAccountCashflowTransformApiByCpsHisId({
    cpsHisId,
    transformFunction: computeCashflowAfterTaxAndCom,
  });
  return result;
};

export const useAccountCashflowsCpsHisValueAfterTaxAndComInAccountCurrency = () => {
  const cpsHisId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const result = useAccountCashflowTransformApiByCpsHisId({
    cpsHisId,
    transformFunction: computeCashflowAfterTaxAndComInAccountCurrency,
  });
  return result;
};

export const useAccountCashflowsCpsHisValueAfterTaxAndComInAccountCurrencyTotal = () => {
  const result = useAccountCashflowsTransformApi({
    transformFunction: computeCashflowAfterTaxAndComInAccountCurrencyTotal,
  });
  return result;
};

export const useAccountCashflowsCpsHisRefundValue = () => {
  const cpsHisId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const result = useAccountCashflowTransformApiByCpsHisId({
    cpsHisId,
    transformFunction: computeRefundValueFromLine,
  });
  return result;
};

export const useAccountCashflowsCpsHisRefundValueInAccountCurrency = () => {
  const cpsHisId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const result = useAccountCashflowTransformApiByCpsHisId({
    cpsHisId,
    transformFunction: computeRefundValueFromLineInAccountCurrency,
  });
  return result;
};

export const useAccountCashflowsCpsHisValueAfterTaxAndComWithRefundInAccountCurrencyTotal = () => {
  const result = useAccountCashflowsTransformApi({
    transformFunction: cashflowPriceWithRefundInAccountCurrency,
  });
  return result;
};
