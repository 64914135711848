import { throttledGetTokens } from './getTokens';

export const getSub = async () => {
  const {
    idToken,
  } = await throttledGetTokens();
  const {
    sub,
  } = idToken.payload;
  return sub;
};
