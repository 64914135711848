import { isAfter } from 'date-fns';
import { parseActorDate } from '../../../../shared/utils/parseDates';
import { BondsCall } from '../type';
import { isPostCall } from './isPostCall';

export const nextCallsFilterPredicate = (bondsCall: BondsCall) => {
  if (isPostCall(bondsCall['EVT_ENTRY_S.ID_EVT_CODE'])) {
    return false;
  }
  const now = new Date();
  const evtDate = parseActorDate(bondsCall['EVT_ENTRY_S.EVT_DATE']);
  return isAfter(evtDate, now);
};
