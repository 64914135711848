import * as preferences from './preferences';
import * as taxonomy from './taxonomy';
import * as taxonomyAlignment from './taxonomyAlignment';
import * as taxonomyProportion from './taxonomyProportion';
import * as sfdr from './sfdr';
import * as sfdrDurableProportion from './sfdrDurableProportion';
import * as sfdrSocialEnvironmentalProportion from './sfdrSocialEnvironmentalProportion';
import * as sfdrDurableOnly from './sfdrDurableOnly';
import * as sfdrSocialEnvironmentalOnly from './sfdrSocialEnvironmentalOnly';
import * as incidence from './incidence';
import * as incidenceType from './incidenceType';
import * as incidenceProportion from './incidenceProportion';
import { DurabilityPreferences, DurabilityTaxonomy, DurabilitySFDR, DurabilityIncidence, DurabilityTaxonomyAlignment, DurabilityTaxonomyProportion, DurabilitySFDRDurableProportion, DurabilitySFDRSocialEnvironmentalProportion, DurabilitySFDRDurableOnly, DurabilitySFDRSocialEnvironmentalOnly, DurabilityIncidenceType, DurabilityIncidenceProportion, } from '../../../types';
export var lists = {
    preferences: preferences.list,
    taxonomy: taxonomy.list,
    taxonomyAlignment: taxonomyAlignment.list,
    taxonomyProportion: taxonomyProportion.list,
    sfdr: sfdr.list,
    sfdrDurableProportion: sfdrDurableProportion.list,
    sfdrSocialEnvironmentalProportion: sfdrSocialEnvironmentalProportion.list,
    sfdrDurableOnly: sfdrDurableOnly.list,
    sfdrSocialEnvironmentalOnly: sfdrSocialEnvironmentalOnly.list,
    incidence: incidence.list,
    incidenceType: incidenceType.list,
    incidenceProportion: incidenceProportion.list,
};
export var listEnums = {
    preferences: DurabilityPreferences,
    taxonomy: DurabilityTaxonomy,
    taxonomyAlignment: DurabilityTaxonomyAlignment,
    taxonomyProportion: DurabilityTaxonomyProportion,
    sfdr: DurabilitySFDR,
    sfdrDurableProportion: DurabilitySFDRDurableProportion,
    sfdrSocialEnvironmentalProportion: DurabilitySFDRSocialEnvironmentalProportion,
    sfdrDurableOnly: DurabilitySFDRDurableOnly,
    sfdrSocialEnvironmentalOnly: DurabilitySFDRSocialEnvironmentalOnly,
    incidence: DurabilityIncidence,
    incidenceType: DurabilityIncidenceType,
    incidenceProportion: DurabilityIncidenceProportion,
};
