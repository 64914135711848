/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { to, animated } from 'react-spring';
import { useZIndex } from '../../../../OUI/Form';
import * as animationController from '../../animation';
import * as machine from '../../machine';

const SkrimStyle: ouiStyle.InStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  lines: 'screenHeight',
};

const useSkrimCss = () => {
  const zIndex = useZIndex() - 2;
  const css = React.useMemo(() => ouiStyle.makeCss({
    ...SkrimStyle,
    zIndex,
  }), [zIndex]);
  return css;
};

export const Skrim = () => {
  const sendClose = machine.useClose();
  const closed = animationController.useAnimationClosedSpringValue();
  const mqDown = animationController.useAnimationmqDownSpringValue();
  const style = {
    right: to([closed, mqDown], (c: number, d: number) => {
      if (c < 0.5 && d < 0.5) {
        return '0%';
      }
      return '100%';
    }),
    backgroundColor: closed.to((v: number) => `rgba(0, 0, 0, ${(1 - v) * 0.7})`),
  };
  const css = useSkrimCss();
  return <animated.div css={css} onClick={sendClose} style={style} />;
};

export default Skrim;
