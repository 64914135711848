import { useContext } from 'react';
import { mobileSelectedAccountTypeContext } from './context';

export const useMobileSelectedAccountTypeContext = () => {
  const context = useContext(mobileSelectedAccountTypeContext);
  return context;
};

export const useMobileSelectedAccountType = () => {
  const [mobileSelectedAccountType] = useMobileSelectedAccountTypeContext();
  return mobileSelectedAccountType;
};

export const useSetMobileSelectedAccountType = () => {
  const [, setMobileSelectedAccountType] = useMobileSelectedAccountTypeContext();
  return setMobileSelectedAccountType;
};
