import { identity } from 'ramda';
import React from 'react';
import { PrimaryDimensionListContextProvider, usePrimaryDimensionList } from '../../../utils/dimensions';
export function ListDataFilterAndSortProvider(props) {
    var _a = props.useFilterFn, useFilterFn = _a === void 0 ? identity : _a, _b = props.useSortFn, useSortFn = _b === void 0 ? identity : _b, children = props.children;
    var listData = usePrimaryDimensionList();
    var filteredData = useFilterFn(listData);
    var sortedData = useSortFn(filteredData);
    return (React.createElement(PrimaryDimensionListContextProvider, { primaryDimensionList: sortedData }, children));
}
