import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import ouiDom from '@goldwasserexchange/oui-dom';
import { ActorApiStringDisplay } from '../../../../../../components/display/string';
import {
  usePersonAccountsFormattedTAccNumDisplay, usePersonAccountsTAccNumDisplayStart, usePersonAccountsTAccNumDisplayRootStart, usePersonAccountsTAccNumDisplayRootMiddle, usePersonAccountsTAccNumDisplayRootEnd, usePersonAccountsTAccNumDisplayEnd,
} from '../../hooks';

const PersonsAccountsTAccNumDisplayWithNoColor = (props: {
  fontSize?: ouiStyle.InStyleWithMediaQueries['fontSize'],
  lineFactor?: ouiStyle.InStyleWithMediaQueries['lineFactor'],
  mainColor: ouiStyle.InStyleWithMediaQueries['color'],
}) => {
  const {
    fontSize,
    lineFactor,
    mainColor,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = usePersonAccountsFormattedTAccNumDisplay();
  return (
    <ouiDom.TextSnippet.TextSnippet
      fontSize={fontSize}
      lineFactor={lineFactor}
      color={mainColor}
    >
      <ActorApiStringDisplay
        data={data}
        isLoading={isLoading}
        error={error}
      />
    </ouiDom.TextSnippet.TextSnippet>
  );
};

const PersonAccountsTAccNumDisplayStart = () => {
  const {
    data,
    isLoading,
    error,
  } = usePersonAccountsTAccNumDisplayStart();
  return (
    <ActorApiStringDisplay
      data={data}
      isLoading={isLoading}
      error={error}
    />
  );
};

const PersonAccountsTAccNumDisplayRootStart = () => {
  const {
    data,
    isLoading,
    error,
  } = usePersonAccountsTAccNumDisplayRootStart();
  return (
    <ActorApiStringDisplay
      data={data}
      isLoading={isLoading}
      error={error}
    />
  );
};

const PersonAccountsTAccNumDisplayRootMiddle = () => {
  const {
    data,
    isLoading,
    error,
  } = usePersonAccountsTAccNumDisplayRootMiddle();
  return (
    <ActorApiStringDisplay
      data={data}
      isLoading={isLoading}
      error={error}
    />
  );
};

const PersonAccountsTAccNumDisplayRootEnd = () => {
  const {
    data,
    isLoading,
    error,
  } = usePersonAccountsTAccNumDisplayRootEnd();
  return (
    <ActorApiStringDisplay
      data={data}
      isLoading={isLoading}
      error={error}
    />
  );
};

const PersonAccountsTAccNumDisplayEnd = () => {
  const {
    data,
    isLoading,
    error,
  } = usePersonAccountsTAccNumDisplayEnd();
  return (
    <ActorApiStringDisplay
      data={data}
      isLoading={isLoading}
      error={error}
    />
  );
};

const PersonsAccountsTAccNumDisplayWithColor = (props: {
  fontSize?: ouiStyle.InStyleWithMediaQueries['fontSize'],
  lineFactor?: ouiStyle.InStyleWithMediaQueries['lineFactor'],
  mainColor: ouiStyle.InStyleWithMediaQueries['color'],
  rootNumberColor: ouiStyle.InStyleWithMediaQueries['color'],
}) => {
  const {
    fontSize,
    lineFactor,
    mainColor,
    rootNumberColor,
  } = props;
  return (
    <ouiDom.TextSnippet.TextSnippet
      color={mainColor}
      lineFactor={lineFactor}
      fontSize={fontSize}
    >
      <PersonAccountsTAccNumDisplayStart />
      <ouiDom.TextSnippet.TextSnippet
        color={rootNumberColor}
        lineFactor={lineFactor}
        fontSize={fontSize}
      >
        <PersonAccountsTAccNumDisplayRootStart />
      </ouiDom.TextSnippet.TextSnippet>
      <PersonAccountsTAccNumDisplayRootMiddle />
      <ouiDom.TextSnippet.TextSnippet
        color={rootNumberColor}
        lineFactor={lineFactor}
        fontSize={fontSize}
      >
        <PersonAccountsTAccNumDisplayRootEnd />
      </ouiDom.TextSnippet.TextSnippet>
      <PersonAccountsTAccNumDisplayEnd />
    </ouiDom.TextSnippet.TextSnippet>
  );
};

export const PersonsAccountsTAccNumDisplay = (props: {
  fontSize?: ouiStyle.InStyleWithMediaQueries['fontSize'],
  lineFactor?: ouiStyle.InStyleWithMediaQueries['lineFactor'],
  mainColor?: ouiStyle.InStyleWithMediaQueries['color'],
  rootNumberColor?: ouiStyle.InStyleWithMediaQueries['color'],
}) => {
  const {
    fontSize,
    lineFactor,
    mainColor = ouiStyle.Constants.Colors.normal,
    rootNumberColor = mainColor,
  } = props;
  if (mainColor === rootNumberColor) {
    return (
      <PersonsAccountsTAccNumDisplayWithNoColor
        fontSize={fontSize}
        lineFactor={lineFactor}
        mainColor={mainColor}
      />
    );
  }
  return (
    <PersonsAccountsTAccNumDisplayWithColor
      mainColor={mainColor}
      rootNumberColor={rootNumberColor}
      lineFactor={lineFactor}
      fontSize={fontSize}
    />
  );
};
