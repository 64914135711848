import { CPhone } from '../../../../../../types';
export var list = [
    CPhone.GSM_PRIVE,
    CPhone.EMAIL_NULL,
    CPhone.EMAIL_PRINCIPAL,
    CPhone.EMAIL_2,
    CPhone.FAX_PRINCIPAL,
    CPhone.FAX_2,
    CPhone.GSM_PRINCIPAL,
    CPhone.GSM_2,
    CPhone.SITE_WEB,
    CPhone.TEL_PRINCIPAL,
    CPhone.TEL_2,
];
