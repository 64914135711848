import { dataStructure, AccountType } from '@goldwasserexchange/oblis-frontend-utils';
import ouiBase from '@goldwasserexchange/oui';
import ouiDom from '@goldwasserexchange/oui-dom';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { Trans } from '@lingui/macro';
import * as React from 'react';

export const CUSTODY_FEES = 'custodyFees';

const custodyFeesLabels = {
  [AccountType.RTO]: {
    label: (
      <Trans>
        0,121% par an
        <br />
        <ouiDom.TextSnippet.TextSnippet
          fontSize="xxxs"
        >
          (min. 30,25 EUR par trimestre)
        </ouiDom.TextSnippet.TextSnippet>
      </Trans>
    ),
  },
  [AccountType.CONSEIL]: {
    label: (
      <Trans>
        0,20% par an
        <br />
        <ouiDom.TextSnippet.TextSnippet
          fontSize="xxxs"
        >
          (min. 30,25 EUR par trimestre)
        </ouiDom.TextSnippet.TextSnippet>
      </Trans>
    ),
  },
  [AccountType.GESTION]: {
    label: (
      <Trans>
        0,121% par an
        <br />
        <ouiDom.TextSnippet.TextSnippet
          fontSize="xxxs"
        >
          (min. 30,25 EUR par trimestre)
        </ouiDom.TextSnippet.TextSnippet>
      </Trans>
    ),
  },
};

export const CustodyFeesLabel = (props: { accountType: dataStructure.accountType.listType }) => {
  const { accountType } = props;
  const { label } = custodyFeesLabels[accountType];
  return label;
};

export const CustodyFeesLabelOnPrimaryDimension = () => {
  const accountType = ouiBase.utils.dimensions.useCurrentPrimaryDimension() as dataStructure.accountType.listType;
  return (
    <CustodyFeesLabel accountType={accountType} />
  );
};

const custodyFeesLabel = (
  <Trans>
    Droits de garde
  </Trans>
);

export const custodyFeesTopModels = (
  <>
    <TopModel name={`TableSecondaryTitleCellContent-${CUSTODY_FEES}`}>
      {custodyFeesLabel}
    </TopModel>
    <TopModel name={`TableBodyRowCellContent--${CUSTODY_FEES}`}>
      {CustodyFeesLabelOnPrimaryDimension}
    </TopModel>
  </>
);
