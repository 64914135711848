import ouiBase from '@goldwasserexchange/oui';
import * as React from 'react';
import { useSearch } from './hooks';

export function DefaultSearchDataProvider(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  const result = useSearch();
  return <ouiBase.utils.dimensions.PrimaryDimensionListContextProvider primaryDimensionList={result}>{children}</ouiBase.utils.dimensions.PrimaryDimensionListContextProvider>;
}
