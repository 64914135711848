import { OpenGraphTagProps } from './types';

export const setOpenGraphTag = (props: { head: HTMLHeadElement, openGraph: OpenGraphTagProps}) => {
  const {
    head,
    openGraph,
  } = props;
  const {
    property,
    name,
    content,
  } = openGraph;
  const metaElement = head.querySelector(`meta[property="${property}"]`);
  if (metaElement) {
    if (content == null) {
      metaElement.remove();
    } else {
      metaElement.setAttribute('content', content);
      if (name != null) {
        metaElement.setAttribute('name', name);
      } else {
        metaElement.removeAttribute('name');
      }
    }
  } else if (content != null) {
    const newMetaElement = document.createElement('meta');
    newMetaElement.setAttribute('property', property);
    newMetaElement.setAttribute('content', content);
    if (name != null) {
      newMetaElement.setAttribute('name', name);
    }
    head.appendChild(newMetaElement);
  }
};
