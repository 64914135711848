import ouiBase from '@goldwasserexchange/oui';
import { cashflowLineHasRefund } from '../../utils';
import { useAccountCashflowTransformApiByCpsHisId } from '../api';

export const useAccountCashflowsHasRefund = () => {
  const cpsHisId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const response = useAccountCashflowTransformApiByCpsHisId({
    cpsHisId,
    transformFunction: cashflowLineHasRefund,
  });
  return response;
};
