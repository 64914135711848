import * as React from 'react';

import { fieldPathContext } from './context';

export function FieldPathProvider(
  props: React.PropsWithChildren<{ fieldPath: string }>,
) {
  const { fieldPath, children } = props;
  return (
    <fieldPathContext.Provider value={fieldPath}>
      {children}
    </fieldPathContext.Provider>
  );
}
