import * as React from 'react';
import { ActorApiMapper } from '../../../../../../components';
import { CashflowByCurrency } from '../../type';
import { useCashflowsByCurrencyList } from '../../hooks';

export const CashflowsByCurrencyMapper = (props: React.PropsWithChildren<{
  sortPredicate?: (prev: CashflowByCurrency, next: CashflowByCurrency) => number,
  filterPredicate?: (data: CashflowByCurrency) => boolean,
  limit?: number,
  loadingList?: any[],
}>) => {
  const {
    sortPredicate,
    filterPredicate,
    limit,
    loadingList,
    children,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useCashflowsByCurrencyList({
    sortPredicate,
    filterPredicate,
  });
  return (
    <ActorApiMapper
      data={data}
      isLoading={isLoading}
      error={error}
      limit={limit}
      loadingList={loadingList}
    >
      {children}
    </ActorApiMapper>
  );
};
