import { isAfter } from 'date-fns';
import { parseActorDate } from '../../../../../shared/utils/parseDates';
import { BondsCall } from '../../type';
import { isPostCall } from '../../utils/isPostCall';
import { useBondsCallLinesTransformApi } from '../api';

const transformFunction = (bondsCall: BondsCall[] | undefined) => {
  if (bondsCall == null) {
    return false;
  }
  return bondsCall.length > 0 && bondsCall.some((bondCall) => !isPostCall(bondCall['EVT_ENTRY_S.ID_EVT_CODE']) && isAfter(parseActorDate(bondCall['EVT_ENTRY_S.EVT_DATE']), Date.now()));
};

export const useBondsHasNonPostPlanningFutureCall = () => {
  const result = useBondsCallLinesTransformApi({
    transformFunction,
  });
  return result;
};
