import React from 'react';
import { Trans } from '@lingui/macro';
import { dataStructure, UncastedPhysicalTAdd } from '@goldwasserexchange/oblis-frontend-utils';

export const tAddNationalRegisterLabel = <Trans>Numéro de registre national</Trans>;

const ID_C_COUNTRY_NAT: keyof Pick<UncastedPhysicalTAdd, 'ID_C_COUNTRY_NAT'> = 'ID_C_COUNTRY_NAT';

export const tAddNationalRegisterValidatorLogic = dataStructure.T_ADD.fields.TYPE.logic.makeTAddIsNotMoralOrIgnored({
  truthy: {
    name: `<parentName>.${ID_C_COUNTRY_NAT}`,
    is: 212,
    alternatives: {
      truthy: {
        result: 'requiredNationalRegister',
      },
      falsy: {
        result: 'nilCast',
      },
    },
  },
  falsy: {
    result: 'nilCast',
  },
});

export const tAddNationalRegisterHelpText = <Trans>Composé de 11 chiffres</Trans>;
