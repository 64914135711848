import ouiBase from '@goldwasserexchange/oui';
import { useFilterByContextSTypeGrpId, sumPcPortInt } from '../../utils';
import { useAccountPositionFieldApiByGesEstimD, useAccountPositionsTransformApi } from '../api';

export const useAccountPositionGesEstimDPcPortInt = () => {
  const gesEstimDId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const result = useAccountPositionFieldApiByGesEstimD({
    gesEstimDId,
    field: 'GES_ESTIM_D.PC_PORT_INT',
  });
  return result;
};

export const useAccountPositionGesEstimDPcPortIntBySTypeGrp = () => {
  const filterPredicate = useFilterByContextSTypeGrpId();
  const result = useAccountPositionsTransformApi({
    filterPredicate,
    transformFunction: sumPcPortInt,
  });
  return result;
};
