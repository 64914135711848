import { useCallback } from 'react';
import { useNumberFormatter } from '../../../../../../../Components/NumberDisplay';
import { BondResponse } from '../../type';
import { makeNumberToMillionsString } from '../../utils';
import { useBondsBondFieldTransformFnApi } from '../api';

export const useBondsBondSAccConvRatio = () => {
  const numberFormatter = useNumberFormatter({
    format: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
  });
  const numberToMillionString = useCallback(makeNumberToMillionsString(numberFormatter), [numberFormatter]);
  const transformFn = useCallback((convRatio: BondResponse['S_ACC.CONV_RATIO'] | undefined) => {
    if (!convRatio) {
      return null;
    }
    return numberToMillionString(convRatio * 1_000_000);
  }, [numberToMillionString]);
  const response = useBondsBondFieldTransformFnApi({
    field: 'S_ACC.CONV_RATIO',
    transformFn,
  });
  return response;
};
