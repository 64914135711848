import { computeDimension } from '../utils/dimensions/computeDimension';
export var linesDefault = 'auto';
export function makeHeight(props) {
    var _a = props.lines, lines = _a === void 0 ? 1 : _a, fontSize = props.fontSize, paddingTop = props.paddingTop, paddingBottom = props.paddingBottom, childPaddingTop = props.childPaddingTop, childPaddingBottom = props.childPaddingBottom, parentBorderTopWidth = props.parentBorderTopWidth, parentBorderBottomWidth = props.parentBorderBottomWidth, _b = props.icon, icon = _b === void 0 ? false : _b, lineFactor = props.lineFactor, minus = props.minus;
    return computeDimension({
        factor: lines,
        fontSize: fontSize,
        paddingStart: paddingTop,
        paddingEnd: paddingBottom,
        childPaddingStart: childPaddingTop,
        childPaddingEnd: childPaddingBottom,
        parentBorderStart: parentBorderTopWidth,
        parentBorderEnd: parentBorderBottomWidth,
        icon: icon,
        lineFactor: lineFactor,
        minus: minus,
    });
}
