/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { Heart } from 'react-feather';

const likeBondButtonIconStyle: ouiStyle.InStyle = {
  backgroundColor: ouiStyle.Constants.Colors.inherit,
};

export const LikeBondButtonIcon = (props: {
  isFavorite: boolean,
  size?: string | number,
  color?: ouiStyle.InStyle['color'],
  favoriteColor?: ouiStyle.InStyle['color'],
  fillColor?: ouiStyle.Constants.Colors,
  favoriteFillColor?: ouiStyle.Constants.Colors,
  transform?: ouiStyle.InStyle['transform'],
}) => {
  const {
    isFavorite,
    size,
    color,
    favoriteColor = color,
    fillColor = ouiStyle.Constants.Colors.transparent,
    favoriteFillColor = favoriteColor,
    transform,
  } = props;
  const likeBondButtonIconCss = React.useMemo(
    () => ({
      ...ouiStyle.makeCss({
        ...likeBondButtonIconStyle,
        transform,
      }),
      color: !isFavorite ? color : favoriteColor,
      fill: !isFavorite ? fillColor : favoriteFillColor,
      width: size,
      height: size,
    }),
    [
      size,
      isFavorite,
      color,
      favoriteColor,
      fillColor,
      favoriteFillColor,
      transform,
    ],
  );
  return (
    <Heart
      css={likeBondButtonIconCss}
      size={size}
    />
  );
};
