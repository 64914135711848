import { useContext } from 'react';
import { fieldErrorsContext } from './context';
import { useIsTouched } from '../touched';

export function useErrors(): string[] {
  const errors = useContext(fieldErrorsContext);
  return errors;
}

export function useIsValid() {
  const errors = useErrors();
  return errors.length === 0;
}

export function useFirstError() {
  const errors = useErrors();
  return errors[0] ?? null;
}

export type ValidationVariantsNames = 'untouched' | 'valid' | 'error';

export function useValidationVariant(): ValidationVariantsNames {
  const validation = useIsValid();
  const touched = useIsTouched();
  if (!touched) {
    return 'untouched';
  }
  return validation ? 'valid' : 'error';
}
