import useSWR, { SWRResponse } from 'swr';
import { useAtomValue } from 'jotai';
import { QueryArguments, QueryResponseElement } from '../../types';
import { convertQueryArguments, fetcher } from '../../utils';
import { pathnameUrlLanguageAtom } from '../../../../../history';

export const useSanityTeamMembers = (props: QueryArguments): SWRResponse<QueryResponseElement[], any, any> => {
  const swrArguments = convertQueryArguments(props);
  const response = useSWR(swrArguments, fetcher);
  return response;
};

export const useLocalizedSanityTeamMembers = (): SWRResponse<QueryResponseElement[], any, any> => {
  const locale = useAtomValue(pathnameUrlLanguageAtom);
  return useSanityTeamMembers({ locale });
};
