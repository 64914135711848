import { compareDesc } from 'date-fns';
import { getTAccTwrrPDate } from '../tAccTwrrP/utils/getDate';
import { AccountTwrrs } from '../../type';
import { useAccountTwrrsTransformApi } from '../api';

const transformFunction = (list: AccountTwrrs[] | undefined) => {
  if (list && list.length > 0) {
    const sortedList = list.sort((prev, next) => {
      const prevDate = getTAccTwrrPDate(prev);
      const nextDate = getTAccTwrrPDate(next);
      return compareDesc(prevDate, nextDate);
    });
    return getTAccTwrrPDate(sortedList[0]);
  }
  return '';
};

export const useAccountTwrrEndDate = () => {
  const result = useAccountTwrrsTransformApi({
    transformFunction,
  });
  return result;
};
