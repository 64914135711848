import { useContext } from 'react';
import {
  animationControllerApiContext,
  animationControllerLogoExpandedContext,
  animationControllerClosedTopContext,
  animationControllerMqUpContext,
  animationControllerCollapseContext,
  AppBarSpringRef,
  AppBarLogoExpandedSpringValue,
  AppBarClosedSpringValue,
  AppBarCollapse,
  AppBarMqUpSpringValue,
} from './context';

export const useAnimationApi = (): AppBarSpringRef => {
  const api = useContext(animationControllerApiContext);
  if (api === null) {
    throw new Error(
      'you are trying to use an animation controller outside of the animation context',
    );
  }
  return api;
};

export const useAnimationLogoExpandedSpringValue = (): AppBarLogoExpandedSpringValue => {
  const logoExpanded = useContext(animationControllerLogoExpandedContext);
  if (logoExpanded === null) {
    throw new Error(
      'you are trying to use an animation controller outside of the animation context',
    );
  }
  return logoExpanded;
};

export const useAnimationClosedSpringValue = (): AppBarClosedSpringValue => {
  const closed = useContext(animationControllerClosedTopContext);
  if (closed === null) {
    throw new Error(
      'you are trying to use an animation controller outside of the animation context',
    );
  }
  return closed;
};

export const useAnimationmqDownSpringValue = (): AppBarMqUpSpringValue => {
  const mqDown = useContext(animationControllerMqUpContext);
  if (mqDown === null) {
    throw new Error(
      'you are trying to use an animation controller outside of the animation context',
    );
  }
  return mqDown;
};

export const useAnimationCollapseSpringValue = (): AppBarCollapse => {
  const collapse = useContext(animationControllerCollapseContext);
  if (collapse === null) {
    throw new Error(
      'you are trying to use an animation controller outside of the animation context',
    );
  }
  return collapse;
};
