import { useContext } from 'react';
import { searchKeysContext } from './context';

export const useGetSearchKeys = () => {
  const searchKeys = useContext(searchKeysContext);
  return searchKeys;
};

export const useFirstSearchKey = () => {
  const [firstSearchKey] = useGetSearchKeys();
  return firstSearchKey;
};
