/* eslint-disable @typescript-eslint/member-ordering */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { createState, } from '@hookstate/core';
import { Untracked } from '@hookstate/untracked';
import { useEffect } from 'react';
import { useFormData } from './hooks';
// should be global variable
var DebouncePluginId = Symbol('DebouncePlugin');
var DebouncePluginInstance = /** @class */ (function () {
    function DebouncePluginInstance(state) {
        var _this = this;
        this.onSet = function (data) {
            Object.values(_this.timeouts).forEach(function (subscription) { return _this.handleSubscription(subscription.name, data); });
        };
        this.handleSubscription = function (name, data) {
            var dataWithDefault = data || {
                path: [''],
                state: Untracked(_this.state).get(),
            };
            var _a = _this.timeouts[name], fn = _a.fn, timeoutDelay = _a.timeoutDelay, timeout = _a.timeout, condFn = _a.condFn;
            if (!condFn || condFn(dataWithDefault)) {
                _this.loading[name].set(true);
                if (timeout) {
                    clearTimeout(timeout);
                }
                var newTimeout = setTimeout(function () {
                    fn(dataWithDefault);
                    _this.loading[name].set(false);
                }, timeoutDelay);
                _this.timeouts[name] = __assign(__assign({}, _this.timeouts[name]), { timeout: newTimeout });
            }
        };
        this.subscribe = function (subscription) {
            var name = subscription.name;
            _this.timeouts[name] = __assign(__assign({}, subscription), { timeout: undefined });
        };
        this.unsubscribe = function (name) {
            var _a = _this.timeouts, _b = name, toUnsubscribe = _a[_b], rest = __rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
            _this.timeouts = rest;
        };
        this.trigger = function (name) {
            _this.handleSubscription(name, undefined);
        };
        this.loading = createState({});
        this.timeouts = {};
        this.state = state;
    }
    return DebouncePluginInstance;
}());
export function DebouncePlugin($this) {
    if ($this) {
        var $th = $this;
        var _a = __read($th.attach(DebouncePluginId), 1), instance = _a[0];
        if (instance instanceof Error) {
            throw instance;
        }
        var inst_1 = instance;
        return {
            subscribe: function (subscription) { return inst_1.subscribe(subscription); },
            unsubscribe: function (name) { return inst_1.unsubscribe(name); },
            loading: function (name) { return inst_1.loading[name]; },
            trigger: function (name) { return inst_1.trigger(name); },
        };
    }
    return {
        id: DebouncePluginId,
        init: function (state) { return new DebouncePluginInstance(state); },
    };
}
export var useDebounceSubscribe = function (subscription) {
    var formData = useFormData();
    useEffect(function () {
        DebouncePlugin(formData).subscribe(subscription);
        return function () { return DebouncePlugin(formData).unsubscribe(subscription.name); };
    }, [subscription]);
};
