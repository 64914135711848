import { imageQuery } from '../../shared';
import { query as promoQuery } from '../../promos/query';

const cursor = /* groq */`select(
  defined($searchTerm) => "0",
  defined(
    *[
      _type == "featuredNews"
      && language == $locale
      && currentFeaturedNews._ref == ^._id
    ][0]
  ) => "1",
  "0"
) + "_" + publishDate.utc + "_" + _id`;

export const query = /* groq */`
{
  "promotions": select(
    defined($searchTerm) => [],
    defined($cursor) => [],
    ${promoQuery}
  ),
  "news": *[
    _type == "news"
    && language == $locale
    && publishDate.utc <= now()
    && (!defined($cursor) || ${cursor} < $cursor)
    && (
      !defined($searchTerm)
      || [title, pt::text(content)] match $searchTerm
    )
  ] {
    _id,
    title,
    language,
    "publishDate": publishDate.utc,
    "intro": pt::text(content[0]),
    "cursor": ${cursor},
    image ${imageQuery},
  }
  | order(cursor desc)
  [0...$quantity],
}
` as const;
