import * as React from 'react';
import ouiDom from '@goldwasserexchange/oui-dom';
import ouiStyle from '@goldwasserexchange/oui-style';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { RecaptchaInput, TextInput } from '../../../../../../../OUI-dom/Inputs';
import { tAddPhoneGsmLabel } from '../../../../../../tAddPhone';
import { CenteredButtonContainer } from '../../../../../../CenteredButtonContainer';
import { ForgotPasswordInputUserNameSubmitButton } from './submitButton';
import { ForgotPasswordInputUserNameForm } from './form';

export const ForgotPasswordInputUserNameFormComponent = () => (
  <ouiDom.HeadingSection.HeadingSection
    name="InputCodeSignIn"
    index={1}
    backgroundColor={ouiStyle.Constants.Colors.transparent}
    container={ouiStyle.Constants.Container.slim}
    paddingHorizontal="double"
  >
    <ForgotPasswordInputUserNameForm>
      <TextInput
        fieldPath="auth.username"
      >
        <TopModel name="TextInputLabelContent">
          {tAddPhoneGsmLabel}
        </TopModel>
      </TextInput>
      <RecaptchaInput />
      <CenteredButtonContainer>
        <ForgotPasswordInputUserNameSubmitButton />
      </CenteredButtonContainer>
    </ForgotPasswordInputUserNameForm>
  </ouiDom.HeadingSection.HeadingSection>
);
