declare global {
  interface Window { dataLayer: any[], gtag: (..._: any[]) => void } // eslint-disable-line @typescript-eslint/consistent-type-definitions
}

export const GA_TRACKING_ID = 'G-184JZPWEMR';

export function gtag(..._: any[]) { window.dataLayer.push(arguments); } // eslint-disable-line prefer-rest-params

export function init() {
  window.dataLayer = window.dataLayer || [];
  window.gtag = gtag;
  gtag('js', new Date());
  gtag('config', GA_TRACKING_ID);
  gtag('consent', 'default', {
    ad_storage: 'denied',
    analytics_storage: 'denied',
    wait_for_update: 1500, // milliseconds
  });
  gtag('set', 'ads_data_redaction', true);
  //
}
