import * as React from 'react';
import { zIndexContext } from './context';
import { defaultBaseZIndex } from './default';
import { useZIndex } from './hooks';

export function ZIndexSetProvider(
  props: React.PropsWithChildren<{ zIndex: number }>,
) {
  const { zIndex, children } = props;
  return (
    <zIndexContext.Provider value={zIndex}>{children}</zIndexContext.Provider>
  );
}

export function ZIndexSourceProvider(
  props: React.PropsWithChildren<{}>,
) {
  const {
    children,
  } = props;
  return (
    <ZIndexSetProvider zIndex={defaultBaseZIndex}>
      {children}
    </ZIndexSetProvider>
  );
}

export function ZIndexAddProvider(
  props: React.PropsWithChildren<{ addZIndex?: number }>,
) {
  const { addZIndex = 1, children } = props;
  const contextZIndex = useZIndex();
  return (
    <ZIndexSetProvider zIndex={contextZIndex + addZIndex}>
      {children}
    </ZIndexSetProvider>
  );
}

const getChildTotalZIndex = (child): number => {
  if (typeof child === 'object' && child.type && child.type.totalZIndex) {
    return child.type.totalZIndex;
  }
  return 1;
};

const getChildrenArrayTotalZIndex = (childrenArray): number => childrenArray.reduce((acc: number, child) => (acc + getChildTotalZIndex(child)), 0);

export function ZIndexInvertedChildren(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  const contextZIndex = useZIndex();
  const childrenArray = React.Children.toArray(children);
  return (
    <>
      {childrenArray.map((child, index, arr) => {
        const addZ = contextZIndex - getChildrenArrayTotalZIndex(arr.slice(0, index));
        return (
          <ZIndexSetProvider key={addZ} zIndex={addZ}>
            {child}
          </ZIndexSetProvider>
        );
      })}
    </>
  );
}
