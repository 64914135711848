var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import ouiBase from '@goldwasserexchange/oui';
var idTNatureLrpPath = 'ID_T_NATURE_LRP';
export function makeIdTNatureLrpDependentLogic(props) {
    var is = props.is, _a = props.truthy, truthy = _a === void 0 ? {
        result: 'visible',
    } : _a, _b = props.falsy, falsy = _b === void 0 ? {
        result: 'invisible',
    } : _b, options = props.options;
    return ({
        name: "<parentName>.".concat(idTNatureLrpPath),
        is: is,
        options: __assign(__assign({}, options), { matcher: ouiBase.utils.match.arrayMatcherNames.some }),
        alternatives: {
            truthy: truthy,
            falsy: falsy,
        },
    });
}
