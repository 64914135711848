import { useContext } from 'react';
import { useRifm as useRifmBase } from 'rifm';
import { useOnChange } from '../../../../../utils';
import { rifmContext } from './context';

export const useRifmContext = () => {
  const rifm = useContext(rifmContext);
  return rifm;
};

const voidFn = (_str: string) => { };

export const useRifm = (value: string) => {
  const {
    accept,
    format,
    mask,
    replace,
    append,
  } = useRifmContext();
  const onChange = useOnChange();
  const result = useRifmBase({
    accept,
    format,
    value,
    onChange: onChange ?? voidFn,
    mask,
    replace,
    append,
  });
  return result;
};
