import { UncastedDataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import ouiBase from '@goldwasserexchange/oui';
import * as React from 'react';
import { useParentFieldPath } from '../../../../OUI/Inputs';

export const FilterCurrentUser = (props: React.PropsWithChildren<{ isNegative?: boolean }>) => {
  const { isNegative = false, children } = props;
  const parentName = useParentFieldPath();
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const currentPathArray = valueState.onboarding.users[0].current.path;
  const currentPathString = currentPathArray[currentPathArray.length - 1];
  return (
    <ouiBase.utils.hookstate.FormFilterLogicVisible
      parentName={parentName}
      logic={{
        name: `<parentName>.${currentPathString}`,
        is: true,
        options: {
          isNegative,
        },
        alternatives: {
          truthy: {
            result: 'visible',
          },
          falsy: {
            result: 'invisible',
          },
        },
      }}
    >
      {children}
    </ouiBase.utils.hookstate.FormFilterLogicVisible>
  );
};
