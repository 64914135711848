import { times } from 'ramda';
import { sanityClient } from '../../../client';
import { slugify } from '../../../utils';
import { query } from './query';
import {
  QueryArguments,
  BaseQueryResponse,
  QueryResponse,
  SwrArguments,
  QueryArgumentsWithSearchTermArray,
  BaseSwrArguments,
  BaseQueryArguments,
} from './types';
import {
  filterAndTransformPromoQueryResponse,
} from '../../promos/utils';

export const convertSwrArguments = (args: SwrArguments): QueryArguments => {
  const [, locale, searchTerm, cursor] = args;
  const trimmedSearchTerm = searchTerm != null ? searchTerm.trim() : '';
  return {
    locale,
    searchTerm: trimmedSearchTerm,
    cursor,
  };
};

export const convertBaseSwrArguments = (args: BaseSwrArguments): BaseQueryArguments => {
  const [, quantity, locale, searchTerm, cursor] = args;
  const trimmedSearchTerm = searchTerm != null ? searchTerm.trim() : '';
  return {
    quantity,
    locale,
    searchTerm: trimmedSearchTerm,
    cursor,
  };
};

export const convertQueryArguments = (args: QueryArguments): SwrArguments => {
  const {
    locale,
    searchTerm = null,
    cursor,
  } = args;
  const trimmedSearchTerm = searchTerm != null ? searchTerm.trim() : '';
  return [query, locale, trimmedSearchTerm === '' ? null : trimmedSearchTerm, cursor];
};

export const fetcher = async (args: BaseSwrArguments): Promise<QueryResponse> => {
  const [queryString] = args;
  const sanityArguments = convertBaseSwrArguments(args);
  const {
    searchTerm,
    ...rest
  } = sanityArguments;
  const searchTermArray = searchTerm != null && searchTerm !== ''
    ? searchTerm
      .split(' ')
      .map((word) => `*${word}*`)
    : null;
  const baseResponse = await sanityClient.fetch<BaseQueryResponse, QueryArgumentsWithSearchTermArray>(
    queryString,
    {
      ...rest,
      searchTerm: searchTermArray,
    },
  );
  const {
    quantity,
  } = sanityArguments;
  const {
    news: baseNewsList,
    promotions: basePromotions,
  } = baseResponse;
  const news = baseNewsList.map((item, _index, src) => ({
    ...item,
    slug: slugify({
      locale: item.language,
      title: item.title,
      date: item.publishDate,
      // eslint-disable-next-line no-underscore-dangle
      id: item._id,
    }),
    quantity,
    lastPage: src.length < quantity,
  }));
  const promotions = filterAndTransformPromoQueryResponse(basePromotions);
  return {
    news,
    promotions,
  };
};

export const makeLoadingSlugs = (start: number, stop: number) => times((index) => `index-${start + index}`, stop - start);
