import ouiBase from '@goldwasserexchange/oui';
import { makeTAddIsTypePhysicLogic } from '../../TYPE/logic';
import { professionOptionalLastProfession } from '../../ID_ECPL_PROF/list';
var idEcplProfPath = 'ID_ECPL_PROF';
export function makeShowLogic(props) {
    var _a = props.truthy, truthy = _a === void 0 ? {
        result: 'visible',
    } : _a, _b = props.falsy, falsy = _b === void 0 ? {
        result: 'invisible',
    } : _b;
    return makeTAddIsTypePhysicLogic({
        alternatives: {
            truthy: {
                name: "<parentName>.".concat(idEcplProfPath),
                is: professionOptionalLastProfession,
                options: {
                    matcher: ouiBase.utils.match.arrayMatcherNames.some,
                },
                alternatives: {
                    truthy: truthy,
                    falsy: falsy,
                },
            },
            falsy: falsy,
        },
    });
}
export var showLogic = makeShowLogic({});
