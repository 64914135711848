import * as React from 'react';
import { useAuthMachineSend } from '../../../../context';

export const InputCodeForm = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  const send = useAuthMachineSend();
  const onSubmit = React.useCallback((e) => {
    e.preventDefault();
    send({
      type: 'SUBMIT',
    });
  }, [
    send,
  ]);
  return (
    <form
      onSubmit={onSubmit}
    >
      {children}
    </form>
  );
};
