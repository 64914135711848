import {
  Dispatch, SetStateAction, useMemo, useEffect, useState,
} from 'react';
import { useAccountPositionsLiquiditiesCCurrencyCodes } from './useAccountPositionsLiquiditiesCCurrencyCodes';

export const useCurrentAccountPositionsLiquiditiesCCurrencyCode = () => {
  const {
    data: currencies,
    isLoading,
  } = useAccountPositionsLiquiditiesCCurrencyCodes();
  const handlers = useState('EUR');
  const [currencyCode, setCurrencyCode] = handlers;
  const joinedCurrencies = currencies?.join('-') ?? '';
  useEffect(() => {
    if (currencies && currencies.length > 0) {
      if (!currencies.includes(currencyCode)) {
        setCurrencyCode(currencies[0]);
      }
    } else if (currencyCode !== 'EUR') {
      setCurrencyCode('EUR');
    }
  }, [joinedCurrencies, currencyCode]);
  const currenciesHasContent = (currencies?.length ?? 0) > 0;
  const result = useMemo((): [
    string,
    Dispatch<SetStateAction<string>>,
    boolean,
    boolean,
  ] => [...handlers, isLoading, currenciesHasContent], [
    handlers,
    isLoading,
    currenciesHasContent,
  ]);
  return result;
};
