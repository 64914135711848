import ouiBase from '@goldwasserexchange/oui';
import * as React from 'react';
import { elementFieldPathHookContext } from './context';

export const ElementFieldPathHookProvider = (
  props: React.PropsWithChildren<{
    elementFieldPathHook: () => string,
  }>,
) => {
  const { elementFieldPathHook, children } = props;
  const freezedElementFieldPathHook = ouiBase.utils.freeze.useFreeze(elementFieldPathHook);
  return (
    <elementFieldPathHookContext.Provider value={freezedElementFieldPathHook}>
      {children}
    </elementFieldPathHookContext.Provider>
  );
};
