import * as React from 'react';
import { useHasFocus } from '../focus';
import { showHelpTextContext } from './context';

export const ShowHelpTextContextProvider = (
  props: React.PropsWithChildren<{}>,
) => {
  const { children } = props;
  const showHelpState = React.useState(false);
  const [showHelp, setShowHelp] = showHelpState;
  const hasFocus = useHasFocus();
  React.useEffect(
    () => {
      if (showHelp !== hasFocus) {
        setShowHelp(hasFocus);
      }
    },
    /* the next dependency array isn't lintend because we do not want to react to showHelp changes */[
      setShowHelp,
      hasFocus,
    ],
  );
  return (
    <showHelpTextContext.Provider value={showHelpState}>
      {children}
    </showHelpTextContext.Provider>
  );
};
