import * as React from 'react';
import {
  BondsBondSMarketGrossYield,
  BondsBondSMarketNetYield,
  BondsBondSQuoteArchiveDate,
  BondsBondSQuotePrice,
  GrossAmountFor,
  NetAmountFor,
} from '../../../../actor/api/Services/bonds';
import { SanityBondFieldContainer } from './components';

export const SanityBondField = (props) => {
  const {
    value,
  } = props;
  const {
    id,
    field,
    tob = false,
    bold,
    italic,
    underline,
    strike,
  } = value;
  switch (field) {
    case 'yield':
    case 'gross_yield':
      return (
        <SanityBondFieldContainer
          id={id}
          bold={bold}
          italic={italic}
          underline={underline}
          strike={strike}
        >
          <BondsBondSMarketGrossYield />
        </SanityBondFieldContainer>
      );
    case 'net_yield':
      return (
        <SanityBondFieldContainer
          id={id}
          bold={bold}
          italic={italic}
          underline={underline}
          strike={strike}
        >
          <BondsBondSMarketNetYield
            tob={tob}
          />
        </SanityBondFieldContainer>
      );
    case 'price':
      return (
        <SanityBondFieldContainer
          id={id}
          bold={bold}
          italic={italic}
          underline={underline}
          strike={strike}
        >
          <BondsBondSQuotePrice />
        </SanityBondFieldContainer>
      );
    case 'price_date':
      return (
        <SanityBondFieldContainer
          id={id}
          bold={bold}
          italic={italic}
          underline={underline}
          strike={strike}
        >
          <BondsBondSQuoteArchiveDate />
        </SanityBondFieldContainer>
      );
    case 'gross_amount_for_10k':
      return (
        <SanityBondFieldContainer
          id={id}
          bold={bold}
          italic={italic}
          underline={underline}
          strike={strike}
        >
          <GrossAmountFor nominal={10_000} />
        </SanityBondFieldContainer>
      );
    case 'net_amount_for_10k':
      return (
        <SanityBondFieldContainer
          id={id}
          bold={bold}
          italic={italic}
          underline={underline}
          strike={strike}
        >
          <NetAmountFor nominal={10_000} tob={tob} />
        </SanityBondFieldContainer>
      );
    default:
      return null;
  }
};
