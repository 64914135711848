import * as React from 'react';
import { ActorApiNumberDisplay } from '../../../../../../components/display/number/component';
import {
  useBondsBondIsRedacted,
  useBondsBondSMarketYieldExists,
  useBondsBondSQuotePrice,
  useBondsBondNetYieldCalculator,
  useBondsBondAccruedValue,
} from '../../hooks';
import { getPriceWithTob } from '../../utils';

export const BondsBondSMarketNetYield = (props: {
  tob?: boolean,
  withRedactedIcon?: boolean,
}) => {
  const {
    tob,
    withRedactedIcon,
  } = props;
  const {
    data: actorYieldExists,
    isLoading: actorYieldExistsIsLoading,
  } = useBondsBondSMarketYieldExists();
  const {
    data: yieldCalculator,
    isLoading,
    error,
  } = useBondsBondNetYieldCalculator();
  const {
    data: accrued,
    isLoading: accruedIsLoading,
  } = useBondsBondAccruedValue();
  const {
    data: price,
    isLoading: priceIsLoading,
  } = useBondsBondSQuotePrice();
  const {
    data: redacted,
    isLoading: redactedIsLoading,
  } = useBondsBondIsRedacted('S_MARKET.YIELD');
  const priceWithTob = getPriceWithTob({
    price,
    accrued,
    tob,
  });
  return (
    <ActorApiNumberDisplay
      data={yieldCalculator != null && priceWithTob != null && actorYieldExists === true ? yieldCalculator(priceWithTob) * 100 : undefined}
      isLoading={isLoading || redactedIsLoading || priceIsLoading || actorYieldExistsIsLoading || accruedIsLoading}
      error={error}
      format={{
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 3,
      }}
      loadingValue={3.625}
      nullContent="–"
      isBlocked={redacted}
      withRedactedIcon={withRedactedIcon}
    />
  );
};
