import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { AnchorBase, AnchorButtonBase, LinkBase, LinkButtonBase, } from './components';
export var aDefaultTopModelsObject = {
    ABase: (React.createElement(TopModel, { name: "ABase" }, AnchorBase)),
    ABaseLink: (React.createElement(TopModel, { name: "ABaseLink" }, LinkBase)),
};
export var aButtonDefaultTopModelsObject = {
    ABase: (React.createElement(TopModel, { name: "ABase" }, AnchorButtonBase)),
    ABaseLink: (React.createElement(TopModel, { name: "ABaseLink" }, LinkButtonBase)),
};
