import { useCallback } from 'react';
import ouiBase from '@goldwasserexchange/oui';
import { useLmlGetPositionsTitleCase } from '../../utils';
import { useAccountPositionTransformApiByGesEstimDId } from '../api';
import { AccountPosition } from '../../type';
import { useLinguiLocale } from '../../../../../../../Components/useLinguiLocale';

export const useAccountPositionsSAccLml2Description = () => {
  const gesEstimDId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const locale = useLinguiLocale();
  const titleCaser = useLmlGetPositionsTitleCase(locale);
  const transformFunction = useCallback((accountPosition: AccountPosition | undefined) => {
    if (!accountPosition) {
      return '';
    }
    const lml2Description = accountPosition['S_ACC.LML2_DESCRIPTION'];
    return lml2Description ? titleCaser(lml2Description) : accountPosition['C_CURRENCY.CODE'];
  }, [titleCaser]);
  const response = useAccountPositionTransformApiByGesEstimDId({
    gesEstimDId,
    transformFunction,
  });
  return response;
};
