import React, { useContext } from 'react';
import ouiBase from '@goldwasserexchange/oui';
import { stringIsNegative, trimNegativeFromString } from '../../Match/syntax';
import {
  MatcherOnStateProps, MatcherOnState,
} from './utils';
import { MachineContext, MachineDefinitionContext } from '../context';

type matchActionOptions = {
  isNegative?: boolean,
}

export const matchActionString = (is: string, state: any, options?: matchActionOptions): boolean => {
  const { isNegative = false } = options || {};
  const matches = state.nextEvents.includes(is);
  return !isNegative ? matches : !matches;
};

export const matchActionStringWithGuard = (is: string, state: any, machineOptions: any, options?: matchActionOptions): boolean => {
  const { isNegative = false } = options || {};
  const { context, configuration } = state;
  const matches = configuration.reduce(
    (acc, sn) => {
      const ownEventGuards = sn.ownEvents
        .filter((d) => d === is)
        .some((event) => {
          if (sn.config.on[event].cond) {
            const condName = typeof sn.config.on[event].cond === 'object' ? sn.config.on[event].cond.type : sn.config.on[event].cond;
            return machineOptions.guards[condName](context, {}, sn.config.on[event]);
          }
          return true;
        });
      return acc || ownEventGuards;
    },
    false,
  );
  return !isNegative ? matches : !matches;
};

export const matchActionStringWithSyntax = (state: any) => (is: string): boolean => {
  const isNegative = stringIsNegative(is);
  const trimmedIs = trimNegativeFromString(is);
  const matches = matchActionString(trimmedIs, state, { isNegative });
  return matches;
};

export const matchActionStringWithGuardAndSyntax = (machineOptions: any) => (state: any) => (is: string): boolean => {
  const isNegative = stringIsNegative(is);
  const trimmedIs = trimNegativeFromString(is);
  const matches = matchActionStringWithGuard(trimmedIs, state, machineOptions, { isNegative });
  return matches;
};

export const useMatchOnStateWithUpdatedContext = (elementMatcher: any, is: string, options?: ouiBase.utils.match.MatchArrayOptions): boolean | string | null => {
  const [state] = useContext(MachineContext);
  const matches = ouiBase.utils.match.match(elementMatcher({ ...state, context: state.context }), is, options);
  return matches;
};

export const useActionWithGuardFilter = (is: string, options?: ouiBase.utils.match.MatchArrayOptions) => {
  const [, machineOptions] = useContext(MachineDefinitionContext);
  const matches = useMatchOnStateWithUpdatedContext(matchActionStringWithGuardAndSyntax(machineOptions), is, options);
  return matches;
};

type ActionFilterProps = Omit<MatcherOnStateProps, 'elementMatcher'>

export const ActionFilter = (
  props: React.PropsWithChildren<ActionFilterProps>,
): JSX.Element | null => {
  const { children, ...rest } = props;
  return (
    <MatcherOnState
      {...rest}
      elementMatcher={matchActionStringWithSyntax}
    >
      {children}
    </MatcherOnState>
  );
};
