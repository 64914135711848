import { createContext } from 'react';
import { SimulationBuyAndSellCostData } from '../../type';
import { DEFAULT_SIMULATION_COUNTER_VALUE } from '../../utils/computeCountervalue';

export const simulationDataBuyAndSellContext = createContext<SimulationBuyAndSellCostData>({
  counterValue: DEFAULT_SIMULATION_COUNTER_VALUE,
  durationYears: 3,
  type: 'bonds',
  foreignCurrency: false,
  refund: false,
});
