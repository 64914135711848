import ouiBase from '@goldwasserexchange/oui';
import { replace } from 'ramda';
import { useContext } from 'react';
import { useHookstate } from '@hookstate/core';
import { useFieldContent, useGetFieldPath } from '../../../Inputs';
import { correctAnswerContext } from './context';
import { useFieldIsCorrectedByFieldPath } from './isCorrected';

export function useCorrectAnswer() {
  const correct = useContext(correctAnswerContext);
  return useHookstate(correct);
}

export function useFieldCorrectAnswerByFieldPath(fieldPath: string) {
  const correct = useCorrectAnswer();
  return useHookstate(correct.nested(fieldPath));
}

export function useFieldIsCorrectByFieldPath(fieldPath: string) {
  const correctAnswer = useFieldCorrectAnswerByFieldPath(replace('Answer', '', fieldPath)).get();
  const primaryKey = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  return primaryKey === correctAnswer;
}

export type CorrectVariantNames = 'notCorrected' | 'correct' | 'incorrect';

export function useCorrectVariant(fieldPath: string): CorrectVariantNames {
  const isCorrected = useFieldIsCorrectedByFieldPath(fieldPath);
  const isCorrect = useFieldIsCorrectByFieldPath(fieldPath);
  if (!isCorrected.get()) {
    return 'notCorrected';
  }
  return isCorrect ? 'correct' : 'incorrect';
}

export function useCorrectCurrentVariant() {
  const fieldPath = useGetFieldPath();
  return useCorrectVariant(fieldPath);
}

export type CorrectWithSelectVariantNames = CorrectVariantNames | 'correctSelected' | 'incorrectSelected' | 'correctNotSelected' | 'incorrectNotSelected'

export function useCorrectWithSelectCurrentVariant(): CorrectWithSelectVariantNames {
  const correctVariant = useCorrectCurrentVariant();
  const value = useFieldContent().get();
  const primaryKey = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const selected = value === primaryKey;
  if (correctVariant === 'notCorrected') {
    return correctVariant;
  }
  if (selected) {
    return `${correctVariant}Selected` as 'correctSelected' | 'incorrectSelected';
  }
  return `${correctVariant}NotSelected` as 'correctNotSelected' | 'incorrectNotSelected';
}
