import * as React from 'react';
import ouiDom from '@goldwasserexchange/oui-dom';
import { ErrorBoundary } from '../ErrorBoundary';
import { SanityTableContainer } from './components';

export const SanityTableWithErrorBoundary = (props) => (
  <ErrorBoundary renderNull>
    <SanityTableContainer>
      <ouiDom.SanityTable.SanityTable {...props} />
    </SanityTableContainer>
  </ErrorBoundary>
);
