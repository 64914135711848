import * as ScrollUp from './scrollUp';
import * as ScrollDown from './scrollDown';

export * as ScrollUp from './scrollUp';
export * as ScrollDown from './scrollDown';

export type EventTypeString = ScrollUp.EventTypeString | ScrollDown.EventTypeString;

export const eventTypes = [...ScrollUp.eventTypes, ...ScrollDown.eventTypes];

export const eventTypeStrings: string[] = eventTypes as string[];

export type EventPayload = ScrollUp.EventPayload | ScrollDown.EventPayload;

export type EventType = ScrollUp.EventType | ScrollDown.EventType

export const eventBuilder = (payload: EventPayload): EventType => {
  const {
    scrollDirection,
  } = payload;
  return scrollDirection === 'down' ? ScrollDown.eventBuilder(payload) : ScrollUp.eventBuilder(payload);
};
