import React from 'react';
import { Trans } from '@lingui/macro';
import ouiBase from '@goldwasserexchange/oui';
import { dataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import { FilterCurrentUser } from '../../Sections/onboarding/form/constants';
import { LowerAlphaListContainer } from '../../lowerAlphaList';

export const tAddYnUsLabel = (
  <>
    <FilterCurrentUser>
      <Trans>Avez-vous un lien (nationalité, résidence, green card) avec les Etats-Unis d'Amérique ?</Trans>
    </FilterCurrentUser>
    <FilterCurrentUser isNegative>
      <Trans>La personne a-t-elle un lien (nationalité, résidence, green card) avec les Etats-Unis d'Amérique ?</Trans>
    </FilterCurrentUser>
  </>
);

export const tAddYnUsHelpPopper = (
  <Trans>
    <div>
      <LowerAlphaListContainer>
        <li>
          est citoyen américain ou née aux USA (simple ou double nationalité) ;
        </li>
        <li>
          est un résident étranger ayant son domicile aux USA (permis de résident permanent, détenteur de la carte verte de séjour «Green Card», ou longs et fréquents séjours aux USA, cette année et au cours de ces deux dernières années) ;
        </li>
        <li>
          est un contribuable américain pour une autre raison (double résident, statut fiscal commun avec le (la) conjoint(e), renonciation à la nationalité américaine).
        </li>
      </LowerAlphaListContainer>
    </div>
  </Trans>
);

export const tAddYnUsValidatorLogic: ouiBase.utils.hookstate.FormValueFilterLogic<'requiredBoolean' | 'nilCast'> = dataStructure.T_ADD.fields.TYPE.logic.makeTAddIsNotMoralOrIgnored({
  truthy: {
    result: 'requiredBoolean',
  },
  falsy: {
    result: 'nilCast',
  },
});
