import { msg } from '@lingui/macro';
import * as React from 'react';
import { useLingui } from '@lingui/react';
import { ErrorBoundary } from '../../../../../../../Components/ErrorBoundary';
import { ActorApiStringDisplay } from '../../../../../../components/display/string';
import {
  useBondsBondAccruedValue, useBondsBondCalculator, useBondsBondCCurrencyCode, useBondsBondHasRedacted,
} from '../../hooks';
import { ActorApiNumberDisplayCurrency } from '../../../../../../components/display/number/currency';

const BondsBondAccruedDaysInner = (props: {
  withRedactedIcon?: boolean,
}) => {
  const {
    withRedactedIcon,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useBondsBondCalculator();
  const {
    data: isBlocked,
  } = useBondsBondHasRedacted();
  const {
    i18n,
  } = useLingui();
  const plural = data ? data.accruedDays > 1 : false;
  const accruedDays = data ? `${data.accruedDays} ${plural ? i18n._(msg`jours`) : i18n._(msg`jour`)}` : data;
  return (
    <ActorApiStringDisplay
      data={accruedDays}
      isLoading={isLoading}
      error={error}
      withRedactedIcon={withRedactedIcon}
      isBlocked={isBlocked}
    />
  );
};

export const BondsBondAccruedDays = (props: {
  withRedactedIcon?: boolean,
}) => {
  const {
    withRedactedIcon,
  } = props;
  return (
    <ErrorBoundary
      renderNull
    >
      <BondsBondAccruedDaysInner withRedactedIcon={withRedactedIcon} />
    </ErrorBoundary>
  );
};

const BondsBondAccruedValueInner = (props: {
  withRedactedIcon?: boolean,
}) => {
  const {
    withRedactedIcon,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useBondsBondAccruedValue();
  const {
    data: currencyData,
    isLoading: currencyIsLoading,
    error: currencyError,
  } = useBondsBondCCurrencyCode();
  const {
    data: isBlocked,
  } = useBondsBondHasRedacted();
  return (
    <ActorApiNumberDisplayCurrency
      data={data}
      isLoading={isLoading}
      error={error}
      currencyData={currencyData}
      currencyIsLoading={currencyIsLoading}
      currencyError={currencyError}
      withRedactedIcon={withRedactedIcon}
      isBlocked={isBlocked}
    />
  );
};

export const BondsBondAccruedValue = (props: {
  withRedactedIcon?: boolean,
}) => {
  const {
    withRedactedIcon,
  } = props;
  return (
    <ErrorBoundary
      renderNull
    >
      <BondsBondAccruedValueInner withRedactedIcon={withRedactedIcon} />
    </ErrorBoundary>
  );
};
