import React, { useEffect } from 'react';
import { I18nProvider } from '@lingui/react';
import { IsPrintContextProvider, UncastedDataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import { useAtomValue } from 'jotai';
import ouiBase from '@goldwasserexchange/oui';
import { useHookstate } from '@hookstate/core';
import { AppContainer } from '../AppContainer';
import { MediaContextProvider, OuiStyleMediaContextProvider } from '../MediaContext';
import { makeInitialValues } from './initialValues';
import { validator } from './validator';
import {
  FullFormContext,
  ZIndexSourceProvider,
} from '../../OUI/Form';
import {
  correctAnswers,
} from './correctAnswers';
import { AuthContextProvider } from '../Auth';
import { SelectedAccountContextProvider } from '../Sections/online/selectedAccount';
import { BannerHeightProvider } from '../banner/context';
import {
  HistoryProvider, pathnameUrlLanguageAtom, languageI18n,
} from '../../history';

const LocaleSet = () => {
  const language = useAtomValue(pathnameUrlLanguageAtom);
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const languageState = useHookstate(valueState.onboarding.language);
  useEffect(() => {
    if (language !== languageState.get()) {
      languageState.set(language);
    }
  }, [language, languageState.get()]);
  return null;
};

export const OblisContainer = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  return (
    <HistoryProvider>
      <FullFormContext
        makeInitialValues={makeInitialValues}
        validator={validator}
        correctAnswers={correctAnswers}
      >
        <IsPrintContextProvider>
          <LocaleSet />
          <ZIndexSourceProvider>
            <AuthContextProvider>
              <SelectedAccountContextProvider>
                <AppContainer>
                  <OuiStyleMediaContextProvider>
                    <MediaContextProvider>
                      <I18nProvider i18n={languageI18n}>
                        <BannerHeightProvider>
                          {children}
                        </BannerHeightProvider>
                      </I18nProvider>
                    </MediaContextProvider>
                  </OuiStyleMediaContextProvider>
                </AppContainer>
              </SelectedAccountContextProvider>
            </AuthContextProvider>
          </ZIndexSourceProvider>
        </IsPrintContextProvider>
      </FullFormContext>
    </HistoryProvider>
  );
};
