import * as React from 'react';
import { TopModelMerge, TopModelNameTransformProvider } from '@goldwasserexchange/react-topmodel';
import { AButtonStructure, AStructure } from './structure';
import { aButtonDefaultTopModelsObject, aDefaultTopModelsObject } from './topmodels';
import { aPrefixer } from './utils';
export function A(props) {
    if ('href' in props) {
        var href = props.href, target_1 = props.target, fontSize_1 = props.fontSize, fontWeight_1 = props.fontWeight, color_1 = props.color, onClick = props.onClick, children_1 = props.children;
        return (React.createElement(TopModelNameTransformProvider, { transformFn: aPrefixer },
            React.createElement(TopModelMerge, { topModels: aDefaultTopModelsObject },
                React.createElement(AStructure, { href: href, target: target_1, fontSize: fontSize_1, fontWeight: fontWeight_1, color: color_1, onClick: onClick }, children_1))));
    }
    var to = props.to, replace = props.replace, target = props.target, fontSize = props.fontSize, fontWeight = props.fontWeight, color = props.color, children = props.children;
    return (React.createElement(TopModelNameTransformProvider, { transformFn: aPrefixer },
        React.createElement(TopModelMerge, { topModels: aDefaultTopModelsObject },
            React.createElement(AStructure, { to: to, replace: replace, target: target, fontSize: fontSize, fontWeight: fontWeight, color: color }, children))));
}
export function AButton(props) {
    if ('href' in props) {
        var href = props.href, target_2 = props.target, fontSize_2 = props.fontSize, fontWeight_2 = props.fontWeight, color_2 = props.color, hoverBackgroundColor_1 = props.hoverBackgroundColor, children_2 = props.children;
        return (React.createElement(TopModelNameTransformProvider, { transformFn: aPrefixer },
            React.createElement(TopModelMerge, { topModels: aButtonDefaultTopModelsObject },
                React.createElement(AButtonStructure, { href: href, target: target_2, fontSize: fontSize_2, fontWeight: fontWeight_2, color: color_2, hoverBackgroundColor: hoverBackgroundColor_1 }, children_2))));
    }
    var to = props.to, replace = props.replace, target = props.target, fontSize = props.fontSize, fontWeight = props.fontWeight, color = props.color, hoverBackgroundColor = props.hoverBackgroundColor, children = props.children;
    return (React.createElement(TopModelNameTransformProvider, { transformFn: aPrefixer },
        React.createElement(TopModelMerge, { topModels: aButtonDefaultTopModelsObject },
            React.createElement(AButtonStructure, { to: to, replace: replace, target: target, fontSize: fontSize, fontWeight: fontWeight, color: color, hoverBackgroundColor: hoverBackgroundColor }, children))));
}
