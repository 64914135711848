import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { InputTypeContextProvider } from '../inputType';
import { FieldPathProvider } from '../fieldPath';
import {
  FieldErrorsContextProvider,
  InputDependenciesFieldPathsProvider,
} from '../valid';
import { FocusWithHandlersSetContextProvider } from '../focus';
import { ShowHelpTextContextProvider } from '../helpText';
import { TouchedContext } from '../touched';

export const inputContextProviderContextsDefaultTopModelsObject = {
  TypeContextProvider: (
    <TopModel name="TypeContextProvider ">{InputTypeContextProvider}</TopModel>
  ),
  DependenciesFieldPathsProvider: (
    <TopModel name="InputDependenciesFieldPathsProvider">
      {InputDependenciesFieldPathsProvider}
    </TopModel>
  ),
  FieldPathProvider: (
    <TopModel name="FieldPathProvider">{FieldPathProvider}</TopModel>
  ),
  FieldErrorsContextProvider: (
    <TopModel name="FieldErrorsContextProvider">
      {FieldErrorsContextProvider}
    </TopModel>
  ),
  FocusWithHandlersContextProvider: (
    <TopModel name="FocusWithHandlersContextProvider">
      {FocusWithHandlersSetContextProvider}
    </TopModel>
  ),
  ShowHelpTextContextProvider: (
    <TopModel name="ShowHelpTextContextProvider">
      {ShowHelpTextContextProvider}
    </TopModel>
  ),
  TouchedContextProvider: (
    <TopModel name="TouchedContextProvider">
      {TouchedContext}
    </TopModel>
  ),
};
