import * as React from 'react';
import { useBondsBondDisconnectedAndNotPromo } from '../../../../../../actor/api/Services/bonds';
import { useSecurityQuotesGraphDataWithLoadingData } from '../../../../../../actor/api/Services/securities';
import { BondPriceGraphInner } from '../graph';
import { SanitySecurityPriceGraphTitle } from '../title';

export const SecurityPriceGraphContent = (props: {
  alternateTitle?: string | null,
}) => {
  const {
    alternateTitle,
  } = props;
  const {
    data: hasRedacted,
    isLoading: hasRedactedIsLoading,
  } = useBondsBondDisconnectedAndNotPromo();
  const {
    data,
    isLoading,
  } = useSecurityQuotesGraphDataWithLoadingData(hasRedacted ?? hasRedactedIsLoading, undefined);
  if (!data || data.length === 0) {
    return null;
  }
  return (
    <>
      <SanitySecurityPriceGraphTitle
        alternateTitle={alternateTitle}
      />
      <BondPriceGraphInner
        data={data}
        isLoading={isLoading}
        hasRedacted={hasRedacted}
        hasRedactedIsLoading={hasRedactedIsLoading}
      />
    </>
  );
};
