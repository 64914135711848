import * as TYPE from '../../TYPE';
import { PersonType, TAddType, } from '../../../../../types';
var onboardingPath = 'onboarding';
var personTypePath = 'personType';
var currentPath = 'current';
var tAddTypePath = 'T_ADD_type';
export var showLogic = TYPE.logic.makeTAddIsTypePhysicLogic({
    alternatives: {
        truthy: {
            name: "".concat(onboardingPath, ".").concat(personTypePath),
            is: PersonType.FOR_MINORS,
            alternatives: {
                truthy: {
                    name: "<parentName>.".concat(currentPath),
                    is: true,
                    alternatives: {
                        truthy: {
                            result: 'visible',
                        },
                        falsy: {
                            name: "<parentName>.".concat(tAddTypePath),
                            is: TAddType.TITULAR,
                            options: {
                                isNegative: true,
                            },
                            alternatives: {
                                truthy: {
                                    result: 'visible',
                                },
                                falsy: {
                                    result: 'invisible',
                                },
                            },
                        },
                    },
                },
                falsy: {
                    result: 'visible',
                },
            },
        },
        falsy: {
            result: 'invisible',
        },
    },
});
