var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
var ratioContainerStyle = {
    position: 'relative',
    widthLines: 'full',
    marginVertical: 'simple',
};
export var RatioContainer = function (props) {
    var lines = props.lines, aspectRatio = props.aspectRatio, _a = props.marginVertical, marginVertical = _a === void 0 ? ratioContainerStyle.marginVertical : _a, _b = props.marginTop, marginTop = _b === void 0 ? marginVertical !== null && marginVertical !== void 0 ? marginVertical : ratioContainerStyle.marginTop : _b, _c = props.marginBottom, marginBottom = _c === void 0 ? marginVertical !== null && marginVertical !== void 0 ? marginVertical : ratioContainerStyle.marginBottom : _c, children = props.children;
    var css = React.useMemo(function () { return ouiStyle.mediaQuery(__assign(__assign({}, ouiStyle.makeCss(__assign(__assign({}, ratioContainerStyle), { lines: lines, marginTop: marginTop, marginBottom: marginBottom }))), { aspectRatio: "".concat(aspectRatio), paddingTop: "".concat(100 / aspectRatio, "%") })); }, [
        aspectRatio,
        marginTop,
        marginBottom,
        lines,
    ]);
    return (jsx("div", { css: css }, children));
};
