import * as React from 'react';
import { Ramp, useTopModelNameTransform } from '@goldwasserexchange/react-topmodel';

export type BaseBuilderProps = { rampName: string };

export function getBaseBuilderNames(name: string) {
  return [`${name}Container`, `${name}Content`];
}

export function BaseBuilder(props: BaseBuilderProps) {
  const { rampName: name } = props;
  const [container, content] = getBaseBuilderNames(name);
  return (
    <Ramp rampName={container}>
      <Ramp rampName={content} />
    </Ramp>
  );
}

export function BaseBuilderWithNameTransform(props: BaseBuilderProps) {
  const { rampName: sourceName } = props;
  const name = useTopModelNameTransform(sourceName);
  return <BaseBuilder rampName={name} />;
}
