import { Context, useContext } from 'react';
import { leftButtonVisibleContext } from '../leftButtonVisible';
import { rightButtonVisibleContext } from '../rightButtonVisible';

export type ScrollDirections = 'left' | 'right';

const leftRighButtonVisibletContext: Record<ScrollDirections, Context<boolean>> = {
  left: leftButtonVisibleContext,
  right: rightButtonVisibleContext,
};

export const useIsButtonVisible = (direction: ScrollDirections) => {
  const directionContext = leftRighButtonVisibletContext[direction];
  const visible = useContext(directionContext);
  return visible;
};
