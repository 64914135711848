import { useSize } from '../../../../../../components/context/size';
import { makeIsLoadingMore } from '../../utils';
import { useAccountDocumentsTransformApi } from '../api';

export const useAccountDocumentsIsLoadingOrLoadingMore = () => {
  const size = useSize();
  const getIsLoadingMore = makeIsLoadingMore(size);
  const response = useAccountDocumentsTransformApi({
    transformFunction: getIsLoadingMore,
  });
  const data = response.isLoading || response.data;
  return ({
    ...response,
    data,
  });
};
