import ouiBase from '@goldwasserexchange/oui';
import { PassThrough, TopModel } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import {
  ZIndexInvertedChildren,
  OnSubmitContextProvider,
  OnSubmitType,
} from './context';

export function Form(
  props: React.PropsWithChildren<{
    onSubmit?: OnSubmitType,
  }>,
) {
  const {
    onSubmit, children,
  } = props;
  return (
    <ouiBase.Form.FormOnGlobalDs>
      <TopModel name="FormContainer">
        {PassThrough}
      </TopModel>
      <OnSubmitContextProvider onSubmit={onSubmit}>
        <ZIndexInvertedChildren>
          {children}
        </ZIndexInvertedChildren>
      </OnSubmitContextProvider>
    </ouiBase.Form.FormOnGlobalDs>
  );
}
