import ouiBase from '@goldwasserexchange/oui';
import { useAccountHistoryFieldApiByHisMvtId } from '../api';

export const useAccountHistoryHisMvtCmtConsult = () => {
  const hisMvtId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const result = useAccountHistoryFieldApiByHisMvtId({
    hisMvtId,
    field: 'HIS_MVT.CMT_CONSULT',
  });
  return result;
};
