import { getTopModelFromChildren, TopModelMerge } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import { ToolbarDropDownContainer } from './container';
import { ToolbarDropDownContextProvider } from './context';
import { ToolbarDropDownLabel } from './label';
import { ToolbarDropDownList } from './list';

export const ToolbarDropDown = (props: React.PropsWithChildren<{
  list: string[],
}>) => {
  const {
    list,
    children: incomingChildren,
  } = props;
  const [, topModelChildren] = getTopModelFromChildren(incomingChildren);
  return (
    <TopModelMerge topModels={topModelChildren}>
      <ToolbarDropDownContextProvider
        list={list}
      >
        <ToolbarDropDownContainer>
          <ToolbarDropDownLabel />
          <ToolbarDropDownList />
        </ToolbarDropDownContainer>
      </ToolbarDropDownContextProvider>
    </TopModelMerge>
  );
};
