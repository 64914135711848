import {
  History, Update, createPath,
} from 'history';
import { languageRouteDefinition } from './router';
import { splitPathname } from '../../utils';
import {
  getLanguageRedirectValue,
  getStorageLanguage,
  isLocale,
  setStorageLanguage,
} from './utils';

export const languageRouting = (sourceHistory: History, currentUpdate: Update) => {
  const update = {
    action: currentUpdate.action,
    location: {
      ...currentUpdate.location,
    },
  };
  const { location } = update;
  const {
    pathname,
  } = location;
  const splittedPathname = splitPathname(pathname);
  let language = splittedPathname.at(0);
  const languageRedirectValue = getLanguageRedirectValue(
    language,
  );
  if (languageRedirectValue != null) {
    language = languageRedirectValue;
    update.location.pathname = `/${[language, ...splittedPathname.slice(1)].join('/')}`;
    sourceHistory.replace(createPath(update.location));
  }
  language = language === '' || language == null ? getStorageLanguage() : language;
  if (language && isLocale(language)) {
    const { importFn } = languageRouteDefinition[language];
    if (importFn != null && typeof importFn === 'function') {
      importFn();
    }
  }
  if (language !== splittedPathname.at(0)) {
    update.location.pathname = `/${[language, ...splittedPathname.slice(1)].join('/')}`;
  }
  if (language !== getStorageLanguage() && isLocale(language)) {
    setStorageLanguage(language);
  }
  return update;
};
