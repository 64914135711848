import * as React from 'react';
import { SanityTableWithErrorBoundary } from '../../../SanityTable';

export const SanityTableWithData = (props: {
  value?: any,
}) => {
  const {
    value,
  } = props;
  return (
    <SanityTableWithErrorBoundary
      value={value}
    />
  );
};
