var _a;
import * as fields from '../../fields';
import { STypeGrp } from '../../../../types';
export var makeFinancialProductSelectedLogicRecord = (_a = {},
    _a[STypeGrp.OBLIGATIONS] = fields.BOND.logic.makeSelectedLogic,
    _a[STypeGrp.FONDS] = fields.FUND.logic.makeSelectedLogic,
    _a[STypeGrp.OBLIGATIONS_PERP] = fields.PERPETUAL_BOND.logic.makeSelectedLogic,
    _a[STypeGrp.ACTIONS] = fields.STOCK.logic.makeSelectedLogic,
    _a[STypeGrp.OBLIGATIONS_SUB] = fields.SUBORDINATED_BOND.logic.makeSelectedLogic,
    _a[STypeGrp.TRACKERS] = fields.TRACKER.logic.makeSelectedLogic,
    _a[STypeGrp.TRACKERS_MAT] = fields.RAW_MATERIAL_TRACKER.logic.makeSelectedLogic,
    _a);
