import * as React from 'react';
import { Toaster } from 'react-hot-toast';
import { GEToastBar } from './components';

export const GEToaster = () => (
  <Toaster
    position="bottom-center"
  >
    {(t) => (
      <GEToastBar toast={t} />
    )}
  </Toaster>
);
