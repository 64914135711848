var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useTopModelExistByNameWithFallbacksAndNameTransform } from '@goldwasserexchange/react-topmodel';
import { useHeadingSectiondepth, useHeadingSectionIndex } from '../../../context';
import { useHeadingSectionName } from '../../../context/name';
export var useIntroContentRampNames = function () {
    var headingName = useHeadingSectionName();
    var headingDepth = useHeadingSectiondepth();
    var headingIndex = useHeadingSectionIndex();
    var result = [
        "IntroContent-".concat(headingName),
        [
            "IntroContent-".concat(headingDepth, "-").concat(headingIndex),
            "IntroContent-".concat(headingDepth),
            'IntroContent',
        ],
    ];
    return result;
};
export var useHasIntroContent = function () {
    var _a = __read(useIntroContentRampNames(), 2), rampName = _a[0], rampFallbackNames = _a[1];
    var exists = useTopModelExistByNameWithFallbacksAndNameTransform(rampName, rampFallbackNames);
    return exists;
};
export var useIntroContainerRampNames = function () {
    var headingName = useHeadingSectionName();
    var headingDepth = useHeadingSectiondepth();
    var headingIndex = useHeadingSectionIndex();
    var result = [
        "IntroContainer-".concat(headingName),
        [
            "IntroContainer-".concat(headingDepth, "-").concat(headingIndex),
            "IntroContainer-".concat(headingDepth),
            'IntroContainer',
        ],
    ];
    return result;
};
