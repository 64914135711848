import { useFilterByTAccId } from '../../utils';
import { PersonAccounts } from '../../type';
import { usePersonAccountTransformApi } from '../api';

const transformFunction = (personAccounts: PersonAccounts | undefined) => (
  personAccounts && personAccounts['T_ACC.ROOT']
    ? `${personAccounts['T_ACC.ROOT']}`
    : ''
);

export const usePersonAccountsTAccRoot = () => {
  const tAccFilterById = useFilterByTAccId();
  const result = usePersonAccountTransformApi({
    filterPredicate: tAccFilterById,
    transformFunction,
  });
  return result;
};
