import * as React from 'react';
import { PersonsDevicesBrowser, PersonsDevicesOS } from '.';

export const PersonsDevicesBrowserAndOs = () => (
  <>
    <PersonsDevicesBrowser />
    {' '}
    /
    {' '}
    <PersonsDevicesOS />
  </>
);
