import * as React from 'react';
import { GetAccountsPositionsParams } from '@goldwasserexchange/actor/rest-services';
import { useAccountPositionsDefaultParams } from '../hooks';
import { accountsPositionsParamsContext } from '../context';

export const AccountsPositionsParamsProvider = (props: React.PropsWithChildren<{
  params: Partial<GetAccountsPositionsParams>,
}>) => {
  const {
    params,
    children,
  } = props;
  const value = useAccountPositionsDefaultParams(params);
  return (
    <accountsPositionsParamsContext.Provider
      value={value}
    >
      {children}
    </accountsPositionsParamsContext.Provider>
  );
};
