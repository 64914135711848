import { useFilterByTAccId } from '../../utils';
import { usePersonAccountFieldApi } from '../api';

export const usePersonAccountsSIndiceEndDate = () => {
  const filterPredicate = useFilterByTAccId();
  const result = usePersonAccountFieldApi({
    filterPredicate,
    field: 'S_INDICE.END_DATE',
  });
  return result;
};
