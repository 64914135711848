import { BondFrequency } from '@goldwasserexchange/common/lib/bond';
import { msg } from '@lingui/macro';
import { MessageDescriptor } from '@lingui/core';
import { BondResponse } from '../../type';
import { transformSAccJrsFreqToBondFrequency } from '../../utils/transformSAccJrsFreqToBondFrequency';
import { useBondsBondFieldTransformFnApi } from '../api';

export const useBondsBondSAccJrsFreqBondFrequency = () => {
  const response = useBondsBondFieldTransformFnApi({
    field: 'S_ACC_JRS.FREQ',
    transformFn: transformSAccJrsFreqToBondFrequency,
  });
  return response;
};

const sAccJrsFreqStrings: Record<BondFrequency, MessageDescriptor> = {
  [BondFrequency.ANNUAL]: msg`Annuelle`,
  [BondFrequency.SEMI_ANNUAL]: msg`Semestrielle`,
  [BondFrequency.QUARTERLY]: msg`Trimestrielle`,
};

const transformSAccJrsFreqToString = (sAccJrsFreq: BondResponse['S_ACC_JRS.FREQ'] | undefined) => {
  const frequency = transformSAccJrsFreqToBondFrequency(sAccJrsFreq);
  if (frequency == null) {
    return undefined;
  }
  const messageDescriptor = sAccJrsFreqStrings[frequency];
  return messageDescriptor;
};

export const useBondsBondSAccJrsFreqText = () => {
  const response = useBondsBondFieldTransformFnApi({
    field: 'S_ACC_JRS.FREQ',
    transformFn: transformSAccJrsFreqToString,
  });
  return response;
};
