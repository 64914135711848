import ouiDom from '@goldwasserexchange/oui-dom';
import { Trans } from '@lingui/macro';
import * as React from 'react';

export const UserInfo = () => (
  <ouiDom.P.P
    paddingTop="none"
  >
    <Trans>
      Pour continuer, veuillez confirmer votre identité
    </Trans>
  </ouiDom.P.P>
);
