import * as React from 'react';
import { usePrimaryDimensionDirection } from '../primaryDimension/direction/hooks';
import { useCurrentRenderDirection } from './current/hooks';
import { dimensionCurrentContextByDirectionMap, dimensionCurrentIndexContextByDirectionMap } from './data';
export var CurrrentDimensionContextByDirection = function (props) {
    var renderDirection = props.renderDirection, currentDimensionKey = props.currentDimensionKey, children = props.children;
    var currentDirection = usePrimaryDimensionDirection();
    var context = dimensionCurrentContextByDirectionMap[currentDirection][renderDirection];
    return (React.createElement(context.Provider, { value: currentDimensionKey }, children));
};
export var CurrentDimensionContextByDirectionAndRenderDirection = function (props) {
    var currentDimensionKey = props.currentDimensionKey, children = props.children;
    var currentRenderDirection = useCurrentRenderDirection();
    return (React.createElement(CurrrentDimensionContextByDirection, { renderDirection: currentRenderDirection, currentDimensionKey: currentDimensionKey }, children));
};
export var CurrrentDimensionIndexContextByDirection = function (props) {
    var renderDirection = props.renderDirection, index = props.index, children = props.children;
    var currentDirection = usePrimaryDimensionDirection();
    var context = dimensionCurrentIndexContextByDirectionMap[currentDirection][renderDirection];
    return (React.createElement(context.Provider, { value: index }, children));
};
export var CurrentDimensionIndexContextByDirectionAndRenderDirection = function (props) {
    var index = props.index, children = props.children;
    var currentRenderDirection = useCurrentRenderDirection();
    return (React.createElement(CurrrentDimensionIndexContextByDirection, { renderDirection: currentRenderDirection, index: index }, children));
};
