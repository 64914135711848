import * as React from 'react';
import { RampWithNameTransform } from '@goldwasserexchange/react-topmodel';
import ouiBase from '@goldwasserexchange/oui';
import ouiStyle from '@goldwasserexchange/oui-style';
import { UseGetFnProps } from '../../shared';
import { SearchKeysProps } from '../../shared/components/list/context/searchKeys/context';

export function DefaultSelectInputBase(
  props: {
    fieldPath: string,
    dependenciesFieldPaths?: string[],
    type: Extract<ouiBase.Input.InputTypes, 'radio'>,
    elementLines?: ouiStyle.InStyle['lines'],
    useAutoSelect?: (autoSelect: boolean) => void,
    autoSelect?: boolean,
  } & SearchKeysProps & UseGetFnProps & ouiBase.List.ListProps & ouiBase.List.ListDataFilterAndSortProviderProps,
) {
  const {
    fieldPath,
    dependenciesFieldPaths = [],
    type,
    list,
    length,
    elementLines,
    searchKeys,
    useFilterFn,
    useSortFn,
    useGetFn,
    useAutoSelect,
    autoSelect,
  } = props;
  return (
    <RampWithNameTransform<
    {
      type: Extract<ouiBase.Input.InputTypes, 'radio'>,
      fieldPath: string,
      dependenciesFieldPaths: string[],
      elementLines?: ouiStyle.InStyle['lines'],
      useAutoSelect?: (autoSelect: boolean) => void,
      autoSelect?: boolean,
    } & SearchKeysProps & UseGetFnProps & ouiBase.List.ListProps & ouiBase.List.ListDataFilterAndSortProviderProps
    >
      rampName="InputContextProvider"
      type={type}
      fieldPath={fieldPath}
      dependenciesFieldPaths={dependenciesFieldPaths}
      list={list}
      length={length}
      useGetFn={useGetFn}
      elementLines={elementLines}
      searchKeys={searchKeys}
      useFilterFn={useFilterFn}
      useSortFn={useSortFn}
      useAutoSelect={useAutoSelect}
      autoSelect={autoSelect}
    >
      <RampWithNameTransform rampName="InputContainer">
        <RampWithNameTransform rampName="InputContent" />
      </RampWithNameTransform>
    </RampWithNameTransform>
  );
}
