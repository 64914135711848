import * as React from 'react';
import { validateFieldPathContext } from './context';

export const ValidateFieldPathProvider = (props: React.PropsWithChildren<{
  validateFieldPath?: boolean,
}>) => {
  const {
    validateFieldPath = true,
    children,
  } = props;
  return (
    <validateFieldPathContext.Provider value={validateFieldPath}>
      {children}
    </validateFieldPathContext.Provider>
  );
};
