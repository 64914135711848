import { useFocus } from './useFocus';

export const useHasFocus = () => {
  const [hasFocus] = useFocus();
  return hasFocus;
};

export type FocusVariantsNames = 'focus' | 'noFocus';

export function useHasFocusVariant(): FocusVariantsNames {
  const hasFocus = useHasFocus();
  return hasFocus ? 'focus' : 'noFocus';
}
