import * as React from 'react';
import ouiDom from '@goldwasserexchange/oui-dom';
import { BondsBondFullName } from '../../../../../../actor/api/Services/bonds';
import { useUnlockRedactedOnClick } from '../../../../../../actor/components/display/loader/hooks';

export const SanitySecurityPriceGraphTitle = (props: {
  alternateTitle?: string | null,
}) => {
  const {
    alternateTitle,
  } = props;
  const onClick = useUnlockRedactedOnClick();
  return (
    <ouiDom.ElementTitle.ElementTitle
      onClick={onClick}
    >
      {alternateTitle == null
        ? <BondsBondFullName />
        : alternateTitle}
    </ouiDom.ElementTitle.ElementTitle>
  );
};
