import React, { createContext } from 'react';
export const ElementIndexContext = createContext<null | number>(null);

export const ElementIndexContextProvider = (props: React.PropsWithChildren<{ index: number }>) => {
  const {
    index,
    children,
  } = props;
  return (
    <ElementIndexContext.Provider value={index}>
      {children}
    </ElementIndexContext.Provider>
  );
};

ElementIndexContextProvider.displayName = 'ElementIndexContextProvider';
