import * as React from 'react';
import {
  PortableText,
} from '@portabletext/react';
import { useFootnotes } from '../../context';
import { FootnoteElement, FootNotesContainer } from './components';
import { SanityLink } from '../../../link';
import { SanityInternalLink } from '../../../internalLink';

const components = {
  marks: {
    link: (props) => (
      <SanityLink
        value={props.value}
      >
        {props.children}
      </SanityLink>
    ),
    internalLink: (props) => (
      <SanityInternalLink
        value={props.value}
      >
        {props.children}
      </SanityInternalLink>
    ),
  },
};

export const SanityFootnotes = () => {
  const notes = useFootnotes();
  if (notes == null) {
    return null;
  }
  return (
    <FootNotesContainer>
      {notes.map((note) => {
        const {
          _key,
          text,
        } = note;
        return (
          <FootnoteElement
            key={_key}
            id={_key}
          >
            <PortableText value={text} components={components} />
          </FootnoteElement>
        );
      })}
    </FootNotesContainer>
  );
};
