import { PersonType } from '../../../../../types';
var onboardingPath = 'onboarding';
var personTypePath = 'personType';
var makeLogic = function (truthy, falsy) { return ({
    name: "".concat(onboardingPath, ".").concat(personTypePath),
    is: PersonType.MORAL,
    alternatives: {
        truthy: {
            result: falsy,
        },
        falsy: {
            result: truthy,
        },
    },
}); };
export var showLogic = makeLogic('visible', 'invisible');
export var showLogicBoolean = makeLogic(true, false);
