/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const searchPlaceResultElementContainerStyle: ouiStyle.InStyle = {
  padding: 'simple',
  borderColor: ouiStyle.Constants.Colors.primary,
  borderHorizontalStyle: 'solid',
  borderHorizontalWidth: 'simple',
  borderBottomStyle: 'solid',
  borderBottomWidth: 'simple',
};

export const SearchPlaceResultElementContainer = (props: React.PropsWithChildren<{
  onClick: NonNullable<React.DOMAttributes<HTMLLIElement>['onClick']>,
  focus: boolean,
}>) => {
  const {
    focus,
    onClick,
    children,
  } = props;
  const searchPlaceResultElementContainerCss = React.useMemo(
    () => ouiStyle.makeCss({
      ...searchPlaceResultElementContainerStyle,
      borderColor: !focus ? ouiStyle.Constants.Colors.primary : ouiStyle.Constants.Colors.accent,
    }),
    [focus],
  );
  return (
    <li
      css={searchPlaceResultElementContainerCss}
      onClick={onClick}
    >
      {children}
    </li>
  );
};
