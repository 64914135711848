import { makeTAddTypeFilterLogic } from '../../TYPE/logic';
import { TAddType, CTitMoral } from '../../../../../types';
var idCTitMoralPath = 'ID_C_TIT_MORAL';
export var showLogic = makeTAddTypeFilterLogic({
    is: TAddType.MANDATARY,
    truthy: {
        name: "<parentName>.".concat(idCTitMoralPath),
        is: CTitMoral.AUTRE,
        alternatives: {
            truthy: {
                result: 'visible',
            },
            falsy: {
                result: 'invisible',
            },
        },
    },
});
