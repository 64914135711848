import { RampWithNameTransform } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import { CurrentDimensionContextByDirectionAndRenderDirection, CurrentDimensionIndexContextByDirectionAndRenderDirection, useDimensionListByCurrentRenderDirection, } from '../../../../utils/dimensions/directionDimension';
export var TableMapper = function (props) {
    var rampName = props.rampName, rampFallbacks = props.rampFallbacks, log = props.log;
    var dimensionList = useDimensionListByCurrentRenderDirection();
    return (React.createElement(React.Fragment, null, dimensionList.map(function (currentDimensionKey, index) { return (React.createElement(CurrentDimensionIndexContextByDirectionAndRenderDirection, { key: currentDimensionKey, index: index },
        React.createElement(CurrentDimensionContextByDirectionAndRenderDirection, { currentDimensionKey: currentDimensionKey },
            React.createElement(RampWithNameTransform, { rampName: rampName, rampFallbacks: rampFallbacks, log: log })))); })));
};
