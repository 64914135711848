import { mapSAccId, useFilterByCCurrencyCode } from '../../../../shared';
import { useSameIssuerCurrency } from '../../components';
import { BondSameIssuerResponse } from '../../type';
import { useBondsSameIssuerTransformApi } from '../api';

const transformToSAccId = (securityBondSameIssuer?: BondSameIssuerResponse[]) => {
  if (!securityBondSameIssuer) {
    return undefined;
  }
  return mapSAccId(securityBondSameIssuer);
};

export const useSecuritiesBondsSameIssuerSAccIdList = () => {
  const response = useBondsSameIssuerTransformApi({
    transformFunction: transformToSAccId,
  });
  return response;
};

export const useBondsSameIssuerSAccIdListByCurrency = () => {
  const currencyCode = useSameIssuerCurrency();
  const filterPredicate = useFilterByCCurrencyCode(currencyCode);
  const response = useBondsSameIssuerTransformApi({
    filterPredicate,
    transformFunction: transformToSAccId,
  });
  return response;
};

const transformToSAccIdLength = (securityBondSameIssuer?: BondSameIssuerResponse[]) => {
  if (!securityBondSameIssuer) {
    return 0;
  }
  return transformToSAccId(securityBondSameIssuer)?.length ?? 0;
};

export const useBondsSameIssuerSAccIdListByCurrencyLength = () => {
  const currencyCode = useSameIssuerCurrency();
  const filterPredicate = useFilterByCCurrencyCode(currencyCode);
  const response = useBondsSameIssuerTransformApi({
    filterPredicate,
    transformFunction: transformToSAccIdLength,
  });
  return response;
};
