import { useCallback, useContext } from 'react';
import * as events from '../events';
import { AppBarMachineController, machineContext } from './context';

export const useMachineContext = (): AppBarMachineController => {
  const service = useContext(machineContext);
  if (!service) {
    throw new Error('uou can\'t use a machine contexte oustide of a machine context provider');
  }
  return service;
};

export const useMachineSendContext = () => {
  const service = useMachineContext();
  return service.send;
};

export const useOpen = () => {
  const send = useMachineSendContext();
  const fn = useCallback(() => {
    send(events.openEvent.eventBuilder());
  }, [send]);
  return fn;
};

export const useClose = () => {
  const send = useMachineSendContext();
  const fn = useCallback(() => {
    send(events.closeEvent.eventBuilder());
  }, [send]);
  return fn;
};

export const useLinkClick = () => {
  const send = useMachineSendContext();
  const fn = useCallback(() => {
    send(events.linkClickEvent.eventBuilder());
  }, [send]);
  return fn;
};
