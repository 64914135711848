import { SimulationBuyOrSellCostData, SimulationBuyAndSellCostData } from '../type';
import { computeOrGetGrossAmount, GetGrossAmountProps } from './computeGrossAmount';
import { yearlyPct } from './yearlyPct';

const tobs: Record<SimulationBuyOrSellCostData['type'] | SimulationBuyAndSellCostData['type'], number> = {
  stocks: 0.0035,
  bonds: 0.0012,
  capitalisationFunds: 0.0132,
  distibutionFunds: 0,
  trackers: 0.0012,
};

export type GetTOBRateProps = {
  type: SimulationBuyOrSellCostData['type'] | SimulationBuyAndSellCostData['type'],
  tRelMcCpsFisc?: number,
}

export const getTOBRate = (
  props: GetTOBRateProps,
) => {
  const {
    tRelMcCpsFisc,
    type,
  } = props;
  const isOrdinaire = tRelMcCpsFisc === 2630;
  return isOrdinaire ? tobs[type] : 0;
};

export type ComputeTOBProps = SimulationBuyOrSellCostData | (GetGrossAmountProps & {
  type: SimulationBuyAndSellCostData['type'],
})

export const computeTOB = (props: ComputeTOBProps) => (tRelMcCpsFisc?: number) => {
  const grossAmountInEur = computeOrGetGrossAmount(props);
  const {
    type,
  } = props;
  const TOBRate = getTOBRate({ type, tRelMcCpsFisc });
  return grossAmountInEur * TOBRate;
};

export type ComputeBuyTOBAmountProps = {
  type: SimulationBuyAndSellCostData['type'],
  counterValue: SimulationBuyAndSellCostData['counterValue'],
}

export const computeBuyTOBAmount = (props: ComputeBuyTOBAmountProps) => (tRelMcCpsFisc?: number) => {
  const {
    type,
    counterValue,
  } = props;
  if (type === 'capitalisationFunds') {
    return 0;
  }
  const tob = computeTOB({
    grossAmountInEur: counterValue,
    type,
  })(tRelMcCpsFisc);
  return tob;
};

export type ComputeBuyAndSellTOBAmountProps = {
  type: SimulationBuyAndSellCostData['type'],
  counterValue: SimulationBuyAndSellCostData['counterValue'],
  refund: SimulationBuyAndSellCostData['refund'],
}

export const computeBuyAndSellTOBAmount = (props: ComputeBuyAndSellTOBAmountProps) => (
  tRelMcCpsFisc?: number,
) => {
  const {
    type,
    counterValue,
    refund,
  } = props;
  const tob = computeTOB({
    grossAmountInEur: counterValue,
    type,
  })(tRelMcCpsFisc);
  if ((type === 'bonds' && refund === true) || type === 'capitalisationFunds') {
    return tob;
  }
  return 2 * tob;
};

export type ComputeBuyAndSellTOBPctProps = {
  durationYears: SimulationBuyAndSellCostData['durationYears'],
} & ComputeBuyAndSellTOBAmountProps

export const computeBuyAndSellTOBPct = (props: ComputeBuyAndSellTOBPctProps) => (tRelMcCpsFisc: number | undefined) => {
  const {
    type,
    counterValue,
    durationYears,
    refund,
  } = props;
  const tobBuyAndSell = computeBuyAndSellTOBAmount({
    counterValue,
    type,
    refund,
  })(tRelMcCpsFisc);
  return yearlyPct({
    counterValue,
    durationYears,
  })(tobBuyAndSell);
};
