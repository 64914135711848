import * as React from 'react';
import { TopModelNameTransformProvider } from '@goldwasserexchange/react-topmodel';
import ouiBase from '@goldwasserexchange/oui';
import {
  TextInputStructure,
  textInputPrefixer,
  DefaultContextProviderContextsTopModelsProvider,
  DefaultTextInputTopModelsProvider,
  DefaultInputErrorMessageTopModelsProvider,
} from '../../../../OUI/Inputs';
import { textInputDomDefaultTopModelsObject } from './topmodels';
import {
  DefaultIconContentDomTopModelsProvider,
  DefaultFooterDomTopModelsProvider,
} from '../shared';
import { LocalRifmArgs } from '../../../../OUI/Inputs/components/text/context/rifm/context';

export function TextInput(
  props: React.PropsWithChildren<{
    fieldPath: string,
    dependenciesFieldPaths?: string[],
    type?: Exclude<ouiBase.Input.InputTypes, 'radio' | 'checkbox'>,
    autocomplete?: boolean,
  } & LocalRifmArgs>,
) {
  const {
    fieldPath,
    dependenciesFieldPaths,
    format,
    replace,
    append,
    mask,
    accept,
    type,
    autocomplete,
    children,
  } = props;
  return (
    <TopModelNameTransformProvider transformFn={textInputPrefixer}>
      <DefaultContextProviderContextsTopModelsProvider>
        <DefaultIconContentDomTopModelsProvider>
          <DefaultFooterDomTopModelsProvider>
            <DefaultTextInputTopModelsProvider>
              <DefaultInputErrorMessageTopModelsProvider>
                <TextInputStructure
                  type={type}
                  fieldPath={fieldPath}
                  defaultTopModels={textInputDomDefaultTopModelsObject}
                  dependenciesFieldPaths={dependenciesFieldPaths}
                  format={format}
                  replace={replace}
                  append={append}
                  mask={mask}
                  accept={accept}
                  autocomplete={autocomplete}
                >
                  {children}
                </TextInputStructure>
              </DefaultInputErrorMessageTopModelsProvider>
            </DefaultTextInputTopModelsProvider>
          </DefaultFooterDomTopModelsProvider>
        </DefaultIconContentDomTopModelsProvider>
      </DefaultContextProviderContextsTopModelsProvider>
    </TopModelNameTransformProvider>
  );
}

TextInput.isOuiInput = true;
