import { VirtualElement } from '@popperjs/core';
import * as React from 'react';
import { popperReferenceContext } from './context';

export const PopperReferenceContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  const popperRef = React.useRef<Element | VirtualElement | null>(null);
  return (
    <popperReferenceContext.Provider value={popperRef}>
      {children}
    </popperReferenceContext.Provider>
  );
};
