/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { animated } from 'react-spring';
import { useZIndex } from '../../../../OUI/Form';
import { useOuiStyleMediaContextByIndex } from '../../../MediaContext';
import * as animationController from '../../animation';

const navBarContainerStyle: ouiStyle.InStyle = {
  display: ouiStyle.Constants.DisplayProp.flex,
  position: 'absolute',
  right: 0,
  backgroundColor: ouiStyle.Constants.Colors.logoBlue,
  overflowY: 'visible',
};

const useNavBarContainerCss = () => {
  const zIndex = useZIndex() - 1;
  const css = React.useMemo(() => ouiStyle.makeCss({
    ...navBarContainerStyle,
    zIndex,
  }), [zIndex]);
  return css;
};

export const NavBarContainer = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  const mqDown = animationController.useAnimationmqDownSpringValue();
  const closed = animationController.useAnimationClosedSpringValue();
  const isUp = useOuiStyleMediaContextByIndex(5);
  const style = {
    transform: !isUp ? closed.to((v) => `translateX(${v * 100}%)`) : 'translateX(0%)',
    flexDirection: mqDown.to((v) => {
      if (v <= 0.5) {
        return 'column';
      }
      return 'row';
    }),
    width: mqDown.to((v) => {
      if (v <= 0.5) {
        return 'auto';
      }
      return '100%';
    }),
    justifyContent: mqDown.to((v) => {
      if (v <= 0.5) {
        return 'flex-start';
      }
      return 'flex-end';
    }),
    height: mqDown.to((v) => {
      if (v <= 0.5) {
        return '100vh';
      }
      return `${3.75}rem`;
    }),
  };
  const css = useNavBarContainerCss();
  return <animated.div css={css} style={style}>{children}</animated.div>;
};
