import { makeName } from '../../../../../makeName';
var fiscalResidencySameAsResidencyPath = 'fiscalResidencySameAsResidency';
var addressPath = 'address';
var fiscalAddressPath = 'fiscalAddress';
export var makePathLogic = function (column) { return ({
    name: makeName('<parentName>', fiscalResidencySameAsResidencyPath),
    is: false,
    alternatives: {
        truthy: {
            result: "".concat(fiscalAddressPath, ".").concat(column),
        },
        falsy: {
            result: "".concat(addressPath, ".").concat(column),
        },
    },
}); };
export var pathLogic = {
    name: makeName('<parentName>', fiscalResidencySameAsResidencyPath),
    is: false,
    alternatives: {
        truthy: {
            result: "".concat(fiscalAddressPath),
        },
        falsy: {
            result: "".concat(addressPath),
        },
    },
};
