/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { Circle } from 'react-feather';
import { useMemo } from 'react';

const radioButtonStyle: ouiStyle.InStyle = {
  borderRadius: 'full',
  overflow: 'hidden',
  transform: 'translateY(3px)',
  lines: 1,
  widthLines: 1,
  borderColor: ouiStyle.Constants.Colors.primary,
  backgroundColor: ouiStyle.Constants.Colors.inverted,
  borderWidth: 'simple',
  borderStyle: 'solid',
  padding: 'none',
  margin: 'none',
};

const useRadioButtonCss = (disabled: boolean) => {
  const css = useMemo(() => {
    const baseCss = {
      ...ouiStyle.makeCss(radioButtonStyle),
      '&:hover': {
        backgroundColor: ouiStyle.Constants.Colors.inverted,
      },
      ':active': {
        backgroundColor: ouiStyle.Constants.Colors.inverted,
      },
    };
    if (disabled) {
      return {
        ...baseCss,
        backgroundColor: ouiStyle.Constants.Colors.disabled,
        '&:hover': {
          backgroundColor: ouiStyle.Constants.Colors.disabled,
        },
        ':active': {
          backgroundColor: ouiStyle.Constants.Colors.disabled,
        },
      };
    }
    return baseCss;
  }, [disabled]);
  return css;
};

export const RadioButton = (props: {
  disabled?: boolean,
  isSelected?: boolean,
  setSelected: () => void,
}) => {
  const {
    disabled = false,
    isSelected = false,
    setSelected,
  } = props;
  const radioButtonCss = useRadioButtonCss(disabled);
  return (
    <button
      type="button"
      css={radioButtonCss}
      onClick={!disabled ? setSelected : () => null}
    >
      {isSelected && (
        <Circle
          size={`${ouiStyle.Constants.sizes.basePlusFontSize}rem`}
          color={ouiStyle.Constants.Colors.primary}
          fill={ouiStyle.Constants.Colors.primary}
          style={{
            paddingTop: '1px',
            paddingLeft: '1px',
            verticalAlign: 'baseline',
          }}
        />
      )}
    </button>
  );
};
