import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Dot,
  Tooltip,
  AreaChart,
  Area,
  ReferenceLine,
} from 'recharts';
import {
  addMonths,
  differenceInMonths,
  format,
  parse,
  startOfMonth,
} from 'date-fns';
import { times } from 'ramda';
import { SQuoteChartLabel } from '../label';
import { Domain, SQuoteChartDataElement } from '../type';
import { getDomain, makeDomainWithMinMax } from '../utils/getDomain';
import { useGetDateFnsLocale } from '../../../../../../../Components/getDateLocale';
import { useOuiStyleMediaContextByIndex } from '../../../../../../../Components/MediaContext';
import { useNumberFormatter } from '../../../../../../../Components/NumberDisplay';
import { SQuoteChartContainer } from './container';

const toPrecisionFloat = (num: number, precision?: number) => parseFloat(num.toPrecision(precision));

export const SQuoteAreaChart = (props: {
  data: SQuoteChartDataElement[],
  isLoading: boolean,
  height?: number,
  hideXAxis?: boolean,
  xAxisDomain?: Domain,
  xAxisLine?: boolean,
  xAxisOrientation?: 'bottom' | 'top',
  xTickSize?: number,
  xTickLine?: boolean,
  xMinTickGap?: number,
  xInterval?: number,
  hideYAxis?: boolean,
  yAxisDomain?: Domain,
  yAxisLine?: boolean,
  yAxisOrientation?: 'left' | 'right',
  yTickSize?: number,
  yTickLine?: boolean,
  yMinTickGap?: number,
  yInterval?: number,
}) => {
  const upSm = useOuiStyleMediaContextByIndex(2);
  const upMd = useOuiStyleMediaContextByIndex(3);
  const upLd = useOuiStyleMediaContextByIndex(4);
  const {
    data,
    isLoading,
    height = 200,
    hideXAxis = false,
    xAxisLine = false,
    xAxisDomain = ['dataMin', 'dataMax'],
    xAxisOrientation = 'bottom',
    xTickSize,
    xTickLine,
    xMinTickGap = 0,
    xInterval = 0,
    hideYAxis = !upSm,
    yAxisLine = false,
    yAxisDomain,
    yAxisOrientation = 'right',
    yTickSize,
    yTickLine = false,
    yMinTickGap = 0,
    yInterval = 0,
  } = props;
  const realDomain = React.useMemo(() => getDomain(data, [0, 0], ['dataMin', 'dataMax']), [data]);
  const domainStep = toPrecisionFloat((realDomain.y[1] - realDomain.y[0]) / (height / 40), 1);
  const yAxisDomainWithDefault = React.useMemo(() => {
    if (!yAxisDomain) {
      if (domainStep < 0.05) {
        return makeDomainWithMinMax(0.01);
      }
      if (domainStep < 0.1) {
        return makeDomainWithMinMax(0.05);
      }
      if (domainStep < 0.5) {
        return makeDomainWithMinMax(0.1);
      }
      if (domainStep < 1) {
        return makeDomainWithMinMax(0.5);
      }
      if (domainStep < 5) {
        return makeDomainWithMinMax(1);
      }
      return makeDomainWithMinMax(5);
    }
    return yAxisDomain;
  }, [yAxisDomain, domainStep]);
  const domain = React.useMemo(() => getDomain(data, xAxisDomain, yAxisDomainWithDefault), [data]);
  const {
    x: [domainMinX, domainMaxX],
    y: [domainMinY, domainMaxY],
  } = domain;
  const yDomainWidth = domainMaxY - domainMinY;
  const yStep = toPrecisionFloat(yDomainWidth / (height / 40), 1);
  const dateStart = format(new Date(domainMinX), 'yyyyMMdd');
  const dateEnd = format(new Date(domainMaxX), 'yyyyMMdd');
  const numberFormatter = useNumberFormatter({
    format: {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
  });
  const xTicks = React.useMemo(() => {
    if (dateStart && dateEnd) {
      const startDate = parse(dateStart, 'yyyyMMdd', new Date());
      const endDate = parse(dateEnd, 'yyyyMMdd', new Date());
      const difference = differenceInMonths(endDate, startDate);
      return difference !== 0
        ? times((i) => startOfMonth(addMonths(startDate, i + 1)).getTime(), difference)
        : [];
    }
    return [];
  }, [data, dateStart, dateEnd]);
  const yTicks = React.useMemo(() => {
    if (yDomainWidth === 0) {
      return [];
    }
    return times((i) => domainMinY + (i * yStep), Math.ceil((yDomainWidth / yStep)));
  }, [domainMinY, domainMaxY, height, yDomainWidth]);
  const locale = useGetDateFnsLocale();
  return (
    <>
      <svg style={{ height: 0 }}>
        <linearGradient
          id="area"
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0%" stopColor={ouiStyle.Constants.Colors.primary} />
          <stop offset="100%" stopColor={ouiStyle.Constants.Colors.inverted} />
        </linearGradient>
      </svg>

      <ResponsiveContainer
        width="100%"
        height={(Math.ceil(yDomainWidth / yStep)) * 40}
      >
        <AreaChart
          data={data}
          margin={{
            right: upSm ? 0 : 15,
            left: upSm ? 60 : 15,
            bottom: 0,
            top: 26,
          }}
        >
          {xTicks.map((x) => <ReferenceLine key={`reference-xTick-${x}`} x={x} strokeDasharray="3 3" strokeWidth={2} />)}
          {yTicks.map((y) => <ReferenceLine key={`reference-y-${y}`} y={y} strokeDasharray="3 3" />)}
          <XAxis
            dataKey="x"
            hide={hideXAxis}
            type="number"
            scale="time"
            domain={[domainMinX, domainMaxX]}
            axisLine={xAxisLine}
            tickFormatter={(value, index) => {
              if (xTicks.length === 0) {
                return '';
              }
              if (xTicks.length <= 4 || index % Math.ceil(xTicks.length / 4) === 0) {
                const tickDate = new Date(value);
                return format(tickDate, ((upSm && !upMd) || upLd) ? 'MMMM yyyy' : 'MM/yy', { locale });
              }
              return '';
            }}
            orientation={xAxisOrientation}
            tickSize={xTickSize}
            tickLine={xTicks.length > 0 ? xTickLine : false}
            ticks={xTicks}
            minTickGap={xMinTickGap}
            interval={xInterval}
          />
          <YAxis
            dataKey="y"
            type="number"
            hide={hideYAxis}
            domain={[domainMinY, domainMaxY]}
            tickFormatter={(value) => numberFormatter(value)}
            axisLine={yAxisLine}
            orientation={yAxisOrientation}
            tickSize={yTickSize}
            tickLine={yTickLine}
            ticks={yTicks}
            minTickGap={yMinTickGap}
            interval={yInterval}
          />
          <Area
            type="monotoneX"
            dataKey="y"
            stroke={ouiStyle.Constants.Colors.primary}
            strokeWidth={2}
            dot={false}
            fill="url(#area)"
            fillOpacity={0.5}
            // eslint-disable-next-line react/no-unstable-nested-components
            activeDot={(p) => <Dot {...p} stroke={p.fill} r={2} />}
          />

          {!isLoading
            ? (
              <Tooltip
                content={(
                  <SQuoteChartLabel />
                )}
                offset={50}
              />
            )
            : null}
        </AreaChart>
      </ResponsiveContainer>
    </>
  );
};

export const SQuoteAreaChartWithContainer = (props: {
  data: SQuoteChartDataElement[],
  isLoading: boolean,
  height?: number,
  hasRedacted?: boolean,
  hasRedactedIsLoading: boolean,
}) => {
  const {
    data,
    isLoading,
    height,
    hasRedacted,
    hasRedactedIsLoading,
  } = props;
  return (
    <SQuoteChartContainer
      hasRedacted={hasRedacted}
      hasRedactedIsLoading={hasRedactedIsLoading}
    >
      <SQuoteAreaChart
        data={data}
        isLoading={isLoading}
        height={height}
      />
    </SQuoteChartContainer>
  );
};
