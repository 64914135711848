import ouiBase from '@goldwasserexchange/oui';
import { useContext } from 'react';
import { useHookstate } from '@hookstate/core';
import { touchedContext } from './context';

export function useUnhookedTouchedContext() {
  const touched = useContext(touchedContext);
  return touched;
}

export function useTouchedContext() {
  const touched = useUnhookedTouchedContext();
  return useHookstate(touched);
}

export function useFieldTouchedByFieldPath(fieldPath: string) {
  const touched = useTouchedContext();
  const key = touched.keys.findIndex((touchedKey) => touchedKey === fieldPath);
  if (key === -1) {
    touched.nested(fieldPath).set(false);
  }
  return useHookstate(touched.nested(fieldPath));
}

export function useFieldTouchedByFieldPaths(fieldPaths: string[]) {
  const frozenFieldPaths = ouiBase.utils.freeze.useFreezeArrayOnLength(fieldPaths, 'fieldPaths');
  const fieldsTouched = frozenFieldPaths.map((frozenFieldPath) => useFieldTouchedByFieldPath(frozenFieldPath)); // eslint-disable-line react-hooks/rules-of-hooks
  return fieldsTouched;
}
