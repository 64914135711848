import { arDocIdKeysTransform } from '../../utils';
import { AccountDocument } from '../../type';
import { useAccountDocumentsTransformApi } from '../api';

export const useAccountDocumentsList = (props: {
  filterPredicate?: ((data: AccountDocument) => boolean),
  sortPredicate?: (prev: AccountDocument, next: AccountDocument) => number,
}) => {
  const {
    filterPredicate,
    sortPredicate,
  } = props;
  const result = useAccountDocumentsTransformApi({
    transformFunction: arDocIdKeysTransform,
    filterPredicate,
    sortPredicate,
  });
  return result;
};
