import * as events from '../../../events';
import { makeSpringService } from '../utils';

export type AppBarLogoExpandingServiceMeta = {
  src: {
    type: 'LogoExpandingService',
  },
};

export const name = 'logoExpanding';

export const makeService = makeSpringService(name, 'logoExpanded', 1, false, events.logoExpandingFinished.eventBuilder);

export default makeService;
