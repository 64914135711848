import * as React from 'react';
import { StateFilter } from '../../../../../../Machine';
import { LazyDurabilityQuestions } from '../../../questionnaires/DurabilityQuestionnaire';
import { LazyGestionDurabilityResults } from './components';

export const GestionDurability = () => (
  <>
    <StateFilter is=".questions">
      <LazyDurabilityQuestions />
    </StateFilter>
    <StateFilter is=".results">
      <LazyGestionDurabilityResults />
    </StateFilter>
  </>
);
