/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';

const style: ouiStyle.InStyle = {
  display: ouiStyle.Constants.DisplayProp.listItem,
  color: ouiStyle.Constants.Colors.greyDarkest,
  listStyleType: ouiStyle.Constants.ListStyleTypeProps.ordered,
  paddingLeft: 2,
};

const css = {
  ...ouiStyle.makeCss(style),
  '::marker': {
    color: ouiStyle.Constants.Colors.primary,
    textDecoration: 'none',
    fontWeight: ouiStyle.Constants.FontWeightProp.bold,
  },
};

export const FootnoteElement = (props: React.PropsWithChildren<{
  id: string,
}>) => {
  const {
    id,
    children,
  } = props;
  return (
    <li
      id={id}
      css={css}
    >
      {children}
    </li>
  );
};
