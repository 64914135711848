/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const buttonContainerStyle: ouiStyle.InStyle = {
  padding: 'simple',
  textAlign: 'center',
};

const buttonContainerCss = ouiStyle.makeCss(buttonContainerStyle);

export const ButtonContainer = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  return (
    <div
      css={buttonContainerCss}
    >
      {children}
    </div>
  );
};
