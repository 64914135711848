import { initialBondSortContext } from '../initial';
import { BondSortDirection } from './type';
import { isBondSortDirectionValid } from './valid';

export const getBondSortDirection = (sortDirection: string | (string | null)[] | null): BondSortDirection => {
  if (!sortDirection || Array.isArray(sortDirection)) {
    return initialBondSortContext.sortDirection;
  }
  if (!isBondSortDirectionValid(sortDirection)) {
    return initialBondSortContext.sortDirection;
  }
  return sortDirection;
};
