const parseDigits = (str: string) => (str.match(/\d+/g) || []).join('');

const formatDate = (str: string) => {
  const digits = parseDigits(str);
  const chars = digits.split(''); // eslint-disable-line unicorn/prefer-spread
  return chars
    .reduce(
      (r, v, index) => (index === 2 || index === 4 ? `${r}/${v}` : `${r}${v}`),
      '',
    )
    .substr(0, 10);
};

const formatDateWithAppend = (str: string) => {
  const res = formatDate(str);

  if (str.endsWith('/')) {
    if (res.length === 2) {
      return `${res}/`;
    }

    if (res.length === 5) {
      return `${res}/`;
    }
  }
  return res;
};

const appendSlash = (v: string) => (v.length === 2 || v.length === 5 ? `${v}/` : v);

export const dateFormatter = {
  accept: /\d+/g,
  format: formatDateWithAppend,
  append: appendSlash,
};
