import { makeName } from '@goldwasserexchange/oblis-frontend-utils';
import ouiBase from '@goldwasserexchange/oui';
import toPath from 'lodash.topath';
import { useGetFieldPath, useLastFieldPathPart, useParentFieldPath } from '../../../OUI/Inputs';
import { useTableName } from './context';
import { getColumnData, getDataTable } from './getters';

export const useElementFieldPathHookWithRootFieldPath = () => {
  const fieldPath = useGetFieldPath();
  const primaryKey = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  return `${fieldPath}.${primaryKey}`;
};

export const useElementFieldPathHookWithParentFieldPath = () => {
  const parentFieldPath = useParentFieldPath();
  const primaryKey = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  return `${parentFieldPath}.${primaryKey}`;
};

export const useDataTableElement = () => {
  const primaryKey = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const tableName = useTableName();
  const parentFieldPathArray = useLastFieldPathPart();
  const dataTable = getDataTable(tableName, parentFieldPathArray);
  return dataTable.data[primaryKey] ?? {};
};

export const useDataTableElementFromElement = () => {
  const primaryKey = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const tableName = useTableName();
  const fieldPath = useGetFieldPath();
  const fieldPathArray = toPath(fieldPath);
  const dataTable = getDataTable(tableName, fieldPathArray[fieldPathArray.length - 2]);
  return dataTable.data[primaryKey] ?? {};
};

export const useDataTableElementFieldPath = () => {
  const dataTableLine = useDataTableElement();
  return 'fieldPath' in dataTableLine ? dataTableLine.fieldPath : '';
};

export const useDataTableElementValueFromElement = () => {
  const dataTableLine = useDataTableElementFromElement();
  return 'value' in dataTableLine ? dataTableLine.value || null : null;
};

export const useElementFieldPathHookWithParentAndDataFieldPath = () => {
  const parentFieldPath = useParentFieldPath();
  const dataFieldPath = useDataTableElementFieldPath();
  return `${parentFieldPath}.${dataFieldPath}`;
};

export const useElementFieldPathHookWithRootAndDataFieldPath = () => {
  const fieldPath = useGetFieldPath();
  const dataFieldPath = useDataTableElementFieldPath();
  return `${fieldPath}.${dataFieldPath}`;
};

export const useFilterBlackAndWhiteList = (whiteList, blackList) => {
  const list = whiteList.filter((el) => !blackList.includes(el));
  return list;
};

export const useListColumnData = (list, tableName) => {
  const columnDatas = list.map((el) => getColumnData(tableName, el));
  return columnDatas;
};

export const useListColumnFieldFilterLogic = (list, tableName) => {
  const columnDatas = list.map((el) => getColumnData(tableName, el).fieldFilterLogic.logic);
  return columnDatas;
};

export const useFilterColumns = (whiteList, blackList, parentName, tableName) => {
  const list = useFilterBlackAndWhiteList(whiteList, blackList);
  const columnFilterLogic = useListColumnFieldFilterLogic(list, tableName);
  const presenceLogicMap = {
    map: columnFilterLogic,
  };
  const presence = ouiBase.utils.hookstate.useFormFilterLogicMap(presenceLogicMap, parentName);
  const filteredList = list.filter((_, index) => presence[index] === 'visible');
  return filteredList;
};

export const useGetFieldPaths = (whiteList, blackList, parentName, tableName) => {
  const list = useFilterBlackAndWhiteList(whiteList, blackList);
  const columnDatas = useListColumnData(list, tableName);
  const fieldPaths = Object.fromEntries(columnDatas.map(({ id, useMakeFieldPath = makeName }) => [id, useMakeFieldPath(parentName, id)])); // eslint-disable-line react-hooks/rules-of-hooks
  return fieldPaths;
};

export const useGetFilteredFieldPaths = (whiteList, blackList, parentName, tableName) => {
  const visibleColumns = useFilterColumns(whiteList, blackList, parentName, tableName);
  const fieldPaths = useGetFieldPaths(whiteList, blackList, parentName, tableName);
  return visibleColumns.map((column) => fieldPaths[column]);
};
