import { Locale } from '@goldwasserexchange/i18n';
import { MessageDescriptor } from '@lingui/core';
import { messages as frMessages } from '@goldwasserexchange/i18n/src/router/locale/fr/messages.js';
import { messages as nlMessage } from '@goldwasserexchange/i18n/src/router/locale/nl/messages.js';

export const getTitleFromMessageDescriptor = (messageDescriptor: MessageDescriptor, language: Locale) => {
  const { id } = messageDescriptor;
  const title = language === 'fr' ? frMessages[id] : nlMessage[id];
  if (!title) {
    return messageDescriptor.message ?? '';
  }
  return title;
};
