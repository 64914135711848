import { DEFAULT_ONBOARDING_SECTION, ONBOARDING_SECTIONS } from './constants';

export const isValidOnboardingSections = (section: string): section is 'welcome' | 'form' | 'status' => ONBOARDING_SECTIONS.includes(section);

export const getValidOnboardingSection = (section: string | undefined) => {
  if (section == null || section === '') {
    return DEFAULT_ONBOARDING_SECTION;
  }
  if (!isValidOnboardingSections(section)) {
    return 'not-found';
  }
  return section;
};
