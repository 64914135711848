import * as React from 'react';
import { sizeContext } from './context';

export const SizeContextProvider = (props: React.PropsWithChildren<{
  size?: number,
}>) => {
  const {
    size = 1,
    children,
  } = props;
  return (
    <sizeContext.Provider value={size}>
      {children}
    </sizeContext.Provider>
  );
};
