import { useCallback } from 'react';
import { AccountPosition } from '../../type';
import { filterByCCurrencyCode } from '../../../../shared';
import { filterBySTypeGrpId } from '../sTypeGrp/filterBySTypeGrpId';

const filterBySTypeGrpIdAndCCurrencyCode = (cCurrencyCode: AccountPosition['C_CURRENCY.CODE'] | 'All' | null, sTypeGrpId: string | null) => (accountPosition: AccountPosition) => {
  const cCurrencyCodeFilter = filterByCCurrencyCode(cCurrencyCode);
  const sTypeGrpFilter = filterBySTypeGrpId(sTypeGrpId);
  return cCurrencyCodeFilter(accountPosition) && sTypeGrpFilter(accountPosition);
};

export const useFilterBySTypeGrpIdAndCCurrencyCode = (
  cCurrencyCode: AccountPosition['C_CURRENCY.CODE'] | 'All' | null,
  sTypeGrpId: string | null,
) => {
  const filterFn = useCallback(filterBySTypeGrpIdAndCCurrencyCode(cCurrencyCode, sTypeGrpId), [cCurrencyCode, sTypeGrpId]);
  return filterFn;
};
