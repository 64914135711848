var _a;
import { STypeGrp } from '../../../../types';
export var questionnaireDependenciesByStatus = (_a = {},
    _a[STypeGrp.OBLIGATIONS_PERP] = [
        STypeGrp.OBLIGATIONS,
        STypeGrp.OBLIGATIONS_SUB,
    ],
    _a[STypeGrp.OBLIGATIONS_SUB] = [
        STypeGrp.OBLIGATIONS,
    ],
    _a[STypeGrp.TRACKERS_MAT] = [
        STypeGrp.TRACKERS,
    ],
    _a);
