import { RampWithNameTransform } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';

export function MakeListElementContentRamp(props: { baseRampName?: string }) {
  const { baseRampName = '' } = props;
  return (
    <>
      <RampWithNameTransform
        rampName={`${baseRampName}ElementButtonPlaceHolder`}
      >
        <RampWithNameTransform
          rampName={`${baseRampName}ElementButtonHandler`}
        />
      </RampWithNameTransform>
      <RampWithNameTransform rampName={`${baseRampName}ElementLabelContainer`}>
        <RampWithNameTransform rampName={`${baseRampName}ElementLabelContent`} />
        <RampWithNameTransform rampName={`${baseRampName}ElementLabelDisabledReasonSwitcher`} baseRampName={baseRampName} />
      </RampWithNameTransform>
    </>
  );
}
