import { useCallback } from 'react';
import { PositionSubGroup } from '../../type';
import { useFilterByBySTypeGrpId } from '../../utils';
import { useLmlGet } from '../../../../../shared';
import { usePositionsSubGroupTransformApi } from '../api';
import { useLinguiLocale } from '../../../../../../../Components/useLinguiLocale';

const makeTransformFunction = (lmlGet: (lml: string) => string) => (positionSubGroup: PositionSubGroup | undefined | null) => (positionSubGroup && positionSubGroup['S_TYPE_GRP.LML_DESCRIPTION'] ? lmlGet(positionSubGroup['S_TYPE_GRP.LML_DESCRIPTION']) : '');

export const usePositionSubGroupSTypeGrpLmlDescriptions = () => {
  const filterPredicate = useFilterByBySTypeGrpId();
  const locale = useLinguiLocale();
  const lmlGet = useLmlGet(locale);
  const transformFunction = useCallback(makeTransformFunction(lmlGet), [lmlGet]);
  const result = usePositionsSubGroupTransformApi({
    filterPredicate,
    transformFunction,
  });
  return result;
};
