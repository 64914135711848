import ouiBase from '@goldwasserexchange/oui';
import { RampWithNameTransform } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';

export function DefaultListInputElementContextContainer(
  props: React.PropsWithChildren<{ index: number }>,
) {
  const { index, children } = props;
  return (
    <ouiBase.List.DefaultListElementContextContainer index={index}>
      <RampWithNameTransform rampName="RefContextProvider">
        <RampWithNameTransform rampName="ElementFocusWithHandlersContextProvider">
          {children}
        </RampWithNameTransform>
      </RampWithNameTransform>
    </ouiBase.List.DefaultListElementContextContainer>
  );
}
