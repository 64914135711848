import { AppBarSpringRef } from '../../animation/context/context';
import actions from './actions';
import makeGuards from './guards';
import makeServices from './services';

export * as actions from './actions';
export * as guards from './guards';
export * as services from './services';

export const makeOptions = (
  matcher: MediaQueryList,
  springApi: AppBarSpringRef,
) => ({
  actions,
  guards: makeGuards(matcher),
  services: makeServices(matcher, springApi),
});

export default makeOptions;
