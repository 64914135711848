import { createContext } from 'react';

export type OnFocusContextType =
  | ((e: React.FocusEvent<HTMLInputElement | HTMLButtonElement>) => void)
  | undefined;

export const onFocusContext = createContext<
|((e: React.FocusEvent<HTMLInputElement | HTMLButtonElement>) => void)
| undefined
>(undefined);
