import { GetBondsCallsParams } from '@goldwasserexchange/actor/rest-services';
import { useContext, useMemo } from 'react';
import { useAuthIsSettle } from '../../../../../../Components/Auth';
import { useCognitoUserValidAccounts } from '../../../../../../Components/Sections/online/selectedAccount';
import { stringifyParams } from '../../../shared/utils/makeUrl';
import { useBondSAccId } from '../../shared';
import { bondCallParamsContext } from '../context';

export const useBondsCallDefaultParams = (params: Partial<GetBondsCallsParams>): GetBondsCallsParams | null => {
  const {
    'S_ACC.ID': contextSAccId,
  } = useContext(bondCallParamsContext);
  const bondSAccId = useBondSAccId();
  const sAccId = params['S_ACC.ID'] ?? bondSAccId ?? contextSAccId;
  const defaultParams = useMemo(() => (sAccId != null && sAccId >= 0
    ? ({
      'S_ACC.ID': sAccId,
    })
    : null
  ), [
    sAccId,
  ]);
  return defaultParams;
};

export const useBondsCallStringifiedParams = () => {
  const contextParams = useBondsCallDefaultParams({});
  const accounts = useCognitoUserValidAccounts();
  const isAuthMachineSettled = useAuthIsSettle();
  return contextParams !== null && isAuthMachineSettled && accounts.length > 0 ? stringifyParams({
    ...contextParams,
    CONNECTED: accounts.length > 0 ? 1 : 0,
  }) : null;
};
