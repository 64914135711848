import ouiBase from '@goldwasserexchange/oui';
import { AccountType } from '../../../../../types';
var onboardingPath = 'onboarding';
var selectedService = 'selectedService';
var makeLogic = function (truthy, falsy) { return ({
    name: "".concat(onboardingPath, ".").concat(selectedService),
    is: [AccountType.CONSEIL, AccountType.GESTION],
    options: {
        matcher: ouiBase.utils.match.arrayMatcherNames.some,
    },
    alternatives: {
        truthy: {
            result: truthy,
        },
        falsy: {
            result: falsy,
        },
    },
}); };
export var showLogic = makeLogic('visible', 'invisible');
export var showLogicBoolean = makeLogic(true, false);
