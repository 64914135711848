import { atom } from 'jotai';
import { pathnamePartFamilly } from '../../../atoms';
import { getNewsSectionType, getNewsSectionWithDefault } from './utils';

export const pathnameUrlNewsArticleOrSection = atom((get) => {
  const article = get(pathnamePartFamilly(2));
  return getNewsSectionWithDefault(article);
});

export const pathnameUrlNewsType = atom((get) => {
  const article = get(pathnameUrlNewsArticleOrSection);
  return getNewsSectionType(article);
});

export const pathnameUrlNewsArticleId = atom((get) => {
  const article = get(pathnameUrlNewsArticleOrSection);
  const id = article !== 'home' && article !== 'search' ? (article.split('_').at(-1) ?? null) : null;
  return id;
});
