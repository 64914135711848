import * as React from 'react';
import { useAtomValue } from 'jotai';
import {
  ValidSections,
  pathnameUrlSectionAtom,
} from '../../history';
import { BondsSubsectionHead } from './bonds';
import { NewsSubSectionHead } from './news';

export const Head = React.memo(() => {
  const section = useAtomValue(pathnameUrlSectionAtom);
  switch (section) {
    case ValidSections.BONDS:
      return <BondsSubsectionHead />;
    case ValidSections.NEWS:
      return <NewsSubSectionHead />;
    default:
      return null;
  }
});
