import { RampWithNameTransform } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import { useCurrentPrimaryDimension } from '../../../../../utils/dimensions';
import { useCurrentPrimaryDimensionIndex } from '../../../../../utils/dimensions/primaryDimension/current/indexes/hooks';
export function DefaultListElementBaseInner(props) {
    var style = props.style;
    var index = useCurrentPrimaryDimensionIndex();
    var primaryKey = useCurrentPrimaryDimension();
    return (React.createElement(RampWithNameTransform, { rampName: "ElementContainer-primaryKey-".concat(primaryKey), rampFallbacks: [
            "ElementContainer-index-".concat(index),
            'ElementContainer',
        ], style: style },
        React.createElement(RampWithNameTransform, { rampName: "ElementContent-primaryKey-".concat(primaryKey), rampFallbacks: [
                "ElementContent-index-".concat(index),
                'ElementContent',
            ] })));
}
export function DefaultListElementBase(props) {
    var index = props.index, style = props.style;
    return (React.createElement(RampWithNameTransform, { rampName: "ElementContextContainer", index: index },
        React.createElement(DefaultListElementBaseInner, { style: style })));
}
