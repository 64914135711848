import { makeName } from '@goldwasserexchange/oblis-frontend-utils';
import { Ramp } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import { ZIndexInvertedChildren } from '../../OUI/Form';
import { ParentNameProps } from '../Form/utils';

export const ActorRenderColumns = (props: ParentNameProps & { filteredList: string[] }) => {
  const { parentName, filteredList } = props;
  const children = filteredList.map((column) => (
    <Ramp
      key={makeName(parentName, column)}
      rampName="columnRender"
      parentName={parentName}
      column={column}
    />
  ));
  return (
    <ZIndexInvertedChildren>
      {children}
    </ZIndexInvertedChildren>
  );
};
