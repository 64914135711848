import { RampWithNameTransform } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import { useTableMultiBody } from '../../context';
export var TableBodyBase = function () {
    var multiBody = useTableMultiBody();
    return !multiBody
        ? (React.createElement(RampWithNameTransform, { rampName: "BodyContainer" },
            React.createElement(RampWithNameTransform, { rampName: "BodyMapper" })))
        : (React.createElement(RampWithNameTransform, { rampName: "BodyMapper" }));
};
