import { HeadProps, applyHead } from '../../../history';

export const setHead = (props: HeadProps & {
  run: boolean,
}) => {
  const {
    run,
  } = props;
  if (run) {
    const {
      href,
      frHref,
      nlHref,
      language,
      jsonLd,
      openGraph,
      title,
    } = props;
    applyHead({
      title,
      language,
      href,
      frHref,
      nlHref,
      openGraph,
      jsonLd,
    });
  }
};
