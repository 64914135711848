import * as events from '../../../events';
import { makeSpringService } from '../utils';

export type AppBarCollapsingServiceMeta = {
  src: {
    type: 'CollapsingService',
  },
};

export const name = 'collapsing';

export const makeService = makeSpringService(name, 'collapse', 1, false, events.collapsingFinished.eventBuilder);

export default makeService;
