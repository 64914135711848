/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { animated } from 'react-spring';
import {
  InputVariantsNames,
  useBaseVariant,
  useHasFocusVariant,
  useValidationVariant,
  useDisabledVariant,
  InputVariantHooksArray,
} from '../../../../../../OUI/Inputs';
import { useVariantSpring } from '../../../../utils';

const placeHolderStyle: ouiStyle.InStyle = {
  widthLines: 'full',
  borderBottomStyle: 'solid',
  borderBottomColor: ouiStyle.Constants.Colors.primary,
  borderBottomWidth: 'simple',
};

const textInputPlaceHolderCss = ouiStyle.makeCss(placeHolderStyle);

const textInputPlaceHolderBaseCss = ouiStyle.makeCssAndExtract(placeHolderStyle, [
  'borderBottomColor',
]);

const placeHolderFocusStyle: ouiStyle.InStyle = {
  ...placeHolderStyle,
  borderBottomWidth: 'double',
  borderBottomColor: ouiStyle.Constants.Colors.accent,
};

const placeHolderFocusCss = ouiStyle.makeCssAndExtract(placeHolderFocusStyle, [
  'borderBottomWidth',
  'borderBottomColor',
]);

const placeHolderNoFocusStyle: ouiStyle.InStyle = {
  ...placeHolderStyle,
  borderBottomWidth: 'simple',
};

const placeHolderNoFocusCss = ouiStyle.makeCssAndExtract(placeHolderNoFocusStyle, [
  'borderBottomWidth',
]);

const placeHolderErrorStyle = {
  ...placeHolderStyle,
  borderBottomColor: ouiStyle.Constants.Colors.danger,
};

const placeHolderErrorCss = ouiStyle.makeCssAndExtract(placeHolderErrorStyle, [
  'borderBottomColor',
]);

const placeHolderDisabledStyle = {
  ...placeHolderStyle,
  borderBottomColor: ouiStyle.Constants.Colors.disabled,
};

const placeHolderDisabledCss = ouiStyle.makeCssAndExtract(placeHolderDisabledStyle, [
  'borderBottomColor',
]);

const placeHolderVariants: Record<
InputVariantsNames,
Partial<Pick<ouiStyle.Style, 'borderBottomWidth' | 'borderBottomColor'>>
> = {
  opened: {},
  closed: {},
  base: textInputPlaceHolderBaseCss,
  hasContent: {},
  noContent: {},
  focus: placeHolderFocusCss,
  noFocus: {
    borderBottomWidth: placeHolderNoFocusCss.borderBottomWidth,
  },
  untouched: {},
  valid: {},
  error: placeHolderErrorCss,
  disabled: placeHolderDisabledCss,
  enabled: {},
  checked: {},
  unChecked: {},
  last: {},
  notLast: {},
  before: {},
  inside: {},
  after: {},
  noSelection: {},
  notCorrected: {},
  correct: {},
  incorrect: {},
  correctSelected: {},
  correctNotSelected: {},
  incorrectSelected: {},
  incorrectNotSelected: {},
};

const defaultTextInputPlaceHolderContainerInputVariantHooks: InputVariantHooksArray = [
  useBaseVariant,
  useHasFocusVariant,
  useValidationVariant,
  useDisabledVariant,
];

export function DefaultTextInputPlaceHolderContainer(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  const spring = useVariantSpring(
    placeHolderVariants,
    defaultTextInputPlaceHolderContainerInputVariantHooks,
  );
  return (
    <animated.div css={textInputPlaceHolderCss} style={spring}>
      {children}
    </animated.div>
  );
}
