/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { animated } from 'react-spring';
import ouiStyle from '@goldwasserexchange/oui-style';
import {
  useChecked,
  InputVariantsNames,
  InputVariantHooksArray,
  useBaseVariant,
  useCheckedVariant,
  useHasFocusVariant,
  useDisabledVariant,
  useValidationVariant,
} from '../../../../../../../OUI/Inputs';
import { useScaleEnterLeave, useVariantSpring } from '../../../../../utils';

const radioListInputDomListElementButtonContentStyle: ouiStyle.InStyle = {
  position: 'absolute',
  top: 0,
  color: ouiStyle.Constants.Colors.inverted,
  backgroundColor: ouiStyle.Constants.Colors.transparent,
  svg: true,
  fill: true,
};
const radioListInputDomListElementButtonContentCss = ouiStyle.makeCss(
  radioListInputDomListElementButtonContentStyle,
);

const baseRadioListInputDomListElementButtonContentCss = ouiStyle.makeCssAndExtract(
  radioListInputDomListElementButtonContentStyle,
  ['color'],
);

const focusRadioListInputDomListElementButtonContentStyle: ouiStyle.InStyle = {
  ...radioListInputDomListElementButtonContentStyle,
  color: ouiStyle.Constants.Colors.accent,
};

const focusRadioListInputDomListElementButtonContentCss = ouiStyle.makeCssAndExtract(
  focusRadioListInputDomListElementButtonContentStyle,
  ['color'],
);

const errorRadioListInputDomListElementButtonContainerStyle: ouiStyle.InStyle = {
  ...radioListInputDomListElementButtonContentStyle,
  color: ouiStyle.Constants.Colors.danger,
};

const errorRadioListInputDomListElementButtonContentCss = ouiStyle.makeCssAndExtract(
  errorRadioListInputDomListElementButtonContainerStyle,
  ['color'],
);

const disabledRadioListInputDomListElementButtonContentStyle: ouiStyle.InStyle = {
  ...radioListInputDomListElementButtonContentStyle,
  color: ouiStyle.Constants.Colors.disabled,
};

const disabledRadioListInputDomListElementButtonContentCss = ouiStyle.makeCssAndExtract(
  disabledRadioListInputDomListElementButtonContentStyle,
  ['color'],
);

const checkedRadioListInputDomListElementButtonContentStyle: ouiStyle.InStyle = {
  ...radioListInputDomListElementButtonContentStyle,
  color: ouiStyle.Constants.Colors.primary,
};

const checkedRadioListInputDomListElementButtonContentCss = ouiStyle.makeCssAndExtract(
  checkedRadioListInputDomListElementButtonContentStyle,
  ['color'],
);

const radioListInputDomListElementButtonContentVariants: Record<
InputVariantsNames,
Partial<Pick<ouiStyle.Style, 'color'>>
> = {
  opened: {},
  closed: {},
  base: baseRadioListInputDomListElementButtonContentCss,
  hasContent: {},
  noContent: {},
  checked: checkedRadioListInputDomListElementButtonContentCss,
  unChecked: {},
  focus: focusRadioListInputDomListElementButtonContentCss,
  noFocus: {},
  untouched: {},
  valid: {},
  error: errorRadioListInputDomListElementButtonContentCss,
  disabled: disabledRadioListInputDomListElementButtonContentCss,
  enabled: {},
  last: {},
  notLast: {},
  before: {},
  inside: {},
  after: {},
  noSelection: {},
  notCorrected: {},
  correct: {},
  incorrect: {},
  correctSelected: {},
  correctNotSelected: {},
  incorrectSelected: {},
  incorrectNotSelected: {},
};

const listInputLabelContentHookVariants: InputVariantHooksArray = [
  useBaseVariant,
  useCheckedVariant,
  useHasFocusVariant,
  useValidationVariant,
  useDisabledVariant,
];

const SvgRadioContent = (props: { style: any }) => {
  const { style: transitionStyle } = props;
  const style = useVariantSpring(
    radioListInputDomListElementButtonContentVariants,
    listInputLabelContentHookVariants,
  );
  return (
    <animated.svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      css={radioListInputDomListElementButtonContentCss}
      style={{ ...style, ...transitionStyle }}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="8" />
    </animated.svg>
  );
};
export function RadioListInputDomListElementButtonContent() {
  const checked = useChecked();
  const checkedString = checked ? checked.toString() : 'unknown';
  const transition = useScaleEnterLeave(checkedString);
  return transition((style, item) => {
    if (item === 'true') {
      return <SvgRadioContent key={item} style={style} />;
    }
    return null;
  });
}
