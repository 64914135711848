import ouiDom from '@goldwasserexchange/oui-dom';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { identity } from 'ramda';
import * as React from 'react';
import { ActorRenderColumns } from '../../geInputList';
import { getGeSectionByName } from '../getters';
import { SectionHeading } from './header';

const GeSectionContent = (props: {
  parentName: string,
  section: string,
  whiteList: string[],
}) => {
  const {
    parentName,
    whiteList,
    section,
  } = props;
  const sectionData = getGeSectionByName(section);
  const list = sectionData.fields.filter((field) => whiteList.includes(field));
  return (
    <ActorRenderColumns
      parentName={parentName}
      filteredList={list}
    />
  );
};

const GeSectionIntroLabel = (props: { sectionName: string }) => {
  const { sectionName } = props;
  const sectionData = getGeSectionByName(sectionName);
  if (sectionData.description == null) {
    return null;
  }

  return <>{sectionData.description()}</>;
};

export const GeSection = (props: {
  parentName: string,
  section: string,
  whiteList: string[],
  index: number,
}) => {
  const {
    parentName: sourceParentName,
    section,
    whiteList,
    index,
  } = props;
  const sectionData = getGeSectionByName(section);
  const { useChangeParentName = identity } = sectionData;
  const parentName = useChangeParentName(sourceParentName);
  const hasDescription = sectionData.description != null;
  return (
    <ouiDom.HeadingSection.HeadingSection index={index} name={section}>
      <TopModel
        name={`HeadingSectionTitleContent-${section}`}
        sectionName={section}
        parentName={parentName}
      >
        {SectionHeading}
      </TopModel>
      {
        hasDescription
          ? <TopModel name={`HeadingSectionIntroContent-${section}`} sectionName={section}>{GeSectionIntroLabel}</TopModel>
          : null
      }
      <GeSectionContent
        parentName={parentName}
        section={section}
        whiteList={whiteList}
      />
    </ouiDom.HeadingSection.HeadingSection>
  );
};
