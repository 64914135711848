/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { InputHTMLAttributes } from 'react';

const toggleInputStyle: ouiStyle.InStyle = {
  srOnly: true,
};

const toggleInputCss = ouiStyle.makeCss(toggleInputStyle);

export const ToggleInput = (props: {
  id: string,
  value: boolean,
  onChange: InputHTMLAttributes<HTMLInputElement>['onChange'],
  disabled?: boolean,
}) => {
  const {
    value,
    onChange,
    disabled,
    id,
  } = props;
  return (
    <input
      id={id}
      name={id}
      type="checkbox"
      css={toggleInputCss}
      checked={value}
      onChange={onChange}
      disabled={disabled}
    />
  );
};
