import ouiStyle from '@goldwasserexchange/oui-style';
import { Ramp } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import { useOuiStyleMediaContextByIndex } from '../../MediaContext';

export const ModalIcon = () => {
  const up = useOuiStyleMediaContextByIndex(3);
  return (
    <Ramp rampName="ModalIconContainer">
      <Ramp rampName="ModalIconContent" size={up ? 40 : 20} stroke={ouiStyle.Constants.Colors.fadeDarker} />
    </Ramp>
  );
};
