import * as React from 'react';
import { ActorApiStringDisplay } from '../../../../../../components/display/string';
import { useBondsBondIsSomeRedacted, useBondsBondSQuotePriceString } from '../../hooks';

export const BondsBondSQuotePrice = (props: {
  withRedactedIcon?: boolean,
}) => {
  const {
    withRedactedIcon,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useBondsBondSQuotePriceString();
  const {
    data: redacted,
    isLoading: redactedIsLoading,
  } = useBondsBondIsSomeRedacted(['S_QUOTE.PRICE_MAX', 'S_QUOTE.PRICE_CLOSE']);
  return (
    <ActorApiStringDisplay
      data={data}
      isLoading={isLoading || redactedIsLoading}
      error={error}
      loadingValue="--.--- %"
      isBlocked={redacted}
      withRedactedIcon={withRedactedIcon}
    />
  );
};
