import { useMemo, useRef, useState } from 'react';
import { Placement } from '@popperjs/core';
import { usePopper } from 'react-popper';
import { PopperContainerType } from './container';
import { PopperArrowType } from './arrow';
import { usePopperReference } from './reference';

export const useMemoisedPopper = (
  placement: Placement,
  fallbackPlacements: Placement[],
) => {
  const referenceElement = usePopperReference();
  const popperElement = useRef<null | HTMLDivElement>(null);
  const [arrowElement, setArrowElement] = useState<string | HTMLElement | null | undefined>(null);
  const { styles, attributes, state: popperState } = usePopper(
    referenceElement.current,
    popperElement.current,
    {
      placement,
      modifiers: [
        { name: 'arrow', options: { element: arrowElement } },
        {
          name: 'offset',
          options: {
            offset: [0, 0],
          },
        },
        {
          name: 'flip',
          options: {
            fallbackPlacements,
          },
        },
      ],
    },
  );
  const currentPlacement = popperState && popperState.placement;
  const memoizedPopperContainerProps = useMemo<PopperContainerType>(
    () => [popperElement, styles.popper, attributes.popper],
    [currentPlacement],
  );
  const memoizedPopperArrowProps = useMemo<PopperArrowType>(
    () => [setArrowElement, styles.arrow],
    [currentPlacement, setArrowElement],
  );
  const memoizedPopper = useMemo<[PopperContainerType, PopperArrowType]>(
    () => [memoizedPopperContainerProps, memoizedPopperArrowProps],
    [memoizedPopperArrowProps, memoizedPopperContainerProps],
  );
  return memoizedPopper;
};
