/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Plural } from '@lingui/macro';
import * as React from 'react';
import { isEmpty } from 'ramda';
import ouiDom from '@goldwasserexchange/oui-dom';
import ouiStyle from '@goldwasserexchange/oui-style';
import { fuzySections } from '../../utils';

export const NotFoundSuggestions = (props: {
  param: string,
  whiteListedSections: string[],
  labelMap: Record<string, () => JSX.Element>,
  baseTo: string,
  color?: ouiStyle.Constants.Colors,
}) => {
  const {
    param,
    whiteListedSections,
    labelMap,
    baseTo,
    color,
  } = props;
  const fuzzySearch = React.useMemo(() => fuzySections(whiteListedSections), [whiteListedSections]);
  const fuzzyResult = fuzzySearch.search(param);
  const filteredFuzzyResult = fuzzyResult.filter((index) => !!labelMap[whiteListedSections[index]]);
  const css = React.useMemo(() => ({
    color,
  }), [color]);
  return !isEmpty(filteredFuzzyResult) ? (
    <section
      css={css}
    >
      <p
        css={css}
      >
        <Plural
          value={filteredFuzzyResult.length}
          _0="Nous ne connaissons aucune section ayant une addresse proche"
          one="Cherchiez-vous la section suivante ?"
          other="Cherchiez-vous l'une des sections suivantes ?"
        />
      </p>
      <ul>
        {filteredFuzzyResult.map((index) => {
          const Label = labelMap[whiteListedSections[index]];
          const to = `${baseTo}/${whiteListedSections[index]}`;
          return (
            <li
              css={css}
            >
              <ouiDom.A.A
                to={to}
              >
                <Label />
              </ouiDom.A.A>
            </li>
          );
        })}
      </ul>
    </section>
  ) : null;
};
