import * as React from 'react';
import { Trans } from '@lingui/macro';
import {
  dataStructure, EcplProf,
} from '@goldwasserexchange/oblis-frontend-utils';
import ouiBase from '@goldwasserexchange/oui';
import { useHookstate } from '@hookstate/core';
import { useUser } from '../../../utils';

const PensionerLabelText = () => {
  const userState = useUser();
  const profession = useHookstate(userState.ID_ECPL_PROF).get();
  return profession === `${EcplProf.PENSIONER}` ? (
    <Trans>Date d'entrée en pension</Trans>
  ) : null;
};

const professionOptionalLastProfession = dataStructure.T_ADD.fields.ID_ECPL_PROF.professionOptionalLastProfession.map((prof) => `${prof}`);

const RentierHomeUnemployedLabelText = () => {
  const userState = useUser();
  const profession = useHookstate(userState.ID_ECPL_PROF).get();
  return professionOptionalLastProfession.includes(profession)
    ? (
      <Trans>Année de cessation d'activité</Trans>
    )
    : null;
};

export const lastProfessionDateLabelText = (
  <>
    <PensionerLabelText />
    <RentierHomeUnemployedLabelText />
  </>
);

export const tAddLastProfessionDateValidatorLogic: ouiBase.utils.hookstate.FormValueFilterLogic<'requiredPastDate' | 'nilCast'> = dataStructure.T_ADD.fields.ID_ECPL_PROF.logic.makeIdEcplProfMayHaveProfessionHasLastProfessionFilterLogic({
  visible: {
    result: 'requiredPastDate',
  },
  invisible: {
    result: 'nilCast',
  },
});
