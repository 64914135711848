import * as React from 'react';
import useDimensions from 'react-cool-dimensions';
import {
  SectionScrollRefCallbacks,
  sectionScrollRefCallbacksContext,
} from './refCallbacks';
import {
  leftButtonVisibleContext,
} from './leftButtonVisible';
import {
  rightButtonVisibleContext,
} from './rightButtonVisible';
import {
  scrollAddContext,
} from './scrollAdd';
import { useScrollToLeft } from '../../../Components/Scrollable/utils';

export const ScrollableContextProvider = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  const containerRef = React.useRef<HTMLElement | HTMLDivElement | null>(null);
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const [containerWidth, setContainerWidth] = React.useState(0);
  const [listContainerWidth, setListContainerWidth] = React.useState(0);
  const { observe: observeContainerSize } = useDimensions<HTMLElement>({
    useBorderBoxSize: true,
    polyfill: ResizeObserver,
    onResize: ({ width }) => {
      if (width !== containerWidth) {
        setContainerWidth(width);
      }
    },
  });
  const { observe: listContainerRefCallback } = useDimensions<HTMLElement>({
    useBorderBoxSize: true,
    polyfill: ResizeObserver,
    onResize: ({ width }) => {
      if (width !== listContainerWidth) {
        setListContainerWidth(width);
      }
    },
  });
  React.useEffect(() => {
    const scroll = () => {
      if (containerRef.current) {
        const { scrollLeft } = containerRef.current;
        if (scrollLeft !== scrollPosition) {
          setScrollPosition(scrollLeft);
        }
      }
    };
    if (containerRef.current) {
      containerRef.current.addEventListener(
        'scroll',
        scroll,
        true,
      );
    }
    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('scroll', scroll);
      }
    };
  }, [containerRef, scrollPosition]);
  const containerRefCallback = React.useCallback((el: HTMLElement | HTMLDivElement | null | undefined) => {
    if (el) {
      observeContainerSize(el);
      containerRef.current = el;
    }
  }, [observeContainerSize, containerRef]);
  const refCallbackContextValue = React.useMemo<SectionScrollRefCallbacks>(() => [containerRefCallback, listContainerRefCallback], [containerRefCallback, listContainerRefCallback]);
  const leftButtonVisible = scrollPosition !== 0;
  const rightButtonVisible = Math.floor(listContainerWidth - scrollPosition) > containerWidth;
  const scrollTo = useScrollToLeft(containerRef);
  const scrollAdd = React.useCallback((pixels: number) => scrollTo(scrollPosition + pixels), [scrollTo, scrollPosition]);
  return (
    <sectionScrollRefCallbacksContext.Provider
      value={refCallbackContextValue}
    >
      <leftButtonVisibleContext.Provider value={leftButtonVisible}>
        <rightButtonVisibleContext.Provider value={rightButtonVisible}>
          <scrollAddContext.Provider value={scrollAdd}>
            {children}
          </scrollAddContext.Provider>
        </rightButtonVisibleContext.Provider>
      </leftButtonVisibleContext.Provider>
    </sectionScrollRefCallbacksContext.Provider>
  );
};
