import * as React from 'react';
import { useState } from 'react';
import { canCloseCurrentModalContext } from './context';

export const CanCloseCurrentModalContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  const handlers = useState<boolean>(true);
  return (
    <canCloseCurrentModalContext.Provider value={handlers}>
      {children}
    </canCloseCurrentModalContext.Provider>
  );
};
