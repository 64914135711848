import { BondResponse } from '../../type';
import { useBondsBondFieldApi, useBondsBondFieldTransformFnApi } from '../api';

export const useBondsBondSQuotePriceClose = () => {
  const response = useBondsBondFieldApi('S_QUOTE.PRICE_CLOSE');
  return response;
};

const minMaxPriceClose = (securityBond: BondResponse['S_QUOTE.PRICE_CLOSE'] | undefined) => {
  if (securityBond == null) {
    return [];
  }
  return [securityBond - 15, securityBond + 15];
};

export const useBondsBondSQuotePriceCloseMinMax = () => {
  const response = useBondsBondFieldTransformFnApi({
    field: 'S_QUOTE.PRICE_CLOSE',
    transformFn: minMaxPriceClose,
  });
  return response;
};
