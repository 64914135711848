import ouiStyle from '@goldwasserexchange/oui-style';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { Trans } from '@lingui/macro';
import * as React from 'react';
import { ValidSections } from '../../../../history';
import { ToolbarDropDown, ToolbarDropDownListContainer } from '../toolbarDropDown';
import { LogoutButton } from './logoutButton';
import { SectionToolbarDropDownListElementLink } from '../toolbarDropDown/list/sectionLink';
import { UserIcon } from './icon';

export const UserMenu = () => (
  <ToolbarDropDown
    list={['security', 'logout']}
  >
    <TopModel
      name="ToolbarDropDownLabelTextContent"
    >
      <UserIcon
        style={{
          backgroundColor: ouiStyle.Constants.Colors.logoBlue,
          width: '1.5rem',
          height: '1.5rem',
        }}
      />
    </TopModel>
    <TopModel
      name="ToolbarDropDownListContainer"
      right={0}
      widthLines={8}
      container={ouiStyle.Constants.Container.none}
    >
      {ToolbarDropDownListContainer}
    </TopModel>
    <TopModel
      name="ToolbarDropDownListElementLink-security"
      section={ValidSections.SECURITY}
    >
      {SectionToolbarDropDownListElementLink}
    </TopModel>
    <TopModel
      name="ToolbarDropDownListElementContent-security"
    >
      <Trans>
        Profil
      </Trans>
    </TopModel>
    <TopModel
      name="ToolbarDropDownListElementLink-logout"
    >
      {LogoutButton}
    </TopModel>
    <TopModel
      name="ToolbarDropDownListElementContent-logout"
    >
      <Trans>
        Déconnexion
      </Trans>
    </TopModel>
  </ToolbarDropDown>
);
