import * as React from 'react';
import ouiStyle, { MediaQueryProp } from '@goldwasserexchange/oui-style';
import ouiDom from '@goldwasserexchange/oui-dom';
import { useIsHighlighted } from '../context';

export const AccountTypePrimaryTitleCellContainer = (props: React.PropsWithChildren<{
  visibleDownMd?: boolean,
  scope?: 'col' | 'row' | undefined,
} & ouiDom.Table.TableBodyRowCellInStyle
>): JSX.Element | null => {
  const {
    visibleDownMd = false,
    children,
    ...rest
  } = props;
  const isHighlighted = useIsHighlighted();
  const display: MediaQueryProp<ouiStyle.Constants.DisplayProp> = React.useMemo(
    () => {
      if (visibleDownMd) {
        return [ouiStyle.Constants.DisplayProp.block, undefined, ouiStyle.Constants.DisplayProp.tableCell, undefined, undefined, undefined];
      }
      return [ouiStyle.Constants.DisplayProp.hidden, undefined, ouiStyle.Constants.DisplayProp.tableCell, undefined, undefined, undefined];
    },
    [visibleDownMd],
  );
  return (
    <ouiDom.Table.TableHeaderHeading
      display={display}
      backgroundColor={isHighlighted ? [ouiStyle.Constants.Colors.inverted, undefined, ouiStyle.Constants.Colors.primaryTranslucid, undefined, undefined, undefined] : undefined}
      borderTopColor={isHighlighted ? ouiStyle.Constants.Colors.primary : ouiStyle.Constants.Colors.transparent}
      borderTopWidth={['none', undefined, 'double', undefined, undefined, undefined]}
      borderTopStyle={['none', undefined, 'solid', undefined, undefined, undefined]}
      borderLeftWidth={['simple', undefined, isHighlighted ? 'double' : 'simple', undefined, undefined, undefined]}
      borderLeftColor={isHighlighted ? [ouiStyle.Constants.Colors.accent, undefined, ouiStyle.Constants.Colors.primary, undefined, undefined, undefined] : [ouiStyle.Constants.Colors.primary, undefined, ouiStyle.Constants.Colors.transparent, undefined, undefined, undefined]}
      borderRightWidth={isHighlighted ? ['simple', undefined, 'double', undefined, undefined, undefined] : ['simple', undefined, 'none', undefined, undefined, undefined]}
      borderRightColor={isHighlighted ? [ouiStyle.Constants.Colors.accent, undefined, ouiStyle.Constants.Colors.primary, undefined, undefined, undefined] : [ouiStyle.Constants.Colors.primary, undefined, ouiStyle.Constants.Colors.transparent, undefined, undefined, undefined]}
      borderBottomWidth={['simple', undefined, 'double', undefined, undefined, undefined]}
      borderBottomColor={ouiStyle.Constants.Colors.fade}
      borderTopLeftRadius={isHighlighted ? ['none', undefined, 'rounded', undefined, undefined, undefined] : 'none'}
      borderTopRightRadius={isHighlighted ? ['none', undefined, 'rounded', undefined, undefined, undefined] : 'none'}
      widthLines={['auto', undefined, 10, undefined, undefined, undefined]}
      {...rest}
    >
      {children}
    </ouiDom.Table.TableHeaderHeading>
  );
};
