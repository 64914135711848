export type MediaQueryChangeUp = 'MEDIA_QUERY_CHANGE_UP';

export const eventType: MediaQueryChangeUp = 'MEDIA_QUERY_CHANGE_UP';

export type EventType = {
  type: MediaQueryChangeUp,
};

export const eventBuilder = (): EventType => ({
  type: eventType,
});
