import useSWR, { KeyedMutator } from 'swr';
import { useMemo } from 'react';
import memoize from 'memoizee';
import { useAtomValue } from 'jotai';
import { isIdLoading } from '../../../shared';
import { getServiceUrl } from '../../../../shared';
import { BondList } from '../type';
import { useCognitoUserValidAccounts } from '../../../../../../Components/Sections/online/selectedAccount';
import { stringifyParams } from '../../../shared/utils/makeUrl';
import { fetchWithOptionalAuth } from '../../../shared/utils/fetchWithAuth';
import { useAuthIsSettle, useSingleTableNameContext } from '../../../../../../Components/Auth';
import { bondSearchParamsAtom } from '../../shared';
import { Bonds } from '../../enum';
import { makeApiUrl, useFavoriteBonds } from '../../../../../../aws';
import { useBondsTableListOrFavoriteType } from '../../context';

export const PAGE_SIZE = 20;

const useMakeBondsListUrl = (
  service: Bonds.List | Bonds.Favorites,
  tableName: string | null,
) => {
  const favoriteResponse = useFavoriteBonds(tableName ?? '', tableName != null && service === Bonds.Favorites);
  const sAccIds = service === Bonds.Favorites ? { 'S_ACC.ID': favoriteResponse.data?.join(';') ?? '' } : {};
  const accounts = useCognitoUserValidAccounts();
  const searchAndFilter = useAtomValue(bondSearchParamsAtom);
  const isAuthMachineSettled = useAuthIsSettle();
  if (service === Bonds.Favorites) {
    if (favoriteResponse.isLoading) {
      return null;
    }
    if ((favoriteResponse.data?.length ?? 0) === 0) {
      return null;
    }
  }
  if (!isAuthMachineSettled) {
    return null;
  }
  const {
    sortColumn,
    sortDirection,
    page,
    'C_CURRENCY.CODE': cCurrencyCode,
    'S_ACC.MATURITY': maturity,
    'S_MARKET.YIELD': sMarketYield,
    SEARCH_TERM,
    ...rest
  } = searchAndFilter;
  const castedSqlBoolean = Object.fromEntries(Object.entries(rest).map(([key, value]) => [key, value === true ? 1 : 0]));
  const stringifiedParams = stringifyParams({
    CONNECTED: accounts.length > 0 ? 1 : 0,
    SEARCH_TERM: SEARCH_TERM !== '' ? `%${SEARCH_TERM.split(' ').join('%')}%` : undefined,
    SORT_DIRECTION: sortDirection,
    SORT_COLUMN: sortColumn,
    ROW_NUMBER_START: ((page - 1) * PAGE_SIZE) + 1,
    ROW_NUMBER_END: (page) * PAGE_SIZE,
    'C_CURRENCY.CODE': cCurrencyCode,
    'S_ACC.MATURITY': maturity,
    'S_MARKET.YIELD': sMarketYield,
    ...sAccIds,
    ...castedSqlBoolean,
  });
  const url = `${makeApiUrl('actor')}/${getServiceUrl(service)}${stringifiedParams}`;
  return url;
};

type BaseUseBondsListApiResponse = {
  isLoading: boolean,
  error?: any,
  mutate: KeyedMutator<BondList[]>,
  isValidating: boolean,
}

type UseBondsListApiResponse = {
  data: undefined | BondList[],
} & BaseUseBondsListApiResponse;

type FilterPredicate = (bondList: BondList) => boolean;

export type BondsListSortPredicate = (prevBondList: BondList, nextBondList: BondList) => number;

export type BondsListWithoutLengthSortPredicate = (prevBondList: BondList, nextBondList: BondList) => number;

type UseBondsListApiProps = {
  filterPredicate?: FilterPredicate,
  sortPredicate?: BondsListSortPredicate,
};

const useBondsListSwr = (isLoading?: boolean) => {
  const tableName = useSingleTableNameContext();
  const service = useBondsTableListOrFavoriteType();
  const url = useMakeBondsListUrl(service, tableName);
  const result = useSWR<BondList[], any>(
    !isLoading ? url : null,
    fetchWithOptionalAuth,
    {
      dedupingInterval: 120000,
      keepPreviousData: service === Bonds.Favorites,
    },
  );
  return result;
};

export const useBondsListApi = (props: UseBondsListApiProps): UseBondsListApiResponse => {
  const {
    filterPredicate,
    sortPredicate,
  } = props;
  const result = useBondsListSwr();
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    let { data } = result;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      if (filterPredicate) {
        data = data.filter((position) => filterPredicate(position));
      }
    }
    return ({
      ...result,
      data,
      isLoading,
    });
  }, [result, filterPredicate, sortPredicate]);
  return resultWithLoading;
};

type TransformArrayFunction<T> = (bondList: BondList[] | undefined) => T;

type UseBondsListApiTransformProps<T> = {
  transformFunction: TransformArrayFunction<T>,
} & UseBondsListApiProps

type UseBondsListApiTransformResponse<T> = {
  data: T | undefined,
} & BaseUseBondsListApiResponse;

export function useBondsListTransformApi<T>(props: UseBondsListApiTransformProps<T>): UseBondsListApiTransformResponse<T> {
  const {
    filterPredicate,
    sortPredicate,
    transformFunction,
  } = props;
  const response = useBondsListApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: (!isLoading && !error) ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UseBondListApiResponse = {
  data: BondList | undefined,
} & BaseUseBondsListApiResponse;

export const useBondListApi = (props: UseBondsListApiProps): UseBondListApiResponse => {
  const {
    filterPredicate,
    sortPredicate,
  } = props;
  const result = useBondsListSwr();
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    // eslint-disable-next-line prefer-destructuring
    let data: BondList[] | BondList | undefined = result.data;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      if (filterPredicate) {
        data = data.find((position) => filterPredicate(position));
      }
      if (Array.isArray(data)) {
        // eslint-disable-next-line prefer-destructuring
        data = data[0];
      }
    }
    return ({
      ...result,
      data,
      isLoading,
    });
  }, [result, filterPredicate, sortPredicate]);
  return resultWithLoading;
};

type TransformFunction<T> = (bondList: BondList | undefined) => T

type UseBondListApiTransformProps<T> = {
  transformFunction: TransformFunction<T>,
} & UseBondsListApiProps;

export function useBondListTransformApi<T>(props: UseBondListApiTransformProps<T>): UseBondsListApiTransformResponse<T> {
  const {
    filterPredicate,
    sortPredicate,
    transformFunction,
  } = props;
  const response = useBondListApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: !isLoading && !error ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UseBondListFieldApiResponse<Field extends keyof BondList> = {
  data: BondList[Field] | undefined,
} & BaseUseBondsListApiResponse;

type UseBondListFieldApiProps<Field extends keyof BondList> = {
  field: Field,
} & UseBondsListApiProps

export function useBondListFieldApi<Field extends keyof BondList>(props: UseBondListFieldApiProps<Field>): UseBondListFieldApiResponse<Field> {
  const {
    filterPredicate,
    sortPredicate,
    field,
  } = props;
  const response = useBondListApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
    } = response;
    return {
      ...response,
      data: data ? data[field] : undefined,
    };
  }, [response, field]);
  return result;
}

type TransformFieldFunction<Field extends keyof BondList, T> = (bondList: BondList[Field] | undefined) => T

type UseBondListFieldApiTransformProps<Field extends keyof BondList, T> = {
  transformFunction: TransformFieldFunction<Field, T>,
} & UseBondListFieldApiProps<Field>;

export function useBondListFieldTransformApi<Field extends keyof BondList, T>(props: UseBondListFieldApiTransformProps<Field, T>): UseBondsListApiTransformResponse<T> {
  const {
    field,
    filterPredicate,
    sortPredicate,
    transformFunction,
  } = props;
  const response = useBondListFieldApi({
    field,
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: !isLoading && !error ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

const findBySAccId = (sAccId: string, data: BondList[]) => data.find((line) => `${line['S_ACC.ID']}` === sAccId);

const memoFindBySAccId: typeof findBySAccId = memoize(findBySAccId);

type UseBondListApiTransformBySAccIdProps<T> = {
  sAccId: string,
  transformFunction: TransformFunction<T>,
};

export function useBondListTransformApiBySAccId<T>(props: UseBondListApiTransformBySAccIdProps<T>): UseBondsListApiTransformResponse<T> {
  const {
    sAccId,
    transformFunction,
  } = props;
  const sAccIdLoading = isIdLoading(sAccId);
  const response = useBondsListSwr(sAccIdLoading);
  const result = useMemo(() => {
    let data: BondList[] | BondList | undefined = response.data as BondList[] | undefined;
    const isLoading = !response.data && !response.error;
    if (data) {
      data = memoFindBySAccId(sAccId, data);
    }
    return {
      ...response,
      isLoading,
      data: data ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UseBondListFieldApiBySAccIdProps<Field extends keyof BondList> = {
  field: Field,
  sAccId: string,
}

export function useBondListFieldApiBySAccId<Field extends keyof BondList>(props: UseBondListFieldApiBySAccIdProps<Field>): UseBondListFieldApiResponse<Field> {
  const {
    sAccId,
    field,
  } = props;
  const sAccIdLoading = isIdLoading(sAccId);
  const response = useBondsListSwr(sAccIdLoading);
  const result = useMemo(() => {
    let data: BondList[] | BondList | undefined = response.data as BondList[] | undefined;
    const isLoading = !response.data && !response.error;
    if (data) {
      data = memoFindBySAccId(sAccId, data);
    }
    return {
      ...response,
      isLoading,
      data: data ? data[field] : undefined,
    };
  }, [response, field]);
  return result;
}

type UseBondListFieldApiTransformBySAccIdProps<Field extends keyof BondList, T> = {
  transformFunction: TransformFieldFunction<Field, T>,
  sAccId: string,
  field: Field,
}

export function useBondListFieldTransformApiBySAccId<Field extends keyof BondList, T>(props: UseBondListFieldApiTransformBySAccIdProps<Field, T>): UseBondsListApiTransformResponse<T> {
  const {
    field,
    sAccId,
    transformFunction,
  } = props;
  const sAccIdLoading = isIdLoading(sAccId);
  const response = useBondsListSwr(sAccIdLoading);
  const result = useMemo(() => {
    let data: BondList[] | BondList | undefined = response.data as BondList[] | undefined;
    const isLoading = !response.data && !response.error;
    if (data) {
      data = memoFindBySAccId(sAccId, data);
    }
    return {
      ...response,
      isLoading,
      data: data ? transformFunction(data[field]) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}
