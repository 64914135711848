import * as React from 'react';
import { useSpring } from 'react-spring';
import { useAtomValue } from 'jotai';
import { MediaContext } from '../../../MediaContext';
import { ValidSections, pathnameUrlSectionAtom } from '../../../../history';
import {
  animationControllerLogoExpandedContext,
  animationControllerClosedTopContext,
  animationControllerMqUpContext,
  animationControllerCollapseContext,
  animationControllerApiContext,
} from './context';

export const AnimationControllerProvider = (
  props: React.PropsWithChildren<{}>,
) => {
  const {
    children,
  } = props;
  const section = useAtomValue(pathnameUrlSectionAtom);
  const {
    upXl,
  } = React.useContext(MediaContext);
  const spring = React.useCallback(
    () => ({
      from: {
        logoExpanded: section === ValidSections.HOME && upXl ? 1 : 0,
        closed: 1,
        mqUp: 0,
        collapse: 0,
        logoExpandedAction: 0,
        openAction: 0,
        mqAction: 0,
        collapsingAction: 0,
      },
      to: {
        logoExpanded: section === ValidSections.HOME && upXl ? 1 : 0,
        closed: 1,
        mqUp: 0,
        collapse: 0,
        logoExpandedAction: 0,
        openAction: 0,
        mqAction: 0,
        collapsingAction: 0,
      },
      immediate: false,
    }),
    [],
  );
  const [styles, springApi] = useSpring(spring);
  return (
    <animationControllerApiContext.Provider value={springApi}>
      <animationControllerLogoExpandedContext.Provider value={styles.logoExpanded}>
        <animationControllerClosedTopContext.Provider value={styles.closed}>
          <animationControllerMqUpContext.Provider value={styles.mqUp}>
            <animationControllerCollapseContext.Provider value={styles.collapse}>
              {children}
            </animationControllerCollapseContext.Provider>
          </animationControllerMqUpContext.Provider>
        </animationControllerClosedTopContext.Provider>
      </animationControllerLogoExpandedContext.Provider>
    </animationControllerApiContext.Provider>
  );
};
