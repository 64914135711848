import { AccountRates, SimulationBuyOrSellCostData, SimulationBuyAndSellCostData } from '../type';
import { computeAmountAfterExchangeFees } from './computeExchangeFees';
import { computeOrGetGrossAmount, GetGrossAmountProps } from './computeGrossAmount';
import { yearlyPct } from './yearlyPct';

const getCourtRateAndMinCourt = (type: SimulationBuyOrSellCostData['type']) => (rates: AccountRates | null | undefined) => {
  if (!rates) {
    return {
      courtRate: 0,
      minCourt: 0,
    };
  }
  const courtRate = type === 'bonds' ? rates['T_ACC.BONDS_RATE_COURT'] : rates['T_ACC.STOCKS_RATE_COURT'];
  const minCourt = (type === 'bonds' ? rates['T_ACC.BONDS_COURT_MIN'] : rates['T_ACC.STOCKS_COURT_MIN']) ?? 0;
  return {
    courtRate,
    minCourt,
  };
};

export type ComputeBuyOrSellCourtage = SimulationBuyOrSellCostData | ({
  currency: SimulationBuyOrSellCostData['currency'],
  type: SimulationBuyOrSellCostData['type'],
} & GetGrossAmountProps)

export const computeBuyOrSellCourtage = (props: ComputeBuyOrSellCourtage) => (rates: AccountRates | null | undefined) => {
  const {
    type,
  } = props;
  if (rates) {
    const amountWithoutExchangeFees = computeAmountAfterExchangeFees(props)(rates['T_ACC.FRC_RATE']);
    const {
      courtRate,
      minCourt,
    } = getCourtRateAndMinCourt(type)(rates);
    const brokerageFees = Math.max(amountWithoutExchangeFees * courtRate, minCourt);
    return brokerageFees;
  }
  return 0;
};

export const computeBuyOrSellCourtagePct = (props: ComputeBuyOrSellCourtage) => (rates: AccountRates | null | undefined) => {
  const grossAmountInEur = computeOrGetGrossAmount(props);
  const courtage = computeBuyOrSellCourtage(props)(rates);
  return courtage / grossAmountInEur;
};

export type ComputeBuyAndSellCourtageProps = {
  counterValue: SimulationBuyAndSellCostData['counterValue'],
  type: SimulationBuyAndSellCostData['type'],
  refund: SimulationBuyAndSellCostData['refund'],
}

export const computeBuyAndSellCourtage = (props: ComputeBuyAndSellCourtageProps) => (rates: AccountRates | null | undefined) => {
  const {
    counterValue,
    type,
    refund = false,
  } = props;
  const courtage = computeBuyOrSellCourtage({
    grossAmountInEur: counterValue,
    currency: 'EUR',
    type,
  })(rates);
  return (!(refund && type === 'bonds') ? 2 : 1) * courtage;
};

export type ComputeBuyAndSellCourtagePropsPct = {
  durationYears: SimulationBuyAndSellCostData['durationYears'],
} & ComputeBuyAndSellCourtageProps;

export const computeBuyAndSellCourtagePct = (props: ComputeBuyAndSellCourtagePropsPct) => (rates: AccountRates | null | undefined) => {
  const {
    counterValue,
    type,
    refund,
  } = props;
  const courtage = computeBuyAndSellCourtage({
    counterValue,
    type,
    refund,
  })(rates);
  return yearlyPct(props)(courtage);
};
