import { AccountPosition } from '../../type';
import { useAccountPositionsTransformApi } from '../api';

const sumPctByCCurrencyCode = (positions: AccountPosition[] | undefined): {
  x: string,
  y: number,
}[] => {
  if (!positions) {
    return [];
  }
  return Object.values(positions.reduce((acc, position) => ({
    ...acc,
    [position['C_CURRENCY.CODE']]: {
      x: position['C_CURRENCY.CODE'],
      y: (acc[position['C_CURRENCY.CODE']] || { y: 0 }).y + position['GES_ESTIM_D.PC_PORT_INT'],
    },
  }), {}));
};

export const useAccountPositionsSumPctByCurrency = () => {
  const sumPctByCurrencyApiResponse = useAccountPositionsTransformApi({
    transformFunction: sumPctByCCurrencyCode,
  });
  return sumPctByCurrencyApiResponse;
};
