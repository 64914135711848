import ouiBase from '@goldwasserexchange/oui';
import { useBondListTransformApiBySAccId } from '../api';
import { isNewBondList } from '../../utils';

export const useBondListIsNew = () => {
  const sAccId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const response = useBondListTransformApiBySAccId({
    sAccId,
    transformFunction: isNewBondList,
  });
  return response;
};
