export var list = [
    'preferences',
    'taxonomy',
    'taxonomyAlignment',
    'taxonomyProportion',
    'sfdr',
    'sfdrDurableProportion',
    'sfdrSocialEnvironmentalProportion',
    'sfdrDurableOnly',
    'sfdrSocialEnvironmentalOnly',
    'incidence',
    'incidenceType',
    'incidenceProportion',
];
