import * as React from 'react';
import ouiDom from '@goldwasserexchange/oui-dom';
import ouiStyle from '@goldwasserexchange/oui-style';
import { useAtomValue } from 'jotai';
import { locationPathnameAtom } from '../../history';

export const ResetQsButton = (props: React.PropsWithChildren<{
  color: ouiStyle.InStyleWithMediaQueries['color'],
  backgroundColor: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
  borderColor: ouiStyle.InStyleWithMediaQueries['borderColor'],
  hoverBackgroundColor: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
  onReset?: () => void,
}>) => {
  const {
    color,
    backgroundColor,
    borderColor,
    hoverBackgroundColor,
    onReset,
    children,
  } = props;
  const pathname = useAtomValue(locationPathnameAtom);
  const history = ouiDom.A.useHistory();
  const onClick = React.useCallback(() => {
    if (onReset) {
      onReset();
    }
    history.push(`${pathname}`);
  }, [onReset, history, pathname]);
  return (
    <ouiDom.Button.Button
      type="button"
      onClick={onClick}
      color={color}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      hoverBackgroundColor={hoverBackgroundColor}
    >
      {children}
    </ouiDom.Button.Button>
  );
};
