import * as React from 'react';
import { Ramp, useTopModelNameTransform } from '@goldwasserexchange/react-topmodel';
import { getBaseBuilderNames, BaseBuilderProps } from '../../../OUI/Inputs';

export type BaseBuilderWithStyleProps = BaseBuilderProps & {
  containerStyle?: any,
  contentStyle?: any,
};

export function BaseBuilderWithStyle(props: BaseBuilderWithStyleProps) {
  const {
    rampName, containerStyle, contentStyle,
  } = props;
  const [container, content] = getBaseBuilderNames(rampName);
  return (
    <Ramp rampName={container} style={containerStyle}>
      <Ramp rampName={content} style={contentStyle} />
    </Ramp>
  );
}

export function BaseBuilderWithNameTransformAndStyle(
  props: BaseBuilderWithStyleProps,
) {
  const {
    rampName: sourceName, containerStyle, contentStyle,
  } = props;
  const name = useTopModelNameTransform(sourceName);
  return (
    <BaseBuilderWithStyle
      rampName={name}
      containerStyle={containerStyle}
      contentStyle={contentStyle}
    />
  );
}

export function BaseBuilderWithNameTransformAndContainerStyle(
  props: BaseBuilderProps & { style: any },
) {
  const { rampName, style } = props;
  return (
    <BaseBuilderWithNameTransformAndStyle
      rampName={rampName}
      containerStyle={style}
    />
  );
}
