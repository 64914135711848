/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';

const style: ouiStyle.InStyleWithMediaQueries = {
  paddingLeft: 1.6,
};

const css = ouiStyle.mediaQuery(ouiStyle.makeCss(style));

export const FootNotesContainer = (props: {
  children: React.ReactNode,
}) => {
  const {
    children,
  } = props;
  return (
    <ol
      css={css}
    >
      {children}
    </ol>
  );
};
