import { useContext } from 'react';
import { canCloseCurrentModalContext } from './context';

export const useCanCloseCurrentModalContextHandlers = () => {
  const handlers = useContext(canCloseCurrentModalContext);
  return handlers;
};

export const useCanCloseCurrentModal = () => {
  const [canCloseCurrentModal] = useCanCloseCurrentModalContextHandlers();
  return canCloseCurrentModal;
};

export const useSetCanCloseCurrentModal = () => {
  const [, setCanCloseCurrentModal] = useCanCloseCurrentModalContextHandlers();
  return setCanCloseCurrentModal;
};
