import ouiBase from '@goldwasserexchange/oui';
import { RampWithNameTransform } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';

export function DefaultSelectInputListSearchInputContainer() {
  const open = ouiBase.List.useSelectIsOpen();
  return (
    <>
      {open === true && (
        <RampWithNameTransform rampName="SelectedIconPlaceHolder">
          <RampWithNameTransform rampName="SelectedIconContent" />
        </RampWithNameTransform>
      )}
      <RampWithNameTransform rampName="SearchInputContext" />
    </>
  );
}
