import { GetBondsFavoritesResponse, GetBondsListResponse } from '@goldwasserexchange/actor/rest-services';
import { BooleanSearchAndFilterBondParams } from './type';

export const bondFilterRedactedColumnsDict: Record<BooleanSearchAndFilterBondParams, (keyof GetBondsListResponse) | (keyof GetBondsFavoritesResponse) | 'CPS_HIS.ACCRUED'> = {
  eur: 'C_CURRENCY.CODE',
  usd: 'C_CURRENCY.CODE',
  foreignCurrencies: 'C_CURRENCY.CODE',
  'S_MARKET.YIELD': 'S_MARKET.YIELD',
  'S_MARKET.SMALL_QT_MIN': 'S_MARKET.QT_MIN',
  'CPS_HIS.LOW_ACCRUED': 'CPS_HIS.ACCRUED',
  'S_MARKET.PRICE_CLOSE_LOW': 'S_QUOTE.PRICE_CLOSE',
  perpetual: 'S_TYPE_GRP.ID',
  lessThanFiveYears: 'S_ACC.OBL_END_DATE',
  'RATING_SP.INVESTMENT_GRADE': 'GES_RAT_VAL_SP.SHORTCUT',
  'S_ACC.IS_GREEN': 'S_ACC.GREEN',
};
