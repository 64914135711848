import * as Yup from 'yup';
import { UncastedDataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import {
  makeRequired, passwordValidator, equalsError,
} from '../../../../../OUI/Inputs';
import { ValidationContextType } from '../../../../Form/validations/contextType';

const changedPasswordFieldValidatorDependencyPaths: (keyof UncastedDataStructure['auth'] | '$')[] = [
  '$',
];

export const changedPasswordPasswordFieldValidator = Yup.mixed().when(changedPasswordFieldValidatorDependencyPaths, (context: ValidationContextType) => {
  const {
    rootValue,
  } = context;
  const {
    auth,
  } = rootValue;
  const {
    password,
  } = auth;
  return makeRequired(passwordValidator.test(equalsError, equalsError, (v) => v !== password));
});
