export const query = /* groq */`
*[
  _type == "promotion"
  && startDate.utc < now()
  && endDate.utc > now()
] 
| order(endDate.utc desc)
{
  bondId,
  "startDate": startDate.utc,
  "endDate": endDate.utc,
  brokerageFees,
  custodyFees,
  "news": news[_key == $locale][0].value -> {
    "locale": language,
    title,
    "date": publishDate.utc,
    "id": _id,
  },
  "language": $locale
}
` as const;
