import ouiBase from '@goldwasserexchange/oui';
import { useBondListFieldTransformApiBySAccId } from '../api';
import { spRatingWithDefault } from '../../../../shared';

export const useBondListGesRatValSpShortcut = () => {
  const sAccId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const response = useBondListFieldTransformApiBySAccId({
    sAccId,
    field: 'GES_RAT_VAL_SP.SHORTCUT',
    transformFunction: spRatingWithDefault,
  });
  return response;
};
