import { lineHeightMap } from '../../lineHeight';
import { mediaQueries, mediaQuerizeProps } from '../../mediaQuery';
import { borderSize, space } from '../sizes';
import { sumSpace } from './space';
var handleMinus = function (value, minus) {
    if (minus && minus !== '0') {
        return "calc(".concat(value, " - ").concat(minus, ")");
    }
    return value;
};
export var computeDimension = function (props) {
    var _a = props.fontSize, fontSize = _a === void 0 ? 'xs' : _a, _b = props.factor, factor = _b === void 0 ? 'auto' : _b, paddingStart = props.paddingStart, paddingEnd = props.paddingEnd, childPaddingStart = props.childPaddingStart, childPaddingEnd = props.childPaddingEnd, parentBorderStart = props.parentBorderStart, parentBorderEnd = props.parentBorderEnd, _c = props.icon, icon = _c === void 0 ? false : _c, _d = props.lineFactor, lineFactor = _d === void 0 ? 1 : _d, minus = props.minus;
    if (!Array.isArray(factor) && !Array.isArray(fontSize) && !Array.isArray(lineFactor) && !Array.isArray(minus)) {
        if (factor === 'auto') {
            return 'auto';
        }
        if (typeof factor === 'string' && factor.includes('/')) {
            var splitted = factor.split('/');
            var nominator = parseInt(splitted[0], 10);
            var denominator = parseInt(splitted[1], 10);
            return handleMinus("".concat((nominator / denominator) * 100, "%"), minus);
        }
        if (factor === 'none') {
            return handleMinus('0%', minus);
        }
        if (factor === 'full') {
            return handleMinus('100%', minus);
        }
        if (factor === 'screenWidth') {
            return handleMinus('100vw', minus);
        }
        if (factor === 'screenHeight') {
            return handleMinus('100vh', minus);
        }
        if (typeof factor === 'string') {
            return 'auto';
        }
        var spaceFactor = sumSpace(paddingStart !== 'none' ? paddingStart : childPaddingStart, paddingEnd !== 'none' ? paddingEnd : childPaddingEnd);
        var borderWidthFactor = sumSpace(parentBorderStart, parentBorderEnd);
        var borderWidth = borderWidthFactor * borderSize;
        var baseLineSize = lineHeightMap.get(fontSize) * lineFactor;
        var lineSize = !icon ? baseLineSize : 1.2 * baseLineSize;
        return handleMinus("".concat(factor * lineSize + spaceFactor * space - borderWidth, "rem"), minus);
    }
    var factorMQ = mediaQuerizeProps(factor);
    var fontSizeMQ = mediaQuerizeProps(fontSize);
    var lineFactorMQ = mediaQuerizeProps(lineFactor);
    var minusMQ = mediaQuerizeProps(minus);
    return mediaQueries.map(function (_, index) { return computeDimension({
        fontSize: fontSizeMQ[index],
        factor: factorMQ[index],
        paddingStart: paddingStart,
        paddingEnd: paddingEnd,
        childPaddingStart: childPaddingStart,
        childPaddingEnd: childPaddingEnd,
        parentBorderStart: parentBorderStart,
        parentBorderEnd: parentBorderEnd,
        icon: icon,
        lineFactor: lineFactorMQ[index],
        minus: minusMQ[index],
    }); });
};
