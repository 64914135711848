/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const inputMainStyle: ouiStyle.InStyle = {
  flex: 'one',
};

const inputMainCss = ouiStyle.makeCss(inputMainStyle);

export const InputMain = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  return (
    <div
      css={inputMainCss}
    >
      {children}
    </div>
  );
};
