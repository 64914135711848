import * as React from 'react';
import ouiDom from '@goldwasserexchange/oui-dom';
import ouiStyle from '@goldwasserexchange/oui-style';

export const BannerContentNormal = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  return (
    <ouiDom.P.P
      color={ouiStyle.Constants.Colors.inherit}
      paddingVertical="none"
      textAlign="inherit"
    >
      {children}
    </ouiDom.P.P>
  );
};
