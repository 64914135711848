/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { UncastedDataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import ouiBase from '@goldwasserexchange/oui';

const labelContainerStyle: ouiStyle.InStyle = {
  display: ouiStyle.Constants.DisplayProp.block,
  paddingHorizontal: 'simple',
};

const labelContainerCss = ouiStyle.makeCss(labelContainerStyle);

export const LabelContainer = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  return (
    <label
      htmlFor={valueState.auth.rememberDevice.path.join('.')}
      css={labelContainerCss}
    >
      {children}
    </label>
  );
};
