var onboardingPath = 'onboarding';
var selectedServicePath = 'selectedService';
var durabilityPath = 'durability';
var taxonomyPath = 'taxonomy';
var sfdrPath = 'sfdr';
var incidencePath = 'incidence';
export var dependencyFieldPaths = [
    "".concat(onboardingPath, ".").concat(selectedServicePath),
    "".concat(onboardingPath, ".").concat(durabilityPath, ".").concat(taxonomyPath),
    "".concat(onboardingPath, ".").concat(durabilityPath, ".").concat(sfdrPath),
    "".concat(onboardingPath, ".").concat(durabilityPath, ".").concat(incidencePath),
];
