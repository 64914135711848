import * as React from 'react';
import { currentRenderDirectionContext } from './context';
import { useCurrentRenderDirection } from './hooks';
export var CurrentRenderDirectionContextProvider = function (props) {
    var currentDirection = props.currentDirection, children = props.children;
    return (React.createElement(currentRenderDirectionContext.Provider, { value: currentDirection }, children));
};
export var CurrentDirectionContextProviderToggle = function (props) {
    var children = props.children;
    var currentDirection = useCurrentRenderDirection();
    return (React.createElement(CurrentRenderDirectionContextProvider, { currentDirection: currentDirection === 'horizontal' ? 'vertical' : 'horizontal' }, children));
};
