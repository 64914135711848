export type StatusDisabledReasonMap = {
  correct?: string,
  failure?: string,
  notValidated?: string,
}

export const getForceDisabledReasonFromStatuses = (statuses: ('success' | 'failure' | 'notValidated' | 'unchecked' | '')[], statusValueMaps: StatusDisabledReasonMap[]): string => {
  const statusValueMapIndex = statusValueMaps.findIndex((statusValueMap, index) => statusValueMap[statuses[index]] !== undefined);
  if (statusValueMapIndex !== -1) {
    return statusValueMaps[statusValueMapIndex][statuses[statusValueMapIndex]] || '';
  }
  return '';
};
