import React from 'react';
import { Trans } from '@lingui/macro';
import {
  dataStructure, CCountry,
} from '@goldwasserexchange/oblis-frontend-utils';
import { useHookstate } from '@hookstate/core';
import { tAddNationalRegisterLabel } from './nationalRegister';
import { useUser } from '../../../utils';

export const FISCAL_NUMBER_KEY = 'fiscalNumber';

export const tAddRegistNumLabel = <Trans>Numéro d'identification fiscale</Trans>;

export const tAddFiscRegistNumValidatorLogic = dataStructure.T_ADD.fields.TIN_US.logic.show.makeShowLogic({
  national: {
    result: 'requiredNationalRegister',
  },
  global: {
    result: 'requiredString',
  },
  invisible: {
    result: 'nilCast',
  },
  nationalInNatNumber: {
    result: 'nilCast',
  },
});

const TAddFiscRegistNumLabel = () => {
  const userState = useUser();
  const fiscalResidencySameAsResidency = useHookstate(userState.fiscalResidencySameAsResidency);
  const idCCountry = useHookstate(userState.address.ID_C_COUNTRY);
  const idCCountryRes = useHookstate(userState.fiscalAddress.ID_C_COUNTRY);
  const fiscalResidency = fiscalResidencySameAsResidency.get() === '1' ? idCCountry : idCCountryRes;
  const fiscaleResidencyIsBelgium = fiscalResidency.get() === CCountry.BELGIUM;
  if (fiscaleResidencyIsBelgium) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{tAddNationalRegisterLabel}</>;
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{tAddRegistNumLabel}</>;
};

export const tAddFiscRegistNumLabel = <TAddFiscRegistNumLabel />;
