/* eslint-disable camelcase */
import React, { useCallback, useMemo, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { none, useHookstate } from '@hookstate/core';
import { Trans } from '@lingui/macro';
import F from 'ramda/es/F';
import {
  object, array, mixed, string,
} from 'yup';
import {
  dataStructure,
  UncastedPhysicalTAdd,
  UncastedDataStructure,
  PersonType,
  TAddType,
  TNatureLrp,
  TProcType,
  PhysicalTAdd,
  UncastedAddress,
  AdressType,
  UncastedMoralTAdd,
  MoralTAdd,
} from '@goldwasserexchange/oblis-frontend-utils';
import { isNil, T } from 'ramda';
import { TopModel, TopModelMerge } from '@goldwasserexchange/react-topmodel';
import ouiBase from '@goldwasserexchange/oui';
import ouiStyle from '@goldwasserexchange/oui-style';
import ouiDom from '@goldwasserexchange/oui-dom';
import {
  IconName,
} from '../Icons';
import { StateFilter } from '../Machine';
import {
  FormikTAddIconCardLegend,
} from './Scrollable';
import {
  TNatureLRPProcTypeUpdater,
  T_ADD_GSM_PRINCIPAL,
  T_ADD_EMAIL_PRINCIPAL,
  T_ADD_TEL_PRINCIPAL,
  mcCivilStatusValidator,
} from './inputs';
import {
  ArrayInput, ArrayInputProps, ArrayListInput, ArrayListInputBaseProps,
} from '../Form/array';
import { Slot } from '../Layout/components';
import { ParentNameProps } from '../Form/utils';
import { addPhones } from '../tAddPhone';
import {
  isRepresentative,
} from './utils';
import { getColumnData, TAddInput, useFilterColumns } from './data';
import { ZIndexInvertedChildren } from '../../OUI/Form';
import {
  significantOtherValidator,
  T_ADD_SIGNIFICANT_OTHER_BIRTH_DATE_COLUMN,
  T_ADD_SIGNIFICANT_OTHER_FIRSTNAME_COLUMN,
  T_ADD_SIGNIFICANT_OTHER_NAME1_COLUMN,
} from './inputs/mcCivilStatus/significantOther/significantOther';
import {
  useGetGetFn, minError, requiredError, makeRequired, stringValidator, booleanValidator, isMinorFromEuroBirthdate, nilValidator, numberValidator,
} from '../../OUI/Inputs';
import { SelectListInput } from '../../OUI-dom/Inputs';
import { ColumnKey, GeTables, T_ADD_TABLE_NAME } from './data/types';
import { TableContextProvider } from './data/context';
import { makeFilterLogicFromTableNameAndColumn } from '../Form/validations/makeFilterLogic';
import { useLayout } from '../Layout/helpers';
import { GeSection } from './sections/components';
import { geSections } from './sections/data';
import { ActorRenderColumns } from './geInputList';
import { makeWhenAlwaysCast } from '../Form/validations/whenAlwaysCast';
import { SearchKey } from '../../OUI/Inputs/components/shared/components/list/context/searchKeys/context';
import { ValidationContextType } from '../Form/validations/contextType';
export * from './data';
export * from './utils';

type TAddFieldList = ColumnKey[]

export const tAddPhoneNumber: TAddFieldList = [
  T_ADD_GSM_PRINCIPAL,
];

export const tAddSignup: TAddFieldList = [
  'FIRSTNAME',
  'NAME_1',
  'BIRTH_DATE',
  T_ADD_GSM_PRINCIPAL,
];

const fileKeysDependencies: (keyof UncastedPhysicalTAdd)[] = [
  'id',
  'T_ADD_type',
];

const adressShape: Record<keyof UncastedAddress, any> = {
  type: makeRequired(stringValidator),
  STREET_1: makeRequired(stringValidator),
  ZIP: makeRequired(stringValidator),
  CITY: makeRequired(stringValidator),
  ID_C_COUNTRY: makeRequired(numberValidator),
};

const nilAdressShape: Record<keyof UncastedAddress, any> = {
  type: stringValidator,
  STREET_1: nilValidator,
  ZIP: nilValidator,
  CITY: nilValidator,
  ID_C_COUNTRY: nilValidator,
};

type TAddKeys = (keyof UncastedPhysicalTAdd) | (keyof UncastedMoralTAdd) | (keyof PhysicalTAdd) | (keyof MoralTAdd);

type TAddField<F extends TAddKeys> =
| UncastedPhysicalTAdd[F]
| UncastedMoralTAdd[F]
| PhysicalTAdd[F]
| MoralTAdd[F]

const addressDependencies: TAddKeys[] = [
  'fiscalResidencySameAsResidency',
  'onlyOneFiscalResidency',
  'T_ADD_type',
];

const addressValidator = makeWhenAlwaysCast(
  addressDependencies,
  (
    fiscalResidencySameAsResidency: TAddField<'fiscalResidencySameAsResidency'>,
    onlyOneFiscalResidency: TAddField<'onlyOneFiscalResidency'>,
    T_ADD_type: TAddField<'T_ADD_type'>,
    _,
    meta: {
      value: UncastedAddress,
      context: {
        rootValue: UncastedDataStructure,
      },
    },
  ) => {
    const {
      value,
      context,
    } = meta;
    const {
      rootValue,
    } = context;
    const isIgnoredType = T_ADD_type === TAddType.IGNORED
  || T_ADD_type === '';
    if (isIgnoredType) {
      return object().shape(nilAdressShape);
    }
    const isMoralUserInNotMoralPersonType = (T_ADD_type === TAddType.MORAL && rootValue.onboarding.personType !== PersonType.MORAL);
    if (
      isMoralUserInNotMoralPersonType
    ) {
      return object().shape(nilAdressShape);
    }
    const isFiscalAddressAndNotRequired = value.type === AdressType.FISCAL_ADDRESS
  && (
    fiscalResidencySameAsResidency === '1'
    || fiscalResidencySameAsResidency === true
    || T_ADD_type === TAddType.MORAL
  );
    if (
      isFiscalAddressAndNotRequired
    ) {
      return object().shape(nilAdressShape);
    }
    const isSecondaryFiscalAddressAndNotRequired = value.type === AdressType.SECONDARY_FISCAL_ADDRESS
  && (
    onlyOneFiscalResidency === '1'
    || onlyOneFiscalResidency === true
    || T_ADD_type === TAddType.MORAL
  );
    if (
      isSecondaryFiscalAddressAndNotRequired
    ) {
      return object().shape(nilAdressShape);
    }
    return object().shape(adressShape);
  },
);

const tAddShape: Record<keyof UncastedPhysicalTAdd, any> = {
  id: makeRequired(stringValidator),
  current: makeRequired(booleanValidator),
  T_ADD_type: makeRequired(stringValidator),
  ID_T_PROC_TYPE: makeFilterLogicFromTableNameAndColumn(T_ADD_TABLE_NAME, 'ID_T_PROC_TYPE'),
  ID_T_NATURE_LRP: makeFilterLogicFromTableNameAndColumn(T_ADD_TABLE_NAME, 'ID_T_NATURE_LRP'),
  ID_C_TIT: makeFilterLogicFromTableNameAndColumn(T_ADD_TABLE_NAME, 'ID_C_TIT'),
  FIRSTNAME: makeFilterLogicFromTableNameAndColumn(T_ADD_TABLE_NAME, 'FIRSTNAME'),
  NAME_1: makeFilterLogicFromTableNameAndColumn(T_ADD_TABLE_NAME, 'NAME_1'),
  BIRTH_DATE: makeFilterLogicFromTableNameAndColumn(T_ADD_TABLE_NAME, 'BIRTH_DATE'),
  isMinor: makeRequired(booleanValidator),
  ID_C_COUNTRY_BIRTH: makeFilterLogicFromTableNameAndColumn(T_ADD_TABLE_NAME, 'ID_C_COUNTRY_BIRTH'),
  BIRTH_PLACE: makeFilterLogicFromTableNameAndColumn(T_ADD_TABLE_NAME, 'BIRTH_PLACE'),
  ID_C_COUNTRY_NAT: makeFilterLogicFromTableNameAndColumn(T_ADD_TABLE_NAME, 'ID_C_COUNTRY_NAT'),
  nat_REGIST_NUM: makeFilterLogicFromTableNameAndColumn(T_ADD_TABLE_NAME, 'nat_REGIST_NUM'),
  REGIST_NUM: makeFilterLogicFromTableNameAndColumn(T_ADD_TABLE_NAME, 'REGIST_NUM'),
  MIFID_ID: makeFilterLogicFromTableNameAndColumn(T_ADD_TABLE_NAME, 'MIFID_ID'),
  isTRelMail: makeFilterLogicFromTableNameAndColumn(T_ADD_TABLE_NAME, 'isTRelMail'),
  ID_ECPL_PROF: makeFilterLogicFromTableNameAndColumn(T_ADD_TABLE_NAME, 'ID_ECPL_PROF'),
  HadProfession: makeFilterLogicFromTableNameAndColumn(T_ADD_TABLE_NAME, 'HadProfession'),
  DATE_CESS: makeFilterLogicFromTableNameAndColumn(T_ADD_TABLE_NAME, 'DATE_CESS'),
  ID_ECPL_PROF_LAST: makeFilterLogicFromTableNameAndColumn(T_ADD_TABLE_NAME, 'ID_ECPL_PROF_LAST'),
  ID_ECPL_SECTOR: makeFilterLogicFromTableNameAndColumn(T_ADD_TABLE_NAME, 'ID_ECPL_SECTOR'),
  HasSameProfessionAndResidencyCountry: makeFilterLogicFromTableNameAndColumn(T_ADD_TABLE_NAME, 'HasSameProfessionAndResidencyCountry'),
  ID_C_COUNTRY_ACTI: makeFilterLogicFromTableNameAndColumn(T_ADD_TABLE_NAME, 'ID_C_COUNTRY_ACTI'),
  fundsOrigin: makeFilterLogicFromTableNameAndColumn(T_ADD_TABLE_NAME, 'fundsOrigin'),
  PHONE: makeFilterLogicFromTableNameAndColumn(T_ADD_TABLE_NAME, 'PHONE'),
  fiscalResidencySameAsResidency: makeFilterLogicFromTableNameAndColumn(T_ADD_TABLE_NAME, 'fiscalResidencySameAsResidency'),
  TIN_US: makeFilterLogicFromTableNameAndColumn(T_ADD_TABLE_NAME, 'TIN_US'),
  onlyOneFiscalResidency: makeFilterLogicFromTableNameAndColumn(T_ADD_TABLE_NAME, 'onlyOneFiscalResidency'),
  TIN_US_SEC: makeFilterLogicFromTableNameAndColumn(T_ADD_TABLE_NAME, 'TIN_US_SEC'),
  MC_MAR_STATUT: makeFilterLogicFromTableNameAndColumn(T_ADD_TABLE_NAME, 'MC_MAR_STATUT'),
  otherConstraints: makeFilterLogicFromTableNameAndColumn(T_ADD_TABLE_NAME, 'otherConstraints'),
  MC_PEP: makeFilterLogicFromTableNameAndColumn(T_ADD_TABLE_NAME, 'MC_PEP'),
  YN_US: makeFilterLogicFromTableNameAndColumn(T_ADD_TABLE_NAME, 'YN_US'),
  ID_T_ADD_FAMILY_LINK: makeFilterLogicFromTableNameAndColumn(T_ADD_TABLE_NAME, 'ID_T_ADD_FAMILY_LINK'),
  ID_C_TIT_MORAL: makeFilterLogicFromTableNameAndColumn(T_ADD_TABLE_NAME, 'ID_C_TIT_MORAL'),
  ID_C_TIT_MORALPrecision: makeFilterLogicFromTableNameAndColumn(T_ADD_TABLE_NAME, 'ID_C_TIT_MORALPrecision'),
  ID_T_ACC_AYDR: makeFilterLogicFromTableNameAndColumn(T_ADD_TABLE_NAME, 'ID_T_ACC_AYDR'),
  ID_T_ADD_ADDRESS_KEY: makeFilterLogicFromTableNameAndColumn(T_ADD_TABLE_NAME, 'ID_T_ADD_ADDRESS_KEY'),
  DESC_ECPL_SECTOR: makeFilterLogicFromTableNameAndColumn(T_ADD_TABLE_NAME, 'DESC_ECPL_SECTOR'),
  fileKeys: makeWhenAlwaysCast(
    fileKeysDependencies,
    (
      id: PhysicalTAdd['id'],
      type: TAddType,
      _,
      meta: { context: ValidationContextType },
    ) => {
      const {
        context,
      } = meta;
      const {
        rootValue,
      } = context;
      const {
        onboarding,
      } = rootValue;
      const {
        personType,
      } = onboarding;
      if (
        type === TAddType.IGNORED
        || (type === TAddType.MORAL && personType !== PersonType.MORAL)) {
        return array()
          .of(string())
          .compact(T)
          .required();
      }
      const {
        users,
        moralPerson,
      } = onboarding;
      const currentTAdd = moralPerson.id !== id ? users.find((user) => user.id === id) : moralPerson;
      const {
        excludedFileKeys = [],
      } = currentTAdd ?? {};
      return array()
        .of(string())
        .compact((v) => excludedFileKeys.includes(v))
        .required(requiredError)
        .min(1, minError);
    },
  ),
  excludedFileKeys: array().of(string()).compact(T).required(),
  MC_CIVILSTATUS: mcCivilStatusValidator,
  significantOther: significantOtherValidator,
  address: addressValidator,
  fiscalAddress: addressValidator,
  secondaryFiscalAddress: addressValidator,
};

export const TAddValidator = object()
  .shape(tAddShape);

const mergeLogic = (acc: Set<string>, key: string): Set<string> => {
  const logic = getColumnData(T_ADD_TABLE_NAME, key)?.validators?.logic;
  const pathsSet = logic ? ouiBase.utils.hookstate.extractPathsFromLogic(logic).filter((path) => !path.includes('<parentName>')) : [];
  return new Set([
    ...acc,
    ...pathsSet,
  ]);
};

const extractParentFieldPaths = () => {
  const keys = [...Object.keys(tAddShape)];
  return keys.reduce(
    (acc, key) => mergeLogic(acc, key),
    new Set([]) as Set<string>,
  );
};

export const makeTAddsValidator = () => {
  const parentFieldPaths = extractParentFieldPaths();
  return mixed().when([...parentFieldPaths], () => array().compact((user) => user === none).of(TAddValidator));
};

const initialAddress = <T extends AdressType>(type: T): Omit<UncastedAddress, 'type'> & { type: T} => ({
  type,
  STREET_1: '',
  ZIP: '',
  CITY: '',
  ID_C_COUNTRY: '',
});

export const initialTAdd = (moral?: boolean): UncastedPhysicalTAdd => {
  const id = uuidv4();
  const base: UncastedPhysicalTAdd = ({
    id,
    fileKeys: [],
    excludedFileKeys: [],
    T_ADD_type: '',
    ID_C_TIT: '',
    NAME_1: '',
    current: false,
    ID_T_NATURE_LRP: '',
    ID_T_PROC_TYPE: `${TProcType.GENERAL}`,
    isTRelMail: false,
    FIRSTNAME: '',
    PHONE: addPhones(id, moral),
    BIRTH_DATE: '',
    isMinor: !moral ? null : false,
    BIRTH_PLACE: '',
    fundsOrigin: {
      savings: '',
      heritage: '',
      donation: '',
      lotteryCasino: '',
    },
    ID_T_ADD_FAMILY_LINK: '',
    ID_C_COUNTRY_BIRTH: '',
    ID_C_COUNTRY_NAT: '',
    nat_REGIST_NUM: '',
    ID_T_ADD_ADDRESS_KEY: '',
    ID_ECPL_PROF: '',
    ID_ECPL_PROF_LAST: '',
    DATE_CESS: '',
    DESC_ECPL_SECTOR: '',
    ID_ECPL_SECTOR: '',
    HadProfession: '',
    MC_MAR_STATUT: '',
    otherConstraints: '',
    MC_CIVILSTATUS: '',
    MC_PEP: '',
    HasSameProfessionAndResidencyCountry: '',
    ID_C_COUNTRY_ACTI: '',
    ID_T_ACC_AYDR: '',
    ID_C_TIT_MORAL: '',
    ID_C_TIT_MORALPrecision: '',
    YN_US: '',
    fiscalResidencySameAsResidency: '',
    TIN_US: '',
    onlyOneFiscalResidency: '',
    TIN_US_SEC: '',
    REGIST_NUM: '',
    MIFID_ID: '',
    significantOther: {
      parentUuid: id,
      FIRSTNAME: '',
      NAME_1: '',
      BIRTH_DATE: '',
    },
    address: initialAddress(AdressType.ADDRESS),
    fiscalAddress: initialAddress(AdressType.FISCAL_ADDRESS),
    secondaryFiscalAddress: initialAddress(AdressType.SECONDARY_FISCAL_ADDRESS),
  });
  return base;
};

export const defaultTAddColumnWhiteList: TAddFieldList = [
  'ID_T_NATURE_LRP',
  'ID_T_PROC_TYPE',
  'ID_C_TIT_MORAL',
  'ID_C_TIT_MORALPrecision',
  'ID_T_ACC_AYDR',
  'ID_C_TIT',
  'FIRSTNAME',
  'NAME_1',
  'BIRTH_DATE',
  'ID_C_COUNTRY_BIRTH',
  'BIRTH_PLACE',
  'ID_C_COUNTRY_NAT',
  'nat_REGIST_NUM',
  T_ADD_EMAIL_PRINCIPAL,
  T_ADD_GSM_PRINCIPAL,
  T_ADD_TEL_PRINCIPAL,
  'ID_T_ADD_ADDRESS_KEY',
  'adressSelector',
  'T_ADD_ADDRESS_STREET_1',
  'T_ADD_ADDRESS_ZIP',
  'T_ADD_ADDRESS_CITY',
  'T_ADD_ADDRESS_ID_C_COUNTRY',
  'REGIST_NUM',
  'MIFID_ID',
  'MC_CIVILSTATUS',
  T_ADD_SIGNIFICANT_OTHER_FIRSTNAME_COLUMN,
  T_ADD_SIGNIFICANT_OTHER_NAME1_COLUMN,
  T_ADD_SIGNIFICANT_OTHER_BIRTH_DATE_COLUMN,
  'ID_T_ADD_FAMILY_LINK',
  'ID_ECPL_PROF',
  'HadProfession',
  'DATE_CESS',
  'ID_ECPL_PROF_LAST',
  'DESC_ECPL_SECTOR',
  'ID_ECPL_SECTOR',
  'HasSameProfessionAndResidencyCountry',
  'ID_C_COUNTRY_ACTI',
  'fundsOrigin',
  'fiscalResidencySameAsResidency',
  'fiscalAdressSelector',
  'T_ADD_FISCAL_ADDRESS_STREET_1',
  'T_ADD_FISCAL_ADDRESS_ZIP',
  'T_ADD_FISCAL_ADDRESS_CITY',
  'T_ADD_FISCAL_ADDRESS_ID_C_COUNTRY',
  'TIN_US',
  'onlyOneFiscalResidency',
  'secondaryFiscalAdressSelector',
  'T_ADD_SECONDARY_FISCAL_ADDRESS_STREET_1',
  'T_ADD_SECONDARY_FISCAL_ADDRESS_ZIP',
  'T_ADD_SECONDARY_FISCAL_ADDRESS_CITY',
  'T_ADD_SECONDARY_FISCAL_ADDRESS_ID_C_COUNTRY',
  'TIN_US_SEC',
  'MC_PEP',
  'MC_MAR_STATUT',
  'otherConstraints',
  'YN_US',
];

export const defaultTAddMoralColumnWhiteList: TAddFieldList = [
  'ID_T_NATURE_LRP',
  'ID_T_PROC_TYPE',
  'ID_C_TIT_MORAL',
  'ID_C_TIT_MORALPrecision',
  'ID_T_ACC_AYDR',
  'ID_C_TIT',
  'FIRSTNAME',
  'NAME_1',
  'BIRTH_DATE',
  'ID_C_COUNTRY_BIRTH',
  'BIRTH_PLACE',
  'ID_C_COUNTRY_NAT',
  'nat_REGIST_NUM',
  T_ADD_EMAIL_PRINCIPAL,
  T_ADD_GSM_PRINCIPAL,
  T_ADD_TEL_PRINCIPAL,
  'ID_T_ADD_ADDRESS_KEY',
  'adressSelector',
  'T_ADD_ADDRESS_STREET_1',
  'T_ADD_ADDRESS_ZIP',
  'T_ADD_ADDRESS_CITY',
  'T_ADD_ADDRESS_ID_C_COUNTRY',
  'REGIST_NUM',
  'MIFID_ID',
  'MC_CIVILSTATUS',
  T_ADD_SIGNIFICANT_OTHER_FIRSTNAME_COLUMN,
  T_ADD_SIGNIFICANT_OTHER_NAME1_COLUMN,
  T_ADD_SIGNIFICANT_OTHER_BIRTH_DATE_COLUMN,
  'ID_T_ADD_FAMILY_LINK',
  'ID_ECPL_PROF',
  'HadProfession',
  'DATE_CESS',
  'ID_ECPL_PROF_LAST',
  'ID_ECPL_SECTOR',
  'DESC_ECPL_SECTOR',
  'HasSameProfessionAndResidencyCountry',
  'ID_C_COUNTRY_ACTI',
  'fundsOrigin',
  'fiscalResidencySameAsResidency',
  'T_ADD_FISCAL_ADDRESS_STREET_1',
  'T_ADD_FISCAL_ADDRESS_ZIP',
  'T_ADD_FISCAL_ADDRESS_CITY',
  'T_ADD_FISCAL_ADDRESS_ID_C_COUNTRY',
  'TIN_US',
  'onlyOneFiscalResidency',
  'T_ADD_SECONDARY_FISCAL_ADDRESS_STREET_1',
  'T_ADD_SECONDARY_FISCAL_ADDRESS_ZIP',
  'T_ADD_SECONDARY_FISCAL_ADDRESS_CITY',
  'T_ADD_SECONDARY_FISCAL_ADDRESS_ID_C_COUNTRY',
  'TIN_US_SEC',
  'MC_PEP',
  'MC_MAR_STATUT',
  'otherConstraints',
  'YN_US',
];

const ActorSections = (props: ParentNameProps & { sections: (keyof typeof geSections)[], filteredList: string[] }) => {
  const { parentName, sections, filteredList } = props;
  const filteredSections = sections.filter((section) => geSections[section].fields.some((field) => filteredList.includes(field)));
  return (
    <ZIndexInvertedChildren>
      {filteredSections.map((sectionId, index) => (
        <GeSection
          key={sectionId}
          parentName={parentName}
          section={sectionId}
          whiteList={filteredList}
          index={index}
        />
      ))}
    </ZIndexInvertedChildren>
  );
};

export const ActorTable = (props: ParentNameProps & {
  tableName: keyof GeTables,
  whiteList?: string[],
  blackList?: string[],
  sections?: (keyof typeof geSections)[],
}): JSX.Element => {
  const {
    tableName,
    parentName,
    whiteList = defaultTAddColumnWhiteList,
    blackList = [],
    sections,
  } = props;
  const filteredList = useFilterColumns(whiteList, blackList, parentName, tableName);
  const children = sections === undefined
    ? (
      <ActorRenderColumns
        parentName={parentName}
        filteredList={filteredList}
      />
    )
    : (
      <ActorSections
        parentName={parentName}
        sections={sections}
        filteredList={filteredList}
      />
    );
  return (
    <TableContextProvider tableName={tableName}>
      <ouiDom.HeadingSection.HeadingSection index={0} name="tAdd">
        {children}
      </ouiDom.HeadingSection.HeadingSection>
    </TableContextProvider>
  );
};

const tAddInputTopModels = {
  columnRender: (
    <TopModel name="columnRender">
      {TAddInput}
    </TopModel>
  ),
};

const MinorUpdater = (props: { userId: number }) => {
  const {
    userId,
  } = props;
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const currentUserState = valueState.onboarding.users[userId];
  const birthdate = useHookstate(currentUserState.BIRTH_DATE).get();
  const currentUserIsMinorState = useHookstate(currentUserState.isMinor);
  useEffect(() => {
    const isMinor = isMinorFromEuroBirthdate(birthdate);
    if (isMinor && currentUserIsMinorState.get() !== true) {
      currentUserIsMinorState.set(true);
    }
    if (!isMinor && currentUserIsMinorState.get() !== false) {
      currentUserIsMinorState.set(false);
    }
  }, [birthdate, currentUserIsMinorState]);
  return null;
};

export const TAddRender = (props: ParentNameProps & { whiteList?: string[], blackList?: string[], sections?: string[], index?: number }) => {
  const { parentName, index } = props;
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  return (
    <>
      {
        parentName.startsWith(valueState.onboarding.users.path.join('.')) && index != null
          ? <TNatureLRPProcTypeUpdater index={index} />
          : null
      }
      {
        index != null
          ? <MinorUpdater userId={index} />
          : null
      }
      <TopModelMerge topModels={tAddInputTopModels}>
        <ActorTable
          {...props}
          tableName={T_ADD_TABLE_NAME}
        />
      </TopModelMerge>
    </>
  );
};

export const CurrentTAdd = (props: { whiteList?: string[], blackList?: string[], sections?: string[] }) => {
  const {
    whiteList,
    blackList,
    sections,
  } = props;
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const currentUserIndex = dataStructure.T_ADD.fields.CURRENT.hooks.useCurrentUserIndex();
  const parentName = valueState.onboarding.users[currentUserIndex].path.join('.');
  return (
    <TAddRender
      parentName={parentName}
      index={currentUserIndex}
      whiteList={whiteList}
      blackList={blackList}
      sections={sections}
    />
  );
};

const elementWidth = ouiStyle.Constants.sizes.averageNameWidth + (2 * ouiStyle.Constants.sizes.space);
const height = `${ouiStyle.Constants.sizes.fullHeight + (4 * (ouiStyle.Constants.sizes.computedLineHeight + ouiStyle.Constants.sizes.space + (2 * ouiStyle.Constants.sizes.borderSize))) + 2 * ouiStyle.Constants.sizes.space}rem`;
const gap = ouiStyle.Constants.sizes.space * 2;

const MoralPersonMandatariesAddText = (): JSX.Element => (
  <Trans>
    Ajouter un mandataire
  </Trans>
);

const TitularsAddText = (): JSX.Element => (
  <Trans>
    Ajouter un titulaire
  </Trans>
);

const ProcurationsAddText = (): JSX.Element => (
  <Trans>
    Ajouter une procuration
  </Trans>
);

const BeneficiariesAddText = (): JSX.Element => (
  <Trans>
    Ajouter un bénéficiaire
  </Trans>
);

const AddText = (): JSX.Element => (
  <>
    <StateFilter is="AML.physic.addTitulars">
      <TitularsAddText />
    </StateFilter>
    <StateFilter is="AML.physic.procurations">
      <ProcurationsAddText />
    </StateFilter>
    <StateFilter is="AML.moral.mandataries">
      <MoralPersonMandatariesAddText />
    </StateFilter>
    <StateFilter is="AML.moral.beneficiaries">
      <BeneficiariesAddText />
    </StateFilter>
  </>
);

const MoralPersonMandatariesRemoveText = (): JSX.Element => (
  <Trans>
    Retirer le mandataire
  </Trans>
);

const TitularsRemoveText = (): JSX.Element => (
  <Trans>
    Retirer le titulaire
  </Trans>
);

const ProcurationsRemoveText = (): JSX.Element => (
  <Trans>
    Retirer la procuration
  </Trans>
);

const BeneficiariesRemoveText = (): JSX.Element => (
  <Trans>
    Retirer le bénéficiaire
  </Trans>
);

const RemoveText = (): JSX.Element => (
  <>
    <StateFilter is="AML.physic.addTitulars">
      <TitularsRemoveText />
    </StateFilter>
    <StateFilter is="AML.physic.procurations">
      <ProcurationsRemoveText />
    </StateFilter>
    <StateFilter is="AML.moral.mandataries">
      <MoralPersonMandatariesRemoveText />
    </StateFilter>
    <StateFilter is="AML.moral.beneficiaries">
      <BeneficiariesRemoveText />
    </StateFilter>
  </>
);

type LinkedPersonProps = {
  addValue: ArrayInputProps['addValue'],
  filterFn?: ArrayInputProps['filterFn'],
  resetUnlessFn?: ArrayInputProps['resetUnlessFn'],
  whiteListColumns?: string[],
  blackListColumns?: string[],
  sections?: string[],
  hasAdd?: boolean,
  hasRemove?: boolean,
  minLength?: number,
}

export const LinkedPersons = (props: React.PropsWithChildren<LinkedPersonProps>): JSX.Element => {
  const {
    addValue,
    filterFn,
    resetUnlessFn = F,
    whiteListColumns = defaultTAddColumnWhiteList,
    blackListColumns,
    sections,
    hasAdd,
    hasRemove,
    minLength,
    children,
  } = props;
  const addValueWithDefault = {
    ...initialTAdd(),
    ...addValue,
  };
  const {
    legend = (
      <Slot slot="legend">
        <FormikTAddIconCardLegend />
      </Slot>
    ),
    addText = (
      <Slot slot="addText">
        <AddText />
      </Slot>
    ),
    removeText = (
      <Slot slot="removeText">
        <RemoveText />
      </Slot>
    ),
    input = (
      <Slot slot="input">
        {(parentName, index) => (
          <TAddRender
            key={parentName}
            parentName={parentName}
            index={index}
            whiteList={whiteListColumns}
            blackList={blackListColumns}
            sections={sections}
          />
        )}
      </Slot>
    ),
  } = useLayout(children);
  return (
    <ArrayInput
      filterFn={filterFn}
      iconName={IconName.User}
      addIconName={IconName.UserPlus}
      height={height}
      elementWidth={elementWidth}
      gap={gap}
      addValue={addValueWithDefault}
      resetUnlessFn={resetUnlessFn}
      hasAdd={hasAdd}
      hasRemove={hasRemove}
      minLength={minLength}
      whiteListColumns={whiteListColumns}
      blackListColumns={blackListColumns}
    >
      {legend}
      {addText}
      {removeText}
      {input}
      {children}
    </ArrayInput>
  );
};

type PersonListProps = Omit<ArrayListInputBaseProps, 'iconName' | 'name'> & Partial<Pick<ArrayListInputBaseProps, 'iconName'>>

export const PersonsList = (props: React.PropsWithChildren<PersonListProps>): JSX.Element => {
  const {
    filterFn,
    iconName = IconName.User,
    cardWidth,
    children,
  } = props;
  return (
    <ArrayListInput
      iconName={iconName}
      filterFn={filterFn}
      cardWidth={cardWidth}
    >
      {children}
    </ArrayListInput>
  );
};

const representative = (): UncastedPhysicalTAdd => {
  const initialTAddValue = initialTAdd();
  return ({
    ...initialTAddValue,
    ID_T_NATURE_LRP: TNatureLrp.REPRESENTANT_LEGAL,
    T_ADD_type: TAddType.ONLY_REPRESENTATIVE,
  });
};

export const useRepresentativeUserIndex = (potentialLength: number) => {
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const tAdds = useHookstate(valueState.onboarding.users);
  const isExistingRepresentative = useHookstate(valueState.onboarding.isRepresentativeExistingTitular).get();
  let index = tAdds.findIndex((tAdd) => isRepresentative(tAdd.get()));
  const currentLength = tAdds.length;
  if ((index === -1 && (isExistingRepresentative === '0' || potentialLength === 0))) {
    tAdds.nested(currentLength).set(representative());
    if (valueState.onboarding.legalRepresentative.get() !== `${currentLength}`) {
      valueState.onboarding.legalRepresentative.set(`${currentLength}`);
    }
    index = currentLength;
  }
  if (isExistingRepresentative === '1' && index !== -1) {
    tAdds.nested(index).set(none);
  }
  return index;
};

export const useUserSelectGetFn = () => {
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const users = useHookstate(valueState.onboarding.users);
  const getFn = useCallback((source: string | { ID: string }, path: SearchKey): string | undefined => {
    const primaryKey = typeof source === 'string' || source == null ? source : source.ID;
    if (isNil(primaryKey)) {
      return '';
    }
    const userState = users.nested(parseInt(primaryKey, 10));
    if (path === 'value') {
      return `${userState.FIRSTNAME.get()} ${userState.NAME_1.get()}`;
    }
    if (path === 'socialForm' || path === 'T_REL_FIN.NUM_FIN' || path === 'C_CURRENCY.CODE' || path === 'LML_DESCRIPTION' || path === 'LML_NATIONALITY') {
      return '';
    }
    return userState.nested(path).get() as string | undefined;
  }, []);
  return getFn;
};

const UserSelectListElementLabel = () => {
  const getFn = useGetGetFn();
  const primaryKey = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const value = getFn(primaryKey, 'value');
  return value;
};

const userSelectListElementLabel = (
  <TopModel name="SelectInputListElementLabelContent">
    {UserSelectListElementLabel}
  </TopModel>
);

export const UserSelect = (props: React.PropsWithChildren<{ filterFn?: (users) => boolean }>) => {
  const {
    filterFn = T,
    children,
  } = props;
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const users = useHookstate(valueState.onboarding.users);
  const list = users.keys.map((key) => `${key}`);
  const useFilterFn = useCallback((listToFilter) => {
    const filtered = useMemo(() => listToFilter.filter((key) => filterFn(users.nested(key).get())), [listToFilter, users.get()]); // eslint-disable-line react-hooks/rules-of-hooks
    return filtered;
  }, [filterFn]);
  return (
    <SelectListInput
      fieldPath={valueState.onboarding.legalRepresentative.path.join('.')}
      list={list}
      searchKeys={['value']}
      useGetFn={useUserSelectGetFn}
      useFilterFn={useFilterFn}
    >
      {userSelectListElementLabel}
      {children}
    </SelectListInput>
  );
};

export const useIsCurrentUserSelected = (): (selectedUserIndex: number | null) => boolean => {
  const currentUserIndex = dataStructure.T_ADD.fields.CURRENT.hooks.useCurrentUserIndex();
  return (selectedUserIndex): boolean => selectedUserIndex === currentUserIndex;
};
