import ouiBase from '@goldwasserexchange/oui';
import { spRatingWithDefaultFromAccountPosition } from '../../utils';
import { useAccountPositionTransformApiByGesEstimDId } from '../api';

export const useAccountPositionsGesRatValSPShortcut = () => {
  const gesEstimDId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const result = useAccountPositionTransformApiByGesEstimDId({
    gesEstimDId,
    transformFunction: spRatingWithDefaultFromAccountPosition,
  });
  return result;
};
