import React from 'react';
import { Trans } from '@lingui/macro';

export const tAddPhoneGsmLabel = (
  <Trans>
    Numéro de téléphone mobile
  </Trans>
);

export const tAddPhoneGsmConfirmLabel = (
  <Trans>
    Confirmation du numéro de téléphone mobile
  </Trans>
);
