import * as React from 'react';
import { RampWithNameTransform, TopModel } from '@goldwasserexchange/react-topmodel';
import ouiBase from '@goldwasserexchange/oui';
import {
  SelectInputListBase,
  DefaultSearchDataProvider,
  DefaultSelectInputBase,
  SelectInputListContextProvider,
  SelectInputListButtonContextProvider,
  DefaultSelectInputListSearchInputContext,
} from '../components';
import { DefaultSelectListInputContextProvider } from '../context';
import { DefaultSelectInputListSearchInputContainer } from '../components/list/listMapper/selected/selected';

export const selectInputDefaultTopModelsObject = {
  SelectInputBase: (
    <TopModel name="SelectInputBase">{DefaultSelectInputBase}</TopModel>
  ),
  SelectInputContextProvider: (
    <TopModel name="InputContextProvider">
      {DefaultSelectListInputContextProvider}
    </TopModel>
  ),
  SelectInputListBase: (
    <TopModel name="SelectInputListBase">{SelectInputListBase}</TopModel>
  ),
  SelectInputListContextContainer: (
    <TopModel name="SelectInputListContextContainer">
      {SelectInputListContextProvider}
    </TopModel>
  ),
  SelectInputListDataProvider: (
    <TopModel name="SelectInputListDataProvider">
      {DefaultSearchDataProvider}
    </TopModel>
  ),
  SelectInputListSearchInputContainer: (
    <TopModel name="SelectInputListSearchInputContainer">
      {DefaultSelectInputListSearchInputContainer}
    </TopModel>
  ),
  SelectInputListCurrentSelectedBase: (
    <TopModel name="SelectInputListCurrentSelectedBase" rampName="ElementBase">
      {RampWithNameTransform}
    </TopModel>
  ),
  SelectInputListSearchInputContext: (
    <TopModel name="SelectInputListSearchInputContext">
      {DefaultSelectInputListSearchInputContext}
    </TopModel>
  ),
  SelectInputListButtonContext: (
    <TopModel name="SelectInputListButtonContext">
      {SelectInputListButtonContextProvider}
    </TopModel>
  ),
  SelectInputListMapper: (
    <TopModel name="SelectInputListMapper">
      {ouiBase.List.DefaultVirtualizedListMapper}
    </TopModel>
  ),
};
