import { TwoDimensionalMatrix, UniDimensionalMatrix } from './types';

/**
 * This function combines two matrices into one matrix.
 * @param {TwoDimensionalMatrix} left The left-hand matrix.
 * @param {UniDimensionalMatrix} right The right-hand matrix.
 * @returns {TwoDimensionalMatrix} A new matrix that combines the two input matrices.
 */
export const combineMatrices = (
  left: TwoDimensionalMatrix,
  right: UniDimensionalMatrix,
): TwoDimensionalMatrix => {
  const rows = right.length;
  const cols = left[0].length;
  const returnMatrix: TwoDimensionalMatrix = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < rows; i++) {
    returnMatrix.push([]);

    // eslint-disable-next-line no-plusplus
    for (let j = 0; j <= cols; j++) {
      if (j === cols) {
        returnMatrix[i][j] = right[i];
      } else {
        returnMatrix[i][j] = left[i][j];
      }
    }
  }

  return returnMatrix;
};
