import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { DomButtonContainer, DomButtonAContainer, DomButtonIconContainer, } from './components';
import { RippleEffect } from '../RippleEffect';
export var buttonDefaultDomTopModelsObject = {
    ButtonContainer: (React.createElement(TopModel, { name: "ButtonContainer" }, DomButtonContainer)),
    ButtonRippleEffect: (React.createElement(TopModel, { name: "ButtonRippleEffect" }, RippleEffect)),
    ButtonIconContainer: (React.createElement(TopModel, { name: "ButtonIconContainer" }, DomButtonIconContainer)),
};
export var buttonADefaultDomTopModelsObject = {
    ButtonContainer: (React.createElement(TopModel, { name: "ButtonContainer" }, DomButtonAContainer)),
};
