import { Locale } from '@goldwasserexchange/i18n';
import Fuse from 'fuse.js';
import { getStorage } from '../../../storage';

const localeArray = Object.values(Locale);

export const languageFuse = new Fuse(localeArray, {
  shouldSort: true,
  threshold: 0.4,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
});

export const isLocale = (locale: string | null | undefined): locale is Locale => locale != null && Object.values(Locale).includes(locale as Locale);

export const getStorageLanguage = () => {
  const localStorage = getStorage('local');
  const rawStorageLanguage = localStorage.getItem('language');
  const storageLanguage = (isLocale(rawStorageLanguage) ? rawStorageLanguage : Locale.FR);
  return storageLanguage;
};

export const setStorageLanguage = (locale: Locale) => {
  const localStorage = getStorage('local');
  localStorage.setItem('language', locale);
};

export const getLanguageRedirectValue = (language: string | undefined) => {
  if (language === '' || language == null) {
    return null;
  }
  if (language === 'fr-BE') {
    return Locale.FR;
  }
  if (language === 'nl-BE') {
    return Locale.NL;
  }
  if (!isLocale(language)) {
    const [localeIndex = 0] = languageFuse.search(language);
    return localeArray[localeIndex];
  }
  return null;
};
