import toPath from 'lodash.topath';
import { Untracked } from '@hookstate/untracked';
export function getInHookState(state, key, untracked, p) {
    if (untracked === void 0) { untracked = false; }
    if (p === void 0) { p = 0; }
    var path = typeof key === 'string' ? toPath(key) : key;
    var s = state;
    while (p < path.length) {
        var currentPath = path[p++]; // eslint-disable-line no-param-reassign,no-plusplus
        var keyIncluded = (s.keys || []).map(function (k) { return k.toString(); }).includes(currentPath);
        if (keyIncluded) {
            s = s.nested(currentPath);
        }
        else {
            throw new Error("there is no initial value on \"".concat(path.join('.'), "\" at \"").concat(currentPath, "\""));
        }
    }
    if (untracked === true) {
        return Untracked(s);
    }
    return s;
}
