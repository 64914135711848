import ouiDom from '@goldwasserexchange/oui-dom';
import ouiStyle from '@goldwasserexchange/oui-style';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import Accounts from '../../../../../accounts';
import { DocumentsGetS3Url } from '../../../../../documents';
import { ButtonContainer } from './buttonContainer';
import { ArDocFileIcon } from '../../fileIcon';
import { ArDocDocumentType } from '../../documentType';
import { DocumentModalContainer } from './container';
import { DocumentModalTitle } from './title';

export const DocumentModal = (props: {
  service: Accounts.History | Accounts.Documents | Accounts.PositionsHistory,
  arDocId?: number,
}) => {
  const {
    service,
    arDocId,
  } = props;
  return (
    <DocumentModalContainer
      service={service}
    >
      <TopModel
        name="ModalIconContent"
        service={service}
      >
        {ArDocFileIcon}
      </TopModel>
      <ouiDom.HeadingSection.HeadingSection
        name="documentModal"
        index={0}
        textAlign="center"
      >
        <ouiDom.TextSnippet.TextSnippet
          color={ouiStyle.Constants.Colors.primary}
        >
          <ArDocDocumentType
            service={service}
          />
        </ouiDom.TextSnippet.TextSnippet>
        <br />
        <ouiDom.TextSnippet.TextSnippet
          color={ouiStyle.Constants.Colors.accent}
        >
          <DocumentModalTitle
            service={service}
          />
        </ouiDom.TextSnippet.TextSnippet>
        <ButtonContainer>
          <DocumentsGetS3Url
            arDocId={arDocId}
          />
        </ButtonContainer>
      </ouiDom.HeadingSection.HeadingSection>
    </DocumentModalContainer>
  );
};
