/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const toolBarListElementStyle: ouiStyle.InStyle = {
  lines: 'full',
  listStyleType: ouiStyle.Constants.ListStyleTypeProps.none,
  flexShrink: true,
  flexGrow: false,
  display: ouiStyle.Constants.DisplayProp.flex,
  justifyContent: ouiStyle.Constants.JustifyContentProp.center,
  backgroundColor: ouiStyle.Constants.Colors.transparent,
};

const toolBarListElementCss = {
  ...ouiStyle.makeCss(toolBarListElementStyle),
  flexBasis: '3.75rem',
};

export const ToolBarListElement = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  return <li css={toolBarListElementCss}>{children}</li>;
};

export default ToolBarListElement;
