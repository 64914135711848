/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiBase from '@goldwasserexchange/oui';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { animated, useSpring } from 'react-spring';
import { useZIndex } from '../../../../../../../../../../OUI/Form';
import {
  InputVariantHooksArray,
  InputVariantsNames,
  useBaseVariant,
  useDisabledVariant,
  useHasFocusVariant,
  useSelectedIndex,
  useValidationVariant,
} from '../../../../../../../../../../OUI/Inputs';
import { useVariantSpring } from '../../../../../../../../utils';
import { defaultSelectInputListElementContainerStyle } from '../container';

const defaultSelectInputListElementSelectedContainerStyle: ouiStyle.InStyle = {
  ...defaultSelectInputListElementContainerStyle,
  borderTopWidth: 'none',
  borderTopStyle: 'none',
  borderTopColor: ouiStyle.Constants.Colors.primary,
  left: 0,
  widthLines: 'full',
  position: 'sticky',
};

const baseDomSelectInputListElementSelectedContainerCss = ouiStyle.makeCssAndExtract(
  defaultSelectInputListElementSelectedContainerStyle,
  [
    'borderBottomWidth',
    'borderBottomStyle',
    'borderBottomColor',
    'borderTopWidth',
    'borderTopStyle',
    'borderTopColor',
  ],
);

const focusDomSelectInputListElementSelectedContainerStyle: ouiStyle.InStyle = {
  ...defaultSelectInputListElementSelectedContainerStyle,
  borderBottomColor: ouiStyle.Constants.Colors.accent,
  borderTopColor: ouiStyle.Constants.Colors.accent,
};

const focusDomSelectInputListElementSelectedContainerCss = ouiStyle.makeCssAndExtract(
  focusDomSelectInputListElementSelectedContainerStyle,
  ['borderBottomColor', 'borderTopColor'],
);

const errorDomSelectInputListElementSelectedContainerStyle: ouiStyle.InStyle = {
  ...defaultSelectInputListElementSelectedContainerStyle,
  borderBottomColor: ouiStyle.Constants.Colors.danger,
  borderTopColor: ouiStyle.Constants.Colors.danger,
};

const errorDomSelectInputListElementSelectedContainerCss = ouiStyle.makeCssAndExtract(
  errorDomSelectInputListElementSelectedContainerStyle,
  ['borderBottomColor', 'borderTopColor'],
);

const disabledDomSelectInputListElementSelectedContainerStyle: ouiStyle.InStyle = {
  ...defaultSelectInputListElementContainerStyle,
  borderBottomColor: ouiStyle.Constants.Colors.disabled,
  borderTopColor: ouiStyle.Constants.Colors.disabled,
};

const disabledDomSelectInputListElementSelectedContainerCss = ouiStyle.makeCssAndExtract(
  disabledDomSelectInputListElementSelectedContainerStyle,
  ['borderBottomColor', 'borderTopColor'],
);

const DomSelectInputListElementSelectedContainerVariants: Record<
InputVariantsNames,
Partial<
Pick<
ouiStyle.Style,
| 'borderBottomWidth'
| 'borderBottomStyle'
| 'borderBottomColor'
| 'borderTopWidth'
| 'borderTopStyle'
| 'borderTopColor'
>
>
> = {
  opened: {},
  closed: {},
  base: baseDomSelectInputListElementSelectedContainerCss,
  hasContent: {},
  noContent: {},
  focus: focusDomSelectInputListElementSelectedContainerCss,
  noFocus: {},
  untouched: {},
  valid: {},
  error: errorDomSelectInputListElementSelectedContainerCss,
  disabled: disabledDomSelectInputListElementSelectedContainerCss,
  enabled: {},
  checked: {},
  unChecked: {},
  last: {},
  notLast: {},
  before: {},
  inside: {},
  after: {},
  noSelection: {},
  notCorrected: {},
  correct: {},
  incorrect: {},
  correctSelected: {},
  correctNotSelected: {},
  incorrectSelected: {},
  incorrectNotSelected: {},
};

export function useMakeDefaultSelectInputListElementSelectedContainerCss() {
  const lines = ouiBase.List.useListInputElementLines();
  const zIndex = useZIndex();
  const css = React.useMemo(() => ouiStyle.mediaQuery(ouiStyle.makeCss({
    ...defaultSelectInputListElementSelectedContainerStyle,
    lines,
    zIndex,
  })), [lines, zIndex]);
  return css;
}

export const selectInputListElementSelectedContainerHookVariants: InputVariantHooksArray = [
  useBaseVariant,
  useHasFocusVariant,
  useValidationVariant,
  useDisabledVariant,
];

export const useDefaultSelectedInputListElementStyle = () => {
  const selectedIndex = useSelectedIndex();
  const [initialised, setInitialised] = React.useState(false);
  const showedItems = ouiBase.List.useShowedItems();
  const currentElementHeight = ouiBase.List.useListInputElementHeight();
  const [style, springApi] = useSpring<{
    top: number,
    opacity: number,
    immediate: boolean,
  }>(() => ({
    to: [
      {
        top: selectedIndex * currentElementHeight,
        opacity: 0,
        immediate: true,
      },
    ],
  }));
  const scrollTop = ouiBase.List.useScrollTop();
  React.useEffect(() => {
    if (!initialised) {
      springApi.start({
        to: [
          {
            top: selectedIndex * currentElementHeight,
            opacity: 0,
            immediate: true,
          },
        ],
      });
    } else {
      const currentTop = style?.top?.animation?.to;
      const currentOpacity = style?.opacity?.animation?.to;
      const indexHeight = selectedIndex * currentElementHeight;
      const startHeight = scrollTop;
      const endHeight = startHeight + showedItems * currentElementHeight;
      if (indexHeight > startHeight && indexHeight < endHeight) {
        springApi.start({
          to: [
            {
              top: indexHeight - scrollTop,
              opacity: 0,
              immediate: false,
            },
          ],
        });
      } else if (
        indexHeight < startHeight
        && (currentTop !== 0 || currentOpacity === 0)
      ) {
        springApi.start({
          to: [
            { top: -currentElementHeight, opacity: 0, immediate: true },
            { top: 0, opacity: 1, immediate: false },
          ],
        });
      } else if (
        indexHeight > endHeight
        && typeof currentTop === 'number' && (currentTop < (showedItems - 1) * currentElementHeight || currentOpacity === 0)
      ) {
        springApi.start({
          to: [
            { top: showedItems * currentElementHeight, opacity: 0, immediate: true },
            { top: (showedItems - 1) * currentElementHeight, opacity: 1, immediate: false },
          ],
        });
      }
    }
    setInitialised(true);
  }, [springApi.start, selectedIndex, scrollTop, initialised, setInitialised, currentElementHeight, showedItems]);
  return style;
};

export function DefaultSelectInputListElementSelectedContainer(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  const css = useMakeDefaultSelectInputListElementSelectedContainerCss();
  const variantStyle = useVariantSpring(
    DomSelectInputListElementSelectedContainerVariants,
    selectInputListElementSelectedContainerHookVariants,
  );
  const stickyStyle = useDefaultSelectedInputListElementStyle();
  const currentOpacity = stickyStyle?.opacity?.animation?.to;
  const currentTop = stickyStyle?.top?.animation?.to;
  const selectedBorderStyle = React.useMemo(() => ouiStyle.makeCssAndExtract(
    {
      borderBottomWidth: currentOpacity === 1 && currentTop === 0 ? 'simple' : 'none',
      borderBottomStyle: currentOpacity === 1 && currentTop === 0 ? 'solid' : 'none',
      borderTopWidth: currentOpacity === 1 && currentTop !== 0 ? 'simple' : 'none',
      borderTopStyle: currentOpacity === 1 && currentTop !== 0 ? 'solid' : 'none',
    },
    ['borderBottomStyle', 'borderBottomWidth', 'borderTopWidth', 'borderTopStyle'],
  ), [
    currentOpacity,
    currentTop,
  ]);
  const style = React.useMemo(
    () => ({
      ...variantStyle,
      ...stickyStyle,
      ...selectedBorderStyle,
    }),
    [stickyStyle, variantStyle, selectedBorderStyle],
  );
  const [open, setOpen] = ouiBase.List.useSelectOpenStateHandlers();
  const onClick = React.useCallback(() => {
    if (open === true) {
      setOpen(false);
    }
  }, [open, setOpen]);
  return (
    <animated.div css={css} style={style} onClick={onClick}>
      {children}
    </animated.div>
  );
}
