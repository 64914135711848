import * as React from 'react';
import { useAtomValue } from 'jotai';
import { DateDisplay } from '../../../../../../../../DateDisplay';
import { onlineHistoryTimeIntervalEndAtom } from '../atoms';

export const HistoryEndTime = (props: {
  spaceBefore?: boolean,
  spaceAfter?: boolean,
}) => {
  const {
    spaceBefore,
    spaceAfter,
  } = props;
  const endDate = useAtomValue(onlineHistoryTimeIntervalEndAtom);
  return (
    <DateDisplay
      date={endDate}
      spaceBefore={spaceBefore}
      spaceAfter={spaceAfter}
    />
  );
};
