import * as React from 'react';
import { ToastBar, Toast } from 'react-hot-toast';
import { GEToastDismiss } from './components';

export const GEToastBar = (props: {
  toast: Toast,
}) => {
  const {
    toast: t,
  } = props;
  return (
    <ToastBar toast={t}>
      {({ icon, message }) => (
        <>
          {icon}
          {message}
          {t.type !== 'loading' && (
            <GEToastDismiss toast={t} />
          )}
        </>
      )}
    </ToastBar>
  );
};
