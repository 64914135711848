import React from 'react';
import { Trans } from '@lingui/macro';
import { dataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import { FilterCurrentUser } from '../../../Sections/onboarding/form/constants';

export const ONLY_ONE_FISCAL_RES_KEY = 'onlyOneFiscalResidency';

export const onlyOneFiscalResidencyLabel = (
  <>
    <FilterCurrentUser>
      <Trans>Déclarez-vous n'avoir qu'une seule résidence fiscale</Trans>
    </FilterCurrentUser>
    <FilterCurrentUser isNegative>
      <Trans>La personne déclare n'avoir qu'une seule résidence fiscale</Trans>
    </FilterCurrentUser>
  </>
);

export const tAddOnlyOneFiscalResidencyValidatorLogic = dataStructure.T_ADD.fields.TYPE.logic.makeTAddIsNotMoralOrIgnored({
  truthy: {
    result: 'requiredBoolean',
  },
  falsy: {
    result: 'nilCast',
  },
});
