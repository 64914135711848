import React from 'react';
import { Trans } from '@lingui/macro';
import ouiBase from '@goldwasserexchange/oui';
import { dataStructure } from '@goldwasserexchange/oblis-frontend-utils';

export const tAddFamilyLinkLabel = (
  <Trans>Lien avec le titulaire</Trans>
);

export const tAddFamilyLinkValidatorLogic: ouiBase.utils.hookstate.FormValueFilterLogic<'requiredNumber' | 'nilCast'> = dataStructure.T_ADD.fields.ID_T_ADD_FAMILY_LINK.logic.helpers.makeShowTAddFamillyLinkLogic({
  truthy: {
    result: 'requiredNumber',
  },
  falsy: {
    result: 'nilCast',
  },
});
