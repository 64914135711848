import { makeShowMoralField } from '../../../../personType/logic/show';
import { makeTAddIsNotMoralOrIgnored, } from '../../TYPE/logic';
var onboardingPath = 'onboarding';
var currentPath = 'current';
var isPrintPath = 'isPrint';
var tAddAddressPath = 'ID_T_ADD_ADDRESS_KEY';
export var showLogic = {
    name: "".concat(onboardingPath, ".").concat(isPrintPath),
    is: false,
    alternatives: {
        truthy: makeTAddIsNotMoralOrIgnored({
            truthy: makeShowMoralField({
                visible: {
                    name: "<parentName>.".concat(currentPath),
                    is: true,
                    options: {
                        isNegative: true,
                    },
                    alternatives: {
                        truthy: {
                            name: "<parentName>.".concat(tAddAddressPath),
                            is: '',
                            alternatives: {
                                truthy: {
                                    result: 'visible',
                                },
                                falsy: {
                                    result: 'invisible',
                                },
                            },
                        },
                        falsy: {
                            result: 'invisible',
                        },
                    },
                },
            }),
        }),
        falsy: {
            result: 'invisible',
        },
    },
};
