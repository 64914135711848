import { makeHasExperienceLogicByFinancialProduct } from '../../logic/hasExperience/make';
import { STypeGrp } from '../../../../types';
export * from '../../../financialProducts/fields/BOND/logic';
export function makeHasExperienceLogic(props) {
    var alternatives = props.alternatives;
    return makeHasExperienceLogicByFinancialProduct({
        financialProduct: STypeGrp.OBLIGATIONS,
        alternatives: alternatives,
    });
}
