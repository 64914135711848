/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { useInputContainerRef } from '../../../../../OUI/Inputs';
import { defaultDomInputContainerStyle } from '../../shared';

const defaultDomCaptchaInputContainerStyle: ouiStyle.InStyle = {
  ...defaultDomInputContainerStyle,
  textAlign: 'center',
  paddingVertical: 'simple',
};

const defaultDomCaptchaInputContainerCss = {
  ...ouiStyle.makeCss(defaultDomCaptchaInputContainerStyle),
  '& > div': {
    display: ouiStyle.Constants.DisplayProp.inlineBlock,
  },
};

export function DefaultDomCaptchaInputContainer(props: React.PropsWithChildren<{}>) {
  const { children } = props;
  const inputContainerRef = useInputContainerRef();
  return (
    <section ref={inputContainerRef} css={defaultDomCaptchaInputContainerCss}>
      {children}
    </section>
  );
}
