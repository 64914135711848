import { useCallback } from 'react';
import { useSTypeGrpId } from '../../../../../../../Components/Sections/online/sections/details/positionDetails/sTypeGroupTable/context/sTypeGrpId';
import { STypeGrpIds } from '../../../../../shared/utils/sTypeGrpIds';
import { AccountPosition } from '../../type';

export const filterBySTypeGrpId = (sTypeGrpId: string | null) => (positionLine: AccountPosition) => `${(positionLine['S_TYPE_GRP.ID'] ?? STypeGrpIds.CASH)}` === sTypeGrpId;

export const useFilterBySTypeGrpId = (sTypeGrpId: string | null) => {
  const filterFn = useCallback(filterBySTypeGrpId(sTypeGrpId), [sTypeGrpId]);
  return filterFn;
};

export const useFilterByContextSTypeGrpId = () => {
  const sTypeGrpId = useSTypeGrpId();
  const filterFn = useFilterBySTypeGrpId(sTypeGrpId);
  return filterFn;
};
