import * as React from 'react';
import { isNil } from 'ramda';
import ouiStyle from '@goldwasserexchange/oui-style';
import { DateDisplay } from '../../../Components/DateDisplay';
import { ActorApiLoadingProvider, useSetActorApiLoading } from '../context';
import { LoadingMask } from './loader';
import { parseActorDate } from '../../api/shared/utils/parseDates';

const ActorApiDateDisplayInner = (props: {
  data: Date | string | undefined | null,
  isLoading: boolean,
  error: any,
  format?: string,
  capitalized?: boolean,
  loadingValue?: Date,
  withSpaceBefore?: boolean,
  withSpaceAfter?: boolean,
  formatMq?: boolean,
  isBlocked?: boolean,
  placeHolderOnBlocked?: boolean,
  withRedactedIcon?: boolean,
  lockBackgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
  lockWidthLines?: ouiStyle.InStyleWithMediaQueries['widthLines'],
  lockContainer?: ouiStyle.InStyleWithMediaQueries['container'],
  lockPaddingTop?: ouiStyle.InStyleWithMediaQueries['paddingTop'],
  lockPaddingBottom?: ouiStyle.InStyleWithMediaQueries['paddingBottom'],
  lockPaddingLeft?: ouiStyle.InStyleWithMediaQueries['paddingLeft'],
  lockPaddingRight?: ouiStyle.InStyleWithMediaQueries['paddingRight'],
  lockAlignItems?: ouiStyle.InStyleWithMediaQueries['alignItems'],
  LockChild?: () => JSX.Element | null,
}) => {
  const {
    data,
    isLoading: dataIsLoading,
    error,
    format,
    capitalized,
    loadingValue = new Date(),
    withSpaceBefore = false,
    withSpaceAfter = false,
    formatMq,
    isBlocked,
    placeHolderOnBlocked = true,
    withRedactedIcon,
    lockBackgroundColor,
    lockWidthLines,
    lockContainer,
    lockPaddingTop,
    lockPaddingBottom,
    lockPaddingLeft,
    lockPaddingRight,
    lockAlignItems,
    LockChild,
  } = props;
  const setActorApiLoading = useSetActorApiLoading();
  const isLoading = dataIsLoading || !isNil(error);
  React.useEffect(() => {
    setActorApiLoading(isLoading);
  }, [isLoading, setActorApiLoading]);
  const parsedDate = typeof data === 'string' ? parseActorDate(data) : data;
  if ((isNil(data) && !isLoading && !isBlocked)) {
    return null;
  }
  return (
    <LoadingMask
      isBlocked={isBlocked}
      withRedactedIcon={withRedactedIcon}
      lockBackgroundColor={lockBackgroundColor}
      lockWidthLines={lockWidthLines}
      lockContainer={lockContainer}
      lockPaddingTop={lockPaddingTop}
      lockPaddingBottom={lockPaddingBottom}
      lockPaddingLeft={lockPaddingLeft}
      lockPaddingRight={lockPaddingRight}
      lockAlignItems={lockAlignItems}
      LockChild={LockChild}
    >
      {withSpaceBefore ? ' ' : ''}
      <DateDisplay
        date={parsedDate ?? loadingValue}
        format={format}
        capitalized={capitalized}
        placeholder={(isBlocked && placeHolderOnBlocked) || isLoading ? '\u2011' : undefined}
        formatMq={formatMq}
      />
      {withSpaceAfter ? ' ' : ''}
    </LoadingMask>
  );
};

export const ActorApiDateDisplay = (props: {
  data: Date | string | undefined | null,
  isLoading: boolean,
  error: any,
  format?: string,
  capitalized?: boolean,
  loadingValue?: Date,
  ownLoadingContext?: boolean,
  initialLoading?: boolean,
  withSpaceBefore?: boolean,
  withSpaceAfter?: boolean,
  formatMq?: boolean,
  isBlocked?: boolean,
  placeHolderOnBlocked?: boolean,
  withRedactedIcon?: boolean,
  lockBackgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
  lockWidthLines?: ouiStyle.InStyleWithMediaQueries['widthLines'],
  lockContainer?: ouiStyle.InStyleWithMediaQueries['container'],
  lockPaddingTop?: ouiStyle.InStyleWithMediaQueries['paddingTop'],
  lockPaddingBottom?: ouiStyle.InStyleWithMediaQueries['paddingBottom'],
  lockPaddingLeft?: ouiStyle.InStyleWithMediaQueries['paddingLeft'],
  lockPaddingRight?: ouiStyle.InStyleWithMediaQueries['paddingRight'],
  lockAlignItems?: ouiStyle.InStyleWithMediaQueries['alignItems'],
  LockChild?: () => JSX.Element | null,
}) => {
  const {
    data,
    isLoading,
    error,
    format,
    capitalized,
    loadingValue,
    ownLoadingContext = true,
    initialLoading,
    withSpaceBefore,
    withSpaceAfter,
    formatMq,
    isBlocked,
    placeHolderOnBlocked,
    withRedactedIcon,
    lockBackgroundColor,
    lockWidthLines,
    lockContainer,
    lockPaddingTop,
    lockPaddingBottom,
    lockPaddingLeft,
    lockPaddingRight,
    lockAlignItems,
    LockChild,
  } = props;
  const inner = (
    <ActorApiDateDisplayInner
      data={data}
      isLoading={isLoading}
      error={error}
      format={format}
      capitalized={capitalized}
      loadingValue={loadingValue}
      withSpaceBefore={withSpaceBefore}
      withSpaceAfter={withSpaceAfter}
      formatMq={formatMq}
      isBlocked={isBlocked}
      placeHolderOnBlocked={placeHolderOnBlocked}
      withRedactedIcon={withRedactedIcon}
      lockBackgroundColor={lockBackgroundColor}
      lockWidthLines={lockWidthLines}
      lockContainer={lockContainer}
      lockPaddingTop={lockPaddingTop}
      lockPaddingBottom={lockPaddingBottom}
      lockPaddingLeft={lockPaddingLeft}
      lockPaddingRight={lockPaddingRight}
      lockAlignItems={lockAlignItems}
      LockChild={LockChild}
    />
  );
  return ownLoadingContext ? (
    <ActorApiLoadingProvider
      initialLoading={initialLoading}
    >
      {inner}
    </ActorApiLoadingProvider>
  ) : inner;
};
