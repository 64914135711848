import * as React from 'react';
import { visibleIndexesRefContext } from './context';

export function VisibleIndexesRefContextProvider(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  const visibleStartIndexRef = React.useRef<number>(0);
  const visibleStopIndexRef = React.useRef<number>(0);
  const visibleIndexes: [
    React.MutableRefObject<number>,
    React.MutableRefObject<number>
  ] = React.useMemo(() => [visibleStartIndexRef, visibleStopIndexRef], []);
  return (
    <visibleIndexesRefContext.Provider value={visibleIndexes}>
      {children}
    </visibleIndexesRefContext.Provider>
  );
}
