export var AccountType;
(function (AccountType) {
    AccountType["RTO"] = "Gold";
    AccountType["CONSEIL"] = "Premium";
    AccountType["GESTION"] = "Global+";
})(AccountType || (AccountType = {}));
export var PersonType;
(function (PersonType) {
    PersonType["INDIVIDUAL"] = "individual";
    PersonType["JOINT"] = "joint";
    PersonType["INDIVISION"] = "indivision";
    PersonType["DIVIDED"] = "divided";
    PersonType["FOR_MINORS"] = "forMinors";
    PersonType["MORAL"] = "moral";
})(PersonType || (PersonType = {}));
export var AdressType;
(function (AdressType) {
    AdressType["ADDRESS"] = "address";
    AdressType["FISCAL_ADDRESS"] = "fiscalAddress";
    AdressType["SECONDARY_FISCAL_ADDRESS"] = "secondaryFiscalAddress";
})(AdressType || (AdressType = {}));
export var GesEvalType;
(function (GesEvalType) {
    GesEvalType[GesEvalType["MAIL"] = 0] = "MAIL";
    GesEvalType[GesEvalType["ONLINE"] = 1] = "ONLINE";
})(GesEvalType || (GesEvalType = {}));
export var TAddType;
(function (TAddType) {
    TAddType["TITULAR"] = "0";
    TAddType["PROCURATION"] = "1";
    TAddType["UBO"] = "2";
    TAddType["MANDATARY"] = "3";
    TAddType["MORAL"] = "4";
    TAddType["ONLY_REPRESENTATIVE"] = "6";
    TAddType["IGNORED"] = "-1";
})(TAddType || (TAddType = {}));
export var CTit;
(function (CTit) {
    CTit[CTit["MONSIEUR"] = 16] = "MONSIEUR";
    CTit[CTit["MADAME"] = 24] = "MADAME";
    CTit[CTit["MADEMOISELLE"] = 63] = "MADEMOISELLE";
    CTit[CTit["ASBL"] = 61] = "ASBL";
    CTit[CTit["SOCIETE"] = 98] = "SOCIETE";
    CTit[CTit["FONDATION_PRIVEE"] = 200] = "FONDATION_PRIVEE";
    CTit[CTit["FONDATION_D_UTILITE_PUBLIQUE"] = 201] = "FONDATION_D_UTILITE_PUBLIQUE";
})(CTit || (CTit = {}));
export var TNatureLrp;
(function (TNatureLrp) {
    TNatureLrp["SOCIETE_SIMPLE"] = "81";
    TNatureLrp["TITULAIRE"] = "1";
    TNatureLrp["REPRESENTANT_LEGAL"] = "2";
    TNatureLrp["INDIVISAIRE"] = "3";
    TNatureLrp["NU_PROPRIETAIRE"] = "4";
    TNatureLrp["USUFRUITIER"] = "5";
    TNatureLrp["TITULAIRE_INCAPABLE"] = "6";
})(TNatureLrp || (TNatureLrp = {}));
export var TProcType;
(function (TProcType) {
    TProcType[TProcType["GENERAL"] = 3] = "GENERAL";
    TProcType[TProcType["CONSULTATION"] = 84] = "CONSULTATION";
    TProcType[TProcType["GESTION"] = 45] = "GESTION";
})(TProcType || (TProcType = {}));
export var EcplProf;
(function (EcplProf) {
    EcplProf[EcplProf["ADMINISTRATOR"] = 1] = "ADMINISTRATOR";
    EcplProf[EcplProf["UNEMPLOYED"] = 2] = "UNEMPLOYED";
    EcplProf[EcplProf["STUDENT"] = 3] = "STUDENT";
    EcplProf[EcplProf["HOME"] = 4] = "HOME";
    EcplProf[EcplProf["PUBLIC_AGENT"] = 5] = "PUBLIC_AGENT";
    EcplProf[EcplProf["INDEPENDANT"] = 6] = "INDEPENDANT";
    EcplProf[EcplProf["LIBERAL_PROFESSION"] = 8] = "LIBERAL_PROFESSION";
    EcplProf[EcplProf["PENSIONER"] = 9] = "PENSIONER";
    EcplProf[EcplProf["EMPLOYEE"] = 10] = "EMPLOYEE";
    EcplProf[EcplProf["RENTIER"] = 11] = "RENTIER";
    EcplProf[EcplProf["WORKER"] = 32] = "WORKER";
    EcplProf[EcplProf["FIDUCIARY"] = 53] = "FIDUCIARY";
    EcplProf[EcplProf["SENIOR_EXECUTIVE"] = 112] = "SENIOR_EXECUTIVE";
})(EcplProf || (EcplProf = {}));
export var EcplSector;
(function (EcplSector) {
    EcplSector[EcplSector["COMMERCE"] = 1] = "COMMERCE";
    EcplSector[EcplSector["BANCASSURANCE"] = 3] = "BANCASSURANCE";
    EcplSector[EcplSector["SECTEUR_PUBLIC"] = 4] = "SECTEUR_PUBLIC";
    EcplSector[EcplSector["FIDUCIAIRE"] = 5] = "FIDUCIAIRE";
    EcplSector[EcplSector["IMMOBILIER"] = 6] = "IMMOBILIER";
    EcplSector[EcplSector["INDUSTRIE"] = 7] = "INDUSTRIE";
    EcplSector[EcplSector["AGRICULTURE"] = 28] = "AGRICULTURE";
    EcplSector[EcplSector["ARTISTIQUE"] = 29] = "ARTISTIQUE";
    EcplSector[EcplSector["CONSTRUCTION"] = 31] = "CONSTRUCTION";
    EcplSector[EcplSector["DIAMANTAIRE"] = 32] = "DIAMANTAIRE";
    EcplSector[EcplSector["ENSEIGNEMENT"] = 33] = "ENSEIGNEMENT";
    EcplSector[EcplSector["HORECA"] = 34] = "HORECA";
    EcplSector[EcplSector["IT_TELECOM"] = 35] = "IT_TELECOM";
    EcplSector[EcplSector["SANS_SECTEUR"] = 37] = "SANS_SECTEUR";
    EcplSector[EcplSector["MEDICAL"] = 38] = "MEDICAL";
    EcplSector[EcplSector["TRANSPORT"] = 39] = "TRANSPORT";
    EcplSector[EcplSector["NON_MARCHAND"] = 108] = "NON_MARCHAND";
    EcplSector[EcplSector["SCIENCES"] = 109] = "SCIENCES";
    EcplSector[EcplSector["SECURITE"] = 110] = "SECURITE";
    EcplSector[EcplSector["CONSULTANCE"] = 111] = "CONSULTANCE";
    EcplSector[EcplSector["ARTISANAT"] = 112] = "ARTISANAT";
    EcplSector[EcplSector["COMMUNICATION"] = 128] = "COMMUNICATION";
    EcplSector[EcplSector["NETTOYAGE"] = 148] = "NETTOYAGE";
    EcplSector[EcplSector["PETROCHIMIE"] = 149] = "PETROCHIMIE";
    EcplSector[EcplSector["COMMERCE_CARTE_TEL"] = 150] = "COMMERCE_CARTE_TEL";
    EcplSector[EcplSector["COMMERCE_MAT_ELECTRO"] = 151] = "COMMERCE_MAT_ELECTRO";
    EcplSector[EcplSector["COMMERCE_OR"] = 152] = "COMMERCE_OR";
    EcplSector[EcplSector["BIJOUTIER"] = 153] = "BIJOUTIER";
    EcplSector[EcplSector["HORLOGER"] = 154] = "HORLOGER";
    EcplSector[EcplSector["CONCESSIONNAIRE"] = 155] = "CONCESSIONNAIRE";
    EcplSector[EcplSector["COMMERCE_ARME"] = 156] = "COMMERCE_ARME";
    EcplSector[EcplSector["COMMERCE_OEUVRE_ART"] = 157] = "COMMERCE_OEUVRE_ART";
    EcplSector[EcplSector["PARIS_EN_LIGNE"] = 158] = "PARIS_EN_LIGNE";
    EcplSector[EcplSector["IMPORT_EXPORT"] = 168] = "IMPORT_EXPORT";
})(EcplSector || (EcplSector = {}));
export var CivilStatus;
(function (CivilStatus) {
    CivilStatus["CELIBATAIRE"] = "101";
    CivilStatus["SEPARE"] = "103";
    CivilStatus["MARIE"] = "102";
    CivilStatus["COHLEG"] = "106";
    CivilStatus["DIVORCE"] = "104";
    CivilStatus["VEUF"] = "105";
})(CivilStatus || (CivilStatus = {}));
export var CTitMoral;
(function (CTitMoral) {
    CTitMoral["GERANT"] = "0";
    CTitMoral["ADMINISTRATEUR"] = "1";
    CTitMoral["ADMINISTRATEUR_DELEGUE"] = "2";
    CTitMoral["DIRECTEUR"] = "3";
    CTitMoral["AUTRE"] = "4";
})(CTitMoral || (CTitMoral = {}));
export var CPhone;
(function (CPhone) {
    CPhone["GSM_PRIVE"] = "32";
    CPhone["EMAIL_NULL"] = "72";
    CPhone["EMAIL_PRINCIPAL"] = "1";
    CPhone["EMAIL_2"] = "2";
    CPhone["FAX_PRINCIPAL"] = "3";
    CPhone["FAX_2"] = "4";
    CPhone["GSM_PRINCIPAL"] = "5";
    CPhone["GSM_2"] = "6";
    CPhone["SITE_WEB"] = "7";
    CPhone["TEL_PRINCIPAL"] = "8";
    CPhone["TEL_2"] = "11";
})(CPhone || (CPhone = {}));
export var FinancialSituationCash;
(function (FinancialSituationCash) {
    FinancialSituationCash[FinancialSituationCash["LESS_THAN_15K"] = 0] = "LESS_THAN_15K";
    FinancialSituationCash[FinancialSituationCash["BETWEEN_15K_AND_40K"] = 15000] = "BETWEEN_15K_AND_40K";
    FinancialSituationCash[FinancialSituationCash["BETWEEN_40K_AND_60K"] = 40000] = "BETWEEN_40K_AND_60K";
    FinancialSituationCash[FinancialSituationCash["ABOVE_60K"] = 60000] = "ABOVE_60K";
})(FinancialSituationCash || (FinancialSituationCash = {}));
export var FinancialSituationMonthlyExpenses;
(function (FinancialSituationMonthlyExpenses) {
    FinancialSituationMonthlyExpenses[FinancialSituationMonthlyExpenses["BETWEEN_1K_AND_2K"] = 2000] = "BETWEEN_1K_AND_2K";
    FinancialSituationMonthlyExpenses[FinancialSituationMonthlyExpenses["BETWEEN_2K_AND_5K"] = 5000] = "BETWEEN_2K_AND_5K";
    FinancialSituationMonthlyExpenses[FinancialSituationMonthlyExpenses["BETWEEN_5K_AND_8K"] = 8000] = "BETWEEN_5K_AND_8K";
    FinancialSituationMonthlyExpenses[FinancialSituationMonthlyExpenses["ABOVE_8K"] = 10000] = "ABOVE_8K";
})(FinancialSituationMonthlyExpenses || (FinancialSituationMonthlyExpenses = {}));
export var FinancialSituationMonthlyIncome;
(function (FinancialSituationMonthlyIncome) {
    FinancialSituationMonthlyIncome[FinancialSituationMonthlyIncome["FROM_0_TO_2K"] = 0] = "FROM_0_TO_2K";
    FinancialSituationMonthlyIncome[FinancialSituationMonthlyIncome["BETWEEN_2K_AND_5K"] = 2000] = "BETWEEN_2K_AND_5K";
    FinancialSituationMonthlyIncome[FinancialSituationMonthlyIncome["BETWEEN_5K_AND_8K"] = 5000] = "BETWEEN_5K_AND_8K";
    FinancialSituationMonthlyIncome[FinancialSituationMonthlyIncome["BETWEEN_8K_AND_10K"] = 8000] = "BETWEEN_8K_AND_10K";
    FinancialSituationMonthlyIncome[FinancialSituationMonthlyIncome["ABOVE_10K"] = 10000] = "ABOVE_10K";
})(FinancialSituationMonthlyIncome || (FinancialSituationMonthlyIncome = {}));
export var FinancialSituationNetWorth;
(function (FinancialSituationNetWorth) {
    FinancialSituationNetWorth[FinancialSituationNetWorth["FROM_0_TO_200K"] = 0] = "FROM_0_TO_200K";
    FinancialSituationNetWorth[FinancialSituationNetWorth["FROM_200K_TO_500K"] = 200000] = "FROM_200K_TO_500K";
    FinancialSituationNetWorth[FinancialSituationNetWorth["FROM_500K_TO_800K"] = 500000] = "FROM_500K_TO_800K";
    FinancialSituationNetWorth[FinancialSituationNetWorth["FROM_800K_TO_1M"] = 800000] = "FROM_800K_TO_1M";
    FinancialSituationNetWorth[FinancialSituationNetWorth["ABOVE_1M"] = 1000000] = "ABOVE_1M";
})(FinancialSituationNetWorth || (FinancialSituationNetWorth = {}));
export var FinancialSituationOwner;
(function (FinancialSituationOwner) {
    FinancialSituationOwner["NO"] = "NO";
    FinancialSituationOwner["IS_OWNER_WITH_CREDIT"] = "IS_OWNER_WITH_CREDIT";
    FinancialSituationOwner["IS_OWNER"] = "IS_OWNER";
})(FinancialSituationOwner || (FinancialSituationOwner = {}));
export var InvestmentGoalsForeignCurrencies;
(function (InvestmentGoalsForeignCurrencies) {
    InvestmentGoalsForeignCurrencies["ALLOW"] = "ALLOW";
    InvestmentGoalsForeignCurrencies["DENY"] = "DENY";
})(InvestmentGoalsForeignCurrencies || (InvestmentGoalsForeignCurrencies = {}));
export var InvestmentGoalsHorizon;
(function (InvestmentGoalsHorizon) {
    InvestmentGoalsHorizon["SHORT"] = "3110";
    InvestmentGoalsHorizon["MEDIUM"] = "3113";
    InvestmentGoalsHorizon["LONG"] = "3111";
})(InvestmentGoalsHorizon || (InvestmentGoalsHorizon = {}));
export var InvestmentGoalsInvestmentGoals;
(function (InvestmentGoalsInvestmentGoals) {
    InvestmentGoalsInvestmentGoals["GROW"] = "GROW";
    InvestmentGoalsInvestmentGoals["PRESERVE"] = "PRESERVE";
    InvestmentGoalsInvestmentGoals["COMPLETE_REVENUE"] = "COMPLETE_REVENUE";
})(InvestmentGoalsInvestmentGoals || (InvestmentGoalsInvestmentGoals = {}));
export var InvestmentGoalsMaxRecoveryPeriod;
(function (InvestmentGoalsMaxRecoveryPeriod) {
    InvestmentGoalsMaxRecoveryPeriod["LESS_THAN_ONE_YEAR"] = "LESS_THAN_ONE_YEAR";
    InvestmentGoalsMaxRecoveryPeriod["BETWEEN_ONE_AND_THREE_YEARS"] = "BETWEEN_ONE_AND_THREE_YEARS";
    InvestmentGoalsMaxRecoveryPeriod["MORE_THAN_THREE_YEARS"] = "MORE_THAN_THREE_YEARS";
})(InvestmentGoalsMaxRecoveryPeriod || (InvestmentGoalsMaxRecoveryPeriod = {}));
export var InvestmentGoalsRiskLevel;
(function (InvestmentGoalsRiskLevel) {
    InvestmentGoalsRiskLevel["LOW"] = "3100";
    InvestmentGoalsRiskLevel["MEDIUM"] = "3101";
    InvestmentGoalsRiskLevel["HIGH"] = "3102";
})(InvestmentGoalsRiskLevel || (InvestmentGoalsRiskLevel = {}));
export var DurabilityPreferences;
(function (DurabilityPreferences) {
    DurabilityPreferences["FALSE"] = "322";
    DurabilityPreferences["NON_SPECIFIC_TRUE"] = "323";
    DurabilityPreferences["SPECIFIC_TRUE"] = "324";
})(DurabilityPreferences || (DurabilityPreferences = {}));
export var DurabilityTaxonomy;
(function (DurabilityTaxonomy) {
    DurabilityTaxonomy["YES"] = "1";
    DurabilityTaxonomy["NO"] = "0";
})(DurabilityTaxonomy || (DurabilityTaxonomy = {}));
export var DurabilityTaxonomyAlignment;
(function (DurabilityTaxonomyAlignment) {
    DurabilityTaxonomyAlignment[DurabilityTaxonomyAlignment["FIVE_PERCENT"] = 5] = "FIVE_PERCENT";
    DurabilityTaxonomyAlignment[DurabilityTaxonomyAlignment["TEN_PERCENT"] = 10] = "TEN_PERCENT";
    DurabilityTaxonomyAlignment[DurabilityTaxonomyAlignment["FIFTEEN_PERCENT"] = 15] = "FIFTEEN_PERCENT";
    DurabilityTaxonomyAlignment[DurabilityTaxonomyAlignment["TWENTY_PERCENT"] = 20] = "TWENTY_PERCENT";
})(DurabilityTaxonomyAlignment || (DurabilityTaxonomyAlignment = {}));
export var DurabilityTaxonomyProportion;
(function (DurabilityTaxonomyProportion) {
    DurabilityTaxonomyProportion[DurabilityTaxonomyProportion["MIN_TWENTY_FIVE_PERCENT"] = 25] = "MIN_TWENTY_FIVE_PERCENT";
    DurabilityTaxonomyProportion[DurabilityTaxonomyProportion["MIN_FIFTY_PERCENT"] = 50] = "MIN_FIFTY_PERCENT";
    DurabilityTaxonomyProportion[DurabilityTaxonomyProportion["MIN_SEVENTY_FIVE_PERCENT"] = 75] = "MIN_SEVENTY_FIVE_PERCENT";
    DurabilityTaxonomyProportion[DurabilityTaxonomyProportion["FULL"] = 100] = "FULL";
})(DurabilityTaxonomyProportion || (DurabilityTaxonomyProportion = {}));
export var DurabilitySFDR;
(function (DurabilitySFDR) {
    DurabilitySFDR["YES"] = "1";
    DurabilitySFDR["NO"] = "0";
})(DurabilitySFDR || (DurabilitySFDR = {}));
export var DurabilitySFDRDurableProportion;
(function (DurabilitySFDRDurableProportion) {
    DurabilitySFDRDurableProportion[DurabilitySFDRDurableProportion["MIN_TWENTY_FIVE_PERCENT"] = 25] = "MIN_TWENTY_FIVE_PERCENT";
    DurabilitySFDRDurableProportion[DurabilitySFDRDurableProportion["MIN_FIFTY_PERCENT"] = 50] = "MIN_FIFTY_PERCENT";
    DurabilitySFDRDurableProportion[DurabilitySFDRDurableProportion["MIN_SEVENTY_FIVE_PERCENT"] = 75] = "MIN_SEVENTY_FIVE_PERCENT";
    DurabilitySFDRDurableProportion[DurabilitySFDRDurableProportion["FULL"] = 100] = "FULL";
})(DurabilitySFDRDurableProportion || (DurabilitySFDRDurableProportion = {}));
export var DurabilitySFDRSocialEnvironmentalProportion;
(function (DurabilitySFDRSocialEnvironmentalProportion) {
    DurabilitySFDRSocialEnvironmentalProportion[DurabilitySFDRSocialEnvironmentalProportion["MIN_TWENTY_FIVE_PERCENT"] = 25] = "MIN_TWENTY_FIVE_PERCENT";
    DurabilitySFDRSocialEnvironmentalProportion[DurabilitySFDRSocialEnvironmentalProportion["MIN_FIFTY_PERCENT"] = 50] = "MIN_FIFTY_PERCENT";
    DurabilitySFDRSocialEnvironmentalProportion[DurabilitySFDRSocialEnvironmentalProportion["MIN_SEVENTY_FIVE_PERCENT"] = 75] = "MIN_SEVENTY_FIVE_PERCENT";
    DurabilitySFDRSocialEnvironmentalProportion[DurabilitySFDRSocialEnvironmentalProportion["FULL"] = 100] = "FULL";
})(DurabilitySFDRSocialEnvironmentalProportion || (DurabilitySFDRSocialEnvironmentalProportion = {}));
export var DurabilitySFDRDurableOnly;
(function (DurabilitySFDRDurableOnly) {
    DurabilitySFDRDurableOnly["YES"] = "1";
    DurabilitySFDRDurableOnly["NO"] = "0";
})(DurabilitySFDRDurableOnly || (DurabilitySFDRDurableOnly = {}));
export var DurabilitySFDRSocialEnvironmentalOnly;
(function (DurabilitySFDRSocialEnvironmentalOnly) {
    DurabilitySFDRSocialEnvironmentalOnly["YES"] = "1";
    DurabilitySFDRSocialEnvironmentalOnly["NO"] = "0";
})(DurabilitySFDRSocialEnvironmentalOnly || (DurabilitySFDRSocialEnvironmentalOnly = {}));
export var DurabilityIncidence;
(function (DurabilityIncidence) {
    DurabilityIncidence["YES"] = "1";
    DurabilityIncidence["NO"] = "0";
})(DurabilityIncidence || (DurabilityIncidence = {}));
export var DurabilityIncidenceType;
(function (DurabilityIncidenceType) {
    DurabilityIncidenceType["GREENHOUSE_GAS"] = "GREENHOUSE_GAS";
    DurabilityIncidenceType["BIODIVERSITY"] = "BIODIVERSITY";
    DurabilityIncidenceType["WATER"] = "WATER";
    DurabilityIncidenceType["TRASH"] = "TRASH";
    DurabilityIncidenceType["SOCIAL_AND_PERSON"] = "SOCIAL_AND_PERSON";
})(DurabilityIncidenceType || (DurabilityIncidenceType = {}));
export var DurabilityIncidenceProportion;
(function (DurabilityIncidenceProportion) {
    DurabilityIncidenceProportion[DurabilityIncidenceProportion["MIN_TWENTY_FIVE_PERCENT"] = 25] = "MIN_TWENTY_FIVE_PERCENT";
    DurabilityIncidenceProportion[DurabilityIncidenceProportion["MIN_FIFTY_PERCENT"] = 50] = "MIN_FIFTY_PERCENT";
    DurabilityIncidenceProportion[DurabilityIncidenceProportion["MIN_SEVENTY_FIVE_PERCENT"] = 75] = "MIN_SEVENTY_FIVE_PERCENT";
    DurabilityIncidenceProportion[DurabilityIncidenceProportion["FULL"] = 100] = "FULL";
})(DurabilityIncidenceProportion || (DurabilityIncidenceProportion = {}));
// TODO: generate and complete in the future
export var CCountry;
(function (CCountry) {
    CCountry["BELGIUM"] = "212";
})(CCountry || (CCountry = {}));
export var Pep;
(function (Pep) {
    Pep["NON"] = "3560";
    Pep["LIE"] = "3562";
    Pep["ASSOCIE"] = "3563";
    Pep["OUI"] = "3561";
})(Pep || (Pep = {}));
export var MarStatut;
(function (MarStatut) {
    MarStatut["AUCUN"] = "3612";
    MarStatut["PCA"] = "3611";
    MarStatut["PDMR"] = "3610";
})(MarStatut || (MarStatut = {}));
export var STypeGrp;
(function (STypeGrp) {
    STypeGrp["OBLIGATIONS"] = "63";
    STypeGrp["ACTIONS"] = "64";
    STypeGrp["FONDS"] = "66";
    STypeGrp["TRACKERS"] = "65";
    STypeGrp["OBLIGATIONS_SUB"] = "124";
    STypeGrp["OBLIGATIONS_PERP"] = "123";
    STypeGrp["TRACKERS_MAT"] = "203";
})(STypeGrp || (STypeGrp = {}));
export var PrRisk;
(function (PrRisk) {
    PrRisk["GOLD1"] = "82";
    PrRisk["GOLD2"] = "102";
    PrRisk["GOLD3"] = "103";
    PrRisk["GOLD4"] = "104";
    PrRisk["GOLD5"] = "105";
})(PrRisk || (PrRisk = {}));
export var OrientationDecisionType;
(function (OrientationDecisionType) {
    OrientationDecisionType["AUTONOMOUS"] = "AUTONOMOUS";
    OrientationDecisionType["RECOMMENDATION"] = "RECOMMENDATION";
    OrientationDecisionType["DELEGATE"] = "DELEGATE";
})(OrientationDecisionType || (OrientationDecisionType = {}));
export var OrientationEvaluationFrequency;
(function (OrientationEvaluationFrequency) {
    OrientationEvaluationFrequency["REGULARLY"] = "REGULARLY";
    OrientationEvaluationFrequency["OCCASIONALY"] = "OCCASIONALY";
    OrientationEvaluationFrequency["RARELY"] = "RARELY";
})(OrientationEvaluationFrequency || (OrientationEvaluationFrequency = {}));
export var OrientationMarketNewsFrequency;
(function (OrientationMarketNewsFrequency) {
    OrientationMarketNewsFrequency["REGULARLY"] = "REGULARLY";
    OrientationMarketNewsFrequency["OCCASIONALY"] = "OCCASIONALY";
    OrientationMarketNewsFrequency["RARELY"] = "RARELY";
})(OrientationMarketNewsFrequency || (OrientationMarketNewsFrequency = {}));
export var OrientationThreeYearsInvestmentPlan;
(function (OrientationThreeYearsInvestmentPlan) {
    OrientationThreeYearsInvestmentPlan[OrientationThreeYearsInvestmentPlan["LESS_THAN_100K"] = 0] = "LESS_THAN_100K";
    OrientationThreeYearsInvestmentPlan[OrientationThreeYearsInvestmentPlan["BETWEEN_100_AND_500K"] = 100000] = "BETWEEN_100_AND_500K";
    OrientationThreeYearsInvestmentPlan[OrientationThreeYearsInvestmentPlan["BETWEEN_500K_AND_1M"] = 500000] = "BETWEEN_500K_AND_1M";
    OrientationThreeYearsInvestmentPlan[OrientationThreeYearsInvestmentPlan["MORE_THAN_1M"] = 1000000] = "MORE_THAN_1M";
})(OrientationThreeYearsInvestmentPlan || (OrientationThreeYearsInvestmentPlan = {}));
