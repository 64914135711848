import { isFuture, isPast } from 'date-fns';
import { parseActorDate } from '../../../shared/utils/parseDates';
import { BondList } from '../../bonds/listOrFavorites/type';
import { BondResponse } from '../../bonds/bond/type';
import { BondSameIssuerResponse } from '../../bonds/sameIssuer/type';

export const isPromoFn = (response: BondResponse | BondList | BondSameIssuerResponse | undefined): boolean => {
  if (!response) {
    return false;
  }
  const isPromo = response['S_ACC.YN_PROMO'];
  const promoStart = parseActorDate(response['S_ACC.DPROMO_DE']);
  const promoEnd = parseActorDate(response['S_ACC.DPROMO_A']);
  return isPromo && isPast(promoStart) && isFuture(promoEnd);
};
