import { parseISO } from 'date-fns';
import speakingurl from 'speakingurl';

export const slugify = (props: {
  locale: string,
  title: string,
  date: string,
  id: string,
}) => {
  const {
    title,
    date: dateString,
    locale,
    id,
  } = props;
  const date = parseISO(dateString);
  const year = date.getFullYear();
  const month = `${date.getMonth() + 1}`.padStart(2, '0');
  const day = `${date.getDate()}`.padStart(2, '0');
  const slugBase = speakingurl(title, { symbols: true, lang: locale });
  return `${slugBase}-${year}-${month}-${day}_${id}`;
};
