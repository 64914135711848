import * as React from 'react';

import { ElementIndexContextProvider } from './Index';

export const ListMap = (props: {
  data: string[],
  renderFilterFn: (dataElement: {}, index: number) => boolean,
  children: (dataElement: string, index: number) => any,
}): JSX.Element => {
  const {
    data = [],
    renderFilterFn,
    children,
  } = props;
  return (
    <>
      {data.map((dataElement, index) => (renderFilterFn && !renderFilterFn(dataElement, index) ? null : (
        <ElementIndexContextProvider index={index}>
          {children(dataElement, index)}
        </ElementIndexContextProvider>
      )))}
    </>
  );
};
