import { useContext } from 'react';
import { popperContainerContext } from './context';

export const usePopperContainerContext = () => useContext(popperContainerContext);

export const usePopperContainerPlacement = () => {
  const [,, attributes] = usePopperContainerContext() ?? [];
  const { 'data-popper-placement': placement = '' } = attributes ?? {};
  return placement;
};

export const usePopperContainerPlacementStart = () => {
  const placement = usePopperContainerPlacement();
  if (placement.startsWith('top')) {
    return 'top';
  }
  if (placement.startsWith('right')) {
    return 'right';
  }
  if (placement.startsWith('bottom')) {
    return 'bottom';
  }
  if (placement.startsWith('left')) {
    return 'left';
  }
  return null;
};
