import { DataPoint } from './types';

/**
 * Sums up all `key` coordinates raised to a power
 *
 * @param {DataPoint} data - An array of data points containing `key` and `value` properties.
 * @param {keyof DataPoint} key - The key of the property to be used in the calculation.
 * @param {number} power - The power to raise each value to.
 * @returns {number} The sum of all `key` coordinates raised to a power.
 */
export const sumPow = (
  data: DataPoint[],
  key: keyof DataPoint,
  power: number,
): number => {
  let sum = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < data.length; i++) {
    const value = data[i][key];
    if (value != null) {
      sum += value ** power;
    }
  }
  return sum;
};
