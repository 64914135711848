import ouiBase from '@goldwasserexchange/oui';
import { computeExRatePerformance } from '../../utils';
import { useAccountPositionTransformApiByGesEstimDId } from '../api';

export const useAccountPositionGesEstimDExRatePerformance = () => {
  const gesEstimDId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const result = useAccountPositionTransformApiByGesEstimDId({
    gesEstimDId,
    transformFunction: computeExRatePerformance,
  });
  return result;
};
