import * as React from 'react';
import { TopModelMergeNameTransformed, } from '@goldwasserexchange/react-topmodel';
import { listContextDefaultTopModelsObject, virtualizedListContextDefaultTopModelsObject } from './topmodels';
export function DefaultListContextTopModelsProvider(props) {
    var children = props.children, order = props.order, transformBeforeFn = props.transformBeforeFn;
    return (React.createElement(TopModelMergeNameTransformed, { topModels: listContextDefaultTopModelsObject, order: order, transformBeforeFn: transformBeforeFn }, children));
}
export function DefaultVirtualizedListContextTopModelsProvider(props) {
    var children = props.children, order = props.order, transformBeforeFn = props.transformBeforeFn;
    return (React.createElement(TopModelMergeNameTransformed, { topModels: virtualizedListContextDefaultTopModelsObject, order: order, transformBeforeFn: transformBeforeFn }, children));
}
