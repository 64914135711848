import { useCallback } from 'react';
import { useOuiStyleMediaContextByIndex } from '../../../../../../../Components/MediaContext';
import { useNumberFormatter } from '../../../../../../../Components/NumberDisplay';
import { BondResponse } from '../../type';
import { useBondsBondFieldApi, useBondsBondFieldTransformFnApi, useBondsBondTransformApi } from '../api';

export const useBondsBondExRateHisExRateGes = () => {
  const response = useBondsBondFieldApi('EXRATE_HIS.EXRATE_GES');
  return response;
};

const invertExRate = (exRate: BondResponse['EXRATE_HIS.EXRATE_GES'] | undefined) => {
  if (!exRate) {
    return undefined;
  }
  return 1 / exRate;
};

export const useBondsBondExRateHisExRateGesInverted = () => {
  const response = useBondsBondFieldTransformFnApi({
    field: 'EXRATE_HIS.EXRATE_GES',
    transformFn: invertExRate,
  });
  return response;
};

const makeToExRateString = (up: boolean, numberFormatter: (value: number, formatOptions?: Partial<Intl.NumberFormatOptions> | undefined) => string) => (bondResponse: BondResponse | undefined) => {
  if (!bondResponse) {
    return undefined;
  }
  const exRate = bondResponse['EXRATE_HIS.EXRATE_GES'];
  const cCurrency = bondResponse['C_CURRENCY.CODE'];
  if (!exRate) {
    return undefined;
  }
  const invertedExRate = invertExRate(exRate);
  if (!invertedExRate) {
    return undefined;
  }
  const formattedBase = numberFormatter(1, {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
  const formattedExRate = numberFormatter(invertedExRate, {
    style: up ? 'currency' : 'decimal',
    ...(up
      ? { currency: cCurrency }
      : {}
    ),
    maximumFractionDigits: 4,
    minimumFractionDigits: 4,
  });
  return up ? `${formattedBase} = ${formattedExRate}` : formattedExRate;
};

export const useBondsBondExRateHisExRateGesInvertedString = (mediaQueryIndex: number) => {
  const up = useOuiStyleMediaContextByIndex(mediaQueryIndex);
  const numberFormatter = useNumberFormatter({});
  const toExRateString = useCallback(
    makeToExRateString(
      up,
      numberFormatter,
    ),
    [
      numberFormatter,
      up,
    ],
  );
  const response = useBondsBondTransformApi(toExRateString);
  return response;
};
