import { fontSize } from '../utils';
export var makeLetterSpacing = function (letterSpacing) {
    if (letterSpacing === 'normal') {
        return letterSpacing;
    }
    if (letterSpacing === '1/2') {
        return "".concat(fontSize / 2, "rem");
    }
    if (letterSpacing === '1/4') {
        return "".concat(fontSize / 4, "rem");
    }
    if (letterSpacing === '1/8') {
        return "".concat(fontSize / 8, "rem");
    }
    throw new Error("letter spacing \"".concat(letterSpacing, "\" doesn't exist"));
};
