import { useFilterByTAccId } from '../../utils/tAcc/filterByTAccId';
import { currencyIsEur } from '../../../../shared';
import { usePersonAccountTransformApi } from '../api';
import { PersonAccounts } from '../../type';

const transformFunction = (personAccounts: PersonAccounts | undefined) => (personAccounts ? currencyIsEur(personAccounts['C_CURRENCY_T_ACC.CODE']) : false);

export const usePersonAccountsCCurrencyTAccCodeIsEur = () => {
  const tAccFilterById = useFilterByTAccId();
  const response = usePersonAccountTransformApi({
    transformFunction,
    filterPredicate: tAccFilterById,
  });
  return response;
};
