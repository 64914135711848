import React, { useContext } from 'react';
import ouiBase from '@goldwasserexchange/oui';
import { MachineContext } from '../context';

export type ElementMatcherOnState = (state: any) => ouiBase.utils.match.ElementMatcher<any>

export const useMatchOnState = (elementMatcher: ElementMatcherOnState, is: string | string[] | ouiBase.utils.match.FindLabelList<any>, options?: ouiBase.utils.match.MatchArrayOptions): boolean | string | null => {
  const [state] = useContext(MachineContext);
  const matches = ouiBase.utils.match.match(elementMatcher(state), is, options);
  return matches;
};

export type MatcherOnStateProps = {
  elementMatcher: ElementMatcherOnState,
  is: string | string[],
  options?: ouiBase.utils.match.MatchArrayOptions,
}

export const MatcherOnState = (props: React.PropsWithChildren<MatcherOnStateProps>): JSX.Element | null => {
  const {
    elementMatcher, is, options, children,
  } = props;
  const matches = useMatchOnState(elementMatcher, is, options);
  return matches
    // eslint-disable-next-line react/jsx-no-useless-fragment
    ? <>{children}</>
    : null;
};
