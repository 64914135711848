/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiBase from '@goldwasserexchange/oui';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

export const defaultListInputListElementButtonPlaceHolderStyle: ouiStyle.InStyle = {
  display: ouiStyle.Constants.DisplayProp.flex,
  flexDirection: ouiStyle.Constants.InFlexDirectionProp.col,
  alignItems: ouiStyle.Constants.AlignItemsProp.center,
  justifyContent: ouiStyle.Constants.JustifyContentProp.center,
  fontSize: 's',
  flex: 'none',
  padding: 'simple',
  baselinePadding: true,
  iconContainer: true,
};

function useMakedefaultListInputListElementButtonPlaceHolderCss() {
  const lines = ouiBase.List.useListInputElementLines();
  const css = React.useMemo(() => ouiStyle.mediaQuery(ouiStyle.makeCss({
    ...defaultListInputListElementButtonPlaceHolderStyle,
    lines,
  })), [lines]);
  return css;
}

export function DefaultListInputListElementButtonPlaceHolder(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  const css = useMakedefaultListInputListElementButtonPlaceHolderCss();
  return <div css={css}>{children}</div>;
}
