import { STypeGrp, } from '../../types';
var initialProductValue = function (sTypeGrp) { return ({
    ID_S_TYPE_GRP: sTypeGrp,
    selected: false,
    experience: '',
    knowledge: null,
    knowledgeQuestionnaires: {
        conseilGestion: {
            success: null,
            lastFailedDate: '',
            failureAndWasRetried: false,
        },
        RTO: {
            success: null,
            lastFailedDate: '',
            failureAndWasRetried: false,
        },
    },
}); };
export var initialValues = function () {
    var _a;
    return (_a = {},
        _a[STypeGrp.OBLIGATIONS] = initialProductValue(STypeGrp.OBLIGATIONS),
        _a[STypeGrp.ACTIONS] = initialProductValue(STypeGrp.ACTIONS),
        _a[STypeGrp.FONDS] = initialProductValue(STypeGrp.FONDS),
        _a[STypeGrp.TRACKERS] = initialProductValue(STypeGrp.TRACKERS),
        _a[STypeGrp.OBLIGATIONS_SUB] = initialProductValue(STypeGrp.OBLIGATIONS_SUB),
        _a[STypeGrp.OBLIGATIONS_PERP] = initialProductValue(STypeGrp.OBLIGATIONS_PERP),
        _a[STypeGrp.TRACKERS_MAT] = initialProductValue(STypeGrp.TRACKERS_MAT),
        _a);
};
