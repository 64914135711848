import * as React from 'react';
import ouiDom from '@goldwasserexchange/oui-dom';
import ouiStyle from '@goldwasserexchange/oui-style';
import { UseRemarkOptions } from 'react-remark';
import rehypeRaw from 'rehype-raw';

export const remarkOptions: UseRemarkOptions = {
  rehypeReactOptions: {
    components: {
      h1: (props) => {
        const {
          children,
        } = props;
        return <ouiDom.HeadingSection.DomH1 color={ouiStyle.Constants.Colors.accent}>{children}</ouiDom.HeadingSection.DomH1>;
      },
      h2: (props) => {
        const {
          children,
        } = props;
        return <ouiDom.HeadingSection.DomH2 lineFactor={1.25} color={ouiStyle.Constants.Colors.accent}>{children}</ouiDom.HeadingSection.DomH2>;
      },
      h3: (props) => {
        const {
          children,
        } = props;
        return <ouiDom.HeadingSection.DomH3 lineFactor={1} color={ouiStyle.Constants.Colors.accent}>{children}</ouiDom.HeadingSection.DomH3>;
      },
      strong: (props) => {
        const {
          children,
        } = props;
        return (
          <ouiDom.TextSnippet.TextSnippet
            fontSize="inherit"
            color={ouiStyle.Constants.Colors.inherit}
            fontWeight={ouiStyle.Constants.FontWeightProp.intermediary}
          >
            {children}
          </ouiDom.TextSnippet.TextSnippet>
        );
      },
      em: (props) => {
        const {
          children,
        } = props;
        return (
          <ouiDom.TextSnippet.TextSnippet
            fontSize="inherit"
            color={ouiStyle.Constants.Colors.inherit}
            fontStyle="italic"
          >
            {children}
          </ouiDom.TextSnippet.TextSnippet>
        );
      },
      p: (props) => {
        const {
          children,
        } = props;
        return <ouiDom.P.P>{children}</ouiDom.P.P>;
      },
      a: (props) => {
        const {
          href,
          children,
        } = props;
        const splittedHref = href.split('/');
        if (href.startsWith('/') && !splittedHref[splittedHref.length - 1].includes('.')) {
          return (
            <ouiDom.A.A
              to={href}
              fontSize="inherit"
              textDecoration="underline"
            >
              {children}
            </ouiDom.A.A>
          );
        }
        return (
          <ouiDom.A.A
            href={href}
            fontSize="inherit"
            textDecoration="underline"
            target="_blank"
          >
            {children}
          </ouiDom.A.A>
        );
      },
    },
  },
  remarkToRehypeOptions: { allowDangerousHtml: true },
  // @ts-expect-error rehypeRaw
  rehypePlugins: [rehypeRaw],
};
