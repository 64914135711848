import pipe from 'ramda/es/pipe';
import {
  UncastedTAddPhone, CPhone, UncastedDataStructure, UncastedPhysicalTAdd, PersonType, TAddType, UncastedOnboarding,
} from '@goldwasserexchange/oblis-frontend-utils';
import {
  makeRequired, stringValidator, makeStringMobilePhoneNumber, makeStringPhoneNumber, requiredEmailWithMaxLengthValidator, emailWithMaxLengthValidator, isMinorFromEuroBirthdate, nilValidator,
} from '../../../../OUI/Inputs';
import { makeWhenAlwaysCast } from '../../../Form/validations/whenAlwaysCast';

const tAddPhoneValidatorDependencyFields: (keyof UncastedTAddPhone | '$')[] = [
  '$',
  'ID_C_PHONE',
];

const makeOptionalTAddPhoneValidator = (_, cPhoneId, schema) => {
  if (
    cPhoneId === CPhone.GSM_PRINCIPAL
    || cPhoneId === CPhone.GSM_PRIVE
    || cPhoneId === CPhone.GSM_2) {
    return makeStringMobilePhoneNumber(schema);
  }
  if (
    cPhoneId === CPhone.TEL_PRINCIPAL
    || cPhoneId === CPhone.FAX_PRINCIPAL
    || cPhoneId === CPhone.TEL_2
    || cPhoneId === CPhone.FAX_2) {
    return makeStringPhoneNumber(schema);
  }
  if (cPhoneId === CPhone.EMAIL_PRINCIPAL || cPhoneId === CPhone.EMAIL_NULL || cPhoneId === CPhone.EMAIL_2) {
    return emailWithMaxLengthValidator(schema);
  }
  return schema;
};

const makeRequiredTAddPhoneValidator = (_, cPhoneId, schema) => {
  if (
    cPhoneId === CPhone.GSM_PRINCIPAL
    || cPhoneId === CPhone.GSM_PRIVE
    || cPhoneId === CPhone.GSM_2) {
    return pipe(
      makeRequired,
      makeStringMobilePhoneNumber,
    )(schema);
  }
  if (
    cPhoneId === CPhone.TEL_PRINCIPAL
    || cPhoneId === CPhone.FAX_PRINCIPAL
    || cPhoneId === CPhone.TEL_2
    || cPhoneId === CPhone.FAX_2) {
    return makeStringPhoneNumber(schema);
  }
  if (cPhoneId === CPhone.EMAIL_PRINCIPAL || cPhoneId === CPhone.EMAIL_NULL || cPhoneId === CPhone.EMAIL_2) {
    return requiredEmailWithMaxLengthValidator(schema);
  }
  return schema;
};

const usersKey: keyof Pick<UncastedOnboarding, 'users'> = 'users';

const birthdateKey: keyof Pick<UncastedPhysicalTAdd, 'BIRTH_DATE'> = 'BIRTH_DATE';

export const useTAddPhoneMakeDependenciesFieldPaths = (parentName: string) => {
  const splittedParentName = parentName.split('.');
  if (splittedParentName[1] === usersKey) {
    return [`${splittedParentName[0]}.${splittedParentName[1]}.${splittedParentName[2]}.${birthdateKey}`];
  }
  return [];
};

export const makeTAddPhoneValidator = (validator) => makeWhenAlwaysCast(tAddPhoneValidatorDependencyFields, (context, cPhoneId, schema, meta) => {
  const {
    parent,
  } = meta as {
    parent: UncastedTAddPhone,
  };
  const {
    moral,
    userUuid,
    ID_C_PHONE,
  } = parent;
  if (moral) {
    return nilValidator;
  }
  const {
    rootValue,
  } = context as {
    rootValue: UncastedDataStructure,
  };
  const tAdd = rootValue.onboarding.users.find((user) => user.id === userUuid);
  const isCurrent = tAdd?.current;
  const isMinor = tAdd?.BIRTH_DATE && isMinorFromEuroBirthdate(tAdd.BIRTH_DATE);
  const shouldBeMinor = rootValue.onboarding.personType === PersonType.FOR_MINORS && tAdd?.T_ADD_type === TAddType.TITULAR;
  if (!isCurrent && ((isMinor || shouldBeMinor) || (tAdd?.T_ADD_type === TAddType.UBO && ID_C_PHONE !== CPhone.EMAIL_PRINCIPAL))) {
    return makeOptionalTAddPhoneValidator(context, cPhoneId, schema);
  }
  return makeRequiredTAddPhoneValidator(context, cPhoneId, schema);
}, validator);

export const TAddPhoneValidator = makeTAddPhoneValidator(stringValidator);
