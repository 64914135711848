import { Action } from 'history';

export const shouldScrollOnPathChange = (props: {
  initialLoad: boolean,
  action: Action,
  previousPathname: string,
  pathname: string,
}) => {
  const {
    initialLoad,
    action,
    previousPathname,
    pathname,
  } = props;
  return !initialLoad
    && action !== Action.Pop
    && previousPathname !== pathname;
};
