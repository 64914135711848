var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { makeName } from '../../../../makeName';
import { PersonType, TAddType, } from '../../../../types';
var tAddTypePath = 'T_ADD_type';
var onboardingPath = 'onboarding';
var personTypePath = 'personType';
export function makeShowMoralField(props) {
    var _a = props.visible, visible = _a === void 0 ? {
        result: 'visible',
    } : _a, _b = props.invisible, invisible = _b === void 0 ? {
        result: 'invisible',
    } : _b, _c = props.parentNameAdditionalPaths, parentNameAdditionalPaths = _c === void 0 ? [] : _c;
    var parentName = makeName.apply(void 0, __spreadArray(['<parentName>'], __read(parentNameAdditionalPaths), false));
    return ({
        name: "".concat(parentName, ".").concat(tAddTypePath),
        is: TAddType.IGNORED,
        options: {
            isNegative: true,
        },
        alternatives: {
            truthy: {
                name: "".concat(parentName, ".").concat(tAddTypePath),
                is: TAddType.MORAL,
                alternatives: {
                    truthy: {
                        name: "".concat(onboardingPath, ".").concat(personTypePath),
                        is: PersonType.MORAL,
                        alternatives: {
                            truthy: visible,
                            falsy: invisible,
                        },
                    },
                    falsy: visible,
                },
            },
            falsy: invisible,
        },
    });
}
