import * as React from 'react';

export const IconContainer = (props: {
  style?: React.SVGAttributes<SVGSVGElement>['style'],
  children: React.ReactNode,
}) => {
  const {
    style,
    children,
  } = props;
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 75 75"
      style={style}
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      width={24}
      height={24}
    >
      {children}
    </svg>
  );
};
