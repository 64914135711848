import * as React from 'react';
import { TopModelMergeNameTransformed, } from '@goldwasserexchange/react-topmodel';
import { listMapperTopModelsObject, virtualizedHorizontalListMapperTopModelsObject, virtualizedListMapperTopModelsObject } from './topmodels';
export function DefaultListMapperTopModelsProvider(props) {
    var children = props.children, order = props.order, transformBeforeFn = props.transformBeforeFn;
    return (React.createElement(TopModelMergeNameTransformed, { topModels: listMapperTopModelsObject, order: order, transformBeforeFn: transformBeforeFn }, children));
}
export function DefaultVirtualizedListMapperTopModelsProvider(props) {
    var children = props.children, order = props.order, transformBeforeFn = props.transformBeforeFn;
    return (React.createElement(TopModelMergeNameTransformed, { topModels: virtualizedListMapperTopModelsObject, order: order, transformBeforeFn: transformBeforeFn }, children));
}
export function DefaultVirtualizedHorizontalListMapperTopModelsProvider(props) {
    var children = props.children, order = props.order, transformBeforeFn = props.transformBeforeFn;
    return (React.createElement(TopModelMergeNameTransformed, { topModels: virtualizedHorizontalListMapperTopModelsObject, order: order, transformBeforeFn: transformBeforeFn }, children));
}
