/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';

const style: ouiStyle.InStyleWithMediaQueries = {
  color: ouiStyle.Constants.Colors.greyDarkest,
  listStyleType: ouiStyle.Constants.ListStyleTypeProps.unordered,
  textAlign: 'justify',
};

const css = {
  ...ouiStyle.mediaQuery(ouiStyle.makeCss(style)),
  lineHeight: 1.5,
};

export const SanityBulletListElement = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  return (
    <li
      css={css}
    >
      {children}
    </li>
  );
};
