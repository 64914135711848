const hasStorage = (name: 'sessionStorage' | 'localStorage') => {
  try {
    const storage = window[name] as Storage;
    storage.setItem('storage.test', '1');
    storage.removeItem('storage.test');
    return true;
  } catch (err) {
    return false;
  }
};

const memoryStorage = () => {
  let storage = {};
  const memStor: Storage = {
    setItem(key, value) {
      storage[key] = value || '';
    },
    getItem(key) {
      return key in storage ? storage[key] : null;
    },
    removeItem(key) {
      delete storage[key];
    },
    get length() {
      return Object.keys(storage).length;
    },
    key(i) {
      const keys = Object.keys(storage);
      return keys[i] || null;
    },
    clear() {
      storage = {};
    },
  };
  return memStor;
};

const storageType: Record<'session' | 'local', Storage> = {
  session: hasStorage('sessionStorage') ? window.sessionStorage : memoryStorage(),
  local: hasStorage('localStorage') ? window.localStorage : memoryStorage(),
};

export const getStorage = (type: 'session' | 'local') => storageType[type];
