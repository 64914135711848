/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const changeButtonContainerStyle: ouiStyle.InStyle = {
  display: ouiStyle.Constants.DisplayProp.flex,
  flexDirection: ouiStyle.Constants.InFlexDirectionProp.row,
  justifyContent: ouiStyle.Constants.JustifyContentProp.center,
  textAlign: 'center',
};

const changeButtonContainerCss = ouiStyle.makeCss(changeButtonContainerStyle);

export const ChangeButtonContainer = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  return (
    <div
      css={changeButtonContainerCss}
    >
      {children}
    </div>
  );
};
