import * as React from 'react';
import { ActorApiTable, ActorApiTableProps } from '../../../../../../components';
import { useHistoryByCurrencyListWithCurrencyAndEndDate } from '../../hooks';
import { hisMvtIdsloadingValues } from '../../../../accounts/history/utils';

export const HistoryByCurrencyTable = (props: React.PropsWithChildren<React.PropsWithChildren<Pick<ActorApiTableProps, 'direction' | 'wrapping' | 'multiBody' | 'secondaryDimensionList'>> & { currency: string }>) => {
  const {
    direction,
    wrapping,
    multiBody,
    secondaryDimensionList,
    currency,
    children,
  } = props;
  const {
    data: sourceData,
    isLoading,
    error,
  } = useHistoryByCurrencyListWithCurrencyAndEndDate(currency);
  const [length, setLength] = React.useState(10);
  const totalLength = sourceData?.length ?? 0;
  React.useEffect(() => {
    setLength(10);
  }, [isLoading]);
  React.useEffect(() => {
    let timeout;
    if (length < totalLength) {
      timeout = setTimeout(() => setLength((l) => l + 10), 100);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [length, setLength, totalLength]);
  const data = React.useMemo(() => {
    if (!sourceData) {
      return sourceData;
    }
    const slicedData = sourceData.slice(0, length);
    return [
      ...(slicedData),
      ...(sourceData.length !== (slicedData?.length ?? -1)
        ? [
          'more',
        ]
        : []
      )];
  }, [sourceData, length]);
  return (
    <ActorApiTable
      data={data}
      isLoading={isLoading}
      error={error}
      loadingValue={hisMvtIdsloadingValues}
      direction={direction}
      wrapping={wrapping}
      multiBody={multiBody}
      secondaryDimensionList={secondaryDimensionList}
    >
      {children}
    </ActorApiTable>
  );
};
