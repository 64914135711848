import { identity } from 'ramda';
import { createContext } from 'react';

export type RifmArgs = {
  accept: RegExp,
  format: (str: string) => string,
  replace?: (str: string) => string,
  append?: (str: string) => string,
  mask?: boolean,
}

export type LocalRifmArgs = Partial<RifmArgs>

export const rifmContext = createContext<RifmArgs>({
  accept: /./g,
  format: identity,
  replace: undefined,
  append: undefined,
  mask: false,
});
