import { atom } from 'jotai';
import { pathnamePartFamilly } from '../../atoms';
import { getValidSection } from './utils';

export const pathnameRawUrlSectionAtom = atom((get) => {
  const section = get(pathnamePartFamilly(1));
  if (section == null) {
    return '';
  }
  return section;
});

export const pathnameUrlSectionAtom = atom((get) => {
  const section = get(pathnameRawUrlSectionAtom);
  return getValidSection(section);
});
