import { useContext } from 'react';
import { useHookstate } from '@hookstate/core';
import { disabledReasonContext } from './context';

export function useDisabledReason() {
  const disabledReason = useContext(disabledReasonContext);
  return useHookstate(disabledReason);
}

export function useFieldDisabledReasonByFieldPath(fieldPath: string) {
  const disabledReason = useDisabledReason();
  const key = disabledReason.keys.findIndex((disabledReasonKey) => disabledReasonKey === fieldPath);
  if (key === -1) {
    disabledReason.nested(fieldPath).set('');
  }
  return useHookstate(disabledReason.nested(fieldPath));
}
