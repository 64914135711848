import * as React from 'react';
import { dataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { Trans } from '@lingui/macro';
import ouiStyle from '@goldwasserexchange/oui-style';
import ouiDom from '@goldwasserexchange/oui-dom';
import { AccountTypePrimaryTitleCellContainer } from '../shared';

const cornerTitleContent = (
  <Trans>
    Comparaison des comptes
  </Trans>
);

export const accountTypePresentationTablePrimaryDimensionTopModels = (
  <>
    <TopModel<Pick<ouiStyle.InStyle, 'fontSize'>>
      name="TablePrimaryTitleCellContainer"
      fontSize="m"
    >
      {AccountTypePrimaryTitleCellContainer}
    </TopModel>
    <TopModel name="TablePrimaryTitleCellContent">
      {dataStructure.accountType.labels.LabelOnPrimaryDimension}
    </TopModel>
    <TopModel<ouiDom.Table.TableBodyRowCellInStyle>
      name="TableHeaderCornerTitleContainer"
      fontSize="m"
      display={[ouiStyle.Constants.DisplayProp.hidden, undefined, ouiStyle.Constants.DisplayProp.tableCell, undefined, undefined, undefined]}
      color={ouiStyle.Constants.Colors.primary}
      textAlign={['center', undefined, 'left', undefined, undefined, undefined]}
      borderBottomColor={[ouiStyle.Constants.Colors.transparent, undefined, undefined, ouiStyle.Constants.Colors.fade, undefined, undefined]}
      borderBottomStyle={['none', undefined, 'solid', undefined, undefined, undefined]}
      borderBottomWidth={['none', undefined, 'simple', undefined, undefined, undefined]}
    >
      {ouiDom.Table.TableHeaderHeading}
    </TopModel>
    <TopModel name="TableHeaderHorizontalWrapperCornerTitleContent">
      {cornerTitleContent}
    </TopModel>

  </>
);
