import * as React from 'react';
import { TopModel, TopModelMerge, TopModelNameTransformProvider } from '@goldwasserexchange/react-topmodel';
import ouiBase from '@goldwasserexchange/oui';
import ouiStyle from '@goldwasserexchange/oui-style';
import {
  unorderedListDefaultTopModelsObject,
  unorderedListWithNoMarkDefaultTopModelsObject,
} from './topmodels';
import {
  DefaultUnorderedListElementWithBar, DefaultUnorderedListElementWithBarAndIcon, DefaultUnorderedListElementWithColoredMarkContainer, DefaultUnorderedListWithNoMarkContainer, IconBase, IconContainer,
} from './components';

export function UnorderedList(
  props: React.PropsWithChildren<{
    list: string[],
    length?: number,
  }>,
) {
  const {
    list, length, children,
  } = props;
  return (
    <TopModelNameTransformProvider transformFn={ouiBase.List.listPrefixer}>
      <ouiBase.List.DefaultListContextTopModelsProvider>
        <ouiBase.List.DefaultListMapperTopModelsProvider>
          <ouiBase.List.DefaultListElementBaseTopModelsProvider>
            <ouiBase.List.DefaultListElementContextTopModelsProvider>
              <TopModelMerge topModels={unorderedListDefaultTopModelsObject}>
                <ouiBase.List.ListStructure
                  list={list}
                  length={length}
                >
                  {children}
                </ouiBase.List.ListStructure>
              </TopModelMerge>
            </ouiBase.List.DefaultListElementContextTopModelsProvider>
          </ouiBase.List.DefaultListElementBaseTopModelsProvider>
        </ouiBase.List.DefaultListMapperTopModelsProvider>
      </ouiBase.List.DefaultListContextTopModelsProvider>
    </TopModelNameTransformProvider>
  );
}

export function UnorderedListWithNoMark(
  props: React.PropsWithChildren<{
    list: string[],
    length?: number,
  }>,
) {
  const {
    list, length, children,
  } = props;
  return (
    <TopModelNameTransformProvider transformFn={ouiBase.List.listPrefixer}>
      <ouiBase.List.DefaultListContextTopModelsProvider>
        <ouiBase.List.DefaultListMapperTopModelsProvider>
          <ouiBase.List.DefaultListElementBaseTopModelsProvider>
            <ouiBase.List.DefaultListElementContextTopModelsProvider>
              <TopModelMerge topModels={unorderedListWithNoMarkDefaultTopModelsObject}>
                <ouiBase.List.ListStructure list={list} length={length}>
                  {children}
                </ouiBase.List.ListStructure>
              </TopModelMerge>
            </ouiBase.List.DefaultListElementContextTopModelsProvider>
          </ouiBase.List.DefaultListElementBaseTopModelsProvider>
        </ouiBase.List.DefaultListMapperTopModelsProvider>
      </ouiBase.List.DefaultListContextTopModelsProvider>
    </TopModelNameTransformProvider>
  );
}

export function UnorderedListWithColoredMark(
  props: React.PropsWithChildren<{
    list: string[],
    length?: number,
    color?: ouiStyle.InStyle['color'],
    fontSize?: ouiStyle.InStyle['fontSize'],
    bulletColor?: ouiStyle.InStyle['color'],
  } & ouiStyle.Constants.InPadding>,
) {
  const {
    list,
    length,
    color,
    fontSize,
    bulletColor,
    padding,
    paddingHorizontal,
    paddingVertical,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    childPaddingTop,
    childPaddingRight,
    childPaddingBottom,
    childPaddingLeft,
    iconContainer,
    baselinePadding,
    children,
  } = props;
  return (
    <TopModelNameTransformProvider transformFn={ouiBase.List.listPrefixer}>
      <ouiBase.List.DefaultListContextTopModelsProvider>
        <ouiBase.List.DefaultListMapperTopModelsProvider>
          <ouiBase.List.DefaultListElementBaseTopModelsProvider>
            <ouiBase.List.DefaultListElementContextTopModelsProvider>
              <TopModelMerge topModels={ouiBase.List.listDefaultTopModelsObject}>
                <ouiBase.List.ListStructure list={list} length={length}>
                  <TopModel
                    name="ListElementContainer"
                    bulletColor={bulletColor}
                    color={color}
                    fontSize={fontSize}
                  >
                    {DefaultUnorderedListElementWithColoredMarkContainer}
                  </TopModel>
                  <TopModel
                    name="ListContainer"
                    padding={padding}
                    paddingHorizontal={paddingHorizontal}
                    paddingVertical={paddingVertical}
                    paddingTop={paddingTop}
                    paddingRight={paddingRight}
                    paddingBottom={paddingBottom}
                    paddingLeft={paddingLeft}
                    childPaddingTop={childPaddingTop}
                    childPaddingRight={childPaddingRight}
                    childPaddingBottom={childPaddingBottom}
                    childPaddingLeft={childPaddingLeft}
                    iconContainer={iconContainer}
                    baselinePadding={baselinePadding}
                  >
                    {DefaultUnorderedListWithNoMarkContainer}
                  </TopModel>
                  {children}
                </ouiBase.List.ListStructure>
              </TopModelMerge>
            </ouiBase.List.DefaultListElementContextTopModelsProvider>
          </ouiBase.List.DefaultListElementBaseTopModelsProvider>
        </ouiBase.List.DefaultListMapperTopModelsProvider>
      </ouiBase.List.DefaultListContextTopModelsProvider>
    </TopModelNameTransformProvider>
  );
}

export function UnorderedListWithBar(
  props: React.PropsWithChildren<{
    list: string[],
    length?: number,
    color?: ouiStyle.InStyle['color'],
    textAlign?: ouiStyle.InStyle['textAlign'],
    fontSize?: ouiStyle.InStyle['fontSize'],
    fontWeight?: ouiStyle.InStyle['fontWeight'],
    barWidthLines?: ouiStyle.InStyle['widthLines'],
    barColor?: ouiStyle.InStyle['borderBottomColor'],
  } & ouiStyle.Constants.InPadding>,
) {
  const {
    list,
    length,
    color,
    textAlign,
    fontSize,
    fontWeight,
    padding,
    paddingHorizontal,
    paddingVertical,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    childPaddingTop,
    childPaddingRight,
    childPaddingBottom,
    childPaddingLeft,
    iconContainer,
    baselinePadding,
    barWidthLines,
    barColor,
    children,
  } = props;
  return (
    <TopModelNameTransformProvider transformFn={ouiBase.List.listPrefixer}>
      <ouiBase.List.DefaultListContextTopModelsProvider>
        <ouiBase.List.DefaultListMapperTopModelsProvider>
          <ouiBase.List.DefaultListElementBaseTopModelsProvider>
            <ouiBase.List.DefaultListElementContextTopModelsProvider>
              <TopModelMerge topModels={ouiBase.List.listDefaultTopModelsObject}>
                <ouiBase.List.ListStructure list={list} length={length}>
                  <TopModel
                    name="ListElementContainer"
                    textAlign={textAlign}
                    fontSize={fontSize}
                    fontWeight={fontWeight}
                    color={color}
                    barWidthLines={barWidthLines}
                    barColor={barColor}
                  >
                    {DefaultUnorderedListElementWithBar}
                  </TopModel>
                  <TopModel
                    name="ListContainer"
                    padding={padding}
                    paddingHorizontal={paddingHorizontal}
                    paddingVertical={paddingVertical}
                    paddingTop={paddingTop}
                    paddingRight={paddingRight}
                    paddingBottom={paddingBottom}
                    paddingLeft={paddingLeft}
                    childPaddingTop={childPaddingTop}
                    childPaddingRight={childPaddingRight}
                    childPaddingBottom={childPaddingBottom}
                    childPaddingLeft={childPaddingLeft}
                    iconContainer={iconContainer}
                    baselinePadding={baselinePadding}
                  >
                    {DefaultUnorderedListWithNoMarkContainer}
                  </TopModel>
                  {children}
                </ouiBase.List.ListStructure>
              </TopModelMerge>
            </ouiBase.List.DefaultListElementContextTopModelsProvider>
          </ouiBase.List.DefaultListElementBaseTopModelsProvider>
        </ouiBase.List.DefaultListMapperTopModelsProvider>
      </ouiBase.List.DefaultListContextTopModelsProvider>
    </TopModelNameTransformProvider>
  );
}

export function UnorderedListWithBarAndIcon(
  props: React.PropsWithChildren<{
    list: string[],
    length?: number,
    color?: ouiStyle.InStyle['color'],
    textAlign?: ouiStyle.InStyle['textAlign'],
    fontSize?: ouiStyle.InStyle['fontSize'],
    fontWeight?: ouiStyle.InStyle['fontWeight'],
    barWidthLines?: ouiStyle.InStyle['widthLines'],
    barColor?: ouiStyle.InStyle['borderBottomColor'],
  } & ouiStyle.Constants.InPadding>,
) {
  const {
    list,
    length,
    color,
    textAlign,
    fontSize,
    fontWeight,
    padding,
    paddingHorizontal,
    paddingVertical,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    childPaddingTop,
    childPaddingRight,
    childPaddingBottom,
    childPaddingLeft,
    iconContainer,
    baselinePadding,
    barWidthLines,
    barColor,
    children,
  } = props;
  return (
    <TopModelNameTransformProvider transformFn={ouiBase.List.listPrefixer}>
      <ouiBase.List.DefaultListContextTopModelsProvider>
        <ouiBase.List.DefaultListMapperTopModelsProvider>
          <ouiBase.List.DefaultListElementBaseTopModelsProvider>
            <ouiBase.List.DefaultListElementContextTopModelsProvider>
              <TopModelMerge topModels={ouiBase.List.listDefaultTopModelsObject}>
                <ouiBase.List.ListStructure list={list} length={length}>
                  <TopModel
                    name="ListElementContainer"
                    textAlign={textAlign}
                    fontSize={fontSize}
                    fontWeight={fontWeight}
                    color={color}
                    barWidthLines={barWidthLines}
                    barColor={barColor}
                  >
                    {DefaultUnorderedListElementWithBarAndIcon}
                  </TopModel>
                  <TopModel
                    name="ListElementIconBase"
                  >
                    {IconBase}
                  </TopModel>
                  <TopModel name="ListElementIconContainer">
                    {IconContainer}
                  </TopModel>
                  <TopModel
                    name="ListContainer"
                    padding={padding}
                    paddingHorizontal={paddingHorizontal}
                    paddingVertical={paddingVertical}
                    paddingTop={paddingTop}
                    paddingRight={paddingRight}
                    paddingBottom={paddingBottom}
                    paddingLeft={paddingLeft}
                    childPaddingTop={childPaddingTop}
                    childPaddingRight={childPaddingRight}
                    childPaddingBottom={childPaddingBottom}
                    childPaddingLeft={childPaddingLeft}
                    iconContainer={iconContainer}
                    baselinePadding={baselinePadding}
                  >
                    {DefaultUnorderedListWithNoMarkContainer}
                  </TopModel>
                  {children}
                </ouiBase.List.ListStructure>
              </TopModelMerge>
            </ouiBase.List.DefaultListElementContextTopModelsProvider>
          </ouiBase.List.DefaultListElementBaseTopModelsProvider>
        </ouiBase.List.DefaultListMapperTopModelsProvider>
      </ouiBase.List.DefaultListContextTopModelsProvider>
    </TopModelNameTransformProvider>
  );
}
