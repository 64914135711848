import { combineMatrices } from './combineMatrices';
import { forwardElimination } from './forwardElimination';
import { TwoDimensionalMatrix, UniDimensionalMatrix } from './types';
import { backwardSubstitution } from './backwardSubstitution';

/**
 * Performs Gaussian-Jordan elimination to solve a system of linear equations.
 *
 * @param {TwoDimensionalMatrix} leftMatrix - The matrix representing the left-hand side of the system of linear equations.
 * @param {UniDimensionalMatrix} rightMatrix - The matrix representing the right-hand side of the system of linear equations.
 * @returns {UniDimensionalMatrix} The matrix containing the solutions to the system of linear equations.
 */
export const gaussianJordanElimination = (
  leftMatrix: TwoDimensionalMatrix,
  rightMatrix: UniDimensionalMatrix,
): UniDimensionalMatrix => {
  const combinedMatrix = combineMatrices(leftMatrix, rightMatrix);
  const forwardIntegrationMatrix = forwardElimination(combinedMatrix);
  const backwardSubstitutionMatrix = backwardSubstitution(
    forwardIntegrationMatrix,
    [],
    forwardIntegrationMatrix.length - 1,
    forwardIntegrationMatrix[0].length - 2,
  );
  return backwardSubstitutionMatrix;
};
