import * as React from 'react';
import { AccountsTwrrTAccTwrrPDate } from '.';
import { useAccountTwrrPNavEnd, useAccountTwrrCCurrencyCode } from '../../hooks';
import { ActorApiNumberDisplayCurrency } from '../../../../../../components/display/number/currency';

export const AccountsTwrrTAccTwrrNavEnd = () => {
  const {
    data,
    isLoading,
    error,
  } = useAccountTwrrPNavEnd();
  const {
    data: currencyData,
    isLoading: currencyIsLoading,
    error: currencyError,
  } = useAccountTwrrCCurrencyCode();
  return (
    <ActorApiNumberDisplayCurrency
      data={data}
      isLoading={isLoading}
      error={error}
      currencyData={currencyData}
      currencyIsLoading={currencyIsLoading}
      currencyError={currencyError}
    />
  );
};

export const AccountsTwrrTAccTwrrPNavEndWIthDate = () => (
  <>
    <AccountsTwrrTAccTwrrNavEnd />
    {' '}
    (
    <AccountsTwrrTAccTwrrPDate />
    )
  </>
);
