/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';

export const ChartLoader = (props: {
  paddingTop?: ouiStyle.InStyleWithMediaQueries['paddingTop'],
  widthLines?: ouiStyle.InStyleWithMediaQueries['widthLines'],
  lines?: ouiStyle.InStyleWithMediaQueries['lines'],
}) => {
  const {
    paddingTop = 'none',
    widthLines = 'full',
    lines = 'full',
  } = props;
  const css = ouiStyle.mediaQuery(ouiStyle.makeCss({
    widthLines,
    lines,
    paddingTop,
  }));
  return (
    <svg css={css} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style={{ display: 'block', margin: 'auto' }}>
      <g transform="rotate(180 50 50)">
        <rect y="15" height="47.401" fill="#b9dbf4" x="15" width="10">
          <animate attributeName="height" calcMode="spline" values="50;70;30;50" keyTimes="0;0.33;0.66;1" dur="1.3" keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1" begin="-0.52s" repeatCount="indefinite" />
        </rect>
        <rect y="15" height="69.8491" fill="#76beea" x="35" width="10">
          <animate attributeName="height" calcMode="spline" values="50;70;30;50" keyTimes="0;0.33;0.66;1" dur="1.3" keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1" begin="-0.26s" repeatCount="indefinite" />
        </rect>
        <rect y="15" height="30.5346" fill="#2a78c0" x="55" width="10">
          <animate attributeName="height" calcMode="spline" values="50;70;30;50" keyTimes="0;0.33;0.66;1" dur="1.3" keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1" begin="-0.78s" repeatCount="indefinite" />
        </rect>
        <rect y="15" height="53.69" fill="#21346c" x="75" width="10">
          <animate attributeName="height" calcMode="spline" values="50;70;30;50" keyTimes="0;0.33;0.66;1" dur="1.3" keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1" begin="0s" repeatCount="indefinite" />
        </rect>
      </g>
    </svg>
  );
};
