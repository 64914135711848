import React from 'react';
import { Trans } from '@lingui/macro';
import ouiBase from '@goldwasserexchange/oui';
import { dataStructure } from '@goldwasserexchange/oblis-frontend-utils';

export const tAddBirthPlaceLabel = <Trans>Lieu de naissance</Trans>;

export const tAddBirthPlaceValidatorLogic: ouiBase.utils.hookstate.FormValueFilterLogic<'requiredString' | 'nilCast'> = dataStructure.T_ADD.fields.TYPE.logic.makeTAddIsNotMoralOrIgnored({
  truthy: {
    result: 'requiredString',
  },
  falsy: {
    result: 'nilCast',
  },
});
