export enum StickyPosition {
  'before',
  'inside',
  'after',
  'noSelection',
}

export type StickyPositionVariants =
  | 'before'
  | 'inside'
  | 'after'
  | 'noSelection';

export const getStickyPositionVariants = (
  stickyPosition: StickyPosition,
): StickyPositionVariants => {
  switch (stickyPosition) {
    case StickyPosition.before:
      return 'before';
    case StickyPosition.inside:
      return 'inside';
    case StickyPosition.after:
      return 'after';
    case StickyPosition.noSelection:
      return 'noSelection';
    default: {
      console.error('sticky position problem'); // eslint-disable-line no-console
      return 'noSelection';
    }
  }
};
