import { useFilterByPrimaryDimensionArDocId } from '../../utils';
import { useAccountDocumentFieldApi } from '../api';

export const useAccountDocumentsArDocDescription = () => {
  const filterPredicate = useFilterByPrimaryDimensionArDocId();
  const result = useAccountDocumentFieldApi({
    field: 'AR_DOC.DESCRIPTION',
    filterPredicate,
  });
  return result;
};
