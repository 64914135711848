import ouiBase from '@goldwasserexchange/oui';
import { useBondSameIssuerFieldApiBySAccId } from '../api';

export const useBondSameIssuerSQuotePriceClose = () => {
  const sAccId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const response = useBondSameIssuerFieldApiBySAccId({
    sAccId,
    field: 'S_QUOTE.PRICE_CLOSE',
  });
  return response;
};
