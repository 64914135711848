export var JustifyContentProp;
(function (JustifyContentProp) {
    JustifyContentProp["normal"] = "normal";
    JustifyContentProp["start"] = "flex-start";
    JustifyContentProp["center"] = "center";
    JustifyContentProp["end"] = "flex-end";
    JustifyContentProp["between"] = "space-between";
    JustifyContentProp["around"] = "space-around";
    JustifyContentProp["stretch"] = "stretch";
})(JustifyContentProp || (JustifyContentProp = {}));
