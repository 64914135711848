import * as React from 'react';
import { IconContainer } from '../container';

export const BondsIcon = (props: {
  style?: React.SVGAttributes<SVGSVGElement>['style'],
}) => {
  const {
    style,
  } = props;
  return (
    <IconContainer
      style={style}
    >
      <path d="M37.4,72.3H12.4c-5.5,0-9.9-4.4-9.9-9.9V12.4C2.5,6.9,6.9,2.5,12.4,2.5h50c5.5,0,9.9,4.4,9.9,9.9v24.2c0,1.4-1.1,2.5-2.5,2.5s-2.5-1.1-2.5-2.5V12.4c0-2.7-2.2-4.9-4.9-4.9H12.4c-2.7,0-4.9,2.2-4.9,4.9v50c0,2.7,2.2,4.9,4.9,4.9h25c1.4,0,2.5,1.1,2.5,2.5s-1.1,2.5-2.5,2.5Z" />
      <g>
        <g>
          <path d="M44.3,55.8h-12.2c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h12.2c1.4,0,2.5,1.1,2.5,2.5s-1.1,2.5-2.5,2.5Z" />
          <path d="M20.3,57.4c-2.3,0-4.1-1.8-4.1-4.1s1.8-4.1,4.1-4.1,4.1,1.8,4.1,4.1-1.8,4.1-4.1,4.1Z" />
        </g>
        <g>
          <path d="M56,39.9h-24c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h24c1.4,0,2.5,1.1,2.5,2.5s-1.1,2.5-2.5,2.5Z" />
          <path d="M20.3,41.4c-2.3,0-4.1-1.8-4.1-4.1s1.8-4.1,4.1-4.1,4.1,1.8,4.1,4.1-1.8,4.1-4.1,4.1Z" />
        </g>
        <g>
          <path d="M56,23.9h-24c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h24c1.4,0,2.5,1.1,2.5,2.5s-1.1,2.5-2.5,2.5Z" />
          <path d="M20.3,25.5c-2.3,0-4.1-1.8-4.1-4.1s1.8-4.1,4.1-4.1,4.1,1.8,4.1,4.1-1.8,4.1-4.1,4.1Z" />
        </g>
      </g>
      <g>
        <path d="M55.6,68.7c-7.3,0-13.2-5.9-13.2-13.2s5.9-13.2,13.2-13.2,13.2,5.9,13.2,13.2-5.9,13.2-13.2,13.2ZM55.6,47.3c-4.5,0-8.2,3.7-8.2,8.2s3.7,8.2,8.2,8.2,8.2-3.7,8.2-8.2-3.7-8.2-8.2-8.2Z" />
        <path d="M69.8,72.3c-.6,0-1.3-.2-1.8-.7l-6.6-6.6c-1-1-1-2.6,0-3.5s2.6-1,3.5,0l6.6,6.6c1,1,1,2.6,0,3.5s-1.1.7-1.8.7h.1Z" />
      </g>
    </IconContainer>
  );
};
