import { UncastedPhysicalTAdd, TAddType } from '@goldwasserexchange/oblis-frontend-utils';
import ouiBase from '@goldwasserexchange/oui';

export { tAddPhoneEmailLabel } from '../../tAddPhone';

const tAddType: keyof Pick<UncastedPhysicalTAdd, 'T_ADD_type'> = 'T_ADD_type';

export const tAddIdTAddPhoneValidatorLogic: ouiBase.utils.hookstate.FormValueFilterLogic<'noTAddPhones' | 'tAddPhones'> = {
  name: `<parentName>.${tAddType}`,
  is: [TAddType.IGNORED, TAddType.MORAL],
  options: {
    matcher: ouiBase.utils.match.arrayMatcherNames.some,
  },
  alternatives: {
    truthy: {
      result: 'noTAddPhones',
    },
    falsy: {
      result: 'tAddPhones',
    },
  },
};

export const T_ADD_GSM_PRINCIPAL = 'T_ADD_GSM_PRINCIPAL';

export const T_ADD_GSM_PRINCIPAL_CONFIRM = 'T_ADD_GSM_PRINCIPAL_CONFIRM';

export const T_ADD_EMAIL_PRINCIPAL = 'T_ADD_EMAIL_PRINCIPAL';

export const T_ADD_EMAIL_PRINCIPAL_CONFIRM = 'T_ADD_EMAIL_PRINCIPAL_CONFIRM';

export const T_ADD_TEL_PRINCIPAL = 'T_ADD_TEL_PRINCIPAL';

export const T_ADD_TEL_PRINCIPAL_CONFIRM = 'T_ADD_TEL_PRINCIPAL_CONFIRM';
