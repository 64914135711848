import { basePlusLineHeight, lineHeight, mediumLineHeight, mediumPlusLineHeight, smallerLineHeight, smallestLineHeight, } from '../utils';
var createLineHeigtMap = function () {
    var newLineHeightMap = new Map();
    newLineHeightMap.set('xxxs', smallestLineHeight);
    newLineHeightMap.set('xxs', smallerLineHeight);
    newLineHeightMap.set('xs', lineHeight);
    newLineHeightMap.set('s', basePlusLineHeight);
    newLineHeightMap.set('m', mediumLineHeight);
    newLineHeightMap.set('xm', mediumPlusLineHeight);
    return newLineHeightMap;
};
export var lineHeightMap = createLineHeigtMap();
