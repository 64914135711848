import * as React from 'react';
import { bannerHeightContext } from './context';

export const BannerHeightProvider = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  const heightState = React.useState(0);
  return (
    <bannerHeightContext.Provider value={heightState}>
      {children}
    </bannerHeightContext.Provider>
  );
};
