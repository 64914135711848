import { makeIdTNatureLrpDependentLogic } from '../../ID_T_NATURE_LRP/logic/helpers';
import { makeTAddIsTypePhysicLogic } from '../../TYPE/logic';
import { TAddType, TNatureLrp } from '../../../../../types';
export function makeShowIdTProcTypeLogic(props) {
    var _a = props.truthy, truthy = _a === void 0 ? {
        result: 'visible',
    } : _a, _b = props.falsy, falsy = _b === void 0 ? {
        result: 'invisible',
    } : _b;
    return makeTAddIsTypePhysicLogic({
        alternatives: {
            truthy: makeIdTNatureLrpDependentLogic({
                is: TNatureLrp.TITULAIRE_INCAPABLE,
                options: { isNegative: true },
                truthy: {
                    name: '<parentName>.isMinor',
                    is: false,
                    alternatives: {
                        truthy: truthy,
                        falsy: falsy,
                    },
                },
                falsy: falsy,
            }),
            falsy: falsy,
        },
        exclude: [TAddType.ONLY_REPRESENTATIVE],
    });
}
