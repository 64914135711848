import { useContext, useMemo } from 'react';
import { GetAccountsCashflowsParams } from '@goldwasserexchange/actor/rest-services';
import { useTAccId } from '../../../shared';
import { stringifyParams } from '../../../shared/utils/makeUrl';
import { accountsCashflowsParamsContext } from '../context';
import { useIsConnected } from '../../../../../../Components/Auth';

export const useAccountCashflowsDefaultParams = (params: Partial<GetAccountsCashflowsParams>): Partial<GetAccountsCashflowsParams> => {
  const {
    'T_ACC.ID': contextTAccId,
    'CPS_HIS.DETACHE_DATE_END': contextCpsHisDetacheDateEnd,
    LIMIT: contextLimit,
    NO_ZERO: contextNoZero,
  } = useContext(accountsCashflowsParamsContext);
  const tAccIdString = useTAccId();
  const currentTAccId = tAccIdString ? parseInt(tAccIdString, 10) : undefined;
  const tAccId = params['T_ACC.ID'] ?? contextTAccId ?? currentTAccId;
  const cpsHisDetacheDateEnd = params['CPS_HIS.DETACHE_DATE_END'] ?? contextCpsHisDetacheDateEnd;
  const limit = params.LIMIT ?? contextLimit;
  const noZero = params.NO_ZERO ?? contextNoZero;
  const defaultParams: Partial<GetAccountsCashflowsParams> = useMemo(() => ({
    'T_ACC.ID': tAccId,
    'CPS_HIS.DETACHE_DATE_END': cpsHisDetacheDateEnd,
    LIMIT: limit,
    NO_ZERO: noZero,
  }), [
    tAccId,
    cpsHisDetacheDateEnd,
    limit,
    noZero,
  ]);
  return defaultParams;
};

const useAccountCashflowsParams = (): GetAccountsCashflowsParams | null => {
  const defaultParams = useAccountCashflowsDefaultParams({});
  const tAccId = defaultParams['T_ACC.ID'];
  const isConnected = useIsConnected();
  if (tAccId == null || !isConnected) {
    return null;
  }
  return {
    'T_ACC.ID': tAccId,
    LIMIT: defaultParams.LIMIT,
    'CPS_HIS.DETACHE_DATE_END': defaultParams['CPS_HIS.DETACHE_DATE_END'],
    NO_ZERO: defaultParams.NO_ZERO,
  };
};

export const useAccountCashflowsStringifiedParams = () => {
  const contextParams = useAccountCashflowsParams();
  return contextParams !== null ? stringifyParams(contextParams) : null;
};
