import ouiStyle from '@goldwasserexchange/oui-style';
import { isNil } from 'ramda';
import * as React from 'react';
import { NumberDisplay } from '../../../../Components/NumberDisplay';
import { useSetActorApiLoading, ActorApiLoadingProvider } from '../../context';
import { LoadingMask } from '../loader';

const ActorApiNumberDisplayCurrencyInner = (
  props: {
    data: number | undefined | null,
    isLoading: boolean,
    error: any,
    currencyData: string | undefined | null,
    currencyIsLoading: boolean,
    currencyError: any,
    format?: Partial<Intl.NumberFormatOptions>,
    forcedSigned?: boolean,
    loadingValue?: number,
    loadingCurrency?: string,
    withSpaceAfter?: boolean,
    withSpaceBefore?: boolean,
    isBlocked?: boolean,
    placeHolderOnBlocked?: boolean,
    withRedactedIcon?: boolean,
    lockBackgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
    lockWidthLines?: ouiStyle.InStyleWithMediaQueries['widthLines'],
    lockContainer?: ouiStyle.InStyleWithMediaQueries['container'],
    lockPaddingTop?: ouiStyle.InStyleWithMediaQueries['paddingTop'],
    lockPaddingBottom?: ouiStyle.InStyleWithMediaQueries['paddingBottom'],
    lockPaddingLeft?: ouiStyle.InStyleWithMediaQueries['paddingLeft'],
    lockPaddingRight?: ouiStyle.InStyleWithMediaQueries['paddingRight'],
    lockAlignItems?: ouiStyle.InStyleWithMediaQueries['alignItems'],
    LockChild?: () => JSX.Element | null,
  },
) => {
  const {
    data,
    isLoading: dataIsLoading,
    error,
    currencyData,
    currencyIsLoading,
    currencyError,
    format,
    forcedSigned,
    loadingValue = 0,
    loadingCurrency = 'EUR',
    withSpaceBefore = false,
    withSpaceAfter = false,
    isBlocked,
    placeHolderOnBlocked = true,
    withRedactedIcon,
    lockBackgroundColor,
    lockWidthLines,
    lockContainer,
    lockPaddingTop,
    lockPaddingBottom,
    lockPaddingLeft,
    lockPaddingRight,
    lockAlignItems,
    LockChild,
  } = props;
  const isLoading = (dataIsLoading || error || currencyIsLoading || currencyError);
  const setActorApiLoading = useSetActorApiLoading();
  React.useEffect(() => {
    setActorApiLoading(isLoading);
  }, [isLoading, setActorApiLoading]);
  if ((isNil(data) && !isLoading)) {
    return null;
  }
  return (
    <LoadingMask
      isBlocked={isBlocked}
      withRedactedIcon={withRedactedIcon}
      lockBackgroundColor={lockBackgroundColor}
      lockWidthLines={lockWidthLines}
      lockContainer={lockContainer}
      lockPaddingTop={lockPaddingTop}
      lockPaddingBottom={lockPaddingBottom}
      lockPaddingLeft={lockPaddingLeft}
      lockPaddingRight={lockPaddingRight}
      lockAlignItems={lockAlignItems}
      LockChild={LockChild}
    >
      {withSpaceBefore ? ' ' : ''}
      <NumberDisplay
        value={data ?? loadingValue}
        format={{
          ...format,
          style: 'currency',
          currency: currencyData ?? loadingCurrency,
        }}
        forcedSigned={forcedSigned}
        placeholder={(isBlocked && placeHolderOnBlocked) || isLoading ? '\u2011' : undefined}
      />
      {withSpaceAfter ? ' ' : ''}
    </LoadingMask>
  );
};

export const ActorApiNumberDisplayCurrency = (
  props: {
    data: number | undefined | null,
    isLoading: boolean,
    error: any,
    currencyData: string | undefined | null,
    currencyIsLoading: boolean,
    currencyError: any,
    format?: Omit<Partial<Intl.NumberFormatOptions>, 'style'>,
    forcedSigned?: boolean,
    loadingValue?: number,
    loadingCurrency?: string,
    ownLoadingContext?: boolean,
    initialLoading?: boolean,
    withSpaceAfter?: boolean,
    withSpaceBefore?: boolean,
    isBlocked?: boolean,
    withRedactedIcon?: boolean,
    lockBackgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
    lockWidthLines?: ouiStyle.InStyleWithMediaQueries['widthLines'],
    lockContainer?: ouiStyle.InStyleWithMediaQueries['container'],
    lockPaddingTop?: ouiStyle.InStyleWithMediaQueries['paddingTop'],
    lockPaddingBottom?: ouiStyle.InStyleWithMediaQueries['paddingBottom'],
    lockPaddingLeft?: ouiStyle.InStyleWithMediaQueries['paddingLeft'],
    lockPaddingRight?: ouiStyle.InStyleWithMediaQueries['paddingRight'],
    lockAlignItems?: ouiStyle.InStyleWithMediaQueries['alignItems'],
    LockChild?: () => JSX.Element | null,
  },
) => {
  const {
    data,
    isLoading,
    error,
    currencyData,
    currencyIsLoading,
    currencyError,
    format,
    forcedSigned,
    loadingValue,
    loadingCurrency,
    ownLoadingContext = true,
    initialLoading,
    withSpaceBefore,
    withSpaceAfter,
    isBlocked,
    withRedactedIcon,
    lockBackgroundColor,
    lockWidthLines,
    lockContainer,
    lockPaddingTop,
    lockPaddingBottom,
    lockPaddingLeft,
    lockPaddingRight,
    lockAlignItems,
    LockChild,
  } = props;
  const inner = (
    <ActorApiNumberDisplayCurrencyInner
      data={data}
      isLoading={isLoading}
      error={error}
      currencyData={currencyData}
      currencyIsLoading={currencyIsLoading}
      currencyError={currencyError}
      format={format}
      forcedSigned={forcedSigned}
      loadingValue={loadingValue}
      loadingCurrency={loadingCurrency}
      withSpaceBefore={withSpaceBefore}
      withSpaceAfter={withSpaceAfter}
      isBlocked={isBlocked}
      withRedactedIcon={withRedactedIcon}
      lockBackgroundColor={lockBackgroundColor}
      lockWidthLines={lockWidthLines}
      lockContainer={lockContainer}
      lockPaddingTop={lockPaddingTop}
      lockPaddingBottom={lockPaddingBottom}
      lockPaddingLeft={lockPaddingLeft}
      lockPaddingRight={lockPaddingRight}
      lockAlignItems={lockAlignItems}
      LockChild={LockChild}
    />
  );
  return ownLoadingContext ? (
    <ActorApiLoadingProvider
      initialLoading={initialLoading}
    >
      {inner}
    </ActorApiLoadingProvider>
  ) : inner;
};
