import { useContext } from 'react';
import { useCurrentRenderDirection } from '../../directionDimension/current/hooks';
import { primaryDimensionDirectionContext } from './context';
export var usePrimaryDimensionDirection = function () {
    var primaryDimensionDirection = useContext(primaryDimensionDirectionContext);
    return primaryDimensionDirection;
};
export var useCurrentDimensionNameByRenderDirection = function (renderDirection) {
    var currentDirection = usePrimaryDimensionDirection();
    if (renderDirection === currentDirection) {
        return 'Primary';
    }
    return 'Secondary';
};
export var useCurrentDimensionNameByDirectionAndRenderDirection = function () {
    var renderDirection = useCurrentRenderDirection();
    return useCurrentDimensionNameByRenderDirection(renderDirection);
};
