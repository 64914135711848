/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Trans } from '@lingui/macro';
import ouiStyle from '@goldwasserexchange/oui-style';
import ouiDom from '@goldwasserexchange/oui-dom';
import { ActionFilter, useSendOnClick } from '../../../../../Machine';

const touchText = (
  <Trans>
    Modifier mes réponses
  </Trans>
);

type ModifyAnswersProps = {
  state: string,
}

const knowledgeQuestionnaireStyle: ouiStyle.InStyle = {
  display: ouiStyle.Constants.DisplayProp.flex,
  flexDirection: ouiStyle.Constants.InFlexDirectionProp.row,
  justifyContent: ouiStyle.Constants.JustifyContentProp.center,
};

const knowledgeQuestionnaireCss = ouiStyle.makeCss(knowledgeQuestionnaireStyle);

export const ModifyAnswers = (props: ModifyAnswersProps): JSX.Element => {
  const {
    state,
  } = props;
  const onClick = useSendOnClick(state);
  return (
    <div
      css={knowledgeQuestionnaireCss}
    >
      <ActionFilter is={state}>
        <ouiDom.Button.Button
          type="button"
          onClick={onClick}
        >
          {touchText}
        </ouiDom.Button.Button>
      </ActionFilter>
    </div>
  );
};
