import ouiDom from '@goldwasserexchange/oui-dom';
import ouiStyle from '@goldwasserexchange/oui-style';
import { Trans } from '@lingui/macro';
import * as React from 'react';
import { ArrowLeft } from 'react-feather';

export const BackButton = (props: {
  onClick: React.MouseEventHandler<HTMLButtonElement>,
}) => {
  const {
    onClick,
  } = props;
  return (
    <ouiDom.Button.Button
      type="button"
      onClick={onClick}
      position="absolute"
      color={ouiStyle.Constants.Colors.primary}
      top={0}
      left={0}
      paddingLeft="simple"
      paddingRight="simple"
      paddingTop="simple"
      paddingBottom="simple"
      borderWidth="none"
      iconContainer

    >
      <ArrowLeft />
      {' '}
      <Trans>
        Retour
      </Trans>
    </ouiDom.Button.Button>
  );
};
