import { RampWithNameTransform } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import {
  requiredError,
  nilError,
  booleanError,
  booleanIsTrueError,
  booleanIsFalseError,
  euroDateError,
  useFirstError,
  dateMinorError,
  notInThePastError,
  notInTheFutureError,
  notANumberError,
  numberMinError,
  numberMinRefError,
  numberMaxError,
  numberMaxRefError,
  numberMoreThanError,
  numberMoreThanRefError,
  numberLessThanError,
  numberLessThanRefError,
  numberPositiveError,
  numberNegativeError,
  notAStringError,
  minError,
  maxError,
  lengthError,
  onlyNumbersError,
  hasAtLeastOneDigitError,
  hasAtLeastOneLowerCaseError,
  hasAtLeastOneUpperCaseError,
  hasAtLeastOneSpecialCharError,
  phoneError,
  emailError,
  equalsRefError,
  tvaNumberError,
  nationalRegisterError,
  nationalRegisterAndBirthdateMismatch,
  invalidBirthdateError,
  multiChoiceAtLeastOne,
  equalsError,
  dateNotMinorError,
  wrongLeiFormatError,
  invalidLeiError,
  invalidDurabilityError,
} from '../../../../../context';

export function DefaultInputFooterErrorContent() {
  const error = useFirstError();
  switch (error) {
    case requiredError: {
      return <RampWithNameTransform rampName="InputRequiredError" />;
    }
    case equalsError: {
      return <RampWithNameTransform rampName="InputEqualsError" />;
    }
    case nilError: {
      return <RampWithNameTransform rampName="InputNotNilError" />;
    }
    case booleanError: {
      return <RampWithNameTransform rampName="InputBooleanError" />;
    }
    case booleanIsTrueError: {
      return <RampWithNameTransform rampName="InputBooleanIsTrueError" />;
    }
    case booleanIsFalseError: {
      return <RampWithNameTransform rampName="InputBooleanIsFalseError" />;
    }
    case euroDateError: {
      return <RampWithNameTransform rampName="InputNotAEuroDateError" />;
    }
    case dateMinorError: {
      return <RampWithNameTransform rampName="InputDateMinorError" />;
    }
    case dateNotMinorError: {
      return <RampWithNameTransform rampName="InputDateNotMinorError" />;
    }
    case notInThePastError: {
      return <RampWithNameTransform rampName="InputNotInThePastError" />;
    }
    case notInTheFutureError: {
      return <RampWithNameTransform rampName="InputNotInTheFutureError" />;
    }
    case notANumberError: {
      return <RampWithNameTransform rampName="InputNotANumberError" />;
    }
    case numberMinError: {
      return <RampWithNameTransform rampName="InputNumberMinError" />;
    }
    case numberMinRefError: {
      return <RampWithNameTransform rampName="InputNumberMinRefError" />;
    }
    case numberMaxError: {
      return <RampWithNameTransform rampName="InputNumberMaxError" />;
    }
    case numberMaxRefError: {
      return <RampWithNameTransform rampName="InputNumberMaxRefError" />;
    }
    case numberMoreThanError: {
      return <RampWithNameTransform rampName="InputNumberMoreThanError" />;
    }
    case numberMoreThanRefError: {
      return <RampWithNameTransform rampName="InputNumberMoreThanRefError" />;
    }
    case numberLessThanError: {
      return <RampWithNameTransform rampName="InputNumberLessThanError" />;
    }
    case numberLessThanRefError: {
      return <RampWithNameTransform rampName="InputNumberLessThanRefError" />;
    }
    case numberPositiveError: {
      return <RampWithNameTransform rampName="InputNumberPositiveError" />;
    }
    case numberNegativeError: {
      return <RampWithNameTransform rampName="InputNumberNegativeError" />;
    }
    case notAStringError: {
      return <RampWithNameTransform rampName="InputNotAStringError" />;
    }
    case minError: {
      return <RampWithNameTransform rampName="InputMinError" />;
    }
    case maxError: {
      return <RampWithNameTransform rampName="InputMaxError" />;
    }
    case lengthError: {
      return <RampWithNameTransform rampName="InputLengthError" />;
    }
    case onlyNumbersError: {
      return <RampWithNameTransform rampName="InputOnlyNumbersError" />;
    }
    case hasAtLeastOneDigitError: {
      return <RampWithNameTransform rampName="InputHasAtLeastOneDigitError" />;
    }
    case hasAtLeastOneLowerCaseError: {
      return <RampWithNameTransform rampName="InputHasAtLeastOneLowerCaseError" />;
    }
    case hasAtLeastOneUpperCaseError: {
      return <RampWithNameTransform rampName="InputHasAtLeastOneUpperCaseError" />;
    }
    case hasAtLeastOneSpecialCharError: {
      return <RampWithNameTransform rampName="InputHasAtLeastOneSpecialCharError" />;
    }
    case phoneError: {
      return <RampWithNameTransform rampName="InputPhoneError" />;
    }
    case emailError: {
      return <RampWithNameTransform rampName="InputEmailError" />;
    }
    case equalsRefError: {
      return <RampWithNameTransform rampName="InputEqualsRefError" />;
    }
    case wrongLeiFormatError: {
      return <RampWithNameTransform rampName="InputWrongLeiFormatError" />;
    }
    case invalidLeiError: {
      return <RampWithNameTransform rampName="InputInvalidLeiError" />;
    }
    case tvaNumberError: {
      return <RampWithNameTransform rampName="InputTvaNumberError" />;
    }
    case nationalRegisterError: {
      return <RampWithNameTransform rampName="InputNationalRegisterError" />;
    }
    case nationalRegisterAndBirthdateMismatch: {
      return <RampWithNameTransform rampName="InputNationalRegisterAndBirthdateMismatchError" />;
    }
    case invalidBirthdateError: {
      return <RampWithNameTransform rampName="InputInvalidBirthdateError" />;
    }
    case multiChoiceAtLeastOne: {
      return <RampWithNameTransform rampName="InputMultiChoiceAtLeatOneError" />;
    }
    case invalidDurabilityError: {
      return <RampWithNameTransform rampName="InputInvalidDurabilityError" />;
    }
    default: {
      return error;
    }
  }
}
