import * as React from 'react';
import {
  Place,
  SearchForSuggestionsResult,
} from '@aws-sdk/client-location';
import { SearchPlaceResultElementContainer } from './components';
import { LoadingMask } from '../../../../../../actor/components/display/loader';
import { ActorApiLoadingProvider, useSetActorApiLoading } from '../../../../../../actor/components/context';
import { tryGetPlaceFetcher } from '../../../../../../aws';
import { usePlaceIndexName } from '../../../../../Auth';
import { useLinguiLocale } from '../../../../../useLinguiLocale';

export const SearchPlaceResultElementInner = (props: {
  text: SearchForSuggestionsResult['Text'],
  placeId: SearchForSuggestionsResult['PlaceId'],
  isLoading: boolean,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  focus: boolean,
  onSelect: (result: Place) => void,
}) => {
  const {
    text,
    placeId,
    isLoading,
    setOpen,
    focus,
    onSelect,
  } = props;
  const setActorApiLoading = useSetActorApiLoading();
  React.useEffect(() => {
    setActorApiLoading(isLoading);
  }, [isLoading, setActorApiLoading]);
  const placeIndexName = usePlaceIndexName();
  const locale = useLinguiLocale();
  const onClick = React.useCallback<NonNullable<React.DOMAttributes<HTMLLIElement>['onClick']>>(async () => {
    if (!isLoading && placeIndexName != null && placeId != null) {
      const place = await tryGetPlaceFetcher({ placeIndexName, placeId, locale });
      if (place) {
        onSelect(place);
        setOpen(false);
      }
    }
  }, [placeId, setOpen, isLoading, onSelect, placeIndexName]);
  return (
    <SearchPlaceResultElementContainer
      onClick={onClick}
      focus={focus}
    >
      <LoadingMask>
        {text}
      </LoadingMask>
    </SearchPlaceResultElementContainer>
  );
};

export const SearchPlaceResultElement = (props: {
  text: SearchForSuggestionsResult['Text'],
  placeId: SearchForSuggestionsResult['PlaceId'],
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  focus: boolean,
  onSelect: (result: Place) => void,
}) => {
  const {
    text,
    placeId,
    setOpen,
    focus,
    onSelect,
  } = props;
  const isLoading = placeId?.startsWith('loading-') ?? false;
  return (
    <ActorApiLoadingProvider
      initialLoading={isLoading}
    >
      <SearchPlaceResultElementInner
        text={text}
        placeId={placeId}
        isLoading={isLoading}
        setOpen={setOpen}
        focus={focus}
        onSelect={onSelect}
      />
    </ActorApiLoadingProvider>
  );
};
