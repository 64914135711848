/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import { useMakeDefaultListInputListElementContainerCss } from './containerStyle';

export function DefaultListInputListElementContainer(
  props: React.PropsWithChildren<{ style?: React.CSSProperties }>,
) {
  const { style, children } = props;
  const css = useMakeDefaultListInputListElementContainerCss();
  return (
    <li css={css} style={style}>
      {children}
    </li>
  );
}
