/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const ContainerStyle: ouiStyle.InStyle = {
  display: ouiStyle.Constants.DisplayProp.flex,
  container: ouiStyle.Constants.Container.sm,
  alignItems: ouiStyle.Constants.AlignItemsProp.center,
  paddingTop: 'simple',
  paddingBottom: 'quadruple',
};

const ContainerCss = ouiStyle.makeCss(ContainerStyle);

export const Container = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  return (
    <div
      css={ContainerCss}
    >
      {children}
    </div>
  );
};
