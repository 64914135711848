import * as React from 'react';
import { useHookstate } from '@hookstate/core';
import { disabledPreviousValueContext } from './context';

export const FormDisabledPreviousValueContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  const disabledPreviousValueState = useHookstate<Record<string, string>>({});
  return (
    <disabledPreviousValueContext.Provider value={disabledPreviousValueState}>
      {children}
    </disabledPreviousValueContext.Provider>
  );
};
