import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { ActorApiStringDisplay } from '../../../../../../components/display/string';
import { useBondSameIssuerGesRatValSpShortcut, useBondsSameIssuerIsRedacted } from '../../hooks';

export const BondsSameIssuersGesRatValSpShortcut = (props: {
  withRedactedIcon?: boolean,
  lockWidthLines?: ouiStyle.InStyleWithMediaQueries['widthLines'],
}) => {
  const {
    lockWidthLines,
    withRedactedIcon,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useBondSameIssuerGesRatValSpShortcut();
  const {
    data: redacted,
    isLoading: redactedIsLoading,
  } = useBondsSameIssuerIsRedacted('GES_RAT_VAL_SP.SHORTCUT');
  return (
    <ActorApiStringDisplay
      data={data}
      isLoading={isLoading || redactedIsLoading}
      error={error}
      loadingValue="---"
      isBlocked={redacted}
      withRedactedIcon={withRedactedIcon}
      lockWidthLines={lockWidthLines}
    />
  );
};
