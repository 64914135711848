import ouiDom from '@goldwasserexchange/oui-dom';
import ouiStyle from '@goldwasserexchange/oui-style';
import { Trans } from '@lingui/macro';
import {
  isNil,
} from 'ramda';
import * as React from 'react';
import { ChartLoader } from '../../../../../../Components/chartLoader';
import { useDocumentsGetS3Url } from '../hooks';
import { DocumentGetS3ParamsProvider } from './paramsProvider';

const DocumentsGetS3UrlInner = () => {
  const {
    data: url,
    isLoading,
    error,
  } = useDocumentsGetS3Url();
  if (isLoading || error) {
    return (
      <ChartLoader
        widthLines="1/2"
        lines="1/2"
      />
    );
  }
  if (isNil(url)) {
    return (
      <Trans>
        Ce document n'est plus disponible. Veuillez nous contacter.
      </Trans>
    );
  }
  return (
    <>
      <ouiDom.A.AButton
        href={url}
        target="_blank"
        display={ouiStyle.Constants.DisplayProp.block}
        backgroundColor={ouiStyle.Constants.Colors.accent}
        color={ouiStyle.Constants.Colors.inverted}
        hoverBackgroundColor={ouiStyle.Constants.Colors.accentShaded}
      >
        <Trans>
          Télécharger
        </Trans>
      </ouiDom.A.AButton>
      <ouiDom.P.P
        textAlign="center"
      >
        <Trans>
          Cliquez pour télécharger le document
        </Trans>
      </ouiDom.P.P>
    </>
  );
};

export const DocumentsGetS3Url = (props: {
  arDocId?: number,
}) => {
  const {
    arDocId,
  } = props;
  const params = React.useMemo(() => ({
    'AR_DOC.ID': arDocId,
  }), [arDocId]);
  return arDocId != null ? (
    <DocumentGetS3ParamsProvider
      params={params}
    >
      <DocumentsGetS3UrlInner />
    </DocumentGetS3ParamsProvider>
  ) : null;
};
