import { RampWithNameTransform, TopModelChildrenMerge, } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
export function ListStructure(props) {
    var _a = props.list, list = _a === void 0 ? [] : _a, length = props.length, children = props.children;
    return (React.createElement(TopModelChildrenMerge, null,
        children,
        React.createElement(RampWithNameTransform, { rampName: "Base", list: list, length: length })));
}
export function VirtualizedListStructure(props) {
    var _a = props.list, list = _a === void 0 ? [] : _a, length = props.length, showedItems = props.showedItems, _b = props.elementLines, elementLines = _b === void 0 ? 1 : _b, _c = props.elementWidthLines, elementWidthLines = _c === void 0 ? 1 : _c, _d = props.elementChildPaddingTop, elementChildPaddingTop = _d === void 0 ? 'none' : _d, _e = props.elementChildPaddingRight, elementChildPaddingRight = _e === void 0 ? 'none' : _e, _f = props.elementChildPaddingBottom, elementChildPaddingBottom = _f === void 0 ? 'none' : _f, _g = props.elementChildPaddingLeft, elementChildPaddingLeft = _g === void 0 ? 'none' : _g, children = props.children;
    return (React.createElement(TopModelChildrenMerge, null,
        children,
        React.createElement(RampWithNameTransform, { rampName: "Base", list: list, length: length, showedItems: showedItems, elementLines: elementLines, elementWidthLines: elementWidthLines, elementChildPaddingTop: elementChildPaddingTop, elementChildPaddingRight: elementChildPaddingRight, elementChildPaddingBottom: elementChildPaddingBottom, elementChildPaddingLeft: elementChildPaddingLeft })));
}
