import { EcplSector } from '../../../../types';
export var list = [
    EcplSector.COMMERCE,
    EcplSector.BANCASSURANCE,
    EcplSector.SECTEUR_PUBLIC,
    EcplSector.FIDUCIAIRE,
    EcplSector.IMMOBILIER,
    EcplSector.INDUSTRIE,
    EcplSector.AGRICULTURE,
    EcplSector.ARTISTIQUE,
    EcplSector.CONSTRUCTION,
    EcplSector.DIAMANTAIRE,
    EcplSector.ENSEIGNEMENT,
    EcplSector.HORECA,
    EcplSector.IT_TELECOM,
    EcplSector.SANS_SECTEUR,
    EcplSector.MEDICAL,
    EcplSector.TRANSPORT,
    EcplSector.NON_MARCHAND,
    EcplSector.SCIENCES,
    EcplSector.SECURITE,
    EcplSector.CONSULTANCE,
    EcplSector.ARTISANAT,
    EcplSector.COMMUNICATION,
    EcplSector.NETTOYAGE,
    EcplSector.PETROCHIMIE,
    EcplSector.COMMERCE_CARTE_TEL,
    EcplSector.COMMERCE_MAT_ELECTRO,
    EcplSector.COMMERCE_OR,
    EcplSector.BIJOUTIER,
    EcplSector.HORLOGER,
    EcplSector.CONCESSIONNAIRE,
    EcplSector.COMMERCE_ARME,
    EcplSector.COMMERCE_OEUVRE_ART,
    EcplSector.PARIS_EN_LIGNE,
    EcplSector.IMPORT_EXPORT,
];
