/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import {
  useOnFocus,
  useOnBlur,
  useOnChange,
} from '../../../../../../../OUI/utils';
import {
  useChecked,
  useIsCurrentFieldDisabled,
  useInputRef,
  useRadioHtmlId,
  useGetFieldPath,
} from '../../../../../../../OUI/Inputs';

const defaultDomCheckboxListInputListElementCss = ouiStyle.makeCss({
  srOnly: true,
});

export function DefaultDomCheckboxListInputListElementInput() {
  const ref = useInputRef();
  const name = useGetFieldPath();
  const id = useRadioHtmlId();
  const disabled = useIsCurrentFieldDisabled();
  const onFocus = useOnFocus();
  const onBlur = useOnBlur();
  const onChange = useOnChange();
  const checked = useChecked();
  return (
    <input
      ref={ref}
      type="checkbox"
      name={name}
      id={id}
      css={defaultDomCheckboxListInputListElementCss}
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
}
