import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { useOuiStyleMediaContextByIndex } from '../../../../../../../Components/MediaContext';
import { ActorApiStringDisplay } from '../../../../../../components/display/string';
import { useBondListCCurrencyCode, useBondsListIsRedacted } from '../../hooks';

export const BondListCCurrencyCode = (props: {
  withRedactedIcon?: boolean,
  lockBackgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
  lockWidthLines?: ouiStyle.InStyleWithMediaQueries['widthLines'],
  lockContainer?: ouiStyle.InStyleWithMediaQueries['container'],
  lockPaddingTop?: ouiStyle.InStyleWithMediaQueries['paddingTop'],
  lockPaddingBottom?: ouiStyle.InStyleWithMediaQueries['paddingBottom'],
  lockPaddingLeft?: ouiStyle.InStyleWithMediaQueries['paddingLeft'],
  lockPaddingRight?: ouiStyle.InStyleWithMediaQueries['paddingRight'],
  lockAlignItems?: ouiStyle.InStyleWithMediaQueries['alignItems'],
}) => {
  const {
    withRedactedIcon,
    lockBackgroundColor,
    lockWidthLines,
    lockContainer,
    lockPaddingTop,
    lockPaddingBottom,
    lockPaddingLeft,
    lockPaddingRight,
    lockAlignItems,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useBondListCCurrencyCode();
  const {
    data: redacted,
    isLoading: redactedIsLoading,
  } = useBondsListIsRedacted('C_CURRENCY.CODE');
  return (
    <ActorApiStringDisplay
      data={data}
      isLoading={isLoading || redactedIsLoading}
      error={error}
      isBlocked={redacted}
      loadingValue="---"
      withRedactedIcon={withRedactedIcon}
      lockBackgroundColor={lockBackgroundColor}
      lockWidthLines={lockWidthLines}
      lockContainer={lockContainer}
      lockPaddingTop={lockPaddingTop}
      lockPaddingBottom={lockPaddingBottom}
      lockPaddingLeft={lockPaddingLeft}
      lockPaddingRight={lockPaddingRight}
      lockAlignItems={lockAlignItems}
    />
  );
};

export const BondListCCurrencyCodeWithMediaQueryRedactedIcon = (props: {
  mediaQueryIndex: number,
  withRedactedIcon?: boolean,
  lockWidthLines?: ouiStyle.InStyle['widthLines'],
  lockContainer?: ouiStyle.InStyleWithMediaQueries['container'],
  lockPaddingTop?: ouiStyle.InStyle['paddingTop'],
  lockPaddingBottom?: ouiStyle.InStyle['paddingBottom'],
  lockPaddingLeft?: ouiStyle.InStyle['paddingLeft'],
  lockPaddingRight?: ouiStyle.InStyle['paddingRight'],
  lockAlignItems?: ouiStyle.InStyleWithMediaQueries['alignItems'],
}) => {
  const {
    mediaQueryIndex,
    withRedactedIcon,
    lockWidthLines,
    lockContainer,
    lockPaddingTop,
    lockPaddingBottom,
    lockPaddingLeft,
    lockPaddingRight,
    lockAlignItems,
  } = props;
  const up = useOuiStyleMediaContextByIndex(mediaQueryIndex);
  return (
    <BondListCCurrencyCode
      withRedactedIcon={withRedactedIcon || !up}
      lockWidthLines={lockWidthLines}
      lockContainer={lockContainer}
      lockPaddingTop={lockPaddingTop}
      lockPaddingBottom={lockPaddingBottom}
      lockPaddingLeft={lockPaddingLeft}
      lockPaddingRight={lockPaddingRight}
      lockAlignItems={lockAlignItems}
    />
  );
};
