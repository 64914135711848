import { mediaQuerizeProps } from '../../mediaQuery';
var isNotMediaQuery = function (flexShrink) { return !Array.isArray(flexShrink); };
export function makeFlexShrink(props) {
    var flexShrink = props.flexShrink;
    if (!flexShrink) {
        return 0;
    }
    if (isNotMediaQuery(flexShrink)) {
        if (typeof flexShrink === 'boolean') {
            return flexShrink ? 1 : 0;
        }
        return flexShrink;
    }
    return mediaQuerizeProps(flexShrink).map(function (element) { return makeFlexShrink({
        flexShrink: element,
    }); });
}
