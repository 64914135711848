import * as Yup from 'yup';
import { UncastedDataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import {
  makeRequired,
  nilValidator,
  stringValidator,
  makeNumberMoreThan,
  makeNumberMax,
  numberValidator,
} from '../../../../../OUI/Inputs';

const amountValidatorDependencyPaths: (keyof UncastedDataStructure['transfer'])[] = ['maxAmount'];

const transferShape: Record<keyof UncastedDataStructure['transfer'], any> = {
  communication: stringValidator,
  currency: makeRequired(stringValidator),
  amount: Yup.mixed().when(amountValidatorDependencyPaths, (max) => makeNumberMoreThan(0)(makeNumberMax(max)(makeRequired(numberValidator)))),
  iban: makeRequired(stringValidator),
  maxAmount: stringValidator,
  amounts: nilValidator,
};

export const transferValidator = Yup.object().shape(transferShape);
