import { useEffect, useRef } from 'react';
import {
  BaseActionObject, Interpreter, ResolveTypegenMeta, ServiceMap, TypegenDisabled,
} from 'xstate';
import { ValidSections } from '../../../../../history';
import * as events from '../../events';
import { AppBarMachineContext } from '../../machineContext';

export const useEmitSectionChange = (service: Interpreter<AppBarMachineContext, any, events.AppBarEvent, {
  value: any,
  context: AppBarMachineContext,
}, ResolveTypegenMeta<TypegenDisabled, events.AppBarEvent, BaseActionObject, ServiceMap>>, section: ValidSections | 'not-found') => {
  const lastSection = useRef<ValidSections | 'not-found'>(ValidSections.HOME);
  useEffect(() => {
    if (lastSection.current !== section) {
      service.send(events.sectionChange.eventBuilder({ section }));
      lastSection.current = section;
    }
  }, [service.send, section]);
};
