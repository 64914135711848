export const castNumber = (number: string | (string | null)[] | null | undefined) => {
  if (!number || Array.isArray(number)) {
    return undefined;
  }

  const parsed = parseInt(number, 10);
  if (Number.isNaN(parsed)) {
    return undefined;
  }

  return parsed;
};
