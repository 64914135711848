var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
var style = {
    widthLines: 'full',
    display: ouiStyle.Constants.DisplayProp.flex,
    alignItems: ouiStyle.Constants.AlignItemsProp.center,
    justifyContent: ouiStyle.Constants.JustifyContentProp.center,
};
var css = ouiStyle.mediaQuery(__assign(__assign({}, ouiStyle.makeCss(style)), { maxWidth: 550 }));
export var SocialEmbedInnerContainer = function (props) {
    var children = props.children;
    return (jsx("div", { css: css }, children));
};
