import * as React from 'react';
import { usePersonAccountsCCurrencyTAccCode } from '../../../../persons';
import { useAccountPositionGesEstimDMtInt } from '../../hooks';
import { ActorApiNumberDisplayCurrency } from '../../../../../../components/display/number/currency';

export const AccountsPositionsGesEstimDMtInt = () => {
  const {
    data,
    isLoading,
    error,
  } = useAccountPositionGesEstimDMtInt();
  const {
    data: currencyData,
    isLoading: currencyIsLoading,
    error: currencyError,
  } = usePersonAccountsCCurrencyTAccCode();
  return (
    <ActorApiNumberDisplayCurrency
      data={data}
      isLoading={isLoading}
      error={error}
      currencyData={currencyData}
      currencyIsLoading={currencyIsLoading}
      currencyError={currencyError}
    />
  );
};
