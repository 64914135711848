import { TAddType } from '../../../../types';
export var list = [
    TAddType.TITULAR,
    TAddType.PROCURATION,
    TAddType.UBO,
    TAddType.MANDATARY,
    TAddType.ONLY_REPRESENTATIVE,
    TAddType.IGNORED,
];
export var clientList = [
    TAddType.TITULAR,
    TAddType.PROCURATION,
];
