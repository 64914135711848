/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { useSectionNavScrollContainerRefCallback } from '../../../OUI/Scrollable';

const scrollableContainerDomStyle: ouiStyle.InStyle = {
  display: ouiStyle.Constants.DisplayProp.flex,
  overflowX: 'scroll',
  hiddenScroll: true,
};

type ScrollableContainerDomPropsWithoutMediaQueries = {
  overflowX?: ouiStyle.InStyle['overflowX'],
  hiddenScroll?: ouiStyle.InStyle['hiddenScroll'],
  display?: ouiStyle.InStyle['display'],
  lines?: ouiStyle.InStyle['lines'],
} & ouiStyle.Constants.InPadding

type ScrollableContainerDomPropsWithMediaQueries = {
  overflowX?: ouiStyle.InStyleWithMediaQueries['overflowX'],
  hiddenScroll?: ouiStyle.InStyleWithMediaQueries['hiddenScroll'],
  display?: ouiStyle.InStyleWithMediaQueries['display'],
  lines?: ouiStyle.InStyleWithMediaQueries['lines'],
} & ouiStyle.Constants.InPaddingWithMediaQueries

export const ScrollableContainerDom = (props: React.PropsWithChildren<ScrollableContainerDomPropsWithoutMediaQueries | ScrollableContainerDomPropsWithMediaQueries>) => {
  const {
    display = scrollableContainerDomStyle.display,
    lines = scrollableContainerDomStyle.lines,
    overflowX = scrollableContainerDomStyle.overflowX,
    hiddenScroll = scrollableContainerDomStyle.hiddenScroll,
    padding = scrollableContainerDomStyle.padding,
    paddingHorizontal = scrollableContainerDomStyle.paddingHorizontal,
    paddingVertical = scrollableContainerDomStyle.paddingVertical,
    paddingTop = scrollableContainerDomStyle.paddingTop,
    paddingLeft = scrollableContainerDomStyle.paddingLeft,
    paddingBottom = scrollableContainerDomStyle.paddingBottom,
    paddingRight = scrollableContainerDomStyle.paddingRight,
    iconContainer = scrollableContainerDomStyle.iconContainer,
    baselinePadding = scrollableContainerDomStyle.baselinePadding,
    childPaddingTop = scrollableContainerDomStyle.childPaddingTop,
    childPaddingLeft = scrollableContainerDomStyle.childPaddingLeft,
    childPaddingBottom = scrollableContainerDomStyle.childPaddingBottom,
    childPaddingRight = scrollableContainerDomStyle.childPaddingRight,
    children,
  } = props;
  const refCallback = useSectionNavScrollContainerRefCallback();
  const css = React.useMemo(() => ouiStyle.mediaQuery(ouiStyle.makeCss({
    display,
    lines,
    overflowX,
    hiddenScroll,
    padding,
    paddingHorizontal,
    paddingVertical,
    paddingTop,
    paddingLeft,
    paddingBottom,
    paddingRight,
    iconContainer,
    baselinePadding,
    childPaddingTop,
    childPaddingLeft,
    childPaddingBottom,
    childPaddingRight,
  })), [
    display,
    lines,
    overflowX,
    hiddenScroll,
    padding,
    paddingHorizontal,
    paddingVertical,
    paddingTop,
    paddingLeft,
    paddingBottom,
    paddingRight,
    iconContainer,
    baselinePadding,
    childPaddingTop,
    childPaddingLeft,
    childPaddingBottom,
    childPaddingRight,
  ]);
  return (
    <nav
      ref={refCallback}
      css={css}
    >
      {children}
    </nav>
  );
};
