import ouiBase from '@goldwasserexchange/oui';
import { useCallback } from 'react';
import { AccountIbans } from '../../type';

export const filterByTRelFinId = (tRelFinId: AccountIbans['T_REL_FIN.ID']) => (cashFlowLine: AccountIbans) => cashFlowLine['T_REL_FIN.ID'] === tRelFinId;

export const useFilterByTRelFinId = (tRelFinId: AccountIbans['T_REL_FIN.ID']) => {
  const filterFn = useCallback(filterByTRelFinId(tRelFinId), [tRelFinId]);
  return filterFn;
};

export const useFilterByPrimaryDimensionTRelFinId = () => {
  const idString = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const id = parseInt(idString, 10);
  const filteredByTRelFinId = useFilterByTRelFinId(id);
  return filteredByTRelFinId;
};
