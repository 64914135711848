import * as React from 'react';
import { times } from 'ramda';
import { ActorApiTable, ActorApiTableProps } from '../../../../../../components';
import { useAccountDocumentsList } from '../../hooks';
import { AccountDocument } from '../../type';

const loadingValue = times((i) => `${-i}`, 10);

export const AccountDocumentsTable = (props: React.PropsWithChildren<Pick<ActorApiTableProps, 'direction' | 'wrapping' | 'multiBody' | 'secondaryDimensionList'> & {
  filterPredicate?: ((data: AccountDocument) => boolean),
  sortPredicate?: (prev: AccountDocument, next: AccountDocument) => number,
}>) => {
  const {
    direction,
    wrapping,
    multiBody,
    secondaryDimensionList,
    filterPredicate,
    sortPredicate,
    children,
  } = props;
  const {
    data,
    isLoading,
    error,
    size,
    setSize,
  } = useAccountDocumentsList({
    filterPredicate,
    sortPredicate,
  });
  return (
    <ActorApiTable
      data={data}
      isLoading={isLoading}
      error={error}
      size={size}
      setSize={setSize}
      loadingValue={loadingValue}
      direction={direction}
      wrapping={wrapping}
      multiBody={multiBody}
      secondaryDimensionList={secondaryDimensionList}
    >
      {children}
    </ActorApiTable>
  );
};
