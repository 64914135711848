import React, { useCallback, useMemo } from 'react';
import { Trans } from '@lingui/macro';
import {
  dataStructure,
  GES_EVAL_PER,
  getIn,
  makeName,
  UncastedDataStructure,
  GesEvalType,
  UncastedOnboarding,
} from '@goldwasserexchange/oblis-frontend-utils';
import lmlGet from '@goldwasserexchange/utils/lib/lmlGet';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import ouiBase from '@goldwasserexchange/oui';
import { Locale } from '@goldwasserexchange/i18n';
import { RadioListInput } from '../../../../OUI-dom/Inputs';
import { GetComponentKeyByElementPrimaryKey } from '../../../Sections/onboarding/form/utils/ElementLabel';
import {
  makeRequired, nilValidator, numberValidator, useGetGetFn,
} from '../../../../OUI/Inputs';
import { makeWhenAlwaysCast } from '../../../Form/validations/whenAlwaysCast';
import { useLinguiLocale } from '../../../useLinguiLocale';

export const tAccIdGesEvalTypeValidator = makeRequired(numberValidator);

const tAccIdGesEvalTypeLabel = <Trans>Comment souhaitez-vous consulter vos états de portefeuille ?</Trans>;

const tAccIdGesEvalTypeData = {
  [GesEvalType.ONLINE]: {
    ID: GesEvalType.ONLINE,
    value: <Trans>en ligne</Trans>,
  },
  [GesEvalType.MAIL]: {
    ID: GesEvalType.MAIL,
    value: <Trans>par courrier</Trans>,
  },
};

const tAccIdGesEvalTypeLabelTopModel = (
  <TopModel name="RadioListInputLabelContent">
    {tAccIdGesEvalTypeLabel}
  </TopModel>
);

const tAccIdGesEvalTypeElementLabel = (
  <TopModel name="RadioListInputListElementLabelContent" data={tAccIdGesEvalTypeData} valueKey="value">
    {GetComponentKeyByElementPrimaryKey}
  </TopModel>
);

const useTAccIdGesEvalTypeGetFn = () => {
  const getFn = useCallback((source: string | { ID: string }, path: string): string | undefined => {
    const primaryKey = typeof source === 'string' || source == null ? source : source.ID;
    const rawValue = getIn(tAccIdGesEvalTypeData, makeName(primaryKey, path));
    return rawValue as string | undefined;
  }, []);
  return getFn;
};

export const TAccIdGesEvalTypeInput = () => {
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  return (
    <RadioListInput
      fieldPath={valueState.onboarding.T_ACC.idGesEvalType.path.join('.')}
      list={dataStructure.T_ACC.fields.idGesEvalType.list.map((d) => `${d}`)}
      searchKeys={['value']}
      useGetFn={useTAccIdGesEvalTypeGetFn}
    >
      {tAccIdGesEvalTypeLabelTopModel}
      {tAccIdGesEvalTypeElementLabel}
    </RadioListInput>
  );
};

const sortOrder = ['44', '43'];

const tAccIdGesEvalPerLabel = <Trans>Fréquence des états de portefeuille</Trans>;

const defaultLabelText = (
  <TopModel name="RadioListInputLabelContent">
    {tAccIdGesEvalPerLabel}
  </TopModel>
);

const tAccIdGesValPerValidatorDependencyPaths: (keyof UncastedOnboarding['T_ACC'])[] = [
  'idGesEvalType',
];

export const tAccIdGesValPerValidator = makeWhenAlwaysCast(tAccIdGesValPerValidatorDependencyPaths, (evalType) => {
  if (evalType === GesEvalType.MAIL) {
    return makeRequired(numberValidator);
  }
  return nilValidator;
});

const tAccIdGesValPerData = GES_EVAL_PER.IdLmlDescriptionInput.edges.reduce((acc, edge) => ({ ...acc, [edge.node.ID]: edge.node }), {});

const tAccIdGesValPerList = Object.keys(tAccIdGesValPerData);

const useTAccIdGesEvalPerGetFn = () => {
  const locale = useLinguiLocale();
  const getFn = useCallback((source: string | { ID: string }, path: string): string | undefined => {
    const primaryKey = typeof source === 'string' || source == null ? source : source.ID;
    const rawValue = getIn(tAccIdGesValPerData, makeName(primaryKey, path));
    if (locale === Locale.NL) {
      return lmlGet(1, rawValue);
    }
    return lmlGet(0, rawValue);
  }, [locale]);
  return getFn;
};

const TAccIdGesEvalPerElementLabel = () => {
  const getFn = useGetGetFn();
  const primaryKey = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  return getFn(primaryKey, 'LML_DESCRIPTION');
};

const useTAccIdGesEvalPerSortFn = (list) => {
  const sortedList = useMemo(() => list.sort((prevId, nextId) => {
    const prevIndex = sortOrder.findIndex((id) => id === prevId);
    const nextIndex = sortOrder.findIndex((id) => id === nextId);
    return nextIndex - prevIndex;
  }), [list]);
  return sortedList;
};

const tAccIdGesEvalPerElementLabel = (
  <TopModel name="RadioListInputListElementLabelContent">
    {TAccIdGesEvalPerElementLabel}
  </TopModel>
);

export const TAccIdGesEvalPerInput = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  return (
    <RadioListInput
      fieldPath={valueState.onboarding.T_ACC.ID_GES_EVAL_PER.path.join('.')}
      list={tAccIdGesValPerList}
      searchKeys={['LML_DESCRIPTION']}
      useGetFn={useTAccIdGesEvalPerGetFn}
      useSortFn={useTAccIdGesEvalPerSortFn}
    >
      {defaultLabelText}
      {tAccIdGesEvalPerElementLabel}
      {children}
    </RadioListInput>
  );
};
