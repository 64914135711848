import { useEffect, useState } from 'react';

export const useTickingNowState = (intervalTime = 1000) => {
  const now = new Date();
  const [nowState, setNowState] = useState(now);
  useEffect(() => {
    const interval = setInterval(() => { setNowState(new Date()); }, intervalTime);
    return () => clearInterval(interval);
  }, [intervalTime, setNowState]);
  return nowState;
};
