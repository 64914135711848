import { times } from 'ramda';
import * as React from 'react';
import { useBondsBondGesRatValGeShortcut } from '../../../../..';
import { BondDetailsHeaderRiskClassIndicator } from './indicator';

const list = times((i) => i + 1, 5);

export const BondDetailsRiskClassIndicators = () => {
  const { data } = useBondsBondGesRatValGeShortcut();
  return (
    <>
      {list.map((value) => (
        <BondDetailsHeaderRiskClassIndicator
          key={`riskClass-${value}`}
          isSelected={data != null ? data === value : false}
        >
          {value}
        </BondDetailsHeaderRiskClassIndicator>
      ))}
    </>
  );
};
