import useSWR, { KeyedMutator } from 'swr';
import { useMemo } from 'react';
import { AccountRates } from '../type';
import { Accounts } from '../../enum';
import { getServiceUrl } from '../../../../shared';
import { useAccountRatesStringifiedParams } from './defaultParams';
import { ACTOR_DEDUPING_INTERVAL } from '../../../shared';
import { useFetchWithAuthWithSend } from '../../../shared/utils/fetchWithAuth';
import { makeApiUrl } from '../../../../../../aws';

const useMakeAccountsRatesUrl = () => {
  const stringifiedParams = useAccountRatesStringifiedParams();
  const url = stringifiedParams !== null ? `${makeApiUrl('actor')}/${getServiceUrl(Accounts.Rates)}${stringifiedParams}` : null;
  return url;
};

type BaseUseAccountRateApiResponse = {
  isLoading: boolean,
  error?: any,
  mutate: KeyedMutator<AccountRates[]>,
  isValidating: boolean,
}

type UseAccountRateApiTransformResponse<T> = {
  data: T | undefined,
} & BaseUseAccountRateApiResponse;

type UseAccountRateApiResponse = {
  data: AccountRates | undefined,
} & BaseUseAccountRateApiResponse;

export const useAccountRateApi = (): UseAccountRateApiResponse => {
  const url = useMakeAccountsRatesUrl();
  const fetcher = useFetchWithAuthWithSend();
  const result = useSWR<AccountRates[], any>(
    url,
    fetcher,
    { dedupingInterval: ACTOR_DEDUPING_INTERVAL },
  );
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    return ({
      ...result,
      data: result.data ? result.data[0] : undefined,
      isLoading,
    });
  }, [result]);
  return resultWithLoading;
};

type TransformFunction<T> = (accountRate: AccountRates | undefined) => T

type UseAccountRateApiTransformProps<T> = {
  transformFunction: TransformFunction<T>,
};

export function useAccountRateTransformApi<T>(props: UseAccountRateApiTransformProps<T>): UseAccountRateApiTransformResponse<T> {
  const {
    transformFunction,
  } = props;
  const response = useAccountRateApi();
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: !isLoading && !error ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UseAccountRateFieldApiResponse<Field extends keyof AccountRates> = {
  data: AccountRates[Field] | undefined,
} & BaseUseAccountRateApiResponse;

type UseAccountRateFieldApiProps<Field extends keyof AccountRates> = {
  field: Field,
};

export function useAccountRateFieldApi<Field extends keyof AccountRates>(props: UseAccountRateFieldApiProps<Field>): UseAccountRateFieldApiResponse<Field> {
  const {
    field,
  } = props;
  const response = useAccountRateApi();
  const result = useMemo(() => {
    const {
      data,
    } = response;
    return {
      ...response,
      data: data ? data[field] : undefined,
    };
  }, [response, field]);
  return result;
}
