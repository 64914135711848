import { roundToNearestDown } from '../../../../../../../utils/roundToNearestDown';
import { roundToNearestUp } from '../../../../../../../utils/roundToNearestUp';
import {
  Domain, DomainFn, DomainFns,
} from '../type';

export const getDomainMin = (min: number | 'dataMin' | DomainFn, prev: number, val: number) => {
  if (typeof min === 'number') {
    return Math.min(prev, min);
  }
  if (min === 'dataMin') {
    return Math.min(prev, val);
  }
  return Math.min(prev, min(val));
};

export const getDomainMax = (max: number | 'dataMax' | DomainFn, prev: number, val: number) => {
  if (typeof max === 'number') {
    return Math.max(prev, max);
  }
  if (max === 'dataMax') {
    return Math.max(prev, val);
  }
  return Math.max(prev, max(val));
};

type DataElement = {
  x: number,
  y: number | undefined,
};

export const getDomain = (data: DataElement[], xAxisDomain: Domain, yAxisDomain: Domain) => data.reduce<{
  x: [number, number],
  y: [number, number],
}>((acc, { x, y }) => {
  const minX = getDomainMin(xAxisDomain[0], acc.x[0], x);
  const maxX = getDomainMax(xAxisDomain[1], acc.x[1], x);
  const minY = y != null ? getDomainMin(yAxisDomain[0], acc.y[0], y) : acc.y[0];
  const maxY = y != null ? getDomainMax(yAxisDomain[1], acc.y[1], y) : acc.y[1];
  return {
    x: [minX, maxX],
    y: [minY, maxY],
  };
}, {
  x: [Infinity, -Infinity],
  y: [Infinity, -Infinity],
});

export const domainMin = (domainStep: number) => (dataMin: number) => roundToNearestDown(domainStep, dataMin);

export const domainMax = (domainStep: number) => (dataMax: number) => roundToNearestUp(domainStep, dataMax);

export const makeDomainWithMinMax = (precision): DomainFns => [domainMin(precision), domainMax(precision)];
