export var getSpaceValue = function (space) {
    if (typeof space === 'number') {
        return space;
    }
    if (space === 'half') {
        return 0.5;
    }
    if (space === 'simple') {
        return 1;
    }
    if (space === 'double') {
        return 2;
    }
    if (space === 'triple') {
        return 3;
    }
    if (space === 'quadruple') {
        return 4;
    }
    return 0;
};
