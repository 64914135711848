/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const cardContentStyle: ouiStyle.InStyle = {
  margin: 'none',
};

const cardContentCss = ouiStyle.makeCss(cardContentStyle);

export const CardContent = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  return (
    <p
      css={cardContentCss}
    >
      {children}
    </p>
  );
};
