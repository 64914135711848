import * as React from 'react';
import { UncastedDataStructure, OrientationMarketNewsFrequency } from '@goldwasserexchange/oblis-frontend-utils';
import { Trans } from '@lingui/macro';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import ouiBase from '@goldwasserexchange/oui';
import { makeRequired, stringValidator } from '../../../../../../OUI/Inputs';
import { RadioListInput } from '../../../../../../OUI-dom/Inputs';
import { GetComponentKeyByElementPrimaryKey } from '../../utils/ElementLabel';

export const marketNewsFrequencyValidator = makeRequired(stringValidator);

const marketNewsFrequencyData: Record<OrientationMarketNewsFrequency, { ID: OrientationMarketNewsFrequency, value: JSX.Element }> = {
  [OrientationMarketNewsFrequency.REGULARLY]: {
    ID: OrientationMarketNewsFrequency.REGULARLY,
    value: <Trans>Régulièrement</Trans>,
  },
  [OrientationMarketNewsFrequency.OCCASIONALY]: {
    ID: OrientationMarketNewsFrequency.OCCASIONALY,
    value: <Trans>Occasionnellement</Trans>,
  },
  [OrientationMarketNewsFrequency.RARELY]: {
    ID: OrientationMarketNewsFrequency.RARELY,
    value: <Trans>Rarement</Trans>,
  },
};
const marketNewsFrequencyList = Object.keys(marketNewsFrequencyData);

const marketNewsFrequencyDefaultLabel = (
  <TopModel name="RadioListInputLabelContent">
    <Trans>Vous tenez vous informé de l'évolution des marchés financiers ?</Trans>
  </TopModel>
);

const marketNewsFrequencyElementLabel = (
  <TopModel name="RadioListInputListElementLabelContent" data={marketNewsFrequencyData} valueKey="value">
    {GetComponentKeyByElementPrimaryKey}
  </TopModel>
);

export const MarketNewsFrequency = (): JSX.Element => {
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  return (
    <RadioListInput
      fieldPath={valueState.onboarding.orientation.marketNewsFrequency.path.join('.')}
      dependenciesFieldPaths={[valueState.onboarding.orientation.submitted.path.join('.')]}
      list={marketNewsFrequencyList}
    >
      {marketNewsFrequencyDefaultLabel}
      {marketNewsFrequencyElementLabel}
    </RadioListInput>
  );
};
