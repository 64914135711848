/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const stepperPlaceHolderComponentStyle: ouiStyle.InStyle = {
  overflowX: 'scroll',
  overflowY: 'hidden',
};

const stepperPlaceHolderComponentCss = ouiStyle.makeCss(stepperPlaceHolderComponentStyle);

export const StepperPlaceholderComponent = (props: React.PropsWithChildren<{
  innerRef?: React.Ref<HTMLDivElement>,
  scrollBarHeight: number,
  onScrollLeft?: React.UIEventHandler<HTMLDivElement>,
}>) => {
  const {
    innerRef,
    scrollBarHeight,
    onScrollLeft,
    children,
  } = props;
  return (
    <div
      ref={innerRef}
      css={stepperPlaceHolderComponentCss}
      onScroll={onScrollLeft}
      style={{
        height: `calc(100% + ${scrollBarHeight}px)`,
      }}
    >
      {children}
    </div>
  );
};

export const StepperPlaceholderComponentWithRef = React.forwardRef<
HTMLDivElement,
React.PropsWithChildren<{
  scrollBarHeight: number,
  onScrollLeft?: React.UIEventHandler<HTMLDivElement>,
}>
>((props, ref) => (
  <StepperPlaceholderComponent
    innerRef={ref}
    {...props}
  />
));
