import * as NET_WORTH from './NET_WORTH';
import * as MONTHLY_EXPENSES from './MONTHLY_EXPENSES';
import * as MONTHLY_INCOME from './MONTHLY_INCOME';
import * as CASH from './CASH';
import * as OWNER from './OWNER';
import { FinancialSituationCash, FinancialSituationMonthlyExpenses, FinancialSituationMonthlyIncome, FinancialSituationNetWorth, FinancialSituationOwner, } from '../../../types';
export var lists = {
    netWorth: NET_WORTH.list,
    monthlyExpenses: MONTHLY_EXPENSES.list,
    monthlyIncome: MONTHLY_INCOME.list,
    cash: CASH.list,
    owner: OWNER.list,
};
export var listEnums = {
    netWorth: FinancialSituationNetWorth,
    monthlyExpenses: FinancialSituationMonthlyExpenses,
    monthlyIncome: FinancialSituationMonthlyIncome,
    cash: FinancialSituationCash,
    owner: FinancialSituationOwner,
};
