import { object } from 'yup';
import {
  dataStructure, AccountType, UncastedOnboarding,
} from '@goldwasserexchange/oblis-frontend-utils';
import {
  makeRequired, nilValidator, stringValidator,
} from '../../../../../../OUI/Inputs';
import { makeWhenAlwaysCast } from '../../../../../Form/validations/whenAlwaysCast';

export const horizonValidator = makeRequired(stringValidator);

export const riskLevelValidator = makeRequired(stringValidator);

export const maxRecoveryPeriodValidator = makeRequired(stringValidator);

export const foreignCurrenciesValidator = makeRequired(stringValidator);

export const investmentGoalValidator = makeRequired(stringValidator);

const investmentGoalsValidatorDependencyFields: (keyof UncastedOnboarding)[] = [
  'selectedService',
];

const investmentGoalsValidatorShape: Record<keyof UncastedOnboarding['investmentGoals'], any> = {
  horizon: horizonValidator,
  riskLevel: riskLevelValidator,
  maxRecoveryPeriod: maxRecoveryPeriodValidator,
  foreignCurrencies: foreignCurrenciesValidator,
  investmentGoal: investmentGoalValidator,
};

export const investmentGoalsValidator = makeWhenAlwaysCast(
  investmentGoalsValidatorDependencyFields,
  (serviceSelection: dataStructure.accountType.listType) => {
    if (serviceSelection === AccountType.CONSEIL || serviceSelection === AccountType.GESTION) {
      return object().shape(investmentGoalsValidatorShape);
    }
    return nilValidator;
  },
);

export const DEFAULT_INVESTMENT_GOALS_QUESTIONNAIRE_INITIAL_VALUES: UncastedOnboarding['investmentGoals'] = {
  horizon: undefined,
  riskLevel: undefined,
  maxRecoveryPeriod: undefined,
  foreignCurrencies: undefined,
  investmentGoal: undefined,
};
