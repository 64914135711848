import * as React from 'react';
import ouiBase from '@goldwasserexchange/oui';
import { useCloseOnClickOutside } from './hooks';
import { useFocus } from '../../../../context/focus';
import { useFieldContent } from '../../../../context/content';

export const OpenOnSearchFocusProvider = (
  props: React.PropsWithChildren<{}>,
) => {
  const { children } = props;
  const [hasFocus, setFocus] = useFocus();
  const [open, setOpen] = ouiBase.List.useSelectOpenStateHandlers();
  const fieldContent = useFieldContent().get();
  useCloseOnClickOutside();
  React.useEffect(() => {
    if (hasFocus && open === false) {
      setFocus(false);
    }
  }, [open]);
  React.useEffect(() => {
    setOpen(false);
  }, [fieldContent]);
  React.useEffect(() => {
    if (hasFocus && !open) {
      setOpen(true);
    }
  }, [hasFocus]);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
