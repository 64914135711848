import { DataPoint, OutputDataPoint } from './types';
import { getCoefficients } from './getCoefficients';
import { predictY } from './predictY';

/**
 * Calculates the polynomial regression for a given dataset and degree
 * @param {DataPoint[]} data - The dataset to use for regression
 * @param {number} degree - The degree of the regression polynomial
 * @returns {OutputDataPoint[]} - An array of output data points containing the x, y, and regression values
 */
export const getPolynomialRegression = (data: DataPoint[], degree: number): OutputDataPoint[] => {
  const terms = getCoefficients(data, degree);
  return data.map((dataPoint) => {
    const { x, y, ...rest } = dataPoint;
    return {
      ...rest,
      x,
      y,
      regression: predictY(terms, x),
    };
  });
};
