import * as React from 'react';
import { StateFilter } from '../../../../../../Machine';
import { Suspended } from '../../../../../../Suspended';
import { LazyFinancialSituationQuestionnaireStep } from '../../../questionnaires/FinancialSituationQuestionnaire/lazy';
import { LazyFinancialSituationExclusions } from './exclusions';

export const ConseilFinancialSituation = () => (
  <>
    <StateFilter is=".questions">
      <Suspended>
        <LazyFinancialSituationQuestionnaireStep />
      </Suspended>
    </StateFilter>
    <StateFilter is=".exclusions">
      <Suspended>
        <LazyFinancialSituationExclusions />
      </Suspended>
    </StateFilter>
  </>
);
