import * as React from 'react';
import { CashflowByCurrency } from '../../type';
import { CashflowsByCurrencyMapper } from './component';

const cashflowNotEur = (cashflow) => cashflow['C_CURRENCY.CODE'] !== 'EUR';

export const CashflowsByCurrencyNotEurMapper = (props: React.PropsWithChildren<{
  sortPredicate?: (prev: CashflowByCurrency, next: CashflowByCurrency) => number,
}>) => {
  const {
    sortPredicate,
    children,
  } = props;
  return (
    <CashflowsByCurrencyMapper
      filterPredicate={cashflowNotEur}
      sortPredicate={sortPredicate}
    >
      {children}
    </CashflowsByCurrencyMapper>
  );
};
