import * as React from 'react';
import { IconContainer } from '../container';

export const OnboardingIcon = (props: {
  style?: React.SVGAttributes<SVGSVGElement>['style'],
}) => {
  const {
    style,
  } = props;
  return (
    <IconContainer
      style={style}
    >
      <path d="M69.4,39.3c-1.4,0-2.5-1.1-2.5-2.5v-17.3c0-2.7-2.2-4.9-4.9-4.9h-30.8c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h30.8c5.5,0,9.9,4.4,9.9,9.9v17.3c0,1.4-1.1,2.5-2.5,2.5Z" />
      <path d="M41.7,69.4H12.9c-5.5,0-9.9-4.4-9.9-9.9V15.5c0-5.5,4.4-9.9,9.9-9.9h9.8c7.6,0,10,5.9,11.7,10.2,1.8,4.4,2.8,6.2,5.5,6.2h22c5.5,0,9.9,4.4,9.9,9.9s-1.1,2.5-2.5,2.5-2.5-1.1-2.5-2.5c0-2.7-2.2-4.9-4.9-4.9h-22c-6.4,0-8.5-5.1-10.2-9.3-1.8-4.5-3.1-7.1-7.1-7.1h-9.8c-2.7,0-4.9,2.2-4.9,4.9v44c0,2.7,2.2,4.9,4.9,4.9h28.7c1.4,0,2.5,1.1,2.5,2.5s-1.1,2.5-2.5,2.5Z" />
      <path d="M60.6,69.4c-1.4,0-2.5-1.1-2.5-2.5v-17.7c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v17.7c0,1.4-1.1,2.5-2.5,2.5Z" />
      <path d="M69.5,60.5h-17.7c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h17.7c1.4,0,2.5,1.1,2.5,2.5s-1.1,2.5-2.5,2.5Z" />
    </IconContainer>
  );
};
