import { GetBondsBondResponse } from '@goldwasserexchange/actor/rest-services';
import { useCallback } from 'react';
import { useAuthIsSettle, useIsConnected } from '../../../../../../../Components/Auth';
import { useCognitoUserValidAccounts } from '../../../../../../../Components/Sections/online/selectedAccount';
import { isPromoFn } from '../../../../shared';
import { BondResponse } from '../../type';
import {
  useBondsBondFieldApi,
  useBondsBondTransformApi,
} from '../api';

export const useBondsBondRedacted = () => {
  const response = useBondsBondFieldApi('redacted');
  return response;
};

const makeTransformFnHasRedactedOrIsDisconnectedPromo = (isConnected?: boolean) => (response: BondResponse | undefined) => {
  const redacted = response?.redacted ?? [];
  const isPromo = isPromoFn(response);
  return (isPromo && !isConnected) || redacted.length > 0;
};

export const useBondsBondHasRedactedOrIsDisconnectedPromo = () => {
  const isConnected = useIsConnected();
  const transformFnHasRedactedOrIsPromo = useCallback(makeTransformFnHasRedactedOrIsDisconnectedPromo(isConnected), [isConnected]);
  const response = useBondsBondTransformApi(transformFnHasRedactedOrIsPromo);
  return response;
};

const makeTransformFnDisconnectedAndNotPromo = (isConnected?: boolean) => (response: BondResponse | undefined) => {
  if (isConnected) {
    return false;
  }
  const isPromo = isPromoFn(response);
  return !isPromo;
};

export const useBondsBondDisconnectedAndNotPromo = () => {
  const isConnected = useIsConnected();
  const transformFnDisconnectedAndNotPromo = useCallback(makeTransformFnDisconnectedAndNotPromo(isConnected), [isConnected]);
  const response = useBondsBondTransformApi(transformFnDisconnectedAndNotPromo);
  return response;
};

const transformFnHasRedacted = (response: BondResponse | undefined) => {
  const redacted = response?.redacted ?? [];
  return redacted.length > 0;
};

export const useBondsBondHasRedacted = () => {
  const response = useBondsBondTransformApi(transformFnHasRedacted);
  return response;
};

export const useBondsBondIsRedacted = (field: keyof GetBondsBondResponse) => {
  const accountsLength = useCognitoUserValidAccounts().length;
  const isAuthMachineSettled = useAuthIsSettle();
  const transformFn = useCallback((response: BondResponse | undefined) => {
    const redacted = response?.redacted ?? [];
    const isPromo = isPromoFn(response);
    if (redacted.length === 0 || isPromo) {
      return false;
    }
    if ((!isAuthMachineSettled || accountsLength === 0) && field === 'S_ACC.ISIN') {
      return true;
    }
    return !isPromo ? redacted.includes(field) : false;
  }, [field, accountsLength, isAuthMachineSettled]);
  const response = useBondsBondTransformApi(transformFn);
  return response;
};

export const useBondsBondIsSomeRedacted = (fields: (keyof GetBondsBondResponse)[]) => {
  const transformFn = useCallback((response: BondResponse | undefined) => {
    const redacted = response?.redacted ?? [];
    const isPromo = isPromoFn(response);
    if (redacted.length === 0 || isPromo) {
      return false;
    }
    return fields.some((field) => redacted.includes(field));
  }, [fields]);
  const response = useBondsBondTransformApi(transformFn);
  return response;
};
