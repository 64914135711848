import * as React from 'react';
import { ActorApiStringDisplay } from '../../../../../../components/display/string';
import { useBondsBondIsRedacted, useBondsBondSAccConvRatio } from '../../hooks';

export const BondsBondSAccConvRatio = (props: {
  withRedactedIcon?: boolean,
}) => {
  const {
    withRedactedIcon,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useBondsBondSAccConvRatio();
  const {
    data: redacted,
    isLoading: redactedIsLoading,
  } = useBondsBondIsRedacted('S_ACC.CONV_RATIO');
  return (
    <ActorApiStringDisplay
      data={data}
      isLoading={isLoading || redactedIsLoading}
      error={error}
      loadingValue="--- -"
      isBlocked={redacted}
      withRedactedIcon={withRedactedIcon}
    />
  );
};
