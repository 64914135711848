import ouiBase from '@goldwasserexchange/oui';
import { useBondListFieldApiBySAccId } from '../api';

export const useBondListCCurrencyCode = () => {
  const sAccId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const response = useBondListFieldApiBySAccId({
    sAccId,
    field: 'C_CURRENCY.CODE',
  });
  return response;
};
