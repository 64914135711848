import { useContext } from 'react';
import { visibleIndexesRefContext } from './context';

export const useVisibleIndexesRefs = () => {
  const visibleIndexesRefs = useContext(visibleIndexesRefContext);
  return visibleIndexesRefs;
};

export const useVisibleStartIndexRef = () => {
  const [visibleStartIndexRef] = useVisibleIndexesRefs();
  return visibleStartIndexRef;
};

export const useVisibleStopIndexRef = () => {
  const [, visibleStopIndexRef] = useVisibleIndexesRefs();
  return visibleStopIndexRef;
};
