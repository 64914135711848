/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const questionGroupTitleStyle: ouiStyle.InStyle = {
  color: ouiStyle.Constants.Colors.accent,
  fontSize: 'xs',
  paddingBottom: 'simple',
};

const questionGroupTitleCss = ouiStyle.makeCss(questionGroupTitleStyle);

export const QuestionGroupTitle = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  return (
    <h2
      css={questionGroupTitleCss}
    >
      {children}
    </h2>
  );
};
