export var ANSWER_SUFFIX = 'Answer';
export var IS_CORRECT_SUFFIX = 'IsCorrect';
export var IS_REMEDIAL_SUFFIX = 'Remedial';
export var WAS_RETRIED_SUFFIX = 'WasRetried';
export var IS_CORRECTED_SUFFIX = 'IsCorrected';
export function makeAnswerKey(sourceKey) {
    return "".concat(sourceKey).concat(ANSWER_SUFFIX);
}
export function makeIsCorrectKey(sourceKey) {
    return "".concat(sourceKey).concat(IS_CORRECT_SUFFIX);
}
export function makeRemedialKey(sourceKey) {
    return "".concat(sourceKey).concat(IS_REMEDIAL_SUFFIX);
}
export function makeWasRetried(sourceKey) {
    return "".concat(sourceKey).concat(WAS_RETRIED_SUFFIX);
}
export function makeIsCorrected(sourceKey) {
    return "".concat(sourceKey).concat(IS_CORRECTED_SUFFIX);
}
