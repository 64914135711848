import * as React from 'react';
import { ActorApiMapper } from '../../../../../../components';
import { AccountCashflow } from '../../type';
import { useAccountCashflowsList } from '../../hooks';
import { useMakeLoadingIds } from '../../../../shared';

export const AccountCashflowsMapper = (props: React.PropsWithChildren<{
  filterPredicate?: (data: AccountCashflow) => boolean,
  sortPredicate?: (prev: AccountCashflow, next: AccountCashflow) => number,
  limit?: number,
  loadingList?: any[],
}>) => {
  const {
    filterPredicate,
    sortPredicate,
    loadingList: propsLoadingList,
    limit,
    children,
  } = props;
  const loadingList = useMakeLoadingIds(!propsLoadingList ? (limit ?? 10) : 0);
  const {
    data,
    isLoading,
    error,
  } = useAccountCashflowsList({ filterPredicate, sortPredicate });
  return (
    <ActorApiMapper
      data={data}
      isLoading={isLoading}
      error={error}
      loadingList={propsLoadingList ?? loadingList}
      limit={limit}
    >
      {children}
    </ActorApiMapper>
  );
};
