import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { HeadingSectionBase, HeadingSectionHeadingBase, HeadingSectionIllustrationBase, HeadingSectionIntroBase, HeadingSectionTitleBase, } from './components';
import { HeadingSectionContextProvider } from './context';
export var headingSectionDefaultTopModelsObject = {
    HeadingSectionBase: (React.createElement(TopModel, { name: "HeadingSectionBase" }, HeadingSectionBase)),
    HeadingSectionContextProvider: (React.createElement(TopModel, { name: "HeadingSectionContextProvider" }, HeadingSectionContextProvider)),
    HeadingSectionHeadingBase: (React.createElement(TopModel, { name: "HeadingSectionHeadingBase" }, HeadingSectionHeadingBase)),
    HeadingSectionHeadingTitleBase: (React.createElement(TopModel, { name: "HeadingSectionHeadingTitleBase" }, HeadingSectionTitleBase)),
    HeadingSectionHeadingIntroBase: (React.createElement(TopModel, { name: "HeadingSectionHeadingIntroBase" }, HeadingSectionIntroBase)),
    HeadingSectionHeadingIllustrationBase: (React.createElement(TopModel, { name: "HeadingSectionIllustrationBase" }, HeadingSectionIllustrationBase)),
};
