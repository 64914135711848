import * as React from 'react';
import { ActorApiTable, ActorApiTableProps } from '../../../../../../components';
import { makeLoadingIds } from '../../../../shared/utils';
import { useDeviceList } from '../../hooks';

const loadingValue = makeLoadingIds(10, 1);

export const DeviceTable = (props: React.PropsWithChildren<Pick<ActorApiTableProps, 'direction' | 'wrapping' | 'multiBody' | 'secondaryDimensionList'>>) => {
  const {
    direction,
    wrapping,
    multiBody,
    secondaryDimensionList,
    children,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useDeviceList();
  return (
    <ActorApiTable
      data={data}
      isLoading={isLoading}
      error={error}
      loadingValue={loadingValue}
      direction={direction}
      wrapping={wrapping}
      multiBody={multiBody}
      secondaryDimensionList={secondaryDimensionList}
    >
      {children}
    </ActorApiTable>
  );
};
