import * as React from 'react';
import { hasFocusContext } from './context';
import { useSetBooleanToggleStateHandlers } from '../../../utils/hooks/useSetBooleanStateHandler';
import {
  OnFocusSetContextProvider,
  OnFocusEnhanceContextProvider,
} from '../../../utils/context/onFocus';
import {
  OnBlurSetContextProvider,
  OnBlurEnhanceContextProvider,
} from '../../../utils/context/onBlur';

import { useFocus } from './hooks';

export const HasFocusContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  const focusStateHandlers = React.useState(false);
  return (
    <hasFocusContext.Provider value={focusStateHandlers}>
      {children}
    </hasFocusContext.Provider>
  );
};

export const FocusHandlersSetContextProvider = (
  props: React.PropsWithChildren<{}>,
) => {
  const { children } = props;
  const [hasFocus, setFocus] = useFocus();
  const [onFocus, onBlur] = useSetBooleanToggleStateHandlers(
    hasFocus,
    setFocus,
  );
  return (
    <OnFocusSetContextProvider onFocus={onFocus}>
      <OnBlurSetContextProvider onBlur={onBlur}>
        {children}
      </OnBlurSetContextProvider>
    </OnFocusSetContextProvider>
  );
};

export const FocusHandlersEnhanceContextProvider = (
  props: React.PropsWithChildren<{}>,
) => {
  const { children } = props;
  const [hasFocus, setFocus] = useFocus();
  const [onFocus, onBlur] = useSetBooleanToggleStateHandlers(
    hasFocus,
    setFocus,
  );
  return (
    <OnFocusEnhanceContextProvider onFocus={onFocus}>
      <OnBlurEnhanceContextProvider onBlur={onBlur}>
        {children}
      </OnBlurEnhanceContextProvider>
    </OnFocusEnhanceContextProvider>
  );
};

export const FocusWithHandlersSetContextProvider = (
  props: React.PropsWithChildren<{}>,
) => {
  const { children } = props;
  return (
    <HasFocusContextProvider>
      <FocusHandlersSetContextProvider>
        {children}
      </FocusHandlersSetContextProvider>
    </HasFocusContextProvider>
  );
};

export const FocusWithHandlersEnhanceContextProvider = (
  props: React.PropsWithChildren<{}>,
) => {
  const { children } = props;
  return (
    <HasFocusContextProvider>
      <FocusHandlersEnhanceContextProvider>
        {children}
      </FocusHandlersEnhanceContextProvider>
    </HasFocusContextProvider>
  );
};
