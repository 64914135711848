/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { useIsHighlighted } from '../table/context/highlightContext';
import premiumIcon from './GE-icon-PREMIUM.svg';
import premiumNegIcon from './GE-icon-PREMIUM-neg.svg';

const premiumIconStyle: ouiStyle.InStyle = {
  lines: 6,
  widthLines: 6,
};

const premiumIconCss = ouiStyle.makeCss(premiumIconStyle);

export const PremiumIcon = () => {
  const isHighlighted = useIsHighlighted();
  return (
    <img
      src={!isHighlighted ? premiumIcon : premiumNegIcon}
      alt="icone du compte Premium"
      css={premiumIconCss}
    />
  );
};
