import * as React from 'react';
import { cCurrencyCodeContext } from './context';

export const CCurrencyCodeContextProvider = (props: React.PropsWithChildren<{
  cCurrencyCode: string,
}>) => {
  const {
    cCurrencyCode,
    children,
  } = props;
  return (
    <cCurrencyCodeContext.Provider value={cCurrencyCode}>
      {children}
    </cCurrencyCodeContext.Provider>
  );
};
