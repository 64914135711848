import ouiBase from '@goldwasserexchange/oui';
import { useCallback } from 'react';
import { useFieldContent } from '../../../../../context';

export function useIsElementDataSelectedFn() {
  const selectedValue = useFieldContent().get();
  const fn = useCallback(
    (elementData: string) => elementData === selectedValue,
    [selectedValue],
  );
  return fn;
}

export function useContainsSelectedFn() {
  const isSelectedFn = useIsElementDataSelectedFn();
  const selectedValue = useFieldContent().get();
  const fn = useCallback(
    (data: string[]) => {
      if (selectedValue != null && selectedValue !== '') {
        return data.some((x) => isSelectedFn(x));
      }
      return true;
    },
    [selectedValue, isSelectedFn],
  );
  return fn;
}

export function useFindSelectedFn() {
  const isSelectedFn = useIsElementDataSelectedFn();
  const selectedValue = useFieldContent().get();
  const fn = useCallback(
    (data: string[]) => {
      if (selectedValue != null && selectedValue !== '') {
        const result = data.find((x) => isSelectedFn(x));
        return result != null ? [result] : [];
      }
      return [];
    },
    [selectedValue, isSelectedFn],
  );
  return fn;
}

export function useFindSelectedFnInListData() {
  const sourceData = ouiBase.utils.dimensions.usePrimaryDimensionList();
  const selectedFn = useFindSelectedFn();
  const fn = useCallback(() => selectedFn(sourceData), [sourceData, selectedFn]);
  return fn;
}
