import * as React from 'react';
import Fuse from 'fuse.js';
import ouiBase from '@goldwasserexchange/oui';
import { fuzzySearchIndexerContext } from './context';
import { useGetGetFn } from '../../../../shared/components/list/context/getFn';
import { useGetSearchKeys } from '../../../../shared/components/list/context/searchKeys';
import { FuseType, FuseData, FuseOptions } from './type';

export function FuzzySearchIndexerRefBaseProvider(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  const getFn = useGetGetFn();
  const keys = useGetSearchKeys();
  const data = ouiBase.utils.dimensions.usePrimaryDimensionList();

  const fuzySearchIndexerRef = React.useRef<FuseType | undefined>(undefined);
  React.useEffect(() => {
    fuzySearchIndexerRef.current = new Fuse<FuseData, FuseOptions>(
      data.map((d) => (typeof d === 'string' ? { ID: d } : d)),
      {
        shouldSort: true,
        threshold: 0.4,
        location: 0,
        distance: 100,
        minMatchCharLength: 1,
        includeMatches: true,
        includeScore: true,
        getFn,
        keys,
      },
    );
  }, [data, getFn, keys]);
  return (
    <fuzzySearchIndexerContext.Provider value={fuzySearchIndexerRef}>
      {children}
    </fuzzySearchIndexerContext.Provider>
  );
}

export function FuzzySearchIndexerRefProvider(
  props: React.PropsWithChildren<{
  }>,
) {
  const { children } = props;
  return (

    <FuzzySearchIndexerRefBaseProvider>
      {children}
    </FuzzySearchIndexerRefBaseProvider>
  );
}
