import React from 'react';
import ouiDom from '@goldwasserexchange/oui-dom';
import ouiStyle from '@goldwasserexchange/oui-style';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import {
  NotFoundIntro,
  NotFoundIsErrorContact,
  Title,
  NotFoundSuggestions,
} from './components';

export const NotFoundWithSuggestions = (props: {
  param: string,
  whiteListedSections: string[],
  labelMap: Record<string, () => JSX.Element>,
  container?: ouiStyle.InStyle['container'],
  baseTo: string,
  color?: ouiStyle.Constants.Colors,
}) => {
  const {
    param,
    whiteListedSections,
    labelMap,
    container = ouiStyle.Constants.Container.lg,
    baseTo,
    color,
  } = props;
  return (
    <ouiDom.HeadingSection.HeadingSection
      index={0}
      name="NotFound"
      container={container}
      paddingHorizontal="simple"
    >
      <TopModel name="HeadingSectionTitleContent-NotFound">
        {Title}
      </TopModel>
      <TopModel name="HeadingSectionIntroContent-NotFound">
        <NotFoundIntro
          param={param}
        />
      </TopModel>
      <TopModel
        name="HeadingSectionIntroContainer-NotFound"
        color={color}
      >
        {ouiDom.P.DomPContainer}
      </TopModel>
      <NotFoundSuggestions
        param={param}
        whiteListedSections={whiteListedSections}
        labelMap={labelMap}
        baseTo={baseTo}
        color={color}
      />
      <NotFoundIsErrorContact
        color={color}
      />
    </ouiDom.HeadingSection.HeadingSection>
  );
};
