import ouiBase from '@goldwasserexchange/oui';
import { Untracked } from '@hookstate/untracked';
import * as React from 'react';
import { UncastedDataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import { useFieldTouchedByFieldPaths } from '../touched/hooks';
import { formValidatorContext } from './context';
import { validateAts } from '../../../../Components/Form/validations/validate';

export function useFormValidator() {
  const validator = React.useContext(formValidatorContext);
  return validator;
}

export function useValidateMultipleFn() {
  const validator = useFormValidator();
  const valuesState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const fn = React.useCallback((paths: string[]) => {
    const values = Untracked(valuesState).get();
    return validator ? validateAts({ validator, values, paths }) : Promise.reject(new Error('no validator'));
  }, [validator]);
  return fn;
}

export const useValidateMultipleDebounced = (props: {
  paths: string[],
  timeoutDuration: number,
  onSuccess?: () => void,
  onError?: () => void,
}) => {
  const {
    paths,
    timeoutDuration,
    onSuccess,
    onError,
  } = props;
  const values = ouiBase.Form.useFieldValuesByPaths(paths);
  const toucheds = useFieldTouchedByFieldPaths(paths);
  const validateMultipleFn = useValidateMultipleFn();
  React.useEffect(() => {
    let mounted = true;
    let timeout: NodeJS.Timeout | null = null;
    if (toucheds.some((touched) => Untracked(touched).get())) {
      timeout = setTimeout(() => validateMultipleFn(paths)
        .then(() => {
          if (mounted && onSuccess) {
            onSuccess();
          }
        })
        .catch(() => {
          if (mounted && onError) {
            onError();
          }
        }), timeoutDuration);
    }
    return () => {
      mounted = false;
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [timeoutDuration, onSuccess, onError, validateMultipleFn, values.map((value) => value.get()).join('~'), toucheds.map((touched) => (touched.get() ? 'true' : 'false')).join('~')]);
};

export const useSubmitDisabled = (paths: string[]) => {
  const [disabled, setDisabledState] = React.useState(false);
  const setDisabled = React.useCallback(() => setDisabledState(true), [setDisabledState]);
  const setEnabled = React.useCallback(() => setDisabledState(false), [setDisabledState]);
  useValidateMultipleDebounced({
    paths,
    timeoutDuration: 500,
    onSuccess: setEnabled,
    onError: setDisabled,
  });
  return disabled;
};
