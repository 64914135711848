var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { getTopModelFromChildren, RampWithNameTransform, TopModelMerge, } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
export function PStructure(props) {
    var container = props.container, widthLines = props.widthLines, minusWidth = props.minusWidth, textAlign = props.textAlign, textTransform = props.textTransform, fontSize = props.fontSize, fontWeight = props.fontWeight, lineFactor = props.lineFactor, whiteSpace = props.whiteSpace, textOverflow = props.textOverflow, padding = props.padding, paddingHorizontal = props.paddingHorizontal, paddingVertical = props.paddingVertical, paddingTop = props.paddingTop, paddingLeft = props.paddingLeft, paddingBottom = props.paddingBottom, paddingRight = props.paddingRight, iconContainer = props.iconContainer, baselinePadding = props.baselinePadding, childPaddingTop = props.childPaddingTop, childPaddingLeft = props.childPaddingLeft, childPaddingBottom = props.childPaddingBottom, childPaddingRight = props.childPaddingRight, color = props.color, backgroundColor = props.backgroundColor, minLines = props.minLines, minIcon = props.minIcon, minFontSize = props.minFontSize, minPaddingTop = props.minPaddingTop, minPaddingBottom = props.minPaddingBottom, minChildPaddingTop = props.minChildPaddingTop, minChildPaddingBottom = props.minChildPaddingBottom, minParentBorderTopWidth = props.minParentBorderTopWidth, minParentBorderBottomWidth = props.minParentBorderBottomWidth, overflow = props.overflow, alignSelf = props.alignSelf, lineClamp = props.lineClamp, incomingChildren = props.children;
    var _a = __read(getTopModelFromChildren(incomingChildren), 2), children = _a[0], topModelChildren = _a[1];
    return (React.createElement(TopModelMerge, { topModels: topModelChildren },
        React.createElement(RampWithNameTransform, { rampName: "Base", container: container, lineFactor: lineFactor, widthLines: widthLines, minusWidth: minusWidth, textAlign: textAlign, textTransform: textTransform, fontSize: fontSize, fontWeight: fontWeight, whiteSpace: whiteSpace, textOverflow: textOverflow, overflow: overflow, padding: padding, paddingHorizontal: paddingHorizontal, paddingVertical: paddingVertical, paddingTop: paddingTop, paddingLeft: paddingLeft, paddingBottom: paddingBottom, paddingRight: paddingRight, iconContainer: iconContainer, baselinePadding: baselinePadding, childPaddingTop: childPaddingTop, childPaddingLeft: childPaddingLeft, childPaddingBottom: childPaddingBottom, childPaddingRight: childPaddingRight, color: color, backgroundColor: backgroundColor, minLines: minLines, minIcon: minIcon, minFontSize: minFontSize, minPaddingTop: minPaddingTop, minPaddingBottom: minPaddingBottom, minChildPaddingTop: minChildPaddingTop, minChildPaddingBottom: minChildPaddingBottom, minParentBorderTopWidth: minParentBorderTopWidth, minParentBorderBottomWidth: minParentBorderBottomWidth, alignSelf: alignSelf, lineClamp: lineClamp }, children)));
}
