import { GetBondsPromotionsResponse } from '@goldwasserexchange/actor/rest-services';
import { STypeGrpIds } from '../../../shared/utils/sTypeGrpIds';
import { BondList } from '../../bonds';
import { BondResponse } from '../../bonds/bond/type';
import { BondSameIssuerResponse } from '../../bonds/sameIssuer/type';

export const getOblEndDateWithPerpetual = (listBond?: BondList | BondResponse | BondSameIssuerResponse | GetBondsPromotionsResponse) => {
  if (!listBond) {
    return undefined;
  }
  if (listBond['S_TYPE_GRP.ID'] === STypeGrpIds.OBLIGATIONS_PERP) {
    return '∞';
  }
  return listBond['S_ACC.OBL_END_DATE'];
};
