import ouiBase from '@goldwasserexchange/oui';
import * as CASH from '../CASH';
import * as MONTHLY_EXPENSES from '../MONTHLY_EXPENSES';
import * as MONTHLY_INCOME from '../MONTHLY_INCOME';
import * as NET_WORTH from '../NET_WORTH';
import * as OWNER from '../OWNER';
export var map = {
    netWorth: NET_WORTH.logic.show.showLogic,
    monthlyExpenses: MONTHLY_EXPENSES.logic.show.showLogic,
    monthlyIncome: MONTHLY_INCOME.logic.show.showLogic,
    cash: CASH.logic.show.showLogic,
    owner: OWNER.logic.show.showLogic,
};
export var mapLogic = {
    map: [
        NET_WORTH.logic.show.showLogicBoolean,
        MONTHLY_EXPENSES.logic.show.showLogicBoolean,
        MONTHLY_INCOME.logic.show.showLogicBoolean,
        CASH.logic.show.showLogicBoolean,
        OWNER.logic.show.showLogicBoolean,
    ],
};
export var useShowList = function (parentName) { return ouiBase.utils.hookstate.useFormFilterLogicMap(mapLogic, parentName); };
