import React from 'react';
import { Trans } from '@lingui/macro';
import {
  AccountType,
  UncastedDataStructure,
  PrRisk,
  UncastedOnboarding,
  CastedOnboarding,
} from '@goldwasserexchange/oblis-frontend-utils';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import ouiBase from '@goldwasserexchange/oui';
import { RadioListInput } from '../../../../../../../OUI-dom/Inputs';
import {
  makeRequired, nilValidator, stringValidator,
} from '../../../../../../../OUI/Inputs';
import { makeWhenAlwaysCast } from '../../../../../../Form/validations/whenAlwaysCast';

const idRiskPrRiskKeyValidatorDependencyPaths: (`${keyof UncastedOnboarding}`)[] = [
  'selectedService',
];

export const idRiskPrRiskKeyValidator = makeWhenAlwaysCast(
  idRiskPrRiskKeyValidatorDependencyPaths,
  (serviceSelection: CastedOnboarding['selectedService']) => {
    if (serviceSelection === AccountType.RTO) {
      return makeRequired(stringValidator);
    }
    return nilValidator;
  },
);

const riskClassData = {
  [PrRisk.GOLD1]: (): JSX.Element => <Trans>Produits de classe 1</Trans>,
  [PrRisk.GOLD2]: (): JSX.Element => <Trans>Produits de classe 2</Trans>,
  [PrRisk.GOLD3]: (): JSX.Element => <Trans>Produits de classe 3</Trans>,
  [PrRisk.GOLD4]: (): JSX.Element => <Trans>Produits de classe 4</Trans>,
  [PrRisk.GOLD5]: (): JSX.Element => <Trans>Tout type de produit, peu importe les risques qu'il présente</Trans>,
};

const riskClassList = Object.keys(riskClassData);

const labelText = <TopModel name="RadioListInputLabelContent"><Trans>Profil de risque maximum</Trans></TopModel>;

const ElementLabel = () => {
  const primaryKey = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const Component = riskClassData[primaryKey];
  return <Component />;
};

const defaultRiskClassElementLabel = (
  <TopModel name="RadioListInputListElementLabelContent">
    {ElementLabel}
  </TopModel>
);

export const MaxRiskClassInput = (): JSX.Element => {
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  return (
    <RadioListInput
      fieldPath={valueState.onboarding.ID_RISK_PR_RISK.path.join('.')}
      list={riskClassList}
    >
      {labelText}
      {defaultRiskClassElementLabel}
    </RadioListInput>
  );
};
