/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiBase from '@goldwasserexchange/oui';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { useSpring, animated } from 'react-spring';

export function DefaultDomVirtualizedListContainerPlaceHolder(props: React.PropsWithChildren<{
  style: React.CSSProperties,
  onScroll: ((event: React.UIEvent<HTMLDivElement, UIEvent>) => void),
}>) {
  const {
    style: { height = 0, ...restStyle },
    children,
    onScroll: onScrollProps,
    ...rest
  } = props;
  const heightSpring = useSpring({
    height: (typeof height === 'number' && height !== 0) ? height - 1 : height,
  });
  const onScrollContext = ouiBase.List.useOnScroll();
  const onScroll = React.useCallback(
    (e) => {
      onScrollContext(e);
      onScrollProps(e);
    },
    [onScrollContext, onScrollProps],
  );
  return (
    <animated.div
      {...rest}
      style={{ ...restStyle, ...heightSpring }}
      onScroll={onScroll}
    >
      {children}
    </animated.div>
  );
}

export function DefaultDomVirtualizedHorizontalListContainerPlaceHolder(props: React.PropsWithChildren<{
  style: React.CSSProperties,
  onScroll: ((event: React.UIEvent<HTMLDivElement, UIEvent>) => void),
}>) {
  const {
    style,
    children,
    onScroll: onScrollProps,
    ...rest
  } = props;
  const onScrollContext = ouiBase.List.useOnScroll();
  const onScroll = React.useCallback(
    (e) => {
      onScrollContext(e);
      onScrollProps(e);
    },
    [onScrollContext, onScrollProps],
  );
  return (
    <animated.div
      {...rest}
      style={style}
      onScroll={onScroll}
    >
      {children}
    </animated.div>
  );
}

const scrollerContainerStyle = ouiStyle.makeCss({
  display: ouiStyle.Constants.DisplayProp.flex,
  flexDirection: ouiStyle.Constants.InFlexDirectionProp.row,
});

const scrollButtonStyle = ouiStyle.makeCss({
  widthLines: 1,
  lines: 1,
  padding: 'simple',
  iconContainer: true,
});

const scrollButtonContainerStyle = ouiStyle.makeCss({
  flex: 'none',
  widthLines: 1,
  lines: 1,
  childPaddingLeft: 'simple',
  childPaddingRight: 'simple',
  childPaddingTop: 'simple',
  childPaddingBottom: 'simple',
});

const useScrollByElementWidht = (ref: React.MutableRefObject<HTMLDivElement | undefined> | React.RefObject<HTMLDivElement | undefined>, factor = 1) => {
  const currentElementWidth = ouiBase.List.useListInputElementWidth();
  const scrollByElementWidth = React.useCallback(() => {
    ref.current?.scrollBy({
      left: factor * currentElementWidth,
      behavior: 'smooth',
    });
  }, [currentElementWidth, factor, ref]);
  return scrollByElementWidth;
};

export function DefaultDomHorizontalScrollerContainerPlaceHolder(props: React.PropsWithChildren<{
  style: React.CSSProperties,
  onScroll: ((event: React.UIEvent<HTMLDivElement, UIEvent>) => void),
}>) {
  const {
    style,
    children,
    onScroll: onScrollProps,
    ...rest
  } = props;
  const scrollerRef = React.useRef<HTMLDivElement>(null);
  const scrollForward = useScrollByElementWidht(scrollerRef);
  const scrollBack = useScrollByElementWidht(scrollerRef, -1);
  const onScrollContext = ouiBase.List.useOnScroll();
  const { width } = style;
  const currentElementWidth = ouiBase.List.useListInputElementWidth();
  const length = ouiBase.utils.dimensions.usePrimaryDimensionLength();
  const totalScrollerWidth = currentElementWidth * length;
  const [isStart, setIsStart] = React.useState(true);
  const [isEnd, setIsEnd] = React.useState(false);
  if (scrollerRef.current) {
    if (scrollerRef.current.scrollLeft === 0 && !isStart) {
      setIsStart(true);
    }
    if (scrollerRef.current.scrollLeft !== 0 && isStart) {
      setIsStart(false);
    }
    if (typeof width === 'number' && Math.ceil(scrollerRef.current.scrollLeft) >= totalScrollerWidth - scrollerRef.current.clientWidth && !isEnd) {
      setIsEnd(true);
    }
    if ((typeof width !== 'number' || Math.ceil(scrollerRef.current.scrollLeft) < totalScrollerWidth - scrollerRef.current.clientWidth) && isEnd) {
      setIsEnd(false);
    }
  }
  const onScroll = React.useCallback(
    (e) => {
      if (e.target.scrollLeft === 0 && !isStart) {
        setIsStart(true);
      }
      if (e.target.scrollLeft !== 0 && isStart) {
        setIsStart(false);
      }
      if (typeof width === 'number' && Math.ceil(e.target.scrollLeft) >= totalScrollerWidth - e.target.clientWidth && !isEnd) {
        setIsEnd(true);
      }
      if ((typeof width !== 'number' || Math.ceil(e.target.scrollLeft) < totalScrollerWidth - e.target.clientWidth) && isEnd) {
        setIsEnd(false);
      }
      onScrollContext(e);
      onScrollProps(e);
    },
    [onScrollContext, onScrollProps, isStart, setIsStart, isEnd, setIsEnd, totalScrollerWidth],
  );
  const heightWithDefault = style?.height ?? 0;
  const parsedHeight = typeof heightWithDefault === 'number' ? heightWithDefault : parseInt(heightWithDefault, 10);
  const computedHeight = parsedHeight + 20;
  return (
    <div css={scrollerContainerStyle}>
      <div css={scrollButtonContainerStyle}>
        {!isStart && (
          <button // eslint-disable-line jsx-a11y/control-has-associated-label
            type="button"
            css={scrollButtonStyle}
            onClick={scrollBack}
          >
            <ChevronLeft />
          </button>
        )}
      </div>
      <animated.div
        style={{
          width,
          height: style.height,
          overflow: 'hidden',
        }}
      >
        <animated.div
          ref={scrollerRef}
          {...rest}
          style={{
            ...style,
            height: computedHeight,
          }}
          onScroll={onScroll}
        >
          {children}
        </animated.div>
      </animated.div>
      <div css={scrollButtonContainerStyle}>
        {!isEnd && (
          <button // eslint-disable-line jsx-a11y/control-has-associated-label
            type="button"
            css={scrollButtonStyle}
            onClick={scrollForward}
          >
            <ChevronRight />
          </button>
        )}
      </div>
    </div>
  );
}
