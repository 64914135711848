import { useContext } from 'react';
import { listOrFavoritesSearchTermContext } from './context';

export const useListOrFavoritesSearchTermContext = () => {
  const context = useContext(listOrFavoritesSearchTermContext);
  return context;
};

export const useSetListOrFavoritesSearchTerm = () => {
  const [, setSearchTerm] = useListOrFavoritesSearchTermContext();
  return setSearchTerm;
};
