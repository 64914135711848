import * as React from 'react';
import { prefix, TopModelNameTransformProvider } from '@goldwasserexchange/react-topmodel';
import ouiBase from '@goldwasserexchange/oui';
import {
  RadioListInputStructure,
  radioListInputPrefixer,
  DefaultContextProviderContextsTopModelsProvider,
  DefaultListInputTopModelsProvider,
  DefaultRadioListInputTopModelsProvider,
  UseGetFnProps,
  DefaultInputErrorMessageTopModelsProvider,
} from '../../../../OUI/Inputs';
import {
  DefaultRadioListInputDomTopModelsProvider,
} from '../radio/topmodels';
import {
  DefaultIconContentDomTopModelsProvider,
  DefaultFooterDomTopModelsProvider,
  DefaultListInputDomTopModelsProvider,
} from '../shared';
import { DefaultYesNoInputDomTopModelsProvider } from './topmodels';
import { InvertedContextProvider } from './context';
import { SearchKeysProps } from '../../../../OUI/Inputs/components/shared/components/list/context/searchKeys/context';

const baseYesNoInputList = ['1', '0'];

const invertedYesNoInputList = ['0', '1'];

const yesNoInputList = (inverted) => (!inverted ? baseYesNoInputList : invertedYesNoInputList);

export function YesNoInput(
  props: React.PropsWithChildren<
  {
    fieldPath: string,
    dependenciesFieldPaths?: string[],
    inverted?: boolean,
    useSortFn?: ((item: string[]) => string[]),
  } & Partial<SearchKeysProps> & Partial<UseGetFnProps>
  >,
) {
  const {
    fieldPath,
    dependenciesFieldPaths,
    inverted,
    searchKeys,
    useGetFn,
    useSortFn,
    children,
  } = props;
  return (
    <TopModelNameTransformProvider transformFn={radioListInputPrefixer}>
      <DefaultContextProviderContextsTopModelsProvider>
        <DefaultIconContentDomTopModelsProvider>
          <DefaultFooterDomTopModelsProvider>
            <ouiBase.List.DefaultListContextTopModelsProvider
              transformBeforeFn={prefix('InputList')}
            >
              <ouiBase.List.DefaultListMapperTopModelsProvider
                transformBeforeFn={prefix('InputList')}
              >
                <ouiBase.List.DefaultListElementBaseTopModelsProvider
                  transformBeforeFn={prefix('InputList')}
                >
                  <ouiBase.List.DefaultListElementSubContextsTopModelsProvider
                    transformBeforeFn={prefix('InputList')}
                  >
                    <DefaultListInputTopModelsProvider>
                      <DefaultInputErrorMessageTopModelsProvider>
                        <DefaultListInputDomTopModelsProvider>
                          <DefaultRadioListInputTopModelsProvider>
                            <DefaultRadioListInputDomTopModelsProvider>
                              <DefaultYesNoInputDomTopModelsProvider>
                                <InvertedContextProvider inverted={inverted}>
                                  <RadioListInputStructure
                                    fieldPath={fieldPath}
                                    dependenciesFieldPaths={dependenciesFieldPaths}
                                    list={yesNoInputList(inverted)}
                                    length={2}
                                    elementLines={1}
                                    searchKeys={searchKeys}
                                    useGetFn={useGetFn}
                                    useSortFn={useSortFn}
                                  >
                                    {children}
                                  </RadioListInputStructure>
                                </InvertedContextProvider>
                              </DefaultYesNoInputDomTopModelsProvider>
                            </DefaultRadioListInputDomTopModelsProvider>
                          </DefaultRadioListInputTopModelsProvider>
                        </DefaultListInputDomTopModelsProvider>
                      </DefaultInputErrorMessageTopModelsProvider>
                    </DefaultListInputTopModelsProvider>
                  </ouiBase.List.DefaultListElementSubContextsTopModelsProvider>
                </ouiBase.List.DefaultListElementBaseTopModelsProvider>
              </ouiBase.List.DefaultListMapperTopModelsProvider>
            </ouiBase.List.DefaultListContextTopModelsProvider>
          </DefaultFooterDomTopModelsProvider>
        </DefaultIconContentDomTopModelsProvider>
      </DefaultContextProviderContextsTopModelsProvider>
    </TopModelNameTransformProvider>
  );
}
