import React from 'react';
import { RampWithNameTransform } from '@goldwasserexchange/react-topmodel';
import { ShowedItemsContextProvider } from './showedItems';
import { ListElementChildPaddingProvider, ListInputElementLinesProvider, ListInputElementWidthLinesProvider, } from './elementLines';
import { ScrollTopContextProvider } from './scrollTop';
export function ListContextContainer(props) {
    var _a = props.list, list = _a === void 0 ? [] : _a, length = props.length, useFilterFn = props.useFilterFn, useSortFn = props.useSortFn, children = props.children;
    return (React.createElement(RampWithNameTransform, { rampName: "DataProvider", primaryDimensionList: list },
        React.createElement(RampWithNameTransform, { rampName: "FilterAndSortDataProvider", useFilterFn: useFilterFn, useSortFn: useSortFn },
            React.createElement(RampWithNameTransform, { rampName: "LengthProvider", length: length }, children))));
}
export function VirtualizedListContextContainer(props) {
    var list = props.list, length = props.length, useFilterFn = props.useFilterFn, useSortFn = props.useSortFn, showedItems = props.showedItems, _a = props.elementLines, elementLines = _a === void 0 ? 1 : _a, _b = props.elementWidthLines, elementWidthLines = _b === void 0 ? 1 : _b, _c = props.elementChildPaddingTop, elementChildPaddingTop = _c === void 0 ? 'none' : _c, _d = props.elementChildPaddingRight, elementChildPaddingRight = _d === void 0 ? 'none' : _d, _e = props.elementChildPaddingBottom, elementChildPaddingBottom = _e === void 0 ? 'none' : _e, _f = props.elementChildPaddingLeft, elementChildPaddingLeft = _f === void 0 ? 'none' : _f, children = props.children;
    return (React.createElement(ListInputElementLinesProvider, { elementLines: elementLines },
        React.createElement(ListInputElementWidthLinesProvider, { elementWidthLines: elementWidthLines },
            React.createElement(ListElementChildPaddingProvider, { elementChildPaddingTop: elementChildPaddingTop, elementChildPaddingRight: elementChildPaddingRight, elementChildPaddingBottom: elementChildPaddingBottom, elementChildPaddingLeft: elementChildPaddingLeft },
                React.createElement(ListContextContainer, { list: list, length: length, useFilterFn: useFilterFn, useSortFn: useSortFn },
                    React.createElement(ScrollTopContextProvider, null,
                        React.createElement(ShowedItemsContextProvider, { showedItems: showedItems }, children)))))));
}
