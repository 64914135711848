import { PutCommand } from '@aws-sdk/lib-dynamodb';
import { KeyedMutator } from 'swr';
import { getSub } from '../../auth';
import { getDynamoDbDocumentClient } from '../getDynamoDbClient';
import { optimisticUpdateFavorites } from './optimisticUpdateFavorites';

type AddFavoriteProps = {
  data: string[] | undefined,
  tableName: string,
  ttl: number | undefined,
  sAccId: number,
}

const addFavorite = async (props: AddFavoriteProps) => {
  const {
    data,
    tableName,
    sAccId,
    ttl,
  } = props;
  const { client } = await getDynamoDbDocumentClient();
  const sub = await getSub();
  if (!sub) {
    return [];
  }
  await client.send(new PutCommand({
    TableName: tableName,
    Item: {
      pk: `u#${sub}`,
      sk: `favorite#${sAccId}`,
      ...(ttl != null
        ? {
          ttl,
        }
        : {}),
    },
  }));
  client.destroy();
  return optimisticUpdateFavorites({
    data,
    element: `${sAccId}`,
    operation: 'ADD',
  });
};

export const addFavoriteWithMutate = async (props: AddFavoriteProps & {
  mutate: KeyedMutator<string[]>,
}) => {
  const {
    data,
    tableName,
    sAccId,
    ttl,
    mutate,
  } = props;
  return mutate(
    addFavorite({
      data,
      tableName,
      sAccId,
      ttl,
    }),
    {
      optimisticData: optimisticUpdateFavorites({
        data,
        element: `${sAccId}`,
        operation: 'ADD',
      }),
      rollbackOnError: true,
      populateCache: true,
      revalidate: false,
    },
  );
};
