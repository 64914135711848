import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { I18n } from '@lingui/core';
import { useCallback } from 'react';
import { useBondsBondFieldApi, useBondsBondFieldTransformFnApi } from '../api';

export const useBondsBondSAccGreen = () => {
  const response = useBondsBondFieldApi('S_ACC.GREEN');
  return response;
};

const transformSAccGreenBoolean = (sAccGreen: string | undefined) => {
  if (sAccGreen == null) {
    return null;
  }
  return `${sAccGreen}` === '1';
};

export const useBondsBondSAccGreenBoolean = () => {
  const response = useBondsBondFieldTransformFnApi({
    field: 'S_ACC.GREEN',
    transformFn: transformSAccGreenBoolean,
  });
  return response;
};

const makeTransformSAccGreenText = (i18n: I18n) => (sAccGreen: string | undefined) => {
  if (sAccGreen == null) {
    return null;
  }
  return `${sAccGreen}` === '1' ? i18n._(msg`Oui`) : i18n._(msg`Non`);
};

export const useBondsBondSAccGreenText = () => {
  const {
    i18n,
  } = useLingui();
  const transformSAccGreenText = useCallback(makeTransformSAccGreenText(i18n), [i18n]);
  const response = useBondsBondFieldTransformFnApi({
    field: 'S_ACC.GREEN',
    transformFn: transformSAccGreenText,
  });
  return response;
};
