import * as React from 'react';
import { useState } from 'react';
import { currentModalContext } from './context';

export const CurrentModalContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  const handlers = useState<string | null>(null);
  return (
    <currentModalContext.Provider value={handlers}>
      {children}
    </currentModalContext.Provider>
  );
};
