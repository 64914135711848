/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { usePopperContainerPlacementStart } from '../../../../../../../../../OUI/utils';

const helpInputPopperArrowContentStyle: ouiStyle.InStyle = {
  backgroundColor: ouiStyle.Constants.Colors.inverted,
  transform: 'rotate(45deg)',
  borderStyle: 'solid',
  borderWidth: 'simple',
};

export const HelpInputPopperArrowContent = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  const placementStart = usePopperContainerPlacementStart();
  const css = React.useMemo(() => ({
    ...ouiStyle.makeCss({
      ...helpInputPopperArrowContentStyle,
      borderTopColor: placementStart === 'bottom' || placementStart === 'left' ? ouiStyle.Constants.Colors.primary : ouiStyle.Constants.Colors.inverted,
      borderRightColor: placementStart === 'top' || placementStart === 'left' ? ouiStyle.Constants.Colors.primary : ouiStyle.Constants.Colors.inverted,
      borderBottomColor: placementStart === 'top' || placementStart === 'right' ? ouiStyle.Constants.Colors.primary : ouiStyle.Constants.Colors.inverted,
      borderLeftColor: placementStart === 'bottom' || placementStart === 'right' ? ouiStyle.Constants.Colors.primary : ouiStyle.Constants.Colors.inverted,
    }),
    width: '0.7071rem', // gives a diagonal of approx. 1rem
    height: '0.7071rem', // gives a diagonal of approx. 1rem
  }), [placementStart, helpInputPopperArrowContentStyle]);
  return (
    <div
      css={css}
    >
      {children}
    </div>
  );
};
