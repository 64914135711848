import { dataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import { useQuestionnaireStatusByProductNames } from '../../../../status';
import { getForceDisabledReasonFromStatuses } from './getter';
import { questionnaireStatusDisabledReasonMaps, defaultQuestionnaireStatusDisabledMap } from './data';

export const useQuestionnaireStatusDisabledReasonByProductNames = (
  productName: dataStructure.financialProducts.listType,
) => {
  const statuses = useQuestionnaireStatusByProductNames([productName, ...(dataStructure.financialProductsSelection.dependencies.byStatus.getter.getFinancialProductDependenciesByStatus(productName))]);
  const disabledReasonFromStatuses = getForceDisabledReasonFromStatuses(statuses, questionnaireStatusDisabledReasonMaps[productName] ?? defaultQuestionnaireStatusDisabledMap);
  return disabledReasonFromStatuses;
};
