import ouiBase from '@goldwasserexchange/oui';
import ouiStyle from '@goldwasserexchange/oui-style';
import ouiDom from '@goldwasserexchange/oui-dom';
import * as React from 'react';
import { Ramp } from '@goldwasserexchange/react-topmodel';
import { useOuiStyleMediaContextByIndex } from '../../../../MediaContext';
import { useCurrentHighlight, useCurrentHighlightList } from '../../context';

const getUpMqBorderBottomColor = (props: {
  isLast: boolean,
  isHighlightedAndWhiteListed: boolean,
  isLastHighlight: boolean,
}) => {
  const {
    isLast,
    isHighlightedAndWhiteListed,
    isLastHighlight,
  } = props;
  if (isHighlightedAndWhiteListed && isLastHighlight) {
    return ouiStyle.Constants.Colors.primary;
  }
  if (isLast) {
    return ouiStyle.Constants.Colors.transparent;
  }
  return ouiStyle.Constants.Colors.fade;
};

const getDownMqBorderBottomColor = (props: {
  isLast: boolean,
  isHighlighted: boolean,
}) => {
  const {
    isLast,
    isHighlighted,
  } = props;
  if (isLast) {
    if (isHighlighted) {
      return ouiStyle.Constants.Colors.accent;
    }
    return ouiStyle.Constants.Colors.primary;
  }
  return ouiStyle.Constants.Colors.fade;
};

export type AccountTypeBodyRowCellContainerProps = ouiDom.Table.TableBodyRowCellInStyle & {
  rowSpan: number | undefined,
  colSpan: number | undefined,
}

export const AccountTypeBodyRowCellContainer = (props: React.PropsWithChildren<AccountTypeBodyRowCellContainerProps>) => {
  const {
    children,
    ...rest
  } = props;
  const accountType = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const highlight = useCurrentHighlight();
  const secondaryDimension = ouiBase.utils.dimensions.useCurrentSecondaryDimension();
  const highlightWhiteList = useCurrentHighlightList();
  const isHighlighted = accountType === highlight;
  const isInHighlightWhiteList = highlightWhiteList.includes(secondaryDimension);
  const isHighlightedAndWhiteListed = isHighlighted && isInHighlightWhiteList;
  const currentSecondaryIndex = ouiBase.utils.dimensions.useCurrentSecondaryDimensionIndex();
  const nextSecondaryDimension = ouiBase.utils.dimensions.useSecondaryDimensionListElementByIndex(currentSecondaryIndex + 1);
  const isLast = ouiBase.utils.dimensions.useIsLastSecondaryDimensionIndex();
  const isLastHighlight = !highlightWhiteList.includes(nextSecondaryDimension);
  const upMq = useOuiStyleMediaContextByIndex(2);
  const borderBottomColor: ouiStyle.MediaQueryProp<ouiStyle.Constants.Colors> = [getDownMqBorderBottomColor({ isLast, isHighlighted }), undefined, getUpMqBorderBottomColor({ isLast, isLastHighlight, isHighlightedAndWhiteListed }), undefined, undefined, undefined];
  const dimension = ouiBase.utils.dimensions.useSecondaryDimensionList()[currentSecondaryIndex];
  return (
    <ouiDom.Table.TableBodyRowCellContainer
      display={[ouiStyle.Constants.DisplayProp.block, undefined, ouiStyle.Constants.DisplayProp.tableCell, undefined, undefined, undefined]}
      textAlign={['center', undefined, 'left', undefined, undefined, undefined]}
      backgroundColor={[ouiStyle.Constants.Colors.inverted, undefined, isHighlightedAndWhiteListed ? ouiStyle.Constants.Colors.primaryTranslucid : ouiStyle.Constants.Colors.inverted, undefined, undefined, undefined]}
      borderRightColor={[isHighlighted ? ouiStyle.Constants.Colors.accent : ouiStyle.Constants.Colors.primary, undefined, isHighlightedAndWhiteListed ? ouiStyle.Constants.Colors.primary : ouiStyle.Constants.Colors.transparent, undefined, undefined, undefined]}
      borderRightWidth={['simple', undefined, isHighlightedAndWhiteListed ? 'double' : 'none', undefined, undefined, undefined]}
      borderBottomColor={borderBottomColor}
      borderBottomWidth={['simple', undefined, 'double', undefined, undefined, undefined]}
      borderBottomLeftRadius={[isLast ? 'rounded' : 'none', undefined, isLastHighlight && isHighlightedAndWhiteListed ? 'rounded' : 'none', undefined, undefined, undefined]}
      borderBottomRightRadius={[isLast ? 'rounded' : 'none', undefined, isLastHighlight && isHighlightedAndWhiteListed ? 'rounded' : 'none', undefined, undefined, undefined]}
      borderLeftColor={[isHighlighted ? ouiStyle.Constants.Colors.accent : ouiStyle.Constants.Colors.primary, undefined, isHighlightedAndWhiteListed ? ouiStyle.Constants.Colors.primary : ouiStyle.Constants.Colors.transparent, undefined, undefined, undefined]}
      borderLeftWidth={['simple', undefined, isHighlightedAndWhiteListed ? 'double' : 'none', undefined, undefined, undefined]}
      {...rest}
    >
      {
        !upMq
          ? (
            <div
              style={{
                color: ouiStyle.Constants.Colors.accent,
              }}
            >
              <Ramp rampName={`TableSecondaryTitleCellContent-${dimension}`} />
            </div>
          )
          : null
      }
      {children}
    </ouiDom.Table.TableBodyRowCellContainer>
  );
};
