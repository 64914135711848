import { useContext } from 'react';
import { modalZIndexContext } from './context';

export const useModalZIndex = () => {
  const zIndex = useContext(modalZIndexContext);
  if (zIndex === null) {
    throw new Error('you\'re not in a modal ZIndex context');
  }
  return zIndex;
};
