import { Locale } from '@goldwasserexchange/i18n';
import ouiStyle from '@goldwasserexchange/oui-style';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { Trans } from '@lingui/macro';
import * as React from 'react';
import { ToolbarDropDown, ToolbarDropDownListContainer } from '../toolbarDropDown';
import { LanguageSelectorLabelText } from './labelText';
import { LanguageToolbarDropDownListElementLinkSwitcher } from './components';

export const AppBarLanguageSelector = () => (
  <ToolbarDropDown
    list={[Locale.FR, Locale.NL]}
  >
    <TopModel
      name="ToolbarDropDownLabelTextContent"
    >
      {LanguageSelectorLabelText}
    </TopModel>
    <TopModel
      name="ToolbarDropDownListContainer"
      left={0}
      widthLines={2.35}
      container={ouiStyle.Constants.Container.none}
    >
      {ToolbarDropDownListContainer}
    </TopModel>
    <TopModel
      name="ToolbarDropDownListElementLink-fr"
      language={Locale.FR}
    >
      {LanguageToolbarDropDownListElementLinkSwitcher}
    </TopModel>
    <TopModel
      name="ToolbarDropDownListElementContent-fr"
    >
      <Trans>
        FR
      </Trans>
    </TopModel>
    <TopModel
      name="ToolbarDropDownListElementLink-nl"
      language={Locale.NL}
    >
      {LanguageToolbarDropDownListElementLinkSwitcher}
    </TopModel>
    <TopModel
      name="ToolbarDropDownListElementContent-nl"
    >
      <Trans>
        NL
      </Trans>
    </TopModel>
  </ToolbarDropDown>
);

export default AppBarLanguageSelector;
