import { useInputType } from '../inputType';
import { useCheckboxPotentialFieldPaths } from '../valid/checkboxPotentialFieldPaths';
import { useFieldTouchedByFieldPath } from '../../../Form';
import { useGetFieldPath } from '../fieldPath';

export function useIsTouched() {
  const fieldPath = useGetFieldPath();
  const touched = useFieldTouchedByFieldPath(fieldPath);
  const checkboxPotentialFieldPaths = useCheckboxPotentialFieldPaths();
  const type = useInputType();
  const checkboxPotentialToucheds = checkboxPotentialFieldPaths.map(
    (checkboxPotentialFieldPath) => useFieldTouchedByFieldPath(checkboxPotentialFieldPath), // eslint-disable-line react-hooks/rules-of-hooks
  );
  if (type === 'checkbox') {
    return checkboxPotentialToucheds.every((checkboxPotentialTouched) => checkboxPotentialTouched.get()) && touched.get();
  }
  return touched.get();
}
