import * as React from 'react';
import { QueryResponseElement } from '../../types';
import { teamMemberIdContext } from './context';

export const TeamMemberIdContextProvider = (props: React.PropsWithChildren<{ id: QueryResponseElement['_id'] }>) => {
  const {
    id,
    children,
  } = props;
  return (
    <teamMemberIdContext.Provider value={id}>
      {children}
    </teamMemberIdContext.Provider>
  );
};
