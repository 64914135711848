import { makeName } from '../../../../../makeName';
import { makeShowMoralField } from '../../../../personType/logic/show';
import { makeTAddTypeFilterLogic } from '../../TYPE/logic';
import { TAddType, } from '../../../../../types';
var onboardingPath = 'onboarding';
var isPrintPath = 'isPrint';
var registNumPath = 'REGIST_NUM';
export var showLogic = makeShowMoralField({
    visible: makeTAddTypeFilterLogic({
        is: TAddType.MORAL,
        truthy: {
            name: "".concat(onboardingPath, ".").concat(isPrintPath),
            is: false,
            alternatives: {
                truthy: {
                    result: 'visible',
                },
                falsy: {
                    name: makeName('<parentName>', registNumPath),
                    is: null,
                    options: {
                        isNegative: true,
                    },
                    alternatives: {
                        truthy: {
                            result: 'visible',
                        },
                        falsy: {
                            result: 'invisible',
                        },
                    },
                },
            },
        },
        falsy: {
            result: 'invisible',
        },
    }),
});
