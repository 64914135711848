import { useContext } from 'react';
import { teamMemberIdContext } from './context';

export const useTeamMemberId = () => {
  const id = useContext(teamMemberIdContext);
  if (id === null) {
    throw new Error('useTeamMemberId must be used within a TeamMemberIdProvider');
  }
  return id;
};
