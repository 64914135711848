import * as React from 'react';
import { RampWithNameTransform, TopModelNameTransformProvider } from '@goldwasserexchange/react-topmodel';
import ouiBase from '@goldwasserexchange/oui';
import { useInputListPrefixer } from '../../../shared/components/list/utils';
import { BaseBuilderWithNameTransform } from '../../../../utils';

export function SelectInputListBase() {
  const listInputListPrefixer = useInputListPrefixer();
  const itemCount = ouiBase.utils.dimensions.usePrimaryDimensionLength();
  return (
    <TopModelNameTransformProvider transformFn={listInputListPrefixer}>
      <RampWithNameTransform rampName="MainContainer">
        <RampWithNameTransform rampName="MapperPlaceHolder">
          <RampWithNameTransform rampName="MapperContainer">
            <RampWithNameTransform rampName="SearchInputPlaceHolder">
              <RampWithNameTransform rampName="SearchInputContainer" />
            </RampWithNameTransform>
            <RampWithNameTransform rampName="Mapper" />
          </RampWithNameTransform>
        </RampWithNameTransform>
        {itemCount !== 0 ? (
          <RampWithNameTransform rampName="ButtonContext">
            <BaseBuilderWithNameTransform rampName="Button" />
          </RampWithNameTransform>
        ) : null}
      </RampWithNameTransform>
    </TopModelNameTransformProvider>
  );
}
