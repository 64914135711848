/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import { Trans } from '@lingui/macro';
import ouiStyle from '@goldwasserexchange/oui-style';
import ouiDom from '@goldwasserexchange/oui-dom';
import { useStateFilter } from '../Machine';
import { Service } from './atoms/Service';

const style: ouiStyle.InStyleWithMediaQueries = {
  widthLines: 16,
  paddingTop: [0, 9, undefined, 13, undefined, undefined],
};

const css = ouiStyle.mediaQuery(ouiStyle.makeCss(style));

export const OrientationInformationContent = () => {
  const isWithoutRecommendation = useStateFilter('.withoutRecommendation');
  return isWithoutRecommendation
    ? (
      <Trans>
        Vous avez choisi d'ignorer le test d'orientation, nous ne sommes donc pas en mesure
        {' '}
        d'émettre une recommandation sur le service à choisir. Merci de faire un choix entre
        {' '}
        les différents services proposés.
      </Trans>
    )
    : (
      <React.Fragment>
        <Trans>
          Sur base de vos réponses au test d'orientation, nous vous recommandons d'opter pour le
        </Trans>
        {' '}
        <ouiDom.TextSnippet.TextSnippet
          color={ouiStyle.Constants.Colors.accent}
          fontWeight={ouiStyle.Constants.FontWeightProp.bold}
        >
          Compte
          {' '}
          <Service />
        </ouiDom.TextSnippet.TextSnippet>
        .
        {' '}
        <Trans>
          Vous avez bien entendu la possibilité d'opter pour un autre service.
          {' '}
          Merci de faire un choix entre les différents services proposés.
        </Trans>
      </React.Fragment>
    );
};

export const OrientationInformation = (): JSX.Element => (
  <div
    css={css}
  >
    <OrientationInformationContent />
  </div>
);
