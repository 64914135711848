import { useContext } from 'react';
import { useHookstate } from '@hookstate/core';
import { disabledPreviousValueContext } from './context';

export function useDisabledPreviousValue() {
  const disabledPreviousValue = useContext(disabledPreviousValueContext);
  return useHookstate(disabledPreviousValue);
}

export const NoneForced = Symbol('no forced value');

export function useFieldDisabledPreviousValueByFieldPath(fieldPath: string) {
  const disabledPreviousValue = useDisabledPreviousValue();
  return useHookstate(disabledPreviousValue.nested(fieldPath));
}
