import { Trans } from '@lingui/macro';
import ouiDom from '@goldwasserexchange/oui-dom';
import * as React from 'react';

export const ChangeUser = (props: {
  setWithUserName: React.Dispatch<React.SetStateAction<boolean>>,
}) => {
  const {
    setWithUserName,
  } = props;
  const onClick = React.useCallback(() => {
    setWithUserName(true);
  }, [setWithUserName]);
  return (
    <ouiDom.Button.ButtonA
      type="button"
      onClick={onClick}
      paddingVertical="none"
    >
      <Trans>
        Changez d'utilisateur
      </Trans>
    </ouiDom.Button.ButtonA>
  );
};
