import * as React from 'react';
import { onFocusContext, OnFocusContextType } from './context';
import { useEnhanceHandler, FnMergeOrder } from '../../hooks';

export function OnFocusSetContextProvider(
  props: React.PropsWithChildren<{
    onFocus: OnFocusContextType,
  }>,
) {
  const { onFocus, children } = props;
  return (
    <onFocusContext.Provider value={onFocus}>
      {children}
    </onFocusContext.Provider>
  );
}

export function OnFocusUnsetContextProvider(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  return (
    <onFocusContext.Provider value={undefined}>
      {children}
    </onFocusContext.Provider>
  );
}

export function OnFocusEnhanceContextProvider(
  props: React.PropsWithChildren<{
    onFocus: OnFocusContextType,
    order?: FnMergeOrder,
  }>,
) {
  const {
    onFocus: onFocusProps,
    order = FnMergeOrder.before,
    children,
  } = props;
  const contextonFocus = React.useContext(onFocusContext);
  const onFocus = useEnhanceHandler(contextonFocus, onFocusProps, order);
  return (
    <onFocusContext.Provider value={onFocus}>
      {children}
    </onFocusContext.Provider>
  );
}
