import { GetAccountsPositionsHistoryResponse } from '@goldwasserexchange/actor/rest-services';
import { getHistoryLineId } from '../../../../shared';
import { useAccountPositionsHistoryLinesTransformApi } from '../api';

const transformFunction = (data: GetAccountsPositionsHistoryResponse[] | undefined) => (
  data
    ? data.map((d) => `${getHistoryLineId(d)}`)
    : []
);

export const useAccountsPositionsHistoryList = (props: {
  filterPredicate?: ((data: GetAccountsPositionsHistoryResponse) => boolean),
}) => {
  const {
    filterPredicate,
  } = props;
  const result = useAccountPositionsHistoryLinesTransformApi({
    transformFunction,
    filterPredicate,
  });
  return result;
};
