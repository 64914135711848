import * as React from 'react';
import { useHookstate } from '@hookstate/core';
import { disabledReasonContext } from './context';

export const FormDisabledReasonContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  const disabledReasonState = useHookstate<Record<string, string>>({});
  return (
    <disabledReasonContext.Provider value={disabledReasonState}>
      {children}
    </disabledReasonContext.Provider>
  );
};
