import ouiBase from '@goldwasserexchange/oui';
import * as React from 'react';
import { Search } from 'react-feather';
import { useSpring, animated } from 'react-spring';

export function DefaultDomSelectInputListSearchInputIconContent() {
  const isOpen = ouiBase.List.useSelectIsOpen();
  const style = useSpring({
    transform: isOpen ? 'scale(1)' : 'scale(0)',
  });
  return (
    <animated.div
      style={style}
    >
      <Search height="100%" width="100%" />
    </animated.div>
  );
}
