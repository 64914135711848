import * as React from 'react';
import { BaseBuilderWithNameTransform } from '../../../../../../utils';

export function ListInputLabelInfoContent() {
  return (
    <>
      <BaseBuilderWithNameTransform rampName="InputLabel" />
      <BaseBuilderWithNameTransform rampName="InputIcon" />
    </>
  );
}
