import ouiBase from '@goldwasserexchange/oui';
import { isNil } from 'ramda';
import { useCallback } from 'react';
import { makeCurrencySorter } from '../../../../../../../Components/currencySorter';
import { useBondSAccId } from '../../../shared';
import { BondSameIssuerResponse } from '../../type';
import { useBondSameIssuerFieldApiBySAccId, useBondsSameIssuerTransformApi } from '../api';

export const useBondsSameIssuerCCurrencyCode = () => {
  const sAccId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const acountPositionsCCurrencyCodeApiResponse = useBondSameIssuerFieldApiBySAccId({
    sAccId,
    field: 'C_CURRENCY.CODE',
  });
  return acountPositionsCCurrencyCodeApiResponse;
};

const makeGetCurrencies = (sAccId: number) => (securityBondSameIssuer: BondSameIssuerResponse[] | undefined) => {
  if (isNil(securityBondSameIssuer)) {
    return [];
  }
  const current = securityBondSameIssuer.find((element) => element['S_ACC.ID'] === sAccId);
  const currentCCurrencyCode = current?.['C_CURRENCY.CODE'];
  const currencySorter = makeCurrencySorter(currentCCurrencyCode);
  return securityBondSameIssuer
    .reduce(
      (acc, element) => {
        if (acc.includes(element['C_CURRENCY.CODE']) || element['S_ACC.ID'] === sAccId) {
          return acc;
        }
        return [
          ...acc,
          element['C_CURRENCY.CODE'],
        ];
      },
      [] as BondSameIssuerResponse['C_CURRENCY.CODE'][],
    )
    .sort((prev, next) => currencySorter(prev, next));
};

export const useBondsSameIssuerCCurrencyCodes = () => {
  const sAccId = useBondSAccId();
  const getCurrencies = useCallback(makeGetCurrencies(sAccId), [sAccId]);
  const response = useBondsSameIssuerTransformApi({
    transformFunction: getCurrencies,
  });
  return response;
};
