/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const onboardingStatusBannerContainerStyle: ouiStyle.InStyle = {
  textAlign: 'left',
  widthLines: 'full',
  flexDirection: ouiStyle.Constants.InFlexDirectionProp.row,
  paddingVertical: 'simple',
  paddingHorizontal: 'simple',
  backgroundColor: ouiStyle.Constants.Colors.logoBlue,
  color: ouiStyle.Constants.Colors.inverted,
  container: ouiStyle.Constants.Container.xl,
  marginVertical: 'simple',
};

export const OnboardingStatusBannerContainer = (props: React.PropsWithChildren<{
  container?: ouiStyle.InStyle['container'],
  marginVertical?: ouiStyle.InStyle['marginVertical'],
}>) => {
  const {
    container,
    marginVertical,
    children,
  } = props;
  const onboardingStatusBannerCss = React.useMemo(() => ouiStyle.makeCss({
    ...onboardingStatusBannerContainerStyle,
    container,
    marginVertical,
  }), [container, marginVertical]);
  return (
    <div
      css={onboardingStatusBannerCss}
    >
      {children}
    </div>
  );
};
