var onboardingPath = 'onboarding';
var selectedServicePath = 'selectedService';
var durabilityPath = 'durability';
var preferencePath = 'preferences';
var sfdrPath = 'sfdr';
export var dependencyFieldPaths = [
    "".concat(onboardingPath, ".").concat(selectedServicePath),
    "".concat(onboardingPath, ".").concat(durabilityPath, ".").concat(preferencePath),
    "".concat(onboardingPath, ".").concat(durabilityPath, ".").concat(sfdrPath),
];
