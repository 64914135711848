import { AccountRates, SimulationBuyAndSellCostData } from '../type';
import { computeBuyAndSellCourtage, computeBuyOrSellCourtage } from './computeCourtage';
import { computeBuyAndSellCustodyFeesAmountBuyAndSell } from './computeCustodyFeesRate';
import { computeBuyAndSellExchangeFees, computeExchangeFees } from './computeExchangeFees';
import { computeProductCostAmount } from './computeProductCost';
import { computeProductTrailerFeesAmount } from './computeProductTrailerFees';
import { computeBuyAndSellTOBAmount, computeBuyTOBAmount } from './computeTOB';
import { yearlyPct } from './yearlyPct';

export const computeBuyTotalFees = (props: SimulationBuyAndSellCostData) => (rates: AccountRates | undefined) => {
  const {
    counterValue,
    type,
    foreignCurrency,
  } = props;
  if (rates) {
    const brokerageFees = computeBuyOrSellCourtage({
      grossAmountInEur: counterValue,
      type,
      currency: 'EUR',
    })(rates);
    const tob = computeBuyTOBAmount({
      counterValue,
      type,
    })(rates['T_REL.MC_CPSFISC']);
    const exchangeFees = computeExchangeFees({
      grossAmountInEur: counterValue,
      currency: !foreignCurrency ? 'EUR' : 'USD',
    })(rates['T_ACC.FRC_RATE']);
    return tob + brokerageFees + exchangeFees;
  }
  return 0;
};

export const computeBuyTotalFeesPct = (props: SimulationBuyAndSellCostData) => (rates: AccountRates | undefined) => {
  const {
    counterValue,
  } = props;
  const total = computeBuyTotalFees(props)(rates);
  return total / counterValue;
};

export const computeBuyAndSellTotalFees = (props: SimulationBuyAndSellCostData) => (rates: AccountRates | undefined | null) => {
  const {
    counterValue,
    type,
    foreignCurrency,
    durationYears,
    refund,
  } = props;
  if (rates) {
    const brokerageFees = computeBuyAndSellCourtage({
      counterValue,
      type,
      refund,
    })(rates);
    const exchangeFees = computeBuyAndSellExchangeFees({
      counterValue,
      foreignCurrency,
      refund,
      type,
    })(rates['T_ACC.FRC_RATE']);
    const custodyFees = computeBuyAndSellCustodyFeesAmountBuyAndSell({
      counterValue,
      durationYears,
    })(rates['T_ACC.RATE_DG']);
    const TOB = computeBuyAndSellTOBAmount({
      type,
      counterValue,
      refund,
    })(rates['T_REL.MC_CPSFISC']);
    const productCost = computeProductCostAmount({
      counterValue,
      type,
      durationYears,
    });
    const trailerFees = computeProductTrailerFeesAmount({
      counterValue,
      type,
      durationYears,
    });
    return brokerageFees + exchangeFees + custodyFees + TOB + productCost + trailerFees;
  }
  return 0;
};

export const computeBuyAndSellServiceTransactionTotalFees = (props: SimulationBuyAndSellCostData) => (rates: AccountRates | undefined | null) => {
  const {
    counterValue,
    type,
    foreignCurrency,
    refund,
  } = props;
  if (rates) {
    const brokerageFees = computeBuyAndSellCourtage({
      counterValue,
      type,
      refund,
    })(rates);
    const exchangeFees = computeBuyAndSellExchangeFees({
      counterValue,
      foreignCurrency,
      refund,
      type,
    })(rates['T_ACC.FRC_RATE']);
    const TOB = computeBuyAndSellTOBAmount({
      type,
      counterValue,
      refund,
    })(rates['T_REL.MC_CPSFISC']);
    return brokerageFees + exchangeFees + TOB;
  }
  return 0;
};

export const computeBuyAndSellServiceTotalFees = (props: SimulationBuyAndSellCostData) => (rates: AccountRates | undefined | null) => {
  const {
    counterValue,
    type,
    foreignCurrency,
    refund,
    durationYears,
  } = props;
  if (rates) {
    const brokerageFees = computeBuyAndSellCourtage({
      counterValue,
      type,
      refund,
    })(rates);
    const exchangeFees = computeBuyAndSellExchangeFees({
      counterValue,
      foreignCurrency,
      refund,
      type,
    })(rates['T_ACC.FRC_RATE']);
    const TOB = computeBuyAndSellTOBAmount({
      type,
      counterValue,
      refund,
    })(rates['T_REL.MC_CPSFISC']);
    const custodyFees = computeBuyAndSellCustodyFeesAmountBuyAndSell({
      counterValue,
      durationYears,
    })(rates['T_ACC.RATE_DG']);
    return brokerageFees + exchangeFees + TOB + custodyFees;
  }
  return 0;
};

export const computeBuyAndSellTotalFeesPct = (props: SimulationBuyAndSellCostData) => (rates: AccountRates | undefined | null) => {
  const {
    counterValue,
    durationYears,
  } = props;
  const total = computeBuyAndSellTotalFees(props)(rates);
  return yearlyPct({
    counterValue,
    durationYears,
  })(total);
};

export const computeBuyAndSellServiceTransactionTotalFeesPct = (props: SimulationBuyAndSellCostData) => (rates: AccountRates | undefined | null) => {
  const {
    counterValue,
    durationYears,
  } = props;
  const total = computeBuyAndSellServiceTransactionTotalFees(props)(rates);
  return yearlyPct({
    counterValue,
    durationYears,
  })(total);
};

export const computeBuyAndSellServiceTotalFeesPct = (props: SimulationBuyAndSellCostData) => (rates: AccountRates | undefined | null) => {
  const {
    counterValue,
    durationYears,
  } = props;
  const total = computeBuyAndSellServiceTotalFees(props)(rates);
  return yearlyPct({
    counterValue,
    durationYears,
  })(total);
};
