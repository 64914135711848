import { Ramp, TopModelChildrenMerge } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import * as fields from './fields';
import { list } from './list';
var Mapper = function (props) {
    var parentName = props.parentName;
    var showList = fields.logic.show.useShowList(parentName);
    return (React.createElement(React.Fragment, null, list.filter(function (_, index) { return showList[index]; }).map(function (column) { return (React.createElement(Ramp, { key: column, rampName: "FinancialSituationQuestionnaireInput", parentName: parentName, column: column })); })));
};
export var Render = function (props) {
    var parentName = props.parentName, children = props.children;
    return (React.createElement(TopModelChildrenMerge, null,
        children,
        React.createElement(Mapper, { parentName: parentName })));
};
