import ouiBase from '@goldwasserexchange/oui';
import { currencyIsEur } from '../../../../shared';
import { AccountPosition } from '../../type';
import { useAccountPositionTransformApiByGesEstimDId } from '../api';

const accountPositionsCCurrencyCodeIsNotEurAndCurrencyTAccCodeIsEur = (line: AccountPosition | null | undefined) => {
  if (line) {
    const cCurrencyTAccCodeIsEur = currencyIsEur(line['C_CURRENCY_T_ACC.CODE']);
    const cCurrencyCodeIsEur = currencyIsEur(line['C_CURRENCY.CODE']);
    return cCurrencyTAccCodeIsEur && !cCurrencyCodeIsEur;
  }
  return false;
};

export const useAccountPositionsCCurrencyCodeIsNotEurAndCCurrencyTAccCodeIsEur = () => {
  const gesEstimDId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const result = useAccountPositionTransformApiByGesEstimDId({
    gesEstimDId,
    transformFunction: accountPositionsCCurrencyCodeIsNotEurAndCurrencyTAccCodeIsEur,
  });
  return result;
};
