import * as React from 'react';
import { RampWithNameTransform, TopModelNameTransformProvider } from '@goldwasserexchange/react-topmodel';
import { useInputListPrefixer } from '../../../utils';

export function ListInputListBase() {
  const listInputListPrefixer = useInputListPrefixer();
  return (
    <TopModelNameTransformProvider transformFn={listInputListPrefixer}>
      <RampWithNameTransform rampName="Container">
        <RampWithNameTransform rampName="Mapper" />
      </RampWithNameTransform>
    </TopModelNameTransformProvider>
  );
}
