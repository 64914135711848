import * as fields from './fields';
export var dependencyFieldPaths = {
    preferences: fields.preferences.dependencyFieldPaths,
    taxonomy: fields.taxonomy.dependencyFieldPaths,
    taxonomyAlignment: fields.taxonomyAlignment.dependencyFieldPaths,
    taxonomyProportion: fields.taxonomyProportion.dependencyFieldPaths,
    sfdr: fields.sfdr.dependencyFieldPaths,
    sfdrDurableProportion: fields.sfdrDurableProportion.dependencyFieldPaths,
    sfdrSocialEnvironmentalProportion: fields.sfdrSocialEnvironmentalProportion.dependencyFieldPaths,
    sfdrDurableOnly: fields.sfdrDurableOnly.dependencyFieldPaths,
    sfdrSocialEnvironmentalOnly: fields.sfdrSocialEnvironmentalOnly.dependencyFieldPaths,
    incidence: fields.incidence.dependencyFieldPaths,
    incidenceType: fields.incidenceType.dependencyFieldPaths,
    incidenceProportion: fields.incidenceProportion.dependencyFieldPaths,
};
