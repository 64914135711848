import { GetBondsBondParams } from '@goldwasserexchange/actor/rest-services';
import { useContext, useMemo } from 'react';
import { useAuthIsSettle } from '../../../../../../Components/Auth';
import { useCognitoUserValidAccounts } from '../../../../../../Components/Sections/online/selectedAccount';
import { stringifyParams } from '../../../shared/utils/makeUrl';
import { useBondSAccId } from '../../shared';
import { bondsBondParamsContext } from '../context';

export const useBondsBondDefaultParams = (params: Partial<GetBondsBondParams>): GetBondsBondParams | null => {
  const {
    'S_ACC.ID': contextSAccId,
  } = useContext(bondsBondParamsContext);
  const bondSAccId = useBondSAccId();
  const sAccId = params['S_ACC.ID'] ?? bondSAccId ?? contextSAccId;
  const defaultParams = useMemo(() => (sAccId != null && sAccId >= 0
    ? ({
      'S_ACC.ID': sAccId,
    })
    : null
  ), [
    sAccId,
  ]);
  return defaultParams;
};

export const useBondsBondStringifiedParams = () => {
  const contextParams = useBondsBondDefaultParams({});
  const accounts = useCognitoUserValidAccounts();
  const isAuthMachineSettled = useAuthIsSettle();
  return contextParams !== null && isAuthMachineSettled ? stringifyParams({
    ...contextParams,
    CONNECTED: accounts.length > 0 ? 1 : 0,
  }) : null;
};
