/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const defaultDomSelectInputListMapperPlaceHolderStyle: ouiStyle.InStyle = {
  position: 'relative',
  lines: 1,
  childPaddingBottom: 'simple',
  childPaddingLeft: 'simple',
  childPaddingRight: 'simple',
  childPaddingTop: 'simple',
  flexGrow: true,
  flexShrink: false,
};

const defaultDomSelectInputListMapperPlaceHolderCss = ouiStyle.makeCss(
  defaultDomSelectInputListMapperPlaceHolderStyle,
);

export function DefaultDomSelectInputListMapperPlaceHolder(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  return (
    <div css={defaultDomSelectInputListMapperPlaceHolderCss}>{children}</div>
  );
}
