import { Locale, i18n } from '@goldwasserexchange/i18n';
import { I18n } from '@lingui/core';

export const languageI18n = i18n;

export const languageRouteDefinition: Record<Locale, { importFn: (i18n?: I18n) => Promise<void>}> = {
  [Locale.FR]: {
    importFn: () => import('@goldwasserexchange/i18n/src/frontend/locale/fr/messages.js').then((module) => {
      const {
        messages,
      } = module;
      languageI18n.load(Locale.FR, messages);
      languageI18n.activate(Locale.FR);
    }),
  },
  [Locale.NL]: {
    importFn: () => import('@goldwasserexchange/i18n/src/frontend/locale/nl/messages.js').then((module) => {
      const {
        messages,
      } = module;
      languageI18n.load(Locale.NL, messages);
      languageI18n.activate(Locale.NL);
    }),
  },
};
