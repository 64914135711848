/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { animated } from 'react-spring';
import { inputIconInnerContainerStyle } from '../style';

const TextInputErrorIconContainerStyle: ouiStyle.InStyle = {
  ...inputIconInnerContainerStyle,
  color: ouiStyle.Constants.Colors.danger,
};

const TextInputErrorIconContainerCss = ouiStyle.makeCss(
  TextInputErrorIconContainerStyle,
);

export function DomInputErrorIconContainer(
  props: React.PropsWithChildren<{ style: any }>,
) {
  const { style: propStyle, children } = props;
  return (
    <animated.div css={TextInputErrorIconContainerCss} style={propStyle}>
      {children}
    </animated.div>
  );
}
