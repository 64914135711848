import * as TYPE from '../../TYPE';
import { TAddType } from '../../../../../types';
export var showLogic = TYPE.logic.makeTAddTypeFilterLogic({
    is: [TAddType.IGNORED, TAddType.MORAL, TAddType.UBO],
    options: {
        isNegative: true,
    },
});
export var showLogicWithUbo = TYPE.logic.makeTAddTypeFilterLogic({
    is: [TAddType.IGNORED, TAddType.MORAL],
    options: {
        isNegative: true,
    },
});
