import { DataPoint } from './types';

/**
 * Computes the sum of x^power * y for all DataPoints in an array.
 *
 * @param {DataPoint[]} data - The array of DataPoints to compute the sum for.
 * @param {keyof DataPoint} xKey - The key in the DataPoint object for the x value.
 * @param {keyof DataPoint} yKey - The key in the DataPoint object for the y value.
 * @param {number} power - The power to raise x to.
 * @returns {number} The sum of x^power * y for all DataPoints in the array.
 */
export const sumPowTimes = (
  data: DataPoint[],
  xKey: keyof DataPoint,
  yKey: keyof DataPoint,
  power: number,
) => {
  let sum = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < data.length; i++) {
    const x = data[i][xKey];
    const y = data[i][yKey];
    if (x != null && y != null) {
      sum += x ** power * y;
    }
  }
  return sum;
};
