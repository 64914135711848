import { useCallback } from 'react';
import { getFormat } from '../../../../../../../Components/NumberDisplay/utils/getFormat';
import { useLinguiLocale } from '../../../../../../../Components/useLinguiLocale';
import { BondResponse } from '../../type';
import { useBondsBondTransformApi } from '../api';

const useMakeTransformFn = () => {
  const locale = useLinguiLocale();
  const formatWithDefaults = getFormat({
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  const intlFormater = useCallback((v: number) => new Intl.NumberFormat(locale, formatWithDefaults).format(v), [locale, formatWithDefaults]);
  return useCallback((line: BondResponse | undefined) => {
    if (!line) {
      return undefined;
    }
    const qtMin = line['S_MARKET.QT_MIN'];
    const quotMul = line['S_MARKET.QUOT_MULT'];
    if (qtMin === quotMul) {
      return intlFormater(qtMin);
    }
    return `${intlFormater(qtMin)} + ${intlFormater(quotMul)}`;
  }, []);
};

export const useBondsBondQMarketQtMinAndQuotMul = () => {
  const transformFn = useMakeTransformFn();
  const response = useBondsBondTransformApi(transformFn);
  return response;
};
