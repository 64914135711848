import * as React from 'react';
import { onMouseEnterContext, OnMouseEnterContextType } from './context';
import { useEnhanceHandler, FnMergeOrder } from '../../hooks';

export function OnMouseEnterSetContextProvider(
  props: React.PropsWithChildren<{
    onMouseEnter: OnMouseEnterContextType,
  }>,
) {
  const { onMouseEnter, children } = props;
  return (
    <onMouseEnterContext.Provider value={onMouseEnter}>{children}</onMouseEnterContext.Provider>
  );
}

export function OnMouseEnterUnsetContextProvider(props: React.PropsWithChildren<{}>) {
  const { children } = props;
  return (
    <onMouseEnterContext.Provider value={undefined}>
      {children}
    </onMouseEnterContext.Provider>
  );
}

export function OnMouseEnterEnhanceContextProvider(
  props: React.PropsWithChildren<{
    onMouseEnter: OnMouseEnterContextType,
    order?: FnMergeOrder,
  }>,
) {
  const { onMouseEnter: onMouseEnterProps, order = FnMergeOrder.before, children } = props;
  const contextonMouseEnter = React.useContext(onMouseEnterContext);
  const onMouseEnter = useEnhanceHandler(contextonMouseEnter, onMouseEnterProps, order);
  return (
    <onMouseEnterContext.Provider value={onMouseEnter}>{children}</onMouseEnterContext.Provider>
  );
}
