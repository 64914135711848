import * as React from 'react';
import { ActorApiNumberDisplay } from '../../../../../../components/display/number/component';
import { useAccountPositionGesEstimDPcPv } from '../../hooks';

export const AccountsPositionsGesEstimDPcPv = () => {
  const {
    data,
    isLoading,
    error,
  } = useAccountPositionGesEstimDPcPv();
  return (
    <ActorApiNumberDisplay
      data={data}
      isLoading={isLoading}
      error={error}
      format={{
        style: 'percent',
      }}
    />
  );
};
