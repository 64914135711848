/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const listInputLabelInfoContainerStyle: ouiStyle.InStyle = {
  display: ouiStyle.Constants.DisplayProp.flex,
};

const listInputLabelInfoContainerCss = ouiStyle.makeCss(
  listInputLabelInfoContainerStyle,
);

export function ListInputLabelInfoContainer(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  return <div css={listInputLabelInfoContainerCss}>{children}</div>;
}
