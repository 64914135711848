/* eslint-disable no-underscore-dangle */
import { sanityClient } from '../../../client';
import { slugify } from '../../../utils';
import { query } from './query';
import {
  QueryArguments,
  BaseQueryResponseWithTranslation,
  QueryResponse,
  SwrArguments,
  BaseTranslation,
} from './types';

export const convertSwrArguments = (args: SwrArguments): QueryArguments => {
  const [, id] = args;
  return {
    id,
  };
};

export const convertQueryArguments = (args: QueryArguments): SwrArguments | null => {
  const {
    id,
  } = args;
  if (id == null) {
    return null;
  }
  return [query, id];
};

export const fetcher = async (args: SwrArguments): Promise<QueryResponse | null> => {
  const [queryString] = args;
  const sanityArguments = convertSwrArguments(args);
  const baseResponse = await sanityClient.fetch<BaseQueryResponseWithTranslation | null, QueryArguments>(queryString, sanityArguments);
  if (baseResponse == null) {
    return null;
  }
  const localeTranslation = baseResponse.translations.find((translation) => translation?.language === baseResponse.language);
  const translations = [
    ...baseResponse.translations,
    ...(localeTranslation == null
      ? [{
        _id: baseResponse._id,
        publishDate: baseResponse.publishDate,
        language: baseResponse.language,
        title: baseResponse.title,
      }]
      : []
    ),
  ];
  const response = {
    ...baseResponse,
    slug: slugify({
      locale: baseResponse.language,
      title: baseResponse.title,
      date: baseResponse.publishDate,
      id: baseResponse._id,
    }),
    translations: translations
      .filter<BaseTranslation>((translation): translation is BaseTranslation => translation != null)
      .map((translation) => ({
        ...translation,
        slug: slugify({
          locale: translation.language,
          title: translation.title,
          date: translation.publishDate,
          id: translation._id,
        }),
      })) ?? [],
    bondIds: (baseResponse.bondIds?.map((id) => parseInt(id, 10))?.filter((id) => !Number.isNaN(id)) ?? []),
    content: baseResponse.content.map((block) => {
      if (!block.markDefs) {
        return block;
      }
      return {
        ...block,
        markDefs: block.markDefs.map((markDef) => {
          if (markDef._type === 'internalLink' && markDef.reference) {
            return {
              ...markDef,
              reference: {
                ...markDef.reference,
                slug: slugify({
                  locale: markDef.reference.language,
                  title: markDef.reference.title,
                  date: markDef.reference.publishDate,
                  id: markDef.reference._id,
                }),
              },
            };
          }
          return markDef;
        }),
      };
    }),
    latestNews: baseResponse.latestNews != null
      ? baseResponse.latestNews.map((news) => ({
        ...news,
        slug: slugify({
          locale: baseResponse.language,
          title: news.title,
          date: news.publishDate,
          id: news._id,
        }),
      }))
      : [],
  };
  return response;
};
