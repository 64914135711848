import ouiStyle from '@goldwasserexchange/oui-style';
import ouiDom from '@goldwasserexchange/oui-dom';
import * as React from 'react';

export const StepContainer = (props: React.PropsWithChildren<{ large?: boolean }>) => {
  const { large, children } = props;
  return (
    <ouiDom.HeadingSection.HeadingSection
      index={0}
      name="step"
      container={large ? ouiStyle.Constants.Container.xl : ouiStyle.Constants.Container.slim}
      paddingBottom="simple"
      paddingTop="double"
      paddingLeft="simple"
      paddingRight="simple"
    >
      {children}
    </ouiDom.HeadingSection.HeadingSection>
  );
};
