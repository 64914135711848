import ouiStyle from '@goldwasserexchange/oui-style';
import { Trans } from '@lingui/macro';
import * as React from 'react';
import { UncastedDataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import ouiBase from '@goldwasserexchange/oui';
import { SubmitButton } from '../../../../../../../OUI/Form';

export const ForgotPasswordInputUserNameSubmitButton = () => {
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  return (
    <SubmitButton
      paths={[
        valueState.auth.username.path.join('.'),
        valueState.auth.recaptcha.path.join('.'),
      ]}
      backgroundColor={ouiStyle.Constants.Colors.accent}
      color={ouiStyle.Constants.Colors.inverted}
      hoverBackgroundColor={ouiStyle.Constants.Colors.accentShaded}
    >
      <Trans>
        Soumettre
      </Trans>
    </SubmitButton>
  );
};
