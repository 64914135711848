import {
  format,
} from 'date-fns';
import { parseActorDate } from '../../../shared/utils/parseDates';
import { STypeGrpIds } from '../../../shared/utils/sTypeGrpIds';
import { BondList } from '../../bonds';
import { BondResponse } from '../../bonds/bond/type';
import { BondSameIssuerResponse } from '../../bonds/sameIssuer/type';
import { getTimeDifferenceString } from './getTimeDifferenceString';

export const makeGetOblEndDateWithPerpetualAndDifference = (up: boolean) => (listBond?: BondList | BondResponse | BondSameIssuerResponse) => {
  if (!listBond) {
    return undefined;
  }
  if (listBond['S_TYPE_GRP.ID'] === STypeGrpIds.OBLIGATIONS_PERP) {
    return '∞';
  }
  const oblEndDate = parseActorDate(listBond['S_ACC.OBL_END_DATE']);
  const date = format(oblEndDate, 'dd/MM/yyyy');
  return up
    ? `${date} (${getTimeDifferenceString(oblEndDate)})`
    : date;
};

export const makeGetOblEndDateDifference = (withParentheses?: boolean) => (listBond?: BondList | BondResponse | BondSameIssuerResponse) => {
  if (!listBond) {
    return undefined;
  }
  if (listBond['S_TYPE_GRP.ID'] === STypeGrpIds.OBLIGATIONS_PERP) {
    return null;
  }
  const oblEndDate = parseActorDate(listBond['S_ACC.OBL_END_DATE']);
  const difference = getTimeDifferenceString(oblEndDate);
  return withParentheses === true
    ? `(${difference})`
    : difference;
};
