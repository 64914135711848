import { sumPow } from './sumPow';
import { sumPowTimes } from './sumPowTimes';
import { DataPoint, UniDimensionalMatrix } from './types';

/**
 * Generates the right matrix for the Gaussian Jordan Elimination given the data and degree.
 * The right matrix is a unidimensional array.
 *
 * @param {DataPoint[]} data Array of data points.
 * @param {number} degree Degree of the polynomial.
 *
 * @returns The right matrix as a unidimensional array.
 */
export const generateRightMatrix = (
  data: DataPoint[],
  degree: number,
): UniDimensionalMatrix => {
  const rightMatrix: UniDimensionalMatrix = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i <= degree; i++) {
    if (i === 0) {
      rightMatrix[i] = sumPow(data, 'y', 1);
    } else {
      rightMatrix[i] = sumPowTimes(data, 'x', 'y', i);
    }
  }
  return rightMatrix;
};
