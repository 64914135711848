import { useFilterByTAccId } from '../../utils';
import { usePersonAccountFieldApi } from '../api';

export const usePersonAccountsTAccEstimGloIntCur = () => {
  const filterPredicate = useFilterByTAccId();
  const result = usePersonAccountFieldApi({
    filterPredicate,
    field: 'T_ACC.ESTIM_GLO_INT_CUR',
  });
  return result;
};
