export var initialConseilGestionValues = function () { return ({
    stockCurrencyRiskAnswer: undefined,
    stockCurrencyRiskIsCorrect: undefined,
    stockCurrencyRiskWasRetried: false,
    stockCurrencyRiskIsCorrected: false,
    stockDefaultAnswer: undefined,
    stockDefaultIsCorrect: undefined,
    stockDefaultWasRetried: false,
    stockDefaultIsCorrected: false,
    stockDefinitionAnswer: undefined,
    stockDefinitionIsCorrect: undefined,
    stockDefinitionWasRetried: false,
    stockDefinitionIsCorrected: false,
    stockSaleAnswer: undefined,
    stockSaleIsCorrect: undefined,
    stockSaleWasRetried: false,
    stockSaleIsCorrected: false,
    stockVariationAnswer: undefined,
    stockVariationIsCorrect: undefined,
    stockVariationWasRetried: false,
    stockVariationIsCorrected: false,
}); };
