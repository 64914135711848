import * as React from 'react';
import { ActorApiStringDisplay } from '../../../../../../components/display/string';
import { useBondsBondIsSomeRedacted, useBondsBondQMarketQtMinAndQuotMul } from '../../hooks';

export const BondsBondSMarketQtMinAndQuoteMul = (props: {
  withRedactedIcon?: boolean,
}) => {
  const {
    withRedactedIcon,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useBondsBondQMarketQtMinAndQuotMul();
  const {
    data: redacted,
    isLoading: redactedIsLoading,
  } = useBondsBondIsSomeRedacted(['S_MARKET.QT_MIN', 'S_MARKET.QUOT_MULT']);
  return (
    <ActorApiStringDisplay
      data={data}
      isLoading={isLoading || redactedIsLoading}
      error={error}
      loadingValue="------ - ------"
      isBlocked={redacted}
      withRedactedIcon={withRedactedIcon}
    />
  );
};
