import { subDays, isAfter } from 'date-fns';
import { parseActorDate } from '../../../../../shared/utils/parseDates';
import { BondList } from '../../type';

export const isNewBondList = (bondList?: BondList): boolean => {
  if (!bondList) {
    return false;
  }
  const addedToBasket = parseActorDate(bondList['S_ACC.CREATE_AT']);
  const sevenDaysAgo = subDays(new Date(), 7);
  return isAfter(addedToBasket, sevenDaysAgo);
};
