import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import NavBarAnchorButton from '../../navBarLink/navBarLinkButton';
import NavBarButtonListElement from '../../navBarListElement/buttonListElement';
import { ValidSections } from '../../../../../history';

export const NavBarButtonLinkElement = (
  props: React.PropsWithChildren<{
    backgroundColor: ouiStyle.InStyle['backgroundColor'],
    hoverBackgroundColor: ouiStyle.InStyle['backgroundColor'],
  } & ({ href: string, target: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>['target'] } | { section: ValidSections, replace?: boolean })>,
): JSX.Element => {
  const {
    backgroundColor, hoverBackgroundColor, children,
  } = props;
  if ('href' in props) {
    const { href, target } = props;
    return (
      <NavBarButtonListElement>
        <NavBarAnchorButton
          href={href}
          target={target}
          backgroundColor={backgroundColor}
          hoverBackgroundColor={hoverBackgroundColor}
        >
          {children}
        </NavBarAnchorButton>
      </NavBarButtonListElement>
    );
  }
  const {
    section,
    replace,
  } = props;
  return (
    <NavBarButtonListElement>
      <NavBarAnchorButton
        section={section}
        replace={replace}
        backgroundColor={backgroundColor}
        hoverBackgroundColor={hoverBackgroundColor}
      >
        {children}
      </NavBarAnchorButton>
    </NavBarButtonListElement>
  );
};

export default NavBarButtonLinkElement;
