/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import { animated } from 'react-spring';
import ouiBase from '@goldwasserexchange/oui';
import ouiStyle from '@goldwasserexchange/oui-style';
import {
  InputVariantsNames,
  useDisabledVariant,
  useValidationVariant,
  useHasFocusVariant,
  useBaseVariant,
  InputVariantHooksArray,
} from '../../../../../../../../../OUI/Inputs';
import { useVariantSpring } from '../../../../../../../utils';

export const defaultSelectInputListElementContainerStyle: ouiStyle.InStyle = {
  display: ouiStyle.Constants.DisplayProp.flex,
  fontSize: 'xs',
  alignItems: ouiStyle.Constants.AlignItemsProp.stretch,
  borderBottomWidth: 'simple',
  borderBottomStyle: 'solid',
  borderBottomColor: ouiStyle.Constants.Colors.primary,
  backgroundColor: ouiStyle.Constants.Colors.inverted,
  childPaddingBottom: 'simple',
  childPaddingTop: 'simple',
};

const baseDomSelectInputListElementContainerCss = ouiStyle.makeCssAndExtract(
  defaultSelectInputListElementContainerStyle,
  ['borderBottomWidth', 'borderBottomStyle', 'borderBottomColor'],
);

const focusDomSelectInputListElementContainerStyle: ouiStyle.InStyle = {
  ...defaultSelectInputListElementContainerStyle,
  borderBottomColor: ouiStyle.Constants.Colors.accent,
};

const focusDomSelectInputListElementContainerCss = ouiStyle.makeCssAndExtract(
  focusDomSelectInputListElementContainerStyle,
  ['borderBottomColor'],
);

const errorDomSelectInputListElementContainerStyle: ouiStyle.InStyle = {
  ...defaultSelectInputListElementContainerStyle,
  borderBottomColor: ouiStyle.Constants.Colors.danger,
};

const errorDomSelectInputListElementContainerCss = ouiStyle.makeCssAndExtract(
  errorDomSelectInputListElementContainerStyle,
  ['borderBottomColor'],
);

const disabledDomSelectInputListElementContainerStyle: ouiStyle.InStyle = {
  ...defaultSelectInputListElementContainerStyle,
  borderBottomColor: ouiStyle.Constants.Colors.disabled,
};

const disabledDomSelectInputListElementContainerCss = ouiStyle.makeCssAndExtract(
  disabledDomSelectInputListElementContainerStyle,
  ['borderBottomColor'],
);

const isLastDomSelectInputListElementContainerStyle: ouiStyle.InStyle = {
  ...defaultSelectInputListElementContainerStyle,
  borderBottomWidth: 'none',
  borderBottomStyle: 'none',
};

const isLastDomSelectInputListElementContainerCss = ouiStyle.makeCssAndExtract(
  isLastDomSelectInputListElementContainerStyle,
  ['borderBottomWidth', 'borderBottomStyle'],
);

export const DomSelectInputListElementContainerVariants: Record<
InputVariantsNames,
Partial<
Pick<ouiStyle.Style, 'borderBottomWidth' | 'borderBottomStyle' | 'borderBottomColor'>
>
> = {
  opened: {},
  closed: {},
  base: baseDomSelectInputListElementContainerCss,
  hasContent: {},
  noContent: {},
  focus: focusDomSelectInputListElementContainerCss,
  noFocus: {},
  untouched: {},
  valid: {},
  error: errorDomSelectInputListElementContainerCss,
  disabled: disabledDomSelectInputListElementContainerCss,
  enabled: {},
  checked: {},
  unChecked: {},
  last: isLastDomSelectInputListElementContainerCss,
  notLast: {},
  before: {},
  inside: {},
  after: {},
  noSelection: {},
  notCorrected: {},
  correct: {},
  incorrect: {},
  correctSelected: {},
  correctNotSelected: {},
  incorrectSelected: {},
  incorrectNotSelected: {},
};

export function useMakeDefaultSelectInputListElementContainerCss() {
  const lines = ouiBase.List.useListInputElementLines();
  const childPaddings = ouiBase.List.useChildPaddings();
  const css = React.useMemo(() => ouiStyle.mediaQuery(ouiStyle.makeCss({
    ...defaultSelectInputListElementContainerStyle,
    ...childPaddings,
    lines,
  })), [lines, childPaddings]);
  return css;
}

export const selectInputListElementContainerHookVariants: InputVariantHooksArray = [
  useBaseVariant,
  useHasFocusVariant,
  useValidationVariant,
  useDisabledVariant,
  ouiBase.utils.dimensions.useIsLastPrimaryDimensionVariant,
];

export function DefaultSelectInputListElementContainer(
  props: React.PropsWithChildren<{ style?: React.CSSProperties }>,
) {
  const { style: propsStyle, children } = props;
  const css = useMakeDefaultSelectInputListElementContainerCss();
  const variantStyle = useVariantSpring(
    DomSelectInputListElementContainerVariants,
    selectInputListElementContainerHookVariants,
  );
  const style = React.useMemo(() => ({ ...variantStyle, ...propsStyle }), [
    variantStyle,
    propsStyle,
  ]);
  return (
    <animated.li css={css} style={style}>
      {children}
    </animated.li>
  );
}
