import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { useSpring, SpringValue } from 'react-spring';
import { ArchiveIcon } from './archiveIcon';
import { ExcelIcon } from './excelIcon';
import { FileIcon } from './fileIcon';
import { ImageIcon } from './imageIcon';
import { PDFIcon } from './pdfIcon';
import { PowerPointIcon } from './powerPointIcon';
import { SvgContainer } from './svgContainer';
import { TextIcon } from './textIcon';
import { WordIcon } from './wordIcon';

const fileTypes = {
  pdf: PDFIcon,
  zip: ArchiveIcon,
  rar: ArchiveIcon,
  png: ImageIcon,
  gif: ImageIcon,
  jpg: ImageIcon,
  jpeg: ImageIcon,
  svg: ImageIcon,
  tiff: ImageIcon,
  ppt: PowerPointIcon,
  pptx: PowerPointIcon,
  xls: ExcelIcon,
  xlsx: ExcelIcon,
  csv: ExcelIcon,
  doc: WordIcon,
  docx: WordIcon,
  txt: TextIcon,
  default: FileIcon,
};

export const FileTypeIcon = (props: {
  fileType?: string,
  stroke?: ouiStyle.Constants.Colors | SpringValue<ouiStyle.Constants.Colors>,
  fill?: ouiStyle.Constants.Colors,
  size?: string | number,
}) => {
  const {
    fileType = 'default',
    fill,
    stroke = ouiStyle.Constants.Colors.primary,
    size,
  } = props;
  const Icon = fileTypes[fileType] ?? fileTypes.default;
  return (
    <SvgContainer
      stroke={stroke}
      fill={fill}
      size={size}
    >
      <Icon />
    </SvgContainer>
  );
};

export const LoadingFileTypeIcon = (props: {
  isLoading: boolean,
  fileType?: string,
  fill?: ouiStyle.Constants.Colors,
  size?: string | number,
  stroke?: ouiStyle.Constants.Colors,
  loadingStrokeStart?: ouiStyle.Constants.Colors,
  loadingStrokeEnd?: ouiStyle.Constants.Colors,
}) => {
  const {
    fileType,
    fill,
    size,
    isLoading,
    stroke = ouiStyle.Constants.Colors.primary,
    loadingStrokeStart = ouiStyle.Constants.Colors.fade,
    loadingStrokeEnd = ouiStyle.Constants.Colors.fadeDarker,
  } = props;
  const { stroke: strokeSpring } = useSpring({
    from: { stroke: !isLoading ? stroke : loadingStrokeStart },
    stroke: !isLoading ? stroke : loadingStrokeEnd,
    loop: { reverse: true },
    config: {
      duration: 1600,
      clamp: true,
    },
  });
  return (
    <FileTypeIcon
      fileType={fileType}
      fill={fill}
      size={size}
      stroke={strokeSpring}
    />
  );
};
