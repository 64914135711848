import * as React from 'react';
import { BaseBuilderWithNameTransform } from '../../../../utils';

export function DefaultTextInputPlaceHolderContent() {
  return (
    <>
      <BaseBuilderWithNameTransform rampName="InputLabel" />
      <BaseBuilderWithNameTransform rampName="InputMain" />
    </>
  );
}
