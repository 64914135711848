import { useCallback } from 'react';
import { useSimulationDataBuyAndSellContext } from '../../context/simulation/hooks';
import { SimulationBuyAndSellCostData } from '../../type';
import {
  computeBuyAndSellServiceTotalFees,
  computeBuyAndSellServiceTotalFeesPct,
  computeBuyAndSellServiceTransactionTotalFees,
  computeBuyAndSellServiceTransactionTotalFeesPct,
  computeBuyAndSellTotalFees, computeBuyAndSellTotalFeesPct, computeBuyTotalFees, computeBuyTotalFeesPct,
} from '../../utils';
import { useAccountRateTransformApi } from '../api';

export const useAccountRatesTAccBuyTotalFeeAmount = () => {
  const {
    counterValue,
    durationYears,
    type,
    foreignCurrency,
    refund,
  } = useSimulationDataBuyAndSellContext();
  const transformFunction = useCallback(computeBuyTotalFees({
    counterValue,
    durationYears,
    type,
    foreignCurrency,
    refund,
  }), [
    counterValue,
    durationYears,
    type,
    foreignCurrency,
    refund,
  ]);
  const result = useAccountRateTransformApi({
    transformFunction,
  });
  return result;
};

export const useAccountRatesTAccBuyTotalFeePct = () => {
  const {
    counterValue,
    durationYears,
    type,
    foreignCurrency,
    refund,
  } = useSimulationDataBuyAndSellContext();
  const transformFunction = useCallback(computeBuyTotalFeesPct({
    counterValue,
    durationYears,
    type,
    foreignCurrency,
    refund,
  }), [
    counterValue,
    durationYears,
    type,
    foreignCurrency,
    refund,
  ]);
  const result = useAccountRateTransformApi({
    transformFunction,
  });
  return result;
};

export const useAccountRatesTAccBuyAndSellServiceTransactionTotalFeeAmountOnContext = () => {
  const {
    counterValue,
    durationYears,
    type,
    foreignCurrency,
    refund,
  } = useSimulationDataBuyAndSellContext();
  const transformFunction = useCallback(computeBuyAndSellServiceTransactionTotalFees({
    counterValue,
    durationYears,
    type,
    foreignCurrency,
    refund,
  }), [
    counterValue,
    durationYears,
    type,
    foreignCurrency,
    refund,
  ]);
  const result = useAccountRateTransformApi({
    transformFunction,
  });
  return result;
};

export const useAccountRatesTAccBuyAndSellServiceTotalFeeAmountOnContext = () => {
  const {
    counterValue,
    durationYears,
    type,
    foreignCurrency,
    refund,
  } = useSimulationDataBuyAndSellContext();
  const transformFunction = useCallback(computeBuyAndSellServiceTotalFees({
    counterValue,
    durationYears,
    type,
    foreignCurrency,
    refund,
  }), [
    counterValue,
    durationYears,
    type,
    foreignCurrency,
    refund,
  ]);
  const result = useAccountRateTransformApi({
    transformFunction,
  });
  return result;
};

export const useAccountRatesTAccBuyAndSellServiceTransactionTotalFeePct = () => {
  const {
    counterValue,
    durationYears,
    type,
    foreignCurrency,
    refund,
  } = useSimulationDataBuyAndSellContext();
  const transformFunction = useCallback(computeBuyAndSellServiceTransactionTotalFeesPct({
    counterValue,
    durationYears,
    type,
    foreignCurrency,
    refund,
  }), [
    counterValue,
    durationYears,
    type,
    foreignCurrency,
  ]);
  const result = useAccountRateTransformApi({
    transformFunction,
  });
  return result;
};

export const useAccountRatesTAccBuyAndSellServiceTotalFeePct = () => {
  const {
    counterValue,
    durationYears,
    type,
    foreignCurrency,
    refund,
  } = useSimulationDataBuyAndSellContext();
  const transformFunction = useCallback(computeBuyAndSellServiceTotalFeesPct({
    counterValue,
    durationYears,
    type,
    foreignCurrency,
    refund,
  }), [
    counterValue,
    durationYears,
    type,
    foreignCurrency,
  ]);
  const result = useAccountRateTransformApi({
    transformFunction,
  });
  return result;
};

export const useAccountRatesTAccBuyAndSellTotalFeeAmountOnContext = () => {
  const {
    counterValue,
    durationYears,
    type,
    foreignCurrency,
    refund,
  } = useSimulationDataBuyAndSellContext();
  const transformFunction = useCallback(computeBuyAndSellTotalFees({
    counterValue,
    durationYears,
    type,
    foreignCurrency,
    refund,
  }), [
    counterValue,
    durationYears,
    type,
    foreignCurrency,
    refund,
  ]);
  const result = useAccountRateTransformApi({
    transformFunction,
  });
  return result;
};

export const useAccountRatesTAccBuyAndSellTotalFeePct = (props: SimulationBuyAndSellCostData) => {
  const {
    counterValue,
    durationYears,
    type,
    foreignCurrency,
    refund,
  } = props;
  const transformFunction = useCallback(computeBuyAndSellTotalFeesPct({
    counterValue,
    durationYears,
    type,
    foreignCurrency,
    refund,
  }), [
    counterValue,
    durationYears,
    type,
    foreignCurrency,
  ]);
  const result = useAccountRateTransformApi({
    transformFunction,
  });
  return result;
};

export const useAccountRatesTAccBuyAndSellTotalFeePctOnContext = () => {
  const props = useSimulationDataBuyAndSellContext();
  const result = useAccountRatesTAccBuyAndSellTotalFeePct(props);
  return result;
};
