import { Trans } from '@lingui/macro';
import * as React from 'react';

export const NotFoundIntro = (props: { param: string }) => {
  const { param } = props;
  return (
    <Trans>
      La section&#32;
      &quot;
      {param}
      &quot;
      demandée n'existe pas ou plus
    </Trans>
  );
};
