import * as Yup from 'yup';
import { makeRequired } from './mixed';
import {
  notANumberError,
  numberLessThanError,
  numberLessThanRefError,
  numberMaxError,
  numberMaxRefError,
  numberMinError,
  numberMinRefError,
  numberMoreThanError,
  numberMoreThanRefError,
  numberNegativeError,
  numberPositiveError,
} from '../errornames';

export const numberValidator = Yup
  .number()
  .transform((value, originalValue: string) => {
    if (Number.isNaN(value) && originalValue.includes(',')) {
      const replaceInString = originalValue.replace(',', '.');
      const parsed = parseFloat(replaceInString);
      return parsed;
    }
    return value;
  })
  .transform((value, originalValue) => (originalValue === '' ? undefined : value))
  .typeError(notANumberError);

export const requiredNumberValidator = makeRequired(numberValidator);

export const makeNumberMin = (min) => (validator) => {
  const numberMin = validator.min(min, numberMinError);
  return numberMin;
};

export const makeNumberMinRef = (ref) => (validator) => {
  const numberMinRef = validator.min(Yup.ref(ref), numberMinRefError);
  return numberMinRef;
};

export const makeNumberMax = (max) => (validator) => {
  const numberMax = validator.max(max, numberMaxError);
  return numberMax;
};

export const makeNumberMaxRef = (ref) => (validator) => {
  const numberMaxRef = validator.max(Yup.ref(ref), numberMaxRefError);
  return numberMaxRef;
};

export const makeNumberMoreThan = (min) => (validator) => {
  const numberMoreThan = validator.moreThan(min, numberMoreThanError);
  return numberMoreThan;
};

export const makeNumberMoreThanRef = (ref) => (validator) => {
  const numberMoreThanRef = validator.moreThan(Yup.ref(ref), numberMoreThanRefError);
  return numberMoreThanRef;
};

export const makeNumberLessThan = (max) => (validator) => {
  const numberLessThan = validator.moreThan(max, numberLessThanError);
  return numberLessThan;
};

export const makeNumberLessThanRef = (ref) => (validator) => {
  const numberLessThanRef = validator.lessThan(Yup.ref(ref), numberLessThanRefError);
  return numberLessThanRef;
};

export const makeNumberPositive = (validator) => {
  const numberPositive = validator.positive(numberPositiveError);
  return numberPositive;
};

export const makeNumberNegative = (validator) => {
  const numberNegative = validator.negative(numberNegativeError);
  return numberNegative;
};
