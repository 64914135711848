import { cpsHisIdKeysTransform } from '../../utils';
import { AccountCashflow } from '../../type';
import { useAccountCashflowsTransformApi } from '../api';

export const useAccountCashflowsList = (props: {
  filterPredicate?: ((data: AccountCashflow) => boolean),
  sortPredicate?: (prev: AccountCashflow, next: AccountCashflow) => number,
}) => {
  const {
    filterPredicate,
    sortPredicate,
  } = props;
  const result = useAccountCashflowsTransformApi({
    transformFunction: cpsHisIdKeysTransform,
    filterPredicate,
    sortPredicate,
  });
  return result;
};
