import React, { useMemo, ReactNode } from 'react';
import { Trans, msg } from '@lingui/macro';
import pipe from 'ramda/es/pipe';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import {
  dataStructure, UncastedDataStructure, AccountType, UncastedOnboarding,
} from '@goldwasserexchange/oblis-frontend-utils';
import ouiBase from '@goldwasserexchange/oui';
import ouiDom from '@goldwasserexchange/oui-dom';
import * as Yup from 'yup';
import { useHookstate } from '@hookstate/core';
import { MessageDescriptor } from '@lingui/core';
import ouiStyle from '@goldwasserexchange/oui-style';
import { useLingui } from '@lingui/react';
import { StateFilter, useStateFilter } from '../../../../../Machine';
import { Service } from '../../../../../TextIndex/atoms/Service';
import { WarningCard } from '../../../../../Card';
import { CheckboxListInput } from '../../../../../../OUI-dom/Inputs';
import { StepContainer } from '../../utils/Step';
import { addWhenAlwaysCast, makeWhenAlwaysCast } from '../../../../../Form/validations/whenAlwaysCast';
import {
  booleanValidator, makeIsTrue, makeRequired, nilValidator,
} from '../../../../../../OUI/Inputs';
import { ValidationContextType } from '../../../../../Form/validations/contextType';
import { Space } from '../../../../../Space';

addWhenAlwaysCast();

const contractualTermsValidator = pipe(
  makeRequired,
  makeIsTrue,
)(booleanValidator);

const generalTermsAndConditionsValidator = pipe(
  makeRequired,
  makeIsTrue,
)(booleanValidator);

const pricingValidator = pipe(
  makeRequired,
  makeIsTrue,
)(booleanValidator);

const warningSkippedServiceRecommendationValidatorDependencyPaths: (keyof UncastedDataStructure | '$')[] = [
  '$',
];

const warningSkippedServiceRecommendationValidator = makeWhenAlwaysCast(warningSkippedServiceRecommendationValidatorDependencyPaths, (context: ValidationContextType) => {
  const {
    rootValue,
  } = context;
  const {
    onboarding,
  } = rootValue;
  const {
    orientation,
  } = onboarding;
  if (orientation.submitted === false || orientation.submitted === undefined) {
    return pipe(
      makeRequired,
      makeIsTrue,
    )(booleanValidator);
  }
  return nilValidator;
});

const warningDidNotFollowServiceRecommendationValidatorDependencyPaths: (keyof UncastedDataStructure | '$')[] = [
  '$',
];

const warningDidNotFollowServiceRecommendationValidator = makeWhenAlwaysCast(
  warningDidNotFollowServiceRecommendationValidatorDependencyPaths,
  (context: ValidationContextType) => {
    const {
      rootValue,
    } = context;
    const {
      onboarding,
    } = rootValue;
    const {
      orientation,
      selectedService,
    } = onboarding;
    const advice = dataStructure.orientation.logic.getOrientationAdvice(
      !orientation.submitted,
      orientation.decisionType === '' ? null : orientation.decisionType,
      orientation.evaluationFrequency === '' ? null : orientation.evaluationFrequency,
      orientation.marketNewsFrequency === '' ? null : orientation.marketNewsFrequency,
      orientation.threeYearInvestmentPlan === '' ? null : orientation.threeYearInvestmentPlan,
    );
    const followedAdvice = advice === selectedService;
    if (orientation.submitted && !followedAdvice) {
      return pipe(
        makeRequired,
        makeIsTrue,
      )(booleanValidator);
    }
    return nilValidator;
  },
);

const globalPlusTechnicalSheetValidatorDependencyPaths: (keyof UncastedDataStructure | '$')[] = [
  '$',
];

const globalPlusTechnicalSheetValidator = makeWhenAlwaysCast(globalPlusTechnicalSheetValidatorDependencyPaths, (context: ValidationContextType) => {
  const {
    rootValue,
  } = context;
  const {
    onboarding,
  } = rootValue;
  const {
    selectedService,
  } = onboarding;
  if (selectedService === AccountType.GESTION) {
    return makeRequired(makeIsTrue(booleanValidator));
  }
  return nilValidator;
});

const withdrawalRightValidator = pipe(
  makeRequired,
  makeIsTrue,
)(booleanValidator);

const monitorInvestmentsValidator = pipe(
  makeRequired,
  makeIsTrue,
)(booleanValidator);

const consentValidatorShape: Record<keyof UncastedOnboarding['consent'], any> = {
  contractualTerms: contractualTermsValidator,
  generalTermsAndConditions: generalTermsAndConditionsValidator,
  pricing: pricingValidator,
  warningSkippedServiceRecommendation: warningSkippedServiceRecommendationValidator,
  warningDidNotFollowServiceRecommendation: warningDidNotFollowServiceRecommendationValidator,
  withdrawalRight: withdrawalRightValidator,
  monitorInvestments: monitorInvestmentsValidator,
  globalPlusTechnicalSheet: globalPlusTechnicalSheetValidator,
};

const LinkWithDescriptor = (props: React.PropsWithChildren<{
  fontSize?: ouiStyle.InStyleWithMediaQueries['fontSize'],
  hrefMessageDescriptor: MessageDescriptor,
  target?: React.HTMLAttributeAnchorTarget,
}>) => {
  const {
    fontSize,
    hrefMessageDescriptor,
    target,
    children,
  } = props;
  const {
    i18n,
  } = useLingui();
  const href = i18n._(hrefMessageDescriptor);
  return (
    <ouiDom.A.A
      href={href}
      target={target}
      fontSize={fontSize}
    >
      {children}
    </ouiDom.A.A>
  );
};

export const consentValidator = Yup.object().shape(consentValidatorShape);

const onboardingPath: keyof Pick<UncastedDataStructure, 'onboarding'> = 'onboarding';

const consentPath: keyof Pick<UncastedOnboarding, 'consent'> = 'consent';

type ConsentPaths = `${keyof Pick<UncastedDataStructure, 'onboarding'>}.${keyof Pick<UncastedOnboarding, 'consent'>}.${keyof UncastedOnboarding['consent']}`;

const documentConfirmData: Record<ConsentPaths, { ID: ConsentPaths, label: ReactNode }> = {
  'onboarding.consent.contractualTerms': {
    ID: 'onboarding.consent.contractualTerms',
    label: <Trans>Les dispositions contractuelles relatives au service</Trans>,
  },
  'onboarding.consent.generalTermsAndConditions': {
    ID: 'onboarding.consent.generalTermsAndConditions',
    label: (
      <>
        <Trans>
          <LinkWithDescriptor
            fontSize="s"
            hrefMessageDescriptor={msg`https://d35udc6x0xtv0z.cloudfront.net/documents/GE_CG_20230215.pdf`}
            target="_blank"
          >
            Les conditions générales
          </LinkWithDescriptor>
        </Trans>
        {' '}
        <Trans>
          de Goldwasser Exchange
        </Trans>
      </>
    ),
  },
  'onboarding.consent.globalPlusTechnicalSheet': {
    ID: 'onboarding.consent.globalPlusTechnicalSheet',
    label: (
      <Trans>
        <LinkWithDescriptor
          fontSize="s"
          hrefMessageDescriptor={msg`https://d35udc6x0xtv0z.cloudfront.net/documents/global_plus_fr.pdf`}
          target="_blank"
        >
          La fiche technique de Global+
        </LinkWithDescriptor>
      </Trans>
    ),
  },
  'onboarding.consent.pricing': {
    ID: 'onboarding.consent.pricing',
    label: (
      <Trans>
        <LinkWithDescriptor
          fontSize="s"
          hrefMessageDescriptor={msg`https://d35udc6x0xtv0z.cloudfront.net/documents/tarifs.pdf`}
          target="_blank"
        >
          La grille des tarifs
        </LinkWithDescriptor>
      </Trans>
    ),
  },
  'onboarding.consent.warningSkippedServiceRecommendation': {
    ID: 'onboarding.consent.warningSkippedServiceRecommendation',
    label: <Trans>L'avertissement ci-dessus concernant le caractère adapté du service pour lequel vous avez opté</Trans>,
  },
  'onboarding.consent.warningDidNotFollowServiceRecommendation': {
    ID: 'onboarding.consent.warningDidNotFollowServiceRecommendation',
    label: <Trans>L'avertissement ci-dessus concernant le caractère adapté du service pour lequel vous avez opté</Trans>,
  },
  'onboarding.consent.withdrawalRight': {
    ID: 'onboarding.consent.withdrawalRight',
    label: (
      <Trans>
        <LinkWithDescriptor
          fontSize="s"
          hrefMessageDescriptor={msg`https://d35udc6x0xtv0z.cloudfront.net/documents/retractation.pdf`}
          target="_blank"
        >
          Les dispositions relatives au droit de rétractation
        </LinkWithDescriptor>
      </Trans>
    ),
  },
  'onboarding.consent.monitorInvestments': {
    ID: 'onboarding.consent.monitorInvestments',
    label: (
      <Trans>
        Il incombe au client de suivre l'évolution de ses investissements en instruments financiers. L'absence de suivi approprié expose le client au risque de subir des pertes sur ses investissements et/ou d'en aggraver l'ampleur.
      </Trans>
    ),
  },
};

const documentConfirmList = Object.keys(documentConfirmData);

const documentConfirmLabel = (
  <TopModel name="CheckboxListInputLabelContent">
    <Trans>
      Veuillez confirmer avoir pris connaissances et accepté sans réserve les documents et avertissements suivants
    </Trans>
  </TopModel>
);

const ElementLabel = () => {
  const primaryKey = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  return documentConfirmData[primaryKey].label;
};

const documentConfirmElementLabel = (
  <TopModel name="CheckboxListInputListElementLabelContent">
    {ElementLabel}
  </TopModel>
);

const useDocumentFilterFn = (list: string[]) => {
  const skipped = useStateFilter('orientation.confirmation.withDisclaimer.skipped');
  const didntFollow = useStateFilter('orientation.confirmation.withDisclaimer.didntFollow');
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const selectedService = useHookstate(valueState.onboarding.selectedService).get();
  const filteredList = useMemo(() => list.filter((id) => {
    const warningSkippedServiceId = valueState.onboarding.consent.warningSkippedServiceRecommendation.path.join('.');
    const warningDidNotFollowServiceRecommendationId = valueState.onboarding.consent.warningDidNotFollowServiceRecommendation.path.join('.');
    const globalPlusTechnicalSheetId = valueState.onboarding.consent.globalPlusTechnicalSheet.path.join('.');
    if (
      id !== warningSkippedServiceId
      && id !== warningDidNotFollowServiceRecommendationId
      && id !== globalPlusTechnicalSheetId
    ) {
      return true;
    }
    if (id === warningSkippedServiceId && skipped) {
      return true;
    }
    if (id === warningDidNotFollowServiceRecommendationId && didntFollow) {
      return true;
    }
    if (id === globalPlusTechnicalSheetId && selectedService === AccountType.GESTION) {
      return true;
    }
    return false;
  }), [list, skipped, didntFollow, selectedService]);
  return filteredList as string[];
};

const useElementFieldPath = () => {
  const primaryKey = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  return primaryKey;
};

const DocumentConfirm = () => (
  <CheckboxListInput
    fieldPath={`${onboardingPath}.${consentPath}`}
    validateFieldPath={false}
    list={documentConfirmList}
    elementFieldPathHook={useElementFieldPath}
    useFilterFn={useDocumentFilterFn}
    checkboxPotentialFieldPaths={documentConfirmList}
  >
    {documentConfirmLabel}
    {documentConfirmElementLabel}
  </CheckboxListInput>
);

export const Confirmation = () => (
  <StepContainer>
    <ouiDom.P.P
      paddingHorizontal="simple"
    >
      <Trans>Félicitations, vous avez opté pour un compte</Trans>
      {' '}
      <Service />
      .
    </ouiDom.P.P>
    <StateFilter is="orientation.confirmation.withDisclaimer">
      <WarningCard>
        <StateFilter is="orientation.confirmation.withDisclaimer.skipped">
          <Trans>A défaut d'avoir pu récolter des informations sur la manière dont vous gérez vos investissement, nous ne sommes pas en mesure de vérifier que le service choisi correspond à vos besoin et attentes.</Trans>
        </StateFilter>
        <StateFilter is="orientation.confirmation.withDisclaimer.didntFollow">
          <Trans>Sur base des informations récoltées concernant la manière dont vous gérez vos investissement, le service sélectionné semble ne pas correspondre à vos besoins et attentes.</Trans>
        </StateFilter>
      </WarningCard>
    </StateFilter>
    <ouiDom.P.P
      paddingHorizontal="simple"
    >
      <Trans>
        Pour continuer
        <Space />
        <StateFilter is="orientation.confirmation.withDisclaimer">malgré tout</StateFilter>
        <Space />
        le processus d'entrée en relation:
      </Trans>
    </ouiDom.P.P>
    <DocumentConfirm />
  </StepContainer>
);
