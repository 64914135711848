import * as BellowCollapse from './bellowCollapse';
import * as AboveCollapse from './aboveCollapse';

export * as BellowCollapse from './bellowCollapse';
export * as AboveCollapse from './aboveCollapse';

export type EventTypeString = BellowCollapse.EventTypeString | AboveCollapse.EventTypeString;

export const eventTypes = [BellowCollapse.eventType, AboveCollapse.eventType];

export const eventTypeStrings: string[] = eventTypes as string[];

export type EventPayload = BellowCollapse.EventPayload | AboveCollapse.EventPayload;

export type EventType = BellowCollapse.EventType | AboveCollapse.EventType

export const eventBuilder = (payload: EventPayload): EventType => {
  const {
    isAboveCollapse,
  } = payload;
  return isAboveCollapse === true ? AboveCollapse.eventBuilder(payload) : BellowCollapse.eventBuilder(payload);
};
