import * as React from 'react';
import { ActorApiNumberDisplay } from '../../../../../../components/display/number/component';
import { useBondsBondIsRedacted, useBondsBondSMarketQtMin } from '../../hooks';

export const BondsBondSMarketQtMin = (props: {
  withRedactedIcon?: boolean,
}) => {
  const {
    withRedactedIcon,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useBondsBondSMarketQtMin();
  const {
    data: redacted,
    isLoading: redactedIsLoading,
  } = useBondsBondIsRedacted('S_MARKET.QT_MIN');
  return (
    <ActorApiNumberDisplay
      data={data}
      isLoading={isLoading || redactedIsLoading}
      error={error}
      format={{
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }}
      loadingValue={10000}
      isBlocked={redacted}
      withRedactedIcon={withRedactedIcon}
    />
  );
};
