import React from 'react';
import { Trans } from '@lingui/macro';
import ouiBase from '@goldwasserexchange/oui';
import { dataStructure } from '@goldwasserexchange/oblis-frontend-utils';

export const lastIdEcplProfLabel = <Trans>Dernière profession</Trans>;

export const tAddLastIdEcplProfValidatorLogic: ouiBase.utils.hookstate.FormValueFilterLogic<'requiredNumber' | 'nilCast'> = dataStructure.T_ADD.fields.ID_ECPL_PROF.logic.makeIdEcplProfMayHaveProfessionHasLastProfessionFilterLogic({
  visible: {
    result: 'requiredNumber',
  },
  invisible: {
    result: 'nilCast',
  },
});
