import * as React from 'react';
import { useZIndex, ZIndexSetProvider } from '../../../../OUI/Form';
import { modalZIndexContext } from './context';

export const ModalZIndexProvider = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  const currentZIndex = useZIndex();
  return (
    <modalZIndexContext.Provider value={currentZIndex}>
      <ZIndexSetProvider zIndex={currentZIndex - 2}>
        {children}
      </ZIndexSetProvider>
    </modalZIndexContext.Provider>
  );
};
