import ouiBase from '@goldwasserexchange/oui';
import { makeTAddTypeFilterLogic } from '../../TYPE/logic';
import { hasSignificantOtherList } from '../../MC_CIVILSTATUS';
import { PersonType, TAddType, } from '../../../../../types';
var onboardingPath = 'onboarding';
var personTypePath = 'personType';
var mcCivilStatusPath = 'MC_CIVILSTATUS';
var isMinorPath = 'isMinor';
export var makeShowSignificantOtherLogic = function (props) {
    var _a = props || {}, _b = _a.visible, visible = _b === void 0 ? {
        result: 'visible',
    } : _b, _c = _a.invisible, invisible = _c === void 0 ? {
        result: 'invisible',
    } : _c;
    return makeTAddTypeFilterLogic({
        is: [
            TAddType.IGNORED,
            TAddType.MANDATARY,
            TAddType.UBO,
        ],
        options: {
            matcher: ouiBase.utils.match.arrayMatcherNames.some,
            isNegative: true,
        },
        truthy: {
            name: "".concat(onboardingPath, ".").concat(personTypePath),
            is: PersonType.JOINT,
            options: {
                isNegative: true,
            },
            alternatives: {
                truthy: {
                    name: "<parentName>.".concat(mcCivilStatusPath),
                    is: hasSignificantOtherList,
                    options: {
                        matcher: ouiBase.utils.match.arrayMatcherNames.some,
                    },
                    alternatives: {
                        truthy: {
                            name: "<parentName>.".concat(isMinorPath),
                            is: false,
                            alternatives: {
                                truthy: visible,
                                falsy: invisible,
                            },
                        },
                        falsy: invisible,
                    },
                },
                falsy: invisible,
            },
        },
    });
};
export var showLogic = makeShowSignificantOtherLogic();
