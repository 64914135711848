import * as React from 'react';
import { ToggleIconCircle, ToggleIconContainer } from './components';

export const ActiveToggleIcon = (props: {
  value: boolean,
  touched: boolean,
}) => {
  const {
    value,
    touched,
  } = props;
  return (
    <ToggleIconContainer
      value={value}
      touched={touched}
    >
      <ToggleIconCircle
        value={value}
        touched={touched}
      />
    </ToggleIconContainer>
  );
};
