import { AccountRates, SimulationBuyAndSellCostData } from '../type';
import { computeOrGetGrossAmount, ComputeOrGetGrossAmountProps } from './computeGrossAmount';
import { yearlyPct } from './yearlyPct';

export const computeCustodyFeesRate = (props: ComputeOrGetGrossAmountProps) => (rateDg: AccountRates['T_ACC.RATE_DG']) => {
  const grossAmounInEur = computeOrGetGrossAmount(props);
  return grossAmounInEur * rateDg;
};

export type computeBuyAndSellCustodyFeesBuyAndSellProps = {
  counterValue: SimulationBuyAndSellCostData['counterValue'],
  durationYears: SimulationBuyAndSellCostData['durationYears'],
}

export const computeBuyAndSellCustodyFeesAmountBuyAndSell = (props: computeBuyAndSellCustodyFeesBuyAndSellProps) => (rateDg: AccountRates['T_ACC.RATE_DG']) => {
  const {
    counterValue,
    durationYears,
  } = props;
  const custodyFees = computeCustodyFeesRate({
    grossAmountInEur: counterValue,
  })(rateDg);
  return durationYears * custodyFees;
};

export const computeBuyAndSellCustodyFeesBuyAndSellPct = (props: computeBuyAndSellCustodyFeesBuyAndSellProps) => (rateDg: AccountRates['T_ACC.RATE_DG']) => {
  const custodyFeesAmount = computeBuyAndSellCustodyFeesAmountBuyAndSell(props)(rateDg);
  return yearlyPct(props)(custodyFeesAmount);
};
