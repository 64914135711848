import ouiStyle from '@goldwasserexchange/oui-style';
import { Plural } from '@lingui/macro';
import * as React from 'react';
import { ActorApiNumberDisplay } from '../../../../../../../components/display/number/component';
import { useBondListLength } from '../../../hooks';
import { BondListLengthNumberContainer, BondListLengthTextContainer } from './components';

export const BondListLength = (props: {
  showTextFor0?: boolean,
  textFontWeight?: ouiStyle.InStyleWithMediaQueries['fontWeight'],
  textFontSize?: ouiStyle.InStyleWithMediaQueries['fontSize'],
  textLineFactor?: ouiStyle.InStyleWithMediaQueries['lineFactor'],
  numberFontWeight?: ouiStyle.InStyleWithMediaQueries['fontWeight'],
  numberFontSize?: ouiStyle.InStyleWithMediaQueries['fontSize'],
  numberLineFactor?: ouiStyle.InStyleWithMediaQueries['lineFactor'],
  noLoader?: boolean,
}) => {
  const {
    showTextFor0 = false,
    textFontWeight,
    textFontSize,
    textLineFactor,
    numberFontWeight,
    numberFontSize,
    numberLineFactor,
    noLoader,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useBondListLength();
  if ((noLoader && isLoading) || data == null) {
    return null;
  }
  return (
    <>
      {!(data === 0 && showTextFor0)
        ? (
          <>
            <BondListLengthNumberContainer
              fontWeight={numberFontWeight}
              fontSize={numberFontSize}
              lineFactor={numberLineFactor}

            >
              <ActorApiNumberDisplay
                data={data}
                isLoading={isLoading}
                error={error}
                format={{
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }}
                loadingValue={99999}
              />
            </BondListLengthNumberContainer>
            {' '}
          </>
        )
        : null}
      {showTextFor0
        ? (
          <BondListLengthTextContainer
            fontWeight={textFontWeight}
            fontSize={textFontSize}
            lineFactor={textLineFactor}
          >
            <Plural
              value={data}
              _0="Aucune obligation correspondant à vos critères"
              one="obligation"
              other="obligations"
            />
          </BondListLengthTextContainer>
        )
        : (
          <BondListLengthTextContainer
            fontWeight={textFontWeight}
            fontSize={textFontSize}
            lineFactor={textLineFactor}
          >
            <Plural
              value={data}
              _0="résultat"
              one="résultat"
              other="résultats"
            />
          </BondListLengthTextContainer>
        )}
    </>
  );
};
