import { always } from 'ramda';
import { createContext } from 'react';
import { SearchKey } from '../searchKeys/context';

export type GetFnType = (obj: string | { ID: string }, path: SearchKey) => string | undefined;

export type UseGetFnType = () => GetFnType;

export type GetFnProps = {
  getFn: GetFnType,
}

export type UseGetFnProps = {
  useGetFn?: UseGetFnType,
}

export const defaultGetFn = always(undefined);

export const getFnContext = createContext<GetFnType>(defaultGetFn);
