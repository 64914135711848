/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const centeredButtonContainerStyle: ouiStyle.InStyle = {
  textAlign: 'center',
};

export const CenteredButtonContainer = (props: React.PropsWithChildren<{
  paddingVertical?: ouiStyle.InStyle['paddingVertical'],
  paddingTop?: ouiStyle.InStyle['paddingTop'],
  paddingBottom?: ouiStyle.InStyle['paddingBottom'],
}>) => {
  const {
    paddingVertical,
    paddingTop,
    paddingBottom,
    children,
  } = props;
  const centeredButtonContainerCss = React.useMemo(() => ouiStyle.makeCss({
    ...centeredButtonContainerStyle,
    paddingVertical,
    paddingTop,
    paddingBottom,
  }), [paddingVertical, paddingTop, paddingBottom]);
  return (
    <div
      css={centeredButtonContainerCss}
    >
      {children}
    </div>
  );
};
