import { useMemo } from 'react';
import { makeGrossBondCalculator, makeGrossBondPriceCalculator, makeGrossBondYieldCalculator } from '../../utils/makeGrossBondCalculator';
import { makeNetBondCalculator, makeNetBondPriceCalculator, makeNetBondYieldCalculator } from '../../utils/makeNetBondCalculator';
import { useBondsBondTransformApi } from '../api';

export const useBondsBondCalculator = () => {
  const response = useBondsBondTransformApi(makeGrossBondCalculator);
  return response;
};

export const useBondsBondAccruedValue = () => {
  const originalResponse = useBondsBondCalculator();
  const response = useMemo(
    () => {
      const {
        data,
        ...rest
      } = originalResponse;
      if (data == null) {
        return {
          ...rest,
          data: undefined,
        };
      }
      return {
        ...rest,
        data: data.accrued,
      };
    },
    [
      originalResponse.data,
      originalResponse.error,
      originalResponse.isLoading,
    ],
  );
  return response;
};

export const useBondsBondYieldCalculator = () => {
  const response = useBondsBondTransformApi(makeGrossBondYieldCalculator);
  return response;
};

export const useBondsBondPriceCalculator = () => {
  const response = useBondsBondTransformApi(makeGrossBondPriceCalculator);
  return response;
};

export const useBondsBondNetCalculator = () => {
  const response = useBondsBondTransformApi(makeNetBondCalculator);
  return response;
};

export const useBondsBondNetYieldCalculator = () => {
  const response = useBondsBondTransformApi(makeNetBondYieldCalculator);
  return response;
};

export const useBondsBondNetPriceCalculator = () => {
  const response = useBondsBondTransformApi(makeNetBondPriceCalculator);
  return response;
};
