import React from 'react';
import { Trans } from '@lingui/macro';
import ouiDom from '@goldwasserexchange/oui-dom';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { UncastedDataStructure, PersonType } from '@goldwasserexchange/oblis-frontend-utils';
import ouiBase from '@goldwasserexchange/oui';
import { RadioListInput } from '../../../../../OUI-dom/Inputs';
import { GetComponentKeyByElementPrimaryKey } from '../utils/ElementLabel';
import { StepContainer } from '../utils/Step';
import { makeRequired, stringValidator } from '../../../../../OUI/Inputs';
import { OnboardingStatusBanner } from '../../../../../aws';

export const personTypeValidator = makeRequired(stringValidator);

export const personTypesData = {
  [PersonType.INDIVIDUAL]: {
    ID: PersonType.INDIVIDUAL,
    value: <Trans>Un compte individuel ouvert au nom d'une seule personne</Trans>,
  },
  [PersonType.JOINT]: {
    ID: PersonType.JOINT,
    value: <Trans>Un compte joint ouvert au nom de deux époux/cohabitants légaux</Trans>,
  },
  [PersonType.INDIVISION]: {
    ID: PersonType.INDIVISION,
    value: <Trans>Un compte en indivision ouvert au nom de plusieurs personnes qui ne sont pas des époux/cohabitants légaux</Trans>,
  },
  [PersonType.DIVIDED]: {
    ID: PersonType.DIVIDED,
    value: <Trans>Un compte démembré ouvert au nom d'un ou plusieurs usufruitier(s) et d'un ou plusieurs nu-propriétaire(s)</Trans>,
  },
  [PersonType.FOR_MINORS]: {
    ID: PersonType.FOR_MINORS,
    value: <Trans>Un compte ouvert au nom d'un ou plusieurs mineurs dont vous êtes le représentant légal</Trans>,
  },
  [PersonType.MORAL]: {
    ID: PersonType.MORAL,
    value: <Trans>Un compte ouvert au nom d'une personne morale</Trans>,
  },
};

const personTypesList = Object.keys(personTypesData);

const personTypeLabel = (
  <TopModel name="RadioListInputLabelContent">
    <Trans>Le compte que vous envisagez d'ouvrir chez Goldwasser Exchange sera :</Trans>
  </TopModel>
);

const personTypeElementLabel = (
  <TopModel name="RadioListInputListElementLabelContent" data={personTypesData} valueKey="value">
    {GetComponentKeyByElementPrimaryKey}
  </TopModel>
);

export const PersonTypeInput = () => {
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  return (
    <RadioListInput
      fieldPath={valueState.onboarding.personType.path.join('.')}
      list={personTypesList}
    >
      {personTypeLabel}
      {personTypeElementLabel}
    </RadioListInput>
  );
};

export const PersonTypeStep = () => (
  <StepContainer>
    <OnboardingStatusBanner
      openInNewWindow
    />
    <ouiDom.P.P>
      <Trans>
        Les informations que vous complétez sont sauvegardées au fur et à mesure du processus d'ouverture de compte et conservées pendant 5 jours. Pour reprendre le processus plus tard, il vous suffit de vous reconnecter avec votre numéro de téléphone mobile.
      </Trans>
    </ouiDom.P.P>
    <PersonTypeInput />
  </StepContainer>
);
