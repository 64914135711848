import { useIsValid, useShowHelp, useIsTouched } from '../../../../context';

export type FooterType = 'help' | 'error' | 'none';

export const useInputFooterType = (): FooterType => {
  const isValid = useIsValid();
  const showHelp = useShowHelp();
  const touched = useIsTouched();
  if (isValid && !showHelp) {
    return 'none';
  }
  if ((!touched && showHelp) || (isValid && showHelp)) {
    return 'help';
  }
  return touched ? 'error' : 'none';
};
