import { mediaQuerizeProps } from '../mediaQuery';
import { borderSize } from '../utils';
export function getBorderWidth(borderWidth) {
    if (typeof borderWidth === 'number') {
        return "".concat(borderWidth * borderSize, "rem");
    }
    if (borderWidth === 'simple') {
        return "".concat(borderSize, "rem");
    }
    if (borderWidth === 'double') {
        return "".concat(2 * borderSize, "rem");
    }
    if (borderWidth === 'triple') {
        return "".concat(3 * borderSize, "rem");
    }
    if (borderWidth === 'quadruple') {
        return "".concat(4 * borderSize, "rem");
    }
    return '0rem';
}
export function getBorderWidths(borderWidth) {
    if (Array.isArray(borderWidth)) {
        var borderWidths = mediaQuerizeProps(borderWidth);
        return borderWidths.map(function (bw) { return getBorderWidth(bw); });
    }
    return getBorderWidth(borderWidth);
}
