import * as React from 'react';
import { StateFilter } from '../../../../../Machine';
import { ConseilDurability } from './durability';
import { ConseilFinancialSituation } from './financialSituation';
import { ConseilInvestmentGoals } from './investmentGoals/components';

export const KYCConseil = () => (
  <>
    <StateFilter is=".financialSituation">
      <ConseilFinancialSituation />
    </StateFilter>
    <StateFilter is=".investmentGoals">
      <ConseilInvestmentGoals />
    </StateFilter>
    <StateFilter is=".durability">
      <ConseilDurability />
    </StateFilter>
  </>
);
