import ouiBase from '@goldwasserexchange/oui';
import { useAccountPositionsHistoryTransformApiByHisMvtId } from '../api';
import { getFileNameExtensionFromArDoc } from '../../../../shared';

export const useAccountPositionsHistoryArDocFileNameExtension = () => {
  const hisMvtId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const response = useAccountPositionsHistoryTransformApiByHisMvtId({
    hisMvtId,
    transformFunction: getFileNameExtensionFromArDoc,
  });
  return response;
};
