import * as React from 'react';
import { Trans } from '@lingui/macro';
import { BooleanSearchAndFilterBondParams } from './booleanFilters';

export const bondFilterLabels: Record<BooleanSearchAndFilterBondParams, () => JSX.Element> = {
  eur: () => <Trans>€ Uniquement</Trans>,
  usd: () => <Trans>$ Uniquement</Trans>,
  foreignCurrencies: () => <Trans>Devises étrangères</Trans>,
  'S_MARKET.YIELD': () => <Trans>Min. 4% rendement</Trans>,
  'S_MARKET.SMALL_QT_MIN': () => <Trans>Petite coupure</Trans>,
  'CPS_HIS.LOW_ACCRUED': () => <Trans>Peu d'intérêts courus</Trans>,
  'S_MARKET.PRICE_CLOSE_LOW': () => <Trans>Prix bas</Trans>,
  perpetual: () => <Trans>Perpétuelle</Trans>,
  lessThanFiveYears: () => <Trans>Moins de 5 ans</Trans>,
  'RATING_SP.INVESTMENT_GRADE': () => <Trans>Investment grade S&P</Trans>,
  'S_ACC.IS_GREEN': () => <Trans>Green bonds</Trans>,
};

export const BondFilterLabel = (props: {
  field: BooleanSearchAndFilterBondParams,
}) => {
  const {
    field,
  } = props;
  const Label = bondFilterLabels[field];
  return (
    <Label />
  );
};
