import * as React from 'react';
import { useAccountRatesCountervalue } from '../../hooks';
import { SimulationBuyAndSellCostData } from '../../type';
import { DEFAULT_SIMULATION_COUNTER_VALUE } from '../../utils/computeCountervalue';
import { simulationDataBuyAndSellContext } from './context';
import { useSimulationDataBuyAndSellContext } from './hooks';

export const SimulationDataBuyAndSellProvider = (props: React.PropsWithChildren<Partial<SimulationBuyAndSellCostData>>) => {
  const {
    counterValue,
    durationYears,
    type,
    foreignCurrency,
    refund,
    children,
  } = props;
  const {
    counterValue: contextCounterValue,
    durationYears: contextDurationYears,
    type: contextType,
    foreignCurrency: contextForeignCurrency,
    refund: contextRefund,
  } = useSimulationDataBuyAndSellContext();
  const {
    data: minCountervalue = DEFAULT_SIMULATION_COUNTER_VALUE,
  } = useAccountRatesCountervalue();
  const valueWithDefault = React.useMemo(() => {
    const newType = type ?? contextType;
    return ({
      counterValue: minCountervalue ?? counterValue ?? contextCounterValue,
      durationYears: durationYears ?? contextDurationYears,
      type: newType,
      foreignCurrency: foreignCurrency ?? contextForeignCurrency,
      refund: newType === 'bonds' ? (refund ?? contextRefund) : false,
    });
  }, [
    minCountervalue,
    counterValue,
    durationYears,
    type,
    foreignCurrency,
    refund,
    contextCounterValue,
    contextDurationYears,
    contextType,
    contextForeignCurrency,
    contextRefund,
  ]);
  return (
    <simulationDataBuyAndSellContext.Provider value={valueWithDefault}>
      {children}
    </simulationDataBuyAndSellContext.Provider>
  );
};
