import ouiBase from '@goldwasserexchange/oui';
import { UncastedPhysicalTAdd, CTit } from '@goldwasserexchange/oblis-frontend-utils';
import { I18n, MessageDescriptor } from '@lingui/core';
import { ValidationsNames } from '../../Form/validations/store';
import { SearchKey } from '../../../OUI/Inputs/components/shared/components/list/context/searchKeys/context';

export const T_ADD_TABLE_NAME = 'T_ADD';
export const C_COUNTRY_TABLE_NAME = 'C_COUNTRY';
export const ECPL_PROF_TABLE_NAME = 'ECPL_PROF';
export const ECPL_SECTOR_TABLE_NAME = 'ECPL_SECTOR';
export const T_NATURE_LRP_TABLE_NAME = 'T_NATURE_LRP';
export const T_ADD_FAMILY_LINK_NAME = 'T_ADD_FAMILY_LINK';
export const C_TIT_NAME = 'C_TIT';
export const FUND_ORIGIN_NAME = 'fundOrigin';
export const MC_PEP_NAME = 'MC_PEP';
export const MC_MAR_STATUT_NAME = 'MC_MAR_STATUT';
export const UBO_TABLE_NAME = 'ubo';
export const MC_CIVIL_STATUS_TABLE_NAME = 'MC_CIVIL_STATUS';
export const CUSTOM_TABLE_NAME = 'custom';
export const T_PROC_TYPE_TABLE_NAME = 'T_PROC_TYPE';
export const MANDATARY_TABLE_NAME = 'mandatary';

export type GeValidator = {
  id: string,
  formatter: {},
};

export type GeFormatters = Record<string, GeValidator>

export type GeDataTable = {
  id: string,
  searchKeys?: SearchKey[],
  data: Record<string, { ID: string | number }
  | { ID: string | number, LML_DESCRIPTION: string }
  | { ID: string | number, value: string
  | (() => string), socialForm: CTit[], }
  | { ID: string | number, value: string | (() => string) | ((i18n: I18n) => string) }
  | { ID: string | number, value: JSX.Element }
  | { ID: string | number, value: MessageDescriptor }
  | { ID: string | number, fieldPath: string | (keyof UncastedPhysicalTAdd['fundsOrigin']), value: string | JSX.Element | (() => string) }
  | { LML_DESCRIPTION: string }>,
  tableName: keyof GeTables,
  useFilterFn?: (item: string[]) => string[],
  useSortFn?: (item: string[]) => string[],
}

type TableNames =
  | 'ubo'
  | 'mcCivilStatus'
  | 'TProcType'
  | 'TNatureLrp'
  | 'familyLink'
  | 'fundsOrigin'
  | 'countryName'
  | 'countryNationality'
  | 'cTit'
  | 'EcplProfLml'
  | 'EcplSectorLml'
  | 'mandataryFunction'
  | 'MC_PEP'
  | 'MC_MAR_STATUT'

export type GeDataTables = Record<TableNames, GeDataTable>;

export type GeElementFieldPathHook = {
  id: string,
  useElementFieldPathHook: () => string,
}

export type GeElementFieldPathHooks = Record<string, GeElementFieldPathHook>;

export type GeTextColumnBase = {
  inputType: 'Text',
  formatter?: keyof GeFormatters,
}

export type GeRadioListColumnBase = {
  inputType: 'RadioList',
  dataTable: keyof GeDataTables,
}

export type GeCheckboxListColumnBase = {
  inputType: 'CheckboxList',
  dataTable: keyof GeDataTables,
  elementFieldPathHook: keyof GeElementFieldPathHooks,
  elementFieldPathsFn?: (parentName: string) => string[],
}

export type GeSelectColumnBase = {
  inputType: 'Select',
  dataTable: keyof GeDataTables,
}

export type GeYesNoColumnBase = {
  inputType: 'YesNo',
  inverted?: boolean,
  useSortFn?: (item: string[]) => string[],
}

export type GeCustomBase<InputProps> = {
  inputType: 'Custom',
  Input: ((props: any) => JSX.Element | null) | (() => JSX.Element | null),
  inputProps: InputProps,
}

type GeColumnText = {
  label: JSX.Element | null,
  helpText?: JSX.Element | null,
  helpPopperContent?: JSX.Element | null,
}

type GeColumnValidator = {
  validators: {
    logic: ouiBase.utils.hookstate.FormValueFilterLogic<ValidationsNames>,
  },
}

export type ColumnKey = (
  keyof UncastedPhysicalTAdd
  | 'T_ADD_EMAIL_PRINCIPAL'
  | 'T_ADD_GSM_PRINCIPAL'
  | 'T_ADD_TEL_PRINCIPAL'
  | 'significantOther_FIRSTNAME'
  | 'significantOther_NAME1'
  | 'T_ADD_SIGNIFICANT_OTHER_BIRTH_DATE_COLUMN'
  | 'value'
  | 'socialForm'
  | 'LML_DESCRIPTION'
  | 'YN_PAY_PREC '
  | 'adressSelector'
  | 'T_ADD_ADDRESS_STREET_1'
  | 'T_ADD_ADDRESS_ZIP'
  | 'T_ADD_ADDRESS_CITY'
  | 'T_ADD_ADDRESS_ID_C_COUNTRY'
  | 'fiscalAdressSelector'
  | 'T_ADD_FISCAL_ADDRESS_STREET_1'
  | 'T_ADD_FISCAL_ADDRESS_ZIP'
  | 'T_ADD_FISCAL_ADDRESS_CITY'
  | 'T_ADD_FISCAL_ADDRESS_ID_C_COUNTRY'
  | 'secondaryFiscalAdressSelector'
  | 'T_ADD_SECONDARY_FISCAL_ADDRESS_STREET_1'
  | 'T_ADD_SECONDARY_FISCAL_ADDRESS_ZIP'
  | 'T_ADD_SECONDARY_FISCAL_ADDRESS_CITY'
  | 'T_ADD_SECONDARY_FISCAL_ADDRESS_ID_C_COUNTRY'
)

type GeColumnBase = {
  id: ColumnKey,
  type: string,
  fieldFilterLogic: {
    logic: ouiBase.utils.hookstate.FormValueFilterLogic<'visible' | 'invisible'>,
  },
  useMakeFieldPath?: (parentName: string, column?: string) => string,
  useMakeDependenciesFieldPaths?: (parentName: string, column?: string) => string[],
}

export type GeSection = ({
  title?: string | any,
} | {
  TitleComponent?: any,
}) & {
  id: string,
  description?: string | any,
  fields: string[],
  useChangeParentName?: (parentName: string) => string,
}

export type GeTextColumn = (GeTextColumnBase & GeColumnBase & GeColumnText & GeColumnValidator);

export type GeRadioListColumn = (GeRadioListColumnBase & GeColumnBase & GeColumnText & GeColumnValidator)

export type GeCheckboxListColumn = (GeCheckboxListColumnBase & GeColumnBase & GeColumnText & GeColumnValidator);

export type GeSelectColumn = (GeSelectColumnBase & GeColumnBase & GeColumnText & GeColumnValidator);

export type GeYesNoColumn = (GeYesNoColumnBase & GeColumnBase & GeColumnText & GeColumnValidator)

export type GeCustomColumn<InputProps> = (GeCustomBase<InputProps> & GeColumnBase & Partial<GeColumnValidator>);

export type GeColumn<InputProps> =
  | GeTextColumn
  | GeRadioListColumn
  | GeCheckboxListColumn
  | GeSelectColumn
  | GeYesNoColumn
  | GeCustomColumn<InputProps>;

export const isGeTextColumn = <InputProps>(t: GeColumn<InputProps>): t is GeTextColumn => t && t.inputType === 'Text';

export type GeColumns<InputProps> = Partial<Record<ColumnKey, GeColumn<InputProps>>>;

export type GeTable = {
  id: string,
  name: string,
}

type TableNamesFromType =
  | typeof MC_CIVIL_STATUS_TABLE_NAME
  | typeof T_PROC_TYPE_TABLE_NAME
  | typeof ECPL_SECTOR_TABLE_NAME
  | typeof ECPL_PROF_TABLE_NAME
  | typeof T_ADD_TABLE_NAME
  | typeof CUSTOM_TABLE_NAME
  | typeof C_COUNTRY_TABLE_NAME
  | typeof T_ADD_FAMILY_LINK_NAME
  | typeof T_NATURE_LRP_TABLE_NAME
  | typeof FUND_ORIGIN_NAME
  | typeof C_TIT_NAME
  | typeof UBO_TABLE_NAME
  | typeof MANDATARY_TABLE_NAME
  | typeof MC_PEP_NAME
  | typeof MC_MAR_STATUT_NAME

export type GeTables = Partial<Record<TableNamesFromType, GeTable>>
