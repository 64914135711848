/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { animated } from 'react-spring';
import ouiStyle from '@goldwasserexchange/oui-style';
import {
  InputVariantsNames,
  InputVariantHooksArray,
  useBaseVariant,
  useHasFocusVariant,
  useIsCurrentFieldDisabled,
  useInputRef,
  useRifm,
  useInputType,
  useInputAutocomplete,
} from '../../../../../../../../OUI/Inputs';
import { useVariantSpring } from '../../../../../../utils';
import {
  useOnFocus,
  useOnBlur,
} from '../../../../../../../../OUI/utils';

const inputStyle: ouiStyle.InStyle = {
  lines: 1,
  display: ouiStyle.Constants.DisplayProp.block,
  borderStyle: 'none',
  borderWidth: 'none',
  fontSize: 's',
  padding: 'simple',
  flex: 'one',
  parentBorderBottomWidth: 'simple',
};

const inputCss = ouiStyle.makeCss(inputStyle);

const inputBaseCss = ouiStyle.makeCssAndExtract(inputStyle, ['height', 'paddingBottom']);

const inputFocusStyle: ouiStyle.InStyle = {
  ...inputStyle,
  parentBorderBottomWidth: 'double',
};

const inputFocusCss = ouiStyle.makeCssAndExtract(inputFocusStyle, [
  'height',
  'paddingBottom',
]);

const inputVariants: Record<
InputVariantsNames,
Partial<Pick<ouiStyle.Style, 'height' | 'paddingBottom'>>
> = {
  opened: {},
  closed: {},
  base: inputBaseCss,
  hasContent: {},
  noContent: {},
  focus: inputFocusCss,
  noFocus: {},
  untouched: {},
  valid: {},
  error: {},
  enabled: {},
  disabled: {},
  checked: {},
  unChecked: {},
  last: {},
  notLast: {},
  before: {},
  inside: {},
  after: {},
  noSelection: {},
  notCorrected: {},
  correct: {},
  incorrect: {},
  correctSelected: {},
  correctNotSelected: {},
  incorrectSelected: {},
  incorrectNotSelected: {},
};

const defaultTextInputComponentVariantHooksArray: InputVariantHooksArray = [
  useBaseVariant,
  useHasFocusVariant,
];

export function DefaultTextInputComponent(props: {
  htmlName: string,
  value: string,
}) {
  const { htmlName, value: valueBase = '' } = props;
  const inputRef = useInputRef();
  const spring = useVariantSpring(
    inputVariants,
    defaultTextInputComponentVariantHooksArray,
  );
  const disabled = useIsCurrentFieldDisabled();
  const onFocus = useOnFocus();
  const onBlur = useOnBlur();
  const type = useInputType();
  const autocomplete = useInputAutocomplete();
  const {
    value,
    onChange,
  } = useRifm(valueBase);
  return (
    <animated.input
      ref={inputRef}
      id={htmlName}
      type={type ?? undefined}
      name={htmlName}
      autoComplete={autocomplete === false ? 'off' : undefined}
      value={value}
      css={inputCss}
      style={spring}
      disabled={disabled}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
}
