import * as React from 'react';
import { actorApiLoadingContext } from './context';

export const ActorApiLoadingProvider = (props: React.PropsWithChildren<{
  initialLoading?: boolean,
}>) => {
  const {
    initialLoading = true,
    children,
  } = props;
  const handlers = React.useState<boolean>(initialLoading);
  return (
    <actorApiLoadingContext.Provider value={handlers}>
      {children}
    </actorApiLoadingContext.Provider>
  );
};
