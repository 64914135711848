var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { isNil } from 'ramda';
import { useContext } from 'react';
import { useTopModelNameTransformFn } from '../nameTransform';
import { topModelContext } from './context';
export function useTopModels() {
    var topModels = useContext(topModelContext);
    return topModels;
}
export function useTopModelByName(name) {
    var topModels = useTopModels();
    var topModel = topModels[name];
    return topModel;
}
export function useTopModelExistByName(name) {
    var topModel = useTopModelByName(name);
    return !isNil(topModel);
}
export function useTopModelNameByNameWithFallbacks(name, fallbacks) {
    var names = __spreadArray([name], __read(fallbacks), false);
    var topModels = useTopModels();
    var topModel = names.find(function (currentName) { return topModels[currentName] != null; });
    return !isNil(topModel) ? topModel : '';
}
export function useTopModelByNameWithNameTransform(name) {
    var nameTransformFn = useTopModelNameTransformFn();
    var topModel = useTopModelByName(nameTransformFn(name));
    return topModel;
}
export function useTopModelByNameWithFallbacksAndNameTransform(name, fallbacks) {
    var nameTransformFn = useTopModelNameTransformFn();
    var topModel = useTopModelNameByNameWithFallbacks(nameTransformFn(name), fallbacks.map(function (fallback) { return nameTransformFn(fallback); }));
    return topModel;
}
export function useTopModelExistByNameWithNameTransform(name) {
    var nameTransformFn = useTopModelNameTransformFn();
    var exists = useTopModelExistByName(nameTransformFn(name));
    return exists;
}
export function useTopModelExistByNameWithFallbacksAndNameTransform(name, fallbacks) {
    var nameTransformFn = useTopModelNameTransformFn();
    var foundName = useTopModelNameByNameWithFallbacks(nameTransformFn(name), fallbacks.map(function (fallback) { return nameTransformFn(fallback); }));
    return foundName !== '';
}
