import { sumPow } from './sumPow';
import { DataPoint, TwoDimensionalMatrix } from './types';

/**
 * Generates the left matrix for Gaussian Jordan elimination using the given data points and degree of polynomial.
 *
 * @param {DataPoint[]} data - Array of data points to use for generating the left matrix.
 * @param {number} degree - Degree of polynomial to use for generating the left matrix.
 * @returns {TwoDimensionalMatrix} A two-dimensional matrix representing the left matrix for Gaussian Jordan elimination.
 */
export const generateLeftMatrix = (
  data: DataPoint[],
  degree: number,
): TwoDimensionalMatrix => {
  const leftMatrix: TwoDimensionalMatrix = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i <= degree; i++) {
    leftMatrix.push([]);
    // eslint-disable-next-line no-plusplus
    for (let j = 0; j <= degree; j++) {
      if (i === 0 && j === 0) {
        leftMatrix[i][j] = data.length;
      } else {
        leftMatrix[i][j] = sumPow(data, 'x', i + j);
      }
    }
  }
  return leftMatrix;
};
