import { GetBondsCallsResponse } from '@goldwasserexchange/actor/rest-services';
import { useCallback, useMemo } from 'react';
import { useAuthIsSettle } from '../../../../../../../Components/Auth';
import { useCognitoUserValidAccounts } from '../../../../../../../Components/Sections/online/selectedAccount';
import { BondsCall } from '../../type';
import {
  useBondsCallFieldTransformApi,
} from '../api';

export const useBondsCallIsRedacted = (field: keyof GetBondsCallsResponse, isDataLoading: boolean) => {
  const transformFn = useCallback((redacted: BondsCall['redacted'] | undefined) => {
    if (!redacted) {
      return false;
    }
    return redacted.includes(field);
  }, [field]);
  const response = useBondsCallFieldTransformApi({
    field: 'redacted',
    transformFn,
  });
  const accounts = useCognitoUserValidAccounts();
  const isAuthMachineSettled = useAuthIsSettle();
  const isLoading = response.isLoading || isDataLoading
    ? accounts.length > 0 || !isAuthMachineSettled
    : false;
  const data = isAuthMachineSettled && (response.data || accounts.length === 0);
  const responseWithAuth = useMemo(
    () => ({
      ...response,
      data,
      isLoading,
    }),
    [
      response.error,
      isLoading,
      data,
    ],
  );
  return responseWithAuth;
};

export const useBondsCallIsSomeRedacted = (fields: (keyof GetBondsCallsResponse)[]) => {
  const transformFn = useCallback((redacted: BondsCall['redacted'] | undefined) => {
    if (!redacted) {
      return false;
    }
    return fields.some((field) => redacted.includes(field));
  }, [fields]);
  const response = useBondsCallFieldTransformApi({
    field: 'redacted',
    transformFn,
  });
  const accounts = useCognitoUserValidAccounts();
  const isAuthMachineSettled = useAuthIsSettle();
  const isLoading = response.isLoading
    ? accounts.length > 0 || !isAuthMachineSettled
    : false;
  const data = isAuthMachineSettled && (response.data || accounts.length === 0);
  const responseWithAuth = useMemo(
    () => ({
      ...response,
      data,
      isLoading,
    }),
    [
      response.error,
      isLoading,
      data,
    ],
  );
  return responseWithAuth;
};
