import { useMemo } from 'react';
import useSWR, { KeyedMutator } from 'swr';
import memoize from 'memoizee';
import { compareDesc } from 'date-fns';
import { getServiceUrl } from '../../../../shared';
import { ACTOR_DEDUPING_INTERVAL, isIdLoading } from '../../../shared';
import { Bonds } from '../../enum';
import { useBondSameIssuerStringifiedParams } from './defaultParams';
import { BondSameIssuerResponse } from '../type';
import { fetchWithOptionalAuth } from '../../../shared/utils/fetchWithAuth';
import { useSameIssuerSortColumn, useSameIssuerSortDirection } from '../context';
import { SameIssuerSortColumn } from '../context/sort/column/context';
import { parseActorDate } from '../../../../shared/utils/parseDates';
import { creditRatings, CreditRating } from '../../../../../../Components/creditRatings';
import { makeApiUrl } from '../../../../../../aws';

const useMakeBondSameIssuerUrl = () => {
  const stringifiedParams = useBondSameIssuerStringifiedParams();
  const url = stringifiedParams !== null ? `${makeApiUrl('actor')}/${getServiceUrl(Bonds.sameIssuer)}${stringifiedParams}` : null;
  return url;
};

type BaseUseBondSameIssuerApiResponse = {
  isLoading: boolean,
  error?: any,
  mutate: KeyedMutator<BondSameIssuerResponse[]>,
  isValidating: boolean,
}

type UseBondsSameIssuerApiResponse = {
  data: BondSameIssuerResponse[] | undefined,
} & BaseUseBondSameIssuerApiResponse

export type BondSameIssuerFilterPredicate = (accountsPosition: BondSameIssuerResponse) => boolean;

type SortPredicate = (prevBondSameIssuer: BondSameIssuerResponse, nextBondSameIssuer: BondSameIssuerResponse) => number;

type UseBondsSameIssuerApiProps = {
  filterPredicate?: BondSameIssuerFilterPredicate,
  sortPredicate?: SortPredicate,
  run?: boolean,
};

const sort = (sortColumn: SameIssuerSortColumn, sortDirection: 'ASC' | 'DESC', data?: BondSameIssuerResponse[]) => data
  ?.sort((prev, next) => {
    if (sortColumn === 'S_MARKET.YIELD') {
      const prevYield = prev[sortColumn];
      const nextYield = next[sortColumn];
      if (prevYield != null && nextYield != null) {
        return (nextYield - prevYield) * (sortDirection === 'ASC' ? -1 : 1);
      }
      if (prevYield == null && nextYield == null) {
        return 0;
      }
      if (prevYield == null && nextYield != null) {
        return 1;
      }
      return -1;
    }
    if (sortColumn === 'S_ACC.INT_RATE' || sortColumn === 'S_MARKET.QT_MIN' || sortColumn === 'S_QUOTE.PRICE_CLOSE') {
      return (next[sortColumn] - prev[sortColumn]) * (sortDirection === 'ASC' ? -1 : 1);
    }
    if (sortColumn === 'S_ACC.OBL_END_DATE') {
      return compareDesc(parseActorDate(prev[sortColumn]), parseActorDate(next[sortColumn])) * (sortDirection === 'ASC' ? -1 : 1);
    }
    if (sortColumn === 'GES_RAT_VAL_SP.SHORTCUT') {
      return (creditRatings.indexOf((next[sortColumn] ?? 'NR') as CreditRating) - creditRatings.indexOf((prev[sortColumn] ?? 'NR') as CreditRating)) * (sortDirection === 'ASC' ? -1 : 1);
    }
    return 0;
  });

const useBondsSameIssuerFetch = (run = true) => {
  const sortColumn = useSameIssuerSortColumn();
  const sortDirection = useSameIssuerSortDirection();
  const url = useMakeBondSameIssuerUrl();
  const result = useSWR<BondSameIssuerResponse[], any>(
    run ? url : null,
    fetchWithOptionalAuth,
    { dedupingInterval: ACTOR_DEDUPING_INTERVAL },
  );
  return {
    ...result,
    data: sort(sortColumn, sortDirection, result.data),
  };
};

export const useBondsSameIssuerApi = (props: UseBondsSameIssuerApiProps): UseBondsSameIssuerApiResponse => {
  const {
    filterPredicate,
    sortPredicate,
    run,
  } = props;
  const result = useBondsSameIssuerFetch(run);
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    let { data } = result;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      if (filterPredicate) {
        data = data.filter((position) => filterPredicate(position));
      }
    }
    return ({
      ...result,
      isLoading,
      data,
    });
  }, [result, filterPredicate, sortPredicate]);
  return resultWithLoading;
};

type TransformArrayFunction<T> = (securityBondSameIssuer: BondSameIssuerResponse[] | undefined) => T

type UseBondsSameIssuerApiTransformProps<T> = {
  transformFunction: TransformArrayFunction<T>,
  run?: boolean,
} & UseBondsSameIssuerApiProps

type UseBondsSameIssuerApiTransformResponse<T> = {
  data: T | undefined,
} & BaseUseBondSameIssuerApiResponse;

export function useBondsSameIssuerTransformApi<T>(props: UseBondsSameIssuerApiTransformProps<T>): UseBondsSameIssuerApiTransformResponse<T> {
  const {
    filterPredicate,
    sortPredicate,
    transformFunction,
    run,
  } = props;
  const response = useBondsSameIssuerApi({
    filterPredicate,
    sortPredicate,
    run,
  });
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: (!isLoading && !error) ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UseBondSameIssuerApiResponse = {
  data: BondSameIssuerResponse | undefined,
} & BaseUseBondSameIssuerApiResponse;

export const useBondSameIssuerApi = (props: UseBondsSameIssuerApiProps): UseBondSameIssuerApiResponse => {
  const {
    filterPredicate,
    sortPredicate,
  } = props;
  const result = useBondsSameIssuerFetch();
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    // eslint-disable-next-line prefer-destructuring
    let data: BondSameIssuerResponse[] | BondSameIssuerResponse | undefined = result.data;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      if (filterPredicate) {
        data = data.find((position) => filterPredicate(position));
      }
      if (Array.isArray(data)) {
        // eslint-disable-next-line prefer-destructuring
        data = data[0];
      }
    }
    return ({
      ...result,
      data,
      isLoading,
    });
  }, [result, filterPredicate, sortPredicate]);
  return resultWithLoading;
};

type TransformFunction<T> = (securityBondSameIssuer: BondSameIssuerResponse | undefined) => T

type UseBondSameIssuerApiTransformProps<T> = {
  transformFunction: TransformFunction<T>,
} & UseBondsSameIssuerApiProps;

export function useBondSameIssuerTransformApi<T>(props: UseBondSameIssuerApiTransformProps<T>): UseBondsSameIssuerApiTransformResponse<T> {
  const {
    filterPredicate,
    sortPredicate,
    transformFunction,
  } = props;
  const response = useBondSameIssuerApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: !isLoading && !error ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UseBondSameIssuerFieldApiResponse<Field extends keyof BondSameIssuerResponse> = {
  data: BondSameIssuerResponse[Field] | undefined,
} & BaseUseBondSameIssuerApiResponse;

type UseBondSameIssuerFieldApiProps<Field extends keyof BondSameIssuerResponse> = {
  field: Field,
} & UseBondsSameIssuerApiProps

export function useBondSameIssuerFieldApi<Field extends keyof BondSameIssuerResponse>(props: UseBondSameIssuerFieldApiProps<Field>): UseBondSameIssuerFieldApiResponse<Field> {
  const {
    filterPredicate,
    sortPredicate,
    field,
  } = props;
  const response = useBondSameIssuerApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
    } = response;
    return {
      ...response,
      data: data ? data[field] : undefined,
    };
  }, [response, field]);
  return result;
}

const memoFindBySAccId: (data: BondSameIssuerResponse[], sAccId: string) => BondSameIssuerResponse | undefined = memoize((data: BondSameIssuerResponse[], sAccId: string) => data.find((positionLine) => `${positionLine['S_ACC.ID']}` === sAccId));

type UseBondSameIssuerApiTransformBySAccIdProps<T> = {
  sAccId: string,
  transformFunction: TransformFunction<T>,
  sortPredicate?: SortPredicate | undefined,
}

export function useBondSameIssuerTransformApiBySAccId<T>(props: UseBondSameIssuerApiTransformBySAccIdProps<T>): UseBondsSameIssuerApiTransformResponse<T> {
  const {
    sAccId,
    sortPredicate,
    transformFunction,
  } = props;
  const gesEstimDIdLoading = isIdLoading(sAccId);
  const response = useBondsSameIssuerFetch(!gesEstimDIdLoading);
  const result = useMemo(() => {
    const isLoading = !response.data && !response.error;
    // eslint-disable-next-line prefer-destructuring
    let data: BondSameIssuerResponse[] | BondSameIssuerResponse | undefined = response.data;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      data = memoFindBySAccId(data, sAccId);
    }
    return {
      ...response,
      isLoading,
      data: data ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction, sAccId]);
  return result;
}

type UseBondSameIssuerFieldApiPropsByGesEstimDId<Field extends keyof BondSameIssuerResponse> = {
  sAccId: string,
  field: Field,
  sortPredicate?: SortPredicate | undefined,
}

export function useBondSameIssuerFieldApiBySAccId<Field extends keyof BondSameIssuerResponse>(props: UseBondSameIssuerFieldApiPropsByGesEstimDId<Field>): UseBondSameIssuerFieldApiResponse<Field> {
  const {
    sAccId,
    sortPredicate,
    field,
  } = props;
  const gesEstimDIdLoading = isIdLoading(sAccId);
  const response = useBondsSameIssuerFetch(!gesEstimDIdLoading);
  const result = useMemo(() => {
    const isLoading = !response.data && !response.error;
    // eslint-disable-next-line prefer-destructuring
    let data: BondSameIssuerResponse[] | BondSameIssuerResponse | undefined = response.data;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      data = memoFindBySAccId(data, sAccId);
    }
    return {
      ...response,
      isLoading,
      data: data ? data[field] : undefined,
    };
  }, [response, field, sAccId]);
  return result;
}

type TransformFieldFunction<Field extends keyof BondSameIssuerResponse, T> = (securityBondSameIssuer: BondSameIssuerResponse[Field] | undefined) => T

type UseBondSameIssuerFieldTransformApiBySAccIdProps<Field extends keyof BondSameIssuerResponse, T> = {
  transformFn: TransformFieldFunction<Field, T>,
} & UseBondSameIssuerFieldApiPropsByGesEstimDId<Field>;

export function useBondSameIssuerFieldTransformApiBySAccId<Field extends keyof BondSameIssuerResponse, T>(props: UseBondSameIssuerFieldTransformApiBySAccIdProps<Field, T>): UseBondsSameIssuerApiTransformResponse<T> {
  const {
    sAccId,
    sortPredicate,
    field,
    transformFn,
  } = props;
  const response = useBondSameIssuerFieldApiBySAccId({
    sAccId,
    sortPredicate,
    field,
  });
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: !isLoading && !error ? transformFn(data) : undefined,
    };
  }, [response, transformFn]);
  return result;
}
