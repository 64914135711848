import ouiDom from '@goldwasserexchange/oui-dom';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { useAuthMachineSend } from '../../../Auth';

export const LogoutButton = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  const send = useAuthMachineSend();
  return (
    <ouiDom.Button.Button
      type="button"
      onClick={() => send({ type: 'SIGN_OUT', payload: { global: false } })}
      widthLines="full"
      backgroundColor={ouiStyle.Constants.Colors.transparent}
      hoverBackgroundColor={ouiStyle.Constants.Colors.transparent}
      color={ouiStyle.Constants.Colors.inverted}
      textAlign="left"
      fontWeight={ouiStyle.Constants.FontWeightProp.normal}
      paddingHorizontal="simple"
      paddingVertical="simple"
      fontSize="m"
      borderWidth="none"
      borderRadius="none"
      cursor="pointer"
    >
      {children}
    </ouiDom.Button.Button>
  );
};
