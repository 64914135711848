import ouiBase from '@goldwasserexchange/oui';
import { useBondListFieldApiBySAccId } from '../api';

export const useBondListSIssuerName1 = () => {
  const sAccId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const response = useBondListFieldApiBySAccId({
    sAccId,
    field: 'S_ISSUER.NAME_1',
  });
  return response;
};
