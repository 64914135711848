import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import {
  DefaultRadioListInputListElementLabelContainer,
  DefaultDomRadioListInputListElementInput,
  RadioListInputDomListElementButtonContainer,
  RadioListInputDomListElementButtonContent,
} from '../components';

export const radioListInputDomDefaultTopModelsObject = {
  InputListElementLabelContainer: (
    <TopModel name="InputListElementLabelContainer">
      {DefaultRadioListInputListElementLabelContainer}
    </TopModel>
  ),
  InputListElementInput: (
    <TopModel name="InputListElementInput">
      {DefaultDomRadioListInputListElementInput}
    </TopModel>
  ),
  InputListElementButtonContainer: (
    <TopModel name="InputListElementButtonContainer">
      {RadioListInputDomListElementButtonContainer}
    </TopModel>
  ),
  InputListElementButtonContent: (
    <TopModel name="InputListElementButtonContent">
      {RadioListInputDomListElementButtonContent}
    </TopModel>
  ),
};
