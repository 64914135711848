/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiBase from '@goldwasserexchange/oui';
import ouiStyle from '@goldwasserexchange/oui-style';
import React from 'react';
import { defaultOrderedListElementContainerStyle } from '../../Ordered/components/element';
import { defaultUnorderedListElementContainerStyle, defaultUnorderedListElementWithNoMarkContainerStyle } from '../../Unordered';

const useDefaultOrderedListElementContainerCss = () => {
  const lines = ouiBase.List.useListInputElementLines();
  const childPaddings = ouiBase.List.useChildPaddings();
  const css = React.useMemo(() => ouiStyle.makeCss({
    ...defaultOrderedListElementContainerStyle,
    ...childPaddings,
    lines,
  }), [lines, childPaddings]);
  return css;
};

export function DefaultVirtualizedOrderedListElementContainer(
  props: React.PropsWithChildren<{
    style?: React.CSSProperties,
  }>,
) {
  const { style, children } = props;
  const css = useDefaultOrderedListElementContainerCss();
  return <li css={ouiStyle.mediaQuery(css)} style={style}>{children}</li>;
}

const useDefaultUnorderedListElementContainerCss = () => {
  const lines = ouiBase.List.useListInputElementLines();
  const childPaddings = ouiBase.List.useChildPaddings();
  const css = React.useMemo(() => ouiStyle.makeCss({
    ...defaultUnorderedListElementContainerStyle,
    ...childPaddings,
    lines,
  }), [lines, childPaddings]);
  return css;
};

export function DefaultVirtualizedUnorderedListElementContainer(
  props: React.PropsWithChildren<{
    style?: React.CSSProperties,
  }>,
) {
  const { style, children } = props;
  const css = useDefaultUnorderedListElementContainerCss();
  return <li css={ouiStyle.mediaQuery(css)} style={style}>{children}</li>;
}

const useDefaultUnorderedListWithNoMarkElementContainerCss = () => {
  const lines = ouiBase.List.useListInputElementLines();
  const childPaddings = ouiBase.List.useChildPaddings();
  const css = React.useMemo(() => ouiStyle.makeCss({
    ...defaultUnorderedListElementWithNoMarkContainerStyle,
    ...childPaddings,
    lines,
  }), [lines, childPaddings]);
  return css;
};

export function DefaultVirtualizedUnorderedListWithNoMarkElementContainer(
  props: React.PropsWithChildren<{
    style?: React.CSSProperties,
  }>,
) {
  const { style, children } = props;
  const css = useDefaultUnorderedListWithNoMarkElementContainerCss();
  return <li css={ouiStyle.mediaQuery(css)} style={style}>{children}</li>;
}

const useDefaultUnorderedHorizontalListWithNoMarkElementContainerCss = () => {
  const widthLines = ouiBase.List.useListInputElementLines();
  const childPaddings = ouiBase.List.useChildPaddings();
  const css = React.useMemo(() => ouiStyle.makeCss({
    ...defaultUnorderedListElementWithNoMarkContainerStyle,
    ...childPaddings,
    widthLines,
  }), [widthLines, childPaddings]);
  return css;
};

export function DefaultVirtualizedUnorderedHorizontalListWithNoMarkElementContainer(
  props: React.PropsWithChildren<{
    style?: React.CSSProperties,
  }>,
) {
  const { style, children } = props;
  const css = useDefaultUnorderedHorizontalListWithNoMarkElementContainerCss();
  return <li css={ouiStyle.mediaQuery(css)} style={style}>{children}</li>;
}
