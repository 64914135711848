import ouiBase from '@goldwasserexchange/oui';
import { useAtomValue } from 'jotai';
import { useFilterByContextCCurrencyCode, useAmountHieDcTotalTransform } from '../../../../shared';
import { onlineHistoryTimeIntervalStartAtom, onlineHistoryTimeIntervalEndAtom } from '../../../../../../../Components/Sections/online/sections/history/timeNav';
import { useAccountHistoryFieldApiByHisMvtId, useAccountHistoryLinesTransformApi } from '../api';

export const useAccountHistoryAmountHieDcByHisMvtId = () => {
  const hisMvtId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const result = useAccountHistoryFieldApiByHisMvtId({
    hisMvtId,
    field: 'HIS_MVT.AMOUNT_HIE_DC',
  });
  return result;
};

export const useAccountHistoryAmountHieDcAtDateStart = () => {
  const filterPredicate = useFilterByContextCCurrencyCode();
  const dateStart = useAtomValue(onlineHistoryTimeIntervalStartAtom);
  const transformFunction = useAmountHieDcTotalTransform(dateStart);
  const result = useAccountHistoryLinesTransformApi({
    filterPredicate,
    transformFunction,
  });
  return result;
};

export const useAccountHistoryAmountHieDcAtDateEnd = () => {
  const filterPredicate = useFilterByContextCCurrencyCode();
  const dateEnd = useAtomValue(onlineHistoryTimeIntervalEndAtom);
  const transformFunction = useAmountHieDcTotalTransform(dateEnd);
  const result = useAccountHistoryLinesTransformApi({
    filterPredicate,
    transformFunction,
  });
  return result;
};
