import { PersonAccounts } from '../../type';
import { useFilterByTAccId } from '../../utils';
import { usePersonAccountTransformApi } from '../api';

const hasBuySellRights = (line: PersonAccounts | null | undefined) => {
  const tCatShortcut = line ? line['T_CAT.SHORTCUT'] : '';
  const tNatureLrp = line?.['T_NATURE_LRP.SHORTCUT'];
  const tProcType = line?.['T_PROC_TYPE.SHORTCUT'];
  const hasCorrectAccountRights = (tNatureLrp === 'TITULAIRE' || tProcType === 'GENERALE');
  const isCorrectAccountType = (tCatShortcut === 'GOLD' || tCatShortcut === 'CONSEIL');
  return isCorrectAccountType && hasCorrectAccountRights;
};

export const useAccountHasSellRights = () => {
  const filterBySelectedTAccId = useFilterByTAccId();
  const accountTypeAllowTransferResult = usePersonAccountTransformApi({
    transformFunction: hasBuySellRights,
    filterPredicate: filterBySelectedTAccId,
  });
  return accountTypeAllowTransferResult;
};
