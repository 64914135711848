import * as Yup from 'yup';
import {
  UncastedPhysicalTAdd,
} from '@goldwasserexchange/oblis-frontend-utils';
import { tAddPhoneValidator } from '../../tAddPhone';
import {
  makeDateNotMinor,
  makeAtLeastOneTrue,
  numberValidator,
  vatValidator,
  nilValidator,
  makeRequired,
  euroDateValidator,
  makeDateInThePast,
  booleanValidator,
  makeFalseCast,
  leiValidator,
  stringValidator,
  requiredNationalRegisterValidator,
  makeDateMinor,
  makeCapitalized,
} from '../../../OUI/Inputs';

type FundsOriginShape = Record<keyof UncastedPhysicalTAdd['fundsOrigin'], Yup.BooleanSchema<boolean | undefined, Record<string, any>, boolean | undefined>>

const fundsOriginShape: FundsOriginShape = {
  savings: booleanValidator,
  heritage: booleanValidator,
  donation: booleanValidator,
  lotteryCasino: booleanValidator,
};

type NoFundsOriginShape = Record<keyof UncastedPhysicalTAdd['fundsOrigin'], any>

const noFundsOriginShape: NoFundsOriginShape = {
  savings: nilValidator,
  heritage: nilValidator,
  donation: nilValidator,
  lotteryCasino: nilValidator,
};

export const validationsStore = {
  nilCast: nilValidator,
  requiredNotMinorBirthdate: makeRequired(makeDateNotMinor(makeDateInThePast(euroDateValidator))),
  requiredMinorBirthdate: makeRequired(makeDateMinor(makeDateInThePast(euroDateValidator))),
  requiredPastDate: makeRequired(makeDateInThePast(euroDateValidator)),
  requiredString: makeRequired(stringValidator),
  number: numberValidator,
  requiredNumber: makeRequired(numberValidator),
  requiredBoolean: makeRequired(booleanValidator),
  leiCode: leiValidator,
  vat: vatValidator,
  castFalseBoolean: makeFalseCast(booleanValidator),
  requiredNationalRegister: requiredNationalRegisterValidator,
  fundsOrigin: makeAtLeastOneTrue(Yup.object().shape(fundsOriginShape)),
  noFundsOrigin: Yup.object().shape(noFundsOriginShape),
  tAddPhones: Yup.array().of(tAddPhoneValidator),
  noTAddPhones: Yup.array(),
  any: Yup.mixed(),
  capitalizedString: makeCapitalized({}, stringValidator),
  requiredCapitalizedString: makeRequired(makeCapitalized({}, stringValidator)),
  capitalizedNotWithSpaceString: makeCapitalized({
    notWithSpace: true,
  }, stringValidator),
  requiredCapitalizedNotWithSpaceString: makeRequired(makeCapitalized({
    notWithSpace: true,
  }, stringValidator)),
};

export type ValidationsNames = keyof typeof validationsStore;
