export var DisplayProp;
(function (DisplayProp) {
    DisplayProp["hidden"] = "none";
    DisplayProp["block"] = "block";
    DisplayProp["flowRoot"] = "flow-root";
    DisplayProp["inlineBlock"] = "inline-block";
    DisplayProp["inline"] = "inline";
    DisplayProp["flex"] = "flex";
    DisplayProp["inlineFlex"] = "inline-flex";
    DisplayProp["grid"] = "grid";
    DisplayProp["inlineGrid"] = "inline-grid";
    DisplayProp["table"] = "table";
    DisplayProp["tableCaption"] = "table-caption";
    DisplayProp["tableCell"] = "table-cell";
    DisplayProp["tableRow"] = "table-row";
    DisplayProp["tableRowGroup"] = "table-row-group";
    DisplayProp["tableHeaderGroup"] = "table-header-group";
    DisplayProp["listItem"] = "list-item";
})(DisplayProp || (DisplayProp = {}));
export var makeDisplay = function (display, lineClamp) {
    if (display == null && lineClamp != null) {
        return '-webkit-box';
    }
    return display;
};
