import { isFuture, isPast, parseISO } from 'date-fns';
import { sanityClient } from '../../client';
import { slugify } from '../../utils';
import { query } from './query';
import {
  QueryArguments, SwrArguments, BaseQueryResponse, QueryResponse,
} from './types';

const convertSwrArguments = (args: SwrArguments): QueryArguments => {
  const [, locale] = args;
  return {
    locale,
  };
};

export const convertQueryArguments = (args: QueryArguments): SwrArguments => {
  const {
    locale,
  } = args;
  return [query, locale];
};

const transformQueryResponse = (response: BaseQueryResponse): QueryResponse => {
  const {
    news,
    ...rest
  } = response;
  if (news == null) {
    return {
      ...rest,
    };
  }
  return {
    ...rest,
    news: {
      ...news,
      slug: slugify(news),
    },
  };
};

export const filterAndTransformPromoQueryResponse = (baseResponse: BaseQueryResponse[]): QueryResponse[] => baseResponse
  .filter((el) => isPast(parseISO(el.startDate)) && isFuture(parseISO(el.endDate)))
  .map((el) => transformQueryResponse(el));

const baseFetcher = async (args: SwrArguments) => {
  const [queryString] = args;
  const sanityArguments = convertSwrArguments(args);
  const response = await sanityClient.fetch<BaseQueryResponse[] | null, QueryArguments>(queryString, sanityArguments);
  return response;
};

export const fetcher = async (args: SwrArguments) => {
  const baseResponse = await baseFetcher(args);
  if (!baseResponse) {
    return null;
  }
  return baseResponse
    .filter((el) => isPast(parseISO(el.startDate)) && isFuture(parseISO(el.endDate)))
    .map((el) => transformQueryResponse(el));
};
