import { Trans } from '@lingui/macro';
import * as React from 'react';
import {
  dataStructure, UncastedDataStructure, PersonType, TAddType, UncastedOnboarding,
} from '@goldwasserexchange/oblis-frontend-utils';

export const tAddProfessionDescriptionLabel = (
  <Trans>
    Brève description de l'activité professionnelle
  </Trans>
);

const onboardingPath: keyof Pick<UncastedDataStructure, 'onboarding'> = 'onboarding';

const personType: keyof Pick<UncastedOnboarding, 'personType'> = 'personType';

export const tAddProfessionDescriptionValidatorLogic = dataStructure.T_ADD.fields.TYPE.logic.makeTAddTypeLogic<'requiredString' | 'nilCast'>({
  is: TAddType.MORAL,
  alternatives: {
    truthy: {
      name: `${onboardingPath}.${personType}`,
      is: PersonType.MORAL,
      alternatives: {
        truthy: {
          result: 'requiredString',
        },
        falsy: {
          result: 'nilCast',
        },
      },
    },
    falsy: dataStructure.T_ADD.fields.ID_ECPL_PROF.logic.makeIdEcplProfShouldHaveProfessionLogic({
      visible: dataStructure.T_ADD.fields.TYPE.logic.makeTAddIsTypePhysicLogic<'requiredString' | 'nilCast'>({
        alternatives: {
          truthy: {
            result: 'requiredString',
          },
          falsy: {
            result: 'nilCast',
          },
        },
      }),
      invisible: {
        result: 'nilCast',
      },
    }),
  },
});
