import { I18n } from '@lingui/core';
import { Trans, msg } from '@lingui/macro';
import * as React from 'react';

export const globalPlusTwoLineTitleContent = (
  <Trans>
    Compte
    <br />
    Global+
  </Trans>
);

export const globalPlusTitle = (i18n: I18n) => i18n._(msg`compte Global+`);
