import { imageQuery } from '../../shared';

export const query = /* groq */`
*[
  _id == $id
  && _type == "news"
]
{
  _id,
  language,
  title,
  "publishDate": publishDate.utc,
  _updatedAt,
  image ${imageQuery},
  "content": content[] {
    ...,
    _type == "image" => {
        asset->,
        crop,
        hotspot
    },
    "markDefs": markDefs[] {
      ...,
      _type == 'internalLink' => {
        reference -> {
          _type,
          _id,
          title,
          "publishDate": publishDate.utc,
          language,
        },
      },
      _type == 'file' => {
        
        file {
          "url": asset->url,
          "filename": asset->originalFilename,
        }
      },
    },
  },
  "intro": pt::text(content[0]),
  "bondIds": array::unique(
    string::split(
      array::join(
        array::compact(
          [
            ...content[_type == "securityPriceGraph"].securityId,
            ...content[].markDefs[_type == "link" && href match "*/bonds/details/*"]{
              "href": string::split(href, '/')[-1]
            }.href,
            ...content[].markDefs[_type == "bondLink"].securityId,
            ...bondIds[],
            ...content[].children[_type == "bondField"].id,
          ]
        ),
        ','
      ),
      ','
    )
  ),
  "latestNews": *[
    _type == "news"
    && language == ^.language
    && publishDate.utc <= now()
    && _id != ^._id
  ] 
  | order(publishDate.utc desc)
  {
    _id,
    title,
    "publishDate": publishDate.utc,
  }[0...5],
  "translations": *[
    _type == "translation.metadata"
    && references($id)
  ].translations[].value-> {
    _id,
    "publishDate": publishDate.utc,
    language,
    title
  },
  disclaimer,
  showYield,
}[0]
` as const;
