export enum Side {
  SELL = 0,
  BUY = 1,
}

export type OrderDto = {
  sAccId: number,
  tAccId?: number,
  side: Side,
  message?: string,
}

export type Order = OrderDto & {
  pk: string,
  sk: `order#${number}`,
  ttl: number,
};
