import { AccountsIcon } from './accounts/component';
import { BondsIcon } from './bonds';
import { ContactIcon } from './contact';
import { HomeIcon } from './home';
import { NewsIcon } from './news';
import { OnboardingIcon } from './onboarding';

export type NavBarIcons = {
  home: (props: {
    style?: React.SVGAttributes<SVGSVGElement>['style'],
  }) => React.JSX.Element,
  news: (props: {
    style?: React.SVGAttributes<SVGSVGElement>['style'],
  }) => React.JSX.Element,
  bonds: (props: {
    style?: React.SVGAttributes<SVGSVGElement>['style'],
  }) => React.JSX.Element,
  accounts: (props: {
    style?: React.SVGAttributes<SVGSVGElement>['style'],
  }) => React.JSX.Element,
  onboarding: (props: {
    style?: React.SVGAttributes<SVGSVGElement>['style'],
  }) => React.JSX.Element,
  contact: (props: {
    style?: React.SVGAttributes<SVGSVGElement>['style'],
  }) => React.JSX.Element,
}

export const navBarIcons: NavBarIcons = {
  home: HomeIcon,
  news: NewsIcon,
  bonds: BondsIcon,
  accounts: AccountsIcon,
  onboarding: OnboardingIcon,
  contact: ContactIcon,
};
