import { STypeGrp, UncastedOnboarding } from '@goldwasserexchange/oblis-frontend-utils';

export const productSelectedDisabledReasonDependencies: Partial<Record<keyof UncastedOnboarding['products'], (keyof UncastedOnboarding['products'])[]>> = {
  [STypeGrp.OBLIGATIONS]: [
    STypeGrp.OBLIGATIONS_PERP,
    STypeGrp.OBLIGATIONS_SUB,
  ],
  [STypeGrp.OBLIGATIONS_SUB]: [
    STypeGrp.OBLIGATIONS_PERP,
  ],
  [STypeGrp.TRACKERS]: [
    STypeGrp.TRACKERS_MAT,
  ],
};

export const producSelectedDisableReasonMaps: Partial<Record<keyof UncastedOnboarding['products'], string[]>> = {
  [STypeGrp.OBLIGATIONS]: ['PerpetualSelected', 'SubordinatedSelected'],
  [STypeGrp.OBLIGATIONS_SUB]: ['PerpetualSelected'],
  [STypeGrp.TRACKERS]: ['RawMaterialSelected'],
};
