import * as HORIZON from '../HORIZON';
import * as RISK_LEVEL from '../RISK_LEVEL';
import * as MAX_RECOVERY_PERIOD from '../MAX_RECOVERY_PERIOD';
import * as FOREIGN_CURRENCIES from '../FOREIGN_CURRENCIES';
import * as INVESTMENT_GOAL from '../INVESTMENT_GOAL';
export var map = {
    horizon: HORIZON.logic.label.logic,
    riskLevel: RISK_LEVEL.logic.label.logic,
    maxRecoveryPeriod: MAX_RECOVERY_PERIOD.logic.label.logic,
    foreignCurrencies: FOREIGN_CURRENCIES.logic.label.logic,
    investmentGoal: INVESTMENT_GOAL.logic.label.logic,
};
