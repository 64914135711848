import * as React from 'react';
import { useAccountPositionGesEstimDPriceDate } from '../../hooks';
import { ActorApiDateDisplay } from '../../../../../../components/display/date';

export const AccountsPositionsGesEstimDPriceDate = (props: {
  format?: string,
}) => {
  const {
    format,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useAccountPositionGesEstimDPriceDate();
  return (
    <ActorApiDateDisplay
      data={data}
      isLoading={isLoading}
      error={error}
      format={format}
    />
  );
};
