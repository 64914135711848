/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';

const appBarMenuWrapperStyle: ouiStyle.InStyle = {
  display: ouiStyle.Constants.DisplayProp.flex,
  lines: 3,
  justifyContent: ouiStyle.Constants.JustifyContentProp.stretch,
  backgroundColor: ouiStyle.Constants.Colors.logoBlue,
};

const appBarMenuWrapperCss = ouiStyle.makeCss(appBarMenuWrapperStyle);

export const AppBarMenuWrapper = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  return (
    <div
      css={appBarMenuWrapperCss}
    >
      {children}
    </div>
  );
};
