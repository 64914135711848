/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const style: ouiStyle.InStyleWithMediaQueries = {
  display: ouiStyle.Constants.DisplayProp.flex,
  paddingHorizontal: ['half', 'simple', undefined, undefined, undefined, undefined],
  paddingVertical: 'half',
  alignItems: ouiStyle.Constants.AlignItemsProp.center,
  cursor: 'pointer',
};

const css = ouiStyle.mediaQuery(ouiStyle.makeCss(style));

export const ToggleContainer = (props: React.PropsWithChildren<{
  id: string,
  disabled?: boolean,
  disabledOnClick?: React.DOMAttributes<HTMLLabelElement>['onClick'],
}>) => {
  const {
    id,
    disabled,
    disabledOnClick,
    children,
  } = props;
  return (
    <label
      htmlFor={id}
      css={css}
      onClick={disabled ? disabledOnClick : undefined}
    >
      {children}
    </label>
  );
};
