import React from 'react';
import { Trans } from '@lingui/macro';
import {
  dataStructure, UncastedPhysicalTAdd, TAddType, TNatureLrp,
} from '@goldwasserexchange/oblis-frontend-utils';

export const tAddFundsOriginData: Record<number, {
  ID: number,
  fieldPath: keyof UncastedPhysicalTAdd['fundsOrigin'],
  value: JSX.Element,
}> = {
  0: {
    ID: 0,
    fieldPath: 'savings',
    value: (
      <Trans>Epargne</Trans>
    ),
  },
  1: {
    ID: 1,
    fieldPath: 'heritage',
    value: (
      <Trans>Succession</Trans>
    ),
  },
  2: {
    ID: 2,
    fieldPath: 'donation',
    value: (
      <Trans>Donation</Trans>
    ),
  },
  3: {
    ID: 3,
    fieldPath: 'lotteryCasino',
    value: (
      <Trans>Loterie/Casino</Trans>
    ),
  },
};

export const tAddFundsOriginLabel = <Trans>Origine économique de la fortune</Trans>;

export const tAddFundsOriginValidatorLogic = dataStructure.T_ADD.fields.TYPE.logic.makeTAddTypeFilterLogic<'fundsOrigin' | 'noFundsOrigin'>({
  is: TAddType.TITULAR,
  truthy: dataStructure.T_ADD.fields.ID_T_NATURE_LRP.logic.helpers.makeIdTNatureLrpDependentLogic<'fundsOrigin' | 'noFundsOrigin'>({
    is: TNatureLrp.REPRESENTANT_LEGAL,
    options: { isNegative: true },
    truthy: {
      result: 'fundsOrigin',
    },
    falsy: {
      result: 'noFundsOrigin',
    },
  }),
  falsy: {
    result: 'noFundsOrigin',
  },
});
