import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { useOuiStyleMediaContextByIndex } from '../../../../../../../Components/MediaContext';
import { ActorApiNumberDisplay } from '../../../../../../components/display/number/component';
import { useBondListSAccIntRate, useBondsListIsRedacted } from '../../hooks';

export const BondListSAccIntRate = (props: {
  withRedactedIcon?: boolean,
  lockBackgroundColor?: ouiStyle.InStyleWithMediaQueries['backgroundColor'],
  lockWidthLines?: ouiStyle.InStyleWithMediaQueries['widthLines'],
  lockContainer?: ouiStyle.InStyleWithMediaQueries['container'],
  lockPaddingTop?: ouiStyle.InStyleWithMediaQueries['paddingTop'],
  lockPaddingBottom?: ouiStyle.InStyleWithMediaQueries['paddingBottom'],
  lockPaddingLeft?: ouiStyle.InStyleWithMediaQueries['paddingLeft'],
  lockPaddingRight?: ouiStyle.InStyleWithMediaQueries['paddingRight'],
  lockAlignItems?: ouiStyle.InStyleWithMediaQueries['alignItems'],
  LockChild?: () => JSX.Element | null,
}) => {
  const {
    withRedactedIcon,
    lockBackgroundColor,
    lockWidthLines,
    lockContainer,
    lockPaddingTop,
    lockPaddingBottom,
    lockPaddingLeft,
    lockPaddingRight,
    lockAlignItems,
    LockChild,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useBondListSAccIntRate();
  const {
    data: redacted,
    isLoading: redactedIsLoading,
  } = useBondsListIsRedacted('S_ACC.INT_RATE');
  return (
    <ActorApiNumberDisplay
      data={data}
      isLoading={isLoading || redactedIsLoading}
      error={error}
      isBlocked={redacted}
      format={{
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 3,
      }}
      loadingValue={3.625}
      withRedactedIcon={withRedactedIcon}
      lockBackgroundColor={lockBackgroundColor}
      lockWidthLines={lockWidthLines}
      lockContainer={lockContainer}
      lockPaddingTop={lockPaddingTop}
      lockPaddingBottom={lockPaddingBottom}
      lockPaddingLeft={lockPaddingLeft}
      lockPaddingRight={lockPaddingRight}
      lockAlignItems={lockAlignItems}
      LockChild={LockChild}
    />
  );
};

export const BondListSAccIntRateWithMediaQueryRedactedIcon = (props: {
  mediaQueryIndex: number,
  withRedactedIcon?: boolean,
  lockWidthLines?: ouiStyle.InStyleWithMediaQueries['widthLines'],
  lockContainer?: ouiStyle.InStyleWithMediaQueries['container'],
  lockPaddingTop?: ouiStyle.InStyleWithMediaQueries['paddingTop'],
  lockPaddingBottom?: ouiStyle.InStyleWithMediaQueries['paddingBottom'],
  lockPaddingLeft?: ouiStyle.InStyleWithMediaQueries['paddingLeft'],
  lockPaddingRight?: ouiStyle.InStyleWithMediaQueries['paddingRight'],
  lockAlignItems?: ouiStyle.InStyleWithMediaQueries['alignItems'],
  LockChild?: () => JSX.Element | null,
}) => {
  const {
    mediaQueryIndex,
    withRedactedIcon,
    lockWidthLines,
    lockContainer,
    lockPaddingTop,
    lockPaddingBottom,
    lockPaddingLeft,
    lockPaddingRight,
    lockAlignItems,
    LockChild,
  } = props;
  const up = useOuiStyleMediaContextByIndex(mediaQueryIndex);
  return (
    <BondListSAccIntRate
      withRedactedIcon={withRedactedIcon || !up}
      lockWidthLines={lockWidthLines}
      lockContainer={lockContainer}
      lockPaddingTop={lockPaddingTop}
      lockPaddingBottom={lockPaddingBottom}
      lockPaddingLeft={lockPaddingLeft}
      lockPaddingRight={lockPaddingRight}
      lockAlignItems={lockAlignItems}
      LockChild={LockChild}
    />
  );
};
