import ouiBase from '@goldwasserexchange/oui';
import { useFilterBySTypeGrpIdAndCCurrencyCode } from '../../utils';
import { AccountPosition } from '../../type';
import { useAccountPositionFieldApi, useAccountPositionFieldApiByGesEstimD } from '../api';

export const useAccountPositionsGesEstimDQt = (filterFn: (positionLine: AccountPosition) => boolean) => {
  const response = useAccountPositionFieldApi({
    field: 'GES_ESTIM_D.QT',
    filterPredicate: filterFn,
  });
  return response;
};

export const useAccountPositionsGesEstimDQtByPrimaryDimensionGesEstimD = () => {
  const gesEstimDId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const result = useAccountPositionFieldApiByGesEstimD({
    gesEstimDId,
    field: 'GES_ESTIM_D.QT',
  });
  return result;
};

export const useAccountPositionsGesEstimDQtBySTypeGroupAndCCurrencyCode = (cCurrencyCode: AccountPosition['C_CURRENCY.CODE'] | null, sTypeGrpId: AccountPosition['S_TYPE_GRP.ID']) => {
  const filterFn = useFilterBySTypeGrpIdAndCCurrencyCode(cCurrencyCode ?? 'EUR', `${sTypeGrpId}`);
  const response = useAccountPositionsGesEstimDQt(filterFn);
  return response;
};
