import ouiBase from '@goldwasserexchange/oui';
import * as React from 'react';
import { useEffect } from 'react';
import Recaptcha from 'react-recaptcha';
import { UncastedDataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import { useHookstate } from '@hookstate/core';
import { DefaultDomCaptchaInputContainer } from './components/container';
import { useSetRecaptcha } from './useSetRecaptcha';
import { useFieldTouchedByFieldPath } from '../../../../OUI/Form';
import { useLinguiLocale } from '../../../../Components/useLinguiLocale';

export const RecaptchaInput = () => {
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const recaptchaState = useHookstate(valueState.auth.recaptcha);
  const locale = useLinguiLocale();
  const ref = React.useRef<Recaptcha>(null);
  const touched = useFieldTouchedByFieldPath(recaptchaState.path.join('.'));
  const verifyCallback = React.useCallback((response) => {
    recaptchaState.set(response);
    touched.set(true);
  }, [recaptchaState]);
  const setRecaptcha = useSetRecaptcha();
  // sometimes the verifyCallback misfires, we poll the recaptcha value to be extra sure tu fetch it
  useEffect(() => {
    const interval = setInterval(() => {
      setRecaptcha();
      touched.set(true);
    }, 200);
    return () => {
      clearInterval(interval);
    };
  }, [recaptchaState, setRecaptcha]);
  const value = recaptchaState.get();
  useEffect(() => {
    if (value === '' && ref.current) {
      ref.current.reset();
      touched.set(false);
    }
  }, []);
  useEffect(() => {
    const id = 'recaptcha-script';
    const searchScript = document.body.querySelector(`#${id}`);
    if (!searchScript) {
      const script = document.createElement('script');
      script.id = id;
      script.src = `https://www.google.com/recaptcha/api.js?hl=${locale}`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    }
  }, []);
  return (
    <DefaultDomCaptchaInputContainer>
      <Recaptcha
        ref={ref}
        key={locale}
        sitekey="6LdvHjsUAAAAAJGrzkzUnaqvuK6yWgCSpjPsc164"
        verifyCallback={verifyCallback}
        hl={locale}
      />
    </DefaultDomCaptchaInputContainer>
  );
};
