import { dataStructure, UncastedDataStructure, AccountType } from '@goldwasserexchange/oblis-frontend-utils';
import ouiBase from '@goldwasserexchange/oui';
import ouiStyle from '@goldwasserexchange/oui-style';
import ouiDom from '@goldwasserexchange/oui-dom';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { Trans } from '@lingui/macro';
import * as React from 'react';
import { useHookstate } from '@hookstate/core';
import { RadioButton } from '../../../../Form/input/TouchHandles';
import { useOuiStyleMediaContextByIndex } from '../../../../MediaContext';
import { useIsHighlighted } from '../../context';

export const SELECTION_START = 'selection_start';
export const SELECTION_END = 'selection_end';

export const SelectionOnPrimaryDimension = () => {
  const accountTypeDimension = ouiBase.utils.dimensions.useCurrentPrimaryDimension() as dataStructure.accountType.listType;
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const selectedServiceState = useHookstate(valueState.onboarding.selectedService);
  const servicesAccessibility = useHookstate(valueState.onboarding.serviceAccessibility[accountTypeDimension]).get();
  const products = useHookstate(valueState.onboarding.products);
  const requiredProducts = servicesAccessibility === false ? dataStructure.financialProducts.fields.getFailedRequiredProductListFromAccountType(accountTypeDimension, products.get()) : [];
  const disabled = requiredProducts.length > 0 && requiredProducts.some((requiredProduct) => products.get()[requiredProduct].knowledgeQuestionnaires.conseilGestion.failureAndWasRetried);
  return (
    <>
      <RadioButton
        isSelected={selectedServiceState.get() === accountTypeDimension}
        setSelected={() => selectedServiceState.set(accountTypeDimension)}
        disabled={disabled}
      />
      {' '}
      <dataStructure.accountType.labels.LabelOnPrimaryDimension />
    </>
  );
};

const selectionLabel = (
  <Trans>
    Votre choix de service
  </Trans>
);

const SelectionCell = (props: React.PropsWithChildren<ouiDom.Table.TableBodyRowCellInStyle & {
  rowSpan: React.TdHTMLAttributes<HTMLTableDataCellElement>['rowSpan'],
  colSpan: React.TdHTMLAttributes<HTMLTableDataCellElement>['colSpan'],
}>) => {
  const {
    children,
    ...rest
  } = props;
  const isLast = ouiBase.utils.dimensions.useIsLastPrimaryDimensionIndex();
  const isHighlighted = useIsHighlighted();
  return (
    <ouiDom.Table.TableBodyRowCellContainer
      display={[ouiStyle.Constants.DisplayProp.hidden, undefined, ouiStyle.Constants.DisplayProp.tableCell, undefined, undefined, undefined]}
      textAlign={['center', undefined, 'left', undefined, undefined, undefined]}
      fontSize="m"
      color={ouiStyle.Constants.Colors.accent}
      fontWeight={ouiStyle.Constants.FontWeightProp.bold}
      borderBottomWidth={['simple', undefined, 'double', undefined, undefined, undefined]}
      borderBottomColor={isLast ? [isHighlighted ? ouiStyle.Constants.Colors.accent : ouiStyle.Constants.Colors.primary, undefined, ouiStyle.Constants.Colors.primary, undefined, undefined, undefined] : [ouiStyle.Constants.Colors.transparent, undefined, ouiStyle.Constants.Colors.primary, undefined, undefined, undefined]}
      borderTopWidth={['none', undefined, 'double', undefined, undefined, undefined]}
      borderTopColor={[ouiStyle.Constants.Colors.transparent, undefined, ouiStyle.Constants.Colors.primary, undefined, undefined, undefined]}
      borderRightWidth={['simple', undefined, !isLast ? 'none' : 'double', undefined, undefined, undefined]}
      borderRightColor={[isHighlighted ? ouiStyle.Constants.Colors.accent : ouiStyle.Constants.Colors.primary, undefined, !isLast ? ouiStyle.Constants.Colors.transparent : undefined, undefined, undefined, undefined]}
      borderLeftWidth={['simple', undefined, 'none', undefined, undefined, undefined]}
      borderLeftColor={[isHighlighted ? ouiStyle.Constants.Colors.accent : ouiStyle.Constants.Colors.primary, undefined, ouiStyle.Constants.Colors.transparent, undefined, undefined, undefined]}
      borderBottomLeftRadius={['rounded', undefined, 'none', undefined, undefined, undefined]}
      borderBottomRightRadius={['rounded', undefined, 'none', undefined, undefined, undefined]}
      backgroundColor={[ouiStyle.Constants.Colors.inverted, undefined, ouiStyle.Constants.Colors.primaryTranslucid, undefined, undefined, undefined]}
      paddingVertical="double"
      {...rest}
    >
      {children}
    </ouiDom.Table.TableBodyRowCellContainer>
  );
};

const selectionHeading = (props: React.PropsWithChildren<{ scope?: 'col' | 'row' } & ouiDom.Table.TableBodyRowCellInStyle>) => {
  const {
    children,
    ...rest
  } = props;
  const upMq = useOuiStyleMediaContextByIndex(2); // eslint-disable-line react-hooks/rules-of-hooks
  return (
    <ouiDom.Table.TableHeaderHeading
      display={[ouiStyle.Constants.DisplayProp.block, undefined, ouiStyle.Constants.DisplayProp.tableCell, undefined, undefined, undefined]}
      borderTopWidth="double"
      borderTopColor={ouiStyle.Constants.Colors.primary}
      borderBottomWidth={upMq ? 'double' : 'none'}
      borderBottomColor={upMq ? ouiStyle.Constants.Colors.primary : ouiStyle.Constants.Colors.transparent}
      borderLeftWidth="double"
      borderLeftColor={ouiStyle.Constants.Colors.primary}
      borderRightWidth={upMq ? 'none' : 'double'}
      borderRightColor={upMq ? ouiStyle.Constants.Colors.transparent : ouiStyle.Constants.Colors.primary}
      backgroundColor={ouiStyle.Constants.Colors.primaryTranslucid}
      paddingVertical="double"
      {...rest}
    >
      {children}
    </ouiDom.Table.TableHeaderHeading>
  );
};

export const selectionTopModels = (
  <>
    <TopModel
      name={`TableSecondaryTitleCellContainer-${SELECTION_END}`}
    >
      {selectionHeading}
    </TopModel>
    <TopModel
      name={`TableBodyRowCellContainer-${AccountType.RTO}-${SELECTION_END}`}
    >
      {SelectionCell}
    </TopModel>
    <TopModel
      name={`TableBodyRowCellContainer-${AccountType.CONSEIL}-${SELECTION_END}`}
    >
      {SelectionCell}
    </TopModel>
    <TopModel
      name={`TableBodyRowCellContainer-${AccountType.GESTION}-${SELECTION_END}`}
    >
      {SelectionCell}
    </TopModel>
    <TopModel name={`TableSecondaryTitleCellContent-${SELECTION_END}`}>
      {selectionLabel}
    </TopModel>
    <TopModel name={`TableBodyRowCellContent--${SELECTION_END}`}>
      {SelectionOnPrimaryDimension}
    </TopModel>
  </>
);
