import { useBondsListIsRedacted } from '../../../../../listOrFavorites';
import { bondFilterRedactedColumnsDict } from '../redacted';
import { BooleanSearchAndFilterBondParams } from '../type';

export const useBondBooleanFilterDisabled = <Field extends BooleanSearchAndFilterBondParams>(field: Field) => {
  const {
    data,
  } = useBondsListIsRedacted(bondFilterRedactedColumnsDict[field]);
  return data;
};
