import { AccountType } from '@goldwasserexchange/oblis-frontend-utils';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { Trans } from '@lingui/macro';
import * as React from 'react';

export const globalPlusAdvantages = (
  <>
    <TopModel name={`ListElementContent-primaryKey-0-${AccountType.GESTION}-section`}>
      <Trans>
        Le juste équilibre entre croissance et protection
      </Trans>
    </TopModel>
    <TopModel name={`ListElementContent-primaryKey-1-${AccountType.GESTION}-section`}>
      <Trans>
        Plus de 30 ans d'expérience
      </Trans>
    </TopModel>
    <TopModel name={`ListElementContent-primaryKey-2-${AccountType.GESTION}-section`}>
      <Trans>
        Une stratégie diversifiée pour votre épargne
      </Trans>
    </TopModel>
  </>
);
