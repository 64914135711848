import { Locale } from '@goldwasserexchange/i18n';

export const setLink = (props: {
  head: HTMLHeadElement,
  href?: string,
  rel: 'alternate' | 'canonical',
  language?: Locale | 'x-default',
}) => {
  const {
    head,
    href,
    rel,
    language,
  } = props;
  const link: HTMLLinkElement | null = head
    .querySelector(language != null ? `link[rel="${rel}"][hreflang="${language}"]` : `link[rel="${rel}"]`);
  if (link) {
    if (href) {
      link.href = href;
    } else {
      link.remove();
    }
  } else if (href) {
    const newLink = document.createElement('link');
    newLink.rel = rel;
    if (language != null) {
      newLink.hreflang = language;
    } else {
      newLink.removeAttribute('hreflang');
    }
    newLink.href = href;
    head.appendChild(newLink);
  }
};
