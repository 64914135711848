/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { animated } from 'react-spring';
import * as animationController from '../../animation';

const navBarLinkTextContainerStyle: ouiStyle.InStyle = {
  paddingVertical: 'simple',
  color: ouiStyle.Constants.Colors.inverted,
  textDecoration: 'none',
  fontSize: 'm',
  whiteSpace: ouiStyle.Constants.WhiteSpaceProperty.noWrap,
};

const navBarLinkTextContainerCss = ouiStyle.makeCss(navBarLinkTextContainerStyle);

export const NavBarLinkTextContainer = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  const mqDown = animationController.useAnimationmqDownSpringValue();
  const style = {
    paddingLeft: mqDown.to((v) => {
      if (v <= 0.5) {
        return '10px';
      }
      return '0';
    }),
  };
  return <animated.span css={navBarLinkTextContainerCss} style={style}>{children}</animated.span>;
};

export default NavBarLinkTextContainer;
