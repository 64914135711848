import * as React from 'react';
import { MergeOrder, TopModelMergeNameTransformed } from '@goldwasserexchange/react-topmodel';
import { radioListInputDomDefaultTopModelsObject } from './topmodels';

export function DefaultRadioListInputDomTopModelsProvider(
  props: React.PropsWithChildren<{ order?: MergeOrder }>,
) {
  const { children, order } = props;
  return (
    <TopModelMergeNameTransformed
      topModels={radioListInputDomDefaultTopModelsObject}
      order={order}
    >
      {children}
    </TopModelMergeNameTransformed>
  );
}
