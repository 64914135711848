import { useCallback } from 'react';
import { OnChangeContextType } from '../context';

export enum FnMergeOrder {
  before,
  after,
}

export function useEnhanceHandler(
  handler?: OnChangeContextType,
  enhancer?: OnChangeContextType,
  order: FnMergeOrder = FnMergeOrder.before,
): (event: any) => void {
  const enhanced = useCallback(
    (e: any): void => {
      if (order === FnMergeOrder.before && enhancer !== undefined) {
        enhancer(e);
      }
      if (handler) {
        handler(e);
      }
      if (order === FnMergeOrder.after && enhancer !== undefined) {
        enhancer(e);
      }
    },
    [handler, enhancer, order],
  );
  return enhanced;
}
