import ouiBase from '@goldwasserexchange/oui';
import { useCallback } from 'react';
import { UncastedDataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import { useHookstate } from '@hookstate/core';

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    grecaptcha: {
      getResponse,
    },
  }
}

export const useSetRecaptcha = () => {
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const recaptchaStateValue = useHookstate(valueState.auth.recaptcha);
  const setRecaptcha = useCallback(() => {
    if (window && window.grecaptcha && typeof window.grecaptcha.getResponse === 'function') {
      const recaptchaResponse = window.grecaptcha.getResponse();
      recaptchaStateValue.set(recaptchaResponse);
    }
  }, []);
  return setRecaptcha;
};
