import { RampWithNameTransform } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import { useFieldContent, useGetFieldPath } from '../../../../../../context';

export function DefaultTextInputComponentContainer() {
  const fieldContent = useFieldContent();
  const htmlName = useGetFieldPath();
  return (
    <RampWithNameTransform<{
      htmlName: string,
      value: string,
    }>
      rampName="InputComponent"
      htmlName={htmlName}
      value={fieldContent.get()}
    />
  );
}
