import useSWR, { KeyedMutator } from 'swr';
import { useMemo } from 'react';
import { getServiceUrl } from '../../../../shared';
import { useBondsBondStringifiedParams } from './defaultParams';
import { ACTOR_DEDUPING_INTERVAL } from '../../../shared';
import { Bonds } from '../../enum';
import { BondResponse } from '../type';
import { fetchWithOptionalAuth } from '../../../shared/utils/fetchWithAuth';
import { makeApiUrl } from '../../../../../../aws';

const useMakeBondsBondUrl = () => {
  const stringifiedParams = useBondsBondStringifiedParams();
  const url = stringifiedParams !== null ? `${makeApiUrl('actor')}/${getServiceUrl(Bonds.Bond)}${stringifiedParams}` : null;
  return url;
};

type BaseUseBondsBondApiResponse = {
  isLoading: boolean,
  error?: any,
  mutate: KeyedMutator<BondResponse[]>,
  isValidating: boolean,
}

type UseBondsBondApiResponse = {
  data: BondResponse | undefined,
} & BaseUseBondsBondApiResponse

export const useBondsBondApi = (): UseBondsBondApiResponse => {
  const url = useMakeBondsBondUrl();
  const result = useSWR<BondResponse[], any>(
    url,
    fetchWithOptionalAuth,
    { dedupingInterval: ACTOR_DEDUPING_INTERVAL },
  );
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    const { data } = result;
    return ({
      ...result,
      data: data ? data[0] : undefined,
      isLoading,
    });
  }, [result]);
  return resultWithLoading;
};

type UseBondsBondTransformApiResponse<T> = {
  data: T | undefined,
} & BaseUseBondsBondApiResponse

export const useBondsBondTransformApi = <T>(transformFn: (bondsBond: BondResponse | undefined) => T): UseBondsBondTransformApiResponse<T> => {
  const result = useBondsBondApi();
  const resultWithLoading = useMemo(() => {
    const { data, isLoading } = result;
    return ({
      ...result,
      data: isLoading ? undefined : transformFn(data),
    });
  }, [result]);
  return resultWithLoading;
};

type UseBondsBondFieldApiResponse<T extends keyof BondResponse> = {
  data: BondResponse[T] | undefined,
} & BaseUseBondsBondApiResponse

export const useBondsBondFieldApi = <T extends keyof BondResponse>(field: T): UseBondsBondFieldApiResponse<T> => {
  const result = useBondsBondApi();
  const resultWithLoading = useMemo(() => {
    const { data, isLoading } = result;
    return ({
      ...result,
      data: isLoading || !data ? undefined : data[field],
    });
  }, [result, field]);
  return resultWithLoading;
};

export const useBondsBondFieldTransformFnApi = <T, Field extends keyof BondResponse>(props: {
  field: Field,
  transformFn: (securityBond: BondResponse[Field] | undefined) => T,
}): UseBondsBondTransformApiResponse<T> => {
  const {
    field,
    transformFn,
  } = props;
  const result = useBondsBondApi();
  const resultWithLoading = useMemo(() => {
    const { data, isLoading } = result;
    return ({
      ...result,
      data: isLoading || !data ? undefined : transformFn(data[field]),
    });
  }, [result]);
  return resultWithLoading;
};
