import React, { PropsWithChildren } from 'react';
import { Trans } from '@lingui/macro';
import { useLayout } from '../Layout/helpers';
import { Slot } from '../Layout/components';
import { IconName } from '../Icons';
import { CardContainerWithRef } from './container';
import { CardHeader } from './header';
import { CardContent } from './content';
import { SuccessState } from './type';

type CardProps = {
  innerRef?: React.Ref<HTMLDivElement>,
  iconName?: IconName,
  successState?: SuccessState,
  textContent?: boolean,
}

export const Card = (props: PropsWithChildren<CardProps>): JSX.Element => {
  const {
    innerRef,
    children,
    iconName,
    successState,
    textContent = true,
  } = props;
  const {
    title,
    content,
  } = useLayout(children);
  return (
    <CardContainerWithRef ref={innerRef} successState={successState}>
      <CardHeader iconName={iconName} successState={successState}>
        {title}
      </CardHeader>
      {textContent
        ? (
          <CardContent>
            {content}
          </CardContent>
        )
        : content}
    </CardContainerWithRef>
  );
};

export const SuccessCard = (props: React.PropsWithChildren<{
  textContent?: boolean,
}>) => {
  const {
    textContent,
    children,
  } = props;
  return (
    <Card
      iconName={IconName.CheckCircle}
      successState="success"
      textContent={textContent}
    >
      <Slot slot="title">
        <Trans>Bravo !</Trans>
      </Slot>
      <Slot slot="content">
        {children}
      </Slot>
    </Card>
  );
};

export const ProblemCard = (props: React.PropsWithChildren<{
  textContent?: boolean,
}>) => {
  const {
    textContent,
    children,
  } = props;
  return (
    <Card
      iconName={IconName.AlertTriangle}
      successState="error"
      textContent={textContent}
    >
      <Slot slot="title">
        <Trans>Attention !</Trans>
      </Slot>
      <Slot slot="content">
        {children}
      </Slot>
    </Card>
  );
};

export const WarningCard = (props: React.PropsWithChildren<{
  textContent?: boolean,
}>) => {
  const {
    textContent,
    children,
  } = props;
  return (
    <Card
      iconName={IconName.AlertTriangle}
      successState="warning"
      textContent={textContent}
    >
      <Slot slot="title">
        <Trans>Avertissement</Trans>
      </Slot>
      <Slot slot="content">
        {children}
      </Slot>
    </Card>
  );
};
