import ouiDom from '@goldwasserexchange/oui-dom';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { UserNamePasswordInputContent } from './content';

export const UserNamePassword = (props: {
  withUserName?: boolean,
  withForgot?: boolean,
  withSignUp?: boolean,
  withChangeUser?: boolean,
  setWithUserName?: React.Dispatch<React.SetStateAction<boolean>>,
}) => {
  const {
    withUserName,
    withForgot = true,
    withSignUp = true,
    withChangeUser,
    setWithUserName,
  } = props;
  return (
    <ouiDom.HeadingSection.HeadingSection
      name="UserNamePasswordSignIn"
      index={0}
      backgroundColor={ouiStyle.Constants.Colors.transparent}
      container={ouiStyle.Constants.Container.slim}
      paddingHorizontal="double"
    >
      <UserNamePasswordInputContent
        withUserName={withUserName}
        withForgot={withForgot}
        withSignUp={withSignUp}
        withChangeUser={withChangeUser}
        setWithUserName={setWithUserName}
      />
    </ouiDom.HeadingSection.HeadingSection>
  );
};
