import * as React from 'react';
import { ActorApiNumberDisplayCurrency } from '../../../../../../components/display/number/currency';
import { useAccountAvailableCashCCurrencyQt, useAccountAvailableCashCCurrencyCode } from '../../hooks';

export const AccountAvailableCashCCurrencyQt = () => {
  const {
    data,
    isLoading,
    error,
  } = useAccountAvailableCashCCurrencyQt();
  const {
    data: currencyData,
    isLoading: currencyIsLoading,
    error: currencyError,
  } = useAccountAvailableCashCCurrencyCode();
  return (
    <ActorApiNumberDisplayCurrency
      data={data}
      isLoading={isLoading}
      error={error}
      currencyData={currencyData}
      currencyIsLoading={currencyIsLoading}
      currencyError={currencyError}
    />
  );
};
