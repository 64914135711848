import ouiBase from '@goldwasserexchange/oui';
import { RampWithNameTransform, TopModelContextProvider } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import { DefaultTextInputBaseProps } from '.';
import { LocalRifmArgs } from './context/rifm/context';

export function TextInputStructure<M>(
  props: React.PropsWithChildren<{
    fieldPath: string,
    defaultTopModels: M,
    dependenciesFieldPaths?: string[],
    type?: Exclude<ouiBase.Input.InputTypes, 'radio' | 'checkbox'>,
    autocomplete?: boolean,
  } & LocalRifmArgs>,
) {
  const {
    fieldPath,
    defaultTopModels,
    dependenciesFieldPaths,
    format,
    replace,
    append,
    mask,
    accept,
    type,
    autocomplete,
    children,
  } = props;
  return (
    <TopModelContextProvider topModels={defaultTopModels}>
      {children}
      <RampWithNameTransform<DefaultTextInputBaseProps>
        rampName="InputBase"
        fieldPath={fieldPath}
        dependenciesFieldPaths={dependenciesFieldPaths}
        format={format}
        replace={replace}
        autocomplete={autocomplete}
        append={append}
        mask={mask}
        accept={accept}
        type={type}
      />
    </TopModelContextProvider>
  );
}
