import { RampWithNameTransform } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';

export function DefaultDomInputHelpIconBase(props: { style: any }) {
  const { style } = props;
  return (
    <RampWithNameTransform rampName="InputHelpIconContext">
      <RampWithNameTransform rampName="InputHelpIconContainer" style={style}>
        <RampWithNameTransform rampName="InputHelpIconContent" />
      </RampWithNameTransform>
    </RampWithNameTransform>
  );
}
