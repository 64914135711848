import ouiStyle from '@goldwasserexchange/oui-style';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import {
  HelpCircle, Loader, MessageSquare, User,
} from 'react-feather';
import { SignInStateSwitcher } from './signInStateSwitcher';

const UserNamePasswordIcon = (props: {
  size: number,
  stroke: ouiStyle.Constants.Colors,
}) => {
  const {
    size,
    stroke,
  } = props;
  return (
    <User
      size={size}
      color={stroke}
    />
  );
};

const SignInLoadingIcon = (props: {
  size: number,
  stroke: ouiStyle.Constants.Colors,
}) => {
  const {
    size,
    stroke,
  } = props;
  return (
    <Loader
      size={size}
      color={stroke}
    />
  );
};

const InputCodeIcon = (props: {
  size: number,
  stroke: ouiStyle.Constants.Colors,
}) => {
  const {
    size,
    stroke,
  } = props;
  return (
    <MessageSquare
      size={size}
      color={stroke}
    />
  );
};

const ForgotPasswordInputUserNameIcon = (props: {
  size: number,
  stroke: ouiStyle.Constants.Colors,
}) => {
  const {
    size,
    stroke,
  } = props;
  return (
    <HelpCircle
      size={size}
      color={stroke}
    />
  );
};

export const SignInModalIcon = (props: {
  size: number,
  stroke: ouiStyle.Constants.Colors,
}) => {
  const {
    size,
    stroke,
  } = props;
  return (
    <SignInStateSwitcher
      size={size}
      stroke={stroke}
    >
      <TopModel name="UserNamePassword">
        {UserNamePasswordIcon}
      </TopModel>
      <TopModel name="ReAuth">
        {UserNamePasswordIcon}
      </TopModel>
      <TopModel name="InputCode">
        {InputCodeIcon}
      </TopModel>
      <TopModel name="ConfirmSignUp">
        {InputCodeIcon}
      </TopModel>
      <TopModel name="SignInLoading">
        {SignInLoadingIcon}
      </TopModel>
      <TopModel name="ForgotPasswordInputUserName">
        {ForgotPasswordInputUserNameIcon}
      </TopModel>
      <TopModel name="ForgotPasswordInputCodeAndNewPassword">
        {ForgotPasswordInputUserNameIcon}
      </TopModel>
    </SignInStateSwitcher>
  );
};
