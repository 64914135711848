import React from 'react';
import { Trans } from '@lingui/macro';
import { dataStructure } from '@goldwasserexchange/oblis-frontend-utils';

export const hadProfessionLabel = <Trans>Avez-vous exercé une activité professionnelle par le passé ?</Trans>;

export const tAddHadProfessionValidatorLogic = dataStructure.T_ADD.fields.HAD_PROFESSION.logic.show.makeShowLogic<'castFalseBoolean' | 'requiredBoolean'>({
  truthy: {
    result: 'requiredBoolean',
  },
  falsy: {
    result: 'castFalseBoolean',
  },
});
