import { AppBarGuardObjectType } from './type';
import makeIsMqUp from './isMqUp';
import makeIsMqDown from './isMqDown';
import isTargetOpen from './isTargetOpen';
import isTargetClosed from './isTargetClosed';
import isTargetCollapsed from './isTargetCollapsed';
import isMqAnimatedUp from './isMqAnimatedUp';
import isMqAnimatingUp from './isMqAnimatingUp';
import isMqAnimatedDown from './isMqAnimatedDown';
import isMqAnimatingDown from './isMqAnimatingDown';
import shouldLogoExpand from './shouldLogoExpand';
import shouldLogoShrink from './shouldLogoShrink';
import isTargetOpenShrinked from './isTargetOpenShrinked';
import isTargetOpenExpanded from './isTargetOpenExpanded';

export * as isMqUp from './isMqUp';
export * as isMqDown from './isMqDown';
export * as isTargetOpen from './isTargetOpen';
export * as isTargetClosed from './isTargetClosed';
export * as isTargetCollapsed from './isTargetCollapsed';
export * as isMqAnimatedUp from './isMqAnimatedUp';
export * as isMqAnimatingUp from './isMqAnimatingUp';
export * as isMqAnimatedDown from './isMqAnimatedDown';
export * as isMqAnimatingDown from './isMqAnimatingDown';
export * as shouldLogoExpand from './shouldLogoExpand';
export * as shouldLogoShrink from './shouldLogoShrink';
export * as isTargetOpenShrinked from './isTargetOpenShrinked';
export * as isTargetOpenExpanded from './isTargetOpenExpanded';

export const makeGuards = (
  matcher: MediaQueryList,
): AppBarGuardObjectType => ({
  ...makeIsMqUp(matcher),
  ...makeIsMqDown(matcher),
  ...isTargetOpen,
  ...isTargetClosed,
  ...isTargetCollapsed,
  ...isTargetOpenShrinked,
  ...isTargetOpenExpanded,
  ...isMqAnimatedUp,
  ...isMqAnimatingUp,
  ...isMqAnimatedDown,
  ...isMqAnimatingDown,
  ...shouldLogoExpand(matcher),
  ...shouldLogoShrink(matcher),
});

export default makeGuards;
