import { useContext, useMemo } from 'react';
import { endOfDay } from 'date-fns';
import { GetSecuritiesQuotesParams } from '@goldwasserexchange/actor/rest-services';
import { stringifyParams } from '../../../shared/utils/makeUrl';
import { securityQuotesParamsContext } from '../context';
import { formatApiParamDate } from '../../../../shared';
import { useIsConnected } from '../../../../../../Components/Auth';

export const useSecuritiesQuotesDefaultParams = (params: Partial<GetSecuritiesQuotesParams>): Partial<GetSecuritiesQuotesParams> => {
  const {
    'S_ACC.ID': contextSAccId,
    'S_QUOTE.ARCHIVE_DATE_START': contextSQuoteArchiveDateStart,
    'S_QUOTE.ARCHIVE_DATE_END': contextSQuoteArchiveDateEnd,
  } = useContext(securityQuotesParamsContext);
  const sAccId = params['S_ACC.ID'] ?? contextSAccId;
  const sQuoteArchiveDateStart = params['S_QUOTE.ARCHIVE_DATE_START'] ?? contextSQuoteArchiveDateStart;
  const sQuoteArchiveDateEnd = params['S_QUOTE.ARCHIVE_DATE_END'] ?? contextSQuoteArchiveDateEnd ?? formatApiParamDate(endOfDay(Date.now()));
  const defaultParams = useMemo(() => ({
    'S_ACC.ID': sAccId,
    'S_QUOTE.ARCHIVE_DATE_START': sQuoteArchiveDateStart,
    'S_QUOTE.ARCHIVE_DATE_END': sQuoteArchiveDateEnd,
  }), [
    sAccId,
    sQuoteArchiveDateStart,
    sQuoteArchiveDateEnd,
  ]);
  return defaultParams;
};

export const useSecurityQuotesStringifiedParams = () => {
  const contextParams = useSecuritiesQuotesDefaultParams({});
  const isConnected = useIsConnected();
  if (!isConnected) {
    return null;
  }
  return contextParams['S_ACC.ID'] != null ? stringifyParams(contextParams) : null;
};
