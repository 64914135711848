import { mediaQuerizeProps } from '../mediaQuery';
export var makeAspectRatio = function (aspectRatio) {
    if (Array.isArray(aspectRatio)) {
        return mediaQuerizeProps(aspectRatio).map(function (el) { return makeAspectRatio(el); });
    }
    if (typeof aspectRatio === 'number') {
        return "".concat(aspectRatio);
    }
    return aspectRatio;
};
