import { makeJsonLd } from './make';
import { JsonLd } from './types';

export const setJsonLd = (props: {
  head: HTMLHeadElement,
  jsonLd: JsonLd | undefined,
}) => {
  const {
    head,
    jsonLd,
  } = props;
  const text = makeJsonLd(jsonLd);
  const scriptElement: HTMLScriptElement | null = head.querySelector('script[type="application/ld+json"]');
  if (scriptElement) {
    if (text == null) {
      scriptElement.remove();
    } else {
      scriptElement.text = text;
    }
  } else if (text != null) {
    const newScriptElement: HTMLScriptElement = document.createElement('script');
    newScriptElement.type = 'application/ld+json';
    newScriptElement.text = text;
    head.appendChild(newScriptElement);
  }
};
