import * as React from 'react';
import { usePersonAccountsCCurrencyTAccCode } from '../../../../persons';
import { ActorApiNumberDisplayCurrency } from '../../../../../../components/display/number/currency';
import {
  useAccountCashflowsCpsHisValueAfterTaxAndComInAccountCurrency,
  useAccountCashflowsCpsHisValueAfterTaxAndCom,
  useAccountCashflowsCCurrencyCode,
  useAccountCashflowsCpsHisValueAfterTaxAndComInAccountCurrencyTotal,
  useAccountCashflowsCpsHisRefundValue,
  useAccountCashflowsCpsHisRefundValueInAccountCurrency,
  useAccountCashflowsCpsHisValueAfterTaxAndComWithRefundInAccountCurrencyTotal,
} from '../../hooks';

export const AccountsCashflowsCpsHisValueAfterTaxAndCom = () => {
  const {
    data,
    isLoading,
    error,
  } = useAccountCashflowsCpsHisValueAfterTaxAndCom();
  const {
    data: currencyData,
    isLoading: currencyIsLoading,
    error: currencyError,
  } = useAccountCashflowsCCurrencyCode();
  return (
    <ActorApiNumberDisplayCurrency
      data={data}
      isLoading={isLoading}
      error={error}
      currencyData={currencyData}
      currencyIsLoading={currencyIsLoading}
      currencyError={currencyError}
    />
  );
};

export const AccountsCashflowsCpsHisValueAfterTaxAndComInAccountCurrency = () => {
  const {
    data,
    isLoading,
    error,
  } = useAccountCashflowsCpsHisValueAfterTaxAndComInAccountCurrency();
  const {
    data: currencyData,
    isLoading: currencyIsLoading,
    error: currencyError,
  } = usePersonAccountsCCurrencyTAccCode();
  return (
    <ActorApiNumberDisplayCurrency
      data={data}
      isLoading={isLoading}
      error={error}
      currencyData={currencyData}
      currencyIsLoading={currencyIsLoading}
      currencyError={currencyError}
    />
  );
};

export const AccountsCashflowsCpsHisValueAfterTaxAndComInAccountCurrencyTotal = () => {
  const {
    data,
    isLoading,
    error,
  } = useAccountCashflowsCpsHisValueAfterTaxAndComInAccountCurrencyTotal();
  const {
    data: currencyData,
    isLoading: currencyIsLoading,
    error: currencyError,
  } = usePersonAccountsCCurrencyTAccCode();
  return (
    <ActorApiNumberDisplayCurrency
      data={data}
      isLoading={isLoading}
      error={error}
      currencyData={currencyData}
      currencyIsLoading={currencyIsLoading}
      currencyError={currencyError}
    />
  );
};

export const AccountsCashflowsCpsHisRefundValue = () => {
  const {
    data,
    isLoading,
    error,
  } = useAccountCashflowsCpsHisRefundValue();
  const {
    data: currencyData,
    isLoading: currencyIsLoading,
    error: currencyError,
  } = useAccountCashflowsCCurrencyCode();
  return (
    <ActorApiNumberDisplayCurrency
      data={data}
      isLoading={isLoading}
      error={error}
      currencyData={currencyData}
      currencyIsLoading={currencyIsLoading}
      currencyError={currencyError}
    />
  );
};

export const AccountsCashflowsCpsHisRefundValueInAccountCurrency = () => {
  const {
    data,
    isLoading,
    error,
  } = useAccountCashflowsCpsHisRefundValueInAccountCurrency();
  const {
    data: currencyData,
    isLoading: currencyIsLoading,
    error: currencyError,
  } = usePersonAccountsCCurrencyTAccCode();
  return (
    <ActorApiNumberDisplayCurrency
      data={data}
      isLoading={isLoading}
      error={error}
      currencyData={currencyData}
      currencyIsLoading={currencyIsLoading}
      currencyError={currencyError}
    />
  );
};

export const AccountsCashflowsCpsHisValueAfterTaxAndComWithRefundInAccountCurrencyTotal = () => {
  const {
    data,
    isLoading,
    error,
  } = useAccountCashflowsCpsHisValueAfterTaxAndComWithRefundInAccountCurrencyTotal();
  const {
    data: currencyData,
    isLoading: currencyIsLoading,
    error: currencyError,
  } = usePersonAccountsCCurrencyTAccCode();
  return (
    <ActorApiNumberDisplayCurrency
      data={data}
      isLoading={isLoading}
      error={error}
      currencyData={currencyData}
      currencyIsLoading={currencyIsLoading}
      currencyError={currencyError}
    />
  );
};
